import {
  NetworkError,
  ApiTokenExpiredError,
  ApiRefreshTokenExpiredError,
} from "core/errors";
import { Parser, Transformer } from "core/jsonApi";
import get from "lodash/get";

const EXPIRED_TOKEN = "expired_token";
const INVALID_CREDENTIALS = "Invalid credentials";
const USERNAME_NOT_FOUND = "Username could not be found";

/*
 *
 * throwRequestError
 * Returns the correct Error based on the status code
 * And on the errors returned by the back-end
 * @param {Reponse} reponse
 * @param {Object} json
 * @return {Object} Error
 *
 */
function throwRequestError(response, json) {
  // If there are no errors
  // Throw a simple NetworkError
  //v6 errors
  if (json.data) {
    const parsedData = Parser.parse(json);
    throw new NetworkError({
      statusCode: response.status,
      message: get(json, "data.attributes.message"),
      extra: get(json, "data.attributes.extra"),
      statusText: get(json, "data.attributes.code"),
      responseData: parsedData,
    });
  }

  if (!json.errors && !json.error) {
    throw new NetworkError();
  }

  if (json.error) {

    if (json.error.code === EXPIRED_TOKEN)
      throw new ApiTokenExpiredError(EXPIRED_TOKEN);

    throw new NetworkError(
      {
        statusCode: response.status,
        message: json.error.message,
        extra: json.error.extra,
        statusText: json.error.code,
      },
      errors
    );
  }
  const { status } = response;

  // Get the error message from the back-end
  const { errors } = json;
  const firstError = errors[0];

  // specific 403 errors
  if (status === 403) {
    if (firstError.detail.match(INVALID_CREDENTIALS)) {
      throw new ApiTokenExpiredError(INVALID_CREDENTIALS);
    }

    if (firstError.detail.match(USERNAME_NOT_FOUND)) {
      throw new ApiRefreshTokenExpiredError(USERNAME_NOT_FOUND);
    }
  }

  // use detail first, more explicit. If doesn\'t exist, take more generic title
  throw new NetworkError(
    {
      statusCode: status,
      message: firstError.detail || firstError.title,
    },
    errors
  );
}

export default throwRequestError;
