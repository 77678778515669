import { getKeyword } from "app/state/ducks/application/selectors";
import { resourceCreate, resourceFetch } from "app/state/ducks/ressources/actions";
import globalStore from "app/state/store";
import { useDispatch, useSelector } from "react-redux";
import ResourceFactory from "app/state/orm/resources/resourceFactory";
import { getVerifiedUsername, isAnonymousUserParticipation } from "utilities/username";
import { isValidParticipation } from "app/views/components/UserVisibility/validParticipation";
import { useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { genericResourcesSelector } from "app/state/ducks/ressources/selectors";

export const usePostQuote = () => {
  const keyword = useSelector(getKeyword);
  const state = globalStore.getState();
  const resourceFactory = new ResourceFactory(state);
  const dispatch = useDispatch();

  const handlePostQuote = useCallback(
    (quote, onSent) => {
      const { message } = quote;
      const callbackOnValid = () => {
        const isAnonymous = isAnonymousUserParticipation();
        const newQuote = resourceFactory.create("Quote", {
          quote: message,
          username: getVerifiedUsername(),
        });

        let slug = ["event", keyword, "quotes"];
        const callbackFunc = (error, response) => {
          if (error) setError(error);

          onSent && onSent(error, response);
        };

        dispatch(
          resourceCreate(newQuote, {
            slug: slug,
            inject: [
              {
                path: "data.attributes.is_anonymous",
                value: isAnonymous,
              },
            ],
            callback: callbackFunc,
          })
        );

        const messages = document.getElementById("onScrollDown");
        const scrollToBottom = () => (messages.scrollTop = messages.scrollHeight);
        setTimeout(scrollToBottom, 1000);
        return;
      };

      isValidParticipation({ callbackOnValid: callbackOnValid });
    },
    [dispatch]
  );

  return handlePostQuote;
};

export const useReplayQuotes = (wiz, replay, currentTimestamp) => {
  const dispatch = useDispatch();
  const replayDateUnix = dayjs(replay.starts_at).add(currentTimestamp, "seconds").unix();

  let query = [
    {
      key: "sort",
      value: "published",
    },
    {
      key: "unmoderated",
      value: false,
    },
    {
      key: "start",
      value: dayjs(replay.starts_at).add(currentTimestamp, "seconds").toISOString(),
    },
    {
      key: "limit",
      value: 50,
    },
    {
      key: "replay",
      value: true,
    },
  ];

  useEffect(() => {
    if (wiz.status !== "replay") return;

    dispatch(
      resourceFetch("quote", {
        slug: ["event", replay.event.keyword, "quotes"],
        query: query,
        silent: true,
      })
    );
  }, []);

  const quotes = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "quote",
      sort: "created_at",
      order: "desc",
      filter: ({ published_at, moderate }) => {
        return moderate && dayjs(published_at).unix() <= replayDateUnix;
      },
    })
  );

  return quotes.slice(0, 50);
};
