import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Form from "./Form";
import { resourceCreate, resourceFetch } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import { useDispatch, useSelector } from "react-redux";

import { accessesSelector } from "app/state/ducks/ressources/selectors";
import FormEdit from "./FormEdit";
import { generateHash } from "utilities/utils/generateHash";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.colors.white,
    width: 500,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: 20,
    borderRadius: "5px",
  },

  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginBottom: "16px",
  },
  add: {
    display: "flex",
    gap: 20,
    alignItems: "center",
  },
}));

const Members = ({ hashGroup, hasRights }) => {
  const classes = useStyles();
  const [formValue, setFormValue] = useState({ email: "", scope: "vendor" });
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const accesses = useSelector(accessesSelector).filter((access) => access.type === "group" && access.group.hash === hashGroup);

  const handleAddAccess = () => {
    const newAccess = resourceFactory.create("Access", { ...formValue, hash: generateHash(40) });

    dispatch(
      resourceCreate(newAccess, {
        slug: ["groups", hashGroup, "admins"],

        callback: (error, response) => {
          if (error) {
            console.log(error);
          }
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      resourceFetch("access", {
        slug: ["groups", hashGroup],
        callback: (error, response) => {
          if (error) {
            console.log("error", error);
          }
        },
      })
    );
  }, []);

  return (
    <Box className={classes.root}>

      {hasRights && (
        <>
          <Typography className={classes.title}>{_t("Manage Members")}</Typography>
          <Box className={classes.add}>
            <Form setFormValue={setFormValue} formValue={formValue} />
            <Button style={{ marginBottom: "16px" }} onClick={handleAddAccess}>
              {_t("Add")}
            </Button>
          </Box>
        </>
      )}

      <Typography className={classes.title}>{_t("Members")}</Typography>
      <>
        {!hasRights && accesses.map((access) => (
          <Typography>{access.ref.user.email} • {access.scope}</Typography>
        ))}

        {hasRights && accesses.map((access) => (
          <FormEdit access={access} email={access.ref.user.email} scope={access.scope} hashGroup={hashGroup} />
        ))}
      </>
    </Box>
  );
};

Members.propTypes = {};

Members.defaultProps = {};

export default Members;
