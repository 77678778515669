import { resourceEdit } from "app/state/ducks/ressources/actions";
import { API_BASE } from "config";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { _t } from "utilities/i18n";
import { getUserSession } from "utilities/session";

export const streamKeysGenerator = (rtmp_server_url, wiz, dispatch) => {
  const session = getUserSession();
  const apiBase = API_BASE
    .clone()
    .segment("liveshopping")
    .segment("mux")
    .addQuery("url", "https://api.mux.com/video/v1/live-streams")
    .toString()
    .replace('6', '4');

  const headers = {
    "Wisembly-Token": session.token,
    "Accept": "application/json",
    "Content-Type": "application/json",
  };

  fetch(apiBase, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      playback_policy: ['public'],
      new_asset_settings: {
        playback_policy: ['public'],
      },
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (!response?.success) {
        emitter.emit(EVENT_FLASH_ERROR, _t("Cannot create stream keys"));
        return;
      }

      const { id, stream_key, playback_ids } = response.success.data.data;
      const playback_id = playback_ids[0]?.id;

      dispatch(
        resourceEdit(
          Object.assign(wiz, {
            extra_settings: {
              ...wiz.extra_settings,
              stream_key,
              playback_id,
              live_stream_id: id,
              rtmp_server_url: rtmp_server_url
            },
            live_media: { url: `https://stream.mux.com/${playback_id}.m3u8`, enabled: false },
          }),
          {
            slug: ["event", wiz.keyword],
            silent: true,
            patch: ["extra_settings", "live_media"],

            callback: (error) => {
              if (error) {
                emitter.emit(EVENT_FLASH_ERROR, _t("An error has been detected"));
                return;
              }
              emitter.emit(EVENT_FLASH_SUCCESS, _t("Stream keys generated successfully"));
            },
          }
        )
      );
    });
};
