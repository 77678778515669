import { Box, makeStyles } from "@material-ui/core";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { API_BASE } from "config";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { _t } from "utilities/i18n";
import { getUserSession } from "utilities/session";
import Card from "./Card";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    gap: "16px",
    padding: "25px",
    flexWrap: "wrap",
  },
}));

const Library = () => {
  const classes = useStyles();
  const [lives, setLives] = useState(null);
  const wiz = useSelector(currentWizSelector);

  useEffect(() => {
    const session = getUserSession();

    if (!wiz.extra_settings.live_stream_id) {
      setLives([]);
      return;
    }

    const apiBase = API_BASE
      .clone()
      .segment("liveshopping")
      .segment("mux")
      .addQuery("url", `https://api.mux.com/video/v1/assets?live_stream_id=${wiz.extra_settings.live_stream_id}`)
      .toString()
      .replace('6', '4');

    const headers = {
      "Wisembly-Token": session.token,
      "Accept": "application/json",
      "Content-Type": "application/json",
    };

    fetch(apiBase, {
      headers,
    }).then((response) => response.json())
      .then((data) => {
        if (data?.success?.data?.data) setLives(data.success.data.data);
      }
      )
  }, [])

  return (
    <Box className={classes.root}>
      {(!lives || lives.length === 0) && <p>{_t("No record yet")}</p>}
      {(lives && lives.length > 0) && lives.map((live) => <Card live={live} />)}
    </Box>
  )
}

export default Library
