export default function uuid() {
  let d = new Date().getTime();

  if (
    typeof window.performance !== "undefined" &&
    typeof window.performance.now === "function"
  ) {
    d += window.performance.now(); // use high-precision timer if available
  }

  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);

    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
}

// https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
export function generateHash(length = 42) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * (i === 0 ? charactersLength - 9 : charactersLength)));
  }
  return result;
}
