import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import IconButton from "./IconButton";
import ThreeDotsVertical from "react-bootstrap-icons/dist/icons/three-dots-vertical";
import PencilSquare from "react-bootstrap-icons/dist/icons/pencil-square";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getKeyword } from "app/state/ducks/application/selectors";
import Interaction from "./Notifications/Interaction";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import ResourceFactory from "orm/resources";
import { useHistory } from "react-router-dom";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { resourceDelete } from "app/state/ducks/ressources/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    maxWidth: "none",
    minWidth: "106px",
    backgroundColor: "black",
    padding: "8px 10px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "50px",
    marginLeft: "45px",
    gap: "10px",
  },
  line: {
    display: "flex",
    gap: "5px",
    alignItems: "flex-start",
    cursor: "pointer",
  },
  edit: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
  clone: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
  delete: {
    color: theme.palette.colors.error,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
}));

const LiveOptions = ({ }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const keyword = useSelector(getKeyword);
  const wiz = useSelector(currentWizSelector);
  const history = useHistory();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleDelete = () => {
    dispatch(
      resourceDelete(wiz, {
        slug: ["event", keyword],
        callback: (error, response) => {
          if (error) {
            console.log(error);
            emitter.emit(EVENT_FLASH_ERROR, _t("The live can't be deleted !"));
            return;
          }

          emitter.emit(EVENT_FLASH_SUCCESS, _t("The live has been deleted with success !"));
          history.push("/lives");
        },
      })
    );
  };

  const handleCloneLive = () => {
    const newLive = resourceFactory.create("Event", {
      ...wiz.ref,
      hash: wiz.ref.hash + "copy",
      title: _t("Copy of ") + wiz.ref.title,
    });

    dispatch(
      resourceCreate(newLive, {
        slug: ["event", "ls"],
        callback: (error, response) => {
          if (!error) {
            emitter.emit(EVENT_FLASH_SUCCESS, _t("The live has been cloned with success !"));
            history.push(`/${wiz.ref.hash + "copy"}`);
          } else {
            emitter.emit(EVENT_FLASH_ERROR, _t("An error has been detected"));
          }
        },
      })
    );
  };

  return (
    <>
      <IconButton onClick={() => setOpen(!open)}>
        <ThreeDotsVertical />
      </IconButton>

      {open && (
        <Box className={classes.root}>
          <Link style={{ textDecoration: "none" }} className={classes.line} to={getUrl("otm_live_edit", { keyword }, false)}>
            <PencilSquare color={"white"} /> <Typography className={classes.edit}>{_t("Edit")}</Typography>
          </Link>
          <Box onClick={() => setOpenModal(true)} className={classes.line}>
            <Trash color={theme.palette.colors.error} /> <Typography className={classes.delete}>{_t("Delete")}</Typography>
          </Box>
        </Box>
      )}
      {openModal && (
        <Interaction type={"danger"} title={_t("Are you sure to delete this live ?")} textBtn2={_t("No")} textBtn1={_t("Yes")} action2={() => setOpenModal(false)} action1={handleDelete} />
      )}
    </>
  );
};

LiveOptions.propTypes = {};

LiveOptions.defaultProps = {};

export default LiveOptions;
