import React, { forwardRef } from "react";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "appComponents/Mui/IconButton";
import PropTypes from "prop-types";

const AddChipButton = forwardRef(({ onClick, visible }, ref) => {
  if (!visible) {
    return null;
  }
  return (
    <IconButton
      color="primary"
      rounded="full"
      size="small"
      onClick={onClick}
      ref={ref}
    >
      <AddIcon />
    </IconButton>
  );
});

AddChipButton.displayName = "AddChipButton";
AddChipButton.propTypes = {
  setOpen: PropTypes.func,
  visible: PropTypes.bool,
};
export default AddChipButton;
