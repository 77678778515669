import React from "react";

import { Box, ButtonBase, makeStyles, Typography } from "@material-ui/core";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import Chat from "../../Component/Live/Chat/Chat";
import { visitorsSelector } from "app/state/ducks/ressources/selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: props => (props.position * 300) + 5 + "px",
    bottom: "0",
    width: "280px",
    height: "356px",
    filter: "drop-shadow(-2px -2px 10px rgba(0, 0, 0, 0.08))",
    border: "1px solid #E5E5E5",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundColor: "#FFFFFF",
  },
  topBar: {
    height: "36px",
    width: "100%",
    background: props => props.visitorMessage ? theme.palette.colors.primary80 : theme.palette.colors.grey300,
    borderRadius: "8px 8px 0 0",
    borderBottom: `0.8px solid ${theme.palette.colors.grey300}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  buttonClose: {
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    color: props => props.visitorMessage ? theme.palette.colors.white : theme.palette.colors.grey600,
  },
  name: {
    fontSize: "12px",
    lineHeight: "16px",
    color: props => props.visitorMessage ? theme.palette.colors.white : theme.palette.colors.grey600,
    fontWeight: "700",
  },
}));
const ChatContainer = ({ visitor, position, setOpenVisitors }) => {
  const visitors = useSelector(visitorsSelector);
  const currentVisitor = visitors.find(({ hash }) => hash === visitor);
  const visitorMessage = currentVisitor.conversation[currentVisitor.conversation.length - 1]?.user === "visitor"
  const classes = useStyles({ position, visitorMessage });

  const handleDeleteVisitor = () => {
    setOpenVisitors((prev) => prev.filter((v) => v !== visitor));
  };


  return (
    <Box className={classes.root}>
      <Box className={classes.topBar}>
        <Typography className={classes.name}>{"#" + visitor.substring(0, 8)}</Typography>
        <ButtonBase onClick={handleDeleteVisitor} className={classes.buttonClose}><XLg size={12} /></ButtonBase>
      </Box>
      <Chat messageOperator={true} disabled={currentVisitor.status === "ongoing"} size="s" visitor={visitors.find(({ hash }) => hash === visitor)} />
    </Box>
  );
};

export default ChatContainer;