import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase } from "@material-ui/core";
import clsx from "clsx";
import { useSelector } from "react-redux";
import Bag from "react-bootstrap-icons/dist/icons/bag";
import BagCheck from "react-bootstrap-icons/dist/icons/bag-check";
import Eye from "react-bootstrap-icons/dist/icons/eye";
import { currentCartSelector, currentWizSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  buttonBuy: {
    width: "44px",
    height: "44px",
    minWidth: "44px", 
    minHeight: "44px",
    borderRadius: "999px",
    margin: " 0 12px 0 0",
    background: "#1E2023",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconButtonBuy: {
    color: theme.palette.colors.white,
  },
  buttonBought: {
    background: theme.palette.colors.success,
  },
}));

const ButtonBuy = ({ iconSize, product, onClick }) => {
  const classes = useStyles();
  const cart = useSelector(currentCartSelector);
  const wiz = useSelector(currentWizSelector);
  const bought = cart.products.find(({ product_id }) => product_id === product.id);

  return (
    <ButtonBase onClick={onClick} className={clsx({
      [classes.buttonBuy]: true,
      [classes.buttonBought]: bought,
    })}>
      {!wiz?.cart_integration && <Eye size={iconSize} className={classes.iconButtonBuy} />}
      {wiz?.cart_integration && bought && <BagCheck size={iconSize} className={classes.iconButtonBuy} />}
      {wiz?.cart_integration && !bought && <Bag size={iconSize} className={classes.iconButtonBuy} />}

    </ButtonBase>
  );
};

ButtonBuy.propTypes = {};

ButtonBuy.defaultProps = {};

export default ButtonBuy;
