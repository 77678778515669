import { Box, makeStyles, Typography } from "@material-ui/core";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { genericResourceSelector } from "app/state/ducks/ressources/selectors";
import ResourceFactory from "app/state/orm/resources/resourceFactory";
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { _t } from "utilities/i18n";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import TextArea from "app/liveShopping/backoffice/otm/components/reusables/TextArea";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "15px 20px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "10px",
  },
  text: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000000",
  },
  desc: {
    fontSize: "12px",
  },
  components: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
}));

const Communication = () => {
  const classes = useStyles();
  const project = useSelector(currentProjectSelector);
  const { group } = useParams();

  const currentGroup = project.groups.toRefArray().find((g) => g.hash === group);
  const calendar = useSelector(state => genericResourceSelector(state, {
    type: "Calendar",
    filter: (cal) => {
      return cal.group?.hash === currentGroup.hash;
    },
  }));

  const defaultMessages = {
    [`1d`]: "Bonjour, nous vous rappelons votre rendez-vous de demain à {{ time }} avec notre conseiller. Voici le lien qui vous permettra de vous y connecter : {{ link }}",
    [`20m`]: "C'est bientôt l'heure de votre rendez-vous de {{ time }}. Voici le lien qui vous permettra de vous y connecter : {{ link }}. À toute à l'heure !",
    [`live`]: "Bonjour, votre conseiller est en ligne pour vous recevoir ! Retrouvez-le sans plus tarder => {{ link }}",
    ...calendar.messages,
  };

  const dispatch = useDispatch();
  const [messages, setMessages] = useState(defaultMessages);
  const [uri, setUri] = useState(calendar?.appointment_url);

  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleSave = () => {
    const newCalendar = resourceFactory.create("Calendar", {
      messages,
      appointment_url: uri,
    });

    dispatch(
      resourceEdit(newCalendar, {
        slug: ["calendars", calendar.hash],
        patch: ["messages", "appointment_url"],
        callback: (error, response) => {
          if (error) {
            console.log(error);
            return;
          }

          emitter.emit(EVENT_FLASH_SUCCESS, _t("The communications has been updated"));
        }
      }
    ));
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Communication")}</Typography>
      <Box className={classes.components}>

        <Box className={classes.block}>
          <Typography className={classes.text}>{_t("Message sent 1 day before meeting")}</Typography>
          <TextArea value={messages[`1d`]} onChange={(e) => setMessages({ ...messages, [`1d`]: e.target.value })} />
        </Box>

        <Box className={classes.block}>
          <Typography className={classes.text}>{_t("Message sent 20 minutes before meeting")}</Typography>
          <TextArea value={messages[`20m`]} onChange={(e) => setMessages({ ...messages, [`20m`]: e.target.value })} />
        </Box>

        <Box className={classes.block}>
          <Typography className={classes.text}>{_t("Notify vendor live is waiting message")}</Typography>
          <TextArea value={messages[`live`]} onChange={(e) => setMessages({ ...messages, [`live`]: e.target.value })} />
        </Box>

        <Box className={classes.block}>
          <Typography className={classes.text}>{_t("Page address for appointment")}</Typography>
          <Input value={uri} onChange={(e) => setUri(e.target.value)} />
          <Typography className={classes.desc}>{_t("This address will be used to host the widget. If left blank, we'll use the address the visitor visited when taking his appointment.")}</Typography>
        </Box>

      </Box>
      <Button onClick={handleSave} style={{ marginTop: "20px", position: "fixed", right: "20px", bottom: "20px" }}>{_t("Save")}</Button>

    </Box>
  )
}

export default Communication;
