import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import { ButtonBase } from "@material-ui/core";
import CustomShareIcon from "app/liveShopping/assets/icons/CustomShareIcon";
import { useSelector } from "react-redux";
import { isIFrameSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { SHARE_MODAL } from "utilities/emitter/events";
import emitter from "utilities/emitter";
import { emit } from "utilities/liveshopping-sdk";
import ShareModal from "../LandingLiveShopping/sections/ShareModal";
import ShareFill from "react-bootstrap-icons/dist/icons/share-fill";

const useStyles = makeStyles((theme) => ({
 root: {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "12px",
  width: "40px",
  height: "40px",
  background: "#FFFFFF",
  borderRadius: "99px",
  margin: "0px 8px",
 },
}));


const Share = ({}) => {
  const classes = useStyles();
  const [modalDisplay, setModalDisplay] = useState(false);
  const isIFrame = useSelector(isIFrameSelector)
  const isMobile = useSelector(mobileLayoutSelector)

  async function handleShare() {
    if (isIFrame && isMobile)
      return emit("SHARE");

    try {
      if (isMobile && navigator.share) {
        await navigator.share({
          title: _t("Share this live shopping event"),
          text: _t("Share this live shopping event"),
          url: window.parent
            ? window.parent?.location?.href
            : window.location?.href,
        });
      } else {
        setModalDisplay(true);
      }
    } catch (error) {
      console.error(
        "Something went wrong sharing the data, need another way",
        error
      );
    }
  }


  useEffect(() => {
    if (!isIFrame) return;
    const share = () => setModalDisplay(true);
    emitter.on(SHARE_MODAL, share);
    return () => emitter.off(SHARE_MODAL, share);
  }, []);


return (
  <>
    <ButtonBase className={classes.root} onClick={handleShare}>
      <ShareFill size={16} />
    </ButtonBase>
    <ShareModal display={modalDisplay} setDisplay={setModalDisplay} />
  </>
);
};


Share.propTypes = {};


Share.defaultProps = {};


export default Share;