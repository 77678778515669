import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "appComponents/Mui/ButtonUni";
import IconButton from "appComponents/Mui/IconButton";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  iconBase: {
    backgroundColor: theme.palette.button.one.background.enable,
    height: "34px",
    minWidth: "34px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginRight: "10px",
    color: (props) =>
      props.selected
        ? theme.palette.secondary.main
        : theme.wisemblyColors.white,
    cursor: "pointer",
  },

  label: {
    marginLeft: "3px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  badgeRoot: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.wisemblyColors.black,
    marginLeft: "18px",
    //marginRight: (props) => (props.badgeContent ? "10px" : 0),
  },
  badgeContent: {
    marginLeft: (props) => (props.badgeContent ? "18px" : 0),
    marginRight: (props) => (props.badgeContent ? "10px" : 0),
  },
}));
//generic button action that can toogle color on selection

const ActionButton = ({
  title,
  icon,
  badgeContent,
  onClick,
  toogleColor,
  activeColor,
  inactiveColor,
  initState,
  forceSelection,
  controlled,
  isIconButton,
  rounded,
  squared,
  size,
  ml,
  endIcon,
  variant,
  ...rest
}) => {
  const [selected, setSelected] = useState(initState);
  const classes = useStyles({ badgeContent, selected });
  const handleClick = (event) => {
    if (toogleColor) {
      setSelected(!selected);
    }

    onClick(event);
  };
  const badge = (
    <Badge
      badgeContent={badgeContent}
      max={99}
      color="secondary"
      classes={{ badge: classes.badgeRoot }}
    ></Badge>
  );
  //forceSelection is good for buttons that use click away listener
  const selectedState = forceSelection !== null ? forceSelection : selected;

  if (isIconButton) {
    //  classes={{ root: clsx(classes.iconBase, classes.onlyOnSmall) }}
    return (
      <IconButton
        color={selectedState ? activeColor : inactiveColor}
        onClick={handleClick}
        controlled={controlled}
        rounded={rounded}
        squared={squared}
        size={size}
        ml={ml}
        {...rest}
      >
        {icon}
      </IconButton>
    );
  }
  return (
    <Button
      color={selectedState ? activeColor : inactiveColor}
      variant={variant}
      startIcon={icon}
      endIcon={endIcon || badge}
      onClick={handleClick}
      controlled={controlled}
      rounded={rounded}
      squared={squared}
      size={size}
      ml={ml}
      {...rest}
    >
      {title}
    </Button>
  );
};

ActionButton.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.element,
  badgeContent: PropTypes.number,
  onClick: PropTypes.func,
  toogleColor: PropTypes.bool, //toogle color on select
  activeColor: PropTypes.string,
  inactiveColor: PropTypes.string,
  initState: PropTypes.bool,
  forceSelection: PropTypes.bool,
  controlled: PropTypes.bool, //controlled button is used to know if active && focus should be used
  isIconButton: PropTypes.bool,
  rounded: PropTypes.string,
  squared: PropTypes.bool,
  size: PropTypes.string,
  ml: PropTypes.number,
  endIcon: PropTypes.element,
  variant: PropTypes.string,
};
ActionButton.defaultProps = {
  toogleColor: false,
  activeColor: "primary",
  inactiveColor: "secondary",
  initState: false,
  forceSelection: null,
  controlled: true,
  badgeContent: 0,
  isIconButton: false,
  rounded: null,
  squared: false,
  ml: 0,
  endIcon: null,
  variant: "contained",
  onClick: () => {},
};
export default ActionButton;
