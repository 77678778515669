import { Box, makeStyles, Typography } from "@material-ui/core";
import makeUrl from "app/state/ducks/ressources/sagas/makeUrl";
import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { _t } from "utilities/i18n";
import makeRequest from "utilities/makeRequest";
import Card from "../Card";
import HandledLost from "./HandledLost";
import Visitors from "./Visitors";

const useStyles = makeStyles((theme) => ({
  graphs: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    padding: "16px 0px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginTop: "20px",
    marginBottom: "15px",
  },
}));

const Graphs = ({ dates, currentProject }) => {
  const classes = useStyles();
  const [temporalityData, setTemporalityData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchStats = async (dates) => {
    try {
      setLoading(true);
      const url = makeUrl(
        { type: "Custom" },
        {
          slug: ["stats", "project", currentProject?.id, "temporality"],
          query: [{ key: "since", value: dates.since.toISOString() }, { key: "until", value: dates.until.toISOString() }],
        }
      );

      const temporalityResult = await makeRequest(url, { type: "Custom" }, { method: "GET" });
      const finalTemporalityResult = Object.assign(temporalityResult.resources[0].attributes.data.stats);

      setLoading(false);
      setTemporalityData(finalTemporalityResult);

    } catch (e) {
      setLoading(false);
      setTemporalityData(null);
    }
  }

  useEffect(() => {
    fetchStats(dates);
  }, [dates, currentProject]);

  return (
    <>
      <Typography className={classes.title}>{_t("Graphics")}</Typography>
      <Fade left>
        <Box className={classes.graphs}>
          <Card isGraph>
            <HandledLost hours={temporalityData?.hours} />
          </Card>
          <Visitors dates={dates} createdAts={temporalityData?.created_ats} />
        </Box>
      </Fade>
    </>
  )
};

export default Graphs;
