import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { _t } from "i18n";
import { FormIcon } from "appComponents/Viewers";
import clsx from "clsx";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { getParsedPercent } from "utilities/utils";
import Divider from "@material-ui/core/Divider";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  content: {
    backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    justifyContent: (props) =>
      props.isThumbnailView ? "center" : "flex-start",
    alignItems: "center",
    overflow: "auto",
    display: "flex",
    width: "100%",
    borderTopLeftRadius: (props) => (props.isThumbnailView ? 0 : "15px"),
    borderTopRightRadius: (props) => (props.isThumbnailView ? 0 : "15px"),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    height: (props) => (props.isThumbnailView ? "100%" : "calc(100%  - 5px )"),
  },
  root: {
    border: "none",
    height: "100%",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: (props) =>
        props.layoutMode == "split"
          ? "10px"
          : props.isThumbnailView
          ? "0px"
          : "50px",
      paddingRight: (props) =>
        props.layoutMode == "split"
          ? "10px"
          : props.isThumbnailView
          ? "0px"
          : "50px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    display: "flex",
  },
  headerContent: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: (props) => (props.isThumbnailView ? "0px" : "50px"),
    marginBottom: (props) => (props.isThumbnailView ? "0px" : "50px"),
  },
  leaderName: {},
  shadow: {
    boxShadow: theme.palette.shadow,
  },
  percentage: {
    fontSize: (props) => (props.top ? "18px" : "14px"),
    letterSpacing: 0.5,
    fontWeight: 400,
    [theme.breakpoints.up("sm")]: {
      marginRight: "26.5px",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "10px",
    },
  },
  name: {
    flex: "auto",
    display: "flex",
    flexDirection: "row",
    marginLeft: "20px",

    [theme.breakpoints.up("sm")]: {
      fontSize: (props) => (props.top ? "20px" : "16px"),
    },
    fontWeight: (props) => (props.position > 2 ? "400" : "700"),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "calc(100% - 50px)",
      display: "-webkit-box",
      overflow: "hidden",
      textOverflow: "ellipsis",
      flexGrow: 0,
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      fontSize: "16px",
    },
  },
  detailsPosition: {
    //padding: theme.spacing(2),
    marginLeft: "20px",

    borderRadius: "50%",

    [theme.breakpoints.up("sm")]: {
      height: (props) => (props.top ? "35px" : "30px"),
      width: (props) => (props.top ? "35px" : "30px"),
      fontSize: (props) => (props.top ? "18px" : "16px"),
    },
    [theme.breakpoints.down("xs")]: {
      height: (props) => (props.top ? "30px !important" : "25px !important"),
      width: (props) => (props.top ? "30px !important" : "25px !important"),
      fontSize: (props) => (props.top ? "16px" : "12px"),
    },
    color: (props) =>
      props.top ? theme.wisemblyColors.white : theme.palette.secondary.main,
    backgroundColor: (props) =>
      props.top
        ? theme.palette.secondary.main
        : props.position < 3
        ? theme.palette.background.secondaryBox
        : "transparent",
  },
  leftPart: {
    display: "flex",
    flexDirection: "row",
    width: "calc(100% - 28px)",
  },
  leader: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    height: "75px",
    borderRadius: "10px",
  },
  divider: {
    color: theme.palette.border.divider,
  },
  leaderboard: {
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    flexDirection: "column",
    width: "100%",

    [theme.breakpoints.up("sm")]: {
      paddingLeft: (props) => (props.layoutMode == "split" ? "10px" : "50px"),
      paddingRight: (props) => (props.layoutMode == "split" ? "10px" : "50px"),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  titleZone: {
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    marginTop: (props) => (props.isThumbnailView ? "10px" : "30px"),
  },
  titleText: {
    [theme.breakpoints.up("sm")]: {
      fontSize: (props) =>
        props.layoutMode === "split" || props.isThumbnailView ? "16px" : "25px",
    },
    fontWeight: 700,
    lineHeight: "31.25px",
    letterSpacing: "0.5px",
    textAlign: "center",
    color: theme.palette.button.default.color.enable,
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },
}));

const Leader = ({ leader, position }) => {
  const classes = useStyles({ top: position == 0, position });
  const percent =
    leader.scoring > 0
      ? getParsedPercent((leader.scoring / leader.totalScore) * 100)
      : 0;
  return (
    <Box
      className={clsx(
        "Leader",
        classes.leader,
        position == 0 ? classes.shadow : null
      )}
    >
      <Box className={classes.leftPart}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={clsx(classes.detailsPosition)}
        >
          {position + 1}
        </Box>
        <Box className={classes.name}>
          {leader.isAnonymous && isEmpty(leader.entity)
            ? `${_t("anonymous")} #${leader.token.substr(0, 5)}`
            : leader.entity}
        </Box>
      </Box>
      <Box className={classes.percentage}>{`${percent}%`}</Box>
    </Box>
  );
};
Leader.propTypes = {
  leader: PropTypes.object,
  position: PropTypes.number,
};
const LeaderBoard = ({ leaderboard, isThumbnailView, layoutMode }) => {
  const classes = useStyles({ isThumbnailView, layoutMode });
  const boards = map(get(leaderboard, "boards", []), (leader, key) => {
    return (
      <>
        <Leader key={key} leader={leader} position={key} />
        {key != 0 && key < leaderboard.boards.length - 1 && (
          <Divider classes={{ root: classes.divider }} />
        )}
      </>
    );
  });
  return (
    <Box className={clsx("LeaderBoard", classes.root)}>
      <Box className={classes.content}>
        <Box display="flex" width="100%" className={classes.headerContent}>
          <FormIcon
            type="evaluation"
            isThumbnailView={isThumbnailView}
            layoutMode={layoutMode}
          />
          <Box className={classes.titleZone}>
            <Typography display="block" className={classes.titleText}>
              {_t("Quizz leaderboard")}
            </Typography>
          </Box>
        </Box>
        <Box className={clsx("LeaderContent", classes.leaderboard)}>
          {boards}
        </Box>
      </Box>
    </Box>
  );
};

LeaderBoard.propTypes = {
  leaderboard: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
};

export default LeaderBoard;
