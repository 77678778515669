import {
  APP_ID,
  PUSH_SERVER_URL,
  ANALYTICS_PUSH_SERVER,
  DEBUG_MODE,
} from "settings/config";

import io from "socket.io-client";
import basil from "core/basil";
import logger from "core/logger";
import emitter from "core/emitter";
import isEmpty from "lodash/isEmpty";
import store from "appStore";
import {
  UPDATE_PARTICIPANTS_COUNT,
  UPDATE_USER_ACTION,
} from "applicationDucks/actionsTypes";
import { EVENT_PARTICIPANT_COUNT } from "core/emitter/events";
const debug = logger("realtime");

/*
 *
 * Wisembly next Realtime Service
 * - Authenticating through the push server
 * - Listen to analytics server
 * - Joining rooms
 * - Handling Push events and updating the store in realtime
 *
 */
class Realtime {
  appId = null;
  socket = null;
  analyticsSocket = null;
  state = {
    token: null,
    rooms: [],
    keyword: "",
    project: "",
    authenticated: false,
    isAuthenticating: false,
    headers: {},
  };

  constructor(options) {
    if (!options.pushServerUrl) {
      return;
    }

    this.options = options;
    window.addEventListener("beforeunload", this.onCleanUp.bind(this));
  }

  init() {
    if (!this.options) {
      debug.warn("Cannot init push server since options aren't defined");
      return;
    }

    this.appId = this.options.appId;
    this.socket = this.createSocket(this.options.pushServerUrl);

    // Attach events to socket
    this.bindSocketEvents(this.socket);
  }

  isInitialized() {
    return this.socket !== null;
  }

  /*
   *
   * PUBLIC
   * Autenticates the socket to be able to receive events
   * @param {SessionResource} session
   *
   */
  authenticateSocket(session) {
    if (!this.socket) {
      debug.warn("Can't authenticate socket, you need to init Socketio first");

      return;
    }

    // If we're already authenticating, don't do it twice
    if (this.state.isAuthenticating) {
      return;
    }

    // Set token to the state
    this.setState({
      token: session.token || null,
      isAuthenticating: true,
    });

    const authenticateData = {
      token: session.token || null,
    };

    this.socket.emit("join", authenticateData, (error, rooms) => {
      if (error) {
        // @TODO: initiate polling here
        this.setState({ isAuthenticating: false });

        return debug.warn(`Couldn't join rooms. \nOriginal error: ${error}`);
      }

      debug.log("Successfully joined the following Wiz rooms", rooms);

      // Set rooms to the state
      this.setState({
        authenticated: true,
        isAuthenticating: false,
        rooms,
      });
    });
  }

  joinAnalytics(headers) {
    if (this.analyticsSocket) {
      //disconnect previous socket
      this.analyticsSocket.disconnect();
    }
    this.analyticsSocket = this.createSocket(ANALYTICS_PUSH_SERVER);
    //because one time headers was undefined
    if (headers && headers.analytics) {
      this.analyticsSocket.emit("join", headers.analytics, (response) => {
        //console.log("response analytics after join analytics socket", response, this.analyticsSocket.id, this.analyticsSocket.connected)
      });
    }

    this.analyticsSocket.on("c", (count) => {
      store.dispatch({ type: UPDATE_PARTICIPANTS_COUNT, count });
    });
    this.analyticsSocket.on("u", (userAction) => {
      store.dispatch({ type: UPDATE_USER_ACTION, userAction });
    });
    // implement ws reconnect
    this.analyticsSocket.on("reconnect", (response) => {
      const { keyword } = this.state;
      if (!isEmpty(keyword)) {
        this.joinAnalytics(this.state.headers[keyword]);
      }
    });

    this.analyticsSocket.on("connect", () => {
      this.setState({
        analyticsSocket: "connected",
        headers: {
          ...this.state.headers,
          [headers.analytics.keyword]: headers,
        },
      });
    });
  }
  /*
   *
   * PUBLIC
   * Joins the correct event room
   * Doesn't do anything if we already subscribed to the room
   *
   */
  joinMeetingRoom(keyword) {
    if (!this.socket) {
      debug.warn("Cannot join room since socket not opened");

      return;
    }

    const { token } = this.state;

    // TODO: handle password for protected events
    let joinRoomData = {
      token,
      version: "jsonApi",
      organization: null,
      event: {
        keyword,
        password: false,
      },
    };

    if (basil.get(`${keyword}.password`)) {
      joinRoomData.event.password = basil.get(`${keyword}.password`);
    }

    this.socket.emit("join", joinRoomData, (error, rooms, headers) => {
      if (error) {
        return debug.warn(
          `Couldn't join event ${keyword} room. \nOriginal error: ${error}`
        );
      }

      debug.log(`Successfully joined the ${keyword} event room`, rooms);
      if (headers.analytics) {
        this.joinAnalytics(headers);
      }
      // Set rooms
      this.setState({
        rooms,
        keyword,
      });
    });
  }

  joinProjectRoom(project) {
    if (!this.socket) {
      debug.warn("Cannot join room since socket not opened");

      return;
    }

    const { token } = this.state;

    // TODO: handle password for protected events
    let joinRoomData = {
      token,
      version: "jsonApi",
      organization: null,
      project,
    };

    this.socket.emit("join", joinRoomData, (error, rooms, headers) => {
      if (error) {
        return debug.warn(
          `Couldn't join project ${project} room. \nOriginal error: ${error}`
        );
      }

      debug.log(`Successfully joined the ${project} project room`, rooms);
      // no need for analytics in OTO
      // if (headers.analytics) {
      //   this.joinAnalytics(headers);
      // }
      // Set rooms
      this.setState({
        rooms,
        project,
      });
    });
  }

  /*
   *
   * PUBLIC
   * Leave the event room
   *
   */
  leaveMeetingRoom(keyword) {
    const roomToLeave = this.state.rooms.filter((room) =>
      room.includes(keyword)
    );

    if (!roomToLeave.length) {
      return;
    }

    this.socket.emit("leave", roomToLeave, (error, rooms) => {
      if (error) {
        return debug.warn(
          `Couldn't leave event ${keyword} room. \nOriginal error: ${error}`
        );
      }

      debug.log(`Successfully left the ${keyword} event room`, rooms);

      // Update rooms
      this.setState({
        rooms,
        keyword,
      });
    });
  }

  /*
   *
   * PUBLIC
   * Tells if the socket is authenticated or not
   * @return {Boolean} isAuthenticated
   *
   */
  isAuthenticated() {
    return this.state.authenticated;
  }

  isAuthenticating() {
    return this.state.isAuthenticating;
  }

  /*
   *
   * Mutates the current state
   * @param {object} partialState
   *
   */
  setState(partialState) {
    this.state = Object.assign({}, this.state, partialState);
  }

  /*
   *
   * Creates a new Socket.io object and returns it
   * @param {string} socketServerUrl
   * @return {object} Socket.io instance
   *
   */
  createSocket(socketServerUrl, opt = {}) {
    let options = {
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 5000,
      reconnectionDelayMax: 90000,
      transports: ["websocket", "polling"],
      ...opt,
    };

    return io(socketServerUrl, options);
  }

  /*
   *
   * Attaches events to socket
   * @param {object} Socket.io instance
   *
   */
  bindSocketEvents(socket) {
    // When the socket is connected to the Push Server
    socket.on("connect", () => {
      const { keyword } = this.state;

      this.onSocketConnect();

      if (!isEmpty(keyword)) {
        this.joinMeetingRoom(keyword);
      }
    });
  }

  on(event, action) {
    if (!this.socket) {
      debug.warn("Cannot bind on event since no socket opened");
      return;
    }

    return this.socket.on(event, action);
  }

  /*
   *
   * When the user is connected to the socket
   * => mutate state
   *
   */
  onSocketConnect() {
    debug.log("Websocket connected \\o/");

    this.setState({ socket: "connected" });
  }

  onCleanUp() {
    if (!this.socket) {
      debug.info("Cannot disconnect socket because did not previously opened");

      return;
    }

    debug.info("onBeforeUnload closing push server socket");
    this.socket.disconnect();
  }
}

// A new Realtime instance is exported
// So that we can use it everywhere in our app
// (like the eventemitter)
const realtime = new Realtime({
  appId: APP_ID,
  pushServerUrl: PUSH_SERVER_URL,
});

if (DEBUG_MODE)
  window.debug_realtime = realtime;

export default realtime;
