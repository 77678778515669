import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  liveBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    borderRadius: "99px",
    background: ({ isReplay, isPreview }) => (isReplay || isPreview ? theme.palette.colors.primary : theme.palette.colors.error),
  },
  liveTypo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "100%",
    textAlign: "center",
    color: theme.palette.colors.white,
  },
  marginIcons: {
    marginRight: "5px",
    marginLeft: "10px",
  },
}));

const Live = ({ isInfoOpen }) => {
  const wiz = useSelector(currentWizSelector);
  const isReplay = ["awaiting_replay", "replay"].includes(wiz.status);
  const classes = useStyles({ isReplay, isPreview: wiz.status === "ready_for_live" });

  if (isInfoOpen) return null;

  return (
    <Box>
      <Box
        className={clsx({
          [classes.marginIcons]: true,
          [classes.liveBox]: true,
        })}
      >
        <Typography className={classes.liveTypo}>
          {wiz.status === "ready_for_live" && <span>{_t("PREVIEW")}</span>}
          {wiz.status === "live" && <span>{_t("LIVE")}</span>}
          {isReplay && <span>{_t("REPLAY")}</span>}
        </Typography>
      </Box>
    </Box>
  );
};

Live.propTypes = { isInfoOpen: PropTypes.bool };

export default Live;
