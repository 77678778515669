import React from "react";
import PropTypes from "prop-types";

const CustomTooglesIcon = ({ color, size, ...rest }) => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.5 3.49933C6.49982 2.70392 6.18377 1.94113 5.62132 1.37868C5.05871 0.81607 4.29565 0.5 3.5 0.5H4H4.7235L5.60043 1.35749C5.88569 1.63643 6.11224 1.96963 6.26673 2.33747C6.42122 2.70532 6.50054 3.10036 6.5 3.49933ZM6.5 3.49933C6.5 3.49955 6.5 3.49978 6.5 3.5M6.5 3.49933L6.5 3.5M6.5 3.5C6.5 4.29565 6.18393 5.05871 5.62132 5.62132C5.05871 6.18393 4.29565 6.5 3.5 6.5H4.7235L5.60043 5.64251C5.88569 5.36357 6.11224 5.03037 6.26673 4.66253C6.42122 4.29468 6.50054 3.89964 6.5 3.50067L6.5 3.5ZM1.37868 10.3787C1.94129 9.81607 2.70435 9.5 3.5 9.5H10.5C9.70435 9.5 8.94129 9.81607 8.37868 10.3787C7.81607 10.9413 7.5 11.7044 7.5 12.5C7.5 13.2956 7.81607 14.0587 8.37868 14.6213C8.94129 15.1839 9.70435 15.5 10.5 15.5H3.5C2.70435 15.5 1.94129 15.1839 1.37868 14.6213C0.81607 14.0587 0.5 13.2956 0.5 12.5C0.5 11.7044 0.81607 10.9413 1.37868 10.3787Z" fill="#808284" stroke="#808284" />
    </svg>

  );
};

CustomTooglesIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomTooglesIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomTooglesIcon;
