import BaseModel from "./base";
import { attr, fk } from "redux-orm";

const TEMPLATE_PICKER_DATE = "template_date_picker";
const TEMPLATE_PICKER_DURATION_BEFORE = "template_duration_picker_before";
const TEMPLATE_PICKER_DURATION_AFTER = "template_duration_picker_after";
const TEMPLATE_PICKER_AUTO = "template_auto";

const TEMPLATE_KEY_INVITATION = "invitation_participant";
const TEMPLATE_KEY_HAVING_PARTICIPATED = "wiz_ended_having_participated";
const TEMPLATE_KEY_NOT_HAVING_PARTICIPATED =
  "wiz_ended_not_having_participated";
const TEMPLATE_KEY_RELAUNCH_INVITATION = "invitation_relaunch_participant";
const TEMPLATE_KEY_REGISTRATION_SUCCESS = "registration_success";
const TEMPLATE_KEY_REMINDER_DAY = "reminder_day";
const TEMPLATE_KEY_REMINDER_MINUTES = "reminder_minutes";
const TEMPLATE_KEY_CUSTOM_EMAIl = "custom_email";

import { getTitle } from "adminComponents/Mails/utils";
import { _t } from "i18n";

class Template extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Template;
  }

  setEvent(Event) {
    this.event = Event.id;
  }

  setContent(htmlContent) {
    this.content = htmlContent;
  }

  setActive(value) {
    this.active = value;
  }

  toggleActive() {
    this.setActive(!this.active);
  }

  setSubject(value) {
    this.subject = value;
  }

  setSender(value) {
    this.sender_name = value;
  }

  getFormattedSegments() {
    const segment_rsvp = this.segments?.segment_rsvp || [];
    const has = (items) => items.length === 1;
    return {
      rsvpNull: has(segment_rsvp.filter((e) => e !== true && e !== false)),
      rsvpTrue: has(segment_rsvp.filter((e) => e === true)),
      rsvpFalse: has(segment_rsvp.filter((e) => e === false)),
      notInvitedParticipants:
        this.segments?.segment_no_mail_received === TEMPLATE_KEY_INVITATION,
    };
  }

  getTitle() {
    return this.title ? this.title : getTitle(this.template_key);
  }

  setTitle(title) {
    this.title = title;
  }

  getShowConditions() {
    const { landing_enabled, landing, templates } = this.event;
    const allTemplates = templates.toModelArray();

    if (!this.is_prototype) {
      // No conditions to show a template edited
      return {};
    }

    /* Available conditions */
    const invitationEnabled = !!allTemplates.find(
      (template) =>
        template.template_key === TEMPLATE_KEY_INVITATION &&
        template.active === true
    );
    const registrationEnabled = landing_enabled && landing.registration_enabled;
    const landingEnabled = landing_enabled;

    switch (this.template_key) {
      case TEMPLATE_KEY_RELAUNCH_INVITATION:
        return { invitationEnabled };
      case TEMPLATE_KEY_REGISTRATION_SUCCESS:
        return { registrationEnabled };
      case TEMPLATE_KEY_REMINDER_DAY:
        return { landingEnabled };
      case TEMPLATE_KEY_REMINDER_MINUTES:
        return { landingEnabled };
      case TEMPLATE_KEY_NOT_HAVING_PARTICIPATED:
        return { registrationEnabled };
      case TEMPLATE_KEY_CUSTOM_EMAIl:
      case TEMPLATE_KEY_HAVING_PARTICIPATED:
      case TEMPLATE_KEY_INVITATION:
      default:
        return {};
    }
  }

  isAvailable() {
    return (
      Object.values(this.getShowConditions()).filter(
        (conditionFullfiled) => conditionFullfiled === false
      ).length === 0
    );
  }

  getAvailableSendTypes() {
    const getPicker = (activeConditions, picker) => {
      if (typeof activeConditions === "boolean" && activeConditions === true) {
        return picker;
      }
      return activeConditions.includes(this.template_key) ? picker : null;
    };

    return [
      {
        value: "sending_type_customized",
        enabled: getPicker(
          [
            TEMPLATE_KEY_INVITATION,
            TEMPLATE_KEY_HAVING_PARTICIPATED,
            TEMPLATE_KEY_NOT_HAVING_PARTICIPATED,
            TEMPLATE_KEY_RELAUNCH_INVITATION,
            TEMPLATE_KEY_REGISTRATION_SUCCESS,
            TEMPLATE_KEY_REMINDER_DAY,
            TEMPLATE_KEY_REMINDER_MINUTES,
            TEMPLATE_KEY_CUSTOM_EMAIl,
          ],
          TEMPLATE_PICKER_DATE
        ),
        label: _t("Customized"),
      },
      {
        value: "sending_type_after_invitation",
        enabled: getPicker([TEMPLATE_KEY_INVITATION], TEMPLATE_PICKER_AUTO),
        label: _t("Automatically after invitation"),
      },
      {
        value: "sending_type_after_registration",
        enabled: getPicker(
          [TEMPLATE_KEY_REGISTRATION_SUCCESS],
          TEMPLATE_PICKER_AUTO
        ),
        label: _t("Automatically after registration"),
      },
      {
        value: "sending_type_before_event",
        enabled: getPicker(
          [
            TEMPLATE_KEY_REMINDER_DAY,
            TEMPLATE_KEY_REMINDER_MINUTES,
            TEMPLATE_KEY_CUSTOM_EMAIl,
          ],
          TEMPLATE_PICKER_DURATION_BEFORE
        ),
        label: _t("Before event"),
      },
      {
        value: "sending_type_after_event",
        enabled: getPicker(
          [
            TEMPLATE_KEY_HAVING_PARTICIPATED,
            TEMPLATE_KEY_NOT_HAVING_PARTICIPATED,
            TEMPLATE_KEY_CUSTOM_EMAIl,
          ],
          TEMPLATE_PICKER_DURATION_AFTER
        ),
        label: _t("After event"),
      },
    ];
  }

  getAllSegements() {
    const rsvpFalse = _t("Participants that rejected the invitation");
    const rsvpTrue = _t("Participants that accepted their invitation");
    const rsvpNull = _t("Participants that did not answer the invitation");
    // prettier-ignore
    const notInvitedParticipants = _t("Participants who have not received the invitation");

    let alreadySelected = {};
    /* This is to still show not available segments that woul have been set on that email */
    this.getSelectedSegments().forEach((segment) => {
      switch (segment) {
        case "rsvpTrue":
          alreadySelected.rsvpTrue = rsvpTrue;
          break;
        case "rsvpFalse":
          alreadySelected.rsvpFalse = rsvpFalse;
          break;
        case "rsvpNull":
          alreadySelected.rsvpNull = rsvpNull;
          break;
        case "notInvitedParticipants":
          alreadySelected.notInvitedParticipants = notInvitedParticipants;
          break;
      }
    });

    switch (this.template_key) {
      case TEMPLATE_KEY_INVITATION:
        return { notInvitedParticipants, ...alreadySelected };
      case TEMPLATE_KEY_RELAUNCH_INVITATION:
        return { rsvpNull, ...alreadySelected };
      case TEMPLATE_KEY_REGISTRATION_SUCCESS:
        return { rsvpTrue, ...alreadySelected };
      case TEMPLATE_KEY_REMINDER_DAY:
      case TEMPLATE_KEY_REMINDER_MINUTES:
      case TEMPLATE_KEY_HAVING_PARTICIPATED:
        return { rsvpTrue, rsvpNull, ...alreadySelected };
      case TEMPLATE_KEY_NOT_HAVING_PARTICIPATED:
        return { rsvpFalse, rsvpNull, ...alreadySelected };
      case TEMPLATE_KEY_CUSTOM_EMAIl:
        return { rsvpFalse, rsvpTrue, rsvpNull, ...alreadySelected };
      default:
        return { rsvpFalse, rsvpTrue, rsvpNull, notInvitedParticipants };
    }
  }

  getSelectedSegments() {
    return (
      Object.entries(this.getFormattedSegments())
        // eslint-disable-next-line no-unused-vars
        .filter(([segment, selected]) => selected === true)
        .map(([segment]) => segment)
    );
  }

  setSelectedSegments(segments) {
    let newSegments = {};

    const setSegment = (type, value) => {
      newSegments[type] = value;
    };

    const setRsvpSegment = (value) => {
      if (!("segment_rsvp" in newSegments)) {
        setSegment("segment_rsvp", []);
      }
      newSegments.segment_rsvp.push(value);
    };

    segments.forEach((segment) => {
      switch (segment) {
        case "rsvpTrue":
          setRsvpSegment(true);
          break;
        case "rsvpFalse":
          setRsvpSegment(false);
          break;
        case "rsvpNull":
          setRsvpSegment(null);
          break;
        case "notInvitedParticipants":
          setSegment("segment_no_mail_received", TEMPLATE_KEY_INVITATION);
          break;
      }
    });
    this.segments = newSegments;
  }
}

Template.modelName = "Template";
Template.backendName = "template";

Template.fields = {
  id: attr(),
  type: attr(),
  hash: attr(),
  template_key: attr(),
  active: attr(),
  send_delay: attr(),
  send_type: attr(),
  stats: attr(),
  subject: attr(),
  segments: attr(),
  is_prototype: attr(),
  event: fk("Event", "templates"),
  content: attr(),
  sender_name: attr(),
  title: attr(),
  reply_to: attr(),
};

export default Template;
export {
  TEMPLATE_PICKER_AUTO,
  TEMPLATE_PICKER_DATE,
  TEMPLATE_PICKER_DURATION_AFTER,
  TEMPLATE_PICKER_DURATION_BEFORE,
};
