import Basil from "basil.js";
import forEach from "lodash/forEach";
import {
  BASIL_NAMESPACE,
  BASIL_STORAGES,
  BASIL_COOKIES_DOMAIN,
  BASIL_COOKIES_SECURE,
} from "settings/config";

const basil = new Basil({
  namespace: BASIL_NAMESPACE,
  storages: BASIL_STORAGES,
  domain: BASIL_COOKIES_DOMAIN,
});

export const basilResetExcept = (list) => {
  let saved = [];
  forEach(list, (keyword) => {
    saved.push({ keyword, value: basil.get(keyword) });
  });
  basil.reset();
  forEach(saved, (save) => {
    basil.set(save.keyword, save.value);
  });
};

export const getBasilStorages = () => {
  return ["cookie", "session", "local", "memory"];
};

export const setCookie = ({ key, value, conf = {} }) => {
  basil.set(key, value, {
    storages: getBasilStorages(),
    expireDays: 31,
    secure: BASIL_COOKIES_SECURE || true,
    sameSite: "lax",
    ...conf,
  });
};

export const getCookie = ({ key, conf = {} }) => {
  return basil.get(key, {
    storages: getBasilStorages(),
    ...conf,
  });
};

export default basil;
