import React, { useState } from "react";
import { _t } from "i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Members from "./Members";
import { Modal } from "@material-ui/core";

const MembersCTA = ({ hashGroup, hasRights }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button onClick={handleOpen}>{hasRights ? _t("Manage members") : _t("View members")}</Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Members hashGroup={hashGroup} hasRights={hasRights} />
      </Modal>
    </>
  );
};

MembersCTA.propTypes = {};

MembersCTA.defaultProps = {};

export default MembersCTA;
