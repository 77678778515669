import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { getOwnAccessesSelector, visitorsSelector } from "app/state/ducks/ressources/selectors";
import clsx from "clsx";
import React from "react";
import { useSelector } from "react-redux";
import { _n, _t } from "utilities/i18n";
import AvailabilitySwitcher from "../AvailabilitySwitcher";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px",
    gap: "4px",
    width: "320px",
    height: "250px",
    overflowY: "auto",
    position: "absolute",
    top: 37,
    right: "0",
    zIndex: "100",
    backgroundColor: "#fff",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.05)",
    borderRadius: "8px",
    padding: "16px",
  },
  queue: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
  titleSelected: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.primary,
  },
  waitingCustomerTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "150%",
    color: theme.palette.colors.grey700,
    marginBottom: "12px",
  },
  divider: {
    width: "100%",
    backgroundColor: theme.palette.colors.grey200,
  },
}));

const QueuesWidget = ({ }) => {
  const classes = useStyles({});
  const accesses = useSelector(getOwnAccessesSelector);

  return (
    <Box className={classes.root}>
      {accesses.map((access, index) => {
        const visitors = useSelector(visitorsSelector).filter((visitor) => visitor?.queue.group.id === access.group.id);
        const availableVisitors = visitors?.filter(({ status }) => status === "waiting" || status === "ongoing" || status === "ringing");

        return (
          <>
            <Box className={classes.queue}>
              <Box className={classes.details}>
                <Typography className={clsx({ [classes.title]: true, [classes.titleSelected]: access.available })}>{access.group.title}</Typography>
                <Typography className={classes.waitingCustomerTypo}>{_n("{count} waiting customer", "{count} waiting customers", availableVisitors.length, { count: availableVisitors.length })}</Typography>
              </Box>
              <AvailabilitySwitcher access={access} />
            </Box>
            {index !== accesses.length - 1 && <Divider className={classes.divider} />}
          </>
        );
      })}

    </Box>
  );
};

export default QueuesWidget;