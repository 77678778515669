import React, { Component, useEffect, useState } from "react";

import clsx from "clsx";
import get from "lodash/get";

import { _t } from "i18n";

import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

import emitter from "core/emitter";
import {
  EVENT_VONAGE_ENTER_STAGE,
  EVENT_VONAGE_STREAM_UPDATE,
  EVENT_VONAGE_STREAM_DELETE,
  EVENT_SCREEN_FOCUSED_LAYOUT,
  EVENT_VONAGE_STREAM_FOCUS,
  EVENT_VONAGE_STREAM_CLEAR_FOCUS,
} from "core/emitter/events";

import Stream from "./Stream";
import Controls from "./Controls";
import VonageSDK, { VonageStream } from "utilities/vonage";
import basil from "core/basil";

// TODO: move this method to a more generic util folder :(
import { getVerifiedUsername } from "utilities/username";
import { EVENT_SCREEN_LAYOUT_MODE_CHANGE } from "core/emitter/events";
import PropTypes from "prop-types";

// méthode de lionel. La flemme de tester now à 24h de la release. Mais à creuser, car c'est vrai que c'est fort élégant :)
// if (streamscounts <= 16 && streamscounts > 1 ) calc(100% / Math.ceil(Math.sqrt(streamsCount)))
const getWidth = ({ streamsCount, hasFocus }) => {
  if (streamsCount === 1) return "100%";
  if (hasFocus) return "20%";
  if (streamsCount <= 4) return "50%";
  if (streamsCount <= 9) return "calc(100%/3)";
  if (streamsCount <= 16) return "25%";
  return "20%";
};

const getHeight = ({ streamsCount, hasFocus }) => {
  if (hasFocus) return "20%";
  if (streamsCount <= 2) return "100%";
  if (streamsCount <= 6) return "50%";
  return getWidth({ streamsCount, hasFocus });
};

const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  scene: {
    flex: 1,
  },
  controls: {
    height: "78px",
    backgroundColor: theme.palette.background.secondaryBox,
  },
  wrapper: {},
  statuses: {
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    zIndex: 9,
  },
  chip: {
    fontSize: "10px",
    height: "18px",
  },
  live: {
    backgroundColor: theme.wisemblyColors.pastelRed,
  },
  recorded: {
    marginLeft: theme.spacing(1),
    color: theme.wisemblyColors.pastelRed,
    border: `1px solid ${theme.wisemblyColors.pastelRed}`,
  },
  focusHolder: {
    flex: 4,
  },
  streamHolder: {
    display: "flex",
    flexWrap: "wrap",
    flex: "1 auto",
    height: "100%",
    "& .noDisplay": {
      display: "none !important",
    },
  },
  streamHolderWithFocus: {
    display: "block",
    whiteSpace: "nowrap",
    overflowX: "auto",
    overflowY: "hidden",
    "& div.StreamWrapper": {
      width: "20% !important",
      height: "100% !important",
    },
  },
});

const FocusHolder = withStyles(styles)(({ elementId, classes }) => {
  console.log("FocusHolder id", elementId);
  useEffect(() => {
    const hide = (element) => {
      element.classList.add("noDisplay");
    };
    const show = (element) => {
      element.classList.remove("noDisplay");
    };
    const element = document.getElementById(`focusable_${elementId}`);
    const holder = document.getElementById("focus");
    const origin = document.getElementById(`holder_${elementId}`);
    element && holder?.appendChild(element) && hide(origin);
    return () => {
      element && origin?.appendChild(element) && show(origin);
    };
  }, [elementId]);

  return <Box id="focus" className={classes.focusHolder} />;
});

FocusHolder.propTypes = {
  elementId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  originHolder: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

/*
  This component is the main stage
  It handles all the webRTC video sources from other speakers and display them properly on screen
  Its configuration may differ a bit from Live Event and Visio Collab
*/
class StageComponent extends Component {
  constructor(props) {
    super(props);
    const wizFocusedStreamId = get(props, "wiz.live_media.focus", null);
    this.state = {
      streams: [],
      focusHolder: wizFocusedStreamId,
    };

    this.onEnterStage = this.onEnterStage.bind(this);
    this.onStreamUpdate = this.onStreamUpdate.bind(this);
    this.onStreamDelete = this.onStreamDelete.bind(this);
    this.onSetFocus = this.onSetFocus.bind(this);
    this.clearFocus = this.clearFocus.bind(this);
  }

  // this componentDidMount is like a reducer for this component
  // I'm sure there is a better way to code this
  // TODO: refacto when needed and have time
  componentDidMount() {
    emitter.on(EVENT_VONAGE_ENTER_STAGE, this.onEnterStage);
    emitter.on(EVENT_VONAGE_STREAM_UPDATE, this.onStreamUpdate);
    emitter.on(EVENT_VONAGE_STREAM_DELETE, this.onStreamDelete);
    emitter.on(EVENT_VONAGE_STREAM_FOCUS, this.onSetFocus);
    emitter.on(EVENT_VONAGE_STREAM_CLEAR_FOCUS, this.clearFocus);
  }

  componentWillUnmount() {
    emitter.off(EVENT_VONAGE_ENTER_STAGE, this.onEnterStage);
    emitter.off(EVENT_VONAGE_STREAM_UPDATE, this.onStreamUpdate);
    emitter.off(EVENT_VONAGE_STREAM_DELETE, this.onStreamDelete);
    emitter.off(EVENT_VONAGE_STREAM_FOCUS, this.onSetFocus);
    emitter.off(EVENT_VONAGE_STREAM_CLEAR_FOCUS, this.clearFocus);
  }

  componentDidUpdate(prevProps, prevState) {
    const { streams, focusHolder } = this.state;
    const { vonage } = this.props;
    const anyShareScreen = streams.find(
      ({ type }) => type === VonageSDK.TYPE_SCREEN
    );

    /* If we get any share screen, we set it as focus immediately */
    if (anyShareScreen && prevState.focusHolder !== anyShareScreen.id) {
      vonage.setFocus(anyShareScreen.id);
    }

    const previousFocus = get(prevProps, "wiz.live_media.focus");
    const newFocus = get(this.props, "wiz.live_media.focus");

    if (previousFocus !== newFocus) {
      vonage.setFocus(newFocus);
    }
  }

  onEnterStage(type) {
    console.log("[Vonage] entering stage", type);

    const { streams } = this.state;
    const { vonage, user } = this.props;
    switch (type) {
      case VonageSDK.TYPE_VIDEO:
        {
          const own = new VonageStream({
            id: "own",
            type,
            hasFocus: false,
            isOwn: true,
          });
          streams.push(own);
          vonage.addStream(own);
          this.setState({ streams }, async () => {
            try {
              const publisher = await vonage.createPublisher("own", type, {
                name: user ? user.name : getVerifiedUsername(),
              });
              const stream = await vonage.publish(type, publisher);
              own.stream = stream;
              this.setState({ streams });
            } catch (error) {
              console.log("Error while publishing stream", error);

              // remove stream from streams
              const cleanedStreams = streams.filter(({ id }) => id !== "own");
              vonage.setStreams(cleanedStreams.slice(0));
              this.setState({ streams: cleanedStreams.slice(0) });
            }
          });
        }
        break;
      case VonageSDK.TYPE_SCREEN:
        {
          const own = new VonageStream({
            id: "own_screenshare",
            type,
            hasFocus: false,
            isOwn: true,
          });
          streams.push(own);
          vonage.addStream(own);

          this.setState({ streams }, async () => {
            try {
              const publisher = await vonage.createPublisher(
                "own_screenshare",
                type
              );
              const stream = await vonage.publish(type, publisher);
              own.stream = stream;
              this.setState({ streams }, () => {
                vonage.setWizFocus(stream.id);
              });

              // needed because of the native browser button "stop sharing"
              publisher.on("streamDestroyed", () => {
                vonage.stopScreenShare();
              });
            } catch (error) {
              console.log("Error while publishing stream", error);

              // remove stream from streams
              const cleanedStreams = streams.filter(
                ({ id }) => id !== "own_screenshare"
              );
              vonage.setStreams(cleanedStreams.slice(0));
              this.setState({ streams: cleanedStreams.slice(0) });
            }
          });
        }
        break;
    }
  }

  onStreamUpdate(stream = {}) {
    console.log("[Vonage] updating stream", stream.id);
    const { vonage } = this.props;
    // clone vonage streams array
    const streams = vonage.streams.slice(0);

    this.setState({ streams }, async () => {
      if (stream.subscribed || stream.isOwn) return;
      await vonage.subscribeToStream(stream);
    });
  }

  onStreamDelete(stream = {}) {
    console.log("[Vonage] deleting stream", stream.id);
    const { vonage } = this.props;
    // Remove it from vonage
    vonage.clearFocus(stream.id);
    // Remove it from local stream holder
    this.clearFocus(stream);
    // clone vonage streams array
    const streams = vonage.streams.slice(0);

    this.setState({ streams });
  }

  clearFocus({ id }) {
    console.log("Clearing focus on id ", id);
    const { focusHolder } = this.state;
    /* We dont have a focusHolder */
    if (!focusHolder) return;
    /* The focus holder we have is not the id we want to clear */
    if (focusHolder !== id) return;
    /* We clear the focusHolder */
    this.setState({
      focusHolder: null,
    });
  }

  onSetFocus({ id }) {
    const { focusHolder } = this.state;
    /* If we have the same focusHolder, we dont need to set new state */
    if (focusHolder === id) return;
    /* We set the focusHolder to the id we have in parameters */
    this.setState({
      focusHolder: id,
    });
  }

  render() {
    const {
      classes,
      setPreview,
      product,
      wiz,
      displayOnlyMine,
      layout,
      onSetFocus,
      vonage,
    } = this.props;
    const { streams, focusHolder } = this.state;
    const hasFocus = streams.length > 1 && !!focusHolder;
    const ownStreams = streams.filter(({ isOwn }) => isOwn);
    const ownVideoStream = ownStreams.find(
      ({ type }) => type === VonageSDK.TYPE_VIDEO
    );
    const ownShareScreen = ownStreams.find(
      ({ type }) => type === VonageSDK.TYPE_SCREEN
    );
    /* The count screen is different if we only show our screen
     * So this will calculate based on knowing what we show
     * */
    const streamsCount = displayOnlyMine ? ownStreams.length : streams.length;
    const focusedStream = focusHolder && vonage.getStreamById(focusHolder);

    return (
      <Box
        display="flex"
        flex="1 auto"
        flexDirection="column"
        className={clsx("Stage", classes.root)}
      >
        <Box flex="1 auto" display="flex" flexDirection="column">
          {focusedStream && <FocusHolder elementId={focusedStream.id} />}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={clsx("Scene", classes.scene)}
          >
            <Box className={classes.statuses}>
              {
                // prettier-ignore
                product === "wisembly_webinar" &&
                !get(wiz, "live_media.enabled", false) && (
                  <Tooltip
                    title={_t("You are in rehearsal mode between speakers only.")}
                  >
                    <Chip
                      variant="outlined"
                      className={classes.chip}
                      size="small"
                      label={_t("Speakers only")}
                    />
                  </Tooltip>
                )
              }
              {product === "wisembly_webinar" &&
                get(wiz, "live_media.enabled", false) && (
                  <Tooltip title={_t("You're broadcasted live, smile!")}>
                    <Chip
                      className={clsx(classes.chip, classes.live)}
                      size="small"
                      label={_t("Live")}
                    />
                  </Tooltip>
                )}
              {get(wiz, "live_media.archive", false) && (
                <Tooltip title={_t("The content is currently recorded.")}>
                  <Chip
                    variant="outlined"
                    className={clsx(classes.chip, classes.recorded)}
                    size="small"
                    label={_t("Recorded")}
                  />
                </Tooltip>
              )}
            </Box>
            {!!streams.length && (
              <Box
                className={clsx(
                  classes.streamHolder,
                  focusHolder && focusedStream
                    ? classes.streamHolderWithFocus
                    : null
                )}
              >
                {streams.map((stream) => {
                  /*
                   * We are in focus if we have a focus stream id that is one of our screens
                   * */
                  const isFocus = focusHolder === stream.id;
                  /*
                   * I display the stream if :
                   * - We are not only displaying mine
                   * - Or ( we are ) and we have a focus XOR we show our camera AND share screen
                   * */
                  const isDisplayed =
                    !displayOnlyMine ||
                    isFocus ||
                    stream === ownShareScreen ||
                    stream === ownVideoStream;

                  const displayType = (() => {
                    switch (true) {
                      case isDisplayed && !!focusHolder:
                        return "inline-block";
                      case isDisplayed:
                        return "flex";
                      default:
                        return "none";
                    }
                  })();

                  return (
                    <Box
                      key={stream.id}
                      className={clsx("StreamWrapper", classes.wrapper)}
                      style={{
                        display: displayType,
                        width: getWidth({
                          streamsCount,
                          hasFocus,
                          product,
                        }),
                        height: getHeight({
                          streamsCount,
                          hasFocus,
                          product,
                        }),
                      }}
                      id={`holder_${stream.id}`}
                    >
                      <Stream
                        {...stream}
                        product={product}
                        vonage={vonage}
                        focusHolder={focusHolder}
                        wiz={wiz}
                        product={product}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}
            {streams.length === 0 && (
              <>
                {/* prettier-ignore */}
                <Box>
                    {_t("Nobody is currently sharing content. Don't be shy and participate!")}
                  </Box>
              </>
            )}
          </Box>
        </Box>

        <Box display="flex" className={clsx("Controls", classes.controls)}>
          <Controls
            vonage={vonage}
            isOnStage={ownVideoStream || ownShareScreen}
            videoStream={ownVideoStream}
            screenShare={ownShareScreen}
            setPreview={setPreview}
            parentRef={this.props.parentRef}
            isFocused={!displayOnlyMine}
            onSetFocus={onSetFocus}
          />
        </Box>
      </Box>
    );
  }
}

const Stage = ({ ...rest }) => {
  const [focusedLayout, setFocusedLayout] = useState(
    basil.get("currentLayoutFocus", "live")
  );

  const [layoutMode, setLayoutMode] = useState(
    basil.get("currentLayoutMode", "full")
  );

  useEffect(() => {
    const focusedLayoutChange = (newFocus) => setFocusedLayout(newFocus);
    const layoutModeChange = (newLayout) => {
      setLayoutMode(newLayout);
    };

    emitter.on(EVENT_SCREEN_FOCUSED_LAYOUT, focusedLayoutChange);
    emitter.on(EVENT_SCREEN_LAYOUT_MODE_CHANGE, layoutModeChange);

    return () => {
      emitter.removeListener(EVENT_SCREEN_FOCUSED_LAYOUT, focusedLayoutChange);
      emitter.removeListener(EVENT_SCREEN_LAYOUT_MODE_CHANGE, layoutModeChange);
    };
  }, []);
  const displayOnlyMine = layoutMode === "pip" && focusedLayout !== "live";
  return <StageComponent {...rest} displayOnlyMine={displayOnlyMine} />;
};

export default withStyles(styles)(Stage);
