import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import SwitchFocus from "./SwitchFocus";
import { MediaViewer } from "appContainers/Viewers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    zIndex: (props) =>
      (props.layoutMode === "pip" || props.layoutMode === "split") &&
      !props.hasFocus
        ? 1
        : "auto",
  },
}));

const DisplayMedia = ({
  layoutMode,
  hasFocus,
  onSetFocus,
  data,
  displayControlBar,
  layoutsCount,
}) => {
  const classes = useStyles({ layoutMode, hasFocus });

  return (
    <Box position="relative" className={clsx("DisplayMedia", classes.root)}>
      <MediaViewer
        mediaHash={data}
        disabled={true}
        layoutMode={layoutMode}
        hasFocus={hasFocus}
        onSetFocus={onSetFocus}
        displayControlBar={displayControlBar}
        layoutsCount={layoutsCount}
      />

      {(layoutMode === "pip" || layoutMode === "split") && !hasFocus && (
        <SwitchFocus layout="media" onSetFocus={onSetFocus} />
      )}
    </Box>
  );
};

DisplayMedia.propTypes = {
  data: PropTypes.string,
  layoutMode: PropTypes.string,
  hasFocus: PropTypes.bool,
  onSetFocus: PropTypes.func,
  displayControlBar: PropTypes.bool,
  layoutsCount: PropTypes.number,
};

DisplayMedia.defaultProps = {
  displayControlBar: false,
};

export default DisplayMedia;
