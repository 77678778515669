import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Avatar from "coreContainers/Profil/Avatar";
import AvatarInitials from "./Profil/AvatarInitials";
import TextInput from "./OutlinedTextField";
import { _t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { setPopupParticipationMode } from "app/state/ducks/application/actions";
import { getParticipation, getUsername } from "app/state/ducks/application/selectors";
import Icon from "coreUiKit/inputs/Icon";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import globalStore from "appStore";
import { getInitialsFromString } from "./Profil/utils";
import PersonCircle from "react-bootstrap-icons/dist/icons/person-circle";
import { useUser } from "../userHooks";
import Tooltip from "../uikit/component/Tooltip";
import Send from "react-bootstrap-icons/dist/icons/send";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "stretch",
      width: "315px",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
      },
    },
    profil: {
      margin: "0px 10px 0 0",
      cursor: (props) => (props.mode ? "pointer" : "initial"),
      position: "relative",
      overflow: "hidden",
      borderRadius: 100,
    },
    userIcon: {
      backgroundColor: theme.palette.colors.dark50,
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      opacity: 0,
      "&:hover": {
        opacity: 1,
      },
    },
    input: {
      flexGrow: 1,
      position: "relative",
    },
    sendIcon: {
      position: "absolute",
      right: 12,
      top: 12,
    },
  }),
  {
    name: "SendArea",
  }
);

const SendArea = ({ inputOnChange, message, disabled, onSend }) => {
  const state = globalStore.getState();
  const participationMode = useSelector(getParticipation);
  const dispatch = useDispatch();
  const wiz = currentWizSelector(state);
  const username = useSelector(getUsername);
  const user = useUser();
  const mode = !wiz.participation_mode || user ? true : false;
  const classes = useStyles({ mode });
  const mobileScreen = useSelector(mobileLayoutSelector);
  const [errorLength, setErrorLength] = useState(false);

  const RenderAvatar = useCallback(() => {
    if (participationMode === undefined) {
      return <Avatar />;
    }
    if (participationMode.is_admin) {
      return <AvatarInitials anonymous={false} avatar={wiz ? wiz.getLogo() : null} initials={getInitialsFromString(wiz ? wiz.title : "Aa")} />;
    }
    if (participationMode.is_participant) {
      return <AvatarInitials anonymous={false} initials={getInitialsFromString(username ? username : "Aa")} />;
    }
    return <Avatar />;
  }, [wiz, username, participationMode]);

  const openModal = () => dispatch(setPopupParticipationMode(true));

  return (
    <Box className={clsx("SendArea", classes.root)}>
      <Box className={clsx("SendAreaProfil", classes.profil)} onClick={() => openModal()}>
        <RenderAvatar />
        {mode && (
          <Tooltip placement="top" title={_t("Mode of participation")}>
            <Box className={classes.userIcon}>
              <PersonCircle />
            </Box>
          </Tooltip>
        )}
      </Box>
      <Box className={classes.input}>
        <TextInput
          size={"medium"}
          value={message}
          onChange={(value) => {
            if (value.length > 254) {
              setErrorLength(true);
            }
            if (errorLength && value.length < 255) {
              setErrorLength(false);
            }
            inputOnChange(value);
          }}
          placeholder={_t("Message")}
          errorMessage={errorLength ? _t("255 characters maximum") : ""}
          multiline
          maxRows={3}
          type="text"
          fullWidth
          isMobileChat
          disabled={disabled}
          onKeyDown={(ev) => {
            if (ev.keyCode === 13 && message.trim().length > 0) {
              ev.preventDefault();
              onSend();
            }
          }}
        />
        {mobileScreen && (
          <Icon
            className={classes.sendIcon}
            IconComponent={Send}
            size={16}
            hover={disabled ? "grey" : "greyDark"}
            onClick={() => onSend()}
            disabled={disabled}
            color={message.length > 0 && "primary"}
          />
        )}
      </Box>
    </Box>
  );
};

SendArea.propTypes = {
  inputOnChange: PropTypes.func,
  message: PropTypes.string,
  disabled: PropTypes.bool,
  onSend: PropTypes.func,
};
SendArea.displayName = "SendArea";
SendArea.defaultProps = {
  inputOnChange: () => {},
  onSend: () => {},
  message: "",
  disabled: false,
};

export default SendArea;
