import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import toNumber from "lodash/toNumber";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  percentColored: {
    height: (props) => (props.height ? props.height : theme.spacing(1)),
    maxWidth: "100%",
    width: "100%",

    backgroundImage: (props) =>
      props.dynamise
        ? `linear-gradient(to right,${
            theme.palette.colors.white50
          } 0 ${toNumber(props.percent)}% , transparent ${toNumber(
            props.percent
          )}% 100%)`
        : `linear-gradient(to right,${
            theme.palette.colors.white50
          } 0 ${toNumber(props.percent)}% ,${
            theme.palette.colors.white50
          } ${toNumber(props.percent)}% 100%)`,
  },
}));
const PercentIndicator = ({
  percent,
  width,
  height,
  customClass,
  size,
  dynamise,
  timer,
}) => {
  const [dynamicPercent, setDynamicPercent] = useState(0);
  useEffect(() => {
    if (dynamise) {
      const interval = setTimeout(function () {
        if (dynamicPercent < percent) {
          setDynamicPercent(dynamicPercent + 1);
        }
      }, toNumber(timer / percent));
      return () => clearTimeout(interval);
    }
  }, [dynamise, dynamicPercent]);

  const classes = useStyles({
    dynamise,
    percent: dynamise ? dynamicPercent : percent,
    width,
    height,
    size,
  });
  return (
    <Box
      borderRadius={10}
      className={clsx("PercentIndicator", classes.percentColored, customClass)}
    ></Box>
  );
};
PercentIndicator.propTypes = {
  percent: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  customClass: PropTypes.any,
  size: PropTypes.string,
  dynamise: PropTypes.bool,
  timer: PropTypes.number,
};
PercentIndicator.defaultProps = {
  percent: 10,
  customClass: "",
  size: "small",
  dynamise: false,
  timer: 3000,
};

export default PercentIndicator;
