import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { _t } from "i18n";
import { FormIcon } from "appComponents/Viewers";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  surveyTitle: {
    borderTopLeftRadius: (props) =>
      props.isThumbnailView || props.smallScreen || props.layoutMode === "split"
        ? 0
        : 10,
    borderTopRightRadius: (props) =>
      props.isThumbnailView || props.smallScreen || props.layoutMode === "split"
        ? 0
        : 10,

    backgroundColor: theme.palette.background.secondaryBox,
    color: theme.palette.text.primary,
    //height: theme.spacing(10),
    [theme.breakpoints.up("sm")]: {
      height: (props) =>
        props.isThumbnailView
          ? "100%"
          : props.layoutMode === "split"
          ? "217px"
          : "140px",
      minHeight: (props) =>
        props.isThumbnailView ? theme.spacing(17.125) : theme.spacing(17.5),
      padding: (props) => (props.isThumbnailView ? "5px 9px 9px 6px" : "30px"),
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: theme.spacing(21.375),
      padding: "30px",
    },
  },
  responseTitle: {
    color: theme.palette.button.default.color.enable,
    minHeight: theme.spacing(10),
  },

  icon: {
    fontSize: (props) => (props.isThumbnailView ? "1rem" : "2.18rem"),
  },

  content: {
    flexDirection: (props) => (props.isThumbnailView ? "column" : "row"),
    justifyContent: (props) =>
      props.isThumbnailView ? "space-around" : "flex-start",
  },
  titleText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: (props) => (props.isThumbnailView ? 0 : 1),
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    color: theme.wisemblyColors.darkish,
    fontWeight: "normal",
    letterSpacing: " 0.58px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: (props) =>
        props.isThumbnailView
          ? "12px"
          : props.layoutMode === "split"
          ? "20px"
          : "25px",
    },
  },
  actionText: {
    color: theme.palette.secondary.contrastText,
  },
  actionBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  meta: {
    color: theme.wisemblyColors.white,
    display: (props) =>
      props.isThumbnailView || props.forResult ? "none" : "flex",
  },
  metaText: {
    color: theme.wisemblyColors.white,
    textAlign: "center",

    fontSize: "14px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
}));

const SurveyFormTitle = ({
  survey,
  isThumbnailView,
  layoutMode,
  forResult,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles({
    isThumbnailView,
    smallScreen,
    layoutMode,
    forResult,
  });
  const surveyFirstItem = survey.items.toModelArray()[0];
  const singleItemSurvey = survey.items.toModelArray().length == 1;

  const multiple_response_meta = (
    <Box className={classes.meta}>
      <Typography className={classes.metaText}>
        {singleItemSurvey && surveyFirstItem.type === "multiple"
          ? _t("Multi choice response.")
          : singleItemSurvey && surveyFirstItem.type === "unique"
          ? _t("only one choice possible.")
          : ""}
        {survey.multiple_answers
          ? _t("You can answer this many time.")
          : _t("You can answer this only once.")}
      </Typography>
    </Box>
  );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.surveyTitle}
    >
      <Box
        flex={1}
        display="flex"
        height="100%"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.content}
      >
        <FormIcon
          type={survey.type}
          isThumbnailView={isThumbnailView}
          layoutMode={layoutMode}
        />

        <Typography display="block" className={classes.titleText}>
          {survey.name || ""}
        </Typography>
      </Box>
      {multiple_response_meta}
    </Box>
  );
};
SurveyFormTitle.propTypes = {
  survey: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
  forResult: PropTypes.bool,
};
SurveyFormTitle.defaultProps = {
  isThumbnailView: false,
  forResult: false,
};

export default SurveyFormTitle;
