import { put } from "redux-saga/effects";
import { API_BASE } from "settings/config";
import { fetchedOnce } from "applicationDucks/actions";
import makeCallAndUpdate from "./makeCallAndUpdate";

export default function* fetchSessionWithOTT(ott) {
  const url = API_BASE.clone()
    .segment("ott-authentication")
    .addQuery("ott", ott)
    .toString();

  const options = {
    silent: true,
  };

  yield makeCallAndUpdate(url, null, options);
  yield put(fetchedOnce('session'));
}
