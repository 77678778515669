import { baseStreamOptions } from "./initPublisher";
import { call, fork, put, select, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import emitter from "core/emitter";
import { STREAM_UPDATE_TALKING_STATUS } from "utilities/emitter/events";
import { sessionSelector } from "../selectors";
import { setElement } from "../actions";

export const AUDIO_LEVEL = 0.1;

const subscribe = (session, stream) =>
  new Promise((resolve) => {
    const options = {
      ...baseStreamOptions,
    };
    session.subscribe(stream, `stream-${stream.id}`, options, (error) => {
      if (error) return resolve({ error });
      resolve(stream);
    });
  });

export function* audioLevelUpdated(event) {
  const streamId = event.target.stream.streamId;
  const audioLevel = event.audioLevel;

  if (audioLevel > AUDIO_LEVEL) {
    yield emitter.emit(STREAM_UPDATE_TALKING_STATUS, {
      streamId,
      talking: true,
    });
    return;
  }
  yield emitter.emit(STREAM_UPDATE_TALKING_STATUS, {
    streamId,
    talking: false,
  });
}

export function createAudioChannel(subscriber) {
  return eventChannel((emit) => {
    const audioLevelHandler = (event) => {
      emit(event);
    };
    const unsubscribe = () => {
      subscriber.off("audioLevelUpdated", audioLevelHandler);
    };
    subscriber.on("audioLevelUpdated", audioLevelHandler);
    return unsubscribe;
  });
}

export function* unsubscribe({ stream }) {
  const session = yield select(sessionSelector);
  const subscribers = yield session.getSubscribersForStream(stream);
  for (let i = 0; i < subscribers.length; i++) {
    console.log("Unsubscribe from", subscribers[i]);
    yield session.unsubscribe(subscribers[i]);
  }
}

export function* bindToDom({ stream, session }) {
  console.log("[VISIO SAGA] BIND TO DOM", stream)

  const { error } = yield subscribe(session, stream);

  if (error) {
    console.log("[Visio] Error while subscribing to a stream");
    return;
  }
  const subscribers = yield session.getSubscribersForStream(stream);
  const subscriber = subscribers[0];
  const channel = yield call(createAudioChannel, subscriber);
  yield put(setElement(stream.id, subscriber.element, "video"));

  while (true) {
    const payload = yield take(channel);
    try {
      yield fork(audioLevelUpdated, payload);
    } catch (e) {
      console.log("unable to handle payload for audioLevelUpdate", JSON.parse(payload));
    }
  }
}
