import React from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import CheckLg from "react-bootstrap-icons/dist/icons/check-lg";
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import Icon from "./Icon";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

const useStyles = makeStyles(
  (theme) => ({
    listItemRoot: {
      outline: "none",
      cursor: (props) => (props.disabled ? "default" : "pointer"),
      padding: (props) => (props.darkMode ? "0" : "10px"),
      marginBottom: (props) => (props.darkMode ? "10px" : "5px"),
      alignItems: "center",
      "&:last-of-type": {
        marginBottom: "0px",
      },
    },
    listItemSelected: {
      backgroundColor: "transparent !important",
      "& $typography, & $icon, &:hover $typography, &:hover $icon": {
        color: theme.palette.colors.blackMedium,
      },
      "&:hover": {
        backgroundColor: theme.palette.colors.primary10,
      },
    },
    listItemTextRoot: {
      margin: 0,
      display: "flex",
      flexDirection: "column",
    },
    listItemIconRoot: {
      minWidth: "auto",
      marginRight: (props) => (props.darkMode ? "5px" : "8px"),
      flexDirection: "row",
      alignItems: "center",
    },
    check: {
      display: "flex",
      alignItems: "flex-start",
      width: "12px",
      height: "12px",
      color: theme.palette.colors.white,
      margin: "0px 0px 0px 15px",
    },
    customElement: {
      marginLeft: "8px",
    },
    icon: {
      color: (props) =>
        props.selected && !props.darkMode
          ? theme.palette.colors.primary
          : props.color
            ? theme.palette.colors[props.color]
            : props.darkMode
              ? theme.palette.colors.white
              : theme.palette.colors.blackMedium,
      "&:hover": {
        color: (props) => (props.darkMode ? (props.color ? theme.palette.colors[props.color] : theme.palette.colors.white) : theme.palette.colors.primary),
      },
    },
    typography: {
      color: (props) =>
        props.selected && !props.darkMode
          ? theme.palette.colors.primary
          : props.color
            ? theme.palette.colors[props.color]
            : props.darkMode
              ? theme.palette.colors.white
              : theme.palette.colors.blackMedium,
    },
    subtitle: {
      color: theme.palette.colors.greyDark,
      marginTop: "5px",
    },
  }),
  {
    name: "OneMenuItem",
  }
);
const withIcon = ["checkbox", "select", "textIcon"];
//select menu item, and clickable menu item

const MenuItem = ({ item, onClick, type, selected, check = false, id, disabled = false, darkMode = false, customElement = null, ...rest }) => {
  const title = item?.title;
  const subtitle = item?.subtitle;
  const hash = item.hash;
  const color = item?.color;
  const classes = useStyles({ disabled, selected, darkMode, color });

  const handleClick = () => {
    //add element in onclick
    onClick ? onClick(hash) : null;
  };

  return (
    <ListItem
      alignitems="flex-start"
      classes={{
        root: clsx({ [classes.listItemRoot]: true }),
        selected: clsx({ [classes.listItemSelected]: !darkMode }),
      }}
      onClick={handleClick}
      selected={selected}
      disabled={disabled}
      {...rest}
    >
      {withIcon.includes(type) && (
        <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
          {type === "checkbox" && <Checkbox checked={selected} disabled={disabled} />}
          {type === "select" && <Radio name={id} value={hash} checked={selected} disabled={disabled} />}
          {type === "textIcon" && <Icon IconComponent={item.icon} className={classes.icon} />}
          {!!customElement && <Box className={classes.customElement}>{customElement}</Box>}
        </ListItemIcon>
      )}

      <ListItemText
        classes={{ root: classes.listItemTextRoot }}
        disableTypography={true}
        primary={
          <Typography
            variant={
              darkMode
                ? "smallBody13"
                : clsx({
                  bodySB13: type === "select",
                  body13: type !== "select",
                })
            }
            className={classes.typography}
          >
            {title}
          </Typography>
        }
        secondary={
          subtitle && (
            <Typography variant="smallBody13" className={classes.subtitle}>
              {subtitle}
            </Typography>
          )
        }
      />
      {check &&
        (type === "textIcon" && selected ? (
          <Box className={classes.check}>
            <CheckLg size={12} />
          </Box>
        ) : (
          <Box className={classes.check}></Box>
        ))}
    </ListItem>
  );
};

MenuItem.defaultProps = {
  disabled: false,
  darkMode: false,
  check: false,
  customElement: null,
};

export default MenuItem;
