export const THIRD_PARTY_LOADED = "visio/third_party_loaded";

export const SET_ERROR = "visio/set_error";
export const CLEAR_ERROR = "visio/clear_error";

export const INIT = "visio/init";
export const SET_CREDENTIALS = "visio/set_credentials";
export const REFRESH_CREDENTIALS = "visio/refresh_creds";

export const GET_DEVICES = "visio/get_devices";
export const SET_DEVICES = "visio/set_devices";
export const CHECK_PERMISSIONS = "visio/check_permissions";

export const JOIN_SESSION = "visio/join_session";
export const DISCONNECT_SESSION = "visio/disconnect_session";

export const SET_SESSION = "visio/set_session";
export const SET_STREAM = "visio/set_stream";
export const UPDATE_STREAM = "visio/update_stream";
export const REMOVE_STREAM = "visio/remove_stream";

export const INIT_PUBLISHER = "visio/init_publisher";
export const DESTROY_PUBLISHER = "visio/destroy_publisher";

export const PUBLISH_STREAM = "visio/publish_stream";
export const UNPUBLISH_STREAM = "visio/unpublish_stream";

export const SET_SOURCE = "visio/set_source";

export const SET_PUBLISHER = "visio/set_publisher";
export const SET_STREAM_ID = "visio/set_stream_id";

export const MUTE = "visio/mute";
export const MUTE_OTHER = "visio/mute_other";

export const EJECT = "visio/eject";

export const SET_FOCUS = "visio/set_focus";
export const BIND_DOM = "visio/bind_dom";
export const UNSUBSCRIBE_STREAM = "visio/unsubscribe_stream";
export const REQUESTED_JOIN_STAGE = "visio/requested_join_stage";
export const SET_ELEMENT = "visio/set_element";
export const REMOVE_ELEMENT = "visio/remove_element";
export const SET_ELEMENT_POSITION = "visio/set_element_position";

export const GET_VISIO_CREDENTIALS = "visio/get_visio_credentials";
export const START_VISIO = "visio/start_visio";
export const CLEAR_VISIO = "visio/clear_visio";

export const getVisioCredentials = (visitor) => ({ type: GET_VISIO_CREDENTIALS, visitor });
export const hasLoaded = () => ({ type: THIRD_PARTY_LOADED });
export const setRequestedJoinStage = () => ({ type: REQUESTED_JOIN_STAGE });

export const setCredentials = (credentials) => ({
  type: SET_CREDENTIALS,
  credentials,
});
export const setError = (code, message) => ({
  type: SET_ERROR,
  error: { code, message },
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});
export const setSession = (session) => ({ type: SET_SESSION, session });
export const joinSession = () => ({ type: JOIN_SESSION });
export const disconnectSession = () => ({ type: DISCONNECT_SESSION });
export const startVisio = () => ({ type: START_VISIO });
export const getDevices = (mode) => ({ type: GET_DEVICES, mode });
export const setDevices = (devices) => ({ type: SET_DEVICES, devices });

export const setStream = (stream) => ({ type: SET_STREAM, stream });
export const updateStream = (stream) => ({ type: UPDATE_STREAM, stream });
export const removeStream = (stream) => ({ type: REMOVE_STREAM, stream });

export const initPublisher = (mode, containerId, opts) => ({
  type: INIT_PUBLISHER,
  mode,
  containerId,
  opts,
});
export const destroyPublisher = (mode) => ({ type: DESTROY_PUBLISHER, mode });

export const publishStream = (mode) => ({ type: PUBLISH_STREAM, mode });
export const unpublishStream = (mode) => ({ type: UNPUBLISH_STREAM, mode });
export const checkPermissions = (mode) => ({ type: CHECK_PERMISSIONS, mode });

export const setSource = (mode, id) => ({ type: SET_SOURCE, mode, id });

export const setPublisher = (mode, publisher) => ({
  type: SET_PUBLISHER,
  mode,
  publisher,
});
export const setStreamId = (mode, id) => ({ type: SET_STREAM_ID, mode, id });

// TODO : merge two method to an unified one that does the logic behind?
export const mute = (mode, status) => ({ type: MUTE, mode, status });
export const muteOther = (stream, mode, status) => ({
  type: MUTE_OTHER,
  stream,
  mode,
  status,
});

export const focus = (stream) => ({ type: SET_FOCUS, stream });
export const eject = (stream) => ({ type: EJECT, stream });
export const bindDom = (stream, session, volumeChanged) => ({
  type: BIND_DOM,
  stream,
  session,
  volumeChanged,
});

export const unsubscribe = (stream) => ({
  type: UNSUBSCRIBE_STREAM,
  stream,
});

export const setElement = (streamId, element, field) => ({
  type: SET_ELEMENT,
  streamId,
  field,
  element,
});

export const removeElement = (streamId) => ({
  type: REMOVE_ELEMENT,
  streamId,
});

export const setElementPosition = (streamId, position) => ({
  type: SET_ELEMENT_POSITION,
  streamId,
  position,
});

export const clearVisio = () => ({ type: CLEAR_VISIO });
