import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { _t } from "i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  titleText: {
    marginTop: "-50px",
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.5px",
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
}));
const CanNotAnswerView = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.titleText}>
        {_t("Please wait until the survey ends.")}
      </Typography>
    </Box>
  );
};

export default CanNotAnswerView;
