import React, { useEffect, useState } from "react";
import { _t } from "i18n";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { liveShoppingBootstrapSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { displayTabBar } from "app/state/ducks/liveShopping/actions";
import { SelectBox, SelectBoxItem } from "@tremor/react";

const SolutionSwitcher = ({ }) => {
  const location = useLocation();
  const { license } = useSelector(liveShoppingBootstrapSelector);
  const solutionByPath = location.pathname.includes("/oto/") ? "oto" : location.pathname.includes("/otm/") ? "otm" : null;
  const [solution, setSolution] = useState(solutionByPath || localStorage.getItem("solution"));
  const projectId = localStorage.getItem("projectId");
  const isMobile = useSelector(mobileLayoutSelector);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile)
      dispatch(displayTabBar(false));
  }, []);

  useEffect(() => {
    if (!license?.can_use_oto && !license?.can_use_otm) return;

    if (!solution && license?.can_use_oto && license?.can_use_otm) {
      setSolution("oto");
      localStorage.setItem("solution", "oto");
    }

    if (solution && !license[`can_use_${solution}`]) {
      setSolution(license?.can_use_oto ? "oto" : "otm");
      localStorage.setItem("solution", license?.can_use_oto ? "oto" : "otm");
    }

  }, [license]);

  const handleChange = (value) => {
    setSolution(value);
    if (projectId) {
      history.push(value === "oto" ? `/oto/${projectId}/dashboard` : "/otm/lives")
    }
    history.push(value === "otm" ? "/otm/lives" : "/board")
    localStorage.setItem("solution", value);
    window.location.reload();
  };

  const solutions = [];

  if (license?.can_use_oto)
    solutions.push({ value: "oto", label: _t("One To One") });

  if (license?.can_use_otm)
    solutions.push({ value: "otm", label: _t("One To Many") });

  return (
    <SelectBox
      value={solution}
      placeholder={solution === "otm" ? _t("One To Many") : _t("One To One")}
      onValueChange={(value) => handleChange(value)}>
      {solutions.map((item) => (
        <SelectBoxItem
          key={item.value}
          value={item.value}
          text={item.label}
          icon={undefined}
        />
      ))}
    </SelectBox>
  );
};

SolutionSwitcher.propTypes = {};

SolutionSwitcher.defaultProps = {};

export default SolutionSwitcher;
