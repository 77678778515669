import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Clock from "react-bootstrap-icons/dist/icons/clock";
import { getTimeRemaining } from "utilities/utils/date";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  timeNumber: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    textAlign: "center",
    color: theme.palette.colors.grey700,
    margin: "0px 4px",
  },
  timeLetter: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: theme.palette.colors.grey700,
    margin: "0px 4px",
  },
}));

const Timer = ({ }) => {
  const { status, live_start } = useSelector(currentWizSelector);
  const start_live = status === "live" && live_start;
  const classes = useStyles();
  const [countDown, setCountDown] = useState(getTimeRemaining(start_live));
  const [showComponent, setShowComponent] = useState(status === "pre_live");

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getTimeRemaining(start_live);
      setCountDown(remaining);

      if (remaining.total < 0) {
        setShowComponent(false);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (!showComponent) return null;
  if (Math.sign(countDown.total) < 0) return null;

  return (
    <Box>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Clock />
        &nbsp; <Typography>{_t("Direct Live in") + " ..."}</Typography>
      </Box>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Typography className={classes.timeNumber}>{countDown.days}</Typography>
        <Typography className={classes.timeLetter}>j</Typography>
        <Typography style={{ color: "#B4B6B9", fontSize: "16px", margin: "0px 10px" }}>:</Typography>
        <Typography className={classes.timeNumber}>{countDown.hours}</Typography>
        <Typography className={classes.timeLetter}>h</Typography>
        <Typography style={{ color: "#B4B6B9", fontSize: "16px", margin: "0px 10px" }}>:</Typography>
        <Typography className={classes.timeNumber}>{countDown.minutes}</Typography>
        <Typography className={classes.timeLetter}>m</Typography>
      </Box>
    </Box>
  );
};

Timer.propTypes = {};

Timer.defaultProps = {};

export default Timer;
