import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class Workshop extends BaseModel {
  constructor(args) {
    super(args);
    this.Model = Workshop;
  }

  setEvent(Event) {
    this.event = Event.id;
  }
}

Workshop.modelName = "Workshop";
Workshop.backendName = "workshop";

Workshop.fields = {
  id: attr(),
  title: attr(),
  description: attr(),
  current_step: attr(),
  created_at: attr(),
  event: fk("Event", "workshops"),
};

export default Workshop;
