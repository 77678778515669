import React from "react";
import PropTypes from "prop-types";

const CustomLikeIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29.95 12.6955C29.7986 11.3092 29.2596 9.99362 28.3947 8.89963C27.5299 7.80565 26.3743 6.97752 25.0604 6.5102C23.8109 6.01439 22.448 5.87642 21.1245 6.11176C19.801 6.3471 18.5692 6.94644 17.5672 7.8426L17.4939 7.90371L17.4328 7.8426C16.43 6.94768 15.1983 6.34916 13.8751 6.11387C12.5519 5.87858 11.1893 6.0158 9.93953 6.5102C8.62563 6.97752 7.47003 7.80565 6.60522 8.89963C5.74041 9.99362 5.20139 11.3092 5.04999 12.6955C4.70772 15.7025 5.84454 21.8878 17.3594 29.5033C17.4119 29.5138 17.4659 29.5138 17.5183 29.5033C17.5747 29.5155 17.6331 29.5155 17.6895 29.5033C29.1554 21.8878 30.2922 15.7025 29.95 12.6955ZM26.8084 17.585C25.2438 20.6776 22.1023 23.8681 17.4694 27.0707C12.9099 23.9047 9.68283 20.641 8.14263 17.585C6.60242 14.529 7.03026 12.4021 7.6659 11.1675C8.07458 10.3765 8.66795 9.6958 9.3957 9.18297C10.1234 8.67014 10.9641 8.34034 11.8465 8.22153C12.1232 8.20378 12.4009 8.20378 12.6777 8.22153C13.3207 8.21137 13.9594 8.32814 14.5572 8.56516C15.155 8.80218 15.7001 9.15481 16.1615 9.60283L17.2616 10.7396C17.3201 10.7929 17.3964 10.8224 17.4755 10.8224C17.5547 10.8224 17.6309 10.7929 17.6895 10.7396L18.7896 9.60283C19.3545 9.04175 20.0452 8.62369 20.8042 8.38344C21.5632 8.14318 22.3687 8.08767 23.1535 8.22153C24.0236 8.34388 24.8521 8.67153 25.5705 9.17741C26.289 9.68328 26.8767 10.3529 27.2852 11.1308C27.9208 12.3654 28.3609 14.4924 26.8084 17.5483V17.585Z" />
    </svg>
  );
};

CustomLikeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomLikeIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomLikeIcon;
