import { useMemo } from "react";
import get from "lodash/get";
import DisplayMedia from "./DisplayMedia";
import DisplayLiveMedia from "./DisplayLiveMedia";
import DisplayInteractivity from "./DisplayInteractivity";
import basil from "core/basil";
import { isUser } from "utilities/access";
import {
  userSelector,
  currentWizSelector,
} from "app/state/ducks/ressources/selectors";
import { getUserSession } from "core/session";
import { useSelector } from "react-redux";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";

const DISPLAY_MODE_PIP = "pip";
const DISPLAY_MODE_SPLIT = "split";
const DISPLAY_MODE_FULL = "full";

export const AVAILABLE_DISPLAY_MODES = [
  DISPLAY_MODE_FULL,
  DISPLAY_MODE_SPLIT,
  DISPLAY_MODE_PIP,
];

export const LAYOUTS_COMPONENTS = {
  media: DisplayMedia,
  live: DisplayLiveMedia,
  interactivity: DisplayInteractivity,
};

export const useLayoutsData = (layout = "interactivity") => {
  const { user_id } = getUserSession();
  const user = useSelector((state) => userSelector(state, user_id));
  const wiz = useSelector(currentWizSelector);

  const layouts = getLayouts(wiz, user);
  const result = useMemo(() => {
    return get(layouts, `${layout}`, {});
  }, [JSON.stringify(layouts), layout]);
  return result;
};
export const useSurveyInLeaderBoard = () => {
  const interactivityLayoutData = useLayoutsData("interactivity");
  const surveysInLeaderBoard = get(interactivityLayoutData, "data.surveys", []);
  return surveysInLeaderBoard;
};
export const getLayoutsIdendifier = (layouts) => {
  let id = "";
  if (layouts.media) {
    id = id.concat(`media_${layouts.media.data}`);
  }
  if (layouts.live) {
    id = id.concat(`live_${layouts.live.data.url}`);
  }
  if (layouts.interactivity) {
    id = id.concat(
      `interactivity_${get(layouts, "interactivity.type")}_hash_${
        layouts.interactivity.data.hash
      }_type_${get(layouts, "interactivity.data.type")}_displayPoll_${get(
        layouts,
        "interactivity.displayPoll"
      )}_parentHash_${get(layouts, "interactivity.displayPoll")}`
    );
  }
  return id;
};

export const isMediaInPreview = (media, preview) => {
  if (isEmpty(preview)) return false;
  return media && preview.type === "media" && preview.payload.data === media;
};
//preview {type, payload}
export const updateLayoutsWithPreview = (layouts, preview) => {
  let result = { ...layouts };
  if (isEmpty(preview)) return layouts;
  switch (preview.type) {
    case "media":
    case "interactivity": {
      set(result, preview.type, preview.payload);
      return result;
    }

    default:
      return result;
  }
};
export const getLayouts = (wiz, user, force = false) => {
  const layouts = {};
  const { live, live_media } = wiz;

  // Live Media
  // also add live_media.test AND speaker+
  if (
    (live_media && live_media.enabled) ||
    (live_media && live_media.test_mode && isUser("speaker+", user, wiz)) ||
    force
  ) {
    layouts.live = { type: "live_media", data: live_media };
  }

  // Media
  if (live && live.visio) layouts.media = { type: "media", data: live.visio };

  // Interactivity
  // Order is important while backend has not refacto its way of handling that
  // TODO: simplify that when backend changes API
  if (
    get(live, "popup.class_name") === "Survey" ||
    get(live, "popup.class_name") === "SurveyItem"
  ) {
    layouts.interactivity = {
      type: get(live, "popup.class_name"),
      data: live.popup,
      displayPoll: get(live, "popup.class_name") === "SurveyItem" && !live.poll,
    };
  }

  if (get(live, "leaderboard.displayed"))
    layouts.interactivity = { type: "leaderboard", data: live.leaderboard };

  if (get(live, "popup.class_name") === "Quote")
    layouts.interactivity = { type: "Quote", data: live.popup };

  if (
    wiz.is_hybrid &&
    basil.get("u.hybridModeChoice") === "present" &&
    !isUser("speaker+", user, wiz) &&
    !force
  )
    delete layouts.live;
  return layouts;
};

export const getFocusedLayout = (layouts) => {
  if (getLayoutsCount(layouts) < 1) return null;
  if (getLayoutsCount(layouts) == 1) return Object.keys(layouts)[0];
  if (layouts.media) return "media";
  if (layouts.live) return "live";
  return "interactivity";
};

export const getLayoutsCount = (layouts) => {
  if (!layouts) return 0;

  return Object.keys(layouts).length;
};

export const getLayoutDisplayMode = (layouts) => {
  if (getLayoutsCount(layouts) <= 1) return DISPLAY_MODE_FULL;

  if (basil.get("p:display")) return basil.get("p:display");

  return DISPLAY_MODE_PIP;
};

export const saveLayoutMode = (layout) => {
  basil.set("p:display", layout);

  return layout;
};
