import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Button from "../reusables/Button";
import Input from "../reusables/Input";
import ResourceFactory from "orm/resources";
import { useDispatch, useSelector } from "react-redux";
import { getKeyword } from "applicationDucks/selectors";
import { resourceCreate } from "ressourcesDucks/actions";

import { makeStyles } from "@material-ui/core/styles";
import { _t } from "utilities/i18n";
import { getUrl } from "app/views/containers/Application/Routes";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "row",
    padding: "25px",
  },
  titleCreate: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    paddingLeft: "25px",
    paddingTop: "25px",
  },
}));
const ReplayCreate = () => {
  const classes = useStyles();
  const [replayLink, setReplayLink] = useState("");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const onCreate = () => {
    const newReplay = resourceFactory.create("Replay", {
      replay_url: replayLink,
      enabled: false,
      starts_at: dayjs(new Date()).toISOString(),
    });

    dispatch(
      resourceCreate(newReplay, {
        slug: ["event", keyword, "replay"],
        callback: (error) => {
          if (error) return setError(error);
          window.location.href = getUrl("otm_replay", { keyword });
        },
      })
    );
  }

  return (
    <Box>
      <Typography className={classes.titleCreate}>{_t("Create Replay")}</Typography>
      <Box className={classes.root} style={{ width: "500px", gap: "8px" }}>
        <Input value={replayLink} onChange={(e) => setReplayLink(e?.target?.value)} placeholder={_t("Replay Link")} />
        <Button style={{ height: "40px" }} onClick={onCreate}>
          {_t("Create")}
        </Button>
      </Box>
    </Box>
  )
}

export default ReplayCreate;