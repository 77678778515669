import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import isEqual from "lodash/isEqual";
import { _t } from "i18n";
import { resourceFetch } from "ressourcesDucks/actions";
import { genericResourcesSelector } from "ressourcesDucks/selectors";
import {
  getKeyword,
  getFetchResultStatusSelector,
} from "applicationDucks/selectors";
import { withUser } from "hoc/User";
import {
  ShowUserSurveyItemAnswer,
  ShowUserSurveyEndView,
} from "appComponents/Viewers";
import {
  useFormEndState,
  SurveySubmitSection,
} from "appComponents/SurveyForms";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
}));
const UserResponse = ({
  keyword,
  surveyHash,
  fetchUserAnswersStatus,
  resourceFetch,
  answers,
  survey,
  isThumbnailView,
  useOwnPaginator,
}) => {
  const classes = useStyles();
  const done = useRef(false);
  const [forceFetchAnswers, setForceFetchAnswers] = useState(false);
  const showFormEnd = useFormEndState(surveyHash);
  useEffect(() => {
    //do this once

    if (
      (!isEqual(fetchUserAnswersStatus, "pending") &&
        isEmpty(answers) &&
        //!isEmpty(userHash) &&
        !isEmpty(keyword) &&
        done.current === false) ||
      forceFetchAnswers == true
    ) {
      resourceFetch("surveyAnswer", {
        slug: [
          "event",
          keyword,
          "surveys",
          surveyHash,
          "answers",
          "me",
          //userHash,
        ],
        XHR_ID: `user_me_survey_${surveyHash}_response`,
        callback: () => {
          //done.current = true;
        },
      });
      done.current = true;
      setForceFetchAnswers(false);
      //setFetchOnce(true);
    }
  }, [
    fetchUserAnswersStatus,
    resourceFetch,
    answers,
    surveyHash,
    keyword,
    forceFetchAnswers,
    //userHash,
  ]);

  useEffect(() => {
    setForceFetchAnswers(true);
  }, [surveyHash]);

  if (
    (isEqual(fetchUserAnswersStatus, "pending") ||
      isEmpty(fetchUserAnswersStatus)) &&
    isEmpty(answers)
  ) {
    return <CircularProgress />;
  }

  if (isEqual(fetchUserAnswersStatus, "error") || isEmpty(answers))
    return <Box>{_t("unable to get your answers")}</Box>;

  const lastAnswer = last(answers);
  //create a ShowUserSurveyEndView component that listen to EVENT_SHOW_FORM_ENDING event
  const result = showFormEnd ? (
    <ShowUserSurveyEndView
      survey={survey}
      answer={lastAnswer}
      isThumbnailView={isThumbnailView}
      useOwnPaginator={useOwnPaginator}
    />
  ) : (
    <ShowUserSurveyItemAnswer
      survey={survey}
      answer={lastAnswer}
      isThumbnailView={isThumbnailView}
      useOwnPaginator={useOwnPaginator}
    />
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      flex={1}
      width="100%"
      className={clsx("UserResponse", classes.root)}
    >
      {result}
    </Box>
  );
};
UserResponse.propTypes = {
  resourceFetch: PropTypes.func,
  surveyHash: PropTypes.string,
  keyword: PropTypes.string,
  fetchUserAnswersStatus: PropTypes.string,
  userHash: PropTypes.string,
  answers: PropTypes.array, //[surveyAnswer]
  surveyItem: PropTypes.object, //surveyItem
  userId: PropTypes.string,
  survey: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  useOwnPaginator: PropTypes.bool,
};

export const filterFunction = (userHash, token, survey_hash) => {
  const anonymousSurveyFilter = (surveyAnswer) =>
    surveyAnswer.is_anonymous &&
    surveyAnswer.token === token &&
    surveyAnswer.survey_hash === survey_hash;
  const userSurveyFilter = (surveyAnswer) =>
    surveyAnswer.user &&
    surveyAnswer.user.id === userHash &&
    surveyAnswer.survey_hash === survey_hash;
  return userHash == null ? anonymousSurveyFilter : userSurveyFilter;
};
const mapStateToProps = (state, ownProps) => {
  const userHash = get(ownProps, "user.hash", null);
  const userId = get(ownProps, "user.id", null);
  const token = get(ownProps, "session.token", null);
  const surveyHash = get(ownProps, "survey.hash", null);

  return {
    keyword: getKeyword(state),
    surveyHash,
    //bug here whith the answer that don't change

    answers: genericResourcesSelector(state, {
      type: "SurveyAnswer",
      filter: filterFunction(userHash, token, surveyHash),
      order: ["asc"],
      sort: ["answered_at"],
    }),
    //bug here to value doesnt change
    //`user_me_survey_${surveyHash}_answers`
    fetchUserAnswersStatus: getFetchResultStatusSelector(
      state,
      `user_me_survey_${surveyHash}_response`
    ),
    userHash,
    userId,
    //items : genericResourcesSelector(state, {type: "Item", filter: (item)=>isEqual})
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resourceFetch: bindActionCreators(resourceFetch, dispatch),
  };
};

export default compose(
  withUser,
  connect(mapStateToProps, mapDispatchToProps)
)(UserResponse);
