import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "22px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    background: props => props.color,
    borderRadius: "99px",
  },
  callTypo: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "100%",
    textAlign: "center",
    color: props => props.status === "ended" ? theme.palette.colors.grey700 : theme.palette.colors.white,
    textTransform: "uppercase",
  },
}));

const BadgeLive = ({ status, color }) => {
  const classes = useStyles({ color, status });
  const isOngoing = status === "ongoing";
  const isEnded = status === "ended";
  return (
    <Box className={classes.root}>
      <Typography className={classes.callTypo}>
        {isOngoing && _t("In call")}
        {isEnded && _t("Call ended")}
      </Typography>
    </Box>
  );
};

export default BadgeLive;