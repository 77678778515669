import {
  locatorString,
  retrieveResource,
  setResource
} from "./index";

const getRelatedResource = (session, includedMap, locator) => {

  // if we have a resource in our store just use it
  // we mainly needs it to access Model, id, type, etc..
  try {
    const relatedResource = retrieveResource(session, locator);

    if (relatedResource)
      return relatedResource;
  } catch (error) {}

  const includedResource = includedMap[locatorString(locator)];

  // if not in store, and not included, we can do nothing
  if (!includedResource) return null;

  // otherwise, create it and put it in our store before returning it
  try {
    return setResource(session, includedResource);
  } catch (error) {
    console.warn(`setReource Error, ${locator.type} is not (yet?) supported`, locator);
  }

  return null;
}

export default getRelatedResource;
