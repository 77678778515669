import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import TextArea from "app/liveShopping/backoffice/otm/components/reusables/TextArea";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useDispatch } from "react-redux";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import Dropzone from "./Dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    height: "500px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginTop: "20px",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "5px",
  },
  field: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "30px",
  },
}));

const Wording = ({ project }) => {
  const classes = useStyles();
  const [waitingMessage, setWaitingMessage] = useState(project?.options?.interface_messages?.waiting_message || "");
  const [cguUrl, setCguUrl] = useState(project?.options?.cgu_url || "");

  const [title, setTitle] = useState(project?.options?.choice_screen?.title || _t("Our experts answer your questions"));
  const [description, setDescription] = useState(project?.options?.choice_screen?.description || _t("How do you want to reach us today?"));
  const [ctaBookAppointment, setCtaBookAppointment] = useState(project?.options?.choice_screen?.cta_appointment || _t("Schedule an appointment"));
  const [ctaLiveChat, setCtaLiveChat] = useState(project?.options?.choice_screen?.cta_chat || _t("Live chat now"));
  const [backgroundURL, setBackgroundURL] = useState(project?.options?.choice_screen?.background_url || "");
  const [joinAppointment, setJoinAppointment] = useState(project?.options?.appointment_screen?.join_appointment || _t("I join my adviser"));
  const dispatch = useDispatch();

  const options = {
    ...project.options,
    cgu_url: cguUrl,
    interface_messages: {
      waiting_message: waitingMessage,
    },
    choice_screen: {
      title: title,
      description: description,
      cta_appointment: ctaBookAppointment,
      cta_chat: ctaLiveChat,
      background_url: backgroundURL,
    },
    appointment_screen: {
      join_appointment: joinAppointment,
    },
  };

  const handleSend = () => {
    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
        }
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };




  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Interface messages")}</Typography>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Waiting message")}</Typography>
        <TextArea value={waitingMessage} onChange={(e) => setWaitingMessage(e.target.value)} />
      </Box>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("GCU url")}</Typography>
        <Input value={cguUrl} onChange={(e) => setCguUrl(e.target.value)} />
      </Box>

      <Typography className={classes.title}>{_t("Choice screen")}</Typography>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Title")}</Typography>
        <Input value={title} onChange={(e) => setTitle(e.target.value)} />
      </Box>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Description")}</Typography>
        <Input value={description} onChange={(e) => setDescription(e.target.value)} />
      </Box>


      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("CTA book appointment")}</Typography>
        <Input value={ctaBookAppointment} onChange={(e) => setCtaBookAppointment(e.target.value)} />
      </Box>


      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("CTA live chat")}</Typography>
        <Input value={ctaLiveChat} onChange={(e) => setCtaLiveChat(e.target.value)} />
      </Box>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Background URL (400x556px)")}</Typography>
        <Dropzone value={backgroundURL} setValue={setBackgroundURL} />
      </Box>

      <Typography className={classes.title}>{_t("Appointment screen")}</Typography>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("CTA join appointment (screen before the appointment)")}</Typography>
        <Input value={joinAppointment} onChange={(e) => setJoinAppointment(e.target.value)} />
      </Box>

      <Button onClick={handleSend} clsName={classes.buttonSave}>{_t("Save")}</Button>
    </Box>
  );
};

export default Wording;
