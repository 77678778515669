import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Live from "app/liveShopping/frontoffice/otm/components/Player/Elements/Live";
import Info from "app/liveShopping/frontoffice/otm/components/Player/Elements/Info";
import Viewers from "app/liveShopping/frontoffice/otm/components/Player/Elements/Viewers";
import Cart from "app/liveShopping/frontoffice/otm/components/Player/Elements/Cart";
import Volume from "app/liveShopping/frontoffice/otm/components/Player/Elements/Volume";
import Catalog from "app/liveShopping/frontoffice/otm/components/Player/Elements/catalog/Catalog";
import Fullscreen from "app/liveShopping/frontoffice/otm/components/Player/Elements/Fullscreen";
import Share from "app/liveShopping/frontoffice/otm/components/Player/Elements/Share";
import { useIsInfoOpen } from "app/liveShopping/frontoffice/otm/hooks/useIsInfoOpen";
import { useSelector } from "react-redux";
import FloatingHearts from "app/liveShopping/frontoffice/otm/components/FloatingHearts/FloatingHearts";
import { desktopLayoutSelector } from "liveShoppingDucks/selectors";
import { focusedProductsSelector } from "ressourcesDucks/selectors";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import ReplayChaptersButton from "app/liveShopping/frontoffice/otm/components/Player/Elements/ReplayChaptersButton";
import ShareModal from "app/liveShopping/frontoffice/otm/components/LandingLiveShopping/sections/ShareModal";
import emitter from "utilities/emitter";
import { REPLAY_TIMESTAMP_UPDATE, SHARE_MODAL } from "utilities/emitter/events";
import { focusedProductsUtility } from "app/liveShopping/utils";
import ProgressBarTime from "app/liveShopping/frontoffice/otm/components/Player/Elements/ProgressBarTime";
import { allProductsSelector, isIFrameSelector, isPreliveSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { _t } from "i18n";
import Quit from "app/liveShopping/frontoffice/otm/components/Player/Elements/Quit";
import { emit } from "utilities/liveshopping-sdk";
import Prelive from "app/liveShopping/frontoffice/otm/components/Prelive/Prelive";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "0px",
    position: "absolute",
    zIndex: 1,
    top: 0,
    marginTop: (props) => (props.isLive ? "10px" : "0px"),
  },
  videoInfos: {
    marginTop: "10px",
    width: "100%",
    height: "32px",
    display: (props) => (props.isPrelive ? "none" : "flex"),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: (props) => (props.desktopLayout ? "0" : "10px"),
    paddingLeft: "18px",
    zIndex: 1,
    alignItems: "center",
  },
  appElements: {
    position: "absolute",
    right: "5px",
    height: (props) => (props.desktopLayout ? "calc(100vh - 30px)" : "100%"),
    marginTop: (props) => (props.isLive ? "10px" : "5px"),
    width: 40,
    top: 0,
    zIndex: 1,
  },
  hearts: {
    position: "absolute",
    right: "5px",
    bottom: (props) => (props.hasProducts ? 120 : 0),
    display: (props) => (props.fullScreen ? "none" : "block"),
  },
  toHide: {
    display: (props) => props.fullScreen || (props.isPrelive ? "none" : "block"),
  },
}));

const Elements = ({ openProducts, setOpenProducts }) => {
  const desktopLayout = useSelector(desktopLayoutSelector);
  const fullScreen = useSelector((state) => state.liveShopping.fullScreen);
  const focusedProductsLive = useSelector(focusedProductsSelector);
  const isInfoOpen = useIsInfoOpen();
  const wiz = useSelector(currentWizSelector);
  const { live_media } = wiz;
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const products = useSelector(allProductsSelector);
  const isPrelive = useSelector(isPreliveSelector);
  const updateTimestamp = (seconds) => {
    setCurrentTimestamp(seconds);
  };

  useEffect(() => {
    emitter.on(REPLAY_TIMESTAMP_UPDATE, updateTimestamp);
    return () => emitter.off(REPLAY_TIMESTAMP_UPDATE, updateTimestamp);
  }, []);

  const focusedProducts = focusedProductsUtility(wiz, products, currentTimestamp, focusedProductsLive);

  const hasProducts = focusedProducts.length > 0;
  const classes = useStyles({ desktopLayout, fullScreen, hasProducts, isLive: live_media?.enabled, isPrelive });
  const mobileLayout = useSelector(mobileLayoutSelector);
  const [modalDisplay, setModalDisplay] = useState(false);
  const isIFrame = useSelector(isIFrameSelector);

  async function handleShare() {
    if (isIFrame && mobileLayout) return emit("SHARE");

    try {
      if (mobileLayout && navigator.share) {
        await navigator.share({
          title: _t("Share this live shopping event"),
          text: _t("Share this live shopping event"),
          url: window.parent ? window.parent?.location?.href : window.location?.href,
        });
      } else {
        setModalDisplay(true);
      }
    } catch (error) {
      console.error("Something went wrong sharing the data, need another way", error);
    }
  }

  useEffect(() => {
    if (!isIFrame) return;
    const share = () => setModalDisplay(true);
    emitter.on(SHARE_MODAL, share);
    return () => emitter.off(SHARE_MODAL, share);
  }, []);

  return (
    <Box className={classes.root}>
      {isPrelive && <Prelive />}
      {wiz.isLive() && <ProgressBarTime />}
      <Box className={classes.videoInfos}>
        <Box display="flex" alignItems="center" sx={{ position: "absolute", left: "0px", top: "0px" }}>
          <Info {...isInfoOpen} />
          <Live {...isInfoOpen} />

          {wiz.isLive() && !wiz?.extra_settings.hide_count && <Viewers {...isInfoOpen} />}
        </Box>

        {wiz.status === "replay" && !isInfoOpen.isInfoOpen && <ReplayChaptersButton {...isInfoOpen} />}
      </Box>
      <Box className={classes.appElements}>
        {isIFrame && !["pre_live", "ready_for_live"].includes(wiz.status) && <Quit />}

        <Box className={classes.toHide}>
          {wiz.cart_integration && !wiz?.extra_settings.hide_cart && <Cart />}
          {mobileLayout && <Fullscreen />}
          <Volume />
          <Share onClick={handleShare} />
          <ShareModal display={modalDisplay} setDisplay={setModalDisplay} />
          <Catalog setOpenProducts={setOpenProducts} openProducts={openProducts} />
        </Box>
        {desktopLayout && (
          <Box className={classes.hearts}>
            <FloatingHearts />
          </Box>
        )}
      </Box>
      <ShareModal display={modalDisplay} setDisplay={setModalDisplay} />
    </Box>
  );
};

Elements.propTypes = {
  screenType: PropTypes.string,
  desktopLayout: PropTypes.bool,
  hasProducts: PropTypes.bool,
};

export default Elements;
