import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      maxWidth: (props) => props.maxWidth,
      paddingLeft: ({ paddingLeft }) => paddingLeft,
      paddingRight: ({ paddingRight }) => paddingRight,
      //paddingTop: "16px",
      paddingTop: ({ paddingTop }) => paddingTop,
      paddingBottom: ({ paddingBottom }) => paddingBottom,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
    },
    divider: {
      backgroundColor: (props) => (props.color ? theme.palette.colors[props.color] : theme.palette.colors.greyDark),
      //width: "calc(100% - 30px)",
      width: "calc(100%)",
    },
  }),
  {
    name: "OnDivider",
  }
);
//controlled checkBox
const Separator = ({ color, maxWidth, paddingLeft, paddingRight, paddingBottom, paddingTop, noPadding }) => {
  const classes = useStyles({
    maxWidth,
    color,
    paddingLeft,
    paddingRight,
    paddingBottom,
    paddingTop,
    noPadding,
  });
  return (
    <Box className={classes.root}>
      <Divider classes={{ root: classes.divider }} />
    </Box>
  );
};
Separator.propTypes = {
  maxWidth: PropTypes.string,
  color: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  noPadding: PropTypes.bool,
};
Separator.defaultProps = {
  maxWidth: "100%",
  color: "greyMedium",
  noPadding: false,
  paddingLeft: "15px",
  paddingRight: "15px",
  paddingBottom: "15px",
  paddingTop: "15px",
};
export default Separator;
