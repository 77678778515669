import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import { Box, Typography, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { formatPrice } from "app/liveShopping/utils";
import Card from "app/liveShopping/backoffice/oto/Container/ProjectDashboard/Card";
import { Block, Flex, Metric, Text } from "@tremor/react";
import { BagCheckFill, BagFill, BagPlusFill, Cart4, ChatDotsFill, CreditCard, CreditCardFill, PeopleFill } from "react-bootstrap-icons";
import PersonFill from "react-bootstrap-icons/dist/icons/person-fill";
import Icon from "app/liveShopping/backoffice/oto/Container/ProjectDashboard/Icon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  stats: {
    display: "flex",
    gap: "8px",
    flexWrap: "wrap",
    maxWidth: "100%",
    textTransform: "capitalize",
    height: "480px",
    overflowY: "auto",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    width: "auto",
    height: "32px",
    background: theme.palette.colors.primary,
    borderRadius: "99px",
    color: "#FFF",
  },
  title: {
    textAlign: "left",
    paddingTop: "20px",
    paddingBottom: "10px",
    fontWeight: "bold",
  },
}));

const AllStats = ({ cartsCreated, sum, finalSum, uniqueVisitors, chatMessages, wentToPay, paid, addedInCart, registeredUsers, visitedUsers }) => {
  const classes = useStyles();
  const theme = useTheme();
  const viewers = useSelector((state) => state.application.participants_count);

  const stats = [
    { text: _t("carts created"), value: cartsCreated, icon: BagPlusFill },
    { text: _t("live users"), value: viewers, icon: PeopleFill },
    { text: _t("in cart"), value: formatPrice(sum, "EUR"), icon: BagFill },
    { text: _t("paid"), value: formatPrice(finalSum, "EUR"), icon: BagCheckFill },
    { text: _t("Unique visitors"), value: uniqueVisitors, icon: PersonFill },
    { text: _t("Chat messages"), value: chatMessages, icon: ChatDotsFill },
    // { text: _t("Registered users"), value: registeredUsers },
    { text: _t("Visited users"), value: visitedUsers, icon: PeopleFill },
    { text: _t("Products added in cart"), value: addedInCart, icon: Cart4 },
    { text: _t("Went to pay"), value: wentToPay, icon: CreditCard },
    { text: _t("Paid"), value: paid, icon: CreditCardFill },
  ];
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("All Statistics")}</Typography>
      <Box className={classes.stats}>
        {stats.map(({ text, value, icon }) => (
          <Card maxWidth="20rem">
            <Flex justifyContent="justify-start" spaceX="space-x-4">
              <Icon
                icon={icon}
                size={"1.5rem"}
                color={theme.palette.colors.primary}
              />
              <Block truncate={true}>
                <Text>{text}</Text>
                <Metric>{value}</Metric>
              </Block>
            </Flex>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

AllStats.propTypes = {};

AllStats.defaultProps = {};

export default AllStats;
