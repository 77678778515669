import React, { useMemo } from "react";
import PropTypes from "prop-types";

import ActionsComponent from "../../uikit/component/Actions/Actions";

import { useIsUser } from "../../userHooks";
import { useIsLiveQuote, useQuoteEditManager, useQuoteDeleteManager, useQuoteManager, useQuote, useToggleReaction } from "app/liveShopping/backoffice/otm/ChatModo/hooks";
import { currentWizSelector } from "ressourcesDucks/selectors";
import { useSelector } from "react-redux";

const QuoteActions = ({ isHovered, hash }) => {
  const quote = useQuote(hash);
  const isLive = useIsLiveQuote(quote);

  const handleEditQuote = useQuoteEditManager(quote);
  const handleDeleteQuote = useQuoteDeleteManager(quote);
  const handleManageQuote = useQuoteManager(quote);
  const handleToggleReaction = useToggleReaction(quote);

  const event = useSelector(currentWizSelector);
  const { processed, pinned, favorite } = quote;

  const isAtLeastSpeaker = useIsUser("speaker+");
  const isAtLeastAdmin = useIsUser("admin+");

  const enabledActions = useMemo(() => {
    //participant
    let actions = [
      // {
      //   key: "reply",
      //   onClick: () => {}, // emitter
      // },
    ];

    event.reactions &&
      event.reactions.length > 0 &&
      actions.push({
        key: "reaction",
        onClick: (reaction) => handleToggleReaction(reaction),
      });

    if (isAtLeastSpeaker) {
      if (isAtLeastAdmin) {
        actions.push(
          {
            key: "processed",
            enabled: quote.processed,
            onClick: () => handleEditQuote({ processed: !quote.processed }),
          },
          {
            key: "pinned",
            enabled: quote.pinned,
            onClick: () => handleEditQuote({ pinned: !quote.pinned }),
          },
          {
            key: "delete",
            onClick: () => handleDeleteQuote(),
          }
        );

        // first place
        actions.splice(0, 0, {
          key: "projected",
          enabled: isLive,
          onClick: () => handleManageQuote(isLive ? "hide" : "display"),
        });
      }

      // second place
      actions.splice(1, 0, {
        key: "favorite",
        enabled: quote.favorite,
        onClick: () => handleEditQuote({ favorite: !quote.favorite }),
      });
    }

    return actions;
  }, [processed, pinned, favorite, isAtLeastSpeaker, isAtLeastAdmin, isLive, event.live]);

  const params = {
    enabledActions,
    placement: quote.is_mine ? "bottom-start" : "bottom-end",
    reactions: event.reactions ? event.reactions : "",
    selectedReaction: quote?.user_reaction,
  };

  return enabledActions.length > 0 && <ActionsComponent {...params} isHovered={isHovered} />;
};

QuoteActions.propTypes = {
  hash: PropTypes.string,
  isHovered: PropTypes.bool,
};

export default QuoteActions;
