import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import {
  TextSurveyItemResult,
  HtmlSurveyItemResult,
  GridSurveyItemResult,
  RatingSurveyItemResult,
  UniqueSurveyItemResult,
  RankingSurveyItemResult,
  MultipleSurveyItemResult,
  ImageSurveyItemResult,
  SurveyItemTitle,
} from "appComponents/Viewers";
import { SurveySubmitSection } from "appComponents/SurveyForms";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formFieldRowContent: {
    display: "flex",
    width: "100%",
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "auto",
      paddingLeft: "25px",
      paddingRight: "5%",
      // borderBottomLeftRadius: "0",
      // borderBottomRightRadius: "0",
    },
  },
  formFieldRow: {
    //height: "100%",
    //actionPanel height theme.spacing(4.75)
    flexDirection: "column",
    minHeight: `calc(100% - ${theme.spacing(17.5)}px ) `,
    //maxHeight: `calc(100% - ${theme.spacing(20.5)}px)`,
    //height: "auto",
    width: "100%",
    paddingTop: 20,
    [theme.breakpoints.down("xs")]: {
      flex: "auto",
    },
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
  },
  paginationScreen: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    // alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
    overflow: "auto",
    overflowX: "hidden",
  },
  hidden: {
    display: "none",
  },
}));

const ShowSurveyItemAnswersSummary = ({
  surveyItem,
  survey,
  isThumbnailView,
  layoutMode,
  showSubmitSection,
}) => {
  const classes = useStyles({ isThumbnailView });
  let result;
  //options array has objects with label and hash
  if (isEmpty(surveyItem)) {
    return <Box>{_t("unable to get item")}</Box>;
  }
  const itemObject = get(surveyItem, "ref", surveyItem);
  switch (surveyItem.type) {
    case "unique":
      result = <UniqueSurveyItemResult item={itemObject} survey={survey} />;
      break;
    case "grid":
      result = <GridSurveyItemResult item={itemObject} />;
      break;
    case "rating":
      result = <RatingSurveyItemResult item={itemObject} showDetails={true} />;
      break;
    case "multiple":
      result = <MultipleSurveyItemResult item={itemObject} survey={survey} />;
      break;
    case "html":
      result = <HtmlSurveyItemResult item={itemObject} />;
      break;
    case "ranking":
      result = <RankingSurveyItemResult item={itemObject} />;
      break;
    case "image":
      result = <ImageSurveyItemResult item={itemObject} />;
      break;
    case "text":
    default:
      result = <TextSurveyItemResult item={itemObject} />;
  }

  return (
    <Box className={clsx(classes.paginationScreen)}>
      <SurveyItemTitle
        survey={survey}
        item={surveyItem}
        isThumbnailView={isThumbnailView}
        layoutMode={layoutMode}
        forResult={true}
      />
      {!isThumbnailView && (
        <Box className={classes.formFieldRow}>
          <Box className={classes.formFieldRowContent}>{result}</Box>
          {showSubmitSection && (
            <SurveySubmitSection
              isThumbnailView={isThumbnailView}
              surveyHash={survey.hash}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

ShowSurveyItemAnswersSummary.propTypes = {
  surveyItem: PropTypes.object,
  survey: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
  showSubmitSection: PropTypes.bool,
};
ShowSurveyItemAnswersSummary.defaultProps = {
  surveyItem: {},
  survey: {},
  isThumbnailView: false,
  layoutMode: "full",
  showSubmitSection: false,
};

export default ShowSurveyItemAnswersSummary;
