import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { _t } from "utilities/i18n";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import dayjs from "dayjs";
import DatePicker from "app/liveShopping/backoffice/otm/components/reusables/DatePicker/DatePicker";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "10px",
  },
}));
const EndDateComponent = ({ setEndAtAbsolute, setEndAtRelative, endAtAbsolute, endAtRelative, calendar, setSelectedOption, selectedOption }) => {
  const classes = useStyles();


  if (!calendar) null;

  // set selected option to the one that is already set in the calendar
  useEffect(() => {
    if (calendar.end_at_absolute && calendar.end_at_relative) {
      setSelectedOption("end_at_absolute");
      return;
    }

    if (calendar.end_at_absolute) {
      setSelectedOption("end_at_absolute");
      return;
    }
    if (calendar.end_at_relative) {
      setSelectedOption("end_at_relative");
      return;
    }
  }, [calendar.end_at_absolute, calendar.end_at_relative]);


  const onValueChange = (e) => {
    setSelectedOption(e.target.value)
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.text}>{_t("Invitees can schedule...")}</Typography>
      <Box>
        <Box>
          <span style={{ display: "flex", marginBottom: "5px" }}>
            <input
              checked={selectedOption === "end_at_relative"}
              type="radio" name={"end_at_relative"}
              value="end_at_relative"
              onChange={onValueChange} />
            <Typography>{_t("calendar days into the future")}</Typography>
          </span>

          {selectedOption === "end_at_relative" && (
            <>
              <Input type="number" value={endAtRelative} onChange={(e) => setEndAtRelative(e.target.value)} />
            </>)
          }
        </Box>
        <Box>
          <span style={{ display: "flex", marginBottom: "5px" }}>
            <input
              checked={selectedOption === "end_at_absolute"}
              type="radio"
              name={"end_at_absolute"}
              value="end_at_absolute"
              onChange={onValueChange} />
            <Typography>{_t("until a specific date")}</Typography>
          </span>

          {selectedOption === "end_at_absolute" && <DatePicker disableHours disableDate inputOnChange={(value) => { setEndAtAbsolute(dayjs(value).format("YYYY-MM-DD")) }} date={endAtAbsolute} />}
        </Box>
      </Box>
    </Box>
  );
}
export default EndDateComponent;