import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import TopBarContainer from "app/liveShopping/backoffice/otm/containers/TopBar/TopBarContainer";
import AppLoader from "app/liveShopping/frontoffice/otm/components/AppLoader";
import { useDispatch, useSelector } from "react-redux";
import { desktopLayoutSelector, displayTabBarSelector } from "app/state/ducks/liveShopping/selectors";
import { useViewInnerHeigh } from "app/liveShopping/frontoffice/otm/hooks/useViewInnerHeight";
import URI from "urijs";
import { useMediaPredicate } from "react-media-hook";
import { MEDIA_QUERIES } from "utilities/mediaQuery";
import { setLayoutType } from "app/state/ducks/liveShopping/actions";
import TabBarContainer from "app/liveShopping/backoffice/otm/containers/TabBar/TabBarContainer";
import Alert from "app/liveShopping/backoffice/otm/components/reusables/Alert/Alert";
import { isUser } from "utilities/access";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    background: "white",
  },
  content: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
}));

const Layout = ({ loading, children }) => {
  const classes = useStyles();
  const isDesktop = useSelector(desktopLayoutSelector);
  const [isMobile, setIsMobile] = useState(true);
  const mobile = useMediaPredicate(MEDIA_QUERIES.MOBILE);
  const displayTabBar = useSelector(displayTabBarSelector);
  const dispatch = useDispatch();
  useViewInnerHeigh();
  // setIsMobile once on boot
  useEffect(() => {
    // can override mobile/desktop layout by url
    // usefull when opened in PIP in destkop, do not load mobile layout
    const { layout } = new URI(window.location).search(true);

    if (layout) {
      setIsMobile(layout === "mobile");
      return;
    }

    setIsMobile(mobile);
    // setThemeName("back");
  }, []);


  useEffect(() => {
    dispatch(setLayoutType("desktop"));
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  return (
    <Box className={classes.root}>
      <Alert />
      {loading && <AppLoader />}
      {!loading && (
        <>
          {isDesktop && isUser("user+") && <TopBarContainer />}
          {isDesktop && isUser("user+") ? (
            <Box className={classes.content}>{children}</Box>
          ) : (
            <Box style={{ display: "flex", flexDirection: "column", height: "100%", position: "relative", overflow: "auto", flex: "1" }}>
              <Box className={classes.content}>{children}</Box>
              {displayTabBar && <TabBarContainer />}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

Layout.propTypes = {};

Layout.defaultProps = {};

export default Layout;
