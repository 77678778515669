import React from "react";
import { Formik, Form as FormikForm } from "formik";
import PropTypes from "prop-types";
import clsx from "clsx";

export const Form = ({ children, classes, ...rest }) => {
  return (
    <Formik {...rest}>
      <FormikForm className={clsx("needs-validation", classes)}>
        {children}
      </FormikForm>
    </Formik>
  );
};

Form.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};

Form.defaultProps = {
  classes: "",
};

export default Form;
