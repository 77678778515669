import { select } from "redux-saga/effects";
import { getOwnScreenSharingStream, getOwnVideoStream, sessionSelector } from "../selectors";

export function* mute({ stream, mode, status }) {
  const session = yield select(sessionSelector);
  const action = mode === "audio" ? "toggleAudio" : "toggleVideo";

  session.signal({
    type: "msg",
    data: JSON.stringify({
      action,
      streamId: stream.streamId,
      value: status,
    }),
  });
}

export function* eject({ stream }) {
  const session = yield select(sessionSelector);

  session.signal({
    type: "msg",
    data: JSON.stringify({
      action: "leaveStage",
      streamId: stream.streamId,
    }),
  });
}
