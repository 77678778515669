import { Parser, Transformer } from "core/jsonApi";

import sendRequest from "./sendRequest";

import { APP_ID, BASIL_COOKIES_DOMAIN } from "settings/config";

import { getUserSession } from "core/session";

const ACCEPT_HEADERS = "application/vnd.api+json";
const CONTENT_TYPE = "application/vnd.api+json";
export const DEFAULT_HEADERS = {
  Accept: ACCEPT_HEADERS,
  "Content-Type": CONTENT_TYPE,
  "Wisembly-App-Id": APP_ID,
};
import set from "lodash/set";
import basil from "core/basil";
import URI from "urijs";

/*
 *
 * makeGetRequest
 * Sends the GET request
 * Then parses the result
 *
 */
function makeGetRequest(url, options) {
  // https://github.com/github/fetch/issues/402
  // explicitely set body to undefined for GET requests otherwise FF error :(
  options.body = undefined;

  return sendRequest(url, options);
}

const sendRequestWithBody = (url, options) => {
  const preparedData =
    options.transform !== false
      ? Transformer.transform(options.body)
      : options.body;

  if (options.meta) {
    preparedData.meta = options.meta;
  }
  if (options.inject) {
    if (Array.isArray(options.inject)) {
      options.inject.forEach((inj) => set(preparedData, inj.path, inj.value));
    } else {
      set(preparedData, options.inject.path, options.inject.value);
    }
  }
  const newOptions = Object.assign(options, {
    body: JSON.stringify(preparedData),
  });

  return sendRequest(url, newOptions);
};
/*
 *
 * makePostOrPatchRequest
 * Prepares the data with JSON API Format
 * Sends the POST|PATCH request
 * Then parses the result if not null
 *
 */
function makePostOrPatchRequest(url, options) {
  return sendRequestWithBody(url, options);
}

/*
 *
 * makeDeleteRequest
 * Prepares the data with JSON API Format
 * We call parse here, even if empty response, because we could catch json.error or json.errors
 *
 */
function makeDeleteRequest(url, options) {
  if (options.batch) {
    return sendRequestWithBody(url, options);
  }
  return sendRequest(url, options);
}

/*
 *
 * makeRequest
 * Will dispatch the arguments to the correct request handler
 * @param url <String>
 * @param options <Object: method, headers, body>
 *
 */
export default async function makeRequest(url, body, options) {
  // Merge options with the default method and other stuff
  // send cookies with the request
  // https://github.com/github/fetch#sending-cookies
  options = Object.assign({}, { method: "GET" }, options);

  // special assign of headers object
  options.headers = Object.assign({}, DEFAULT_HEADERS, options.headers);

  const session = getUserSession();

  // @DOC
  // inject user token to every call headers if it exists or not explicitely removed with tokenless option
  if (session.token && options.tokenless !== true) {
    options.headers = { "Wisembly-Token": session.token, ...options.headers };
  }

  const window_location = new URI(window.location);

  // Fix orga in query argument
  const organization_query = window_location.search(true).organization;
  if (organization_query) {
    options.headers = {
      "Wisembly-Organization": organization_query,
      ...options.headers,
    };
  }

  const organization_domain = window_location.subdomain();
  const wisemblyDomains = ["app", "www", "prp", "next", "next-prp"];

  if (
    organization_domain !== "" &&
    wisemblyDomains.indexOf(organization_domain) === -1
  ) {
    // This domain belongs to an organization
    options.headers = {
      "Wisembly-Organization": organization_domain,
      ...options.headers,
    };
  }

  const keyword = window.location.pathname.slice(1);

  if (basil.get(`${keyword}.password`)) {
    options.headers = {
      "Wisembly-Password": basil.get(`${keyword}.password`),
      ...options.headers,
    };
  }

  // inject body into options if not already given in options
  // bit of a hack for DELETE batch body requests ;) (see makeDeleteRequest)
  options.body = options.body || body;

  let json;

  switch (options.method) {
    case "GET":
      json = await makeGetRequest(url, options);
      break;
    case "POST":
      json = await makePostOrPatchRequest(url, {
        ...options,
        body: options.body,
      });
      break;
    case "PATCH":
      json = await makePostOrPatchRequest(url, {
        ...options,
        body: options.body,
      });
      break;
    case "DELETE":
      json = await makeDeleteRequest(url, options);
      break;
    default:
      throw Error(
        "makeRequest: request method doesn't match GET|POST|PATCH|DELETE"
      );
  }

  // do not try to parse if we want raw response (application/json call for example)
  if (options.raw) {
    return json;
  }
  //parse differently for batch
  if (options.batch) {
    return json !== null ? Parser.parseBatchData(json) : null;
  }
  return json !== null ? Parser.parse(json) : null;
}
