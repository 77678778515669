import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useDispatch } from "react-redux";
import { startVisio } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/actions";
import CameraVideo from "react-bootstrap-icons/dist/icons/camera-video";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.colors.grey600,
  },
  button: {
    background: theme.palette.colors.success,
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "12px 24px",
    height: "40px",
  },
  typoButton: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
  },
  typoInfo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.grey100,
  }
}));

const Call = ({ visitor }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isRinging = visitor?.status === "ringing";

  const handleTakeCall = () => {
    if (isRinging) {
      dispatch(startVisio());
      return;
    }
    dispatch(startVisio());
  };

  const is5MinutesBeforeAppointment = (scheduled_at) => {
    if (!scheduled_at) return true;
    return dayjs(scheduled_at).diff(dayjs(), "minute") <= 5;
  }

  return (
    <Box className={classes.root}>
      {
        (visitor.status === "waiting") ? (
          <Button clsName={classes.button} onClick={handleTakeCall}>
            <CameraVideo size={16} />
            <Typography className={classes.typoButton}>{_t("Start the call")}</Typography>
          </Button>

        ) : (
          <Typography className={classes.typoInfo}>{_t("You can call the visitor when he arrived")}</Typography>
        )}

    </Box>
  )
};

export default Call;