import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class Visitor extends BaseModel {
  constructor(args) {
    super(args);
    this.Model = Visitor;
  }

  setQueue(Queue) {
    this.queue = Queue.id;
  }

  setVendor(User) {
    this.vendor = User.id
  }
}

Visitor.modelName = "Visitor";
Visitor.backendName = "visitor";

Visitor.fields = {
  id: attr(),
  hash: attr(),
  created_at: attr(),
  handled_at: attr(),
  status: attr(),
  referer: attr(),
  options: attr(),
  cart: attr(),
  session_id: attr(),
  stats_init: attr(),
  terminated_at: attr(),
  nps_score: attr(),
  nps_comment: attr(),
  token: attr(),
  in_pip: attr(),
  scheduled_at: attr(),
  timezone: attr(),
  queue: fk("Queue", "visitors"),
  current_page_title: attr(),
  joined_page_title: attr(),
  vendor: fk("User", "visitors"),
};

export default Visitor;
