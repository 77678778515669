/* eslint-disable prettier/prettier */
import { Box, makeStyles, Modal, useMediaQuery } from "@material-ui/core";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _t } from "utilities/i18n";
import Footer from "./Footer";
import dayjs from "dayjs";
import Bubble from "./Bubble";
import { isUser } from "utilities/access";
import CustomMessagingImage from "app/liveShopping/frontoffice/oto/assets/images/CustomMessagingImage";
import clsx from "clsx";
import { Howl, Howler } from "howler";
import NotifSound from "app/liveShopping/frontoffice/oto/assets/ring/notificationsoundliveboutique.mp3"
import OnBoard from "./OnBoard";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import { Fade } from "react-reveal";
import Appointment from "app/liveShopping/frontoffice/oto/components/Appointment";
import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.colors.white,
    width: "100%",
    height: props => props.size === "s" ? "calc(100% - 36px)" : !props.isWidget ? "100%" : "50%",
    bottom: props => (props.isWaiting && !props.isAdmin) && 0,
    borderRaidus: props => props.isAdmin && "8px",
    position: "relative",
    flex: "1"
  },
  chat: {
    width: "100%",
    height: "100%",
    padding: " 10px 20px",
    overflowY: "auto",
    scrollbarWidth: "none",
    flex: 1,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    position: "relative",

  },
  empty: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  isOngoingChat: {
    display: "flex !important",
  },
  isFullscreenChat: {
    width: "450px !important",
  },
  isPipChat: {
    display: "none !important",
  },
  propulsedText: {
    textAlign: "center",
    fontSize: "12px",
    textDecoration: "none",
    color: theme.palette.colors.grey500,
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  onlineMessage: {
    textAlign: "center",
    fontSize: "12px",
    textDecoration: "none",
    backgroundColor: theme.palette.colors.warning,
    color: theme.palette.colors.grey600,
    width: "100%",
    position: "absolute",
    padding: "5px",
    bottom: "35px",
    zIndex: 1,
  },
  modal: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    width: "95%",
    height: "95%",
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
}));

let initialLoad = true;


const Chat = ({ disabled, size = null, visitor = {}, messageOperator }) => {
  const isAdmin = isUser("user+");
  const currentVisitor = useSelector(currentVisitorSelector) || visitor;
  const isPip = useMediaQuery("(max-height: 90px)");
  const isWaiting = currentVisitor?.status === "waiting";
  const isOngoing = currentVisitor?.status === "ongoing";
  const isRinging = currentVisitor?.status === "ringing";
  const [open, setOpen] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [contactSent, setContactSent] = useState(false);
  const [messages, setMessages] = useState(currentVisitor?.conversation || [])
  const isWidget = useMediaQuery("@media (max-width:400px)");
  const isMobile = useMediaQuery("(min-width: 340px)");
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const classes = useStyles({ isAdmin, isPip, isWaiting, isWidget, isRinging, isMobile, size });
  const dispatch = useDispatch();
  // to humanize date
  var duration = require("dayjs/plugin/duration")
  dayjs.extend(duration)
  var relativeTime = require("dayjs/plugin/relativeTime")
  dayjs.extend(relativeTime)

  const handleSchedule = () => setOpen(true);
  const handleEmail = () => setOpenEmail(true);

  useEffect(() => {
    const chat = document.getElementById("LiveboutiqueChat");
    chat.scrollTop = chat.scrollHeight;
    // scroll to bottom
  }, [messages]);


  useEffect(() => {
    if (initialLoad) {
      initialLoad = false;
      return;
    }

    if (isAdmin) return;
    if (document.querySelector("#inputChat").value.length) return;
    if (messages[messages.length - 1]?.user === "visitor") return;

    const sound = new Howl({
      src: [NotifSound],
    });
    Howler.volume(1.0);
    sound.play();
    return () => sound.stop();
  }, [messages.length]);

  const SendMessage = (message) => {

    const newMessage = resourceFactory.create("Custom", { message: message, user: "vendor" });

    dispatch(resourceEdit(newMessage, {
      slug: ["visitors", currentVisitor.hash, currentVisitor.token, "message"],
      method: "POST",
      callback: (error) => console.log(error)
    }))
  };


  // send automatics messages for cta
  useEffect(() => {
    if (messages.length !== 0) return;

    setTimeout(() => {
      SendMessage(_t("leave_email"));
    }, 1000);
    setTimeout(() => {
      SendMessage(_t("take_appointment"));
    }, 3000);
  }, []);

  // open the right modal by cta
  const handleCta = (message) => {
    if (messages[0].message === message) handleEmail();
    if (messages[1].message === message) handleSchedule()
  }


  useEffect(() => {
    if (currentVisitor?.conversation.length === 0) return;

    setMessages(currentVisitor?.conversation);
  }, [currentVisitor?.conversation]);

  return (
    <Box className={clsx({
      [classes.root]: true,
      [classes.isOngoingChat]: !isAdmin && isWidget,
      [classes.isFullscreenChat]: isOngoing && !isAdmin && !isWidget,
      [classes.isPipChat]: isPip && !isAdmin,
    })}>
      {currentVisitor.status === "ongoing" && messageOperator && <Box className={classes.onlineMessage}>{_t("conversation ended, the customer is supported")}</Box>}

      <Box className={classes.chat} id="LiveboutiqueChat">
        {(messages?.length === 0) && isAdmin || !messages && (
          <Box className={classes.empty}>
            <CustomMessagingImage />
          </Box>
        )}

        {!isAdmin && messages.length === 0 && <OnBoard />}
        {messages?.map(({ message, user, date }, index) => (
          <Box key={index} onClick={() => handleCta(message)}>
            <Bubble publishedAt={dayjs.unix(date || 0)} message={messages[1]?.message === message ? _t("Book an appointment by clicking here") : messages[0]?.message === message ? _t("Leave your email by clicking here, to be recontacted") : message} scope={user} />
          </Box>
        ))}
      </Box>
      {!isAdmin && <a href="https://liveboutique.io/" target="_blank" className={classes.propulsedText} rel="noreferrer">{_t("Propulsed by liveboutik")}</a>}

      <Footer size={size} disabled={disabled} currentVisitor={currentVisitor} />

      <Fade>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box className={classes.modal}>
            <Appointment onCancel={() => setOpen(false)} />
          </Box>
        </Modal>
      </Fade>
      <Fade>
        <Modal
          open={openEmail}
          onClose={() => setOpenEmail(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box className={classes.modal}>
            <ContactForm setOpenEmail={setOpenEmail} sent={contactSent} setSent={setContactSent} />
          </Box>
        </Modal>
      </Fade>
    </Box>
  )
};

export default Chat;
