import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import {
  getFetchResultStatusSelector,
  getKeyword,
} from "applicationDucks/selectors";
import { resourceFetch } from "ressourcesDucks/actions";
import {
  genericResourcesSelector,
  userSelector,
} from "ressourcesDucks/selectors";
import { filterFunction } from "appContainers/Viewers/UserResponse";
import { getUserSession } from "utilities/session";

export const useUserAnswerToSurvey = ({
  surveyHash,
  forceFetchAnswers,
  setForceFetchAnswers,
  canFetch = true,
}) => {
  const done = useRef(false);
  const session = getUserSession();
  const { user_id } = session;
  const token = session.token;
  const dispatch = useDispatch();
  //const [savedAnswer , setSavedAnswer] = useState([])

  const keyword = useSelector((state) => getKeyword(state), isEqual);
  const user = useSelector((state) => userSelector(state, user_id), isEqual);

  const fetchUserAnswersStatus = useSelector(
    (state) =>
      getFetchResultStatusSelector(
        state,
        `user_me_survey_${surveyHash}_answers`
      ),
    isEqual
  );
  //// TODO: this can be optimize in the future
  const answers = useSelector((state) => {
    if (isEmpty(surveyHash)) return [];
    return genericResourcesSelector(state, {
      type: "SurveyAnswer",
      filter: filterFunction(user ? user.hash : null, token, surveyHash),
      order: ["desc"],
      sort: ["answered_at"],
    });
  }, isEqual);

  useEffect(() => {
    if (
      (!isEqual(fetchUserAnswersStatus, "pending") &&
        isEmpty(answers) &&
        !isEmpty(surveyHash) &&
        !isEmpty(keyword) &&
        done.current === false &&
        canFetch) ||
      (forceFetchAnswers && !isEmpty(surveyHash))
    ) {
      //setShowSwitch(false);
      dispatch(
        resourceFetch("surveyAnswer", {
          slug: ["event", keyword, "surveys", surveyHash, "answers", "me"],
          XHR_ID: `user_me_survey_${surveyHash}_answers`,
          callback: () => {},
          silent: true,
        })
      );
      done.current = true;
      if (forceFetchAnswers) {
        setForceFetchAnswers(false);
      }
    }
  }, [
    fetchUserAnswersStatus,
    dispatch,
    answers,
    surveyHash,
    keyword,
    forceFetchAnswers,
  ]);

  return answers;
};
//stop = !isEmpty(answers)||survey.type === poll
//in any cas the watcher will fetch at least once
export const useUserAnswerToSurveyWatcher = ({ surveyHash, stop = true }) => {
  const [forceFetchAnswers, setForceFetchAnswers] = useState(false);

  useEffect(() => {
    if (!stop) {
      const interval = setInterval(() => {
        if (!stop) {
          setForceFetchAnswers(true);
        }
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [stop]);
  const answers = useUserAnswerToSurvey({
    surveyHash,
    forceFetchAnswers,
    setForceFetchAnswers,
  });
  return answers;
};
