import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { Select } from "app/views/components/Mui";
import { ButtonBase } from "@material-ui/core";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import { useDispatch, useSelector } from "react-redux";
import { accessesSelector } from "app/state/ducks/ressources/selectors";
import { resourceDelete, resourceEdit } from "app/state/ducks/ressources/actions";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "flex",
    justifyContent: "space-between",
    gap: 20,
    marginBottom: "16px",
  },
  select: {
    width: "300px",
  },
}));

const FormEdit = ({ email, scope, hashGroup, access }) => {
  const classes = useStyles();
  const [formValue, setFormValue] = useState({ email, scope });
  const accesses = useSelector(accessesSelector);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleChangeScope = (event) => {
    const { value } = event.target;
    dispatch(
      resourceEdit(
        Object.assign(access, {
          scope: value,
        }),
        {
          slug: ["groups", hashGroup, "admins"],
        }
      )
    );
  };

  const handleDelete = () => {
    const accessToDelete = accesses.find((a) => a.hash === access.hash);

    dispatch(
      resourceDelete(accessToDelete, {
        slug: ["groups", hashGroup, "admins"],
        batch: true,
        body: accessToDelete,
        callback(error, response) {
          if (error) {
            console.log(error);
          } else {
            console.log(response);
          }
        },
      })
    );
  };

  return (
    <Box className={classes.line}>
      <Input disabled style={{ width: "200px !important" }} name="email" value={email} onChange={(e) => handleChange(event)} />
      <Select
        className={classes.select}
        value={formValue.scope}
        onChange={(e) => handleChangeScope(e)}
        name="scope"
        options={[
          { value: "vendor", label: _t("Vendor") },
          { value: "admin", label: _t("Admin") },
        ]}
      />
      <ButtonBase onClick={handleDelete}>
        <Trash />
      </ButtonBase>
    </Box>
  );
};

FormEdit.propTypes = {};

FormEdit.defaultProps = {};

export default FormEdit;
