import React from "react";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import Mic from "@material-ui/icons/Mic";
import MicOff from "@material-ui/icons/MicOff";
import Videocam from "@material-ui/icons/Videocam";
import VideocamOff from "@material-ui/icons/VideocamOff";
import Close from "@material-ui/icons/Close";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import VonageSDK from "utilities/vonage";
import { _t } from "utilities/i18n";
import { isUser } from "utilities/access";
import * as Sentry from "@sentry/react";

import { resourceEdit } from "app/state/ducks/ressources/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100%",
    width: "100%",
    "& video": {
      maxHeight: "100%",
      maxWidth: "100%",
    },
    "& .OT_widget-container": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .OT_video-poster": {
      backgroundPosition: "center",
    },
  },
  controls: {
    visibility: ({ preview }) => (preview ? "visible" : "hidden"),
    opacity: ({ preview }) => (preview ? 1 : 0),
    transition: "visibility 0.3s linear, opacity 0.3s linear",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    bottom: "5%",
    left: "0",
    width: "100%",
    zIndex: 2,
    ".VisioStreamWrapper:hover &": {
      visibility: "visible",
      opacity: 1,
    },
  },
  button: {
    width: "40px",
    height: "40px",
    borderRadius: "6px",
    minWidth: "auto",
  },
  name: {
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0.3s linear, opacity 0.3s linear",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.secondaryBox,
    color: theme.palette.text.primary,
    ".VisioStreamWrapper:hover &": {
      visibility: "visible",
      opacity: 1,
    },
  },
  indicators: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
  },
  // only on preview, video type and Live Event
  muteVideoButton: {
    display: ({ product, type, preview }) => {
      if (preview) return "flex";
      if (type === VonageSDK.TYPE_SCREEN) return "none";
      if (product === "wisembly_visio_plus") return "none";
      return "flex";
    },
  },
  muteAudioButton: {
    display: ({ type }) => (type === VonageSDK.TYPE_SCREEN ? "none" : "flex"),
  },
  kickButton: {
    display: "flex",
  },
}));

const Stream = ({
  id,
  vonage,
  product,
  stream,
  type,
  onMuteAudio,
  onMuteVideo,
  isAudioMuted,
  isVideoMuted,
  isOwn,
  preview,
  focusHolder,
  wiz,
}) => {
  const classes = useStyles({ preview, product, isOwn });
  const { streams } = vonage;
  const dispatch = useDispatch();

  let name = "";

  if (stream) {
    name = `${stream.name || ""} ${isOwn ? _t("(you)") : ""}`;
    isAudioMuted = !stream.hasAudio;
    isVideoMuted = !stream.hasVideo;
  }

  const visioStreamClasses = {
    VisioStream: true,
    [classes.root]: true,
    OT_publisher: true,
    OT_root: true,
    OT_mirrored: type === "camera" && isOwn,
  };

  const anyShareScreen = streams.find(
    ({ type }) => type === VonageSDK.TYPE_SCREEN
  );
  const canbeFocused = !focusHolder || focusHolder !== id;
  const hasMultipleStreams = streams.length > 1;

  return (
    <Box
      className={clsx("VisioStreamWrapper", classes.root)}
      id={`focusable_${id}`}
    >
      {name && <Box className={classes.name}>{name}</Box>}
      <Box id={id} className={clsx(visioStreamClasses)} />

      {/* Show admin controls on preview and streams other than own */}
      <Box className={clsx("VisioStreamControls", classes.controls)}>
        {((isUser("anim+") && !isOwn) || preview) && (
          <>
            <Box>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color={isVideoMuted ? "default" : "secondary"}
                className={clsx(classes.button, classes.muteVideoButton)}
                onClick={() => {
                  vonage.muteVideo(id, !isVideoMuted);
                  onMuteVideo && onMuteVideo(!isVideoMuted);
                }}
              >
                {isVideoMuted ? <VideocamOff /> : <Videocam />}
              </Button>
            </Box>
            <Box ml={1}>
              <Button
                type="submit"
                variant="contained"
                size="medium"
                color={isAudioMuted ? "default" : "secondary"}
                className={clsx(classes.button, classes.muteAudioButton)}
                onClick={() => {
                  vonage.muteAudio(id, !isAudioMuted);
                  onMuteAudio && onMuteAudio(!isAudioMuted);
                }}
              >
                {isAudioMuted ? <MicOff /> : <Mic />}
              </Button>
            </Box>
            {!preview && (
              <Box ml={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={clsx(classes.button, classes.kickButton)}
                  onClick={() => {
                    vonage.kickSpeaker(id);
                  }}
                >
                  <Close />
                </Button>
              </Box>
            )}
          </>
        )}
        {!anyShareScreen && hasMultipleStreams && !preview && (
          <>
            {canbeFocused ? (
              <Box ml={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={clsx(classes.button, classes.kickButton)}
                  onClick={() => {
                    vonage.setFocus(id);
                  }}
                >
                  <FullscreenIcon />
                </Button>
              </Box>
            ) : (
              <Box ml={1}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="medium"
                  className={clsx(classes.button, classes.kickButton)}
                  onClick={() => {
                    vonage.clearFocus(id);
                    vonage.setWizFocus(null);
                  }}
                >
                  <FullscreenExitIcon />
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>

      {type === VonageSDK.TYPE_VIDEO && (
        <Box className={clsx("VisioStreamIndicators", classes.indicators)}>
          {isVideoMuted && <VideocamOff fontSize="small" />}
          {isAudioMuted && <MicOff fontSize="small" />}
        </Box>
      )}
    </Box>
  );
};

export default Stream;
