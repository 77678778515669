import { resourceEdit } from "app/state/ducks/ressources/actions";

export const endCallVisitor = (dispatch, visitor, callback = () => {}) => {
  dispatch(
    resourceEdit(visitor, {
      slug: ["visitors", visitor.hash, visitor.token, "end"],
      method: "POST",
      callback,
    })
  );
};

export const notifySMSVisitor = (dispatch, visitor, callback = () => {}) => {
  dispatch(
    resourceEdit(visitor, {
      slug: ["visitors", visitor.hash, "notify"],
      method: "POST",
      callback,
    })
  );
};

export const endCallVisitorFlag = (dispatch, visitor, flag, ban, callback = () => { }) => {

  const query = [];

  if (flag)
    query.push({ key: "flag", value: "true" });

  if (ban)
    query.push({ key: "ban", value: "true" });

  dispatch(
    resourceEdit(visitor, {
      slug: ["visitors", visitor.hash, visitor.token, "end"],
      query,
      method: "POST",
      callback,
    })
  );
};

export const vendorComment = (dispatch, visitor, comment, callback = () => {}) => {
  dispatch(
    resourceEdit(comment, {
      slug: ["visitors", visitor],
      patch: ["options"],
      callback,
    })
  );
};


export const triggerCartRefresh = (dispatch, visitor, newVisitor) => {
  dispatch(
    resourceEdit(newVisitor, {
      slug: ["visitors", visitor?.hash],
      patch: ["cart"],
    })
  );
};

export const visitorReady = (dispatch, visitor) => {
  dispatch(
    resourceEdit(visitor, {
      slug: ["visitors", visitor.hash, visitor.token, "ready"],
      method: "POST",
    })
  );
};

export const visitorAnswer = (dispatch, visitor) => {
  dispatch(
    resourceEdit(visitor, {
      slug: ["visitors", visitor.hash, visitor.token, "answer"],
      method: "POST",
    })
  );
};

export const visitorAddToCart = (dispatch, visitor, product, variant_id, quantity = 1, callback = () => {}) => {

  const add_to_cart = visitor.options?.add_to_cart || [];

  add_to_cart.push({
    product_id: product.id,
    variant_id,
    quantity,
    status: "pending",
    added_at: new Date().toISOString(),
  });

  visitor.options = { ...visitor.options, add_to_cart };

  console.log('visitor', visitor);

  dispatch(
    resourceEdit(visitor, {
      slug: ["visitors", visitor.hash, visitor.token],
      patch: ["options"],
      callback,
    })
  );
};
