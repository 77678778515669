import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Typography } from "@material-ui/core"
import Clock from "react-bootstrap-icons/dist/icons/clock"
import dayjs from "dayjs"
import { useSelector } from "react-redux"
import { liveShoppingBootstrapSelector } from "app/state/ducks/liveShopping/selectors"
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    gap: "4px",
    width: "110px",
    height: "28px",
    background: props => props.pendingTime?.minutes > 10 ? "rgba(255, 85, 40, 0.1)" : theme.palette.colors.grey200,
    borderRadius: "8px",
  },
  time: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    textAlign: "center",
    color: props => props.pendingTime?.minutes > 10 ? theme.palette.colors.error : theme.palette.colors.grey700,
  },
  clock: {
    color: props => props.pendingTime?.minutes > 10 ? theme.palette.colors.error : theme.palette.colors.grey700,
  },
}))

const hasOneDigit = val => String(Math.abs(val)).charAt(0) == val

const WaitingTime = ({ current, visitor }) => {
  const { datetime_diff_sec } = useSelector(liveShoppingBootstrapSelector);
  const [pendingTime, setPendingTime] = useState({ seconds: 0, minutes: 0 });

  useEffect(() => {
    const interval = setInterval(() => { estimatedPendingTime(visitor.created_at); }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [current, visitor]);

  const estimatedPendingTime = (created_at) => {
    if (!created_at) return;
    const now = dayjs().unix();
    const cr_at = dayjs(created_at).unix();
    const diff = now - cr_at;

    const minutes = Math.floor(diff / 60);
    const seconds = diff - minutes * 60;


    setPendingTime({
      seconds: hasOneDigit(seconds) ? `0${seconds}` : seconds,
      minutes: hasOneDigit(minutes) ? `0${minutes}` : minutes,
    });
  };

  const classes = useStyles({ pendingTime });

  return (
    <Box className={classes.root}>
      <Clock className={classes.clock} />
      <Typography className={classes.time}>{pendingTime.minutes}m {pendingTime.seconds}s</Typography>
    </Box>
  )
}

export default WaitingTime
