import BaseModel from "./base";
import { attr } from "redux-orm";

class Watcher extends BaseModel {
  constructor(args) {
    super(args);
    this.Model = Watcher;
  }
}

Watcher.modelName = "Watcher";
Watcher.backendName = "watcher";

Watcher.fields = {
  id: attr(),
  user_id: attr(),
  token: attr(),
  period: attr(),
  ip: attr(),
  is_persistent: attr(),
};

export default Watcher;
