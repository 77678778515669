import React, { useState } from "react";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Modal, Box, Typography } from "@material-ui/core";
import CustomCopyIcon from "app/liveShopping/assets/icons/CustomCopyIcon";
import CustomMessageIcon from "app/liveShopping/assets/icons/CustomMessageIcon";
import CustomFacebookIcon from "app/liveShopping/assets/icons/CutsomFacebookIcon";
import { FacebookShareButton, TwitterIcon, TwitterShareButton } from "react-share";
import CustomCloseIcon from "app/liveShopping/assets/icons/CustomCloseIcon";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
  },
  root: {
    top: "0",
    bottom: "0",
    margin: "auto",
    backgroundColor: "white",
    padding: "40px 30px",
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.25)",
    borderRadius: "10px",
    width: "500px",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  modalContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "130%",
    textAlign: "center",
    marginBottom: "28px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    display: "flex",

    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    maxWidth: "50px",
    maxHeight: "50px",
    borderRadius: "10px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    cursor: "pointer",
  },
  closeImg: {
    cursor: "pointer",
    float: "right",
    margin: "-25px -15px 0 0",
  },
  labelButton: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "100%",
    marginTop: "5px",
    textAlign: "center",
    color: "#0A0A0F",
  },
  copyButton: {
    background: "linear-gradient(138.37deg, #000000 0%, #3E3D3D 94.77%);",
  },
  facebookButton: {
    background: "linear-gradient(top, #3b5998, #2b4170)",
  },
  twitterButton: {
    background: "#04ABED",
  },
}));

const ShareModal = ({ display, setDisplay }) => {
  const classes = useStyles({});
  const [copyText, setCopyText] = useState(_t("Copy link"));

  const urlParams = new URLSearchParams(window.location.search);
  const referrer = urlParams.get('referrer') || window.location.href;

  function RenderCloseButton(onClick) {
    return (
      <Box className={classes.closeImg} onClick={onClick}>
        <CustomCloseIcon />
      </Box>
    );
  }

  function renderButton(onClick, buttonSpecificity, icon, label, href) {
    return (
      <Box className={clsx("ButtonContainer", classes.buttonContainer)}>
        <a href={href}>
          <Box
            className={clsx("CopyButton", classes.button, buttonSpecificity)}
            onClick={onClick}
          >
            {icon}
          </Box>
        </a>
        <Typography className={clsx("LabelButton", classes.labelButton)}>
          {label}
        </Typography>
      </Box>
    );
  }

  return (
    <Modal
      aria-labelledby="modal-modal-title"
      open={display}
      onClose={() => setDisplay(false)}
      className={clsx("Modal", classes.modal)}
    >
      <Box className={clsx("modal", classes.root)}>
        {RenderCloseButton(() => setDisplay(false))}
        <Typography
          id="modal-modal-title"
          className={clsx("TitleModal", classes.title)}
        >
          {_t("Share the event")}
        </Typography>
        <Box className={clsx("ModalContent", classes.modalContent)}>
          {renderButton(
            () => {
              navigator.clipboard.writeText(referrer);
              setCopyText(_t("Link copied !"));
              setTimeout(() => setCopyText(_t("Copy link")), 3000);
            },
            classes.copyButton,
            <CustomCopyIcon color="white" />,
            copyText
          )}
          <FacebookShareButton
            url={referrer}
            quote={_t("Join our great live shopping !")}
            description={"Description"}
          >
            {renderButton(
              () => { },
              classes.facebookButton,
              <CustomFacebookIcon color="white" />,
              _t("Facebook")
            )}
          </FacebookShareButton>
          <TwitterShareButton
            url={referrer}
            title={_t("Join our great live shopping !")}
            via="LiveBoutik"
          >
            {renderButton(
              () => { },
              classes.twitterButton,
              <TwitterIcon size={32} round />,
              _t("Twitter")
            )}
          </TwitterShareButton>
        </Box>
      </Box>
    </Modal>
  );
};

ShareModal.propTypes = {
  display: PropTypes.bool,
  setDisplay: PropTypes.func,
};

ShareModal.defaultProps = {
  display: false,
};

export default ShareModal;
