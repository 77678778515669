import React, { useState } from "react";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import ReplyFill from "react-bootstrap-icons/dist/icons/reply-fill";
import clsx from "clsx";

const defaultBubble = {
  display: "inline-flex",
  padding: "10px 15px",
  maxWidth: "291px",
};

const useStyles = makeStyles((theme) => ({
  bubbleSent: {
    ...defaultBubble,
    background: theme.palette.colors.primary,
    borderRadius: "19px 3px 15px 19px",
    justifyContent: "flex-end",
  },
  bubbleReceived: {
    ...defaultBubble,
    background: theme.palette.colors.greyLight,
    justifyContent: "flex-start",

    borderRadius: "3px 19px 19px 15px",
  },
  bubbleReplyByMe: {
    ...defaultBubble,
    background: theme.palette.colors.greyMedium,
    justifyContent: "flex-start",

    borderRadius: "3px 19px 19px 15px",
  },
  bubbleReplyByOthers: {
    ...defaultBubble,
    background: theme.palette.colors.greyMedium,
    justifyContent: "flex-start",

    borderRadius: "19px 3px 15px 19px",
  },
  typoSent: {
    color: "white",
  },
  typoReceived: {
    color: theme.palette.colors.blackMedium,
  },
  typeReplied: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  replyfill: {
    color: theme.palette.colors.greyDark,
    margin: "0px 5px",
  },
  readMore: {
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const Bubble = ({ quote, origin, pin }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  if (!quote) return null;
  return (
    <Box
      className={clsx({
        [classes.bubbleSent]: origin === "me",
        [classes.bubbleReceived]: origin === "others",
        [classes.bubbleReplyByMe]: origin === "replyByMe",
        [classes.bubbleReplyByOthers]: origin === "replyByOthers",
      })}
    >
      <Typography
        className={clsx({
          [classes.typoSent]: origin === "me",
          [classes.typoReceived]: origin === "others",
          [classes.typeReplied]: origin === "replyByMe" || origin === "replyByOthers",
        })}
        variant={clsx({
          body13: origin === "me" || origin === "others",
          smallBody13: origin === "replyByMe" || origin === "replyByOthers",
        })}
      >
        {/* // Message */}
        {quote && quote.length >= 255 && !isOpen && !pin && (
          <Box>
            {quote.slice(0, 255)}...{" "}
            <Box className={classes.readMore} component="span" onClick={() => setIsOpen(!isOpen)}>
              {_t("read more")}
            </Box>
          </Box>
        )}
        {quote && quote.length >= 255 && isOpen && !pin && (
          <Box>
            {quote}
            <br />
            <Box className={classes.readMore} onClick={() => setIsOpen(!isOpen)}>
              {_t("read less")}
            </Box>
          </Box>
        )}
        {quote && (quote.length < 255 || pin) && quote}
        {(origin === "replyByMe" || origin === "replyByOthers") && <ReplyFill className={classes.replyfill} />}
      </Typography>
    </Box>
  );
};

Bubble.propTypes = {
  quote: PropTypes.string,
  origin: PropTypes.string,
  pin: PropTypes.bool,
};

Bubble.defaultProps = {
  quote: "",
  origin: "",
  pin: false,
};

export default Bubble;
