import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Header from "./Header"
import Product from "./Product"
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { useDispatch, useSelector } from "react-redux";
import { getKeyword } from "app/state/ducks/application/selectors";
import { productsSelector } from "app/state/ducks/ressources/selectors";
import { Divider } from "@material-ui/core";
import Empty from "app/liveShopping/assets/images/Empty.js"
import { desktopLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "../../../reusables/Button"
import { _t } from "utilities/i18n";
import AppLoader from "app/liveShopping/frontoffice/otm/components/AppLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: props => props.isDesktop ? "100%" : "0",
    flex: "1 auto",
    boxShadow: props => !props.isDesktop && "0px -3px 8px rgba(0, 0, 0, 0.03)",
    borderTop: props => !props.isDesktop && "1px solid #F0F0F4",
  },
  wrapperProducts: {
    padding: "0 20px",
    flex: "1 auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "-ms-overflow-style": "none",  /* IE and Edge */
    scrollbarWidth: "none",
    overflowY: "auto",
    paddingBottom: props => props.isDesktop && "281px",
  },
  divider: { border: "1px solid #F0F0F4" },
  footer: {
    flex: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px 16px 32px",
    gap: "10px",
    height: "96px",
    background: "white",
    boxShadow: "0px -3px 15px rgba(0, 0, 0, 0.05)",
  }
}));

const Catalog = ({ }) => {
  const isDesktop = useSelector(desktopLayoutSelector)
  const classes = useStyles({ isDesktop });
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();
  const products = useSelector(productsSelector);
  const [isLoading, setIsLoading] = useState(products.length === 0);

  useEffect(() => {
    dispatch(
      resourceFetch("products", {
        slug: ["event", keyword, "products"],
        once: true,
        callback: (error, response) => {
          setIsLoading(false);

          if (error) {
            dispatch(setError(error));
            return;
          }
        },
      })
    );
  }, []);

  return (
    <Box className={classes.root}>
      <Header count={products.length} />

      {isLoading && <AppLoader />}
      {!isLoading &&
        <Box className={classes.wrapperProducts}>
          {products.length > 0 ? products.map((product, index) => {
            return (
              <>
                <Product
                  picture_url={product.variants[0]?.picture_url}
                  name={product.name}
                  enabled={product.enabled}
                  short={product.short}
                  previous_price={product.variants[0]?.previous_price}
                  price={product.variants[0]?.price}
                  currency={product.displayCurrency}
                  hash={product.hash}
                  product={product}
                />
                {index !== products.length - 1 && <Divider className={classes.divider} />}
              </>
            )
          }
          ) : (
            <Box style={{
              margin: "auto",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%"
            }}>
              <Empty />
            </Box>
          )}
        </Box>
      }

      {!isDesktop && !isLoading && (
        <Box className={classes.footer}>
          <Button w100 link url={{ pathname: "/product", state: { keyword, action: "create" } }}>
            <Typography>{_t("Add a product")}</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};


Catalog.propTypes = {};


Catalog.defaultProps = {};


export default Catalog;
