import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tabs, Tab } from "@material-ui/core";
import { _t } from "i18n";
import AllStats from "./AllStats";
import TopProducts from "./TopProducts";

const useStyles = makeStyles((theme) => ({
  root: {},
  indicator: {
    backgroundColor: theme.palette.colors.primary,
  },
}));

const Wrapper = ({ computes, participants, quotes }) => {
  const classes = useStyles();
  const [selectedView, setSelectedView] = useState(0);
  return (
    <Box style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <Tabs value={selectedView} onChange={(event, value) => setSelectedView(value)}
        classes={{ indicator: classes.indicator }}
        marginTop="mt-6">
        <Tab label={_t("All Statistics")} />
        <Tab label={_t("Top Products")} />
      </Tabs>

      {
        selectedView === 0 && (
          <AllStats
            {...computes}
            uniqueVisitors={participants?.true_reach}
            chatMessages={quotes?.total}
            registeredUsers={participants?.authenticated_users}
            visitedUsers={participants?.anonymous_users}
          />
        )
      }
      {selectedView === 1 && <TopProducts {...computes} />}
    </Box>
  );
};

Wrapper.propTypes = {};

Wrapper.defaultProps = {};

export default Wrapper;
