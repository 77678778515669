import isEqual from "lodash/isEqual";

const needsUpdate = (resource, attributes) => {
  for (const key in attributes) {
    if (!isEqual(attributes[key], resource[key])) return true;
  }

  return false;
};

export default needsUpdate;
