import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase } from "@material-ui/core";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { displayBackOfficeElementSelector } from "app/state/ducks/liveShopping/selectors";
import Catalog from "app/liveShopping/backoffice/otm/components/Live/Detail/Catalog/Catalog"
import Stats from "../Live/Detail/Stats"
import XLg from "react-bootstrap-icons/dist/icons/x-lg";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "0px -3px 8px rgba(0, 0, 0, 0.03)",
    borderTop: "1px solid #F0F0F4",
    height: "100%",
    width: "100%",
    position: "relative"
  }
}));


const Element = ({ }) => {
  const classes = useStyles();
  const elements = useSelector(displayBackOfficeElementSelector);

  const element = () => {
    switch (elements) {
      case "catalog":
        return <Catalog />
      case "stats":
        return <Stats />
    }
  }

  if (!elements) return null;

  return (
    <Box className={classes.root}>
      {element}
    </Box>
  );
};


Element.propTypes = {};


Element.defaultProps = {};


export default Element;