import { backendMapping } from "orm";
import isEqual from "lodash/isEqual";
import retrieveResource from "./retrieveResource";
import basil from "core/basil";
import needsUpdate from "./needsUpdate";

const checkAndUpdateWizPassword = ({ keyword, password }) => {
  if (!isEqual(basil.get(`${keyword}.password`), password)) {
    basil.set(`${keyword}.password`, password);
  }
};
export default function setResource(session, resource) {
  let attributes = {
    ...resource.attributes,
  };

  if (resource.meta) {
    attributes._meta = resource.meta;
  }

  try {
    const existingResource = retrieveResource(session, resource);

    // update wiz password into basil if there is one
    if (resource.type === "event" && attributes.password) {
      checkAndUpdateWizPassword({
        keyword: attributes.keyword,
        password: attributes.password,
      });
    }

    // extra check, if attributes haven't changed, no need to update
    // this is very important here, because we were blindly updating every single parsed resource here, even if unchanged
    // and that totally fuck*d up the memoized selectors and drained all the performances :(
    // event (Wiz) object was particularly considered changed because we fetch a "full" event object on GET /event/keyword endpoint
    // while only a "sort" version is included in relationships. Hence the need of needsUpdate method
    // that checks if the given props (even if not exhaustive) have changes with the one stored in store
    // sorry for the short novel here, but I found that pretty interesting, and that it was worth an explaination
    if (!needsUpdate(existingResource, attributes)) return existingResource;

    existingResource.update(attributes);

    return existingResource;
  } catch (error) {
    // console.warn("error with retrieveResource", error);
  }

  const { type } = resource;
  const resourceClass = session[backendMapping[type]];

  if (!resourceClass) {
    throw new Error(`resource "${type}" isnt supported`);
  }

  // use generate method if available for model
  if (resourceClass && resourceClass.generate) {
    return resourceClass.generate(attributes);
  }

  // else use standard redux-orm create method
  return resourceClass.create(attributes);
}
