import React from "react";
import { Box } from "@material-ui/core";
import ChatContainer from "./Chat";
import { useUser } from "../userHooks";
import ParticipantModeDialog from "../components/ParticipantModeDialog";

const index = () => {
  const user = useUser();
  return (
    <>
      <ParticipantModeDialog user={user} />
      <ChatContainer />
    </>
  );
};

export default index;
