import isEmpty from "lodash/isEmpty";
//import isEqual from "lodash/isEqual";
import get from "lodash/get";
//import last from "lodash/last";
import { getCookie } from "core/basil";
import forEach from "lodash/forEach";
//import isObject from "lodash/isObject";
export const isFilterEmpty = (filter) => {
  if (isEmpty(filter)) return true;
  if (
    isEmpty(get(filter, "tags")) &&
    isEmpty(get(filter, "default")) &&
    isEmpty(get(filter, "admin"))
  )
    return true;
  return false;
};
export const filterQuote = (quote, keyword, filters, eventSession, admin) => {
  if (quote.event && quote.event.id !== keyword && quote.event !== keyword) {
    return false;
  }
  let filterResult = null;

  if (filters?.wizRank && quote.wiz_rank <= filters.wizRank) {
    return false;
  }

  const filter = get(filters, "default[0]", null) || "recent";
  switch (filter) {
    default:
    case "all_messages":
    case "recent":
      filterResult =
        !eventSession || eventSession.started_at < quote.created_at;
      break;
    case "reacted":
      if (eventSession && eventSession.started_at > quote.created_at) {
        filterResult = false;
      } else {
        filterResult = quote.reaction_count > 0;
      }
      break;
    case "mine": {
      //const { token } = getCookie({ key: "api_session.anonymous" });
      //filterResult = quote.isOwner(token);
      filterResult = quote.is_mine;
      break;
    }
  }
  if (admin) {
    if (filterResult === false) return false;
    if (!isEmpty(filters.tags)) {
      forEach(filters.tags, (elem) => {
        if (!quote.tags_list.includes(elem)) {
          filterResult = false;
        }
      });
    }
    //if(filterResult === false) return false
    if (filterResult && filters.admin?.includes("unprocessed")) {
      filterResult = !quote.processed;
    }
    if (filterResult && filters.admin?.includes("favorite")) {
      filterResult = quote.favorite;
    }
    if (filterResult && filters.admin?.includes("unmoderated")) {
      filterResult = !quote.moderate;
    }
  }
  return filterResult && quote.moderate;
};

export const filterModerationQuote = (quote, keyword, eventSession) => {
  if (quote.event && quote.event.id !== keyword && quote.event !== keyword) {
    return false;
  }
  let filterResult = null;

  filterResult = !eventSession || eventSession.started_at < quote.created_at;
  if (filterResult === false) return false;
  filterResult = !quote.moderate;

  return filterResult;
};
