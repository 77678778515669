import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "utilities/i18n";
import { Box, Typography, useTheme } from "@material-ui/core";
import { ExclamationLg } from "react-bootstrap-icons";
import theme from "app/views/styles/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    gap: "10px",
    maxWidth: "355px",
    minHeight: "114px",
    border: "1px solid rgba(240, 78, 55, 0.3)",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "12px",
    textAlign: "center",
  },
  exclamation: {
    width: "40px",
    height: "40px",
    border: "1px solid rgba(240, 78, 55, 0.3)",
    background: "rgba(240, 78, 55, 0.1)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

  },
}));

const ErrorComponent = ({ text }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box className={classes.root}>
      <Box className={classes.card}>
        <Box className={classes.exclamation}>
          <ExclamationLg size={25} color={theme.palette.colors.primary} />
        </Box>
        <Typography>{text}</Typography>
      </Box>

    </Box>
  );
};

export default ErrorComponent;