import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: "none",
    height: "62px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  title: {
    color: (props) => (props.active ? theme.palette.colors.primary : theme.palette.colors.grey400),
  },
  icon: {
    fontSize: (props) => props.iconSize,
    marginBottom: "7px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: (props) => (props.active ? theme.palette.colors.primary : theme.palette.colors.grey500),
  },
  typo: {
    color: (props) => (props.active ? theme.palette.colors.primary : theme.palette.colors.grey400),
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "130%",
    textAlign: "center",
  },
}));

const Tab = ({ url, icon, title, iconSize }) => {
  const [active, setActive] = useState(false);
  const classes = useStyles({ active, iconSize });

  return (
    <NavLink
      className={classes.root}
      to={url}
      isActive={(match, location) => {
        match ? setActive(true) : setActive(false);
      }}
    >
      <Box className={classes.icon}>{icon}</Box>
      <Typography variant={"bodySB13"} className={classes.title}>
        {title}
      </Typography>
    </NavLink>
  );
};

Tab.propTypes = {};

Tab.defaultProps = {};

export default Tab;
