import React from "react";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import Button from "appComponents/Mui/Button";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import {
  useSurveyContextState,
  getLogicalJumps,
  getChoiceLogicalJump,
  useFormIntroState,
  useFormEndState,
  useSurveyContextDispatcher,
} from "appComponents/SurveyForms";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import emitter from "core/emitter";
import { SURVEY_EVENT_FLASH_ERROR } from "core/emitter/events";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import images from "images";
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
      alignItems: "center",
    },
  },
  introRoot: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      width: "100vw",
    },
  },
  previousButton: {
    display: (props) =>
      props.length > 1 && !props.hidePrevious ? "flex" : "none",

    [theme.breakpoints.up("sm")]: {
      marginRight: (props) =>
        props.length > 1 && !props.hideNext ? theme.spacing(2) : 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: (props) =>
        props.length > 1 && !props.hideNext ? "10px" : 0,
    },
  },
  nextButton: {
    display: (props) => (props.length > 1 && !props.hideNext ? "flex" : "none"),
  },
  answerAgain: {
    marginRight: (props) => (!props.notifyCanNotSeeAnswer ? "10px" : 0),
  },
}));

const useButtonStyles = makeStyles((theme) => ({
  round: {
    borderRadius: "50%",
    height: "50px",
    width: "50px",
    backgroundColor: theme.palette.secondary.main,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    pointerEvents: (props) => (props.disabled ? "none" : "auto"),
    opacity: (props) => (props.disabled ? 0.8 : 1),
  },
  roundBack: {
    borderRadius: "50%",
    height: "35px",
    width: "35px",
    backgroundColor: theme.palette.background.secondaryBox,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-end",
    },
  },
  leftDirectionIcon: {
    fontSize: "14px",
  },
  rigthDirectionIcon: {
    fontSize: "20px",
  },
}));
export const SubmitAction = ({
  isSubmitting,
  disableButton,
  notifyCanNotSeeAnswer,
  onSubmit,
  title,
  isMobile,
  isAnswerableForm,
  preview,
}) => {
  const classes = useButtonStyles({
    disabled:
      isSubmitting ||
      disableButton ||
      notifyCanNotSeeAnswer ||
      !isAnswerableForm,
  });
  // prettier-ignore
  return isMobile ? (
    <Box
      display={!isAnswerableForm ? "none" : "flex"}
      onClick={onSubmit}
      className={classes.round}
    >
      <images.MobileSubmitPresentation className={classes.submitButtonIcon} />
    </Box>
  ) : (
    <Button
        disabled={isSubmitting || disableButton || notifyCanNotSeeAnswer}
        onClick={preview ? () => {} : onSubmit}
        variant="contained"
        color="primary"
        size="medium"
      >
        {preview
          ? _t("You are in preview mode, you can view and test the question, but you cannot submit your answers")
          : title}
      </Button>
    );
};
SubmitAction.propTypes = {
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  disableButton: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  notifyCanNotSeeAnswer: PropTypes.bool,
  isMobile: PropTypes.bool,
  isAnswerableForm: PropTypes.bool,
  preview: PropTypes.bool,
};

export const NextButton = ({ onNext, isMobile, outerClasses }) => {
  const classes = useButtonStyles();
  return isMobile ? (
    <Box
      onClick={onNext}
      className={clsx(classes.round, outerClasses.nextButton)}
    >
      <ArrowForwardIosOutlinedIcon className={classes.rigthDirectionIcon} />
    </Box>
  ) : (
    <Button
      variant="contained"
      color="secondary"
      onClick={onNext}
      className={clsx(outerClasses.nextButton)}
      size="medium"
      bold
    >
      {_t("Next")}
    </Button>
  );
};
NextButton.propTypes = {
  onNext: PropTypes.func,
  outerClasses: PropTypes.object,
  isMobile: PropTypes.bool,
};

export const PreviousButton = ({ onPrevious, isMobile, outerClasses }) => {
  const classes = useButtonStyles();
  return isMobile ? (
    <Box
      onClick={onPrevious}
      className={clsx(classes.roundBack, outerClasses.previousButton)}
    >
      <ArrowBackIosOutlinedIcon className={classes.leftDirectionIcon} />
    </Box>
  ) : (
    <Button
      variant="contained"
      color="secondary"
      onClick={onPrevious}
      className={clsx(outerClasses.previousButton)}
      size="medium"
    >
      {_t("Back")}
    </Button>
  );
};
PreviousButton.propTypes = {
  onPrevious: PropTypes.func,
  outerClasses: PropTypes.object,
  isMobile: PropTypes.bool,
};

export const SubmitButton = ({
  title,
  Component,
  disableButton,
  panel,
  isAnswerableForm,
  notifyCanNotSeeAnswer,
  ...rest
}) => {
  const { isSubmitting, submitForm, validateField, values } =
    useFormikContext();
  const surveyContext = useSurveyContextState();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const surveyContextDispatch = useSurveyContextDispatcher();
  const showIntro = useFormIntroState(surveyContext.hash);
  const showFormEnd = useFormEndState(surveyContext.hash);

  const canSubmit =
    surveyContext.index == surveyContext.length - 1 && panel === "form";
  const canSeeFormEvalEnding =
    showFormEnd &&
    panel === "userResponse" &&
    (surveyContext.surveyType === "form" ||
      surveyContext.surveyType === "evaluation");
  const canRestartFormEval =
    showFormEnd && panel === "userResponse" && surveyContext.multipleAnswers;
  const formSchema = surveyContext.formSchema;
  const indexMap = surveyContext.indexMap;
  const hidePrevious =
    ((surveyContext.index === 0 && surveyContext.surveyType !== "evaluation") ||
      (surveyContext.index === -1 &&
        surveyContext.surveyType === "evaluation")) &&
    panel === "userResponse";
  const hideNext =
    (isEmpty(surveyContext.responseIndexMap) ||
      (!isEmpty(surveyContext.responseIndexMap) &&
        surveyContext.index === surveyContext.responseIndexMap.length - 1)) &&
    panel === "userResponse";
  const currentFieldHash =
    indexMap &&
    indexMap[surveyContext.index] &&
    indexMap[surveyContext.index].hash;

  const itemSchema = get(formSchema, currentFieldHash);
  const logicalJumps = getLogicalJumps(itemSchema);
  const currentItemValue = get(values, currentFieldHash);

  const nextItemToJumpTo = getChoiceLogicalJump(currentItemValue, logicalJumps);

  const onSubmit = async () => {
    const currentFieldError =
      currentFieldHash && (await validateField(currentFieldHash));
    if (!isEmpty(currentFieldError) && panel === "form") {
      emitter.emit(SURVEY_EVENT_FLASH_ERROR, {
        surveyHash: surveyContext.hash,
        message: _t("One or many fields seem incorrect"),
        section: surveyContext.section,
      });
    }
    if (isEmpty(currentFieldError) && panel === "form") {
      submitForm();
    }
  };
  const onPrevious = () => {
    if (notifyCanNotSeeAnswer) return;
    surveyContextDispatch({ type: "PREVIOUS_ITEM" });
  };
  const onNext = async () => {
    const currentFieldError =
      currentFieldHash && (await validateField(currentFieldHash));
    if (
      !isEmpty(currentFieldError) &&
      panel === "form" &&
      !notifyCanNotSeeAnswer
    ) {
      emitter.emit(SURVEY_EVENT_FLASH_ERROR, {
        surveyHash: surveyContext.hash,
        message: _t("One or many fields seem incorrect"),
        section: surveyContext.section,
      });
    }
    if (
      ((isEmpty(currentFieldError) && panel === "form") || panel !== "form") &&
      !notifyCanNotSeeAnswer
    ) {
      //if panel==form and item has logical jump ue surveyContextDispatch({ type: "NEXT_ITEM", jumpTo: hash });
      if (panel === "form" && !isEmpty(nextItemToJumpTo)) {
        //get item index
        const item = indexMap.filter(
          (item) => item.hash === nextItemToJumpTo
        )[0];

        const itemIndex = get(item, "index", surveyContext.index);

        surveyContextDispatch({
          type: "JUMP_TO_NEXT_ITEM",
          index: itemIndex,
          item: nextItemToJumpTo,
        });
      } else {
        surveyContextDispatch({ type: "NEXT_ITEM" });
      }
    }
  };
  const onDissmiss = () => {
    surveyContextDispatch({
      type: "DISSMISS_INTRO",
      survey: surveyContext.hash,
    });
  };
  const onStartAgain = () => {
    surveyContextDispatch({
      type: "DISSMISS_FORM_ENDING",
      survey: surveyContext.hash,
    });
    //setPanel form (done in ActionPanel)
    surveyContextDispatch({
      type: "SHOW_INTRO",
      survey: surveyContext.hash,
    });
  };
  const onSeeMysAnswers = () => {
    surveyContextDispatch({
      type: "DISSMISS_FORM_ENDING",
      survey: surveyContext.hash,
    });
  };
  const classes = useStyles({
    length:
      surveyContext.surveyType === "evaluation"
        ? surveyContext.length + 1
        : surveyContext.length,
    disabled: (!isAnswerableForm && panel === "form") || notifyCanNotSeeAnswer,
    hidePrevious,
    hideNext,
    notifyCanNotSeeAnswer,
  });
  //items not empty
  if (showIntro && panel === "form" && !isEmpty(surveyContext.indexMap)) {
    return (
      <Box className={clsx(classes.introRoot)}>
        <Button
          variant="contained"
          color="secondary"
          onClick={onDissmiss}
          size="medium"
          bold
        >
          {_t("Start")}
        </Button>
      </Box>
    );
  }
  if (canSeeFormEvalEnding) {
    return (
      <Box className={classes.root}>
        {canRestartFormEval && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onStartAgain}
            classes={{ root: classes.answerAgain }}
            size="medium"
            bold
          >
            {_t("Answer again")}
          </Button>
        )}
        {!notifyCanNotSeeAnswer && (
          <Button
            variant="contained"
            color="secondary"
            onClick={onSeeMysAnswers}
            size="medium"
          >
            {_t("See my answers")}
          </Button>
        )}
      </Box>
    );
  }
  return (
    <Box className={classes.root}>
      <PreviousButton
        isMobile={smallScreen}
        outerClasses={classes}
        onPrevious={onPrevious}
      />
      {canSubmit ? (
        <SubmitAction
          isSubmitting={isSubmitting}
          disableButton={disableButton}
          notifyCanNotSeeAnswer={notifyCanNotSeeAnswer}
          onSubmit={onSubmit}
          title={title}
          isMobile={smallScreen}
          isAnswerableForm={isAnswerableForm}
          preview={surveyContext.preview}
        />
      ) : (
        <NextButton
          isMobile={smallScreen}
          outerClasses={classes}
          onNext={onNext}
        />
      )}
    </Box>
  );
};

SubmitButton.propTypes = {
  title: PropTypes.string,
  Component: PropTypes.element,
  disableButton: PropTypes.bool,
  panel: PropTypes.string,
  isAnswerableForm: PropTypes.bool,
  notifyCanNotSeeAnswer: PropTypes.bool,
};
SubmitButton.defaultProps = {
  Component: Button,
  disableButton: false,
};

export default SubmitButton;
