import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import forEach from "lodash/forEach";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import map from "lodash/map";
import PercentIndicator from "./PercentIndicator";

import { _n } from "i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  detailsPosition: {
    //padding: theme.spacing(2),
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.secondaryBox,
  },
  optionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  optionRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    width: "100%",
    alignItems: "center",
  },
  optionLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
  },
  answerType: {},
  itemHelp: {
    marginBottom: theme.spacing(1),
  },
  itemLabel: {},
  errorMsg: {
    color: theme.palette.error.main,
  },
  required: {
    color: (props) => (props.error ? theme.palette.error.main : "currentcolor"),
  },
  item: {
    //height: theme.spacing(5),
    display: "flex",
  },
  detailsPercent: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
    "& div": {
      whiteSpace: "nowrap",
    },
  },
  goodQuizzAnswer: {
    backgroundColor: theme.wisemblyColors.lightGreen,
  },
  badQuizzAnswer: {
    backgroundColor: theme.wisemblyColors.pastelRed,
  },
  detailsSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 40px)",
  },
}));

const RankingSurveyItemResult = ({ item, survey }) => {
  const classes = useStyles();
  const options = get(item, "options.choices", []);
  const summary = get(item, "summary", {});
  const result = map(options, (option, key) => {
    const points = get(
      summary,
      `leaderboard.${get(option, "hash", "hash")}`,
      0
    );
    const board = summary.leaderboard || {};
    let max = 1;
    forEach(board, (value, key) => {
      if (value > max) max = value;
    });

    return (
      <Box flexWrap="wrap" mb={2} className={classes.optionRow}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={clsx(classes.detailsPosition)}
        >
          {key + 1}
        </Box>
        <Box className={classes.detailsSection}>
          <Box className={classes.main}>
            <Box pl={2} className={classes.item} value={option.hash}>
              <Typography className={classes.optionLabel}>
                {option.label}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexDirection="row"
            width="97%"
            className={classes.detailsPercent}
          >
            <PercentIndicator percent={(points / max) * 100} size="large" />
            <Box ml={1}>
              {_n("{count} point", "{count} points", points, { count: points })}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  });
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      className={clsx("RankingSurveyItemResult", classes.root)}
    >
      <Box flex={1}>
        {result}
      </Box>
    </Box>
  );
};

RankingSurveyItemResult.propTypes = {
  item: PropTypes.object,
};

export default RankingSurveyItemResult;
