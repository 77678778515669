import React, { useState } from "react";

import { _t } from "i18n";

import get from "lodash/get";

import emitter from "core/emitter";
import { EVENT_VONAGE_ENTER_STAGE } from "core/emitter/events";
import VonageSDK from "utilities/vonage";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import Mic from "@material-ui/icons/Mic";
import MicOff from "@material-ui/icons/MicOff";
import Videocam from "@material-ui/icons/Videocam";
import VideocamOff from "@material-ui/icons/VideocamOff";
import PresentToAll from "@material-ui/icons/PresentToAll";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";

const useStyles = makeStyles((theme) => ({
  root: {},
  flex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  leave: {
    color: theme.wisemblyColors.white,
    background: theme.wisemblyColors.pastelRed,
    "&:hover": {
      background: theme.wisemblyColors.darkRed,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute !important",
  },
  backdrop: {
    position: "absolute",
  },
  wrapper: {
    padding: theme.spacing(3),
    borderRadius: "6px",
    backgroundColor: theme.palette.background.box,
    outline: "none",
  },
}));

/*
  This component handle the various controls (comme son nom l'indique en fait..)
*/
const Controls = ({
  vonage,
  isOnStage,
  setPreview,
  videoStream,
  screenShare,
  parentRef,
  isFocused,
  onSetFocus,
}) => {
  const classes = useStyles();
  const hasVideo = get(videoStream, "stream.hasVideo");
  const hasAudio = get(videoStream, "stream.hasAudio");
  const [showLeaveConfirm, setLeaveConfirm] = useState(false);

  if (!isOnStage)
    return (
      <Box
        display="flex"
        flexDirection="column"
        flex="1 auto"
        justifyContent="center"
        alignItems="center"
      >
        <Box mb={0.7}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            onClick={() => {
              onSetFocus("live");
              setPreview(true);
            }}
          >
            {_t("Participate")}
          </Button>
        </Box>
        {isFocused && (
          <Typography variant="caption">
            {_t("To appear on screen, click on the participate button above")}
          </Typography>
        )}
      </Box>
    );

  const onLeave = () => {
    try {
      vonage.unpublishStreams();
      vonage.destroyPublishers();
      setLeaveConfirm(false);
    } catch (error) {
      console.log("error while leaving scene", error);
    }
  };

  return (
    <Box ml={4} mr={4} display="flex" flexDirection="row" flex="1 auto">
      <Modal
        open={showLeaveConfirm}
        disableEnforceFocus={true}
        disableBackdropClick={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
        className={classes.modal}
        outline="none"
        container={parentRef.current}
        onEscapeKeyDown={() => setLeaveConfirm(false)}
      >
        <Box
          className={classes.wrapper}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>
            {_t("Do you really want to leave the screen ?")}
          </Typography>
          <Box
            mt={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              size="medium"
              onClick={() => setLeaveConfirm(false)}
            >
              {_t("Cancel")}
            </Button>
            <Box ml={4}>
              <Button
                type="submit"
                variant="contained"
                className={classes.leave}
                onClick={onLeave}
                size="medium"
              >
                {_t("Leave screen")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      {isFocused && (
        <Box display="flex" className={classes.flex}>
          <Button
            variant="contained"
            className={classes.leave}
            onClick={() => setLeaveConfirm(true)}
            size="medium"
          >
            {_t("Leave stage")}
          </Button>
        </Box>
      )}

      <Box flex="1 auto" className={classes.flex}>
        {videoStream && (
          <>
            <Box>
              <Button
                type="submit"
                variant="contained"
                color={!hasVideo ? "default" : "secondary"}
                size="medium"
                className={classes.button}
                onClick={() => {
                  vonage.muteVideo("own", hasVideo);
                }}
              >
                {!hasVideo ? <VideocamOff /> : <Videocam />}
              </Button>
            </Box>

            <Box ml={1}>
              <Button
                type="submit"
                variant="contained"
                color={!hasAudio ? "default" : "secondary"}
                className={classes.button}
                size="medium"
                onClick={() => {
                  vonage.muteAudio("own", hasAudio);
                }}
              >
                {!hasAudio ? <MicOff /> : <Mic />}
              </Button>
            </Box>
          </>
        )}
      </Box>

      {isFocused && (
        <Box className={classes.flex}>
          <Button
            type="submit"
            className={classes.button}
            color={!screenShare ? "default" : "secondary"}
            size="medium"
            onClick={() => {
              if (!screenShare)
                emitter.emit(EVENT_VONAGE_ENTER_STAGE, VonageSDK.TYPE_SCREEN);
              else vonage.stopScreenShare();
            }}
          >
            {screenShare ? <CancelPresentationIcon /> : <PresentToAll />}&nbsp;
            {screenShare ? _t("Stop sharing") : _t("Share screen")}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Controls;
