import BaseModel from "./base";
import { attr } from "redux-orm";

class Reaction extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Reaction;
  }
}

Reaction.modelName = "Reaction";
Reaction.backendName = "reaction";

Reaction.fields = {
  id: attr(),
  created_at: attr(),
  reaction: attr(),
};

export default Reaction;
