import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import TextInput from "app/liveShopping/backoffice/otm/components/reusables/OutlinedTextField";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import EyeSlash from "react-bootstrap-icons/dist/icons/eye-slash";
import Eye from "react-bootstrap-icons/dist/icons/eye";
import Icon from "coreUiKit/inputs/Icon";
import PersonFill from "react-bootstrap-icons/dist/icons/person-fill";

import {
  TYPE_FORGOT_PWD,
  sendVerificationMail,
} from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import { useSelector } from "react-redux";
import { getKeyword } from "applicationDucks/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
  },
  btn: { margin: "30px 0" },
  link: { cursor: "pointer" },
  magikLink: {
    margin: "30px 0",
  },
  dividers: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    margin: "30px 0px",
  },
  marginSide: {
    margin: "0 15px",
  },
  divider: { flex: 1, justifyContent: "center", alignItems: "center" },
  profil: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  email: {
    display: "flex",
    alignItems: "center",
  },
  modify: {
    cursor: "pointer",
    "&:hover": {
      opacity: 0.9,
    },
  },
  avatar: {
    background: theme.palette.colors.sun,
    borderRadius: "99px",
    height: "32px",
    width: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.colors.white,
  },
}));

const SignInPart2 = ({
  email,
  password,
  onChange,
  setStep,
  errors,
  // user,
  onSubmit,
  handleSetType,
  loading,
  errorConnect,
  callbackMagikLink,
}) => {
  const classes = useStyles();
  const [typeInput, setTypeInput] = useState("password");

  const keyword = useSelector(getKeyword);

  const sendMagikLink = () => {
    sendVerificationMail(email, keyword);
    callbackMagikLink();
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.profil}>
        <Box className={classes.email}>
          <Box className={classes.avatar}>
            <PersonFill height={16} width={16} />
          </Box>
          <Box mr={1.25} />
          <Typography variant={"bodySB13"}>{email}</Typography>
        </Box>
        <Typography
          className={classes.modify}
          onClick={() => setStep(1)}
          color={"greyDark"}
          variant={"smallBody13"}
        >
          {_t("Modify")}
        </Typography>
      </Box>

      <Typography variant={"bodySB13"}>{_t("Password")}</Typography>
      <Box mb={1} />
      <TextInput
        autoFocus
        name={"password"}
        value={password}
        onChange={onChange}
        size={"medium"}
        required
        type={typeInput}
        fullWidth
        endAdornment={
          <Icon
            IconComponent={typeInput === "password" ? EyeSlash : Eye}
            size={16}
            color="greyDark"
            onClick={() =>
              setTypeInput(typeInput === "password" ? "text" : "password")
            }
          />
        }
        placeholder={_t("Enter your password")}
        errorMessage={errors?.password || errorConnect}
        error={errors?.password?.length || errorConnect?.length}
      />
      <Button
        style={{ width: "100%", marginTop: "20px" }}
        onClick={onSubmit}
      >
        {_t("Connect")}
      </Button>

      {/* <Typography
        className={classes.link}
        color="primary"
        variant={"smallBody13"}
        onClick={() => handleSetType(TYPE_FORGOT_PWD, { email })}
      >
        {_t("Forgot password ?")}
      </Typography> */}
    </Box>
  );
};

SignInPart2.propTypes = {
  password: PropTypes.string,
  errorConnect: PropTypes.string,
  email: PropTypes.string,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  setStep: PropTypes.func,
  errors: PropTypes.object,
  user: PropTypes.object,
  handleSetType: PropTypes.func,
};

SignInPart2.defaultProps = {
  password: "",
  email: "",
  errorConnect: "",
  onChange: () => { },
  setStep: () => { },
  onSubmit: () => { },
  errors: {},
  user: {},
  handleSetType: () => { },
};

export default SignInPart2;