import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useFormik } from "formik";
import { resourceCreate } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import { useDispatch, useSelector } from "react-redux";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.colors.white,
    width: 400,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: 20,
    borderRadius: "5px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginBottom: "15px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

const Add = ({ onAdd }) => {
  const classes = useStyles();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const currentProject = useSelector(currentProjectSelector);
  const user = useSelector(currentUserSelector);



  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      title: "",
    },
  });

  const handleAdd = () => {
    const newGroup = resourceFactory.create("Group", formik.values);
    dispatch(
      resourceCreate(newGroup, {
        slug: ["projects", currentProject.hash, "groups"],

        callback: (error, success) => {
          if (error) {
            console.log(error);
          } else {
            onAdd();
          }
        },
      })
    );
  };


  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Add a group")}</Typography>
      <Box className={classes.form}>
        <Input name={"title"} placeholder={_t("Add a name")} onChange={formik.handleChange} />
        <Button onClick={handleAdd}>{_t("Add")}</Button>
      </Box>
    </Box>
  );
};

Add.propTypes = {};

Add.defaultProps = {};

export default Add;
