import React, { useEffect, useState } from "react";
import {
  getRelativeDate,
  elapsedSeconds
} from "core/utils/date";
import Typography from "@material-ui/core/Typography";

const RelativeDate = ({ date, variant, color, className }) => {
  const [since, setSince] = useState(elapsedSeconds(date));

  useEffect(() => {
    if (since > 3600) return;
    const timeout = setTimeout(() => setSince(elapsedSeconds(date)), 59 * 1000);
    return () => clearTimeout(timeout);
  }, [since]);

  return (
    <Typography variant={variant} color={color} className={className}>
      {getRelativeDate(date)}
    </Typography>
  );
};

export default RelativeDate;
