import { call, takeEvery, all, put } from "redux-saga/effects";
import { patchResource } from "core/utils";

import {
  RESOURCE_FETCH,
  RESOURCES_FETCH,
  RESOURCE_CREATE,
  RESOURCES_CREATE,
  RESOURCE_EDIT,
  RESOURCES_EDIT,
  RESOURCE_DELETE,
  RESOURCES_DELETE,
} from "../actionsTypes";

import notify from "./notify";
import request from "./request";
import makeUrl from "./makeUrl";
import dispatch from "./dispatch";
import { setRequestStatus } from "applicationDucks/actions";

//export default function* resourcesSaga() {
function* handleResourceFetch({ locator, options = {} }) {
  const url = makeUrl(locator, options);

  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: "pending",
      })
    );
  }
  const response = yield call(request, url, null, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: response === false ? "error" : "success",
      })
    );
  }
  if (response === false) {
    return;
  }
  yield call(dispatch, response, options);
  yield call(notify, options, response);
}

function* handleResourcesFetch({ resourceType, options = {} }) {
  const url = makeUrl({ type: resourceType }, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: "pending",
      })
    );
  }
  const response = yield call(request, url, null, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: response === false ? "error" : "success",
      })
    );
  }
  if (response === false) {
    return;
  }

  yield call(dispatch, response);
  yield call(notify, options, response);
}

function* handleResourceCreate({ resource, options = {} }) {
  const url = makeUrl(
    { type: resource.type || resource.locator.type },
    options
  );
  options = Object.assign({ method: "POST" }, options);

  // if options.patch is present, patch only requested fields
  if (options.patch && Array.isArray(options.patch)) {
    resource = patchResource(resource, options.patch);
  }
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: "pending",
      })
    );
  }
  try{
    const response = yield call(request, url, resource, options);
    if (options.XHR_ID) {
      yield put(
        setRequestStatus({
          id: options.XHR_ID,
          status: response === false ? "error" : "success",
        })
      );
    }
    if (response === false) {
      return;
    }

    yield call(dispatch, response);
    yield call(notify, options, response);
  } catch (e) {
    console.error(e);
  }
}

function* handleResourcesCreate({ resources, options = {} }) {
  const url = makeUrl({}, options);
  options = Object.assign({ method: "POST" }, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: "pending",
      })
    );
  }
  const response = yield call(request, url, resources, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: response === false ? "error" : "success",
      })
    );
  }
  if (response === false) {
    return;
  }

  yield call(dispatch, response);
  yield call(notify, options, response);
}

function* handleResourcesEdit({ resources, options = {} }) {
  const url = makeUrl({}, options);

  options = Object.assign({ method: "PATCH" }, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: "pending",
      })
    );
  }

  const response = yield call(request, url, resources, options);

  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: response === false ? "error" : "success",
      })
    );
  }
  if (response === false) {
    return;
  }

  yield call(dispatch, response);
  yield call(notify, options, response);
}
function* handleResourceEdit({ resource, options = {} }) {
  const url = makeUrl(resource, options);
  options = Object.assign({ method: "PATCH" }, options);

  // if options.patch is present, patch only requested fields
  if (options.patch && Array.isArray(options.patch)) {
    resource = patchResource(resource, options.patch);
  }
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: "pending",
      })
    );
  }
  const response = yield call(request, url, resource, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: response === false ? "error" : "success",
      })
    );
  }
  if (response === false) {
    return;
  }

  yield call(dispatch, response);
  yield call(notify, options, response);
}

function* handleResourceDelete({ locator, options = {} }) {
  const url = makeUrl(locator, options);
  options = Object.assign({ method: "DELETE" }, options);

  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: "pending",
      })
    );
  }
  const response = yield call(request, url, null, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: response === false ? "error" : "success",
      })
    );
  }
  if (response === false) {
    return;
  }

  yield call(dispatch, { locator });
  yield call(notify, options, response);
}

//this generator relies on pushEvent to remove ressources from orm
function* handleResourcesDelete({ resources, options = {} }) {
  const url = makeUrl({}, options);
  options = Object.assign({ method: "DELETE" }, options);
  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: "pending",
      })
    );
  }
  const response = yield call(request, url, resources, options);

  if (options.XHR_ID) {
    yield put(
      setRequestStatus({
        id: options.XHR_ID,
        status: response === false ? "error" : "success",
      })
    );
  }
  //if response === false , notify already been fired
  if (response === false) {
    return;
  }
  //yield call(dispatch, response, options);
  yield call(notify, options, response);
}

export default function* () {
  yield all([
    yield takeEvery(RESOURCE_FETCH, handleResourceFetch),
    yield takeEvery(RESOURCES_FETCH, handleResourcesFetch),
    yield takeEvery(RESOURCE_CREATE, handleResourceCreate),
    yield takeEvery(RESOURCES_CREATE, handleResourcesCreate),
    yield takeEvery(RESOURCE_EDIT, handleResourceEdit),
    yield takeEvery(RESOURCES_EDIT, handleResourcesEdit),
    yield takeEvery(RESOURCE_DELETE, handleResourceDelete),
    yield takeEvery(RESOURCES_DELETE, handleResourcesDelete),
  ]);
}
