import React, { useEffect, useState } from "react";
import { Box, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { _t } from "utilities/i18n";
import { getOwnAccessesSelector, visitorsSelector } from "app/state/ducks/ressources/selectors";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { currentProjectSelector } from "app/state/ducks/application/selectors";


const obfuscator = (phone) => {
  if (!phone || phone === "") return phone;

  // replace all but the last 4 digits with x
  return phone.replace(/.(?=.{4})/g, "x");
}

const useStyles = makeStyles((theme) => ({
  head: {
    background: theme.palette.colors.grey200,
  },
}));

const TableComponent = ({ }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const accesses = useSelector(getOwnAccessesSelector).filter((access) => access.available === true);
  const visitors = useSelector(visitorsSelector)
  const availableVisitors = visitors?.filter(({ scheduled_at }) => dayjs(scheduled_at).isAfter(dayjs()));
  const project = useSelector(currentProjectSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    accesses.map((access) => {
      dispatch(
        resourceFetch("queue", {
          slug: ["queues", access.group.queue.hash, "visitors"],
          callback: () => setIsLoading(false),
        })
      );
    });
  }, [project]);

  if (availableVisitors.length === 0) return (
    <Box display="flex" justifyContent="center" alignItems="center" height="100%">
      <Box>{_t("There is no visitors yet")}</Box>
    </Box>
  )

  return (
    // create a mui table with the following columns: hash, online for how minutes, queue, status, messages count, actions
    <Table>
      <TableHead className={classes.head}>
        <TableRow>
          <TableCell>Hash</TableCell>
          <TableCell>{_t("Firstname")}</TableCell>
          <TableCell>{_t("Lastname")}</TableCell>
          <TableCell>{_t("Phone number")}</TableCell>
          <TableCell>{_t("Date")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          // map over the visitors and create a table row for each visitor
          availableVisitors.map((visitor) => {
            return (
              <TableRow>
                <TableCell>{visitor?.hash.substring(0, 8)}</TableCell>
                <TableCell>{visitor?.options?.schedule?.firstname}</TableCell>
                <TableCell>{visitor?.options?.schedule?.lastname}</TableCell>
                <TableCell>{obfuscator(visitor?.options?.schedule?.phone)}</TableCell>
                <TableCell>{dayjs(visitor.scheduled_at).format("DD/MM/YYYY HH:mm")}</TableCell>
              </TableRow>
            )
          })
        }


      </TableBody>
    </Table>
  )
};

export default TableComponent;