import { createTheme } from "@material-ui/core";
import { hexToRGB } from "utilities/utils";

const defaultPalette = () => {
  return {
    primary: "#1E2023",
    white: "#FFFFFF",
    black: "#000000",
    success: "#2DBE6E",
    error: "#F0464B",
    lightGrey: "#F0F0F4",
    darkGrey: "#808284",
    grey: "#B4B6B9",
    mediumGrey: "#E2E4E8",
    border: "#000000",
  };
};

export const frontTheme = () => {
  const palette = { ...defaultPalette(), ...{} };
  return createTheme({
    palette: {
      colors: palette,
      border: {
        default: hexToRGB(palette.border, "0.1"),
        divider: "#FFFFFF",
        textField: "#CED4DA",
        default20: hexToRGB(palette.border, "0.2"),
        default30: hexToRGB(palette.border, "0.3"),
        default40: hexToRGB(palette.border, "0.4"),
        default50: hexToRGB(palette.border, "0.5"),
      },
    }
  });
};
