import { _t } from "i18n";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import { STATUS_NOT_STARTED, STATUS_ONGOING, STATUS_FINISHED } from "constants";

export const getTypeWording = (element) => {
  if (element.type !== "poll") {
    switch (element.type) {
      case "form":
        return _t("Questionnaire");
      case "evaluation":
        return _t("Quiz");
      case "leaderBoard":
        return _t("all quizz are selected");
    }
  }

  const items = element.items && element.items.toModelArray();
  const firstItem = get(items, "[0]", { type: "text" });

  switch (firstItem.type) {
    default:
    case "text":
      return _t("Word cloud");
    case "unique":
      return _t("Single choice question");
    case "multiple":
      return _t("Multiple choices question");
    case "image":
      return _t("Picture choices");
    case "ranking":
      return _t("Rank choices");
    case "rating":
      return _t("Stars rating");
    case "grid":
      return _t("Grid question");
  }
};

export const getType = (element) => {
  if (element.type !== "poll") {
    return element.type;
  }

  const items = element.items && element.items.toModelArray();
  return get(items, "[0]", { type: "text" }).type;
};

export const getStatus = (survey) => {
  if (
    survey?.is_active ||
    (isEmpty(survey?.ended_at) && !isEmpty(survey?.started_at))
  ) {
    return STATUS_ONGOING;
  }

  if (survey?.answers_count === 0) {
    return STATUS_NOT_STARTED;
  }

  return STATUS_FINISHED;
};
