import { Box, makeStyles } from "@material-ui/core";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useFormik } from "formik";
import React from "react";
import { _t } from "utilities/i18n";
import { isEmpty } from "lodash-es";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR } from "utilities/emitter/events";
import { useLogoManager } from "app/liveShopping/backoffice/otm/hooks/backgroundHooks";
import Dropzone from "../reusables/Dropzone";
import { Image } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  dropzones: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "10px",
  },
  dropzoneBox: {
    textAlign: "center",
  },
  typoDrop: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "5px",
  },
  image: {
    width: "112px",
    height: "187px",
    borderRadius: "12px",
    background: ({ logoImage }) => (logoImage ? `url("${logoImage}")` : "none"),
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },
  remove: {
    fontSize: "10px",
    color: theme.palette.colors.grey700,
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "10px",
  },
}));

const LogoUpload = ({ wiz }) => {
  const classes = useStyles({ logoImage: wiz?.logo_paths?.desktop });

  console.log(">>>>", wiz?.logo_paths);

  const handleManageLogo = useLogoManager();

  const handleAddingImage = (media) => {
    handleManageLogo({
      action: "add",
      keyword: wiz.keyword,
      media,
      errorCallback: () => emitter.emit(EVENT_FLASH_ERROR, _t("Cannot upload the logo file")),
      callback: () => { },
    });
  };

  const onRemoveBgImage = () => {
    handleManageLogo({
      action: "delete",
      keyword: wiz?.keyword,
    });
  };

  const formik = useFormik({
    initialValues: {
      media: wiz?.logo_paths?.desktop,
    },
  });

  return (
    <Box style={{ textAlign: "center" }}>
      <Typography variant={"bodySB13"} className={classes.typoDrop}>
        {_t("Logo Image")}
      </Typography>

      {!wiz?.logo_paths?.desktop && (
        <Dropzone
          isLoading={formik.isSubmitting}
          availableFormats={["image/jpeg", "image/png"]}
          isSmall
          multiple={false}
          title={<Box className={classes.title}>{_t("Add")}</Box>}
          instruction={_t("512x521px 2Mo max.")}
          disabled={false}
          icon={Image}
          onDrop={(file) => handleAddingImage(file)}
          error={!isEmpty(formik.errors?.errorUpload) && formik.errors.errorUpload}
        />
      )}

      {wiz?.logo_paths?.desktop && (
        <>
          <Box className={classes.image} />
          <Box className={classes.remove} onClick={onRemoveBgImage}>
            {_t("Remove / Change")}
          </Box>
        </>
      )}
    </Box>
  );
};

export default LogoUpload;
