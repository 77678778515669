import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Counter from "../../../reusables/Counter";
import Button from "../../../reusables/Button";
import { useSelector, useDispatch } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import { ButtonBase } from "@material-ui/core";
import { displayBackOfficeElement } from "app/state/ducks/liveShopping/actions";
import { getKeyword } from "app/state/ducks/application/selectors";
import { Link } from "react-router-dom";
import { getUrl } from "app/views/containers/Application/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "17px",
    height: "32px",
    flex: (props) => !props.isMobile && 1,
    paddingTop: (props) => props.isMobile && 24,
    padding: "0 20px",
    position: "relative",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginRight: "10px",
  },
  typoButton: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    color: "#FFF",
  },
  close: {
    position: "absolute",
    top: "15px",
    right: "20px",
  },
  import: {
    fontSize: "10px",
    textDecoration: "underline",
    cursor: "pointer",
  },
  fjc: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Header = ({ count }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Typography className={classes.title}>{_t("Live's Catalog")}</Typography>
        <Counter count={count} />
      </Box>

      {!isMobile && (
        <Box className={classes.fjc} flexDirection="column">
          <Button link url={getUrl("otm_product", { keyword }, false)}>
            <Typography className={classes.typoButton}>{_t("Add a product")}</Typography>
          </Button>
          <Box className={classes.fjc}>
            <span style={{ fontSize: "10px" }}>{_t("or")}</span>&nbsp;
            <Link to={getUrl("otm_product_import", { keyword }, false)}>
              <Typography className={classes.import}>{_t("import")}</Typography>
            </Link>
          </Box>
        </Box>
      )}

      {isMobile && (
        <ButtonBase onClick={() => dispatch(displayBackOfficeElement(""))}>
          <XLg size={20} />
        </ButtonBase>
      )}
    </Box>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
