import React, { forwardRef, useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import Pencil from "react-bootstrap-icons/dist/icons/pencil-square";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import Check from "react-bootstrap-icons/dist/icons/check-lg";
import Icon from "coreUiKit/inputs/Icon";
import Bubble from "coreUiKit/components/Chat/Bubble";
import Image from "coreUiKit/components/Chat/Image";
import Head from "coreUiKit/components/Chat/Head";
import TextInput from "./OutlinedTextField";
import DeletedQuoteNotifyer from "./DeletedQuoteNotifyer";
import Button from "coreUiKit/inputs/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    padding: "15px",
    backgroundColor: theme.palette.colors.white,
    borderRadius: "8px",
    width: "315px",
  },
  head: { margin: "4px 0px" },
  rightElement: {
    display: "flex",
    justifyContent: "flex-end",
  },
  image: {
    display: "flex",
    justifyContent: (props) => (props.hasRightAlign ? "flex-end" : "flex-start"),
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "11px",
  },
  apply: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    color: theme.palette.colors.success,
  },
  cancel: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    color: theme.palette.colors.greyDark,
  },
  left: {
    display: "flex",
    flexDirection: "row",
  },
}));

const ChatBubble = forwardRef(({ hash, quote, createdAt, origin, owner, isAdmin, imgPath, handleEditQuote, handleDeleQuote }, ref) => {
  //const hasRightAlign = ["me", "replyByMe"].includes(origin);
  const classes = useStyles({ hasRightAlign: false });
  const [editing, setEditing] = useState(false);
  const [message, setMessage] = useState(quote);
  const [deleting, setDeleting] = useState(false);
  const [pause, setPause] = useState(false);
  const [loading, setLoading] = useState(false);
  const deleteTimer = useRef(3000);

  const onSuccess = () => {
    setEditing(false);
    setLoading(false);
  };
  const onError = () => {
    setEditing(false);
    setLoading(false);
  };
  //const [isHovered, setIsHovered] = useState(false);
  const onValidate = () => {
    setLoading(true);
    if (editing) {
      handleEditQuote({ quote: message, hash, moderate: false }, onSuccess, onError);
    } else {
      //validate quote
      handleEditQuote({ quote, hash, moderate: true }, onSuccess, onError);
    }
  };
  const onDelete = () => {
    handleDeleQuote({ hash });
  };
  useEffect(() => {
    if (deleting && !pause) {
      const timeout = setTimeout(function () {
        onDelete();
      }, deleteTimer.current);
      const interval = setInterval(function () {
        deleteTimer.current = deleteTimer.current - 30;
      }, 3000 / 100);
      return () => {
        clearTimeout(timeout);
        clearInterval(interval);
      };
    }
    if (!deleting) {
      deleteTimer.current = 3000;
    }
  }, [deleting, pause]);

  if (deleting) {
    return <DeletedQuoteNotifyer onCancel={() => setDeleting(false)} onPause={() => setPause(true)} onResume={() => setPause(false)} />;
  }
  return (
    <Box ref={ref} className={classes.root}>
      <Box width="100%">
        <Box className={classes.head}>
          <Head owner={owner} createdAt={createdAt} isAdmin={isAdmin} origin="others" isHovered={true} revert />
        </Box>
        {imgPath && (
          <Box className={classes.image}>
            <Image path={imgPath} size={75} />
          </Box>
        )}
        <Box
          className={clsx(classes.head, {
            [classes.rightElement]: false,
          })}
        >
          {!editing ? (
            <Box>
              <Bubble quote={quote} origin="others" />
            </Box>
          ) : (
            <TextInput value={message} onChange={setMessage} placeholder={_t("Message")} multiline maxRows={3} type={"text"} fullWidth />
          )}
        </Box>
        <Box className={classes.actions}>
          <Box className={classes.left}>
            {!editing ? (
              <>
                <Icon IconComponent={Trash} onClick={() => setDeleting(true)} color="alert" hover="alert" />
                <Box className={classes.left} ml={2}>
                  <Icon IconComponent={Pencil} onClick={() => setEditing(true)} />
                </Box>
              </>
            ) : (
              <Box className={classes.cancel} onClick={() => setEditing(false)}>
                <Button variant="link" onClick={() => setEditing(false)} color="greyDark" startIcon={<XLg />} size="medium">
                  {" "}
                  {_t("Cancel")}
                </Button>
              </Box>
            )}
          </Box>

          <Box className={classes.apply} onClick={onValidate}>
            <Button variant="link" onClick={onValidate} color="success" startIcon={<Check />} size="medium" loading={loading}>
              {editing ? _t("Apply") : _t("Approve")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

ChatBubble.displayName = "ModerationBubble";

ChatBubble.propTypes = {
  hash: PropTypes.string,
  quote: PropTypes.string,
  createdAt: PropTypes.string,
  origin: PropTypes.string,
  owner: PropTypes.string,
  isAdmin: PropTypes.bool,
  imgPath: PropTypes.string,
  handleEditQuote: PropTypes.func,
  handleDeleQuote: PropTypes.func,
};

export default ChatBubble;
