import BaseModel from "./base";
import { attr } from "redux-orm";

class Project extends BaseModel {
  constructor(args) {
    super(args);
    this.Model = Project;
  }
}

Project.modelName = "Project";
Project.backendName = "project";

Project.fields = {
  id: attr(),
  title: attr(),
  options: attr(),
  urls: attr(),
  widget_mode: attr(),
};

export default Project;
