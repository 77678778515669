class Transformer {
  /*
   *
   * transform
   * http://jsonapi.org/format/#crud-creating
   *
   */
  transform(entity) {
    let json = {};
    if (Array.isArray(entity)) {
      let result = [];
      entity.forEach((elem) => {
        result.push(elem.json || this.fallback(elem));
      });
      json = {
        data: result,
      };
    } else {
      json = {
        data: entity.json || this.fallback(entity),
      };
    }

    return json;
  }

  fallback(object) {
    if (!object.type) {
      throw new Error("Invalid object, must at least provide a type");
    }

    const formatted = {
      id: object.id,
      type: object.type,
      attributes: object.attributes || {},
      relationships: object.relationships || {},
    };

    for (let key in object) {
      if (
        key === "id" ||
        key === "type" ||
        key === "relationships" ||
        key === "attributes"
      ) {
        continue;
      }

      formatted.attributes[key] = object[key];
    }

    return formatted;
  }
}

export default new Transformer();
