import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Switch from "../../../reusables/Switch";
import { resourceDelete, resourceEdit, resourceFetch } from "app/state/ducks/ressources/actions";
import { useDispatch, useSelector } from "react-redux";
import { getKeyword } from "app/state/ducks/application/selectors";
import ResourceFactory from "orm/resources";
import IconButton from "../../../reusables/IconButton";
import PencilSquare from "react-bootstrap-icons/dist/icons/pencil-square";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { productsSelector } from "app/state/ducks/ressources/selectors";
import CloneButton from "./CloneButton";
import Interaction from "../../../reusables/Notifications/Interaction";
import { getUrl } from "app/views/containers/Application/Routes";
import { formatPrice } from "app/liveShopping/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: "80px",
    margin: "10px 0",
  },
  picture: {
    width: "60px",
    height: "60px",
    borderRadius: "8px",
    background: (props) => (props.picture_url ? `url("${props.picture_url}")` : "none"),
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    marginRight: "16px",
  },

  infos: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.grey700,
    marginBottom: "4px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.grey500,
    marginBottom: "6px",
  },
  discountPrice: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.grey700,
    marginRight: "8px",
  },
  originalPrice: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    textDecorationLine: "line-through",
    color: theme.palette.colors.grey500,
  },
}));

const Product = ({ name, short, picture_url, enabled, price, previous_price, currency, hash, product }) => {
  const classes = useStyles({ picture_url });
  const keyword = useSelector(getKeyword);
  const [isChecked, setIsChecked] = useState(enabled);
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const dispatch = useDispatch();
  const products = useSelector(productsSelector);
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const handleDelete = () => {
    const productToDelete = products.find((p) => p.hash === hash);
    dispatch(
      resourceDelete(productToDelete, {
        slug: ["event", keyword, "products", hash],

        callback: (error) => {
          if (error) {
            emitter.emit(EVENT_FLASH_ERROR, _t("Unable to delete this product. Please try again."));
            return;
          }

          setOpenModal(false);
          emitter.emit(EVENT_FLASH_SUCCESS, _t("The product has been deleted with success !"));
        },
      })
    );
  };

  const handleCheck = (enabled) => {
    setIsChecked(enabled);

    const newProduct = resourceFactory.create("Product", { enabled });
    dispatch(
      resourceEdit(newProduct, {
        slug: ["event", keyword, "products", hash],
        patch: ["enabled"],
        callback: (error) => {
          if (!error) return;
          setIsChecked(!enabled);
          emitter.emit(EVENT_FLASH_ERROR, _t("An error occured"));
        },
      })
    );

    emitter.emit(EVENT_FLASH_SUCCESS, enabled ? _t("Your product is active") : _t("Your product isn't active"));
  };

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex" }}>
        <Box className={classes.picture} />
        <Box className={classes.infos}>
          <Typography className={classes.title}>
            {name}
            &nbsp;
            {/* {product.hasVariants() && (
              <span>|&nbsp;{product.getVariant(variant_id).name}</span>
            )} */}
          </Typography>
          <Typography className={classes.subTitle}>{short}</Typography>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.discountPrice}>
              {formatPrice(price, "EUR")}
            </Typography>
            {previous_price && (
              <Typography className={classes.originalPrice}>
                {previous_price} {currency}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Switch onClick={() => handleCheck(!isChecked)} checked={isChecked} />
        <IconButton link url={getUrl("otm_product_edit", { keyword, hash: product.hash }, false)}>
          <PencilSquare />
        </IconButton>
        <CloneButton hash={hash} />
        <IconButton onClick={() => setOpenModal(true)} color={theme.palette.colors.error}>
          <XLg color={"white"} />
        </IconButton>
      </Box>
      {openModal && (
        <Interaction type={"danger"} title={_t("Are you sure to delete this product ?")} textBtn2={_t("No")} textBtn1={_t("Yes")} action2={() => setOpenModal(false)} action1={handleDelete} />
      )}
    </Box>
  );
};

Product.propTypes = {};

Product.defaultProps = {};

export default Product;
