import React from "react";
import PropTypes from "prop-types";

const CustomCatalogIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.7419 27.5385C8.03404 26.9525 10.1173 26.2489 12.1201 26.0467C14.1996 25.8371 15.7491 26.201 16.5391 27.1868C16.6545 27.3307 16.8008 27.4468 16.9671 27.5266C17.1334 27.6064 17.3155 27.6478 17.5 27.6478C17.6845 27.6478 17.8666 27.6064 18.0329 27.5266C18.1992 27.4468 18.3457 27.3305 18.4611 27.1866C19.2511 26.2007 20.8005 25.8371 22.8784 26.0467C24.8829 26.2489 26.9676 26.9526 28.258 27.5384C28.4456 27.6238 28.6517 27.6607 28.8573 27.6457C29.0629 27.6308 29.2614 27.5645 29.4347 27.453C29.6081 27.3414 29.7507 27.1882 29.8494 27.0072C29.9482 26.8263 29.9999 26.6233 30 26.4172V9.89064C29.9999 9.65419 29.9318 9.42277 29.8038 9.22398C29.6758 9.02519 29.4933 8.86743 29.2781 8.76954C27.8141 8.10371 25.4668 7.30516 23.1266 7.06867C21.26 6.88057 19.1078 7.02378 17.5 8.20026C15.8921 7.02388 13.7393 6.88208 11.8732 7.06869C9.53307 7.30518 7.18587 8.1037 5.72194 8.76954C5.50671 8.86743 5.32419 9.02519 5.19618 9.22398C5.06817 9.42277 5.00007 9.65433 5 9.89077V26.4173C5.00006 26.6235 5.05183 26.8263 5.15059 27.0072C5.24934 27.1882 5.39192 27.3414 5.56525 27.453C5.73859 27.5645 5.93715 27.6308 6.14274 27.6457C6.34829 27.6607 6.55429 27.6238 6.7419 27.5385ZM11.8729 23.5952C10.3178 23.7533 8.75981 24.158 7.46394 24.6058V10.7069C8.74169 10.2 10.46 9.68722 12.1215 9.52026C13.9802 9.33299 15.4222 9.60485 16.268 10.3735V24.053C14.8847 23.5029 13.3 23.4515 11.8729 23.5952ZM18.732 24.0524V10.3735C19.5778 9.60485 21.0198 9.33299 22.8785 9.52026C24.54 9.68722 26.2583 10.2 27.5361 10.7069V24.6059C26.2399 24.1579 24.6826 23.7523 23.1267 23.5966C21.6986 23.4518 20.1152 23.5018 18.732 24.0524Z"
      />
    </svg>
  );
};

CustomCatalogIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomCatalogIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomCatalogIcon;
