import Dropzone from "dropzone";
import "dropzone/dist/dropzone.css";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";
import PropTypes from "prop-types";
import clsx from "clsx";
import get from "lodash/get";

import { _t } from "core/i18n";
import { API_BASE, APP_ID } from "settings/config";
import { getKeyword } from "applicationDucks/selectors";
import { getSecuredUrl } from "core/utils/media";
import { getUserSession } from "core/session";
import emitter from "utilities/emitter";
import { ADMIN_EVENT_FLASH_SUCCESS_VANISH } from "utilities/emitter/events";

import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";

import Button from "appComponents/Mui/Button";
import images from "images";
import DialogSendNotify, {
  NotifyInvitation,
  DialogErrorNotify,
} from "adminComponents/UserManagement/DialogSendNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
  },
  action: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: theme.spacing(4.25),
  },
  linkText: {
    fontSize: "12px",
    marginTop: theme.spacing(1.875),
    textDecoration: "underline",
  },
  form: {
    backgroundColor: theme.palette.background.secondaryBox,
    border: `1px dashed ${theme.palette.background.secondaryBox}`,
    padding: theme.spacing(3.25),
    borderRadius: "5px",
    width: "100%",
    minHeight: "220px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  defaultText: {
    fontSize: "14px",
    "&:first-child": {
      marginBottom: "10px",
    },
  },
  defaultBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    margin: "0 !important",
  },
  defaultIcon: {
    color: theme.palette.border.default,
    fontSize: "40px",
  },
  defaultButton: {
    marginTop: theme.spacing(1.875),
  },
  templateBoxDetails: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.text.primary,
    borderRadius: "5px",
    boxShadow: theme.palette.shadow,
    padding: theme.spacing(1.75),
    margin: theme.spacing(1, 0),
  },
  templateIcon: {
    fontSize: "35px",
    marginRight: theme.spacing(1.25),
    color: theme.palette.secondary.main,
    "& path": {
      fill: theme.palette.secondary.main,
    },
  },
  templateDetails: {
    display: "flex",
    flexDirection: "column",
  },
  templateFilename: {
    fontSize: "14px",
    color: theme.palette.secondary.contrastText,
    textOverflow: "ellipsis",
    wordBreak: "break-word",
  },
  templateSize: {
    fontSize: "12px",
    color: theme.wisemblyColors.grey2,
  },
  templateError: {
    color: theme.wisemblyColors.pastelRed,
    whiteSpace: "pre-line",
  },
  templateRemove: {
    display: "flex",
    color: theme.wisemblyColors.pastelRed,
    marginLeft: "auto",
    cursor: "pointer !important",
    "& *": {
      cursor: "pointer !important",
    },
  },
}));

const FileImport = ({ ListenerQueuedFiles, isAdmin }) => {
  const classes = useStyles();
  const keyword = useSelector(getKeyword);
  const [dropzone, setDropzone] = useState(null);
  const [hideButton, setHideButton] = useState(true);
  const [errorModal, setErrorModal] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notifyModal, setNotifyModal] = useState(null);
  const [notify, setNotify] = useState(null);
  const importUrl = API_BASE.clone().segment(
    API_BASE.segment().concat(["event", keyword, "accesses", "import"])
  );
  const draftUrl = importUrl.clone().segment("draft");

  const refreshQueuedFiled = (force = false) => {
    const files = force ? dropzone.getAddedFiles() : dropzone.getQueuedFiles();
    ListenerQueuedFiles(files);
    setHideButton(files.length === 0);
  };

  const previewTemplate = (
    <Box className={clsx(classes.templateBoxDetails)}>
      <images.CsvFile className={classes.templateIcon} />
      <Box className={classes.templateDetails}>
        <Box className={classes.templateFilename} data-dz-name />
        <Box className={classes.templateSize} data-dz-size />
        <Box data-dz-errormessage className={classes.templateError} />
      </Box>
      <Box data-dz-remove className={classes.templateRemove}>
        <DeleteOutlineOutlinedIcon />
      </Box>
    </Box>
  );

  useEffect(() => {
    if (dropzone === null) {
      const { token } = getUserSession();
      const myDropzone = new Dropzone("#dropzone-import", {
        url: importUrl.toString(),
        headers: {
          "Wisembly-App-Id": APP_ID,
          "Wisembly-Token": token,
          "Cache-Control": null,
        },
        acceptedFiles:
          ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
        autoProcessQueue: false,
        previewTemplate: ReactDOMServer.renderToString(previewTemplate),
        error: function (file, message, xhr) {
          if (file.previewElement) {
            file.previewElement.classList.add("dz-error");
            if (xhr) {
              let jsonMessage = null;
              try {
                jsonMessage = JSON.parse(message);
              } catch (error) {}
              if ("wrong_format" === get(jsonMessage, "data.attributes.code")) {
                message = _t("Wrong format");
                setErrorMessage(
                  JSON.parse(get(jsonMessage, "data.attributes.message"))
                );
                setErrorModal(true);
              } else {
                message = get(
                  jsonMessage,
                  "data.attributes.message",
                  _t("An error has occurred")
                );
              }
            }
            for (let node of file.previewElement.querySelectorAll(
              "[data-dz-errormessage]"
            )) {
              node.textContent = message;
            }
          }
        },
      });

      setDropzone(myDropzone);
    }
  }, []);

  useEffect(() => {
    if (dropzone !== null) {
      dropzone.on("addedfile", function () {
        refreshQueuedFiled(true);
      });
      dropzone.on("removedfile", function () {
        refreshQueuedFiled();
      });
      dropzone.on("canceled", function () {
        refreshQueuedFiled();
      });
      dropzone.on("reset", function () {
        refreshQueuedFiled();
      });
      dropzone.on("success", function () {
        emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
          message: _t("Your file is sent, it is being processed"),
        });
      });
    }
  });

  const handleClick = () => {
    dropzone.on("sending", (file, xhr, formData) => {
      formData.set("notify", false);
      formData.set("default_scope", isAdmin ? "modo" : "user");
    });
    dropzone.processQueue();
  };

  return (
    <>
      <DialogSendNotify
        open={notifyModal}
        roleUpdateAction={(value) => {
          setNotify(value);
        }}
        notify={<NotifyInvitation count={2} />}
      />
      <DialogErrorNotify
        open={errorModal}
        setOpen={(value) => {
          setErrorModal(value);
        }}
        errors={errorMessage}
      />
      <Box className={classes.root}>
        <form id="dropzone-import" className={clsx("dropzone", classes.form)}>
          <Box className={clsx("dz-default", "dz-message", classes.defaultBox)}>
            <BackupOutlinedIcon className={classes.defaultIcon} />
            <Typography className={classes.defaultText}>
              {_t("Drag & drop")}
            </Typography>
            <Typography className={classes.defaultText}>{_t("Or")}</Typography>
            <Button
              className={classes.defaultButton}
              variant="contained"
              color="primary"
              size="large"
              bold
            >
              {_t("Choose a .csv file")}
            </Button>
          </Box>
        </form>
        <Typography className={classes.linkText}>
          <Link
            href={getSecuredUrl({ url: draftUrl.toString() })}
            target="_blank"
            rel="noopener"
            color="inherit"
          >
            {_t("Download a template")}
          </Link>
        </Typography>
      </Box>
      {!hideButton && (
        <Box className={classes.action}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={dropzone === null}
            onClick={() => handleClick()}
          >
            {_t("Importer")}
          </Button>
        </Box>
      )}
    </>
  );
};

FileImport.propTypes = {
  ListenerQueuedFiles: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default FileImport;
