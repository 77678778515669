import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase, Typography, useTheme } from "@material-ui/core";
import { _t } from "i18n";
import SendInput from "./reusables/SendInput";
import CustomSendIcon from "app/liveShopping/assets/icons/CustomSendIcon";
import FloatingHearts from "../FloatingHearts/FloatingHearts";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { usePostQuote } from "app/liveShopping/frontoffice/otm/containers/Chat/hooks";
import emitter from "utilities/emitter";
import { ADMIN_EVENT_FLASH_SUCCESS_VANISH } from "utilities/emitter/events";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
    padding: "0 10px 0 10px",
    position: "relative",
  },
  awaitingModeration: {
    color: "#ffffff",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SendArea = () => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles();
  const [message, setMessage] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [awaitingModeration, setAwaitingModeration] = useState(false);
  const postMessage = usePostQuote();
  const theme = useTheme();
  const handlePostQuote = () => {
    if (message.trim() === "") return;
    setIsSending(true);
    postMessage({ message }, (error, response) => {
      setIsSending(false);
      if (!error) setMessage("");
      if (response?.single?.attributes?.moderate === false) {
        setAwaitingModeration(true);
        emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, _t("Your message has been sent and is awaiting moderation!"));
      }
    });
  };

  const handlePressSend = (e) => {
    if (message.trim() === "" || e.keyCode !== 13) return;
    e.preventDefault();
    handlePostQuote();
  };

  useEffect(() => {
    if (!awaitingModeration) return;
    setTimeout(() => setAwaitingModeration(false), 1500);
  }, [awaitingModeration]);

  return (
    <Box className={classes.root}>
      {awaitingModeration && (
        <Box className={classes.awaitingModeration}>
          <Typography>{_t("Your message has been sent and is awaiting moderation!")}</Typography>
        </Box>
      )}

      {!awaitingModeration && (
        <>
          <SendInput message={message} setMessage={setMessage} onKeyPress={(e) => handlePressSend(e)} loading={isSending} />
          <ButtonBase onClick={handlePostQuote} disabled={message.length < 1 ? true : false}>
            <CustomSendIcon size={35} color={isMobile ? theme.palette.colors.white : theme.palette.colors.grey} />
          </ButtonBase>
        </>
      )}

      {isMobile && <FloatingHearts />}
    </Box>
  );
};

SendArea.propTypes = {};

SendArea.defaultProps = {};

export default SendArea;
