import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import clsx from "clsx";
import { markdownToHTML } from "core/utils/markdown";
import CustomChevronDown from "app/liveShopping/assets/icons/CustomChevronDown";
import CustomChevronUp from "app/liveShopping/assets/icons/CustomChevronUp";
import { ButtonBase } from "@material-ui/core";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "6px",
    maxHeight: (props) => props.isMobile && props.HEIGHT / "4",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    marginTop: theme.spacing(1),
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  content: {
    paddingRight: "15px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: theme.palette.colors.darkGrey,
  },
  textMore: {
    [theme.breakpoints.down("xs")]: {
      maxHeight: (props) => props.HEIGHT / "8",
      display: "block" /* or inline-block */,
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },
  button: {
    paddingTop: "10px",
    cursor: "pointer",
    alignSelf: "center",
  },
}));

const Description = ({ product }) => {
  const HEIGHT = window.innerHeight;
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile, HEIGHT });
  const [size, setSize] = useState(window.innerWidth <= 576 && product.description.length >= 133);

  const showText = () => {
    setSize(false);
  };
  const hideText = () => {
    setSize(true);
  };

  const more =
    window.innerWidth <= 576 && size === true ? (
      <ButtonBase className={clsx(classes.button, classes.content)} onClick={showText}>
        <CustomChevronDown size={14} />
      </ButtonBase>
    ) : (
      ""
    );
  const less =
    window.innerWidth <= 576 && size === false ? (
      <ButtonBase className={clsx(classes.button, classes.content)} onClick={hideText}>
        <CustomChevronUp />
      </ButtonBase>
    ) : (
      ""
    );
  return (
    <Box className={clsx("LandingPresentation", classes.root)}>
      <Typography
        className={clsx(size && classes.textMore, classes.content)}
        dangerouslySetInnerHTML={{
          __html: product && product.description && markdownToHTML(product.description),
        }}
      />
      {product.description && more} {product.description && less}
    </Box>
  );
};

Description.propTypes = {};

Description.defaultProps = {};

export default Description;
