export const getInitials = (firstname, lastname) => {
  if (!firstname) return "AN";
  if (!lastname) {
    return firstname.substr(0, 2).toUpperCase();
  }
  return firstname.substr(0, 1).toUpperCase() + lastname.substr(0, 1).toUpperCase();
};

export const getInitialsFromUser = (user) => {
  if (user?.name) return getInitialsFromString(user?.name);
  return getInitials(user?.firstname, user?.lastname);
};

export const getInitialsFromString = (name) => {
  const splitName = name.split(" ");
  return getInitials(splitName[0], splitName.length > 1 ? splitName.lastItem : null);
};
