import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import URI from "urijs";
import { API_BASE } from "config";
import basil from "core/basil";

import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useDispatch, useSelector } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import CustomCloseIcon from "app/liveShopping/assets/icons/CustomCloseIcon";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { applicationJSONCall } from "app/state/ducks/application/actions";
import { generateHash } from "utilities/uuid";

const useStyles = makeStyles((theme) => ({
  dialogShadow: {
    position: (props) => (props.isDesktop ? "absolute" : "fixed"),
    height: "100%",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, .6)",
    zIndex: 99,
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  dialog: {
    width: "80%",
    maxWidth: "500px",
    height: "auto",
    backgroundColor: "white",
    zIndex: 12,
    padding: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
  },
  close: {
    display: "flex",
    justifyContent: "end",
  },
  input: {
    padding: "10px 12px",
    border: "1px solid #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "130%",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    WebkitAppearance: "none",
    "&:focus": {
      outline: "none",
    },
    "&:placeholder": {
      color: "#808284",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "130%",
    },
  },
  button: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 30px",
    background: (props) => (props.registered ? theme.palette.colors.success : props.alreadyRegistered ? theme.palette.colors.error : theme.palette.colors.primary),
    borderRadius: "99px",
    margin: "auto",
  },
  buttonTypo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "100%",
    textAlign: "center",
    color: "#FFFFFF",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "130%",
    textAlign: "center",
    color: "#000000",
    marginTop: "20px",
  },
}));

const BeWarnedDialog = ({ setOpen }) => {
  const [registered, setRegistered] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const classes = useStyles({ registered, alreadyRegistered });
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const wiz = useSelector(currentWizSelector);
  const regexValidation = /\S+@\S+\.\S+/;
  const validation = email.length > 0 && regexValidation.test(email);

  const handleSend = () => {
    const url = new URI(API_BASE.toString().replace("/6", "/4")).segment("event").segment(wiz.keyword).segment("register").toString();

    dispatch(
      applicationJSONCall(
        url,
        {
          hash: generateHash(40),
          email,
        },
        {
          method: "POST",
          callback: (error, response) => {
            if (error?.statusCode === 409) {
              setAlreadyRegistered(true);
              basil.set(`u:r:${wiz.keyword}`, true);

              setTimeout(() => setOpen(false), 1200);
              return;
            }

            if (response) {
              setRegistered(true);
              basil.set(`u:r:${wiz.keyword}`, true);

              setTimeout(() => setOpen(false), 1200);
            }
          },
        }
      )
    );
  };

  return (
    <Box className={classes.dialogShadow} onClick={() => setOpen(false)}>
      <Box className={classes.dialog} onClick={(e) => e.stopPropagation()}>
        <Box className={classes.close}>
          <ButtonBase onClick={() => setOpen(false)}>
            <CustomCloseIcon />
          </ButtonBase>
        </Box>
        <Typography className={classes.title}>{_t("Be warned !")}</Typography>
        <input name="email" className={classes.input} type="text" placeholder="Email" value={email} autoFocus onChange={(e) => setEmail(e.target.value)} />

        <ButtonBase disabled={!validation} className={classes.button} onClick={handleSend}>
          <Typography className={classes.buttonTypo}>{registered ? _t("You're registered") : alreadyRegistered ? _t("You're already registered") : _t("Send")}</Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
};

BeWarnedDialog.propTypes = {};

BeWarnedDialog.defaultProps = {};

export default BeWarnedDialog;
