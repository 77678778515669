import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import isEmpty from "lodash/isEmpty";
import { _t } from "i18n";

import Box from "@material-ui/core/Box";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { resourceFetch } from "ressourcesDucks/actions";
import { resourceSelector } from "ressourcesDucks/selectors";
import {
  getKeyword,
  getFetchResultStatusSelector,
} from "applicationDucks/selectors";
import { getUrl } from "utilities/utils/media";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
    width: "100%",
  },
  slideBox: {
    backgroundColor: theme.palette.background.box,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    minHeight: theme.spacing(10),
    height: "100%",
    width: "100%",
    position: "relative",
    borderBottomLeftRadius: (props) => (props.rounded ? "10px" : 0),
    borderBottomRightRadius: (props) => (props.rounded ? "10px" : 0),
  },

  media: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    borderRadius: (props) => (props.rounded ? "10px" : 0),
  },
  iframe: {
    flex: 1,
  },
  selection: {
    display: (props) => (props.selected ? "flex" : "none"),
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0,0,0,0.35)",
  },
  checkicon: {
    fontSize: "50px",
  },
}));

export const ImagePresentation = ({
  media,
  layoutMode,
  hasFocus,
  selected,
  rounded,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isThumbnailView = layoutMode === "pip" && !hasFocus;
  const inPipScreenFocus = layoutMode === "pip" && hasFocus;
  const classes = useStyles({
    inPipScreenFocus,
    isThumbnailView,
    selected,
    rounded,
  });
  //it's better to fetch image by fetching the image's item directly
  if (isEmpty(media)) return <div>{_t("unable to get the media")}</div>;
  const isIframe =
    media.type === "embed" ||
    media.type === "video";
  let slideUrl = getUrl({
    isMobile,
    media,
    thumbnails: media.thumbnails,
    step: 0,
  });

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      className={clsx("MediaPresentation", classes.root)}
    >
      {isIframe ? (
        <Box display="flex" flexDirection="row" className={classes.iframe}>
          <CardMedia
            component="iframe"
            className={classes.media}
            src={slideUrl}
            alt={media.hash}
            allow="fullscreen"
            allowfullscreen={true}
            frameborder="0"
          />
        </Box>
      ) : (
        <Box display="flex" flex={1} className={classes.slideBox}>
          <CardMedia
            component="img"
            image={slideUrl}
            alt={media.hash}
            className={classes.media}
          />
          <Box className={classes.selection}>
            <CheckOutlinedIcon className={classes.checkicon} />
          </Box>
        </Box>
      )}
    </Box>
  );
};
ImagePresentation.propTypes = {
  media: PropTypes.object,
  mediaHash: PropTypes.string,
  keyword: PropTypes.string,
  fetchMediaStatus: PropTypes.string,
  resourceFetch: PropTypes.func,
  disabled: PropTypes.bool,
  layoutMode: PropTypes.string,
  hasFocus: PropTypes.bool,
  selected: PropTypes.bool,
  rounded: PropTypes.bool,
};

ImagePresentation.defaultProps = {
  disabled: false,
  selected: false,
  rounded: false,
};

const mapStateToProps = (state, ownProps) => {
  return {
    media: resourceSelector(state, {
      type: "Media",
      id: ownProps.mediaHash,
    }),
    keyword: getKeyword(state),
    fetchMediaStatus: getFetchResultStatusSelector(
      state,
      `media${ownProps.mediaHash}`
    ),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resourceFetch: bindActionCreators(resourceFetch, dispatch),
  };
};

export const SurveyItemImageViewer = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(ImagePresentation);
