import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    borderRadius: "0.5rem",
    border: `solid 1px ${theme.palette.colors.grey300}`,
    width: "100%",
    maxWidth: props => props.maxWidth,
    height: props => props.isGraph ? "22rem" : "8rem",
    overflow: "hidden",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.05)",
  },
  card: {
    width: "inherit",
    height: "inherit",
    borderRadius: "0.5rem",
    overflow: "hidden",
    borderWidth: "4px 0 0",
    border: `solid 1px ${theme.palette.colors.grey}`,
    border: `solid 1px ${theme.palette.colors.primary}`,
    padding: "1rem",
    position: "relative",
  },
}));

const Card = ({ children, maxWidth, isGraph }) => {
  const classes = useStyles({ maxWidth, isGraph });
  return (
    <div className={classes.cardWrapper}>
      <div className={classes.card}>
        {children}
      </div>
    </div>

  );
};

export default Card;