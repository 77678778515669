import { Box, CircularProgress, makeStyles, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import SideBar from "app/liveShopping/backoffice/otm/components/SideBar";
import { tabListOTO } from "app/liveShopping/backoffice/otm/components/SideBar/TabList";
import ProjectSwitcher from "./Component/ProjectSwitcher";
import { useDispatch, useSelector } from "react-redux";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { projectsSelector } from "app/state/ducks/liveShopping/selectors";
import { setCurrentProject } from "app/state/ducks/application/actions";
import { useParams } from "react-router-dom";
import ErrorComponent from "./Component/ErrorComponent";
import { _t } from "utilities/i18n";
import AppointmentMenu from "./Component/Groups/Appointment/AppointmentMenu/AppointmentMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
}));

const OneToOneLayout = ({ children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const currentProject = useSelector(currentProjectSelector);
  const projects = useSelector(projectsSelector);
  const { hash } = useParams();
  const [forbidden, setForbidden] = useState(false);

  useEffect(() => {
    dispatch(
      resourceFetch("project", {
        slug: ["projects"],
        once: true,
        callback: function (err, resp) {
          if (resp) {
            setLoading(false);
          }
        },
      })
    );
  }, []);


  useEffect(() => {
    if (!loading && hash) {
      const project = projects.find((project) => project.hash === hash);
      if (project) {
        dispatch(setCurrentProject(project));
        localStorage.setItem("projectId", project.id);
        return;
      }
      setForbidden(true)
    }
  }, [loading, hash]);

  const tabList = tabListOTO();

  return (
    <Box className={classes.root}>
      <SideBar TabList={tabList} option={ProjectSwitcher} retracted />
      {forbidden && <ErrorComponent text={_t("This project is forbidden for you")} />}
      {currentProject && children}
      {loading && <CircularProgress size={20} style={{ color: theme.palette.colors.primary }} />}
    </Box>
  );
};

export default OneToOneLayout;
