export const computeInfos = (carts) => {
  let currentCart, i;
  let cartsCreated;
  let sum = 0;
  let finalSum = 0;
  let paid = 0;
  let topProducts = [];
  let topVariants = [];
  let wentToPay = 0;
  let addedInCart = 0;
  for (i = 0; i < carts.length; i++) {
    currentCart = carts[i];
    sum += currentCart.sum;
    finalSum += currentCart.final_sum;
    addedInCart += currentCart.products.length;
    
    currentCart.products.map(({ product_id, variant_id }) => {
      if (!topProducts[product_id]) topProducts[product_id] = { id: product_id, count: 0 }
      if (!topVariants[variant_id]) topVariants[variant_id] = { id: variant_id, count: 0 }
      topProducts[product_id].count++;
      topVariants[variant_id].count++;
    });

    if (currentCart.proceeded_to_checkout_at) wentToPay++;
    
    if(currentCart.final_sum) paid++;
    
  }
  topProducts = Object.values(topProducts).sort((a,b) => b.count - a.count);
  topVariants = Object.values(topVariants).sort((a,b) => b.count - a.count);

  cartsCreated = carts.length;

  return {
    sum,
    finalSum,
    topProducts,
    topVariants,
    cartsCreated,
    topProducts,
    topVariants,
    wentToPay,
    paid,
    addedInCart
  }
}