import orm from "orm";

import { getUserSession } from "core/session";

export default function getDefaultState(state) {
  if (state) {
    return state;
  }

  const session = orm.session(state);

  // inject partial current User object from basil storage while fetching it fully with /me endpoint
  const { user_id, username, lang } = getUserSession();

  // always have a id: -1 anonymous user in store
  session.User.generate({ id: -1, name: "Anonymous", isAnonymous: true, lang });

  // generate temp one from session
  if (user_id) {
    session.User.generate({ id: user_id, name: username, lang });
  }

  return session.state;
}
