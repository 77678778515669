export const getPercent = (
  currentTimestamp,
  currentChapter,
  nextChapter,
  replayDuration
) => {
  const a = currentTimestamp - currentChapter?.timestamp;
  const b = nextChapter?.timestamp - currentChapter?.timestamp;
  const c = replayDuration - currentChapter?.timestamp;
  if (!nextChapter) {
    return (a / c) * 100;
  }
  return (a / b) * 100;
};

export const getTimeLivePercent = (now, live_start, live_stop) => {
  const start = new Date(live_start);
  const end = new Date(live_stop);
  return Math.round(((now - start) / (end - start)) * 100);
};

export const getCurrentChapter = (chapters, hash) => {
  if (!chapters || !chapters.length) return false;
  return chapters.find((chapter) => chapter.hash === hash);
};

export const getCurrentChapterIndex = (chapters, currentChapter) => {
  if (!chapters || !chapters.length) return -1;
  return chapters.findIndex((chapter) => currentChapter?.hash === chapter.hash);
};

export const getNextChapter = (chapters, currentChapter) => {
  try {
    return chapters[getCurrentChapterIndex(chapters, currentChapter) + 1];
  } catch (e) {
    return null;
  }
};

export const getChapterTime = (timestamp) => {
  let hours = Math.floor(timestamp / 60 / 60);
  let minutes = Math.floor(timestamp / 60) - hours * 60;
  let seconds = timestamp % 60;
  const chapterTime =
    minutes.toString().padStart(2, "0") +
    "'" +
    Math.round(seconds.toString().padStart(2, "0"));

  return chapterTime;
};
