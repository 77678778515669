import URI from "urijs";
import isEmpty from "lodash/isEmpty";

export const redirectToUrl = (url) => {
  if (isEmpty(url)) return;

  const newUrl = new URI(url).toString();

  window.location.href = newUrl;
};
