import React from "react";
import Box from "@material-ui/core/Box";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Check";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const EditButton = ({ onClick, editing }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root} onClick={onClick}>
      {editing ? <CheckIcon /> : <CreateIcon />}
    </Box>
  );
};

EditButton.propTypes = {
  onClick: PropTypes.func,
  editing: PropTypes.bool,
};

EditButton.defaultProps = {
  onClick: () => {},
  editing: false,
};

export default EditButton;
