import React, { useState, useMemo, useCallback, useEffect, memo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import uniq from "lodash/uniq";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { allColumns } from "adminComponents/UserManagement/constants";
import Pagination from "adminComponents/UserManagement/UserTablePagination";
import Header from "adminComponents/UserManagement/UserTableHeader";
import UserTableRow from "adminComponents/UserManagement/UserTableRow";
import {
  createUsersData,
  allOptionsChecked,
} from "adminComponents/UserManagement/utils";
import {
  usePaginatorUserDataDispatcher,
  useContextFilter,
} from "adminContainers/users/paginatorContext";
import forEach from "lodash/forEach";
import { onlineUsersSelector } from "applicationDucks/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.background.box,
    flexDirection: "column",
    justifyContent: "flex-start",
    //marginTop: "28px",
    marginBottom: "28px",
    alignItems: "center",
    paddingRight: "20px",
    paddingLeft: "20px",
    //overflowX: "auto",
  },
  table: {
    width: "100%",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  paginationRoot: {
    width: "100%",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    maxHeight: (props) =>
      props.admin ? "calc(100vh - 260px)" : "calc(100vh - 260px - 76px)",
    height: (props) =>
      props.admin ? "calc(100vh - 260px)" : "calc(100vh - 260px - 76px)",
    justifyContent: "space-between",
  },
  tableBody: {
    //height: "calc(100vh - 260px - 113px)",
  },
}));

const descendingComparator = (a, b, orderBy) => {
  //

  switch (orderBy) {
    case "online": {
      const aData = a[orderBy] ? 1 : 0;
      const bData = b[orderBy] ? 1 : 0;
      if (bData < aData) {
        return -1;
      }
      if (bData > aData) {
        return 1;
      }
      break;
    }
    case "invitation": {
      const aData = get(a, "access.mails.invitation_participant", "Send");
      const bData = get(b, "access.mails.invitation_participant", "Send");

      if (bData < aData) {
        return -1;
      }
      if (bData > aData) {
        return 1;
      }
      break;
    }
    case "rsvp": {
      const aData = get(a, "access.present", null);
      const bData = get(b, "access.present", null);

      if (bData < aData) {
        return -1;
      }
      if (bData > aData) {
        return 1;
      }
      break;
    }
    case "signature": {
      const aData = get(a, "signature.ref.has_signature", false);
      const bData = get(b, "signature.ref.has_signature", false);

      if (bData < aData) {
        return -1;
      }
      if (bData > aData) {
        return 1;
      }
      break;
    }

    default: {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
    }
  }
  return 0;
};

const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const UserTable = ({
  admin,
  users,
  visibleColumns,
  total,
  //loadMore,
  refresh,
  isLiveEvent,
  hasRegistrationEnabled,
}) => {
  const classes = useStyles({ admin });
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const paginatorUserDataDispatcher = usePaginatorUserDataDispatcher();
  //const paginatorContext = usePaginatorContextState();
  const filter = useContextFilter();
  const onlineUsers = useSelector(onlineUsersSelector);
  //reset page on filter change
  useEffect(() => {
    setPage(0);
  }, [JSON.stringify(filter)]);

  useEffect(() => {
    paginatorUserDataDispatcher({
      type: "UPDATE_SELECTION",
      selected: selectedRows,
    });
  }, [selectedRows.length]);

  useEffect(() => {
    paginatorUserDataDispatcher({
      type: "UPDATE_USES_DATA",
      usersData: users,
    });
  }, [users]);
  //take connected user list
  const columns = useMemo(() => {
    return allColumns.filter((col) => {
      if (
        col?.conditionallyActivated === "registration" &&
        !hasRegistrationEnabled
      ) {
        return false;
      }

      return visibleColumns.includes(col.id);
    });
  }, [JSON.stringify(visibleColumns), hasRegistrationEnabled]);
  const rows = useMemo(() => {
    return createUsersData(users, columns);
  }, [JSON.stringify(columns), users]);

  //update selectedRows after bulk delete for instance
  useEffect(() => {
    if (!isEmpty(selectedRows)) {
      const ids = rows.map((elem) => elem.id);
      let newList = [...selectedRows];
      forEach(selectedRows, (item) => {
        if (!ids.includes(item)) {
          newList = newList.filter((elem) => elem !== item);
        }
        if (isEmpty(newList)) {
          return false;
        }
      });
      setSelectedRows(newList);
    }
  }, [rows]);

  const filteredRows = useMemo(() => {
    let finalRows = rows;
    if (filter.search) {
      finalRows = rows.filter(
        (elem) =>
          elem.name.includes(filter.search) ||
          elem.email.includes(filter.search)
      );
    }
    if (
      filter.onlineFilter &&
      !allOptionsChecked("online", filter.onlineFilter)
    ) {
      const online = filter.onlineFilter.includes("online");
      //save take online users list and send them only
      finalRows = rows.filter((elem) =>
        online ? onlineUsers[elem.id] : !onlineUsers[elem.id]
      );
    }
    if (
      filter.sendingStatusFilter &&
      !allOptionsChecked("sendingStatus", filter.sendingStatusFilter)
    ) {
      let allMailsStatuses = [];
      finalRows = finalRows.filter((elem) => {
        if (!filter.emailsFilter) {
          allMailsStatuses = get(elem.access, "mails", {});
        } else {
          allMailsStatuses = {
            mail: get(elem.access.mails, filter.emailsFilter, ""),
          };
        }
        return Object.values(allMailsStatuses).some((item) =>
          filter.sendingStatusFilter.includes(item)
        );
      });
    }

    if (filter.emailsFilter && filter.emailsFilter !== "") {
      finalRows = finalRows.filter((elem) => {
        const status = get(elem.access, `mails.${filter.emailsFilter}`, null);
        return status !== null;
      });
    }

    if (
      filter.sendingOriginFilter &&
      !allOptionsChecked("sendingOrigin", filter.sendingOriginFilter)
    ) {
      finalRows = finalRows.filter((elem) => {
        const status = get(elem.access, "origin");
        return filter.sendingOriginFilter.includes(status);
      });
    }

    if (filter.rsvpFilter && !allOptionsChecked("rsvp", filter.rsvpFilter)) {
      finalRows = finalRows.filter((elem) => {
        let status = get(elem.access, "present", null);
        if (status) {
          status = "willcome";
        } else if (status === null) {
          status = "notanswered";
        } else {
          status = "wontcome";
        }
        return filter.rsvpFilter.includes(status);
      });
    }
    return finalRows;
  }, [admin, filter, rows, onlineUsers.length]);

  const isSelected = (name) => selectedRows.indexOf(name) !== -1;
  const contextTotal = Math.min(total, filteredRows.length);
  const maxPage = Math.ceil(contextTotal / rowsPerPage);

  const handleChangePage = (newPage) => {
    //dont' need this for now because we load all data at start
    // if (
    //   newPage > page &&
    //   newPage <= maxPage &&
    //   filteredRows.length < contextTotal
    // ) {
    //   loadMore(rowsPerPage);
    // }
    setPage(newPage);
  };

  const handleChangeRowPerPage = (rowsCount) => {
    setRowsPerPage(parseInt(rowsCount, 10));
    refresh(parseInt(rowsCount, 10));
    setPage(0);
  };

  const allSelectedOnPage = useMemo(() => {
    const pageElements = filteredRows.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    const validRows = pageElements.filter((row) => !isEmpty(row));
    let newSelecteds = validRows.map((n) => n.id);
    let result = true;
    forEach(newSelecteds, (item) => {
      if (!selectedRows.includes(item)) {
        result = false;
        return false;
      }
    });
    return !isEmpty(selectedRows) && result;
  }, [page, rowsPerPage, filteredRows, selectedRows]);

  //based on page
  const handlePageSelectAllClick = useCallback(
    (event) => {
      const pageElements = filteredRows.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
      const validRows = pageElements.filter((row) => !isEmpty(row));
      let newSelecteds = validRows.map((n) => n.id);
      if (event.target.checked) {
        setSelectedRows(uniq([...selectedRows, ...newSelecteds]));
        return;
      } else {
        newSelecteds = selectedRows.filter(
          (elem) => !newSelecteds.includes(elem)
        );
        setSelectedRows(newSelecteds);
      }
    },
    [page, rowsPerPage, filteredRows, selectedRows]
  );

  const handleSelect = useCallback(
    (event, name) => {
      const selectedIndex = selectedRows.indexOf(name);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedRows, name);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      setSelectedRows(newSelected);
    },
    [JSON.stringify(selectedRows)]
  );
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";

    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //const header = createHeader({columns})
  return (
    <Box className={clsx("UserTable", classes.root, classes.tableContainer)}>
      <TableContainer component={Paper}>
        <Table stickyHeader className={classes.table} aria-label="simple table">
          <TableHead>
            <Header
              allSelectedOnPage={allSelectedOnPage}
              columns={columns}
              onSelectAllClick={handlePageSelectAllClick}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
          </TableHead>
          <TableBody classes={{ root: classes.tableBody }}>
            {(rowsPerPage > 0
              ? stableSort(filteredRows, getComparator(order, orderBy)).slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredRows
            ).map((row) => {
              if (isEmpty(row)) return null;
              const selected = isSelected(row.id);
              return (
                <UserTableRow
                  key={row.id}
                  columns={columns}
                  row={row}
                  selected={selected}
                  handleClick={handleSelect}
                  isLiveEvent={isLiveEvent}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        rowsPerPage={rowsPerPage}
        count={total}
        page={page}
        maxPage={maxPage}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowPerPage}
      />
    </Box>
  );
};
UserTable.propTypes = {
  admin: PropTypes.bool,
  users: PropTypes.array,
  visibleColumns: PropTypes.array,
  total: PropTypes.number,
  //loadMore: PropTypes.func,
  refresh: PropTypes.func,
  isLiveEvent: PropTypes.bool,
  hasRegistrationEnabled: PropTypes.bool,
};
UserTable.defaultProps = {
  users: [],
  visibleColumns: [],
  total: 0,
  //loadMore: () => {},
  refresh: () => {},
  isLiveEvent: false,
  hasRegistrationEnabled: false,
};
// UserTable.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: "UserTable",
// };

const arePropsEqual = (prev, next) => {
  const result =
    isEqual(prev.admin, next.admin) &&
    isEqual(prev.users, next.users) &&
    isEqual(prev.visibleColumns, next.visibleColumns) &&
    isEqual(prev.total, next.total);
  return result;
};

export default memo(UserTable, arePropsEqual);
