import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import OneSwitch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import { useDispatch } from "react-redux";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { _t } from "utilities/i18n";
import emitter from "utilities/emitter";
import { Select } from "app/views/components/Mui";

const Options = ({ project }) => {
  const [cartIntegration, setCartIntegration] = useState(project.cart_integration);
  const [blurVisitior, setBlurVisitor] = useState(project.options?.blur_visitor || false);
  const [skipChoiceScreen, setSkipChoiceScreen] = useState(project.options?.skip_choice_screen || false);
  const [userMethods, setUserMethods] = useState(project.options?.user_methods || ["video", "audio", "chat"]);
  const [widgetMode, setWidgetMode] = useState(project.options?.widget_mode || "always");
  const [appointmentContact, setAppointmentContact] = useState(project.options?.appointment_contact || "email");
  const [ring, setRing] = useState(project.options?.ring || false);
  const dispatch = useDispatch();

  const handleOptions = () => {
    const options = {
      ...project.options,
      skip_choice_screen: !skipChoiceScreen,
    };

    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        setSkipChoiceScreen(!skipChoiceScreen);
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };
  const handleRing = () => {
    const options = {
      ...project.options,
      ring: !ring,
    };

    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        setRing(!ring);
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  const handleCartIntegration = () => {
    const data = {
      cart_integration: !cartIntegration,
    };

    dispatch(resourceEdit(Object.assign(project, data), {
      slug: ["projects", project.id],
      patch: ["cart_integration"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        setCartIntegration(!cartIntegration);
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  const handleblurVisitor = () => {
    const options = {
      ...project.options,
      blur_visitor: !blurVisitior,
    };

    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        setBlurVisitor(!blurVisitior);
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  const handleUserMethods = (method) => {
    let methods = userMethods;

    if (methods.includes(method)) {
      methods = methods.filter(m => m !== method);
    } else {
      methods.push(method);
    }

    const options = {
      ...project.options,
      user_methods: methods,
    };

    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        setUserMethods([...methods]);
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  const handleWidgetMode = (mode) => {
    const data = {
      widget_mode: mode,
    };

    console.log(data);

    dispatch(resourceEdit(Object.assign(project, data), {
      slug: ["projects", project.id],
      patch: ["widget_mode"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        setWidgetMode(mode);
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  const handleAppointmentContact = (mode) => {
    const options = {
      ...project.options,
      appointment_contact: mode,
    };

    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        setAppointmentContact(mode);
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  return (
    <Box>
      <Box>
        <Typography>{_t("Enable cart integration on this project")}</Typography>
        <OneSwitch onChange={handleCartIntegration} checked={cartIntegration} />

        <Typography>{_t("Widget display")}</Typography>
        <Select
          value={widgetMode}
          options={[
            { value: "always", label: _t("Always displayed") },
            { value: "auto", label: _t("Displayed only when vendor is online") },
          ]}
          name="type"
          onChange={e => handleWidgetMode(e.target.value)}
        />

        <Typography>{_t("Appointment contact preference")}</Typography>
        <Select
          value={appointmentContact}
          options={[
            { value: "both", label: _t("Both") },
            { value: "sms", label: _t("SMS") },
            { value: "email", label: _t("Email") },
          ]}
          name="type"
          onChange={e => handleAppointmentContact(e.target.value)}
        />

        <Typography>{_t("Disable Schedule/Live chat choice screen (directly go to live chat)")}</Typography>
        <OneSwitch onChange={handleOptions} checked={skipChoiceScreen} />

        <Typography>{_t("Automatically blur the visitor")}</Typography>
        <OneSwitch onChange={handleblurVisitor} checked={blurVisitior} />

        <Typography>{_t("Allowed user setup")}</Typography>
        <Box>
          {_t("Video")} <OneSwitch onChange={() => handleUserMethods('video')} checked={userMethods.includes("video")} />
          {_t("Audio only")} <OneSwitch onChange={() => handleUserMethods('audio')} checked={userMethods.includes("audio")} />
          {_t("Chat only")} <OneSwitch onChange={() => handleUserMethods('chat')} checked={userMethods.includes("chat")} />
          {_t("Ring")} <OneSwitch onChange={handleRing} checked={ring} />
        </Box>
      </Box>
    </Box>
  )
}

export default Options;
