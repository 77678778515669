import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { _t } from "i18n";
import UrlComponent from "./UrlComponent";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useSelector } from "react-redux";
import { groupsSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
}));

const UrlGestion = ({ formValues, setFormValues, hashGroup }) => {
  const classes = useStyles();
  const groups = useSelector(groupsSelector);
  const theme = useTheme();

  const addFormFields = () => {
    setFormValues([...formValues, { type: "string", url: "" }]);
  };

  return (
    <>
      {formValues.map((item, index) => (
        <UrlComponent typeValue={item.type} urlValue={item.url} index={index} formValues={formValues} setFormValues={setFormValues} />
      ))}

      <Button color={theme.palette.colors.grey400} onClick={addFormFields}>
        {_t("Add url")}
      </Button>
    </>
  );
};

UrlGestion.propTypes = {};

UrlGestion.defaultProps = {};

export default UrlGestion;
