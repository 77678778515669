import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import emitter from "core/emitter";
import { EVENT_ANIM_SURVEY_PROJECTION_DISPLAY_OPTIONS } from "core/emitter/events";
import { currentWizSelector } from "ressourcesDucks/selectors";

import { Panel } from "adminComponents/Mui";

import {
  ProjectionDisplayOptions,
  ChoicesQuestion,
} from "adminComponents/Animation/Interactivities";
import { getLayouts } from "appContainers/Present/utils";

const PANEL_CHOICES_OF_Q = "choicesOf";
const PANEL_DETAILS_OF_Q = "detailsOf";

const AnimationPanel = ({ liveElement }) => {
  const [displayOptions, setDisplayOptions] = useState(null);
  const [itemSelected, setItemSelected] = useState(0);
  const [survey, setSurvey] = useState(null);

  useEffect(() => {
    const callback = ({ survey }) => {
      setDisplayOptions(null, setSurvey(survey));
    };

    emitter.on(EVENT_ANIM_SURVEY_PROJECTION_DISPLAY_OPTIONS, callback);
    return () =>
      emitter.off(EVENT_ANIM_SURVEY_PROJECTION_DISPLAY_OPTIONS, callback);
  }, []);

  useEffect(() => {
    if (survey === null) {
      setDisplayOptions(null);
      return;
    }

    if (survey.type === "poll") {
      setItemSelected(0, setDisplayOptions(PANEL_DETAILS_OF_Q));
      return;
    }

    setDisplayOptions(PANEL_CHOICES_OF_Q);
  }, [survey]);

  const handleSelectionOfQ = (index) => {
    setItemSelected(index, setDisplayOptions(PANEL_DETAILS_OF_Q));
  };

  return (
    <Panel
      open={displayOptions !== null}
      handleClose={() => setDisplayOptions(null)}
    >
      {displayOptions === PANEL_DETAILS_OF_Q && (
        <ProjectionDisplayOptions
          survey={survey}
          itemSelected={itemSelected}
          handleBackToChoicesOfQ={() => setDisplayOptions(PANEL_CHOICES_OF_Q)}
        />
      )}
      {displayOptions === PANEL_CHOICES_OF_Q && (
        <ChoicesQuestion
          survey={survey}
          handleOpenDetails={(index) => handleSelectionOfQ(index)}
          liveElement={liveElement}
        />
      )}
    </Panel>
  );
};

AnimationPanel.propTypes = {
  liveElement: PropTypes.object,
};

const mapStateToProps = (state) => {
  const event = currentWizSelector(state);
  const live = getLayouts(event, null, true);

  return {
    liveElement: live.interactivity,
  };
};

export default connect(mapStateToProps)(AnimationPanel);
