import React from "react";
import { Box, useTheme } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import CustomChevronCompactRight from "liveShopping/assets/icons/CustomChevronCompactRight";
import { setViewedProduct } from "liveShoppingDucks/actions";
import { _t } from "utilities/i18n";
import Infos from "./Infos";
import Pulse from "../Pulse";
import ProductCardCTA from "../ProductCardCTA";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "133px",
    maxHeight: "133px",
    background: theme.palette.colors.white,
    borderRadius: "10px",
    border: "1px solid #F0F0F4",
    marginBottom: "10px",

    cursor: (props) => (props.desktopLayout ? "pointer" : "initial"),
    position: "relative",
  },
  img: {
    width: "85px",
    height: "100%",
    backgroundImage: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
}));

const ExpandedCard = ({ product, focusedProducts }) => {
  const classes = useStyles({ backgroundImage: product?.variants[0]?.picture_url || "" });
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleProduct = () => dispatch(setViewedProduct(product));

  const focusedProduct = focusedProducts.find(({ hash }) => hash === product.hash);

  return (
    <Box className={classes.card} onClick={handleProduct} key={product.id}>
      {focusedProduct && <Pulse />}
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "95px 5fr 0.7fr",
          width: "100%",
          alignItems: "center",
        }}
      >
        <Box className={classes.img} />
        <Box
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "10px",
              padding: "6px",
              height: "100%",
              paddingBottom: "10px",
              paddingTop: "10px",
            }}
          >
            <Infos product={product} />
            <ProductCardCTA product={product} large />
          </Box>
        </Box>
        <CustomChevronCompactRight size={18} color={theme.palette.colors.grey} />
      </Box>
    </Box>
  );
};

ExpandedCard.propTypes = {};

export default ExpandedCard;
