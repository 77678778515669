import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import get from "lodash/get";
import map from "lodash/map";
import PercentIndicator from "./PercentIndicator";
import { SurveyItemImageViewer } from "appContainers/Viewers";
import { getMediaHashFromImage } from "utilities/utils/media";
import { getParsedPercent } from "utilities/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  optionBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(43.25),
      width: theme.spacing(30),
      marginRight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(23.25),
      width: theme.spacing(17),
    },
    marginBottom: theme.spacing(3),
  },
  description: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(1),
    width: "100%",
    height: theme.spacing(3.75),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(3.75),
    },
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    color: theme.palette.text.primary,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
    },
  },
  main: {
    cursor: (props) => (props.canAnswer ? "pointer" : "not-allowed"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: "auto",
    width: "100%",
    borderRadius: "8px",
    // borderBottomLeftRadius: "8px",
    // borderBottomRightRadius: "8px",
  },
  optionLabel: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "2.13",
    letterSpacing: "0.78px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  imageBox: {
    width: "100%",
    //flex: "auto",
    display: "flex",
    height: theme.spacing(30.375),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(17.25),
    },
    //height: theme.spacing(20),
  },
  content: {},
  itemLabel: {
    marginLeft: theme.spacing(2),
  },
  detailsPercent: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
    width: "100%",
    marginTop: "15px",
  },

  percentText: {
    width: "45px",
    textAlign: "right",
  },
}));

const ImageSurveyItemResult = ({ item, survey }) => {
  const classes = useStyles();

  const options = get(item, "options.choices", []);
  const images = get(item, "options.images", []);
  const summary = get(item, "summary", {});

  const result = map(options, (option, key) => {
    const percent = get(summary, `${get(option, "hash", "hash")}.percent`);
    return (
      <Box key={key} className={clsx(classes.optionBlock)}>
        <Box className={clsx(classes.main)}>
          <Box className={classes.imageBox}>
            <SurveyItemImageViewer
              mediaHash={getMediaHashFromImage(get(images, `${option.hash}`))}
              rounded
            />
          </Box>
          <Box className={clsx(classes.description)}>
            <Typography className={classes.optionLabel}>
              {option.label}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            flexDirection="row"
            className={classes.detailsPercent}
          >
            <PercentIndicator percent={percent} size="extra" />
            <Box ml={1} className={classes.percentText}>{`${getParsedPercent(
              percent
            )}%`}</Box>
          </Box>
        </Box>
      </Box>
    );
  });

  return (
    <Box className={clsx("ImageSurveyItemResult", classes.container)}>
      {result}
    </Box>
  );
};

ImageSurveyItemResult.propTypes = {
  item: PropTypes.object,
  survey: PropTypes.object,
};

export default ImageSurveyItemResult;
