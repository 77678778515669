import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import dispatch from "app/state/ducks/ressources/sagas/dispatch";
import { endCallVisitor } from "./service";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "20px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "100%",
  },
  info: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "130%",
    color: theme.palette.colors.grey500,
  },
  cta: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
  },
  cancelButton: {
    background: theme.palette.colors.grey500,
  },
}));

const RemoveVisitor = ({ setOpenRemoveVisitor, visitor }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleRemove = () => {
    endCallVisitor(dispatch, visitor, () => {
      setOpenRemoveVisitor(false);
    });
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Remove a visitor")}</Typography>
      <Typography className={classes.info}>{_t("This visitor is not here, remove it from the queue")}</Typography>

      <Box className={classes.cta}>
        <Button clsName={classes.cancelButton} onClick={() => setOpenRemoveVisitor(false)}>{_t("Cancel")}</Button>
        <Button onClick={handleRemove}>{_t("Remove")}</Button>
      </Box>
    </Box>
  );
}

export default RemoveVisitor;