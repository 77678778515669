import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { _t } from "i18n";
import { withWiz } from "hoc/Wiz";
import { withUser } from "hoc/User";
import { resourceFetch, resourceCreate } from "ressourcesDucks/actions";
import { resourceSelector } from "ressourcesDucks/selectors";
import {
  getFetchResultStatusSelector,
  ottStateSelector,
} from "applicationDucks/selectors";
import { setError, setKeyword, eventJoin } from "applicationDucks/actions";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import basil from "core/basil";
import emitter from "core/emitter";
import {
  GO_TO_WIZ_HOME,
  REFRESH_WIZ_DATA,
  EVENT_INVALID_OTT,
} from "core/emitter/events";

import { bindActionCreators } from "redux";
import { setConnection } from "applicationDucks/actions";
import { getConnection } from "applicationDucks/selectors";
import ResourceFactory from "orm/resources";
import {
  UnverifiedUserWall,
  UserNotInvitedWall,
  PasswordRequieredWall,
  WrongMailDomainWall,
  InvalidOttWall,
} from "appComponents/Firewall";
import AppLoader from "appComponents/AppLoader";

import memo, { renderLog } from "core/memo";
import { isWizAccessible } from "appComponents/Header/WizState";
import ModalHybrid from "appComponents/Landing/ModalHybrid";

const styles = (theme) => ({
  root: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100%",
    background: theme.palette.background.default,
  },
  loading: {
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
});

class Firewall extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      eventFinished: false,
      fetchingWizFromPassword: false,
      alreadyCheckPassword: false,
      hybridMode: basil.get("u.hybridModeChoice"),
    };
  }

  // update keyword in redux ASAP!!
  componentWillMount() {
    const { keyword, dispatch } = this.props;
    dispatch(setKeyword(keyword));
  }

  componentDidMount() {
    emitter.on(GO_TO_WIZ_HOME, () => this.forceUpdate());
    emitter.on(REFRESH_WIZ_DATA, () => {
      this.setState({ error: null, landingError: null });
      this.loadData();
      //this.forceUpdate()
    });

    this.loadData();
  }

  loadData = () => {
    const { keyword, dispatch } = this.props;

    dispatch(
      resourceFetch("event", {
        slug: ["event", keyword],
        XHR_ID: `event_${keyword}`,
        callback: (error) => {
          console.log("error callback", error)
          if (error) {
            dispatch(setError(error));
            this.setState({ error: error });
          }


          dispatch(eventJoin(keyword));
        },
        silent: true,
      })
    );
  };

  fetchWizFromPassword({ password, keyword }, forceUpdate = false) {
    try {
      let submitObject = {
        keyword,
        password,
      };

      const newPassword = this.props.resourceFactory.create(
        "Custom",
        submitObject
      );
      this.props.resourceCreate(newPassword, {
        slug: ["event", "check-password"],
        query: [
          {
            key: "keyword",
            value: keyword,
          },
        ],
        XHR_ID: `submit_wiz_password_${keyword}`,
        callback: (error) => {
          if (!error) {
            basil.set(`${keyword}.password`, password);
            if (forceUpdate) {
              this.forceUpdate();
            } else {
              this.setState({ alreadyCheckPassword: true });
            }
          } else {
            basil.remove(`${keyword}.password`);
            this.setState({ alreadyCheckPassword: false });
          }
        },
        silent: true,
      });
    } catch (e) {
      console.log("catch error in wiz", e);
    }
  }

  toBeLogged() {
    basil.set("u:redirectToWiz", this.props.keyword);

    this.props.setConnection({
      displayModal: true,
      forceConnection: true,
    });
  }

  render() {
    const {
      classes,
      wiz,
      landing,
      fetchWizStatus,
      fetchSubmitWizPasswordState,
      keyword,
      user,
      invalidOtt,
      forModule,
    } = this.props;

    const { error, fetchingWizFromPassword, alreadyCheckPassword } = this.state;
    let forceLanding = false;

    renderLog("WizContainer");

    if (error) {
      switch (error.statusText) {
        case "event_requires_to_be_logged":
        case "event_requires_to_be_logged_to_check_your_network":
          if (!this.props.connection.displayModal && wiz) {
            this.toBeLogged();
          }
          break;

        case "event_requires_verified_account":
        case "event_requires_to_be_verified_to_check_your_network":
          return <UnverifiedUserWall keyword={keyword} />;

        case "event_requires_you_to_be_invited":
          return <UserNotInvitedWall />;

        case "event_requires_you_to_be_in_authorized_network":
          return <WrongMailDomainWall />;

        case "check_password": {
          //check if password is saved in storage and try to fetch wiz with password
          const savedPassword = basil.get(`${keyword}.password`);
          if (alreadyCheckPassword) {
            break;
          }

          if (!wiz && !isEmpty(savedPassword) && !fetchingWizFromPassword) {
            this.setState({ fetchingWizFromPassword: true });
            this.fetchWizFromPassword(
              {
                password: savedPassword,
                keyword: this.props.keyword,
              },
              true
            );
          }

          return (
            <PasswordRequieredWall
              alreadyCheckPassword={() =>
                this.setState({ alreadyCheckPassword: true })
              }
            />
          );
        }

        case "event_finished":
        case "event_not_started_yet":
          if (!wiz) {
            break;
          }

          // only connected
          if (wiz.is_protected) {
            if (user.isAnonymous && !this.props.connection.displayModal) {
              this.toBeLogged();
              return null;
            }

            if (!user.isAnonymous && !user.is_verified) {
              return <UnverifiedUserWall keyword={keyword} />;
            }
          }

          // network protection
          if (
            !user.isAnonymous &&
            wiz.is_network_protected &&
            !wiz.networks.includes(user.email.split("@").pop())
          ) {
            return <WrongMailDomainWall />;
          }

          // password protection
          if (wiz.is_password_protected) {
            const savedPassword = basil.get(`${keyword}.password`);

            if (isEmpty(savedPassword)) {
              return (
                <PasswordRequieredWall
                  alreadyCheckPassword={() =>
                    this.setState({ alreadyCheckPassword: true })
                  }
                />
              );
            } else if (!fetchingWizFromPassword && !alreadyCheckPassword) {
              this.setState({ fetchingWizFromPassword: true });
              this.fetchWizFromPassword({
                password: savedPassword,
                keyword: this.props.keyword,
              });
            }
          }

          //just go to landing
          basil.set(`p:alreadySeenLanding_${wiz.keyword}`, false);
          forceLanding = true;

          break;

        case 'event_not_found':
          return (
            <Box className={clsx("Error", classes.root)}>{_t("Live not found")}</Box>
          );

        default:
          return (
            <Box className={clsx("Error", classes.root)}>{_t("Error")}</Box>
          );
      }
    }

    const alreadySeenLanding = basil.get(`p:alreadySeenLanding_${keyword}`);

    if (
      !wiz && (
        isEqual(fetchWizStatus, "pending") ||
        isEmpty(fetchWizStatus) ||
        isEqual(fetchSubmitWizPasswordState, "pending") ||
        (this.state.error &&
          this.state.error.statusText === "check_password" &&
          isEmpty(fetchSubmitWizPasswordState))
      )
    ) {
      // liveshopping: do not mention anything about a Wiz
      return <AppLoader />;
    }

    if (!wiz) {
      return (
        <Box className={clsx("Loading", classes.root)}>
          <Typography className={classes.loading}>
            {_t("This is not a valid event.")}
          </Typography>
        </Box>
      );
    }

    if (
      wiz &&
      wiz.is_hybrid &&
      isEmpty(this.state.hybridMode) &&
      isWizAccessible(wiz.start, wiz.stop) &&
      !forModule
    ) {
      return (
        <ModalHybrid
          wiz={wiz}
          onUserChoice={(value) => this.setState({ hybridMode: value })}
        />
      );
    }

    const showLanding =
      (!isEmpty(landing) && !alreadySeenLanding && wiz.landing_enabled) ||
      (forceLanding && wiz.landing_enabled);

    return this.props.children({
      showLanding,
      landing,
      wiz,
    });
  }
}

Firewall.propTypes = {
  dispatch: PropTypes.func,
  classes: PropTypes.object,
  wiz: PropTypes.object,
  landing: PropTypes.object,
  fetchWizStatus: PropTypes.string,
  keyword: PropTypes.string,
  setConnection: PropTypes.func,
  connection: PropTypes.object,
  resourceFactory: PropTypes.object,
  resourceCreate: PropTypes.func,
  fetchSubmitWizPasswordState: PropTypes.string,
  user: PropTypes.object,
  invalidOtt: PropTypes.bool,
  forModule: PropTypes.bool,
  children: PropTypes.func,
};
Firewall.defaultProps = {
  forModule: false,
};

function mapStateToProps(state, { keyword }) {
  return {
    connection: getConnection(state),
    keyword,
    landing: resourceSelector(state, {
      type: "Landing",
      id: keyword,
    }),
    fetchSubmitWizPasswordState: getFetchResultStatusSelector(
      state,
      `submit_wiz_password_${keyword}`
    ),
    fetchWizStatus: getFetchResultStatusSelector(state, `event_${keyword}`),
    resourceFactory: new ResourceFactory(state),
    invalidOtt: ottStateSelector(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setConnection: bindActionCreators(setConnection, dispatch),
    resourceCreate: bindActionCreators(resourceCreate, dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withWiz,
  withUser,
  withStyles(styles)
)(memo(Firewall));
