import React, { useEffect, useReducer, useState } from "react";
import Popper from "appComponents/Mui/Popper";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import EditButton from "../EditButton";
import Switch from "@material-ui/core/Switch";
import { _t } from "i18n";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { Radio } from "appComponents/Mui";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import images from "images";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import { generateButtonGroup } from "./utils";

const useStyles = makeStyles((theme) => ({
  buttonsEditor: {
    width: "423px",
    height: "500px",
    background: "#FFF",
    borderRadius: "5px",
    padding: "15px",
    overflowY: "scroll",
  },
  box: {
    background: "rgba(7, 105, 141, 0.1);",
    borderRadius: "5px",
    margin: "10px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      color: "#07698D",
      marginLeft: "auto",
      marginRight: "10px",
    },
  },
  label: {
    color: "#07698D",
    "& svg": {
      marginRight: "5px",
    },
  },
  rsvpOption: {
    padding: "10px",
    marginTop: "0px",
    "& .MuiFormLabel-root": {
      color: "#07698D",
      margin: "10px 0",
      fontSize: "15px",
      lineHeight: "14px",
      fontWeight: "600",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-root": {
      background: "#FFF",
      "& .MuiInputBase-input": {
        padding: "12.5px 10px",
      },
    },
  },
  title: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "15px",
    textAlign: "center",
    letterSpacing: "0.5px",
    margin: "15px 0",
  },
  description: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "15px",
    textAlign: "center",
    letterSpacing: "0.5px",
  },
}));

const RsvpStyleOption = ({ value, type, state, dispatch, icon, label }) => {
  const option = state[type];
  const classes = useStyles();

  const setType = () => {
    dispatch({
      type: "SET_TYPE",
      option: type,
      payload: value,
    });
  };

  return (
    <FormControlLabel
      control={
        <Radio
          color="secondary"
          checked={option.type === value}
          onChange={setType}
        />
      }
      label={
        <Box className={classes.label} display="flex" alignItems="center">
          {icon} {label}
        </Box>
      }
      labelPlacement="end"
    />
  );
};

const OptionBox = ({ type, state, dispatch, canSetHref = false }) => {
  const classes = useStyles();
  const option = state[type];

  const setLabel = (e) => {
    dispatch({
      type: "SET_LABEL",
      option: type,
      payload: e.target.value,
    });
  };

  const setHref = (e) => {
    dispatch({
      type: "SET_HREF",
      option: type,
      payload: e.target.value,
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mt={3}
      width="100%"
      className={classes.rsvpOption}
    >
      <InputLabel>{_t("Text")}</InputLabel>
      <OutlinedInput
        fullWidth
        value={option.label}
        onChange={setLabel}
        variant="contained"
      />
      <FormLabel component="legend" className={classes.label}>
        {_t("Style")}
      </FormLabel>
      {[
        {
          value: "button",
          label: _t("Button"),
          icon: <images.EmailEditorButton />,
        },
        { value: "link", label: _t("Link"), icon: <images.EmailEditorLink /> },
      ].map((styleOption) => (
        <RsvpStyleOption
          key={type}
          type={type}
          value={styleOption.value}
          icon={styleOption.icon}
          label={styleOption.label}
          state={state}
          dispatch={dispatch}
        />
      ))}
      {canSetHref && (
        <>
          <InputLabel>{_t("Link")}</InputLabel>
          <OutlinedInput
            fullWidth
            value={option.href}
            onChange={setHref}
            variant="contained"
          />
        </>
      )}
    </Box>
  );
};

const ButtonOption = ({
  state,
  dispatch,
  type,
  label,
  disabled,
  defaultValues,
}) => {
  const classes = useStyles();
  const toggleOpened = () => {
    setOpened(!opened);
  };
  const [opened, setOpened] = useState(false);
  const { enabled } = state[type];
  const toggleEnabled = () => {
    dispatch({
      type: "SET_ENABLED",
      option: type,
      payload: !enabled,
    });
  };

  return (
    <Box className={classes.box}>
      <Box className={classes.header}>
        <Switch
          checked={enabled}
          onChange={toggleEnabled}
          inputProps={{ "aria-label": "secondary checkbox" }}
          disabled={disabled}
        />
        <Typography className={classes.label}>{label}</Typography>
        {!opened ? (
          <ExpandMoreIcon onClick={toggleOpened} />
        ) : (
          <ExpandLessIcon onClick={toggleOpened} />
        )}
      </Box>
      {opened && (
        <OptionBox
          type={type}
          state={state}
          dispatch={dispatch}
          defaultValues={defaultValues}
          canSetHref={type === "url"}
        />
      )}
    </Box>
  );
};

const ButtonsModal = ({
  defaultButtonValues,
  updateInitialHtml,
  setIsEditingButtons,
  hasLanding,
  template,
}) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "SET_LABEL": {
        let newState = cloneDeep(state);
        newState[action.option].label = action.payload;
        return newState;
      }
      case "SET_ENABLED": {
        let newState = cloneDeep(state);
        newState[action.option].enabled = action.payload;
        return newState;
      }
      case "SET_TYPE": {
        let newState = cloneDeep(state);
        newState[action.option].type = action.payload;
        return newState;
      }
      case "SET_HREF": {
        let newState = cloneDeep(state);
        newState[action.option].href = action.payload;
        return newState;
      }
    }
  };
  const [state, dispatch] = useReducer(reducer, defaultButtonValues);
  const [anchorEl, setAnchorEl] = useState(null);

  const closePopper = () => {
    updateInitialHtml(generateButtonGroup(state));
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    const target = event.currentTarget;
    setIsEditingButtons(target);
    setAnchorEl(target);
  };
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const hasReachMaximumSelectable =
    Object.values(state).filter((option) => option.enabled === true).length >=
    2;

  return (
    <>
      <Popper anchorEl={anchorEl} open={open} placement={"right-end"}>
        <ClickAwayListener onClickAway={closePopper}>
          <Box className={classes.buttonsEditor}>
            <Typography className={classes.title}>
              {_t("Buttons").toUpperCase()}
            </Typography>
            <Typography className={classes.description}>
              {_t("You can enable up to two buttons simultaneously")}
            </Typography>
            {[
              {
                type: "rsvpTrue",
                label: _t("Landing page of your event"),
                enabled: true,
              },
              {
                type: "rsvpFalse",
                label: _t("RSVP: No"),
                enabled:
                  hasLanding &&
                  [
                    "invitation_participant",
                    "invitation_relaunch_participant",
                    "reminder_day",
                    "reminder_minutes",
                    "custom_email",
                  ].includes(template),
              },
              { type: "url", label: _t("Link"), enabled: true },
            ].map((button) => {
              if (button.enabled) {
                const alreadyEnabled = get(state, button.type, {}).enabled;
                return (
                  <ButtonOption
                    state={state}
                    dispatch={dispatch}
                    key={button.type}
                    type={button.type}
                    label={button.label}
                    disabled={hasReachMaximumSelectable && !alreadyEnabled}
                  />
                );
              }
            })}
          </Box>
        </ClickAwayListener>
      </Popper>
      <EditButton
        onClick={anchorEl ? closePopper : handleClick}
        editing={!!anchorEl}
      />
    </>
  );
};

ButtonsModal.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.object,
};

ButtonsModal.defaultProps = {
  onClick: () => {},
};

export default ButtonsModal;
