import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { _t } from "i18n";
import TabBar from "app/liveShopping/backoffice/otm/components/TabBar"

const useStyles = makeStyles((theme) => ({
  root: {},
}));


const TabBarContainer = ({ }) => {
  const classes = useStyles();


  return <TabBar />;
};


TabBarContainer.propTypes = {};


TabBarContainer.defaultProps = {};


export default TabBarContainer;