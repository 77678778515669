import React from "react";
import PropTypes from "prop-types";
import { ButtonBase, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomCatalogIcon from "liveShopping/assets/icons/CustomCatalogIcon";
import { setOpenCatalog } from "app/state/ducks/liveShopping/actions";
import { useDispatch, useSelector } from "react-redux";
import { mobileLayoutSelector, desktopLayoutSelector } from "app/state/ducks/liveShopping/selectors";
const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "99px",
    display: (props) => (props.fullScreen ? "none" : props.desktopLayout ? "none" : "flex"),
    justifyContent: "center",
    alignItems: "center",
    width: 35,
    marginTop: "10px",
  },
}));
const Catalog = () => {
  const fullScreen = useSelector((state) => state.liveShopping.fullScreen);
  const mobileLayout = useSelector(mobileLayoutSelector);
  const desktopLayout = useSelector(desktopLayoutSelector);
  const classes = useStyles({ fullScreen, desktopLayout });

  const dispatch = useDispatch();
  const handleCatalog = () => {
    if (mobileLayout) {
      dispatch(setOpenCatalog(true));
    } else {
      dispatch(setOpenCatalog(true));
    }
  };

  return (
    <Box className={classes.root}>
      <ButtonBase onClick={handleCatalog}>
        <CustomCatalogIcon color={"#FFF"} />
      </ButtonBase>
    </Box>
  );
};

Catalog.propTypes = {
  fullScreen: PropTypes.bool,
  desktopLayout: PropTypes.bool,
};

export default Catalog;
