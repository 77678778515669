import React, { useEffect, useState } from "react";
import { makeStyles, Box, Divider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { resourceEdit, resourceFetch } from "app/state/ducks/ressources/actions";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { _t } from "utilities/i18n";
import { API_BASE } from "settings/config";
import makeRequest from "utilities/makeRequest";
import { currentProjectSelector } from "app/state/ducks/application/selectors";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: "800px",
    height: "400px",
    overflowY: "auto",
  },
  card: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: `1px solid ${theme.palette.colors.grey300}`,
    borderRadius: "5px",
    padding: "10px",
    margin: "auto",
    maxWidth: "500px",
    marginBottom: "10px",
  },
  scoreTypo: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  divider: {
    margin: "auto",
    width: "300px",
    marginBottom: "10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
}));

const INTERVAL_TIME = 5000;

const FishingRod = ({ handleClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const projectId = localStorage.getItem("projectId");
  const [scores, setScores] = useState([]);
  const [chosen, setChosen] = useState("");
  const currentProject = useSelector(currentProjectSelector);
  const [loading, setLoading] = useState(true);

  const getScores = () => {
    dispatch(resourceFetch("project", {
      slug: ["projects", projectId, "scores"],
      callback: (error, response) => {
        if (error) {
          console.log("error", error);
          return;
        }
        if (response) setLoading(false);

        let rankedScores = response.resources[0].attributes.data.scores.sort((a, b) => {
          return b.score - a.score;
        });

        setScores(rankedScores)
      },
    }))
  }

  useEffect(() => {
    if (!currentProject.options.fishing_rod) return;
    getScores();
  }, []);

  useEffect(() => {
    if (!currentProject.options.fishing_rod) return;
    const scoreInterval = setInterval(() => {
      getScores();
    }, INTERVAL_TIME);

    return () => {
      clearInterval(scoreInterval);
    }
  }, []);

  const handleChooseVisitor = async (visitorId) => {
    const url = API_BASE.clone()
      .segment("projects")
      .segment(projectId)
      .segment("scores")
      .segment(visitorId)
      .toString();

    const result = await makeRequest(url, { type: "Custom" }, { method: "POST" });

    if (result.resources[0].attributes.data.scores[0].chosen === true) {
      setChosen(visitorId)
    }
  };

  if (!scores) return null;

  return (
    <>
      <Box className={classes.paper}>
        <Box className={classes.title}>{_t("Choose a visitor")}</Box>
        {!currentProject.options.fishing_rod && <Box>{_t("Fishing rod is not enabled, to use it, please enable it in your parameters")}</Box>}

        {loading && <Box>{_t("Loading...")}</Box>}

        {(!loading && scores.length === 0) && <Box>{_t("No visitor found")}</Box>}

        {scores.map((score) => {
          return (
            <>
              <Box className={classes.card}>
                <Box className={classes.scoreTypo}>{score.score}</Box>
                <Box>{score.data.page_title}</Box>
                <Button disabled={chosen === score.token} onClick={() => handleChooseVisitor(score.token)}>{_t("Choose")}</Button>
              </Box>
              <Divider className={classes.divider} />
            </>

          )
        })}
      </Box>
    </>
  );
};

export default FishingRod;