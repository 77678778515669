// Landing ( next )
export const LANDING_PATH = "/l/:keyword";
export const MODULE_MAILS = "/mails/:keyword";

// Session management
export const CONNECT = "/connect";
export const CONFIRM_PASSWORD = "/confirm-password/:token";
export const LOGOUT = "/logout";
export const LOGIN = "/login";

// Modules
export const MODULE_PATH = "/m/:module/:keyword/";
export const MODULE_HANDLE_USER = "/m/handle_user/:keyword/";
export const MODULE_HANDLE_USER_PARTICIPANT =
  "/m/handle_user_participant/:keyword/";
export const MODULE_HANDLE_USER_TEAM = "/m/handle_user_team/:keyword/";
export const MODULE_HANDLE_MAILS = "/m/handle_mails/:keyword/";
export const MODULE_SIGNATURES = "/m/signatures/:keyword/";
export const MODULE_HANDLE_SIGNATURES = "/m/handle_signature/:keyword/";
export const MODULE_VISIO = "/m/visio/:keyword/";

// New routes for one
export const INDEX = "/";
export const DASHBOARD_USER = "/board";
export const ONE_LIVE = "/:keyword";
export const DASHBOARD_PATH = "/admin/session/:keyword";
export const EVENT_DASHBOARD_PATH = "/admin/event/:keyword";
export const DASHBOARD_USER_CREATE_EVENT = "/dashboard/event/create";
export const PROFILE_USER = "/profile";
export const SECURITY_USER = "/profile/security";
export const VISIO_MODULE = "/visio";
export const VOTES_MODULE = "/surveys/polls";
export const MEDIAS_MODULE = "/medias";
export const SURVEYS_MODULE = "/surveys/surveys";
export const FEATURE_FLAG_MODULE = "/flags";

/* Thoses routes will render with the old next template */
export const NEXT_ROUTES = [
  MODULE_HANDLE_USER,
  MODULE_HANDLE_USER_PARTICIPANT,
  MODULE_HANDLE_USER_TEAM,
  MODULE_HANDLE_MAILS,
  MODULE_SIGNATURES,
  MODULE_HANDLE_SIGNATURES,
  MODULE_SIGNATURES,
  MODULE_MAILS,
];

/* Thoses routes will render without header */
export const NO_HEADER_ROUTES = [
  INDEX,
  LOGOUT,
  LANDING_PATH,
  MODULE_PATH,
  CONNECT,
  CONFIRM_PASSWORD,
  SECURITY_USER,
  PROFILE_USER,
  MODULE_MAILS,
  FEATURE_FLAG_MODULE,
];

export const PERSONALISED_LOGO = [DASHBOARD_PATH, LANDING_PATH, MODULE_MAILS];
export const BASIC_LOGO = [
  INDEX,
  DASHBOARD_USER,
  DASHBOARD_USER_CREATE_EVENT,
  PROFILE_USER,
  SECURITY_USER,
  VISIO_MODULE,
  FEATURE_FLAG_MODULE,
];

export const SHOW_ADMINBAR_ROUTES = [ONE_LIVE];

export const SHOW_USER_TOPBAR_ROUTES = [
  DASHBOARD_USER,
  DASHBOARD_USER_CREATE_EVENT,
  PROFILE_USER,
  SECURITY_USER,
];