import { Box, Divider } from "@material-ui/core";
import React from "react";
import Catalog from "./Catalog";
import Cobrowsing from "./Cobrowsing";
import FishingRod from "./FishingRod";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "500px",
    overflowY: "auto",
    width: "50%",
  },
  divider: {
    width: "50%",
    marginTop: "20px",
    marginBottom: "20px",
  },
}));
const Plugins = ( { project }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Cobrowsing project={project} />
      <Catalog project={project} />
      <FishingRod project={project} />
    </Box>
  )
};

export default Plugins;