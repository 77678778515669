import React, { useState } from "react";
import PropTypes from "prop-types";
import { _t } from "utilities/i18n";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import Tooltip from "coreUiKit/components/Tooltip";
import Icon from "coreUiKit/inputs/Icon";
import Reactions from "coreUiKit/components/Chat/Actions/Reactions";

import ThreeDots from "react-bootstrap-icons/dist/icons/three-dots";
import CheckCircle from "react-bootstrap-icons/dist/icons/check-circle";
import CheckCircleFill from "react-bootstrap-icons/dist/icons/check-circle-fill";
import Pin from "react-bootstrap-icons/dist/icons/pin";
import PinFill from "react-bootstrap-icons/dist/icons/pin-fill";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import Reply from "react-bootstrap-icons/dist/icons/reply";
import EmojiLaughing from "react-bootstrap-icons/dist/icons/emoji-laughing";
import Star from "react-bootstrap-icons/dist/icons/star";
import StarFill from "react-bootstrap-icons/dist/icons/star-fill";
import Megaphone from "react-bootstrap-icons/dist/icons/megaphone";
import PencilSquare from "react-bootstrap-icons/dist/icons/pencil-square";
import TagsFill from "react-bootstrap-icons/dist/icons/tags-fill";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 10px",
  },
  menu: {
    visibility: (props) => (props.isHovered ? "visible" : "hidden"),
  },
  icons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& $icon": {
      display: "flex",
      alignItems: "center",
      margin: "0 0 5px",
      "&:last-child": {
        marginBottom: "0",
      },
    },
  },
  iconsOption: {
    display: "flex",
    flexDirection: "row",
    padding: "5px",
    backgroundColor: theme.palette.colors.white,
    boxShadow: theme.palette.shadow.tooltip,
    borderRadius: "5px",
    "& $icon": {
      margin: "0 5px",
      display: "flex",
      alignItems: "center",
      visibility: "visible",
      "&:first-child": {
        marginLeft: "0",
      },
      "&:last-child": {
        marginRight: "0",
      },
    },
  },
  icon: {
    visibility: (props) => (props.isHovered ? "visible" : "hidden"),
    "&.alwaysVisible": {
      visibility: "visible",
    },
  },
  reactionsTooltip: {
    marginTop: "5px",
    marginLeft: "-10px",
  },
}));

const defaultValues = {
  hover: null,
  enabled: false,
  onClick: () => {},
};

const defaultActions = (action) => {
  switch (action) {
    case "processed":
      return {
        ...defaultValues,
        title: _t("Processed"),
        icon: CheckCircle,
        activatingIcon: CheckCircleFill,
        activatingColor: "primary",
      };
    case "favorite":
      return {
        ...defaultValues,
        title: _t("Favorite"),
        icon: Star,
        activatingIcon: StarFill,
        activatingColor: "sun",
      };
    case "pinned":
      return {
        ...defaultValues,
        title: _t("Pinned"),
        icon: Pin,
        activatingIcon: PinFill,
        activatingColor: "primary",
      };
    case "delete":
      return {
        ...defaultValues,
        title: _t("Delete"),
        icon: Trash,
        hover: "error",
      };
    case "reply":
      return {
        ...defaultValues,
        title: _t("Reply"),
        icon: Reply,
      };
    case "tags":
      return {
        ...defaultValues,
        title: _t("Tags"),
        icon: TagsFill,
      };
    case "edit":
      return {
        ...defaultValues,
        title: _t("Edit"),
        icon: PencilSquare,
      };
    case "reaction":
      return {
        ...defaultValues,
        title: _t("Reactions"),
        icon: EmojiLaughing,
      };
    case "projected":
      return {
        ...defaultValues,
        title: _t("Display"),
        icon: Megaphone,
        activatingColor: "primary",
      };
  }
};

const iconComponent = ({ classes, key, title, placement = "right", icon, activatingIcon, activatingColor, hover, enabled, ...props }) => {
  const params = {
    ...props,
    IconComponent: enabled && activatingIcon ? activatingIcon : icon,
    hover: enabled && activatingColor ? activatingColor : hover,
    color: enabled && activatingColor ? activatingColor : null,
  };

  return (
    <Tooltip title={title} key={key} placement={placement}>
      <Box className={classes}>
        <Icon {...params} />
      </Box>
    </Tooltip>
  );
};

const shouldAlwaysVisible = ({ enabled, key }) => {
  return enabled === true && key === "favorite";
};

const Actions = ({ enabledActions, placement, isHovered, selectedReaction, reactions }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorReaction, setAnchorReaction] = useState(null);
  const classes = useStyles({ isHovered: isHovered || Boolean(anchorEl) });

  const handleClose = () => {
    setAnchorReaction(null);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    if (anchorEl) {
      setAnchorReaction(null);
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  let handleToggleReaction = () => {};
  const handleClickReaction = (event) => setAnchorReaction(anchorReaction ? null : event.currentTarget);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box className={classes.root}>
        <Box className={classes.icons}>
          {enabledActions.slice(0, 2).map(({ key, ...action }) => {
            if (key === "reaction" && action?.onClick) {
              handleToggleReaction = action.onClick;
            }

            const props = {
              ...defaultActions(key),
              ...action,
              ...(key === "reaction" ? { onClick: handleClickReaction } : {}),
            };

            return iconComponent({
              ...props,
              key,
              classes: clsx({
                [classes.icon]: true,
                alwaysVisible: shouldAlwaysVisible({ ...props, key }),
              }),
            });
          })}
          {enabledActions.length > 2 && <Icon key={2} IconComponent={ThreeDots} onClick={handleClick} className={classes.menu} />}
        </Box>
        <Popper anchorEl={anchorEl} placement={placement} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <Box className={classes.iconsOption}>
            {enabledActions.slice(2).map(({ key, ...action }) => {
              if (key === "reaction" && action?.onClick) {
                handleToggleReaction = action.onClick;
              }

              const props = {
                ...defaultActions(key),
                ...action,
                ...(key === "reaction" ? { onClick: handleClickReaction } : {}),
              };

              return iconComponent({
                ...props,
                key,
                classes: classes.icon,
                placement: "top-start",
              });
            })}
          </Box>
        </Popper>
        <Popper className={classes.reactionsTooltip} anchorEl={anchorReaction} placement="bottom-start" open={Boolean(anchorReaction)} onClose={() => setAnchorReaction(null)}>
          <Reactions selectedReaction={selectedReaction} reactions={reactions} onClick={handleToggleReaction} />
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

Actions.propTypes = {
  placement: PropTypes.oneOf(["bottom-end", "bottom-start"]),
  enabledActions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      enabled: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  isHovered: PropTypes.bool,
  reactions: PropTypes.array,
  selectedReaction: PropTypes.string,
};

Actions.defaultProps = {
  enabledActions: [],
  placement: "bottom-end",
  isHovered: false,
};

export default Actions;
