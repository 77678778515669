import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    padding: theme.spacing(1),
    fontWeight: 700,
    fontSize: "18px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    lineHeight: "24.38px",
    letterSpacing: "0.5px",
  },
  average: {
    borderColor: "red",
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  textResult: {},
  ratingRoot: {
    "& .MuiRating-iconEmpty": {
      color: theme.palette.background.secondaryBox,
    },
  },
  bulletPoint: {
    "&::marker": {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  iconBox: {
    color: theme.palette.text.primary,
  },
}));

const UserRatingSurveyItemResult = ({ surveyItemAnswer }) => {
  const classes = useStyles();

  return (
    <Box className={clsx("RatingSurveyItemResult", classes.root)}>
      <li className={classes.bulletPoint}>
        <Box className={classes.content}>
          <Typography className={classes.label}>
            {surveyItemAnswer.value}
          </Typography>
          <Box className={classes.iconBox}>
            <StarOutlinedIcon />
          </Box>
        </Box>
      </li>
    </Box>
  );
};

UserRatingSurveyItemResult.propTypes = {
  surveyItemAnswer: PropTypes.object,
};

UserRatingSurveyItemResult.defaultProps = {
  userResponse: false,
};

export default UserRatingSurveyItemResult;
