import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "utilities/i18n";
import { DateRangePicker } from '@tremor/react';
import dayjs from "dayjs";
import makeRequest from "utilities/makeRequest";
import makeUrl from "ressourcesDucks/sagas/makeUrl";
import Dropdown from "app/liveShopping/backoffice/otm/components/reusables/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { currentProjectSelector, otoDashboardDatesSelector } from "app/state/ducks/application/selectors";
import HistoryTable from "./HistoryTable";
import ExportCSVButton from "./ExportCSVButton";
import { setOtoDashboardDates } from "app/state/ducks/application/actions";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({

  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}));

const filterBounced = (data) => {
  if (!data) return [];
  return data.filter(({ created_at, terminated_at }) => (new Date(terminated_at).getTime() - new Date(created_at).getTime()) >= (30 * 1000))
}

const History = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [generalSelector, setGeneralSelector] = useState(false)
  const currentProject = useSelector(currentProjectSelector);
  const [data, setData] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState("");

  const dates = useSelector(otoDashboardDatesSelector);

  const setDates = (dates) => {
    dispatch(setOtoDashboardDates(dates[0], dates[1], dates[2]));
    fetchStats();
  }

  const fetchStats = async (dates) => {
    if (!currentProject?.id) return;

    try {
      const slug = ["stats", "project", currentProject?.id, "history"];
      const query = [{ key: "since", value: dates.since.toISOString() }, { key: "until", value: dates.until.toISOString() }];

      if (selectedVendor)
        query.push({ key: "vendor", value: selectedVendor });

      setLoading(true);
      const url = makeUrl({ type: "Custom" }, { slug, query });

      const result = await makeRequest(url, { type: "Custom" }, { method: "GET" });
      const finalResult = Object.assign(result.resources[0].attributes.data.history);

      setLoading(false);
      // setGeneralSelector(false)

      setData(finalResult);
    } catch (e) {
      setLoading(false);
      setData(null);
    }
  }

  useEffect(() => {
    fetchStats(dates);
  }, [dates, currentProject]);

  const vndrs = data?.filter(({ vendor_hash }) => vendor_hash !== null).map(({ vendor_hash, vendor_firstname, vendor_lastname }) => ({ name: `${vendor_firstname} ${vendor_lastname}`, hash: vendor_hash }));
  const filteredArr = vndrs?.reduce((acc, current) => {
    const x = acc.find(item => item.hash === current.hash);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const optionsVendors = [...filteredArr || [], { name: _t("All vendors"), hash: "" }];

  return (
    <Box className={classes.root}>
      <Header setDates={setDates} dates={dates} setSelectedVendor={setSelectedVendor} optionsVendors={optionsVendors} />

      <HistoryTable data={filterBounced(data)} loading={loading} />

    </Box>
  );
}

export default History;
