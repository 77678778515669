import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { createCachedSelector } from "re-reselect";
import { createSelector as reselect } from "reselect";

export const sessionSelector = (state) => state.application.session || {};
export const fetchedOnceSelector = (state) => state.application.fetchedOnce || {};
export const browserInfoSelector = (state) => state.application.browserInfo || {};
export const droppedFileSelector = (state) => state.application.droppedFile;
export const verifyUserSelector = (state) => state.application.verifyUser;
export const localeSelector = (state) => state.application.locale;
export const socketSelector = (state) => state.application.socket;
export const getInvalidOtt = (state) => state.application.invalidOtt;
export const getAnonymousUserParticipation = (state) => state.application.anonymousUserParticipation;
export const displayPopupParticipation = (state) => {
  return {
    displayPopupParticipation: state.application.displayPopupParticipation,
    popupParticipationCallback: state.application.popupParticipationCallback,
  };
};
export const getUsername = (state) => state.application.username;
export const getOnlineUsers = (state) => state.application.onlineUsers;
export const getKeyword = (state) => state.application.keyword;
export const getError = (state) => state.application.error;
export const getTimeline = (state) => state.application.timeline;
export const getConnection = (state) => state.application.connection;
export const getStream = (state) => state.application.stream;
export const moderationSelector = (state) => state.application.moderation;
export const collapseChatSelector = (state) => state.application.collapseChat;
export const getArchives = (state) => state.application.archives;
export const getParticipantCount = (state) => state.application.participants_count;
export const getParticipation = (state) => state.application.participation_mode;
export const getFullLeaderBoard = (state) => state.application.fullLeaderboard;
export const getPreview = (state) => state.application.adminPreview;
export const currentProjectSelector = (state) => state.application.currentProject;
export const customThemeColorsSelector = (state) => state.application.customThemeColors;
const getFetchResultStatus = (state) => get(state, "application.requests_status", {});
export const getFetchResultStatusSelector = createCachedSelector(
  getFetchResultStatus,
  (state, id) => id,
  (status, id) => {
    if (!isEmpty(id) && !isEmpty(status) && !isEmpty(status[id])) {
      return status[id];
    }

    return null;
  }
)((state, id) => `fetch_status_${id}`);

export const getArchiveStreamSelector = createCachedSelector(
  getArchives,
  (state, id) => id,
  (archives, id) => {
    if (!isEmpty(id) && !isEmpty(archives) && !isEmpty(archives[id])) {
      return archives[id];
    }

    return { items: [], count: 0 };
  }
)((state, id) => `fetch_archive_stream_${id}`);

export const isUserOnlineSelector = createCachedSelector(
  getOnlineUsers,
  (state, id) => id,
  (onlineUsers, id) => {
    if (!id) return false;
    return onlineUsers[id];
  }
)((state, id) => `onlineUser_${id}`);
export const onlineUsersSelector = reselect(getOnlineUsers, (users) => users);
export const getFullTimeline = () => { };

export const adminPreviewSelector = reselect([getPreview], (preview) => preview);
export const ottStateSelector = reselect([getInvalidOtt], (invalid) => invalid);

export const otoDashboardDatesSelector = (state) => state.application.otoDashboardDates;

