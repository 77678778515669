import React, { useState, useEffect } from "react";
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { _t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getCalendar, getVisitor } from "./service";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import Onboard from "./components/Onboard";
import VisioVonage from "./containers/Visio/VisioVonage";
import { emit } from "core/liveshopping-sdk";
import Nps from "./components/Visio/Nps/Nps";
import { liveShoppingInit } from "app/state/ducks/liveShopping/actions";
import emitter from "utilities/emitter";
import URI from "urijs";
import { currentCalendarSelector } from "app/state/ducks/liveShopping/selectors";
import AppointmentInfo from "./components/Appointment/AppointmentInfo";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {},
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%'
  },
}));

const OneToOne = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { visitor, token } = useParams();

  const currentVisitor = useSelector(currentVisitorSelector);
  const currentCalendar = useSelector(currentCalendarSelector);
  const [currentVisitorFetched, setCurrentVisitorFetched] = useState(false);
  const [currentCalendarFetched, setCurrentCalendarFetched] = useState(false);

  const showAppointment = currentVisitor && currentVisitor.scheduled_at && dayjs(currentVisitor.scheduled_at).diff(dayjs(), 'minute') > 20;

  useEffect(() => {
    getVisitor(dispatch, { hash: visitor, token: token }, () => setCurrentVisitorFetched(true));
    getCalendar(dispatch, { hash: visitor, token: token }, () => setCurrentCalendarFetched(true));
  }, []);

  useEffect(() => {
    dispatch(liveShoppingInit());

    // detach widget feature
    emitter.bc.postMessage({ action: 'init' });
    emitter.on('BroadcastChannel', (data) => {
      const { popup } = (new URI).search(true);

      if (data.action === 'init' && popup)
        window.close();
    });
  }, []);

  useEffect(() => {
    // status for picto sdk navbar
    if (!currentVisitor) return;
    emit("STATUS", { status: currentVisitor.status });
  }, [currentVisitor?.status]);

  useEffect(() => {
    // cobrowsing
    if (currentVisitor?.options?.cobrowsing?.synchronized !== false) return;
    console.log("COBROWSING", currentVisitor?.options?.cobrowsing?.url)
    emit("COBROWSING", { url: currentVisitor?.options?.cobrowsing?.url });
  }, [currentVisitor?.options?.cobrowsing]);

  // if visitor endpoint returned nothing we have an issue here
  if (!currentVisitor && currentVisitorFetched)
    return null;

  // do not go further if visitor and agenda are not both fetched
  if (!currentCalendarFetched || !currentVisitorFetched)
    return <Box className={classes.loading}><CircularProgress /></Box>;

  // if visitor is scheduled, we display appointment info until 20 minutes before the appointment
  if (showAppointment)
    return <AppointmentInfo visitor={currentVisitor} calendar={currentCalendar} />;

  // if conversation ended, show NPS screen
  if (currentVisitor.status === "ended")
    return <Nps />;

  // if user is preparing, show onboard screen
  if (currentVisitor.status === "preparing" || (currentVisitor.scheduled_at && !currentVisitor.options?.mode))
    return <Onboard />;

  // otherwise, let's roll
  return <VisioVonage visitor={currentVisitor} />;
};

OneToOne.propTypes = {};

OneToOne.defaultProps = {};

export default OneToOne;
