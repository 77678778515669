import React, { Component } from "react";
import { connect } from "react-redux";

import { getUserSession } from "core/session";
import { userSelector } from "ressourcesDucks/selectors";

export function withUser(WrappedComponent) {
  class User extends Component {
    static displayName = `WithUser(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`;
    static getClass = () =>
      WrappedComponent.getClass
        ? WrappedComponent.getClass()
        : WrappedComponent;

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    const session = getUserSession();
    const { user_id } = session;

    return {
      session,
      user: userSelector(state, user_id),
    };
  }

  return connect(mapStateToProps)(User);
}
