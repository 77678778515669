import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { useSelector } from "react-redux";
import { getTimeLivePercent } from "../../Replay/replayUtilities";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "8px",
  },
  progressContainer: {
    width: "95%",
    height: 2,
    position: "absolute",
    background: "rgba(255, 255, 255, 0.5)",
    overflow: "hidden",
    borderRadius: "99px",
  },
  line: {
    width: (props) => props.progress + "%",
    height: 2,
    borderRadius: "99px",
    position: "absolute",
    borderRadius: "99px",
    background: theme.palette.colors.white,
  },
}));

const ProgressBarTime = ({}) => {
  const { live_start, live_stop } = useSelector(currentWizSelector);
  const [date, setDate] = useState(Date.now());
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const myInterval = setInterval(() => {
      setDate(Date.now());
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, []);

  useEffect(() => {
    const percent = getTimeLivePercent(date, live_start, live_stop);
    setProgress(percent < 0 ? 0 : percent > 100 ? 100 : Math.max(3, percent));
  }, [date]);

  const classes = useStyles({ progress });
  return (
    <Box className={classes.root}>
      <Box className={classes.progressContainer}>
        <Box className={classes.line}></Box>
      </Box>
    </Box>
  );
};

ProgressBarTime.propTypes = {};

ProgressBarTime.defaultProps = {};

export default ProgressBarTime;
