import Box from "@material-ui/core/Box";
import FilledInput from "@material-ui/core/FilledInput";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  field: {
    "& .MuiFilledInput-root:hover": {
      backgroundColor: theme.palette.background.secondaryBox,
    },
  },
  inputRoot: {
    color: theme.palette.border.default40,
    backgroundColor: theme.palette.background.secondaryBox,
    borderRadius: "5px",
    fontWeight: 400,
    fontSize: "12px",
    height: "40px",
    "& .MuiFilledInput-input": {
      //height: "auto",
      fontSize: 12,
      lineHeight: 1.25,
      paddingLeft: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  notchedOutline: {
    borderColor: theme.wisemblyColors.lightGray,
  },
}));

const MailDatePicker = ({
  date,
  time,
  onDateChange,
  onTimeChange,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <>
      <Box mr={2} className={classes.field}>
        <FilledInput
          type="date"
          required
          id="date"
          value={date}
          classes={{
            root: classes.inputRoot,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          }}
          name="date"
          disableUnderline
          disabled={disabled}
          inputProps={{ min: dayjs().format("YYYY-MM-DD") }}
          onChange={(e) => onDateChange(e.target.value)}
        />
      </Box>
      <Box className={classes.field}>
        <FilledInput
          type="time"
          required
          id="time"
          value={time}
          classes={{
            root: classes.inputRoot,
            input: classes.input,
            notchedOutline: classes.notchedOutline,
          }}
          name="time"
          disableUnderline
          disabled={disabled}
          onChange={(e) => onTimeChange(e.target.value)}
        />
      </Box>
    </>
  );
};

export default MailDatePicker;
