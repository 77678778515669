import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import Chat from "app/liveShopping/frontoffice/otm/containers/Chat/ChatContainer";
import Player from "app/liveShopping/frontoffice/otm/components/Player/Player";
import PlayerElements from "app/liveShopping/frontoffice/otm/containers/Player/Elements";
import Catalog from "app/liveShopping/frontoffice/otm/components/Catalog/Catalog";
import ProductDialog from "app/liveShopping/frontoffice/otm/components/ProductDialog/ProductDialog";
import VisibilityDialog from "app/liveShopping/frontoffice/otm/components/VisibilityDialog";
import LoadingLayout from "app/liveShopping/frontoffice/otm/components/LoadingLayout";
import PlayLayout from "./PlayLayout";
import Cart from "./Cart/Cart";
import Replay from "app/liveShopping/frontoffice/otm/components/Replay/Replay";
import TapZone from "./Replay/TapZone";
import ReplayPlayerOverlay from "./Replay/ReplayPlayerOverlay";
import { playerLoadedSelector, viewedProductSelector } from "liveShoppingDucks/selectors";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import ReplayDialog from "./Replay/ReplayDialog";
import MonoProduct from "./MonoProduct";

const useStyles = makeStyles((theme) => ({
  desktopWrapper: {
    display: "flex",
    width: "100%",
    height: "100%",
    position: "relative",
  },
  leftSide: {
    display: (props) => (props.inPip ? "none" : "flex"),
    width: "35%",
    height: "100%",
    position: "relative",
  },
  center: {
    width: (props) => (props.inPip ? "100%" : "30%"),
    position: "relative",
    userSelect: "none",
  },
  rightSide: {
    width: "35%",
    height: "100%",
    display: (props) => (props.inPip ? "none" : "flex"),
    flexDirection: "column",
    flex: "1 auto",
    position: "relative",
  },
  rightSideHeader: {
    height: "86px",
    width: "100%",
    background: theme.palette.colors.white,
    display: "flex",
    alignItems: "center",
  },
  rightSideTitle: {
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "130%",
    marginLeft: "24px",
    marginTop: "30px",
    marginBottom: "30px",
  },
}));

const Desktop916Layout = () => {
  const playerIsLoaded = useSelector(playerLoadedSelector);
  const viewedProduct = useSelector(viewedProductSelector);
  const wiz = useSelector(currentWizSelector);

  const inPip = window.innerWidth <= 200;

  const classes = useStyles({ inPip });

  return (
    <Box className={classes.desktopWrapper}>
      <Box
        style={{
          background: "#FFF",
          borderRadius: 8,
          display: "flex",
          width: "100%",
        }}
      >
        <Box className={classes.leftSide}>
          <Catalog />
          {wiz.cart_integration && <Cart />}
          {wiz.status === "replay" && <Replay />}
          {viewedProduct && <ProductDialog viewedProduct={viewedProduct} />}
        </Box>

        <Box className={classes.center}>
          {playerIsLoaded === true ? <PlayLayout /> : <LoadingLayout loadedState={playerIsLoaded} />}

          {wiz.status === "replay" && (
            <>
              <TapZone />
              <ReplayPlayerOverlay />
            </>
          )}

          <PlayerElements />
          <Player />
          <Box
            style={{
              position: "absolute",
              bottom: "0px",
              width: "100%",
            }}
          >
            <MonoProduct />
          </Box>
        </Box>
        <Box className={classes.rightSide}>
          <Box className={classes.rightSideHeader}>
            <Typography className={classes.rightSideTitle}>Chat</Typography>
          </Box>
          <Chat />
          <VisibilityDialog />
          <ReplayDialog />
        </Box>
      </Box>
    </Box>
  );
};

Desktop916Layout.propTypes = {};

Desktop916Layout.defaultProps = {};

export default Desktop916Layout;
