import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { _t } from "i18n";
import Wrapper from "./Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { getKeyword } from "app/state/ducks/application/selectors";
import { genericResourcesSelector } from "app/state/ducks/ressources/selectors";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { computeInfos } from "./utilities";
import URI from "urijs";
import { API_BASE } from "config";
import { applicationJSONCall } from "app/state/ducks/application/actions";

const useStyles = makeStyles(() => ({
  root: {},
  title: {
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "left",
  },
}));

const Statistics = ({}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const [data, setData] = useState(null);
  useEffect(() => {
    dispatch(
      resourceFetch("cart", {
        slug: ["event", keyword, "carts"],
        silent: true,
      })
    );

    const url = new URI(API_BASE.toString().replace("/6", "/4"))
      .segment("event")
      .segment(keyword)
      .segment("analytics")
      .query({ "modules[]": ["quotes", "participants"] })
      .toString();

    dispatch(
      applicationJSONCall(
        url,
        {},
        {
          callback: (error, response) => {
            console.log(error, response);
            setData(response.success.data);
          },
        }
      )
    );
  }, []);

  const carts = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "cart",
      sort: "created_at",
      order: "desc",
    })
  );

  const cartsComputes = computeInfos(carts);

  return (
    <Box style={{ width: "100%", margin: "16px 30px" }}>
      <Wrapper computes={cartsComputes} {...data} />
    </Box>
  );
};

Statistics.propTypes = {};

Statistics.defaultProps = {};

export default Statistics;
