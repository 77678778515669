import React from "react";
import PropTypes from "prop-types";
import MuiTooltip from "@material-ui/core/Tooltip";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: "240px",
      backgroundColor: theme.palette.colors.black,
      boxShadow: theme.palette.shadow.tooltip,
      padding: "8px 10px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      marginTop: (props) => (props.placement.includes("bottom") ? `calc(5px + ${props.extraMargin})` : 0),
      marginRight: (props) => (props.placement.includes("left") ? `calc(5px + ${props.extraMargin})` : 0),
      marginBottom: (props) => (props.placement.includes("top") ? `calc(5px + ${props.extraMargin})` : 0),
      marginLeft: (props) => (props.placement.includes("right") ? `calc(5px + ${props.extraMargin})` : 0),
    },
    arrow: {
      color: theme.palette.colors.black,
      overflow: "initial",
      left: (props) => (!props.centerArrow ? "5px !important" : "0px"),
    },
    icon: {
      marginRight: "5px",
      fontSize: "12px",
      color: theme.palette.colors.white,
      backgroundColor: theme.palette.colors.black,
    },
  }),
  {
    name: "OneTooltip",
  }
);

const Tooltip = ({
  Icon,
  title,
  enterDelay,
  enterNextDelay,
  placement,
  children,
  open,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  leaveDelay,
  arrow,
  centerArrow,
  extraMargin,
}) => {
  const classes = useStyles({ placement, extraMargin, centerArrow });

  const text = (
    <Typography color="white" variant="smallBodySB13">
      {title}
    </Typography>
  );

  const content = !Icon ? (
    text
  ) : (
    <>
      <Icon className={classes.icon} /> {text}
    </>
  );

  return (
    <MuiTooltip
      classes={{ tooltip: classes.root, arrow: classes.arrow }}
      title={content}
      placement={placement}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      open={open}
      leaveDelay={leaveDelay}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      arrow={arrow}
    >
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  Icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  enterDelay: PropTypes.number,
  enterNextDelay: PropTypes.number,
  placement: PropTypes.string,
  open: PropTypes.bool,
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  centerArrow: PropTypes.bool,
  leaveDelay: PropTypes.number,
  arrow: PropTypes.bool,
  extraMargin: PropTypes.string,
};

Tooltip.defaultProps = {
  placement: "right",
  arrow: false,
  centerArrow: false,
  extraMargin: "0px",
};

export default Tooltip;
