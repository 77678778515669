import { useCallback, useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import ResourceFactory from "orm/resources";
import isEqual from "lodash/isEqual";
import { getKeyword } from "applicationDucks/selectors";

import { filterModerationQuote } from "./utils";
import { resourceEdit, resourceDelete, resourceFetch } from "ressourcesDucks/actions";
import { genericResourcesSelector, genericResourceSelector } from "ressourcesDucks/selectors";
import isEmpty from "lodash/isEmpty";
import { getFetchResultStatusSelector } from "applicationDucks/selectors";
import * as Sentry from "@sentry/react";
import last from "lodash/last";

export const useEditQuote = () => {
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  //data {quote: "", hash:""}
  const handleEditQuote = useCallback(
    (data, successCallback = () => {}, errorCallback = () => {}) => {
      const newQuote = resourceFactory.create("Custom", {
        quote: data.quote,
        moderate: data.moderate,
      });
      dispatch(
        resourceEdit(newQuote, {
          slug: ["event", keyword, "quotes", data.hash],
          silent: true,
          patch: ["quote", "moderate"],
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
              errorCallback();
            } else {
              successCallback();
            }
          },
        })
      );
    },
    [dispatch]
  );

  return handleEditQuote;
};
export const useDeleteQuote = () => {
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleDeleteQuote = useCallback(
    (data, successCallback = () => {}, errorCallback = () => {}) => {
      const quote = resourceFactory.create("Custom", {
        hash: data.hash,
      });
      dispatch(
        resourceDelete(quote, {
          slug: ["event", keyword, "quotes", data.hash],
          silent: true,
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
              //// TODO: error display
              errorCallback();
            } else {
              successCallback();
            }
          },
        })
      );
    },
    [dispatch]
  );

  return handleDeleteQuote;
};
const reducer = (state, action) => {
  switch (action.type) {
    case "REFRESH_QUOTES":
      return { ...state, loadMore: false, refresh: true, loading: false };

    case "FETCH_MORE":
      return { ...state, loadMore: true, refresh: false, loading: false };

    case "LOADING":
      return { ...state, loadMore: false, refresh: false, loading: true };

    case "FINISH_LOADING":
      return { ...state, loading: false, loadMore: false, refresh: false };
    default:
      return state;
  }
};

export const useQuotesForModeration = ({ moderation }) => {
  //const [fetchedOnceMine, setFetchedOnceMine] = useState(false);
  //const [lastWizRankAsk, setWizRankAsk] = useState(null);
  //  const [lastFilterSelected, setFilterSelected] = useState(filterSelected);
  const [state, dispatch] = useReducer(reducer, {
    loadMore: false,
    refresh: false,
    loading: false,
  });

  const keyword = useSelector(getKeyword);
  const reduxDispatch = useDispatch();
  const eventSession = useSelector((state) =>
    genericResourceSelector(state, {
      type: "eventSession",

      filter: (session) => {
        return session.ongoing === true && session.event && session.event.id === keyword;
      },
    })
  );
  //const adminFilters = [];
  const quotes = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "quote",
      sort: "created_at",
      order: "asc",
      filter: (quote) => filterModerationQuote(quote, keyword, eventSession),
    })
  );
  const lastQuote = last(quotes);
  const lastQuoteWizRank = lastQuote ? lastQuote.wiz_rank : null;
  const [total, setTotal] = useState(quotes.length || 0);
  const fetchQuotesStatus = useSelector((state) => getFetchResultStatusSelector(state, `quotes_to_moderate_${keyword}`));

  const fetchWizQuotes = useCallback(
    (shouldRefresh, wizRank) => {
      const fetchQuotes = (shouldRefresh, wizRank) => {
        if (!shouldRefresh && !wizRank) {
          //probably get to end of list
          return;
        }
        let query = [
          {
            key: "sort",
            value: "recent",
          },
          {
            key: "limit",
            value: 20,
          },
          { key: "filter", value: "moderate" },
          {
            key: "processed",
            value: "both",
          },
        ];

        // add wizRank only to fetch old quotes
        if (wizRank !== null && !shouldRefresh) {
          query.push({ key: "wizRank", value: wizRank });
        }

        query.push({
          key: "unmoderated",
          value: true,
        });

        if (eventSession) {
          query.push({ key: "session", value: eventSession.hash });
        }

        reduxDispatch(
          resourceFetch("quote", {
            slug: ["event", keyword, "quotes"],
            query: query,
            XHR_ID: `quotes_to_moderate_${keyword}`,
            silent: true,
            //important
            refresh: shouldRefresh,
            callback: (error, response) => {
              if (error) {
                return;
              }
              if (total < response.meta?.total) {
                setTotal(response?.meta?.total || total);
              }
            },
          })
        );

        // if (wizRank !== null && !shouldRefresh) {
        //   setWizRankAsk(wizRank);
        // }
      };

      fetchQuotes(shouldRefresh, wizRank);
    },
    [keyword, total]
  );
  //handle loading state
  useEffect(() => {
    if ((state.loadMore || state.refresh) && isEqual(fetchQuotesStatus, "pending")) {
      dispatch({ type: "LOADING" });
    }

    if ((isEqual(fetchQuotesStatus, "success") || isEqual(fetchQuotesStatus, "error")) && state.loading) {
      dispatch({ type: "FINISH_LOADING" });
    }
  });

  //loadMore data
  useEffect(() => {
    if (state.loadMore && !state.loading && !isEqual(fetchQuotesStatus, "pending")) {
      fetchWizQuotes(false, lastQuoteWizRank);
    }
  }, [state.loadMore, state.loading, fetchQuotesStatus]);

  //refresh data
  useEffect(() => {
    if (state.refresh && !state.loading && !isEqual(fetchQuotesStatus, "pending")) {
      fetchWizQuotes(true, lastQuoteWizRank);
    }
  }, [state.refresh, state.loading, fetchQuotesStatus]);

  //init fetch
  useEffect(() => {
    if (isEmpty(quotes) && isEmpty(fetchQuotesStatus)) {
      fetchWizQuotes(true, lastQuoteWizRank);
    }
  }, []);
  const loadMore = useCallback(() => {
    dispatch({ type: "FETCH_MORE" });
  }, [dispatch]);
  const refresh = useCallback(() => {
    dispatch({ type: "REFRESH_QUOTES" });
  }, [dispatch]);
  const fetchingAtStart = (isEqual(fetchQuotesStatus, "pending") || isEmpty(fetchQuotesStatus)) && isEmpty(quotes);
  return [quotes, loadMore, total];
};
