import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: (props) => (props.noHeader ? "0px" : "60px"),
    paddingBottom: theme.spacing(3.75),
    width: "560px",
    height: (props) => (props.noHeader ? "100%" : null),
    backgroundColor: theme.palette.background.box,
  },
  icon: {
    textAlign: "right",
  },
}));

const Panel = ({ children, open, handleClose, anchor, noHeader, style }) => {
  const classes = useStyles({ anchor, noHeader });

  return (
    <Drawer
      variant="persistent"
      anchor={anchor}
      open={open}
      onClose={handleClose}
      ModalProps={{ hideBackdrop: true }}
      PaperProps={{ className: classes.paper }}
    >
      <Box className={clsx("Panel", classes.icon)}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box style={{ height: "100%", ...style }}>{children}</Box>
    </Drawer>
  );
};

Panel.propTypes = {
  children: PropTypes.any,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  anchor: PropTypes.string,
  noHeader: PropTypes.bool,
};

Panel.defaultProps = {
  anchor: "right",
  noHeader: false,
};

export default Panel;
