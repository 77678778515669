import { createSelector } from "reselect";

export const credentialsSelector = (state) => state.visio.credentials;
export const sessionSelector = (state) => state.visio.session;
export const errorSelector = (state) => state.visio.error;
export const streamsSelector = (state) => ({
  streams: state.visio.streams,
  streamsById: state.visio.streamsById,
});
export const devicesSelector = (state) => state.visio.devices;
export const sourcesSelector = (state) => ({
  audioSource: state.visio.audioSource,
  videoSource: state.visio.videoSource,
});
export const publishStateSelector = (state) => ({
  publishAudio: state.visio.publishAudio,
  publishVideo: state.visio.publishVideo,
});
export const publishersSelector = (state) => ({
  videoPublisher: state.visio.videoPublisher,
  screenPublisher: state.visio.screenPublisher,
});
export const streamsIdSelector = (state) => ({
  videoStreamId: state.visio.videoStreamId,
  screenStreamId: state.visio.screenStreamId,
});

export const isOnStage = (state) =>
  state.visio.streams.includes(state.visio.videoStreamId);
export const isSharingScreen = (state) =>
  state.visio.streams.includes(state.visio.screenStreamId);
export const getOwnVideoStream = (state) =>
  state.visio.streamsById[state.visio.videoStreamId];
export const getOwnScreenSharingStream = (state) =>
  state.visio.streamsById[state.visio.screenStreamId];
export const hasRequestedJoinStage = (state) => state.visio.requestedJoinStage;
export const visioElementsSelector = (state) => state.visio.elements;