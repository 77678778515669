import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import ShortCard from "app/liveShopping/frontoffice/otm/components/Cards/ShortCard/ShortCard";
import ExpandedCard from "app/liveShopping/frontoffice/otm/components/Cards/ExpandedCard/ExpandedCard";
import DesktopCard from "app/liveShopping/frontoffice/otm/components/Cards/DesktopCard/DesktopCard";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "liveShoppingDucks/selectors";
import { currentWizSelector, focusedProductsSelector } from "app/state/ducks/ressources/selectors";
import emitter from "utilities/emitter";
import { REPLAY_TIMESTAMP_UPDATE } from "utilities/emitter/events";
import { focusedProductsUtility } from "app/liveShopping/utils";
const useStyles = makeStyles(() => ({
  wrapper: {
    overflowY: "auto",
    display: (props) => (props.mobileLayout ? "block" : "flex"),
    flex: "1 1 auto",
    width: "100%",
    height: "100%",
    padding: "0 24px",
    margin: "auto",
    flexDirection: "column",
    alignItems: "stretch",
    paddingTop: "10px",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  wrapperDesktop: {
    display: (props) => (props.mobileLayout ? "block" : "grid"),
    overflowY: "auto",
    flex: "1 1 auto",
    width: "100%",
    height: "100%",
    padding: "0 24px",
    margin: "auto",
    gridTemplateRows: "260px",
    gridTemplateColumns: "200px 200px",

    alignItems: "stretch",
    paddingTop: "10px",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const FilteredProducts = ({ products, search, parentWidth }) => {
  const mobileLayout = useSelector(mobileLayoutSelector);
  const wiz = useSelector(currentWizSelector);
  const classes = useStyles({ mobileLayout });
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  if (!products) return null;

  const updateTimestamp = (seconds) => {
    setCurrentTimestamp(seconds);
  };

  useEffect(() => {
    emitter.on(REPLAY_TIMESTAMP_UPDATE, updateTimestamp);
    return () => emitter.off(REPLAY_TIMESTAMP_UPDATE, updateTimestamp);
  }, []);

  const focusedProductsLive = useSelector(focusedProductsSelector);

  const focusedProducts = focusedProductsUtility(wiz, products, currentTimestamp, focusedProductsLive);

  return (
    <Box className={parentWidth <= 488 ? classes.wrapper : classes.wrapperDesktop}>
      {products
        .filter((p) => p.enabled)
        .filter((product) => {
          if (search == null) return product;
          else if (product.name.toLowerCase().includes(search.toLowerCase())) return product;
        })
        .map((product) => {
          if (mobileLayout) {
            if (products.length < 5) {
              return <ExpandedCard key={product.id} product={product} focusedProducts={focusedProducts} />;
            } else {
              return <ShortCard key={product.id} product={product} focusedProducts={focusedProducts} />;
            }
          } else {

            if (parentWidth <= 488) {
              return <ShortCard key={product.id} product={product} focusedProducts={focusedProducts} />;
            }


            return <DesktopCard key={product.id} product={product} focusedProducts={focusedProducts} />;


          }
        })}
    </Box>
  );
};

FilteredProducts.propTypes = {};

FilteredProducts.defaultProps = {};

export default FilteredProducts;
