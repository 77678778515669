import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import PropTypes from "prop-types";

import IconButton from "app/liveShopping/backoffice/otm/components/reusables/IconButton";
import CustomCrossLg from "./CustomCrossLg";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    zIndex: "10",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.colors.dark50,
  },
  data: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    padding: "0 10px",
  },
  image: {
    width: "100%",
    height: "260px",
    borderRadius: "10px",
    background: (props) => props.picture && `center / contain no-repeat url(${props.picture})`,
  },
  hover: {
    marginTop: "15px",
  },
}));

const DisplayImage = ({ picture, onClose }) => {
  const classes = useStyles({ picture });

  return (
    <Box className={classes.root}>
      <Box className={classes.data}>
        <ClickAwayListener onClickAway={onClose}>
          <Box className={classes.image} />
        </ClickAwayListener>
        <IconButton className={classes.hover} color="tertiary" size="small" onClick={onClose}>
          <CustomCrossLg />
        </IconButton>
      </Box>
    </Box>
  );
};

DisplayImage.propTypes = {
  picture: PropTypes.string,
  onClose: PropTypes.func,
};

DisplayImage.defaultProps = {
  picture: "",
  onClose: () => { },
};

export default DisplayImage;
