import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { _t } from "i18n";
import get from "lodash/get";
import clsx from "clsx";
import isNumber from "lodash/isNumber";
import { FormIcon } from "appComponents/Viewers";
import {
  useFormIntroState,
  useFormEndState,
  useFormSectionState,
} from "appComponents/SurveyForms";
import { getTimeRemainingTillTimeout } from "utilities/utils/date";
import emitter from "core/emitter";
import { TOOLTIP_INTERACTIVITY } from "core/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  surveyTitle: {
    //borderRadius: 0,
    // borderTopLeftRadius: (props) =>
    //   props.isThumbnailView || props.layoutMode === "split" ? 0 : 15,
    // borderTopRightRadius: (props) =>
    //   props.isThumbnailView || props.layoutMode === "split" ? 0 : 15,
    borderRadius: 0,

    backgroundColor: theme.palette.background.secondaryBox,
    color: theme.palette.text.primary,
    width: "100%",
    //height: theme.spacing(10),
    [theme.breakpoints.up("sm")]: {
      height: (props) => (props.isThumbnailView ? "100%" : "auto"),
      minHeight: "auto",
      padding: (props) => (props.isThumbnailView ? "0" : "30px"),
    },
    [theme.breakpoints.down("xs")]: {
      minHeight: "88px",
      height: "auto",
      //padding: "30px",
    },
  },
  responseTitle: {
    color: theme.palette.button.default.color.enable,
    minHeight: theme.spacing(10),
  },
  titleZone: {
    // width: (props) => (props.layoutMode === "split" ? "41px" : "54px"),
    // minWidth: (props) => (props.layoutMode === "split" ? "41px" : "54px"),
    // height: (props) => (props.layoutMode === "split" ? "41px" : "54px"),
    // marginRight: (props) => (props.isThumbnailView ? 0 : "11px"),
    //width: "100%",
    display: (props) => (props.isThumbnailView ? "none" : "block"),
    [theme.breakpoints.up("sm")]: {
      width: (props) =>
        props.isThumbnailView
          ? `calc(100% - ${65}px)`
          : props.hasTimeout
          ? `calc(100% - ${65 + 11 + 75}px)`
          : `calc(100% - ${65 + 11}px)`,
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  icon: {
    fontSize: (props) => (props.isThumbnailView ? "1rem" : "2.18rem"),
  },

  content: {
    flexDirection: (props) => (props.isThumbnailView ? "column" : "row"),
    justifyContent: (props) =>
      props.isThumbnailView ? "space-around" : "flex-start",
  },
  titleText: {
    marginBottom: theme.spacing(1.25),
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: (props) => (props.isThumbnailView ? 0 : 1),
    display: "-webkit-box",

    WebkitBoxOrient: "vertical",
    color: theme.palette.button.default.color.enable,
    fontWeight: 700,
    lineHeight: "125%",
    letterSpacing: " 0.5px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      WebkitLineClamp: 2,
      fontSize: "18px",
    },
    [theme.breakpoints.up("sm")]: {
      WebkitLineClamp: 3,
      fontSize: (props) =>
        props.isThumbnailView
          ? "12px"
          : props.layoutMode === "split"
          ? "20px"
          : "20px",
    },
    [theme.breakpoints.down("lg")]: {
      WebkitLineClamp: 2,
    },
  },
  actionText: {
    color: theme.palette.secondary.contrastText,
  },
  meta: {
    flexDirection: "row",
    justifyContent: "center",
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    visibility: (props) =>
      props.forResult || props.showIntro ? "hidden" : "visible",
  },
  metaText: {
    color: theme.palette.button.default.color.enable,
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "125%",
    letterSpacing: "0.5px",
    fontSize: "14px",

    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    [theme.breakpoints.up("xl")]: {
      WebkitLineClamp: 3,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  details: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
  },
  progressRoot: {
    color: theme.palette.secondary.main,
    position: "absolute",

    width: "75px !important",
    height: "75px !important",

    [theme.breakpoints.down("xs")]: {
      width: "65px !important",
      height: "65px !important",
    },
  },
  bottom: {
    color: theme.wisemblyColors.greyTimeout,

    width: "75px !important",
    height: "75px !important",
    [theme.breakpoints.down("xs")]: {
      width: "65px !important",
      height: "65px !important",
    },
  },
  circle: {},
  timeoutZone: {
    width: "75px",
    height: "75px",
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "5px",
    [theme.breakpoints.down("xs")]: {
      width: "65px",
      height: "65px",
    },
  },
  value: {
    fontSize: "16px",
    letterSpacing: "0.5px",
    color: theme.palette.button.default.color.enable,
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  asterisk: {
    display: (props) => (props.showRequired ? "inline" : "none"),
    color: theme.wisemblyColors.pastelRed,
    marginLeft: "6px",
  },
}));
const Timeout = ({ timeout, startDate }) => {
  const [countDown, setCountDown] = useState(
    getTimeRemainingTillTimeout({ date: startDate, timeout })
  );
  //let progressValue
  useEffect(() => {
    if (!countDown.isExpired) {
      const interval = setInterval(() => {
        setCountDown(getTimeRemainingTillTimeout({ date: startDate, timeout }));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [countDown.isExpired, timeout, startDate]);
  const classes = useStyles({ isExpired: countDown.isExpired });
  const infos = [
    {
      label: "hours",
      value: countDown.hours.toLocaleString("fr-fr", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
      hide:
        (countDown.days === 0 && countDown.hours === 0) || countDown.isExpired,
    },
    {
      label: "minutes",
      value: countDown.minutes.toLocaleString("fr-fr", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
      hide:
        countDown.days === 0 &&
        countDown.hours === 0 &&
        countDown.minutes === 0,
    },
    {
      label: "seconds",
      value: countDown.seconds.toLocaleString("fr-fr", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      }),
    },
  ];

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        thickness={3}
        value={100}
      />
      <CircularProgress
        classes={{
          root: classes.progressRoot,
          circle: classes.circle,
        }}
        variant="determinate"
        thickness={3}
        value={countDown.percent}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {infos.map((info) => {
          return (
            <Box
              key={info.label}
              display={info.hide ? "none" : "inline-flex"}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
              className={clsx(`${info.label}`, classes.dateDetailsBlock)}
            >
              <Typography className={classes.value}>{info.value}</Typography>
              {!(info.label === "seconds" || countDown.isExpired) && (
                <span>:</span>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
Timeout.propTypes = {
  timeout: PropTypes.string,
  startDate: PropTypes.string,
};
const SurveyItemTitle = ({
  survey,
  item,
  isThumbnailView,
  layoutMode,
  forResult,
  notifyCanNotSeeAnswer,
  showSurveyTitle,
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const showIntro = useFormIntroState(get(survey, "hash", ""));
  const showEnd = useFormEndState(get(survey, "hash", ""));
  const timeout = get(survey, "timeout");
  const surveyStart = get(survey, "started_at");
  const hasTimeout = isNumber(timeout) && timeout > 0;
  const showRequired =
    get(item, "required", false) &&
    ["form", "evaluation"].includes(get(survey, "type", ""));
  const surveySection = useFormSectionState(get(survey, "hash", ""));
  const title =
    notifyCanNotSeeAnswer || showIntro || showEnd || showSurveyTitle
      ? survey.name
      : (item && item.label) || "";
  useEffect(() => {
    if (surveySection === "present") {
      emitter.emit(TOOLTIP_INTERACTIVITY, title);
    }
  }, [surveySection, title]);
  const classes = useStyles({
    isThumbnailView,
    smallScreen,
    layoutMode,
    forResult,
    showIntro,
    hasTimeout,
    showRequired,
  });

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.surveyTitle}
    >
      <Box
        flex={1}
        display="flex"
        height="100%"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.content}
      >
        <FormIcon
          type={
            survey.type === "form" || survey.type === "evaluation"
              ? survey.type
              : item.type
          }
          isThumbnailView={isThumbnailView}
          layoutMode={layoutMode}
          hide={smallScreen}
        />
        <Box className={classes.titleZone}>
          <Typography display="block" className={classes.titleText}>
            {notifyCanNotSeeAnswer || showIntro || showEnd || showSurveyTitle
              ? survey.name
              : (item && item.label) || ""}
            <span className={classes.asterisk}>*</span>
          </Typography>
          {!notifyCanNotSeeAnswer && (
            <Box className={classes.meta}>
              <Typography className={classes.metaText}>
                {item && item.help}
              </Typography>
            </Box>
          )}
        </Box>
        {hasTimeout && !forResult && (
          <Box className={classes.timeoutZone}>
            <Timeout timeout={timeout} startDate={surveyStart} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
SurveyItemTitle.propTypes = {
  survey: PropTypes.object,
  item: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
  forResult: PropTypes.bool,
  notifyCanNotSeeAnswer: PropTypes.bool,
  showSurveyTitle: PropTypes.bool,
};
SurveyItemTitle.defaultProps = {
  isThumbnailView: false,
  forResult: false,
  showSurveyTitle: false,
};

export default SurveyItemTitle;
