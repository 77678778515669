import React from "react";
import PropTypes from "prop-types";

const CustomPlusLg = ({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      {...rest}
      viewBox="0 0 14 14"
    >
      <path
        d="M7.39775 1.78975C7.29226 1.68426 7.14918 1.625 7 1.625C6.85082 1.625 6.70774 1.68426 6.60225 1.78975C6.49676 1.89524 6.4375 2.03832 6.4375 2.1875V6.4375H2.1875C2.03832 6.4375 1.89524 6.49676 1.78975 6.60225C1.68426 6.70774 1.625 6.85082 1.625 7C1.625 7.14918 1.68426 7.29226 1.78975 7.39775C1.89524 7.50324 2.03832 7.5625 2.1875 7.5625H6.4375V11.8125C6.4375 11.9617 6.49676 12.1048 6.60225 12.2102C6.70774 12.3157 6.85082 12.375 7 12.375C7.14918 12.375 7.29226 12.3157 7.39775 12.2102C7.50324 12.1048 7.5625 11.9617 7.5625 11.8125V7.5625H11.8125C11.9617 7.5625 12.1048 7.50324 12.2102 7.39775C12.3157 7.29226 12.375 7.14918 12.375 7C12.375 6.85082 12.3157 6.70774 12.2102 6.60225C12.1048 6.49676 11.9617 6.4375 11.8125 6.4375H7.5625V2.1875C7.5625 2.03832 7.50324 1.89524 7.39775 1.78975Z"
        fill="#808284"
        stroke="#808284"
        stroke-width="0.25"
      />
    </svg>
  );
};

CustomPlusLg.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomPlusLg.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomPlusLg;
