import React from "react";
import PropTypes from "prop-types";

const CustomArrowClockLess = ({ color, size, ...rest }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M25.0411 8.59774C23.1425 7.1748 20.8611 6.36229 18.4984 6.26082V5.0097C21.1314 5.11147 23.6757 6.01141 25.7908 7.596C28.0395 9.28071 29.6818 11.6486 30.4717 14.3451C31.2617 17.0415 31.1568 19.9213 30.1727 22.5531C29.1886 25.1849 27.3783 27.4271 25.013 28.9437C22.6476 30.4603 19.8546 31.1697 17.0523 30.9657C14.2499 30.7617 11.5891 29.6552 9.46831 27.8121C7.34756 25.9689 5.88107 23.4882 5.2885 20.7417C4.69674 17.9989 5.00831 15.1383 6.17648 12.5874C6.25027 12.4474 6.37447 12.3405 6.52411 12.2885L6.35993 11.8162L6.52411 12.2885C6.67653 12.2355 6.84354 12.2436 6.99018 12.3109C7.13683 12.3782 7.25174 12.4997 7.31087 12.6498C7.36896 12.7973 7.36879 12.9613 7.31062 13.1085C6.25514 15.4138 5.97359 17.9987 6.5082 20.4774C7.04363 22.9598 8.3689 25.2019 10.2855 26.868C12.2022 28.534 14.607 29.5343 17.1398 29.7189C19.6726 29.9035 22.197 29.2626 24.335 27.8922C26.473 26.5217 28.1094 24.4955 28.9992 22.117C29.889 19.7384 29.9842 17.1356 29.2707 14.6984C28.5571 12.2612 27.0732 10.1208 25.0411 8.59774Z" />
      <path d="M18.0012 10.0485V1.20151C18.0011 1.09463 17.9706 0.989968 17.9133 0.899788C17.8559 0.809608 17.774 0.73764 17.6772 0.692311C17.5804 0.646983 17.4727 0.63017 17.3667 0.643843C17.2607 0.657515 17.1608 0.701106 17.0787 0.769511L11.7687 5.19301C11.7053 5.2458 11.6544 5.31186 11.6195 5.38651C11.5845 5.46116 11.5664 5.54258 11.5664 5.62501C11.5664 5.70744 11.5845 5.78886 11.6195 5.86351C11.6544 5.93816 11.7053 6.00422 11.7687 6.05701L17.0787 10.4805C17.1608 10.5489 17.2607 10.5925 17.3667 10.6062C17.4727 10.6199 17.5804 10.603 17.6772 10.5577C17.774 10.5124 17.8559 10.4404 17.9133 10.3502C17.9706 10.2601 18.0011 10.1554 18.0012 10.0485Z" />
      <path d="M10 20.0244H13.2471V18.7402H10V20.0244Z" />
      <path d="M16.4795 22.2266H17.9541V15.1807H16.4844L14.6631 16.4453V17.7734L16.3916 16.5723H16.4795V22.2266Z" />
      <path d="M22.583 22.4072C24.3262 22.4072 25.3809 20.9814 25.3809 18.7061V18.6963C25.3809 16.416 24.3262 15 22.583 15C20.8447 15 19.7852 16.416 19.7852 18.6963V18.7061C19.7852 20.9814 20.8447 22.4072 22.583 22.4072ZM22.583 21.2402C21.7529 21.2402 21.2793 20.2979 21.2793 18.7061V18.6963C21.2793 17.0996 21.7529 16.167 22.583 16.167C23.418 16.167 23.8916 17.0996 23.8916 18.6963V18.7061C23.8916 20.2979 23.418 21.2402 22.583 21.2402Z" />
    </svg>
  );
};

CustomArrowClockLess.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomArrowClockLess.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomArrowClockLess;
