import { Box, makeStyles, Typography } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _t } from "utilities/i18n";
import { isUser } from "utilities/access";
import ResourceFactory from "orm/resources";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import CustomSendIcon from "app/liveShopping/assets/icons/CustomSendIcon";
import { replaceBadWordsFromString } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
  },
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    background: theme.palette.colors.white,
    width: "100%",
    height: props => props.size === "s" ? "35px" : "60px",
    borderTop: "1px solid #F0F0F4",
    boxSizing: "border-box",
    padding: " 0 8px",
    gap: "20px",
    // position: "fixed",
    bottom: 0,
  },
  typoSend: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.white,
  },
  input: {
    height: props => props.size === "s" ? "30px !important" : "38px !important",
    border: "none",
  },
  btn: {
    background: "none",
    padding: 0,
  },
}));

const Footer = ({ disabled, currentVisitor, size }) => {
  const classes = useStyles({ size });
  const [message, setMessage] = useState("");

  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const dispatch = useDispatch();

  const handleKeyPress = (e) => {
    if (e.keyCode !== 13) return;
    postMessage();
  };



  const postMessage = () => {
    if (message === "") return;
    const user = isUser("user+") ? "vendor" : "visitor";

    const newMessage = resourceFactory.create("Custom", { message: replaceBadWordsFromString(message), user });


    dispatch(resourceEdit(newMessage, {
      slug: ["visitors", currentVisitor.hash, currentVisitor.token, "message"],
      method: "POST",
      callback: () => {

        var regex = /\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/gmi // The actual regex
        var matches = regex.exec(message);

        if (!matches || !matches[0]) return;

        const newVisitor = resourceFactory.create("Visitor", { options: { ...currentVisitor.options, form: { email: matches[0] } } });

        dispatch(
          resourceEdit(newVisitor, {
            slug: ["visitors", currentVisitor.hash, currentVisitor.token],
            patch: ["options"],
          })
        );

      }
    }))
    setMessage("");
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.root}>
        <Input id={'inputChat'} onKeyDown={handleKeyPress} disabled={disabled} clsName={classes.input} value={message} onChange={(e) => setMessage(e.target.value)} placeholder={_t("Enter your message...")} />

        <Button disabled={disabled} clsName={classes.btn} onClick={postMessage}>
          <CustomSendIcon size={size === "s" ? 25 : 35} color={"#B4B6B9"} />
        </Button>
      </Box>
    </Box>
  )
};

export default Footer;
