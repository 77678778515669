import { Box, makeStyles, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react"
import { useHover } from "../../hooks/useHover";
import { markdownToHTML } from "core/utils/markdown";

const defaultBubble = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "5px 10px",
  borderRadius: "3px 19px 19px 15px",
  marginTop: "10px",
  width: "fit-content",
  maxWidth: "calc(100% - 50px)",
};

const useStyles = makeStyles((theme) => ({
  bubble: {
    ...defaultBubble,
    background: "#F0F0F4",
  },
  isMyBubble: {
    ...defaultBubble,
    background: theme.palette.colors.primary,
    color: theme.palette.colors.white,
  },
  bubbleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  typoMessage: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",

    "& p": {
      margin: 0,
      wordBreak: "break-word",
    },
    "& p > a": {
      color: theme.palette.colors.white,
    }
  },
  duration: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "100%",
    marginTop: "5px",
  },
}));

const Bubble = ({ message, publishedAt, scope }) => {
  const [hoverRef, isHovering] = useHover();
  const classes = useStyles();
  const [duration, setDuration] = useState("");
  var drtion = require('dayjs/plugin/duration')
  dayjs.extend(drtion)
  useEffect(() => {
    if (dayjs(publishedAt).unix() === 0) return;
    const dateNow = dayjs();
    setDuration(dayjs.duration(dateNow.diff(publishedAt)).humanize());

  }, [new Date()]);

  const replaceURLs = (quote) => {
    if (!quote) return;

    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return quote.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + "</a>";
    });
  };


  return (
    <Box className={classes.bubbleContainer}>
      <Box ref={hoverRef} className={scope === "vendor" ? classes.isMyBubble : classes.bubble}>
        {scope === "vendor" ? <div
          className={classes.typoMessage}
          dangerouslySetInnerHTML={{
            __html: markdownToHTML(replaceURLs(message)),
          }} /> : replaceURLs(message)}

      </Box>
      {isHovering && <Typography className={classes.duration}>{duration}</Typography>}
    </Box>
  )
}

export default Bubble;