import React, { useEffect, useState } from "react";
import { useField } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import PercentIndicator from "appComponents/Viewers/PercentIndicator";
import {
  useCurrentFieldHash,
  ItemMeta,
  SurveyProgressBar,
} from "appComponents/SurveyForms";
import basil from "core/basil";
import emitter from "core/emitter";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { getParsedPercent } from "utilities/utils";
import KeyboardArrowDownOutlinedIcon from "@material-ui/icons/KeyboardArrowDownOutlined";

import { EVENT_RESET_SURVEY_FORM } from "core/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  optionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1.875),
  },
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
  },
  detailsPercent: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
    width: "calc(100% - 40px)",
    paddingLeft: theme.spacing(2.25),
  },
  optionLabel: {
    paddingLeft: theme.spacing(1.25),
    width: "calc(100% - 40px)",
    display: "flex",
    flex: "auto",
    flexDirection: "row",
    alignItems: "center",

    [theme.breakpoints.up("xs")]: {
      fontSize: (props) => (props.layoutMode === "split" ? "16px" : "18px"),
    },
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: 0.5,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  answerType: {},
  itemHelp: {
    marginBottom: theme.spacing(1),
  },
  itemLabel: {},
  errorMsg: {
    color: theme.palette.error.main,
  },
  required: {
    display: (props) => (props.error ? "block" : "none"),
    color: (props) => (props.error ? theme.palette.error.main : "currentcolor"),
    position: "absolute",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
    fontSize: "12px",
    marginTop: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  dropdownDetailsSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "calc(100% - 40px)",
  },
  detailsSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  percentText: {
    width: "45px",
  },
  radio: {
    marginLeft: -5,
    padding: 5,
    "& svg": {
      fontSize: "30px",
    },
    "& svg:first-of-type": {
      color: "transparent",
      backgroundColor: theme.palette.background.secondaryBox,
      borderRadius: "100%",
    },
    "& svg:last-of-type": {
      color: theme.palette.secondary.main,
    },
  },
  checked: {
    color: theme.palette.secondary.main,
  },
  dropdownLabel: {
    top: 16,
    transform: "none",
    left: 12,
    fontSize: 14,
    lineHeight: 1.25,
    color: theme.palette.text.primary,
    zIndex: 1,
    "&.MuiFormLabel-filled": {
      display: "none",
    },
    "&.Mui-focused": {
      color: theme.palette.text.primary,
    },
  },
  dropdownOptionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    "& p": {
      fontSize: 14,
    },
  },
  dropdownOptionLabel: {
    width: "calc(100% - 40px)",
    display: "flex",
    flex: "auto",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
  },
  formControl: {
    //backgroundColor: theme.wisemblyColors.white,
    borderRadius: "5px",
    "& label + .MuiInput-formControl": {
      marginTop: 0,
    },
  },
  inputRoot: {
    color: theme.wisemblyColors.black,
    backgroundColor: theme.wisemblyColors.white,
    borderRadius: "5px",
    borderColor: theme.palette.border.textField,
    border: "1px solid",
  },
  inputFormControl: {},
  selectIcon: {
    color: theme.wisemblyColors.black,
    fontSize: 25,
    top: 11.5,
    right: 7,
    transition: 0.25,
  },
  menuPaper: {
    backgroundColor: theme.palette.background.box,
    boxShadow: theme.palette.shadow,
    marginTop: 5,
    borderRadius: 5,
    "& ul": {
      padding: 0,
    },
  },
  menuList: {
    //
  },
  listItemButton: {
    "&:hover": {
      backgroundColor: theme.palette.background.secondaryBox,
    },
  },
  menuItemSelected: {
    backgroundColor: theme.palette.background.secondaryBox,
  },
  select: {
    padding: "15px 12px",
    "&.MuiSelect-select": {
      paddingRight: 36,
    },
    minHeight: "auto",
    backgroundColor: theme.wisemblyColors.white,
    borderRadius: "5px",
    "& > span": {
      display: "block",
      height: 17,
    },
    "&:focus": {
      backgroundColor: theme.wisemblyColors.white,
      borderRadius: "5px",
    },
  },
  detailsPosition: {
    //padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    backgroundColor: theme.palette.background.secondaryBox,
    color: theme.palette.secondary.main,
  },
  menuItem: {
    padding: "12.5px 10px",
    "&:focus": {
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: theme.palette.background.secondaryBox,
      },
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.secondaryBox,
      "&:hover": {
        backgroundColor: theme.palette.background.secondaryBox,
      },
    },
  },
}));

const DropDownList = ({
  withLiveResult,
  canAnswer,
  handleChange,
  selectedValue,
  options,
  summary,
}) => {
  const classes = useStyles();

  const result =
    options.choices &&
    options.choices.map((optn) => {
      return (
        <MenuItem
          key={optn.hash}
          value={optn.hash}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected,
            gutters: classes.menuItemGutters,
          }}
          ListItemClasses={{ button: classes.listItemButton }}
        >
          <Box flexWrap="wrap" className={classes.dropdownOptionRow}>
            <Box className={classes.main}>
              <Typography className={classes.dropdownOptionLabel}>
                {optn.label}
              </Typography>
            </Box>
          </Box>
        </MenuItem>
      );
    });
  const liveResult =
    withLiveResult &&
    options.choices &&
    options.choices.map((optn, key) => {
      const percent = get(summary, `${get(optn, "hash", "hash")}.percent`);
      return (
        <Box key={optn.hash} flexWrap="wrap" className={classes.optionRow}>
          <Box className={classes.main}>
            <Box className={classes.detailsPosition}>{key + 1}</Box>
            <Box className={classes.dropdownDetailsSection}>
              <Typography className={classes.optionLabel}>
                {optn.label}
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                flexDirection="row"
                className={classes.detailsPercent}
              >
                <PercentIndicator percent={percent} />
                <Box
                  ml={1}
                  className={classes.percentText}
                >{`${getParsedPercent(percent)}%`}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    });

  const menuClasses = { paper: classes.menuPaper };
  const inputClasses = {
    root: classes.inputRoot,
    formControl: classes.inputFormControl,
  };
  return (
    <FormControl classes={{ root: classes.formControl }}>
      <InputLabel
        classes={{
          root: classes.dropdownLabel,
          focused: classes.focusedLabel,
        }}
        id="select-outlined-label"
        shrink={false}
      >
        {_t("Choose a response")}
      </InputLabel>
      <Select
        labelId="select-outlined-label"
        id="form_option"
        value={selectedValue}
        onChange={canAnswer ? handleChange : () => {}}
        displayEmpty
        IconComponent={KeyboardArrowDownOutlinedIcon}
        classes={{ select: classes.select, icon: classes.selectIcon }}
        MenuProps={{
          classes: menuClasses,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          getContentAnchorEl: null,
        }}
        inputProps={{ classes: inputClasses }}
        disableUnderline
      >
        {result}
      </Select>
      <Box mt={1}></Box>
      {liveResult}
    </FormControl>
  );
};
DropDownList.propTypes = {
  options: PropTypes.array,
  canAnswer: PropTypes.bool,
  handleChange: PropTypes.func,
  selectedValue: PropTypes.string,
  summary: PropTypes.object,
  withLiveResult: PropTypes.bool,
};
//update later with material ui radio
export const UniqueField = ({
  name,
  required,
  options,
  help,
  summary,
  withLiveResult,
  canAnswer,
  hash,
}) => {
  const [selectedValue, setSelectedValue] = useState();
  const currentFieldHash = useCurrentFieldHash();
  const layoutMode = basil.get("currentLayoutMode") || "split";
  const validate = (value) => {
    let error;
    if (currentFieldHash !== hash) return;
    if (required && (!value || isEmpty(value.trim()) < 0)) {
      error = _t("field is required");
    }
    if (required && options.choices) {
      const values = [];
      const labels = [];

      options.choices.forEach(({ hash, label }) => {
        values.push(hash);
        labels.push(label);
      });
      error = _t("option must be one of options {labels}", {
        labels: labels.join(","),
      });
      if (values.includes(value)) error = null;
    }
    return error;
  };
  const [field, meta, helpers] = useField({
    name,
    validate,
    // value: ratingValue,
    // type: "number",
  });
  const { setValue } = helpers;

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setValue(event.target.value, true);
  };
  useEffect(() => {
    const callback = () => setValue("", false);

    emitter.on(EVENT_RESET_SURVEY_FORM, callback);

    return () => emitter.removeListener(EVENT_RESET_SURVEY_FORM, callback);
  }, [setValue]);
  const classes = useStyles({ error: meta.error, layoutMode });

  const result =
    options.choices &&
    options.choices.map((optn) => {
      const percent = get(summary, `${get(optn, "hash", "hash")}.percent`);
      return (
        <Box key={optn.hash} flexWrap="wrap" className={classes.optionRow}>
          <Box className={classes.main}>
            <Radio
              name={name}
              value={optn.hash}
              disabled={!canAnswer}
              onChange={handleChange}
              checked={isEqual(optn.hash, selectedValue)}
              classes={{ root: classes.radio, checked: classes.checked }}
              color="secondary"
            />
            <Box className={classes.detailsSection}>
              <Typography className={classes.optionLabel}>
                {optn.label}
              </Typography>
              {withLiveResult && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                  flexDirection="row"
                  className={classes.detailsPercent}
                >
                  <PercentIndicator percent={percent} />
                  <Box
                    ml={1}
                    className={classes.percentText}
                  >{`${percent}%`}</Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      );
    });
  const dropdown = (
    <DropDownList
      canAnswer={canAnswer}
      options={options}
      handleChange={handleChange}
      selectedValue={selectedValue}
      withLiveResult={withLiveResult}
      summary={summary}
    />
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
      className={classes.errorBorder}
    >
      <SurveyProgressBar />
      <ItemMeta />
      {required && (
        <Typography className={classes.required}>
          {_t("This field is required")}
        </Typography>
      )}
      <Box
        mb={3.25}
        height="100%"
        display="flex"
        flexDirection="column"
        width="100%"
      >
        {options.expanded ? result : dropdown}
      </Box>
    </Box>
  );
};
UniqueField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  help: PropTypes.string,
  withLiveResult: PropTypes.bool,
  summary: PropTypes.array,
  canAnswer: PropTypes.bool,
  hash: PropTypes.string,
};

export default UniqueField;
