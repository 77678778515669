import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "coreUiKit/inputs/Button";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  bubbles: {
    display: "flex",
    flexDirection: "column",
    justifycontent: "center",
    alignItems: "center",
    margin: "10px 0px 5px 0px",
  },
  box: {
    margin: "5px 0px",
  },
  text: {
    marginBottom: "10px",
  },
}));

const IntroCTA = ({ messagesBubbles, text }) => {
  const classes = useStyles();

  return (
    <Box className={clsx("ChatEmptyState", classes.root)}>
      <Box className={clsx("EmptyStateBubbles", classes.bubbles)}>
        {messagesBubbles.map((message, i) => (
          <Button color="primary" size="small" className={classes.box} key={i}>
            {message}
          </Button>
        ))}
      </Box>
      <Typography
        className={classes.text}
        color={"greyDark"}
        variant={"smallBody13"}
      >
        {text}
      </Typography>
    </Box>
  );
};

IntroCTA.propTypes = {
  messagesBubbles: PropTypes.array,
  text: PropTypes.string,
};

IntroCTA.defaultProps = {
  messagesBubbles: [],
  text: "",
};

export default IntroCTA;
