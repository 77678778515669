import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Appointment from "app/liveShopping/frontoffice/oto/components/Appointment";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { currentCalendarSelector } from "app/state/ducks/liveShopping/selectors";
import React, { useState, useEffect } from "react";
import { Calendar, Calendar2Week, CalendarDay } from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Fade } from "react-reveal";
import { _t } from "utilities/i18n";
import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    overflowY: "auto",
  },
  bubble: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "5px 10px",
    borderRadius: "3px 19px 19px 15px",
    marginTop: "10px",
    width: "fit-content",
    maxWidth: "calc(100% - 50px)",
    background: theme.palette.colors.primary,
    cursor: "pointer",
  },
  typo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    color: "grey",
  },
  typoBubble: {
    color: "#FFFFFF",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  button: {
    backgroundColor: "#F2F2F2",
    color: "#000000",
  },
  icon: {
    paddingTop: "5px",
  },
  link: {
    color: "#000000",
  },
  modal: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    width: "95%",
    height: "95%",
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
  },
  cgu: {
    textAlign: "left",
  },
}));

const ELAPSED_WAITING_TIME = 15000;

const OnBoard = () => {
  const classes = useStyles();
  const project = useSelector(currentProjectSelector);

  const [open, setOpen] = useState(false);
  const [sent, setSent] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(false);
  const currentCalendar = useSelector(currentCalendarSelector);

  useEffect(() => {
    setTimeout(() => setElapsedTime(true), ELAPSED_WAITING_TIME);
  }, []);

  if (sent)
    setTimeout(() => setOpen(false), 3000);

  const handleSchedule = () => setOpen(true);

  return (
    <Box className={classes.root}>

      {elapsedTime && currentCalendar && currentCalendar.active ? (<>
        <Box className={classes.bubble}>
          <Typography className={classes.typoBubble}>{_t("Taking too long?")}</Typography>
        </Box>
        <Box className={classes.bubble} onClick={handleSchedule}>
          <Typography className={classes.typoBubble}>
          <CalendarDay className={classes.icon} size={18} />
          &nbsp;
          {_t("Book an appointment by clicking here")}
          </Typography>
        </Box></>) : elapsedTime && (
        <Box className={classes.bubble}>
          <Typography className={classes.typoBubble}>{_t("What can i do for you, today ? Enter your email by message to be contacted")}</Typography>
        </Box>
      )}

      {/* {elapsedTime && (
        <Box className={classes.bubble}>
          <Typography className={classes.typoBubble}>{_t("What can i do for you, today ? Enter your email by message to be contacted")}</Typography>
        </Box>
      )} */}

      <Fade>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="parent-modal-title"
          aria-describedby="parent-modal-description"
        >
          <Box className={classes.modal}>
            <Appointment onCancel={() => setOpen(false)} />
          </Box>
        </Modal>
      </Fade>

    </Box>
  )
};

export default OnBoard;
