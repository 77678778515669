import React from "react";
import PropTypes from "prop-types";

const Empty = ({ color, size, ...rest }) => {
  return (<svg width="190" height="168" viewBox="0 0 190 168" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M99.8429 145.295C138.588 145.295 170 113.884 170 75C170 36.1162 138.588 4.70472 99.8429 4.70472C61.0975 4.70472 29.686 36.1162 29.686 75C29.686 113.884 61.0975 145.295 99.8429 145.295Z" fill="#F7F7F9"/>
  <g filter="url(#filter0_d_5305_35002)">
  <path d="M164.88 46.9098H44.9079C42.2788 46.9098 40.2031 44.8342 40.2031 42.205V15.2216C40.2031 12.5924 42.2788 10.5168 44.9079 10.5168H164.742C167.371 10.5168 169.447 12.5924 169.447 15.2216V42.205C169.585 44.8342 167.371 46.9098 164.88 46.9098Z" fill="white"/>
  </g>
  <rect x="48.5117" y="18.2717" width="41.7835" height="20.7512" rx="2" fill="#E2E4E8"/>
  <path d="M124.339 25.5997H97.7711C97.0792 25.5997 96.6641 25.0462 96.6641 24.4927V21.0333C96.6641 20.3414 97.2176 19.9262 97.7711 19.9262H124.339C125.031 19.9262 125.446 20.4797 125.446 21.0333V24.4927C125.446 25.1845 125.031 25.5997 124.339 25.5997Z" fill="#E2E4E8"/>
  <path d="M155.889 37.3617H97.7711C97.0792 37.3617 96.6641 36.8082 96.6641 36.2547V32.7953C96.6641 32.1034 97.2176 31.6883 97.7711 31.6883H155.889C156.581 31.6883 156.996 32.2418 156.996 32.7953V36.2547C156.996 36.9466 156.443 37.3617 155.889 37.3617Z" fill="#E2E4E8"/>
  <g filter="url(#filter1_d_5305_35002)">
  <path d="M164.88 137.684H44.9079C42.2788 137.684 40.2031 135.608 40.2031 132.979V105.996C40.2031 103.367 42.2788 101.291 44.9079 101.291H164.742C167.371 101.291 169.447 103.367 169.447 105.996V132.979C169.585 135.608 167.371 137.684 164.88 137.684Z" fill="white"/>
  </g>
  <rect x="48.5117" y="109.037" width="41.7835" height="20.7512" rx="2" fill="#E2E4E8"/>
  <path d="M124.339 116.513H97.7711C97.0792 116.513 96.6641 115.959 96.6641 115.406V111.946C96.6641 111.254 97.2176 110.839 97.7711 110.839H124.339C125.031 110.839 125.446 111.393 125.446 111.946V115.406C125.446 115.959 125.031 116.513 124.339 116.513Z" fill="#E2E4E8"/>
  <path d="M155.889 128.275H97.7711C97.0792 128.275 96.6641 127.721 96.6641 127.168V123.708C96.6641 123.016 97.2176 122.601 97.7711 122.601H155.889C156.581 122.601 156.996 123.155 156.996 123.708V127.168C156.996 127.721 156.443 128.275 155.889 128.275Z" fill="#E2E4E8"/>
  <g filter="url(#filter2_d_5305_35002)">
  <path d="M144.539 92.2969H24.7048C22.0756 92.2969 20 90.2212 20 87.5921V60.6086C20 57.9795 22.0756 55.9038 24.7048 55.9038H144.539C147.168 55.9038 149.244 57.9795 149.244 60.6086V87.5921C149.382 90.2212 147.168 92.2969 144.539 92.2969Z" fill="white"/>
  </g>
  <path d="M104.137 70.9868H77.5684C76.8766 70.9868 76.4614 70.4333 76.4614 69.8798V66.4204C76.4614 65.7285 77.0149 65.3134 77.5684 65.3134H104.137C104.829 65.3134 105.244 65.8669 105.244 66.4204V69.8798C105.244 70.5717 104.829 70.9868 104.137 70.9868Z" fill="#E2E4E8"/>
  <path d="M135.687 82.8876H77.5684C76.8766 82.8876 76.4614 82.3341 76.4614 81.7806V78.3212C76.4614 77.6293 77.0149 77.2141 77.5684 77.2141H135.548C136.24 77.2141 136.655 77.7676 136.655 78.3212V81.7806C136.794 82.3341 136.24 82.8876 135.687 82.8876Z" fill="#E2E4E8"/>
  <rect x="28.3091" y="63.6543" width="41.7835" height="20.7512" rx="2" fill="#E2E4E8"/>
  <defs>
  <filter id="filter0_d_5305_35002" x="20.2031" y="0.516785" width="169.25" height="76.393" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="10"/>
  <feGaussianBlur stdDeviation="10"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5305_35002"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5305_35002" result="shape"/>
  </filter>
  <filter id="filter1_d_5305_35002" x="20.2031" y="91.291" width="169.25" height="76.393" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="10"/>
  <feGaussianBlur stdDeviation="10"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5305_35002"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5305_35002" result="shape"/>
  </filter>
  <filter id="filter2_d_5305_35002" x="0" y="45.9038" width="169.25" height="76.393" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
  <feOffset dy="10"/>
  <feGaussianBlur stdDeviation="10"/>
  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5305_35002"/>
  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5305_35002" result="shape"/>
  </filter>
  </defs>
  </svg>);
};

Empty.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Empty.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default Empty;
