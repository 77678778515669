import { Box, Typography } from "@material-ui/core";
import React from "react";
import { CalendarCheck } from "react-bootstrap-icons";
import { _t } from "utilities/i18n";
import { makeStyles } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useDispatch, useSelector } from "react-redux";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import { emit } from "utilities/liveshopping-sdk";
import { endCallVisitor } from "app/liveShopping/backoffice/oto/Component/Live/service";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  checkRound: {
    backgroundColor: theme.palette.colors.success,
    borderRadius: "50%",
    padding: "5px",
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  successText: {
    fontWeight: "bold",
    fontSize: "18px",
    marginTop: "10px",
  },
}));
const SuccessPage = () => {
  const classes = useStyles();
  const handleQuit = () => {
    emit("CLOSE");
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.checkRound}>
        <CalendarCheck size={18} color={"#FFFFFF"} />
      </Box>
      <Typography className={classes.successText}>{_t("Your appointment has been scheduled!")}</Typography>

      <Button onClick={handleQuit}>{_t("Close")}</Button>
    </Box>
  );
}

export default SuccessPage;
