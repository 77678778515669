import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { _n } from "core/i18n";
import { onlineUsersSelector } from "applicationDucks/selectors";
import { currentWizSelector, resourceSelector, presentUserAccessesSelector } from "ressourcesDucks/selectors";
import { getWizState } from "appComponents/Header/WizState";
import { useSelector } from "react-redux";
import size from "lodash/size";
import { EVENT_PARTICIPANT_USER_COUNT } from "utilities/emitter/events";
import emitter from "utilities/emitter";
import { renderLog } from "utilities/memo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    width: "100%",

    //backgroundColor: theme.wisemblyColors.white,

    justifyContent: "flex-start",

    marginBottom: "50px",
    alignItems: "center",
    paddingLeft: "16px",
    //overflowX: "auto",
  },
  statBox: {
    backgroundColor: "white",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
    height: "40px",
    borderRadius: "5px",
    padding: "15px",
    marginRight: "15px",
    color: theme.palette.colors.primary,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  statNumber: {
    fontSize: "25px",
    fontWeight: 700,
  },
  statTitle: {
    fontSize: "16px",
    fontWeight: 400,
    marginLeft: "10px",
  },
}));
const StatBox = ({ title, count }) => {
  const classes = useStyles();
  return (
    <Box className={classes.statBox}>
      {" "}
      <Box className={classes.statNumber}>{count}</Box> <Box className={classes.statTitle}>{title}</Box>{" "}
    </Box>
  );
};
StatBox.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
};
const DumbComponent = ({ invited, presentCount, onlineCount, eventFinished, total_participants, landingParticipantLimitation, remainingPlaces }) => {
  renderLog("DumbComponent stats");
  const classes = useStyles();
  return (
    <Box className={clsx("userStats", classes.root)}>
      <StatBox title={_n("Invited", "Invited", invited)} count={invited} />
      {/* prettier-ignore */}
      <StatBox title={_n("Registered","Registered",presentCount )} count={presentCount} />
      {/* prettier-ignore */}
      <StatBox title={ _n("connected","connected",onlineCount)} count={onlineCount} />
      {eventFinished && (
        <>
          {/* prettier-ignore */}
          <StatBox
          title={_n("Had participated","Had participated",total_participants)}
          count={total_participants}
        />
        </>
      )}

      {!!landingParticipantLimitation && !eventFinished && (
        <>
          {/* prettier-ignore */}
          <StatBox
          title={_n("Remaining places","Remaining places",remainingPlaces)}
          count={remainingPlaces}
        />
        </>
      )}
    </Box>
  );
};
DumbComponent.propTypes = {
  invited: PropTypes.number,
  presentCount: PropTypes.number,
  onlineCount: PropTypes.number,
  eventFinished: PropTypes.bool,
  total_participants: PropTypes.number,
  landingParticipantLimitation: PropTypes.number,
  remainingPlaces: PropTypes.number,
};
const MemoDumb = memo(DumbComponent);
const Stats = () => {
  const [invited, setInvited] = useState(0);
  //const classes = useStyles();
  const onlineUsers = useSelector(onlineUsersSelector);
  const presentUsers = useSelector(presentUserAccessesSelector);
  const event = useSelector(currentWizSelector);
  const landing = useSelector((state) =>
    resourceSelector(state, {
      type: "Landing",
      id: event.keyword,
    })
  );

  useEffect(() => {
    const callback = (count) => {
      if (count !== invited) {
        setInvited(count);
      }
    };
    emitter.on(EVENT_PARTICIPANT_USER_COUNT, callback);
    return () => emitter.off(EVENT_PARTICIPANT_USER_COUNT, callback);
  }, []);
  const wizState = getWizState(event.start, event.stop);
  //const eventNotStarted = wizState === "WIZ_NOT_STARTED";
  const eventFinished = wizState === "WIZ_FINISHED";

  const landingParticipantLimitation = landing?.participant_limitation;
  const landingRegisteredParticipantCount = landing?.registered_participants_count;
  let remainingPlaces = landingParticipantLimitation - landingRegisteredParticipantCount;
  remainingPlaces = remainingPlaces >= 0 ? remainingPlaces : 0;
  const parameters = {
    invited,
    presentCount: presentUsers.length,
    onlineCount: size(onlineUsers),
    eventFinished,
    total_participants: event.total_participants || 0,
    landingParticipantLimitation,
    remainingPlaces,
  };
  return <MemoDumb {...parameters} />;
};

export default Stats;
