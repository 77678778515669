import { Box, CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { visitorReady } from "app/liveShopping/backoffice/oto/Component/Live/service";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import React, { useEffect } from "react";
import { Hourglass, HourglassSplit } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { Rotate } from "react-reveal";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    padding: "0px 35px",
    gap: "20px",
  },
}));

const WaitingForVendor = () => {
  const currentVisitor = useSelector(currentVisitorSelector);
  const dispatch = useDispatch();
  const classes = useStyles();
  const isPreparing = currentVisitor.status === "preparing";
  const project = useSelector(currentProjectSelector);

  useEffect(() => {
    if (isPreparing)
      visitorReady(dispatch, currentVisitor);
  }, []);

  return (
    <Box className={classes.root}>
      <Typography>{project?.options?.interface_messages?.waiting_message || _t("A vendor will join you in a few moments")}</Typography>
      <Rotate forever={true}>
        <HourglassSplit size={30} delay={5000} />
      </Rotate>
    </Box>
  )
};

export default WaitingForVendor;
