import { Box, ButtonBase, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { TabListAppointment } from "./TabList";
import AppointmentMenuTab from "./AppointmentMenuTab";
import { _t } from "utilities/i18n";
import { getUrl } from "app/views/containers/Application/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "170px",
    height: "100%",
    borderRight: `1px solid ${theme.palette.colors.grey300}`,
  },
  back: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000000",
    marginBottom: "20px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    marginTop: "20px",
    marginLeft: "16px",
  },
}));

const AppointmentMenu = ({ }) => {
  const classes = useStyles();
  const tabList = TabListAppointment();
  const history = useHistory();
  const { hash } = useParams();
  return (
    <Box className={classes.root}>
      <ButtonBase className={classes.back}
        onClick={() => history.push(getUrl("oto_groups", { hash }))}>
        {_t("Back")}
      </ButtonBase>

      {tabList.map(({ url, icon, title, }) => <AppointmentMenuTab url={url} icon={icon} title={title} />)}
    </Box>
  );
};

export default AppointmentMenu;
