import { _t } from "i18n";
import makeRequest from "core/makeRequest";
import { API_BASE } from "settings/config";

import get from "lodash/get";

import emitter from "core/emitter";
import {
  EVENT_VONAGE_STREAM_UPDATE,
  EVENT_VONAGE_STREAM_DELETE,
  EVENT_VONAGE_STREAM_FOCUS,
  EVENT_VONAGE_STREAM_CLEAR_FOCUS,
  EVENT_VONAGE_STREAM_COUNT_UPDATE,
} from "core/emitter/events";

import { resourceEdit } from "app/state/ducks/ressources/actions";
import * as Sentry from "@sentry/react";

// This stream object is ours and wraps the Vonage one
// It adds Wisembly-only features (like focus mode) that does not exist in Vonage world
// TODO: stream.stream might be a bit confusing. Maybe use stream.origin or stream.raw for less ambiguity?
export class VonageStream {
  constructor({ id, type, hasFocus, isOwn, stream }) {
    this.id = id;
    this.type = type;
    this.hasFocus = hasFocus;
    this.isOwn = isOwn;
    this.stream = stream;
    this.subscribed = isOwn || false;
  }
}

// Our Vonage SDK implementation where we decide how we choose to publish a stream
// How to subscribe other streams. Detec mic/cam and other stuffs
class Vonage {
  constructor(OT, dispatch, wiz) {
    // the opentok.min.js lazy-lodaed vonage SDK
    this.OT = OT;
    this.dispatch = dispatch;
    this.wiz = wiz;

    // api6 credentials to connect to Wiz Vonage room
    this.credentials = {};

    // initialized on Vonage server side with proper credentials above
    this.session = false;

    // Store native user mediastreams (audio/video tracks we could use to publish streams)
    this.mediaStreams = undefined;

    // strings
    this.audioSourceId = false;
    this.videoSourceId = false;

    // keep track of muted statusses
    this.audioMuted = false;
    this.videoMuted = false;

    // our own audio and video publishers (Vonage objects that allows us to control what we stream/share)
    this.audioVideoPublisher = false;
    this.screenPublisher = false;

    // the open streams list. VonageStream Array
    this.streams = [];

    this.onStreamCreated = this.onStreamCreated.bind(this);
    this.onStreamDestroyed = this.onStreamDestroyed.bind(this);
  }

  static TYPE_VIDEO = "camera";
  static TYPE_SCREEN = "screen";

  static EVENTS = {
    credentials: "credentials",
    sessionOpened: "sessionOpened",
    grantedPermission: "grantedPermission",
  };

  addStream(stream) {
    const count = this.streams.push(stream);
    // tell the world we updated number of streams
    emitter.emit(EVENT_VONAGE_STREAM_COUNT_UPDATE, count);
  }

  setStreams(streams) {
    this.streams = streams;
    // tell the world we updated number of streams
    emitter.emit(EVENT_VONAGE_STREAM_COUNT_UPDATE, this.streams.length);
  }

  on(event, callback) {
    if (!Vonage.EVENTS[event]) return;
    emitter.on(event, (data) => callback(data));
  }

  emit(event, data) {
    if (!Vonage.EVENTS[event]) return;
    emitter.emit(event, data);
  }

  // ensure system is webRTC capable
  checkCompatibility() {
    const isCompatible = this.OT.checkSystemRequirements() === 1;

    if (!isCompatible) {
      this.OT.upgradeSystemRequirements();
      return false;
    }

    return true;
  }

  // ensure screen share is available
  checkScreenSharingCompatibility() {
    return new Promise((resolve) => {
      this.OT.checkScreenSharingCapability((response) => {
        if (!response.supported || response.extensionRegistered === false) {
          resolve(response);
        } else {
          resolve(true);
        }
      });
    });
  }

  // Not IE11 working.
  // Ask for granting permissions
  // fetch and store available mediastreams (devices) on machine
  async getUserMedia(options = {}) {
    try {
      const mediaStreams = await this.OT.getUserMedia(options).catch(
        (error) => {
          this.emit(Vonage.EVENTS.grantedPermission, error);
        }
      );

      if (!mediaStreams) return null;

      this.mediaStreams = mediaStreams;
      this.emit(Vonage.EVENTS.grantedPermission, true);
      return mediaStreams;
    } catch (error) {
      this.emit(Vonage.EVENTS.grantedPermission, error);
      return false;
    }
  }

  async getPermissions() {
    const checkPermission = async (device) =>
      navigator?.permissions?.query({ name: device });
    const camera = await checkPermission("camera");
    const microphone = await checkPermission("microphone");
    const isAllowed = (device) => device?.state === "granted";
    const canPrompt = (devices) =>
      !!devices
        .map((device) => device.state === "prompt")
        .find((result) => result === true);
    const canJoin = (devices) =>
      !!devices
        .map((device) => isAllowed(device))
        .find((result) => result === true);
    return {
      camera: isAllowed(camera),
      microphone: isAllowed(microphone),
      canPrompt: canPrompt([microphone, camera]),
      canJoin: canJoin([microphone, camera]),
    };
  }

  getDevices() {
    return new Promise((resolve) => {
      try {
        this.OT.getDevices((error, devices) => {
          if (error) return resolve([]);
          resolve(devices);
        });
      } catch (error) {
        console.error("getDevice error", error);
      }
    });
  }

  // ask api6 for fresh Vonage credentials.
  async getCredentials(wiz = {}) {
    if (!wiz.keyword) return false;

    const url = API_BASE.clone()
      .segment("event")
      .segment(wiz.keyword)
      .segment("visio")
      .segment("credentials")
      .toString();

    try {
      const response = await makeRequest(
        url,
        { type: "VonageCredentials" },
        {
          method: "POST",
        }
      );

      const credentials = response.resources[0].attributes;
      this.credentials = credentials;
      this.emit(Vonage.EVENTS.credentials, credentials);

      return response;
    } catch (error) {
      console.error("error", error);
      return false;
    }
  }

  // connect to Vonage server (kind of router) with credentials
  initSession() {
    return new Promise((resolve, reject) => {
      this.streams = [];

      this.session = this.OT.initSession(
        this.credentials.key,
        this.credentials.session
      );

      this.session.connect(this.credentials.token, (error) => {
        if (error) {
          console.error("[Vonage] session connect error");
          return reject(false);
        }

        console.log("[Vonage] session connected!");
        this.emit(Vonage.EVENTS.sessionOpened, this.session);
        resolve(true);
      });

      // listen to websockets signaling events. Used by moderation.
      // When we "mute" someone, we just send him a websocket event triggering its mute method
      // cuz we do not have a proper moderator way of doing it implemented natively on Vonage side
      this.session.on("signal:msg", (event) =>
        this.onSignal(event, this.session.connection.id)
      );

      // very very very important listener
      // this one allows us to know everytime a remote stream changed (mute/unmute)
      this.session.on("streamPropertyChanged", (event) =>
        this.onStreamPropertyChanged(event)
      );
    });
  }

  // get every other user stream. remove them when destroyed
  subscribeToPublishers() {
    console.log("[Vonage] Subscribe to publishers");
    if (!this.session?.on) {
      console.warn("[Vonage] No session has been found");
      return;
    }
    this.session.on("streamCreated", this.onStreamCreated);
    this.session.on("streamDestroyed", this.onStreamDestroyed);
  }

  unsubscribeToPublishers() {
    console.log("[Vonage] UnSubscribe to publishers");
    if (!this.session?.off) {
      console.warn("[Vonage] No session has been found");
      return;
    }
    this.session.off("streamCreated", this.onStreamCreated);
    this.session.off("streamDestroyed", this.onStreamDestroyed);
  }

  onStreamPropertyChanged({
    changedProperty,
    newValue,
    oldValue,
    stream,
    target,
  }) {
    if (["hasVideo", "hasAudio"].indexOf(changedProperty) === -1) {
      console.log("unsupported property changed", changedProperty);
      return;
    }

    const updatedStream = this.streams.filter(
      (s) => get(s, "stream.streamId") === stream.id
    )[0];

    if (!updatedStream) return;

    emitter.emit(EVENT_VONAGE_STREAM_COUNT_UPDATE, this.streams.length);
    emitter.emit(EVENT_VONAGE_STREAM_UPDATE, updatedStream);
  }

  // when a remote user shares its stream, wrap it with our VonageStream object and add it to streams collection
  // TODO: all this behaviour should be coded in a Saga, which is design to handle such async events like that
  // in order to decouplate better code. All is kinda mixed up here, but you konw the delays.. ¯\_(ツ)_/¯
  onStreamCreated({ stream: createdStream }) {
    const { id, videoType, hasAudio, hasVideo } = createdStream;

    const stream = new VonageStream({
      id,
      type: videoType,
      isVideoMuted: !hasVideo,
      isAudioMuted: !hasAudio,
      isFocused: false,
      own: false,
      stream: createdStream,
    });

    this.addStream(stream);
    // tell the world we have updated the stream collection
    emitter.emit(EVENT_VONAGE_STREAM_UPDATE, stream);
  }

  // this method is called right after the previous one in Stage component
  // Once the receptacle DOM element is rendered, attach vonage subscribe mechanism to it
  // not very elegant, but like the chicken and the egg, you don't relly know which one comes first..
  subscribeToStream(stream) {
    return new Promise((resolve, reject) => {
      this.session.subscribe(
        stream.stream,
        stream.id,
        {
          insertMode: "replace",
          width: "100%",
          height: "100%",
          style: {
            nameDisplayMode: "off",
            buttonDisplayMode: "off",
            audioLevelDisplayMode: "off",
            archiveStatusDisplayMode: "off",
          },
        },
        (error) => {
          if (error) {
            reject(error);
            return;
          }

          stream.subscribed = true;
          resolve(stream);
        }
      );
    });
  }

  // emit event to tell interfaces to update accordingly when a stream is destroyed
  onStreamDestroyed({ stream }) {
    this.setStreams(this.streams.filter(({ id }) => stream.id !== id));
    emitter.emit(EVENT_VONAGE_STREAM_DELETE, stream);
    this.clearFocus(stream.id);
  }

  setPublisher(publisher, type) {
    if (type === Vonage.TYPE_SCREEN) return (this.screenPublisher = publisher);

    return (this.audioVideoPublisher = publisher);
  }

  getPublisher(type) {
    return type === Vonage.TYPE_VIDEO
      ? this.audioVideoPublisher
      : this.screenPublisher;
  }

  // Publish our own audio/video or screen share
  // nothing really fancy here
  createPublisher(node, videoType = Vonage.TYPE_VIDEO, opts = {}) {
    const options = {
      insertMode: "replace",
      fitMode: "contain",
      width: "100%",
      height: "100%",
      style: {
        buttonDisplayMode: "off",
        audioLevelDisplayMode: "off",
        archiveStatusDisplayMode: "off",
        nameDisplayMode: "off",
      },
      ...opts,
    };

    if (videoType === Vonage.TYPE_SCREEN) options.videoSource = "screen";

    if (videoType === Vonage.TYPE_VIDEO) {
      if (this.audioMuted) options.publishAudio = !this.audioMuted;

      if (this.videoMuted) options.publishVideo = !this.videoMuted;

      if (this.audioSourceId) options.audioSource = this.audioSourceId;

      if (this.videoSourceId) options.videoSource = this.videoSourceId;
    }

    return new Promise((resolve, reject) => {
      const publisher = this.OT.initPublisher(node, options, (error) => {
        if (error) return reject(error);
        console.log("[Vonage] publisher created !");

        this.setPublisher(publisher, videoType);
        resolve(publisher);
      });
    });
  }

  // clean our local streams (mostly to switch off webcam light)
  unpublishStreams() {
    if (this.audioVideoPublisher && this.session) {
      try {
        this.session.unpublish(this.audioVideoPublisher);
      } catch (error) {
        console.error("failed properly unpublishing audio/video");
      }
    }

    if (this.screenPublisher && this.session) {
      try {
        this.session.unpublish(this.screenPublisher);
      } catch (error) {
        console.error("failed properly unpublishing screenshare");
      }
    }

    // ensure to clear all personal streams in the array
    this.setStreams(
      this.streams.filter(({ id }) => id !== "own" && id !== "own_screenshare")
    );

    emitter.emit(EVENT_VONAGE_STREAM_DELETE);
  }

  // stop publishing to vonage. Kind off Armageddon. Destroy all. Do not try this at home
  destroyPublishers() {
    if (this.audioVideoPublisher) {
      try {
        this.audioVideoPublisher.destroy();
        this.audioVideoPublisher = false;
      } catch (error) {
        console.error(error);
      }
    }

    if (this.screenPublisher) this.screenPublisher.destroy();
    this.screenPublisher = false;
  }

  stopScreenShare() {
    if (!this.screenPublisher) return;
    this.screenPublisher.destroy();
    this.clearFocus("own_screenshare");
    if (this.session) {
      try {
        this.session.unpublish(this.screenPublisher);
        this.setStreams(
          this.streams.filter(({ id }) => id !== "own_screenshare")
        );
      } catch (error) {
        console.error("failed properly unpublishing screenshare");
      }
    }

    emitter.emit(EVENT_VONAGE_STREAM_DELETE);
  }

  publish(type, publisher) {
    const p = publisher || this.getPublisher(type);
    console.log("[Vonage] publishing", type, publisher);

    return new Promise((resolve, reject) => {
      if (!publisher) {
        console.error("[Vonage] Publisher not instanciated!");
        reject("no_publisher");
        return;
      }

      this.session.publish(p, (error) => {
        if (error) {
          console.error("error while trying to publish", error);
          reject(error);
          return;
        }

        resolve(p.stream);
      });
    });
  }

  getStreamById(streamId) {
    return this.streams.find(
      (s) => get(s, "stream.id") === streamId || s.id === streamId
    );
  }

  // TODO: we should refacto muteAudio and muteVideo in a generic mute method
  // mute(type, id, mute)
  muteAudio(id, mute) {
    const stream = this.getStreamById(id);

    if (id === "own" || get(stream, "id") === "own") {
      this.audioVideoPublisher.publishAudio(!mute);
      this.audioMuted = mute;
      return;
    }

    this.session.signal({
      type: "msg",
      data: JSON.stringify({
        streamId: get(stream, "stream.streamId"),
        action: "toggleAudio",
        value: mute,
      }),
    });
  }

  setWizFocus(id) {
    const { dispatch, wiz } = this;
    /* We want to do this only for webinar mode */
    if (
      !wiz?.live_media?.provider ||
      wiz.live_media.provider !== "wisembly_webinar"
    )
      return;
    dispatch(
      resourceEdit(
        Object.assign(wiz, {
          live_media: {
            ...wiz.live_media,
            focus: id ? this.getStreamById(id)?.stream?.id : null,
          },
        }),
        {
          slug: ["event", wiz.keyword],
          silent: true,
          patch: ["live_media"],
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
            }
          },
        }
      )
    );
  }

  setFocus(streamId) {
    /* We do this on LE for "own" property */
    const id = this.getStreamById(streamId)?.id;
    emitter.emit(EVENT_VONAGE_STREAM_FOCUS, { id });
    this.setWizFocus(id);
  }

  clearFocus(streamId) {
    const id = this.getStreamById(streamId)?.id;
    emitter.emit(EVENT_VONAGE_STREAM_CLEAR_FOCUS, { id });
  }

  kickSpeaker(id) {
    const stream = this.streams.filter(
      (s) => get(s, "stream.id") === id || s.id === id
    )[0];

    if (id === "own" || get(stream, "id") === "own") {
      /* This should never happend, as the kick button is not implemented on our own stream ...
       * But just in case one day the condition is removed, it's always safer to do that check
       * */
      return;
    }

    this.session.signal({
      type: "msg",
      data: JSON.stringify({
        streamId: get(stream, "stream.streamId"),
        action: "leaveStage",
      }),
    });
  }

  muteVideo(id, mute) {
    const stream = this.streams.filter(
      (s) => get(s, "stream.id") === id || s.id === id
    )[0];

    if (id === "own" || get(stream, "id") === "own") {
      this.audioVideoPublisher.publishVideo(!mute);
      this.videoMuted = mute;
      return;
    }

    this.session.signal({
      type: "msg",
      data: JSON.stringify({
        streamId: get(stream, "stream.streamId"),
        action: "toggleVideo",
        value: mute,
      }),
    });
  }

  // not implemented yet
  // will be used to be muted/unmuted by moderator
  onSignal({ data, from }, connectionId) {
    // ignore self signals
    if (from.connectionId === connectionId) return;

    try {
      data = JSON.parse(data);
    } catch (e) {
      data = {};
    }

    const { action, streamId, value } = data;

    // ignore signals for others
    if (
      get(this.audioVideoPublisher, "stream.id") !== streamId &&
      get(this.screenPublisher, "stream.id") !== streamId
    )
      return;
    switch (action) {
      case "leaveStage":
        this.unpublishStreams();
        this.destroyPublishers();
        alert(_t("You've been removed from screen by a moderator."));
        break;
      case "toggleAudio":
        this.muteAudio(streamId, value);
        break;
      case "toggleVideo":
        this.muteVideo(streamId, value);
        break;
      case "focus":
        console.log("SetFocus", streamId);
        break;
    }
  }

  // TODO: implement mediastream add/remove detection to switch mic/webcam when its changes
}

export default Vonage;
