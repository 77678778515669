import BaseModel from "./base";
import { attr, fk, oneToOne } from "redux-orm";

class Calendar extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Calendar;
  }
}

Calendar.modelName = "Calendar";
Calendar.backendName = "calendar";

Calendar.fields = {
  id: attr(),
  active: attr(),
  configuration: attr(),
  overrides: attr(),
  slots_duration_minutes: attr(),
  simultaneous_slots: attr(),
  timezone: attr(),
  end_at_absolute: attr(),
  end_at_relative: attr(),
};

export default Calendar;
