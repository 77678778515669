import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import toInteger from "lodash/toInteger";
import toNumber from "lodash/toNumber";
import isNull from "lodash/isNull";
import { _t, _n } from "i18n";

dayjs.extend(relativeTime);

export const elapsedSeconds = (date) => dayjs().unix() - dayjs(date).unix();

export const getRelativeDate = (date) => {
  if (!date || !dayjs(date).isValid()) {
    return dayjs().from(dayjs().subtract(-1, "day"));
  }
  return dayjs(date).fromNow();
};

export const getUnix = (date) => {
  if (!date || !dayjs(date).isValid()) {
    return dayjs().unix();
  }
  return dayjs(date).unix();
};

export const getTimeRemaining = (date) => {
  const endtime = dayjs(date);
  //const total = Date.parse(endtime) - Date.parse(new Date());
  const total = endtime.valueOf() - dayjs().valueOf();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

export const getTimeRemainingTillTimeout = ({ date, timeout }) => {
  const starttime = dayjs(date);
  const endtime = starttime.add(toInteger(timeout), "millisecond");
  const isExpired = dayjs().isAfter(endtime);
  if (isExpired)
    return {
      isExpired: true,
      percent: 100,
      seconds: 0,
      minutes: 0,
      hours: 0,
      days: 0,
    };
  //const total = Date.parse(endtime) - Date.parse(new Date());
  const total = endtime.valueOf() - dayjs().valueOf();
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    isExpired: false,
    total,
    days,
    hours,
    minutes,
    seconds,
    percent:
      toInteger(timeout) - total >= 0
        ? ((toInteger(timeout) - total) / toInteger(timeout)) * 100
        : 0,
  };
};

export const secondsToDhms = (seconds) => {
  if (isNull(seconds)) return null;
  seconds = toNumber(Math.abs(seconds));
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  const dDisplay =
    d > 0 ? _n("{count} day", "{count} days", d, { count: d }) : "";
  const hDisplay =
    h > 0 ? _n("{count} hour", "{count} hours", h, { count: h }) : "";
  const mDisplay =
    m > 0 ? _n("{count} minute", "{count} minutes", m, { count: m }) : "";
  const sDisplay =
    s > 0 ? _n("{count} second", "{count} seconds", s, { count: s }) : "";
  return `${dDisplay} ${hDisplay} ${mDisplay} ${sDisplay}`;
};

export const secondsToDhmsRounded = (seconds) => {
  if (isNull(seconds)) return null;

  seconds = toNumber(Math.abs(seconds));
  const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  if (d >= 1) {
    return _n("{count} day", "{count} days", d, { count: d });
  }

  if (h >= 1) {
    return _n("{count} hour", "{count} hours", h, { count: h });
  }

  if (m >= 1) {
    return _n("{count} minute", "{count} minutes", m, { count: m });
  }

  return _n("{count} second", "{count} seconds", s, { count: s });
};

export default dayjs;
