import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import map from "lodash/map";
import get from "lodash/get";
import PercentIndicator from "./PercentIndicator";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";
import { getParsedPercent } from "utilities/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  average: {
    fontSize: "40px",
  },
  textResult: {
    marginTop: "50px",
    fontSize: "25px",
    marginBottom: "15px",
    height: "49px",
    width: "100%",
  },
  ratingRoot: {
    "& .MuiRating-iconEmpty": {
      color: theme.palette.background.secondaryBox,
    },
  },
  ratingLabel: {
    fontSize: "40px",
  },
  content: {
    display: "inline-flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "50px",
  },
  iconBox: {
    color: theme.palette.text.primary,
  },
  label: {
    padding: theme.spacing(1),
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.5px",
  },
  percentText: {
    width: "45px",
    fontSize: "16px",
  },
  detailsPercent: {
    width: "calc(100% - 40px)",
    paddingLeft: theme.spacing(2.25),
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
  },
  iconFilled: {
    color: theme.palette.secondary.main,
  },
  iconHover: {
    color: theme.palette.secondary.main,
  },
}));

export const RatingSurveyItemTextResult = ({ rating, summary }) => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      className={classes.textResult}
    >
      {" "}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        fontWeight="fontWeightBold"
        className={classes.average}
      >
        {summary.average}
      </Box>{" "}
      {`/ ${rating.max || 5}`}
    </Box>
  );
};

RatingSurveyItemTextResult.propTypes = {
  rating: PropTypes.object,
  summary: PropTypes.object,
};
RatingSurveyItemTextResult.defaultProps = {
  rating: { max: 5 },
  summary: { average: 0 },
};
export const RatingSurveyItemResultNoLabel = ({ rating, summary }) => {
  const classes = useStyles();
  const result = (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <RatingSurveyItemTextResult summary={summary} rating={rating} />
      <Box>
        <Rating
          classes={{
            root: classes.ratingRoot,
            iconEmtpy: classes.ratingIconEmpty,
            label: classes.ratingLabel,
            iconFilled: classes.iconFilled,
            iconHover: classes.iconHover,
          }}
          name="read-only"
          value={summary.average}
          readOnly
          max={rating.max || 5}
        />
      </Box>
    </Box>
  );
  return result;
};
export const RatingSurveyItemResultDetails = ({ summary }) => {
  const classes = useStyles();
  let max = 1;
  const list = map(get(summary, "values", {}), (key, word) => {
    if (key > max) max = key;
    return {
      value: word,
      count: key,
    };
  });
  const result = list.map((elem) => {
    const percent = (elem.count / max) * 100;
    return (
      <Box
        key={elem.value}
        display="flex"
        justifyContent="flex-start"
        flexDirection="row"
        alignItems="center"
      >
        <Box className={classes.content}>
          <Typography className={classes.label}>{elem.value}</Typography>
          <Box className={classes.iconBox}>
            <StarOutlinedIcon />
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="row"
          alignItems="center"
          className={classes.detailsPercent}
        >
          <PercentIndicator percent={percent} />
          <Box ml={1} className={classes.percentText}>{`${getParsedPercent(
            percent
          )}%`}</Box>
        </Box>
      </Box>
    );
  });

  return result;
};

RatingSurveyItemResultNoLabel.propTypes = {
  rating: PropTypes.object,
  summary: PropTypes.object,
};
RatingSurveyItemResultNoLabel.defaultProps = {
  rating: { max: 5 },
  summary: { average: 0 },
};
const RatingSurveyItemResult = ({ item, showDetails }) => {
  const classes = useStyles(showDetails);
  const rating = get(item, "options.rating", {});
  const summary = item.summary;

  return (
    <Box className={clsx("RatingSurveyItemResult", classes.root)}>
      <Box>
        <RatingSurveyItemResultNoLabel summary={summary} rating={rating} />
      </Box>
      {showDetails && <RatingSurveyItemResultDetails summary={summary} />}
    </Box>
  );
};

RatingSurveyItemResult.propTypes = {
  item: PropTypes.object,
  showDetails: PropTypes.bool,
};

RatingSurveyItemResult.defaultProps = {
  showDetails: false,
};

export default RatingSurveyItemResult;
