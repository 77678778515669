import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import TextArea from "app/liveShopping/backoffice/otm/components/reusables/TextArea";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { vendorComment } from "./service";
import { useDispatch, useSelector } from "react-redux";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import ResourceFactory from "orm/resources";
import { openNps, setCurrentVisitor } from "app/state/ducks/liveShopping/actions";
import { destroyPublisher } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/actions";
import CheckLg from "react-bootstrap-icons/dist/icons/check-lg";


const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    backdropFilter: "blur(4px)",
    position: "absolute",
    top: 0,
    zIndex: 6,
  },
  root: {
    background: theme.palette.colors.white,
    width: "50%",
    padding: 30,
    borderRadius: "5px",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "5px",
    marginTop: "16px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
  },
  subtitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: theme.palette.colors.grey500,
    marginBottom: "20px",
  },
  ignore: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.grey500,
    cursor: "pointer",
  },
  button: {
    height: "40px",
    background: props => props.isSending ? theme.palette.colors.primary : theme.palette.colors.success,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },
  typoButton: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.white,
  },
}));

const Comment = ({ }) => {
  const dispatch = useDispatch();
  const currentVisitor = useSelector(currentVisitorSelector);
  const [comment, setComment] = useState("");
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const [isSending, setIsSending] = useState(true);
  const classes = useStyles({ isSending });
  useEffect(() => {
    dispatch(destroyPublisher("video"));
  }, []);

  const handleChange = (e) => {
    setComment(e.target.value);
  };

  const handleSend = () => {
    const newComment = resourceFactory.create("Visitor", { options: { ...currentVisitor.options, vendor_comment: comment } });
    vendorComment(dispatch, currentVisitor.hash, newComment, (error, response) => {
      if (error) console.log(error);

      setIsSending(false);
      setTimeout(() => {
        dispatch(setCurrentVisitor(null));
        dispatch(openNps(false));
      }, 1500);
    });

  };

  const handleIgnore = () => {
    dispatch(setCurrentVisitor(null));
    dispatch(openNps(false));
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.root}>
        <Typography className={classes.title}>{_t("Leave a comment")}</Typography>
        <Typography className={classes.subtitle}>{_t("Not visible to the customer")}</Typography>
        <TextArea name="comment" onChange={(e) => handleChange(e)} value={comment} placeholder={_t("Enter here")} />
        <Box className={classes.buttons}>
          <Typography onClick={handleIgnore} className={classes.ignore}>{_t("Ignore")}</Typography>
          <Button clsName={classes.button} onClick={handleSend}>
            {isSending ? <Typography className={classes.typoButton}>{_t("Send")}</Typography> : (
              <>
                <CheckLg size={16} />
                <Typography className={classes.typoButton}>{_t("Sent")}</Typography>
              </>
            )}

          </Button>
        </Box>
      </Box>
    </Box>

  );
};

Comment.propTypes = {};

Comment.defaultProps = {};

export default Comment;
