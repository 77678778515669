import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { _t } from "utilities/i18n";
import TableComponent from "./Table"
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: "25px",
    lineHeight: "130%",
    fontWeight: 700,
    padding: "15px 20px",
  },
}));

const Appointments = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Upcoming appointments")}</Typography>
      <TableComponent />
    </Box>
  )
};

export default Appointments;