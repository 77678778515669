import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useSelector } from "react-redux";
import { getOwnAccessesSelector } from "app/state/ducks/ressources/selectors";
import Queue from "../../Component/Queues/Queue";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { _n } from "utilities/i18n";
import ErrorComponent from "../../Component/ErrorComponent";
import Fade from 'react-reveal/Fade';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    zIndex: 0,
    flexGrow: 1,
    flexDirection:
      "column",
    width: "100%",
    height: "100%",
    position:
      "relative",
    background: "#FFF",
    zIndex: 8,
    padding: "16px 0",

  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "25px",
    lineHeight: "130%",
    margin: "16px 32px 0px 32px",
  },
  queues: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    gap: "16px",
    overflowY: "auto",
    padding: "30px 30px",
  },
  next: {
    display: "flex",
    height: "60px",
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "16px",
    marginTop: "32px",
    paddingRight: "32px",
  },
  retracted: {
    marginLeft: "8px",
    marginTop: "10px",
  },
  queuesSelected: {
    color: props => props.queuesSelected ? theme.palette.colors.primary : theme.palette.colors.grey400,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
  },
  typoJoin: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: "#FFFFFF",
  }
}));

const QueuesContainer = ({ setLive }) => {
  const accesses = useSelector(getOwnAccessesSelector);

  const queuesSelected = accesses?.filter((access) => access.available === true).length;

  const classes = useStyles({ queuesSelected });


  if (accesses.length === 0)
    return <ErrorComponent text={_t("No queue assigned")} />

  return (
    <>
      <Box className={classes.root}>
        <Typography className={classes.title}>{_t("Choose your queues")}</Typography>

        <Box className={classes.queues}>
          {accesses.map((access, index) =>
            <Fade key={index} left><Queue key={access.id} access={access} /></Fade>
          )}
        </Box>

        <Box className={classes.next}>

          <Typography className={classes.queuesSelected}>{_n("{count} queue selected", "{count} queues selected", queuesSelected, { count: queuesSelected })}</Typography>
          <Button disabled={queuesSelected <= 0} onClick={() => setLive(true)}>
            <Typography className={classes.typoJoin}>{_t("Join now")}</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

QueuesContainer.propTypes = {};

QueuesContainer.defaultProps = {};

export default QueuesContainer;
