import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Plus from "react-bootstrap-icons/dist/icons/plus";
import Wrapper from "./Cards/Wrapper";
import { useSelector, useDispatch } from "react-redux";
import { desktopLayoutSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { displayTabBar } from "app/state/ducks/liveShopping/actions";
import { Divider } from "@material-ui/core";
import { getUrl } from "app/views/containers/Application/Routes";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    height: "100%",
    overflowY: "auto",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: (props) => (props.isMobile ? "30px" : "20px"),
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
    flex: "none",
    order: 0,
    flexGrow: 1,
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "30px",
  },
  typoNewLive: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    textAlign: "center",
    color: "white",
  },
  divider: {
    border: "1px solid #F0F0F4",
    margin: " 10px 10px",
  },
}));

const Lives = ({ coming, live, draft, replay }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile, isDesktop });
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile) {
      dispatch(displayTabBar(true));
    }
  }, []);

  const statesTable = [
    {
      stateTitle: _t("Drafts"),
      lives: draft,
    },
    {
      stateTitle: _t("Live"),
      lives: live,
    },
    {
      stateTitle: _t("Coming"),
      lives: coming,
    },
    {
      stateTitle: _t("Replay"),
      lives: replay,
    },
  ];

  return (
    <Box className={classes.root}>
      <Box className={classes.top}>
        <Typography variant={"bodySB13"} className={classes.title}>
          {_t("My Lives")}
        </Typography>
        {isDesktop && (
          <Button style={{ textAlign: "center" }} url={getUrl("otm_live_create")} link={true}>
            <Plus size={18} />
            <Typography className={classes.typoNewLive}>{_t("New Live")}</Typography>
          </Button>
        )}
      </Box>

      {statesTable.map((state, index) => {
        return (
          <>
            <Wrapper {...state} />
            {index !== statesTable.length - 1 ? <Divider className={classes.divider} /> : null}
          </>
        );
      })}
    </Box>
  );
};

Lives.propTypes = {};

Lives.defaultProps = {};

export default Lives;
