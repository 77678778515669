import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "16px",
      height: "16px",
      border: (props) => (props.checked ? `5px solid ${theme.palette.colors.primary}` : `1px solid ${theme.palette.colors.greyMedium}`),
      borderRadius: "99px",
      boxSizing: "border-box",
      backgroundColor: theme.palette.colors.white,
      opacity: (props) => (props.disabled ? 0.5 : 1),
      cursor: (props) => (props.disabled || props.checked ? "default" : "pointer"),
      pointerEvents: (props) => (props.disabled ? "none" : "auto"),
      transition: ".25s",
      "&:active": {
        transform: (props) => (props.checked ? "scale(1)" : "scale(0.875)"),
      },
    },
  }),
  {
    name: "OneRadio",
  }
);
//controlled checkBox
const Radio = ({ checked, onChange, disabled, value, name }) => {
  const classes = useStyles({ checked, disabled });
  return <Box id={name} onClick={() => !disabled && onChange(value)} className={classes.root}></Box>;
};
Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

Radio.defaultProps = {
  onChange: () => {},
};

export default Radio;
