import React from "react";
import PropTypes from "prop-types";
import InfoLg from "react-bootstrap-icons/dist/icons/info-lg";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { desktopLayoutSelector, isPreliveSelector } from "liveShoppingDucks/selectors";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    display: (props) => (!props.isInfoOpen ? "flex" : "none"),
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    background: "rgba(0, 0, 0, 0.15)",
    borderRadius: "99px",
  },
  circleTypo: {
    color: theme.palette.colors.white,
  },
  eventName: {
    color: theme.palette.colors.white,
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14px",
    marginLeft: "13px",
  },

  thumbnail: {
    borderRadius: "99px",
    width: "32px",
    height: "32px",
    border: "1px solid #F0F0F4",
    backgroundImage: (props) => `url(${props.BackgroundThumbnail})`,
    backgroundSize: "cover",
    cursor: "pointer",
  },
}));
const Info = ({ setIsInfoOpen, isInfoOpen }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const wiz = useSelector(currentWizSelector);
  const classes = useStyles({ BackgroundThumbnail: wiz.logo_paths.mobile, isInfoOpen });
  const isPrelive = useSelector(isPreliveSelector);
  if (isDesktop && !isPrelive) return null;

  const handleInfo = () => {
    setIsInfoOpen(!isInfoOpen);
  };

  return (
    <Box className={classes.root}>
      <ButtonBase className={classes.btn} onClick={handleInfo}>
        <InfoLg size={16} className={classes.circleTypo} />
      </ButtonBase>
      {isInfoOpen && (
        <Box style={{ display: "flex", alignItems: "center", width: "200px" }} onClick={handleInfo}>
          <Box className={classes.thumbnail}></Box>
          <Typography className={classes.eventName}>{wiz.title || ""}</Typography>
        </Box>
      )}
    </Box>
  );
};

Info.propTypes = {
  isInfoOpen: PropTypes.bool,
  BackgroundThumbnail: PropTypes.string,
};

export default Info;
