import React from "react";
import PropTypes from "prop-types";
import { _t } from "core/i18n";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import { ALIGN_CENTER_COLUMN } from "./constants";

import Checkbox from "@material-ui/core/Checkbox";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",

    //overflowX: "auto",
  },
  TableRow: {
    backgroundColor: theme.palette.colors.greyLight,
  },
  tabCell: {
    padding: "10px",
  },
}));
const Header = ({ columns, onSelectAllClick, order, orderBy, onRequestSort, allSelectedOnPage }) => {
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const activatedColumns = columns.filter((col) => !col.deactivated);
  return (
    <TableRow className={classes.TableRow}>
      <TableCell key="checkbox" sortDirection={false} align="left" padding="normal" classes={{ root: classes.tabCell }}>
        <TableSortLabel active={false} direction={"asc"} hideSortIcon>
          <Checkbox key={"checkbox"} indeterminate={false} checked={allSelectedOnPage} onChange={onSelectAllClick} inputProps={{ "aria-label": "select all" }} />
        </TableSortLabel>
      </TableCell>

      {activatedColumns.map((col) => {
        return (
          <TableCell key={col.id} sortDirection={orderBy === col.id ? order : false} align={ALIGN_CENTER_COLUMN.includes(col.id) ? "center" : "left"} padding="normal">
            {col.sortable ? (
              <TableSortLabel active={orderBy === col.id} direction={orderBy === col.id ? order : "asc"} onClick={createSortHandler(col.id)}>
                {_t(col.title)}
              </TableSortLabel>
            ) : (
              _t(col.title)
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
Header.propTypes = {
  columns: PropTypes.array,
  rowCount: PropTypes.number,
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
  allSelectedOnPage: PropTypes.bool,
};
Header.defaultProps = {
  columns: [],
  rowCount: 0,
  numSelected: 0,
  onSelectAllClick: () => {},
  order: "asc",
  orderBy: "",
  onRequestSort: () => {},
  allSelectedOnPage: false,
};
// Header.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: "Header",
// };
export default Header;
