import React, { useEffect, useReducer } from "react";
//import { mountEvents, unmountEvents } from "core/emitter";
import emitter from "core/emitter";
import get from "lodash/get";
import {
  SURVEY_EVENT_FLASH_SUCCESS,
  SURVEY_EVENT_FLASH_ERROR,
  SURVEY_EVENT_FLASH_SUCCESS_VANISH,
  SURVEY_EVENT_FLASH_ERROR_VANISH,
} from "core/emitter/events";
import { ALERT_VANISH_TIME_MS } from "app/views/constants";

import Snackbar from "@material-ui/core/Snackbar";
import UiAlert from "@material-ui/lab/Alert";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    overscrollBehavior: "contain",
    height: "100%",
  },
  snackbar: {
    position: "absolute",
    [theme.breakpoints.up("sm")]: {},
    [theme.breakpoints.down("xs")]: {
      //bottom: 500,
    },
    boxShadow: theme.palette.shadow,
    borderRadius: "5px",
  },

  anchorOriginBottomCenter: {
    [theme.breakpoints.up("sm")]: {
      bottom: "105px",
    },
    [theme.breakpoints.down("xs")]: {
      bottom: `calc(${theme.spacing(9.75)}px + 20px )`,
    },
  },
  alertMessage: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontWeight: 600,
    letterSpacing: "0.75px",
    fontSize: "16px",
    color: (props) => {
      switch (props.type) {
        case "success":
          return theme.palette.button.default.color.enable;
        case "error":
          return theme.wisemblyColors.white;
        default:
          return theme.wisemblyColors.white;
      }
    },
  },

  alertIcon: {
    fontSize: 30,
  },
  alertAction: {},

  standardSuccess: {
    backgroundColor: theme.palette.background.box,
    "& .MuiAlert-icon": {
      color: theme.palette.secondary.main,
    },
    "& .MuiAlert-action": {
      color: theme.palette.secondary.main,
    },
  },
  standardError: {
    backgroundColor: theme.wisemblyColors.pastelRed,
    "& .MuiAlert-icon": {
      color: theme.wisemblyColors.white,
    },
    "& .MuiAlert-action": {
      color: theme.wisemblyColors.white,
    },
  },
}));

const surveyAlertReducer = (state, action) => {
  switch (action.type) {
    case "ON_SUCCESS": {
      return {
        ...state,
        type: "success",
        hidden: false,
        content: action.content,
      };
    }
    case "ON_ERROR": {
      return {
        ...state,
        type: "error",
        hidden: false,
        content: action.content,
      };
    }
    case "HIDE": {
      return {
        ...state,
        hidden: true,
        type: null,
        content: null,
      };
    }
  }
};
const SurveyAlert = ({ layoutMode, surveyHash, section }) => {
  const [state, dispatch] = useReducer(surveyAlertReducer, {
    type: null,
    hidden: true,
    content: null,
  });
  const classes = useStyles({ layoutMode, type: state.type });

  const unmountEvents = () => {
    emitter.removeListener(SURVEY_EVENT_FLASH_ERROR, onError);
    emitter.removeListener(SURVEY_EVENT_FLASH_SUCCESS, onSuccess);
    emitter.removeListener(SURVEY_EVENT_FLASH_ERROR_VANISH, onErrorVanish);
    emitter.removeListener(SURVEY_EVENT_FLASH_SUCCESS_VANISH, onSuccessVanish);
  };
  useEffect(() => {
    const mountEvents = () => {
      emitter.on(SURVEY_EVENT_FLASH_ERROR, onError);
      emitter.on(SURVEY_EVENT_FLASH_SUCCESS, onSuccess);
      emitter.on(SURVEY_EVENT_FLASH_ERROR_VANISH, onErrorVanish);
      emitter.on(SURVEY_EVENT_FLASH_SUCCESS_VANISH, onSuccessVanish);
    };
    mountEvents();
    return () => unmountEvents();
  }, [unmountEvents]);

  const onError = (content) => {
    if (
      get(content, "surveyHash", "empty") === surveyHash &&
      get(content, "section", "present") === section
    ) {
      dispatch({ type: "ON_ERROR", content: get(content, "message", "") });
    }
  };

  const onSuccess = (content) => {
    //section is present if not provided
    if (
      get(content, "surveyHash", "empty") === surveyHash &&
      get(content, "section", "present") === section
    ) {
      dispatch({ type: "ON_SUCCESS", content: get(content, "message", "") });
    }
  };

  const onSuccessVanish = (content, vanishTimeMs = ALERT_VANISH_TIME_MS) => {
    onSuccess(content);

    setTimeout(() => {
      onClose();
    }, vanishTimeMs);
  };

  const onErrorVanish = (content, vanishTimeMs = ALERT_VANISH_TIME_MS) => {
    onError(content);

    setTimeout(() => {
      onClose();
    }, vanishTimeMs);
  };

  const onClose = () => {
    dispatch({ type: "HIDE" });
    //this.setState({ hidden: true });
  };

  const computeClass = (type) => {
    switch (type) {
      default:
        return "success";
      case "success":
      case "error":
      case "warning":
        return type;
    }
  };

  //dont forget to reset timeout
  return (
    <Snackbar
      open={!state.hidden}
      autoHideDuration={6000}
      onClose={onClose}
      classes={{
        root: classes.snackbar,
        anchorOriginBottomCenter: classes.anchorOriginBottomCenter,
      }}
    >
      <UiAlert
        iconMapping={{
          success: <CheckOutlinedIcon classes={{ root: classes.alertIcon }} />,
          error: (
            <ErrorOutlineOutlinedIcon classes={{ root: classes.alertIcon }} />
          ),
        }}
        classes={{
          message: classes.alertMessage,
          standardSuccess: classes.standardSuccess,
          standardError: classes.standardError,
        }}
        severity={computeClass(state.type)}
      >
        {state.content}
      </UiAlert>
    </Snackbar>
  );
};

SurveyAlert.propTypes = {
  layoutMode: PropTypes.string,
  surveyHash: PropTypes.string,
  section: PropTypes.string,
};
SurveyAlert.defaultProps = {
  layoutMode: "full",
  section: "present",
};

export default SurveyAlert;
