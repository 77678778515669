import indexOf from "lodash/indexOf";
import forEach from "lodash/forEach";
import flattenDepth from "lodash/flattenDepth";
import {
  currentWizSelector,
  currentUserAccessesSelector,
  userSelector,
} from "ressourcesDucks/selectors";
import { sessionSelector } from "applicationDucks/selectors";
import { useSelector } from "react-redux";

export const useUser = () => {
  const session = useSelector(sessionSelector);
  const user = useSelector((state) => userSelector(state, session?.user_id));

  if (!user || user.isAnon()) return null;

  return user;
};

export const useIsUser = (scopes, specificUser = null) => {
  const currentUser = useUser();
  const user = specificUser || currentUser;

  const event = useSelector(currentWizSelector);
  const userAccesses = useSelector(currentUserAccessesSelector);
  const currentUserScope = getUserScope(user, event, userAccesses);

  return hasExpectedScopes(
    event && event.isOwner(user),
    currentUserScope,
    scopes
  );
};

export const hasExpectedScopes = (
  isOwner = false,
  currentUserScope,
  scopes = "user"
) => {
  const allScopes = [
    "anonymous",
    "user",
    "speaker",
    "anim",
    "modo",
    "admin",
    "owner",
    "root",
  ];
  let ret = false;

  forEach(flattenDepth([scopes || ""], true), (scope) => {
    let operator = "";
    scope = scope.replace(/([\+\-]?)$/, function ($0, $1) {
      operator = $0;
      return "";
    });

    let index = indexOf(allScopes, scope);
    if (index === -1) {
      return;
    }

    //const currentUserScope = getUserScope(user, event);

    if (operator === "+") {
      ret |= allScopes
        .slice(index, allScopes.length)
        .includes(currentUserScope);
    } else if (operator === "-") {
      ret |= allScopes.slice(0, index + 1).includes(currentUserScope);
    } else {
      ret |= scope === "owner" ? isOwner : scope === currentUserScope;
    }
  });

  return !!ret;
};

export const getUserScope = (user, event, userAccesses) => {
  if (user === null || user.isAnon()) return "anonymous";

  let userScopes = [
    "root",
    "owner",
    "admin",
    "modo",
    "anim",
    "speaker",
    "user",
  ];
  let userScope = null;
  let scope = null;

  for (let i = 0; !userScope && i < userScopes.length; i++) {
    scope = userScopes[i];
    switch (scope) {
      case "root":
        userScope = user.isRoot() ? scope : userScope;
        break;
      case "owner":
        userScope = event && event.isOwner(user) ? scope : userScope;
        break;
      case "admin":
      case "modo":
      case "anim":
      case "speaker": {
        let accessScope = null;
        forEach(userAccesses || [], function (access) {
          accessScope =
            !accessScope ||
            userScopes.indexOf(access.scope) < userScopes.indexOf(accessScope)
              ? access.scope
              : accessScope;
        });
        userScope = scope === accessScope ? scope : userScope;
        break;
      }
      case "user":
        userScope = scope;
        break;
    }
  }

  return scope;
};