import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { SurveyItemTitle, SurveyIntro } from "appComponents/Viewers";
import {
  getFormElement,
  useSurveyContextState,
  useFormIntroState,
  SurveySubmitSection,
} from "appComponents/SurveyForms";
import { _t } from "i18n";

const useStyles = makeStyles((theme) => ({
  formFieldRowContent: {
    display: "flex",
    width: "100%",
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "auto",
      paddingLeft: "25px",
      paddingRight: "5%",
      // borderBottomLeftRadius: "0",
      // borderBottomRightRadius: "0",
    },
  },
  formFieldRow: {
    //height: "100%",
    //actionPanel height theme.spacing(7.25)
    position: "relative",
    //maxHeight: `calc(100% - ${theme.spacing(17.5)}px ) `,

    width: "100%",

    [theme.breakpoints.down("xs")]: {
      flex: "auto",
      // paddingLeft: "25px",
      // paddingRight: "5%",
    },
    display: (props) =>
      props.isThumbnailView || props.showIntro ? "none" : "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paginationScreen: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",

    // borderBottomLeftRadius: (props) => (props.isThumbnailView ? 0 : "15px"),
    // borderBottomRightRadius: (props) => (props.isThumbnailView ? 0 : "15px"),
    // borderTopLeftRadius: (props) =>
    //   props.isThumbnailView || props.layoutMode === "split" ? 0 : "15px",
    // borderTopRightRadius: (props) =>
    //   props.isThumbnailView || props.layoutMode === "split" ? 0 : "15px",
    height: (props) => (props.isThumbnailView ? "100%" : "auto"),
    maxHeight: "100%",
    overflow: "auto",
    //boxShadow: theme.palette.shadow,
    overflowX: "hidden",
  },
  hidden: {
    display: "none",
  },
  note: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "50px",
    marginBottom: "50px",
  },
  title: {
    fontSize: "30px",
  },
  surveyIntro: {
    maxHeight: `calc(100% - ${theme.spacing(17.5)}px - ${theme.spacing(
      4.75
    )}px ) `,
    //maxHeight: `calc(100% - ${theme.spacing(20.5)}px)`,
    height: "auto",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    display: (props) =>
      props.isThumbnailView || !props.showIntro ? "none" : "flex",
  },
}));
const CanNotSeeAnswerNote = () => {
  const classes = useStyles();
  return (
    <Box className={classes.note}>
      <Typography>
        {_t("Your answer will be available when the admin shows it.")}
      </Typography>
    </Box>
  );
};
//the implementation of canUserSeeAnswer here does not handle case of multiple answer
//in case of evaluation it's because of specifications :)
const PaginatedFormViewer = ({
  formSchema,
  survey,
  isThumbnailView,
  layoutMode,
  alreadyAnswer,
  canUserSeeAnswer,
}) => {
  //index, indexMap, length
  const surveyContext = useSurveyContextState();
  const showIntro = useFormIntroState(survey.hash);
  const classes = useStyles({
    showIntro,
    isThumbnailView,
    layoutMode,
  });
  const indexMap = surveyContext.indexMap;
  const currentFieldHash =
    indexMap &&
    indexMap[surveyContext.index >= 0 ? surveyContext.index : 0] &&
    indexMap[surveyContext.index >= 0 ? surveyContext.index : 0].hash;
  //const formSchemaKeys = Object.keys(formSchema)
  let result =
    !isEmpty(formSchema) &&
    Object.keys(formSchema).map((key) => (
      <Box
        key={key}
        className={clsx(
          classes.paginationScreen,
          !isEqual(key, currentFieldHash) ? classes.hidden : null
        )}
      >
        {showIntro && (
          <>
            <SurveyIntro
              survey={survey}
              isThumbnailView={isThumbnailView}
              layoutMode={layoutMode}
              forResult={false}
            />
            <SurveySubmitSection
              isThumbnailView={isThumbnailView}
              surveyHash={survey.hash}
            />
          </>
        )}
        {!showIntro && (
          <>
            <SurveyItemTitle
              survey={survey}
              item={formSchema[key]}
              isThumbnailView={isThumbnailView}
              layoutMode={layoutMode}
              forResult={false}
              notifyCanNotSeeAnswer={alreadyAnswer && !canUserSeeAnswer}
            />
            {/* In thumbnail view, only Title is needed. Do not unecessary render the rest of the survey */}
            {!isThumbnailView && (
              <Box className={classes.formFieldRow} pt={2}>
                <Box className={classes.formFieldRowContent}>
                  {alreadyAnswer && !canUserSeeAnswer ? (
                    <CanNotSeeAnswerNote />
                  ) : (
                    getFormElement(
                      survey.live_results,
                      formSchema[key],
                      survey.multiple_answers ||
                        (!survey.multiple_answers && !alreadyAnswer)
                    )
                  )}
                </Box>
                <SurveySubmitSection
                  isThumbnailView={isThumbnailView}
                  surveyHash={survey.hash}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    ));
  if (isEmpty(formSchema) && ["evaluation", "form"].includes(survey.type)) {
    result = (
      <Box className={clsx(classes.paginationScreen)}>
        <SurveyIntro
          survey={survey}
          isThumbnailView={isThumbnailView}
          layoutMode={layoutMode}
          forResult={false}
        />
        <SurveySubmitSection
          isThumbnailView={isThumbnailView}
          surveyHash={survey.hash}
        />
      </Box>
    );
  }

  return result;
};
PaginatedFormViewer.propTypes = {
  survey: PropTypes.object,
  formSchema: PropTypes.object,
  layoutMode: PropTypes.string,
  isThumbnailView: PropTypes.bool,
  alreadyAnswer: PropTypes.bool,
  canUserSeeAnswer: PropTypes.bool,
};
export default PaginatedFormViewer;
