import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import SideBar from "liveShopping/backoffice/otm/components/SideBar";
import { TabListOTM } from "app/liveShopping/backoffice/otm/components/SideBar/TabList";
import { getUrl } from "app/views/containers/Application/Routes";
import { useDispatch, useSelector } from "react-redux";
import { liveShoppingBootstrapSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { displayTabBar } from "app/state/ducks/liveShopping/actions";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
  },
}));

const BackofficeLayout = ({ match, children }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = useSelector(mobileLayoutSelector);
  const liveShoppingBootstrap = useSelector(liveShoppingBootstrapSelector);

  if (isMobile && liveShoppingBootstrap?.can_use_oto) {
    dispatch(displayTabBar(false));
  }
  return (
    <Box className={classes.root}>
      <SideBar retracted TabList={TabListOTM()} backLink={getUrl("otm_lives")} />
      {children}
    </Box>
  );
};

export default BackofficeLayout;
