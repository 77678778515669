import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { Divider, useTheme } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isIFrameSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import Button from "../../reusables/Button";
import IconButton from "../../reusables/IconButton";
import BoxArrowUp from "react-bootstrap-icons/dist/icons/box-arrow-up";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import Interaction from "../../reusables/Notifications/Interaction";
import { getKeyword } from "app/state/ducks/application/selectors";
import { emit } from "utilities/liveshopping-sdk";
import emitter from "utilities/emitter";
import { SHARE_MODAL } from "utilities/emitter/events";
import ShareModal from "app/liveShopping/frontoffice/otm/components/LandingLiveShopping/sections/ShareModal";
import LiveOptions from "../../reusables/LiveOptions";
import { getUrl } from "app/views/containers/Application/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "end",
    padding: (props) => (props.isMobile ? "20px 20px 0px 20px" : "0px 20px 0px 20px"),
    height: "32px",
    marginTop: "24px",
  },

  greyButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    width: (props) => (props.isMobile ? "40px" : "32px"),
    height: (props) => (props.isMobile ? "40px" : "32px"),
    borderRadius: "99px",
    background: theme.palette.colors.grey200,
  },
  modifyTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.grey700,
  },
  typoButton: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    color: theme.palette.colors.white,
  },
  options: {
    display: "flex",
    //  width: "112px",
    justifyContent: "space-between",
    gap: "8px",
  },
  divider: {
    marginLeft: "8px",
    marginRight: "8px",
    height: "23px",
  },
}));

const TopBar = ({ }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const isIFrame = useSelector(isIFrameSelector);
  const classes = useStyles({ isMobile });
  const theme = useTheme();
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [modalShareDisplay, setModalShareDisplay] = useState(false);
  const wiz = useSelector(currentWizSelector);

  async function handleShare() {
    if (isIFrame && isMobile) return emit("SHARE");
    try {
      if (isMobile && navigator.share) {
        await navigator.share({
          title: _t("Share this live shopping event"),
          text: _t("Share this live shopping event"),
          url: window.parent ? window.parent?.location?.href : window.location?.href,
        });
      } else {
        setModalShareDisplay(true);
      }
    } catch (error) {
      console.error("Something went wrong sharing the data, need another way", error);
    }
  }

  useEffect(() => {
    if (!isIFrame) return;
    const share = () => setModalShareDisplay(true);
    emitter.on(SHARE_MODAL, share);
    return () => emitter.off(SHARE_MODAL, share);
  }, []);

  let label = _t("Open the live");

  if (wiz.status === "draft" || wiz.status === "pre_live") label = _t("Open the pre live");

  if (wiz.status === "awaiting_replay" || wiz.status === "replay") label = _t("Open the replay");

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center" }}>
        {isMobile ? (
          <Button color={theme.palette.colors.grey200} link url={getUrl("otm_live_edit", { keyword }, false)}>
            <Typography className={classes.modifyTypo}>{_t("Modify")}</Typography>
          </Button>
        ) : (
          <>
            <Box className={classes.options}>
              <IconButton onClick={handleShare}>
                <BoxArrowUp />
              </IconButton>
              <LiveOptions />
            </Box>
            <Divider orientation={"vertical"} className={classes.divider} />
            <Button target={"_blank"} rel={"noopener noreferrer"} link url={`/ls/${keyword}`}>
              <Typography className={classes.typoButton}>{label}</Typography>
            </Button>
          </>
        )}
      </Box>
      <ShareModal display={modalShareDisplay} setDisplay={setModalShareDisplay} />
      {openModal && (
        <Interaction
          type={"success"}
          title={_t("Your live will be public")}
          subTitle={_t("Users will have access and can chat. They will not have access to the products.")}
          textBtn2={_t("Cancel")}
          textBtn1={_t("Publish")}
          action1={handlePublish}
          action2={() => setOpenModal(false)}
        />
      )}
    </Box>
  );
};

TopBar.propTypes = {};

TopBar.defaultProps = {};

export default TopBar;
