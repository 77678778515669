import { _t } from "core/i18n";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import dayjs from "utilities/utils/date";
import every from "lodash/every";

//to improve with other roles
export const getRoleLabel = (role) => {
  switch (role) {
    case "admin":
      return _t("Administrator");
    case "user":
      return _t("Participant");
    case "anim":
      return _t("Animator");
    case "speaker":
      return _t("Speaker");
    default:
      return _t("Participant");
  }
};

export const getOriginLabel = (origin) => {
  switch (origin) {
    case "origin_other":
      return _t("Other");
    case "origin_wisembly":
      return _t("Wisembly");
    case "origin_import":
      return _t("Import");
    default:
      return origin;
  }
};

export const getRSVPLabel = (response) => {
  if (response) {
    return _t("Will come");
  } else if (response === null) {
    return _t("No response");
  } else {
    return _t("Not coming");
  }
};

export const extractUserData = (user, columns) => {
  let result = {
    id: user.hash,
    access: get(user, "access.ref"),
    signatureRequest: user.signatureRequest,
  };

  columns.forEach((col) => {
    if (!col) return null;
    switch (col.id) {
      case "checkbox":
        //check is selected
        set(result, `${col.id}`, false);
        break;
      case "name": {
        set(result, `${col.id}`, user.name);
        break;
      }
      case "email": {
        set(result, `${col.id}`, user.email);
        break;
      }
      case "entreprise": {
        set(result, `${col.id}`, user.company);
        break;
      }
      case "role": {
        //implement select
        //admin, anim, user
        const role = get(user, "access.scope", "user");
        set(result, `${col.id}`, role);
        break;
      }
      case "registrationConfirmation": {
        set(result, `${col.id}`, "");
        break;
      }
      case "invitationToTheTeam": {
        set(result, `${col.id}`, "");
        break;
      }
      case "invitation": {
        set(result, `${col.id}`, "");
        break;
      }
      case "relaunchInvitation": {
        set(result, `${col.id}`, "");
        break;
      }
      case "eventRegistrationConfirmation": {
        set(result, `${col.id}`, "");
        break;
      }
      case "oneDayReminder": {
        set(result, `${col.id}`, "");
        break;
      }
      case "tenMinuteReminder": {
        set(result, `${col.id}`, "");
        break;
      }
      case "havingParticipated": {
        set(result, `${col.id}`, "");
        break;
      }
      case "notHavingParticipated": {
        set(result, `${col.id}`, "");
        break;
      }
      case "origin": {
        set(result, `${col.id}`, get(user, "access.origin", ""));
        break;
      }
      case "originDetails": {
        set(result, `${col.id}`, "");
        break;
      }
      case "originUrl": {
        set(result, `${col.id}`, "");
        break;
      }
      case "rsvp": {
        set(result, `${col.id}`, "");
        break;
      }
      case "online": {
        set(result, `${col.id}`, "");
        break;
      }
      case "signature": {
        set(result, `${col.id}`, user.signature);

        break;
      }
      default:
        set(result, `${col.id}`, false);
    }
  });
  return result;
};

export const createUsersData = (users, columns) => {
  const validRows = users.filter((user) => !isEmpty(user));
  const result = validRows.map((user) => {
    if (isEmpty(user)) return null;
    return extractUserData(user, columns);
  });
  return result;
};

export const isUserNew = (access) => {
  if (!access) return false;

  let createdDate = get(access, "granted_at", "now");
  const now = dayjs();
  createdDate = dayjs(createdDate);
  return now.diff(createdDate, "day") <= 1;
};
export const ALL_OPTIONS_FILTER = {
  online: ["online", "offline"],
  sendingStatus: [
    "clicked",
    "sent",
    "opened",
    "spam",
    "error",
    "blocked",
    "sending",
    "unsubscribed",
  ],
  sendingOrigin: ["wisembly", "email", "import", "other"],
  rsvp: ["willcome", "wontcome", "notanswered"],
};

export const allOptionsChecked = (filter, filterlist) => {
  if (!Array.isArray(filterlist)) return false;
  if (filterlist.includes("all")) return true;
  if (!ALL_OPTIONS_FILTER[filter]) return false;
  if (every(ALL_OPTIONS_FILTER[filter], (elem) => filterlist.includes(elem)))
    return true;
  return false;
};
