import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { focusedProductsUtility } from "app/liveShopping/utils";
import { useDispatch, useSelector } from "react-redux";
import { allProductsSelector } from "app/state/ducks/liveShopping/selectors";
import { currentWizReplaySelector, currentWizSelector, focusedProductSelector, focusedProductsSelector } from "app/state/ducks/ressources/selectors";
import emitter from "utilities/emitter";
import { REPLAY_TIMESTAMP_UPDATE } from "utilities/emitter/events";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import Card from "../Carousel/Card";
import { setViewedProduct } from "app/state/ducks/liveShopping/actions";

const MonoProduct = ({ }) => {
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const products = useSelector(allProductsSelector);
  const replay = useSelector(currentWizReplaySelector);
  const wiz = useSelector(currentWizSelector);

  const focusedProductLive = useSelector(wiz.status === 'replay' ? focusedProductsSelector : focusedProductSelector);
  const dispatch = useDispatch();

  const updateTimestamp = (seconds) => {
    setCurrentTimestamp(seconds);
  };

  useEffect(() => {
    emitter.on(REPLAY_TIMESTAMP_UPDATE, updateTimestamp);
    return () => emitter.off(REPLAY_TIMESTAMP_UPDATE, updateTimestamp);
  }, []);

  useEffect(() => {
    dispatch(
      resourceFetch("products", {
        slug: ["event", wiz.keyword, "products"],
        once: true,
        callback: error => {
          if (error) {
            dispatch(setError(error));
            return;
          }
        },
      })
    );
  }, []);

  const focusedProduct = focusedProductsUtility(
    wiz,
    products,
    currentTimestamp,
    focusedProductLive
  )[0];

  if (!focusedProduct) return null;

  return (
    <Box mb={1} style={{ display: "flex", justifyContent: "center", zIndex: 3 }}>
      <Card product={focusedProduct} isActive={true} onClick={() => dispatch(setViewedProduct(focusedProduct))} />
    </Box>
  );
};

MonoProduct.propTypes = {};

MonoProduct.defaultProps = {};

export default MonoProduct;
