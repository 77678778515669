import { ensureFloat } from "app/liveShopping/utils";
import { _t } from "utilities/i18n";

export const validate = (values, wiz) => {
  let errors = {};

  if (!values.name)
    errors.name = _t("The name must be filled in");

  if (!values.description)
    errors.description = _t("The description must be filled in");

  if (!values.product_url && !wiz?.cart_integration)
    errors.product_url = _t("The product URL must be filled in");

  return errors;
};
