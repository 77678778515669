import React, { useState } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import ControlBar from "adminComponents/UserManagement/ControlBar";
import Paginator from "adminContainers/users/Paginator";
import { makeStyles } from "@material-ui/core/styles";
import { PaginatorContextProvider } from "adminContainers/users/paginatorContext";
import Stats from "adminComponents/UserManagement/ParticipantsStats";
import memo, { renderLog } from "utilities/memo";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.wisemblyColors.white,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  stats: {
    marginTop: "52px",
    paddingRight: "20px",
    paddingLeft: "4px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  right: {},
}));

const Participants = () => {
  const classes = useStyles();
  const [visibleColumns, setVisibleColumns] = useState([]);

  renderLog("Participants");
  return (
    <Box className={clsx("Participants", classes.root)}>
      <PaginatorContextProvider panel="participant">
        <Box className={classes.stats}>
          <Stats />
        </Box>

        <ControlBar forTeam={false} onVisibleColumnChange={setVisibleColumns} />
        <Box className={clsx("Table", classes.table)}>
          <Paginator admins={false} visibleColumns={visibleColumns} />
        </Box>
      </PaginatorContextProvider>
    </Box>
  );
};

export default memo(Participants);
