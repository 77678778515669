import PropTypes from "prop-types";
import BaseModel from "./base";
import { attr, fk, many, oneToOne } from "redux-orm";
import { _t } from "i18n";
import md5 from "crypto-js/md5";

class Quote extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Quote;
  }

  getOwnerName(isOwner = false) {
    if (this.announcement) {
      return this.event.title;
    }

    const itIsMe = isOwner ? ` ${_t("(me)")}` : "";

    if (this.user === null && this.username === null) {
      return _t("Anonymous") + itIsMe;
    }

    return this.username + itIsMe;
  }

  toArray() {
    let data = {
      username: this.username,
      quote: this.quote,
      announcement: this.announcement,
    };

    let tags = [];
    this.tags.toModelArray().forEach((tag) => tags.push(tag.toArray()));
    data.tags = tags;

    return data;
  }

  isOwner(anonymousToken = null) {
    if (!anonymousToken || this.is_mine) {
      return this.is_mine;
    }

    return (
      this.secure_token && this.secure_token === md5(anonymousToken).toString()
    );
  }

  setEvent(Event) {
    this.event = Event.id;
  }

  setUser(User) {
    this.user = User.id;
  }

  setQuote(value) {
    this.quote = value;
  }

  setMedia(Media) {
    this.media = Media.id;
  }

  addTags(tag) {
    this.tags.add(tag);
  }

  setProcessed(value) {
    this.processed = value;
  }

  removeTags(tag) {
    this.tags.remove(tag);
  }
}

Quote.modelName = "Quote";
Quote.backendName = "quote";

Quote.fields = {
  id: attr(),
  quote: attr(),
  username: attr(),
  reactions: attr(),
  reaction_count: attr(),
  moderate: attr(),
  favorite: attr(),
  pinned: attr(),
  created_at: attr(),
  published_at: attr(),
  processed: attr(),
  announcement: attr(),
  comments_count: attr(),
  user_reaction: attr(),
  has_commented: attr(),
  wiz_rank: attr(),
  is_mine: attr(),
  secure_token: attr(),
  event: fk("Event", "quotes"),
  user: fk("User", "quotes"),
  media: oneToOne("Media", "quote"),
  tags: many("Tag"),
};

export default Quote;
export const QuoteProptypes = {
  id: PropTypes.string,
  quote: PropTypes.string,
  username: PropTypes.string,
  reactions: PropTypes.array,
  reaction_count: PropTypes.number,
  moderate: PropTypes.bool,
  favorite: PropTypes.bool,
  pinned: PropTypes.bool,
  position: PropTypes.number,
  created_at: PropTypes.string,
  published_at: PropTypes.string,
  processed: PropTypes.bool,
  announcement: PropTypes.bool,
  wiz_rank: PropTypes.number,
  comments_count: PropTypes.number,
  user_reaction: PropTypes.string,
  has_commented: PropTypes.bool,
  is_mine: PropTypes.bool,
  secure_token: PropTypes.string,
};
