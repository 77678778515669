// CORE
export const CORE_USER_LOADED = "core:user:loaded";
export const CORE_ROUTE_CHANGE = "core:route:change";
export const CORE_RESOURCE_ACTION = "core:resource:action";

// XHR
export const XHR_START = "xhr:start";
export const XHR_DONE = "xhr:done";
export const XHR_FAIL = "xhr:fail";
export const XHR_ALWAYS = "xhr:always";

// Notifications
export const EVENT_SEND_NOTIFICATION = "notification:send";

// Modals
export const OPEN_ALERT_MODAL = "alertmodal:open";
export const CLOSE_ALERT_MODAL = "alertmodal:close";

// Alerts
export const EVENT_FLASH_SUCCESS = "flash:success";
export const EVENT_FLASH_ERROR = "flash:error";
export const EVENT_FLASH_SUCCESS_VANISH = "flash:success:vanish";
export const EVENT_FLASH_ERROR_VANISH = "flash:errror:vanish";

// Live Shopping
export const REPLAY_TIMESTAMP_UPDATE = "replay:timestamp:update";
export const REPLAY_PLAYER_PLAY = "replay:player:play";
export const REPLAY_PLAYER_PAUSE = "replay:player:pause";
export const REPLAY_PLAYER_SEEK_FORWARD = "replay:player:seek:forward";
export const REPLAY_PLAYER_SEEK_BACKWARD = "replay:player:seek:backward";
export const CART_UPDATE = "cart:update";
export const CART_UPDATED = "cart:updated";
export const SHARE_MODAL = "share:modal";
export const NEW_ACCEPTANCE = "new:acceptance";

//Survey Alerts
export const SURVEY_EVENT_FLASH_SUCCESS = "survey:flash:success";
export const SURVEY_EVENT_FLASH_ERROR = "survey:flash:error";
export const SURVEY_EVENT_FLASH_SUCCESS_VANISH = "survey:flash:success:vanish";
export const SURVEY_EVENT_FLASH_ERROR_VANISH = "survey:flash:error:vanish";

//Admin Alerts
export const ADMIN_EVENT_FLASH_SUCCESS = "admin:flash:success";
export const ADMIN_EVENT_FLASH_ERROR = "admin:flash:error";
export const ADMIN_EVENT_FLASH_SUCCESS_VANISH = "admin:flash:success:vanish";
export const ADMIN_EVENT_FLASH_ERROR_VANISH = "admin:flash:error:vanish";

// ORM
export const EVENT_RESOURCE_SET = "orm:resource:set";
export const EVENT_RESOURCE_UNSET = "orm:resource:unset";
//was missing
export const EVENT_USER_JOIN_MEETING = "user:join:meeting";
export const EVENT_USER_LEAVE_MEETING = "user:leave:meeting";

export const EVENT_TIMELINE_NOTIFICATION = "notification:timeline";
export const EVENT_SET_TIMELINE_STATUS = "notification:timeline:status";
export const EVENT_PARTICIPANT_COUNT = "event:participant:count";
export const GO_TO_WIZ_HOME = "core:app:gotowiz";
export const REFRESH_WIZ_DATA = "core:app:wiz:refresh";
export const FORCE_TIMELINE_UPDATE = "event:timeline:force:update";
export const EVENT_SHOW_FORM_INTRO = "event:form:show:intro";
export const EVENT_DISMISS_FORM_INTRO = "event:form:dismiss:intro";
export const EVENT_SHOW_USER_EVALUATION_NOTE = "event:app:show:userevaluation:note";
export const EVENT_RESET_SURVEY_FORM = "event:form:survey:reset";
export const EVENT_SHOW_FORM_ENDING = "event:form:survey:ending";
export const EVENT_DISMISS_FORM_ENDING = "event:form:dismiss:survey:ending";

// Vonage
export const EVENT_VONAGE_ENTER_STAGE = "vonage:stage:enter";
export const EVENT_VONAGE_STREAM_UPDATE = "vonage:stream:update";
export const EVENT_VONAGE_STREAM_DELETE = "vonage:stream:delete";
export const EVENT_VONAGE_STREAM_COUNT_UPDATE = "vonage:stream:count:update";
export const EVENT_FLASH_SUCCESS_AFTER_GET_ANSWER = "event:form:survey:submit:andGetResponse";
export const EVENT_SCREEN_LAYOUT_MODE_CHANGE = "event:screen:layout_mode_change";
export const EVENT_SCREEN_FOCUSED_LAYOUT = "event:screen:focused_layout";
export const REDRAW = "event:screen:redraw";
export const TOOLTIP_INTERACTIVITY = "event:screen:tooltip_interactivity";
export const POINTER_ENTER_PRESENT = "event:screen:pointer_enter_present";
export const POINTER_LEAVE_PRESENT = "event:screen:pointer_leave_present";
export const EVENT_VONAGE_STREAM_FOCUS = "vonage:stream:focus";
export const EVENT_VONAGE_STREAM_CLEAR_FOCUS = "vonage:stream:clear_focus";
export const EVENT_ADMIN_TAGS_OPEN_EDITING_MODE = "admin:tags:open:editing_mode";
export const UNREAD_MESSAGE = "unread:message:event";
export const EVENT_ANIM_SURVEY_PROJECTION_DISPLAY_OPTIONS = "event:animation:survey:project_display_options";
export const FAVORITE_MESSAGE_COUNT = "event:favorite:message:count";
export const UNMODERATED_MESSAGE_COUNT = "event:umoderated:message:count";
export const MODIFY_LEADERBOARD = "event:modify:leaderboard";
export const CANCEL_MODIFY_LEADERBOARD = "event:cancel:modify:leaderboard";
export const EDIT_LEADERBOARD = "event:edit:leaderboard";
export const NOTIFIY_LEADERBOARD_QUIZZ = "event:notify:leaderboard:quizz";
export const EVENT_PROJECTION_MAMAGEMENT_DISPLAY_PANEL = "event:projection_management:display:panel";
export const EVENT_ADMIN_ADD_TAG_TO_QUOTE = "event:admin:add:tag:to:quote";
export const EVENT_PARTICIPANT_USER_COUNT = "event:participant:user:count";
export const EVENT_ADMIN_USER_COUNT = "event:admin:user:count";
export const EVENT_ADD_USERS = "event:admin:add:users";
export const EVENT_INVALID_OTT = "event:invalid:ott";
export const EVENT_EDIT_SIGNATURE_MODE = "event:admin:edit_signature_mode";
export const EVENT_VALIDATE_SIGNATURES = "event:admin:validate_signatures";
export const DECREMENT_COUNT_SURVEY = "event:admin:decrement:count:survey";
export const OPEN_MESSAGES_FILTER = "event:chat:open:filter";
export const CLOSE_MESSAGES_FILTER = "event:chat:close:filter";
export const RESET_MESSAGES_FILTER = "event:chat:reset:filter";

export const EVENT_CHAT_MESSAGES_UPDATE = "event:chat:messages:update";
export const EVENT_CHAT_DISPLAY_INDICATOR_NEW_MESSAGES = "event:chat:display:indicator:new_messages";
export const EVENT_CHAT_SCROLLDOWN_TRIGGER = "event:chat:scrolldown:trigger";
export const EVENT_RESET_CHAT_UNREAD_INDICATOR = "event:reset:chat:unread:indicator";
export const EVENT_RESET_SIZE_LIST_CHAT = "event:reset:size:list:chat";
export const STREAM_UPDATE_TALKING_STATUS = "stream:update:talking:_status";
