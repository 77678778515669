import { getUserSession } from "core/session";
import { currentWizSelector } from "ressourcesDucks/selectors";
import {
  setPopupParticipationMode,
  setConnection,
} from "applicationDucks/actions";
import { getAnonymousUserParticipation } from "applicationDucks/selectors";
import { TYPE_SIGNIN } from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import globalStore from "appStore";
import { _t } from "core/i18n";
import basil from "core/basil";
import { hasUsername } from "utilities/username";

//allowEffect trigger or not effect like showing popup
const isValidParticipation = ({
  allowEffect = true,
  callbackOnValid = () => { },
  callBackOnInvalid = () => { },
}) => {
  const state = globalStore.getState();
  const session = getUserSession();
  let validationResult = false;
  const anonymousUserParticipation = getAnonymousUserParticipation(state);
  const { force_username, allow_username, participation_mode, keyword } =
    currentWizSelector(state) || {};
  const choseToRemainAnonymous = basil.get(
    `participationMode:${session.token}:${keyword}:remainAnonymous`
  );

  switch (participation_mode) {
    default:
    case null:
      if (force_username) {
        if (session.isAnon() && !hasUsername()) {
          if (allowEffect) {
            globalStore.dispatch(
              setPopupParticipationMode(true, callbackOnValid)
            );
          }
          validationResult = false;
          break;
        }
        validationResult = true;
        break;
      }

      if (
        session.isAnon() &&
        ((!anonymousUserParticipation &&
          (!allow_username || // anonyme + switch "in his name" + username disable
            (allow_username && !hasUsername()))) || // anonyme + switch "in his name" + username optional + no username given
          (anonymousUserParticipation &&
            allow_username &&
            !hasUsername() &&
            !choseToRemainAnonymous)) // anonyme + switch "anonymous" + username optional + no username given + propose the modal only once
      ) {
        if (allowEffect) {
          globalStore.dispatch(
            setPopupParticipationMode(true, callbackOnValid)
          );
        }
        validationResult = false;
        break;
      }
      validationResult = true;
      break;

    case "connected":
      if (session.isAnon()) {
        if (allowEffect) {
          globalStore.dispatch(
            setConnection({
              displayModal: true,
              type: TYPE_SIGNIN,
              title: _t("You must be logged in to intervene"),
              callback: callbackOnValid,
            })
          );
        }
        validationResult = false;
        break;
      }
      validationResult = true;
      break;

    case "anonymous":
      validationResult = true;
      break;
  }

  if (validationResult) {
    callbackOnValid();
  } else {
    callBackOnInvalid();
  }

  return validationResult;
};

const canCustomiseName = () => {
  const state = globalStore.getState();
  const session = getUserSession();
  const { force_username, allow_username, participation_mode } =
    currentWizSelector(state);

  switch (participation_mode) {
    default:
    case null:
      if (!session.isAnon()) {
        // connected user can't have username
        return false;
      }

      if (force_username || allow_username) {
        return true;
      }

      return true; // connection modal

    case "connected":
      if (!session.isAnon()) {
        return false;
      }

      return true; // connection modal

    case "anonymous":
      return false;
  }
};

const lunchParticipationModePopPup = () => {
  globalStore.dispatch(setPopupParticipationMode(true));
};

export { isValidParticipation, canCustomiseName, lunchParticipationModePopPup };
