import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { _t } from "i18n";
import emitter from "core/emitter";
import VonageSDK from "utilities/vonage";
import { getVerifiedUsername } from "utilities/username";
import { EVENT_VONAGE_ENTER_STAGE } from "core/emitter/events";

import Box from "@material-ui/core/Box";
import Mic from "@material-ui/icons/Mic";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import PermCameraMicIcon from "@material-ui/icons/PermCameraMic";
import Typography from "@material-ui/core/Typography";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import Link from "@material-ui/core/Link";
import VideocamOutlinedIcon from "@material-ui/icons/VideocamOutlined";
import MicNoneOutlinedIcon from "@material-ui/icons/MicNoneOutlined";

import Button from "appComponents/Mui/Button";
import Stream from "./Stream";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(2),
    borderRadius: "6px",
    backgroundColor: theme.palette.background.box,
    outline: "none",
    marginBottom: "75px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px",
    },
  },
  preview: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: theme.sizes.webcamPreview.width,
    height: theme.sizes.webcamPreview.height,
    backgroundColor: theme.palette.background.box,
    borderRadius: "6px",
    marginBottom: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  formControl: {
    display: "flex",
    flex: 1,
  },
  link: {
    cursor: "pointer",
  },
  vuMeter: {
    "& > .icon": {
      fontSize: "40px",
      color: theme.palette.secondary.main,
      margin: "20px",
    },
  },
  dotLevel: {
    fontSize: "8px",
    color: theme.palette.button.default.background.enable,
    "&.active": {
      color: theme.palette.secondary.main,
    },
  },
  cameraPlaceholder: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: theme.sizes.webcamPreview.width,
    height: theme.sizes.webcamPreview.height,
    background: theme.palette.background.secondaryBox,
    color: theme.wisemblyColors.black,
    padding: "45px",
  },
  cameraPlaceholderIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(226 92 91 / 10%);",
    height: "100px",
    width: "100px",
    borderRadius: "50%",
    color: theme.wisemblyColors.pastelRed,
    "& svg": {
      fontSize: "36px",
    },
  },
  cameraPlaceholderTitle: {
    fontSize: "20px",
    fontWeight: 700,
    textAlign: "center",
  },
  cameraPlaceholderSubtitle: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
  },
  boxContent: {
    width: "100%",
  },
  buttonSpacing: {
    margin: theme.spacing(1),
  },
  noMicDetected: {
    fontSize: "15px",
  },
}));

const MicrophoneLevel = ({ publisher, dots, permissions }) => {
  const classes = useStyles();
  const [currentLevel, setCurrentLevel] = useState(0);
  useEffect(() => {
    if (!publisher) return;
    publisher.on("audioLevelUpdated", function (event) {
      let movingAvg = null;
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }
      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      var logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);
      /* Here we have a log level beween 0 and 1, lets see how many dots we need to colorize  */
      const dotsToShow = Math.round(dots * logLevel);
      if (dotsToShow !== currentLevel) {
        setCurrentLevel(dotsToShow);
      }
    });

    return () => {
      if (!publisher) return;
      publisher.off("audioLevelUpdated");
    };
  }, [publisher]);

  const openHowTo = () => {
    /*window.open(
      _t("https://www.wisembly.com/connectivity/index.html"),
      "_blank"
    );*/
    console.log(
      "Here, we open the how to for microphone ... ( waiting for product )"
    );
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={classes.vuMeter}
    >
      <Mic className="icon" />
      {!permissions?.microphone && (
        <>
          <Typography className={classes.noMicDetected}>
            {_t("No microphone detected")}
          </Typography>
          <Link href="#" onClick={openHowTo} color="inherit">
            {_t("How to do ?")}
          </Link>
        </>
      )}
      {permissions.microphone && (
        <Box display="flex" justifyContent="center">
          {[...Array(dots).keys()].map((level) => {
            return (
              <FiberManualRecordIcon
                key={`micLevel-${level}`}
                className={clsx(
                  classes.dotLevel,
                  level < currentLevel ? "active" : null
                )}
              />
            );
          })}
        </Box>
      )}
    </Box>
  );
};

MicrophoneLevel.propTypes = {
  publisher: PropTypes.object,
  dots: PropTypes.number,
  permissions: PropTypes.object,
};
MicrophoneLevel.defaultProps = {
  dots: 5,
};

const DeviceList = ({
  id,
  label,
  value,
  onChange,
  type,
  devices,
  canSelect,
}) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${id}_inputlabel`}>{label}</InputLabel>
      <Select
        labelId={`${id}_select`}
        id={`${id}_select`}
        label={label}
        value={value || ""}
        onChange={({ target }) => onChange(target.value, type)}
        disabled={!canSelect}
      >
        {devices.map(({ label, deviceId }) => {
          return (
            <MenuItem key={deviceId} value={deviceId}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

DeviceList.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  devices: PropTypes.array,
  canSelect: PropTypes.bool,
};
DeviceList.defaultProps = {
  onChange: () => {},
  devices: [],
  canSelect: false,
};

const VideoSourceSelector = ({
  availableDevices,
  sources,
  onChange,
  canSelect,
}) => {
  const videoSources = availableDevices.filter(
    ({ kind }) => kind === "videoInput"
  );

  return (
    <DeviceList
      id="webcam-label"
      label={_t("Video source")}
      value={sources.videoSource}
      onChange={onChange}
      type="videoSource"
      devices={videoSources}
      canSelect={canSelect}
    />
  );
};

VideoSourceSelector.propTypes = {
  availableDevices: PropTypes.array,
  sources: PropTypes.array,
  onChange: PropTypes.func,
  canSelect: PropTypes.bool,
};
VideoSourceSelector.defaultProps = {
  availableDevices: [],
  sources: [],
  onChange: () => {},
  canSelect: false,
};

const AudioSourceSelector = ({
  availableDevices,
  sources,
  onChange,
  canSelect,
}) => {
  const audioSources = availableDevices.filter(
    ({ kind }) => kind === "audioInput"
  );
  return (
    <DeviceList
      id="min-label"
      label={_t("Audio source")}
      value={sources.audioSource}
      onChange={onChange}
      type="audioSource"
      devices={audioSources}
      canSelect={canSelect}
    />
  );
};

AudioSourceSelector.propTypes = {
  availableDevices: PropTypes.array,
  sources: PropTypes.array,
  onChange: PropTypes.func,
  canSelect: PropTypes.bool,
};
AudioSourceSelector.defaultProps = {
  availableDevices: [],
  sources: [],
  onChange: () => {},
  canSelect: false,
};

const HelpSection = () => {
  const classes = useStyles();

  const openTestPerformances = () => {
    window.open(
      _t("https://www.wisembly.com/connectivity/index.html"),
      "_blank"
    );
  };
  return (
    <Box display="flex" justifyContent="center" mb={2}>
      <Button
        size="small"
        color="secondary"
        variant="contained"
        onClick={openTestPerformances}
        className={classes.buttonSpacing}
        startIcon={<FlashOnIcon />}
      >
        {_t("Test your performance")}
      </Button>
      <Button
        size="small"
        variant="contained"
        color="secondary"
        className={classes.buttonSpacing}
        startIcon={<EmojiObjectsOutlinedIcon />}
      >
        {_t("Useful tips")}
      </Button>
    </Box>
  );
};

const EnterStageVideo = ({ canJoin, setPreview }) => {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      disabled={!canJoin}
      className={classes.buttonSpacing}
      size="medium"
      onClick={() => {
        setPreview(false);
        setTimeout(
          () => emitter.emit(EVENT_VONAGE_ENTER_STAGE, VonageSDK.TYPE_VIDEO),
          100
        );
      }}
    >
      {_t("Appear on screen")}
    </Button>
  );
};

EnterStageVideo.propTypes = {
  canJoin: PropTypes.bool,
  setPreview: PropTypes.func,
};
EnterStageVideo.defaultProps = {
  canJoin: false,
  setPreview: () => {},
};

const EnterStageScreen = ({ canJoin, canShareScreen, setPreview }) => {
  const classes = useStyles();
  return (
    <Button
      type="submit"
      variant="contained"
      className={classes.buttonSpacing}
      disabled={!canShareScreen || !canJoin}
      size="medium"
      onClick={() => {
        setPreview(false);
        setTimeout(
          () => emitter.emit(EVENT_VONAGE_ENTER_STAGE, VonageSDK.TYPE_SCREEN),
          100
        );
      }}
    >
      {_t("Share screen")}
    </Button>
  );
};
EnterStageScreen.propTypes = {
  canJoin: PropTypes.bool,
  canShareScreen: PropTypes.bool,
  setPreview: PropTypes.func,
};
EnterStageScreen.defaultProps = {
  canJoin: false,
  canShareScreen: false,
  setPreview: () => {},
};

const CameraErrorMessage = ({ icon, title, subtitle, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.cameraPlaceholder}>
      <Box className={classes.cameraPlaceholderIcon}>{icon}</Box>
      <Typography className={classes.cameraPlaceholderTitle}>
        {title}
      </Typography>
      <Typography
        className={classes.cameraPlaceholderSubtitle}
        paragraph={true}
        variant="body2"
      >
        {subtitle}
      </Typography>
      {children}
    </Box>
  );
};

CameraErrorMessage.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.object,
};
CameraErrorMessage.defaultProps = {
  icon: null,
  children: null,
};

const CameraOnboarding = ({ vonage }) => {
  const askDevicePermissions = () => {
    try {
      vonage.getUserMedia();
    } catch (error) {
      console.error("Error when requesting user media", error);
    }
  };
  // prettier-ignore
  return (
    <CameraErrorMessage
      icon={<PermCameraMicIcon />}
      title={_t("We need some permissions")}
      subtitle={_t("To use your microphone and webcam we need to ask for permissions, please click on allow when we will request them")}
    >
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={askDevicePermissions}
      >
        {_t("Understood")}
      </Button>
    </CameraErrorMessage>
  );
};

CameraOnboarding.propTypes = {
  vonage: PropTypes.object,
};

const CameraPreview = ({
  vonage,
  setStream,
  stream,
  sources,
  ownPublisher,
  setOwnPublisher,
  setVonageInitError,
}) => {
  const classes = useStyles();
  const ref = useRef();
  const preview = (sources) => {
    if (!sources.audioSource && !sources.videoSource) return;
    if (ownPublisher) {
      ownPublisher.setVideoSource(sources.videoSource);
      ownPublisher.setAudioSource(sources.audioSource);
      return;
    }
    vonage
      .createPublisher("own")
      .then((publisher) => {
        setOwnPublisher(publisher);
      })
      .catch((error) => {
        error.name && setVonageInitError(error.name);
      });
  };

  useEffect(() => {
    preview(sources);
  }, [sources]);

  useEffect(() => {
    return () => {
      console.log("Destroy publishers video");
      vonage.destroyPublishers();
    };
  }, []);
  return (
    <Box className={clsx("PreviewVideo", classes.preview)}>
      <Stream
        id="own"
        ref={ref}
        type={VonageSDK.TYPE_VIDEO}
        vonage={vonage}
        onMuteAudio={(mute) => setStream({ ...stream, hasAudio: !mute })}
        onMuteVideo={(mute) => setStream({ ...stream, hasVideo: !mute })}
        stream={stream}
        isOwn={true}
        preview={true}
      />
    </Box>
  );
};

CameraPreview.propTypes = {
  vonage: PropTypes.object,
  setStream: PropTypes.func,
  stream: PropTypes.object,
  sources: PropTypes.array,
  ownPublisher: PropTypes.object,
  setOwnPublisher: PropTypes.func,
  setVonageInitError: PropTypes.func,
};
CameraPreview.defaultProps = {
  setStream: () => {},
  setOwnPublisher: () => {},
  setVonageInitError: () => {},
  sources: [],
};

const DeviceSelector = ({ vonage, setPreview }) => {
  const classes = useStyles();
  const [ownPublisher, setOwnPublisher] = useState(null);
  const [hasDevices, setDevices] = useState(null);
  // already configured sources (default if first time)
  const [sources, setSources] = useState({
    audioSource: vonage.audioSourceId,
    videoSource: vonage.videoSourceId,
  });

  // Boolean to set if user can join stage
  const [vonageOpened, setVonageOpened] = useState(!!vonage.session);

  /* Available devices */
  const [availableDevices, setAvailableDevices] = useState([]);
  const getDevices = async () => {
    const devices = await vonage.getDevices();
    setAvailableDevices(devices);
  };

  useEffect(() => {
    /* This use effect check when availableDevices changes that we have a default video and audio source in vonage
     * If not, we take the first device and we set it as current source */
    const { audioSourceId, videoSourceId } = vonage;
    const getDeviceId = (search) =>
      availableDevices.find(({ kind }) => kind === search)?.deviceId || null;
    if (availableDevices?.length) {
      const audioSource = audioSourceId
        ? audioSourceId
        : getDeviceId("audioInput");
      const videoSource = videoSourceId
        ? videoSourceId
        : getDeviceId("videoInput");
      setSources({ audioSource, videoSource });
    }
  }, [availableDevices]);

  const [canShareScreen, setCanShareScreen] = useState(null);

  const shareScreenCheck = async () => {
    const canShare = await vonage.checkScreenSharingCompatibility();
    setCanShareScreen(canShare === true);
  };

  const updateDevice = (id, type) => {
    /* This method update a device from the video and audio selector */
    const newSources = { ...sources, [type]: id };
    vonage.audioSourceId = newSources.audioSource;
    vonage.videoSourceId = newSources.videoSource;
    setSources(newSources);
  };

  const [stream, setStream] = useState({
    name: getVerifiedUsername(),
    hasAudio: !vonage.audioMuted,
    hasVideo: !vonage.videoMuted,
  });

  const [vonageInitError, setVonageInitError] = useState(null);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    if (
      !availableDevices.length &&
      (permissions?.camera || permissions?.microphone)
    ) {
      (async () => {
        await getDevices();
      })();
    }
  }, [permissions]);

  const enableJoin = () => {
    setVonageOpened(true);
  };

  useEffect(() => {
    vonage.on(VonageSDK.EVENTS.sessionOpened, enableJoin);
    shareScreenCheck();
    (async () => {
      const permissions = await vonage.getPermissions();
      setPermissions(permissions);
    })();
  }, [hasDevices]);

  const openHowTo = () => {
    window.open(
      _t("https://www.wisembly.com/connectivity/index.html"),
      "_blank"
    );
  };

  const grantedPermissions = (result) => {
    if (!hasDevices && result === true) return setDevices(true);
    // TODO: use proper Modal component
    if (result.name === "OT_USER_MEDIA_ACCESS_DENIED") {
      setPermissions({ canPrompt: false, camera: false, microphone: false });
    }
  };

  useEffect(() => {
    emitter.on(VonageSDK.EVENTS.grantedPermission, grantedPermissions);
    return () => {
      emitter.off(VonageSDK.EVENTS.grantedPermission, grantedPermissions);
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={clsx("Preview", classes.wrapper)}
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        className={clsx("PreviewContent")}
      >
        {permissions.canPrompt && <CameraOnboarding vonage={vonage} />}
        {!permissions.canPrompt && (
          <>
            {permissions.camera && (
              <CameraPreview
                vonage={vonage}
                setStream={setStream}
                stream={stream}
                sources={sources}
                setOwnPublisher={setOwnPublisher}
                setVonageInitError={setVonageInitError}
                ownPublisher={ownPublisher}
              />
            )}
            {
              // prettier-ignore
              !permissions.camera && (
              <CameraErrorMessage
                icon={<VideocamOffIcon />}
                title={_t("You have not allowed access to your camera")}
                subtitle={_t("Please check that the camera access is allowed in your browser settings")}
              >
                <Link href="#" onClick={openHowTo} color="inherit">
                  {_t("How to do ?")}
                </Link>
              </CameraErrorMessage>
            )
            }
            <Box
              display="flex"
              flex="1 0"
              flexDirection="column"
              className={classes.boxContent}
            >
              <Typography className={classes.title} variant="h4">
                {_t("Ready to participate?")}
              </Typography>
              <HelpSection />
              <Box mb={4} display="flex">
                <Box flex={4}>
                  <VideoSourceSelector
                    availableDevices={availableDevices}
                    sources={sources}
                    onChange={updateDevice}
                    canSelect={permissions.camera}
                  />
                  <AudioSourceSelector
                    availableDevices={availableDevices}
                    sources={sources}
                    onChange={updateDevice}
                    canSelect={permissions.microphone}
                  />
                </Box>
                <Box flex={3}>
                  <MicrophoneLevel
                    publisher={ownPublisher}
                    permissions={permissions}
                  />
                </Box>
              </Box>
            </Box>
            <Box mb={1}>
              <EnterStageVideo
                canJoin={vonageOpened && permissions.canJoin}
                setPreview={setPreview}
              />
              <EnterStageScreen
                canJoin={vonageOpened}
                canShareScreen={canShareScreen}
                setPreview={setPreview}
              />
            </Box>
          </>
        )}

        <Typography
          variant="body2"
          className={classes.link}
          onClick={() => {
            setPreview(false);
          }}
        >
          {_t("Not now")}
        </Typography>
      </Box>
    </Box>
  );
};
DeviceSelector.propTypes = {
  vonage: PropTypes.object,
  setPreview: PropTypes.func,
};
DeviceSelector.defaultProps = {
  setPreview: () => {},
};

export default DeviceSelector;
