import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";


const useStyles = makeStyles((theme) => ({
 root: {
  background: "#FFFFFF",
  border: "1px solid #E2E4E8",
  boxSizing: "border-box",
  borderRadius: "8px",
  height: props => props.isMobile ? "56px" : "40px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "12px",
  width: "100%",
  height: "8em",
  fontFamily: 'Montserrat',
  "&:focus" : {
    outline: "none"
  }
 },
}));


const TextArea = ({name, onChange, value, placeholder, onBlur}) => {
  const isMobile = useSelector(mobileLayoutSelector)
  const classes = useStyles({isMobile});
  return <textarea  className={classes.root} name={name} value={value} onChange={onChange} placeholder={placeholder} onBlur={onBlur} />;
};


TextArea.propTypes = {};


TextArea.defaultProps = {};


export default TextArea;