import memoize from "lodash/memoize";
import URI from "urijs";
import dayjs from "dayjs";
import sha1 from "crypto-js/sha1";

import Session from "./session";

import { getCookie, setCookie } from "core/basil";
import { BASIL_SESSION_KEY } from "settings/config";

let session = {};

export function getUserSession() {
  const basilSession = getCookie({ key: BASIL_SESSION_KEY }) || {};

  // very important : JSON.parse() from basil render each time new session instance from localStorage
  // that way, getMemoizedSession is kinda useless :(
  // Now, we retrieve things (and store things) in Basil, but only when changed. Otherwise, get it from memory
  if (
    session.token &&
    basilSession.token &&
    basilSession.token === session.token
  )
    return session;

  const getMemoizedSession = memoize(s => new Session(s));

  session = getMemoizedSession(basilSession);

  return session;
}

export function getTToken({ url }) {
  const session = getUserSession();

  if (session.isAnon()) {
    // if the user is anon, fallback to the token way
    return { token: session.token };
  }

  let u = session.user_id;
  let t = dayjs().endOf("hour").unix();

  const path = new URI(url).pathname();

  return {
    ttoken: sha1(u + t + session.token + path),
    u: u,
    t: t,
  };
}

export function createSession(args) {
  const value = new Session(args);

  session = value;
  setCookie({ key: BASIL_SESSION_KEY, value });
  
  if (session.isAnon()) {
    setCookie({ key: "api_session.anonymous", value });
  }

  return session;
}

export function clearSession() {
  setCookie({ key: BASIL_SESSION_KEY, value: undefined });
  session = {};
}

export default Session;
