import { combineReducers } from "redux";

import applicationReducer from "applicationDucks";
import resourcesReducer from "ressourcesDucks";
import liveShoppingReducer from "liveShoppingDucks";
import visioReducer from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/reducer";
// Returns the main reducer used in the app
// We only have one reducer for now :)
export default function createReducer() {
  return combineReducers({
    application: applicationReducer,
    resources: resourcesReducer,
    liveShopping: liveShoppingReducer,
    visio: visioReducer,
  });
}
