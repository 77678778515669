import React from "react";
import { _t } from "i18n";
import { Files } from "react-bootstrap-icons";
import IconButton from "../../../reusables/IconButton";
import { productsSelector } from "app/state/ducks/ressources/selectors";
import { generateHash } from "utilities/uuid";
import { resourceCreate } from "app/state/ducks/ressources/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getKeyword } from "app/state/ducks/application/selectors";
import ResourceFactory from "orm/resources";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { getUrl } from "app/views/containers/Application/Routes";

const CloneButton = ({ hash }) => {
  const products = useSelector(productsSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const keyword = useSelector(getKeyword);
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const productToClone = products.find((p) => p.hash === hash);

  const handleClone = () => {
    const newProduct = resourceFactory.create("Product", { ...productToClone.ref, hash: generateHash(40), name: _t("Copy of ") + productToClone.ref.name });

    dispatch(
      resourceCreate(newProduct, {
        slug: ["event", keyword, "products"],
        callback: (error) => {
          if (error) {
            emitter.emit(EVENT_FLASH_ERROR, _t("An error has been detected"));
            return;
          }
          emitter.emit(EVENT_FLASH_SUCCESS, _t("The product has been cloned with success !"));
          history.push(getUrl("otm_live", { keyword }));
        },
      })
    );
  };

  return (
    <IconButton onClick={handleClone}>
      <Files />
    </IconButton>
  );
};

CloneButton.propTypes = {};

CloneButton.defaultProps = {};

export default CloneButton;
