import React from "react";
import PropTypes from "prop-types";

const CustomVolumeUpIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4681 27.6458L25.0945 29.2698L25.2393 29.1254C26.7507 27.6182 27.9493 25.8271 28.7664 23.8551C29.5833 21.8832 30.0026 19.7693 30 17.6349C30.0026 15.5005 29.5833 13.3866 28.7664 11.4147C27.9493 9.4427 26.7507 7.65163 25.2393 6.14439L25.0945 6L23.4679 7.62424L23.6133 7.76924C24.911 9.06328 25.9401 10.601 26.6415 12.294C27.3429 13.9871 27.7028 15.8023 27.7005 17.6349C27.7005 21.4879 26.138 24.9759 23.6131 27.5008L23.4681 27.6458Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.8006 24.9782L22.4231 26.6007L22.568 26.4562C23.7282 25.299 24.6483 23.924 25.2755 22.4101C25.9026 20.8963 26.2244 19.2735 26.2225 17.6349C26.2244 15.9964 25.9026 14.3736 25.2755 12.8598C24.6483 11.3459 23.7282 9.97088 22.568 8.81372L22.4231 8.6692L20.7986 10.2936L20.9439 10.4385C21.8903 11.3825 22.6409 12.5042 23.1525 13.7391C23.6641 14.9741 23.9266 16.2986 23.9249 17.6353C23.927 18.9722 23.665 20.2963 23.1537 21.5316C22.6424 22.7669 21.8921 23.8889 20.9458 24.8333L20.8006 24.9782Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8986 11.4829L19.7537 11.3384L18.1295 12.9626L18.2742 13.1075C19.4324 14.2675 20.1492 15.8682 20.1492 17.6352C20.1504 18.476 19.9854 19.3088 19.6636 20.0857C19.3419 20.8625 18.8698 21.5682 18.2745 22.162L18.1292 22.3069L19.7537 23.9313L19.8986 23.7868C20.7077 22.9798 21.3493 22.0208 21.7866 20.965C22.2239 19.9093 22.4483 18.7776 22.4468 17.6349C22.4483 16.4922 22.2239 15.3605 21.7866 14.3047C21.3493 13.2489 20.7077 12.29 19.8986 11.4829Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7364 25.1859V10.0839C16.7361 9.86768 16.6748 9.65569 16.5595 9.4728C16.4443 9.28991 16.2798 9.14324 16.0849 9.04965C15.8901 8.95606 15.6728 8.91935 15.458 8.94373C15.2432 8.96812 15.0395 9.05271 14.8706 9.1876L10.466 12.7106H6.1488C5.84412 12.7106 5.55192 12.8316 5.33647 13.047C5.12103 13.2625 5 13.5547 5 13.8594V21.4104C5 21.7151 5.12103 22.0073 5.33647 22.2227C5.55192 22.4382 5.84412 22.5592 6.1488 22.5592H10.466L14.8707 26.0823C15.0396 26.2171 15.2432 26.3016 15.458 26.326C15.6728 26.3504 15.8901 26.3137 16.0849 26.2201C16.2798 26.1265 16.4443 25.9798 16.5595 25.797C16.6748 25.6141 16.7361 25.4021 16.7364 25.1859ZM7.29759 15.0082L10.8682 15.0082C11.1289 15.0077 11.3821 14.9186 11.5854 14.7554L14.4388 12.4734V22.7964L11.5852 20.5141C11.3818 20.3511 11.1288 20.2621 10.8682 20.2616H7.29759V15.0082Z"
      />
    </svg>
  );
};

CustomVolumeUpIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomVolumeUpIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomVolumeUpIcon;
