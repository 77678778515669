import React from "react";
import { useState } from "react";
import CreateIcon from "@material-ui/icons/Create";
import Box from "@material-ui/core/Box";
import { TextField } from "appComponents/Mui";
import { EditableField } from "./MailPreviewFields";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector } from "react-redux";
import ResourceFactory from "orm/resources";

/*
* width: 100%;
    padding: 0 30px 0 10px
;*/

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "0 30px 0 10px",
    "& .MuiInputBase-root": {
      boxShadow: "none",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputAdornment-root": {
      color: "#000000",
    },
  },
}));

const MailEditTitle = ({ template, onSave }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(template.getTitle());
  const classes = useStyles();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const saveAction = (editingValue) => {
    const newTemplate = resourceFactory.create(
      "Template",
      Object.assign({}, template.ref, {
        title: value,
        send_type: template.send_type || "sending_type_customized",
      })
    );

    setEditing(editingValue);
    if (value && editingValue === false) {
      onSave(newTemplate);
    }
  };

  return (
    <Box display="flex" alignItems="center" className={classes.root}>
      <EditableField editing={editing} setEditing={saveAction}>
        <TextField
          name="message"
          fullWidth
          variant="outlined"
          value={value}
          disabled={!editing}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CreateIcon />
              </InputAdornment>
            ),
          }}
        />
      </EditableField>
    </Box>
  );
};

export default MailEditTitle;
