import React, { Component, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import emitter from "core/emitter";
import { isUser } from "utilities/access";
import VonageSDK from "utilities/vonage";
import { withWiz } from "hoc/Wiz";
import { withUser } from "hoc/User";
import { withScript } from "hoc/ScriptLoader";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import VideoPlayer from "appComponents/VideoPlayer";

import Preview from "./Preview";
import Stage from "./Stage";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
}));

/*
  This component is the main Vonage Wisembly implementation
  Loads Stage/Preview/Controls
  Handle permission checks and control on first arrival
*/
const Vonage = ({
  scriptsLoaded,
  scriptsLoadedSuccessfully,
  wiz,
  product,
  onSetFocus,
  user,
}) => {
  const classes = useStyles();
  const vonageRef = useRef(null);
  const [hasPreview, setPreview] = useState(false);
  const [vonageInstance, setVonageInstance] = useState(false);
  const initSession = () => vonageInstance.initSession();
  const [isSessionInitialized, setSessionInitialized] = useState(false);
  const dispatch = useDispatch();

  const subscribeToPublishers = () => {
    setSessionInitialized(true);
    vonageInstance.subscribeToPublishers();
  };
  useEffect(() => {
    if (!vonageInstance) return;
    emitter.on(VonageSDK.EVENTS.credentials, initSession);
    emitter.on(VonageSDK.EVENTS.sessionOpened, subscribeToPublishers);
    setTimeout(() => setPreview(true), 500);
    return () => {
      vonageInstance.unsubscribeToPublishers();
      emitter.off(VonageSDK.EVENTS.credentials, initSession);
      emitter.off(VonageSDK.EVENTS.sessionOpened, subscribeToPublishers);
      vonageInstance.session.disconnect();
    };
  }, [vonageInstance]);

  useEffect(() => {
    if (!scriptsLoadedSuccessfully) return;

    if (!window.OT) {
      console.error("[Vonage] Unable to load OT js external lib");
      return;
    }

    const vonage = new VonageSDK(window.OT, dispatch, wiz);
    console.log("[Vonage] init");
    // get current session credentials to be a publisher
    vonage.getCredentials(wiz);
    setVonageInstance(vonage);
  }, [scriptsLoaded, scriptsLoadedSuccessfully]);
  if (!vonageInstance) return null;
  return (
    <Box
      display="flex"
      flex="1 auto"
      className={clsx("Vonage", classes.root)}
      ref={vonageRef}
    >
      {hasPreview && (
        <Preview
          vonage={vonageInstance}
          parentRef={vonageRef}
          setPreview={setPreview}
        />
      )}

      <Stage
        wiz={wiz}
        vonage={vonageInstance}
        product={product}
        setPreview={setPreview}
        parentRef={vonageRef}
        onSetFocus={onSetFocus}
        user={user}
      />
    </Box>
  );
};

Vonage.propTypes = {
  scriptsLoaded: PropTypes.bool,
  scriptsLoadedSuccessfully: PropTypes.bool,
  wiz: PropTypes.object,
  product: PropTypes.string,
  onSetFocus: PropTypes.func,
  user: PropTypes.object,
};

// this util here ensure to lazy-load the opentok lib only when needed
// regular user will load the HLS video player
class WisemblyVonage extends Component {
  // re-render only if user changed to switch from HLS to studio or reciprocally
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.user, this.props.user);
  }

  render() {
    const { wiz, product, onSetFocus, user } = this.props;
    // for user- on Live Event render HLS video player

    if (isUser("user-") && product === "wisembly_webinar")
      return (
        <VideoPlayer
          src={wiz.live_media.url}
          type="application/x-mpegURL"
          autoPlay
          noControls
        />
      );

    // for speakers, load vonage 3rd party lib and load Stage
    const scriptUri = "https://static.opentok.com/v2/js/opentok.min.js";
    const WrappedComponent = compose(
      withWiz,
      withScript([scriptUri], { cache: true })
    )(Vonage);

    return (
      <WrappedComponent product={product} onSetFocus={onSetFocus} user={user} />
    );
  }
}

WisemblyVonage.propTypes = {
  user: PropTypes.object,
  wiz: PropTypes.object,
  onSetFocus: PropTypes.func,
};

export default withUser(WisemblyVonage);
