import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Input, Typography, Button } from "@material-ui/core";
import clsx from "clsx";
import { _t } from "i18n";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "row",
  },
  rowContainer: {
    display: "flex",
    flex: "1",
    cursor: "pointer",
    alignItems: "center",
  },
  row: {
    display: "flex",
    flex: "1",
  },
  index: {
    marginRight: "16px",
    fontSize: "22px",
    fontWeight: "bold",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
  },
  input: {
    display: "flex",
    flex: "1",
    margin: "10px 5px",
  },
  button: {
    marginLeft: "16px",
    padding: "5px 15px",
    background: theme.palette.colors.primary,
    borderRadius: "99px",
    color: "white",
    "&:hover": {
      color: "black",
    },
  },
}));

export default function ChapterButton({ index, chapter, onChange, onDelete }) {
  const classes = useStyles();
  const timestamp = chapter?.timestamp || "";

  function formatTime(timeStamp) {
    if (!timeStamp) return "00:00";
    const minutes = Math.floor(timeStamp / 60);
    const seconds = Math.floor(timeStamp - minutes * 60);

    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  function toSeconds(string) {
    const parts = string.split(":");

    return parseInt(parts[0] * 60, 10) + parseInt(parts[1], 10);
  }

  function onChangeState(value, type) {
    if (!value) return;
    if (type === "timestamp") value = toSeconds(value);
    onChange({ ...chapter, [type]: value });
  }

  return (
    <Box className={clsx("Root", classes.root)}>
      <Box className={clsx("RowContainer", classes.rowContainer)}>
        <Typography className={clsx("Index", classes.index)}>{index + 1}</Typography>
        <Box className={clsx("RowContainer", classes.inputContainer)}>
          <Box className={clsx("RowContainer", classes.row)}>
            <Input
              id="timeStamp"
              placeholder="mm:ss"
              className={clsx("RowContainer", classes.input)}
              defaultValue={formatTime(chapter?.timestamp)}
              onBlur={(e) => onChangeState(e?.target?.value, "timestamp")}
            />
            <Input className={clsx("RowContainer", classes.input)} value={chapter?.title} placeholder={_t("Title")} onChange={(e) => onChangeState(e?.target?.value, "title")} />
          </Box>
          <Input className={clsx("RowContainer", classes.input)} value={chapter?.short} placeholder={_t("Short description (optionnal)")} onChange={(e) => onChangeState(e?.target?.value, "short")} />
        </Box>
        <Box className={clsx("RowContainer", classes.inputContainer)} style={{ flex: "unset" }}>
          <Button className={clsx("Button", classes.button)} onClick={() => onDelete()}>
            {_t("Delete")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

ChapterButton.propTypes = {
  index: PropTypes.number,
  chapter: PropTypes.object,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

ChapterButton.defaultProps = {
  chapter: {
    hash: "",
    short: "",
    timestamp: 0,
    title: "",
  },
};
