import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { resourceCreate } from "app/state/ducks/ressources/actions";
//import { setError } from "app/state/ducks/application/actions";
import ResourceFactory from "orm/resources";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import ThumbDownOutlined from "@material-ui/icons/ThumbDownOutlined";
import ThumbUpOutlined from "@material-ui/icons/ThumbUpOutlined";
import IconTextButton from "appComponents/Mui/IconTextButton";
import { FormIcon } from "appComponents/Viewers";
import emitter from "core/emitter";
import { TOOLTIP_INTERACTIVITY } from "core/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: (props) => (props.isThumbnailView ? "100%" : "auto"), //maybe limit to thumnail
    backgroundColor: theme.palette.background.secondaryBox,
    padding: (props) => (props.isThumbnailView ? 0 : theme.spacing(2)),
    justifyContent: (props) =>
      props.isThumbnailView ? "center" : "flex-start",
    borderRadius: (props) => (props.isThumbnailView ? 0 : theme.spacing(2)),
    overflow: "auto",
  },
  icon: {
    display: (props) => (props.isThumbnailView ? "flex" : "none"),
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },

  owner: {
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    color: theme.wisemblyColors.grey,
    margin: theme.spacing(1, 1, 3, 2),
    borderTopLeftRadius: theme.spacing(3.75),
    "& .MuiTypography-root": {
      lineHeight: "1.25",
      fontWeight: "bold",
      [theme.breakpoints.down("xs")]: {
        fontSize: "10px",
      },
      [theme.breakpoints.up("sm")]: {
        fontSize: (props) => (props.layoutMode == "split" ? "12px" : "20px"),
      },
    },
    //padding: theme.spacing(1, 2),
    //borderTopLeftRadius: theme.spacing(3.5),
  },
  quote: {
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    color: theme.palette.text.primary,
    margin: theme.spacing(1, 1, 2, 2),
    height: (props) => (props.isThumbnailView ? "100%" : "auto"),
  },
  thumbnailQuote: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: "3",
    WebkitBoxOrient: "vertical",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 9px 9px 6px",
  },
  quoteText: {
    fontWeight: "normal",
    letterSpacing: " 1.95px",
    lineHeight: "1.28",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: (props) =>
        props.isThumbnailView
          ? "12px"
          : props.layoutMode == "split"
          ? "20px"
          : "40px",
    },
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  contentRightFooter: {
    marginLeft: "auto",
    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  iconButton: {
    minWidth: "auto",
    lineHeight: "1",
  },
  noReaction: {
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(0),
    },
  },
  reactions: {
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    marginLeft: theme.spacing(2),
    "& > .reactionSize": {
      minWidth: theme.spacing(5.25),
    },
    "& > .reactionSize:first-child": {
      marginRight: theme.spacing(1.25),
    },
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(0.875),
    },
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
}));

function getReaction(reaction, isFilled) {
  switch (reaction) {
    case "thumbs_up":
      if (isFilled) {
        return <ThumbUp fontSize="small" />;
      }
      return <ThumbUpOutlined fontSize="small" />;

    case "thumbs_down":
      if (isFilled) {
        return <ThumbDown fontSize="small" />;
      }
      return <ThumbDownOutlined fontSize="small" />;
  }
}

const Message = ({
  quote,
  isThumbnailView,
  resourceCreate,
  resourceFactory,
  layoutMode,
}) => {
  const classes = useStyles({ isThumbnailView, layoutMode });
  useEffect(() => {
    emitter.emit(TOOLTIP_INTERACTIVITY, quote.quote);
  }, [quote.quote]);
  //const [openComments, setOpenComments] = useState(false);
  const toggleLikeQuote = (reaction) => () => {
    const newReaction = resourceFactory.create("Reaction", { reaction });

    resourceCreate(newReaction, {
      slug: ["event", quote.event.keyword, "quotes", quote.hash, "react"],
      // callback: (error, response) => {
      //   if (error) {
      //     dispatch(setError(error));
      //   }
      // },
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius={16}
      className={clsx("Message", classes.root)}
    >
      <Box className={classes.icon}>
        <FormIcon
          type="quote"
          isThumbnailView={isThumbnailView}
          layoutMode={layoutMode}
        />
      </Box>
      <Box display="flex" className={clsx(classes.quote)}>
        <Typography
          className={clsx(
            classes.quoteText,
            isThumbnailView ? classes.thumbnailQuote : ""
          )}
        >
          {quote.quote}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        className={classes.owner}
      >
        <Typography variant="h5">{quote.getOwnerName()}</Typography>
      </Box>
      <Box
        flexDirection="row"
        justifyContent="flex-start"
        className={classes.reactions}
      >
        {["thumbs_up", "thumbs_down"].map((reaction, i) => (
          <Box key={i} className={"reactionSize"}>
            <IconTextButton
              disableRipple
              color={
                quote.user_reaction && quote.user_reaction === reaction
                  ? "secondary"
                  : "default"
              }
              key={i}
              startIcon={getReaction(
                reaction,
                quote.user_reaction && quote.user_reaction === reaction
              )}
              onClick={toggleLikeQuote(reaction)}
              classes={{
                root: clsx(
                  classes.iconButton,

                  !Object.prototype.hasOwnProperty.call(
                    quote.reactions,
                    reaction
                  )
                    ? classes.noReaction
                    : null
                ),
              }}
            >
              {quote.reactions[reaction] && ` ${quote.reactions[reaction]}`}
            </IconTextButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

Message.propTypes = {
  quote: PropTypes.object,
  resourceCreate: PropTypes.func,
  resourceFactory: PropTypes.func,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
};
const mapStateToProps = (state) => {
  return {
    resourceFactory: new ResourceFactory(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resourceCreate: bindActionCreators(resourceCreate, dispatch),
  };
};
Message.propTypes = {
  quote: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
