import { call, put } from "redux-saga/effects";
import dayjs from "dayjs";

import find from "lodash/find";

import makeRequest from "core/makeRequest";

import { createSession } from "core/session";
import { sessionSet } from "applicationDucks/actions";
import { setResources } from "ressourcesDucks/actions";

export function setSessionUser(session, user = null) {
  if (!user) return session;
  session.user_id = user.attributes.id;
  session.email = user.attributes.email;
  session.username = user.attributes.name;
  session.is_root = user.attributes.is_root;
  session.lang = user.attributes.lang;
  session.is_verified = user.attributes.is_verified;
  return session;
}

export default function* makeCallAndUpdate(url, body, options, refresh_token = null) {
  const response = yield call(makeRequest, url, body, options);
  const { resources, included, meta } = response;

  const rawSession = resources[0];
  let newSession = rawSession.attributes;

  newSession.id = rawSession.id;
  newSession.type = rawSession.type;
  newSession.refresh_token = refresh_token || rawSession.attributes.refresh_token;
  newSession.refreshed_at = dayjs().format();

  newSession = createSession(
    setSessionUser(
      newSession,
      find(included, o => o.type === "user")
    )
  );

  // store included resources to redux (USER, LICENCE, etc..)
  yield put(setResources(resources, included, meta));
  yield put(sessionSet(newSession));

  options && options.callback && options.callback();
}
