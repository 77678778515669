import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import CameraVideo from "react-bootstrap-icons/dist/icons/camera-video";
import Mic from "react-bootstrap-icons/dist/icons/mic";
import { isUser } from "utilities/access";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    zIndex: 1,
    padding: "0px 35px",
  },
  logos: {
    display: "flex",
    gap: "16px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    textAlign: "center",
    marginTop: "20px",
  },
  text: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "150%",
    color: "#FFFFFF",
    textAlign: "center",
    marginTop: "4px",
  },
  button: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "16px 32px",
    height: "48px",
    marginTop: "20px",
  },
  typoButton: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "100%",
    color: theme.palette.colors.white,
  },
}));

const RequestPermissions = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {isUser("user+") && <Box className={classes.logos}>
        <CameraVideo size={32} />
        <Mic size={32} />
      </Box>}
      <Typography style={isUser("user+") ? { color: "#000000" } : { color: "#FFFFFF" }} className={classes.title}>{_t("Please, authorize access to your camera and your microphone")}</Typography>
      {!isUser("user+") && <Typography className={classes.text}>{_t("By default, your camera will be hidden from the vendor")}</Typography>}
      {isUser("user+") && <Button clsName={classes.button} onClick={onClick}>
        <Typography className={classes.typoButton}>{_t("Authorize")}</Typography>
      </Button>}
    </Box >
  );
};

RequestPermissions.propTypes = {};

RequestPermissions.defaultProps = {};

export default RequestPermissions;
