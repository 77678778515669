import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { _t } from "core/i18n";
import ResourceFactory from "orm/resources";
import { resourceCreate } from "ressourcesDucks/actions";
import emitter from "core/emitter";
import { EVENT_SEND_NOTIFICATION } from "core/emitter/events";
import { CONNECT } from "app/liveShopping/backoffice/otm/containers/Router/routes";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "coreUiKit/inputs/Button";
import TextInput from "app/liveShopping/backoffice/otm/components/reusables/OutlinedTextField";
import EyeSlash from "react-bootstrap-icons/dist/icons/eye-slash";
import Eye from "react-bootstrap-icons/dist/icons/eye";
import Icon from "coreUiKit/inputs/Icon";
import PasswordValidation from "app/liveShopping/backoffice/otm/components/Connect/Register/PasswordValidation";

import {
  validate,
  TYPE_SIGNIN,
  TYPE_CONFIRM_PWD,
  TYPE_FORGOT_PWD,
} from "app/liveShopping/backoffice/otm/containers/Connect/authentication";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "400px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  title: {
    marginBottom: "8px",
  },
  subtitle: {
    marginBottom: "50px",
  },
  label: {
    marginBottom: "8px",
  },
  input: {
    marginBottom: "30px",
  },
}));

const ConfirmPassword = ({ token, handleSetType }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const [displayPwd, setDisplayPwd] = useState(false);
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validateOnChange: false,
    validate: (values) => validate(values, TYPE_CONFIRM_PWD),
    onSubmit: (values) => {
      const { password } = values;
      const user = resourceFactory.create("User");
      dispatch(
        resourceCreate(user, {
          slug: ["user", "lost-password", "confirm"],
          inject: [
            { path: "data.attributes.password", value: password },
            { path: "data.attributes.token", value: token },
          ],
          silent: true,
          callback: (error) => {
            if (error) {
              formik.setSubmitting(false);

              if (error.statusText !== "token_expired") {
                emitter.emit(EVENT_SEND_NOTIFICATION, {
                  type: "alert",
                  text: error.statusText,
                  button: false,
                  duration: 3,
                  hideTimer: true,
                });
                return;
              }

              emitter.emit(EVENT_SEND_NOTIFICATION, {
                type: "alert",
                /* prettier-ignore */
                text: _t("The link to reset your password has expired, please fill out the form again."),
                button: false,
                duration: 3,
                hideTimer: true,
              });
              history.push(CONNECT);
              handleSetType(TYPE_FORGOT_PWD);
              return;
            }

            emitter.emit(EVENT_SEND_NOTIFICATION, {
              type: "success",
              /* prettier-ignore */
              text: _t("Your password has been reset, you can now login with it."),
              button: false,
              duration: 3,
              hideTimer: true,
            });
            history.push(CONNECT);
            handleSetType(TYPE_SIGNIN);
          },
        })
      );
    },
  });

  return (
    <Box className={clsx("ConnectConfirmPassword", classes.root)}>
      <Typography variant="title13" className={classes.title}>
        {_t("Resetting the password")}
      </Typography>
      <Typography
        variant="body15"
        color="greyDark"
        className={classes.subtitle}
      >
        {_t("You can now set a new password to log in.")}
      </Typography>
      <Typography variant="bodySB13" className={classes.label}>
        {_t("Your new password")}
      </Typography>
      <Box className={classes.input}>
        <TextInput
          name={"password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          type={displayPwd ? "text" : "password"}
          fullWidth
          required
          placeholder={_t("Enter a new password")}
          errorMessage={formik.errors?.password}
          error={formik.errors?.password}
          size="medium"
          endAdornment={
            <Icon
              IconComponent={displayPwd ? Eye : EyeSlash}
              size={16}
              color="greyDark"
              onClick={() => setDisplayPwd(!displayPwd)}
            />
          }
        />
        <PasswordValidation password={formik.values.password} />
      </Box>
      <Button
        fullWidth
        size="medium"
        color="primary"
        onClick={formik.handleSubmit}
      >
        {_t("Validate")}
      </Button>
    </Box>
  );
};

ConfirmPassword.propTypes = {
  token: PropTypes.string.isRequired,
  handleSetType: PropTypes.func,
};

export default ConfirmPassword;