import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import Box from "@material-ui/core/Box";
//import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import { EntryChoice } from "appComponents/SurveyForms";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },

  content: {},

  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
}));

const UserGridSurveyItemResult = ({ surveyItemAnswer, surveyItem }) => {
  const classes = useStyles();

  const choices = get(surveyItem, "options.choices", []);
  let entries = get(surveyItem, "options.entries", []);
  const summary = surveyItemAnswer.value || {};

  const result = (
    <Box className={classes.container}>
      {map(entries, (entry) => (
        <EntryChoice
          key={entry.hash}
          onChoice={() => {}}
          entry={entry}
          choices={choices}
          value={summary[entry.hash]}
        />
      ))}
    </Box>
  );

  return result;
};
UserGridSurveyItemResult.propTypes = {
  surveyItem: PropTypes.object,
  surveyItemAnswer: PropTypes.object,
};

export default UserGridSurveyItemResult;
