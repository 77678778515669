import React, { useEffect, useState } from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import PropTypes from "prop-types";
import themeData from "./theme.json";

import Viewport from "app/views/styles/viewport";
import { connect } from "react-redux";
import { hexToRGB } from "utilities/utils";

const themeName = "Wisembly next theme ";

//custom hook to calculate the good vh to use instead of 100vh
//limit this only to mobile
const useViewInnerHeigh = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [vh, setVh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile) {
        //for mobile update for each change in height for now
        setVh(window.innerHeight * 0.01);
      }
    };
    window.addEventListener("resize", handleResize);

    // cleanup this component
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [vh, isMobile]);
  return vh;
};

const defaultPalette = () => {
  return {
    primary: "#07698D",
    secondary: "#07698D",
    secondaryFocus: "#07698D",
    colorButton: "#000000",
    surface1: "#f5f5f5",
    surface2: "#FFFFFF",
    border: "#000000",
    alert: "#E25C5B",
    valid: "#46B489",
  };
};

export default responsiveFontSizes(createTheme({ ...themeData, themeName }));

export const getTheme = (vh) => {
  const palette = { ...defaultPalette(), ...{} };
  return responsiveFontSizes(
    createTheme({
      ...themeData,
      themeName,
      typography: {
        fontFamily: "Montserrat",
      },
      vh,
      palette: {
        colors: palette,
        type: "light",
        shadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.1)",
        border: {
          default: hexToRGB(palette.border, "0.1"),
          divider: "#FFFFFF",
          textField: "#CED4DA",
          default20: hexToRGB(palette.border, "0.2"),
          default30: hexToRGB(palette.border, "0.3"),
          default40: hexToRGB(palette.border, "0.4"),
          default50: hexToRGB(palette.border, "0.5"),
        },
        background: {
          default: palette.surface1,
          box: palette.surface2,
          dark50: "rgba(0, 0, 0, 0.5)",
          secondaryBox: hexToRGB(palette.secondary, "0.1"),
          gradient:
            "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 73.44%, rgba(255, 255, 255, 0) 100%)",
        },
        text: {
          primary: "#000000",
          disabled: "#a6bdcb",
          lightContrast: "rgba(0, 0, 0, 0.5)",
          black: "#000000",
          darkContrast: "rgba(255, 255, 255, 0.4)",
        },
        error: {
          main: "#E25C5B",
        },
        secondary: {
          main: palette.primary,
          contrastText: "#FFFFFF",
          overlay: "rgba(0,0,0,0.2)",
        },
        button: {
          one: {
            background: {
              enable: "#2997FF",
              disable: "#2997FF",
              focus: "#2997FF",
              hover: hexToRGB("#2997FF", "0.8"),
            },
            color: {
              default: "#FFFFFF",
              focus: "#FFFFFF",
              outlined: "#2997FF",
            },
            opacity: {
              disabled: 0.5,
            },
            ripple: {
              background: "rgba(0, 0, 0, 0.2)",
            },
            border: "2px solid #2997FF",
          },
          default: {
            background: {
              enable: hexToRGB(palette.secondary || palette.primary, 0.1),
              disable: hexToRGB(palette.secondary || palette.primary, 0.1),
              focus: palette.primary,
              hover: hexToRGB(palette.secondary || palette.primary, 0.2),
              off: "rgb(204, 204, 204)",
            },
            color: {
              enable: palette.secondary || palette.primary,
            },
          },
          primary: {
            background: {
              enable: palette.primary,
              disable: palette.primary,
              focus: palette.primary,
              hover: palette.primary,
            },
            color: {
              default: "#FFFFFF",
              focus: "#FFFFFF",
            },
            opacity: {
              disabled: 0.5,
            },
            ripple: {
              background: "rgba(0, 0, 0, 0.2)",
            },
          },
          secondary: {
            background: {
              enable: hexToRGB(palette.secondary, "0.1"),
              disable: hexToRGB(palette.secondary, "0.1"),
              focus: palette.secondaryFocus,
              hover: hexToRGB(palette.secondary, "0.2"),
            },
            color: {
              default: "#07688D",
              focus: "#FFFFFF",
            },
            opacity: {
              disabled: 0.5,
            },
            ripple: {
              /* Secondary has no ripple */
              background: "rgba(0, 0, 0, 0)",
            },
          },
          tertiary: {
            background: {
              enable: hexToRGB(palette.secondary, "0"),
              disable: hexToRGB(palette.secondary, "0.1"),
              focus: hexToRGB(palette.secondary, "0"),
              hover: hexToRGB(palette.secondary, "0.1"),
            },
            color: {
              default: palette.primary,
              focus: palette.primary,
              inverted: palette.primary,
            },
            opacity: {
              disabled: 0.5,
            },
            ripple: {
              /* Secondary has no ripple */
              background: "rgba(0, 0, 0, 0)",
            },
          },
          alert: {
            background: {
              enable: palette.alert,
              disable: palette.alert,
              focus: palette.alert,
              hover: palette.alert,
            },
            color: {
              default: "#FFFFFF",
              focus: "#FFFFFF",
            },
            opacity: {
              disabled: 0.5,
            },
            ripple: {
              background: "rgba(0, 0, 0, 0.2)",
            },
            border: `2px solid ${palette.alert}`,
          },
          valid: {
            background: {
              enable: palette.valid,
              disable: palette.valid,
              focus: palette.valid,
              hover: palette.valid,
            },
            color: {
              default: "#FFFFFF",
              focus: "#FFFFFF",
            },
            opacity: {
              disabled: 0.5,
            },
            ripple: {
              background: "rgba(0, 0, 0, 0.2)",
            },
            border: `2px solid ${palette.valid}`,
          },
        },
      },
    })
  );
};

const Wrapper = ({ children }) => {
  //inspired from this https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  const vh = useViewInnerHeigh();
  const theme = React.useMemo(() => getTheme(vh), []);

  return (
    <ThemeProvider theme={theme}>
      <Viewport />
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
};

export const ThemeWrapper = Wrapper;
