import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Profiler,
} from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import get from "lodash/get";

import { useSelector, useDispatch } from "react-redux";
import { resourcesFetch } from "ressourcesDucks/actions";
import {
  currentWizAccess,
  currentWizSelector,
} from "ressourcesDucks/selectors";

import {
  getFetchResultStatusSelector,
  getKeyword,
} from "applicationDucks/selectors";
import {
  EVENT_PARTICIPANT_USER_COUNT,
  EVENT_ADMIN_USER_COUNT,
} from "utilities/emitter/events";
import emitter from "utilities/emitter";

import UserTable from "adminComponents/UserManagement/UserTable";
const logTimes = (id, phase, actualTime, baseTime, startTime, commitTime) => {
  console.log(`${id}'s ${phase} phase:`);
  console.log(`Actual time: ${actualTime}`);
  console.log(`Base time: ${baseTime}`);
  console.log(`Start time: ${startTime}`);
  console.log(`Commit time: ${commitTime}`);
};
const Paginator = ({
  admins,
  visibleColumns,
  signature,
  signatures,
  signatureRequest,
}) => {
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const accesses = useSelector((state) => currentWizAccess(state, admins));
  const event = useSelector(currentWizSelector);
  const isLiveEvent = event.isLiveEventEnabled();
  const registrationEnabled = event.hasRegistrationEnabled();
  const [offset, setOffset] = useState(accesses.length || 0);

  const [rowPerPage, setRowPerPage] = useState(20);
  //const [meta, setMeta] = useState({});
  const participants_count = event?.extra_settings?.participants_count || 0;
  const teams_count = event?.extra_settings?.teams_count || 0;
  const contextTotal = admins ? teams_count : participants_count;

  // const fetchStatus = useSelector((state) =>
  //   getFetchResultStatusSelector(state, `event_accesses_${keyword}`)
  // );
  const users = useMemo(() => {
    return accesses.map((access) => {
      let data = Object.assign({}, get(access, "user.ref", null));
      data.access = access;
      if (signature) {
        data.signatureRequest = signatureRequest;
        if (signatures.length) {
          data.signature = signatures.filter(
            (elem) => get(elem, "user.id", null) === data.id
          )[0];
        }
      }

      return data;
    });
  }, [accesses, signature, signatures.length]);
  //Todo fetch access or access if not complete
  //Todo update access push event to add user
  //fetch all users
  useEffect(() => {
    if (accesses.length < contextTotal && offset < contextTotal) {
      setOffset(offset + rowPerPage);
    }
  }, [offset, rowPerPage, accesses.length, contextTotal]);
  const loadData = useCallback(() => {
    let query = [
      {
        key: "rights",
        value: admins ? "true" : "false",
      },
    ];
    query.push({ key: "limit", value: rowPerPage });
    query.push({ key: "offset", value: offset });
    dispatch(
      resourcesFetch("access", {
        slug: ["event", keyword, "accesses"],
        query,
        XHR_ID: `event_accesses_${keyword}`,
        silent: true,
        callback: (error, response) => {
          
          if (error) {
            return;
          }
          //  setMeta(response.meta);
        },
      })
    );
  }, [offset, rowPerPage]);
  useEffect(() => {
    if (accesses.length < contextTotal && offset < contextTotal) {
      loadData();
    }
  }, [offset, accesses.length, contextTotal]);
  // const loadMore = useCallback(
  //   (rowPerPage = 20) => {
  //     if (offset <= contextTotal) {
  //       setOffset(offset + rowPerPage);
  //     }
  //   },
  //   [offset, contextTotal]
  // );
  const refresh = useCallback(
    (rPerPage) => {
      if (rPerPage > rowPerPage) {
        setRowPerPage(rPerPage);
        setOffset(0);
      }
    },
    [rowPerPage]
  );
  // const showLoader =
  //   (isEqual(fetchStatus, "pending") || isEmpty(fetchStatus)) &&
  //   isEmpty(accesses);

  //// TODO: find a way to update on user remove
  useEffect(() => {
    emitter.emit(EVENT_PARTICIPANT_USER_COUNT, participants_count);
  }, [participants_count]);
  useEffect(() => {
    emitter.emit(EVENT_ADMIN_USER_COUNT, teams_count);
  }, [teams_count]);

  return (
    <Box className={clsx("Paginator")}>
      <UserTable
        users={users}
        admin={admins}
        total={contextTotal}
        visibleColumns={visibleColumns}
        refresh={refresh}
        isLiveEvent={isLiveEvent}
        hasRegistrationEnabled={registrationEnabled}
      />
    </Box>
  );
};
Paginator.propTypes = {
  admins: PropTypes.bool,
  visibleColumns: PropTypes.array,
  signatures: PropTypes.array,
  signature: PropTypes.bool,
  signatureRequest: PropTypes.object,
};
Paginator.defaultProps = {
  admins: false,
  visibleColumns: [],
  signatures: [],
  signature: false,
  signatureRequest: {},
};
export default Paginator;
