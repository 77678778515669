import resourcesSaga from "ressourcesDucks/sagas";

import { sessionSaga, realtimeSaga, applicationJSONSaga, fileUploadSaga, queryArgsSaga, referralSaga } from "applicationDucks/sagas";

import { cartSaga, bootstrapSaga } from "app/state/ducks/liveShopping/sagas";
import { all } from "redux-saga/effects";
// import participantsSaga from 'containers/Participants/sagas'
// import homeSaga from 'containers/Home/sagas'
// import lostPasswordSaga from 'containers/LostPassword/sagas'

import Realtime from "core/realtime";
import visioSaga from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/sagas";
import trackEventSaga from "app/state/ducks/application/sagas/trackEventSaga";

// Returns the main saga
// Containing all the sagas of our app
export default function* createSaga() {
  yield all([
    // Generic API Resource CRUD
    resourcesSaga(),

    // Handle user session, login, refresh, logout..
    sessionSaga(),

    // handle realtime stuff
    realtimeSaga(Realtime),

    // call non-jsonApi calls
    applicationJSONSaga(),

    // raw call non-json/application
    fileUploadSaga(),

    // handle specific parameters in urls to do things
    // (like accept meeting access request)
    queryArgsSaga(),

    referralSaga(),
    // ******* TO BE MERGED OR MOVED INTO CORE/SAGAS ******

    bootstrapSaga(),
    cartSaga(),
    visioSaga(),
    trackEventSaga(),

  ]);
}
