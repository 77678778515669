import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";

import Box from "@material-ui/core/Box";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {
  UserTextSurveyItemResult,
  UserGridSurveyItemResult,
  UserRatingSurveyItemResult,
  UserUniqueSurveyItemResult,
  UserMultipleSurveyItemResult,
  UserImageSurveyItemResult,
  UserRankingSurveyItemResult,
} from "appComponents/Viewers";

import { resourceSelector } from "ressourcesDucks/selectors";
import { _t } from "i18n";
import { SurveyItemTitle } from "appComponents/Viewers";
import { makeStyles } from "@material-ui/core/styles";
import basil from "core/basil";

const useStyles = makeStyles((theme) => ({
  formFieldRow: {
    //height: "100%",
    //actionPanel height theme.spacing(4.75)
    flexDirection: "column",
    //maxHeight: `calc(100vh - ${theme.spacing(17.5)}px ) `,
    paddingTop: "20px",
    //maxHeight: `calc(100% - ${theme.spacing(20.5)}px)`,
    //height: "auto",
    overflow: "auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "50px",
      paddingRight: "50px",
      // minHeight: theme.spacing(30),
    },
    [theme.breakpoints.down("xs")]: {
      flex: "auto",
      paddingLeft: "25px",
      paddingRight: "5%",
    },
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
  },
  paginationScreen: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
    // justifyContent: "center",
    height: "100%",
    // borderBottomLeftRadius: (props) => (props.isThumbnailView ? 0 : "15px"),
    // borderBottomRightRadius: (props) => (props.isThumbnailView ? 0 : "15px"),
    // borderTopLeftRadius: (props) =>
    //   props.isThumbnailView || props.layoutMode === "split" ? 0 : "15px",
    // borderTopRightRadius: (props) =>
    //   props.isThumbnailView || props.layoutMode === "split" ? 0 : "15px",
  },
  hidden: {
    display: "none",
  },
}));
const ShowSurveyItemAnswer = ({
  surveyItemAnswer,
  surveyItem,
  survey,
  isThumbnailView,
  layoutMode,
}) => {
  let result;
  const classes = useStyles({ isThumbnailView, layoutMode });
  if (isEmpty(surveyItem)) {
    return <Box>{_t("unable to get item")}</Box>;
  }

  switch (surveyItem.type) {
    case "text":
      result = (
        <UserTextSurveyItemResult
          surveyItemAnswer={surveyItemAnswer}
          surveyItem={surveyItem}
        />
      );
      break;
    case "unique":
      result = (
        <UserUniqueSurveyItemResult
          surveyItemAnswer={surveyItemAnswer}
          surveyItem={surveyItem}
          survey={survey}
        />
      );
      break;
    case "grid":
      result = (
        <UserGridSurveyItemResult
          surveyItemAnswer={surveyItemAnswer}
          surveyItem={surveyItem}
        />
      );
      break;
    case "rating":
      result = (
        <UserRatingSurveyItemResult surveyItemAnswer={surveyItemAnswer} />
      );
      break;
    case "multiple":
      result = (
        <UserMultipleSurveyItemResult
          surveyItemAnswer={surveyItemAnswer}
          surveyItem={surveyItem}
          survey={survey}
        />
      );
      break;
    case "image":
      result = (
        <UserImageSurveyItemResult
          surveyItemAnswer={surveyItemAnswer}
          surveyItem={surveyItem}
          survey={survey}
        />
      );
      break;
    case "ranking":
      result = (
        <UserRankingSurveyItemResult
          surveyItemAnswer={surveyItemAnswer}
          surveyItem={surveyItem}
          survey={survey}
        />
      );
      break;
    default:
      result = null;
  }

  return (
    <Box className={clsx("ShowSurveyItemAnswer", classes.paginationScreen)}>
      <SurveyItemTitle
        survey={survey}
        item={surveyItem}
        isThumbnailView={isThumbnailView}
        layoutMode={layoutMode}
        forResult={true}
      />
      <Box className={classes.formFieldRow}>{result}</Box>
    </Box>
  );
};

ShowSurveyItemAnswer.propTypes = {
  surveyItemAnswer: PropTypes.object,
  surveyItem: PropTypes.object,
  survey: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
};
const mapStateToProps = (state, ownProps) => {
  return {
    surveyItem: resourceSelector(state, {
      type: "Item",
      id: get(ownProps, "surveyItemAnswer.hash", null),
    }),

    //items : genericResourcesSelector(state, {type: "Item", filter: (item)=>isEqual})
  };
};
export default connect(mapStateToProps)(ShowSurveyItemAnswer);
