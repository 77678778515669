import { put, select } from "redux-saga/effects";
import { publishersSelector } from "../selectors";
import { removeElement, setPublisher } from "../actions";

export const ensureDestroyPublisher = (publisher) =>
  new Promise((resolve) => {
    publisher.on("destroyed", () => {
      console.log("destroyed!");
      resolve();
    });

    publisher.destroy();
  });

export function* destroyPublisher({ mode }) {
  console.log("[VISIO SAGA] DESTROY PUBLISHER")

  const { videoPublisher } = yield select(publishersSelector);

  try {
    const videoSource = videoPublisher.getVideoSource();
    videoSource.track.stop();
    console.log('stopped videoSource', videoSource)
  } catch (error) {
    console.log("[Visio] Unable to stop video source", error)
  }

  try {
    const audioSource = videoPublisher.getAudioSource();
    audioSource.stop();
    console.log('stopped audioSource', audioSource)
  } catch (error) {
    console.log("[Visio] Unable to stop audio source", error)
  }

  try {
    yield ensureDestroyPublisher(videoPublisher);
  } catch (error) {
    console.log("[Visio] Unable to destroy publisher", mode);
  }

  yield put(setPublisher(mode, null));
  yield put(removeElement(`tmp_${mode}`));
}
