import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { currentWizReplaySelector, currentWizSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.colors.primary,
    opacity: 1,
    color: theme.palette.colors.white,
    padding: "10px 15px",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  pinTypo: {
    fontSize: "14px",
    display: "inline-flex",
    flexWrap: "wrap",
  },
  pinAuthorTypo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "130%",
    marginRight: "5px",
    display: "flex",
    alignItems: "center",
  },
  quote: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    hyphens: "auto",
    wordBreak: "break-all",
  },
}));

const PinnedElement = ({ hasPinnedElement, quote }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const replay = useSelector(currentWizReplaySelector);
  const { logo_paths } = useSelector(currentWizSelector);
  if (!hasPinnedElement || replay?.enabled) return null;

  return (
    <Box className={classes.root}>
      <img width="36" height="36" style={{ borderRadius: "99px", marginRight: "8px" }} src={logo_paths.mobile} />
      <Box className={classes.pinTypo}>
        <Typography className={classes.quote}>{quote?.quote}</Typography>
      </Box>
    </Box>
  );
};

PinnedElement.propTypes = {};

PinnedElement.defaultProps = {};

export default PinnedElement;
