import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { _t } from "core/i18n";

import { getType } from "utilities/utils/survey";
import emitter from "core/emitter";
import { EVENT_ANIM_SURVEY_PROJECTION_DISPLAY_OPTIONS } from "core/emitter/events";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import {
  useSurveyManager,
  useLiveLeaderboard,
  useLeaderBoardManager,
} from "adminComponents/Animation/hooks";
import { useDispatch } from "react-redux";
import { updateAdminPreview } from "applicationDucks/actions";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    backgroundColor: theme.palette.secondary.contrastText,
  },
  item: {
    padding: theme.spacing(1.25, 2),
  },
  label: {
    fontSize: "14px",
  },
}));

const OptionsMenu = ({ survey, isLive, handleOnClick }) => {
  const classes = useStyles();
  const handleSurveyState = useSurveyManager(survey);
  //{surveys:[]}
  const liveLeaderBoard = useLiveLeaderboard();
  const handleLeaderboard = useLeaderBoardManager();
  const dispatch = useDispatch();
  const getOptions = () => {
    const display = {
      action: () => {
        handleSurveyState("display");
        dispatch(updateAdminPreview({}));
        //cancel preview if available
        handleOnClick();
      },
      wording: _t("Display"),
    };
    const hide = {
      action: () => {
        handleSurveyState("hide");
        handleOnClick();
      },
      wording: _t("Hide"),
    };
    const projectionDisplay = {
      action: () => {
        emitter.emit(EVENT_ANIM_SURVEY_PROJECTION_DISPLAY_OPTIONS, { survey });
        handleOnClick();
      },
      wording: _t("Display settings on the stage"),
    };
    const displayLeadboard = {
      action: () => {
        handleLeaderboard("edit_play", [survey.hash]);
        handleOnClick();
      },
      wording: _t("Display leaderBoard"),
    };
    const hideLeadboard = {
      action: () => {
        handleLeaderboard("edit_hide");
        handleOnClick();
      },
      wording: _t("Hide leaderBoard"),
    };
    const preview = {
      action: () => {
        let data = {};
        switch (survey.type) {
          case "evaluation":
          case "form":
            data = {
              displayPoll: false,

              type: "Survey",

              data: {
                parent_hash: null,
                class_name: "Survey",
                hash: survey.hash,
                preview: true,
              },
            };
            break;
          case "poll": {
            const item = survey.items.toModelArray()[0];

            if (!isEmpty(item)) {
              data = {
                displayPoll: false,

                type: "SurveyItem",

                data: {
                  class_name: "SurveyItem",
                  parent_hash: survey.hash,
                  hash: item.ref.hash,
                  preview: true,
                },
              };
            }
            break;
          }

          default:
            data = {};
        }
        dispatch(updateAdminPreview({ type: "interactivity", payload: data }));
        handleOnClick();
      },
      wording: _t("Preview (visible to me)"),
    };

    switch (survey.type) {
      case "poll":
      case "evaluation":
      case "form": {
        let toggleDisplay = isLive ? hide : display;
        let leaderBoardDisplay =
          liveLeaderBoard.surveys.length === 1 &&
          liveLeaderBoard.surveys[0] === survey.hash &&
          liveLeaderBoard.displayed === true
            ? hideLeadboard
            : displayLeadboard;
        if (
          survey.type === "poll" &&
          ["rating", "grid"].includes(getType(survey))
        ) {
          return { toggleDisplay, preview };
        }
        if (survey.type === "evaluation") {
          return {
            toggleDisplay,
            projectionDisplay,
            preview,
            leaderBoardDisplay,
          };
        }
        return { toggleDisplay, projectionDisplay, preview };
      }

      default:
        return [];
    }
  };

  const options = getOptions();

  return (
    <List
      className={clsx("OptionsMenuInteractivity", classes.list)}
      dense
      disablePadding
    >
      {Object.keys(options).map((option) => {
        const data = options[option];
        return (
          <ListItem
            key={option}
            button
            onClick={data.action}
            className={classes.item}
          >
            <ListItemText className={classes.label} primary={data.wording} />
          </ListItem>
        );
      })}
    </List>
  );
};

OptionsMenu.propTypes = {
  survey: PropTypes.object,
  isLive: PropTypes.bool,
  handleOnClick: PropTypes.func,
};

OptionsMenu.defaultProps = {
  handleOnClick: () => {},
};

export default OptionsMenu;
