import React from "react";
import Switch from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "40px",
      height: "24px",
      padding: "0px",
      display: "flex",
      alignItems: "center",
    },
    switchBase: {
      padding: "4px",
      transition: ".3s",
      "&:hover": {
        backgroundColor: "transparent",
      },
      backgroundColor: "transparent",
      color: theme.palette.colors.grey500,
      "&.MuiSwitch-colorSecondary.Mui-checked": {
        color: ({ color }) => (color ? theme.palette.colors[color] : theme.palette.colors.primary),
        backgroundColor: "transparent",
        "& + .MuiSwitch-track": {
          backgroundColor: ({ color }) => (color ? theme.palette.colors[color] + "10" : "rgba(240, 78, 55, 0.1)"),
          opacity: 1,
          border: "none",
        },
      },
      "&$checked": {
        transform: "translateX(16px)",
      },
      "&$checked $thumb": {
        backgroundColor: theme.palette.colors.primary,
      },
      "&$checked + $track": {
        backgroundColor: "rgba(0, 150, 150, 0.1)",
        opacity: 1,
      },
    },
    thumb: {
      width: "16px",
      height: "16px",
      borderRadius: "99px",
      backgroundColor: theme.palette.colors.white,
      boxShadow: "none",
    },
    track: {
      borderRadius: "99px",
      backgroundColor: theme.palette.colors.grey200,
      opacity: 1,
      transition: ".3s",
    },
    checked: {},
    focusVisible: {},
  }),
  {
    name: "OneSwitch",
  }
);

const OneSwitch = ({ checked, color, disabled, ...rest }) => {
  const classes = useStyles({ color });
  return (
    <Switch
      disabled={disabled}
      focusVisibleClassName={classes.focusVisible}
      checked={checked}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...rest}
    />
  );
};
OneSwitch.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
};
export default OneSwitch;
