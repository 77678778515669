import React, { useState } from "react";

import { _t } from "core/i18n";
import { getCookie, setCookie } from "core/basil";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    alignItems: "stretch",
    backgroundColor: "black",
    boxShadow: theme.palette.shadow,
    borderRadius: "10px",
    padding: theme.spacing(1.5, 2.5),
  },
  text: {
    display: "flex",
    flexDirection: "column",
    color: "white",
    fontSize: "12px",
    maxWidth: "425px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "200px",
    },
  },
  button: {
    alignSelf: "center",
    marginLeft: theme.spacing(2.5625),
    color: "white"
  },
  icon: {
    color: theme.palette.secondary.main,
    alignSelf: "center",
    marginRight: theme.spacing(2.5),
    width: "40px",
    height: "40px",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  link: {
    color: "white",
  },
}));

export const CookiesConsent = () => {
  const classes = useStyles();
  const location = useLocation();

  if (location.pathname.includes("visio") || location.pathname.includes("fishingrod")) {
    return null;
  }

  const [isOpen, setIsOpen] = useState(
    !(getCookie({ key: "cookies.consent" }) !== null)
  );

  const handleConsentCookies = () => {
    setCookie({
      key: "cookies.consent",
      value: true,
      conf: { expireDays: 30 * 13 },
    });

    setIsOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={isOpen}
    >
      <Box className={classes.content}>
        <Box className={classes.text}>
          {/* prettier-ignore */}
          <Typography variant="caption">
            {_t("This site uses cookies, which you accept by continuing your navigation.")}
            &nbsp;
            <a
              className={classes.link}
              target="_blank"
              href={_t("https://wisembly.zendesk.com/hc/en-us/articles/360017674700")}
            >
              {_t("More information")}
            </a>
          </Typography>

        </Box>
        <Box className={classes.button}>
          <ButtonBase
            onClick={() => handleConsentCookies()}
          >
            {_t("Ok!")}
          </ButtonBase>
        </Box>
      </Box>
    </Snackbar>
  );
};

export default CookiesConsent;
