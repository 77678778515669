import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Tab from "./Tab";
import { useDispatch, useSelector } from "react-redux";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { displayBackOfficeElement } from "app/state/ducks/liveShopping/actions";
import { useHistory } from "react-router-dom";
import ArrowLeftShort from "react-bootstrap-icons/dist/icons/arrow-left-short";
import { isRetractedSelector } from "app/state/ducks/liveShopping/selectors";
import RetractSideBar from "app/liveShopping/backoffice/oto/Component/RetractSideBar";

const useStyles = makeStyles((theme) => ({
  root: {
    width: props => props.isRetracted ? "72px" : "240px",
    borderRight: "1px solid #F0F0F4",
    height: "100%",
    position: "relative",
  },
  back: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    margin: "10px 25px",
    cursor: "pointer",
  },
  retract: {
    display: "flex",
    justifyContent: "flex-start",
    margin: props => props.solution === "otm" || props.isRetracted ? "8px 20px" : "8px 0px",
  },
  option: {
    position: "relative !important",
  },
  typoBack: {
    color: theme.palette.colors.grey500,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
  },
  head: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    height: "60px",
    borderBottom: "1px solid #F0F0F4",
    padding: props => props.solution === "otm" ? "0px" : (props.solution === "oto" || !props.solution) && props.isRetracted ? "0px" : "0px 20px",
  },

}));

const SideBar = ({ TabList, backLink, option, retracted }) => {
  const isRetracted = useSelector(isRetractedSelector);
  const solution = localStorage.getItem("solution");
  const classes = useStyles({ isRetracted, solution });
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  return (
    <Box className={classes.root}>
      <Box className={classes.head}>
        {(option && !isRetracted) && <Box className={classes.option}>{option}</Box>}
        {retracted && <RetractSideBar clsName={classes.retract} />}
      </Box>

      {backLink && (
        <Box
          className={classes.back}
          onClick={() => {
            dispatch(displayBackOfficeElement(null));
            history.push(backLink);
          }}
        >
          <ArrowLeftShort size={18} color={theme.palette.colors.grey500} />
          {!isRetracted && <Typography className={classes.typoBack}>{_t("Back to my lives")}</Typography>}
        </Box>
      )}

      {TabList.map((t) => (
        <Tab isRetracted={isRetracted} key={t.url} {...t} />
      ))}




    </Box>
  );
};

SideBar.propTypes = {};

SideBar.defaultProps = {};

export default SideBar;
