import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { isUser } from "utilities/access";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    width: "100%",
    height: "100%",
  },
}));

const CannotAccessDevices = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography style={isUser("user+") ? { color: "#000000" } : { color: "#FFFFFF" }}>
        {_t("Your browser does not allow the use of the microphone and camera. Change your browser settings to use video.")}
      </Typography>
    </Box>
  );
};

export default CannotAccessDevices;