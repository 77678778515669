import React, { forwardRef } from "react";
import { withStyles } from "@material-ui/styles";
import MuiButton from "@material-ui/core/Button";
import PropTypes from "prop-types";
import ThemedButton from "./ThemedButton";

/*
for now i only add customisation for color="secondary"&&variant="contained" combinaison  and color="success"&&variant="contained" combinaison plus label classe

i created a new fontWeight attribute to quickly customise label fontWeight
i created a new textColor attribute to quickly customise label color
i created a new height attribute to quickly customise height value for the button
usage
import Button from "appComponents/Mui/Button"

<Button color="secondary" variant="contained" fontWeight="normal" fontWeight="normal" textColor="white" height="auto" >
</Button>
*/

const CustomButton = withStyles((theme) => {
  return {
    //some customisation on root in we want in the futur
    root: (props) => {
      background: "#009696"
      return {
        [theme.breakpoints.up("sm")]: {
          whiteSpace: "nowrap",
        },
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      };
    },
    disabled: {},
    text: (props) => {
      const themeButton = ThemedButton(props, theme);
      return {
        ...themeButton,
        minWidth: 0,
      };
    },
    contained: (props) => {
      const themeButton = ThemedButton(props, theme);
      return {
        ...themeButton,
        minWidth: 0,
      };
    },
    containedSecondary: {},
    outlinedSecondary: {},
    label: {
      textAlign: "center",
      textTransform: "none",
    },
  };
})(MuiButton);

const Button = forwardRef(({ color, ...props }, ref) => {
  const params = {
    ...props,
    color,
    ...(color !== "link" ? {} : { variant: "text" }),
  };

  return <CustomButton ref={ref} {...params} />;
});

Button.displayName = "Button";

Button.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.oneOf(["primary", "secondary", "alert", "tertiary"]),
};

Button.defaultProps = {
  variant: "contained",
};

export default Button;
