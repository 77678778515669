import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { CircularProgress, Typography, useTheme } from "@material-ui/core";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    zIndex: 0,
    background: ({ backgroundImage }) => (backgroundImage ? `url("${backgroundImage}") no-repeat` : "black"),
    backgroundSize: ({ backgroundImage }) => (backgroundImage ? "cover" : "auto"),
  },
  typo: {
    color: "white",
    textAlign: "center",
    padding: "15px",
    marginBottom: "120px", // used to be above tchat on iPhone 6 resolutions,
    background: "black",
    opacity: 0.5,
  },
}));

const LoadingLayout = ({ loadedState }) => {
  const wiz = useSelector(currentWizSelector);
  const classes = useStyles({ backgroundImage: wiz?.theme?.background_image });
  const theme = useTheme();

  let shouldntSeePlayer = false;
  let message = _t("The Live is about to start, please wait a few more minutes");

  if (wiz.status === "pre_live" && !wiz?.extra_settings?.teasing_video) {
    shouldntSeePlayer = true;
    message = _t("Welcome to this live session. Please register to be notified when it will start.");
  }

  if (wiz.status === "ready_for_live" && !wiz?.extra_settings?.teasing_video) {
    shouldntSeePlayer = true;
    message = _t("The live is about to begin in a few more minutes, pleaase fasten your seatbelt!");
  }

  if (wiz.status === "awaiting_replay") {
    shouldntSeePlayer = true;
    message = _t("The live just ended, the replay will be available soon.");
  }

  if (shouldntSeePlayer) {
    return (
      <Box className={classes.root}>
        <Typography className={classes.typo}>{message}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      {loadedState === false && <CircularProgress style={{ color: theme.palette.colors.white }} />}

      {typeof loadedState === "string" && <Typography className={classes.typo}>{loadedState}</Typography>}
    </Box>
  );
};

LoadingLayout.propTypes = {};

LoadingLayout.defaultProps = {};

export default LoadingLayout;
