const ThemedButton = (props, theme) => {
  let buttonPalette;
  let fontSize;
  let paddingVertical;
  let paddingHorizontal;
  let squaredSize;
  let squaredFontSize;
  let fontWeight;
  let radius;
  let height = "auto";
  let width = "auto";
  let minHeight = 0;
  let minWidth = 0;

  /* Handle the color */
  switch (props.color) {
    // case "one":
    //   buttonPalette = theme.palette.button.one;
    //   break;
    case "primary":
      buttonPalette = theme.palette.button.one;
      break;
    case "secondary":
      //to define
      buttonPalette = theme.palette.button.one;
      break;
    case "tertiary":
      //to define
      buttonPalette = theme.palette.button.tertiary;
      break;
    case "alert":
      buttonPalette = theme.palette.button.alert;
      break;
    case "valid":
      buttonPalette = theme.palette.button.valid;
      break;
    default:
      buttonPalette = theme.palette.button.one;
  }
  /* Handle the size */
  switch (props.size) {
    case "large":
      fontSize = "16px";
      paddingVertical = 20;
      paddingHorizontal = 35;
      squaredSize = 34;
      squaredFontSize = "20px";
      break;
    case "small":
      fontSize = "10px";
      paddingVertical = 7;
      paddingHorizontal = 12;
      squaredSize = 24;
      squaredFontSize = "15px";
      break;
    case "xLarge":
      fontSize = "30px";
      paddingVertical = 30;
      paddingHorizontal = 40;
      squaredSize = 45;
      squaredFontSize = "30px";
      break;
    default:
    case "medium":
      fontSize = "14px";
      paddingVertical = 10;
      paddingHorizontal = 17;
      squaredSize = 24;
      squaredFontSize = "20px";
      break;
  }

  /* Handle the weight */
  switch (true) {
    case props.bold === true:
    case props.size === "large":
      fontWeight = 600;
      break;
    default:
      fontWeight = 400;
      break;
  }

  /* Handle the radius */
  switch (props.rounded) {
    case "full":
      radius = "999px";
      paddingHorizontal = paddingVertical;
      break;
    case "l":
      radius = "15px";
      break;
    case "m":
      radius = "10px";
      break;
    case "s":
    default:
      radius = "5px";
      break;
  }

  if (props.squared) {
    width = squaredSize;
    height = squaredSize;
    fontSize = squaredFontSize;
  }

  if (props.fullWidth) {
    width = "100%";
  }

  const transformPadding = (vertical, horizontal) => {
    return theme.spacing(vertical / 8, horizontal / 8);
  };

  let style = {};

  style = Object.assign(style, {
    color: buttonPalette.color.default,
    fontWeight: fontWeight,
    borderRadius: radius,
    height: height,
    fontSize: fontSize,
    width: width,
    minWidth: minWidth,
    minHeight: minHeight,
    padding: props.squared
      ? 0
      : transformPadding(paddingVertical, paddingHorizontal),

    "&:active, &:focus": {
      //backgroundColor: buttonPalette.background.focus,
      //color: buttonPalette.color.focus,
      //boxShadow: "none",
    },

    "&.MuiButtonGroup-groupedHorizontal:not(:first-child)": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    "&.MuiButtonGroup-groupedHorizontal:not(:last-child)": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "&.MuiButtonGroup-grouped": {
      minWidth: "auto",
    },
    "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
      borderRight: `1px solid ${buttonPalette.background.hover}`,
    },
  });
  if (props.variant === "outlined") {
    style = Object.assign(style, {
      border: buttonPalette.border,
      color: buttonPalette.color.outlined,
      "&:hover": {
        backgroundColor: buttonPalette.background.hover,
        boxShadow: "none",
        border: buttonPalette.border,
      },
    });
  } else {
    style = Object.assign(style, {
      backgroundColor: buttonPalette.background.enable,
      "&:hover": {
        backgroundColor: buttonPalette.background.hover,
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: buttonPalette.background.disable,
        color: buttonPalette.color.default,
        opacity: buttonPalette.opacity.disabled,
      },
    });
  }
  //controlled button handle themeselve the active && focus state with color attribute
  if (!props.controlled) {
    style = Object.assign(style, {
      "&:active, &:focus": {
        backgroundColor: buttonPalette.background.focus,
        color: buttonPalette.color.focus,
        boxShadow: "none",
      },
    });
  }
  if (props.ml) {
    style = Object.assign(style, {
      marginLeft: `${props.ml}px`,
    });
  }

  return style;
};

export default ThemedButton;
