import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Check from "react-bootstrap-icons/dist/icons/check-lg";
import Dash from "react-bootstrap-icons/dist/icons/dash";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      opacity: (props) => (props.disabled ? 0.5 : 1),
      alignItems: "center",
      height: (props) => (props.checked ? "18px" : "18px"),
      width: (props) => (props.checked ? "18px" : "18px"),
      borderRadius: "5px",
      //margin: "10px",
      color: theme.palette.colors.white,
      border: (props) =>
        props.checked ? "none" : `1px solid ${theme.palette.colors.greyMedium}`,
      backgroundColor: (props) =>
        props.checked
          ? theme.palette.colors.primary
          : theme.palette.colors.white,
    },
  }),
  {
    name: "OneCheckbox",
  }
);
//controlled checkBox
const Checkbox = ({ checked, onChange, disabled, indeterminate }) => {
  const classes = useStyles({ checked, disabled });
  return (
    <Box
      onClick={() => !disabled && onChange(!checked)}
      className={classes.root}
    >
      {checked &&
        (indeterminate ? (
          <Dash width="18px" height="18px" />
        ) : (
          <Check width="18px" height="18px" />
        ))}
    </Box>
  );
};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
};
Checkbox.defaultProps = {
  onChange: () => { },
};
export default Checkbox;
