import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import clsx from "clsx";
import CheckCircle from "react-bootstrap-icons/dist/icons/check-circle";
import CheckCircleFill from "react-bootstrap-icons/dist/icons/check-circle-fill";
import {
  PASSWORD_DIGIT_REGEX,
  PASSWORD_LETTER_REGEX,
  PASSWORD_MIN_LENGTH,
} from "core/utils";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

const useStyles = makeStyles((theme) => ({
  validators: {
    padding: "5px",
    color: theme.wisemblyColors.grey,
    display: "flex",
    flexDirection: "column",
    "& > *": {
      display: "flex",
      alignItems: "center",
    },
  },
  validator: {
    padding: "2.5px 0",
    "& > *": {
      color: theme.palette.colors.greyDark,
    },
  },
  success: {
    "& > *": {
      color: theme.palette.colors.success,
    },
  },
  iconValidator: {
    marginRight: "5px",
    width: "12px",
  },
}));

const PasswordValidation = ({ password }) => {
  const classes = useStyles();

  const hasDigit = PASSWORD_DIGIT_REGEX.test(password);
  const hasLetter = PASSWORD_LETTER_REGEX.test(password);
  const hasAuthorizedSize = password && password.length >= PASSWORD_MIN_LENGTH;

  const getIcon = (isValid) =>
    isValid ? (
      <CheckCircleFill className={classes.iconValidator} />
    ) : (
      <CheckCircle className={classes.iconValidator} />
    );

  return (
    <Box className={classes.validators}>
      <Box
        className={clsx(classes.validator, {
          [classes.success]: hasAuthorizedSize,
        })}
      >
        {getIcon(hasAuthorizedSize)}
        <Typography variant="smallBody13">
          {_t("8 characters or more")}
        </Typography>
      </Box>
      <Box
        className={clsx(classes.validator, {
          [classes.success]: hasLetter,
        })}
      >
        {getIcon(hasLetter)}
        <Typography variant="smallBody13">
          {_t("At least one uppercase")}
        </Typography>
      </Box>
      <Box
        className={clsx(classes.validator, {
          [classes.success]: hasDigit,
        })}
      >
        {getIcon(hasDigit)}
        <Typography variant="smallBody13">
          {_t("At least one digit")}
        </Typography>
      </Box>
    </Box>
  );
};

PasswordValidation.propTypes = {
  password: PropTypes.string,
};

export default PasswordValidation;