import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useSelector } from "react-redux";
import { groupsSelector } from "app/state/ducks/liveShopping/selectors";
import Group from "./Group";
import AddOptions from "./AddOption";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    margin: "auto",
    overflow: "auto",
    height: "calc(100vh - 150px)",
    padding: "10px",
    alignItems: "center",
  },
  add: {
    width: "400px",
    display: "flex",
    justifyContent: "flex-end",
  },
  groupWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
}));

const Groups = ({ }) => {
  const classes = useStyles();
  const groups = useSelector(groupsSelector);
  const currentProject = useSelector(currentProjectSelector);
  const projectGroups = groups.filter((group) => group.project.hash === currentProject?.hash);

  const user = useSelector(currentUserSelector);
  // TODO: use Project ACLs to do so..
  const hasRights = user?.license;

  return (
    <Box className={classes.root}>
      {hasRights && (
        <Box className={classes.add}>
          <AddOptions />
        </Box>
      )}

      {projectGroups.length === 0 && <Typography>{_t("No groups yet")}</Typography>}
      {projectGroups.map(({ title, hash, urls, calendar }) => (
        <Box className={classes.groupWrapper} key={hash}>
          <Group title={title} key={hash} hash={hash} urls={urls} calendar={calendar} />
        </Box>
      ))}
    </Box>
  );
};

Groups.propTypes = {};

Groups.defaultProps = {};

export default Groups;
