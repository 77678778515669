import { API_BASE } from "settings/config";
import { put } from "redux-saga/effects";
import { fetchedOnce } from "applicationDucks/actions";

import makeCallAndUpdate from "./makeCallAndUpdate";

export default function* fetchSession({
  token,
  refresh_token,
  options = {},
  data = null,
}) {
  const url = API_BASE.clone().segment("authentication").toString();
  options = Object.assign(
    { silent: true, headers: !token ? {} : { "Wisembly-Token": token } },
    options
  );

  yield makeCallAndUpdate(url, data, options, refresh_token);
  yield put(fetchedOnce('session'));
}
