import { put, select } from "redux-saga/effects";

import { setSession, setError, setStream } from "../actions";

import { CANNOT_CONNECT } from "../errors";
import { credentialsSelector, sessionSelector } from "../selectors";

import { bindToDom } from "./bindToDom";

// wrap connect method in a Promise to be able to use yield saga generator sytle
const connect = (session, token) =>
  new Promise((resolve, reject) => {
    session.connect(token, (error) => (error ? reject(false) : resolve(session)));
  });

// streams list is not a public session property
// Need to extract it with either map() or forEach() built-in method
const getInitialStreams = (session) => {
  const streams = [];
  session.streams.map((s) => streams.push(s));
  return streams;
};

// Open Vonage session for the room
// Retrieve initial streams (ppl already on stage)
// Store session into redux to bind listeners and for later use
export function* initSession() {
  console.log("[VISIO SAGA] INIT SESSION")

  if (!window.OT) {
    console.error("No OT lib found");

    return;
  }
  const { key, session, token } = yield select(credentialsSelector);
  const OTSession = yield connect(window.OT.initSession(key, session), token);

  if (!OTSession) {
    yield put(setError(CANNOT_CONNECT, "Session connect error"));
    return;
  }

  yield put(setSession(OTSession));

  const initialStreams = getInitialStreams(OTSession);
  console.log('[OT] Connected !', initialStreams);

  for (let i = 0; i < initialStreams.length; i++) {
    yield put(setStream(initialStreams[i]));
  }
}

export function* disconnectSession() {
  const session = yield select(sessionSelector);
  session.disconnect();

  console.log('[OT] Disconnected !');

  yield put(setSession(null));
}
