import React from "react";
import PropTypes from "prop-types";

const CustomChevronCompactRight = ({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      {...rest}
      className="bi bi-chevron-compact-right"
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"
      />
    </svg>
  );
};

CustomChevronCompactRight.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomChevronCompactRight.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomChevronCompactRight;
