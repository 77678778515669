import { Box } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import React from "react";
import { _t } from "utilities/i18n";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { resourceCreate } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "15px 30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const CreateAgenda = ({ hash }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleCreate = () => {
    const calendar = resourceFactory.create("Calendar", {});
    dispatch(resourceCreate(calendar, {
      slug: ["groups", hash, "calendar"],
    }))
  };

  return (
    <Box className={classes.root}>
      <Button onClick={handleCreate}>{_t("Setup my agenda")}</Button>
    </Box>
  );
};

export default CreateAgenda;
