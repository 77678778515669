import React from "react";
import PropTypes from "prop-types";

const CustomFullScreenExitIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.6909 5.50341C12.758 5.43632 12.8301 5.37534 12.9062 5.32085V5H13.9062C14.3621 5 14.7993 5.18108 15.1216 5.50341C15.4439 5.82574 15.625 6.26291 15.625 6.71875V12.4688C15.625 13.3058 15.2925 14.1086 14.7006 14.7006C14.1086 15.2925 13.3058 15.625 12.4688 15.625H6.71875C6.26291 15.625 5.82574 15.4439 5.50341 15.1216C5.18108 14.7993 5 14.3621 5 13.9062C5 13.4504 5.18108 13.0132 5.50341 12.6909C5.82574 12.3686 6.26291 12.1875 6.71875 12.1875H12.1875V6.71875C12.1875 6.26291 12.3686 5.82574 12.6909 5.50341Z" />
      <path d="M19.8784 5.50341C19.9455 5.43632 20.0176 5.37534 20.0938 5.32085V5H21.0938C21.5496 5 21.9868 5.18108 22.3091 5.50341C22.6314 5.82574 22.8125 6.26291 22.8125 6.71875V12.1875H28.2812C28.7371 12.1875 29.1743 12.3686 29.4966 12.6909C29.8189 13.0132 30 13.4504 30 13.9062C30 14.3621 29.8189 14.7993 29.4966 15.1216C29.1743 15.4439 28.7371 15.625 28.2812 15.625H22.5312C21.6942 15.625 20.8914 15.2925 20.2994 14.7006C19.7075 14.1086 19.375 13.3058 19.375 12.4688V6.71875C19.375 6.26291 19.5561 5.82574 19.8784 5.50341Z" />
      <path d="M5.50341 19.8784C5.18108 20.2007 5 20.6379 5 21.0938C5 21.5496 5.18108 21.9868 5.50341 22.3091C5.82574 22.6314 6.26291 22.8125 6.71875 22.8125H12.1875V28.2812C12.1875 28.7371 12.3686 29.1743 12.6909 29.4966C13.0132 29.8189 13.4504 30 13.9062 30C14.3621 30 14.7993 29.8189 15.1216 29.4966C15.4439 29.1743 15.625 28.7371 15.625 28.2812V22.5312C15.625 21.6942 15.2925 20.8914 14.7006 20.2994C14.1086 19.7075 13.3058 19.375 12.4688 19.375H6.71875C6.26291 19.375 5.82574 19.5561 5.50341 19.8784Z" />
      <path d="M20.2994 20.2994C19.7075 20.8914 19.375 21.6942 19.375 22.5312V28.2812C19.375 28.7371 19.5561 29.1743 19.8784 29.4966C20.2007 29.8189 20.6379 30 21.0938 30C21.5496 30 21.9868 29.8189 22.3091 29.4966C22.6314 29.1743 22.8125 28.7371 22.8125 28.2812V22.8125H28.2812C28.7371 22.8125 29.1743 22.6314 29.4966 22.3091C29.8189 21.9868 30 21.5496 30 21.0938C30 20.6379 29.8189 20.2007 29.4966 19.8784C29.1743 19.5561 28.7371 19.375 28.2812 19.375H22.5312C21.6942 19.375 20.8914 19.7075 20.2994 20.2994Z" />
    </svg>
  );
};

CustomFullScreenExitIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomFullScreenExitIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomFullScreenExitIcon;
