import EventEmitter from "eventemitter3";

import memoize from "lodash/memoize";

import logger, { addLoggerContext } from "core/logger";

import { XHR_START, XHR_DONE, XHR_FAIL, CORE_USER_LOADED } from "./events";

import { getUserSession } from "core/session";

import { listen } from "utilities/liveshopping-sdk";

const debug = logger("emitter");

class Emitter extends EventEmitter {
  constructor(args) {
    super(args);

    this.instance = Math.random();
  }

  emit(event, ...rest) {
    if (emitter.ns[event]) {
      Object.keys(emitter.ns[event]).forEach((namespacedEvent) => {
        super.emit.apply(this, [namespacedEvent, ...rest]);
      });
    }

    super.emit.apply(this, arguments);
  }

  dispatch({ type, ...rest }) {
    this.emit(type, rest);
  }
}

const getEmitter = memoize(() => {
  const emitter = new Emitter();

  // listen from sdk postMessage and forward to emitter
  listen((event) => {
    emitter.emit("postMessage", event.data);
  });

  // BroadcastChannel not supported by some browsers, deactivate gracefully this feature
  try {
    const bc = new BroadcastChannel('Liveboutique');

    bc.onmessage = (event) => {
      emitter.emit("BroadcastChannel", event.data);
    };

    emitter.bc = bc;
  } catch (e) {
    emitter.bc = {
      postMessage: () => { }
    };
  }

  emitter.ns = {};

  emitter.on("error", (args) => {
    debug.error("error", args);
  });

  emitter.on(CORE_USER_LOADED, (id, user) => {
    const session = getUserSession();
    const context = {
      id,
      email: user.email,
      token: session.token,
    };

    addLoggerContext(context);
  });

  return emitter;
});

const emitter = getEmitter();

// const addNs = (event, namespacedEvent) => {
//   emitter.ns[event] = Object.assign({}, emitter.ns[event], { [namespacedEvent]: true })
// }

// const removeNs = (event, namespacedEvent) => {
//   if (!emitter.ns[event]) {
//     return
//   }

//   delete emitter.ns[event][namespacedEvent]
// }

export function mountEvents(events, context) {
  for (const event in events) {
    const methodName = events[event];
    // const eventName = namespace ? `${namespace}:${event}` : event

    // if (namespace) {
    //   addNs(event, eventName)
    // }

    emitter.on(
      event,
      typeof methodName === "function" ? methodName : context[methodName],
      context
    );
  }
}

export function unmountEvents(events, context) {
  for (const event in events) {
    const methodName = events[event];

    emitter.off(
      event,
      typeof methodName === "function" ? methodName : context[methodName],
      context
    );
  }
}

export function simulateXHRDone(id, delay = 300) {
  emitter.emit(XHR_START, id);
  setTimeout(() => emitter.emit(XHR_DONE, id), delay);
}

export function simulateXHRFail(id, delay = 300) {
  emitter.emit(XHR_START, id);
  setTimeout(() => emitter.emit(XHR_FAIL, id), delay);
}

export default emitter;
