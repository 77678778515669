import { makeStyles, Typography } from "@material-ui/core";
import makeUrl from "app/state/ducks/ressources/sagas/makeUrl";
import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { _t } from "utilities/i18n";
import makeRequest from "utilities/makeRequest";
import VendorsTable from "./VendorsTable";

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginTop: "20px",
    marginBottom: "15px",
  },
}));
const Vendors = ({ dates, currentProject }) => {
  const classes = useStyles();

  const [performancesData, setPerformancesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchStats = async (dates) => {
    try {
      setLoading(true);
      const url = makeUrl(
        { type: "Custom" },
        {
          slug: ["stats", "project", currentProject?.id, "performances"],
          query: [{ key: "since", value: dates.since.toISOString() }, { key: "until", value: dates.until.toISOString() }],
        }
      );

      const performancesResult = await makeRequest(url, { type: "Custom" }, { method: "GET" });
      const finalPerformancesResult = Object.assign(performancesResult.resources[0].attributes.data.stats);

      setLoading(false);
      setPerformancesData(finalPerformancesResult);

    } catch (e) {
      setLoading(false);
      setPerformancesData(null);
    }
  }

  useEffect(() => {
    fetchStats(dates);
  }, [dates, currentProject]);

  return (
    <Fade left>
      <Typography className={classes.title}>{_t("Detailed statistics")}</Typography>
      <VendorsTable data={performancesData} project={currentProject} />
    </Fade>
  );
};

export default Vendors;
