import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";
import Input from "../reusables/Input";
import Button from "../reusables/Button";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { useFormik } from "formik";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import emitter from "utilities/emitter";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    margin: "16px 30px"
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginBottom: "50px"
  },
  form: {
    width: (props) => props.isMobile ? "100%" : "390px",
  },
  wrapperInput: {
    marginBottom: "16px"
  },
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "8px",
  }
}));


const AccountPage = ({ }) => {
  const user = useSelector(currentUserSelector);
  const history = useHistory();
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const onSend = () => {
    const newUser = resourceFactory.create("User", formik.values);

    dispatch(
      resourceEdit(newUser, {
        slug: ["user", user.id],
        callback: (error, response) => {
          if (error) {
            emitter.emit(EVENT_FLASH_ERROR, _t("An error has been detected"));
            return;
          }

          emitter.emit(EVENT_FLASH_SUCCESS, _t("Your account has been updated with success !"));
          setTimeout(() => window.location.reload(), 1500);
        },
      }
      ));
  };

  const formik = useFormik({
    initialValues: {
      firstname: user.firstname || "",
      lastname: user.lastname || ""
    }
  });

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("My Account")}</Typography>

      <form className={classes.form}>
        <Box className={classes.wrapperInput}>
          <Typography className={classes.label}>{_t("Firstname")}</Typography>
          <Input value={formik.values.firstname} name={"firstname"} onChange={formik.handleChange} />
        </Box>
        <Box className={classes.wrapperInput}>
          <Typography className={classes.label}>{_t("Lastname")}</Typography>
          <Input value={formik.values.lastname} name={"lastname"} onChange={formik.handleChange} />
        </Box>
        <Button style={{ float: "right" }} onClick={onSend} w100>{_t("Save")}</Button>
      </form>
    </Box>
  )
};


AccountPage.propTypes = {};


AccountPage.defaultProps = {};


export default AccountPage;