import dispatch from "../ressources/sagas/dispatch";
import {
  SESSION_SET,
  SESSION_UNSET,
  DROP_FILE,
  VERIFY_USER,
  SET_LOCALE,
  SOCKET_SET,
  LOG_OUT,
  EVENT_JOIN,
  EVENT_LEAVE,
  APPLICATION_INIT,
  FETCHED_ONCE,
  APPLICATION_JSON_CALL,
  FILE_UPLOAD_SAGA,
  CURRENT_WIZ_SET,
  CURRENT_WIZ_ERROR_SET,
  CREATE_NOTIFICATION,
  REMOVE_NOTIFICATION,
  UPDATE_REQUEST_STATUS,
  CONNECTION_SET,
  SET_TIMELINE_STATUS,
  NOTIFICATION_INCREMENT,
  NOTIFICATION_RESET,
  ANONYMOUS_USER_PARTICIPATION_SET,
  DISPLAY_POPUP_PARTICIPATION_SET,
  UPDATE_STREAM,
  USERNAME_SET,
  UPDATE_PREVIEW,
  UPDATE_CONNECTED_USERS_ACTION,
  INVALID_OTT,
  UPDATE_MODERATION,
  SET_PARTICIPATION_MODE,
  SET_CURRENT_PROJECT,
  SET_CUSTOM_THEME_COLORS,
  TRACK_EVENT,
  SET_OTO_DASHBOARD_DATES,
} from "./actionsTypes"; //or from applicationActionTypes

export const setTimelineStatus = (status) => {
  return { type: SET_TIMELINE_STATUS, status };
};
export const incrementNotification = (notification) => {
  return { type: NOTIFICATION_INCREMENT, notification };
};
export const resetNotification = (notification) => {
  return { type: NOTIFICATION_RESET, notification };
};
export const eventJoin = (keyword, id) => ({
  type: EVENT_JOIN,
  keyword,
  wizId: id,
});
export const eventLeave = (keyword) => ({ type: EVENT_LEAVE, keyword });

export const applicationInit = (session) => ({
  type: APPLICATION_INIT,
  session,
});

export const fetchedOnce = (key) => ({ type: FETCHED_ONCE, key });
export const logOut = () => ({ type: LOG_OUT });
export const sessionSet = (session) => ({ type: SESSION_SET, session });
export const sessionUnset = (session) => ({ type: SESSION_UNSET, session });
export const dropFile = (file) => ({ type: DROP_FILE, file });
export const verifyUser = (email) => ({ type: VERIFY_USER, verifyUser: email });
export const setLocale = (locale) => ({ type: SET_LOCALE, locale });
export const socketSet = (socket) => ({ type: SOCKET_SET, socket });

export const applicationJSONCall = (url, body = {}, options = {}) => {
  return { type: APPLICATION_JSON_CALL, url, body, options };
};

export const fileUploadSaga = (url, body = {}, options = {}) => {
  return { type: FILE_UPLOAD_SAGA, url, body, options };
};

export const setKeyword = (keyword) => {
  return { type: CURRENT_WIZ_SET, keyword };
};

export const setError = (error) => {
  return { type: CURRENT_WIZ_ERROR_SET, error };
};

export const createNotification = (notification) => {
  return { type: CREATE_NOTIFICATION, notification };
};

export const removeNotification = (notification) => {
  return { type: REMOVE_NOTIFICATION, notification };
};

export const setRequestStatus = ({ id, status }) => ({
  type: UPDATE_REQUEST_STATUS,
  payload: { id, status },
});

export const setConnection = (connection) => {
  return { type: CONNECTION_SET, connection };
};

export const setUserParticipation = (anonymousUserParticipation) => {
  return { type: ANONYMOUS_USER_PARTICIPATION_SET, anonymousUserParticipation };
};

export const setUsername = (username) => {
  return { type: USERNAME_SET, username };
};

export const setParticipationModeStore = (data = getDefaultParticipationMode()) => {
  return {
    type: SET_PARTICIPATION_MODE,
    payload: {
      participation_mode: data,
    },
  };
};

export const setPopupParticipationMode = (displayPopupParticipation, callback = () => { }) => {
  return {
    type: DISPLAY_POPUP_PARTICIPATION_SET,
    displayPopupParticipation,
    callback,
  };
};

export const updateStream = (stream) => {
  return { type: UPDATE_STREAM, stream };
};

export const updateModeration = (payload) => {
  return { type: UPDATE_MODERATION, payload };
};
export const updateAdminPreview = (preview = {}) => {
  return {
    type: UPDATE_PREVIEW,
    preview,
  };
};

export const getConnectedUsers = (users) => {
  return {
    type: UPDATE_CONNECTED_USERS_ACTION,
    users,
  };
};

export const setInvalidOtt = (invalid) => {
  return {
    type: INVALID_OTT,
    invalid,
  };
};

export const setCurrentProject = (project) => ({
  type: SET_CURRENT_PROJECT,
  project,
});

export const setCustomThemeColors = (primaryTextColor, primaryBackgroundColor) => ({
  type: SET_CUSTOM_THEME_COLORS,
  setCustomThemeColors: {
    primaryTextColor,
    primaryBackgroundColor
  },
});

export const trackEvent = (eventName, resource = null) => ({
  type: TRACK_EVENT,
  eventName,
  data: resource ? resource.locator : []
})

export const setOtoDashboardDates = (since, until, period) => ({
  type: SET_OTO_DASHBOARD_DATES,
  since,
  until,
  period,
});

