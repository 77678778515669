import BaseModel from "./base";
import { attr } from "redux-orm";

class TrackEvent extends BaseModel {
  constructor(args) {
    super(args);
    this.Model = TrackEvent;
  }
}

TrackEvent.modelName = "TrackEvent";
TrackEvent.backendName = "track_event";

TrackEvent.fields = {
  event_name: attr(),
  data: attr(),
};

export default TrackEvent;
