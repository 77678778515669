import memoize from "lodash/memoize";

import Segment from "./Segment";
import Analytics from "./analytics";

import { SEGMENTIO_ID } from "settings/config";

const getSegment = memoize((id) => {
  let analytics = false;

  if (!id || id.length === 0)
    return {
      track: () => {},
    };

  // call CDN snippet, instanciate it
  try {
    analytics = Analytics(id);
  } catch (error) {
    console.warn(error);
    return null;
  }

  return new Segment(id, analytics);
});

export default getSegment(SEGMENTIO_ID);
