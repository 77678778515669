import React, { useEffect } from "react";
import { makeStyles, Box } from "@material-ui/core";
import CheckCircleFill from "react-bootstrap-icons/dist/icons/check-circle-fill";
import { Bounce } from "react-reveal";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    borderRadius: "10px",
    width: "90%",
    height: "200px",
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  check: {
    color: theme.palette.colors.success,
  },
}));
const Validate = ({ setSent }) => {
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setSent(false);
    }, 3000);
  }, []);
  return (
    <Box className={classes.root}>
      <Bounce>
        <CheckCircleFill className={classes.check} size={50} />
      </Bounce>
    </Box>
  );
};

export default Validate;