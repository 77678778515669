import React, { useEffect, useState } from "react";
import { Box, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { _t } from "utilities/i18n";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector, getOwnAccessesSelector, queuesSelector, visitorsSelector } from "app/state/ducks/ressources/selectors";
import { calculateTimeOnline } from "./utils"
import { resourceFetch } from "app/state/ducks/ressources/actions";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import NotificationMessageQueue from "../Live/NotificationMessageQueue";
const useStyles = makeStyles((theme) => ({
  head: {
    background: theme.palette.colors.grey200,
  },
}));

const TableComponent = ({ handleChat, openVisitors }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const accesses = useSelector(getOwnAccessesSelector).filter((access) => access.available === true);
  const visitors = useSelector(visitorsSelector)
  const currentUser = useSelector(currentUserSelector)
  const availableVisitors = visitors?.filter(({ status, handled_at, vendor }) => (status === "waiting" || status === "ringing" || status === "ongoing") && (handled_at === null || vendor?.id === currentUser?.id));
  const dispatch = useDispatch();

  useEffect(() => {
    accesses.map((access) => {
      dispatch(
        resourceFetch("queue", {
          slug: ["queues", access.group.queue.hash, "visitors"],
          callback: () => setIsLoading(false),
        })
      );
    });
  }, []);

  return (
    // create a mui table with the following columns: hash, online for how minutes, queue, status, messages count, actions
    <Table>
      <TableHead className={classes.head}>
        <TableRow>
          <TableCell>Hash</TableCell>
          <TableCell>{_t("Online for")}</TableCell>
          <TableCell>{_t("Queue")}</TableCell>
          <TableCell>{_t("Status")}</TableCell>
          <TableCell>{_t("Messages count")}</TableCell>
          <TableCell>{_t("Actions")}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          // map over the visitors and create a table row for each visitor
          availableVisitors.map((visitor) => {
            console.log(visitor)

            return (
              <TableRow>
                <TableCell>{visitor.hash.substring(0, 8)}</TableCell>
                <TableCell>{calculateTimeOnline(visitor.created_at) + "min"}</TableCell>
                <TableCell>{visitor.queue.group.title}</TableCell>
                <TableCell>{visitor.status}</TableCell>
                <TableCell>
                  <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    {visitor.conversation.length}
                    <NotificationMessageQueue visitor={visitor} openVisitors={openVisitors} />
                  </span>
                </TableCell>
                <TableCell>
                  <Button onClick={() => handleChat(visitor.hash)}>
                    {_t("Chat")}
                  </Button>
                </TableCell>
              </TableRow>
            )
          })
        }


      </TableBody>
    </Table>
  )
};

export default TableComponent;