import {
  useSurveyContextState,
  useSurveyContextDispatcher,
} from "appComponents/SurveyForms";

export const useCurrentFieldHash = () => {
  const surveyContext = useSurveyContextState();
  const indexMap = surveyContext.indexMap;

  const currentFieldHash =
    indexMap &&
    indexMap[surveyContext.index] &&
    indexMap[surveyContext.index].hash;

  return currentFieldHash;
};
export const useFormIntroState = (surveyId) => {
  const surveyContext = useSurveyContextState();
  return surveyContext.hash === surveyId && surveyContext.showIntro;
};

export const useDispatchShowFormEnding = (surveyId) => {
  const surveyContext = useSurveyContextState();
  const surveyContextDispatch = useSurveyContextDispatcher();
  const dispatcher = () => {
    if (surveyContext.hash === surveyId && !surveyContext.showFormConclusion) {
      surveyContextDispatch({
        type: "SET_PANEL",
        panel: "userResponse",
      });
      surveyContextDispatch({
        type: "SHOW_FORM_ENDING",
        survey: surveyContext.hash,
      });
    }
  };

  return dispatcher;
};

export const useFormEndState = (surveyId) => {
  const surveyContext = useSurveyContextState();

  return surveyContext.hash === surveyId && surveyContext.showFormConclusion;
};

export const useFormSectionState = (surveyId) => {
  const surveyContext = useSurveyContextState();

  return surveyContext.hash === surveyId && surveyContext.section;
};

export const useSurveyDisplaySubmitState = (surveyId) => {
  const surveyContext = useSurveyContextState();

  return surveyContext.hash === surveyId && surveyContext.displaySubmit;
};

export const useSurveyNotifyCanNotSeeAnswerState = (surveyId) => {
  const surveyContext = useSurveyContextState();

  return surveyContext.hash === surveyId && surveyContext.notifyCanNotSeeAnswer;
};
export const useSurveyIsEmptyAnswersState = (surveyId) => {
  const surveyContext = useSurveyContextState();

  return surveyContext.hash === surveyId && surveyContext.isEmptyAnswers;
};
export const useSurveyPanelState = (surveyId) => {
  const surveyContext = useSurveyContextState();

  return surveyContext.hash === surveyId && surveyContext.panel;
};

export const useIsAnswerableFormState = (surveyId) => {
  const surveyContext = useSurveyContextState();

  return surveyContext.hash === surveyId && surveyContext.isAnswerableForm;
};
