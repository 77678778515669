import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PlusLg from "react-bootstrap-icons/dist/icons/plus-lg";
import DashLg from "react-bootstrap-icons/dist/icons/dash-lg";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  quantity: {
    width: "21px",
    fontSize: "20px",
    fontWeight: 800,
    lineHeight: "130%",
    margin: "0 10px 0 10px",
    textAlign: "center",
  },
  incrementButton: {
    width: (props) => props.size,
    height: (props) => props.size,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "none",
    alignItems: "center",
    background: theme.palette.colors.primary,
    borderRadius: "999px",
    color: theme.palette.colors.white,
    fontSize: "20px",
    "&:hover": {
      background: theme.palette.colors.primary,
    },
  },
}));

let timeout;

export const QuantityInput = ({ size, defaultValue, onChange, onDebouncedChange }) => {
  const classes = useStyles({ size });
  const [quantity, setQuantity] = useState(defaultValue || 1);

  useEffect(() => {
    setQuantity(defaultValue);
  }, [defaultValue]);

  const onChangeBy = (value) => {
    const newValue = quantity + value;
    setQuantity(newValue);
    onChange && onChange(newValue);
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => onDebouncedChange && onDebouncedChange(newValue), 1500);
  };

  return (
    <Box>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <ButtonBase className={classes.incrementButton} onClick={() => onChangeBy(-1)} disabled={quantity <= 1}>
          <DashLg />
        </ButtonBase>
        <Typography className={classes.quantity}>{quantity}</Typography>
        <ButtonBase onClick={() => onChangeBy(1)} className={classes.incrementButton}>
          <PlusLg />
        </ButtonBase>
      </Box>
    </Box>
  );
};

QuantityInput.propTypes = {
  disabled: PropTypes.bool,
  size: PropTypes.number,
};
