import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, useTheme } from "@material-ui/core";
import Select from "app/liveShopping/backoffice/otm/components/reusables/Select";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "500px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    borderRadius: "5px",
    background: "#FFFFFF",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingTop: "10px",
    paddingBottom: "20px",
  },
  header: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "10px",
  },
  img: {
    width: "150px",
    height: "150px",
    objectFit: "contain",
    borderRadius: "5px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 700,
  },
  body: {
    overflowY: "auto",
    maxHeight: "200px",
    marginBottom: "10px",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    marginTop: "10px",
  },
  outOfStock: {
    color: theme.palette.error.main,
    fontWeight: 700,
  },
}));

const Detail = ({product, embedded, variant_id, setVariantId, handleAddToCart, handleClose}) => {
  const classes = useStyles();
  const [currentVariant, setCurrentVariant] = useState(null);
  const theme = useTheme();
  
  useEffect(() => {
    if (product.variants.length) setVariantId(product.variants[0].id);
  }, [product]);

  useEffect(() => {
      setCurrentVariant(product?.variants.find((el) => el.id === Number(variant_id)));
  }, [variant_id]);

  
  const getVariantTitle = (variant) => {
    if (variant.title === "Default Title") {
      return product.title + " " + variant.price + "€";
    }
    return variant.title + " | " + variant.price + "€";
  };
  
  const getImage = (variant_id) => {
    const variantImage = product?.images.find((image) => image.variant_ids.includes(variant_id));
    
    if (variantImage) {
      return variantImage.src;
    }
    return product?.image.src;
  };
  
  const variantsOptions =  product.variants.map((variant) => ({
    name: getVariantTitle(variant),
    hash: variant.id,
  }));
  
  const handleChange = (event) => {
    setVariantId(event.target.value);
  };
  
  if (!product) return null;

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <img src={getImage(variant_id)} alt={product?.title} className={classes.img}/>
        <Typography className={classes.title}>{product.title}</Typography>
      </Box>
      <Box className={classes.body} dangerouslySetInnerHTML={{ __html: product.body_html }} />
      {(variant_id && currentVariant?.inventory_management) && currentVariant?.inventory_quantity <= 0 && <Typography className={classes.outOfStock}>{_t("Out of stock")}</Typography>}
      <Select label="Variant" value={variant_id} options={variantsOptions} onChange={(event) => handleChange(event)} />
      <Box className={classes.buttons}>
        <Button color={theme.palette.colors.grey} onClick={handleClose}>{_t("Back")}</Button>
        {currentVariant && embedded && <Button onClick={handleAddToCart} disabled={currentVariant?.inventory_management && currentVariant?.inventory_quantity <= 0}>{_t("Add to cart")}</Button>}
      </Box>
    </Box>
  );
};

export default Detail;