import React, { useEffect, useState, useRef } from "react";
import Chat from "../components/Chat";
import ModerationPanel from "./ModerationPanel";
import ChatBubbleLight from "./ChatBubbleLight";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { moderationSelector, collapseChatSelector } from "applicationDucks/selectors";
import map from "lodash/map";

import {
  VISIBLE_ELEMENT_PRESENT,
  OPEN_MESSAGES_FILTER,
  CLOSE_MESSAGES_FILTER,
  EVENT_CHAT_DISPLAY_INDICATOR_NEW_MESSAGES,
  EVENT_RESET_CHAT_UNREAD_INDICATOR,
  EVENT_CHAT_SCROLLDOWN_TRIGGER,
  EVENT_RESIZE_VISIO,
} from "utilities/emitter/events";
import emitter from "utilities/emitter";
import isEqual from "lodash/isEqual";
import _filter from "lodash/filter";
import head from "lodash/head";
import { useQuotes, usePostQuote } from "app/liveShopping/backoffice/otm/ChatModo/hooks";
import { MODERATION_COMPACT, MODERATION_TABS, MODERATION_COLUMNS, MODERATION_TABS_THREAD, MODERATION_TABS_MODERATION } from "constants";

import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: "100%",
      // padding: "5px",
      // background: theme.palette.colors.white15,
      // borderRadius: "8px",
      position: "relative",
      transition: ".3s ease-in-out",
      [theme.breakpoints.up("sm")]: {
        width: (props) => (props.collapseChatState ? "0px" : "345px"),
        marginLeft: (props) => (props.collapseChatState ? "0px" : "16px"),
        transform: (props) => (props.collapseChatState ? "translateX(24px)" : "none"),
      },
      [theme.breakpoints.down("xs")]: {
        height: (props) => (props.emptyPresent ? "100%" : "67%"), //new
        maxHeight: (props) => (props.emptyPresent ? "100%" : "67%"), //new
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
      },
      "@media (max-width:900px) and (orientation: landscape) and (max-height: 600px)": {
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        position: "absolute",
        top: 1000,
        right: "-3000px",
      },
    },
    moderation: {
      display: "flex",
      height: "100%",
      //position:"absolute",
      //right: props=>
    },
    switchModeration: {
      zIndex: 2,
      position: "absolute",
      top: 0,
      bottom: 0,
    },
    switchThread: {
      position: "absolute",
      top: 0,
      right: -1000,
    },
    compact: {
      position: "absolute",
      top: 0,
      right: -1000,
    },
    noModeration: {
      display: "none",
    },
  }),
  {
    name: "ChatContainer",
  }
);

const ChatContainer = ({ isAdmin, disabled, disabledPicture }) => {
  const [openFilter, setOpenFiler] = useState(false);
  const [filter, setFilter] = useState({});

  const isMobile = useSelector(mobileLayoutSelector);
  const moderationState = useSelector(moderationSelector);
  const [quotes, loadMore, total] = useQuotes({
    filters: filter,
    admin: isAdmin,
  });
  const lastWizRank = useRef(0);

  const [countPresentElements, setCountPresentElements] = useState(0);
  const collapseChatState = useSelector(collapseChatSelector);
  const classes = useStyles({
    emptyPresent: countPresentElements === 0,
    collapseChatState,
  });
  const elementsHashes = map(quotes, (elem) => elem.hash);
  useEffect(() => {
    // We need to setTimeout here because of the Chat animation (transition .3s ease-in-out )
    setTimeout(() => emitter.emit(EVENT_RESIZE_VISIO), 400);
  }, [collapseChatState]);

  useEffect(() => {
    const callback = (count) => {
      setCountPresentElements(count);
    };
    emitter.on(VISIBLE_ELEMENT_PRESENT, callback);
    return () => emitter.off(VISIBLE_ELEMENT_PRESENT, callback);
  }, []);

  //  const moderationQuotes = useQuotesForModeration({moderation:moderationState.activated})

  // const countNewQuotes = useCountNewQuotes({
  //   filters: {...filter, wizRank: lastWizRank.current},
  //   admin: adminChat,
  // })
  const [countNewQuotes, setCountNewQuotes] = useState(0);
  const createQuote = usePostQuote({ admin: isAdmin });

  const onClose = () => {
    setOpenFiler(false);
  };

  useEffect(() => {
    const onOpen = () => {
      setOpenFiler(true);
    };

    emitter.on(OPEN_MESSAGES_FILTER, onOpen);
    emitter.on(CLOSE_MESSAGES_FILTER, onClose);
    return () => {
      emitter.off(OPEN_MESSAGES_FILTER, onOpen);
      emitter.off(CLOSE_MESSAGES_FILTER, onClose);
    };
  }, []);

  useEffect(() => {
    //init lastWizRank
    if (lastWizRank.current === 0 && quotes.length > 0) {
      lastWizRank.current = head(quotes)?.wiz_rank;
    }
    if (lastWizRank.current) {
      const newSize = _filter(quotes, (element) => element.wiz_rank > lastWizRank.current).length;
      if (newSize !== countNewQuotes) {
        setCountNewQuotes(newSize);
      }
    }

    const refreshWizRank = () => {
      const newLastWizRank = head(quotes)?.wiz_rank;
      lastWizRank.current = newLastWizRank;
      setCountNewQuotes(0);
    };

    emitter.on(EVENT_RESET_CHAT_UNREAD_INDICATOR, refreshWizRank);
    return () => {
      emitter.off(EVENT_RESET_CHAT_UNREAD_INDICATOR, refreshWizRank);
    };
  }, [quotes.length]);

  useEffect(() => {
    // @TODO add inactif condition
    const lastQuote = head(quotes);
    const newLastWizRank = lastQuote?.wiz_rank;

    if (countNewQuotes > 0 && lastWizRank.current !== 0 && lastWizRank.current < newLastWizRank && !lastQuote?.is_mine) {
      emitter.emit(EVENT_CHAT_DISPLAY_INDICATOR_NEW_MESSAGES, {
        count: countNewQuotes,
      });
    }
  }, [countNewQuotes]);

  const onFilterChange = (data) => {
    if (!isEqual(data, filter)) {
      setFilter(data);
    }
  };

  const handlePostQuote = (quote, successCallback = () => { }, errorCallback = () => { }, onCancelCallback = () => { }) => {
    const finalCallback = () => {
      successCallback();
      emitter.emit(EVENT_CHAT_SCROLLDOWN_TRIGGER, { newQuotes: 1 });
    };
    createQuote(quote, finalCallback, errorCallback, onCancelCallback);
  };

  return (
    <Box className={clsx("ChatWrapper", classes.root)}>
      <Chat
        openFilter={openFilter}
        closeFilter={onClose}
        onFilterChange={onFilterChange}
        Element={ChatBubbleLight}
        messages={elementsHashes}
        filter={filter}
        isAdmin={isAdmin}
        fetchData={loadMore}
        total={total}
        postMessage={handlePostQuote}
        disabled={disabled}
        disabledPicture={disabledPicture}
        moderationModeColumn={moderationState.activated && moderationState.mode === MODERATION_COLUMNS}
      />
      {isAdmin && !isMobile && (
        <Box
          order={-1}
          className={clsx({
            [classes.moderation]: moderationState.activated,
            [classes.switchModeration]: moderationState.mode === MODERATION_TABS && moderationState.currentTab === MODERATION_TABS_MODERATION,
            [classes.switchThread]: moderationState.mode === MODERATION_TABS && moderationState.currentTab === MODERATION_TABS_THREAD,
            [classes.compact]: moderationState.mode === MODERATION_COMPACT,
            [classes.noModeration]: !moderationState.activated,
          })}
        >
          {moderationState.activated && <ModerationPanel />}
        </Box>
      )}
    </Box>
  );
};
ChatContainer.propTypes = {
  isAdmin: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledPicture: PropTypes.bool,
};
export default ChatContainer;
