import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Header from "app/liveShopping/frontoffice/otm/components/Catalog/Header";
import FilteredProducts from "app/liveShopping/frontoffice/otm/components/Catalog/FilteredProducts";
import LayoutWrapper from "app/liveShopping/frontoffice/otm/components/LayoutWrapper";
import { allProductsSelector, catalogIsOpenSelector, desktopLayoutSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  rootMobile: {
    display: (props) => (props.catalogIsOpen ? "flex" : "none"),
    position: "relative",
    zIndex: 2,
    width: "100%",
    height: "100%",
    background: theme.palette.colors.white,
    overflow: "hidden",
    flexDirection: "column",
  },
  rootDesktop: {
    display: (props) => (props.catalogIsOpen ? "flex" : "none"),
    width: "100%",
    height: "100%",
    background: theme.palette.colors.white,
    position: "relative",
    overflow: "hidden",
    flexDirection: "column",
    borderRadius: (props) => (props.isDesktop ? "10px 0px 0px 10px" : 0),
  },
}));
const Catalog = () => {
  const [search, setSearch] = useState("");
  const catalogIsOpen = useSelector(catalogIsOpenSelector);
  const products = useSelector(allProductsSelector);
  const divRef = useRef(null);
  const [width, setWidth] = useState(0);
  const mobileLayout = useSelector(mobileLayoutSelector);
  const isDesktop = useSelector(desktopLayoutSelector);
  const classes = useStyles({ catalogIsOpen });

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      // this callback gets executed whenever the size changes
      // when size changes get the width and update the state
      // so that the Child component can access the updated width
      for (let entry of entries) {
        if (entry.contentRect) {
          setWidth(entry.contentRect.width);
        }
      }
    });
    resizeObserver.observe(divRef.current); // observe the divRef

    // unregister the observer
    return () => resizeObserver.unobserve(divRef.current);
  }, []);

  return (
    <div ref={divRef} className={mobileLayout ? classes.rootMobile : classes.rootDesktop} >
      <Header setSearch={setSearch} />
      <FilteredProducts products={products} search={search} parentWidth={width} />
    </div>
  );
};

Catalog.propTypes = {};

export default Catalog;
