/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { connect } from "react-redux";
import React, { PureComponent, useContext, useEffect } from "react";

import dayjs from "dayjs";
require("dayjs/locale/en");
require("dayjs/locale/fr");

import PropTypes from "prop-types";

import { applicationInit } from "applicationDucks/actions";
import { Route, Switch, withRouter } from "react-router-dom";

import emitter from "core/emitter";
import { CORE_ROUTE_CHANGE } from "core/emitter/events";

import { getUserSession } from "core/session";
import CookiesConsentLiveShopping from "app/liveShopping/frontoffice/otm/components/CookiesConsent";
import { localeSelector, browserInfoSelector, getConnection, fetchedOnceSelector } from "applicationDucks/selectors";
import { userSelector } from "ressourcesDucks/selectors";

import i18n, { getLocale } from "core/i18n";
import frenchDictionnary from "assets/i18n/fr.json";

import NotFound from "appComponents/NotFound";

import Logout from "appContainers/Logout";
import Connect from "app/liveShopping/backoffice/otm/containers/Connect";
import Welcome from "app/liveShopping/backoffice/otm/components/Welcome";

import { compose } from "redux";
import get from "lodash/get";
import Layout from "liveShopping/layout.js";
import ThemeProvider from "app/liveShopping/themes/ThemeProvider";

import LiveShoppingWiz from "liveShopping";

import { renderLog } from "utilities/memo";
import Firewall from "appComponents/Firewall/Firewall";
import Mails from "app/liveShopping/backoffice/otm/containers/Mails";
import Home from "app/liveShopping/backoffice/otm/containers/Home";
import BackOfficeLayout from "app/liveShopping/backoffice/Layout";
import SecuredRoute from "./SecuredRoute";
import LivesContainer from "app/liveShopping/backoffice/otm/containers/Lives/LivesContainer";
import CreateLive from "app/liveShopping/backoffice/otm/components/Live/Create";
import EditLive from "app/liveShopping/backoffice/otm/components/Live/Edit";
import Detail from "app/liveShopping/backoffice/otm/components/Live/Detail";
import CreateProduct from "app/liveShopping/backoffice/otm/components/Live/Product";
import EditProduct from "app/liveShopping/backoffice/otm/components/Live/Detail/Catalog/Edit";
import ReplayManagement from "app/liveShopping/backoffice/otm/components/Replay";
import AccountPage from "app/liveShopping/backoffice/otm/components/Profil/AccountPage";
import Participants from "app/liveShopping/backoffice/otm/containers/Participants/Participants";
import DndProvider from "app/liveShopping/backoffice/otm/components/DndProvider";
import VideoParameters from "app/liveShopping/backoffice/otm/components/VideoParameters";
import BackofficeLayout from "app/liveShopping/backoffice/BackofficeLayout";
import ShopifyImport from "app/liveShopping/backoffice/otm/components/Live/Product/ShopifyImport";
import Appearance from "app/liveShopping/backoffice/otm/components/Appearance";
import Statistics from "app/liveShopping/backoffice/otm/components/Statistics";
import Viewport from "app/views/styles/viewport";
import CssBaseline from "@material-ui/core/CssBaseline";
import ProjectDashboard from "app/liveShopping/backoffice/oto/Container/ProjectDashboard/ProjectDashboard";
import Groups from "app/liveShopping/backoffice/oto/Container/GroupsContainer";
import OneToOneLayout from "app/liveShopping/backoffice/oto/OneToOneLayout";
import GeneralLiveContainer from "app/liveShopping/backoffice/oto/Container/GeneralLiveContainer";
import OneToOne from "app/liveShopping/frontoffice/oto";
import History from "app/liveShopping/backoffice/oto/Component/History/History";
import Parameters from "app/liveShopping/backoffice/oto/Component/Parameters/Parameters";
import ReplayLibrary from "app/liveShopping/backoffice/otm/components/Replay/Library";
import ReplayCreate from "app/liveShopping/backoffice/otm/components/Replay/ReplayCreate";
import Fullscreen from "app/liveShopping/backoffice/otm/components/Fullscreen";
import ChatModo from "app/liveShopping/backoffice/otm/ChatModo";
import MultiChat from "app/liveShopping/backoffice/oto/Container/MultiChat";
import Appointment from "app/liveShopping/frontoffice/oto/components/Appointment";
import GroupAppointment from "app/liveShopping/backoffice/oto/Component/Groups/Appointment/index";
import Appointments from "app/liveShopping/backoffice/oto/Component/Appointments";
import FishingRodClient from "app/liveShopping/frontoffice/oto/components/FishingRodClient";
import Products from "app/liveShopping/backoffice/oto/Component/Products";

// <= not supported
const NOT_SUPPORTED = {
  ie: 10,
  chrome: 36,
  firefox: 44,
  safari: 8,
};

class Application extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      browserSupported: true,
      locale: getLocale(this.props.user),
    };

    // listen for every route change and fwd it to who needs it through emitter
    this.props.history.listen((location, action) => {
      emitter.emit(CORE_ROUTE_CHANGE, location, action);
    });

    this.loadLocale(this.state.locale);
  }

  componentDidMount() {
    const { browserInfo } = this.props;
    const { name, version } = browserInfo;

    if (NOT_SUPPORTED[name] && version <= NOT_SUPPORTED[name]) {
      return this.setState({ browserSupported: false });
    }

    // Init app. Do Session magic behind
    this.props.dispatch(applicationInit());

    document.body.classList.add(`Browser-${browserInfo.name}`);
    document.body.classList.add(`Browser-${browserInfo.name}-${browserInfo.version}`);

    if (window.location.hash === "#embed") {
      document.body.classList.add("is-embedded");
    }
  }
  //prevprops, prevstate
  componentDidUpdate() {
    if (get(this.props, "user.lang") === get(this.state, "locale")) {
      return;
    }

    this.updateLocale(get(this.props, "user.lang", null));
  }

  updateLocale(locale) {
    locale = locale || getLocale(this.props.user);

    if (locale === this.state.locale) {
      return;
    }

    // changing language here by taking the state to reload full App subcomponents
    // state will change with eventlistener used for selecting language
    this.loadLocale(locale);
    this.setState({ locale });
    //// TODO: remove this after refacto of traduction system
    this.props.history.go(0);
  }

  loadLocale(locale) {
    switch (locale) {
      case "fr":
        i18n.load(frenchDictionnary);
        dayjs.locale("fr");
        break;
      default:
        // i18n.default();
        // dayjs.locale("en");
    }
  }

  render() {
    const { browserSupported } = this.state;
    const { sessionFetched } = this.props;

    const withFirewall = (match, Component, forModule = false) => {
      const keyword = get(match, "params.keyword", "").toLowerCase();

      return (
        <Firewall match={match} keyword={keyword} forModule={forModule}>
          {(props) => {
            return <Component {...props} />;
          }}
        </Firewall>
      );
    };

    const inBackoffice = (match, Component) => {
      const keyword = get(match, "params.keyword", "").toLowerCase();
      return (
        <Firewall match={match} keyword={keyword}>
          {(props) => {
            return (
              <BackofficeLayout>
                <Component {...props} />
              </BackofficeLayout>
            );
          }}
        </Firewall>
      );
    };

    const inOneToOneBackoffice = (Component) => {

      return (
        <OneToOneLayout>
          <Component {...this.props} />
        </OneToOneLayout>
      );
    };

    // const JJ = () => <div>foo</div>;

    const FirewallWizLiveShopping = ({ match }) => withFirewall(match, LiveShoppingWiz);
    // const FirewallWizLiveShopping = ({ match }) => withFirewall(match, JJ);

    renderLog("App");

    if (!browserSupported)
      return (
        <div>
          {/* <img src="assets/images/wisembly-full-color.svg" /> */}
          <br />
          <br />
          <div className="message">
            Il semble que votre navigateur ne soit pas supporté. Utilisez ou installez si vous le pouvez un navigateur comme Chrome, Firefox, ou Microsoft Edge, dans une version récente. Si vous avez
            besoin d&apos;aide, n&apos;hésitez pas à contacter notre support : support@wisembly.com
            <br />
            <br />
            It seems that your browser is not supported. If you can, please use or install a browser such as Chrome, Firefox, or Microsoft Edge, in a recent version. If you need help, don&apos;t
            hesitate to contact our support: support@wisembly.com
          </div>
        </div>
      );

    // We are in a connecting state if we have not yet fetched once the user session
    const connecting = !sessionFetched;

    // TODO: A refacto
    const isLS = this.props.location.pathname.indexOf("/ls/") || this.props.location.pathname.indexOf("/visio/") === -1 ? false : true;
    const FinalLayout = isLS ? Layout : BackOfficeLayout;

    return (
      <ThemeProvider>
        <Viewport />
        <CssBaseline />
        <FinalLayout loading={connecting}>
          <CookiesConsentLiveShopping />
          <DndProvider>
            {!connecting && (
              <Switch>
                <Route exact path="/" component={Welcome} />
                <Route exact path="/connect/" component={Connect} />
                <Route path="/ls/:keyword" component={FirewallWizLiveShopping} />
                <Route path="/visio/:visitor/:token/" component={OneToOne} />
                <Route path="/fishingrod/:project/:token/" component={FishingRodClient} />
                <Route path="/oto/appointment" component={Appointment} />
                <SecuredRoute exact path="/board" component={Home} />
                <SecuredRoute exact path="/oto/:hash/dashboard" component={() => inOneToOneBackoffice(ProjectDashboard)} />
                <SecuredRoute exact path="/oto/:hash/groups" component={() => inOneToOneBackoffice(Groups)} />
                <SecuredRoute exact path="/oto/:hash/groups/:group/appointment/:tab?" component={() => inOneToOneBackoffice(GroupAppointment)} />

                <SecuredRoute path="/oto/:hash/appointments" component={() => inOneToOneBackoffice(Appointments)} />
                <SecuredRoute exact path="/oto/:hash/products" component={() => inOneToOneBackoffice(Products)} />

                <SecuredRoute exact path="/oto/:hash/live" component={() => inOneToOneBackoffice(GeneralLiveContainer)} />
                <SecuredRoute exact path="/oto/:hash/history" component={() => inOneToOneBackoffice(History)} />
                <SecuredRoute exact path="/oto/:hash/parameters/:tab?" component={() => inOneToOneBackoffice(Parameters)} />
                <SecuredRoute exact path="/oto/:hash/chat" component={() => inOneToOneBackoffice(MultiChat)} />
                <SecuredRoute exact path="/otm/lives" component={LivesContainer} />
                <SecuredRoute exact path="/otm/live/create/" component={CreateLive} />
                <SecuredRoute exact path="/user/account" component={AccountPage} />

                <SecuredRoute exact path="/otm/:keyword/edit" component={({ match }) => inBackoffice(match, EditLive)} />
                <SecuredRoute exact path="/otm/:keyword/product" component={({ match }) => inBackoffice(match, CreateProduct)} />
                <SecuredRoute exact path="/otm/:keyword/product/import" component={({ match }) => inBackoffice(match, ShopifyImport)} />
                <SecuredRoute exact path="/otm/:keyword/product/:hash" component={({ match }) => inBackoffice(match, EditProduct)} />
                <SecuredRoute exact path="/otm/:keyword/participants" component={({ match }) => inBackoffice(match, Participants)} />
                <SecuredRoute exact path="/otm/:keyword/replay" component={({ match }) => inBackoffice(match, ReplayManagement)} />
                <SecuredRoute exact path="/otm/:keyword/replay/library" component={({ match }) => inBackoffice(match, ReplayLibrary)} />
                <SecuredRoute exact path="/otm/:keyword/replay/create" component={({ match }) => inBackoffice(match, ReplayCreate)} />
                <SecuredRoute exact path="/otm/:keyword/video" component={({ match }) => inBackoffice(match, VideoParameters)} />
                <SecuredRoute exact path="/otm/:keyword/appearance" component={({ match }) => inBackoffice(match, Appearance)} />
                <SecuredRoute exact path="/otm/:keyword/stats" component={({ match }) => inBackoffice(match, Statistics)} />
                <SecuredRoute exact path="/otm/:keyword" component={({ match }) => inBackoffice(match, Detail)} />
                <SecuredRoute exact path="/otm/:keyword/moderation" component={({ match }) => inBackoffice(match, ChatModo)} />
                <SecuredRoute exact path="/otm/:keyword/mails" component={({ match }) => inBackoffice(match, Mails)} />
                <SecuredRoute exact path="/otm/:keyword/fullscreen" component={({ match }) => inBackoffice(match, Fullscreen)} />
                <Route exact path="/confirm-password/:token" component={Connect} />
                <Route exact path="/logout" component={Logout} />
                <Route component={NotFound} />
              </Switch>
            )}
          </DndProvider>
        </FinalLayout>
      </ThemeProvider>
    );
  }
}

Application.propTypes = {
  user: PropTypes.object,
  locale: PropTypes.string,
  browserInfo: PropTypes.object,
  connection: PropTypes.object,
  history: PropTypes.object,
};

// Pass in the session to the Application
function mapStateToProps(state) {
  const { user_id } = getUserSession();

  return {
    connection: getConnection(state),
    locale: localeSelector(state),
    browserInfo: browserInfoSelector(state),
    sessionFetched: fetchedOnceSelector(state).session,
    user: userSelector(state, user_id),
  };
}

/*
 *
 * Here, we connect the Application component to the Router by wrapping it inside the withRouter HigherOrderComponent
 * So that Application will have access to the Router props, particularly the 'history' props
 * Without this, redirects in <AuthenticatedRoute> will never trigger a re-render of the Application component
 * And will result in a blank page.
 * source: https://reacttraining.com/react-router/web/api/withRouter
 *
 */
export default compose(connect(mapStateToProps), withRouter)(Application);
//export default hot(App);
