import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import clsx from "clsx";
import dayjs from "dayjs";
import { _t } from "i18n";
import WizStateChip from "./WizStateChip";

export const isWizLive = (live_start, live_stop) => {
  return isWizAccessible(live_start, live_stop);
};
export const isWizAccessible = (start, end) => {
  const fromdayjs = dayjs(start);
  const toDatedayjs = dayjs(end);

  if (!fromdayjs.isValid() || !toDatedayjs.isValid()) {
    return false;
  }
  if (toDatedayjs.isBefore(new Date())) {
    return false;
  }
  if (fromdayjs.isBefore(new Date())) {
    return true;
  }

  return false;
};

export const getWizState = (start, end, live = null) => {
  const fromdayjs = dayjs(start);
  const toDatedayjs = dayjs(end);

  //what is the difference btw live and started then ?
  if (live) return "WIZ_LIVE";
  if (!fromdayjs.isValid() || !toDatedayjs.isValid()) {
    return null;
  }

  if (dayjs(end).isBefore(new Date())) {
    return "WIZ_FINISHED";
  }
  if (dayjs(start).isBefore(new Date())) {
    return "WIZ_STARTED";
  }

  return "WIZ_NOT_STARTED";
};

export const getWizStateLabel = (start, end, live = null) => {
  const status = getWizState(start, end, live);
  switch (status) {
    case "WIZ_FINISHED":
      return _t("Finished");
    case "WIZ_STARTED":
      return _t("Ongoing");
    case "WIZ_NOT_STARTED":
      return _t("Not Started");
    case "WIZ_LIVE":
      return _t("Live");
    default:
      return _t("Not started");
  }
};

const WizState = ({
  start,
  end,
  live,
  customClass,
  customTextClass,
  customIconClass,
}) => {
  const status = getWizState(start, end, live);

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      className={clsx("WizState")}
    >
      <WizStateChip
        status={status}
        customClass={customClass}
        customTextClass={customTextClass}
        customIconClass={customIconClass}
      />
    </Box>
  );
};

WizState.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  live: PropTypes.bool,
  customClass: PropTypes.string,
  customTextClass: PropTypes.string,
  customIconClass: PropTypes.string,
};
export default WizState;
