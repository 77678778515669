import { withStyles } from "@material-ui/styles";
import MuiButton from "@material-ui/core/Button";
import ThemedButton from "./ThemedButton";

/*
for now i only add customisation for color="secondary"&&variant="contained" combinaison  and color="success"&&variant="contained" combinaison plus label classe

i created a new fontWeight attribute to quickly customise label fontWeight
i created a new textColor attribute to quickly customise label color
i created a new height attribute to quickly customise height value for the button
usage
import Button from "appComponents/Mui/Button"

<Button color="secondary" variant="contained" fontWeight="normal" fontWeight="normal" textColor="white" height="auto" >
</Button>
*/

const Button = withStyles((theme) => {
  return {
    //some customisation on root in we want in the futur
    root: (props) => {
      return {
        [theme.breakpoints.up("sm")]: {
          whiteSpace: "nowrap",
        },
        lineHeight: "100%",
        letterSpacing: "0.5px",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
      };
    },
    disabled: {},
    contained: (props) => {
      const themeButton = ThemedButton(props, theme);
      return {
        ...themeButton,
        minWidth: 0,
      };
    },
    containedSecondary: {},
    label: {
      textAlign: "center",
      textTransform: "none",
    },
  };
})(MuiButton);

export default Button;
