import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Broadcast from "react-bootstrap-icons/dist/icons/broadcast";
import { useSelector, useDispatch } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import InfoCircle from "react-bootstrap-icons/dist/icons/info-circle";
import { ButtonBase, CircularProgress, Tooltip, useTheme } from "@material-ui/core";
import { displayBackOfficeElement } from "app/state/ducks/liveShopping/actions";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import { Select } from "appComponents/Mui";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR } from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => (props.isMobile ? "100%" : "338px"),
    height: (props) => props.isMobile && "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px 24px 32px 32px",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: (props) => (!props.isMobile ? "12px" : "0"),
    margin: (props) => (!props.isMobile ? "24px 30px 24px 50px" : "0"),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  typoTitle: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginLeft: "9px",
  },
  wrapperStats: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
  },
  info: {
    marginLeft: "5px",
    cursor: "pointer",
  },
  edit: {
    fontSize: "12px",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const getTootlipContent = (status) => {
  switch (status) {
    case "draft":
      return _t("The session is not accessible to end users. Make it accessible once you're ready.");
    case "pre_live":
      return _t("Your session is ready and accessible to users. They'll see the pre-live screen and can register to be notified of the live.");
    case "ready_for_live":
      return _t("You configured your live settings and already broadcasting. Make the final adjustments, smile, and put it live when you want to start!");
    case "live":
      return _t("It's live!");
    case "awaiting_replay":
      return _t("Live has ended, take time to upload/edit the replay, create the chapters and then release it.");
    case "replay":
      return _t("Replay is accessible to users, they can still buy products and experience the live feeling with the replay.");
    case "closed":
      return _t("Live session is ended and not accessible anymore to end users.");
  }
};

const getStatusDisplayName = (status) => {
  switch (status) {
    case "draft":
      return _t("Draft");
    case "pre_live":
      return _t("Pre Live");
    case "ready_for_live":
      return _t("Ready for Live");
    case "live":
      return _t("Live");
    case "awaiting_replay":
      return _t("Awaiting Replay");
    case "replay":
      return _t("Replay");
    case "closed":
      return _t("Closed");
  }
};

const getStatusCheck = (status, wiz) => {
  switch (status) {
    case "pre_live":
      if (!wiz.theme.background_image && !wiz.extra_settings.teasing_video) return _t("You must give a background image or a teasing video");
      return true;
    case "live":
    case "ready_for_live":
      if (!wiz.live_media.url) return _t("You must fill a valid live video url");
      return true;
    case "replay":
      if (!wiz?.replay?.replay_url) return _t("You must fill a valid live video url");
      return true;
    case "draft":
    case "closed":
    case "awaiting_replay":
      return true;
  }
};

const Status = ({ }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const wiz = useSelector(currentWizSelector);
  const dispatch = useDispatch();
  const theme = useTheme();
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState(wiz.status);
  const [loading, setLoading] = useState(false);

  const toggleEditMode = () => setEdit(!edit);

  const handleChange = (name, value) => {
    const check = getStatusCheck(value, wiz);

    if (check !== true) return emitter.emit(EVENT_FLASH_ERROR, check);

    setLoading(true);
    setStatus(value);

    const data = {
      status: value,
      extra_settings: {
        ...wiz.extra_settings,
        [`status_${value}_at`]: new Date().getTime(),
      },
    };

    dispatch(
      resourceEdit(Object.assign(wiz, data), {
        slug: ["event", wiz.keyword],
        patch: ["status", "extra_settings"],
        callback: (error) => {
          setLoading(false);

          if (error) {
            emitter.emit(EVENT_FLASH_ERROR, error?.message || _t("An error has occurred"));
            return;
          }

          setEdit(false);
        },
      })
    );
  };

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "10px" }}>
        <Box className={classes.title}>
          <Broadcast />
          <Typography className={classes.typoTitle} variant={"bodySB13"}>
            {_t("Live status")}
          </Typography>
        </Box>

        {isMobile && (
          <ButtonBase onClick={() => dispatch(displayBackOfficeElement(""))}>
            <XLg size={20} />
          </ButtonBase>
        )}
      </Box>

      <Box className={classes.wrapperStats}>
        {!edit && (
          <Box>
            {_t("Current status: {status}", { status: getStatusDisplayName(status) })}

            <Tooltip title={getTootlipContent(status)}>
              <InfoCircle className={classes.info} />
            </Tooltip>

            <Box onClick={toggleEditMode}>
              <Typography className={classes.edit}>{_t("Edit")}</Typography>
            </Box>
          </Box>
        )}

        {edit && (
          <Box>
            <Box display="flex" alignItems="center">
              <Select
                name="type"
                value={status}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                options={[
                  { value: "draft", label: getStatusDisplayName("draft") },
                  { value: "pre_live", label: getStatusDisplayName("pre_live") },
                  { value: "ready_for_live", label: getStatusDisplayName("ready_for_live") },
                  { value: "live", label: getStatusDisplayName("live") },
                  { value: "awaiting_replay", label: getStatusDisplayName("awaiting_replay") },
                  { value: "replay", label: getStatusDisplayName("replay") },
                  { value: "closed", label: getStatusDisplayName("closed") },
                ]}
              />

              {loading && <CircularProgress size={20} style={{ color: theme.palette.colors.primary, marginLeft: "5px" }} />}
            </Box>
            <Box onClick={toggleEditMode}>
              <Typography className={classes.edit}>{_t("Cancel")}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

Status.propTypes = {};

Status.defaultProps = {};

export default Status;
