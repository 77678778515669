import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useField } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PropTypes from "prop-types";
import get from "lodash/get";
import shuffle from "lodash/shuffle";
import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";
//import Divider from "@material-ui/core/Divider";
import basil from "core/basil";
import clone from "lodash/clone";
import { _t, _n } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import PercentIndicator from "appComponents/Viewers/PercentIndicator";
import {
  useCurrentFieldHash,
  DragableElement,
  ItemMeta,
  SurveyProgressBar,
} from "appComponents/SurveyForms";
import ErrorBoundary from "appComponents/NotFound/ErrorBoundary";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  wrapper: {
    "& > div": {
      marginBottom: theme.spacing(1.875),
    },
  },
  optionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    backgroundColor: theme.palette.background.secondaryBox,
    borderRadius: "5px",
    padding: theme.spacing(1.875),
  },
  optionRoot: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",

    width: "100%",
    maxWidth: (props) =>
      props.withLiveResult ? "calc(100% - 205px)" : "calc(100%)",
    alignItems: "center",
  },
  optionLabel: {
    marginLeft: theme.spacing(2.25),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    //fontSize: (props) => (props.layoutMode === "split" ? "16px" : "20px"),
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: .5,
  },
  answerType: {},
  itemHelp: {
    marginBottom: theme.spacing(1),
  },
  itemLabel: {},
  errorMsg: {
    color: theme.palette.error.main,
  },
  required: {
    fontSize: "12px",
    color: (props) => (props.error ? theme.palette.error.main : "currentcolor"),
    display: (props) => (props.error ? "block" : "none"),
    position: "absolute",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
    marginTop: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  item: {
    display: "flex",
  },
  dragIcon: {
    color: theme.palette.secondary.main,
    fontSize: 30,
    marginLeft: -8.75,
  },
  detailsPercent: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
    width: "190px",
  },
}));

export const OptionPreview = ({ optn, summary, canAnswer, withLiveResult }) => {
  const points = get(summary, `leaderboard.${get(optn, "hash", "hash")}`, 0);
  const board = summary.leaderboard || {};
  let max = 1;
  forEach(board, (value, key) => {
    if (value > max) max = value;
  });
  const layoutMode = basil.get("currentLayoutMode") || "split";
  const classes = useStyles({ layoutMode, withLiveResult });
  return (
    <Box flexWrap="wrap" className={classes.optionRow}>
      <Box className={classes.main}>
        <DragIndicatorIcon classes={{ root: classes.dragIcon }} />
        <Box className={classes.item} value={optn.hash} disabled={!canAnswer}>
          <Typography className={classes.optionLabel}>{optn.label}</Typography>
        </Box>
      </Box>
      {withLiveResult && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexDirection="row"
          pl={2}
          className={classes.detailsPercent}
        >
          <PercentIndicator percent={(points / max) * 100} />
          <Box ml={1}>
            {_n("{count} pt", "{count} pts", points, { count: points })}
          </Box>
        </Box>
      )}
    </Box>
  );
};
OptionPreview.propTypes = {
  optn: PropTypes.object,
  withLiveResult: PropTypes.bool,
  summary: PropTypes.array,
  canAnswer: PropTypes.bool,
};
//update later with material ui radio
export const RankingField = ({
  name,
  required,
  options,
  help,
  summary,
  withLiveResult,
  canAnswer,
  hash,
}) => {
  const currentFieldHash = useCurrentFieldHash();

  const sortDefault = useMemo(() => {
    const elements = options.choices.map(({ hash, label }, i) => ({
      id: i,
      hash,
      label,
    }));
    if (options.randomize) {
      return shuffle(elements);
    }
    return elements;
  }, [options.choices, options.randomize]);

  const [cards, setCards] = useState(sortDefault);
  const validate = (value) => {
    let error;
    if (currentFieldHash !== hash) return;
    if (required && (!value || isEmpty(value))) {
      error = _t("field is required");
    }
    if (required && options.choices) {
      const values = [];
      const labels = [];

      options.choices.forEach(({ hash, label }) => {
        values.push(hash);
        labels.push(label);
      });

      value.forEach((choice) => {
        if (!values.includes(choice))
          error = _t("option must be one of options {labels}", {
            labels: labels.join(","),
          });
      });
    }
    return error;
  };
  const [field, meta, helpers] = useField({
    name,
    validate,
    // value: ratingValue,
    // type: "number",
  });
  const { setValue } = helpers;

  useEffect(() => {
    //init response with default choices
    setValue(
      sortDefault.map((elem) => elem.hash),
      true
    );
  }, []);

  const classes = useStyles({ error: meta.error });

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = cards[dragIndex];
      let copyCard = clone(cards);
      copyCard.splice(dragIndex, 1);
      copyCard.splice(hoverIndex, 0, dragCard);
      setCards(copyCard);
      setValue(
        copyCard.map((elem) => elem.hash),
        true
      );
    },
    [cards, setValue]
  );
  const renderCard = (card, index) => {
    return (
      <DragableElement
        key={card.id}
        index={index}
        id={card.id}
        moveCard={moveCard}
        canDrag={canAnswer}
      >
        <OptionPreview
          key={card.hash}
          optn={card}
          summary={summary}
          withLiveResult={withLiveResult}
          canAnswer={canAnswer}
        />
      </DragableElement>
    );
  };

  const result = cards.map((card, i) => renderCard(card, i));

  return (
    <ErrorBoundary>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
        height="100%"
        className={classes.errorBorder}
      >
        <SurveyProgressBar />
        <ItemMeta />
        {required && (
          <Typography className={classes.required}>
            {_t("This field is required")}
          </Typography>
        )}
        <DndProvider backend={HTML5Backend}>
          <Box display="flex" flexDirection="column" width="100%" className={classes.wrapper}>
            {result}
          </Box>
        </DndProvider>
      </Box>
    </ErrorBoundary>
  );
};
RankingField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  help: PropTypes.string,
  withLiveResult: PropTypes.bool,
  summary: PropTypes.array,
  canAnswer: PropTypes.bool,
  hash: PropTypes.string,
};

export default RankingField;
