import React, { Component } from "react";

import ScriptLoader from "react-script-loader-hoc";

import { generateHash } from "core/utils";

export const withScript = (scriptUrls = [], options = { cache: true }) => (
  WrappedComponent
) => {
  return class Script extends Component {
    static displayName = `WithScript(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`;

    static getClass = () =>
      WrappedComponent.getClass
        ? WrappedComponent.getClass()
        : WrappedComponent;

    constructor(props, context) {
      super(props, context);

      this.scriptUrls = scriptUrls;

      if (options.cache === false) {
        this.scriptUrls = scriptUrls.map(
          (scriptUrl) => `${scriptUrl}#${generateHash()}`
        );
      }

      this.ScriptWrappedComponent = ScriptLoader(...this.scriptUrls)(
        WrappedComponent
      );
    }

    render() {
      const Component = this.ScriptWrappedComponent;

      return <Component {...this.props} />;
    }
  };
};
