import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useState, useEffect } from "react";
export const useViewInnerHeigh = () => {
  const mobileLandscape = useMediaQuery(
    "@media (max-width:900px) and (orientation: landscape) and (max-height: 600px)"
  );
  const isMobile = useMediaQuery("(max-width:600px)") || mobileLandscape;

  const [vh, setVh] = useState(window.innerHeight * 0.01);

  useEffect(() => {
    if (isMobile) {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    }
  }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      if (isMobile) {
        //for mobile update for each change in height for now
        setTimeout(function () {
          setVh(window.innerHeight * 0.01);
          const newVh = window.innerHeight * 0.01;
          document.documentElement.style.setProperty("--vh", `${newVh}px`);
        }, 1000);
      } else {
        document.documentElement.style.removeProperty("--vh");
      }
    };
    window.addEventListener("resize", handleResize);

    // cleanup this component
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [vh, isMobile]);

  return vh;
};
