import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import images from "images";
import { getSendDate } from "./utils";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "9px",
    background: "#F5F5F5",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    cursor: "default",
  },
  icon: {
    margin: "0 10px",
  },
  text: {
    fontSize: "10px",
  },
  cancelLink: {
    fontSize: "10px",
    color: "#F1BC33",
    cursor: "pointer",
    margin: "0 5px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const CancelSendingButton = ({ template, onClick }) => {
  const classes = useStyles();
  const sendDate = getSendDate(template);

  return (
    <Box className={classes.root} ml={1}>
      <images.SendScheduled className={classes.icon} />
      <Typography className={classes.text}>
        {sendDate
          ? template.status !== "status_done"
            ? _t("Your email will be sent the {date}", {
                date: sendDate.format(`DD[/]MM[/]YY [${_t("at")}] HH:mm`),
              })
            : _t("Your email was sent on {date}", {
                date: sendDate.format(`DD[/]MM[/]YY [${_t("at")}] HH:mm`),
              })
          : _t("Your email will be sent automatically")}
      </Typography>
      {template.status !== "status_done" && (
        <Typography onClick={onClick} className={classes.cancelLink}>
          {_t("Cancel")}
        </Typography>
      )}
    </Box>
  );
};

CancelSendingButton.propTypes = {
  template: PropTypes.object,
  onClick: PropTypes.func,
};

export default CancelSendingButton;
