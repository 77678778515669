import dayjs from "dayjs";

export function getDates(startDate, stopDate) {
  var dateArray = [];
  var currentDate = dayjs(startDate);
  var stopDate = dayjs(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate).format('YYYY-MM-DD'))
    currentDate = dayjs(currentDate).add(1, 'days');
  }
  return dateArray;
}

export function getHours(startDate, stopDate) {
  var dateArray = [];
  var currentDate = dayjs(startDate);
  var stopDate = dayjs(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate).format('HH'))
    currentDate = dayjs(currentDate).add(1, 'hours');
  }
  return dateArray;
}

export function getMonths(startDate, stopDate) {
  var dateArray = [];
  var currentDate = dayjs(startDate);
  var stopDate = dayjs(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate).format('MM'))
    currentDate = dayjs(currentDate).add(1, 'months');
  }
  return dateArray;
}

export const padTo2Digits = (num) => num.toString().slice(0, 2);
