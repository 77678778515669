import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { _t } from "i18n";
import { resourceFetch } from "ressourcesDucks/actions";
import { resourceSelector } from "ressourcesDucks/selectors";
import {
  getKeyword,
  getFetchResultStatusSelector,
} from "applicationDucks/selectors";

import { Message } from "appComponents/Viewers";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
    maxWidth: (props) => (props.isThumbnailView ? "100%" : "85%"),
    //padding: (props) => (props.isThumbnailView ? theme.spacing(2) : 0),
    //height: theme.spacing(5),
  },
}));

const InteractivityQuoteViewer = ({
  quote,
  keyword,
  quoteHash,
  resourceFetch,
  fetchQuoteStatus,
  layoutMode,
  hasFocus,
}) => {
  const isThumbnailView = layoutMode === "pip" && !hasFocus;
  const classes = useStyles({ isThumbnailView });
  //check if survey id in state
  useEffect(() => {
    if (isEmpty(quote)) {
      resourceFetch("quote", {
        slug: ["event", keyword, "quotes", quoteHash],

        XHR_ID: `interactivityQuote${quoteHash}`,
      });
    }
  }, [resourceFetch, quote, quoteHash, keyword]);

  if (
    (isEqual(fetchQuoteStatus, "pending") || isEmpty(fetchQuoteStatus)) &&
    isEmpty(quote)
  ) {
    return <CircularProgress />;
  }

  if (isEmpty(quote)) return <div>{_t("unable to get the quote")}</div>;

  return (
    <Box className={classes.root}>
      <Message
        quote={quote}
        isThumbnailView={isThumbnailView}
        layoutMode={layoutMode}
      />
    </Box>
  );
};

InteractivityQuoteViewer.propTypes = {
  quote: PropTypes.object,
  keyword: PropTypes.string,
  quoteHash: PropTypes.string,
  resourceFetch: PropTypes.func,
  fetchQuoteStatus: PropTypes.string,
  layoutMode: PropTypes.string,
  hasFocus: PropTypes.bool,
};
const mapStateToProps = (state, ownProps) => {
  return {
    quote: resourceSelector(state, {
      type: "Quote",
      id: ownProps.quoteHash,
    }),
    keyword: getKeyword(state),
    fetchQuoteStatus: getFetchResultStatusSelector(
      state,
      `interactivityQuote${ownProps.quoteHash}`
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resourceFetch: bindActionCreators(resourceFetch, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractivityQuoteViewer);
