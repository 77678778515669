import { Badge, Box, makeStyles, Typography } from "@material-ui/core";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import theme from "app/views/styles/theme";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { _t } from "utilities/i18n";
import Bounce from 'react-reveal/Bounce';
import { ChatFill } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  badge: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    background: theme.palette.colors.error,
    borderRadius: "99px",
    width: "fit-content",
  },
  typo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.white,
  },
}));

const NotificationMessageQueue = ({ visitor, openVisitors = [], message }) => {
  const classes = useStyles();
  const [display, setDisplay] = useState(false);
  const currentVisitor = useSelector(currentVisitorSelector);


  useEffect(() => {
    if (
      visitor.status === "waiting" &&
      visitor.conversation[visitor.conversation.length - 1]?.user === "visitor" &&
      currentVisitor?.id !== visitor.id &&
      !openVisitors.includes(visitor.id)
    ) {
      setDisplay(true)
      return;
    }
    setDisplay(false);


  }, [visitor.conversation, openVisitors, currentVisitor]);

  if (!visitor) return null;

  return (
    <>
      {display && (
        <Bounce>
          <Box className={classes.badge}>
            <Typography className={classes.typo}>{message}</Typography>
            {!message && <ChatFill color="#FFFFFF" />}
          </Box>
        </Bounce>
      )}
    </>
  )
};

export default NotificationMessageQueue;