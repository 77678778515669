import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import { _t } from "i18n";

import { makeStyles } from "@material-ui/core/styles";

import {
  useSurveyContextState,
  useSurveyContextDispatcher,
} from "appComponents/SurveyForms";
import Button from "appComponents/Mui/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
  },
  previousButton: {
    display: (props) =>
      props.length > 1 && !props.hidePrevious ? "flex" : "none",
    marginRight: (props) =>
      props.length > 1 && !props.hideNext ? theme.spacing(2) : 0,
  },
  nextButton: {
    display: (props) => (props.length > 1 && !props.hideNext ? "flex" : "none"),
  },
}));

export const PaginationButton = () => {
  const surveyContext = useSurveyContextState();
  const surveyContextDispatch = useSurveyContextDispatcher();

  const hidePrevious =
    ((surveyContext.index === 0 && surveyContext.surveyType !== "evaluation") ||
      (surveyContext.index === -1 &&
        surveyContext.surveyType === "evaluation")) &&
    surveyContext.panel === "userResponse";
  const hideNext =
    (isEmpty(surveyContext.responseIndexMap) ||
      (!isEmpty(surveyContext.responseIndexMap) &&
        surveyContext.index === surveyContext.responseIndexMap.length - 1)) &&
    surveyContext.panel === "userResponse";
  const onPrevious = () => {
    surveyContextDispatch({ type: "PREVIOUS_ITEM" });
  };
  const onNext = async () => {
    surveyContextDispatch({ type: "NEXT_ITEM" });
  };
  const classes = useStyles({
    length:
      surveyContext.surveyType === "evaluation"
        ? surveyContext.length + 1
        : surveyContext.length,
    hideNext,
    hidePrevious,
  });

  return (
    <Box className={classes.root}>
      <Button
        variant="contained"
        color="secondary"
        onClick={onPrevious}
        size="medium"
        className={clsx(classes.previousButton)}
        bold
      >
        {_t("Back")}
      </Button>

      <Button
        variant="contained"
        color="secondary"
        size="medium"
        onClick={onNext}
        className={clsx(classes.nextButton)}
        bold
      >
        {_t("Next")}
      </Button>
    </Box>
  );
};

PaginationButton.propTypes = {
  title: PropTypes.string,
  Component: PropTypes.element,
  disableButton: PropTypes.bool,
  panel: PropTypes.string,
};
PaginationButton.defaultProps = {
  disableButton: false,
};

export default PaginationButton;
