import { select } from "redux-saga/effects";

// TODO: inspect why the fuck we have to use videoPublisher stored in Redux AND the one in ownStream :(
// This has something to do with saga and the way it handles things
import { publishersSelector } from "../selectors";

export function* setSource({ mode, id }) {
  console.log("[VISIO SAGA] SET SOURCE", mode, id)

  if (!window.OT) {
    console.error("No OT lib found");
    return;
  }
  const { videoPublisher } = yield select(publishersSelector);

  const method = mode === "audioInput" ? "setAudioSource" : "setVideoSource";
  console.log("ICI", method, id)
  if (videoPublisher) videoPublisher[method](id);
}
