import React from "react";
import AvatarComponent from "coreComponents/Profil/Avatar";

import { useSelector } from "react-redux";
import { currentUserSelector } from "ressourcesDucks/selectors";

const Avatar = () => {
  const user = useSelector(currentUserSelector);

  return <AvatarComponent user={user} />;
};

export default Avatar;
