export const SESSION_SET = "core/reducers/SESSION_SET";
export const SESSION_UNSET = "core/reducers/SESSION_UNSET";
export const DROP_FILE = "core/reducers/DROP_FILE";
export const VERIFY_USER = "core/reducers/VERIFY_USER";
export const SET_LOCALE = "core/reducers/SET_LOCALE";
export const SOCKET_SET = "core/reducers/SOCKET_SET";

export const EVENT_JOIN = "wisembly-next/GLOBAL_ACTIONS/EVENT_JOIN";
export const EVENT_LEAVE = "wisembly-next/GLOBAL_ACTIONS/EVENT_LEAVE";

export const LOG_OUT = "wisembly-next/GLOBAL_ACTIONS/LOG_OUT";
export const USER_LOADED = "wisembly-next/GLOBAL_ACTIONS/USER_LOADED";
export const REFRESH_SESSION = "wisembly-next/GLOBAL_ACTIONS/REFRESH_SESSION";

export const APP_NAME = "Wisembly";
export const CONTACT_US_EMAIL = "contact@wisembly.com";

export const APPLICATION_INIT = "core/reducers/APPLICATION_INIT";
export const FETCHED_ONCE = "core/reducers/FETCHED_ONCE";

export const APPLICATION_JSON_CALL = "core/sagas/APPLICATION_JSON_CALL";
export const FILE_UPLOAD_SAGA = "core/sagas/FILE_UPLOAD_SAGA";

export const CURRENT_WIZ_SET = "application/action/CURRENT_WIZ_SET";
export const CURRENT_WIZ_ERROR_SET = "application/action/CURRENT_WIZ_ERROR_SET";
export const CREATE_NOTIFICATION = "application/action/CREATE_NOTIFICATION";
export const REMOVE_NOTIFICATION = "application/action/REMOVE_NOTIFICATION";
export const UPDATE_TIMELINE = "application/action/UPDATE_TIMELINE";
export const UPDATE_LEADERBOARD = "application/action/UPDATE_LEADERBOARD";
export const UPDATE_REQUEST_STATUS = "application/action/UPDATE_REQUEST_STATUS";
export const CONNECTION_SET = "application/action/CONNECTION_SET";

export const SET_TIMELINE_STATUS = "application/action/SET_TIMELINE_STATUS";
export const NOTIFICATION_INCREMENT = "application/action/NOTIFICATION_INCREMENT";
export const NOTIFICATION_RESET = "application/action/NOTIFICATION_RESET";
export const ANONYMOUS_USER_PARTICIPATION_SET = "application/action/ANONYMOUS_USER_PARTICIPATION_SET";
export const DISPLAY_POPUP_PARTICIPATION_SET = "application/action/DISPLAY_POPUP_PARTICIPATION_SET";
export const UPDATE_STREAM = "application/action/UPDATE_STREAM";
export const UPDATE_ARCHIVE_STREAM = "application/action/UPDATE_ARCHIVE_STREAM";
export const UPDATE_PARTICIPANTS_COUNT = "application/action/UPDATE_PARTICIPANTS_COUNT";
export const USERNAME_SET = "application/action/SET_USERNAME";
export const UPDATE_PREVIEW = "application/actions/UPDATE_PREVIEW";
export const UPDATE_USER_ACTION = "application/actions/UPDATE_USER_ACTION";
export const UPDATE_CONNECTED_USERS_ACTION = "application/actions/UPDATE_CONNECTED_USERS_ACTION";
export const INVALID_OTT = "applications/actions/invalid_ott";
export const UPDATE_MODERATION = "applications/actions/update_moderation";
export const SET_PARTICIPATION_MODE = "application/action/SET_PARTICIPATION_MODE";
export const SET_CURRENT_PROJECT = "application/action/SET_CURRENT_PROJECT";
export const SET_CUSTOM_THEME_COLORS = "application/action/SET_CUSTOM_THEME_COLORS";
export const TRACK_EVENT = "application/action/TRACK_EVENT";
export const SET_OTO_DASHBOARD_DATES = "application/action/SET_OTO_DASHBOARD_DATES";
