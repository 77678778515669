import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { EVENT_SCREEN_FOCUSED_LAYOUT } from "core/emitter/events";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import emitter from "core/emitter";
import basil from "core/basil";
import DeviceSelector from "./DeviceSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute !important",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  backdrop: {
    position: "absolute",
  },
}));

/*
  This component display the user video preview
  Allows him to change with device he will use
  It also suggest to check connectivity
  And finally allow to enter stage later
*/
const Preview = ({ vonage, parentRef, setPreview }) => {
  const classes = useStyles();
  const [focusedLayout, setFocusedLayout] = useState(
    basil.get("currentLayoutFocus", "live")
  );

  useEffect(() => {
    const focusedLayoutChange = (focusedLayout) =>
      setFocusedLayout(focusedLayout);

    emitter.on(EVENT_SCREEN_FOCUSED_LAYOUT, focusedLayoutChange);

    return () => {
      emitter.removeListener(EVENT_SCREEN_FOCUSED_LAYOUT, focusedLayoutChange);
    };
  }, []);

  if (focusedLayout !== "live") return null;

  return (
    <Modal
      open={true}
      className={clsx("PreviewWrapper", classes.root)}
      BackdropComponent={Backdrop}
      disableEnforceFocus={true}
      disableBackdropClick={true}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      outline="none"
      container={parentRef.current}
    >
      <DeviceSelector vonage={vonage} setPreview={setPreview} />
    </Modal>
  );
};

export default Preview;
