import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";
import ResourceFactory from "orm/resources";
import {
  resourcesEdit,
  resourcesCreate,
  resourcesDelete,
} from "app/state/ducks/ressources/actions";
import { getConnectedUsers } from "applicationDucks/actions";
import { getKeyword } from "applicationDucks/selectors";
import makeRequest from "core/makeRequest";
import { ANALYTICS_PUSH_SERVER } from "config";
import get from "lodash/get";
import globalStore from "appStore";

//this can be sent in some reduxSaga
export const useConnectedUsers = () => {
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();
  //const [list, setList] = useState({});
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await makeRequest(
          `${ANALYTICS_PUSH_SERVER}/count/app/${keyword}?keyword=${keyword}`,
          null,
          {
            method: "GET",
            raw: true,
          }
        );
        dispatch(getConnectedUsers(get(users, "success", {})));
        //setList(get(users, "success", {}));
      } catch (e) {
        console.error("could not fetch onlineUsers", e);
      }
    };

    fetchUsers();
  }, []);
  //return list;
};
export const useRoleUpdater = () => {
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();
  const state = globalStore.getState();
  const resourceFactory = new ResourceFactory(state);
  //const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleRoleChange = useCallback(
    //remove useForce
    (
      action,
      access,
      value,
      callback = () => {},
      callbackError = () => {},
      notify = false
    ) => {
      let resources = [];
      let actionCreator = resourcesEdit;
      let XHR_ID = "";
      let method = "PATCH";
      let patch = null;
      //let newAccesses = {};
      //create tag if necessary
      switch (action) {
        case "create": {
          const accesses = access.map((mail) => {
            return resourceFactory.create("Access", {
              email: mail,
              scope: value,
            });
          });
          resources = accesses;
          actionCreator = resourcesCreate;
          XHR_ID = "CREATE_BATCH_USER_ROLE";
          method = "POST";

          break;
        }
        case "edit": {
          //let hashs = [];
          let newAccess = access.map((elem) =>
            Object.assign({}, elem, { scope: value })
          );

          resources = newAccess;
          actionCreator = resourcesEdit;
          XHR_ID = "EDIT_BATCH_USER_ROLE";
          method = "PATCH";
          patch = ["data"];
          //newAccesses = resourceFactory.create("Custom", { data: hashs });
          break;
        }
        case "sendInvitation": {
          let newAccess = access.map((elem) => Object.assign(elem, {}));
          resources = newAccess;
          actionCreator = resourcesEdit;
          XHR_ID = "SEND_INVITATION_BATCH_USER_ROLE";
          method = "PATCH";
          patch = ["data"];
          break;
        }
        case "delete": {
          //let newAccess = Object.assign(access, { scope: value });
          resources = Array.isArray(access) ? access : [access];
          XHR_ID = "DELETE_BATCH_USERS";
          method = "DELETE";
          actionCreator = resourcesDelete;
          break;
        }
        default:
          break;
      }

      dispatch(
        actionCreator(resources, {
          slug: ["event", keyword, "accesses", "batch"],
          query: [{ key: "notify", value: notify }],
          silent: true,
          method,
          patch,
          batch: true,
          XHR_ID,
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          //transform: false,
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
              callbackError();
              //// TODO: error display
              return;
            } else {
              callback();
            }
          },
        })
      );
    },
    [keyword]
  );

  return handleRoleChange;
};
