import React from "react";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    //overflowX: "auto",
  },
  chipSecondary: {
    backgroundColor: theme.palette.button.secondary.background.enable,
    color: theme.palette.button.secondary.color.default,
  },
  chipPrimary: {
    backgroundColor: theme.palette.button.primary.background.enable,
    color: theme.palette.button.primary.color.default,
  },
  chipError: {
    backgroundColor: theme.palette.button.alert.background.enable,
    color: theme.palette.button.alert.color.default,
  },
  chipTertiary: {
    backgroundColor: theme.palette.button.tertiary.background.enable,
    color: theme.palette.button.tertiary.color.default,
  },
  chip: {
    height: theme.spacing(2),
    fontSize: "10px",
  },
  chipLabel: {
    paddingLeft: "6px",
    paddingRight: "6px",
  },
}));

const ChipWiz = ({ title, color }) => {
  const classes = useStyles({ color });

  return (
    <Chip
      label={title}
      classes={{
        root: clsx({
          [classes.chip]: true,
          [classes.chipSecondary]: color === "secondary",
          [classes.chipPrimary]: color === "primary",
          [classes.chipTertiary]: color === "tertiary",
          [classes.chipError]: color === "error",
        }),
        label: classes.chipLabel,
      }}
    />
  );
};
ChipWiz.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
};
ChipWiz.defaultProps = {
  color: "secondary",
};
export default ChipWiz;
