import React from "react";
import PropTypes from "prop-types";

import Icon from "coreUiKit/inputs/Icon";
import { OPEN_MESSAGES_FILTER } from "utilities/emitter/events";
import emitter from "utilities/emitter";
import Gear from "react-bootstrap-icons/dist/icons/gear";

const FilterGear = ({ isMobile }) => {
  const onOpen = () => {
    emitter.emit(OPEN_MESSAGES_FILTER);
  };
  return (
    <Icon size={isMobile ? 24 : 16} IconComponent={Gear} onClick={onOpen} />
  );
};
FilterGear.propTypes = {
  isMobile: PropTypes.bool,
};
FilterGear.defaultProps = {
  isMobile: false,
};

export default FilterGear;
