import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { EVENT_SEND_NOTIFICATION } from "core/emitter/events";
import emitter from "core/emitter";
import Actions from "./Actions";
import SendArea from "./SendArea";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      width: "100%",
      paddingLeft: "15px",
    },
    alignItems: "center",
  },
  row: {
    margin: " 15px 0px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
}));

const Footer = forwardRef(({ postMessage, disabled, disabledPicture }, ref) => {
  const classes = useStyles();
  const [media, setMedia] = useState(null);
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const handlePostQuote = (callback = () => {}, errorCallback = () => {}, onCancelCallback = () => {}) => {
    if (disabled) return;
    setLoading(true);
    const quote = {
      message: message.trim(),
      media,
      tags: [],
      announcement: false,
    };
    postMessage(
      quote,
      () => {
        setMessage("");
        setMedia(null);
        callback();
        setLoading(false);
      },
      (error) => {
        //error hanlding
        errorCallback(error);
        emitter.emit(EVENT_SEND_NOTIFICATION, {
          type: "alert",
          icon: null,
          button: false,
          userOnly: false,
          WisemblyIcon: null,
          duration: 5,
          text: _t("Oups Unable to send your message try again !"),
        });
        setLoading(false);
      },
      () => {
        onCancelCallback();
        setLoading(false);
      }
    );
  };

  return (
    <Box ref={ref} className={classes.root}>
      <Box className={classes.row}>
        <SendArea inputOnChange={setMessage} message={message} onSend={handlePostQuote} disabled={disabled} />
      </Box>
      <Box>
        <Actions
          onUpload={setMedia}
          buttonLabel={_t("Send")}
          onSend={handlePostQuote}
          disableSend={(message.trim().length < 1 && media === null) || loading}
          loading={loading}
          disabled={disabled}
          disabledPicture={disabledPicture}
        />
      </Box>
    </Box>
  );
});

Footer.propTypes = {
  postMessage: PropTypes.func,
  disabled: PropTypes.bool,
  disabledPicture: PropTypes.bool,
};
Footer.displayName = "MessageFooter";
Footer.defaultProps = {
  postMessage: () => {},
  disabled: false,
  disabledPicture: false,
};

export default Footer;
