import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { hexToRGB } from "utilities/utils";
const useStyles = makeStyles((theme) => ({
  icon: {
    alignItems: "center",
    backgroundColor: hexToRGB(theme.palette.colors.primary, "0.3"),
    borderRadius: ".5rem",
    boxSizing: "border-box",
    color: "rgb(59 130 246/1)",
    display: "inline-flex",
    flexShrink: 0,
    lineHeight: "1.5",
    padding: ".625rem",
    tabSize: "4",
    textAlign: "left",
  },
  iconTypo: {
    color: "inherit",
  },
}));

const Icon = ({ icon, size, color }) => {
  const classes = useStyles();
  const Icon = icon;
  return (
    <Box className={classes.icon}>
      <Icon className={classes.iconTypo} size={size} color={color} />
    </Box>
  )
};

export default Icon;