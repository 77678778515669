import Box from "@material-ui/core/Box";
import clsx from "clsx";
import React, { forwardRef, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";
import { TextField } from "appComponents/Mui";
import Hidden from "@material-ui/core/Hidden";
import MailPreviewFields from "./MailPreviewFields";
import { Toggle } from "appComponents/Actions";
import EditButton from "./EditButton";
import * as ReactDOM from "react-dom";
import MailEditTitle from "./MailEditTitle";
import LoadingBox from "appComponents/Loaders/LoadingBox";
import ButtonsModal from "./Buttons/ButtonsModal";
import { ThemeWrapper } from "app/views/styles/theme/index";
import { parseDefaultValue } from "./Buttons/utils";
import cloneDeep from "lodash/cloneDeep";

const useStyles = makeStyles((theme) => ({
  preview: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: "20px",
    backgroundColor: theme.palette.background.box,
    borderRadius: "10px 10px 0px 0px",
  },
  previewHtml: {
    background: "#FFF",
    "&.preview .ql-toolbar": {
      display: "none",
    },
    "& .ql-container.ql-snow": {
      border: "none",
    },
    "& .ql-toolbar.ql-snow": {
      background: "#FFF",
      boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.35)",
    },
    "& .editablehtml, & .editableButtonGroup": {
      position: "relative",
      "& .editButton": {
        zIndex: 5000,
        visibility: "hidden",
        position: "absolute",
        top: "calc(50% - 25px)",
        right: "-20px",
        height: "50px",
        width: "50px",
        borderRadius: "50%",
        boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.1)",
        background: "#FFF",
      },
    },
    "& .editablehtml div, & .editableButtonGroup div": {
      borderSize: "1px",
      padding: "10px",
    },
    "& .editablehtml:hover, & .editableButtonGroup:hover": {
      //background: "rgba(7, 105, 141, 0.1)",
      background: "#F5F5F5",
      cursor: "pointer",
      "& .editButton": {
        visibility: "visible",
      },
    },
    "&.editing .editablehtml, &.editing .editablehtml:hover": {
      background: "rgba(7, 105, 141, 0.1)",
      "& .quill": {
        border: "1px dashed #CCCCCC",
      },
      "& .editButton": {
        visibility: "visible",
        background: theme.palette.button.primary.background.enable,
        color: theme.palette.button.primary.color.default,
      },
    },
    "&.editingButtons .editableButtonGroup, &.editingButtons .editableButtonGroup:hover":
      {
        background: "rgba(7, 105, 141, 0.1)",
        "& .editButton": {
          visibility: "visible",
          background: theme.palette.button.primary.background.enable,
          color: theme.palette.button.primary.color.default,
        },
      },
  },
  previewTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "10px",
    paddingBottom: "10px",
    paddingRight: "10px",
    borderRadius: "10px 10px 0 0",
  },
}));

const MailPreview = forwardRef(
  (
    {
      onSave,
      template,
      event,
      setEditing,
      editing,
      htmlTemplate,
      manualTemplate,
      setManualTemplate,
      areaContents,
      buttonAreaContents,
      setSegments,
      loading,
      setButtonAreaContents,
    },
    ref
  ) => {
    const classes = useStyles();
    const user = useSelector(currentUserSelector);
    const [htmlEditorEnabled, setHtmlEditorEnabled] = useState(false);
    const [isEditingButtons, setIsEditingButtons] = useState(false);

    useEffect(() => {
      Object.keys(areaContents).forEach((areaId) => {
        const parentElement = document.getElementById(areaId + "_editButton");
        if (parentElement) {
          ReactDOM.render(
            <EditButton
              onClick={() => {
                if (editing) {
                  onSave(template);
                } else {
                  setEditing(true);
                }
              }}
              editing={editing}
            />,
            document.getElementById(areaId + "_editButton")
          );
        }
      });
      // Adding button group editing button
      Object.keys(buttonAreaContents).forEach((areaId) => {
        const parentElement = document.getElementById(areaId + "_editButton");
        const buttonElement = document.getElementById(areaId);
        if (parentElement) {
          const defaultButtonValues = parseDefaultValue(buttonElement);
          ReactDOM.render(
            <ThemeWrapper>
              <ButtonsModal
                defaultButtonValues={defaultButtonValues}
                setIsEditingButtons={setIsEditingButtons}
                hasLanding={event.hasLandingEnabled()}
                template={template.template_key}
                updateInitialHtml={(html) => {
                  let newButtonAreaContents = cloneDeep(buttonAreaContents);
                  newButtonAreaContents[areaId] = html;
                  setButtonAreaContents(newButtonAreaContents);
                }}
              />
            </ThemeWrapper>,
            document.getElementById(areaId + "_editButton")
          );
        }
      });
    }, [
      htmlTemplate,
      areaContents,
      buttonAreaContents,
      event.hasLandingEnabled(),
      template,
    ]);

    useEffect(() => {
      if (isEditingButtons) {
        /* We are editing the buttons and we got a buttonAreaContents update
         ** This means that our buttons were regenerated and we need to save the template
         */
        onSave(template);
        setIsEditingButtons(false);
      }
    }, [buttonAreaContents]);

    const changeView = (view) => {
      switch (view) {
        case "html":
          setEditing(true);
          setHtmlEditorEnabled(!htmlEditorEnabled);
          break;
        case "preview":
          setEditing(false);
          setHtmlEditorEnabled(false);
          break;
      }
    };

    return (
      <Box className={clsx("Preview", classes.preview)}>
        <Box className={classes.previewTop}>
          <MailEditTitle template={template} onSave={onSave} />
          <Toggle
            onChange={changeView}
            options={{
              preview: _t("Preview"),
              html: _t("<Html>"),
            }}
            defaultValue="preview"
          />
        </Box>
        <MailPreviewFields
          template={template}
          editing={editing}
          event={event}
          user={user}
          setSegments={setSegments}
          onSave={onSave}
        />
        {editing && htmlEditorEnabled && (
          <TextField
            value={manualTemplate}
            e
            onChange={(e) => {
              setManualTemplate(e.target.value);
            }}
            multiline
          />
        )}
        {loading && <LoadingBox />}
        <Hidden xsUp={htmlEditorEnabled}>
          <Box
            className={clsx(
              classes.previewHtml,
              editing ? "editing" : "preview",
              isEditingButtons ? "editingButtons" : "previewButtons"
            )}
            id="previewHtml"
            ref={ref}
            dangerouslySetInnerHTML={{
              __html: htmlTemplate,
            }}
          />
        </Hidden>
      </Box>
    );
  }
);

MailPreview.displayName = "MailPreview";

MailPreview.propTypes = {
  onSave: PropTypes.func,
  htmlTemplate: PropTypes.string,
  template: PropTypes.object,
  event: PropTypes.object,
  setEditing: PropTypes.func,
  editing: PropTypes.bool,
  manualTemplate: PropTypes.string,
  setManualTemplate: PropTypes.func,
  areaContents: PropTypes.object,
  setSegments: PropTypes.func,
  loading: PropTypes.bool,
};

MailPreview.defaultProps = {
  onSave: () => {},
  htmlTemplate: "",
  template: {},
  event: {},
  setEditing: () => {},
  editing: true,
  manualTemplate: "",
  setManualTemplate: () => {},
  areaContents: {},
  setSegments: () => {},
  loading: false,
};

export default MailPreview;
