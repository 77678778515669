import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase } from "@material-ui/core";
import CustomFullScreenIcon from "liveShopping/assets/icons/CustomFullScreenIcon";
import CustomFullScreenExitIcon from "app/liveShopping/assets/icons/CustomFullScreenExitIcon";

import { useDispatch, useSelector } from "react-redux";
import { setFullScreen } from "app/state/ducks/liveShopping/actions";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // width: 35,
    height: 35,
    marginBottom: 15,
  },
}));

const Fullscreen = ({}) => {
  const fullScreen = useSelector((state) => state.liveShopping.fullScreen);
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFullScreen = () => {
    fullScreen ? dispatch(setFullScreen(false)) : dispatch(setFullScreen(true));
  };
  return (
    <Box>
      <ButtonBase className={classes.root} onClick={handleFullScreen}>
        {fullScreen ? <CustomFullScreenExitIcon color={"#FFF"} /> : <CustomFullScreenIcon color={"#FFF"} />}
      </ButtonBase>
    </Box>
  );
};

export default Fullscreen;
