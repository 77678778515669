import React from "react";
import { getUserSession } from "core/session";
import { Box, makeStyles } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { API_BASE } from "config";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "150px",
    height: "40px",
  },
}));

const ExportCSVButton = ({ projectId, until, since }) => {
  const classes = useStyles();
  const userSession = getUserSession();
  const { token } = userSession;

  return (
    <Box className={classes.root}>
      <a
        style={{ textDecoration: "none" }}
        target={"_blank"}
        href={`${API_BASE}/stats/project/${projectId}/history?since=${since}&until=${until}&format=csv&token=${token}`}
      >
        <Button clsName={classes.button}>{_t("Export CSV")}</Button>
      </a>
    </Box>
  );
};

export default ExportCSVButton;