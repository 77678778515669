import React from "react";
import PropTypes from "prop-types";
import { _t } from "i18n";

import Box from "@material-ui/core/Box";

import Menu from "coreUiKit/inputs/Menu";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

import timezones from './timezones.json';

const SelectedTimezone = ({
    timezone,
    setTimezone,
    timezoneError,
    setTimezoneError
}) => {
    const onChange = (value) => {
        setTimezone(value.value);
        setTimezoneError(false);
    }

    return (
        <Box>
            <Typography variant={"bodySB13"}>
                {_t("Time zone")}
            </Typography>
            <Box mb={"8px"} />
            <Menu
                defaultOption={{ hash: timezone }}
                type="select"
                options={timezones}
                title={""}
                onFilterChange={onChange}
                error={timezoneError}
            />
        </Box>
    );
};

SelectedTimezone.propTypes = {
    timezone: PropTypes.object,
    setTimezone: PropTypes.func,
    timezoneError: PropTypes.bool,
    setTimezoneError: PropTypes.func,
};

SelectedTimezone.defaultProps = {
    timezone: Element,
    setTimezone: () => { },
    timezoneError: false,
    setTimezoneError: () => { },
};

export default SelectedTimezone;