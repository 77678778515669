import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import Button from "../reusables/Button";
import { _t } from "utilities/i18n";
import { useSelector } from "react-redux";
import { currentWizSelector, replaySelector } from "app/state/ducks/ressources/selectors";
import ReplayManagement from "./Replay";
import { getUrl } from "app/views/containers/Application/Routes";
import { getKeyword } from "app/state/ducks/application/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  component: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "16px",
  },
}));

const OnBoarding = () => {
  const classes = useStyles();
  const replay = useSelector(replaySelector);
  const keyword = useSelector(getKeyword);

  if (replay?.id) return <ReplayManagement />;

  return (
    <Box className={classes.root}>
      <Box className={classes.component}>
        <Button link url={getUrl('otm_replay_library', { keyword })}>{_t("Choose a live")}</Button>
        <Typography>{_t("or")}</Typography>
        <Button link url={getUrl('otm_replay_create', { keyword })}>{_t("Enter an URL")}</Button>
      </Box>

    </Box>
  )
};

export default OnBoarding;