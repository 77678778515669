import isEqual from "lodash/isEqual";
import { Model, Attribute, ForeignKey, OneToOne, ManyToMany } from "redux-orm";

import { generateHash } from "utilities/uuid";

const defaultAttributes = {};

class BaseModel extends Model {
  static __className = "BaseModel";

  static generate(specifiedAttributes = {}) {
    const id = generateHash();

    const mergedAttributes = {
      ...defaultAttributes,
      id,
      ...specifiedAttributes,
    };

    return this.create(mergedAttributes);
  }

  get model() {
    const fields = this.Model.fields;
    const modelName = this.Model.modelName;
    const backendName = this.Model.backendName;

    return { fields, modelName, backendName };
  }

  get locator() {
    return { type: this.Model.backendName, id: this.id };
  }

  get json() {
    const json = {
      id: this.id,
      type: this.Model.backendName,
      attributes: {},
      relationships: {},
    };

    const fields = this.Model.fields;

    Object.keys(fields).map((key) => {
      if (key === "id" || key.startsWith("_")) {
        return;
      }

      const field = fields[key];
      const value = this[key];

      if (field instanceof ForeignKey || field instanceof OneToOne) {
        if (!value) {
          return;
        }

        const data = { id: value.id, type: key };

        json.relationships[key] = { data };

        return;
      }

      if (field instanceof ManyToMany) {
        if (!value.count()) {
          return;
        }

        json.relationships[key] = { data: [] };

        value
          .all()
          .toRefArray()
          .map((ref) => {
            json.relationships[key].data.push({
              type: key,
              id: ref.id,
            });
          });

        return;
      }

      if (typeof value === "undefined") {
        return;
      }

      json.attributes[key] = value;
    });

    return json;
  }

  // check that attributes of two resources are the same (not relations)
  isEqualTo(model) {
    const fields = this.Model.fields;

    for (const key in fields) {
      const field = fields[key];

      if (!(field instanceof Attribute)) continue;

      // handle nested arrays or objects
      if ("object" === typeof this[key]) {
        if (!isEqual(this[key], model[key])) return;

        continue;
      }

      // plain regular keys (strings, ints, bools)
      if (this[key] !== model[key]) return false;
    }

    return true;
  }
}

export default BaseModel;
