import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase } from "@material-ui/core";
import VolumeUp from "react-bootstrap-icons/dist/icons/volume-up";
import VolumeMute from "react-bootstrap-icons/dist/icons/volume-mute";
import { useDispatch, useSelector } from "react-redux";
import { setPlayerVolume } from "liveShoppingDucks/actions";
import Tooltip from "./Tooltip";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.colors.white,
    borderRadius: "99px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 40,
    height: 40,
  },
  boxMuted: {
    width: 40,
    height: 40,
    borderRadius: "99px",
    backgroundColor: theme.palette.colors.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const Volume = ({}) => {
  const classes = useStyles({});
  const dispatch = useDispatch();
  const playerVolume = useSelector((state) => state.liveShopping.playerVolume);

  const [clickedOnce, setClickedOnce] = useState(false);

  const handleMute = (mute) => {
    setClickedOnce(true);
    dispatch(setPlayerVolume(mute ? 0 : 1));
  };

  return (
    <ButtonBase id="muteUnmuteButton" className={classes.root}>
      {playerVolume === 1 ? (
        <VolumeUp size={25} color={"black"} onClick={() => handleMute(true)} />
      ) : (
        <Box onClick={() => handleMute(false)}>
          {!clickedOnce && <Tooltip message={_t("Activate sound")} />}
          <Box className={classes.boxMuted}>
            <VolumeMute size={25} color={"black"} />
          </Box>
        </Box>
      )}
    </ButtonBase>
  );
};

Volume.propTypes = {};

Volume.defaultProps = {};

export default Volume;
