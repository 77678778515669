import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  currentWizReplaySelector,
  currentWizSelector,
} from "app/state/ducks/ressources/selectors";
import {
  setOpenCart,
  setOpenCatalog,
  setOpenReplay,
  setCurrentChapter,
  setPlayerLoaded,
  setPlay,
} from "app/state/ducks/liveShopping/actions";
import { getCurrentChapter } from "../Replay/replayUtilities";
import {
  mobileLayoutSelector,
  desktopLayoutSelector,
} from "liveShoppingDucks/selectors";
import emitter from "utilities/emitter";
import {
  REPLAY_TIMESTAMP_UPDATE,
  REPLAY_PLAYER_PAUSE,
  REPLAY_PLAYER_PLAY,
  REPLAY_PLAYER_SEEK_FORWARD,
  REPLAY_PLAYER_SEEK_BACKWARD,
} from "utilities/emitter/events";
import {
  loadLivePlayer,
  loadReplayPlayer,
  getProviderPlayer,
} from "./utilities";
import {
  cartIsOpenSelector,
  catalogIsOpenSelector,
  currentChapterSelector,
  playerVolumeSelector,
  replayDurationSelector,
  replayIsOpenSelector,
} from "app/state/ducks/liveShopping/selectors";
import { emit } from "utilities/liveshopping-sdk";
import { Box } from "@material-ui/core";
import { isUser } from "utilities/access";

const useStyles = makeStyles(() => ({
  playerWrapper: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  myPlayer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    zIndex: 0,
    pointerEvents: "none",
    overflow: "hidden",
    "& > div > iframe": {
      width: "100%",
      height: "100%",
      position: "absolute",
      margin: "auto",
      background: "black",
      zIndex: 0,
    },
    "& > div": {
      height: "100%",
      width: "100%",
    },
    "& > div > video": {
      top: "50% !important",
      left: "50% !important",
      transform: "translate(-50%, -50%)",
    },
    "&.heightLimitant > div > video": {
      height: "unset",
      width: "100%",
    },
    "&.widthLimitant > div > video": {
      height: "100%",
      width: "unset",
    },
  },
  inPip: {
    position: "absolute",
    display: ({ isDesktop }) => (isDesktop ? "none" : "block"),
    width: "90px",
    height: "160px",
    right: "25px",
    top: "5px",
    borderRadius: "6px",
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.15)",
    zIndex: "10",
    cursor: "pointer",
    "& > div": {
      height: "100%",
      zIndex: -10,
      pointerEvents: "none",
    },
    "& > div > iframe": {
      width: "100%",
      height: "100%",
      borderRadius: "6px",
      zIndex: -10,
      pointerEvents: "none",
    },
  },
  vimeoPip: {
    width: "100%",
    height: "100%",
    margin: 0,
  }
}));

// prevent spamming with resize postmessages
let _previous = 0;

let initTimeout;

const MyPlayer = ({ }) => {
  const dispatch = useDispatch();

  const videoRef = useRef(null);
  const [playerProvider, setPlayerProvider] = useState(null);
  const [status, setStatus] = useState('idle');
  const [dimensions, setDimensions] = useState({ w: window.innerWidth, h: window.innerHeight })

  let heightLimitant = false;

  if (videoRef?.current && videoRef.current.offsetWidth > 9 / 16 * videoRef.current.offsetHeight)
    heightLimitant = true;

  // console.log('heightLimitant', heightLimitant, videoRef?.current?.offsetWidth, videoRef?.current?.offsetHeight)

  useEffect(() => {
    const handleResize = () => {
      // console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
      setDimensions({ w: window.innerWidth, h: window.innerHeight })
    }

    const debouncedResize = debounce(handleResize, 300)

    window.addEventListener('resize', debouncedResize)

    return () => window.removeEventListener('resize', debouncedResize)
  }, []);

  const isMobile = useSelector(mobileLayoutSelector);
  const isDesktop = useSelector(desktopLayoutSelector);
  const classes = useStyles({ isDesktop, heightLimitant });

  const playerVolume = useSelector(playerVolumeSelector);
  const catalogIsOpen = useSelector(catalogIsOpenSelector);
  const replayIsOpen = useSelector(replayIsOpenSelector);
  const cartIsOpen = useSelector(cartIsOpenSelector);
  const currentChapterHash = useSelector(currentChapterSelector);
  const wiz = useSelector(currentWizSelector);
  const replay = useSelector(currentWizReplaySelector);

  const { live_media } = wiz;
  const { chapters } = replay;
  const [player, setPlayer] = useState(null);
  const replayDuration = useSelector(replayDurationSelector);

  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const currentChapter = getCurrentChapter(chapters, currentChapterHash);

  const [layerIsOpen, setLayerIsOpen] = useState(catalogIsOpen || cartIsOpen || replayIsOpen);
  const [isPip, setIsPip] = useState(layerIsOpen && isMobile);

  useEffect(() => {
    const isOpen = catalogIsOpen || cartIsOpen || replayIsOpen;
    setLayerIsOpen(isOpen);
    setIsPip(isOpen && isMobile)
  }, [catalogIsOpen || cartIsOpen || replayIsOpen]);

  useEffect(() => {
    setDimensions({ w: window.innerWidth + Math.random(), h: window.innerHeight + Math.random() })
  }, [isPip]);

  const resetPlayer = (player) => {
    console.log('>>> start reset player');
    player.destroy();
    setPlayer(null);
    setPlayerProvider(null);
    dispatch(setPlay(false));
    dispatch(setPlayerLoaded(false));
    window._initOnce = false;
    console.log('>>> end reset player');
  };

  const onTimerUpdate = ({ seconds, type }) => {

    // videojs case, doesnt return seconds, need to retrieve it
    if (type === 'timeupdate')
      seconds = player.currentTime();

    // anti-spam mechanism, ensure one event max per second
    if (Math.floor(seconds) === Math.floor(window._prevSeconds)) return;
    window._prevSeconds = seconds;

    emitter.emit(REPLAY_TIMESTAMP_UPDATE, seconds);
  };

  // determine a status depending on live and replay enabling
  useEffect(() => {
    console.log('>>> wiz.status changed', wiz.status)

    if (
      wiz.status === 'pre_live' ||
      (wiz.status === 'ready_for_live' && isUser('user-'))
    ) {
      setStatus('prelive');
      return;
    }

    if (
      ['live'].includes(wiz.status) ||
      (wiz.status === 'ready_for_live' && isUser('speaker+'))
    ) {
      setStatus('live');
      return;
    }

    if (wiz.status === 'replay') {
      setStatus('replay');
      return;
    }

    // awaiting_replay is idle
    setStatus('idle');
  }, [wiz.status]);

  // determine which url to play with the right player
  // priorité au direct ;)
  useEffect(() => {
    console.log('>>> status changed', status);

    if (player)
      resetPlayer(player);

    if (status === 'live' && !playerProvider)
      return setPlayerProvider(getProviderPlayer(live_media?.url));

    if (status === 'prelive' && !playerProvider)
      return setPlayerProvider(getProviderPlayer(wiz.extra_settings.teasing_video));

    if (status === 'replay' && !playerProvider)
      return setPlayerProvider(getProviderPlayer(replay?.replay_url));

    if (status === 'idle' && playerProvider)
      return setPlayerProvider(null);
  }, [status, playerProvider]);

  // load player
  useEffect(() => {
    console.log('>>> load player');

    if (initTimeout) clearTimeout(initTimeout);

    // handle live to replay transition, need to unmout live then re-mount replay when available
    if (status === 'idle' && player) {
      resetPlayer(player);
      // player.off("timeupdate", onTimerUpdate);
      return;
    }

    // we have to create player but do not have DOM elem right now, exit for now
    if (status !== 'idle' && !videoRef.current) {
      return;
    }

    initTimeout = setTimeout(() => {
      window._initOnce = true;
      console.log('>>> init once', status);

      if (status === 'live' || status === 'prelive')
        return setPlayer(loadLivePlayer(playerProvider, dispatch, status));

      if (status === 'replay') {
        window._playerSyncedWithChapter = true;
        return setPlayer(loadReplayPlayer(playerProvider, dispatch));
      }

    }, 500);

  }, [playerProvider, videoRef]);

  // hook on timeupdate to dected replay progress
  // and setup first chapter
  useEffect(() => {
    if (!player || status !== 'replay') return;
    player.off("timeupdate", onTimerUpdate);
    player.on("timeupdate", onTimerUpdate);
    dispatch(setCurrentChapter(replay.chapters[0].hash));
  }, [player]);

  useEffect(() => {
    emitter.on(REPLAY_TIMESTAMP_UPDATE, setCurrentTimestamp);
    return () => emitter.off(REPLAY_TIMESTAMP_UPDATE, setCurrentTimestamp);
  }, []);

  // Navigate to currentChapter and add timeupdate seconds
  useEffect(() => {
    if (!player) return;
    if (!currentChapter) return;

    player.setCurrentTime(currentChapter.timestamp).then((seconds) => {
      // le player prend plus de temps a repondre pour changer de chapitre
      setTimeout(() => (window._playerSyncedWithChapter = true), 5000);
    });
  }, [currentChapter]);

  const setPlayerPause = () => player.pause();

  const setPlayerPlay = () => player.play();

  const setSeekForward = (time) => {
    player.setCurrentTime(Math.min(replayDuration, currentTimestamp + time));
  };

  // cannot go back 1rst chapter
  // (we do not want to allow user to see potentially weird first seconds of video when live not started yet)
  const setSeekBackward = (time) => {
    player.setCurrentTime(
      Math.max(chapters[0].timestamp, currentTimestamp - time)
    );
  };

  useEffect(() => {
    if (!player) return;

    emitter.on(REPLAY_PLAYER_PAUSE, setPlayerPause);
    emitter.on(REPLAY_PLAYER_PLAY, setPlayerPlay);
    emitter.on(REPLAY_PLAYER_SEEK_FORWARD, setSeekForward);
    emitter.on(REPLAY_PLAYER_SEEK_BACKWARD, setSeekBackward);

    return () => {
      emitter.off(REPLAY_PLAYER_PAUSE, setPlayerPause);
      emitter.off(REPLAY_PLAYER_PLAY, setPlayerPlay);
      emitter.off(REPLAY_PLAYER_SEEK_FORWARD, setSeekForward);
      emitter.off(REPLAY_PLAYER_SEEK_BACKWARD, setSeekBackward);
    };
  }, [player, replayDuration, currentTimestamp]);

  // VOLUME
  useEffect(() => {
    if (!player) return;
    try {
      player.setVolume(playerVolume);
    } catch (error) { }
  }, [playerVolume]);

  const handleQuitPip = () => {
    if (cartIsOpen && isMobile) {
      dispatch(setOpenCart(false));
    }
    if (catalogIsOpen && isMobile) {
      dispatch(setOpenCatalog(false));
    }
    if (replayIsOpen && isMobile) {
      dispatch(setOpenReplay(false));
    }
  };

  const myObserver = new ResizeObserver((entries) => {
    if (isPip) return

    entries.forEach((entry) => {
      const size = entry.contentRect.height;

      if (_previous === size) return;

      emit("RESIZE", { size });
      _previous = size;
    });
  });

  const iframe = document.querySelector("iframe");
  if (iframe) myObserver.observe(iframe);

  if (!playerProvider?.provider)
    return null;

  if (playerProvider.provider === 'videojs')
    return (
      <div
        id="playerWrapper"
        ref={videoRef}
        className={clsx("video-js", isPip ? classes.inPip : classes.myPlayer, heightLimitant ? 'heightLimitant' : 'widthLimitant')}
        onClick={handleQuitPip}
      >
        <video
          id="player"
          playsInline
          autoPlay
        />
      </div>
    );

  if (playerProvider.provider === 'vimeo') {

    const style = {};

    if (videoRef.current && heightLimitant) {
      style.width = videoRef.current.offsetWidth;
      style.height = Math.ceil(videoRef.current.offsetWidth * 16 / 9);
      style.marginHeight = `-${Math.round((style.height - videoRef.current.offsetHeight) / 2)}px`;
      // console.log('>>> heightLimitant', videoRef.current.offsetWidth, videoRef.current.offsetHeight, isPip)
    }

    if (videoRef.current && !heightLimitant) {
      style.height = videoRef.current.offsetHeight;
      style.width = Math.ceil(videoRef.current.offsetHeight * 9 / 16);
      style.marginLeft = `-${Math.round((style.width - videoRef.current.offsetWidth) / 2)}px`;
      // console.log('>>> not heightLimitant', videoRef.current.offsetWidth, videoRef.current.offsetHeight, isPip)
    }

    return (
      <Box
        className={clsx(classes.playerWrapper, isPip ? classes.inPip : '')}
        onClick={handleQuitPip}
        ref={videoRef}>
        <div
          id="player"
          className={clsx("vimeo", isPip ? classes.vimeoPip : classes.myPlayer)}
          style={style}
          playsInline
          autoPlay
        />
      </Box>
    );
  }

  if (playerProvider.provider === 'youtube') {
    const style = {};

    if (videoRef.current && heightLimitant) {
      style.width = videoRef.current.offsetWidth;
      style.height = Math.ceil(videoRef.current.offsetWidth * 16 / 9);
      style.marginHeight = `-${Math.round((style.height - videoRef.current.offsetHeight) / 2)}px`;
      console.log('>>> heightLimitant', videoRef.current.offsetWidth, videoRef.current.offsetHeight, isPip)
    }

    if (videoRef.current && !heightLimitant) {
      style.height = videoRef.current.offsetHeight;
      style.width = Math.ceil(videoRef.current.offsetHeight * 9 / 16);
      style.marginLeft = `-${Math.round((style.width - videoRef.current.offsetWidth) / 2)}px`;
      console.log('>>> not heightLimitant', videoRef.current.offsetWidth, videoRef.current.offsetHeight, isPip)
    }

    return (
      <Box
        className={clsx(classes.playerWrapper, isPip ? classes.inPip : '')}
        onClick={handleQuitPip}
        ref={videoRef}>
        <div
          id="player"
          className={clsx("vimeo", isPip ? classes.vimeoPip : classes.myPlayer)}
          playsInline
          autoPlay
        />
      </Box>
    );
  }
};

MyPlayer.propTypes = { isDesktop: PropTypes.string };


// MyPlayer.whyDidYouRender = {
//   logOnDifferentValues: false,
//   customName: "MyPlayer",
//   trackAllPureComponents: true,
//   trackHooks: true,
// };

export default MyPlayer;
