import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, useTheme } from "@material-ui/core";
import { _t } from "utilities/i18n";
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import OneSwitch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { endCallVisitorFlag } from "./service";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "16px 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "130%",
  },
  description: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey500,
    textAlign: "justify",
  },
  top: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "16px",
    gap: "8px",
  },
  actions: {
    marginTop: "16px",
  },
  switch: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    marginTop: "16px",

  },
  cta: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
    marginTop: "16px",
  },
  blockTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
}));

const AbusiveDeclaration = ({ setOpenAbusive, visitor }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [ban, setBan] = useState(false);

  const handleFlagAndBan = () => {
    endCallVisitorFlag(dispatch, visitor, true, ban, () => {
      setOpenAbusive(false);
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.top}>
        <ExclamationTriangleFill size={32} color={theme.palette.colors.warning} />
        <Typography className={classes.title}>{_t("Inappropriate user behaviour")}</Typography>
      </Box>
      <Typography className={classes.description}>{_t("This visitor shows inappropriate or offending behaviour. End the conversation now and flag the conversation as offending. It won’t be taken into account in the statistics or history.")}</Typography>

      <Box className={classes.actions}>
        <Box className={classes.switch}>
          <Typography className={classes.blockTypo}>{_t("Block this visitor ip address for 24hours")}</Typography>
          <OneSwitch checked={ban} onClick={() => setBan(!ban)} />
        </Box>
        <Box className={classes.cta}>
          <Button color={theme.palette.colors.grey500} onClick={() => setOpenAbusive(false)}>{_t("Cancel")}</Button>
          <Button onClick={handleFlagAndBan}>{_t("Flag as offending")}</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AbusiveDeclaration;