import Box from "@material-ui/core/Box";
import clsx from "clsx";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import ScheduleOutlinedIcon from "@material-ui/icons/ScheduleOutlined";
import React, { useEffect, useState } from "react";
import { _t } from "core/i18n";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { currentWizSelector } from "ressourcesDucks/selectors";
import MailDatePicker from "./MailDatePicker";
import TestMail from "./TestMail";
import { ActionButton } from "appComponents/Mui";
import { Select } from "appComponents/Mui";
import { makeStyles } from "@material-ui/core/styles";
import ResourceFactory from "orm/resources";
import MailRelativeTimePicker from "./MailRelativeTimePicker";
import {
  TEMPLATE_PICKER_DURATION_AFTER,
  TEMPLATE_PICKER_DURATION_BEFORE,
  TEMPLATE_PICKER_DATE,
} from "orm/resources/template";
import cloneDeep from "lodash/cloneDeep";
import CancelSendingButton from "./CancelSendingButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmSendingButton from "./ConfirmSendingButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    minHeight: "95px",
    backgroundColor: theme.palette.background.box,
    borderRadius: "10px",
    paddingTop: "13px",
    paddingLeft: "19px",
    paddingRight: "19px",
    paddingBottom: "13px",
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeigh: 20,
    letterSpacing: "0.5px",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "14px",
  },
  announcementSelect: {
    display: "flex",
    flexDirection: "row",
    flex: 4,
  },
  mailActions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "flex-end",
    paddingTop: "5px",
    paddingBottom: "5px",
    marginTop: "14px",
    backgroundColor: theme.palette.background.box,
  },
}));

const MailSchedule = ({ template, onSave }) => {
  const classes = useStyles();

  const { send_delay, send_type, active } = template;

  const [canSchedule, setCanSchedule] = useState(false);

  /* We store the date and time in US format*/
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const isValidDate = date && date !== "Invalid Date";
  const isValidTime = time && time !== "Invalid Date";

  /* Thoses values are the one we manipulate locally in this component */
  const [sendingType, setSendingType] = useState(
    cloneDeep(send_type) || "sending_type_customized"
  );
  const [sendingDelay, setSendingDelay] = useState(cloneDeep(send_delay));
  const sendDelayisTimestamp = sendingType === "sending_type_customized";

  const event = useSelector(currentWizSelector);
  const { timezone } = event;
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  /* Utils methods */

  /* This one sets the date and time from dayjs to the state */
  const setDateTime = (reference) => {
    const new_reference = dayjs(reference).tz(timezone);
    setDate(new_reference.format("YYYY-MM-DD"));
    setTime(new_reference.format("HH:mm"));
  };

  /* This get the date and time from the state and return a dayjs object */
  const getDateFromState = () => {
    if (!time) {
      return null;
    }
    let [hour, minute] = time.split(":");
    return dayjs(date)
      .set("hour", hour)
      .set("minute", minute)
      .tz(timezone)
      .unix();
  };

  /* This function is triggered upon each modifications of the paramters to check if they are valid */
  useEffect(() => {
    /* TODO : Check that date is not in the past */
    if (sendingType === "sending_type_customized") {
      const validDate = isValidDate && isValidTime;
      setCanSchedule(validDate);
    } else {
      setCanSchedule(true);
    }
  }, [sendingType, date, time]);

  /* This method convert the submitted date for the components */
  useEffect(() => {
    if (sendingDelay && sendDelayisTimestamp) {
      setDateTime(dayjs.unix(sendingDelay).toISOString());
    }
  }, [sendingDelay]);

  const getNewTemplate = () => {
    let sendDelay = null;

    switch (sendingType) {
      case "sending_type_customized":
        sendDelay = getDateFromState();
        break;
      case "sending_type_before_event":
        sendDelay = sendingDelay;
        break;
      case "sending_type_after_event":
        sendDelay = sendingDelay;
        break;
      default:
        break;
    }
    return resourceFactory.create(
      "Template",
      Object.assign({}, template.ref, {
        send_type: sendingType,
        send_delay: sendDelay,
      })
    );
  };

  const toggleActiveTemplate = () => {
    template.toggleActive();
    const newTemplate = getNewTemplate();
    onSave(newTemplate);
  };

  /* Check send types for template */

  const availableSendTypes = template.getAvailableSendTypes() || {};
  const enabledPickerTool = availableSendTypes.find(
    (type) => type.value === sendingType
  )?.enabled;
  return (
    <Box className={clsx("MailSchedule", classes.root)}>
      <Box className={classes.title}>{_t("Schedule sending")}</Box>
      <Box className={classes.form}>
        <Box className={classes.announcementSelect} mr={2}>
          <Select
            name="announcement"
            fullWidth
            value={sendingType}
            onChange={(e) => {
              const newSendingType = e.target.value;
              setSendingType(newSendingType);
              if (e.target.value === "sending_type_customized") {
                setSendingDelay(null);
              } else {
                setSendingDelay(60);
              }
            }}
            disabled={active}
            options={availableSendTypes.filter((option) => option.enabled)}
          />
        </Box>
        <Box display="flex" flex={2} justifyContent="flex-end">
          {enabledPickerTool === TEMPLATE_PICKER_DATE && (
            <MailDatePicker
              date={date}
              time={time}
              onDateChange={setDate}
              onTimeChange={setTime}
              disabled={active}
            />
          )}

          {[
            TEMPLATE_PICKER_DURATION_BEFORE,
            TEMPLATE_PICKER_DURATION_AFTER,
          ].includes(enabledPickerTool) && (
            <MailRelativeTimePicker
              onChange={(seconds) => setSendingDelay(seconds)}
              negative={enabledPickerTool === TEMPLATE_PICKER_DURATION_BEFORE}
              defaultValue={
                sendingType !== "sending_type_customized" ? sendingDelay : 0
              }
              disabled={active}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.mailActions}>
        <TestMail template={template} />
        {active ? (
          <CancelSendingButton
            template={template}
            onClick={toggleActiveTemplate}
          />
        ) : (
          <ConfirmSendingButton
            onClick={toggleActiveTemplate}
            template={getNewTemplate()}
            disabled={!canSchedule}
          >
            <ActionButton
              title={_t("Schedule sending")}
              inactiveColor="primary"
              ml={10}
              toogleColor={false}
              controlled={true}
              disabled={!canSchedule}
              icon={<ScheduleOutlinedIcon />}
            />
          </ConfirmSendingButton>
        )}
      </Box>
    </Box>
  );
};

export default MailSchedule;
