import {
  THIRD_PARTY_LOADED,
  SET_CREDENTIALS,
  SET_SESSION,
  SET_STREAM,
  REMOVE_STREAM,
  UPDATE_STREAM,
  SET_DEVICES,
  SET_ERROR,
  SET_PUBLISHER,
  SET_STREAM_ID,
  SET_SOURCE,
  MUTE,
  CLEAR_ERROR,
  REQUESTED_JOIN_STAGE,
  SET_ELEMENT,
  REMOVE_ELEMENT,
} from "./actions";

const defaultState = {
  isLoaded: false,
  requestedJoinStage: false,
  credentials: {},
  session: null,

  devices: [],

  audioSource: undefined,
  videoSource: undefined,

  publishAudio: true,
  publishVideo: true,

  streamsById: {},
  streams: [],

  focusedStream: null,

  videoPublisher: null,
  screenPublisher: null,
  videoStreamId: null,

  error: {
    code: undefined,
    message: undefined,
  },
  elements: {},
};

export default function applicationReducer(state = defaultState, action) {
  /* eslint-disable no-case-declarations */
  switch (action.type) {
    case THIRD_PARTY_LOADED:
      return { ...state, isLoaded: true };
    case REQUESTED_JOIN_STAGE:
      return { ...state, requestedJoinStage: true };
    case SET_CREDENTIALS:
      return { ...state, credentials: action.credentials };
    case SET_SESSION:
      return { ...state, session: action.session };
    case SET_STREAM:
      const byIdAdd = {
        ...state.streamsById,
        [action.stream.id]: action.stream,
      };
      const byOccurenceAdd = [...state.streams, action.stream.id];
      return { ...state, streamsById: byIdAdd, streams: byOccurenceAdd };
    case REMOVE_STREAM:
      const byIdRemove = { ...state.streamsById };
      const byElementRemove = { ...state.elements };
      delete byIdRemove[action.stream.id];
      const byOccurenceRemove = [...state.streams].filter(
        (id) => action.stream.id !== id
      );
      delete byElementRemove[action.stream.id];
      return {
        ...state,
        streamsById: byIdRemove,
        streams: byOccurenceRemove,
        elements: byElementRemove,
      };
    case UPDATE_STREAM:
      const byIdUpdated = { ...state.streamsById };
      byIdUpdated[action.stream.id] = action.stream;
      const byOccurrenceUpdate = [...state.streams];
      if (!byOccurrenceUpdate.includes(action.stream.id))
        byOccurrenceUpdate.push(action.stream.id);
      return {
        ...state,
        streamsById: byIdUpdated,
        streams: byOccurrenceUpdate,
      };
    case SET_DEVICES:
      return { ...state, devices: action.devices };
    case SET_ERROR:
      return { ...state, error: action.error };
    case CLEAR_ERROR:
      return { ...state, error: null };
    case SET_ELEMENT:
      const streamIdElement = {
        ...state.elements[action.streamId],
      };
      streamIdElement[action.field] = action.element;
      const newElements = {
        ...state.elements,
        [action.streamId]: streamIdElement,
      };
      return { ...state, elements: newElements };
    case REMOVE_ELEMENT:
      const elementsToKeep = { ...state.elements };
      delete elementsToKeep[action.streamId];
      return { ...state, elements: elementsToKeep };
    case SET_PUBLISHER:
      const publisherType =
        action.mode === "screen" ? "screenPublisher" : "videoPublisher";
      return { ...state, [publisherType]: action.publisher };
    case SET_STREAM_ID:
      const streamType =
        action.mode === "screen" ? "screenStreamId" : "videoStreamId";
      return { ...state, [streamType]: action.id };
    case SET_SOURCE:
      const sourceType =
        action.mode === "audioInput" ? "audioSource" : "videoSource";
      return { ...state, [sourceType]: action.id };
    case MUTE:
      const muteType =
        action.mode === "audio" ? "publishAudio" : "publishVideo";
      return { ...state, [muteType]: action.status };
    /* eslint-enable no-case-declarations */
  }

  return state;
}

export const streamTalkReducer = (state, action) => {
  switch (action.type) {
    case "INIT_TALKING":
      return {
        ...state,
        streams: {
          ...state.streams,
          [action.streamId]: {
            ...state.streams[action.streamId],
            started: action.now,
            isConsideredTalking: false,
          },
        },
      };
    case "SET_TALKING":
      return {
        ...state,
        streams: {
          ...state.streams,
          [action.streamId]: {
            ...state.streams[action.streamId],
            isConsideredTalking: true,
            stopped: null,
          },
        },
        lastTalkerStreamId: action.streamId,
      };
    case "STOP_TALKING":
      return {
        ...state,
        streams: {
          ...state.streams,
          [action.streamId]: {
            ...state.streams[action.streamId],
            isConsideredTalking: true,
            stopped: action.now,
          },
        },
      };
    case "REMOVE_TALKING":
      return {
        ...state,
        streams: Object.fromEntries(
          Object.entries(state.streams).filter(
            ([streamId]) => streamId !== action.streamId
          )
        ),
      };
    default:
      return state;
  }
};
