import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: theme.palette.colors.primary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 9000,
  },
  typo: { color: theme.palette.colors.white },
}));

const LandscapeMode = ({}) => {
  const classes = useStyles();
  const mobileLandscape = useMediaQuery("@media screen and (min-width:400px) and (max-width:900px) and (orientation: landscape) and (max-height: 499px)");

  useEffect(() => {
    if (mobileLandscape && mobileLandscape) document.body.style.background = "#1E2023";
  });

  if (!mobileLandscape) return null;
  return (
    <>
      {mobileLandscape && (
        <Box className={classes.root}>
          <Typography className={classes.typo}>{_t("Please, Turn your mobile on portrait mode")}</Typography>
        </Box>
      )}
    </>
  );
};

LandscapeMode.propTypes = {};

LandscapeMode.defaultProps = {};

export default LandscapeMode;
