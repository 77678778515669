import React from "react";

import { Box, ButtonBase, makeStyles, Typography } from "@material-ui/core";

import { _t } from "utilities/i18n";
import { useDispatch } from "react-redux";
import { resourceDelete } from "app/state/ducks/ressources/actions";
import Trash from "react-bootstrap-icons/dist/icons/trash";

const useStyles = makeStyles((theme) => ({
  delete: {
    cursor: "pointer",
    padding: "10px",
    borderRadius: "50%",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px",
  },
}));

const ListItem = ({ accesses, access, projectHash }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accessToDelete = accesses.find(({ hash }) => hash === access.hash);
  const handleDelete = () => {

    dispatch(
      resourceDelete(accessToDelete, {
        slug: ["projects", projectHash, "admins"],
        batch: true,
        body: accessToDelete,
        callback(error, response) {
          if (error) {
            console.log(error);
          } else {
            console.log(response);
          }
        },
      })
    );
  };

  return (
    <Box className={classes.listItem}>
      <Typography>{access.user.email}</Typography>
      <ButtonBase onClick={handleDelete} className={classes.delete}><Trash /></ButtonBase>
    </Box>
  );
};

export default ListItem;