import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

import { setConnection } from "applicationDucks/actions";
import { getConnection, getKeyword } from "applicationDucks/selectors";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import { useUser } from "app/liveShopping/backoffice/otm/hooks/userHooks";
import { TYPE_SIGNUP, TYPE_FORGOT_PWD, TYPE_SIGNIN, TYPE_VERIFY_MAIL, TYPE_CONFIRM_PWD } from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import Footer from "app/liveShopping/backoffice/otm/components/Firewall/FooterLinks";
import ConnectScreenType from "app/liveShopping/backoffice/otm/components/Connect/ConnectScreenType";
import { getUrl } from "app/views/containers/Application/Routes";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.colors.white,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  carousel: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  connect: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    position: "relative",

    [theme.breakpoints.up("sm")]: {
      padding: (props) => {
        switch (props.type) {
          case TYPE_SIGNIN:
            return "102px 30px 30px 30px";
          case TYPE_SIGNUP:
            return "60px 30px 30px 30px";
          case TYPE_FORGOT_PWD:
          case TYPE_CONFIRM_PWD:
            return "132px 30px 30px 30px";

          default:
            return "30px";
        }
      },
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 48px)",
      margin: "24px",
      justifyContent: "flex-start",
    },
  },
  connectScreen: {
    width: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      maxWidth: "100%",
      marginBottom: "48px",
    },
  },
}));

const Connect = ({ match }) => {
  const dispatch = useDispatch();
  const user = useUser();
  const connection = useSelector(getConnection);
  const classes = useStyles({ type: connection.type });
  const keyword = useSelector(getKeyword);
  const pwdToken = get(match, "params.token", null);

  if (user && connection.type !== TYPE_VERIFY_MAIL) {
    // I'm connected, i should not see this
    console.log(getUrl("otm_lives"));
    return <Redirect to={"/board"} />;
  }

  const handleSetType = (type, args = {}) => {
    dispatch(setConnection({ type, ...args }));
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.connect}>
        <Box className={classes.connectScreen}>
          <ConnectScreenType connection={{ ...connection, pwdToken }} handleSetType={handleSetType} noEvent={!keyword} />
        </Box>
        <Footer />
      </Box>
    </Box>
  );
};

Connect.propTypes = {
  match: PropTypes.object,
};

export default Connect;
