import URI from 'urijs'
import uuid from 'core/uuid'

// Logger stuff
export const DEBUG_MODE = false
export const DEBUG_LEVEL = 'info'
export const SENTRY_URL = ""
export const SENTRY_ENV = "local";
export const APP_BASE = new URI('https://app.liveboutique.io')
export const API_BASE = new URI('https://api.liveboutique.io/api/6')
export const OAUTH_BASE = new URI('https://api.liveboutique.io/oauth')
export const APP_OLD_BASE = new URI('https://app.liveboutique.io/')

export const SIGNIN_APP_ID = 'wisembly_app'
export const SIGNIN_APP_SECRET = 'vYZ3{NwEJRQzXeF'

// Basil-related stuff
export const BASIL_NAMESPACE = 'lb'
export const BASIL_STORAGES = ['cookies', 'local', 'memory']
export const BASIL_COOKIES_DOMAIN = '.liveboutique.io'
export const BASIL_SESSION_KEY = 'session'
export const BASIL_COOKIES_SECURE = false;

// Push server
export const PUSH_SERVER_URL = 'https://p.liveboutique.io'

export const ANALYTICS_PUSH_SERVER = "https://a.liveboutique.io";

// Unique application ID generated for each tab opened
// This is used for ignoring our own push events
// It can be retrived using import { APP_ID } from 'settings/config'
export const APP_ID = uuid()

export const SEGMENTIO_ID = ''
