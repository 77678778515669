import { takeEvery } from "redux-saga/effects";
import { call } from "redux-saga/effects";

import segment from "core/segment";

import { EVENT_FLASH_ERROR_VANISH } from "core/emitter/events";
import emitter from "core/emitter";

import { FILE_UPLOAD_SAGA } from "applicationDucks/actionsTypes";

import notify from "ressourcesDucks/sagas/notify";
import dispatch from "ressourcesDucks/sagas/dispatch";
import { handleRequestError } from "ressourcesDucks/sagas/handleRequestError";
import sendRequest from "core/makeRequest/sendRequest";

import { Parser } from "core/jsonApi";
import { getUserSession } from "core/session";
import makeUrl from "ressourcesDucks/sagas/makeUrl";

function* makeCall({ url, body = {}, options = {} }) {
  let slug = makeUrl({ type: url[0] }, { slug: url });

  options = {
    method: "POST",
    // credentials: "include",
    ...options,
  };

  const session = getUserSession();

  // send cookies with the request
  // https://github.com/github/fetch#sending-cookies
  options.headers = Object.assign(
    {
      "Wisembly-Token": session.token,
    },
    options.headers
  );

  const { analytics } = options;

  if (analytics && analytics.event) {
    segment.track(analytics.event, analytics.context || {});
  }

  options.body = body;

  let response;
  try {
    response = yield call(sendRequest, slug, options);
  } catch (error) {
    yield call(notify, options, null, error);

    yield call(handleRequestError, error);

    // throw error
    if (!options.silent) {
      emitter.emit(EVENT_FLASH_ERROR_VANISH, error.message);
    }

    return;
  }

  if (response === false) {
    return;
  }

  // if data is now raw and have been transformed, dispatch it to the store
  if (!options.raw) {
    yield call(dispatch, response !== null ? Parser.parse(response) : null);
  }

  yield call(notify, options, response);
}

export default function* fileUploadSaga() {
  yield takeEvery(FILE_UPLOAD_SAGA, makeCall);
}
