import React from "react";
import PropTypes from "prop-types";

const CustomHangUpIcon = ({ color, size, ...rest }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.4311 10.6393L14.4311 10.6392L12.0197 10.3388C11.7301 10.3026 11.473 10.135 11.3226 9.88434L11.3225 9.88425L10.3564 8.27472L10.3563 8.27454C10.2618 8.11731 10.1283 7.98718 9.96864 7.89678C9.80903 7.80638 9.62876 7.75878 9.44533 7.75861H9.44487H6.55513H6.55488C6.37115 7.7587 6.19058 7.80638 6.03075 7.89699C5.87102 7.98754 5.73745 8.11789 5.64302 8.27535C5.64296 8.27545 5.64291 8.27555 5.64285 8.27565L4.67743 9.88429L4.67739 9.88435C4.60321 10.008 4.50189 10.1132 4.38109 10.1919C4.26029 10.2706 4.12317 10.3209 3.98011 10.3388L1.56935 10.6399C1.56934 10.6399 1.56934 10.6399 1.56933 10.6399C1.0006 10.7108 0.499615 10.2675 0.5 9.69676V9.69642V8.48031C0.5 7.77909 0.868936 7.1266 1.49511 6.82654L1.4952 6.82649C3.52531 5.85316 5.74851 5.34939 7.99989 5.35256L8.00131 5.35255C10.2524 5.3493 12.4753 5.85284 14.5052 6.82583C15.1304 7.12586 15.5 7.77908 15.5 8.48031V9.69605C15.4999 9.83083 15.4712 9.96406 15.4157 10.0869C15.3602 10.2097 15.2792 10.3194 15.1782 10.4086C15.0771 10.4977 14.9582 10.5644 14.8294 10.6042C14.7007 10.644 14.5649 10.6559 14.4311 10.6393Z" fill="white" stroke="white" />
    </svg>
  );
};

CustomHangUpIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomHangUpIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomHangUpIcon;
