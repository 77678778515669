import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { _t } from "i18n";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Box from "@material-ui/core/Box";

import CustomChevronLeft from "coreUiKit/assets/icons/CustomChevronLeft";
import Button from "coreUiKit/inputs/Button";
import TextInput from "coreComponents/OutlinedTextField";
import ColorList from "coreComponents/Tag/ColorList";
import ColorPicker from "coreUiKit/components/Tag/ColorPicker";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.colors.black,
        padding: "15px",
    },
    header: {
        display: "flex",
        flexDirection: "row",
    },
    chevronIcon: {
        cursor: "pointer",
        margin: "0 5px",
    },
    text: {
        color: theme.palette.colors.white,
        fontSize: "12px",
    },
    input: {
        paddingTop: "15px",
        flexGrow: 1,
    },
    colorList: {
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    colorPicker: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',

    },
    hex: {
        display: "flex",
        flexDirection: "row",
        paddingTop: "15px",
    },
    hashtag: {
        color: theme.palette.colors.white,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: "8px",
    },
    button: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "15px",
    },
}));

const AddTag = ({ setView, createTag, tags, popoverActions }) => {
    const classes = useStyles();
    const [name, setName] = useState("");
    const [color, setColor] = useState("");
    const [colorPickerOpen, setColorPickerOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [error, setError] = useState(false);

    const addTag = () => {
        if (tags.some(tag => tag.name === name)) {
            setError(true);
            setErrorMessage(_t("This name already exist"));
        }
        else if (color != "" && name != "") {
            setError(false);
            createTag(name, color);
            setView("");
        }
        else {
            setError(true);
            setErrorMessage(_t("Enter a name and a color"));
        }
    }

    useEffect(() => {
        if (popoverActions.current) {
            popoverActions.current.updatePosition();
        }
    }, [colorPickerOpen]);

    useEffect(() => {
        const listener = (event) => {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [name]);

    return (
        <Box className={classes.root}>
            <Box className={classes.header}>
                <Box component="a" onClick={() => setView("")} className={classes.chevronIcon}>
                    <CustomChevronLeft size={12} color="white" />
                </Box>
                <Typography className={classes.text} variant={"bodySB13"}>
                    {_t("Back")}
                </Typography>
            </Box>
            <Box className={classes.input}>
                <TextInput
                    error={error}
                    errorMessage={errorMessage}
                    value={name}
                    onChange={setName}
                    placeholder={_t("Label")}
                    multiline
                    maxRows={1}
                    type={"text"}
                    fullWidth
                    mode={"dark"}
                />
            </Box>
            <Box className={classes.colorList}>
                <ColorList color={color} colorPickerOpen={colorPickerOpen} setColor={setColor} setColorPickerOpen={setColorPickerOpen} />
            </Box>
            {colorPickerOpen && (
                <Box>
                    <Box className={classes.colorPicker}>
                        <ColorPicker onChange={(color) => { setColor(color.hex) }} size={100} initialColor={color} setColor={setColor} />
                    </Box>
                    <Box className={classes.hex}>
                        <Typography variant={"bodySB13"} className={classes.hashtag}>
                            #
                        </Typography>
                        <TextInput
                            value={color.substring(1, color.length)}
                            onChange={(text) => setColor("#" + text)}
                            placeholder={_t("Color")}
                            multiline
                            maxRows={1}
                            type={"text"}
                            fullWidth
                            mode={"dark"}
                        />
                    </Box>
                </Box>
            )}
            <Box className={classes.button}>
                <Button onClick={addTag} color={"secondary"}>
                    {_t("Create")}
                </Button>
            </Box>
        </Box>
    );
};

AddTag.propTypes = {
    setView: PropTypes.func,
    createTag: PropTypes.func,
    tags: PropTypes.array,
};

export default AddTag;
