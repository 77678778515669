import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import {
  credentialsSelector,
  devicesSelector,
  publishersSelector,
  publishStateSelector,
  streamsIdSelector,
  streamsSelector
} from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/selectors";
import {
  bindDom,
  getDevices,
  getVisioCredentials,
  initPublisher,
  joinSession,
  mute,
  publishStream,
  setCredentials
} from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/actions";
import { sessionSelector, errorSelector } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/selectors";
import { isUser } from "core/access";
import { emit, listen } from "core/liveshopping-sdk";
import Actions from "app/liveShopping/frontoffice/oto/components/Actions";
import Chat from "../Chat/Chat";
import { ArrowsAngleContract } from "react-bootstrap-icons";
import Screen1x2Icon from "app/liveShopping/frontoffice/oto/assets/icons/Screen1x2Icon";
import ScreenPipIcon from "app/liveShopping/frontoffice/oto/assets/icons/ScreenPipIcon";
import { setScreenOrientation } from "app/state/ducks/liveShopping/actions";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import emitter from "utilities/emitter";
import Own from "./Own";
import Other from "./Other";
import ArrowsAngleExpand from "react-bootstrap-icons/dist/icons/arrows-angle-expand";
import WaitingForVendor from "app/liveShopping/frontoffice/oto/components/Visio/WaitingForVendor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: props => (props.isMobile && !props.isAdmin) ? "column" : !props.isAdmin && !props.isWidget ? "row" : "column",
    height: "100%",
    width: "100%",
    position: 'relative',
    "& .Title, & .Description": {
      textAlign: "center",
    },
    "& .videoContainer": {
      width: "100%",
      height: "100%",
      background: theme.palette.colors.grey700,
      "& .showHover": {
        /* On your own video publisher we dont want to see anything */
        display: "none",
      },
      "& .label": {
        display: "none",
      },
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: props => props.isAdmin ? "row" : "column",
    height: props => !props.isAdmin && props.isWaiting || !props.isAdmin && props.isRinging ? "50%" : !props.isAdmin && props.isOngoing && props.currentDisplaySdk === "pip" ? "100%" : !props.isAdmin && props.isOngoing && props.isMobile ? "50%" : "100%",
    width: props => !props.isWidget && !props.isAdmin ? "70%" : "100%",
    flex: props => props.isWidget ? "1" : "none",
    position: "relative",
  },
  waiting: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.colors.white,
    background: theme.palette.colors.grey700,
    padding: "25px",
    height: "215px",
  },
  screenOrientation: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    gap: "8px",
    position: "absolute",
    width: "50px",
    height: "32px",
    right: "16px",
    top: "16px",
    background: "rgba(255, 255, 255, 0.1)",
    borderRadius: "8px",
    zIndex: 9999,
    cursor: "pointer",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.10)",
  },
  setFullScreenButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    position: "absolute",
    width: "32px",
    height: "32px",
    left: "10px",
    top: "10px",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "8px",
    transform: "rotate(-90deg)",
    zIndex: 9999,
    cursor: "pointer",
  },
  configButton: {
    position: "absolute",
    bottom: 5,
    right: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    borderRadius: "99px",
    zIndex: 9999,
  },
}));

const Visio = ({ visitor }) => {
  const isDesktop = useMediaQuery("@media (min-width:400px)");
  const isWidget = useMediaQuery("@media (max-width:400px)");
  const isMobile = useMediaQuery("@media (max-width:600px)");

  const isWaiting = visitor.status === "waiting";
  const isRinging = visitor.status === "ringing";
  const isOngoing = visitor.status === "ongoing";

  const ownRef = useRef(null);
  const isAdmin = isUser("user+");
  const isPip = useMediaQuery("(max-height: 90px)");

  const dispatch = useDispatch();
  const screenOrientation = useSelector((state) => state.liveShopping.screenOrientation);

  const [ownStream, setOwnStream] = useState(null);
  const [otherStream, setOtherStream] = useState(null);

  const { videoPublisher } = useSelector(publishersSelector);
  const { publishAudio, publishVideo } = useSelector(publishStateSelector);
  const credentials = useSelector(credentialsSelector);
  const session = useSelector(sessionSelector);
  const devices = useSelector(devicesSelector);
  const currentVisitor = useSelector(currentVisitorSelector);
  const streams = useSelector(streamsSelector);
  const { videoStreamId } = useSelector(streamsIdSelector);
  const [currentDisplaySdk, setCurrentDisplaySdk] = useState("default");
  const mode = currentVisitor?.options?.mode;
  const classes = useStyles({ isMobile, isAdmin, isPip, isWidget, isDesktop, publishVideo, device: currentVisitor.client_info.device, isWaiting, isOngoing, isRinging, currentDisplaySdk });
  console.log("currentDisplaySdk", currentDisplaySdk)

  useEffect(() => {
    emitter.on("postMessage", (message) => {
      message.current_display === "pip" ? setCurrentDisplaySdk("pip") : setCurrentDisplaySdk("default")
      return;
    });
  }, []);

  useEffect(() => {
    if (!isAdmin || !visitor?.options?.mode) return;
    if (visitor.options?.mode !== "video")
      dispatch(setScreenOrientation("admin_pip"));
  }, [visitor?.options?.mode, isAdmin]);

  // clear credentials for admin in order to take next call with new credentials
  useEffect(() => {
    if (visitor.terminated_at && credentials?.token)
      dispatch(setCredentials({}));
  }, [visitor.terminated_at, credentials]);

  useEffect(() => {
    // do not check anything while waiting
    if (!isAdmin && isWaiting) return;
    if (devices.length !== 0 || visitor?.options?.mode === "chat") return;
    console.log('getDevices ici')
    dispatch(getDevices(visitor.options?.mode || "video"));
  }, [isWaiting, visitor, devices]);

  useEffect(() => {
    const streamsList = Object.values(streams.streamsById);
    const ownStream = streamsList.find(({ id }) => id === videoStreamId);
    const otherStream = streamsList.find(({ id }) => id !== videoStreamId);

    setOwnStream(ownStream);
    setOtherStream(otherStream);
  }, [streams]);

  useEffect(() => {
    if (!otherStream) return;
    if (visitor.status === "ongoing") {
      dispatch(bindDom(otherStream, session));
    }
  }, [otherStream]);

  useEffect(() => {
    if (visitor.handled_at && !visitor.terminated_at && !credentials.token)
      dispatch(getVisioCredentials(visitor));
  }, [visitor.handled_at, visitor.terminated_at, credentials]);

  useEffect(() => {
    if (visitor.options.trigger_cart_refresh)
      emit("REFRESH_CART");
  }, [visitor.options.trigger_cart_refresh]);

  // handle mise au panier de l'admin
  useEffect(() => {
    if (!visitor.options?.add_to_cart?.length) return;

    visitor.options?.add_to_cart.forEach(({ variant_id, status }) => {
      if (status !== 'pending') return;

      emit("ADD", {
        id: variant_id,
        quantity: 1,
      });

    });

  }, [visitor]);

  useEffect(() => {
    if (!credentials.token) return;
    dispatch(joinSession());
  }, [credentials, devices]);

  useEffect(() => {
    // do not init publisher when waiting for admin
    if (!isAdmin && isWaiting) return;

    if (!session && !isWaiting || !ownRef) return;
    if (!isAdmin && visitor.options?.mode === "chat") return;
    if (videoPublisher) return;
    dispatch(initPublisher("video", "stream-own_video", { mode: isAdmin ? "video" : visitor.options.mode }));
  }, [session, isWaiting, ownRef]);

  // publish stream only when publisher is ready
  useEffect(() => {
    if (!session || visitor.status !== "ongoing" || !ownRef) return;
    if (!isAdmin && visitor.options?.mode === "chat") return;
    if (!videoPublisher) return;
    dispatch(publishStream("video"));
  }, [videoPublisher, visitor.status, ownRef, session]);

  const handleScreenOrientation = () => {
    dispatch(setScreenOrientation(screenOrientation === "1x2_admin" ? "admin_pip" : "1x2_admin"));
  };

  const handleFullScreen = () => {
    emit("RESIZE", { size: isWidget ? "fullscreen" : "default" });
  };

  console.log("otherStream", otherStream)

  if (!isAdmin && isWaiting) {
    return (
      <Box className={classes.root}>
        <Box className={classes.wrapper}>

          <Box className={classes.waiting}>
            <WaitingForVendor />
          </Box>

          <Actions visitor={visitor} />
        </Box>
        <Chat />
      </Box>
    )
  }

  const ScreenOrientationDisplay = () => {
    return (
      <>
        {isAdmin && <Box className={classes.screenOrientation} onClick={handleScreenOrientation}>
          {screenOrientation === "1x2_admin" && <ScreenPipIcon />}
          {screenOrientation === "admin_pip" && <Screen1x2Icon />}
        </Box>}
      </>
    )

  };

  return (
    <Box className={classes.root}>

      {mode === "audio" || mode === "chat" ? null : ScreenOrientationDisplay()}

      {
        (!isAdmin && isDesktop && !isPip && isOngoing) && (
          <Box onClick={handleFullScreen} className={classes.setFullScreenButton}>
            {isWidget ? <ArrowsAngleExpand color="white" size={18} /> : <ArrowsAngleContract color="white" size={18} />}
          </Box>
        )
      }

      {
        !visitor.terminated_at && (
          <>
            <Box className={classes.wrapper}>

              <Own visitor={visitor} />
              <Other visitor={visitor} otherStream={otherStream} />

              <Actions visitor={visitor} />
            </Box>
          </>
        )
      }

      {(!isAdmin) && <Chat />}

    </Box >
  );
};

Visio.propTypes = {};

Visio.defaultProps = {};

export default Visio;
