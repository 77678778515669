// generate me minutes 00 to 59 with a foreach loop
export const minutes = () => {
  let minutes = [];
  for (let i = 0; i < 60; i++) {
    if (i < 10) {
      minutes.push({ value: `0${i}`, label: `0${i}` });
    } else {
      minutes.push({ value: `${i}`, label: `${i}` });
    }
  }
  return minutes;
}

// generate me numbers 00 to 23
export const hours = () => {
  let hours = [];
  for (let i = 0; i < 24; i++) {
    if (i < 10) {
      hours.push({ value: `0${i}`, label: `0${i}` });
    } else {
      hours.push({ value: `${i}`, label: `${i}` });
    }
  }
  return hours;
}