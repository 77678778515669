import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, useTheme } from "@material-ui/core";
import { _t } from "i18n";
import { useSelector, useDispatch } from "react-redux";
import { productsSelector } from "app/state/ducks/ressources/selectors";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { getKeyword } from "app/state/ducks/application/selectors";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    textAlign: "left",
    paddingTop: "20px",
    paddingBottom: "10px",
    fontWeight: "bold",
  },
  line: {
    display: "flex",
    justifyContent: "space-between",
  },
  productNameTypo: {
    minWidth: "100px",
  },
  ProductAddedToCart: {
    maxWidth: "200px",
  },
  head: {
    backgroundColor: theme.palette.colors.primary,
  },
}));

const TopProducts = ({ topProducts }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const products = useSelector(productsSelector);
  const keyword = useSelector(getKeyword);

  dispatch(
    resourceFetch("products", {
      slug: ["event", keyword, "products"],
      callback: (error, response) => {
        if (error) {
          dispatch(setError(error));
          return;
        }
      },
    })
  );

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Top Products")}</Typography>
      <TableContainer>
        <Table>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell style={{ color: theme.palette.colors.white }}>{_t("Name")}</TableCell>
              <TableCell style={{ color: theme.palette.colors.white }}>{_t("Count")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {topProducts.map(({ id, count }) => {
              const product = products.find((product) => product.id === id);
              if (!product) return null;

              topProducts.length === 0 && _t("No products")
              return (
                <>
                  <TableRow>
                    <TableCell style={{ color: theme.palette.colors.grey700 }}>{product.name}</TableCell>
                    <TableCell style={{ color: theme.palette.colors.grey700 }}>{count}</TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

TopProducts.propTypes = {};

TopProducts.defaultProps = {};

export default TopProducts;
