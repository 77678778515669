import { makeStyles } from "@material-ui/core";
import { isRetractedSelector } from "app/state/ducks/liveShopping/selectors";
import { isRetracted as setRetracted } from "app/state/ducks/liveShopping/actions";
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import ArrowBarRight from "react-bootstrap-icons/dist/icons/arrow-bar-right";
import ArrowBarLeft from "react-bootstrap-icons/dist/icons/arrow-bar-left";
import clsx from "clsx";
import basil from "utilities/basil";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    width: "32px",
    height: "32px",
    borderRadius: "8px",
    background: theme.palette.colors.grey100,
    cursor: "pointer",
  },
}))

const RetractSideBar = ({ clsName }) => {
  const classes = useStyles()
  const isRetracted = useSelector(isRetractedSelector);
  const dispatch = useDispatch();

  const handleRetract = () => {
    dispatch(setRetracted(!isRetracted));
    basil.set("isRetracted", !isRetracted);
  };

  return (
    <div className={clsx(classes.root, clsName)} onClick={handleRetract}>
      {isRetracted ? (<ArrowBarRight />) : (<ArrowBarLeft />)}
    </div>
  )
};

export default RetractSideBar
