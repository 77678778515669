import { Box, Typography } from "@material-ui/core";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import React from "react";
import { _t } from "utilities/i18n";

const PhoneField = ({ phone, valid, handlePhoneNumberChange }) => {
  return (
    <Box>
      <Typography>{_t("Phone")}</Typography>
      <Input onChange={(e) => handlePhoneNumberChange(e)} value={phone} />
      {!valid && phone.length > 0 && <p style={{ color: "red", margin: 0 }}>{_t("Invalid phone number")}</p>}
    </Box>
  )
};

export default PhoneField;