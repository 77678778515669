/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, useTheme } from "@material-ui/core";
import Calendar from "./Calendar";
import dayjs from "dayjs";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Time from "./Time";
import Summary from "./Summary";
import { useDispatch, useSelector } from "react-redux";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import ResourceFactory from "orm/resources";
import SuccessPage from "./SucessPage";
import customParseFormat from "dayjs/plugin/customParseFormat";
import timezone from "dayjs/plugin/timezone";
import { currentCalendarSelector } from "app/state/ducks/liveShopping/selectors";
import { getUserTimezone } from "./Calendar/util";

dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "grid",
    placeContent: "center",
    "& -webkit-tap-highlight-color": "transparent",
  },
  title: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
    position: "fixed",
    bottom: "10px",
    width: "100%",
    padding: "0 20px",
  },
  subTitle: {
    fontSize: "16px",
    color: theme.palette.colors.grey500,
    marginBottom: "32px",
  },
}));

const Appointment = ({ onCancel }) => {
  const classes = useStyles();
  const currentVisitor = useSelector(currentVisitorSelector);
  const currentCalendar = useSelector(currentCalendarSelector);

  const [selectedDate, setSelectedDate] = useState(dayjs(currentVisitor?.scheduleds_at).locale("en") || dayjs().locale("en"));
  const [selectedTime, setSelectedTime] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);
  const currentDate = dayjs();
  const [today, setToday] = useState(currentDate);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const [success, setSuccess] = useState(false);
  const titles = [_t("Choose a date"), _t("Choose a time"), _t("Confirm")];
  const subTitles = [
    _t("Please select a date from the calendar below"),
    _t("Please select a time from the calendar below"),
    _t("Please confirm your appointment"),
  ];
  const locale = "en"; // TODO: get locale from user

  const contact = {
    firstname,
    lastname,
    phone,
    setFirstname,
    setLastname,
    setPhone,
    valid,
    setValid,
    email,
    setEmail
  };

  const scheduledAt = `${dayjs(selectedDate).format('YYYY-MM-DD')} ${selectedTime}:00`;
  const timezone = getUserTimezone();

  const handleSchedule = () => {
    const newVisitor = resourceFactory.create("Visitor", {
      scheduled_at: dayjs.tz(scheduledAt, timezone).format(),
      timezone,
      options: {
        ...currentVisitor.options,
        schedule: {
          firstname: firstname,
          lastname: lastname,
          phone: phone,
          email: email,
        },
      }
    });

    dispatch(resourceEdit(newVisitor, {
      slug: ["visitors", currentVisitor.hash, currentVisitor.token],
      patch: ["scheduled_at", "timezone", "options"],
      callback: (error, response) => {
        if (error) {
          console.log(error);
          return;
        }
        setSuccess(true)
      }
    }))
  };

  if (success)
    return <SuccessPage />

  const pageDisplay = () => {
    if (page === 0) {
      return (
        <Calendar
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          locale={locale}
          today={today}
          setToday={setToday}
        />
      );
    }

    if (page === 1) {
      return (
        <Time
          timezone={currentCalendar.timezone}
          selectedDate={selectedDate}
          locale={locale}
          today={today}
          setToday={setToday}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
        />
      );
    }

    if (page === 2) {
      return (
        <Summary
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          {...contact}
        />
      );
    }
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{titles[page]}</Typography>
      <Typography className={classes.subTitle}>{subTitles[page]}</Typography>
      {pageDisplay()}

      <Box className={classes.navigation}>
        <Button
          color={`#000000`}
          disabled={page === 0 && !onCancel}
          onClick={() => {
            if (page === 0 && onCancel)
              return onCancel();

            setPage((currentPage) => currentPage - 1);

            // reset selected time to avoid memorizing a potential unavailable time for another chosen day
            setSelectedTime(null);
          }}
        >
          {_t("Previous")}
        </Button>
        {page === 2 ? (
          <Button disabled={!valid} onClick={handleSchedule}>{_t("Confirm")}</Button>
        ) : (
          <Button
            disabled={(() => {
              if (page === 1 && !selectedTime) return true;
              return false;
            })()}
            onClick={() => setPage((currentPage) => currentPage + 1)}
          >
            {_t("Next")}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default Appointment;
