import React, { forwardRef, memo, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { quoteModelSelector } from "ressourcesDucks/selectors";

import ChatBubbleLightComponent from "app/liveShopping/backoffice/otm/ChatModo/components/ChatBubbleLight";
import QuoteActions from "app/liveShopping/backoffice/otm/ChatModo/containers/Quote/QuoteActions";

import { useTagsEnabled } from "app/liveShopping/backoffice/otm/ChatModo/tagHooks";
import { useIsUser } from "app/liveShopping/backoffice/otm/ChatModo/userHooks";

const ChatBubbleLight = forwardRef(({ id }, ref) => {
  //const element = useSelector((state) => quoteSelector(state, id));
  const element = useSelector((state) => quoteModelSelector(state, id));
  const tags_enabled = useTagsEnabled();
  const isAdmin = useIsUser("admin+");
  const displayTagsIcon = tags_enabled && isAdmin;

  return (
    <ChatBubbleLightComponent
      ref={ref}
      element={element}
      ActionElements={QuoteActions}
      tagsEnabled={displayTagsIcon}
      tagsDisplay={tags_enabled}
    />
  );
});

ChatBubbleLight.displayName = "ChatBubbleLightContainer";

ChatBubbleLight.propTypes = {
  id: PropTypes.string,
};

export default memo(ChatBubbleLight);
