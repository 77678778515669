import React, { useState, useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { TextSurveyItemResultNoLabel } from "appComponents/Viewers/TextSurveyItemResult";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
  useCurrentFieldHash,
  ItemMeta,
  EmptyLiveText,
  SurveyProgressBar,
} from "appComponents/SurveyForms";
import emitter from "core/emitter";
import { EVENT_RESET_SURVEY_FORM } from "core/emitter/events";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  optionRow: {},
  optionLabel: {
    marginLeft: theme.spacing(2),
  },
  answerType: {},
  itemHelp: {
    marginBottom: theme.spacing(1),
  },
  itemLabel: {},
  error: {
    color: theme.palette.error.main,
  },
  // textField: {
  //   width: "100%",
  // },
  textField: {
    marginBottom: "50px",
    width: "100%",
    "& .MuiOutlinedInput-multiline": {
      padding: "15px 12px",
      boxShadow: theme.palette.shadow,
      backgroundColor: theme.wisemblyColors.white,
      borderRadius: theme.spacing(0.625),
    },
    "& .MuiInputBase-input": {
      fontSize: 14,
      lineHeight: 1.25,
      color: theme.wisemblyColors.black,
      "&::placeholder": {
        color: theme.wisemblyColors.black,
        opacity: 0.5,
      },
    },
    "& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.border.default,
      },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.secondary.main,
    },
    "& .MuiOutlinedInput-root:hover.Mui-error .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.error.main,
      },
  },
  notchedOutline: {
    borderColor: theme.palette.border.textField,
  },
  input: {
    justifyContent: "center",
    alignItems: "center",

    //borderRadius: "10px",
  },
  inputRoot: {
    //backgroundColor: theme.wisemblyColors.transparentWhite,
    backgroundColor: theme.wisemblyColors.white,
    "&:hover": {
      backgroundColor: theme.wisemblyColors.white,
    },
  },
  inputFocused: {
    //backgroundColor: theme.wisemblyColors.transparentWhite,
    borderColor: theme.palette.border.textField,
    backgroundColor: theme.wisemblyColors.white,
    "&:hover": {
      backgroundColor: theme.wisemblyColors.white,
    },
  },
  inputLabel: {
    color: theme.wisemblyColors.grey,
  },
  inputText: {
    color: theme.wisemblyColors.black,
    boxShadow: "unset",
  },
  required: {
    display: (props) => (props.error ? "block" : "none"),
    color: (props) => (props.error ? theme.palette.error.main : "currentcolor"),
    position: "absolute",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
    fontSize: "12px",
    marginTop: 25,
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  tagcloud: {
    //100% - textInput - meta - textInputMargin this does not handle error msg size
    height: "calc(100% - 56px - 18px - 65px )",
  },
}));

export const CustomTextField = ({
  name,
  help,
  required,
  summary,
  displayType,
  withLiveResult,
  canAnswer,
  hash,
}) => {
  const [content, setContent] = useState("");

  const currentFieldHash = useCurrentFieldHash();

  //validation function
  const validate = (value) => {
    let error;

    if (currentFieldHash !== hash) return;
    if (required && (!value || isEmpty(value.trim()))) {
      error = _t("field is required");
    }
    return error;
  };
  const [field, meta, helpers] = useField({
    name,
    validate,
    // value: ratingValue,
    // type: "number",
  });
  const classes = useStyles({ error: meta.error });
  const { setValue } = helpers;

  const handleChange = (event) => {
    setValue(event.target.value, true);
    setContent(event.target.value);
  };
  useEffect(() => {
    const callback = () => {
      setContent("");
      setValue("", false);
    };

    emitter.on(EVENT_RESET_SURVEY_FORM, callback);

    return () => emitter.removeListener(EVENT_RESET_SURVEY_FORM, callback);
  }, [setValue]);

  const inputClasses = {
    root: classes.inputRoot,
    focused: classes.inputFocused,
    input: classes.inputText,
    notchedOutline: classes.notchedOutline,
  };
  const inputLabelClasses = {
    root: classes.inputLabel,
    focused: classes.inputLabel,
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      width="100%"
    >
      <SurveyProgressBar />
      <ItemMeta />
      {required && (
        <Typography className={classes.required}>
          {_t("This field is required")}
        </Typography>
      )}
      <TextField
        classes={{ root: classes.textField }}
        fullWidth
        type="text"
        variant="outlined"
        multiline
        name={name}
        id={name}
        placeholder={canAnswer ? _t("Enter your answer") : ""}
        value={content}
        onChange={handleChange}
        error={!!meta.error}
        InputProps={{ classes: inputClasses, disableUnderline: true }}
        InputLabelProps={{ classes: inputLabelClasses }}
        disabled={!canAnswer}
      />
      {withLiveResult && isEmpty(get(summary, "list")) && <EmptyLiveText />}

      {withLiveResult && !isEmpty(get(summary, "list")) && (
        <Box
          width="100%"
          className={clsx(displayType === "tagcloud" ? classes.tagcloud : null)}
        >
          <TextSurveyItemResultNoLabel
            summary={summary}
            displayType={displayType}
            live={withLiveResult}
          />
        </Box>
      )}
    </Box>
  );
};

CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  help: PropTypes.string,
  required: PropTypes.bool,
  withLiveResult: PropTypes.bool,
  summary: PropTypes.object,
  displayType: PropTypes.string,
  canAnswer: PropTypes.bool,
  hash: PropTypes.string,
};

export default CustomTextField;
