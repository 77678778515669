import { Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import CustomTooglesIcon from "app/liveShopping/assets/icons/CustomTooglesIcon";
import React, { useState } from "react";

import { _t } from "utilities/i18n";
import QueuesWidget from "../../Component/Queues/QueuesWidget";

const useStyles = makeStyles((theme) => ({
  waitingQueues: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 16px",
    cursor: "pointer",
    width: "100%",
  },
  waitingQueuesTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.grey500,
  },
  notify: {
    background: theme.palette.colors.white,
    border: `1px solid ${theme.palette.colors.primary}`,
  },
}));
const QueuesWidgetContainer = () => {
  const classes = useStyles();
  const [widget, setWidget] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Box className={classes.waitingQueues}>
        <Box style={{ display: "flex", gap: "9px" }} onClick={() => setWidget(!widget)}>
          <CustomTooglesIcon color={widget ? theme.palette.colors.primary : null} />
          <Typography style={widget ? { color: theme.palette.colors.primary } : null} className={classes.waitingQueuesTypo}>{_t("Waiting queues")}</Typography>
        </Box>
      </Box>

      {widget && <QueuesWidget />}
    </>
  )
}

export default QueuesWidgetContainer