import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { currentProjectSelector, otoDashboardDatesSelector } from "app/state/ducks/application/selectors";
import Header from "./Header";
import ErrorComponent from "../../Component/ErrorComponent";
import Global from "./Global/Global";
import Vendors from "./VendorsTable/Vendors";
import Graphs from "./Graphs/Graphs";
import { Tabs, Tab } from "@material-ui/core";
import { setOtoDashboardDates } from "app/state/ducks/application/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  top: {
    display: "flex",
    width: "100%",
    gap: "10px",
    marginBottom: "30px",
  },
  overflow: {
    overflowY: "auto",
    padding: "10px",
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": { display: "none" },
    margin: "0px 30px 16px 30px",
  },
  indicator: {
    backgroundColor: theme.palette.colors.primary,
  }
}));

const ProjectDashboard = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentProject = useSelector(currentProjectSelector);
  const [generalData, setGeneralData] = useState([]);
  const dates = useSelector(otoDashboardDatesSelector);

  const setDates = (dates) =>
    dispatch(setOtoDashboardDates(dates[0], dates[1], dates[2]));

  const [selectedView, setSelectedView] = useState(0);
  if (!currentProject) return null;
  if (!generalData) return <ErrorComponent text={_t("There is no data available at the moment")} />;

  return (
    <Box className={classes.root}>
      <Header
        generalData={generalData}
        setDates={setDates}
        dates={dates}
      />
      <Box className={classes.overflow}>
        <Tabs value={selectedView} onChange={(event, value) => setSelectedView(value)}
          classes={{ indicator: classes.indicator }}
          marginTop="mt-6">
          <Tab label={_t("Overview")} />
          <Tab label={_t("Detail")} />
          <Tab label={_t("Graphics")} />
        </Tabs>
        {selectedView === 0 && <Global currentProject={currentProject} dates={dates} />}
        {selectedView === 1 && <Vendors currentProject={currentProject} dates={dates} />}
        {selectedView === 2 && <Graphs currentProject={currentProject} dates={dates} />}
      </Box>
    </Box>
  );
};

export default ProjectDashboard;
