import URI from "urijs";

import basil from "core/basil";
import { Base64 } from "js-base64";

import { put, takeEvery } from "redux-saga/effects";
import { redirectToUrl } from "core/utils";

import { APPLICATION_INIT } from "applicationDucks/actionsTypes";

import emitter from "core/emitter";
import { EVENT_FLASH_ERROR_VANISH } from "core/emitter/events";
import { setCustomThemeColors } from "../actions";
import { SET_CUSTOM_THEME_COLORS } from "../actionsTypes";

export function* handleEmailSignupBootstrap(email, signup) {
  if (email) {
    email = Base64.decode(email);
    basil.set("invit:email", email);
    basil.set("invit:signup", signup === "1");
  }

  // clean url from these query args
  redirectToUrl(
    new URI(window.location).removeSearch("e").removeSearch("s").toString()
  );
  yield;
}

export function* handleCustomColors(primaryTextColor, primaryBackgroundColor) {
  yield put(setCustomThemeColors(primaryTextColor, primaryBackgroundColor));
}

export function* handleApiErr(err) {
  setTimeout(
    () => emitter.emit(EVENT_FLASH_ERROR_VANISH, err, 5 * 1000),
    5 * 1000
  );

  // clean url from these query args
  redirectToUrl(new URI(window.location).removeSearch("err").toString());
  yield;
}

function* handleQueryArgsCheck() {
  const { token, refresh_token, id, primaryTextColor, primaryBackgroundColor, ...rest } = new URI(window.location).search(true); // eslint-disable-line no-unused-vars


  if (primaryTextColor && primaryBackgroundColor) {
    console.log("color custom")
    yield handleCustomColors(primaryTextColor, primaryBackgroundColor);
  }
  if (Object.keys(rest).length === 0)
    return;

  const { e, s, err } = rest;

  if (e && s) {
    yield handleEmailSignupBootstrap(e, s);
  }

  if (err) {
    yield handleApiErr(err);
  }
}

export default function* queryArgsSaga() {
  yield takeEvery(APPLICATION_INIT, handleQueryArgsCheck);
}


