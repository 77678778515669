import { eventChannel } from "redux-saga";
import { select, call, take, fork, put } from "redux-saga/effects";

import emitter from "core/emitter";
import { sessionSelector, streamsIdSelector } from "../selectors";

import { unpublishStream, mute } from "../actions";

// this is the redux-way to bind listeners with an eventChannel
function createChannel(session) {
  const ownConnectionId = session?.connection?.id;

  return eventChannel((emit) => {
    session.on("signal:msg", (event) => emit({ event, ownConnectionId }));

    // eventChannel must return an unsubscribe function
    return () => {};
  });
}

function* handleSignal({ event, ownConnectionId }) {
  let { data, from } = event;

  // ignore self signal events
  if (from.connexionId === ownConnectionId) return;

  try {
    data = JSON.parse(data);
  } catch {
    data = {};
  }

  const { action, streamId, value } = data;
  const { videoStreamId, screenStreamId } = yield select(streamsIdSelector);

  // ignore events for others than me
  if (![videoStreamId, screenStreamId].includes(streamId)) return;

  switch (action) {
    case "leaveStage":
      emitter.emit("VONAGE_NOTIFICATION", { action: "VONAGE_KICK_STAGE" });
      yield put(unpublishStream(videoStreamId === streamId ? "video" : "screen"));
      break;
    case "toggleAudio":
      emitter.emit("VONAGE_NOTIFICATION", {
        action: "VONAGE_MUTE_AUDIO",
        value,
      });
      yield put(mute("audio", value));
      break;
    case "toggleVideo":
      emitter.emit("VONAGE_NOTIFICATION", {
        action: "VONAGE_MUTE_VIDEO",
        value,
      });
      yield put(mute("video", value));
      break;
  }
}

export function* subscribeToSignals() {
  const session = yield select(sessionSelector);
  const channel = yield call(createChannel, session);

  while (true) {
    const event = yield take(channel);

    try {
      yield fork(handleSignal, event);
    } catch (e) {
      console.log("[Vonage] unable to handle signal event", e);
    }
  }
}
