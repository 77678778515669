import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useFormik } from "formik";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import { useDispatch, useSelector } from "react-redux";
import UrlGestion from "./UrlGestion/UrlGestion";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.colors.white,
    width: 400,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: 20,
    borderRadius: "5px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginBottom: "15px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

const Edit = ({ title, hashGroup, setOpen, urls }) => {
  const classes = useStyles();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const dispatch = useDispatch();
  const [formValues, setFormValues] = useState([{ type: "string", url: "" }]);

  const formik = useFormik({
    initialValues: {
      title,
      urls: null,
    },
  });

  useEffect(() => {
    parseArrayToObjects();
  }, []);

  const parseInputsUrlToArray = (inputs) => {
    return inputs.map((input) => {
      if (input.type === "pattern") {
        return input.type + "::" + input.url;
      } else {
        return input.url;
      }
    });
  };

  const parseArrayToObjects = () => {
    const datas = [];
    console.log("urls", urls);
    urls.forEach((url) => {
      console.log(url);
      if (!url.includes("pattern::")) {
        datas.push({ type: "string", url: url });
      }
      if (url.includes("pattern::")) {
        datas.push({ type: "pattern", url: url.substring(9) });
      }
    });

    console.log(datas);
    setFormValues(datas);
  };
  useEffect(() => {
    formik.setFieldValue("urls", parseInputsUrlToArray(formValues));
  }, [formValues]);

  const handleEdit = () => {
    const newGroup = resourceFactory.create("Group", formik.values);
    dispatch(
      resourceEdit(newGroup, {
        slug: ["groups", hashGroup],

        callback: (error, response) => {
          if (response) {
            setOpen(false);
          }
        },
      })
    );
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Edit a group")}</Typography>
      <Box className={classes.form}>
        <Input name={"title"} placeholder={_t("Edit the name")} onChange={formik.handleChange} value={formik.values.title} />
        <Typography className={classes.title}>{_t("Url Gestion")}</Typography>
        <UrlGestion formValues={formValues} setFormValues={setFormValues} hashGroup={hashGroup} urls={urls} />
        <Button onClick={handleEdit}>{_t("Edit")}</Button>
      </Box>
    </Box>
  );
};

Edit.propTypes = {};

Edit.defaultProps = {};

export default Edit;
