import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Tooltip from "@material-ui/core/Tooltip";

import get from "lodash/get";

import { _t, _n } from "i18n";
import PercentIndicator from "./PercentIndicator";
import { getParsedPercent } from "utilities/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  detailsPercent: {},
  percentText: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
    minWidth: theme.spacing(10),
  },
  itemLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "2.13",
    letterSpacing: "0.78px",
  },
}));

const GridSurveyItemResult = ({ item }) => {
  const classes = useStyles();
  const theme = useTheme();
  const choices = get(item, "options.choices", []);
  let entries = get(item, "options.entries", []);
  const sortedEntries = entries.sort(
    (a, b) => a.position || 0 - b.position || 0
  );
  const summary = item.summary || {};

  const result = sortedEntries.map((entry, i) => {
    const answersCount = get(summary, `${entry.hash}.${choice.hash}.count`);

    return (
      <Box key={i} mb={2}>
        <Divider />
        <Typography className={classes.itemLabel}>{entry.label}</Typography>
        <Divider />
        <Box mx={2}>
          {choices.map((choice, j) =>
            /* prettier-ignore */
            <Tooltip
            key={j}
            title={_n("{count} answer", "{count} answers", answersCount, { count: answersCount })}
            aria-label="answers"
          >
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <Typography className={classes.itemLabel}>
                  {choice.label}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.detailsPercent}
              >
                <PercentIndicator
                  percent={get(summary, `${entry.hash}.${choice.hash}.percent`)}
                  width={theme.spacing(20)}
                />

                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  className={classes.percentText}
                >{`${getParsedPercent(
                  get(
                    summary,
                    `${entry.hash}.${choice.hash}.percent`
                  )
                )} %`}</Box>
              </Box>
            </Box>
          </Tooltip>
          )}
        </Box>
      </Box>
    );
  });

  return (
    <Box className={clsx("GridSurveyItemResult", classes.root)}>
      <Box>{result}</Box>
    </Box>
  );
};

GridSurveyItemResult.propTypes = {
  item: PropTypes.object,
};

export default GridSurveyItemResult;
