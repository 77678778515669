import { ucfirst } from "core/utils";
import { getRelatedResource } from "./index";

export default function handleRelationships(
  session,
  resource,
  relationships,
  name,
  includedMap
) {
  // remove all previous relationships before adding new ones
  resource[name] && resource[name].clear && resource[name].clear();

  relationships.forEach((locator) => {
    // const relationName = locator !== null ? locator.type : name
    const relationName = name;

    // @DOC
    // unassign relationship if null
    if (locator === null) {
      // handle unset method if exists (to avoid trying to update wrong side of O2O relationship)
      const unset = `unset${ucfirst(relationName)}`;

      if (resource[unset]) {
        resource[unset]();
      } else {
        // otherwise just set to undefined
        resource[relationName] = undefined;
      }

      return;
    }

    const relatedResource = getRelatedResource(session, includedMap, locator);

    // no related resource found neither in included or store, do nothing atm
    if (relatedResource === null) {
      return;
    }

    // handle oneToOne and fk relationships
    let fk = `set${ucfirst(relationName)}`;
    if (resource[fk]) {
      resource[fk](relatedResource);

      return;
    }

    // handle manyToMany or reverse fk relationships
    let many = `add${ucfirst(relationName)}`;
    if (resource[many]) {
      resource[many](relatedResource);

      return;
    }

    // try by related ressource
    if (!resource.model || !resource.model.backendName) {
      return;
    }
    const parentName = resource.model.backendName;

    fk = `set${ucfirst(parentName)}`;
    if (relatedResource[fk]) {
      relatedResource[fk](resource);
      return;
    }

    many = `add${ucfirst(relationName)}s`;
    if (relatedResource[many]) {
      relatedResource[many](resource);
    }
  });
}
