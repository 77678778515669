import { forceArray } from "utilities/utils";

import {
  DATA_EMPTY_ERROR,
  NOT_AN_OBJECT_ERROR,
  NOT_JSON_API_COMPLIANT_ERROR,
} from "./errors";

class Parser {
  parseBatchData(json) {
    if (!json || typeof json !== "object") {
      throw new Error(NOT_AN_OBJECT_ERROR);
    }
    let finalResources = [];
    let finalIncluded = [];
    let finalMeta = {}; //should not merge meta
    let finalLinks = []; //not handled
    json.data.forEach((item) => {
      const { resources, included, meta, links } = this.parse(item.response);
      finalResources = [...finalResources, ...resources];
      finalIncluded = [...finalIncluded, ...included];
    });

    return {
      resources: finalResources,
      included: finalIncluded,
      meta: finalMeta,
      links: finalLinks,
    };
  }
  parse(json) {
    if (!json || typeof json !== "object") {
      throw new Error(NOT_AN_OBJECT_ERROR);
    }

    let resources = [];
    if (Object.prototype.hasOwnProperty.call(json, "data")) {
      if (!json.data) {
        throw new Error(NOT_JSON_API_COMPLIANT_ERROR);
      }

      resources = forceArray(this.parseData(json.data));
    }

    const included = json.included ? this.parseData(json.included) : [];
    const links = json.links || [];
    const meta = json.meta || {};

    return { resources, included, meta, links };
  }

  parseData(data) {
    if (Array.isArray(data)) {
      if (!data.length) {
        return [];
      }

      return data.map((item) => this.parseResource(item));
    }

    if (!Object.keys(data).length) {
      throw new Error(DATA_EMPTY_ERROR);
    }

    return this.parseResource(data);
  }

  parseResource(data) {
    if (!data.type || !data.attributes) {
      throw Error(NOT_JSON_API_COMPLIANT_ERROR);
    }

    // clone data & re-inject id into attributes
    // also tell that resource is persisted in DB since retrieved from API
    const newData = Object.assign({}, data);
    newData.attributes = Object.assign({}, data.attributes, {
      id: data.id,
      _persisted: true,
    });
    newData.relationships = this.parseRelationships(data.relationships);

    return newData;
  }

  // re-work relationships, remove unneccessary data layer and force array for single relationship
  parseRelationships(data) {
    const relationships = {};

    for (let relationshipType in data) {
      const relationship = data[relationshipType].data;

      // ignore empty arrays relationships
      // @todo may be wrong when we'll handle many to many..
      //for empty or not set landing the landing relationship contains {data: null}
      // and not {data: []} so i added the {data:null} case

      // if (relationship === null) {
      //   continue;
      // }

      relationships[relationshipType] = forceArray(relationship);
    }
    return relationships;
  }
}

export default new Parser();
