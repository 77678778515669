import { put, call, takeEvery, all } from "redux-saga/effects";
import { resourceFetch } from "ressourcesDucks/actions";
import { setLiveShoppingBootstrap } from "../actions";
import { APPLICATION_INIT } from "../../application/actionsTypes";
import globalStore from "appStore";

function* bootstrapInit() {
  // no bootstrap for visio widget only for BO
  if (
    window.location.pathname.includes("/visio/") ||
    window.location.pathname.includes("/ls/")
  ) {
    return false;
  }

  const bootstrap = () => new Promise((resolve) => {
    globalStore.dispatch(resourceFetch("custom", {
      slug: ["liveshopping", "bootstrap"],
      once: true,
      callback: function (error, response) {
        if (!response?.single) {
          resolve({ error });
          return;
        };
        resolve(response.single.attributes.data);
      },
    }));
  });

  const data = yield call(bootstrap);
  yield put(setLiveShoppingBootstrap(data));
}

export default function* bootstrapSaga() {
  yield all([
    yield takeEvery(APPLICATION_INIT, bootstrapInit),
  ]);
}
