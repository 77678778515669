import dayjs from "dayjs";
import sha1 from "crypto-js/sha1";
import { generateHash } from "core/uuid";
import { SIGNIN_APP_ID, SIGNIN_APP_SECRET } from "settings/config";

class Session {
  type = "apiSession";

  constructor({
    id = generateHash(),
    token,
    refresh_token,
    expires_at,
    broadcasted_at,
    refreshed_at,
    email,
    user_id,
    is_root,
    is_verified,
    lang,
  } = {}) {
    this.id = id;
    this.token = token;
    this.refresh_token = refresh_token;
    this.expires_at = expires_at;
    this.broadcasted_at = broadcasted_at;
    this.refreshed_at = refreshed_at;
    this.email = email;
    this.user_id = user_id;
    this.is_root = is_root;
    this.is_verified = is_verified;
    this.lang = lang;

    // if session never refreshed yet, last refresh <=> broadcast date
    if (!this.refreshed_at)
      this.refreshed_at = this.broadcasted_at;
  }

  isRoot() {
    return this.isRoot === true;
  }

  isAnon() {
    return undefined === this.user_id;
  }

  isExpired() {
    return Boolean(dayjs(this.expires_at * 1000) < dayjs());
  }

  isAuthenticated() {
    return Boolean(this.token && this.refresh_token);
  }

  // if session reaches its half life, we should refresh it
  shouldBeRefreshed() {
   return false;
  }

  get locator() {
    return { id: this.id, type: this.type };
  }

  // no need to expose the whole object, only these two fileds ATM are used to refresh session
  get json() {
    const { id, token, refresh_token } = this;

    return {
      id,
      type: this.type,
      attributes: {
        token,
        refresh_token,
      },
    };
  }

  generateSigninJson(email, secret) {
    const attributes = {
      app_id: SIGNIN_APP_ID,
      hash: sha1(
        (email ? email : "") + SIGNIN_APP_ID + SIGNIN_APP_SECRET
      ).toString(),
    };

    if (email && secret) {
      Object.assign(attributes, {
        email: email,
        secret: secret,
      });
    }

    return {
      type: this.type,
      attributes: attributes,
    };
  }
}

export default Session;
