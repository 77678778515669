import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import React from "react";

const LoadingBox = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="200px"
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingBox;
