import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { _t } from "utilities/i18n";
import { useDispatch, useSelector } from "react-redux";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import Validate from "./Validate";
import TextArea from "app/liveShopping/backoffice/otm/components/reusables/TextArea";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 30px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginBottom: "20px",
  },
  field: {
    marginBottom: "20px",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "5px",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",

  },
}));

const ContactForm = ({ sent, setSent, setOpenEmail }) => {
  const classes = useStyles();
  const [fisrtname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const visitor = useSelector(currentVisitorSelector);

  const data = {
    options: {
      ...visitor.options,
      form: {
        firstname: fisrtname,
        lastname: lastname,
        email: email,
        message: message
      }
    }
  }
  const handleSend = () => {
    dispatch(resourceEdit(Object.assign(visitor, data), {
      slug: ["visitors", visitor.hash, visitor.token],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
        }
        setSent(true)
      }
    }))
  };

  useEffect(() => {
    if (sent)
      setTimeout(() => setOpenEmail(false), 3000)
  }, [sent])

  if (sent) return <Validate setSent={setSent} />

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Be contacted by the brand")}</Typography>
      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Firstname")}</Typography>
        <Input onChange={(e) => setFirstname(e.target.value)} placeholder={_t("Enter your firstname")} />
      </Box>
      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Lastname")}</Typography>
        <Input onChange={(e) => setLastname(e.target.value)} placeholder={_t("Enter your lastname")} />
      </Box>
      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Email")}</Typography>
        <Input onChange={(e) => setEmail(e.target.value)} placeholder={_t("Enter your email")} />
      </Box>
      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Message")}</Typography>
        <TextArea onChange={(e) => setMessage(e.target.value)} placeholder={_t("Enter your message")} />
      </Box>

      <Box className={classes.buttonWrapper}>
        <Button disabled={email === ""} onClick={handleSend} w100>{_t("Be contacted")}</Button>
      </Box>
    </Box>
  );
};

export default ContactForm;