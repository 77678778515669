import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class EventSession extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = EventSession;
  }

  setEvent(Event) {
    this.event = Event.id;
  }
}

EventSession.modelName = "EventSession";
EventSession.backendName = "eventSession";

EventSession.fields = {
  id: attr(),
  title: attr(),
  is_accessible: attr(),
  started_at: attr(),
  ended_at: attr(),
  ongoing: attr(),
  event: fk("Event", "sessions"),
};

export default EventSession;
