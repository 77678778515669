import React, { useState } from "react";
import { Box, ButtonBase, makeStyles, Typography } from "@material-ui/core";
import { generateSummary } from "./Calendar/util";
import dayjs from "dayjs";
import { Calendar, ClockFill, XCircle } from "react-bootstrap-icons";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Appointment from ".";
import { useDispatch, useSelector } from "react-redux";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import { emit } from "utilities/liveshopping-sdk";
import { endCallVisitor } from "app/liveShopping/backoffice/oto/Component/Live/service";

import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    width: "100%",
    height: "100%",

  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  text: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "16px",
  },
  cta: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    width: "100%",
    marginTop: "32px",
  },
  ctaCancel: {
    backgroundColor: theme.palette.colors.error,
  },
  exit: {
    position: "absolute",
    bottom: "20px",
    right: "50%",
    transform: "translateX(50%)",

    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const AppointmentInfo = ({ visitor, calendar }) => {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleCancel = () => {
    endCallVisitor(dispatch, visitor, () => { })
    emit("QUIT");
  };

  if (edit === true) {
    return <Appointment />
  }
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        {calendar?.slots_duration_minutes + " " + _t("minutes Meeting")}
      </Typography>
      <Typography className={classes.text}>
        <ClockFill />
        {calendar?.slots_duration_minutes + " " + _t("minutes")}
      </Typography>
      <Typography className={classes.text}>
        <Calendar />
        {generateSummary(dayjs(visitor.scheduled_at).toDate(), dayjs(visitor.scheduled_at).tz(visitor.timezone).format("HH:mm"))}
      </Typography>

      <Box className={classes.cta}>
        <Button onClick={() => setEdit(true)}>{_t("Edit")}</Button>
        <Button onClick={handleCancel} clsName={classes.ctaCancel}>{_t("Cancel the appointment")}</Button>
      </Box>

      <ButtonBase className={classes.exit} onClick={() => emit("CLOSE")}>
        <XCircle size={45} />
      </ButtonBase>
    </Box>
  );
};

export default AppointmentInfo;
