import Player from "@vimeo/player";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import YouTubePlayer from 'youtube-player';
import {
  setPlay,
  setPlayerLoaded,
  setPlayerVolume,
  setReplayDuration,
} from "app/state/ducks/liveShopping/actions";

// LIVE LOAD
export const loadLivePlayer = (provider, dispatch, status) => {
  if (null === provider) {
    console.warn("Url not supported", live_media.url);
    dispatch(setPlayerLoaded("PLAYER_NOT_SUPPORTED"));
    return;
  }

  const player = initPlayer(provider, dispatch, status, () => {
    dispatch(setPlayerLoaded(true));
    const playButton = document.getElementById("buttonPlayerId");
    playButton?.addEventListener("click", () => player.play());
  });

  window.player = player;

  return player;
};

// REPLAY LOAD
export const loadReplayPlayer = (provider, dispatch) => {

  const player = initPlayer(provider, dispatch, 'replay', () => {
    console.log('>>>> INIT PLAYER');

    dispatch(setPlayerLoaded(true));
    const playButton = document.getElementById("buttonPlayerId");
    playButton?.addEventListener("click", () => player.play());

    player
      .getDuration()
      .then((duration) => {
        console.log('duration', duration);
        dispatch(setReplayDuration(duration));
      })
      .catch(function (error) {
        // an error occurred
      });
  });

  return player;
};

// GET THE PROVIDER PLAYER
export const getProviderPlayer = (url) => {
  const regexVimeo = /^https:\/\/player\.vimeo\.com\/video\/(\d+)/im;
  const vimeoMatch = regexVimeo.exec(url);

  if (vimeoMatch)
    return { provider: "vimeo", id: vimeoMatch[1] };

  const regexYoutube = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/im;
  const youtubeMatch = regexYoutube.exec(url);

  if (youtubeMatch)
    return { provider: "youtube", id: youtubeMatch[1] };

  const regexVideojs = /.(mp4|avi|m3u8)/im
  const videojsMatch = regexVideojs.exec(url);

  if (!videojsMatch) return null;

  switch (videojsMatch[1]) {
    case 'mp4': return { provider: "videojs", url, type: "video/mp4" };
    case 'avi': return { provider: "videojs", url, type: "video/x-msvideo" };
    case 'm3u8': return { provider: "videojs", url, type: "application/x-mpegURL" };
    default: return { provider: "videojs", url };
  }
};

const tryToAutoplay = (player, dispatch) => {
  console.log('player ready, try to autoplay')

  const playMuted = () => {
    player.setVolume(0);
    dispatch(setPlayerVolume(0));

    player.play();
    dispatch(setPlay(true));

    const muteUnmuteButton = document.getElementById("muteUnmuteButton");

    // could happen in pip mode
    if (!muteUnmuteButton) return;

    window._hasUnmutedOnce = false;

    muteUnmuteButton.onclick = () => {
      if (window._hasUnmutedOnce) return;

      player.setVolume(1);
      dispatch(setPlayerVolume(1));
      window._hasUnmutedOnce = true;
    };
  }

  try {
    player.play()
      .then(() => {
        console.log('OMAGAD autoplay worked !!');
        dispatch(setPlay(true));
      })
      .catch((error) => {
        console.log('Autoplay is prevented, fallback to muted autoplay', error);
        playMuted();
      });
  } catch (error) {
    console.log('Promise not supported, take any unnecessary risks, start muted');
    playMuted();
  }
};

// INIT THE PLAYER
const initPlayer = ({ id, provider, url, type }, dispatch, status, onReady) => {
  let options, player;

  switch (provider) {
    /**
     * Vimeo has an inconsistent player bw VOD and LIVE
     * VOD can be autoplayed muted, while LIVE not :(
     * hence the dual config below..
     */
    case "vimeo":
      const isLiveStreaming = status === 'live' || status === 'ready_for_live';

      options = {
        id,
        controls: false,
        responsive: true,
        loop: !isLiveStreaming,
        autoplay: !isLiveStreaming,
        mute: !isLiveStreaming,
      };

      console.log('vimeo options', options);

      player = new Player("player", options);

      player.ready().then(() => {
        if (!isLiveStreaming) {
          tryToAutoplay(player, dispatch);
        } else {
          player.pause();
          player.setVolume(1);
          dispatch(setPlay(false));
          dispatch(setPlayerVolume(1));
        }

        onReady();
      });

      return player;

    case "youtube":
      options = {
        videoId: id,
        width: "100%",
        height: "100%",
        playerVars: {
          autoplay: 1,
          cc_load_policy: 0,
          color: 'white',
          controls: 0,
          disablekb: 1,
          enablejsapi: 1,
          fs: 0,
          modestbranding: 1,
          playsinline: 1,
          rel: 0,
          showinfo: 0,
        },
      };

      console.log('youtube options', options);

      const yt = new YouTubePlayer("player", options);

      yt.on("ready", () => {
        onReady();
      });

      yt.on('stateChange', function (event) {
        console.log('onStateChange', event);
      });


      player = {
        player: yt,
        getDuration: () => yt.playerInfo.duration,
        setCurrentTime: (time) => new Promise(resolve => resolve(vjs.currentTime(time))),
        currentTime: () => yt.playerInfo.currentTime,
        on: (key, callback) => console.log,
        off: (key, callback) => console.log,
        play: () => yt.playVideo(),
        pause: () => yt.stopVideo(),
        setVolume: (volume) => volume === 0 ? yt.mute() : yt.unMute(),
        destroy: () => { },
        setLoop: (value) => { },
      };

      return player;

    case "videojs":
      options = {
        // aspectRatio: '9:16',
        fluid: true,
        controls: true,
        preload: "auto",
        fill: true,
        loop: status === 'prelive',
        sources: [
          {
            src: url,
            type: type ? type : "application/x-mpegURL",
          },
        ],
        autoplay: false,
        bigPlayButton: false,
        controlBar: {
          playToggle: false,
          captionsButton: false,
          chaptersButton: false,
          subtitlesButton: false,
          remainingTimeDisplay: false,
          progressControl: {
            seekBar: false,
          },
          fullscreenToggle: false,
          playbackRateMenuButton: false,
          pictureInPictureToggle: false,
          audioTrackButton: false,
          volumePanel: false,
        }
      };

      const vjs = videojs(document.getElementById("player"), options, () => {
        // player.getDuration = new Promise(resolve => resolve(player.duration()));
        // player.setCurrentTime = (time) => new Promise(resolve => resolve(player.currentTime(time)));

        console.log('player ready, try to autoplay')

        onReady();
      });

      player = {
        player: vjs,
        getDuration: () => new Promise(resolve => setTimeout(() => resolve(vjs.duration()), 1000)),
        setCurrentTime: (time) => new Promise(resolve => resolve(vjs.currentTime(time))),
        currentTime: () => vjs.currentTime(),
        on: (key, callback) => vjs.on(key, callback),
        off: (key, callback) => vjs.off(key, callback),
        play: () => vjs.play(),
        pause: () => vjs.pause(),
        setVolume: (volume) => vjs.muted(volume === 0),
        destroy: () => vjs.dispose(),
        setLoop: (value) => vjs.setLoop(value),
      };

      window.vjs = player;

      tryToAutoplay(player, dispatch);

      return player;
  }

  return false;
};
