import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import TableComponent from "./Table";
import ChatContainer from "../../Container/MultiChat/ChatContainer";
import { useDispatch } from "react-redux";
import { setCurrentVisitor } from "app/state/ducks/liveShopping/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
}));

const MultiChat = () => {
  const classes = useStyles();
  const [openVisitors, setOpenVisitors] = useState([]);
  const MAX = 3;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentVisitor(null))
  }, []);

  const handleChat = (visitor) => {
    // openVisitor already contains the visitor, do nothing
    if (openVisitors.includes(visitor)) return;

    // openVisitor is empty, add the new visitor
    if (openVisitors.length < MAX) setOpenVisitors([...openVisitors, visitor]);

    // openVisitor is full, remove the last visitor and add the new one
    if (openVisitors.length === MAX) setOpenVisitors([...openVisitors.slice(1), visitor]);
  };


  return (
    <Box className={classes.root}>
      <TableComponent handleChat={handleChat} openVisitors={openVisitors} />

      {openVisitors.map((visitor, index) => (
        <ChatContainer visitor={visitor} position={index} setOpenVisitors={setOpenVisitors} />
      ))}
    </Box>
  )
};

export default MultiChat;