import { NetworkError, ReponseParseError } from "core/errors";

import throwRequestError from "./throwRequestError";

/*
 *
 * sendRequest
 * Sends the request to the given URL
 * Parses the returned data
 * Catches network errors (internet not connected, 403...)
 * @return {Object} response: Response of the fetch request
 * @return {Object} json: Parsed response
 *
 */
async function sendRequest(targetUrl, options) {
  let response;

  try {
    // imported from app/index.js polyfills (whatwg-fetch)
    response = await fetch(targetUrl, options);
  } catch (error) {
    throw new NetworkError(error);
  }

  if (options.rawResponse) {
    return await response.text();
  }

  let json;

  try {
    json = await response.json();
  } catch (error) {
    if (response.status === 204) {
      json = null;
    } else {
      throw new ReponseParseError(error);
    }
  }

  // If everything went well, return the response
  if (response.ok) {
    return json;
  }

  // Otherwise, handle request error
  // And throw an error that will be handled by the saga wrapper
  return throwRequestError(response, json);
}

export default sendRequest;
