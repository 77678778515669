import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import Divider from "../Divider";
import MenuItem from "coreUiKit/inputs/MenuItem";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import CustomChevronDown from "coreUiKit/assets/icons/CustomChevronDown";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { RESET_MESSAGES_FILTER } from "utilities/emitter/events";
import emitter from "utilities/emitter";
import { _t } from "i18n";
import memo, { renderLog } from "core/memo";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: (props) => (props.open ? "flex" : "none"),
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      minWidth: "345px",
      backgroundColor: "white",
      //position: "relative",
      height: "100%",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 2,
    },
    content: {
      position: "absolute",
      bottom: "10px",
      left: "10px",
      right: "10px",
      width: "calc(100% - 20px)",
      maxHeight: "calc(100% - 37px)",
      overflow: "auto",
      paddingTop: "18px",
      paddingBottom: "10px",
      borderRadius: "10px",
      backgroundColor: theme.palette.colors.white,
      scrollbarWidth: "none",
      MsOverflowStyle: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    defaultFilters: {},
    adminFilters: {},
    tagsFilters: {},
    chevronRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      color: theme.palette.colors.grey,
      marginBottom: "21px",
    },
    roundColor: {
      height: "10px",
      width: "10px",
      borderRadius: "5px",
      marginRight: "5px",
      backgroundColor: theme.palette.colors.greyLight,
    },
    tagTitle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    sectionTitle: {
      color: theme.palette.colors.greyDark,
      marginBottom: "5px",
      paddingLeft: "15px",
      paddingRight: "15px",
    },
    chevronIcon: {
      cursor: "pointer",
      display: "flex",
    },
  }),
  {
    name: "OneChatFilter",
  }
);
const checkboxType = ["tags", "admin"];
const adminFilters = () => [
  {
    title: _t("Favorite message"),
    hash: "favorite",
    type: "checkbox",
  },
  {
    title: _t("Not processed"),
    hash: "unprocessed",
    type: "checkbox",
  },
];
const selectOptions = ["all_messages", "reacted", "mine"];
const defaultFilters = () => [
  {
    title: _t("All messages"),
    hash: "all_messages",
    type: "select",
  },
  {
    title: _t("Populars"),
    subtitle: _t("Show only messages with with reactions."),
    hash: "reacted",
    type: "select",
  },
  {
    title: _t("My messages"),
    subtitle: _t("Show only my messages."),
    hash: "mine",
    type: "select",
  },
];

const Filter = ({ admin, tags, open, onClose, onFilterChange }) => {
  const classes = useStyles({ open });
  const [defaultOption, setDefaultOption] = useState([]);
  const [adminOption, setAdminOption] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const onReset = () => {
    setDefaultOption([]);
    setAdminOption([]);
    setSelectedTags([]);
    onFilterChange({
      tags: [],
      default: [],
      admin: [],
    });
  };
  useEffect(() => {
    emitter.on(RESET_MESSAGES_FILTER, onReset);

    return () => {
      emitter.off(RESET_MESSAGES_FILTER, onReset);
    };
  }, []);

  let list =
    !isEmpty(tags) &&
    tags.map((elem) => {
      return {
        title: (
          <Box className={classes.tagTitle}>
            <Box
              className={classes.roundColor}
              style={{
                backgroundColor: elem.color,
              }}
            ></Box>
            {elem.name}
          </Box>
        ),
        hash: elem.hash,
        type: "checkbox",
      };
    });
  const tagsOptions = list || [];
  const handleClick = (selected, type) => {
    if (selected !== null && selected !== undefined) {
      const previousSelection = {
        tagsOptions: tags,
        tags: [...selectedTags],
        default: [...defaultOption],
        admin: [...adminOption],
      };
      let nextSelection = [];
      const config = {
        admin: { data: adminOption, setter: setAdminOption },
        tags: { data: selectedTags, setter: setSelectedTags },
        default: { data: defaultOption, setter: setDefaultOption },
      };
      nextSelection = cloneDeep(config[type]?.data);

      if (nextSelection.includes(selected) && checkboxType.includes(type)) {
        const index = nextSelection.indexOf(selected);
        if (index > -1) {
          nextSelection.splice(index, 1);
        }
      } else {
        if (selectOptions.includes(selected)) {
          nextSelection = [];
        }
        if (selected !== "") {
          nextSelection.push(selected);
        }
      }
      config[type].setter(nextSelection);

      onFilterChange &&
        onFilterChange({
          ...previousSelection,
          [type]: nextSelection,
        });
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.chevronRow}>
          <Box component="a" onClick={onClose} className={classes.chevronIcon}>
            <CustomChevronDown size={20} color="currentColor" />
          </Box>
        </Box>
        <Hidden xsUp={!admin || isEmpty(tags)}>
          <Box>
            <Typography variant="smallBody13" className={classes.sectionTitle}>
              {_t("Tags")}
            </Typography>
          </Box>
          <Box className={classes.tagsFilters}>
            {tagsOptions &&
              tagsOptions.map((elem) => <MenuItem key={elem.hash} onClick={(hash) => handleClick(hash, "tags")} item={elem} selected={selectedTags.includes(elem?.hash)} type={elem.type} />)}
            <Divider />
          </Box>
        </Hidden>
        <Hidden xsUp={!admin}>
          <Box>
            <Typography variant="smallBody13" className={classes.sectionTitle}>
              {_t("Administration")}
            </Typography>
          </Box>
          <Box className={classes.adminFilters}>
            {adminFilters().map((elem) => (
              <MenuItem key={elem.hash} onClick={(hash) => handleClick(hash, "admin")} item={elem} selected={adminOption.includes(elem?.hash)} type={elem.type} />
            ))}
          </Box>
          <Divider />
        </Hidden>

        <Box className={classes.defaultFilters}>
          {defaultFilters().map((elem) => (
            <MenuItem key={elem.hash} onClick={(hash) => handleClick(hash, "default")} item={elem} selected={defaultOption.includes(elem?.hash)} type={elem.type} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

Filter.propTypes = {
  admin: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  tags: PropTypes.array,
  onFilterChange: PropTypes.func,
};
Filter.defaultProps = {
  onFilterChange: () => { },
  onClose: () => { },
};
export default memo(Filter);
