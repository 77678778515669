import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { truncate } from "app/liveShopping/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "146px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
  },
  price: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "18px",
    letterSpacing: "0em",
    marginRight: "6px",
  },
  previousPrice: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
    lineHeight: "130%",
    textDecorationLine: "line-through",
    color: theme.palette.colors.grey,
  },
  productDescription: {
    fontSize: "10px",
    lineHeight: "10px",
    fontWeight: 400,
    color: theme.palette.colors.darkGrey,
  },
}));

const Infos = ({ product }) => {
  const classes = useStyles({});
  return (
    <Box className={classes.root}>
      <Typography style={{ fontSize: "14px" }}>{product.price ? truncate(product.name, 40, true) : truncate(product.name, 50, true)}</Typography>
      <Typography className={classes.productDescription}>{product.short?.slice(0, 20)}</Typography>

      <Box
        style={{
          display: !!product.price ? "flex" : "none",
          alignItems: "center",
          marginTop: "4px",
        }}
      >
        <Typography className={classes.price}>
          {product.price}
          {product.displayCurrency}
        </Typography>
        {product.previous_price ? (
          <Typography className={classes.previousPrice}>
            {product.previous_price}
            {product.displayCurrency}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

Infos.propTypes = {};

export default Infos;
