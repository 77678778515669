import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import MicMuteFill from "react-bootstrap-icons/dist/icons/mic-mute-fill";
import AvatarInitials from "app/liveShopping/backoffice/otm/ChatModo/components/Profil/AvatarInitials";
import { getInitialsFromString } from "app/liveShopping/frontoffice/oto/utils";
import { useDispatch, useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { isUser } from "utilities/access";
import { _t } from "utilities/i18n";
import { CameraVideoOff } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
    color: theme.palette.colors.white,
    "& .hasFocusOff &": {
      padding: "0px !important",
    },
    "& .videoContainer": {
      background: theme.palette.colors.grey700 + "!important",
    },
  },
  videoContainer: {
    background: theme.palette.colors.white50,
    borderRadius: props => props.isMobile && !props.isOther && "8px",
    position: "relative",
    "& .showHover": {
      visibility: "hidden",
    },
    "& .VisioActionMenu": {
      position: "absolute",
      top: "5px",
      right: "5px",
    },
    ".hasFocusOff &": {
      minWidth: "100%",
    },
  },
  videoPlayer: {
    position: "absolute",
    borderRadius: props => props.isMobile && !props.isOther && "8px",
    overflow: "hidden",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    "& video": {
      height: "100%",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .AvatarInitials": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%,-50%)",
    },
    "& .OTVideo": {
      display: "none",
    },
    "&.hasVideo": {
      "& .OTVideo": {
        display: "block",
      },
    },
  },
  stream: {
    height: "100%",
    width: "100%",
  },
  name: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    color: theme.palette.colors.white,
    zIndex: 3,
    ".hasFocusOff &": {
      display: "none",
    },
  },
  label: {
    display: "flex !important",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: "5px",
    right: "5px",
    width: "22px",
    height: "22px",
    borderRadius: "99px",
    background: theme.palette.colors.grey700,
    zIndex: 3,
  },
  notice: {
    color: theme.palette.colors.white,
    padding: "5px",
  },
  blured: {
    filter: "blur(20px)",
  },
}));

const HasAudioOffLabel = () => {

  const classes = useStyles();
  return (
    <Box className={clsx(classes.label, "label")}>
      <MicMuteFill />
    </Box>
  );
};

const VideoCard = ({ stream, streamId, onMounted, onBeforeUnmount, isOther, mode, blured }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const isAdmin = isUser("user+")
  const classes = useStyles({ isMobile, isAdmin, isOther });
  const visioRef = useRef();
  const { hasAudio, hasVideo } = stream;

  useEffect(() => {
    onMounted({ stream, visioRef });

    return () => {
      onBeforeUnmount({ stream, visioRef });
    };
  }, []);

  console.log("VideoCard", isAdmin, isOther, mode)

  return (
    <Box
      className={clsx({
        [classes.root]: true,
        ["videoCard"]: true,
        [classes.blured]: blured,
      })}
    >
      <Box
        className={clsx({
          ["videoContainer"]: true,
          [classes.videoContainer]: true,
        })}
      >
        {name && <Typography className={clsx(classes.name, "showHover")} variant={"smallBodySB1"}>
          {name}
        </Typography>}

        <Box className={clsx(classes.overlay, "showHover", "VideoCardOverlay")} />
        {isAdmin && !hasAudio && <HasAudioOffLabel />}

        {!isOther && mode === "audio" && <Box className={classes.notices}>{_t("You are in audio only mode")}</Box>}

        {isAdmin && isOther && mode !== "video" && (
          <>
            {mode === "audio" && <Box className={classes.notices}>{_t("Your interlocutor is in audio only mode")}</Box>}
            {mode === "chat" && <Box className={classes.notices}>{_t("Your interlocutor is in text chat only mode")}</Box>}
          </>
        )}

        <Box
          className={clsx({
            [classes.videoPlayer]: true,
            ["videoPlayer"]: true,
            ["hasVideo"]: hasVideo,
          })}
        >
          <Box
            ref={visioRef}
            id={`stream-${streamId}`}
            className={clsx({
              ["OTVideo"]: true,
              [classes.stream]: true,
            })}
          />
          {isAdmin && !hasVideo && <AvatarInitials size="small" />}
        </Box>
      </Box>
    </Box>
  );
};

VideoCard.propTypes = {
  onMounted: PropTypes.func,
  stream: PropTypes.object.isRequired,

  streamId: PropTypes.string.isRequired,
  onBeforeUnmount: PropTypes.func,
};

VideoCard.defaultProps = {
  onMounted: () => { },
  onBeforeUnmount: () => { },
};

export default VideoCard;
