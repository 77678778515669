import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.box,
    //think about this later
    zIndex: theme.zIndex.mobileStepper + 1,

    overflow: "auto",
    position: "relative",
    overscrollBehavior: "contain",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      overflow: "hidden",
      // position: "sticky",
      // bottom: "0",
      // left: "0",
    },
    // [theme.breakpoints.up("sm")]: {
    //   position: "relative",
    //   height: "100%",
    // },
  },
  tabBar: {
    [theme.breakpoints.up("sm")]: {
      //see how to make this work later
      // position: "sticky",
      // top: "0",
      // left: "0",
    },
    [theme.breakpoints.down("xs")]: {
      position: "sticky",
      bottom: "0",
      left: "0",
    },
  },
  tabPanelsContainer: {
    position: "relative",
    [theme.breakpoints.up("sm")]: {
      //100% - tabsheader
      maxHeight: "calc(100%  - 55px)",
    },
  },
  indicator: {
    height: "5px",
    backgroundColor: theme.palette.colors.primary,
  },
  tabRoot: {
    textTransform: "none",
    lineHeight: 1.25,
    [theme.breakpoints.up("sm")]: {
      minWidth: theme.spacing(9),
      padding: "16.5px 0 21.5px 0",
    },
  },
  tabsRoot: {
    flex: 1,
    borderBottom: `1px solid ${theme.palette.border.default}`,
    [theme.breakpoints.up("sm")]: {
      height: 55,
    },
    [theme.breakpoints.down("xs")]: {
      //height: theme.spacing(5),
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      height: theme.spacing(6),
    },
  },
  tabWrapper: {
    position: "relative",
  },

  labelIcon: {
    minHeight: "auto",
    color: theme.palette.text.primary,
    opacity: 0.35,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up("sm")]: {
      lineHeight: 1.25,
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: "none",
    },
    "&.Mui-selected": {
      opacity: 1,
      color: theme.palette.button.default.color.enable,
    },
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },

  panelContainer: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 55px - 55px)", // - header - tabs
    },
    [theme.breakpoints.down("xs")]: {
      height: `calc(${theme.vh * 100}px - ${theme.spacing(7.35)}px - ${theme.spacing(7)}px)`,
    },
  },

  textColorInherit: {
    opacity: 1,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Box {...other} hidden={value !== index}>
      {children}
    </Box>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
};
const TestPanel = ({ color }) => {
  return (
    <Box
      style={{
        backgroundColor: color ? color : "blue",
        height: "100%",
        width: "100%",
      }}
    ></Box>
  );
};

const WizTabs = ({ name, labels, panels, panelsOrder, tabsOrder, onTabSelected }) => {
  const [tabSelected, setTabSelected] = useState(0);
  const classes = useStyles();
  const onChange = (event, index) => {
    setTabSelected(index);
    onTabSelected(index);
  };
  return (
    <Box display="flex" flexDirection="column" className={clsx(name, classes.root)}>
      <Box display="flex" justifyContent="space-between" alignItems="stretch" flexDirection="row" order={tabsOrder} className={classes.tabBar}>
        <Tabs variant="fullWidth" value={tabSelected} onChange={onChange} classes={{ indicator: classes.indicator, root: classes.tabsRoot }}>
          {labels.map((label) => {
            return (
              <Tab
                key={label.id}
                classes={{
                  root: classes.tabRoot,
                  labelIcon: classes.labelIcon,
                  textColorInherit: classes.textColorInherit,
                }}
                label={label.value}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box order={panelsOrder} className={classes.tabPanelsContainer}>
        {panels.map((panel) => {
          return (
            <TabPanel key={panel.id} value={tabSelected} index={panel.position}>
              <Box className={classes.panelContainer}>
                <panel.component />
              </Box>
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

WizTabs.propTypes = {
  labels: PropTypes.array,
  panels: PropTypes.array,
  panelsOrder: PropTypes.number,
  tabsOrder: PropTypes.number,
  onTabSelected: PropTypes.func,
  name: PropTypes.string,
};
WizTabs.defaultProps = {
  labels: [
    { id: "first", value: "Equipe" },
    { id: "second", value: "Participants" },
  ],
  panels: [
    { id: "first", component: <TestPanel />, position: 0 },
    { id: "second", component: <TestPanel color="red" />, position: 1 },
  ],
  panelsOrder: 1,
  tabsOrder: 0,
  onTabSelected: (tab) => {
    console.log("selected tab ", tab);
  },
  name: "WizTabs",
};

export default WizTabs;
