import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    width: (props) => (props.width ? props.width : props.size),
    height: (props) => props.size,
    background: (props) =>
      props.path && `center / cover no-repeat url(${props.path})`,
    borderRadius: "8px",
  },
}));

const Image = ({ path, size, width }) => {
  const classes = useStyles({ path, size, width });

  return <Box className={classes.root} />;
};

Image.propTypes = {
  path: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
};

Image.defaultProps = {
  path: "",
  size: 32,
};

export default Image;
