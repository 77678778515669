import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import EnvelopeFill from "react-bootstrap-icons/dist/icons/envelope-fill";
import { useSelector, useDispatch } from "react-redux";
import { resourceCreate } from "ressourcesDucks/actions";
import ResourceFactory from "orm/resources";
import { getKeyword } from "applicationDucks/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    width: "400px",
    paddingTop: "132px",
  },
  icon: { paddingRight: "5px" },
  alert: {
    color: theme.palette.colors.warning,
    display: "flex",
    alignItems: "center",
    padding: "10px 15px",
    margin: "30px 0",
    backgroundColor: theme.palette.colors.warning10,
    width: "fit-content",
    borderRadius: "10px",
  },
  mail: { color: theme.palette.colors.blackMedium, fontWeight: "600" },
  account: {
    display: "flex",
    alignItems: "center",
    paddingTop: "50px",
  },
  link: {
    cursor: "pointer",
  },
}));

const Verification = ({ email }) => {
  const classes = useStyles();
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const sendVerificationMail = () => {
    dispatch(
      resourceCreate(resourceFactory.create("Custom", { keyword: keyword }), {
        XHR_ID: "registration",
        slug: ["user", "verification-email"],
        silent: true,
      })
    );
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.alert}>
          <EnvelopeFill className={classes.icon} />
          <Typography color="warning" variant={"smallBodySB1"}>
            {_t("Waiting for verification")}
          </Typography>
        </Box>
        <Typography variant={"title13"}>{_t("Checking your email")}</Typography>
        <Box p={1} />
        <Typography variant={"body15"} color="greyDark">
          {_t("We have sent a link to")}{" "}
          <Box className={classes.mail} component={"span"}>
            {email}
          </Box>
          .
          <Box>
            {
              // prettier-ignore
              _t("Please click on this link to verify your email address")
            }
          </Box>
        </Typography>
        <Box className={classes.account}>
          <Typography color={"greyDark"} variant={"body15"}>
            {_t("Didn't you receive anything ?")}
          </Typography>
          <Box ml={"5px"} />
          <Typography
            className={classes.link}
            color={"primary"}
            variant={"body15"}
            onClick={sendVerificationMail}
          >
            {_t("Resend link")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

Verification.propTypes = {
  email: PropTypes.string,
};

Verification.defaultProps = {
  email: "",
};

export default Verification;