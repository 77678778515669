import React, { useState, useMemo } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import MenuItem from "appComponents/Mui/MenuItemUni";
import Box from "@material-ui/core/Box";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";

const useStyles = makeStyles((theme) => ({
  selectedItem: {
    borderBottom: `2px solid`,
  },
  menuButon: {},
  menuBox: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .MuiButtonBase-root:hover": {
      border: "none",
      boxShadow: "none !important",
      backgroundColor: "white !important",
      "& .MuiTypography-root": {
        color: "#000  !important",
      },
      "& .MuiSvgIcon-root": {
        color: "#000  !important",
      },
    },
    "& .MuiButtonBase-root": {
      borderColor: "#FFF",
      "& .MuiTypography-root": {
        color: "#000",
      },
      "& .MuiSvgIcon-root": {
        color: "#000",
      },
    },
  },

  arrowUp: {
    position: "absolute",
    width: 0,
    height: 0,
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    top: -theme.spacing(2),
    // zIndex: theme.zIndex.modal,
    //marginTop: - theme.spacing(1.4),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      left: "45%",
    },
    borderBottom: "15px solid white",
  },
  menuList: {
    paddingTop: 0,
    marginTop: theme.spacing(1),
    paddingLeft: 5,
    paddingRight: 5,
  },
  menuPaper: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.colors.white,
    boxShadow: theme.palette.shadow,
  },
  badgeContent: {
    marginLeft: (props) => (props.badgeContent ? "18px" : 0),
    marginRight: (props) => (props.badgeContent ? "10px" : 0),
  },
  label: {
    fontSize: "12px",
    fontWeight: "normal",
    letterSpacing: "0.5px",
    //textTransform: "capitalize",
  },
  badgeRoot: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.colors.black,
  },
}));
const getDefaultOptions = (defaultOption, options) => {
  let result = [];
  if (!defaultOption) return [];
  if (isArray(defaultOption)) {
    result = defaultOption.map((opt) => opt.hash);
  } else {
    result = [defaultOption.hash];
  }

  if (result.includes("all")) {
    result = options.map((opt) => opt.hash);
  }
  return result;
};
const FilterMenu = ({
  title,
  options,
  highlight,
  id,
  notifyParentOnClick,
  badgeContent,
  type,
  onFilterChange,
  defaultOption,
  buttonVariant,
  inactiveColor,
  ml,
  icon,
  keepOptionSelected,
  chevron,
  buttonClassName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  //getDefaultOptions (defaultOption)
  const [selectedOptions, setSelectedOptions] = useState(getDefaultOptions(defaultOption, options));

  const menuTitle = useMemo(() => {
    if (type === "select" && !isEmpty(selectedOptions)) {
      const data = options.filter((elem) => elem.hash === selectedOptions[0]);

      return get(data, "[0].title", title);
    } else return title;
  }, [selectedOptions, type, title]);

  const handleClick = (event) => {
    if (open) {
      handleClose();
      return;
    }
    if (get(event, "currentTarget")) {
      setAnchorEl(get(event, "currentTarget"));
    }

    setOpen(true);

    notifyParentOnClick();
  };

  const handleClose = (selected) => {
    if (selected) {
      let nextSelection = cloneDeep(selectedOptions);
      if (nextSelection.includes(selected) && type === "checkbox") {
        if (keepOptionSelected.includes(selected)) {
          return true;
        }
        if (selected === "all") {
          nextSelection = [];
        } else {
          if (nextSelection.includes("all")) {
            const allIndex = nextSelection.indexOf("all");
            if (allIndex > -1) {
              nextSelection.splice(allIndex, 1);
            }
          }
          const index = nextSelection.indexOf(selected);
          if (index > -1) {
            nextSelection.splice(index, 1);
          }
        }
      } else {
        if (type === "select" || type === "selectText") {
          nextSelection = [];
        }
        if (selected === "all") {
          nextSelection = options.map((opt) => opt.hash);
        } else {
          nextSelection.push(selected);
        }
      }

      setSelectedOptions(nextSelection);
      //improve control here to handle filter without checkbox
      const selection =
        type === "checkbox"
          ? {
            type: id,
            list: nextSelection,
          }
          : {
            type: id,
            value: selected,
          };

      onFilterChange && onFilterChange(selection);
    }
    if (!selected || type === "select") {
      setAnchorEl(null);
      setOpen(false);
    }
  };
  const classes = useStyles({ highlight, badgeContent });
  const endIcon = !isEmpty(options) && chevron ? !open ? <KeyboardArrowDownIcon fontSize="small" /> : open ? <KeyboardArrowUpIcon fontSize="small" /> : null : null;

  return (
    <Box className={classes.menuBox}>
      <Box color={"#FFF"} className={buttonClassName} onClick={handleClick} ml={ml}>
        {icon}
      </Box>
      {!isEmpty(options) && (
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id={`${title}-menu`}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleClose(null)}
          classes={{ list: classes.menuList, paper: classes.menuPaper }}
        >
          <Box style={{ position: "relative" }}>
            {options.map((item, index) => {
              return <MenuItem key={index} onClick={handleClose} item={item} selectedOptions={selectedOptions} type={type} id={id} />;
            })}
          </Box>
        </Menu>
      )}
    </Box>
  );
};

FilterMenu.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      title: PropTypes.string,
      menu: PropTypes.shape({
        menuId: PropTypes.string,
        activated: PropTypes.bool,
        order: PropTypes.number,
      }),
    })
  ),
  highlight: PropTypes.bool,
  notifyParentOnClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  id: PropTypes.string,
  badgeContent: PropTypes.number,
  type: PropTypes.string,
  defaultOption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  buttonVariant: PropTypes.string,
  inactiveColor: PropTypes.string,
  ml: PropTypes.number,
  icon: PropTypes.element,
  keepOptionSelected: PropTypes.array,
  chevron: PropTypes.bool,
};

FilterMenu.defaultProps = {
  highlight: false,
  options: [],
  notifyParentOnClick: () => { },
  badgeContent: 0,
  buttonVariant: "text",
  inactiveColor: "tertiary",
  ml: 0,
  icon: null,
  keepOptionSelected: [],
  chevron: true,
};

export default FilterMenu;
