import { Box, Typography } from "@material-ui/core";
import OneSwitch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import TextArea from "app/liveShopping/backoffice/otm/components/reusables/TextArea";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { _t } from "utilities/i18n";
import { makeStyles } from "@material-ui/styles";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import AccordionOption from "./Accordion";

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    buttonSave: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "20px",
    },
    label: {
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "130%",
      marginBottom: "5px",
    },
    field: {
      marginBottom: "20px",
      marginTop: "20px",
    },
    trackingTreshold: {
      width: "100px",
    },
}));

const FishingRod = ({ project }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [fishingRod, setFishingRod] = useState(project.options?.fishing_rod || false);
    const [chatGptPrompt, setChatGptPrompt] = useState(project.options?.chat_gpt_prompt || "");
    const [trackingTreshold, setTrackingTreshold] = useState(project.options?.tracking_treshold || 0);
    const options = {
        ...project.options,
        fishing_rod: fishingRod,
        chat_gpt_prompt: chatGptPrompt,
        tracking_treshold: trackingTreshold,
      };



  const handleSend = () => {
    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
        }
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  return (
    <Box className={classes.root}>
      <AccordionOption title={_t("Fishing rod parameters")}>
        <Box className={classes.field}>
          <Typography className={classes.label}>{_t("Enable fishing rod mode")}</Typography>
          <OneSwitch onChange={() => setFishingRod(!fishingRod)} checked={fishingRod} />
        </Box>
        <Box className={classes.field}>
          <Typography className={classes.label}>{_t("AI prompt")}</Typography>
          <TextArea value={chatGptPrompt} onChange={(e) => setChatGptPrompt(e.target.value)} />
        </Box>
        <Box className={classes.field}>
          <Typography className={classes.label}>{_t("Scoring treshold")}</Typography>
          <Input value={trackingTreshold} type="number" clsName={classes.trackingTreshold} onChange={(e) => setTrackingTreshold(e.target.value)}  />
        </Box>
        <Box className={classes.buttonSave}>
          <Button onClick={handleSend}>{_t("Save")}</Button>
        </Box>
      </AccordionOption>
    </Box>
  );
};

export default FishingRod;