import React, { useEffect, useState } from "react";
import { Box, makeStyles, Tab, Tabs, Typography } from "@material-ui/core";
import { _t } from "utilities/i18n";
import Wording from "./Wording";
import { useDispatch, useSelector } from "react-redux";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import Admins from "./Admins/Admins";
import Options from "./Options";
import Apparence from "./Apparence";
import Widget from "./Widget";
import Cobrowsing from "./Cobrowsing";
import Catalog from "./Catalog";
import { useParams } from "react-router-dom";
import { getUrl } from "app/views/containers/Application/Routes";
import FishingRod from "./FishingRod";
import Plugins from "./Plugins";
import { Brush, ChatRightText, Gear, MenuUp, PersonCircle, Puzzle } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 30px",
    width: "100%",
    height: "100%",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "25px",
    lineHeight: "130%",
    marginBottom: "20px",
  },
  content: {
    height: "100%",
    overflowY: "auto",
  },
  indicator: {
    backgroundColor: theme.palette.colors.primary,
  }
}));

const Parameters = () => {
  const classes = useStyles();
  const { hash, tab } = useParams();
  const project = useSelector(currentProjectSelector);
  const [selectedView, setSelectedView] = useState(tab || "interface-messages");

  if (!project) return null;

  useEffect(() => {
    window.history.pushState(null, null, getUrl("oto_parameters", { hash, tab: selectedView }));
  }, [selectedView]);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Parameters")}</Typography>
      <Tabs variant="fullWidth" value={selectedView} onChange={(event, value) => setSelectedView(value)}
        classes={{ indicator: classes.indicator }}
        marginTop="mt-6">
        <Tab label={_t("Interface Messages")} value="interface-messages" icon={<ChatRightText />} />
        <Tab label={_t("Admins")} value="admins" icon={<PersonCircle />} />
        <Tab label={_t("Options")} value="options" icon={<Gear />} />
        <Tab label={_t("Apparence")} value="apparence" icon={<Brush />} />
        <Tab label={_t("Widget")} value="widget" icon={<MenuUp />} />
        <Tab label={_t("Plugins")} value="plugins" icon={<Puzzle />} />
      </Tabs>

      <Box className={classes.content}>
        {selectedView === "interface-messages" && <Wording project={project} />}
        {selectedView === "admins" && <Admins project={project} />}
        {selectedView === "options" && <Options project={project} />}
        {selectedView === "apparence" && <Apparence project={project} />}
        {selectedView === "widget" && <Widget project={project} />}
        {selectedView === "plugins" && <Plugins project={project} />}
      </Box>
    </Box>
  )
};

export default Parameters;
