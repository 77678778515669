import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import { useDispatch, useSelector } from "react-redux";
import { Typography, useMediaQuery } from "@material-ui/core";
import { endCallVisitor, visitorAnswer } from "app/liveShopping/backoffice/oto/Component/Live/service";
import { emit } from "utilities/liveshopping-sdk";
import { Howl, Howler } from 'howler';
import Ring from "app/liveShopping/frontoffice/oto/assets/ring/liveboutiquering.mp3"
import basil from "utilities/basil";
import { TelephoneFill } from "react-bootstrap-icons";
import Pulse from 'react-reveal/Pulse';
import { currentProjectSelector } from "app/state/ducks/application/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: props => props.isMobile ? "25px" : "0px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "130%",
    textAlign: "center",
    marginBottom: "16px",
    marginTop: "8px",
  },
  typoAnswer: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "100%",
  },
  button: {
    display: "flex",
    gap: "8px",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    background: theme.palette.colors.success,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: "10px",
  },

}));

const Answer = ({ }) => {
  const isMobile = useMediaQuery("@media (max-width:344px)");
  const classes = useStyles({ isMobile });
  const currentVisitor = useSelector(currentVisitorSelector);
  const dispatch = useDispatch();
  const mode = currentVisitor.options.mode;
  const project = useSelector(currentProjectSelector);

  useEffect(() => {
    if ((mode === "audio" || mode === "video") && project.options.ring === false) {
      handleAnswer();
      return;
    }

    const sound = new Howl({
      src: [Ring],
    });

    Howler.volume(1.0);
    const interval = setInterval(() => {
      sound.play();
    }, 10000);

    sound.play();

    return () => {
      clearInterval(interval);
      sound.stop();
    };
  }, []);

  const handleAnswer = () => {
    basil.set(`rtr:${currentVisitor.hash}`, true);
    visitorAnswer(dispatch, currentVisitor);
  };

  const handleDecline = () => {
    endCallVisitor(dispatch, currentVisitor, (error, response) => {
      if (error) {
        console.error(error);
      }
      emit("QUIT");
    });
  };


  return (
    <Box className={classes.root}>
      <Pulse forever={true}>
        <Box className={classes.button} onClick={handleAnswer}>
          <TelephoneFill />
        </Box>
      </Pulse>
      <Typography className={classes.title}>{_t("Your vendor is calling you")}</Typography>
    </Box>
  );
};

export default Answer;