import React from "react";
import PropTypes from "prop-types";

const CustomFullScreenIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9382 8.62913H8.62913V13.9382C8.62913 14.4194 8.43796 14.881 8.09766 15.2213C7.75736 15.5616 7.29582 15.7528 6.81457 15.7528C6.33331 15.7528 5.87177 15.5616 5.53147 15.2213C5.19118 14.881 5 14.4194 5 13.9382V8.23929C5 7.38018 5.34128 6.55625 5.94877 5.94877C6.55625 5.34128 7.38018 5 8.23929 5H13.9382C14.4194 5 14.881 5.19118 15.2213 5.53147C15.5616 5.87177 15.7528 6.33331 15.7528 6.81457C15.7528 7.29582 15.5616 7.75736 15.2213 8.09766C14.881 8.43796 14.4194 8.62913 13.9382 8.62913Z"
        fill="white"
      />
      <path
        d="M19.7787 5.53147C19.4384 5.87177 19.2472 6.33332 19.2472 6.81457C19.2472 7.29582 19.4384 7.75736 19.7787 8.09766C20.119 8.43796 20.5806 8.62913 21.0618 8.62913H26.3709V13.9382C26.3709 14.4194 26.562 14.881 26.9023 15.2213C27.2426 15.5616 27.7042 15.7528 28.1854 15.7528C28.6667 15.7528 29.1282 15.5616 29.4685 15.2213C29.8088 14.881 30 14.4194 30 13.9382V8.23929C30 7.38018 29.6587 6.55625 29.0512 5.94877C28.4438 5.34128 27.6198 5 26.7607 5H21.0618C20.5806 5 20.119 5.19118 19.7787 5.53147Z"
        fill="white"
      />
      <path
        d="M8.09766 19.7787C7.75736 19.4384 7.29582 19.2472 6.81457 19.2472C6.33332 19.2472 5.87177 19.4384 5.53147 19.7787C5.19118 20.119 5 20.5806 5 21.0618V26.7607C5 27.6198 5.34128 28.4438 5.94877 29.0512C6.55625 29.6587 7.38018 30 8.23929 30H13.9382C14.4194 30 14.881 29.8088 15.2213 29.4685C15.5616 29.1282 15.7528 28.6667 15.7528 28.1854C15.7528 27.7042 15.5616 27.2426 15.2213 26.9023C14.881 26.562 14.4194 26.3709 13.9382 26.3709H8.62913V21.0618C8.62913 20.5806 8.43796 20.119 8.09766 19.7787Z"
        fill="white"
      />
      <path
        d="M29.4685 19.7787C29.1282 19.4384 28.6667 19.2472 28.1854 19.2472C27.7042 19.2472 27.2426 19.4384 26.9023 19.7787C26.562 20.119 26.3709 20.5806 26.3709 21.0618V26.3709H21.0618C20.5806 26.3709 20.119 26.562 19.7787 26.9023C19.4384 27.2426 19.2472 27.7042 19.2472 28.1854C19.2472 28.6667 19.4384 29.1282 19.7787 29.4685C20.119 29.8088 20.5806 30 21.0618 30H26.7607C27.6198 30 28.4437 29.6587 29.0512 29.0512C29.6587 28.4437 30 27.6198 30 26.7607V21.0618C30 20.5806 29.8088 20.119 29.4685 19.7787Z"
        fill="white"
      />
    </svg>
  );
};

CustomFullScreenIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomFullScreenIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomFullScreenIcon;
