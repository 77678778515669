/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { _t } from "utilities/i18n";

var isoWeek = require("dayjs/plugin/isoWeek")
dayjs.extend(isoWeek)
var weekday = require("dayjs/plugin/weekday")
dayjs.extend(weekday);
var isToday = require("dayjs/plugin/isToday")
dayjs.extend(isToday)

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const generateDate = ({ month = dayjs().month(), year = dayjs().year(), locale }) => {
  const WEEK_DEBUT_DAY = locale === "en" ? 0 : 1;
  const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
  const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

  let arrayOfDate = [];

  // create a prefix date, if the first day of the month is not monday (0) or sunday (1), we need to generate the previous date to fill the calendar from the first day of the month to the first monday or sunday
  for (let i = WEEK_DEBUT_DAY; i < firstDateOfMonth.day(); i++) {
    arrayOfDate.push({ currentMonth: false, date: firstDateOfMonth.day(i) });
  }

  // generate current date
  for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
    arrayOfDate.push({ currentMonth: true, date: firstDateOfMonth.date(i), today: firstDateOfMonth.date(i).toDate().toDateString() === dayjs().toDate().toDateString() });
  }

  // calculate the remaining date to fill the calendar
  const remaining = 42 - arrayOfDate.length;

  // generate suffix date
  for (let i = lastDateOfMonth.date() + 1; i <= lastDateOfMonth.date() + remaining; i++) {
    arrayOfDate.push({ currentMonth: false, date: lastDateOfMonth.date(i) });
  }

  return arrayOfDate;
};

// generate week days in FR locale and EN locale (default)
export const generateWeekDays = () => {

  const weekDays = [];
  for (let i = 0; i < 7; i++) {
    weekDays.push(dayjs().weekday(i).format("dd"));
  }
  return weekDays;
}

// generate month in FR locale and EN locale (default)
export const generateMonth = ({ monthNumber = dayjs().month() }) => {

  return dayjs().month(monthNumber).format("MMMM");
}

const DEFAULT_AGENDA_CONFIGURATION = [
  {
    day: _t("monday"),
    slots: [
      {
        start: "09:00",
        end: "12:00",
      },
      {
        start: "14:00",
        end: "18:00",
      },
    ],
  },
  {
    day: _t("tuesday"),
    slots: [
      {
        start: "09:00",
        end: "12:00",
      },
      {
        start: "14:00",
        end: "18:00",
      },
    ],
  },
  {
    day: _t("wednesday"),
    slots: [
      {
        start: "09:00",
        end: "12:00",
      },
      {
        start: "14:00",
        end: "18:00",
      },
    ],
  },
  {
    day: _t("thursday"),
    slots: [
      {
        start: "09:00",
        end: "12:00",
      },
      {
        start: "14:00",
        end: "18:00",
      },
    ],
  },
  {
    day: _t("friday"),
    slots: [
      {
        start: "09:00",
        end: "12:00",
      },
      {
        start: "14:00",
        end: "18:00",
      },
    ],
  },
  {
    day: _t("saturday"),
    slots: [],
  },
  {
    day: _t("sunday"),
    slots: [{
      start: "09:00",
      end: "12:00",
    }],
  },
]

// from agenda configuration day, get days empty of the month by slots
export const getDaysEmpty = ({ agenda_configuration, month = dayjs().month(), year = dayjs().year(), overrides }) => {
  if (!agenda_configuration) return [];
  const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
  const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

  const daysEmpty = [];

  for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
    const day = firstDateOfMonth.date(i);
    const dayName = day.format("dddd").toLowerCase();
    const dayConfiguration = agenda_configuration.find((day) => day.day === dayName);

    // if day is override, and override is equal to [], we push the day in daysEmpty
    if (overrides[day.format("YYYY-MM-DD")] && overrides[day.format("YYYY-MM-DD")].length === 0) {
      daysEmpty.push(day.date(i).toDate().toDateString());
    }

    if (!dayConfiguration || dayConfiguration.slots.length === 0) {
      daysEmpty.push(day.date(i).toDate().toDateString());
    }
  }

  return daysEmpty;
}

export const getUserTimezone = (defaultTZ = 'Europe/Paris') => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) { }

  return defaultTZ;
};

// this function is the most important of the world
// it takes the calendar configuration, the overrides, the current day, the timezone and the meeting duration
// and it generates all the available slots for the given day
// this is quite awesome
export const generateHours = ({ day, agenda_configuration = DEFAULT_AGENDA_CONFIGURATION, minutes = 30, timezone = 'Europe/Paris', overrides = {} }) => {
  const hours = [];

  const dayName = dayjs(day).format("dddd").toLowerCase();
  const dayConfiguration = agenda_configuration.find((day) => day.day === dayName);
  const override = overrides[dayjs(day).format("YYYY-MM-DD")];

  if (override)
    dayConfiguration.slots = override;

  if (!dayConfiguration) return [];

  // current user timezone
  const userTZ = getUserTimezone();

  dayConfiguration.slots.forEach((slot) => {
    const start = dayjs.tz(`${dayjs().format('YYYY-MM-DD')} ${slot.start}:00`, timezone);
    const end = dayjs.tz(`${dayjs().format('YYYY-MM-DD')} ${slot.end}:00`, timezone);

    console.log(start, end);

    // increment hours between start and end
    for (let i = start; i < end; i = i.add(minutes, "minutes"))
      hours.push(dayjs(i).tz(userTZ).format("HH:mm"));

  });

  return hours;
}


export const DEFAULT_UNAVAILABILITIES = {
  "2023-05-10": { "15:00": 1 },
  "2023-05-18": { "14:30": 1 },
};

// generate unavailabilities from unavailabilities configuration and return an array of unavaibilities in string format (HH:mm), check if number of unavaibilities is not greater than the simultaneous_slots parameter
export const generateUnavailabilities = ({ day, unavailabilities = DEFAULT_UNAVAILABILITIES, simultaneous_slots = 1, timezone = 'Europe/Paris' }) => {
  const dayString = dayjs(day).format("YYYY-MM-DD");
  const unavaibilitiesHours = [];
  const unavaibilitiesDay = unavailabilities[dayString];
  console.log("unavaibilitiesDay", unavaibilitiesDay)

  const userTZ = getUserTimezone();

  if (unavaibilitiesDay) {
    Object.keys(unavaibilitiesDay).forEach((hour) => {

      if (unavaibilitiesDay[hour] >= simultaneous_slots) {
        // convert utc hour to user timezone
        const date = dayjs.tz(`${dayjs().format('YYYY-MM-DD')} ${hour}:00`, 'UTC');
        const userHour = dayjs(date).tz(userTZ).format("HH:mm");

        // console.log('>> userHour', hour, userHour)

        unavaibilitiesHours.push(userHour);
      }
    });
  }

  return unavaibilitiesHours;
}


// generate override from override and return an array of override in string format (HH:mm)
export const generateOverride = ({ day, override, minutes }) => {
  const dayString = dayjs(day).format("YYYY-MM-DD");
  const overrideHours = [];
  const overrideDay = override[dayString];

  if (overrideDay) {
    overrideDay.forEach((indispo) => {
      const start = dayjs(indispo.start, "HH:mm");
      const end = dayjs(indispo.end, "HH:mm");

      // increment hours between start and end
      for (let i = start; i < end; i = i.add(minutes, "minutes")) {
        overrideHours.push(i.format("HH:mm"));
      }

      // add end hour if not already in the array
      if (!overrideHours.includes(end.format("HH:mm"))) {
        overrideHours.push(end.format("HH:mm"));
      }
    });
  }

  return overrideHours;
}

// generate summary from selected date and selected hours and return string summary
export const generateSummary = (day, hour) => {
  return `${dayjs(day).format("dddd")} ${dayjs(day).format("DD/MM/YYYY")} ${_t("at")} ${dayjs(hour, "HH:mm").format("HH:mm")}`;
}

export const isUnique = (item, position, array) => {
  return array.indexOf(item) === position;
}

// return true if current time > time + slot_duration_minutes
export const isTimePassed = (time, slots_duration_minutes) => {
  const currentTime = dayjs().add(slots_duration_minutes, "minutes");
  const calendarTime = dayjs(time, 'HH:mm');

  return calendarTime.unix() < currentTime.unix();
}

// i can't select date after the current date
export const isDatePassed = (date) => {
  const currentDate = dayjs();
  const dateToCheck = dayjs(date);

  // return true if date is before current date but not the current date
  return dateToCheck.isBefore(currentDate, "day") && !dateToCheck.isSame(currentDate, "day");
}

// return true if there is no slots in the day
export const isDayEmpty = (day, agenda_configuration) => {
  const dayName = dayjs(day).format("dddd").toLowerCase();

  if (dayjs(day).isSame(dayjs(), "month")) {
    const dayConfiguration = agenda_configuration.find((day) => day.day === dayName);

    if (!dayConfiguration || dayConfiguration.slots.length === 0) {
      return true;
    }
  }
  return false;
}


export const getEndDate = (end_at_absolute, end_at_relative, date_now) => {

  if (end_at_absolute && end_at_relative)
    return dayjs(end_at_absolute);

  if (end_at_absolute)
    return dayjs(end_at_absolute);

  return dayjs(date_now).add(end_at_relative, "days");
}
