import React, { useRef, useEffect, useState, useCallback, memo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import get from "lodash/get";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import Checkbox from "@material-ui/core/Checkbox";
import { ActionButton, Menu, Chip } from "appComponents/Mui";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "core/i18n";
import { getKeyword } from "applicationDucks/selectors";
import { getRoleLabel, isUserNew, getOriginLabel, getRSVPLabel } from "./utils";
import { getMailjetStatusLabel } from "adminComponents/Mails/utils";
import { useRoleUpdater } from "./hooks";
import { useSignatureUpdater } from "adminComponents/signatures/hooks";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import emitter from "utilities/emitter";
import OnlineState from "adminComponents/OnlineState";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import images from "images";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { ALIGN_CENTER_COLUMN } from "./constants";
import SignatureValidationStatus from "adminComponents/signatures/SignatureValidationStatus";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
dayjs.extend(relativeTime);
dayjs.extend(timezone);
import URI from "urijs";
import { getTToken } from "core/session";
import { API_BASE } from "config";
import { useSelector } from "react-redux";
import { ADMIN_EVENT_FLASH_SUCCESS_VANISH, ADMIN_EVENT_FLASH_ERROR_VANISH } from "utilities/emitter/events";
import DialogSendNotify, { NotifyUpdateRole } from "./DialogSendNotify";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.02)",
    },
  },

  redCercle: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: theme.palette.colors.alert,
  },
  greenCercle: {
    width: 24,
    height: 24,
    borderRadius: "50%",
    backgroundColor: theme.palette.colors.success,
  },
  avatarProfile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  name: {
    fontSize: "14px",
    lineHeight: "17.07px",
    letterSpacing: "0.5px",
    marginLeft: "10px",
  },
  remove: {
    position: "absolute",
    right: 0,
    display: (props) => (props.hover ? "flex" : "none"),
    background: theme.palette.background.gradient,
    paddingRight: "20px",
    paddingLeft: "158px",
    paddingTop: "10px",
    paddingBottom: "9px",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  tabCell: {
    padding: "10px",
  },
  checkBox: {
    height: "33px",
    minWidth: "33px",
    maxWidth: "33px",
  },
  signed: {
    backgroundColor: theme.wisemblyColors.green,
  },
  notSigned: {
    backgroundColor: theme.wisemblyColors.pastelRed,
  },
  signedIcon: {
    height: "34px",
    width: "34px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.wisemblyColors.white,
  },
}));

const successCallback = () => {
  emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
    message: _t("User role has been changed."),
  });
};
const callbackError = () => {
  emitter.emit(ADMIN_EVENT_FLASH_ERROR_VANISH, {
    message: _t("The Action could not be completed."),
  });
};
const newNotif = <Chip color="#009696" title={_t("New")} />;
const onExport = (keyword, user) => {
  return () => {
    if (user.signature) {
      try {
        let baseTarget = new URI(API_BASE);
        baseTarget.segment("event").segment(keyword).segment("signature-request").segment(user?.signatureRequest?.hash).segment("signature").segment(user?.signature?.hash).segment("export");

        baseTarget.search(getTToken({ url: baseTarget.toString() }));
        window.open(baseTarget.toString(), "_blank");
      } catch (e) {
        console.log("error", e);
      }
    }
  };
};
const onExportLogs = (keyword, user) => {
  return () => {
    try {
      let baseTarget = new URI(API_BASE);
      baseTarget.segment("event").segment(keyword).segment("connexion-logs").segment(user?.id).segment("csv");

      baseTarget.search(getTToken({ url: baseTarget.toString() }));
      window.open(baseTarget.toString(), "_blank");
    } catch (e) {
      console.log("error", e);
    }
  };
};
const UserTableRow = ({ columns, row, selected, handleClick, isLiveEvent }) => {
  const [hover, setHover] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const classes = useStyles({ hover });
  const ref = useRef(null);
  const [deletePoperOpen, setDeletePoperOpen] = useState(false);
  let activatedColumns = columns.filter((col) => !col.deactivated);
  const keyword = useSelector(getKeyword);

  ///console.log("event", event);
  //const user = useSelector((state) => userSelector(state, row.id));

  const manageSignature = useSignatureUpdater();
  const validateSuccessCallback = useCallback(() => {
    emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
      message: _t("Signature validated."),
    });
    setDisableButton(true);
  }, []);
  const onValidateSignature = () => {
    if (row.signature) {
      manageSignature(
        "edit",
        row.signature,
        {
          content: { validated_at: dayjs().format() },
          patch: "validated_at",
        },
        validateSuccessCallback,
        callbackError
      );
    } else {
      manageSignature(
        "postEdit",
        { signatureRequest: row.signatureRequest, user: { id: row.id } },
        {
          content: { validated_at: dayjs().format(), type: "participant" },
        },
        validateSuccessCallback,
        callbackError
      );
    }
  };
  const [updateRole, setUpdateRole] = useState(null);
  // updateRole([{hash: , scope: }])
  const roleUpdater = useRoleUpdater();

  const invitationSuccessCallback = useCallback(() => {
    emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
      message: _t("Invitation sent."),
    });
    setDisableButton(true);
  }, []);

  const sendInvitation = (email) => {
    roleUpdater("sendInvitation", [row.access], null, invitationSuccessCallback, callbackError, true);
  };

  const formateRoles = () => {
    const roles = [
      { title: _t("Participant"), hash: "user" },
      { title: _t("Animator"), hash: "anim" },
      { title: _t("Moderator"), hash: "modo" },
      { title: _t("Administrator"), hash: "admin" },
    ];
    if (isLiveEvent || row.role === "speaker") {
      roles.splice(1, 0, {
        title: _t("Speaker"),
        hash: "speaker",
      });
    }
    return roles;
  };

  const roleUpdateAction = (notify) => {
    const { action, access, value, successCallback, errorCallback } = updateRole;
    roleUpdater(action, access, value, successCallback, errorCallback, notify);
    setUpdateRole(null);
  };

  useEffect(() => {
    //onMouseOut onMouseOver
    if (ref.current) {
      ref.current.onmouseenter = () => {
        setHover(true);
      };
      ref.current.onmouseleave = () => {
        if (!deletePoperOpen) {
          setHover(false);
        }
      };
    }
  }, [deletePoperOpen]);
  const isNew = isUserNew(row.access);

  const checkboxCell = (
    <Box className={classes.checkBox}>{isNew && !selected && !hover ? newNotif : hover || selected ? <Checkbox onChange={(event) => handleClick(event, row.id)} checked={selected} /> : null}</Box>
  );
  return (
    <TableRow hover={false} role="checkbox" selected={selected} tabIndex={-1} key={row.id} ref={ref} classes={{ root: classes.root }}>
      <DialogSendNotify open={updateRole} roleUpdateAction={roleUpdateAction} notify={<NotifyUpdateRole count={1} />} />
      <TableCell key={"checkbox"} align={"left"}>
        {checkboxCell}
      </TableCell>

      {activatedColumns.map((column) => {
        //some column are not text
        const value = row[column.id];
        let result = value;

        switch (column.type) {
          case "avatarProfile": {
            result = (
              <Box className={clsx(`AvatarProfile${row.id}`, classes.avatarProfile)}>
                <Avatar alt={value}>
                  <PersonOutlinedIcon />
                </Avatar>{" "}
                <Box className={classes.name}>{value}</Box>{" "}
              </Box>
            );
            break;
          }

          case "string": {
            const access = row.access;
            switch (column.id) {
              case "rsvp": {
                const response = get(access, "present", null);
                result = <Box className={classes.name}>{getRSVPLabel(response)}</Box>;
                break;
              }
              case "origin": {
                result = <Box className={classes.name}>{getOriginLabel(value)}</Box>;
                break;
              }

              case "notHavingParticipated": {
                const status = get(access, "mails.wiz_ended_not_having_participated", null);
                result = <Box className={classes.name}>{getMailjetStatusLabel(status)}</Box>;
                break;
              }

              case "havingParticipated": {
                const status = get(access, "mails.wiz_ended_having_participated", null);
                result = <Box className={classes.name}>{getMailjetStatusLabel(status)}</Box>;
                break;
              }

              case "tenMinuteReminder": {
                const status = get(access, "mails.reminder_minutes", null);
                result = <Box className={classes.name}>{getMailjetStatusLabel(status)}</Box>;
                break;
              }

              case "oneDayReminder": {
                const status = get(access, "mails.reminder_day", null);
                result = <Box className={classes.name}>{getMailjetStatusLabel(status)}</Box>;
                break;
              }

              case "eventRegistrationConfirmation": {
                const status = get(access, "mails.registration_success", null);
                result = <Box className={classes.name}>{getMailjetStatusLabel(status)}</Box>;
                break;
              }

              case "relaunchInvitation": {
                console.log("access", access);
                const status = get(access, "mails.invitation_relaunch_participant", null);
                result = <Box className={classes.name}>{getMailjetStatusLabel(status)}</Box>;
                break;
              }
              default:
                result = <Box className={classes.name}>{value}</Box>;
            }
            break;
          }

          case "actionStatus": {
            switch (column.id) {
              case "invitationToTheTeam": {
                const access = row.access;
                //this mail will always be sent
                const status = get(access, "mails.event_accreditation", "sent");
                //template_key =  event_accreditation
                const label = getMailjetStatusLabel(status);

                result = <Box>{label}</Box>;
                break;
              }
              case "signatureValidation": {
                if (get(row, "signature.ref.validated_at", false)) {
                  result = <SignatureValidationStatus signature={row.signature} />;
                } else {
                  result = (
                    <Button onClick={onValidateSignature} disabled={disableButton}>
                      {_t("Validate")}
                    </Button>
                  );
                }

                break;
              }

              case "invitation": {
                //templatekey = invitation_participant
                const access = row.access;
                //this mail will always be sent
                const status = get(access, "mails.invitation_participant", null);
                if (!status) {
                  result = (
                    <Button style={{ display: "flex", gap: 3 }} onClick={() => sendInvitation(row.email)} disabled={disableButton}>
                      <SendOutlinedIcon /> {_t("Send")}
                    </Button>
                  );
                } else {
                  const label = getMailjetStatusLabel(status);

                  result = <Box>{label}</Box>;
                }

                break;
              }

              default:
                result = <Button title="action" />;
            }

            break;
          }

          case "bool": {
            switch (column.id) {
              case "online":
                result = <OnlineState user={row.id} />;
                break;
              case "signature":
                result = (
                  <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                    {" "}
                    {get(row, "signature.ref.has_signature", false) ? (
                      <Box className={clsx(classes.signedIcon, classes.signed)}>
                        <CheckOutlinedIcon fontSize="medium" />
                      </Box>
                    ) : (
                      <Box className={clsx(classes.signedIcon, classes.notSigned)}>
                        <CloseOutlinedIcon fontSize="medium" />
                      </Box>
                    )}
                  </Box>
                );
                break;
              default:
                break;
            }
            break;
          }

          case "action": {
            switch (column.id) {
              case "exportSignature":
                result = <Button onClick={onExport(keyword, row)} isIconButton icon={<images.CustomPdf />} disabled={!row?.signature?.has_signature} />;
                break;
              case "exportLogs":
                result = <Button onClick={onExportLogs(keyword, row)} isIconButton icon={<images.CustomCsv />} />;
                break;
              default:
                result = <Button title="action" />;
            }

            break;
          }

          case "select": {
            //dispatch modify role
            //update role
            const onFilterChange = (option) => {
              if (column.id === "role") {
                setUpdateRole({
                  action: "edit",
                  access: [row.access],
                  value: option.value,
                  successCallback: successCallback,
                  errorCallback: callbackError,
                });
              }
            };
            const defaultOption = { title: getRoleLabel(value), hash: value };

            //if col.id  === role
            result = (
              <Menu
                key="select"
                title={value}
                id="select"
                type="select"
                options={formateRoles()}
                onFilterChange={onFilterChange}
                buttonVariant="text"
                inactiveColor="tertiary"
                defaultOption={defaultOption}
                ml={10}
              />
            );
            break;
          }

          default:
            result = <Box>{value}</Box>;
        }
        return (
          <TableCell key={column.id} classes={{ root: classes.tabCell }} align={ALIGN_CENTER_COLUMN.includes(column.id) ? "center" : "left"}>
            {result}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
UserTableRow.propTypes = {
  columns: PropTypes.array,
  selected: PropTypes.bool,
  row: PropTypes.object,
  handleClick: PropTypes.func,
};
UserTableRow.defaultProps = {
  selected: false,
  handleClick: () => { },
};
// UserTableRow.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: "UserTableRow",
// };
export default memo(UserTableRow);
