import React, { forwardRef, memo } from "react";
import ModerationBubbleLight from "../components/ModerationBubbleLight";
import PropTypes from "prop-types";

import { useEditQuote, useDeleteQuote } from "../moderationHooks";
import { quoteModelSelector } from "ressourcesDucks/selectors";
import { useSelector } from "react-redux";

const ChatModerationBubble = forwardRef(({ id }, ref) => {
  const element = useSelector((state) => quoteModelSelector(state, id));
  const editQuote = useEditQuote();
  const deleteQuote = useDeleteQuote();

  const handleEditQuote = (quote, successCallback = () => {}, errorCallback = () => {}) => {
    editQuote(quote, successCallback, errorCallback);
  };

  const handleDeleQuote = (quote, successCallback = () => {}, errorCallback = () => {}) => {
    deleteQuote(quote, successCallback, errorCallback);
  };
  return <ModerationBubbleLight ref={ref} element={element} handleEditQuote={handleEditQuote} handleDeleQuote={handleDeleQuote} />;
});

ChatModerationBubble.displayName = "ChatModerationBubble";

ChatModerationBubble.propTypes = {
  id: PropTypes.string,
};

export default memo(ChatModerationBubble);
