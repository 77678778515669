import React from "react";
import { DndProvider } from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/esm/HTML5toTouch";
import PropTypes from "prop-types";

const Provider = ({ children }) => {
  return <DndProvider options={HTML5toTouch}>{children}</DndProvider>;
};

Provider.defaultProps = {
  children: PropTypes.node
}
export default Provider;
