import { useCallback, useEffect } from "react";
import indexOf from "lodash/indexOf";
import forEach from "lodash/forEach";
import { useDispatch, useSelector } from "react-redux";
import flattenDepth from "lodash/flattenDepth";
import {
  currentWizSelector,
  genericResourcesSelector,
  currentUserAccessesSelector,
  wizAccesseOfCurrentUserSelector,
  userSelector,
} from "ressourcesDucks/selectors";
import { resourcesFetch, resourceCreate } from "ressourcesDucks/actions";
import { getKeyword, sessionSelector } from "applicationDucks/selectors";
import * as Sentry from "@sentry/react";
import ResourceFactory from "orm/resources";

export const useUser = () => {
  const session = useSelector(sessionSelector);
  const user = useSelector((state) => userSelector(state, session?.user_id));

  if (!user || user.isAnon()) return null;

  return user;
};

export const useIsUser = (scopes, specificUser = null) => {
  const currentUser = useUser();
  const user = specificUser || currentUser;

  const event = useSelector(currentWizSelector);
  const userAccesses = useSelector(currentUserAccessesSelector);
  const currentUserScope = getUserScope(user, event, userAccesses);

  return hasExpectedScopes(
    event && event.isOwner(user),
    currentUserScope,
    scopes
  );
};

export const hasExpectedScopes = (
  isOwner = false,
  currentUserScope,
  scopes = "user"
) => {
  const allScopes = [
    "anonymous",
    "user",
    "speaker",
    "anim",
    "modo",
    "admin",
    "owner",
    "root",
  ];
  let ret = false;

  forEach(flattenDepth([scopes || ""], true), (scope) => {
    let operator = "";
    scope = scope.replace(/([\+\-]?)$/, function ($0, $1) {
      operator = $0;
      return "";
    });

    let index = indexOf(allScopes, scope);
    if (index === -1) {
      return;
    }

    //const currentUserScope = getUserScope(user, event);

    if (operator === "+") {
      ret |= allScopes
        .slice(index, allScopes.length)
        .includes(currentUserScope);
    } else if (operator === "-") {
      ret |= allScopes.slice(0, index + 1).includes(currentUserScope);
    } else {
      ret |= scope === "owner" ? isOwner : scope === currentUserScope;
    }
  });

  return !!ret;
};

export const getUserScope = (user, event, userAccesses) => {
  if (user === null || user.isAnon()) return "anonymous";

  let userScopes = [
    "root",
    "owner",
    "admin",
    "modo",
    "anim",
    "speaker",
    "user",
  ];
  let userScope = null;
  let scope = null;

  for (let i = 0; !userScope && i < userScopes.length; i++) {
    scope = userScopes[i];
    switch (scope) {
      case "root":
        userScope = user.isRoot() ? scope : userScope;
        break;
      case "owner":
        userScope = event && event.isOwner(user) ? scope : userScope;
        break;
      case "admin":
      case "modo":
      case "anim":
      case "speaker": {
        let accessScope = null;
        forEach(userAccesses || [], function (access) {
          accessScope =
            !accessScope ||
            userScopes.indexOf(access.scope) < userScopes.indexOf(accessScope)
              ? access.scope
              : accessScope;
        });
        userScope = scope === accessScope ? scope : userScope;
        break;
      }
      case "user":
        userScope = scope;
        break;
    }
  }

  return scope;
};

export const useUserAccessOfEvent = (keyword) => {
  const user = useUser();
  const access = useSelector((state) =>
    wizAccesseOfCurrentUserSelector(state, user?.hash, keyword)
  );

  if (!user || !access) {
    return null;
  }

  return access;
};

export const useSessionsUser = () => {
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const handleKeyword = useCallback(
    ({ hash, setEvents }) => {
      dispatch(
        resourcesFetch("event", {
          slug: ["event", keyword, "pages", "speaker", hash, "wiz"],
          silent: true,
          XHR_ID: `user_${hash}`,
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
            } else {
              setEvents(
                response.resources.filter(
                  (event) => event.attributes.is_session
                )
              );
              return response.resources;
            }
          },
        })
      );
    },
    [dispatch]
  );
  return handleKeyword;
};

export const useEventUser = () => {
  const dispatch = useDispatch();
  const handleKeyword = useCallback(
    ({ hash, setEvents }) => {
      dispatch(
        resourcesFetch("event", {
          slug: ["user", hash, "wiz"],
          silent: true,
          XHR_ID: `user_${hash}`,
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
            } else {
              setEvents(response.resources);
              return response.resources;
            }
          },
        })
      );
    },
    [dispatch]
  );
  return handleKeyword;
};

export const useEvents = ({ hash, forceFetch, listKeyword }) => {
  const dispatch = useDispatch();

  const events = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "event",
      sort: [
        (session) => session?.getEventState() === "WIZ_LIVE",
        "start",
        "title",
      ],
      order: ["desc", "desc", "asc"],
      filter: (e) => {
        return (e && e?.owner?.id === hash) || listKeyword.includes(e.keyword);
      },
    })
  );

  useEffect(() => {
    if (events.length === 0 || forceFetch) {
      dispatch(
        resourcesFetch("event", {
          slug: ["user", hash, "wiz"],
          silent: true,
          XHR_ID: `user_${hash}`,
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
            }
          },
        })
      );
    }
  }, []);

  return events;
};
