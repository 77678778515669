import { setCurrentProject } from "app/state/ducks/application/actions";
import { setCurrentCalendar, setCurrentLiveVendors, setCurrentVisitor } from "app/state/ducks/liveShopping/actions";
import { resourceEdit, resourceFetch } from "app/state/ducks/ressources/actions";

export const getVisitor = (dispatch, visitor, cb) => {
  dispatch(
    resourceFetch(visitor, {
      slug: ["visitors", visitor.hash, visitor.token],
      callback(error, response) {
        if (error) {
          console.log(error);
          cb && cb();
          return;
        }

        dispatch(setCurrentVisitor(response.single.id));

        if (response?.meta?.live !== null)
          dispatch(setCurrentLiveVendors(response.meta.live));

        // we must set the current project to be able to connect to realtime room
        if (response.included) {
          response.included.forEach(({ type, attributes }) => {
            if (type === "project")
              dispatch(setCurrentProject(attributes));
          });
        }

        cb && cb();
      },
    })
  );
};

// get calendar for visitor
export const getCalendar = (dispatch, visitor, cb) => {
  dispatch(resourceFetch("Visitor", {
    slug: ["visitors", visitor.hash, visitor.token, "calendar"],
    callback: (error, response) => {
      if (error) {
        console.log(error);
        cb && cb();
        return;
      }

      dispatch(setCurrentCalendar(response.single.attributes));
      cb && cb();
    },
  }))
};

export const rateAndComment = (dispatch, visitor, newVisitor, callback = () => { }) => {
  dispatch(
    resourceEdit(newVisitor, {
      slug: ["visitors", visitor.hash, visitor.token],
      patch: ["nps_score", "nps_comment"],
      callback,
    })
  );
};
