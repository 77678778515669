import React, { useState } from "react";
import { Box, ButtonBase, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { isUser } from "utilities/access";
import { useDispatch, useSelector } from "react-redux";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import { screenOrientationSelector } from "app/state/ducks/liveShopping/selectors";
import Gear from "react-bootstrap-icons/dist/icons/gear";
import RequestPermissions from "app/liveShopping/frontoffice/oto/components/Visio/RequestPermissions";
import CannotAccessDevices from "app/liveShopping/frontoffice/oto/components/Visio/CannotAccessDevices";
import WaitingForVendor from "app/liveShopping/frontoffice/oto/components/Visio/WaitingForVendor";
import { devicesSelector, errorSelector } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/selectors";
import Answer from "app/liveShopping/frontoffice/oto/components/Visio/Answer";
import { getDevices } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/sagas/getDevices";
import { _t } from "utilities/i18n";
import clsx from "clsx";
import VideoCard from "app/liveShopping/frontoffice/oto/components/Visio/VideoCard";
import { CANNOT_ACCESS_DEVICES, NEED_PERMISSION_PROMPT } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/errors";
import CheckSetup from "app/liveShopping/frontoffice/oto/components/Visio/CheckSetup";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Eye from "react-bootstrap-icons/dist/icons/eye";
import EyeSlash from "react-bootstrap-icons/dist/icons/eye-slash";

const useStyles = makeStyles((theme) => ({
  visio: {
    backgroundColor: theme.palette.colors.grey700,
    height: "85%",
    width: "50%",
    color: theme.palette.colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  visitor: {
    width: props => props.isMobile && !props.isAdmin ? "100%" : "50%",
    height: props => !props.isAdmin && props.isWaiting ? "250px" : !props.isAdmin && props.isPreparing ? "100%" : !props.isAdmin && props.isOngoing ? "100%" : !props.isAdmin && props.isRinging ? "100%" : "calc(50% - 84px)",
    zIndex: props => props.isPip && !props.isAdmin && 9999,
    borderRadius: props => props.isMobile && "none",
    position: "relative",
  },
  otherAdminPip: {
    position: "absolute",
    width: props => props.device === "mobile" ? "90px" : "160px",
    height: props => props.device === "mobile" ? "160px" : "90px",
    right: "10px",
    bottom: "10px",
    zIndex: 5,
    filter: "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))",
    borderRadius: "8px",
  },

  otherAdmin: {
    width: "50%",
    height: "100%",
    aspectRatio: "auto",
    position: "relative",
  },

  otherMobile: {
    width: "100%",
    height: "100%",
    borderRadius: "none",
    background: theme.palette.colors.grey600,
  },

  otherPip: {
    height: "100% !important",
  },
  otherFullScreen: {
    width: "100% !important",
    height: 'calc(100% - 60px) !important',
  },
  configButton: {
    position: "absolute",
    bottom: 5,
    right: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    borderRadius: "99px",
    zIndex: 9999,
  },
  blurButton: {
    position: "absolute",
    top: 15,
    left: 15,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    borderRadius: "99px",
    zIndex: 2147483648,
  },

}));

const Other = ({ visitor, otherStream }) => {
  const isDesktop = useMediaQuery("@media (min-width:400px)");
  const isWidget = useMediaQuery("@media (max-width:400px)");
  const isMobile = useMediaQuery("@media (max-width:600px)");
  const isPreparing = visitor.status === "preparing";
  const isWaiting = visitor.status === "waiting";
  const isOngoing = visitor.status === "ongoing";
  const isRinging = visitor.status === "ringing";
  const isPip = useMediaQuery("(max-height: 90px)");
  const isAdmin = isUser("user+");
  const currentVisitor = useSelector(currentVisitorSelector);
  const currentProject = useSelector(currentProjectSelector);
  const device = currentVisitor?.client_info.device;
  const screenOrientation = useSelector(screenOrientationSelector);
  const [openConfig, setOpenConfig] = useState(false);
  const error = useSelector(errorSelector);
  const devices = useSelector(devicesSelector);
  const [blurVisitor, setBlurVisitor] = useState(currentProject.options?.blur_visitor || false);
  const dispatch = useDispatch();
  const classes = useStyles({ isDesktop, isWidget, isMobile, isPip, isWaiting, isOngoing, isRinging, isPreparing, isAdmin, device, blurVisitor });
  const mode = visitor?.options?.mode;

  console.log('Other stream', mode);

  const hasOngoingStream = otherStream && visitor.status === "ongoing";

  return <Box className={clsx({
    [classes.visio]: true,
    [classes.visitor]: !isAdmin,
    [classes.otherAdminPip]: screenOrientation === "admin_pip",
    [classes.otherAdmin]: screenOrientation === "1x2_admin",
    [classes.otherMobile]: isMobile && !isAdmin,
    [classes.otherFullScreen]: !isAdmin && !isMobile && !isPip,
    [classes.otherPip]: !isAdmin && isPip,
  })}>

    {isAdmin && hasOngoingStream && (
      <Button clsName={classes.blurButton} onClick={() => setBlurVisitor(!blurVisitor)}>
        {blurVisitor ? <Eye /> : <EyeSlash />}
      </Button>
    )}

    <>
      {!isAdmin && !isPip && mode !== "chat" && (<ButtonBase onClick={() => setOpenConfig(true)} className={classes.configButton}><Gear /></ButtonBase>)}
      {openConfig && <CheckSetup setOpenConfig={setOpenConfig} checkMode={mode} />}

      {!isUser("user+") && visitor.status !== "ongoing" && (
        <>
          {(mode !== "chat" && error?.code === NEED_PERMISSION_PROMPT && !devices?.length) && <RequestPermissions onClick={() => dispatch(getDevices(mode))} />}

          {(mode !== "chat" && isPreparing && error?.code === CANNOT_ACCESS_DEVICES) && <CannotAccessDevices />}

          {((isPreparing || isWaiting) && (mode === "chat" || (!!devices?.length && !error?.code))) && <WaitingForVendor />}

          {isRinging && <Answer />}
        </>
      )}

      {!isAdmin && (!otherStream && visitor.status === "ongoing") && (
        <Box style={{ textAlign: "center" }}>{_t("Waiting for your interlocutor")}</Box>
      )}

      {isAdmin && (!otherStream && visitor.status === "ongoing" && mode === "chat") && (
        <Box style={{ textAlign: "center" }}>{_t("Your interlocutor is in text chat only mode")}</Box>
      )}

      {isAdmin && (!otherStream && mode !== "chat") && (
        <Box style={{ textAlign: "center" }}>{_t("Waiting for your interlocutor")}</Box>
      )}

      {hasOngoingStream && (
        <VideoCard
          blured={isAdmin && blurVisitor}
          key={"visitor_video"}
          streamId={otherStream.id}
          isOther={true}
          mode={visitor?.options?.mode}
          stream={{
            name: isUser("user+") ? _t("Visitor") : _t("Vendor"),
            hasAudio: otherStream.hasAudio,
            hasVideo: otherStream.hasVideo,
          }}
        />
      )}

    </>
  </Box>
};

export default Other;
