import React, { useState, useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import uuid from "core/uuid";
import cloneDeep from "lodash/cloneDeep";
import { _t } from "core/i18n";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { currentWizSelector } from "ressourcesDucks/selectors";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import { ActionButton } from "appComponents/Mui";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import makeRequest from "core/makeRequest";
import { API_BASE } from "config";
import * as ReactDOM from "react-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { resourceCreate, resourceEdit } from "ressourcesDucks/actions";
import emitter from "core/emitter";
import { EVENT_FLASH_SUCCESS } from "core/emitter/events";
import { templateSelector } from "ressourcesDucks/selectors";
import MailPreview from "adminComponents/Mails/Editor/MailPreview";
import MailSchedule from "adminComponents/Mails/Editor/MailSchedule";
import { replaceVariables } from "adminComponents/Mails/Editor/utils";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";
import {
  replaceEventLogo,
  replaceEventBackground,
} from "adminComponents/Mails/Editor/utils";
import { QuillToolbarDropDown } from "adminComponents/Mails/Editor/QuillTools";

dayjs.extend(relativeTime);
dayjs.extend(timezone);

/*
 * The timezone support in dayjs is not great and contains various bugs.
 * See: https://github.com/iamkun/dayjs/issues?q=timezone
 *
 * The main issue that affects us relates to the incorrect calculation of UTC
 * offsets when dealing with daylight savings time. There is a PR to fix this
 * open in the dayjs repo, which will hopefully be merged soon:
 * https://github.com/iamkun/dayjs/pull/1448
 *
 * Until then, we can replicate the work of that PR by writing an additional
 * plugin which overrides the existing UTC valueOf function with the fixed version
 * from https://github.com/iamkun/dayjs/pull/1448/files#diff-2612403b0bf524678efca9da730f3570b7a058b447238e00dd98d7f3d890abeeR116-R118
 */

const utcFix = (option, dayjsClass) => {
  dayjsClass.prototype.valueOf = function () {
    const addedOffset = !this.$utils().u(this.$offset)
      ? this.$offset + (this.$x.$localOffset || this.$d.getTimezoneOffset())
      : 0;
    return this.$d.valueOf() - addedOffset * 60 * 1000;
  };
};
dayjs.extend(utcFix);

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.default,
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  header: {
    width: "100%",
    paddingLeft: "54px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    width: "70%",
    paddingTop: "32px",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flexGrow: 1,
    maxWidth: "600px",
  },
}));

const MailEditor = ({ goBack, templateHash: tmpTemplateHash }) => {
  const [templateHash, setTemplateHash] = useState(tmpTemplateHash);

  const template = useSelector((state) =>
    templateSelector(state, templateHash)
  );

  const event = useSelector(currentWizSelector);
  const keyword = event.keyword;
  const classes = useStyles();
  const previewHtml = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTemplate, setShowTemplate] = useState(null);
  const [quillTemplate, setQuillTemplate] = useState(null);
  const [initialTemplate, setInitialTemplate] = useState(null);
  const [manualTemplate, setManualTemplate] = useState(null);
  const [areaContents, setAreaContents] = useState({});
  const [buttonAreaContents, setButtonAreaContents] = useState({});
  const [segments, setSegments] = useState(template.getSelectedSegments());
  const getHtmlFromDocument = (document) => {
    return document.documentElement.innerHTML;
  };

  useEffect(() => {
    if (initialTemplate) {
      onSave(template);
    }
  }, [segments]);

  useEffect(() => {
    const parser = new DOMParser();
    if (manualTemplate) {
      setInitialTemplate(parser.parseFromString(manualTemplate, "text/html"));
    }
  }, [manualTemplate]);

  useEffect(() => {
    /* When the initial template is fetched, we create and store eveything we need */
    if (initialTemplate) {
      let areas_uuids = {};
      /* From the initial template, we add ids on every editable content */
      const htmlEditorAreas = initialTemplate.querySelectorAll(".editablehtml");
      /* For each area found, we add some ids to be able to identify them individually */
      for (const area of htmlEditorAreas) {
        area.id = "area_" + uuid();
        areas_uuids[area.id] = null;
      }

      /* All the button management is there */
      let buttons_areas_uuids = {};
      const htmlButtonAreas = initialTemplate.querySelectorAll(
        ".editableButtonGroup"
      );
      for (const buttonArea of htmlButtonAreas) {
        buttonArea.id = "button_area_" + uuid();
        buttons_areas_uuids[buttonArea.id] = null;
      }

      let editingTemplate = initialTemplate.cloneNode(true);

      /* We set the editor template with the quill editor */
      Object.keys(areas_uuids).forEach((area_uuid) => {
        const area = editingTemplate.getElementById(area_uuid);
        const children = area.children[0];
        areas_uuids[area_uuid] = children.innerHTML;
        children.innerHTML = "";
      });

      /* We set the editor template with the quill editor */
      Object.keys(buttons_areas_uuids).forEach((button_area_uuid) => {
        const area = editingTemplate.getElementById(button_area_uuid);
        const children = area.children[0];
        buttons_areas_uuids[button_area_uuid] = children.innerHTML;
      });

      const quillTemplateHtml = addEditButtons(
        getHtmlFromDocument(editingTemplate)
      );

      setQuillTemplate(quillTemplateHtml);
      setAreaContents(areas_uuids);
      setButtonAreaContents(buttons_areas_uuids);
    }
  }, [initialTemplate]);

  useEffect(() => {
    if (editing) {
      const editor = previewHtml.current;

      const modules = {
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
      };

      Object.entries(areaContents).forEach(([area_uuid, area_content]) => {
        const holder = editor?.querySelector("#" + area_uuid);
        let quillEditorReference = null;
        if (holder) {
          const quillEditor = (
            <ReactQuill
              theme="snow"
              value={area_content}
              readOnly={false}
              ref={(el) => {
                quillEditorReference = el;
              }}
              onChange={(html) => {
                setAreaContents((prevState) => {
                  prevState[area_uuid] = html;
                  return { ...prevState };
                });
              }}
              modules={modules}
            />
          );

          ReactDOM.render(quillEditor, holder.children[0], () => {
            const variablesDropDown = new QuillToolbarDropDown({
              label: _t("Variables"),
              rememberSelection: false,
              items: [
                { value: "{{eventTitle}}", label: _t("Session title") },
                {
                  value: "{{eventStartDate}}",
                  label: _t("Session start date"),
                },
                {
                  value: "{{eventStartTime}}",
                  label: _t("Session start time"),
                },
                { value: "{{eventStopDate}}", label: _t("Session stop date") },
                { value: "{{eventStopTime}}", label: _t("Session stop time") },
                {
                  value: "{{eventMobileLogoPath}}",
                  label: _t("Event logo url"),
                },
                { value: "{{eventTimezone}}", label: _t("Event timezone") },
                {
                  value: "{{eventLiveStartDate}}",
                  label: _t("Live start date"),
                },
                {
                  value: "{{eventLiveStartTime}}",
                  label: _t("Live start time"),
                },
                {
                  value: "{{eventLiveStopDate}}",
                  label: _t("Live stop date "),
                },
                { value: "{{eventLiveStopTime}}", label: _t("Live stop time") },
                { value: "{{userFirstname}}", label: _t("User firstname") },
                { value: "{{userLastname}}", label: _t("User lastname") },
                { value: "{{userEmail}}", label: _t("User email") },
                { value: "{{userCompany}}", label: _t("User company") },
                { value: "{{userLocale}}", label: _t("User language locale") },
              ],
            });
            variablesDropDown.onSelect = function (label, value, quill) {
              const { index, length } = quill.selection.savedRange;
              quill.deleteText(index, length);
              quill.insertText(index, value);
              quill.setSelection(index + value.length);
            };
            variablesDropDown.attach(quillEditorReference.getEditor());
          });
        }
      });
      replaceEventLogo(editor, event);
      replaceEventBackground(editor, event);
    }
  }, [editing]);

  const generateAreasFromInitialTemplate = () => {
    const parser = new DOMParser();
    const tmpElement = parser.parseFromString(
      getHtmlFromDocument(initialTemplate),
      "text/html"
    );

    /* We replace everything from the area contents */
    Object.entries({ ...areaContents, ...buttonAreaContents }).forEach(
      ([area_uuid, area_content]) => {
        const element = tmpElement.getElementById(area_uuid);
        if (element && area_content) {
          element.children[0].innerHTML = area_content;
        }
      }
    );

    return getHtmlFromDocument(tmpElement);
  };

  const addEditButtons = (html) => {
    const parser = new DOMParser();
    const tmpElement = parser.parseFromString(html, "text/html");
    const htmlEditableAreas = tmpElement.querySelectorAll(".editablehtml");
    const htmlEditableAreasButtons = tmpElement.querySelectorAll(
      ".editableButtonGroup"
    );
    [...htmlEditableAreas, ...htmlEditableAreasButtons].forEach((area) => {
      var editButton = document.createElement("div");
      editButton.classList.add("editButton");
      editButton.id = area.id + "_editButton";
      area.appendChild(editButton);
    });
    return getHtmlFromDocument(tmpElement);
  };

  useEffect(() => {
    if (initialTemplate) {
      const htmlWithEditButton = addEditButtons(
        generateAreasFromInitialTemplate()
      );

      /* If area contents change, we update the showTemplate with the new area content and we replace the values */
      setShowTemplate(replaceVariables(htmlWithEditButton, event, user));
    }
  }, [areaContents, buttonAreaContents]);

  const editTemplate = useCallback((template, silentSave) => {
    const query = [];
    dispatch(
      resourceEdit(template, {
        slug: ["event", keyword, "mailer-templates", template.hash],
        query,
        //patch: ["active"],
        XHR_ID: `edit_event_templates_${templateHash}`,
        silent: true,
        callback: (error, response) => {
          if (error) {
            return;
          }
          if (!silentSave) {
            emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved !"));
          }
        },
      })
    );
  }, []);

  const createTemplate = useCallback((template) => {
    const query = [];
    /* Prevent click when we are not ready */
    if (loading) {
      return;
    }

    setLoading(true);
    dispatch(
      resourceCreate(template, {
        slug: ["event", keyword, "mailer-templates"],
        query,
        XHR_ID: `create_event_templates_${template.template_key}`,
        silent: true,
        callback: (error, response) => {
          if (error) {
            return;
          }
          const createdTemplate = response.resources.find(
            (r) =>
              r.type === "template" &&
              r.attributes.template_key === template.template_key
          );
          setTemplateHash(createdTemplate.attributes.hash);
          setLoading(false);
        },
      })
    );
  }, []);

  const onSave = (newTemplate) => {
    setEditing(false);
    newTemplate.setEvent(event);
    newTemplate.setSelectedSegments(segments);
    newTemplate.setContent(generateAreasFromInitialTemplate());
    if (template.is_prototype) {
      createTemplate(newTemplate);
    } else {
      editTemplate(newTemplate);
    }
  };

  const onGoBack = () => {
    goBack();
  };

  const loadData = useCallback(() => {
    const fetchTemplateHash = template.is_prototype
      ? template.template_key
      : template.hash;

    setLoading(true);
    (async () => {
      const response = await makeRequest(
        `${API_BASE}/event/${keyword}/mailer-templates/${fetchTemplateHash}/preview`,
        null,
        {
          method: "GET",
          rawResponse: true,
          raw: true,
          headers: { Accept: "text/html" },
        }
      );
      const parser = new DOMParser();
      const initialDocument = parser.parseFromString(response, "text/html");
      setInitialTemplate(initialDocument);
      setManualTemplate(getHtmlFromDocument(initialDocument));
      setLoading(false);
    })();
  }, [keyword, template.template_key, templateHash]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <Box className={clsx("MailEditor", classes.root)}>
      <Box className={clsx("Header", classes.header)}>
        <ActionButton
          icon={<ChevronLeftOutlinedIcon />}
          title={_t("Emails list")}
          inactiveColor="tertiary"
          onClick={onGoBack}
          toogleColor={false}
          controlled={true}
        />
      </Box>
      <Box className={clsx("Content", classes.content)}>
        <MailSchedule template={template} onSave={onSave} />
        <MailPreview
          ref={previewHtml}
          onSave={onSave}
          template={template}
          event={event}
          setEditing={setEditing}
          editing={editing}
          htmlTemplate={editing ? quillTemplate : showTemplate}
          manualTemplate={manualTemplate}
          setManualTemplate={setManualTemplate}
          areaContents={areaContents}
          buttonAreaContents={buttonAreaContents}
          setSegments={setSegments}
          setButtonAreaContents={setButtonAreaContents}
          loading={loading}
        />
      </Box>
    </Box>
  );
};
MailEditor.propTypes = {
  goBack: PropTypes.func,
  templateHash: PropTypes.string,
};
MailEditor.defaultProps = {
  templateHash: {},
};

export default MailEditor;
