import orm from "orm";

import { resourcesSelector } from "ressourcesDucks/selectors";

/*
 * This class is a mini reducer/store in itself
 * It sync the state with global state thanks to an event sent by resourcesReducer/entities on each store.entities change
 * It uses it locally to create redux-orm models and handle properly relations
 */
class ResourceFactory {
  constructor(state) {
    if (!state) {
      throw Error("Must give state to ResourceFactory");
    }

    this.state = resourcesSelector(state);
  }

  create(modelName, attrs = {}) {
    if (!modelName) {
      throw new Error("Cannot instanciate a Resource without a modelName");
    }

    const session = orm.session(this.state);
    const modelStore = session[modelName];

    if (!modelStore) {
      throw new Error(
        `${modelName} is not a valid ORM Model name. Did you use an upper case first letter?`
      );
    }

    const resource = modelStore.generate(attrs);

    this.state = session.state;

    return resource;
  }

  get session() {
    return orm.session(this.state);
  }
}

export default ResourceFactory;
