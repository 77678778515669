import React, { useEffect, useState } from "react";
import URI from "urijs";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import CustomLikeIcon from "app/liveShopping/assets/icons/CustomLikeIcon";
import Heart from "./Heart";
import { randomInt } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { API_BASE } from "config";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { applicationJSONCall } from "app/state/ducks/application/actions";
import emitter from "utilities/emitter";
import { NEW_ACCEPTANCE } from "utilities/emitter/events";

let timeout;
let hearts = [];

const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const FloatingHearts = ({ }) => {
  const dispatch = useDispatch();
  const event = useSelector(currentWizSelector);
  const [render, setRender] = useState(null);



  useEffect(() => {
    // if event not in replay mode do nothing
    if (!event?.replay) return;

    // every 5 seconds, one chance out of 2 to emit a new acceptance
    const interval = setInterval(() => {
      if (Math.random() > 0.5) emitter.emit(NEW_ACCEPTANCE);
    }, 5000);

  }, [event]);


  const onNewAcceptance = () => {
    const newHearts = [];

    for (let i = 0; i < randomIntFromInterval(1, 15); i++) newHearts.push({ random: i, date: Date.now(), mine: false });

    hearts = [...hearts, ...newHearts];
    setRender(Math.random());
  };

  useEffect(() => {
    emitter.on(NEW_ACCEPTANCE, onNewAcceptance);

    // clear ended hearts regularly to avoid mermory leaks
    const interval = setInterval(() => {
      hearts = hearts.filter(({ date }) => Date.now() - date < 3000);
      setRender(Math.random());
    }, 5000);

    return () => {
      clearInterval(interval);
      emitter.off(NEW_ACCEPTANCE, onNewAcceptance);
    };
  }, []);

  // clear our own hearts (only) after debounce
  const apiDebounce = () => {
    const own = hearts.filter(({ mine }) => mine);

    // make API DISPATCH CALL HERE
    console.log(`you have posted ${own.length} hearts since ${own[0]?.date}`);

    const url = new URI(API_BASE.toString().replace("/6", "/4")).segment("event").segment(event.keyword).segment("acceptance").toString();

    dispatch(
      applicationJSONCall(
        url,
        {
          acceptence: 1,
        },
        {
          method: "POST",
        }
      )
    );
  };

  const handleClick = () => {
    hearts = [...hearts, { random: randomInt(35), date: Date.now(), mine: true }];

    if (timeout) clearTimeout(timeout);

    // debounce time 5sec since animation lasts 3s (5 > 3)
    // once last animation ended, look how many hearts have been sent
    timeout = setTimeout(apiDebounce, 3000);
    setRender(Math.random());
  };


  

  return (
    <>
      <Box
        onClick={handleClick}
        style={{
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "5px",
          cursor: "pointer",
        }}
      >
        <CustomLikeIcon color={"#FFF"} />
      </Box>
      <Box>
        {hearts.map(({ random, date, mine }) => (
          <Heart key={`d:${date}:r:${random}:m:${mine ? 0 : 1}`} randomRight={random} mine={mine} />
        ))}
      </Box>
    </>
  );
};

FloatingHearts.propTypes = { animation: PropTypes.bool };

FloatingHearts.defaultProps = {};

export default React.memo(FloatingHearts);
