import { makeStyles, Box, Typography } from '@material-ui/core';
import Button from 'app/liveShopping/backoffice/otm/components/reusables/Button';
import React from 'react';
import { Alarm } from 'react-bootstrap-icons';
import { _t } from 'utilities/i18n';
import Shake from 'react-reveal/Shake';
import { useSelector } from 'react-redux';
import { currentProjectSelector } from 'app/state/ducks/application/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '0px 20px',
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    marginBottom: 20,
    textAlign: 'center',
  },
}));

const AppointmentReady = ({ setPrepare }) => {
  const classes = useStyles();
  const project = useSelector(currentProjectSelector);
  const wording = project.options.appointment_screen || {};

  return (
    <Box className={classes.root}>
      <Shake count={100} duration={3000}>
        <Alarm size={50} />
      </Shake>
      <Typography className={classes.title}>
        {_t("It's time for your appointment !")}
      </Typography>
      <Button onClick={() => setPrepare(true)}>{wording.join_appointment || _t("I join my adviser")}</Button>
    </Box>
  )
};

export default AppointmentReady;