import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { Select } from "app/views/components/Mui";
import { useDispatch, useSelector } from "react-redux";
import { projectsSelector } from "app/state/ducks/liveShopping/selectors";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { setCurrentProject } from "app/state/ducks/application/actions";
import { useHistory, useParams } from "react-router-dom";
import { SelectBox, SelectBoxItem } from "@tremor/react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    position: "absolute",
    bottom: "20px",
  },
}));

const ProjectSwitcher = ({ }) => {
  const classes = useStyles();
  const projects = useSelector(projectsSelector);
  const projectsToDisplay = projects?.map(({ id, title }) => ({ value: id, label: title }));
  const currentProject = useSelector(currentProjectSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const [project, setProject] = useState(currentProject?.hash);
  const { hash } = useParams();
  const solution = localStorage.getItem("projectId");
  const handleChange = (value) => {
    setProject(value);
    dispatch(setCurrentProject(projects.find(({ id }) => id === value)));
    localStorage.setItem("projectId", value);
    history.push(`/oto/${value}/dashboard`);
  };

  return (
    <SelectBox
      defaultValue={project || hash}
      className={classes.root}
      onValueChange={(value) => handleChange(value)}
      value={project || hash}
      placeholder={projectsToDisplay.find(({ value }) => solution === value)?.label}
    >
      {
        projectsToDisplay.map(({ value, label }) => <SelectBoxItem value={value}
          text={label}
          icon={undefined} />)
      }
    </SelectBox>);
};

ProjectSwitcher.propTypes = {};

ProjectSwitcher.defaultProps = {};

export default ProjectSwitcher;
