import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import CustomCartIcon from "liveShopping/assets/icons/CustomCartIcon";
import { useSelector, useDispatch } from "react-redux";
import { setOpenCart } from "liveShoppingDucks/actions";
import { fullScreenSelector, isIFrameSelector, mobileLayoutSelector, replayIsOpenSelector } from "app/state/ducks/liveShopping/selectors";
import { setOpenCatalog, setOpenReplay, setViewedProduct } from "app/state/ducks/liveShopping/actions";
import { currentCartSelector, currentUserSelector } from "app/state/ducks/ressources/selectors";
import { isUser } from "utilities/access";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5px",
  },
  cartWrapper: {
    position: "relative",
    marginTop: (props) => (props.isIFrame && props.mobileLayout ? "5px" : 0),
  },
  number: {
    position: "absolute",
    height: "20px",
    width: "20px",
    left: "20px",
    top: "5px",
    textAlign: "center",
    borderRadius: "99px",
    backgroundColor: (props) => (props.sum > 0 ? theme.palette.colors.error : theme.palette.colors.white),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  numberTypo: {
    color: theme.palette.colors.primary,
    padding: "0 7px",
    fontSize: "12px",
    color: (props) => (props.sum > 0 ? theme.palette.colors.white : theme.palette.colors.black),
  },
}));

const Cart = () => {
  const fullScreen = useSelector(fullScreenSelector);
  const replayIsOpen = useSelector(replayIsOpenSelector);
  const mobileLayout = useSelector(mobileLayoutSelector);
  const cart = useSelector(currentCartSelector);
  const isIFrame = useSelector(isIFrameSelector);
  const user = useSelector(currentUserSelector);

  const sum = cart.products.reduce((total, { quantity }) => total + quantity, 0);
  const classes = useStyles({ fullScreen, sum, isIFrame, mobileLayout });

  const dispatch = useDispatch();
  const openCart = () => {
    if (mobileLayout) {
      dispatch(setOpenCart(true));
    } else {
      if (replayIsOpen) dispatch(setOpenReplay(false));

      dispatch(setOpenCart(true));
      dispatch(setOpenCatalog(false));
      dispatch(setViewedProduct(null));
    }
  };

  if (isUser("anim+") || isUser("speaker+")) return null;
  return (
    <ButtonBase
      className={classes.root}
      onClick={() => {
        openCart();
      }}
    >
      <Box className={classes.cartWrapper}>
        <Box className={classes.number}>
          <Typography className={classes.numberTypo}>{sum}</Typography>
        </Box>
        <CustomCartIcon color={"#FFF"} />
      </Box>
    </ButtonBase>
  );
};

Cart.propTypes = { fullScreen: PropTypes.bool };

export default Cart;
