import { Box, makeStyles, Typography } from '@material-ui/core';
import Button from 'app/liveShopping/backoffice/otm/components/reusables/Button';
import Input from 'app/liveShopping/backoffice/otm/components/reusables/Input';
import Select from 'app/liveShopping/backoffice/otm/components/reusables/NewSelect';
import OneSwitch from 'app/liveShopping/backoffice/otm/components/reusables/Switch';
import React, { useState } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { _t } from 'utilities/i18n';
import { minutes, hours } from './hours';
import DatePicker from "app/liveShopping/backoffice/otm/components/reusables/DatePicker/DatePicker.js"
import dayjs from 'dayjs';
const useStyles = makeStyles((theme) => ({
  root: {
    width: "350px",
  },
  time: {
    display: "flex",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: "12px",
    color: theme.palette.colors.grey500,
    marginBottom: "20px",
  },
  override: {
    display: "flex",
    gap: "10px",
    flexDirection: "column",
  },
  overrides: {
    marginTop: "20px",
    height: "250px",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  overrideItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
  cta: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    gap: "10px",
  },
  unavailable: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
}));

const Override = ({ override, setOverride }) => {
  const classes = useStyles();
  const [start, setStart] = useState({ hour: "00", minute: "00" });
  const [end, setEnd] = useState({ hour: "00", minute: "00" });
  const [date, setDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [unavailable, setUnavailable] = useState(false);

  const handleOverride = () => {
    if (!date && !start && !end) return;
    if (unavailable) {
      setOverride({ ...override, [date]: [] });
      return;
    }

    setOverride({ ...override, [date]: [{ start: `${start.hour}:${start.minute}`, end: `${end.hour}:${end.minute}` }] });
  };

  const deleteOverride = (date, i) => {
    const newOverride = { ...override };
    delete newOverride[date];
    setOverride(newOverride);
  };

  const overrides = Object
    .keys(override)
    .sort((a, b) => dayjs(a).unix() - dayjs(b).unix())
    .filter((key) => dayjs(key).isAfter(dayjs()));

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Dates overrides")}</Typography>
      <Typography className={classes.subtitle}>{_t("Add dates when your availability changes from your daily hours.")}</Typography>
      <Box className={classes.override}>

        <DatePicker
          disableHours
          disableDate
          minDate={dayjs()}
          inputOnChange={(value) => { setDate(dayjs(value).format("YYYY-MM-DD")) }}
          date={date}
        />

        <Box className={classes.time}>
          <span style={{ display: "flex", gap: "10px" }}>
            <Select options={hours()} value={start.hour} onChange={(e) => setStart({ ...start, hour: e.target.value })} />
            <span style={{ display: "flex", alignItems: "center" }}>:</span>
            <Select options={minutes()} value={start.minute} onChange={(e) => setStart({ ...start, minute: e.target.value })} />
          </span>
          <span style={{ display: "flex", gap: "10px" }}>
            <Select options={hours()} value={end.hour} onChange={(e) => setEnd({ ...end, hour: e.target.value })} />
            <span style={{ display: "flex", alignItems: "center" }}>:</span>
            <Select options={minutes()} value={end.minute} onChange={(e) => setEnd({ ...end, minute: e.target.value })} />
          </span>
        </Box>
      </Box>
      <Box className={classes.cta}>
        <Button onClick={handleOverride} clsName={classes.add}>{_t("Add override")}</Button>
        <Box className={classes.unavailable}><OneSwitch checked={unavailable} onChange={() => setUnavailable(!unavailable)} />
          <Typography>{_t("Mark unavailable (All day)")}</Typography>
        </Box>
      </Box>

      <Box className={classes.overrides}>
        {overrides.map((key, index) => {
          return (
            <Box className={classes.overrideItem}>
              <Box>
                <Typography style={{ fontWeight: "bold" }}>{key}</Typography>
                {override[key].length === 0 && <Typography>{_t("Unavailable all day")}</Typography>}
                {override[key].map((o) => {
                  return (
                    <Box className={classes.time}>
                      <Typography>{o.start}</Typography>
                      <Typography>-</Typography>
                      <Typography>{o.end}</Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box>
                <Button onClick={() => deleteOverride(key, index)}>
                  <Trash />
                </Button>
              </Box>
            </Box>
          );
        })}

      </Box>
    </Box>
  );
}

export default Override;
