import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ChatList from "./ChatList";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import { desktopLayoutSelector, fullScreenSelector } from "app/state/ducks/liveShopping/selectors";
import PinnedElement from "./reusables/PinnedElement";
import { pinnedElement } from "./utils";

const useStyles = makeStyles(() => ({
  root: {
    visibility: (props) => (props.fullscreen ? "hidden" : "visible"),
    width: "100%",
    height: "calc(100% - 86px)",
    zIndex: (props) => !props.isDesktop && 3,
    background: (props) => (props.isDesktop ? "#FFF" : "transparent"),
    borderRadius: (props) => (props.isDesktop ? "0px 10px 10px 0px" : "none"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
}));

const Chat = ({ quotes }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const fullscreen = useSelector(fullScreenSelector);
  const classes = useStyles({ isDesktop, fullscreen });
  const pinnedQuote = pinnedElement(quotes);

  return (
    <Box className={classes.root}>
      {isDesktop && <PinnedElement {...pinnedQuote} />}
      <ChatList quotes={quotes} />
      <Footer quote={pinnedQuote} />
    </Box>
  );
};

Chat.propTypes = {};

Chat.defaultProps = {};

// Chat.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: "Chat",
// };
export default Chat;
