import React, { useEffect, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useFormik } from "formik";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useDispatch, useSelector } from "react-redux";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  typo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "5px",
  },
  image: {
    width: "112px",
    height: "187px",
    borderRadius: "12px",
    background: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },
  remove: {
    fontSize: "10px",
    color: theme.palette.colors.grey700,
    textDecoration: "underline",
    cursor: "pointer",
    marginTop: "10px",
  },
  iframe: {
    width: "112px",
    height: "187px",
    borderRadius: "12px",
    border: "none",
  },
}));
const VideoUpload = ({ wiz }) => {
  const classes = useStyles({ backgroundImage: wiz?.theme?.background_image });
  const [teasingVideo, setTeasingVideo] = useState(wiz?.extra_settings?.teasing_video);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const newVideo = resourceFactory.create("Event", {
    extra_settings: { teasing_video: teasingVideo },
  });

  useEffect(() => {
    if (teasingVideo === wiz.extra_settings.teasing_video) return;

    dispatch(
      resourceEdit(newVideo, {
        slug: ["event", wiz.hash],
        patch: ["extra_settings"],
      })
    );
  }, [teasingVideo]);

  const formik = useFormik({
    initialValues: {
      extra_settings: wiz?.extra_settings || {
        teasing_video: wiz?.extra_settings?.teasing_video || null,
      },
    },
  });
  return (
    <Box className={classes.root}>
      <Typography variant={"bodySB13"} className={classes.typo}>
        {_t("Teasing Video")}
      </Typography>

      {teasingVideo && (
        <>
          <iframe className={classes.iframe} src={teasingVideo} />
          <Box
            className={classes.remove}
            onClick={() => {
              setTeasingVideo(null);
              formik.setValues({
                ...formik.values,
                extra_settings: { teasing_video: null },
              });
            }}
          >
            {_t("Remove / Change")}
          </Box>
        </>
      )}

      {!teasingVideo && (
        <Button
          onClick={() => {
            const teasing_video = window.prompt(_t("Please enter Vimeo link"));
            formik.setValues({
              ...formik.values,
              extra_settings: { teasing_video },
            });
            setTeasingVideo(teasing_video);
          }}
        >
          {_t("Add")}
        </Button>
      )}
    </Box>
  );
};

export default VideoUpload;
