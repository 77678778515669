import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, Box, ButtonBase, useTheme } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import CheckCircleFill from "react-bootstrap-icons/dist/icons/check-circle-fill";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import ExclamationCircleFill from "react-bootstrap-icons/dist/icons/exclamation-circle-fill";
import { _t } from "utilities/i18n";
const useStyles = makeStyles((theme) => ({
  alrtAction: {
    "& .MuiIconButton-label": {
      color: theme.palette.colors.grey500,
    },
  },
  hideIcon: {
    display: "none",
  },
  messageWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0",
    width: "100%",
  },
  alrt: {
    background: "white",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px",
    gap: "15px",
  },
  wrapperIcon: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "8px",
    width: "32px",
    height: "32px",
    background: (props) => (props.error ? "rgba(240, 70, 75, 0.1)" : "rgba(0, 150, 150, 0.1)"),
    borderRadius: "99px",
    marginRight: "10px",
  },
  typoText: {
    color: theme.palette.colors.grey700,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
  },
  close: {
    marginRight: "10px",
  },
}));

const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

const CustomAlert = ({ }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [error, setError] = useState(false);
  const classes = useStyles({ error });
  const theme = useTheme();
  useEffect(() => {
    const callback = (data) => {
      setText(data);
      setError(false);
      setOpen(true);
    };

    const callbackError = (data) => {
      setError(true);
      setOpen(true);
      setText(data);
    };

    emitter.on(EVENT_FLASH_SUCCESS, callback);
    emitter.on(EVENT_FLASH_ERROR, callbackError);

    return () => {
      emitter.off(EVENT_FLASH_SUCCESS, callback);
      emitter.off(EVENT_FLASH_ERROR, callbackError);
    };
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
      <Alert
        action={
          <ButtonBase
            aria-label="close"
            color="inherit"
            size="small"
            className={classes.close}
            onClick={() => {
              setOpen(false);
            }}
          >
            <XLg size={16} color={theme.palette.colors.grey400} />
          </ButtonBase>
        }
        classes={{
          filledSuccess: classes.alrt,
          action: classes.alertAction,
          message: classes.messageWrapper,
          icon: classes.hideIcon,
        }}
        sx={{ width: "100%" }}
      >
        <Box className={classes.wrapperIcon}>
          {error && <ExclamationCircleFill color={theme.palette.colors.error} />}
          {!error && <CheckCircleFill color={theme.palette.colors.success} />}
        </Box>
        <Typography className={classes.typoText}>{text}</Typography>
      </Alert>
    </Snackbar>
  );
};

CustomAlert.propTypes = {};

CustomAlert.defaultProps = {};

export default CustomAlert;
