import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { _t } from "i18n";

export const parseDefaultValue = (element) => {
  let defaultValues = {
    rsvpTrue: {
      enabled: false,
      href: "{{eventPathUserVerified}}",
      label: _t("Yes, I'm coming"),
      type: "button",
    },
    rsvpFalse: {
      enabled: false,
      href: "{{eventPathWithoutUserRsvpFalse}}",
      label: _t("No, I'm not coming"),
      type: "link",
    },
    url: {
      enabled: false,
      href: "http://www.wisembly.com",
      label: _t("Your link"),
      type: "button",
    },
  };
  const rsvpTrueLink = element.querySelector(".editableButtonRsvpTrue a");
  const rsvpFalseLink = element.querySelector(".editableButtonRsvpFalse a");
  const urlLink = element.querySelector(".editableButtonUrl a");

  const hasTransparentBackground = (element) => {
    return (
      element?.parentElement?.bgColor?.length > 7 &&
      element.parentElement.bgColor.substr(-2) === "00"
    );
  };

  if (rsvpTrueLink) {
    defaultValues["rsvpTrue"] = {
      enabled: true,
      href: "{{eventPathUserVerified}}",
      label: rsvpTrueLink.innerText,
      type: hasTransparentBackground(rsvpTrueLink) ? "link" : "button",
    };
  }

  if (rsvpFalseLink) {
    defaultValues["rsvpFalse"] = {
      enabled: true,
      href: "{{eventPathWithoutUserRsvpFalse}}",
      label: rsvpFalseLink.innerText,
      type: hasTransparentBackground(rsvpFalseLink) ? "link" : "button",
    };
  }

  if (urlLink) {
    defaultValues["url"] = {
      enabled: true,
      href: urlLink.href,
      label: urlLink.innerText,
      type: hasTransparentBackground(urlLink) ? "link" : "button",
    };
  }
  return defaultValues;
};

export const generateHtmlForButton = (
  href,
  label,
  bgColor,
  color,
  textDecoration,
  type
) => {
  console.log("generateHtmlForButton", {
    href,
    label,
    bgColor,
    color,
    textDecoration,
    type,
  });

  return (
    <table
      border="0"
      cellPadding="0"
      cellSpacing="0"
      role="presentation"
      style={{
        verticalAlign: "top",
      }}
      width="100%"
    >
      <tbody>
        <tr>
          <td
            align="center"
            vertical-align="middle"
            className={`editableButton ${type}`}
            style={{
              fontSize: "0px",
              padding: "10px 25px",
              wordBreak: "break-word",
            }}
          >
            <table
              border="0"
              cellPadding="0"
              cellSpacing="0"
              role="presentation"
              style={{ borderCollapse: "separate", lineHeight: "100%" }}
            >
              <tbody>
                <tr>
                  <td
                    align="center"
                    bgcolor={bgColor}
                    role="presentation"
                    style={{
                      border: "none",
                      borderRadius: "3px",
                      cursor: "auto",
                      msoPaddingAlt: "10px 25px",
                      background: bgColor,
                    }}
                    valign="middle"
                  >
                    <a
                      href={href}
                      style={{
                        display: "inline-block",
                        background: bgColor,
                        color: color,
                        fontFamily:
                          "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "22px",
                        letterSpacing: "0.5px",
                        margin: "0",
                        textDecoration: textDecoration,
                        textTransform: "none",
                        padding: "10px 25px",
                        msoPaddingAlt: "0px",
                        borderRadius: "3px",
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {label}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export const generateButton = (href, label, type) => {
  return generateHtmlForButton(href, label, "#07698D", "#FFF", "none", type);
};

export const generateLink = (href, label, type) => {
  return generateHtmlForButton(
    href,
    label,
    "#07698D00",
    "#07698D",
    "revert",
    type
  );
};

export const generateRsvp = (href, label, isButton, isRsvpTrue) => {
  const fn = isButton ? generateButton : generateLink;
  const className = isRsvpTrue
    ? "editableButtonRsvpTrue"
    : "editableButtonRsvpFalse";
  return fn(href, label, className);
};

export const generateUrl = (href, label, isButton) => {
  const fn = isButton ? generateButton : generateLink;
  return fn(href, label, "editableButtonUrl");
};

export const generateRowOneButton = (button) => {
  return (
    <tr>
      <td
        style={{
          direction: "ltr",
          fontSize: "0px",
          padding: "20px 0",
          textAlign: "center",
        }}
      >
        <div
          className="mj-column-per-100 mj-outlook-group-fix"
          style={{
            fontSize: "0px",
            textAlign: "left",
            direction: "ltr",
            display: "inline-block",
            verticalAlign: "top",
            width: "100% !important",
            maxWidth: "100%",
          }}
        >
          {button}
        </div>
      </td>
    </tr>
  );
};

export const generateRowTwoButtons = (leftButton, rightButton) => {
  return (
    <tr>
      <td
        style={{
          direction: "ltr",
          fontSize: "0px",
          padding: "20px 0",
          textAlign: "center",
        }}
      >
        <div
          className="mj-column-per-50 mj-outlook-group-fix"
          style={{
            fontSize: "0px",
            textAlign: "left",
            direction: "ltr",
            display: "inline-block",
            verticalAlign: "top",
            width: "50% !important",
            maxWidth: "50%",
          }}
        >
          {leftButton}
        </div>
        <div
          className="mj-column-per-50 mj-outlook-group-fix"
          style={{
            fontSize: "0px",
            textAlign: "left",
            direction: "ltr",
            display: "inline-block",
            verticalAlign: "top",
            width: "50% !important",
            maxWidth: "50%",
          }}
        >
          {rightButton}
        </div>
      </td>
    </tr>
  );
};

export const generateButtonGroup = (state) => {
  const buttonsNumber = Object.values(state).filter(
    (option) => option.enabled === true
  ).length;

  let buttons = [];
  const { rsvpTrue, rsvpFalse, url } = state;
  if (rsvpTrue && rsvpTrue.enabled) {
    buttons.push(
      generateRsvp(
        rsvpTrue.href,
        rsvpTrue.label,
        rsvpTrue.type === "button",
        true
      )
    );
  }

  if (url && url.enabled) {
    buttons.push(generateUrl(url.href, url.label, url.type === "button"));
  }

  if (rsvpFalse && rsvpFalse.enabled) {
    buttons.push(
      generateRsvp(
        rsvpFalse.href,
        rsvpFalse.label,
        rsvpFalse.type === "button",
        false
      )
    );
  }

  if (buttonsNumber === 1) {
    return renderToStaticMarkup(generateRowOneButton(buttons[0]));
  }
  return renderToStaticMarkup(generateRowTwoButtons(buttons[0], buttons[1]));
};
