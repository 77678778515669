import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CameraVideo, CameraVideoOff, ExclamationTriangleFill, Mic, MicMute } from "react-bootstrap-icons";
import { _t } from "utilities/i18n";
import NavigatorAccessScreen from "./NavigatorAccessScreen";
import NavigatorBlockedIcon from "./NavigatorBlockedIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    gap: "30px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "20px",
    minWidth: "90%",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "10px",
    marginTop: "40px",
  },
  pictos: {
    display: "flex",
    gap: "10px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: "14px",
    color: theme.palette.colors.grey500,
    fontWeight: "bold",
  },
  text: {
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  },
  warningPicto: {
    color: theme.palette.colors.warning,
  },
  errorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
    width: "100%",
    height: "100%",
  },
  errorBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70px",
    height: "70px",
    borderRadius: "5px",
    backgroundColor: theme.palette.colors.error,
    gap: "5px",
  },
  errorTypo: {
    color: theme.palette.colors.white,
  },
  errorTitle: {
    fontSize: "18px",
    fontWeight: "bold",
    color: theme.palette.colors.error,
  },
  errorText: {
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
  },
}));


const ExplicativeScreen = ({ mode, error }) => {
  const classes = useStyles();

  if (error) {
    return (
      <Box className={classes.errorContainer}>
        <Box className={classes.errorBox}>

          {mode === "audio" ? <MicMute className={classes.errorTypo} size={25} /> : <><MicMute className={classes.errorTypo} size={25} /><CameraVideoOff className={classes.errorTypo} size={25} /></>}
        </Box>
        <Typography className={classes.errorTitle}>{_t("An error occurred")}</Typography>
        <Typography className={classes.errorText}>{(`Your ${mode === 'audio' ? 'microphone' : 'microphone or webcam'} is muted by your system setting.`)}</Typography>
        <span style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Typography className={classes.errorText}>{_t(`Click the blocked ${mode === 'audio' ? 'microphone' : 'microphone and webcam'} icon in your browser's address bar.`)}</Typography><NavigatorBlockedIcon mode={mode} />
        </span>

      </Box>
    )
  }

  if (mode === "video") {
    return (
      <Box className={classes.root}>
        <Box className={classes.box}>
          <Box className={classes.pictos}>
            <CameraVideo size={25} />
            <Mic size={25} />
          </Box>
          <Typography className={classes.title}>{_t("With my video and microphone on")}</Typography>
          <Typography className={classes.subtitle}>{_t("You see us and we will see you")}</Typography>
        </Box>
        <Typography className={classes.text}>{_t("Then we will ask you for permission to access the microphone and the camera.")}</Typography>
        <NavigatorAccessScreen mode={mode} />
      </Box>
    )
  }
  if (mode === "audio") {
    return (
      <Box className={classes.root}>
        <Box className={classes.box}>
          <Box className={classes.pictos}>
            <Mic size={25} />
            <CameraVideoOff size={25} />
          </Box>
          <Typography className={classes.title}>{_t("With my microphone on only")}</Typography>
          <Typography className={classes.subtitle}>{_t("You see us and we will hear you")}</Typography>
        </Box>
        <Typography className={classes.text}>{_t("Then we will ask you for permission to access the microphone.")}</Typography>
        <NavigatorAccessScreen mode={mode} />
      </Box>
    )
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        <Box className={classes.pictos}>
          <MicMute size={25} />
          <CameraVideoOff size={25} />
        </Box>
        <Typography className={classes.title}>{_t("With chat only")}</Typography>
        <Typography className={classes.subtitle}>{_t("You see us only")}</Typography>
      </Box>
      <Box className={classes.center}>
        <ExclamationTriangleFill className={classes.warningPicto} size={25} />
        <Typography className={classes.text}>{_t("Please note that this configuration is not recommended. For an optimal experience, we recommend that you use at least your microphone to talk with your interlocutor.")}</Typography>
      </Box>
    </Box>
  )
};

export default ExplicativeScreen;