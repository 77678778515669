import React from "react";
import PropTypes from "prop-types";

const CustomFacebookIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_201_2)">
        <path
          d="M26.2485 0.00728226L21.7099 0C16.6108 0 13.3156 3.38079 13.3156 8.61346V12.5848H8.75218C8.35785 12.5848 8.03852 12.9045 8.03852 13.2989V19.0529C8.03852 19.4473 8.35821 19.7666 8.75218 19.7666H13.3156V34.286C13.3156 34.6803 13.6349 34.9996 14.0293 34.9996H19.9832C20.3776 34.9996 20.6969 34.6799 20.6969 34.286V19.7666H26.0326C26.427 19.7666 26.7463 19.4473 26.7463 19.0529L26.7485 13.2989C26.7485 13.1095 26.6731 12.9282 26.5395 12.7942C26.4058 12.6602 26.2238 12.5848 26.0344 12.5848H20.6969V9.21825C20.6969 7.60013 21.0825 6.77869 23.1904 6.77869L26.2478 6.7776C26.6418 6.7776 26.9611 6.45791 26.9611 6.06394V0.720944C26.9611 0.327338 26.6421 0.00801049 26.2485 0.00728226Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_201_2">
          <rect width="35" height="34.9996" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

CustomFacebookIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomFacebookIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomFacebookIcon;
