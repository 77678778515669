import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { BookmarkStar } from "react-bootstrap-icons";
import { Modal, Typography, useTheme } from "@material-ui/core";
import { _t } from "utilities/i18n";
import FishingRod from "../../Component/Live/FishingRod";
import { useDispatch, useSelector } from "react-redux";
import { fishingRodSelector } from "app/state/ducks/liveShopping/selectors";
import { openFishingRod } from "app/state/ducks/liveShopping/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 16px",
    cursor: "pointer",
    width: "100%",
  },
  typo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.grey500,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const FishingRodContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const fishingRod = useSelector(fishingRodSelector);
  const theme = useTheme();

  const handleOpen = () => dispatch(openFishingRod(true));
  const handleClose = () => dispatch(openFishingRod(false));

  return (
    <>
      <Box className={classes.root}>
        <Box style={{ display: "flex", gap: "9px" }} onClick={handleOpen}>
          <BookmarkStar color={theme.palette.colors.grey500} />
          <Typography style={fishingRod ? { color: theme.palette.colors.primary } : null} className={classes.typo} >{_t("Fishing rod")}</Typography>
        </Box>
      </Box>
      <Modal open={fishingRod} className={classes.modal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <FishingRod handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default FishingRodContainer;