import React,{useState, useEffect} from "react"
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";

const CustomProgress = ({threshold})=>{
  const [hide, setHide]= useState(false);
  useEffect(()=>{

    const timeout = setTimeout(()=>{
      setHide(true)
    }, threshold)
    return()=> clearTimeout(timeout)
  },[])

  return !hide && <CircularProgress  color="secondary" />
}
CustomProgress.propTypes={
  threshold: PropTypes.number
}
CustomProgress.defaultProps={
  threshold: 3000
}
export default CustomProgress
