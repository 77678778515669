import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Teams from "adminContainers/users/admins";
import Participants from "adminContainers/users/participants";
import { _t } from "core/i18n";
import { Tabs, TabLabel } from "appComponents/Mui";
import AddUserPanel from "adminComponents/UserManagement/AddUserPanel";

import {
  EVENT_PARTICIPANT_USER_COUNT,
  EVENT_ADMIN_USER_COUNT,
} from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // backgroundColor: theme.palette.background.default,
    // //think about this later
    // zIndex: theme.zIndex.mobileStepper + 1,
    //
    // overflow: "auto",
    // position: "relative",
    // overscrollBehavior: "contain",
    // height: "100%",
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    //   overflow: "hidden",
    //   // position: "sticky",
    //   // bottom: "0",
    //   // left: "0",
    // },
    // // [theme.breakpoints.up("sm")]: {
    // //   position: "relative",
    // //   height: "100%",
    // // },
  },
}));

const UserManagement = () => {
  const classes = useStyles();

  const [tabSelected, setTabSelected] = useState(0);
  const teamTabLabel = (
    <TabLabel
      title={_t("Team")}
      tabSelected={tabSelected}
      channel={EVENT_ADMIN_USER_COUNT}
    />
  );
  const participantsTabLabel = (
    <TabLabel
      title={_t("Participants")}
      tabSelected={tabSelected}
      channel={EVENT_PARTICIPANT_USER_COUNT}
    />
  );

  return (
    <Box className={clsx(classes.root)}>
      <Tabs
        name="UserManagement"
        labels={[
          { id: "participants", value: participantsTabLabel },
          { id: "team", value: teamTabLabel },
        ]}
        panels={[
          { id: "participants", component: Participants, position: 0 },
          { id: "team", component: Teams, position: 1 },
        ]}
        panelsOrder={1}
        tabsOrder={0}
        onTabSelected={(tab) => {
          setTabSelected(tab);
        }}
      />
      <AddUserPanel />
    </Box>
  );
};
UserManagement.propTypes = {
  keyword: PropTypes.string,
};
export default UserManagement;
