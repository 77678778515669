import { API_BASE } from "settings/config";
import pluralize from "pluralize";

export default function getResourceUrl(...args) {
  let url = API_BASE.clone();

  for (let i = 0; i < args.length; i++) {
    let arg = args[i];

    if (typeof arg === "object") {
      // Handle Model given
      if (arg.id && arg.model) {
        url = url.segment(`${arg.model.backendName}s`).segment(arg.id);

        continue;
      }

      // handle locator type object (id is optional)
      if (arg.type) {
        url = url.segment(pluralize.plural(arg.type));

        if (arg.id) {
          url = url.segment(arg.id);
        }

        continue;
      }

      throw new Error("Unsupported object given to getResourceUrl");
    }

    url.segment(arg);
  }

  return url.toString();
}
