import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { SurveyItemImageViewer } from "appContainers/Viewers";
import { getMediaHashFromImage } from "utilities/utils/media";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  optionBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(43.25),
      width: theme.spacing(30),
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(23.25),
      width: theme.spacing(17),
    },
    marginBottom: theme.spacing(3),
  },

  description: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(1),
    width: "100%",
    height: theme.spacing(3.75),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(3.75),
    },
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    color: theme.palette.text.primary,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  main: {
    cursor: (props) => (props.canAnswer ? "pointer" : "auto"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: "auto",
    width: "100%",
    borderRadius: "8px",
    // borderBottomLeftRadius: "8px",
    // borderBottomRightRadius: "8px",
  },
  optionLabel: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "normal",
    lineHeight: "2.13",
    letterSpacing: "0.78px",
  },
  imageBox: {
    width: "100%",
    display: "flex",
    height: theme.spacing(30.375),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(17.25),
    },
    //height: theme.spacing(20),
  },
  content: {},
  itemLabel: {
    marginLeft: theme.spacing(2),
  },
}));

const UserImageSurveyItemResult = ({ surveyItemAnswer, surveyItem }) => {
  const userChoices = surveyItemAnswer.value;
  const options = get(surveyItem, "options.choices", []);
  const images = get(surveyItem, "options.images", []);

  const classes = useStyles();
  const result = map(options, (option, key) => {
    if (!userChoices.includes(option.hash)) return null;
    return (
      <Box key={key} className={clsx(classes.optionBlock)}>
        <Box className={clsx(classes.main)}>
          <Box className={classes.imageBox}>
            <SurveyItemImageViewer
              mediaHash={getMediaHashFromImage(get(images, `${option.hash}`))}
              rounded
            />
          </Box>
          <Box className={clsx(classes.description)}>
            <Typography className={classes.optionLabel}>
              {option.label}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  });

  return <Box className={classes.container}>{result}</Box>;
};
UserImageSurveyItemResult.propTypes = {
  surveyItem: PropTypes.object,
  surveyItemAnswer: PropTypes.object,
  survey: PropTypes.object,
};

export default UserImageSurveyItemResult;
