import React, { useEffect, useState, useRef } from "react";
import { _t } from "i18n";
import dayjs from "dayjs";
import ResourceFactory from "orm/resources";
import { setError } from "applicationDucks/actions";
import { useDispatch, useSelector } from "react-redux";
import { getKeyword } from "applicationDucks/selectors";
import { resourceCreate, resourceEdit } from "ressourcesDucks/actions";
import { currentWizReplaySelector } from "ressourcesDucks/selectors";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import AppLoader from "appComponents/AppLoader";
import Player from "@vimeo/player";
import { generateHash } from "core/uuid";
import customParseFormat from "dayjs/plugin/customParseFormat";
import clsx from "clsx";
import ChapterButton from "./ChapterButton";
import { ActionButton } from "app/views/components/Mui";
import { getProviderPlayer } from "app/liveShopping/frontoffice/otm/components/Player/utilities";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
dayjs.extend(customParseFormat);
import MuxPlayer from "@mux/mux-player-react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "row",
    padding: "25px",
  },
  managerContainer: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    // padding: "30px",
  },
  playerContainer: {
    flex: "1",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    // padding: "25px",
  },
  replayInfoContainer: {
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    margin: "0 30px",
    padding: "16px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontZize: "28px",
    lineWeight: "130%",
    textAlign: "center",
    color: "#0A0A0F",
    margin: "16px 0",
  },
  input: {
    flex: "1",
  },
  chapterContainer: {
    overflow: "scroll",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    padding: "16px 16px 0 16px",
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    margin: "0 30px 30px 30px",
  },
  chapterContent: {
    display: "flex",
    flexDirection: "column",
    minHeight: "150px",
    maxHeight: "150px",
    height: "100%",
    marginBottom: "20px",
    border: "1px solid #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "15px",
    padding: "30px",
  },
  infosText: {
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
  },
  onlineBubble: {
    minHeight: "5px",
    maxHeight: "5px",
    height: "5px",
    minWidth: "5px",
    maxWidth: "5px",
    width: "5px",
    borderRadius: "50%",
    margin: "auto 5px",
  },
  actionButton: {
    margin: "0 30px 16px",
    padding: "8px 16px",
    background: theme.palette.colors.primary,
    borderRadius: "99px",
    color: "white",
    "&:hover": {
      color: "black",
    },
  },
  player: {

    width: "100%",
    maxWidth: "500px",
    height: "50%",
    margin: "40px auto",

  },
  titleCreate: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    paddingLeft: "25px",
    paddingTop: "25px",
  },
}));

let once = false;

export default function ReplayManagement() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const replay = useSelector(currentWizReplaySelector);
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const [timeStamp, setTimeStamp] = useState(0);
  const [chapters, setChapters] = useState(replay?.chapters ?? []);
  const chapterEndRef = useRef(null);
  const [replayEnabled, setReplayEnabled] = useState(replay.enabled ?? false);
  const [replayLink, setReplayLink] = useState(replay.replay_url ?? "");
  const [replayStartsAt, setReplayStartsAt] = useState(dayjs(replay.starts_at).toISOString() || dayjs().toISOString());



  useEffect(() => {
    if (replay) {
      setChapters(replay.chapters);
      setReplayEnabled(replay.enabled);
      setReplayLink(replay.replay_url);
    }
  }, [replay]);

  useEffect(() => {
    chapterEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chapters]);

  useEffect(() => {
    if (replay) {
      replay.enabled = replayEnabled;
      replay.replay_url = replayLink;
    }
  }, [replayEnabled, replayLink]);

  function handleAddChapter() {
    const tmpChapters = [...chapters];
    tmpChapters.push({
      hash: generateHash(7),
      short: "",
      timestamp: timeStamp ?? 0,
      title: "",
    });
    setChapters(tmpChapters);
  }

  function handleEditChapter(newChapter) {
    const tmpChapters = [...chapters];

    const index = tmpChapters.findIndex(({ hash }) => newChapter.hash === hash);
    tmpChapters[index] = newChapter;

    tmpChapters.sort((a, b) => a.timestamp - b.timestamp);

    setChapters(tmpChapters);
  }

  function handleDeleteChapter(chapterToDelete) {
    const tmpChapters = [...chapters];
    const index = tmpChapters.indexOf(chapterToDelete);
    if (index !== -1) {
      tmpChapters.splice(index, 1);
      setChapters(tmpChapters);
    }
  }

  function saveReplay() {
    // filter chapters that havent title or short
    const chaptersValidated = chapters.filter(({ title }) => title !== "");

    replay.chapters = chaptersValidated;
    replay.starts_at = replayStartsAt;

    dispatch(
      resourceEdit(replay, {
        slug: ["event", keyword, "replay"],
        callback: (error) => {
          if (error) {
            setError(error);
            return;
          }

          emitter.emit(EVENT_FLASH_SUCCESS, _t("Replay saved"));
        },
      })
    );
  }

  const muxPlayer = document.querySelector("mux-player"); // eslint-disable-line

  muxPlayer?.addEventListener("timeupdate", function (event) {
    setTimeStamp(muxPlayer?.currentTime);
  });

  function renderPlayer() {

    return (
      <Box id="playerContainer" className={clsx("PlayerContainer", classes.playerContainer)}>
        <MuxPlayer
          className={clsx("Player", classes.player)}
          streamType="on-demand"
          playbackId={replay?.options?.playback_id}
          metadata={{
            video_id: "video-id-54321",
            viewer_user_id: "user-id-007",
          }}
        />
      </Box>
    );
  }

  function renderInfos() {
    return (
      <Box className={clsx("ReplayInfoContainer", classes.replayInfoContainer)}>
        <Typography>
          {_t("Video url: ")}
          <Input style={{ width: "100%" }} className={clsx("ReplayUrl", classes.input)} value={replayLink} onChange={(e) => setReplayLink(e?.target?.value)} />
        </Typography>
        <Typography className={clsx("InfosText", classes.infosText)}>
          {_t("Starts at: ")}
          <Input
            className={clsx("ReplayUrl", classes.input)}
            value={replayStartsAt}
            style={{ marginLeft: "10px" }}
            placeholder="DD/MM/YYYY HH:mm"
            onChange={(e) => setReplayStartsAt(e?.target?.value)}
          />
        </Typography>
      </Box>
    );
  }

  function renderChapters() {
    return (
      <>
        <Typography className={clsx("Title", classes.title)}>{_t("Chapters")}</Typography>
        <Box className={clsx("ChapterContainer", classes.chapterContainer)}>
          {chapters &&
            chapters.map((chapter, i) => {
              return (
                <Box key={chapter.hash} className={clsx("Chapter", classes.chapterContent)} ref={chapterEndRef}>
                  <ChapterButton index={i} chapter={chapter} onChange={(newChapter) => handleEditChapter(newChapter)} onDelete={() => handleDeleteChapter(chapter)} />
                </Box>
              );
            })}
        </Box>
      </>
    );
  }

  function renderChapterManager() {
    return (
      <Box className={clsx("ManagerContainer", classes.managerContainer)}>
        <Typography className={clsx("Title", classes.title)}>{_t("Replay information")}</Typography>
        {renderInfos()}
        {renderChapters()}
        <ActionButton title={_t("Add chapter")} className={clsx("ActionButtonStyled", classes.actionButton)} badgeContent={0} onClick={handleAddChapter} />
        <ActionButton title={_t("Save")} className={clsx("ActionButtonStyled", classes.actionButton)} badgeContent={0} onClick={saveReplay} />
      </Box>
    );
  }

  if (!replay) return <AppLoader />;

  return (
    <Box className={classes.root}>
      {renderChapterManager()}
      {renderPlayer()}
    </Box>
  );
}
