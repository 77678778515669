import BaseModel from "./base";
import { attr, oneToOne } from "redux-orm";

export const LANDING_BANNER_MODE = "banner";
export const LANDING_FULLSCREEN_MODE = "fullscreen";

class Landing extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Landing;
  }

  setEvent(Event) {
    this.event = Event.id;
  }

  isFull() {
    if (this.participant_limitation === 0)
      return false;
    return (this.registered_participants_count >= this.participant_limitation);
  }
}

Landing.modelName = "Landing";
Landing.backendName = "landing";

Landing.fields = {
  id: attr(),
  keyword: attr(),
  title: attr(),
  className: attr(),
  hash: attr(),
  layout_mode: attr(),
  registration_enabled: attr(),
  registration_fields: attr(),
  email_notifications: attr(),
  description: attr(),
  participant_limitation: attr(),
  registered_participants_count: attr(),
  event: oneToOne("Event", "landing"),
};

export default Landing;
