import { put, select } from "redux-saga/effects";
import { sessionSelector, getOwnVideoStream, getOwnScreenSharingStream } from "../selectors";
import { setStreamId, removeStream, removeElement, setSession, setCredentials, destroyPublisher, setPublisher } from "../actions";

// Unpublish own stream (audio/video or screen sharing)
export function* unpublishStream({ mode }) {
  console.log("[VISIO SAGA] UNPUBLISH STREAM")

  //do not throw unhandled error in sagas it fucks things up
  if (!window.OT) {
    console.error("No OT lib found");
    return;
  }

  const session = yield select(sessionSelector);
  const stream = mode === "video" ? yield select(getOwnVideoStream) : yield select(getOwnScreenSharingStream);

  if (!stream || !stream.publisher) return console.warn("[Visio] nothing to unpublish", mode);

  // TODO: understand why stored videoPublisher cannot unpublish a shit
  // need to retrieve publisher directly from stream object. This is weird (and cost me ~4h)
  try {
    session.unpublish(stream.publisher);
    stream.publisher.destroy();
  } catch (error) {
    console.log("[Visio] Error while tryin to unpublish", error);
  }
  yield put(removeStream(stream));
  yield put(setPublisher(mode, null));
  yield put(setStreamId(mode, null));
  yield put(removeElement(stream.id));
}
