import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Tag from "coreContainers/Tag/Tag";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Bubble from "../uikit/component/Bubble";
import Image from "../uikit/component/Image";
import Head from "../uikit/component/Head";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: (props) => (props.hasRightAlign ? "row" : "row-reverse"),
    justifyContent: "flex-end",
    padding: (props) => {
      if (props.pin) return "";
      return props.hasRightAlign ? "4px 15px 10px 39px" : "0px 39px 10px 15px";
    },
  },
  head: { margin: "4px 0px" },
  rightElement: {
    display: "flex",
    justifyContent: "flex-end",
  },
  image: {
    display: "flex",
    justifyContent: (props) => (props.hasRightAlign ? "flex-end" : "flex-start"),
  },
  actions: {
    alignSelf: "center",
  },
  reactions: {
    display: "flex",
    justifyContent: (props) => (props.hasRightAlign ? "flex-end" : "flex-start"),
    margin: (props) => (props.hasRightAlign ? "-5px 15px 0 0" : "-5px 0 0 15px"),
  },
}));

const ChatBubble = forwardRef(({ hash, quote, createdAt, origin, owner, isAdmin, imgPath, ActionElements, reactions, reaction_count, tagsEnabled, tagsDisplay, pin }, ref) => {
  const hasRightAlign = ["me", "replyByMe"].includes(origin);
  const classes = useStyles({ hasRightAlign, pin });
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box ref={ref} className={classes.root} onMouseEnter={() => setIsHovered(true)} onMouseOver={() => !isHovered && setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      {ActionElements && (
        <Box className={classes.actions}>
          <ActionElements hash={hash} isHovered={isHovered} />
        </Box>
      )}
      <Box>
        <Box className={classes.head}>
          <Head owner={owner} createdAt={createdAt} isAdmin={isAdmin} origin={origin} isHovered={isHovered} pin={pin} />
        </Box>
        {imgPath && (
          <Box className={classes.image}>
            <Image path={imgPath} size={75} />
          </Box>
        )}
        <Box
          className={clsx(classes.head, {
            [classes.rightElement]: hasRightAlign,
          })}
        >
          <Box>
            <Bubble quote={quote} origin={origin} pin={pin} />
          </Box>
        </Box>
        {tagsDisplay && (
          <Box
            className={clsx({
              [classes.rightElement]: hasRightAlign,
            })}
          >
            <Tag hasRightAlign={hasRightAlign} tagsEnabled={tagsEnabled} quoteHash={hash} />
          </Box>
        )}
      </Box>
    </Box>
  );
});

ChatBubble.displayName = "ChatBubble";

ChatBubble.propTypes = {
  hash: PropTypes.string,
  quote: PropTypes.string,
  createdAt: PropTypes.string,
  origin: PropTypes.string,
  owner: PropTypes.string,
  isAdmin: PropTypes.bool,
  imgPath: PropTypes.string,
  ActionElements: PropTypes.func,
  reactions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  reaction_count: PropTypes.number,
  tagsEnabled: PropTypes.bool,
  tagsDisplay: PropTypes.bool,
  pin: PropTypes.bool,
};

export default ChatBubble;
