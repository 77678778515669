import { useCallback } from "react";
import { _t } from "core/i18n";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/react";
import ResourceFactory from "orm/resources";

import { resourceEdit } from "ressourcesDucks/actions";
import { setError, fileUploadSaga } from "app/state/ducks/application/actions";

export const useBackgroundManager = () => {
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleStateBackground = useCallback(
    ({
      action,
      keyword,
      media,
      callback = () => {},
      errorCallback = () => {},
    }) => {
      switch (action) {
        case "add": {
          const slug = ["event", keyword, "background"];
          const callbackFunc = (error) => {
            if (error) {
              switch (error.message) {
                case "file size too big":
                  errorCallback &&
                    errorCallback(_t("Error: file size too big"));
                  break;
                default:
                  errorCallback && errorCallback(_t("Error: an error occured"));
              }
              setError(error);
              return;
            }

            callback && callback();
          };
          const data = new FormData();
          data.append("background", media);

          dispatch(
            fileUploadSaga(slug, data, {
              silent: true,
              callback: callbackFunc,
            })
          );
          break;
        }
        case "delete": {
          dispatch(
            resourceEdit(resourceFactory.create("Custom"), {
              slug: ["event", keyword, "background"],
              patch: ["theme"],
              callback: (error) => {
                if (error) {
                  Sentry.captureException(error);
                  errorCallback && errorCallback();
                  return;
                }
                callback && callback();
              },
            })
          );
        }
      }
    },
    [dispatch]
  );

  return handleStateBackground;
};

export const useLogoManager = () => {
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleStateLogo = useCallback(
    ({
      action,
      keyword,
      media,
      callback = () => {},
      errorCallback = () => {},
    }) => {
      switch (action) {
        case "add": {
          const slug = ["event", keyword, "logo"];
          const callbackFunc = (error) => {
            if (error) {
              switch (error.message) {
                case "file size too big":
                  errorCallback &&
                    errorCallback(_t("Error: file size too big"));
                  break;
                default:
                  errorCallback && errorCallback(_t("Error: an error occured"));
              }
              setError(error);
              return;
            }

            callback && callback();
          };
          const data = new FormData();
          data.append("logo", media);

          dispatch(
            fileUploadSaga(slug, data, {
              silent: true,
              callback: callbackFunc,
            })
          );
          break;
        }
        case "delete": {
          dispatch(
            resourceEdit(resourceFactory.create("Custom"), {
              slug: ["event", keyword, "logo"],
              callback: (error) => {
                if (error) {
                  Sentry.captureException(error);
                  errorCallback && errorCallback();
                  return;
                }
                callback && callback();
              },
            })
          );
        }
      }
    },
    [dispatch]
  );

  return handleStateLogo;
};
