import React from "react";
import URI from "urijs";
import get from "lodash/get";
import has from "lodash/has";
import { _t } from "i18n";
import isString from "lodash/isString";
import basil from "core/basil";

import { getTToken } from "core/session";

export const getMediaHashFromImage = (image) => {
  if (isString(image)) return image;

  return get(image, "media", image);
};
export let PROVIDER_GENERIC = "generic";
let mediaProviders = {
  ["youtube"]: (media) => ({
    getUrl() {
      return new URI(
        `https://www.youtube.com/embed/${media.data.id}`
      ).setSearch({
        autoplay: 1,
        controls: 0,
        rel: 0,
        showinfo: 0,
        ivLoadPolicy: 0,
        wmode: "transparent",
      });
    },
  }),

  ["vimeo"]: (media) => ({
    getUrl() {
      return new URI(
        `https://player.vimeo.com/video/${media.data.id}`
      ).setSearch({
        badge: 0,
        byline: 0,
        portrait: 0,
        title: 0,
      });
    },
  }),

  ["dailymotion"]: (media) => ({
    getUrl() {
      return new URI(
        `https://www.dailymotion.com/embed/video/${media.data.id}`
      ).setSearch({ info: 0, logo: 1, related: 0 });
    },
  }),

  ["prezi"]: (media) => ({
    getUrl() {
      return new URI(`https://prezi.com/embed/${media.data.id}/`).setSearch({
        bgcolor: "000000",
        lockToPath: 0,
        autoplay: 1,
        autohideCtrls: 1,
      });
    },
  }),

  ["office"]: (media) => ({}),

  ["embed"]: (media) => ({}),

  ["slides.com"]: (media) => ({
    getUrl() {
      return new URI(
        `https://slides.com/${media.data.id}/embed#/${media.live.currentSlide}?postMessageEvents=true&style=hidden`
      );
    },

    sendCurrentSlide(iframe) {
      let currentSlide = media.live ? media.live.currentSlide || 0 : 0;

      iframe.contentWindow.postMessage(
        JSON.stringify({
          method: "slide",
          args: [currentSlide],
        }),
        "*"
      );
    },
  }),

  ["uslide"]: (media) => ({
    getUrl() {
      return new URI(
        `https://www.uslide.io/presentations/${media.data.id}/embed`
      );
    },
  }),

  ["bunkr"]: (media) => ({
    getUrl() {
      return new URI(`https://bunkrapp.com/present/${media.data.id}/`);
    },
  }),

  ["join.me"]: (media) => ({
    getUrl() {
      return new URI(`https://join.me/${media.data.id}`);
    },
  }),

  ["appear.in"]: (media) => ({
    getUrl() {
      return new URI(`https://appear.in/${media.data.id}`);
    },
  }),

  ["freecaster"]: (media) => ({
    getUrl() {
      return new URI(
        `https://freecaster.tv/player/embed/${this.prop.media.data.id}`
      ).setSearch({
        width: "100%",
        height: "100%",
      });
    },
  }),

  ["brightcove"]: (media) => ({
    getUrl() {
      return new URI(
        "https://c.brightcove.com/services/viewer/federated_f9"
      ).setSearch({
        playerID: media.data.playerId,
        videoID: media.data.videoId,
        autoStart: 1,
        isVid: 1,
        isUI: 0,
        dynamicStreaming: 1,
      });
    },

    getEmbed(url, props) {
      return (
        <object
          {...props}
          type={"application/x-shockwave-flash"}
          data={String(url)}
        >
          <param name={"allowScriptAccess"} value={"always"} />
          <param name={"allowFullScreen"} value={"true"} />
          <param name={"wmode"} value={"transparent"} />
        </object>
      );
    },
  }),

  ["livestream"]: (media) => ({
    getUrl() {
      return new URI(
        `https://livestream.com/accounts/${media.data.accountId}/events/${media.data.eventId}/player`
      ).setSearch({
        autoPlay: true,
      });
    },
  }),

  ["gotomeeting"]: (media) => ({
    getUrl() {
      return new URI("https://app.gotomeeting.com").setSearch({
        meetingId: media.data.id,
      });
    },
  }),

  ["html5"]: (media) => ({
    getEmbed(url, props = {}) {
      return (
        <video {...props} controls={true} autoPlay={true}>
          <source src={String(url)} />
        </video>
      );
    },
  }),

  ["google drive"]: (media) => ({
    getUrl() {
      let url = media.data.url
        .replace(/^http:\/\//i, "https://")
        .replace(
          /\/(?:pub|edit)([?#].*)?$/gim,
          ($0, $1) => `/embed${$1 || ""}`
        );

      return new URI(url).addQuery("loop", false).addQuery("start", false);
    },
  }),
};

Object.assign(mediaProviders, {
  // Aliases

  ["drive"]: mediaProviders["google drive"],
  ["slides"]: mediaProviders["slides.com"],
});

export function getMediaProvider(media) {
  // FIXME: Le back ne devrait pas avoir media.type ET media.provider ET media.source.
  let displayMode =
    media.provider === PROVIDER_GENERIC ? media.source : media.provider;
  let defaultMediaProvider = {
    getUrl() {
      return media.data.url ? new URI(media.data.url) : null;
    },

    getEmbed(url, props) {
      return <iframe {...props} src={String(url)} />;
    },
  };

  if (!Reflect.ownKeys(mediaProviders).includes(displayMode)) {
    console.warn(
      `Unimplemented media provider "${displayMode}" (getMediaProvider)`
    ); // eslint-disable-line no-console
    return Object.assign(defaultMediaProvider, {
      getEmbed(url, props) {
        return (
          <div {...props}>
            {/* prettier-ignore */}
            <span className={"unsupported"}>
              {_t("Sorry but '{displayMode}' media provider is not supported.",{ displayMode })}
            </span>
          </div>
        );
      },
    });
  }

  return Object.assign(
    defaultMediaProvider,
    mediaProviders[displayMode](media)
  );
}

export const cacheImage = async (src) => {
  const promise = new Promise((resolve, reject) => {
    const img = new Image();
    img.src = src;
    img.onload = resolve();
    img.onerror = reject();
  });

  await Promise.all([promise]);
};

export const getUrl = ({ isMobile, media, thumbnails, step, length }) => {
  let thumbnail;
  //type can be: presentation, document/embed , video
  //source
  //provider : wisembly, google slide/youtube
  if (media.type === "embed") {
    thumbnail = get(media, "data.url", "empty");
    return thumbnail;
  } else if (has(thumbnails, "fixtures")) {
    thumbnail = thumbnails.fixtures.url;
  } else if (media.type === "video") {
    thumbnail = getMediaProvider(media);
    return thumbnail && thumbnail.getUrl() && thumbnail.getUrl().toString();
  } else {
    if (isMobile) {
      thumbnail = get(thumbnails, "small.url", "empty");
    } else {
      thumbnail = get(thumbnails, "medium.url", "empty");
    }
  }

  step = step || 0;

  try {
    for (let offset = 1; offset <= 2; offset++) {
      if (!length || offset + step >= length) {
        break;
      }

      cacheImage(thumbnail.replace(/\{\{ step \}\}/g, step + offset));
    }
  } catch (e) {
    console.log("something happen while preloading", e);
  }

  thumbnail = thumbnail.replace(/\{\{ step \}\}/g, step);

  return getSecuredUrl({ url: thumbnail });
};

export const getSecuredUrl = ({ url }) => {
  let uri = new URI(url);

  uri.search(getTToken({ url }));

  const keyword = window.location.pathname.slice(1);
  if (keyword && basil.get(`${keyword}.password`, null)) {
    uri.addSearch("password", basil.get(`${keyword}.password`, null));
  }

  return uri.toString();
};

export const getTypeWording = (type) => {
  switch (type) {
    case "image":
      return _t("Image");
    case "document":
      return _t("PDF document");
    case "presentation":
      return _t("PPT presentation");
    case "spreadsheet":
      return _t("Spreadsheet");
    case "video":
      return _t("Video");
    case "embed":
      return _t("embed");
    default:
      return _t(type);
  }
};
