import { Box, makeStyles } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import React, { useState } from "react";
import { _t } from "utilities/i18n";
import ResourceFactory from "orm/resources";
import { useDispatch, useSelector } from "react-redux";

import { resourceCreate } from "app/state/ducks/ressources/actions";
import { generateHash } from "utilities/utils/generateHash";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    width: "400px",
    marginTop: "20px",
  },
}));

const Form = ({ projectHash }) => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const dispatch = useDispatch();

  const handleSend = () => {
    const newAccess = resourceFactory.create("Access", { email: email, scope: "admin", hash: generateHash(40) });
    dispatch(
      resourceCreate(newAccess, {
        slug: ["projects", projectHash, "admins"],

        callback: (error, response) => {
          if (error) {
            console.log(error);
          }
        },
      })
    );
  };

  return (
    <Box className={classes.root}>
      <Input placeholder={_t("Enter an email")} onChange={(e) => setEmail(e.target.value)} />
      <Button onClick={handleSend}>{_t("Save")}</Button>
    </Box>
  );
};

export default Form;