/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-key */
import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { _t } from "utilities/i18n";
import { makeStyles } from "@material-ui/core";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { AGENDA_CONFIGURATION } from "app/liveShopping/frontoffice/oto/components/Appointment/data";
import { Trash } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "500px",
    height: "500px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  day: {
    display: "grid",
    gridTemplateColumns: "0.3fr 1fr 0.2fr",
    alignItems: "center",
    gap: "5px",
  },
  hours: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    position: "relative",
  },
  input: {
    maxWidth: "60px"
  },
  buttonAdd: {
    height: "40px",
    width: "40px",
    borderRadius: "50%",
    backgroundColor: theme.palette.colors.grey400,
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.colors.primary,
    },
  },
  unavailable: {
    color: theme.palette.colors.grey400,
  },
  boxDelete: {
    position: "absolute",
    top: "-10px",
    zIndex: "10 !important",
    right: "0",
    width: "20px",
    height: "20px",
    backgroundColor: theme.palette.colors.error,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.3s ease",
    cursor: "pointer",
  }
}));

const Schedule = ({ schedule, setSchedule }) => {
  console.log('schedule', schedule)

  const classes = useStyles();
  const [isHover, setIsHover] = useState(null);
  const handleAddSlot = (day, slot) => {

    const newSchedule = schedule.map((daySchedule) => {
      if (daySchedule.day === day) {
        return {
          ...daySchedule,
          slots: [...daySchedule.slots, slot],
        };
      }

      return daySchedule;
    });

    setSchedule(newSchedule);
  };

  const handleChange = (day, index, value, selectedSlot) => {
    const newSchedule = schedule.map((daySchedule) => {
      if (daySchedule.day === day) {
        return {
          ...daySchedule,
          slots: daySchedule.slots.map((slot, slotIndex) => {
            if (slotIndex === index) {
              if (selectedSlot === "start") {
                return { ...slot, start: value };
              }
              return { ...slot, end: value };
            }
            return slot;
          }),
        };
      }
      return daySchedule;
    });
    setSchedule(newSchedule);
  };

  // delete slot input 
  const handleDeleteSlot = (day, index) => {
    const newSchedule = schedule.map((daySchedule) => {
      if (daySchedule.day === day) {
        return {
          ...daySchedule,
          slots: daySchedule.slots.filter((slot, slotIndex) => slotIndex !== index),
        };
      }
      return daySchedule;
    });
    setSchedule(newSchedule);
  };

  return (
    <Box className={classes.root}>
      {schedule.map((dayConfig, scheduleIndex) => {

        return (
          <div className={classes.day} key={dayConfig.day}>
            <h3>{dayConfig.day}</h3>
            <Box style={{ display: "flex", gap: "15px", flexWrap: schedule[scheduleIndex].slots.length >= 3 ? "wrap" : "unset" }}>
              {schedule[scheduleIndex].slots.length === 0 && <Typography className={classes.unavailable}>{_t("Unavailable")}</Typography>}
              {schedule[scheduleIndex].slots.map((slot, index) => {

                const id = `${scheduleIndex}-${index}`
                return (
                  <span className={classes.hours} key={index} onMouseEnter={() => setIsHover(id)} onMouseLeave={() => setIsHover(null)}>
                    <Input onChange={(e) => handleChange(schedule[scheduleIndex].day, index, e.target.value, "start")} clsName={classes.input} value={slot.start} /> - <Input onChange={(e) => handleChange(schedule[scheduleIndex].day, index, e.target.value, "end")} clsName={classes.input} value={slot.end} />

                    {isHover === id && <Box className={classes.boxDelete} onClick={() => handleDeleteSlot(schedule[scheduleIndex].day, index)}><Trash color="white" /></Box>}
                  </span>
                )
              })}
            </Box>
            <div>
              <Button clsName={classes.buttonAdd} style={{ height: "40px" }} onClick={() => handleAddSlot(dayConfig.day, { start: "", end: "" })}>+</Button>
            </div>
          </div>
        )
      })}
    </Box>
  );
};

export default Schedule;
