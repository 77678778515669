import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { getTimeRemaining } from "utilities/utils/date";
import { ClockFill, RecordFill } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 12px",
    height: "32px",
    background: (props) => (!props.isPublic || props.isLive ? "#FFFFFF" : theme.palette.colors.grey200),
    borderRadius: "8px",
    position: "absolute",
    top: "8px",
    left: "8px",
  },
  typoDraft: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    color: "#FA962D",
    textAlign: "center",
  },
  typoLive: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    color: theme.palette.colors.error,
    textAlign: "center",
  },
  typoReady: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    color: theme.palette.colors.success,
    textAlign: "center",
  },
  typoComming: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    textAlign: "center",
    color: theme.palette.colors.grey700,
  },
}));

const Status = ({ wiz }) => {
  if (!wiz) return null;

  const { status, live_start } = wiz;
  const isPublic = status !== "draft" && status !== "closed";

  const classes = useStyles({ isPublic, isLive: status === "live" });
  const start_live = isPublic && getTimeRemaining(live_start);
  const [countDown, setCountDown] = useState(getTimeRemaining(start_live));
  const theme = useTheme();
  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getTimeRemaining(live_start);
      setCountDown(remaining);

      if (remaining.total < 0) clearInterval(interval);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getCount = () => {
    if (countDown.days !== 0) return _t("D") + " " + countDown.days;
    if (countDown.days === 0) return _t("H") + " " + countDown.hours;
    if (countDown.hours === 0) return _t("Mins") + " " + countDown.minutes;
  };

  if (!countDown.days && !countDown.hours && !countDown.minutes) return null;

  return (
    <Box className={classes.root}>
      {wiz.status === "draft" && (
        <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Typography variant={"bodySB13"} className={classes.typoLive}>
            {_t("Draft")}{" "}
          </Typography>
        </Box>
      )}

      {wiz.status === "pre_live" && (
        <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <ClockFill size={12} color={theme.palette.colors.grey700} />
          <Typography variant={"bodySB13"} className={classes.typoComming}>
            {getCount()}
          </Typography>
        </Box>
      )}

      {wiz.status === "ready_for_live" && (
        <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <RecordFill size={12} color={theme.palette.colors.success} />
          <Typography variant={"bodySB13"} className={classes.typoReady}>
            {_t("Ready")}{" "}
          </Typography>
        </Box>
      )}

      {wiz.status === "live" && (
        <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <RecordFill size={12} color={theme.palette.colors.error} />
          <Typography variant={"bodySB13"} className={classes.typoLive}>
            {_t("Live")}{" "}
          </Typography>
        </Box>
      )}

      {wiz.status === "awaiting_replay" && (
        <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Typography variant={"bodySB13"} className={classes.typoLive}>
            {_t("Waiting Replay")}{" "}
          </Typography>
        </Box>
      )}

      {wiz.status === "replay" && (
        <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Typography variant={"bodySB13"} className={classes.typoComming}>
            {_t("Replay")}{" "}
          </Typography>
        </Box>
      )}

      {wiz.status === "closed" && (
        <Box style={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Typography variant={"bodySB13"} className={classes.typoComming}>
            {_t("Closed")}{" "}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

Status.propTypes = {};

Status.defaultProps = {};

export default Status;
