import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import PatchCheckFill from "react-bootstrap-icons/dist/icons/patch-check-fill";
import RelativeDate from "appComponents/RelativeDate";

import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  headMe: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  headOthers: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  icon: {
    color: theme.palette.colors.secondary,
  },
  ownertxt: {
    color: theme.palette.colors.blackMedium,
    margin: "0px 8px",
  },
  createdAtTxt: {
    color: theme.palette.colors.greyDark,
    lineHeight: "130%",
    fontWeight: "400",
    fontSize: "10px",
  },
}));

const Head = ({ owner, origin, createdAt, isAdmin, isHovered, pin }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.headMe]: origin === "me",
        [classes.headOthers]: origin === "others",
      })}
    >
      {isAdmin && <PatchCheckFill className={classes.icon} />}
      <Typography className={classes.ownertxt} variant={"extraSmall1"}>
        {owner}
      </Typography>
      {createdAt && (isHovered || pin) && <RelativeDate className={classes.createdAtTxt} date={createdAt} />}
    </Box>
  );
};

Head.propTypes = {
  owner: PropTypes.string.isRequired,
  origin: PropTypes.string,
  createdAt: PropTypes.string,
  isAdmin: PropTypes.bool,
  isHovered: PropTypes.bool,
  pin: PropTypes.bool,
};

Head.defaultProps = {
  owner: "",
  origin: "",
  createdAt: "",
  isAdmin: false,
  isHovered: false,
  pin: false,
};

export default Head;
