import BaseModel from "./base";
import { attr, fk } from "redux-orm";
import { _t } from "i18n";

class Comment extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Comment;
  }

  getOwnerName() {
    if (this.username === null) {
      return `Anonymous`;
    }

    return this.username;
  }

  setQuote(Quote) {
    this.quote = Quote.id;
  }

  setUser(User) {
    this.user = User.id;
  }
}

Comment.modelName = "Comment";
Comment.backendName = "comment";

Comment.fields = {
  id: attr(),
  comment: attr(),
  created_at: attr(),
  persisted: attr(),
  username: attr(),
  quote: fk("Quote", "comments"),
  user: fk("User", "comments"),
};

export default Comment;
