import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { _t } from "core/i18n";

import { makeStyles } from "@material-ui/core/styles";
import Button from "appComponents/Mui/ActionButton";
import IconButton from "appComponents/Mui/IconButton";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import KeyboardArrowRightOutlinedIcon from "@material-ui/icons/KeyboardArrowRightOutlined";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
    //overflowX: "auto",
  },

  right: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  left: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  labelPosition: {
    marginLeft: 5,
    marginRight: 5,
  },
}));

const Pagination = ({
  page,
  maxPage,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const classes = useStyles();
  const disabledLeft = page < 1 || maxPage === 1;
  const disabledRight = maxPage === 1 || page === maxPage;

  return (
    <Box className={clsx("Pagination", classes.root)}>
      <Box className={classes.left}>
        <Box className={classes.changeRowPerpage}>
          <Button
            onClick={() => handleChangeRowsPerPage(20)}
            initState={true}
            forceSelection={rowsPerPage === 20}
            variant="outlined"
            inactiveColor="tertiary"
            activeColor="secondary"
            title="20"
          />
          <Button
            onClick={() => handleChangeRowsPerPage(50)}
            variant="outlined"
            forceSelection={rowsPerPage === 50}
            inactiveColor="tertiary"
            activeColor="secondary"
            title="50"
          />
          <Button
            onClick={() => handleChangeRowsPerPage(100)}
            variant="outlined"
            forceSelection={rowsPerPage === 100}
            inactiveColor="tertiary"
            activeColor="secondary"
            controlled={true}
            title="100"
          />
        </Box>
        <Box className={clsx(classes.labelPosition)}>
          {_t("Elements per page")}
        </Box>
      </Box>
      <Box className={classes.right}>
        <IconButton
          className={classes.icon}
          color="secondary"
          withoutbg
          disabled={disabledLeft}
          onClick={() => {
            handleChangePage(page - 1);
          }}
          size="small"
        >
          <ChevronLeftOutlinedIcon />
        </IconButton>
        <Box className={classes.labelPosition}>{`${
          page + 1
        } / ${maxPage}`}</Box>
        <IconButton
          className={classes.icon}
          color="secondary"
          withoutbg
          disabled={disabledRight}
          onClick={() => {
            handleChangePage(page + 1);
          }}
          size="small"
        >
          <KeyboardArrowRightOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
Pagination.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  refresh: PropTypes.func,
  maxPage: PropTypes.number,
  count: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
};

Pagination.defaultProps = {
  handleChangePage: () => {},
  handleChangeRowsPerPage: () => {},
};
export default Pagination;
