import React from "react";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import { makeStyles } from "@material-ui/core/styles";

import SwitchFocus from "./SwitchFocus";
import PropTypes from "prop-types";

import WisemblyJitsi from "./LiveMedia/Jitsi.js";
import WisemblyVonage from "./LiveMedia/Vonage.js";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "stretch",
    flex: 1,
    height: "100%",
    width: "100%",
    zIndex: (props) =>
      props.layoutMode === "pip" && !props.hasFocus
        ? theme.zIndex.mobileStepper
        : "auto",
  },
  iframe: {
    height: "100%",
    width: "100%",
  },
}));

const GetLive = ({ data, wiz, onSetFocus }) => {
  const classes = useStyles();
  switch (data.provider) {
    case "wisembly_visio":
      return <WisemblyJitsi data={data} wiz={wiz} />;
    case "wisembly_webinar":
    case "wisembly_visio_plus":
      return (
        <WisemblyVonage
          data={data}
          wiz={wiz}
          product={data.provider}
          onSetFocus={onSetFocus}
        />
      );
    default:
      return (
        <Box
          className={classes.iframe}
          dangerouslySetInnerHTML={{ __html: data.embed }}
        />
      );
  }
};

GetLive.propTypes = {
  data: PropTypes.object,
  wiz: PropTypes.object,
  onSetFocus: PropTypes.func,
};

const MemoGetLive = React.memo(GetLive, (prevProps, nextProps) =>
  isEqual(prevProps.data, nextProps.data)
);

const DisplayLiveMedia = ({ layoutMode, hasFocus, onSetFocus, data, wiz }) => {
  const classes = useStyles({ layoutMode, hasFocus });
  return (
    <Box position="relative" className={clsx("DisplayLiveMedia", classes.root)}>
      <MemoGetLive data={data} wiz={wiz} onSetFocus={onSetFocus} />

      {(layoutMode === "pip" || layoutMode === "split") && !hasFocus && (
        <SwitchFocus layout="live" onSetFocus={onSetFocus} />
      )}
    </Box>
  );
};

DisplayLiveMedia.propTypes = {
  data: PropTypes.object,
  layoutMode: PropTypes.string,
  hasFocus: PropTypes.bool,
  onSetFocus: PropTypes.func,
  wiz: PropTypes.object,
};

function areEqual(prevProps, nextProps) {
  const dataIsEqual = isEqual(prevProps.data, nextProps.data);
  const layoutModeIsEqual = isEqual(prevProps.layoutMode, nextProps.layoutMode);
  const hasFocusIsEqual = isEqual(prevProps.hasFocus, nextProps.hasFocus);

  return dataIsEqual && layoutModeIsEqual && hasFocusIsEqual;
}

const MemoDisplayLiveMedia = React.memo(DisplayLiveMedia, areEqual);

export default MemoDisplayLiveMedia;
