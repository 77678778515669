import React, { useState, memo, useMemo } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Box from "@material-ui/core/Box";

import Tooltip from "coreUiKit/components/Tooltip";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Icon from "coreUiKit/inputs/Icon";
import TagFill from "react-bootstrap-icons/dist/icons/tags-fill";

import TagPopover from "coreComponents/Tag/TagPopover";
import { hexToRGB } from "utilities/utils";

import {
    useTags,
    useTagManager,
    useQuoteManager,
} from "coreContainers/hooks/tagHooks";
import { useQuote } from "coreContainers/hooks/quoteHooks";

const useStyles = makeStyles((theme) => ({
    tagsIcon: {
        display: "flex",
        flexDirection: "row",
        justifyContent: (props) =>
            props.hasRightAlign ? "flex-end" : "flex-start",
    },
    tagIcon: {
        padding: "4px 0px",
    },
    tagsList: {
        width: "257px",
        display: "flex",
        justifyContent: (props) =>
            props.hasRightAlign ? "flex-end" : "flex-start",
        flexDirection: "row",
        flex: "none",
        flexWrap: "wrap",
    },
    color: {
        marginRight: (props) =>
            props.hasRightAlign ? "5px" : "0px",
        marginLeft: (props) =>
            props.hasRightAlign ? "0px" : "5px",
        marginBottom: "5px",
        borderRadius: "99px",
        display: "flex",
    },
    textTag: {
        margin: "5px 10px",
    }
}));

const Tag = ({ hasRightAlign, quoteHash }) => {
    const classes = useStyles({ hasRightAlign });
    const [anchorEl, setAnchorEl] = useState(null);

    const quote = useQuote(quoteHash);
    const { tags_list } = quote;
    const [tags] = useTags();
    const [tag, setTag] = useState(null);
    const tagsQuote = tags.filter(tag => tags_list.includes(tag.id));

    const handleAddTag = useTagManager(null);
    const handleManageTag = useTagManager(tag);
    const handleStateQuote = useQuoteManager(quote);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const quoteAddTag = (tag) => {
        handleStateQuote("add", tag)
    };
    const quoteRemoveTag = (tag) => {
        handleStateQuote("remove", tag)
    };
    const createTag = (name, color) => {
        handleAddTag("create", name, color);
    };
    const editTag = (name, color) => {
        handleManageTag("edit", name, color);
    };
    const deleteTag = () => {
        handleManageTag("delete");
    };

    const RightSide = () => {
        return (
            <>
                <Box className={classes.tagsList}>
                    {tagsQuote.map((tag, key) => {
                        return (
                            <Box key={key} style={{ backgroundColor: tag.color ? hexToRGB(tag.color, 0.1) : "#F1F1F1" }} className={clsx("Color", classes.color)}>
                                <Typography variant={"smallBody1"} style={{ color: tag.color }} className={classes.textTag}>
                                    {tag.name}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
                <Tooltip
                    title={"Tag"}
                    placement="top"
                    enterDelay={1500}
                    enterNextDelay={1500}
                >
                    <Box className={classes.tagIcon}>
                        <Icon size={"14"} IconComponent={TagFill} onClick={handleClick} />
                    </Box>
                </Tooltip>
            </>
        )
    }
    const LeftSide = () => {
        return (
            <>
                <Tooltip
                    title={"Tag"}
                    placement="bottom"
                    enterDelay={0}
                    enterNextDelay={0}
                >
                    <Box className={classes.tagIcon}>
                        <Icon size={"14"} IconComponent={TagFill} onClick={handleClick} />
                    </Box>
                </Tooltip>
                <Box className={classes.tagsList}>
                    {tagsQuote.map((tag, key) => {
                        return (
                            <Box key={key} style={{ backgroundColor: tag.color ? hexToRGB(tag.color, 0.1) : "#F1F1F1" }} className={clsx("Color", classes.color)}>
                                <Typography variant={"smallBody1"} style={{ color: tag.color }} className={classes.textTag}>
                                    {tag.name}
                                </Typography>
                            </Box>
                        )
                    })}
                </Box>
            </>
        )
    }

    return (
        <Box className={classes.tags}>
            <Box className={classes.tagsIcon}>
                {hasRightAlign ? RightSide() : LeftSide()}
                <TagPopover
                    tags={tags}
                    tagsQuote={tagsQuote}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    tag={tag}
                    setTag={setTag}
                    quoteAddTag={quoteAddTag}
                    quoteRemoveTag={quoteRemoveTag}
                    createTag={createTag}
                    editTag={editTag}
                    deleteTag={deleteTag}
                    hasRightAlign={hasRightAlign} />
            </Box>
        </Box>
    );
};

Tag.propTypes = {
    hasRightAlign: PropTypes.bool,
};

Tag.defaultProps = {
    hasRightAlign: false,
};
/*Tag.whyDidYouRender = {
    logOnDifferentValues: true,
    customName: "Tag",
};*/

export default memo(Tag);
