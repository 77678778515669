import React from "react";
import PropTypes from "prop-types";
import { _t } from "i18n";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Stepper from "./Stepper";

const useStyles = makeStyles(() => ({
  steppers: {
    marginTop: "8px",
    display: "flex",
    flexDirection: "row",
  },
  colon: {
    padding: "7px 10px",
  },
}));

const SelectedTime = ({
  hours,
  setHours,
  minutes,
  setMinutes,
  setHoursError,
  setMinutesError,
  hoursError,
  minutesError,
}) => {
  const classes = useStyles();

  return (
    <Box mb={"15px"}>
      <Typography variant={"bodySB13"}>
        {_t("Time")}
      </Typography>
      <Box className={classes.steppers}>
        <Stepper
          value={hours}
          valueOnChange={setHours}
          valueMax={24}
          width={76}
          error={hoursError}
          setError={setHoursError}
        />
        <Box className={classes.colon}>
          <Typography variant={"bodySB13"}>:</Typography>
        </Box>
        <Stepper
          value={minutes}
          valueOnChange={setMinutes}
          valueMax={60}
          width={76}
          error={minutesError}
          setError={setMinutesError}
        />
      </Box>
    </Box>
  );
};

SelectedTime.propTypes = {
  hours: PropTypes.number,
  setHours: PropTypes.func,
  minutes: PropTypes.number,
  setMinutes: PropTypes.func,
  startEnd: PropTypes.string,
  setHoursError: PropTypes.func,
  setMinutesError: PropTypes.func,
  errorHours: PropTypes.bool,
  errorMinutes: PropTypes.bool,
};

SelectedTime.defaultProps = {
  setAnchorEl: () => { },
  hours: 24,
  setHours: () => { },
  minutes: 60,
  setMinutes: () => { },
  startEnd: "",
  setHoursError: () => { },
  setMinutesError: () => { },
  hoursError: false,
  minutesError: false,
};

export default SelectedTime;