import React, { useState } from "react";
import { Box, Typography, useTheme } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { _t } from "utilities/i18n";

import dayjs from "dayjs";
import { fr } from "date-fns/locale";
import { useSelector } from "react-redux";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { DateRangePicker } from "@tremor/react";

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxShadow: "0px 15px 10px -15px rgba(240, 240, 244, 1)",
    padding: "16px 30px",
    backgroundColor: "transparent",
  },
  time: {
    display: "flex",
    gap: "10px",
  },
}));

const Header = ({ setDates, dates }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const currentProject = useSelector(currentProjectSelector);
  const theme = useTheme();

  return (
    <Box className={classes.header}>
      <Typography className={classes.title}>{currentProject?.title}</Typography>
      <Box className={classes.time}>

        <DateRangePicker
          value={[dates.since, dates.until, dates.period]}
          onValueChange={setDates}
          placeholder={_t("Select")}
          enableDropdown={true}
          enableYearPagination={true}
          color={"gray"}
          locale={fr}
          options={
            [{ value: 'today', text: _t("Today"), startDate: new Date(dayjs().startOf('day')) },
            { value: 'last_week', text: _t("Last week"), startDate: new Date(dayjs().subtract(7, 'days')) },
            { value: 'last_month', text: _t("Last month"), startDate: new Date(dayjs().subtract(30, 'days')) },
            { value: 'last_year', text: _t("Last year"), startDate: new Date(dayjs().subtract(365, 'days')) },
            ]}
          defaultValue={[dates.since, dates.until, dates.period]}
        />
      </Box>
    </Box>
  );
}

export default Header;
