import React, { useReducer, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

//import { EVENT_SHOW_FORM_INTRO } from "core/emitter/events";
//this context will mainly serve to know the index of the form to show
//the context can have as argument the formikContext(actually no)
//or call this context in the submit button compoentn
const PaginatorContext = React.createContext({
  onlineFilter: ["All", "online", "offline"],
  visibleColumns: [],
  sendingStatusFilter: [],
  panel: "team",
  usersData: [],
  selected: [],
  rsvpFilter: ["All", "willcome", "wontcome", "notanswered"],
  sendingOriginFilter: [
    "All",
    "origin_other",
    "origin_import",
    "origin_wisembly",
  ],
  search: "",
  emailsFilter: "",
});
const PaginatorDispatcherContext = React.createContext({
  dispatch: () => {},
});
const PaginatorUserDataContext = React.createContext({
  usersData: [],
  selected: [],
});

const PaginatorUserDataDispatcher = React.createContext({
  dispatch: () => {},
});

const paginatorUserDataReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USES_DATA": {
      if (!isEqual(state.usersData, action.usersData)) {
        return {
          ...state,
          usersData: action.usersData,
        };
      }
      break;
    }
    case "UPDATE_SELECTION": {
      if (!isEqual(action.selected, state.selected)) {
        return {
          ...state,
          selected: action.selected,
        };
      }
      break;
    }
    default:
      return state;
  }
  return state;
};
const paginatorContextReducer = (state, action) => {
  switch (action.type) {
    case "SET_PANEL": {
      if (!isEqual(action.panel, state.panel)) {
        return {
          ...state,
          panel: action.panel,
        };
      }
      break;
    }

    case "UPDATE_SEARCH": {
      if (!isEqual(state.search, action.search)) {
        return {
          ...state,
          search: action.search,
        };
      }
      break;
    }
    case "UPDATE_FILTERS": {
      switch (action.filter) {
        case "online": {
          if (!isEqual(state.onlineFilter, action.value)) {
            return {
              ...state,
              onlineFilter: action.value,
            };
          }
          break;
        }
        case "sendingStatus": {
          if (!isEqual(state.sendingStatusFilter, action.value)) {
            return {
              ...state,
              sendingStatusFilter: action.value,
            };
          }
          break;
        }
        case "sendingOrigin": {
          if (!isEqual(state.sendingOriginFilter, action.value)) {
            return {
              ...state,
              sendingOriginFilter: action.value,
            };
          }
          break;
        }
        case "rsvp": {
          if (!isEqual(state.rsvpFilter, action.value)) {
            return {
              ...state,
              rsvpFilter: action.value,
            };
          }
          break;
        }
        case "emails": {
          if (!isEqual(state.emailsFilter, action.value)) {
            return {
              ...state,
              emailsFilter: action.value,
            };
          }
          break;
        }
        default:
          break;
      }
      return state;
    }
    default:
      return state;
  }
  return state;
};

const PaginatorContextProvider = ({ children, panel }) => {
  const [paginatorContextState, dispatch] = useReducer(
    paginatorContextReducer,
    {
      onlineFilter: ["All", "online", "offline"],
      visibleColumns: [],
      sendingStatusFilter: [
        "clicked",
        "sent",
        "opened",
        "spam",
        "error",
        "blocked",
        "sending",
        "unsubscribed",
      ],
      panel,
      rsvpFilter: ["All", "willcome", "wontcome", "notanswered"],
      search: "",
      emailsFilter: "",
    }
  );
  const [userDataContextState, userDataDispatch] = useReducer(
    paginatorUserDataReducer,
    {
      selected: [],
      usersData: [],
      panel,
    }
  );

  return (
    <PaginatorDispatcherContext.Provider value={dispatch}>
      <PaginatorUserDataDispatcher.Provider value={userDataDispatch}>
        <PaginatorContext.Provider value={paginatorContextState}>
          <PaginatorUserDataContext.Provider value={userDataContextState}>
            {children}
          </PaginatorUserDataContext.Provider>
        </PaginatorContext.Provider>
      </PaginatorUserDataDispatcher.Provider>
    </PaginatorDispatcherContext.Provider>
  );
};

PaginatorContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  panel: PropTypes.string,
};
PaginatorContextProvider.defaultProps = {};
const usePaginatorContextState = () => {
  const state = useContext(PaginatorContext);
  if (state === undefined) {
    throw new Error(
      " usePaginatorContextState must be used within a PaginatorContextProvider"
    );
  }
  return state;
};
const usePaginatorUserDataContextState = () => {
  const state = useContext(PaginatorUserDataContext);
  if (state === undefined) {
    throw new Error(
      " usePaginatorUserDataContextState must be used within a PaginatorUserDataContext"
    );
  }
  return state;
};
const usePaginatorUserDataDispatcher = () => {
  const PaginatorContextDispatcher = useContext(PaginatorUserDataDispatcher);
  if (PaginatorContextDispatcher === undefined) {
    throw new Error(
      " usePaginatorUserDataDispatcher must be used within a PaginatorDispatcherContext"
    );
  }
  return PaginatorContextDispatcher;
};

const useContextFilter = () => {
  const state = useContext(PaginatorContext);
  if (state === undefined) {
    throw new Error(
      " usePaginatorContextState must be used within a PaginatorContextProvider"
    );
  }
  const filter = useMemo(() => {
    return {
      onlineFilter: state.onlineFilter,
      sendingStatusFilter: state.sendingStatusFilter,
      rsvpFilter: state.rsvpFilter,
      search: state.search,
      sendingOriginFilter: state.sendingOriginFilter,
      emailsFilter: state.emailsFilter,
    };
  }, [
    JSON.stringify(state.onlineFilter),
    JSON.stringify(state.sendingStatusFilter),
    JSON.stringify(state.rsvpFilter),
    JSON.stringify(state.sendingOriginFilter),
    JSON.stringify(state.emailsFilter),
    state.search,
  ]);
  return filter;
};
const usePaginatorContextDispatcher = () => {
  const PaginatorContextDispatcher = useContext(PaginatorDispatcherContext);
  if (PaginatorContextDispatcher === undefined) {
    throw new Error(
      " PaginatorContextDispatcher must be used within a PaginatorDispatcherContext"
    );
  }
  return PaginatorContextDispatcher;
};

export {
  usePaginatorContextState,
  PaginatorContextProvider,
  paginatorContextReducer,
  usePaginatorContextDispatcher,
  usePaginatorUserDataContextState,
  usePaginatorUserDataDispatcher,
  useContextFilter,
};
