import emitter from "core/emitter";
import { call } from "redux-saga/effects";

import {
  XHR_START,
  XHR_DONE,
  XHR_FAIL,
  XHR_ALWAYS,
  EVENT_FLASH_ERROR_VANISH,
} from "core/emitter/events";

import { getUserSession } from "core/session";
import { getResourceUrl } from "core/jsonApi";
import refreshSession from "applicationDucks/sagas/sessionSaga/refreshSession";
import makeRequest from "core/makeRequest";

import notify from "./notify";
import { handleRequestError } from "./handleRequestError";
import dispatch from "./dispatch";

const requestsCalledOnce = {};

const notifyXHR = (options) => {
  if (options.XHRId) {
    emitter.emit(XHR_DONE, options.XHRId);
    emitter.emit(XHR_ALWAYS, options.XHRId);
  }
};

export default function* request(url, body, options) {
  let session = getUserSession();
  let response = null;

  const sessionUrlBase = getResourceUrl({ type: "session" });

  if (options.once) {
    if (requestsCalledOnce[url]) {
      notifyXHR(options);

      // return cached response + calledOnce mark
      return {
        ...requestsCalledOnce[url],
        calledOnce: true,
      };
    }
  }

  requestsCalledOnce[url] = true;

  if (options.XHRId) {
    emitter.emit(XHR_START, options.XHRId);
  }

  // if session needs a little refresh, go for it
  if (session.shouldBeRefreshed() && url.indexOf(sessionUrlBase) === -1) {
    yield refreshSession();
    session = getUserSession();
  }

  try {
    response = yield call(makeRequest, url, body, options);
  } catch (error) {
    yield call(notify, options, null, error);

    yield call(handleRequestError, error);
    //add in orm resources sent with errors
    if (error.responseData) {
      yield call(dispatch, error.responseData, options);
    }

    // throw error
    if (!options.silent) {
      emitter.emit(EVENT_FLASH_ERROR_VANISH, error.message);
    }

    if (options.XHRId) {
      emitter.emit(XHR_FAIL, options.XHRId);
      emitter.emit(XHR_ALWAYS, options.XHRId);
    }

    return false;
  }

  notifyXHR(options);

  // store response if this endpoint use 'once' cache feature
  if (options.once) {
    requestsCalledOnce[url] = response;
  }

  return response;
}
