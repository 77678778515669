import { getKeyword } from "applicationDucks/selectors";
import { getUserSession } from "core/session";
import { ucfirst } from "core/utils";
import orderBy from "lodash/orderBy";
import orm from "orm";
import { createSelector } from "redux-orm";
import { createSelector as reselect } from "reselect";
import { currentVisitorSelector as applicationCurrentVisitorSelector } from "app/state/ducks/liveShopping/selectors";
import { Calendar } from "react-bootstrap-icons";
import { currentProjectSelector } from "../application/selectors";
import { getLocale } from "utilities/i18n";

export const resourcesSelector = (state) => state.resources;
export const ormSessionSelector = (state) => orm.session(resourcesSelector(state));

const propsSelector = (state, props) => props;

export const modelSelector = (state, type) => {
  const session = ormSessionSelector(state);

  const modelType = ucfirst(type);
  const modelStore = session[modelType];

  if (!modelStore) {
    throw new Error(`'${modelType}' model doesnt exist in the ORM`);
  }

  return modelStore;
};

export function genericResourceSelector(state, { type, filter }) {
  const collection = genericResourcesSelector(state, {
    type: type,
    filter: filter,
  });

  if (collection.length >= 1) {
    return collection[0];
  }
}
//experimental
export const getTagSession = createSelector(orm, (session) => {
  return session["Tag"];
});

export const genericResourcesSelector = createSelector(orm, propsSelector, (session, { type, order, sort, filter } = {}) => {
  if (!type) {
    throw new Error("You must provide a Resource");
  }

  const modelType = ucfirst(type);

  if (!session[modelType]) {
    throw new Error(`Unsupported model type '${modelType}'`);
  }

  // @see https://github.com/redux-orm/redux-orm/issues/224
  let collection = session[modelType].all().toModelArray();

  // filter first, it would reduce array size before ordering/sorting
  if (filter) {
    //filter can be an object or a function
    collection = collection.filter((item) => filter(item));
  }

  if (order && sort) {
    return orderBy(collection, sort, order);
  }

  return collection;
});

export const countGenericResourcesSelector = createSelector(orm, propsSelector, (session, { type, filter } = {}) => {
  if (!type) {
    throw new Error("You must provide a Resource");
  }

  const modelType = ucfirst(type);

  if (!session[modelType]) {
    throw new Error(`Unsupported model type '${modelType}'`);
  }

  if (filter) {
    //filter can be an object or a function
    // @see https://github.com/redux-orm/redux-orm/issues/224
    return session[modelType].filter((item) => filter(item)).count();
  }

  return session[modelType].count();
});

export const resourceSelector = createSelector(orm, propsSelector, (session, { type, id }) => {
  const modelType = ucfirst(type);

  if (!session[modelType]) {
    return null;
  }

  if (!session[modelType].idExists(id)) {
    return null;
  }

  return session[modelType].withId(id);
});

//this return object and not Orm model
export const quoteSelector = createSelector(orm.Quote, (quote) => {
  return quote;
});

//this return object and not Orm model
export const surveySelector = createSelector(orm.Survey, (survey) => {
  return survey;
});
//this return object and not Orm model
export const leaderboardSelector = createSelector(orm.LeaderBoard, (leaderboard) => {
  return leaderboard;
});

//this return items js objects
export const surveyItemsSelector = createSelector(orm.Survey.items, (items) => {
  return items;
});

export const currentUserSelector = createSelector(orm, propsSelector, (session) => {
  const { user_id } = getUserSession();

  // we have no valid user session in store, retrieve anonymous default user in store
  if (!user_id) {
    return session.User.withId(-1);
  }

  // otherwise, retrieve user from store
  // we MUST have it since in our default store we created it from getUserSession too
  return session.User.withId(user_id);
});

export const userSelector = createSelector(orm, propsSelector, (session, user_id) => {

  // const anon = session.User.withId(-1);
  const lang = getLocale();
  const anon = { id: -1, name: "Anonymous", isAnonymous: true, lang, isAnon: () => true };

  // we have no valid user session in store, retrieve anonymous default user in store
  if (!user_id) return anon;

  // otherwise, retrieve user from store
  const user = session.User.withId(user_id);
  if (user) return user;

  return anon;
});

export const usersSelector = createSelector(orm, (session) => session.User.all().toModelArray());

export const eventsSelector = createSelector(orm, (session) => session.Event.all().toModelArray());

export const accessesSelector = createSelector(orm, (session) => session.Access.all().toModelArray());

export const templatesSelector = createSelector(orm, (session) => session.Template.all().toModelArray());
export const signatureRequestSelector = createSelector(orm, (session) => session.SignatureRequest.all().toModelArray());
export const signaturesSelector = createSelector(orm, (session) => session.Signature.all().toModelArray());
export const signatureForSelector = reselect([propsSelector, signaturesSelector], (signatureRequestHash, collection) => {
  if (!signatureRequestHash) return [];
  return collection.filter((item) => item.signatureRequest.id === signatureRequestHash);
});
export const currentUserAccessesSelector = reselect([currentUserSelector, accessesSelector], (user, accesses) => accesses.filter((access) => access.isUser(user)));
export const presentUserAccessesSelector = reselect([accessesSelector], (accesses) => accesses.filter((access) => access.present));

export const currentWizSelector = reselect([getKeyword, eventsSelector], (keyword, wizes) => {
  const wiz = wizes.filter((w) => w.keyword === keyword)[0];

  return wiz ? wiz : null;
});

export const reportSelector = createSelector(orm, propsSelector, (session, keyword) => {
  if (!keyword) return null;

  return session.Report.withId(keyword);
});

export const currentWizReplaySelector = (state) => {
  const keyword = getKeyword(state);
  return (
    genericResourcesSelector(state, {
      type: "Replay",
      filter: (replay) => replay.ref.event === keyword,
    })[0] || {}
  );
};

export const replaySelector = createSelector(orm, propsSelector, (session) => session.Replay.first());

export const currentCartSelector = (state) => {
  return cartSelector(state) || { sum: 0, currency: "EUR", products: [] };
};

export const cartSelector = createSelector(orm, propsSelector, (session) => session.Cart.first());

export const currentWizAccess = reselect(propsSelector, accessesSelector, (admin = false, collection) => {
  const filter = admin ? (access) => access.scope !== "user" : (access) => access.scope === "user";

  collection = collection.filter((item) => filter(item));

  return collection;
});

export const currentSessionSelector = (state) => {
  const keyword = getKeyword(state);
  let currentSession = genericResourceSelector(state, {
    type: "EventSession",
    filter: (session) => session.ended_at === null && session.ongoing === true && session.event && session.event.keyword === keyword,
  });

  return currentSession;
};

export const currentWizReportSelector = (state) => {
  return reportSelector(state, getKeyword(state));
};

export const surveyAnswersSelector = (state, surveyHash) => {
  const answers = genericResourcesSelector(state, {
    type: "SurveyAnswer",
    filter: (answer) => answer.survey_hash === surveyHash,
  });
  return answers;
};

export const pinnedQuoteSelector = (state) => {
  const quote = genericResourcesSelector(state, {
    type: "Quote",
    filter: (quote) => quote.pinned === true,
  });
  return quote;
};

export const getOwnAccessesSelector = createSelector([orm, accessesSelector, currentProjectSelector, currentUserSelector], (session, accesses, project, user) => {
  if (!project || !user) return [];
  const groups = project.groups.toRefArray().map((group) => group.id);
  return accesses.filter((access) => groups.includes(access.group?.hash) && access.user?.hash === user?.hash);
});



// export const getOwnAccessesSelector = reselect([currentProjectSelector, accessesSelector, currentUserSelector], (project, accesses, user) => {
//   if (!project || !user) return [];
//   console.log("ownAccesses", accesses);
//   const groups = project.groups.toRefArray().map((group) => group.id);

//   return accesses.filter((access) => groups.includes(access.group.id) && access.ref.user.hash === user.hash);
// });

export const queuesSelector = (state) => {
  const queues = genericResourcesSelector(state, {
    type: "Queue",
  });
  return queues;
};

export const visitorsSelector = (state) => {
  const visitors = genericResourcesSelector(state, {
    type: "Visitor",
  });
  return visitors;
};

export const calendarsSelector = (state) => {
  const calendars = genericResourcesSelector(state, {
    type: "Calendar",
  });
  return calendars;
};

export const calendarSelector = reselect(propsSelector, calendarsSelector, (group, collection) => {
  if (!group) return null;
  return collection.find((item) => item.ref.group === group.hash);
})

export const templateSelector = reselect(propsSelector, templatesSelector, (templateHash = null, collection) => {
  return collection.find((item) => item.hash === templateHash);
});

export const productsSelector = (state) => {
  const keyword = getKeyword(state);
  return genericResourcesSelector(state, {
    type: "Product",
    filter: (product) => product.ref.event === keyword,
  });
};

export const currentVisitorSelector = (state) => {
  const current = applicationCurrentVisitorSelector(state);

  if (!current) return null;

  const result = genericResourcesSelector(state, {
    type: "Visitor",
    filter: (visitor) => visitor.id === current,
  });

  return result.length ? result[0] : null;
}

export const focusedProductsSelector = (state) => {
  const products = productsSelector(state);
  return products.filter((p) => p.started_at && !p.stopped_at).sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime());
};

export const focusedProductSelector = (state) => {
  const products = genericResourcesSelector(state, {
    type: "Product",
  });
  return products
    .filter((p) => p.started_at && !p.stopped_at)
    .sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime())
    .shift();
};

export const productSelector = createSelector(orm, propsSelector, (session, id) => session.Product.withId(id));

export const quoteModelSelector = createSelector(orm, propsSelector, (session, id) => {
  // we have no valid user session in store, retrieve anonymous default user in store
  if (!id) {
    return null;
  }

  if (!session.Quote.idExists(id)) {
    return null;
  }

  // otherwise, retrieve user from store
  // we MUST have it since in our default store we created it from getUserSession too
  return session.Quote.withId(id);
});
