import basil from "core/basil";
import { currentWizSelector } from "ressourcesDucks/selectors";
import globalStore from "appStore";
import { getUserSession } from "core/session";
import { useDispatch } from "react-redux";
import { useUser } from "coreContainers/hooks/userHooks";
import {
  setUsername,
  setUserParticipation,
  setParticipationModeStore,
} from "applicationDucks/actions";

const defaultMode = {
  is_anonymous: false,
  is_admin: false,
  is_user: false,
  is_participant: false,
};

export const getDefaultParticipationMode = () => defaultMode;

export const useInitPariticipationMode = () => {
  //const user = useSelector(currentUserSelector);
  const user = useUser();
  const dispatch = useDispatch();
  const participantMode = getParticipationMode();

  const checkStatus = () => {
    if ((!user || user?.isAnon()) && getCurrentMode() === "is_anonymous") {
      const data = { is_anonymous: true };
      setParticipationMode(data);
      dispatch(setParticipationModeStore(data));
      return;
    }
    if (!user?.isAnon() && !participantMode.is_admin) {
      const data = { is_user: true };
      setParticipationMode(data);
      dispatch(setParticipationModeStore(data));
      dispatch(setUserParticipation(false));
      //setAnonymousUserParticipation(false);
      dispatch(setUsername(user?.name));
      return;
    }
    if (!user?.isAnon() && participantMode.is_admin) {
      const data = { is_admin: true };
      setParticipationMode(data);
      dispatch(setParticipationModeStore(data));
      return;
    }

    if (!user?.isAnon() && getCurrentMode() === "is_participant") {
      const data = { is_participant: true };
      setParticipationMode(data);
      dispatch(setParticipationModeStore(data));
    }
  };

  return checkStatus;
};
export const getCurrentMode = () => {
  const state = globalStore.getState();
  const wiz = currentWizSelector(state);
  const session = getUserSession();

  if (!session || !wiz) {
    return;
  }

  const dataStorage = basil.get(
    `participationMode:${session.token}:${wiz.keyword}`
  );
  let currentMode = "is_anonymous";

  if (dataStorage) {
    const dataObject = JSON.parse(dataStorage);

    for (const data in dataObject) {
      if (dataObject[data]) {
        currentMode = data;
      }
    }
  }

  return currentMode;
};

export const getParticipationMode = () => {
  const state = globalStore.getState();
  const wiz = currentWizSelector(state);
  const session = getUserSession();

  if (!session || !wiz) {
    return;
  }

  const dataStorage = basil.get(
    `participationMode:${session.token}:${wiz.keyword}`
  );
  if (dataStorage) {
    return {
      ...defaultMode,
      ...JSON.parse(dataStorage),
    };
  }
  return defaultMode;
};

export const setParticipationMode = (newData = {}) => {
  const state = globalStore.getState();
  const wiz = currentWizSelector(state);
  if (!wiz) return;
  const session = getUserSession();

  if (!session && !wiz?.keyword) {
    return;
  }
  const data = { ...defaultMode, ...newData };
  basil.set(
    `participationMode:${session.token}:${wiz?.keyword}`,
    JSON.stringify(data)
  );
};
