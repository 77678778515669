import React, { useState } from "react";
import clsx from "clsx";
import { Box, CircularProgress, TextareaAutosize, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "utilities/i18n";
import importer from "./Importers/ShopifyImporter";
import Preview from "./Preview";
import { useDispatch, useSelector } from "react-redux";
import { resourceCreate } from "app/state/ducks/ressources/actions";
import { getKeyword } from "app/state/ducks/application/selectors";
import emitter from "utilities/emitter";
import ResourceFactory from "orm/resources";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative",
    padding: "15px",
    overflow: "auto",
  },
  mobileLayout: {
    overflow: "auto",
    flex: "1",
  },
  desktopLayout: {
    overflow: "auto",
    flex: 1,
  },
  row: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
  },
  actions: {
    marginLeft: "10px",
    display: "flex",
  },
  action: {
    cursor: "pointer",
    marginRight: "5px",
    textDecoration: "underline",
  },
  add: {

  },
  ignore: {

  }
}));

const ShopifyImport = () => {
  const classes = useStyles();
  const [jsonData, setJsonData] = useState(false);
  const [ignored, setIgnored] = useState({})
  const [added, setAdded] = useState({})
  const [loading, setLoading] = useState({})

  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const resourceFactory = useSelector(state => new ResourceFactory(state))

  const onChangeData = e => {
    let json;

    try {
      json = JSON.parse(e.target.value);
      console.log(json);
    } catch (error) {
      console.log(error);
      alert('Invalid JSON');
    }

    try {
      const data = importer(json);
      console.log(data);
      setJsonData(data);
    } catch (error) {
      console.log(error);
      alert('Invalid Shopify JSON');
    }
  }

  const handleCreate = data => {
    setLoading({ ...loading, [data.hash]: true })

    const finalData = { ...data, variants: data.variants?.map(variant => ({ ...variant, price: variant.price?.toString().replace(".", ","), previous_price: parseFloat(variant?.previous_price?.toString().replace(".", ",")) })) };
    const newProduct = resourceFactory.create("Product", finalData);

    dispatch(
      resourceCreate(newProduct, {
        slug: ["event", keyword, "products"],
        callback: (error) => {
          if (error) {
            console.log(error);
            emitter.emit(EVENT_FLASH_ERROR, _t("Error while trying to add this product"));
            return;
          }

          emitter.emit(EVENT_FLASH_SUCCESS, _t("The product has been added with success !"));
          setAdded({ ...added, [data.hash]: true })
        },
      })
    );
  }

  const onIgnore = ({ hash }) => {
    setIgnored({ ...ignored, [hash]: true })
  }

  if (!jsonData)
    return (
      <Box className={classes.root}>
        <Typography>{_t("Import from your Shopify catalog")}</Typography>
        <TextareaAutosize
          placeholder={_t("Copy/paste the content of your https://examplerechargestore.myshopify.com/admin/products.json url")}
          style={{ width: 600 }}
          minRows={3}
          maxRows={15}
          onChange={onChangeData}
        />
      </Box>
    );

  console.log(added, ignored);

  return (
    <Box className={classes.root}>
      {jsonData.map((data, i) => {
        const isAdded = added[data.hash];
        const isIgnored = ignored[data.hash];
        const isImporting = loading[data.hash];
        console.log("DATA", data)
        return (
          <Box key={i} className={classes.row}>
            <Preview data={data} />
            <Box className={classes.actions}>

              {!isIgnored && !isAdded &&
                <>
                  {isImporting !== true &&
                    <>
                      <Box onClick={() => handleCreate(data)} className={clsx(classes.action, classes.add)}>{_t("Add")}</Box>
                      <Box onClick={() => onIgnore(data)} className={clsx(classes.action, classes.ignore)}>{_t("Ignore")}</Box>
                    </>
                  }

                  {isImporting === true && <CircularProgress size={20} />}
                </>
              }

              {isIgnored && <span>Ignored</span>}
              {isAdded && <span>Added</span>}
            </Box>
          </Box>
        )
      })}
    </Box>
  );

  return null;
}

export default ShopifyImport;
