import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Groups from "../Component/Groups/Groups";

const useStyles = makeStyles((theme) => ({
  root: { width: "100%" },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    margin: "16px 30px",
  },
}));

const GroupsContainer = ({ }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Groups")}</Typography>
      <Groups />
    </Box>
  );
};

GroupsContainer.propTypes = {};

GroupsContainer.defaultProps = {};

export default GroupsContainer;
