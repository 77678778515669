import BaseModel from "./base";
import { attr } from "redux-orm";

class Organization extends BaseModel {
  constructor(args) {
    super(args);
    this.Model = Organization;
  }
}

Organization.modelName = "Organization";
Organization.backendName = "organization";

Organization.fields = {
  id: attr(),
  name: attr(),
  slug: attr(),
};

export default Organization;
