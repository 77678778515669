import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { _t } from "i18n";
import { resourceFetch } from "ressourcesDucks/actions";
import { leaderboardSelector } from "ressourcesDucks/selectors";
import {
  getKeyword,
  getFetchResultStatusSelector,
} from "applicationDucks/selectors";
import { LeaderBoard } from "appComponents/SurveyForms";
import { useLiveLeaderboard } from "adminComponents/Animation/hooks";
import forEach from "lodash/forEach";
const InteractivityLeaderBoardViewer = ({
  keyword,
  resourceFetch,
  fetchLeaderBoardStatus,
  layoutMode,
  hasFocus,
}) => {
  const isThumbnailView = layoutMode === "pip" && !hasFocus;
  //liveEditingList
  const liveLeaderBoard = useLiveLeaderboard();
  // leaderBoard
  const leaderboard = useSelector((state) =>
    leaderboardSelector(state, keyword)
  );

  const fetchRanking = useCallback(() => {
    const surveys = liveLeaderBoard.surveys;
    let query = [
      {
        key: "scoringBy",
        value: "participant",
      },
    ];
    forEach(surveys, (elem) => {
      query.push({
        key: "surveys[]",
        value: elem,
      });
    });
    if (isEmpty(surveys)) {
      query.push({
        key: "all",
        value: true,
      });
    }

    resourceFetch("leaderboard", {
      slug: ["event", keyword, "surveys", "ranking"],
      query,
      //  full: true,
      XHR_ID: `leaderboardStatus${keyword}`,
    });
  }, [keyword, JSON.stringify(liveLeaderBoard.surveys)]);

  useEffect(() => {
    fetchRanking();
  }, [fetchRanking]);

  if (
    (isEqual(fetchLeaderBoardStatus, "pending") ||
      isEmpty(fetchLeaderBoardStatus)) &&
    isEmpty(leaderboard)
  ) {
    return <CircularProgress />;
  }

  if (isEmpty(fetchLeaderBoardStatus))
    return <div>{_t("unable to get the leaderboard")}</div>;

  return (
    <LeaderBoard
      leaderboard={leaderboard}
      isThumbnailView={isThumbnailView}
      layoutMode={layoutMode}
    />
  );
};

InteractivityLeaderBoardViewer.propTypes = {
  keyword: PropTypes.string,
  resourceFetch: PropTypes.func,
  fetchLeaderBoardStatus: PropTypes.string,
  layoutMode: PropTypes.string,
  hasFocus: PropTypes.bool,
};
const mapStateToProps = (state) => {
  const keyword = getKeyword(state);
  return {
    keyword,
    fetchLeaderBoardStatus: getFetchResultStatusSelector(
      state,
      `leaderboardStatus${keyword}`
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resourceFetch: bindActionCreators(resourceFetch, dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InteractivityLeaderBoardViewer);
