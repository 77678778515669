import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { _t } from "utilities/i18n";
import dayjs from "dayjs";
import { CalendarEvent } from "react-bootstrap-icons";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

const useStyles = makeStyles((theme) => ({
  root: {
    height: "22px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 10px",
    background: props => props.readyAt ? theme.palette.colors.error : theme.palette.colors.success,
    borderRadius: "99px",
    gap: "5px",
  },
  appointmentTypo: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "100%",
    textAlign: "center",
    color: theme.palette.colors.white,
    textTransform: "uppercase",
  },
}));

const BadgeAppointment = ({ visitor }) => {
  const [pendingTime, setPendingTime] = useState({ seconds: 0, minutes: 0 });

  const classes = useStyles({ readyAt: visitor.ready_at });
  const hasOneDigit = val => String(Math.abs(val)).charAt(0) == val;

  useEffect(() => {
    const interval = setInterval(() => { estimatedPendingTime(visitor.ready_at); }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [visitor]);

  const estimatedPendingTime = (ready_at) => {
    if (!ready_at) return;
    const now = dayjs().unix();
    const rd_at = dayjs(ready_at).unix();
    const diff = now - rd_at;

    const minutes = Math.floor(diff / 60);
    const seconds = diff - minutes * 60;


    setPendingTime({
      seconds: hasOneDigit(seconds) ? `0${seconds}` : seconds,
      minutes: hasOneDigit(minutes) ? `0${minutes}` : minutes,
    });
  };

  return (
    <Box className={classes.root}>
      <CalendarEvent color="white" />
      <Typography className={classes.appointmentTypo}>
        {visitor.ready_at === null ? dayjs(visitor.scheduled_at).tz(visitor.queue.group.calendar.timezone).format("h:mm A") : `${pendingTime.minutes}:${pendingTime.seconds}`}
      </Typography>
    </Box>
  );
};

export default BadgeAppointment;
