import BaseModel from "./base";
import { attr, fk, oneToOne } from "redux-orm";

class Custom extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Custom;
  }
}

Custom.modelName = "Custom";
Custom.backendName = "custom";

//ressource to create and send cust
Custom.fields = {
  id: attr(),
  hash: attr(),
  lang: attr(),
  email: attr(),
  emails: attr(),
  keyword: attr(),
  password: attr(),
  firstname: attr(),
  lastname: attr(),
  company: attr(),
  scope: attr(),
  message: attr(),
  user: attr(),
};

export default Custom;
