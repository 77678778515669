import { attr, fk } from "redux-orm";
import BaseModel from "./base";

export const CURRENCIES = {
  EUR: { id: "EUR", label: "€ (EUR)", display: "€" },
  USD: { id: "USD", label: "$ (USD)", display: "$" },
  GBP: { id: "GBP", label: "£ (GBP)", display: "£" },
  JPY: { id: "JPY", label: "¥ (JPY)", display: "¥ (JPY)" },
  AUD: { id: "AUD", label: "$ (AUD)", display: "$ (AUD)" },
  CAD: { id: "CAD", label: "$ (CAD)", display: "$ (CAD)" },
  CHF: { id: "CHF", label: "Fr (CHF)", display: "Fr" },
  CNH: { id: "CNH", label: "¥ (CNH)", display: "¥ (CNH)" },
  HKD: { id: "HKD", label: "HK$ (HKD)", display: "HK$" },
  NZD: { id: "NZD", label: "$ (NZD)", display: "$ (NZD)" },
};

class Product extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Product;
  }

  setEvent(Event) {
    this.event = Event.id;
  }

  getVariant(hash) {
    return this.variants.find((v) => v.hash === hash);
  }

  getVariantByVendorId(vendor_id) {
    return this.variants.find(v => v.vendor_id === vendor_id);
  }

  getVariantPrice(hash) {
    return parseFloat(this.getVariant(hash)?.price || 0);
  }

  getVariantPreviousPrice(hash) {
    return parseFloat(this.getVariant(hash)?.previous_price || 0);
  }

  getVariantPictureUrl(hash) {
    const variant = this.getVariant(hash);
    return variant?.picture_url || this.picture_url;
  }

  hasVariants() {
    return this.variants.length > 1;
  }

  getPrice(variantHash = null) {
    if (!variantHash) return this.price;
    return this.getVariantPrice(variantHash);
  }

  getPreviousPrice(variantHash = null) {
    if (!variantHash) return this.previous_price;
    return this.getVariantPreviousPrice(variantHash);
  }

  getPictureUrl(variantHash = null) {
    if (!variantHash) return this.picture_url;
    return this.getVariantPictureUrl(variantHash) || "";
  }

  get price() {
    return this.variants[0]?.price;
  }

  get previous_price() {
    return this.variants[0]?.previous_price;
  }

  get currency() {
    return this.event?.currency || "EUR";
  }

  get displayCurrency() {
    return CURRENCIES[this.currency]?.display || "€";
  }
}

Product.modelName = "Product";
Product.backendName = "product";

Product.fields = {
  id: attr(),
  data: attr(),
  type: attr(),
  class_name: attr(),
  hash: attr(),
  name: attr(),
  short: attr(),
  product_url: attr(),
  category_url: attr(),
  description: attr(),
  enabled: attr(),
  uploaded_at: attr(),
  persisted: attr(),
  live: attr(),
  vendor_id: attr(),

  /*
    {
      "hash": "p987e",
      "name": "L",
      "price": null,
      "picture_url": null,
      "vendor_id": "41263668101316"
    }
  */
  variants: attr(),
  event: fk("Event", "products"),
};

export default Product;
