import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import PropTypes from "prop-types";
import { _n, _t } from "core/i18n";
import round from "lodash/round";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import ExclamationCircleFill from "react-bootstrap-icons/dist/icons/exclamation-circle-fill";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import Image from "react-bootstrap-icons/dist/icons/image";
import CustomPlusLg from "coreUiKit/assets/icons/CustomPlusLg";
import Loader from "app/liveShopping/frontoffice/otm/components/AppLoader";

import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Icon from "coreUiKit/inputs/Icon";
import IconButton from "coreUiKit/inputs/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    width: "125px",
    height: "210px",
    border: "2px dashed #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "12px",
    cursor: "pointer"
  },
  title: {
    marginBottom: "5px",
  },
  icon: {
    width: "32px",
    height: "32px",
    marginBottom: "15px",
    color: theme.palette.colors.greyDark,
  },
  smallIcon: {
    width: "24px",
    height: "24px",
    marginBottom: "12px",
  },
  iconError: {
    width: "12px",
    color: theme.palette.colors.error,
    marginRight: "5px",
  },
  boxError: {
    display: "flex",
    flexDirection: "row",
    marginTop: "15px",
  },
  boxFileAccepted: {
    display: "flex",
    flexDirection: "row",
    "& $icon, & .boxFileAcceptedContent": {
      margin: "0 10px 0 0",
    },
  },
  errorsFile: {
    whiteSpace: "pre-line",
  },
  colorLoader: {
    color: theme.palette.colors.blackMedium,
  },
  preview: {
    background: ({ previewImage }) =>
      `center / cover no-repeat url(${previewImage})`,
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  },
  hover: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    width: "125px",
    height: "210px",
    border: "2px dashed #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "12px",
  },
}));

const DefaultValue = ({ previewImage, disabled, removeDisabled, onRemove }) => {
  const classes = useStyles({ previewImage });
  const [hover, setHover] = useState(false);

  const handleRemoving = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onRemove();
  };

  return (
    <Box
      className={classes.preview}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {hover && (
        <Box className={classes.hover}>
          <Box>
            <IconButton color="tertiary" disabled={disabled}>
              <Image />
            </IconButton>
          </Box>
          <IconButton
            color="alert"
            disabled={removeDisabled}
            onClick={handleRemoving}
          >
            <Trash />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

const EmptyValue = ({
  title,
  instruction,
  IconComponent,
  isSmall,
  availableFormats,
}) => {
  const classes = useStyles();

  return (
    <>
      <IconComponent
        className={clsx(classes.icon, { [classes.smallIcon]: isSmall })}
      />
      {isSmall ? (
        <Typography align="center" variant="bodySB13" color="greyDark">
          {_t("Add")}
        </Typography>
      ) : (
        <>
          <Typography
            className={classes.title}
            align="center"
            variant="bodySB13"
          >
            {title}
          </Typography>
          <Typography align="center" variant="smallBody13" color="greyDark">
            {
              /* prettier-ignore */
              availableFormats?.length ? (
                `${_n("Format", "Formats", availableFormats?.length)}: ${availableFormats?.join()}`
              ) : (
                `${_t("All file types are accepted.")}`
              )
            }
          </Typography>
          <Typography align="center" variant="smallBody13" color="greyDark">
            {instruction}
          </Typography>
        </>
      )}
    </>
  );
};

const Dropzone = ({
  icon,
  title,
  instruction,
  availableFormats,
  disabled,
  multiple,
  onDrop,
  onRemove,
  error,
  setError,
  isLoading,
  isSmall,
  width,
  height,
  previewImage,
  removeDisabled,
}) => {
  const classes = useStyles({ disabled, isSmall, previewImage, width, height });
  const [myFiles, setMyFiles] = useState([]);

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setMyFiles([...myFiles, ...acceptedFiles]);
      onDrop(multiple ? acceptedFiles : acceptedFiles[0]);
    },
    [myFiles]
  );

  const handleRemoveFile = (e, file) => {
    e.preventDefault();
    e.stopPropagation();

    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const handleRemovePreview = () => {
    setMyFiles([]);
    onRemove();
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDropAccepted,
    accept: availableFormats?.join() || null,
    disabled: disabled,
    multiple,
    maxSize: 100000000,
  });

  const IconComponent = icon ? icon : CustomPlusLg;
  return (
    <Box className={classes.root} {...getRootProps()}>
      <input {...getInputProps()} />
      {previewImage ? (
        <DefaultValue
          previewImage={previewImage}
          disabled={disabled}
          removeDisabled={removeDisabled}
          onRemove={handleRemovePreview}
        />
      ) : myFiles.length === 0 ? (
        <EmptyValue
          title={title}
          instruction={instruction}
          IconComponent={IconComponent}
          isSmall={isSmall}
          availableFormats={availableFormats}
          getInputProps={getInputProps}
        />
      ) : (
        myFiles.map((file) => (
          <Box key={file.path} className={classes.boxFileAccepted}>
            {icon && <IconComponent className={classes.icon} />}
            <Box className="boxFileAcceptedContent">
              <Typography variant="bodySB13">{file.name}</Typography>
              <Typography variant="body13" color="greyDark">
                {`${round(file.size / 1000000, 2)}Mo`}
              </Typography>
            </Box>
            <Icon
              IconComponent={Trash}
              color="error"
              hover="error"
              size="20"
              onClick={(e) => handleRemoveFile(e, file)}
            />
          </Box>
        ))
      )}

      {error && (
        <Box className={classes.boxError}>
          <ExclamationCircleFill className={classes.iconError} />
          <Typography align="center" variant="smallBody13" color="error">
            {error}
          </Typography>
        </Box>
      )}
      {isLoading && <Loader className={classes.colorLoader} />}
      {fileRejections.length > 0 &&
        fileRejections.map(({ file, errors }) => (
          <Box className={classes.boxError} key={file.path}>
            <ExclamationCircleFill className={classes.iconError} />
            <Typography
              className={classes.errorsFile}
              variant="smallBody13"
              color="error"
            >
              {file.name}
              {"\n"}
              {errors.map((e, i) => `${i > 0 ? "\n" : ""} ${e.message}`)}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

Dropzone.propTypes = {
  icon: PropTypes.object,
  title: PropTypes.string,
  instruction: PropTypes.string,
  availableFormats: PropTypes.array,
  disabled: PropTypes.bool,
  removeDisabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onDrop: PropTypes.func,
  onRemove: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  isLoading: PropTypes.bool,
  isSmall: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  previewImage: PropTypes.string,
};

Dropzone.defaultProps = {
  disabled: false,
  removeDisabled: false,
  multiple: true,
  onDrop: () => { },
  onRemove: () => { },
  setError: () => { },
  isLoading: false,
  isSmall: false,
};

export default Dropzone;
