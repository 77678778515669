import { currentVisitorSelector, getOwnAccessesSelector } from "app/state/ducks/ressources/selectors";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import LiveContainer from "./Live/LiveContainer";
import QueuesContainer from "./Queues/QueuesContainer";

const GeneralLiveContainer = () => {
  const [live, setLive] = useState(false);
  const currentVisitor = useSelector(currentVisitorSelector)

  if (live || currentVisitor) {
    return <LiveContainer />;
  }
  return <QueuesContainer setLive={setLive} />
};

export default GeneralLiveContainer;