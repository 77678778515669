import { Box, ButtonBase, Divider } from "@material-ui/core";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import ChevronUp from "react-bootstrap-icons/dist/icons/chevron-up";
import ChevronDown from "react-bootstrap-icons/dist/icons/chevron-down";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    button: {
        width: "30px",
        height: "30px",
        borderRadius: "50%",
    },
    title: {
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "20px",
        lineHeight: "130%",
        marginTop: "20px",
    },
    divider: {
        width: "50%",
        marginTop: "20px",
        marginBottom: "20px",
    },
}));
const AccordionOption = ({title, children}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    return (
        <Box>
            <Box className={classes.header}>
                <Typography className={classes.title}>{title}</Typography>
                <ButtonBase className={classes.button} onClick={() => setExpanded(!expanded)}>
                    {expanded ? (<ChevronUp size={20} />) : (<ChevronDown size={20} />)}
                </ButtonBase>
            </Box>
            {expanded && children}
            {/* <Divider className={classes.divider} /> */}
        </Box>
    )
};

export default AccordionOption;