import React, { useReducer, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import isNull from "lodash/isNull";
import isEqual from "lodash/isEqual";

import Box from "@material-ui/core/Box";
import Button from "appComponents/Mui/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CardMedia from "@material-ui/core/CardMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";

import { _t } from "i18n";
import { resourceFetch } from "ressourcesDucks/actions";
import { resourceSelector } from "ressourcesDucks/selectors";
import {
  getKeyword,
  getFetchResultStatusSelector,
} from "applicationDucks/selectors";
import { getUrl } from "utilities/utils/media";
import MediaControlBar from "adminComponents/Animation/MediaControlBar";

import memo, { renderLog } from "core/memo";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  slideBox: {
    // minHeight: "50vh",
    // maxHeight: "50vh",
    minHeight: theme.spacing(10),
    //maxHeight: "calc(100vh - 100px)",
    [theme.breakpoints.up("sm")]: {
      // maxHeight: (props) =>
      //   props.inPipScreenFocus
      //     ? `calc(100vh - ${theme.spacing(6.87)}px - 33px )`
      //     : `calc(100vh - ${theme.spacing(11.87)}px )`,
      maxHeight: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      //100vh - header - bottomTabBar
      maxHeight: `calc(${theme.vh * 100}px - ${theme.spacing(
        7.35
      )}px - ${theme.spacing(7)}px)`,
    },
    //maxHeight: "50vh",
  },
  bottomSection: {
    //minHeight: theme.spacing(4),
  },
  positionIndicator: {},
  downloadButton: {},
  download: {
    cursor: "pointer",
  },
  actionText: {
    color: theme.palette.secondary.contrastText,
  },
  actionBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  indicatorText: {
    fontSize: "1rem",
  },
  positionAction: {
    cursor: "pointer",
    padding: theme.spacing(1),
    fontSize: "2.3rem",
    borderRadius: "50%",
    color: theme.palette.background.secondaryBox,
    backgroundColor: theme.wisemblyColors.lightBlue2,
  },
  media: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  iframe: {
    flex: 1,
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "NEXT":
      return {
        ...state,
        index: state.index < state.length ? state.index + 1 : 1,
      };
    case "PREVIOUS":
      return { ...state, index: state.index > 1 ? state.index - 1 : 1 };
    case "UPDATE":
      return { ...state, index: action.index, length: action.length };
    default:
      return state;
  }
};

//media type: image, document, thirdparty spreadsheet, presentation
//why does past media attributes's live change
//take slide count from data.slides_count and generic data about media in data
//take data about the live status of the media in live
//if display_status is ongoing  you can check in live for more info
// const live = {
//   active: true/false,
//   display_status: ongoing/presented,
//   current_slide: 5
//
// }
// position
//// TODO: implement live later
export const MediaPresentation = ({
  media,
  startIndex,
  endIndex,
  keyword,
  mediaHash,
  fetchMediaStatus,
  resourceFetch,
  disabled,
  layoutMode,
  hasFocus,
  onSetFocus,
  displayControlBar,
  layoutsCount,
}) => {
  // TODO: remove
  renderLog("MediaPresentation");
  const [previous, setPrevious] = useState(media);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isThumbnailView =
    (layoutMode === "pip" || (layoutMode === "split" && layoutsCount > 2)) &&
    !hasFocus;
  const inPipScreenFocus = layoutMode === "pip" && hasFocus;
  const classes = useStyles({ inPipScreenFocus, isThumbnailView });
  const isWisemblyDoc =
    get(media, "provider") === "wisembly" && get(media, "public") === true;

  useEffect(() => {
    if (!isEqual(media, previous) && !hasFocus) {
      //notify present that media changed
      setPrevious(media);
      onSetFocus("media");
    }
  }, [media]);
  useEffect(() => {
    if (isEmpty(media)) {
      resourceFetch("media", {
        slug: ["event", keyword, "medias", mediaHash],
        XHR_ID: `media${mediaHash}`,
      });
    }
  }, [resourceFetch, media, mediaHash, keyword]);

  const [state, dispatch] = useReducer(reducer, {
    index: isNull(startIndex)
      ? get(media, "live.current_slide", 0) + 1
      : startIndex + 1,
    length: isNull(endIndex)
      ? isEqual(media.type || "image")
        ? 1
        : get(media, "data.slides_count", 1)
      : endIndex + 1,
  });

  if (
    disabled &&
    media &&
    has(media, "live.current_slide") &&
    (state.index === undefined || state.index !== media.live.current_slide)
  ) {
    dispatch({ type: "UPDATE", index: media.live.current_slide, length: 1 });
  }

  if (
    (isEqual(fetchMediaStatus, "pending") || isEmpty(fetchMediaStatus)) &&
    isEmpty(media)
  ) {
    return <CircularProgress />;
  }

  if (isEmpty(media)) return <div>{_t("unable to get the media")}</div>;

  const isIframe = media.type === "embed" || media.type === "video";

  let slideUrl = getUrl({
    isMobile,
    media,
    thumbnails: media.thumbnails,
    step: state.index,
    length: state.length,
  });

  const onClick = () => {
    if (has(media, "download_url")) {
      window.open(media.download_url);
    } else {
      console.log("you can not download this media");
    }
  };
  const onNext = () => {
    dispatch({ type: "NEXT" });
  };
  const onPrevious = () => {
    dispatch({ type: "PREVIOUS" });
  };

  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="column"
      justifyContent="center"
      className={clsx("SlidePresentation", classes.root)}
    >
      {isIframe ? (
        <Box display="flex" flexDirection="row" className={classes.iframe}>
          <CardMedia
            component="iframe"
            className={classes.media}
            src={slideUrl}
            alt={media.hash}
            allow="fullscreen"
            allowfullscreen={true}
            frameborder="0"
          />
        </Box>
      ) : (
        <Box display="flex" flex={1} className={classes.slideBox}>
          {displayControlBar && !isThumbnailView && (
            <MediaControlBar
              media={media}
              position={get(media, "live.current_slide", 0)}
              size={get(media, "data.slides_count", 1)}
            />
          )}
          <CardMedia
            component="img"
            image={slideUrl}
            alt={media.hash}
            className={classes.media}
          />
        </Box>
      )}

      {!disabled && !isIframe && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          className={classes.bottomSection}
        >
          {state.length > 1 && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              className={classes.positionIndicator}
            >
              <ArrowBackIcon
                className={classes.positionAction}
                onClick={onPrevious}
              />
              <Box className={classes.indicatorText} mx={2}>{`${
                state.index || 1
              }/${state.length}`}</Box>
              <ArrowForwardIcon
                className={classes.positionAction}
                onClick={onNext}
              />
            </Box>
          )}
          {isWisemblyDoc && (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={onClick}
              startIcon={
                <Box className={clsx(classes.actionText)}>
                  <SaveAltOutlinedIcon />
                </Box>
              }
            >
              {_t("Download document")}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
MediaPresentation.propTypes = {
  media: PropTypes.object,
  mediaHash: PropTypes.string,
  startIndex: PropTypes.number,
  endIndex: PropTypes.number,
  keyword: PropTypes.string,
  fetchMediaStatus: PropTypes.string,
  resourceFetch: PropTypes.func,
  disabled: PropTypes.bool,
  layoutMode: PropTypes.string,
  hasFocus: PropTypes.bool,
  onSetFocus: PropTypes.func,
  displayControlBar: PropTypes.bool,
  layoutsCount: PropTypes.number,
};

MediaPresentation.defaultProps = {
  disabled: false,
  displayControlBar: false,
  onSetFocus: () => {},
};

const mapStateToProps = (state, ownProps) => {
  const media = resourceSelector(state, {
    type: "Media",
    id: ownProps.mediaHash,
  });

  return {
    media: media ? media.ref : null,
    keyword: getKeyword(state),
    fetchMediaStatus: getFetchResultStatusSelector(
      state,
      `media${ownProps.mediaHash}`
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resourceFetch: bindActionCreators(resourceFetch, dispatch),
  };
};

export const MediaViewer = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(memo(MediaPresentation));

export const mediaViewGenerator = ({
  mediaHash,
  startIndex,
  endIndex,
  data,
}) => {
  return {
    type: "slide",
    headerTitle: get(data, "title"),
    children: (
      <MediaViewer
        mediaHash={mediaHash}
        startIndex={startIndex}
        endIndex={endIndex}
      />
    ),
    hash: mediaHash,
  };
};
