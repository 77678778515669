import { Box, Divider, makeStyles, Typography } from "@material-ui/core";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CoverUpload from "./CoverUpload";
import VideoUpload from "./VideoUpload";
import LogoUpload from "./LogoUpload";
import Switch from "../reusables/Switch";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import { _t } from "utilities/i18n";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px 30px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: (props) => (props.isMobile ? "30px" : "20px"),
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
    marginBottom: "50px",
  },
  backgroundsWrapper: {
    display: "flex",
    gap: "16px",
  },
  divider: {
    marginTop: "20px",
    marginBottom: "10px",
  },
  field: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  fieldLabel: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
  },
}));

const Appearance = () => {
  const wiz = useSelector(currentWizSelector);
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const [hideParticipantsCount, setHideParticipantsCount] = useState(wiz.extra_settings.hide_count || false);
  const dispatch = useDispatch();

  const options = {
    extra_settings: {
      ...wiz.extra_settings,
      hide_count: !hideParticipantsCount,
    },
  }

  const handleHideParticipantsCount = () => {
    setHideParticipantsCount(!hideParticipantsCount);
    dispatch(resourceEdit(Object.assign(wiz, options), {
      slug: ["event", wiz.keyword],
      patch: ["extra_settings"],
      callback: (error) => {
        if (error) {
          emitter.emit(EVENT_FLASH_ERROR, _t("An error occurred while updating the resource"))
        }
        emitter.emit(EVENT_FLASH_SUCCESS, _t("Resource updated successfully"))
      }
    }))

  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Appearance</Typography>
      <Box className={classes.backgroundsWrapper}>
        <LogoUpload wiz={wiz} />
        <CoverUpload wiz={wiz} />
        <VideoUpload wiz={wiz} />
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.field}>
        <Typography className={classes.fieldLabel}>{_t("Hide the participants count for participants")}</Typography>
        <Switch onClick={handleHideParticipantsCount} checked={hideParticipantsCount} />
      </Box>
    </Box>
  );
};

export default Appearance;
