import React from "react";
import PropTypes from "prop-types";
import { Box, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomChevronCompactRight from "liveShopping/assets/icons/CustomChevronCompactRight";
import Infos from "./Infos";
import { useDispatch } from "react-redux";
import { setViewedProduct } from "liveShoppingDucks/actions";
import { useSelector } from "react-redux";
import { desktopLayoutSelector } from "liveShoppingDucks/selectors";
import Pulse from "../Pulse";
import ProductCardCTA from "../ProductCardCTA";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "93px",
    background: theme.palette.colors.white,
    borderRadius: "10px",
    border: "1px solid #F0F0F4",
    marginBottom: "10px",
    cursor: (props) => (props.desktopLayout ? "pointer" : "initial"),
    position: "relative",
  },
  img: {
    width: "85px",
    height: "100%",
    backgroundImage: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",

  },
}));

const ShortCard = ({ product, focusedProducts }) => {
  const desktopLayout = useSelector(desktopLayoutSelector);
  const theme = useTheme();
  const classes = useStyles({ backgroundImage: product?.variants[0]?.picture_url || "", desktopLayout });
  const dispatch = useDispatch();

  const handleProduct = () => {
    dispatch(setViewedProduct(product));
  };

  const focusedProduct = focusedProducts.find((element) => element.hash === product.hash);

  return (
    <Box className={classes.card} key={product.id} onClick={handleProduct}>
      {focusedProduct && <Pulse />}
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "95px 2fr 0.7fr",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box className={classes.img} />
        <Infos product={product} />

        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ProductCardCTA product={product} small />
          <CustomChevronCompactRight size={18} color={theme.palette.colors.grey} />
        </Box>
      </Box>
    </Box>
  );
};

ShortCard.propTypes = { desktopLayout: PropTypes.bool };

export default ShortCard;
