import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import emitter from "utilities/emitter";

const useStyles = makeStyles((theme) => ({
  badgeRoot: {
    marginLeft: theme.spacing(2),
    display: (props) => (props.quantity ? "flex" : "none"),
  },
  badge: {
    backgroundColor: (props) => (props.selected ? theme.palette.colors.grey200 : theme.palette.colors.primary),
    color: (props) => (props.selected ? theme.palette.secondary.main : theme.palette.secondary.contrastText),
    width: "16px",
    height: "16px",
    minWidth: "16px",
    borderRadius: "8px",
    fontSize: "11px",
    fontWeight: "bold",
  },
}));
const TabLabel = ({ title, tabSelected, channel }) => {
  const [quantity, setQuantity] = useState(0);
  const classes = useStyles({ quantity });

  useEffect(() => {
    const callback = (value) => {
      setQuantity(value);
    };
    if (channel) {
      emitter.on(channel, callback);
    }
    return () => {
      if (channel) {
        emitter.off(channel, callback);
      }
    };
  }, [channel]);
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box ml={1} className={clsx(classes.tabLabel, tabSelected === 0 ? classes.unselected : null)}>
        {title}
      </Box>
      <Badge
        badgeContent={quantity}
        max={99}
        classes={{
          root: classes.badgeRoot,
          badge: classes.badge,
        }}
      ></Badge>
    </Box>
  );
};

TabLabel.propTypes = {
  title: PropTypes.string,
  tabSelected: PropTypes.number,
  channel: PropTypes.string,
};

export default TabLabel;
