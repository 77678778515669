import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';

import TableRow from '@material-ui/core/TableRow';
import { Text } from "@tremor/react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { _t } from "utilities/i18n";
import { formatPrice } from "app/liveShopping/utils";
import { hexToRGB } from "utilities/utils";
import TableFooter from '@material-ui/core/TableFooter';
import { calculateAverageFromAllItemsData, calculateAverageFromAllItemsDataWithFormat, calculateAverageFromAllItemsDataWithFormatTime } from "../../../utils";
const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: hexToRGB(theme.palette.colors.primary, "1"),
    color: "#fff",
    fontSize: "14px",
    lineHeight: "130%",
    fontWeight: 700,
    position: "sticky",
    top: 0,
    borderRadiusLeft: "10px",
    borderRadiusRight: "10px",
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    width: "100%",
  },
  seeMore: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    borderRadius: "99px",
  },
}));
const VendorsTable = ({ data, project }) => {
  const classes = useStyles();

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead className={classes.head}>
            <TableRow>
              <TableCell style={{ color: "#FFFFFF" }}>
                {_t('Name')}
              </TableCell>
              <TableCell style={{ color: "#FFFFFF" }}>
                {_t('Conversations')}
              </TableCell>
              {project.cart_integration && (
                <>
                  <TableCell style={{ color: "#FFFFFF" }}>
                    {_t('Average cart value')}
                  </TableCell>
                  <TableCell style={{ color: "#FFFFFF" }}>
                    {_t('Total revenue generated')}
                  </TableCell>
                </>
              )}

              <TableCell style={{ color: "#FFFFFF" }}>
                {_t('Average conversation time')}
              </TableCell>
              <TableCell style={{ color: "#FFFFFF" }}>
                {_t('NPS score')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => {

              let conversationTime_minutes = Math.floor(item.average_conversation_time / 60);
              let conversationTime_seconds = Math.floor(item.average_conversation_time % 60);

              return (
                <TableRow key={index}>
                  <TableCell>
                    {item.vendor.firstname + " " + item.vendor.lastname}
                  </TableCell>
                  <TableCell>
                    {item.conversations}
                  </TableCell>
                  {project.cart_integration && (
                    <>
                      <TableCell>
                        {formatPrice(Math.round(item.average_cart_value / 100), "EUR") || 0}
                      </TableCell>
                      <TableCell>
                        {formatPrice(Math.round(item.revenue_generated / 100), "EUR") || 0}
                      </TableCell>
                    </>
                  )
                  }

                  <TableCell>
                    {`${conversationTime_minutes}m ${conversationTime_seconds}s`}
                  </TableCell>
                  <TableCell>
                    {item.nps_score_answers}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>
                {_t("Average")}
              </TableCell>
              <TableCell>
                {calculateAverageFromAllItemsData(data, "conversations")}
              </TableCell>
              {project.cart_integration && (
                <>
                  <TableCell>
                    {calculateAverageFromAllItemsDataWithFormat(data, "average_cart_value", "EUR")}
                  </TableCell>
                  <TableCell>
                    {calculateAverageFromAllItemsDataWithFormat(data, "revenue_generated", "EUR")}
                  </TableCell>
                </>)}

              <TableCell>
                {calculateAverageFromAllItemsDataWithFormatTime(data, "average_conversation_time")}
              </TableCell>
              <TableCell>
                {calculateAverageFromAllItemsData(data, "nps_score_answers")}
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>

      </TableContainer>
      {data?.length === 0 && (
        <Box className={classes.noData}>
          <Text>{_t("There is no data for this period")}</Text>
        </Box>
      )}
    </>
  )
};

export default VendorsTable;
