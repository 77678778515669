import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import toNumber from "lodash/toNumber";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import basil from "core/basil";

const useStyles = makeStyles((theme) => ({
  percentColored: {
    height: (props) => (props.height ? props.height : theme.spacing(1)),
    maxWidth: "100%",
    width: "calc(100% - 53px)",

    backgroundImage: (props) =>
      `linear-gradient(to right,${theme.palette.secondary.main} 0 ${toNumber(
        props.percent
      )}% ,${theme.palette.background.secondaryBox} ${toNumber(props.percent)}% 100%)`,
  },
}));
const PercentIndicator = ({ percent, width, height, customClass, size }) => {
  const layoutMode = basil.get("currentLayoutMode") || "split";
  const classes = useStyles({ percent, width, height, size, layoutMode });
  return (
    <Box
      borderRadius={10}
      className={clsx("PercentIndicator", classes.percentColored, customClass)}
    ></Box>
  );
};
PercentIndicator.propTypes = {
  percent: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  customClass: PropTypes.any,
  size: PropTypes.string,
};
PercentIndicator.defaultProps = {
  percent: 10,
  customClass: "",
  size: "small",
};

export default PercentIndicator;
