import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";
import Button from "appComponents/Mui/ButtonUni";
import React from "react";
import { _t, _n } from "i18n";
import Box from "@material-ui/core/Box";

export const NotifyUpdateRole = ({ count }) => {
  return (
    <DialogContentText>
      {
        /* prettier-ignore */
        _n("Would you like to send an email to notify the participant of his updated role ?", "Would you like to send an email to notify theses participants of his updated role ?", count)
      }
    </DialogContentText>
  );
};

NotifyUpdateRole.propTypes = {
  count: PropTypes.number,
};

NotifyUpdateRole.defaultProps = {
  count: 0,
};

export const NotifyInvitation = ({ count }) => {
  return (
    <DialogContentText>
      {
        /* prettier-ignore */
        _n("Would you like to send an invitation email to the participant ?", "Would you like to send an invitation email to the participants ?", count)
      }
    </DialogContentText>
  );
};

NotifyInvitation.propTypes = {
  count: PropTypes.number,
};

NotifyInvitation.defaultProps = {
  count: 0,
};

export const DialogErrorNotify = ({ open, setOpen, errors }) => {
  console.log(JSON.stringify(errors));
  return (
    <Dialog
      open={open}
    >
      <DialogTitle>
        {_t("List of errors")}
      </DialogTitle>
      <DialogContent color="error">
        {errors ? errors.map((data) => {
          return (<Box>{_t("Line {line} Column {column} - the value is incorrect", {
            line: data.line,
            column: data != null ? data.column : "",
                  })}</Box>);
                }) : ""}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>
          {_t("Ok")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogErrorNotify.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

DialogErrorNotify.defaultProps = {
  open: false,
  setOpen: () => { },
};

const DialogSendNotify = ({ open, roleUpdateAction, notify }) => {
  return (
    <Dialog
      open={open}
      onClose={() => roleUpdateAction(false)}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
        {_t("Send a notification")}
      </DialogTitle>
      <DialogContent>{notify}</DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => roleUpdateAction(false)}
          color="primary"
        >
          {_t("No")}
        </Button>
        <Button onClick={() => roleUpdateAction(true)} color="primary">
          {_t("Yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogSendNotify.propTypes = {
  open: PropTypes.bool,
  roleUpdateAction: PropTypes.func,
};

DialogSendNotify.defaultProps = {
  open: false,
  roleUpdateAction: () => {},
};

export default DialogSendNotify;