import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

import Messages from "./Messages";
import Footer from "./Footer";
import FilterRemove from "./FilterRemove";
import ChatFilter from "../containers/ChatFilter";
import ErrorBoundary from "../ErrorBoundary";
import ChatList from "./ChatList";
import PinnedQuotes from "../containers/PinnedQuotes";
import ChatBubbleLight from "./ChatBubbleLight";
import DisplayImage from "./DisplayImage";
import ChatBubbleLightContainer from "../containers/ChatBubbleLight";
import isNumber from "lodash/isNumber";
import { EVENT_RESET_CHAT_HEIGHT, CHAT_DISPLAY_IMAGE } from "utilities/emitter/events";
import emitter from "utilities/emitter";
import { useIsMobileLandscape, useResizeObserver } from "../layoutHooks";

import { _t } from "i18n";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",

      backgroundColor: "#ffffff",
      position: "relative",
      overflow: "hidden",

      [theme.breakpoints.up("sm")]: {
        width: "345px",
        minWidth: "345px",
        height: "100%",
        borderRadius: (props) => (props.moderationModeColumn ? "0px 10px 10px 0px " : "12px"),
      },
      [theme.breakpoints.down("xs")]: {
        height: "100%", //new
        width: "100%",
        flexGrow: 1,
      },
      "@media (max-width:900px) and (orientation: landscape) and (max-height: 600px)": {
        height: "100%", //new
        width: "100%",
        flexGrow: 1,
      },
    },
    divider: {
      width: "100%",
      border: "border: 1px solid #F0F0F4",
    },
  }),
  {
    name: "OneChat",
  }
);

const Chat = ({ isAdmin, messages, Element, openFilter, closeFilter, onFilterChange, filter, total, fetchData, postMessage, moderationModeColumn, disabled, disabledPicture }) => {
  const classes = useStyles({ moderationModeColumn });
  const footerRef = useRef(null);
  const rootRef = useRef(null);
  const filterRef = useRef(null);
  const rootDim = useResizeObserver(rootRef);
  const footerDim = useResizeObserver(footerRef);
  const filterDim = useResizeObserver(filterRef);
  const isMobileLandscape = useIsMobileLandscape();
  const [picture, setPicture] = useState(null);

  useEffect(() => {
    if (isNumber(rootDim.height) && isNumber(footerDim.height)) {
      let newMessageHeight = rootDim.height - footerDim.height;
      if (isNumber(filterDim.height) && filterDim.height > 0) {
        //i added -10 because there is a marginBottom in filterRemove component
        newMessageHeight = rootDim.height - footerDim.height - filterDim.height - 10;
      }
      if (!isMobileLandscape) {
        emitter.emit(EVENT_RESET_CHAT_HEIGHT, newMessageHeight);
      }
    }
  }, [JSON.stringify({ footerDim, rootDim, filterDim })]);

  useEffect(() => {
    const callback = (path) => {
      //setEditing(true);
      setPicture(path);
    };
    emitter.on(CHAT_DISPLAY_IMAGE, callback);
    return () => {
      emitter.off(CHAT_DISPLAY_IMAGE, callback);
    };
  }, [picture]);

  return (
    <Box ref={rootRef} className={clsx("Chat", classes.root)}>
      {picture && <DisplayImage picture={picture} onClose={() => setPicture(null)} />}
      <PinnedQuotes />
      <Messages count={messages.length}>
        <ChatList Element={Element} elements={messages} total={total} fetchData={fetchData} />
      </Messages>
      <FilterRemove ref={filterRef} filter={filter} />
      <Divider classes={{ root: classes.divider }} />
      <Footer ref={footerRef} postMessage={postMessage} disabled={disabled} disabledPicture={disabledPicture} />
      <ErrorBoundary hidden={false} customText={_t("oops could not load filter")}>
        <ChatFilter admin={isAdmin} open={openFilter} onClose={closeFilter} onFilterChange={onFilterChange} />
      </ErrorBoundary>
    </Box>
  );
};

Chat.propTypes = {
  messages: PropTypes.array,
  inputValue: PropTypes.string,
  inputPlaceHolder: PropTypes.string,
  adminChat: PropTypes.bool,
  openFilter: PropTypes.bool,
  closeFilter: PropTypes.func,
  onFilterChange: PropTypes.func,
  filter: PropTypes.object,
  total: PropTypes.number,
  fetchData: PropTypes.func,
  postMessage: PropTypes.func,
  Element: PropTypes.oneOf([ChatBubbleLight, ChatBubbleLightContainer]),
  moderationModeColumn: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledPicture: PropTypes.bool,
};

Chat.defaultProps = {
  messages: [],
  inputValue: "",
  adminChat: false,
  openFilter: false,
  closeFilter: () => {},
  onFilterChange: () => {},
  postMessage: () => {},
  filter: {},
  total: 0,
  fetchData: () => {},
  moderationModeColumn: false,
  disabled: false,
  disabledPicture: false,
};

export default Chat;
