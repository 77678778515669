import React from "react";
import { makeStyles } from "@material-ui/core";
import CustomChevronDown from "liveShopping/assets/icons/CustomChevronDown";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { _t } from "utilities/i18n";
import { productSelector } from "app/state/ducks/ressources/selectors";
const useStyles = makeStyles((theme) => ({
  select: {
    background: "#FFFFFF",
    border: "1px solid #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "8px",
    height: props => props.isMobile ? "56px" : "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    width: "100%",
    WebkitAppearance: "none",
    appearance: "none",
    "&:-ms-expand": {
      display: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:selection": {
      backgroundColor: "#000",
    },
  },
  chevronDown: {
    float: "right",
    marginTop: props => props.isMobile ? "-35px" : "-28px",
    marginRight: "10px",
    pointerEvents: "none",
    backgroundColor: "transparent",
    color: "black",
    paddingLeft: "8px",
  },
}));

const Select = ({name, value, placeholder, options, onChange, defaultValue, defaultChoice, ref }) => {
  const isMobile = useSelector(mobileLayoutSelector)
  const classes = useStyles({isMobile});

  return (
    <div>
      <select className={classes.select} name={name} onChange={onChange} value={value} placeholder={placeholder} defaultValue={defaultValue}>
          <option value={"default"} disabled>
            {defaultChoice || _t("Choose a duration")}
          </option>
        {options?.map((item) => (
          <option key={item.hash} value={item.hash}>
            {item.name}
          </option>
        ))}
      </select>
      <CustomChevronDown size={18} className={classes.chevronDown} />
    </div>
  );
};

Select.propTypes = {};

export default Select;
