import { Box, makeStyles } from "@material-ui/core";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { genericResourceSelector } from "app/state/ducks/ressources/selectors";
import { getUrl } from "app/views/containers/Application/Routes";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AppointmentMenu from "./AppointmentMenu/AppointmentMenu";
import Availability from "./Availability";
import Communication from "./Communication";
import CreateAgenda from "./CreateAgenda";
import Limits from "./Limits";
import Setup from "./Setup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
  },
}));

const Appointment = () => {
  const classes = useStyles();
  const { group, tab } = useParams();
  const project = useSelector(currentProjectSelector);
  const currentGroup = project.groups.toRefArray().find((g) => g.hash === group);
  const calendar = useSelector(state => genericResourceSelector(state, {
    type: "Calendar",
    filter: (cal) => {
      return cal.group?.hash === currentGroup?.hash;
    },
  }));

  useEffect(() => {
    window.history.replaceState(null, null, getUrl("oto_appointment", { hash: project.hash, group, tab }));
  }, [tab]);

  if (!calendar) {
    return <CreateAgenda hash={group} />
  }

  return (
    <Box className={classes.root}>
      <AppointmentMenu />
      {tab === "availability" && <Availability />}
      {tab === "setup" && <Setup />}
      {tab === "limits" && <Limits />}
      {tab === "communication" && <Communication />}
    </Box>
  )

};

export default Appointment;
