import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Exclamation from "react-bootstrap-icons/dist/icons/exclamation-circle-fill";

const useStyles = makeStyles(
  (theme) => ({
    input: {
      borderWidth: 1,
      borderColor: theme.palette.colors.greyMedium + "! important",
      padding: "10px 12px",
      cursor: (props) => (props.readOnly ? "default" : "auto"),
      "&.MuiInputBase-input": {
        "&::placeholder": {
          color: (props) =>
            props.darkMode
              ? theme.palette.colors.white50
              : theme.palette.colors.greyDark,
        },
      },
    },
    forceWidth: {
      width: (props) => (props.width ? props.width : "auto"),
    },
    textFieldRoot: {
      borderRadius: "8px",
      borderWidth: 1,
      borderColor: theme.palette.colors.greyMedium + "! important",
      fontWeight: 400,
      color: (props) =>
        props.darkMode
          ? theme.palette.colors.white
          : theme.palette.colors.blackMedium,
      fontSize: (props) => (props.size === "medium" ? "14px" : "12px"),
      lineHeight: (props) => (props.size === "medium" ? "130%" : "100%"),
      backgroundColor: (props) =>
        props.darkMode
          ? theme.palette.colors.white10
          : theme.palette.colors.white,

      "&.MuiOutlinedInput-root": {
        borderWidth: 1,
        borderColor: theme.palette.colors.greyMedium + "! important",
      },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.colors.greyMedium + "! important",
      },
      "&:hover": {
        borderColor: theme.palette.colors.greyMedium + "! important",
      },
      "&.MuiInputBase-input": {
        padding: "10px 12px",
        fontWeight: 400,
        fontSize: (props) => (props.size === "medium" ? "14px" : "12px"),
        lineHeight: (props) => (props.size === "medium" ? "130%" : "100%"),
        color: (props) =>
          props.darkMode
            ? theme.palette.colors.white
            : theme.palette.colors.blackMedium,
        "&::placeholder": {
          color: (props) =>
            props.darkMode
              ? theme.palette.colors.white10
              : theme.palette.colors.greyDark,
        },
        "&.Mui-error": {
          color: theme.palette.colors.error,
        },
      },
    },

    inputFocused: {
      color: (props) =>
        props.darkMode
          ? theme.palette.colors.white
          : theme.palette.colors.blackMedium,
      backgroundColor: (props) =>
        props.darkMode
          ? theme.palette.colors.white15
          : theme.palette.colors.white,
      // borderWidth: "1px",
      "&:hover": {
        backgroundColor: (props) =>
          props.darkMode
            ? theme.palette.colors.white15
            : theme.palette.colors.white,
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: (props) =>
          props.darkMode ? "none" : `1px solid ${theme.palette.colors.greyMedium}`,
        boxShadow: "none",
      },
      "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.colors.error,
        borderWidth: 1,
      },
      "&.MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline":
      {
        borderColor: theme.palette.colors.error,
        borderWidth: 1,
        boxShadow: `0px 0px 0px 3px ${theme.palette.colors.error10}`,
      },
    },
    errorLabel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      marginTop: "8px",
    },
    errorMessage: {
      color: theme.palette.colors.alert,
      fontSize: "12px",
      fontWeight: "normal",
      marginHorizontal: "5px",
    },
    errorIcon: {
      color: theme.palette.colors.alert,
      marginRight: "5px",
    },
    multiline: {
      padding: "0",
    },
  }),
  {
    name: "OneOutlinedInput",
  }
);

export const CustomTextField = ({
  errorMessage,
  readOnly,
  error,
  mode,
  width,
  size,
  ...rest
}) => {
  const classes = useStyles({
    readOnly,
    darkMode: mode === "dark",
    error,
    width,
    size,
  });

  return (
    <Box style={{ width: "auto" }}>
      <OutlinedInput
        classes={{
          root: clsx({
            [classes.textFieldRoot]: true,
            [classes.forceWidth]: width,
          }),

          focused: classes.inputFocused,
          input: classes.input,
          multiline: classes.multiline,
        }}
        readOnly={readOnly}
        error={error}
        {...rest}
      />
      {errorMessage && (
        <Box className={classes.errorLabel}>
          <Box className={classes.errorIcon}>
            <Exclamation width={12} height={12} />
          </Box>
          <Box className={classes.errorMessage}>{errorMessage}</Box>
        </Box>
      )}
    </Box>
  );
};

CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  readOnly: PropTypes.string,
  mode: PropTypes.string,
  width: PropTypes.string,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
};
CustomTextField.defaultProps = {
  name: "default",
  id: "default",
  type: "text",
  placeholder: "",
  fullWidth: false,
  required: true,
  onChange: () => { },
  error: false,
  disabled: false,
  value: "",
  multiline: false,
  mode: "light",
};

export default CustomTextField;
