import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class Item extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Item;
  }

  setSurvey(Survey) {
    this.survey = Survey.id;
  }
}

Item.modelName = "Item";
Item.backendName = "item";

Item.fields = {
  id: attr(),
  type: attr(),
  label: attr(),
  required: attr(),
  help: attr(),
  options: attr(),
  position: attr(),
  display: attr(),
  answers_count: attr(),
  display_status: attr(),
  summary: attr(),
  help_image: attr(),
  survey: fk("Survey", "items"),
};

export default Item;
