import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { _t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { liveShoppingBootstrapSelector, projectsSelector } from "app/state/ducks/liveShopping/selectors";
import { Box, CircularProgress } from "@material-ui/core";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { getUrl } from "app/views/containers/Application/Routes";

const Home = () => {
  const { license, groups } = useSelector(liveShoppingBootstrapSelector);
  const [loading, setLoading] = useState(true);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const projects = useSelector(projectsSelector);
  const dispatch = useDispatch();

  const solution = localStorage.getItem("solution");
  const hash = localStorage.getItem("projectId");

  useEffect(() => {
    if (!license) return;
    setLoading(false);
  }, [license]);

  useEffect(() => {
    // if no license and no groups, we don't need to fetch projects
    if (!license?.can_use_oto && !groups) return;

    dispatch(
      resourceFetch("project", {
        slug: ["projects"],
        once: true,
        callback: (err, response) => {
          if (response) {
            setProjectsLoading(false);
          } else {
            setProjectsLoading(true);
          }
        }
      })
    );
  }, [license, groups]);


  if (solution === "otm" && !hash)
    return <Redirect to={getUrl("otm_lives")} />

  if (hash && solution)
    return <Redirect to={solution === "oto" ? getUrl("oto_dashboard", { hash }) : getUrl("otm_lives")} />

  if (!solution) return <Box style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{_t("It appears that your account does not have yet credentials for Liveboutik back-office. Please get in touch with your administrator.")}</Box>
  if (loading || projectsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (!projectsLoading)
    return <Redirect to={projects.length > 0 ? getUrl("oto_dashboard", { hash: projects[0].id }) : getUrl("otm_lives")} />

  return <Box>{_t("You don't have any solution yet.")}</Box>
};

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
