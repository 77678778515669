import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import { NavLink } from "react-router-dom";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Counter from "../reusables/Counter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
    height: "40px",
  },
  activeBox: {
    width: "4px",
    height: "24px",
    background: (props) => (props.active ? theme.palette.colors.primary : "transparent"),
    borderRadius: "99px",
  },
  typo: {
    color: (props) => (props.active ? theme.palette.colors.primary : "#1E2023"),
    marginRight: "8px",
  },
  icon: {
    fontSize: "16px",
    marginLeft: "24px",
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: (props) => (props.active ? theme.palette.colors.primary : "#808284"),
  },
}));

const Tab = ({ url, title, icon, count, isRetracted }) => {
  const [active, setActive] = useState(false);
  const classes = useStyles({ active });

  const handleActive = (match) => (match ? setActive(true) : setActive(false));

  return (
    <NavLink className={classes.root} to={url} isActive={(match) => handleActive(match)}>
      <Box className={classes.activeBox} />
      <Box className={clsx(classes.icon)}>{icon}</Box>
      {!isRetracted && <Typography variant={"bodySB13"} className={classes.typo}>
        {title}
      </Typography>}
      {count && <Counter count={count} />}
    </NavLink>
  );
};

Tab.propTypes = {};

Tab.defaultProps = {};

export default Tab;
