import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import EyeSlash from "react-bootstrap-icons/dist/icons/eye-slash";
import Eye from "react-bootstrap-icons/dist/icons/eye";
import { useDispatch, useSelector } from "react-redux";
import { currentCartSelector, currentWizSelector } from "app/state/ducks/ressources/selectors";
import { isUser } from "utilities/access";
import clsx from "clsx";
import BagCheck from "react-bootstrap-icons/dist/icons/bag-check";
import { ButtonBase } from "@material-ui/core";
import Bag from "react-bootstrap-icons/dist/icons/bag";
import { resourceEdit } from "app/state/ducks/ressources/actions";

const useStyles = makeStyles((theme) => ({
  buttonBuy: {
    height: (props) => (props.small ? "44px" : "32px"),
    width: (props) => (props.small && props.cart_integration ? "44px" : props.large ? "149px" : props.small && !props.cart_integration ? "44px" : "100%"),
    borderRadius: (props) => (props.small ? "999px" : "99px"),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: (props) => (props.bought ? theme.palette.colors.success : theme.palette.colors.primary),
    margin: (props) => props.small && " 0 12px 0 0",
    padding: (props) => (!props.small ? "10px 16px" : !props.cart_integration ? "10px 16px" : "0px"),
  },
  buttonPush: {
    background: theme.palette.colors.success + "!important",
  },
  buttonUnpush: {
    background: theme.palette.colors.error + "!important",
  },
  typoButtonBuy: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "12px",
    letterSpacing: "0em",
    textAlign: "center",
    color: theme.palette.colors.white,
    marginLeft: "6px",
  },
  iconButtonBuy: {
    color: theme.palette.colors.white,
  },
}));

const ProductCardCTA = ({ product, small, large }) => {
  const wiz = useSelector(currentWizSelector);
  const cart = useSelector(currentCartSelector);
  const dispatch = useDispatch();
  const bought = cart.products.find(({ product_id }) => product_id === product.id);
  const classes = useStyles({ small, bought, cart_integration: wiz?.cart_integration });

  const handlePush = (e) => {
    e.stopPropagation();
    wiz.live.popup = { hash: product.id, class_name: "Product" };

    dispatch(
      resourceEdit(wiz, {
        slug: ["event", wiz.keyword],
        patch: ["live"],
        headers: {
          "Wisembly-App-Id": null, //prevent push event from being ignored
        },
      })
    );
  };

  const handleUnpush = (e) => {
    e.stopPropagation();
    wiz.live.popup.class_name = null;
    wiz.live.popup.hash = null;

    dispatch(
      resourceEdit(wiz, {
        slug: ["event", wiz.keyword],
        patch: ["live"],
        headers: {
          "Wisembly-App-Id": null, //prevent push event from being ignored
        },
      })
    );
  };

  if (wiz.status === "pre_live") return null;
  return (
    <>
      {isUser("user+") && !wiz.isReplay() ? (
        <>
          {product.started_at && !product.stopped_at ? (
            <ButtonBase className={clsx({ [classes.buttonBuy]: true, [classes.buttonUnpush]: true })} onClick={(e) => handleUnpush(e)}>
              {large && <Typography className={classes.typoButtonBuy}>{_t("Unpush")}</Typography>}

              {small && <EyeSlash className={classes.iconButtonBuy} size={18} />}
            </ButtonBase>
          ) : (
            <ButtonBase className={clsx({ [classes.buttonBuy]: true, [classes.buttonPush]: true })} onClick={(e) => handlePush(e)}>
              {large && <Typography className={classes.typoButtonBuy}>{_t("Push")}</Typography>}
              {small && <Eye className={classes.iconButtonBuy} size={18} />}
            </ButtonBase>
          )}
        </>
      ) : (
        <>
          <ButtonBase
            className={clsx({
              [classes.buttonBuy]: true,
              [classes.buttonBought]: bought,
            })}
          >
            {!wiz.cart_integration && !small && <Typography className={classes.typoButtonBuy}>{_t("View product")}</Typography>}
            {!wiz.cart_integration && small && <Eye className={classes.iconButtonBuy} size={20} />}
            {wiz.cart_integration && (
              <>
                {large && !bought && (
                  <>
                    <Bag color={"#FFF"} size={15} />
                    <Typography className={classes.typoButtonBuy}>{_t("Add to cart")}</Typography>
                  </>
                )}
                {large && bought && (
                  <>
                    <BagCheck size={15} className={classes.iconButtonBuy} />
                    <Typography className={classes.typoButtonBuy}>{_t("Added to cart !")}</Typography>
                  </>
                )}

                {small && !bought && <Bag size={20} className={classes.iconButtonBuy} />}
                {small && bought && <BagCheck size={20} className={classes.iconButtonBuy} />}
              </>
            )}

          </ButtonBase>
        </>
      )}
    </>
  );
};

ProductCardCTA.propTypes = {};

ProductCardCTA.defaultProps = {};

export default ProductCardCTA;
