import BaseModel from "./base";
import { attr, fk } from "redux-orm";
import PropTypes from "prop-types";

class Survey extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Survey;
  }

  setEvent(Event) {
    this.event = Event.id;
  }
}

Survey.modelName = "Survey";
Survey.backendName = "survey";

Survey.fields = {
  id: attr(),
  type: attr(),
  name: attr(),
  description: attr(),
  is_active: attr(),
  is_accessible: attr(),
  multiple_answers: attr(),
  live_results: attr(),
  position: attr(),
  answers_count: attr(),
  created_at: attr(),
  started_at: attr(),
  ended_at: attr(),
  processed: attr(),
  display_status: attr(),
  wiz_rank: attr(),
  event: fk("Event", "surveys"),
};

export default Survey;
export const SurveyProptypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  is_active: PropTypes.bool,
  is_accessible: PropTypes.bool,
  multiple_answers: PropTypes.bool,
  live_results: PropTypes.bool,
  position: PropTypes.number,
  answers_count: PropTypes.number,
  created_at: PropTypes.string,
  started_at: PropTypes.string,
  ended_at: PropTypes.string,
  processed: PropTypes.bool,
  display_status: PropTypes.string,
  wiz_rank: PropTypes.number,
};
