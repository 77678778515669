/* eslint-disable mui-unused-classes/unused-classes */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { generateDate, generateWeekDays, generateMonth, getDaysEmpty, getDaysEmptyOverride, isDatePassed, isDayEmpty, generateOverride, getEndDate } from "./util";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { _t } from "utilities/i18n";
import "dayjs/locale/fr";
import "dayjs/locale/en";
import { AGENDA_CONFIGURATION } from "../data";
import { useSelector } from "react-redux";
import { currentCalendarSelector } from "app/state/ducks/liveShopping/selectors";
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  calendar: {
    width: "300px",
    height: "300px",
    marginBottom: "100px",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  weekDays: {
    width: "100%",
    height: "50px",
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
  },
  numbers: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
  },
  date: {
    borderTop: `1px solid ${theme.palette.colors.grey300}`,
    display: "grid",
    placeContent: "center",
  },
  week: {
    display: "grid",
    placeContent: "center",
    color: theme.palette.colors.grey500,
    fontSize: "12px",
    textTransform: "uppercase",
  },
  pastOrFutureDays: {
    color: theme.palette.colors.grey400,
    fontSize: "12px",
  },
  selectedDate: {
    backgroundColor: theme.palette.colors.primary,
    color: theme.palette.colors.white + "!important",
    borderRadius: "50%",
  },
  emptyDay: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.grey400,
    borderRadius: "50%",
    pointerEvents: "none",
  },
  endDate: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.error,
    borderRadius: "50%",
    pointerEvents: "none",
  },
  datePassed: {
    color: theme.palette.colors.white,
    backgroundColor: theme.palette.colors.grey400,
    borderRadius: "50%",
    pointerEvents: "none",
  },
  today: {
    border: `1px solid ${theme.palette.colors.primary}`,
    color: theme.palette.colors.primary,
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    display: "grid",
    placeContent: "center",
  },
  daysOfMonth: {
    width: "30px",
    height: "30px",
    display: "grid",
    placeContent: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.colors.primary,
      color: theme.palette.colors.white,
      borderRadius: "50%",
      transition: "all 0.3s ease-in-out",
    },
  },
  switch: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
  },
  monthTypo: {
    fontWeight: "bold",
  },
}));

const Calendar = ({ today, setToday, selectedDate, setSelectedDate, locale = "en" }) => {
  dayjs.locale(locale);
  const currentDate = dayjs();
  const currentCalendar = useSelector(currentCalendarSelector);
  // selectedDate is in the next month, set today to the next month
  useEffect(() => {
    if (dayjs(selectedDate).month() !== today.month()) {
      setToday(dayjs(selectedDate));
      return;
    }
  }, [selectedDate, setToday]);

  const classes = useStyles();
  const weekDays = generateWeekDays();
  const emptyDays = getDaysEmpty({ agenda_configuration: currentCalendar.configuration, month: today.month(), year: today.year(), overrides: currentCalendar.overrides });
  const endDate = getEndDate(currentCalendar.end_at_absolute, currentCalendar.end_at_relative, dayjs());
  console.log("endDate", dayjs(endDate).month(), dayjs().month());
  return (
    <Box className={classes.calendar}>
      <Box className={classes.top}>
        <Typography className={classes.monthTypo}>{generateMonth({ monthNumber: today.month() })}, {today.year()}</Typography>
        <Box className={classes.switch}>
          <ButtonBase disabled={dayjs().month() === today.month()} onClick={() => setToday(today.month(today.month() - 1))}>
            <ArrowBackIos size={14} />
          </ButtonBase>
          <Typography style={{ cursor: "pointer" }} onClick={() => setToday(currentDate)}>{_t("Now")}</Typography>
          <ButtonBase disabled={today.month() >= dayjs(endDate).month()} onClick={() => setToday(today.month(today.month() + 1))}>
            <ArrowForwardIos size={14} />
          </ButtonBase>
        </Box>
      </Box>
      <Box className={classes.weekDays}>
        {weekDays.map((day, index) => {
          return <Typography key={index} className={classes.week}>{day}</Typography>;
        })}
      </Box>
      <Box className={classes.numbers}>
        {generateDate({ month: today.month(), year: today.year(), locale: locale }).map(({ date, currentMonth, today }, index) => {

          return (
            <Box key={index} className={classes.date}>
              <Typography className={clsx(
                {
                  [classes.daysOfMonth]: true,
                  [classes.pastOrFutureDays]: !currentMonth,
                  [classes.today]: today,
                  [classes.selectedDate]: dayjs(selectedDate).toDate().toDateString() === date.toDate().toDateString(),
                  [classes.emptyDay]: emptyDays.includes(date.toDate().toDateString()) || isDayEmpty(date.toDate().toDateString(), currentCalendar.configuration),
                  [classes.datePassed]: isDatePassed(date.toDate().toDateString()),
                  [classes.endDate]: dayjs(date).isAfter(endDate)
                }
              )}
                onClick={() => emptyDays.includes(date.toDate().toDateString()) ? null : setSelectedDate(date)}
              >{date.date()}</Typography>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
};

export default Calendar;
