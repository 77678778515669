import { _n, _t } from "utilities/i18n";

export const optionsDuration = [
  {hash: "30", name: _n("{count} minute", "{count} minutes", 30, { count: 30 })},
  {hash: "45", name: _n("{count} minute", "{count} minutes", 45, { count: 45 })},
  {hash: "60", name: _n("{count} hour", "{count} hours", 1, { count: 1 })},
  {hash: "90", name:  _n("{count} hour", "{count} hours", 1.5, { count: 1.5 })},
  {hash: "120", name:  _n("{count} hour", "{count} hours", 2, { count: 2 })},
];

export const validate = (values) => {
  let errors = {};

  if (!values.title) {
    errors.title = _t("Title is required");
  }
  if (!values.live_start) {
    errors.live_start = _t("The date must be filled in");
  }
  return errors;
};

export const keywordValidation = (values) => {
  let errors = {};

  if (!values.keyword) {
    errors.keyword = _t("The keyword must be filled in");
  } else {
    if (values.keyword.length < 3 || values.keyword.length > 20) {
      /* prettier-ignore */
      errors.keyword = _t("This keyword must contain between 3 and 20 characters.");
    }

    const format = /[^a-zA-Z0-9]+/;
    if (format.test(values.keyword)) {
      /* prettier-ignore */
      errors.keyword = _t("This keyword is invalid, it cannot contain accents, spaces or special characters.");
    }
  }

  return errors;
};


export const errorHandling = (status) => {
  switch(status) {
    case 409:
      return _t("This keyword is already taken");
    default:
      return _t("An error has been detected");
  }
}