import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Status from "./Status";
import { useHistory } from "react-router-dom";
import { getUrl } from "app/views/containers/Application/Routes";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 10,
    width: "112px",
    cursor: "pointer",
    position: "relative",
  },
  card: {
    width: "112px",
    height: "187px",
    borderRadius: "12px",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: "4px 0px",
    background: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
}));

const Card = ({ background, title, keyword, isPublic, liveStart, liveStop }) => {
  const classes = useStyles({ backgroundImage: background });
  const history = useHistory();

  const handleDetail = () => history.push(getUrl("otm_live", { keyword }));

  return (
    <Box className={classes.root} onClick={handleDetail}>
      <Box className={classes.card}>
        <Status isPublic={isPublic} liveStart={liveStart} liveStop={liveStop} />
      </Box>
      <Typography variant={"bodySB13"} className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

Card.propTypes = {};

Card.defaultProps = {};

export default Card;
