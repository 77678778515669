import BaseModel from "./base";
import { attr, fk } from "redux-orm";
import isEmpty from "lodash/isEmpty";

class Access extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Access;
  }

  isEvent(event) {
    if (isEmpty(event) || isEmpty(this.event)) return false;

    return event.id === this.event.id;
  }

  isUser(user) {
    return user?.id === this.user?.hash;
  }

  // setUser(User) {
  //   this.user = User;
  // }

  setGroup(Group) {
    this.group = Group.id;
  }

  setProject(Project) {
    this.project = Project.id;
  }

  setEvent(Event) {
    this.event = Event.id;
  }
}

Access.modelName = "Access";
Access.backendName = "access";

// self::SCOPE_ADMIN,
// self::SCOPE_ANIM,
// self::SCOPE_MODO,
// self::SCOPE_SPEAKER,
// self::SCOPE_USER
Access.fields = {
  id: attr(),
  keyword: attr(),
  title: attr(),
  user: attr(),
  group: fk("Group", "accesses"),
  project: fk("Project", "accesses"),
  scope: attr(),
  email: attr(),
  origin: attr(),
  present: attr(),
  hash: attr(),
  available: attr(),
  event: fk("Event", "accesses"),
};

export default Access;
