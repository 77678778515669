import { Box } from '@material-ui/core';
import { liveShoppingBootstrapSelector } from 'app/state/ducks/liveShopping/selectors';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { isUser } from 'utilities/access';
import { _t } from 'utilities/i18n';

const SecuredRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const oto = location.pathname.toString().includes('oto');
  const otm = location.pathname.toString().includes('otm');
  const { license } = useSelector(liveShoppingBootstrapSelector);

  useEffect(() => {
    if (!license) return;

    if (!license?.can_use_oto && oto || !license?.can_use_otm && otm) {
      return <Box>{_t("You don't have access to this page")}</Box>
    }
  }, [license]);

  return (
    <Route
      {...rest}
      render={(props) => { return isUser('user+') ? <Component {...props} /> : <Redirect to="/connect" /> }}
    />
  );
}

export default React.memo(SecuredRoute);
