import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import map from "lodash/map";
import { useSelector, useDispatch } from "react-redux";
import { resourcesFetch } from "ressourcesDucks/actions";
import { templatesSelector } from "ressourcesDucks/selectors";
import { getKeyword } from "applicationDucks/selectors";
import { makeStyles } from "@material-ui/core/styles";
import Header from "app/liveShopping/backoffice/otm/components/Mails/Header";
import TemplateHandler from "app/liveShopping/backoffice/otm/components/Mails/TemplateHandler";
import { TEMPLATE_KEYS } from "app/liveShopping/backoffice/otm/components/Mails/utils";
import MailEditor from "./MailEditor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: "#FFF",
    flexDirection: "column",
    justifyContent: "flex-start",
    position: "relative",

    alignItems: "center",
    paddingRight: "20px",
    paddingLeft: "20px",
    overflow: "auto",
    //overflowX: "auto",
  },
  content: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  editModal: {
    display: "flex",
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.palette.background.default,
    width: "100%",
    height: "90%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    overscrollBehavior: "contain",
  },
}));
//delet doublon templates
const filterTemplates = (templates) => {
  let results = [];
  TEMPLATE_KEYS.forEach((key) => {
    let tmpltKeys = filter(templates, (tmp) => {
      const isCustomPrototype =
        tmp.template_key === "custom_email" && tmp.is_prototype === true;
      return tmp.template_key === key && !isCustomPrototype;
    });
    if (!isEmpty(tmpltKeys)) {
      if (tmpltKeys.length > 1) {
        const last = filter(tmpltKeys, (x) => !x.is_prototype);
        results.push(...last);
      } else {
        results.push(tmpltKeys[0]);
      }
    }
  });
  return results;
};
const Mails = () => {
  const classes = useStyles();
  const keyword = useSelector(getKeyword);
  const templates = useSelector(templatesSelector);
  const listRef = useRef(null);
  const [newEvent, setNewEvent] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState({});
  const defaultTemplate = templates.find(
    (template) =>
      template.template_key === "custom_email" && template.is_prototype === true
  );

  //filter templates and remove prototype templates that already exist
  const dispatch = useDispatch();
  //fetch templates
  const loadData = useCallback(() => {
    const query = [];
    dispatch(
      resourcesFetch("template", {
        slug: ["event", keyword, "mailer-templates"],
        query,
        XHR_ID: `event_templates_${keyword}`,
        silent: true,
        callback: (error, response) => {
          if (error) {
            return;
          }
        },
      })
    );
  }, [keyword]);

  const editTemplateLive = (template) => {
    setShowEditModal(true);
    setTemplateToEdit(template);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  const mapResult = useMemo(() => {
    const filteredTmp = filterTemplates(templates);
    let finalList = [];
    //let addStart = false;

    //add start divider
    //if(fil)
    filteredTmp.forEach((item) => {
      finalList.push(item);
    });

    const results = map(finalList, (tmp) => {
      return (
        <TemplateHandler
          template={tmp}
          key={tmp.hash}
          editTemplateLive={editTemplateLive}
        />
      );
    });
    return results;
  }, [templates]);

  useEffect(() => {
    if (newEvent) {
      editTemplateLive(templates.find((template) => template.id === newEvent));
    }
  }, [newEvent]);

  return (
    <Box className={clsx("templateManagement", classes.root)}>
      {!showEditModal && (
        <>
          <Header
            defaultTemplate={defaultTemplate}
            keyword={keyword}
            setNewEvent={setNewEvent}
          />
          <Box className={clsx("content", classes.content)}> {mapResult}</Box>
        </>
      )}

      {showEditModal && (
        <MailEditor
          templateHash={templateToEdit.hash}
          goBack={() => {
            setShowEditModal(false);
            setTemplateToEdit({});
          }}
        />
      )}
    </Box>
  );
};

export default Mails;
