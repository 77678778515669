import { Box, ButtonBase, Divider, makeStyles, Typography } from "@material-ui/core";
import { resourceDelete } from "app/state/ducks/ressources/actions";
import React from "react";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import { useDispatch } from "react-redux";
import ListItem from "./ListItem";

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid #e5e7eb",
    borderRadius: "8px",
    width: "400px",
    marginTop: "20px",
  },
  list: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  divider: {
    width: "100%",
  },

}));
const List = ({ accesses, projectHash }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  if (accesses.length === 0) {
    return (
      <Box>
        <Typography>No admins</Typography>
      </Box>
    )
  }
  return (
    <Box className={classes.listWrapper}>
      {accesses.map((access) => {
        return (
          <Box className={classes.list}>
            <ListItem access={access} accesses={accesses} projectHash={projectHash} />
            {accesses[accesses.length - 1] !== access && <Divider className={classes.divider} />}
          </Box>
        );
      })}
    </Box>
  )
};

export default List;