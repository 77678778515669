import emitter from "core/emitter";
import {
  CORE_USER_LOADED,
  CORE_ROUTE_CHANGE,
  CORE_RESOURCE_ACTION,
} from "core/emitter/events";

export default class Segment {
  constructor(id, analytics) {
    if (!id.length) {
      return;
    }

    // listen for route change to call page() method
    emitter.on(CORE_ROUTE_CHANGE, (location, action) => {
      // do not log replace page changes
      if (action === "REPLACE") return;

      analytics.method("page");
    });

    // listen for logged in user info to call identify() method
    emitter.on(CORE_USER_LOADED, (id, attributes) => {
      console.log("> Segment", CORE_USER_LOADED, id, attributes);
      delete attributes.company;
      analytics.method("identify", id, attributes);
    });

    // listen for resources actions
    emitter.on(CORE_RESOURCE_ACTION, (action, { type, options = {} }) => {
      // do not log fetche(s) actions except if there is an explicit analytics var
      if (action === "fetch" && !options.analytics) return;

      this.onAction(action, type, options);
    });

    // initial call
    analytics.page();

    this.analytics = analytics;
  }

  onAction(action, type, options) {
    let event = `${type}:${action}`;
    let context = {};

    // @DOC
    // we can give analytics context to segment
    // or do not track
    if (options.analytics) {
      if (options.analytics.ignore) return;

      // replace name for example
      if (options.analytics.event)
        event = options.analytics.event.toLowerCase();

      // or give more context
      if (options.analytics.context) context = options.analytics.context;
    }

    this.analytics && this.analytics.method("track", event, context);
  }

  track(event, context = {}) {
    if (!this.analytics || !event) return;

    // Deprecated : used on Jam
    // add integrations key to filter or not some events
    // used to avoid reaching Intercom fucking 120 events limit
    // could be overriden by context
    context = {
      // integrations: destinations(event),
      ...context,
    };

    this.analytics && this.analytics.method("track", event, context);
  }
}
