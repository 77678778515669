import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { getInitials } from "./utils";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  initials: {
    background: theme.palette.colors.grey400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "99px",
    width: "32px",
    height: "32px",
  },
  anonyme: {
    background: theme.palette.colors.grey,
  },
  typoInitials: {
    color: "white",
    fontWeight: 600,
  },
}));

const Avatar = ({ user, children }) => {
  const initials = user ? getInitials(user) : false;

  const classes = useStyles({});
  return (
    <Box className={classes.root}>
      <Box className={classes.initials}>
        {initials && (
          <>
            <Typography className={classes.typoInitials} variant="smallBodySB1">
              {initials}
            </Typography>
          </>
        )}
      </Box>
      {children}
    </Box>
  );
};

Avatar.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
};

Avatar.defaultProps = {
  children: null,
};

export default Avatar;
