import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase, Fade } from "@material-ui/core";
import PropTypes from "prop-types";
import { _t } from "i18n";
import emitter from "utilities/emitter";
import { useSelector, useDispatch } from "react-redux";
import { REPLAY_PLAYER_PAUSE, REPLAY_PLAYER_PLAY, REPLAY_PLAYER_SEEK_FORWARD, REPLAY_PLAYER_SEEK_BACKWARD } from "utilities/emitter/events";
import PlayFill from "react-bootstrap-icons/dist/icons/play-fill";
import PauseFill from "react-bootstrap-icons/dist/icons/pause-fill";
import { setReplayOverlay } from "app/state/ducks/liveShopping/actions";
import clsx from "clsx";
import CustomArrowClockPlus from "app/liveShopping/assets/icons/CustomArrowClockPlus";
import CustomArrowClockLess from "app/liveShopping/assets/icons/CustomArrowClockLess";
import { replayOverlaySelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  visible: {
    background: "rgba(0, 0, 0, 0.75)",
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  invisible: {
    opacity: 0,
    transition: "opacity 1s ease-out",
  },
}));

let timeout;

const ReplayPlayerOverlay = ({}) => {
  const replayOverlay = useSelector(replayOverlaySelector);
  const dispatch = useDispatch();
  const classes = useStyles({ replayOverlay });
  const [played, setPlayed] = useState(true);

  useEffect(() => {
    if (!replayOverlay) return;
    timeout = setTimeout(handleClose, 5000);
  }, [replayOverlay]);

  const preventAutoClose = () => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = false;
    }
  };
  const handleClose = () => {
    dispatch(setReplayOverlay(false));
  };

  const handlePlayPause = (e) => {
    e.stopPropagation();
    preventAutoClose();

    if (played) {
      emitter.emit(REPLAY_PLAYER_PAUSE);
      setPlayed(false);
    } else {
      emitter.emit(REPLAY_PLAYER_PLAY);
      setPlayed(true);
      dispatch(setReplayOverlay(false));
    }
  };

  const handleSeekForward = (e) => {
    e.stopPropagation();
    preventAutoClose();
    emitter.emit(REPLAY_PLAYER_SEEK_FORWARD, 10);
  };
  const handleSeekBackward = (e) => {
    e.stopPropagation();
    preventAutoClose();
    emitter.emit(REPLAY_PLAYER_SEEK_BACKWARD, 10);
  };

  const elements = (
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          width: "25%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonBase onClick={handleSeekBackward}>
          <CustomArrowClockLess color={"#FFF"} />
        </ButtonBase>
      </Box>
      <Box style={{ width: "50%", display: "flex", justifyContent: "center" }}>
        <ButtonBase onClick={handlePlayPause}>{played ? <PauseFill size={70} color={"#FFF"} /> : <PlayFill size={70} color={"#FFF"} />}</ButtonBase>
      </Box>
      <Box
        style={{
          width: "25%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ButtonBase onClick={handleSeekForward}>
          <CustomArrowClockPlus color={"#FFF"} />
        </ButtonBase>
      </Box>
    </Box>
  );

  return (
    <Fade in={replayOverlay}>
      <Box
        className={clsx("ReplayPlayerOverlay", {
          [classes.visible]: true,
          [classes.invisible]: !replayOverlay,
        })}
        onClick={handleClose}
      >
        {replayOverlay ? elements : null}
      </Box>
    </Fade>
  );
};

ReplayPlayerOverlay.propTypes = {};

ReplayPlayerOverlay.defaultProps = {};

export default ReplayPlayerOverlay;
