import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Profil from "liveShopping/backoffice/otm/components/Profil/Profil";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import SolutionSwitcher from "./SolutionSwitcher";
import emitter from "utilities/emitter";
import { useSelector } from "react-redux";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";
import { useHistory } from "react-router-dom";
import logo from "app/liveShopping/assets/images/liveboutiquelogo.svg";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 15px",
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "1px solid #F0F0F4",
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  icon: {
    marginRight: "10px",
    color: theme.palette.colors.greyDark,
  },

  topRight: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
  },
  logo: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "130%",
    color: theme.palette.colors.primary,
  },
}));

const createdVisitors = {};

const TopBar = ({ user }) => {
  const classes = useStyles({});
  const notification = useSelector(currentUserSelector).extra_data?.notification;
  const history = useHistory();

  const onCreate = ({ data }) => {
    const id = data.data.id;
    createdVisitors[id] = true;
    console.log('[Notification] onCreate', id, createdVisitors);
  };

  const onEdit = ({ data }) => {
    const id = data.data.id;
    console.log('[Notification] onEdit', id, data.data.attributes.status, createdVisitors);
    if (data.data.attributes.status !== "waiting") return;
    if (!createdVisitors[id]) return;
    delete createdVisitors[id];
    onNotify();
  };

  const onNotify = () => {
    console.log('[Notification] onNotify');
    new Notification(_t("New visitor"), { body: _t("A new visitor has joined the queue") })
  }

  useEffect(() => {
    if (!notification) return;

    console.log('[Notification] Listen to visitorCreate && visitorEdit events');

    emitter.on("visitorCreate", onCreate);
    emitter.on("visitorEdit", onEdit);

    return () => {
      emitter.off("visitorCreate", onNotify);
      emitter.off("visitorEdit", onEdit);
    }
  }, [notification])

  return (
    <Box className={classes.root}>
      <Box className={classes.content} onClick={() => history.push("/")}>
        <img src={logo} width={150} />
      </Box>
      <Box className={classes.topRight}>
        <SolutionSwitcher />
        {user && <Profil user={user} />}
      </Box>
    </Box>
  );
};

TopBar.propTypes = {
  user: PropTypes.object,
};

TopBar.defaultProps = {
  user: null,
};

export default TopBar;
