import React from "react";
import PropTypes from "prop-types";
import { _t } from "utilities/i18n";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { desktopLayoutSelector } from "liveShoppingDucks/selectors";
import { catalogIsOpenSelector, playerLoadedSelector } from "app/state/ducks/liveShopping/selectors";
import { setOpenCatalog } from "app/state/ducks/liveShopping/actions";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
const useStyles = makeStyles((theme) => ({
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "30px",
    marginBottom: "30px",
  },
  typoHead: {
    color: "black",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "130%",
    marginLeft: "24px",
  },
  close: {
    height: "44px",
    width: "44px",
    right: "24px",
    borderRadius: "99px",
    padding: "12px",
    background: theme.palette.colors.lightGrey,
  },
  searchWrapper: {
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
  },
  searchInput: {
    fontSize: "14px",
    width: (props) => (props.isDesktop ? "100%" : !props.isDesktop && !props.playerIsLoaded ? "100%" : "calc(100% - 150px)"),
    padding: "10px 12px",
    outline: "none",
    margin: " 0 24px",
    background: theme.palette.colors.white,
    border: "1px solid #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "8px",
    WebkitAppearance: "none",
  },
}));

const Header = ({ setSearch }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const catalogIsOpen = useSelector(catalogIsOpenSelector);
  const playerIsLoaded = useSelector(playerLoadedSelector);
  const classes = useStyles({ isDesktop, playerIsLoaded });
  const dispatch = useDispatch();

  const handleSearch = (e) => {
    let keyword = e.target.value;
    setSearch(keyword);
  };

  const handleQuitPip = () => {
    if (catalogIsOpen) {
      dispatch(setOpenCatalog(false));
    }
  };
  return (
    <Box
      style={{
        width: "100%",
        height: isDesktop ? "auto" : "170px",
        display: "flex",
        flexDirection: "column",
        flex: "0 0 auto",
        background: "#FFF",
      }}
    >
      <Box className={classes.top}>
        <Typography className={classes.typoHead}>{_t("Catalog")}</Typography>
        {!isDesktop && (
          <ButtonBase onClick={handleQuitPip} className={classes.close}>
            <XLg size={18} />
          </ButtonBase>
        )}
      </Box>
      <Box className={classes.searchWrapper}>
        <input className={classes.searchInput} placeholder={_t("Search")} onChange={(e) => handleSearch(e)} />
      </Box>
    </Box>
  );
};

Header.propTypes = { isDesktop: PropTypes.bool };

export default Header;
