import React from "react";
import { _t } from "utilities/i18n";
import clsx from "clsx";

import { Box, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { Calendar } from "react-bootstrap-icons";
import CameraFill from "react-bootstrap-icons/dist/icons/camera-video-fill";
import { currentLiveVendorsSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "120px 20px 20px 20px",
    height: "100%",
    background: ({ background }) => background ? `url(${background})` : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  description: {
    maxWidth: "300px",
  },
  actions: {
    marginTop: "15px",
  },
  action: {
    marginBottom: "5px",
  },
  choice: {
    display: "flex",
    padding: "15px",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
  },
  propulsed: {
    position: "absolute",
    bottom: "5px",
    left: "0",
    width: "100%",
    textAlign: "center",
  },
  propulsedText: {
    textAlign: "center",
    fontSize: "12px",
    textDecoration: "none",
    color: theme.palette.colors.grey500,
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  live: {
    pointerEvents: ({ liveVendors }) => liveVendors ? "auto" : "none",
    filter: ({ liveVendors }) => liveVendors ? "none" : "blur(2px)",
  }
}));

const Choice = ({ onChoose }) => {
  const project = useSelector(currentProjectSelector);
  const liveVendors = useSelector(currentLiveVendorsSelector);

  const wording = project.options.choice_screen || {};
  const classes = useStyles({ background: wording.background_url, liveVendors });

  return (
    <Box className={clsx("ChoiceWrapper", classes.root)}>
      <Box>
        <Box className={clsx("ChoiceTitle", classes.title)}>{wording.title || _t("Our experts answer your questions")}</Box>
        <Box className={clsx("ChoiceDesc", classes.description)}>{wording.description || _t("How do you want to reach us today?")}</Box>
        <Box className={clsx("ChoiceActions", classes.actions)}>

          {/* <Box className={clsx("ChoiceAction", classes.action)}>
            <Button onClick={() => onChoose('schedule')}>{wording.cta_appointment || _t("Schedule an appointment")}</Button>
          </Box>

          <Box className={clsx("ChoiceAction", classes.action)}>
            <Button onClick={() => onChoose('chat')}>{wording.cta_chat || _t("Live chat now")}</Button>
          </Box> */}

          <Box className={clsx("ChoiceAction", classes.choice)} onClick={() => onChoose('schedule')}>
            <Box>
              <Calendar />
            </Box>
            {wording.cta_appointment || _t("Schedule an appointment")}
          </Box>

          <Box className={clsx("ChoiceAction", classes.choice, classes.live)} onClick={() => onChoose('chat')}>
            <Box>
              <CameraFill />
            </Box>
            {wording.cta_chat || _t("Live chat now")}
          </Box>

        </Box>
      </Box>
      <Box className={clsx("ChoicePropulsed", classes.propulsed)}>
        <a href="https://liveboutique.io/" target="_blank" className={classes.propulsedText} rel="noreferrer">{_t("Propulsed by liveboutik")}</a>
      </Box>
    </Box>
  );
};

export default Choice;
