export const LIVESHOPPING_INIT = "LIVESHOPPING_INIT";
export const PRELIVE_INIT = "PRELIVE_INIT";
export const SET_LAYOUT_TYPE = "SET_LAYOUT_TYPE";
export const SET_VIEWED_PRODUCT = "SET_VIEWED_PRODUCT";
export const SET_PLAYER_VOLUME = "SET_PLAYER_VOLUME";
export const SET_OPEN_CATALOG = "SET_OPEN_CATALOG";
export const SET_CLOSE_CATALOG = "SET_CLOSE_CATALOG";
export const SET_FULL_SCREEN = "SET_FULL_SCREEN";
export const SET_OPEN_CART = "SET_OPEN_CART";
export const SET_PLAYER_LOADED = "SET_PLAYER_LOADED";
export const SET_PLAY = "SET_PLAY";
export const CHECK_IS_IFRAME = "CHECK_IS_IFRAME";
export const CHECK_IS_PRELIVE = "CHECK_IS_PRELIVE";
//REPLAY
export const SET_OPEN_REPLAY = "SET_OPEN_REPLAY";
export const SET_REPLAY_DURATION = "SET_REPLAY_DURATION";
export const SET_CURRENT_CHAPTER = "SET_CURRENT_CHAPTER";
export const SET_REPLAY_OVERLAY = "SET_REPLAY_OVERLAY";
export const SET_REPLAY_DIALOG = "SET_REPLAY_DIALOG";
//CART
export const CREATE_CART = "CREATE_CART";
export const UPDATE_CART = "UPDATE_CART";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_CART_QUANTITY = "UPDATE_CART_QUANTITY";
export const PROCEED_TO_CHECKOUT = "PROCEED_TO_CHECKOUT";

//BACK-OFFICE
export const SET_LIVE_SHOPPING_BOOTSTRAP = "SET_LIVE_SHOPPING_BOOTSTRAP";
export const DISPLAY_TABBAR = "DISPLAY_TABBAR";
export const DISPLAY_MODAL_ALERT = "DISPLAY_MODAL_ALERT";
export const BACK_OFFICE_ELEMENT = "BACK_OFFICE_ELEMENT";
export const SET_CURRENT_VISITOR = "SET_CURRENT_VISITOR";
export const OPEN_NPS = "OPEN_NPS";
export const ACTIVATE_NOTIFICATIONS = "ACTIVATE_NOTIFICATIONS";
//OTO
export const IS_READY = "IS_READY";
export const IS_RETRACTED = "IS_RETRACTED";
export const SET_SCREEN_ORIENTATION = "SET_SCREEN_ORIENTATION";
export const SET_CURRENT_CALENDAR = "SET_CURRENT_CALENDAR";
export const SET_CURRENT_LIVE_VENDORS = "SET_CURRENT_LIVE_VENDORS";
export const OPEN_FISHING_ROD = "OPEN_FISHING_ROD";