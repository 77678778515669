import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import get from "lodash/get";
import map from "lodash/map";

import { _t } from "i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
}));

const HtmlSurveyItemResult = ({ item }) => {
  const classes = useStyles();
  return (
    <Box className={clsx("HtmlSurveyItemResult", classes.root)}>
      HtmlSurveyItemResult
    </Box>
  );
};

HtmlSurveyItemResult.propTypes = {
  item: PropTypes.object,
};

export default HtmlSurveyItemResult;
