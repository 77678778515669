import { BarChart, Title } from "@tremor/react";
import dayjs from "dayjs";
import React from "react";
import { getDates, getHours, getMonths } from "../utils";
import groupBy from "lodash/groupBy";
import { _t } from "utilities/i18n";
import Card from "../Card";

const Visitors = ({ dates, selectedPeriod, createdAts }) => {



  let groupedResults = groupBy(createdAts, (result) => { return dayjs(result * 1000).format(dates[2] === "day" ? "HH" : dates[2] === "year" ? "MM" : "YYYY-MM-DD") });

  const dataFormatter = (number) => {
    return Intl.NumberFormat("us").format(number).toString();
  };

  const dataToDisplay = () => {
    if (dates[2] === "day") {
      return getHours(dates[0], dates[1]).map((hour) => {
        return { date: hour + "h", [_t("viewers")]: groupedResults[hour]?.length || 0 };
      });
    } else if (dates[2] === "week") {
      return getDates(dates[0], dates[1]).map((date) => {
        return { date: date, [_t("viewers")]: groupedResults[date]?.length || 0 };
      });
    } else if (dates[2] === "month") {
      return getDates(dates[0], dates[1]).map((date) => {
        return { date: date, [_t("viewers")]: groupedResults[date]?.length || 0 };
      });
    } else if (dates[2] === "year") {
      const months = getMonths(dates[0], dates[1])
      months.shift()
      return months.map((date) => {
        return { date: date, [_t("viewers")]: groupedResults[date]?.length || 0 };
      });
    }
    return []
  };

  return (
    <Card isGraph>
      <Title>{_t("Visitors in time")}</Title>
      <BarChart
        data={dataToDisplay()}
        categories={[_t("viewers")]}
        dataKey="date"
        colors={["blue"]}
        valueFormatter={dataFormatter}
        layout="horizontal"
        stack={false}
        relative={false}
        startEndOnly={false}
        showXAxis={true}
        showYAxis={true}
        yAxisWidth="w-14"
        showTooltip={true}
        showLegend={true}
        showGridLines={true}
        showAnimation={true}
        height="h-72"
        marginTop="mt-0"
      />
    </Card>
  )
};

export default Visitors;