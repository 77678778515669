import React, { useState, useEffect } from "react";

import { _t } from "core/i18n";
import emitter from "utilities/emitter";
import { EVENT_ADD_USERS } from "utilities/emitter/events";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { Panel } from "adminComponents/Mui";
import { Radio } from "appComponents/Mui";
import {
  ManualImport,
  FileImport,
} from "adminComponents/UserManagement/Import";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5, 1.875),
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: 500,
  },
  divider: {
    margin: theme.spacing(3.75, 0),
    color: theme.palette.border.default50,
    position: "relative",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    border: "0",
    "&::before, &::after": {
      boxSizing: "border-box",
      content: "''",
      flex: "1 1 auto",
      borderTop: `1px solid ${theme.palette.border.default}`,
    },
  },
  dividerLabel: {
    display: "inline-block",
    fontSize: "20px",
    fontWeight: "600",
    flex: "0 0 auto",
    textAlign: "center",
    padding: theme.spacing(0, 1.875),
  },
  userType: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "50px",
  },
  type: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const AddUserPanel = () => {
  const classes = useStyles();
  const [displayPanel, setDisplayPanel] = useState(null);
  const [hideManualImport, setHideManualImport] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);

  useEffect(() => {
    const callback = (forTeam) => {
      setDisplayPanel(true);
      setHideManualImport(false);
      setIsAdmin(forTeam);
    };

    emitter.on(EVENT_ADD_USERS, callback);
    return () => emitter.off(EVENT_ADD_USERS, callback);
  }, []);

  return (
    <Panel
      open={displayPanel !== null}
      handleClose={() => setDisplayPanel(null)}
      noHeader
    >
      <Box className={classes.root}>
        <Box className={classes.title}>{_t("Add participants")}</Box>
        <Box className={classes.userType}>
          <Box className={classes.type}>
            {" "}
            <Radio
              onChange={() => {
                setIsAdmin(false);
              }}
              name="userType"
              checked={!isAdmin}
            />{" "}
            <Box className={classes.label}>{_t("participants")}</Box>
          </Box>
          <Box className={classes.type} ml={4.75}>
            {" "}
            <Radio
              onChange={() => {
                setIsAdmin(true);
              }}
              name="userType"
              checked={isAdmin}
            />{" "}
            <Box className={classes.label}>{_t("team")}</Box>
          </Box>
        </Box>
        {displayPanel !== null && (
          <FileImport
            ListenerQueuedFiles={(files) =>
              setHideManualImport(files.length > 0)
            }
            isAdmin={isAdmin}
          />
        )}

        {!hideManualImport && (
          <>
            <Box className={classes.divider}>
              <Typography className={classes.dividerLabel} variant="caption">
                {_t("or").toUpperCase()}
              </Typography>
            </Box>
            <ManualImport isAdmin={isAdmin} />
          </>
        )}
      </Box>
    </Panel>
  );
};

//AddUserPanel.propTypes = {};

export default AddUserPanel;
