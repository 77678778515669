import React from "react";
import Box from "@material-ui/core/Box";
import Player from "app/liveShopping/frontoffice/otm/components/Player/Player";
import PlayerElements from "app/liveShopping/frontoffice/otm/containers/Player/Elements";
import Catalog from "app/liveShopping/frontoffice/otm/components/Catalog/Catalog";
import ProductDialog from "app/liveShopping/frontoffice/otm/components/ProductDialog/ProductDialog";
import VisibilityDialog from "app/liveShopping/frontoffice/otm/components/VisibilityDialog";
import Replay from "app/liveShopping/frontoffice/otm/components/Replay/Replay";
import Cart from "app/liveShopping/frontoffice/otm/components/Cart/Cart";
import LandscapeMode from "./LandscapeMode";
import LoadingLayout from "./LoadingLayout";
import PlayLayout from "./PlayLayout";
import { useSelector } from "react-redux";
import { playerLoadedSelector, viewedProductSelector } from "liveShoppingDucks/selectors";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import Chat from "app/liveShopping/frontoffice/otm/containers/Chat/ChatContainer";
import ReplayPlayerOverlay from "./Replay/ReplayPlayerOverlay";
import TapZone from "./Replay/TapZone";
import ReplayDialog from "./Replay/ReplayDialog";
import { useMediaPredicate } from "react-media-hook";
import { MEDIA_QUERIES } from "utilities/mediaQuery";
import MonoProduct from "./MonoProduct";

const Mobile916Layout = () => {
  const playerIsLoaded = useSelector(playerLoadedSelector);
  const viewedProduct = useSelector(viewedProductSelector);
  const wiz = useSelector(currentWizSelector);

  const isPip = useMediaPredicate(MEDIA_QUERIES.PIP);

  return (
    <>
      <LandscapeMode />

      {playerIsLoaded === true ? <PlayLayout /> : <LoadingLayout loadedState={playerIsLoaded} />}

      {wiz.status === "replay" && (
        <>
          <TapZone />
          <ReplayPlayerOverlay />
        </>
      )}

      {!isPip && (
        <>
          <PlayerElements />
          <Catalog />
          {wiz.cart_integration && <Cart />}
        </>
      )}

      {wiz.status === "replay" && <Replay />}

      <Player />

      {!isPip && (
        <Box
          style={{
            position: "absolute",
            bottom: "0px",
            width: "100%",
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 43.09%, rgba(0, 0, 0, 0.8) 100%)",
          }}
        >
          <Chat />
          <VisibilityDialog />
          <ReplayDialog />
          <MonoProduct />
          {viewedProduct ? <ProductDialog /> : null}
        </Box>
      )}
    </>
  );
};

Mobile916Layout.propTypes = {};

Mobile916Layout.defaultProps = {};

export default Mobile916Layout;
