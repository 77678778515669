import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import has from "lodash/has";
import Box from "@material-ui/core/Box";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  detailsPosition: {
    height: "35px",
    width: "35px",
    borderRadius: "50%",

    backgroundColor: theme.palette.text.disabled,
  },
  highligth: {
    backgroundColor: theme.palette.background.secondaryBox,
  },
  content: {},
  itemLabel: {
    marginLeft: theme.spacing(2),
  },
  goodQuizzAnswer: {
    backgroundColor: theme.wisemblyColors.lightGreen,
  },
  badQuizzAnswer: {
    backgroundColor: theme.wisemblyColors.pastelRed,
  },
  correction: {
    color: theme.wisemblyColors.lightGreen,
  },
  detailsText: {
    flex: "auto",
    justifyContent: "flex-start",
    width: "calc(100% - 40px)",
    paddingLeft: theme.spacing(2.25),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: 0.5,
  },
  bulletPoint: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: 0.5,
    paddingLeft: 30,
    paddingTop: 0,
    marginBottom: 36,
    "&::marker": {
      color: theme.palette.secondary.main,
    },
  },
}));

const UserUniqueSurveyItemResult = ({
  surveyItemAnswer,
  surveyItem,
  survey,
}) => {
  const userChoices = surveyItemAnswer.value;
  const options = get(surveyItem, "options.choices", []);
  // const results = filter(options, (option) =>
  //   userChoices.includes(option.hash)
  // );

  // const badOptions = options.filter((optn) => !has(optn, "status"));
  // const isQuizz =
  //   has(get(surveyItem, "options"), "correction") && isEmpty(badOptions);
  const isQuizz = survey && survey.type === "evaluation";
  const hasCorrection =
    isQuizz && has(get(surveyItem, "options"), "correction");

  const classes = useStyles();
  const result = map(options, (option, key) => {
    if (!userChoices.includes(option.hash) && !isQuizz) return null;
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        mb={1}
      >
        <Box
          display={isQuizz ? "flex" : "none"}
          justifyContent="center"
          alignItems="center"
          className={clsx({
            [classes.detailsPosition]: true,
            [classes.highligth]: userChoices.includes(option.hash),
            [classes.goodQuizzAnswer]: isQuizz && option.status,
            [classes.badQuizzAnswer]:
              isQuizz && !option.status && userChoices.includes(option.hash),
          })}
        >
          {key + 1}
        </Box>
        {isQuizz ? (
          <Box
            display="flex"
            ml={1}
            alignItems="center"
            className={classes.detailsText}
          >
            {get(option, "label", "")}
          </Box>
        ) : (
          <li className={classes.bulletPoint}>{get(option, "label", "")}</li>
        )}
      </Box>
    );
  });
  if (isQuizz && hasCorrection) {
    return (
      <>
        {result}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          className={classes.correction}
        >
          {get(surveyItem, "options.correction")}
        </Box>
      </>
    );
  }
  return result;
};
UserUniqueSurveyItemResult.propTypes = {
  surveyItem: PropTypes.object,
  surveyItemAnswer: PropTypes.object,
  survey: PropTypes.object,
};

export default UserUniqueSurveyItemResult;
