import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Form from "../../../Product/Form";
import { productsSelector } from "app/state/ducks/ressources/selectors";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import clsx from "clsx";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { useParams } from "react-router-dom";
import { getKeyword } from "app/state/ducks/application/selectors";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import LoadingLayout from "app/liveShopping/frontoffice/otm/components/LoadingLayout";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    height: "100%",
    width: "100%",
    position: "relative",
    background: "#FFF",
    zIndex: 8,
  },
  mobileLayout: {
    overflow: "auto",
    flex: "1",
  },
  desktopLayout: {
    overflow: "auto",
    flex: 1,
  },
}));

const Edit = ({ }) => {
  const classes = useStyles();
  const isMobile = useSelector(mobileLayoutSelector);
  const [data, setData] = useState(null);
  const products = useSelector(productsSelector);
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const { hash } = useParams();

  useEffect(() => {
    dispatch(
      resourceFetch("products", {
        slug: ["event", keyword, "products"],
        once: true,
        callback: (error, response) => {
          if (error) {
            return;
          }
        },
      })
    );
  }, []);

  if (products.length === 0) return <LoadingLayout />;
  const productToEdit = products.find((product) => product.hash === hash);

  return (
    <Box className={classes.root}>
      <Header />
      <Box
        className={clsx({
          [classes.mobileLayout]: isMobile,
          [classes.desktopLayout]: !isMobile,
        })}
      >
        <Form setData={setData} product={productToEdit} />
      </Box>
      <Footer data={data} hash={productToEdit.hash} />
    </Box>
  );
};

Edit.propTypes = {};

Edit.defaultProps = {};

export default Edit;
