import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t, _n } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

import { setCurrentVisitor } from "app/state/ducks/liveShopping/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHover } from "../hooks/useHover";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import WaitingTime from "./WaitingTime";
import Bag from "react-bootstrap-icons/dist/icons/bag";
import BadgeLive from "./BadgeLive";
import { formatPrice } from "app/liveShopping/utils";
import NotificationMessageQueue from "./NotificationMessageQueue";
import { CalendarCheck } from "react-bootstrap-icons";
import dayjs from "dayjs";
import { Tooltip } from "@material-ui/core";
import BadgeAppointment from "./BadgeAppointment";
import { currentProjectSelector } from "app/state/ducks/application/selectors";

const useStyles = makeStyles((theme) => ({
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: props => props.empty && "center",
    alignItems: props => props.empty && "center",
    width: "320px",
    height: "164px",
    padding: "16px",
    position: "relative",
    background: props => props.currentVisitor === props.visitor || props.isHovering ? theme.palette.colors.grey200 : "#FFFFFF",
    borderRight: "1px solid #F0F0F4",
  },
  username: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  hash: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
  referrer: {
    fontSize: "10px",
  },
  divider: {
    position: "absolute",
    bottom: 0,
  },
  cart: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
  },
  cartTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "150%",
    color: theme.palette.colors.grey700,
  },
  type: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    borderRadius: "99px",
    width: "fit-content",
    background: props => props.currentVisitor === props.visitor || props.isHovering ? theme.palette.colors.white : theme.palette.colors.grey200,
  },
  appointment: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px",
    borderRadius: "50%",
    width: "fit-content",
    background: theme.palette.colors.success,
    color: theme.palette.colors.white,
  },
  typeTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "100%",
    color: theme.palette.colors.grey700,
    width: "fit-content",
  },
  bottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "16px",
  },
}));

const Visitor = ({ visitor, empty }) => {
  const currentVisitor = useSelector(currentVisitorSelector);
  const [hoverRef, isHovering] = useHover();
  const classes = useStyles({ currentVisitor: currentVisitor?.id, visitor: visitor?.id, empty, isHovering });
  const currentProject = useSelector(currentProjectSelector);
  const cart_integration = currentProject?.options?.cart_integration;

  const dispatch = useDispatch();

  const { item_count, total_price } = visitor.options?.cart || { item_count: 0, total_price: 0 };
  const isOngoing = visitor.status === "ongoing";
  const isEnded = visitor.status === "ended";

  const displayUserName = ({ options, hash }) => {
    const { form, schedule } = options;

    if (form?.firstname || form?.lastname)
      return `${form.firstname} ${options.form.lastname}`;

    if (schedule?.firstname || schedule?.lastname)
      return `${schedule?.firstname} ${schedule?.lastname}`;

    return `${_t("Visitor")} #${hash?.slice(0, 5)}`;
  };

  const handleVisitor = () => {
    dispatch(setCurrentVisitor(visitor.hash));
  }

  return (
    <>
      <Box ref={hoverRef} className={classes.info} onClick={handleVisitor}>

        {!empty ? (<>
          <Box className={classes.username}>
            <Typography className={classes.hash}>{displayUserName(visitor)}</Typography>
            {isOngoing ? <BadgeLive status={"ongoing"} color={isOngoing ? "#F0464B" : "#D6D8DC"} /> : visitor.scheduled_at ? <BadgeAppointment visitor={visitor} /> : <WaitingTime current={currentVisitor} visitor={visitor} />}
          </Box>
          <Box>
            <Typography className={classes.referrer}>{_t("Page")} : {visitor.joined_page_title}</Typography>
            {cart_integration && (<Box className={classes.cart}>
              <Bag />
              <Typography className={classes.cartTypo}>{_n("{count} item • {value}", "{count} items • {value}", item_count, { count: item_count, value: formatPrice(Number(((total_price || 0) / 100).toFixed(2)), "EUR") })}</Typography>
            </Box>)}
            <Box className={classes.bottom}>
              <Box className={classes.type}>
                <Typography className={classes.typeTypo}>{visitor.queue.group.title}</Typography>
              </Box>
              <Box>
                {visitor.options?.mode && (
                  <>
                    {visitor.options.mode === "chat" && <Typography className={classes.typeTypo}>{_t("Chat only")}</Typography>}
                    {visitor.options.mode === "audio" && <Typography className={classes.typeTypo}>{_t("Audio only")}</Typography>}
                    {visitor.options.mode === "video" && <Typography className={classes.typeTypo}>{_t("Video")}</Typography>}
                  </>
                )}
              </Box>
              <NotificationMessageQueue visitor={visitor} message={_t("New message")} />
            </Box>
          </Box>
        </>) : <Typography>{_t("No visitor")}</Typography>}
      </Box>
    </>
  );
};

Visitor.propTypes = {};

Visitor.defaultProps = {};

export default Visitor;
