import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Switch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import { useDispatch } from "react-redux";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR } from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", gap: "5px" },
}));

const AvailabilitySwitcher = ({ access }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)

  const handleAvailable = () => {
    setLoading(true)

    dispatch(
      resourceEdit(
        Object.assign(access, {
          available: !access.available,
        }),
        {
          slug: ["groups", access.group.hash, "admins"],
          path: ["available", "hash", "scope"],
          callback: (error) => {
            if (error) {
              emitter.emit(EVENT_FLASH_ERROR, _t("An error has occurred"));
            }
            setLoading(false)
          }
        }
      )
    );
  };

  return (
    <Box className={classes.root}>
      <Switch onClick={handleAvailable} checked={access.available} disabled={loading} />
    </Box>
  );
};

AvailabilitySwitcher.propTypes = {};

AvailabilitySwitcher.defaultProps = {};

export default AvailabilitySwitcher;
