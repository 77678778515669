import MarkdownIt from "markdown-it";

const md = new MarkdownIt({
  html: true,
  breaks: true,
});

const defaultRender =
  md.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    // eslint-disable-line func-names
    return self.renderToken(tokens, idx, options);
  };

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  // eslint-disable-line func-names
  // If you are sure other plugins can't add `target` - drop check below
  const aIndex = tokens[idx].attrIndex(`target`);

  if (aIndex < 0) {
    tokens[idx].attrPush([`target`, `_blank`]); // add new attribute
  } else {
    tokens[idx].attrs[aIndex][1] = `_blank`; // replace value of existing attr
  }

  // pass token to default renderer.
  return defaultRender(tokens, idx, options, env, self);
};

export const markdownToHTML = (markdown) => md.render(markdown);
