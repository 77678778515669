import { API_BASE } from "settings/config";
import React, { useEffect, useState } from "react";
import { _t } from "utilities/i18n";
import Button from "../../reusables/Button";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    height: "40px",
    marginTop: "10px",
    marginBottom: "10px",
  },
}));

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

const OtoChecker = ({ projectId, productUrl, isBackOffice }) => {
  const [projectInfo, setProjectInfo] = useState([]);
  const [showButton, setShowButton] = useState(false);
  const [message, setMessage] = useState("");
  const classes = useStyles();

  if (!projectId) return null;
  const apiBase = API_BASE.clone().toString()
  let baseUrl = `${apiBase.replace(/\/$/, "")}/liveshopping`.replace(/6/g, "4")
  const urlInfo = `${baseUrl.replace(/\/$/, "")}/${projectId}/info`;

  // fetch url info from api and set projectInfo state with the result of the request.
  useEffect(() => {
    fetch(urlInfo)
      .then((res) => res.json())
      .then((data) => {
        if (data?.success?.data === 'no_matching_project') return;
        setProjectInfo(data?.success?.data);
        return;
      });
  }, [projectId]);

  const checkPatternUrl = (urlSite, url) => {
    const regex = new RegExp(url.substring(9), "gmi");
    const result = regex.exec(urlSite);
    if (result === null) return false;
    return true;
  }

  useEffect(() => {
    setShowButton(false)
    setMessage("")

    if (projectInfo.length === 0) return;
    if (productUrl.length === 0) return;

    for (let index = 0; index < projectInfo.available.length; index++) {
      const availableUrl = projectInfo.available[index];

      if (productUrl.indexOf(availableUrl) !== -1 || (availableUrl.indexOf("pattern::") !== -1 && checkPatternUrl(productUrl, availableUrl))) {
        isBackOffice ? setMessage(_t("This product is available for OTO")) : setShowButton(true)
        return;
      }
    }

    if (showButton || message.length > 0) return;
    if (!isBackOffice) return;

    for (let index = 0; index < projectInfo.unavailable.length; index++) {
      const unavailableUrl = projectInfo.unavailable[index];

      if (productUrl.indexOf(unavailableUrl) !== -1 || (unavailableUrl.indexOf("pattern::") !== -1 && checkPatternUrl(productUrl, unavailableUrl))) {
        setMessage(_t("This product is available for OTO"));
        break;
      }
    }

  }, [projectInfo, productUrl]);


  const handleNavigate = () => {
    top.window.location.replace(`${productUrl}?oto_otm=true`)
  };

  return (
    <Box>
      {isBackOffice && message.length > 0 && <p>{message}</p>}
      {!isBackOffice && showButton && <Button clsName={classes.button} onClick={handleNavigate}>{_t("Discuss with a vendor")}</Button>}
    </Box>
  );
};

export default OtoChecker;
