import { Box, Divider, Modal, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SendExclamation, Back, BagFill, CursorFill, ExclamationTriangleFill, PersonX } from "react-bootstrap-icons";
import { _n, _t } from "utilities/i18n";
import { formatPrice } from "app/liveShopping/utils";
import PersonFill from "react-bootstrap-icons/dist/icons/person-fill";
import Laptop from "react-bootstrap-icons/dist/icons/laptop";
import { useDispatch, useSelector } from "react-redux";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import { detectBrowser } from "../../utils";
import { triggerCartRefresh } from "./service";
import ResourceFactory from "orm/resources";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import AbusiveDeclaration from "./AbusiveDeclaration";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import clsx from "clsx";
import RemoveVisitor from "./RemoveVisitor";
import Cobrowsing from "./Cobrowsing";
import NotifyVisitor from "./NotifyVisitor";
import { Panel } from "adminComponents/Mui";
import Products from "app/liveShopping/backoffice/oto/Component/Products";

const obfuscator = (phone) => {
  if (!phone || phone === "") return phone;

  // replace all but the last 4 digits with x
  return phone.replace(/.(?=.{4})/g, "x");
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    height: "100%",
    borderRight: "1px solid #F0F0F4",
    padding: "20px 25px",
    overflowY: "auto",
    zIndex: 1,
    backgroundColor: theme.palette.colors.white,
  },
  cart: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "17.5px",

  },
  leftCart: {
    display: "flex",
    gap: "9px",
    alignItems: "center",
  },
  titleCartTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
  priceTypo1: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "150%",
  },
  divider: {
    width: "100%",
    color: theme.palette.colors.grey200,
  },
  navigation: {
    display: "flex",
    flexDirection: "column",
    marginTop: "16px",
    marginBottom: "16px",
  },
  navigationTitle: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
    marginBottom: "8px",
  },
  navigationTitleTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
  infoPageTypoTitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "150%",
    color: theme.palette.colors.grey500,
  },
  customer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  },
  customerTitle: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
    marginTop: "16px",
    marginBottom: "8px",
  },
  customerTitleTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
  price: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
  },
  bottom: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
    marginTop: "8px",
    justifyContent: "space-between",
  },
  updateTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
    textDecorationLine: "underline",
    cursor: "pointer",
  },
  typoWrapperInfoPage: {
    display: "flex",
    gap: "5px",
  },
  infoPageTypo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "150%",
    color: theme.palette.colors.grey700,
  },
  priceTypo2: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "150%",
    color: theme.palette.colors.grey700,
  },
  priceTypo3: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "150%",
    color: theme.palette.colors.grey700,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalPaper: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    width: "500px",
    maxHeight: "300px",
    background: theme.palette.colors.white,
    margin: "auto",
    borderRadius: "8px",
    "&:focus-visible": {
      outline: "none",
    },
  },
  modalCobrowse: {
    width: "80vw",
    height: "80vh",
    maxHeight: "80vh",
  },
  ctaBottom: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
  },
  ctaButtons: {
    width: "38px",
    height: "38px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
  },
  abusiveButton: {
    background: theme.palette.colors.warning,
  },
  removeVisitor: {
    background: theme.palette.colors.error,
  },
  cobrowsingButton: {
    background: theme.palette.colors.grey500,
  },
  notifyVisitorButton: {
    background: theme.palette.colors.success,
  },
}));

const VisitorInfos = ({ }) => {
  const classes = useStyles();
  const visitor = useSelector(currentVisitorSelector);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const [openAbusive, setOpenAbusive] = useState(false);
  const [openRemoveVisitor, setOpenRemoveVisitor] = useState(false);
  const [openCobrowsing, setOpenCobrowsing] = useState(false);
  const [notifyVisitor, setNotifyVisitor] = useState(false);
  const { item_count, total_price } = visitor?.cart || { item_count: 0, total_price: 0 };
  const currentProject = useSelector(currentProjectSelector);
  const [open, setOpen] = useState(false);
  
  const handleTriggerCartRefresh = () => {
    const newVisitor = resourceFactory.create("Visitor", { ...visitor?.cart, trigger_cart_refresh: true });
    triggerCartRefresh(dispatch, visitor, newVisitor);
  };

  return (
    <Box className={classes.root}>
      {currentProject.cart_integration && (
        <>
          {open && (
            <Panel
              anchor="left"
              open={open}
              handleClose={() => setOpen(false)}
              style={{ overflow: "hidden", maxWidth: "47wv" }}
            >
              <Products embedded visitor={visitor} />
            </Panel>
          )}

          <Box className={classes.cart}>
            <Box className={classes.leftCart}>
              <BagFill size={12} />
              <Typography className={classes.titleCartTypo}>{_t("Customer cart")}</Typography>
              {currentProject.catalog_integration_url && (
                <Button onClick={() => setOpen(!open)}>{open ? _t("Close Catalog") : _t("Open Catalog")}</Button>
              )}
            </Box>
            <Box className={classes.price}>
              <Typography className={classes.priceTypo1}>
                {_n("{count} item •", "{count} items •", item_count, { count: item_count })}
              </Typography>
              <Typography className={classes.priceTypo2}>{_t("Total : ")}</Typography>
              <Typography className={classes.priceTypo3}>{formatPrice(Number(((total_price || 0) / 100).toFixed(2)), "EUR")}</Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
        </>
      )}

      <Box className={classes.navigation}>
        <Box className={classes.navigationTitle}>
          <CursorFill />
          <Typography className={classes.navigationTitleTypo}>{_t("Navigation information")}</Typography>
        </Box>
        <Box className={classes.typoWrapperInfoPage}>
          <Typography className={classes.infoPageTypoTitle}>{_t("Page concerned by the video : ")}</Typography>
          <Typography className={classes.infoPageTypo}>{visitor?.joined_page_title?.slice(0, 51)}</Typography>
        </Box>
        <Box className={classes.typoWrapperInfoPage}>
          <Typography className={classes.infoPageTypoTitle}>{_t("Current page : ")}</Typography>
          <Typography className={classes.infoPageTypo}>{visitor?.current_page_title?.slice(0, 51)}</Typography>

        </Box>
      </Box>
      <Divider className={classes.divider} />

      <Box className={classes.customer}>
        <Box style={{ width: "50%" }}>
          <Box className={classes.customerTitle}>
            <PersonFill size={12} />
            <Typography className={classes.customerTitleTypo}>{_t("Customer information")}</Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography className={classes.infoPageTypoTitle}>{_t("Firstname")}&nbsp;:&nbsp;</Typography>
            <Typography className={classes.infoPageTypoTitle} style={{ color: "#1E2023" }}>{visitor?.options?.form?.firstname || visitor?.options?.schedule?.firstname}</Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography className={classes.infoPageTypoTitle}>{_t("Lastname")}&nbsp;:&nbsp;</Typography>
            <Typography className={classes.infoPageTypoTitle} style={{ color: "#1E2023" }}>{visitor?.options?.form?.lastname || visitor?.options?.schedule?.lastname}</Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography className={classes.infoPageTypoTitle}>{_t("Contact: ")}&nbsp;</Typography>
            <Typography className={classes.infoPageTypoTitle} style={{ color: "#1E2023" }}>{visitor?.options?.form?.email || obfuscator(visitor?.options?.schedule?.phone)}</Typography>
          </Box>

          {visitor.timezone && (
            <Box style={{ display: "flex" }}>
              <Typography className={classes.infoPageTypoTitle}>{_t("Timezone: ")}&nbsp;</Typography>
              <Typography className={classes.infoPageTypoTitle} style={{ color: "#1E2023" }}>{visitor.timezone}</Typography>
            </Box>
          )}

        </Box>
        <Box style={{ width: "50%" }}>
          <Box className={classes.customerTitle}>
            <Laptop size={12} />
            <Typography className={classes.customerTitleTypo}>{_t("Material & communication mode")}</Typography>
          </Box>

          <Box style={{ display: "flex" }}>
            <Typography className={classes.infoPageTypoTitle}>{_t("Navigator : ")}&nbsp;</Typography>
            <Typography className={classes.infoPageTypoTitle} style={{ color: "#1E2023" }}>{visitor && detectBrowser(visitor?.client_info?.browser)}</Typography>
          </Box>
          <Box style={{ display: "flex" }}>
            <Typography className={classes.infoPageTypoTitle}>{_t("OS : ")}&nbsp;</Typography>
            <Typography className={classes.infoPageTypoTitle} style={{ color: "#1E2023" }}>{visitor?.client_info?.os}</Typography>
          </Box>

          {visitor?.options?.mode && (
            <Box style={{ display: "flex" }}>
              <Typography className={classes.infoPageTypoTitle}>{_t("Communication mode : ")}&nbsp;</Typography>
              <Typography className={classes.infoPageTypoTitle} style={{ color: "#1E2023" }}>{visitor?.options?.mode}</Typography>
            </Box>
          )}

        </Box>
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.bottom}>
        <span className={classes.ctaBottom}>

          {visitor.scheduled_at && (
            <>
              <Button
                disabled={visitor.options?.sms?.live}
                clsName={clsx(classes.ctaButtons, classes.notifyVisitorButton)}
                onClick={() => setNotifyVisitor(true)}
              >
                <SendExclamation />
              </Button>

              <Button clsName={clsx(classes.ctaButtons, classes.removeVisitor)} onClick={() => setOpenRemoveVisitor(true)}>
                <PersonX />
              </Button>
            </>
          )}

          {currentProject.options.cobrowsing?.enabled && (
            <Button
              disabled={visitor.status !== 'ongoing'}
              clsName={clsx(classes.ctaButtons, classes.cobrowsingButton)}
              onClick={() => setOpenCobrowsing(true)}
            >
              <Back />
            </Button>
          )}

          <Button clsName={clsx(classes.ctaButtons, classes.abusiveButton)} onClick={() => setOpenAbusive(true)}>
            <ExclamationTriangleFill />
          </Button>
        </span>

        {currentProject.cart_integration && <Typography className={classes.updateTypo} onClick={handleTriggerCartRefresh}>{_t("Cart update")}</Typography>}
      </Box>
      <Modal
        open={openAbusive}
        onClose={() => setOpenAbusive(false)}
        className={classes.modal}
      >
        <Box className={classes.modalPaper}>
          <AbusiveDeclaration setOpenAbusive={setOpenAbusive} visitor={visitor} />
        </Box>
      </Modal>
      <Modal
        open={openRemoveVisitor}
        onClose={() => setOpenRemoveVisitor(false)}
        className={classes.modal}
      >
        <Box className={classes.modalPaper}>
          <RemoveVisitor setOpenRemoveVisitor={setOpenRemoveVisitor} visitor={visitor} />
        </Box>
      </Modal>
      <Modal
        open={openCobrowsing}
        onClose={() => setOpenCobrowsing(false)}
        className={classes.modal}
      >
        <Box className={clsx(classes.modalPaper, classes.modalCobrowse)}>
          <Cobrowsing setOpenCobrowsing={setOpenCobrowsing} visitor={visitor} />
        </Box>
      </Modal>
      <Modal
        open={notifyVisitor}
        onClose={() => setNotifyVisitor(false)}
        className={classes.modal}
      >
        <Box className={classes.modalPaper}>
          <NotifyVisitor setNotifyVisitor={setNotifyVisitor} visitor={visitor} />
        </Box>
      </Modal>
    </Box>
  );
};

export default VisitorInfos;
