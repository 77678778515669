import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import Box from "@material-ui/core/Box";
import Icon from "coreUiKit/inputs/Icon";
import TextInput from "coreComponents/OutlinedTextField";
import Calendar from "react-bootstrap-icons/dist/icons/calendar4";
import PopoverDatePicker from "./PopoverDatePicker";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";

dayjs.extend(customParseFormat);

const DatePicker = ({ inputOnChange, disabled, date, setError, error, maxDate, minDate, disableHours, disableDate }) => {
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.extend(advancedFormat);

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const isMobile = useSelector(mobileLayoutSelector);
  const parsed = dayjs(date);

  const onDateChanged = (date) => {
    inputOnChange(date);
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  useEffect(() => {
    if (!parsed.isValid() && date !== null) {
      setError(true);
    } else if (error) {
      setError(false);
    }
  }, [date]);

  return (
    <Box
      //display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <TextInput
        style={{ height: isMobile ? "56px" : "40px" }}
        value={date && disableHours ? dayjs(date).format("DD MMM YYYY") : date ? dayjs(date).format("DD MMM[.] YYYY[,] HH[h]mm (z)") : ""}
        onChange={inputOnChange}
        error={error}
        errorMessage={error}
        type={"text"}
        disabled={disabled}
        fullWidth
        readOnly
        onClick={handleClick}
        startAdornment={<Icon IconComponent={Calendar} disabled color="blackMedium" hover="blackMedium" />}
      />

      <PopoverDatePicker disableHours={disableHours} disableDate={disableDate} open={open} onSetDate={onDateChanged} onClickOutside={() => setOpen(false)} anchorEl={anchorEl} date={date} maxDate={maxDate} minDate={minDate} />
    </Box>
  );
};
DatePicker.propTypes = {
  date: PropTypes.string,
  inputOnChange: PropTypes.func,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  minDate: null,
  maxDate: null,
  setError: () => { },
};
export default DatePicker;
