import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { _t } from "i18n";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    background: "black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 10px",
    position: "absolute",
    width: "110px",
    maxHeight: "32px",
    right: "50px",
    top: "5px",
    borderRadius: "8px",
    zIndex: 1,
    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      width: "10px",
      height: "5px",
      top: "11px",
      right: "-7px",
      background: "black",
      transform: "rotate(45deg)",
    },
    "&::before": {
      content: "''",
      display: "block",
      position: "absolute",
      top: "12px",
      right: "-6px",
      width: "15px",
      height: "10px",
      background: "black",
      transform: "rotate(-45deg)",
    },
  },
  typoTooltip: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "11px",
    lineHeight: "130%",
    color: theme.palette.colors.white,
    textAlign: "center",
  },
}));

const Tooltip = ({ message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.tooltip}>
      <Typography className={classes.typoTooltip}>{_t(message)}</Typography>
    </Box>
  );
};

Tooltip.propTypes = {};

Tooltip.defaultProps = {};

export default Tooltip;
