import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";

import map from "lodash/map";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import {
  useCurrentFieldHash,
  ItemMeta,
  SurveyProgressBar,
} from "appComponents/SurveyForms";
import emitter from "core/emitter";
import { EVENT_RESET_SURVEY_FORM } from "core/emitter/events";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
  optionRow: {},
  optionLabel: {
    marginLeft: theme.spacing(2),
  },
  answerType: {},
  itemHelp: {
    marginBottom: theme.spacing(1),
  },
  itemLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "2.13",
    letterSpacing: "0.78px",
  },
  error: {
    color: theme.palette.error.main,
  },
  choiceLabel: {
    cursor: "pointer",
  },
  accordionDetailsRoot: {
    display: "flex",
    flexDirection: "column",
  },
  selectedChoice: {
    backgroundColor: theme.wisemblyColors.orange,
    color: theme.wisemblyColors.black,
  },
  unSelected: {
    backgroundColor: theme.wisemblyColors.white,
  },
  required: {
    color: (props) => (props.error ? theme.palette.error.main : "currentcolor"),
    display: (props) => (props.error ? "block" : "none"),
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.5px",
    marginTop: "30px",
    marginBottom: "15px",
  },
  container: {
    width: "100%",
    height: "100%",
    marginTop: theme.spacing(1.875),
  },
  gridFieldChoices: {
    overflowX: "auto",
    "& > p": {
      height: "75px",
      display: "flex",
      alignItems: "center",
    },
    "& > div": {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      "& > label": {
        margin: 0,
      },
      "& > *": {
        textAlign: "center",
        padding: "15px 0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        minWidth: "100px",
        height: "75px",
        overflow: "hidden",
      },
    },
    "& > div:not(:first-of-type):not(:last-of-type) > label": {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.border.default,
    },
    "& > p:not(:first-of-type):not(:last-of-type)": {
      borderBottomWidth: "1px",
      borderBottomStyle: "solid",
      borderBottomColor: theme.palette.border.default,
    },
    "& > p:last-of-type": {
      marginBottom: "15px",
    },
  },
}));

export const GridFieldHeader = ({ choices }) => {
  return (
    <Box>
      {map(choices, (choice) => (
        <Typography>{choice.label}</Typography>
      ))}
    </Box>
  );
};

GridFieldHeader.propTypes = {
  choices: PropTypes.object,
};

export const EntryChoice = ({ entry, choices, onChoice }) => {
  const classes = useStyles();
  const handleClick = (value) => {
    onChoice({ entry: entry.hash, value });
  };

  return (
    <RadioGroup
      onChange={(event) => {
        handleClick(event.target.value);
      }}
    >
      {map(choices, (choice) => (
        <FormControlLabel
          key={choice.hash}
          value={choice.hash}
          control={<Radio />}
        />
      ))}
    </RadioGroup>
  );
};

EntryChoice.propTypes = {
  entry: PropTypes.string,
  choices: PropTypes.array,
  onChoice: PropTypes.func,
};

export const GridField = ({
  name,

  required,
  options,
  help,
  hash,
}) => {
  const [userChoices, setUserChoices] = useState({});

  const currentFieldHash = useCurrentFieldHash();

  const validate = (value) => {
    let error;
    if (currentFieldHash !== hash) return;
    if (required && (!value || value < 0)) {
      error = _t("field is required");
    }
    return error;
  };

  //field  { name, value, onChange, onBlur } plus any other props passed to directly to useField
  //helpers {setValue, setTouched, setError}
  //const [field, meta, helpers] = useField
  const [field, meta, helpers] = useField({
    name,
    validate,
  });
  const classes = useStyles({ error: meta.touched && meta.error });
  const { setValue } = helpers;

  const handleChoice = ({ entry, value }) => {
    let choices = userChoices;

    if (choices[entry]) {
      if (!choices[entry].includes(value)) {
        choices[entry] = [value];
      }
    } else {
      choices[entry] = [value];
    }
    setUserChoices(choices);
    setValue(choices, true);
  };
  useEffect(() => {
    const callback = () => {
      setUserChoices({});
      setValue({}, false);
    };
    emitter.on(EVENT_RESET_SURVEY_FORM, callback);
    return () => emitter.removeListener(EVENT_RESET_SURVEY_FORM, callback);
  }, [setValue]);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
    >
      <SurveyProgressBar />
      <ItemMeta />

      {required && (
        <Typography className={classes.required}>
          {_t("This field is required")}
        </Typography>
      )}
      <Box className={classes.container} display="flex" flexDirection="row">
        <Box
          className={classes.gridFieldChoices}
          flex={2}
          alignContent={"center"}
        >
          <Typography>&nbsp;</Typography>
          {map(options.entries, (entry) => (
            <Typography>{entry.label}</Typography>
          ))}
        </Box>
        <Box
          className={classes.gridFieldChoices}
          display="flex"
          flexDirection="column"
          flex={5}
        >
          <GridFieldHeader choices={options.choices} />
          {map(options.entries, (entry) => (
            <EntryChoice
              onChoice={handleChoice}
              key={entry.hash}
              entry={entry}
              choices={options.choices}
              value={meta.value && meta.value[entry.hash]}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};
GridField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
  required: PropTypes.bool,
  help: PropTypes.string,
  hash: PropTypes.string,
};

export default GridField;
