import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Viewport = ({ children }) => {
  return (
    <Helmet
      meta={[
        {
          name: "robots",
          content: "noindex, nofollow, noimageindex",
        },
        {
          name: "viewport",
          content:
            " width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no",
        },
      ]}
    >
      {/* To ensure proper rendering and touch zooming for all devices, add the responsive viewport meta tag to your <head> element. */}
      {children}
    </Helmet>
  );
};
Viewport.propTypes = {
  children: PropTypes.any,
};
export default Viewport;
