import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import ModerationBubble from "./ModerationBubble";
import { getUrl } from "utilities/utils/media";
import { QuoteProptypes } from "orm/resources/quote";
import { _t } from "i18n";

const getOriginMessage = (message) => {
  return message.is_mine ? "me" : "others";
};

const getUrlImg = (message) => {
  if (message.media) {
    return getUrl({
      isMobile: false,
      media: message.media,
      thumbnails: message.media.thumbnails,
    });
  } else {
    return "";
  }
};

const Bubble = forwardRef(
  ({ element, handleEditQuote, handleDeleQuote }, ref) => {
    const params = {
      quote: element.quote,
      origin: getOriginMessage(element),
      owner: element.username ? element.username : _t("Anonymous"),
      createdAt: element.created_at,
      isAdmin: false,
      imgPath: getUrlImg(element),
      hash: element.hash,
      handleEditQuote,
      handleDeleQuote,
    };
    return <ModerationBubble {...params} ref={ref} />;
  }
);

Bubble.displayName = "ModerationBubbleLight";

Bubble.propTypes = {
  element: QuoteProptypes,
  ActionElements: PropTypes.node,
};

export default Bubble;
