import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import { ArrowDown } from "react-bootstrap-icons";
import ChevronDown from "react-bootstrap-icons/dist/icons/chevron-down";

const useStyles = makeStyles((theme) => ({
  form: {
    "& .MuiInputBase-input": {
      height: "auto",
      fontSize: 12,
      lineHeight: 1.25,
      color: theme.palette.colors.black,
      "&::placeholder": {
        color: theme.palette.colors.black,
        opacity: 0.5,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 15px 12.5px 10px",
    },
    "& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.default,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
      borderColor: theme.palette.border.default,
    },
  },
  select: {
    borderRadius: theme.spacing(0.625),
    backgroundColor: theme.palette.colors.white,
    boxShadow: theme.palette.shadow,
    height: "32px",
    "& option": {
      color: theme.palette.text.primary,
    },
    "& option:hover": {
      backgroundColor: theme.palette.background.secondaryBox,
    },
    "& .MuiSelect-iconOutlined": {
      color: theme.palette.colors.black,
    },
    "&.Mui-disabled": {
      backgroundColor: "rgba(255, 255, 255, 0.12)",
    },
  },
  label: {
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(1),
  },
  icon: {
    color: theme.palette.colors.grey400,
    marginRight: "10px",
    fontWeight: 700,
  },
}));

const Select = ({ name, value, onChange, label, options, fullWidth, multiple, displayEmpty, native, renderValue, disabled, className, defaultValue }) => {
  const classes = useStyles();

  return (
    <>
      {label && <InputLabel className={classes.label}>{label}</InputLabel>}
      <FormControl variant="outlined" fullWidth={fullWidth} className={clsx(classes.form, className)}>
        <MuiSelect
          autoWidth
          native={native}
          name={name}
          onChange={onChange}
          displayEmpty={displayEmpty}
          multiple={multiple}
          className={clsx(className, { [classes.select]: true })}
          disabled={disabled}
          defaultValue={defaultValue}
          IconComponent={() => <ChevronDown className={classes.icon} size={20} />}
        >

          {options.map((option, key) => {
            if (multiple) {
              return (
                <MenuItem key={key} value={option.value} disabled={option.disabled}>
                  <Checkbox checked={value.includes(option.value)} />
                  <ListItemText primary={option.label || option.title} />
                </MenuItem>
              );
            }

            return (
              <option key={key} value={option.hash || option.value}>
                {option.label || option.title}
              </option>
            );
          })}
        </MuiSelect>
      </FormControl>
    </>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.array,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  native: PropTypes.bool,
  multiple: PropTypes.bool,
  renderValue: PropTypes.func,
  displayEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.object,
};

Select.defaultProps = {
  fullWidth: false,
  multiple: false,
  native: true,
  displayEmpty: false,
  disabled: false,
  className: {},
};

export default Select;
