import React, { useState, useEffect } from "react";
import "./styles/ColorWheel.css"
import PropTypes from "prop-types";
import { _t } from "i18n";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Icon from "coreUiKit/inputs/Icon";
import Gear from "react-bootstrap-icons/dist/icons/gear";
import AddIcon from "react-bootstrap-icons/dist/icons/plus";
import Check from "react-bootstrap-icons/dist/icons/check";
import Remove from "react-bootstrap-icons/dist/icons/x";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import emitter from "utilities/emitter";
import memo, { renderLog } from "core/memo";
import { EVENT_RESET_SIZE_LIST_CHAT } from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.colors.black,
        padding: "15px",
    },
    textTag: {
        marginBottom: "15px",
    },
    color: {
        cursor: "pointer",
        width: "18px",
        height: "18px",
        borderRadius: "100%",
        display: "flex",
    },
    selected: {
        margin: "auto",
        width: "8px",
        height: "8px",
        borderRadius: "100%",
        backgroundColor: theme.palette.colors.white,
    },
    tag: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
    },
    add: {
        backgroundColor: theme.palette.colors.white10,
    },
    icon: {
        margin: "2px",
        fontSize: "14px",
    },
    gear: {
        marginLeft: "8px",
        marginBottom: "-4px",
        paddingTop: "2px",
    },
}));

const TagInList = ({ tag, inQuote, key, setTag, setView, quoteAddTag, quoteRemoveTag }) => {
    const classes = useStyles();
    const [onHover, setOnHover] = useState(false);
    //const [icon, setIcon] = useState(inQuote ? Remove : AddIcon);
    const [icon, setIcon] = useState();

    const onAdd = () => {
        inQuote ? quoteRemoveTag(tag) : quoteAddTag(tag);
        //inQuote = !inQuote; Never do this please
        setIcon(Check);
        emitter.emit(EVENT_RESET_SIZE_LIST_CHAT)
    }

    useEffect(() => {
        if (inQuote) {
            setIcon(Remove);
        } else {
            setIcon(AddIcon)
        }
    }, [inQuote])
    return (
        <Box mb={"10px"}
            key={key}
            className={clsx(classes.tag)}
            onMouseOver={() => setOnHover(true)}
            onMouseLeave={() => { setOnHover(false) }}>
            <Box mr={"8px"} onClick={onAdd} style={{ backgroundColor: tag.color }} className={clsx("Color", classes.color)}>
                {onHover && (<Icon
                    IconComponent={icon}
                    className={classes.icon}
                    color={"black"}
                    hover={"black"}
                />)}
            </Box>
            <Typography mr={"8px"} color="white" variant={"body13"}>
                {tag.name}
            </Typography>
            {onHover && (
                <Box onClick={() => { setTag(tag); setView("modify"); }} className={classes.gear}>
                    <Gear size={14} color="grey" />
                </Box>
            )}
        </Box>
    );
};

TagInList.propTypes = {
    tag: PropTypes.object,
    inQuote: PropTypes.bool,
    key: PropTypes.number,
    setTag: PropTypes.func,
    setView: PropTypes.func,
    quoteAddTag: PropTypes.func,
    quoteRemoveTag: PropTypes.func,
};

export default TagInList;
