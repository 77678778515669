import { useEffect, useState, useRef } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

// const reducer = (state, action) => {
//   switch (action.type) {
//     case "UPDATE_SCREEN":
//       return {
//         ...state,
//         device: action.device,
//         orientation: action.orientation,
//       };
//     default:
//       return state;
//   }
// };

export const useIsMobileLandscape = () => {
  const mobileLandscape = useMediaQuery("@media (max-width:900px) and (orientation: landscape) and (max-height: 600px)");
  return mobileLandscape;
};

//when user switch from portrait to landscape or reverse there is a small
// lapse of time where useMediaQuery can not know the mobile orientation
export const useIsMobile = () => {
  const theme = useTheme();
  const isMobileLandscape = useMediaQuery("@media (max-width:900px) and (orientation: landscape) and (max-height: 600px)");
  const mobileState = useMediaQuery(theme.breakpoints.down("xs")) || isMobileLandscape;
  const [mobileScreen, setMobileScreen] = useState(mobileState);
  useEffect(() => {
    const timeoutId = setTimeout(() => setMobileScreen(mobileState), 100);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [mobileState]);
  return mobileScreen;
};

export const useResizeObserver = (elRef) => {
  //const firstQuery = Object.keys(breakpoints[0])[0]
  //const [breakSize, setBreakSize] = useState(firstQuery)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const observer = useRef(
    new ResizeObserver((entries) => {
      // Only care about the first element, we expect one element ot be watched
      //width height top bottom left right
      //doesnt take into account padding
      const { width, height } = entries[0].contentRect;
      setDimensions({ width, height });
      //setBreakSize(findBreakPoint(breakpoints, width))
    })
  );

  useEffect(() => {
    if (elRef.current) {
      observer.current.observe(elRef.current);
      return () => {
        observer.current.disconnect();
      };
    }
  }, [elRef, observer]);

  return dimensions;
};
