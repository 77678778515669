import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { _t } from "i18n";
import { resourceFetch } from "ressourcesDucks/actions";
import { resourceSelector } from "ressourcesDucks/selectors";
import {
  getKeyword,
  getFetchResultStatusSelector,
} from "applicationDucks/selectors";

import UserResponse from "./UserResponse";
import { ShowSurveyItemAnswersSummary } from "appComponents/Viewers";
import ErrorBoundary from "appComponents/NotFound/ErrorBoundary";
import { InteractivitySurveyViewer } from "appContainers/Viewers";
import {
  ActionPanel,
  SurveyContextProvider,
  isSurveyObjectFull,
} from "appComponents/SurveyForms";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
    width: "100%",
    height: (props) =>
      props.panel == "userResponse"
        ? `calc(100% - ${theme.spacing(9.75)}px)`
        : "100%",
  },

  highlighResult: {
    backgroundColor: theme.palette.background.box,
    color: "rgba(0, 0, 0, 0.74)",
  },
  responseTitle: {
    color: theme.palette.button.default.color.enable,
    minHeight: theme.spacing(10),
  },
  iconTitle: {},

  actionText: {
    color: theme.palette.secondary.contrastText,
  },
  actionBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  detailsPosition: {
    //padding: theme.spacing(2),
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: "50%",
    backgroundColor: theme.wisemblyColors.orange,
  },

  actionPanel: {
    display: (props) => (props.showSwitch ? "flex" : "none"),
    alignItems: "center",
    width: "100%",
    height: theme.spacing(7.25),
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: theme.palette.background.secondaryBox,
    //marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "12px",
    },
  },
  gobalResponses: {},
  surveyItemsAnswers: {
    //maxHeight: `calc(100% - ${theme.spacing(17.5)}px)`,
    flexDirection: "column",
    //maxHeight: `calc(100% - ${theme.spacing(20.5)}px)`,
    height: `calc(100% - ${theme.spacing(7.25)}px)`,
    //overflow: "auto",
    display: (props) => (props.panel == "userResponse" ? "none" : "flex"),
  },
  userResponsePanel: {
    //maxHeight: `calc(100% - ${theme.spacing(17.5)}px)`,
    //maxHeight: `calc(100% - ${theme.spacing(20.5)}px)`,
    maxHeight: (props) =>
      props.showSwitch ? `calc(100% - ${theme.spacing(7.25)}px)` : "100%",
    //overflow: "auto",
    backgroundColor: theme.palette.background.box,
    display: (props) => (props.panel === "form" ? "none" : "flex"),
  },
}));

export const Presentation = ({
  survey,
  keyword,
  resourceFetch,
  surveyHash,
  fetchSurveyStatus,
  withNoAnswer,
}) => {
  const [panel, setPanel] = useState(withNoAnswer ? "form" : "userResponse"); //form/userResponse
  const [showSwitch, setShowSwitch] = useState(false);
  //check if survey id in state
  useEffect(() => {
    if (
      isEmpty(survey) ||
      (survey &&
        !isSurveyObjectFull(survey) &&
        !isEqual(fetchSurveyStatus, "pending") &&
        !isEqual(fetchSurveyStatus, "error"))
    ) {
      setShowSwitch(false);
      resourceFetch("survey", {
        slug: ["event", keyword, "surveys", surveyHash],

        XHR_ID: `survey${surveyHash}`,
      });
    }
  }, [resourceFetch, survey, surveyHash, keyword, fetchSurveyStatus]);

  useEffect(() => {
    if (survey && survey.type === "poll" && !showSwitch && !withNoAnswer) {
      setShowSwitch(true);
    }
  }, [survey, showSwitch]);
  // useEffect(() => {
  //   if (canUserSeeEvaluationAnswer(survey)) {
  //     if (survey && survey.type === "evaluation") {
  //       emitter.emit(EVENT_SHOW_USER_EVALUATION_NOTE, survey.hash);
  //     }
  //   }
  // }, [surveyHash]);
  //for survey.type == form/evaluation show only user response
  const canShowGlobal = survey && survey.type === "poll";
  const classes = useStyles({ canShowGlobal, showSwitch, panel });

  if (
    (isEqual(fetchSurveyStatus, "pending") || isEmpty(fetchSurveyStatus)) &&
    isEmpty(survey)
  ) {
    const panelShouldBe = withNoAnswer ? "form" : "userResponse";
    if (panel !== panelShouldBe) setPanel(panelShouldBe);
    return <CircularProgress />;
  }
  if (isEmpty(survey)) return <div>unable to get the survey</div>;

  const surveyFirstItem = survey && survey.items.toModelArray()[0];

  return (
    <SurveyContextProvider
      surveyHash={survey.hash}
      survey={survey}
      panel={panel}
      onlyForResult={true}
    >
      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        className={clsx("SurveyPresentation", classes.root)}
      >
        <ActionPanel
          panel={panel}
          classes={classes}
          setPanel={setPanel}
          onlyForResult={true}
        />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flex={1}
          mb={1}
          className={clsx(classes.iconTitle, classes.surveyItemsAnswers)}
        >
          <ShowSurveyItemAnswersSummary
            surveyItem={surveyFirstItem || {}}
            survey={survey}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          className={clsx(classes.highlighResult, classes.userResponsePanel)}
        >
          <ErrorBoundary>
            <UserResponse
              survey={survey}
              isThumbnailView={false}
              useOwnPaginator={true}
            />
          </ErrorBoundary>
        </Box>
      </Box>
    </SurveyContextProvider>
  );
};
Presentation.propTypes = {
  survey: PropTypes.object,
  items: PropTypes.array,
  hash: PropTypes.string,
  resourceFetch: PropTypes.func,
  surveyHash: PropTypes.string,
  keyword: PropTypes.string,
  fetchSurveyStatus: PropTypes.string,

  userHash: PropTypes.string,

  withNoAnswer: PropTypes.bool,
};
Presentation.defaultProps = {
  survey: {},
  withNoAnswer: false,
};

const mapStateToProps = (state, ownProps) => {
  return {
    survey: resourceSelector(state, {
      type: "Survey",
      id: ownProps.surveyHash,
    }),
    keyword: getKeyword(state),
    fetchSurveyStatus: getFetchResultStatusSelector(
      state,
      `survey${ownProps.surveyHash}`
    ),

    //items : genericResourcesSelector(state, {type: "Item", filter: (item)=>isEqual})
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resourceFetch: bindActionCreators(resourceFetch, dispatch),
  };
};
//surveyResultViewer
export const SurveyViewer = compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Presentation);
export const surveyViewGenerator = ({ hash, withNoAnswer }) => {
  return {
    type: "vote",
    headerTitle: _t("Results"),
    children: <SurveyViewer surveyHash={hash} withNoAnswer={withNoAnswer} />,
    hash: hash,
  };
};
export const surveyResponderViewGenerator = ({ hash, actionDispatcher }) => {
  return {
    type: "survey",
    headerTitle: "",
    children: (
      <InteractivitySurveyViewer
        surveyHash={hash}
        type="survey"
        layoutMode="full"
        hasFocus={true}
        actionDispatcher={actionDispatcher}
      />
    ),
    hash: hash,
  };
};
