import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { Link } from "react-router-dom";
import { ButtonBase, useTheme } from "@material-ui/core";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { useSelector } from "react-redux";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    width: (props) => (props.isMobile ? "40px" : "32px"),
    height: (props) => (props.isMobile ? "40px" : "32px"),
    borderRadius: "99px",
    background: (props) => props.color || theme.palette.colors.grey200,
  },
}));

const IconButton = ({ link, url, clsName, onClick, children, color, target, rel }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile, color });
  const theme = useTheme();
  if (link) {
    return (
      <Link to={url} target={target} rel={rel} style={{ textDecoration: "none", color: theme.palette.colors.grey700 }} className={classes.root}>
        {children}
      </Link>
    );
  }
  return (
    <ButtonBase onClick={onClick} className={clsx(classes.root, clsName)}>
      {children}
    </ButtonBase>
  );
};

IconButton.propTypes = {};

IconButton.defaultProps = {};

export default IconButton;
