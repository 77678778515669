import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { Select } from "app/views/components/Mui";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import { ButtonBase } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", justifyContent: "space-between", gap: 20, marginBottom: "16px" },
  select: { width: "300px" },
}));

const UrlComponent = ({ setFormValues, formValues, typeValue, urlValue, index }) => {
  const classes = useStyles();

  let handleChange = (index, event) => {
    let newFormValues = [...formValues];
    newFormValues[index][event.target.name] = event.target.value;
    setFormValues(newFormValues);
  };

  let removeFormFields = (index) => {
    let newFormValues = formValues.filter((item, i) => i !== index);
    setFormValues(newFormValues);
  };

  return (
    <Box className={classes.root}>
      <Select
        className={classes.select}
        value={typeValue}
        options={[
          { value: "string", label: "String" },
          { value: "pattern", label: "Pattern" },
        ]}
        name="type"
        onChange={(event) => handleChange(index, event)}
      />
      <Input value={urlValue} name="url" onChange={(event) => handleChange(index, event)} placeholder={"Add an url"} />
      <ButtonBase onClick={() => removeFormFields(index)}>
        <Trash />
      </ButtonBase>
    </Box>
  );
};

UrlComponent.propTypes = {};

UrlComponent.defaultProps = {};

export default UrlComponent;
