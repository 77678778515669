import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class SignatureRequest extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = SignatureRequest;
  }

  setEvent(Event) {
    this.event = Event.id;
  }
}

SignatureRequest.modelName = "SignatureRequest";
SignatureRequest.backendName = "signatureRequest";

//ressource to create and send cust
SignatureRequest.fields = {
  id: attr(),
  hash: attr(),
  label: attr(),
  created_at: attr(),
  started_at: attr(),
  stopped_at: attr(),
  modes: attr(),
  event: fk("Event", "signatureRequests"),
  //user: fk("User", "quotes"),
};

export default SignatureRequest;
