import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "../../../../reusables/Button";
import { useSelector, useDispatch } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { useHistory } from "react-router-dom";
import { getUrl } from "app/views/containers/Application/Routes";
import { getKeyword } from "app/state/ducks/application/selectors";
import { ensureFloat } from "app/liveShopping/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "46px",
    background: theme.palette.colors.white,
    boxShadow: "0px -3px 15px rgba(0, 0, 0, 0.05)",
    flex: "0 auto",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: (props) => (props.isMobile ? "center" : "flex-end"),
  },
  btn: {
    width: (props) => (props.isMobile ? "100%" : "auto"),
    margin: "0 16px",
  },
}));

const Footer = ({ data, hash }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const history = useHistory();
  const keyword = useSelector(getKeyword);

  const ensureFloatOnPrice = (data) => {
    const newData = { ...data };
    if (newData.variants.length > 0) {
      newData.variants.forEach((variant) => {
        variant.price = ensureFloat(variant.price);
        variant.previous_price = ensureFloat(variant.previous_price);
      });
    }
    console.log("newData", newData)
    return newData;
  };

  const handleSubmit = () => {

    const newProduct = resourceFactory.create("Product", { ...ensureFloatOnPrice(data) });
    console.log("newProduct", newProduct)
    dispatch(
      resourceEdit(newProduct, {
        slug: ["event", keyword, "products", hash],
        callback: (error, response) => {
          if (error) {
            emitter.emit(EVENT_FLASH_ERROR, _t("An error has been detected"));
          }

          emitter.emit(EVENT_FLASH_SUCCESS, _t("The product has been updated with success !"));
          history.push(getUrl("otm_live", { keyword }));
        },
      })
    );
  };

  return (
    <Box className={classes.root}>
      <Button clsName={classes.btn} onClick={(e) => handleSubmit(e)}>
        <Typography color={"white"} variant={"bodySB13"}>
          {_t("Save")}
        </Typography>
      </Button>
    </Box>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
