import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import TextInput from "app/liveShopping/backoffice/otm/components/reusables/OutlinedTextField";
import { _t } from "i18n";
import emitter from "core/emitter";
import { EVENT_SEND_NOTIFICATION } from "core/emitter/events";
import Button from "coreUiKit/inputs/Button";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dispatchSession } from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import { resourceCreate } from "ressourcesDucks/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  content: {
    width: "400px",
    paddingTop: "132px",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  btn: { margin: "30px 0" },
  account: {
    display: "flex",
    alignItems: "center",
    paddingTop: "20px",
  },
  link: {
    cursor: "pointer",
  },
  number: { color: theme.palette.colors.blackMedium },
  input: {
    width: "56px",
    height: "65px",
    margin: "0 7.5px",
    "& input[type=text]": {
      "-moz-appearance": "textfield",
      fontSize: "25px",
      fontWeight: "700",
      textAlign: "center",
    },
    "& input[type=text]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=text]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  inputs: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "50px",
  },
}));

const DoubleAuthentication = ({ number, token }) => {
  const classes = useStyles();
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (element, index) => {
    if (isNaN(element.target.value)) return false;
    setOtp([...otp.map((d, i) => (i === index ? element.target.value : d))]);
    // Focus next input
    if (document.getElementById(`otp-input-${index + 1}`)) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  const handleDelete = (event, index) => {
    if (
      event.keyCode == 8 &&
      document.getElementById(`otp-input-${index - 1}`)
    ) {
      document.getElementById(`otp-input-${index - 1}`).focus();
    }
  };

  const hideNumber = () => {
    const numberWithoutPlus = number.replace("+", "");
    const str = numberWithoutPlus;
    const str1 = numberWithoutPlus;
    const formetNum =
      str.replace(/\d{8}$/, "") + str1.replace(/^\d{9}/, " ••••• ");
    return formetNum;
  };

  const getErrorMessage = (error, defaultMessage) => {
    switch (error) {
      case "wrong_email_or_secret":
        return _t("There is a mistake in your email or password.");
      default:
        return defaultMessage;
    }
  };

  const handleSubmit = () => {
    const attributes = { twofa_code: otp.join(""), twofa_token: token };

    dispatch(
      resourceCreate(
        {
          type: "twofa",
          attributes: attributes,
        },
        {
          XHR_ID: "user-confirm-twofa",
          slug: ["authentication", "twofa", "verify"],
          callback: (error, response) => {
            if (error) {
              emitter.emit(EVENT_SEND_NOTIFICATION, {
                type: "alert",
                text: getErrorMessage(error.statusText, error.message),
                button: false,
              });
              return;
            }

            dispatchSession(dispatch, response.resources[0]);
            history.push("/dashboard");
          },
        }
      )
    );
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Typography variant={"title13"}>
          {_t("Enter the verification code")}
        </Typography>
        <Typography variant={"body15"} color="greyDark">
          {_t("We sent a 4-digit code to")}{" "}
          <Box className={classes.number} component={"span"}>
            {hideNumber()}
          </Box>
        </Typography>

        <Box className={classes.inputs}>
          {otp.map((data, index) => {
            return (
              <TextInput
                id={`otp-input-${index}`}
                key={index}
                className={classes.input}
                name={"otp"}
                onChange={(e) => handleChange(e, index)}
                onKeyUp={(e) => handleDelete(e, index)}
                onFocus={(e) => e.target.select()}
                type={"text"}
                value={data}
                required
                inputProps={{ maxLength: "1" }}
              />
            );
          })}
        </Box>

        <Button
          className={classes.btn}
          color="primary"
          fullWidth
          onClick={handleSubmit}
        >
          {_t("Validate")}
        </Button>

        <Box className={classes.account}>
          <Typography color={"greyDark"} variant={"body15"}>
            {_t("Didn't you receive anything ?")}
          </Typography>
          <Box ml={"5px"} />
          <Typography
            className={classes.link}
            color={"primary"}
            variant={"body15"}
            onClick={handleSubmit}
          >
            {_t("Resend code")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

DoubleAuthentication.propTypes = {
  number: PropTypes.string,
  token: PropTypes.string.isRequired,
};

DoubleAuthentication.defaultProps = {
  number: "",
};

export default DoubleAuthentication;