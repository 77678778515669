import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import ChatContainer from "./containers/index.js";
import { Link } from "react-router-dom";
import { getUrl } from "app/views/containers/Application/Routes";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getKeyword } from "app/state/ducks/application/selectors.js";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

  },
}));

const ChatModo = ({ }) => {
  const classes = useStyles();
  const keyword = useSelector(getKeyword);

  return (
    <Box className={classes.root}>
      <Link to={getUrl("otm_fullscreen", { keyword })}>{_t("Acceed to fullscreen")}</Link>
      <ChatContainer />
    </Box>
  )
};

ChatModo.propTypes = {};

ChatModo.defaultProps = {};

export default ChatModo;
