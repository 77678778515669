import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Box } from "@material-ui/core";
import {
  generateHours,
  generateUnavailabilities,
  isTimePassed,
  getPastHours,
  generateOverride
} from "../Calendar/util";
import dayjs from "dayjs";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { currentCalendarSelector } from "app/state/ducks/liveShopping/selectors";
import { resourcesFetch } from "app/state/ducks/ressources/actions";
import timezone from "dayjs/plugin/timezone";
import { _t } from "utilities/i18n";
dayjs.extend(timezone)

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "300px",
    overflowY: "scroll",
    marginBottom: "50px",
    // Hide scrollbar for Chrome, Safari and Opera
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  hour: {
    height: "50px",
    display: "grid",
    placeContent: "center",
    borderBottom: `1px solid ${theme.palette.colors.grey300}`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.colors.primary10,
    },
  },
  selectedHour: {
    backgroundColor: theme.palette.colors.primary,
    color: theme.palette.colors.white,

    "&:hover": {
      backgroundColor: theme.palette.colors.primary + "!important",
    },
  },
  indisponible: {
    backgroundColor: theme.palette.colors.grey + "!important",
    color: theme.palette.colors.white,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
}));

const Time = ({ locale, selectedDate, selectedTime, setSelectedTime, timezone }) => {
  dayjs.locale(locale);

  console.log('timezone', timezone)

  const classes = useStyles();
  const currentCalendar = useSelector(currentCalendarSelector);
  const dispatch = useDispatch();
  const [unavailabilities, setUnavailabilities] = useState([]);

  const hours = generateHours({
    day: dayjs(selectedDate),
    agenda_configuration: currentCalendar.configuration,
    minutes: currentCalendar.slots_duration_minutes,
    timezone,
    overrides: currentCalendar.overrides,
  });

  const getUnavailabilities = () => {
    dispatch(resourcesFetch("calendar", {
      slug: ["calendars", currentCalendar.hash, "unavailabilities"],
      callback: (error, response) => {
        if (error) {
          console.log(error);
          return;
        }

        console.log("response", response)
        setUnavailabilities(response.single.attributes.data.unaviabilities);
      }
    }))
  }

  useEffect(() => {
    getUnavailabilities();
  }, [currentCalendar]);

  console.log("unavailabilities", unavailabilities)

  const indisponibilities = generateUnavailabilities({
    day: dayjs(selectedDate),
    unavailabilities: unavailabilities,
    simultaneous_slots: currentCalendar.simultaneous_slots,
  });

  // scroll to available hour
  useEffect(() => {
    const hour = document.querySelector(`.${classes.selectedHour}`);
    if (hour) {
      hour.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [classes.selectedHour, selectedTime]);

  // scroll to first available hour
  useEffect(() => {
    // get first available hour
    const firstAvailableHour = document.querySelector("#available");
    if (firstAvailableHour) {
      firstAvailableHour.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selectedDate]);

  console.log("indisponibilities", indisponibilities);

  const filteredHours = hours.filter((hour) => {
    if (
      dayjs().isSame(selectedDate, "day") &&
      isTimePassed(hour, currentCalendar.slots_duration_minutes)
    ) return false;

    return true;
  });

  return (
    <Box className={classes.root}>

      {filteredHours.length === 0 && (
        <Box>
          <Box className={classes.hour}>{_t("There are no remaining slots for this day")}</Box>
        </Box>
      )}

      {filteredHours.map((hour, index) => {
        return (
          <Box
            onClick={() => setSelectedTime(hour)}
            className={clsx({
              [classes.hour]: true,
              [classes.selectedHour]: selectedTime === hour,
              [classes.indisponible]: indisponibilities.includes(hour),
            })}
            key={index}
          >
            {hour}
          </Box>
        );
      })}
    </Box>
  );
};

export default Time;
