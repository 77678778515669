import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import MuiCheckbox from "@material-ui/core/Checkbox";
import Check from "react-bootstrap-icons/dist/icons/check";
import Dash from "react-bootstrap-icons/dist/icons/dash";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "16px",
      height: "16px",
      padding: 0,
      border: `1px solid ${theme.palette.colors.greyMedium}`,
      borderRadius: "4px",
      boxSizing: "border-box",
      color: theme.palette.colors.customColor,
      backgroundColor: theme.palette.colors.white,
      transition: ".25s",
      "&:hover": {
        backgroundColor: theme.palette.colors.white,
      },
      "&:active": {
        transform: "scale(0.875)",
      },
      "&.Mui-checked": {
        borderColor: theme.palette.colors.primary,
        backgroundColor: theme.palette.colors.primary,
      },
      "&.Mui-disabled": {
        color: theme.palette.colors.customColor,
        backgroundColor: theme.palette.colors.white,
        opacity: 0.5,
      },
      "&.Mui-checked.Mui-disabled": {
        borderColor: theme.palette.colors.primary,
        backgroundColor: theme.palette.colors.primary,
      },
      "& svg": {
        display: "none",
        minWidth: "16px",
        minHeight: "16px",
        maxWidth: "16px",
        maxHeight: "16px",
      },
      "&.Mui-checked svg": {
        display: "block",
      },
    },
  }),
  {
    name: "OneCheckbox",
  }
);
//controlled checkBox
const Checkbox = ({ checked, onChange, disabled, indeterminate, name, ...props }) => {
  const classes = useStyles({ checked, disabled });
  return (
    <MuiCheckbox
      //onClick={() => !disabled && onChange(!checked)}
      className={classes.root}
      name={name}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      indeterminate={indeterminate}
      checkedIcon={<Check className={classes.icon} />}
      indeterminateIcon={<Dash className={classes.icon} />}
      color="default"
      disableRipple
      {...props}
    />
  );
};
Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  indeterminate: PropTypes.bool,
};
Checkbox.defaultProps = {
  onChange: () => { },
  indeterminate: false,
  disabled: false,
};
export default Checkbox;
