import React from "react";
import PropTypes from "prop-types";

const CustomChevronLeft = ({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill={color}
      {...rest}
    >
      <path
        fill-rule="evenodd"
        d="M11.53,13.47,6.06,8l5.47-5.47a.75.75,0,1,0-1.06-1.06l-6,6a.75.75,0,0,0,0,1.06l6,6a.75.75,0,1,0,1.06-1.06Z"
      />
    </svg>
  );
};

CustomChevronLeft.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomChevronLeft.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomChevronLeft;
