import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { useFormik } from "formik";
import { hasRouteInList } from "liveShopping/backoffice/otm/containers/Router/utils";
import { LANDING_PATH, ONE_LIVE } from "liveShopping/backoffice/otm/containers/Router/routes";
import { resourceCreate } from "ressourcesDucks/actions";
import { getKeyword } from "applicationDucks/selectors";
import ResourceFactory from "orm/resources";
import EyeSlash from "react-bootstrap-icons/dist/icons/eye-slash";
import Eye from "react-bootstrap-icons/dist/icons/eye";
import Icon from "coreUiKit/inputs/Icon";
import TextInput from "app/liveShopping/backoffice/otm/components/reusables/OutlinedTextField";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "coreUiKit/inputs/IconButton";
import emitter from "core/emitter";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { EVENT_SEND_NOTIFICATION } from "core/emitter/events";
import {
  createSession,
  validate,
  TYPE_SIGNIN,
  TYPE_SIGNUP,
  TYPE_VERIFY_MAIL,
} from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import { getVerifiedUsername } from "utilities/username";
import { onPressEnter } from "utilities/utils";
import CustomCrossLg from "liveShopping/assets/icons/CustomCrossLg";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PasswordValidation from "app/liveShopping/backoffice/otm/components/Connect/Register/PasswordValidation";
import sha1 from "crypto-js/sha1";
import { OAUTH_BASE, SIGNIN_APP_ID, SIGNIN_APP_SECRET } from "settings/config";
import Button from "../../reusables/Button";
import Checkbox from "../../reusables/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  account: {
    display: "flex",
    alignItems: "center",
    justifyContent: (props) => (props.forFirewall ? "center" : "flex-start"),
    paddingTop: "8px",
  },
  link: {
    cursor: "pointer",
    color: theme.palette.colors.primary,
  },
  emailSection: { paddingTop: "50px" },
  passwordSection: { paddingTop: "15px" },
  anoucement: {
    display: "flex",
    alignItems: "center",
    padding: "30px 0",
  },
  checkbox: { padding: "0 8px 0 0" },
  linkEvent: {
    textAlign: "center",
    paddingBottom: "30px",
  },
  icon: {
    marginRight: "5px",
  },
  buttons: {
    width: "100%",
    marginBottom: "30px",
  },
  aLink: {
    textDecoration: "none",
  },
  oauthButton: {
    backgroundColor: theme.palette.colors.white50,
    border: "1px solid " + theme.palette.colors.greyMedium,
    color: theme.palette.colors.greyDark,
    "&:hover": {
      backgroundColor: theme.palette.colors.white50,
    },
  },
  dividers: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    margin: "30px 0px",
  },
  marginSide: {
    margin: "0 15px",
  },
  divider: { flex: 1, justifyContent: "center", alignItems: "center" },
  privacy: {
    paddingBottom: "60px",

    [theme.breakpoints.down("xs")]: {
      paddingBottom: "0px",
    },
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: (props) => (props.forFirewall ? "center" : "space-between"),
  },
  logo: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      flexGrow: 1,
      [theme.breakpoints.down("xs")]: {
        marginTop: "48px",
      },
    },
  },
}));

const Register = ({
  handleSetType,
  callback,
  isModal,
  onClose,
  noEvent,
  forFirewall,
}) => {
  const classes = useStyles({ isModal, forFirewall });
  const [typeInput, setTypeInput] = useState("password");
  const [errorRegister, setErrorRegister] = useState(null);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const keyword = useSelector(getKeyword);
  const username = useSelector(getVerifiedUsername);
  const theme = useTheme();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      // optin: true,
    },
    validateOnChange: false,
    validate: (values) => validate(values, TYPE_SIGNUP),
    onSubmit: (values) => {
      const { email, optin, password } = values;
      const user = resourceFactory.create(
        "User",
        Object.assign(
          {
            email,
            optin: false
          },
          username ? { firstname: username } : {}
        )
      );

      let query = [];
      let inject = [{ path: "data.attributes.password", value: password }];
      if (hasRouteInList([ONE_LIVE, LANDING_PATH])) {
        query = [{ key: "keyword", value: keyword }];
        inject.push({ path: "data.attributes.keyword", value: keyword });
      }

      dispatch(
        resourceCreate(user, {
          XHR_ID: "registration",
          slug: ["user"],
          query: query,
          silent: true,
          inject: inject,
          callback: async (error) => {
            if (error) {
              formik.setSubmitting(false);
              if (error.statusCode === 409) {
                setErrorRegister(
                  // prettier-ignore
                  _t("This email address is already linked to an account. You should try to log in.")
                );
                return;
              }

              emitter.emit(EVENT_SEND_NOTIFICATION, {
                type: "alert",
                text: error.statusText,
                button: false,
              });
              return;
            }

            await createSession(dispatch, email, password, callback, () => {
              formik.setSubmitting(false);
            });
            handleSetType(TYPE_VERIFY_MAIL, { email });
            formik.setSubmitting(false);
          },
        })
      );
    },
  });

  useEffect(() => {
    if (formik.values.password.length > 1) {
      document.addEventListener("keydown", (e) =>
        onPressEnter(e, () => formik.handleSubmit())
      );
    }
    return () => {
      document.removeEventListener("keydown", (e) =>
        onPressEnter(e, () => formik.handleSubmit())
      );
    };
  }, [formik.values.password]);

  return (
    <Box className={classes.root} flexDirection={"column"}>
      <Box>
        <Box className={classes.title}>
          <Typography variant={"title13"}>{_t("Sign up")}</Typography>
          {isModal && (
            <IconButton color={"ghost"} onClick={() => onClose()}>
              <CustomCrossLg size={20} color={"grey"} />
            </IconButton>
          )}
        </Box>
        {forFirewall ? (
          <Box className={classes.account}>
            <Typography color={"greyDark"} variant={"body15"}>
              {_t("You have to login to join the event.")}
            </Typography>
          </Box>
        ) : (
          <Box className={classes.account}>
            <Typography color={"greyDark"} variant={"body15"}>
              {_t("Already have an account?")}
            </Typography>
            <Box ml={"5px"} />
            <Typography
              className={classes.link}
              color={"primary"}
              variant={"body15"}
              onClick={() => handleSetType(TYPE_SIGNIN)}
            >
              {_t("Sign in")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.emailSection}>
        <Typography variant={"bodySB13"}>{_t("E-mail")}</Typography>
        <Box mb={1} />
        <TextInput
          name={"email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          type={"text"}
          fullWidth
          size={"medium"}
          required
          placeholder={_t("Enter your e-mail")}
          errorMessage={formik.errors?.email || errorRegister}
          error={formik.errors?.email?.length || errorRegister?.length}
        />
      </Box>
      <Box className={classes.passwordSection}>
        <Typography variant={"bodySB13"}>{_t("Password")}</Typography>
        <Box mb={1} />
        <TextInput
          name={"password"}
          value={formik.values.password}
          onChange={formik.handleChange}
          type={typeInput}
          fullWidth
          size={"medium"}
          endAdornment={
            <Icon
              IconComponent={typeInput === "password" ? EyeSlash : Eye}
              size={16}
              color="greyDark"
              onClick={() =>
                setTypeInput(typeInput === "password" ? "text" : "password")
              }
            />
          }
          placeholder={_t("Enter your password")}
        />
        <PasswordValidation password={formik.values.password} />
      </Box>
      {/* <Box className={classes.anoucement}>
        <Box className={classes.checkbox}>
          <Checkbox
            checked={formik.values.optin}
            onChange={formik.handleChange}
            name={"optin"}
          />
        </Box>
        <Typography className={classes.text} variant={"body13"}>
          {_t("I agree to receive update announcements by e-mail")}
        </Typography>
      </Box> */}
      <Button
        clsName={classes.btn}
        w100
        onClick={formik.handleSubmit}
        disabled={formik.isSubmitting}
      >
        {_t("Create an account")}
      </Button>

      {forFirewall && (
        <Box mt={1} mb={1} className={classes.account}>
          <Typography color={"greyDark"} variant={"body15"}>
            {_t("Already have an account?")}
          </Typography>
          <Box ml={"5px"} />
          <Typography
            className={classes.link}
            color={"primary"}
            variant={"body15"}
            onClick={() => handleSetType(TYPE_SIGNIN)}
          >
            {_t("Sign in")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

Register.propTypes = {
  handleSetType: PropTypes.func,
  forFirewall: PropTypes.bool,
};

Register.defaultProps = {
  handleSetType: () => { },
  forFirewall: false,
};

export default Register;