import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { currentWizSelector } from "ressourcesDucks/selectors";
import { getAnonymousUserParticipation } from "applicationDucks/selectors";
import { hasUsername, getVerifiedUsername } from "utilities/username";

import { withUser } from "hoc/User";
import { _t } from "core/i18n";

const getAnonymousProfile = () => {
  return _t("You are in anonymous profile");
};

export const getConnectedProfile = (context, user, username = null) => {
  const profile = username ? username : user.name;
  switch (context) {
    default:
    case "user":
      {/* prettier-ignore */}
      return _t("You act as {username}", { username: profile });
    case "contribution":
      return (
        <>
          <span className="content">{_t("You act as:")}</span>
          <span className="username">{profile}</span>
        </>
      );
  }
};

const getUsernameMandatory = () => {
  return _t("You must fill in a username to intervene");
};

const VisibilityState = ({
  user,
  session,
  participationMode,
  forceUsername,
  allowUsername,
  anonymousUserParticipation,
  context,
  username,
}) => {
  if (participationMode === "anonymous") {
    return getAnonymousProfile();
  }

  if (participationMode === "connected") {
    if (session.isAnon()) {
      return _t("You must be logged in to intervene");
    }

    return getConnectedProfile(context, user);
  }

  // Choice mode

  if (forceUsername) {
    if (session.isAnon() && !hasUsername()) {
      return getUsernameMandatory();
    }

    return getConnectedProfile(context, user, username);
  }

  if (anonymousUserParticipation) {
    return getAnonymousProfile();
  }

  // has chosen to participate on his own behalf
  if (session.isAnon() && (!allowUsername || !hasUsername())) {
    if (!allowUsername) {
      return _t("You must be logged in to intervene");
    }

    return getUsernameMandatory();
  }

  return getConnectedProfile(context, user, username);
};

VisibilityState.propTypes = {
  user: PropTypes.object,
  session: PropTypes.object,
  participationMode: PropTypes.string,
  forceUsername: PropTypes.bool,
  allowUsername: PropTypes.bool,
  anonymousUserParticipation: PropTypes.bool,
  context: PropTypes.string,
};

VisibilityState.defaultProps = {
  context: "user",
};

const mapStateToProps = (state) => {
  const { participation_mode, force_username, allow_username } =
    currentWizSelector(state) || {};

  return {
    participationMode: participation_mode,
    forceUsername: force_username,
    allowUsername: allow_username,
    anonymousUserParticipation: getAnonymousUserParticipation(state),
    username: getVerifiedUsername(),
  };
};

export default compose(withUser, connect(mapStateToProps))(VisibilityState);
