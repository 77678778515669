import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import CollectionPlay from "react-bootstrap-icons/dist/icons/collection-play";
import PlusCircle from "react-bootstrap-icons/dist/icons/plus-circle";
import Tab from "./Tab"

const useStyles = makeStyles((theme) => ({
 root: {
   display: "flex",
   justifyContent: "space-around",
   width: "100%",
   background: "white",
   flex: "0 auto",
 },
}));


const index = ({}) => {
  const classes = useStyles();


  const TabList = [
    {
      "url": "/lives",
      "icon" : <CollectionPlay />,
      "title": _t("My Lives"),
      "iconSize": 20
    },
    {
      "url": "/live/create",
      "icon": <PlusCircle />,
      "iconSize": 30
    },
    // {
    //   "url": "/replays",
    //   "icon" : <SkipBackward />,
    //   "title": _t("My Replays"),
    //   "iconSize": 20
    // },
    // {
    //   "url": "/profil",
    //   "icon" : <Person />,
    //   "title": _t("My Profil"),
    //   "iconSize": 20
    // }
  ]

return (
<Box className={classes.root}>
  {TabList.map((t) => <Tab {...t} />)}
</Box>
);
};


index.propTypes = {};


index.defaultProps = {};


export default index;