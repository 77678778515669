import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useDispatch, useSelector } from "react-redux";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import { devicesSelector, publishersSelector, publishStateSelector } from "../../containers/Visio/VisioVonage/selectors";
import { destroyPublisher, initPublisher, mute, setSource } from "../../containers/Visio/VisioVonage/actions";
import clsx from "clsx";
import VideoCard from "./VideoCard";
import PreviewFooter from "./PreviewFooter";
import DeviceSelector from "./DeviceSelector";
import { isUser } from "utilities/access";
import { isReady } from "app/state/ducks/liveShopping/actions";
import { Divider, Typography } from "@material-ui/core";
import MicrophoneLevel from "./MicrophoneLevel";
import { desktopLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { getDevices } from "../../containers/Visio/VisioVonage/actions";

export const useJoinStageStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    background: theme.palette.colors.white,
    position: "absolute",
    zIndex: 100,
    "& .Title, & .Description": {
      textAlign: "center",
    },
    "& .videoContainer": {
      width: props => !props.isAdmin ? "138px" : "340px",
      height: props => !props.isAdmin ? "235px" : "191.25px",
      background: theme.palette.colors.blackLight,
      borderRadius: "8px",
      "& .showHover": {
        /* On your own video publisher we dont want to see anything */
        display: "none",
      },
      "& .label": {
        display: "none",
      },
    },
  },
  stream: {
    height: "100%",
    width: "100%",
  },
  preview: {
    position: "relative",
    marginBottom: "30px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
  },
  autorisationBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    paddingBottom: "30px",
  },
  settings: {
    // marginBottom: "30px",
    width: props => !props.isAdmin ? "70%" : "340px",
    "& > .MuiBox-root:not(:last-child)": {
      marginBottom: "10px",
    },
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    "& > .MuiBox-root:not(:last-child)": {
      marginBottom: "15px",
    },
  },
  previewFooter: {
    position: "absolute",
    bottom: 10,
    right: 30,
    left: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiIconButton-root": {
      margin: "0 5px",
    },
  },
  title: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginBottom: "30px",
    color: theme.palette.colors.black,
  },
  typoJoin: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "100%",
    color: theme.palette.colors.white,
  },
  buttonMini: {
    height: "4Opx",
    padding: "12px 24px",
    marginTop: "16px",
  },
  info: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.black,
    width: "300px",
    textAlign: "center",
    marginBottom: "20px",
  },
}));

const CheckSetup = ({ setOpenConfig, checkMode, preview }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const isMobile = !isDesktop
  const isAdmin = isUser("user+");
  const dispatch = useDispatch();
  const classes = useJoinStageStyles({ isMobile, isAdmin });
  const devices = useSelector(devicesSelector);

  const { publishAudio, publishVideo } = useSelector(publishStateSelector);
  const { videoPublisher } = useSelector(publishersSelector);

  const devicesTypes = [];
  const mode = checkMode || "video";

  useEffect(() => {
    if (!isAdmin && !preview) return;
    if (!devices.length) return;
    dispatch(initPublisher("video", "stream-own_video_preview"));
  }, [devices]);

  useEffect(() => {
    if (devices.length) return;
    dispatch(getDevices(mode));
  }, [devices])

  const handleIsReady = () => {
    if (isAdmin || preview) {
      dispatch(isReady(true))
      dispatch(destroyPublisher("video"));
    }

    if (!isAdmin && setOpenConfig) setOpenConfig(false);

  };

  if (isAdmin || mode === "video")
    devicesTypes.push({ kind: "videoInput", label: _t("Video source") });

  if (isAdmin || ["video", "audio"].includes(mode))
    devicesTypes.push({ kind: "audioInput", label: _t("Audio source") });






  console.log("devicesTypes", devicesTypes)
  return (
    <Box className={classes.root}>
      {isAdmin && <Typography className={classes.title}>{_t("Video settings")}</Typography>}
      {isAdmin && <Typography className={classes.info}>{_t("please choose your devices to be able to start calling your visitors, if you want to change your device please refresh the page")}</Typography>}
      {(isAdmin || preview) && <Box className={clsx("VideoCard", classes.preview)}>

        <VideoCard
          key={"own_video_preview"}
          streamId={"own_video_preview"}
          mode={mode}
          stream={{
            hasAudio: publishAudio,
            hasVideo: publishVideo,
          }}
        />
        <PreviewFooter
          mode={mode}
          callback={(type, publish) => {
            dispatch(mute(type, publish));
          }}
        />
      </Box>}

      <Box className={clsx("Settings", classes.settings)}>
        {devices?.length > 0 &&
          devicesTypes.map(({ kind, label }) => {
            return (
              <DeviceSelector
                key={kind}
                devices={devices}
                kind={kind}
                label={label}
                setSourceDevice={({ type, value }) => {
                  console.log('update source', type, value)
                  dispatch(setSource(type, value));
                }}
              />
            )
          })}
        <MicrophoneLevel publisher={videoPublisher} permissions={{ microphone: true }} dots={10} />
      </Box>

      {(isAdmin || !preview) && (
        <Box className={clsx("Actions", classes.actions)}>
          <Button disabled={devices.length === 0} onClick={handleIsReady} clsName={classes.buttonMini}>
            <Typography className={classes.typoJoin}>{isAdmin ? _t("Choose these settings and proceed") : _t("Update settings")}</Typography>
          </Button>
        </Box>
      )}
      <Divider />
    </Box>
  );
};

CheckSetup.propTypes = {};

CheckSetup.defaultProps = {};

export default CheckSetup;
