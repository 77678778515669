import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import PropTypes from "prop-types";
import CustomShareIcon from "app/liveShopping/assets/icons/CustomShareIcon";
import { useSelector } from "react-redux";
const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "99px",
    display: (props) => (props.fullScreen ? "none" : "flex"),
    justifyContent: "center",
    alignItems: "center",
    marginTop: "5px",
    height: 35,
    width: 35,
  },
}));

const Share = ({ iconColor, onClick }) => {
  const fullScreen = useSelector((state) => state.liveShopping.fullScreen);
  const classes = useStyles({ fullScreen });

  return (
    <ButtonBase className={classes.root} onClick={onClick}>
      <CustomShareIcon color={iconColor ?? "#FFF"} />
    </ButtonBase>
  );
};

Share.propTypes = {};

Share.defaultProps = {
  fullScreen: PropTypes.bool,
};

export default Share;
