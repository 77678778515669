import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { _t } from "core/i18n";
import { useDispatch } from "react-redux";
import { setViewedProduct } from "liveShoppingDucks/actions";
import Infos from "./Infos";
import Pulse from "../Pulse";
import ProductCardCTA from "../ProductCardCTA";

const useStyles = makeStyles((theme) => ({
  card: {
    alignItems: "center",
    padding: "10px 20px",
    background: theme.palette.colors.white,
    border: "1px solid #F0F0F4",
    borderRadius: "10px",
    order: 0,
    margin: "15px 15px",
    minHeight: "200px",
    maxHeight: "250px",
    height: "auto",
    width: "190px",
    padding: "10px, 20px, 10px, 20px",
    cursor: "pointer",
    position: "relative",
    display: "grid",
    gridTemplateRows: "100px 1fr",
  },
  img: {
    width: "190px",
    height: "100px",
    backgroundImage: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginBottom: 15,
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
}));

const DesktopCard = ({ product, focusedProducts }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ backgroundImage: product?.variants[0]?.picture_url || "" });
  const handleProduct = () => dispatch(setViewedProduct(product));
  const focusedProduct = focusedProducts.find((element) => element.hash === product.hash);
  return (
    <Box className={classes.card} key={product.id} onClick={handleProduct}>
      {focusedProduct && <Pulse />}
      <Box className={classes.img} />
      <Box style={{
        width: "100%",
        height: "100%",
        paddingLeft: "15px",
        paddingRight: "15px",
        paddingBottom: "15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
        <Infos product={product} />
        <ProductCardCTA product={product} large />
      </Box>
    </Box>
  );
};

DesktopCard.propTypes = {
  backgroundImage: PropTypes.string,
};

DesktopCard.defaultProps = {};

export default DesktopCard;
