import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import { emit } from "utilities/liveshopping-sdk";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "35px",
    height: "35px",
    cursor: "pointer",
    marginTop: "5px",
  },
}));

const Quit = ({ iconColor }) => {
  const classes = useStyles();

  return (
    <ButtonBase onClick={() => emit("QUIT")} className={classes.root}>
      <XLg size={25} color={iconColor || "#FFF"} />
    </ButtonBase>
  );
};

Quit.propTypes = {};

Quit.defaultProps = {};

export default Quit;
