import emitter from "core/emitter";

import { EVENT_RESOURCE_UNSET } from "core/emitter/events";

export default function handleDeleteCascade(resource) {
  if (!resource.onCascade) {
    return;
  }

  const resourcesToRemove = resource.onCascade();

  if (!resourcesToRemove || !resourcesToRemove.length) {
    return;
  }

  resourcesToRemove.forEach((resource) => {
    resource.delete();
    emitter.emit(EVENT_RESOURCE_UNSET, resource.locator);
  });
}
