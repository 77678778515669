import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";

import { _t } from "i18n";
import Box from "@material-ui/core/Box";

import ContactMailIcon from "@material-ui/icons/ContactMail";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getKeyword } from "applicationDucks/selectors";
import { getUserSession } from "core/session";
import { userSelector } from "ressourcesDucks/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  iconBG: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    backgroundColor: theme.palette.background.secondaryBox,
    color: theme.palette.secondary.main,
  },
  icon: {
    fontSize: theme.spacing(5),
  },
}));
const UserNotInvitedWall = ({ keyword, user }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      justifyContent="flex-start"
      alignItems="center"
      className={clsx("UserNotInvitedWall", classes.root)}
    >
      <Box display="flex" mt={3}>
        <Typography>{_t("Have you been invited?")}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        className={classes.iconBG}
        my={4}
      >
        <ContactMailIcon className={classes.icon} />
      </Box>
      <Box display="flex" mb={2}>
        <Typography>
          {_t("Sorry but you must be invited to join #{keyword}.", { keyword })}
        </Typography>
      </Box>

      <Box display="flex" mt={2}>
        <Typography>{_t("Are you using the correct email address")}</Typography>
      </Box>
      <Box className={classes.contactUs}>
        <Typography>{`(${user.email}) ?`}</Typography>
      </Box>
    </Box>
  );
};

UserNotInvitedWall.propTypes = {
  keyword: PropTypes.string,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const keyword = getKeyword(state);
  const session = getUserSession();
  const { user_id } = session;

  return {
    keyword,
    user: userSelector(state, user_id),
  };
};

export default connect(mapStateToProps)(UserNotInvitedWall);
