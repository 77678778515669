import React from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
    //marginLeft: theme.spacing(2.25),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: 0.5,
  },
  bulletPoint: {
    fontSize: 18,
    "&::marker": {
      color: theme.palette.secondary.main,
    },
  },
}));

const UserTextSurveyItemResult = ({ surveyItemAnswer }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <li className={classes.bulletPoint}>{surveyItemAnswer.value}</li>
    </Box>
  );
};
UserTextSurveyItemResult.propTypes = {
  surveyItem: PropTypes.object,
  surveyItemAnswer: PropTypes.object,
};

export default UserTextSurveyItemResult;
