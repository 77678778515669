import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { desktopLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import clsx from "clsx";
import { markdownToHTML } from "core/utils/markdown";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";

const defaultBubble = {
  display: "inline-flex",
  padding: "10px 15px",
  width: "100%",
  borderRadius: "8px",
};

const useStyles = makeStyles((theme) => ({
  titleSent: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "100%",
    textTransform: "uppercase",
    color: "#FFFFFF",
    background: "rgba(0, 0, 0, 0.75)",
    borderRadius: "4px",
    padding: "8px",
    display: "inline-flex",
    marginRight: "4px",
  },
  titleReceives: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "130%",
    color: (props) => (props.isDesktop && !props.isAdmin ? "#1E2023" : theme.palette.colors.white),
    display: "inline-flex",
    marginRight: "4px",
  },

  bubbleParticipant: {
    ...defaultBubble,
    background: (props) => (props.isDesktop ? "rgba(0, 0, 0, 0.03)" : "rgba(0, 0, 0, 0.1)"),
  },
  adminBubble: {
    ...defaultBubble,
    background: (props) => (props.isDesktop ? "rgba(0, 0, 0, 0.75)" : "rgba(0, 0, 0, 0.5)"),
  },
  typoParticipant: {
    color: (props) => (props.isDesktop && !props.isAdmin ? "black" : theme.palette.colors.white),
    margin: "auto 0",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  message: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "14px",
    margin: 0,
    "& p:last-child": {
      margin: 0,
      wordBreak: "break-word",
    },
  },
  adminPicto: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    backgroundImage: (props) => (props.avatar ? `url("${props.avatar}")` : "none"),
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

const replaceURLs = (quote) => {
  if (!quote) return;

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return quote.replace(urlRegex, function (url) {
    var hyperlink = url;
    if (!hyperlink.match("^https?://")) {
      hyperlink = "http://" + hyperlink;
    }
    return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + "</a>";
  });
};

const Bubble = ({ quote, origin, owner, isAdmin, url }) => {
  const isMyMessage = ["me", "replyByMe"].includes(origin);
  const isDesktop = useSelector(desktopLayoutSelector);
  const { logo_paths } = useSelector(currentWizSelector);
  const classes = useStyles({ isDesktop, avatar: logo_paths.mobile, isAdmin });
  const windowFeatures = "width=400,height=600,menubar=no,toolbar=no,location=no";

  return (
    <Box
      className={clsx({
        [classes.bubbleParticipant]: origin === "me" || origin === "others",
        [classes.adminBubble]: isAdmin,
      })}
    >
      {isAdmin && <img width="36" height="36" style={{ borderRadius: "99px", marginRight: "8px" }} src={logo_paths.mobile} />}
      <Typography variant="body1" component="div" className={clsx({ [classes.typoParticipant]: origin === "me" || origin === "others" })}>
        <Typography
          variant="body1"
          component="div"
          className={clsx({
            [classes.titleSent]: origin === "me",
            [classes.titleReceives]: origin === "others",
          })}
        >
          {isMyMessage ? owner : `${owner}`}
        </Typography>

        {isAdmin && url ? (
          <div id="msg" className={classes.message}>
            {quote}
            <Button onClick={() => window.open(url, "_blank", windowFeatures)}>
              {_t("Open")}
            </Button>
          </div>
        ) : <div
          id="msg"
          className={classes.message}
          dangerouslySetInnerHTML={{
            __html: markdownToHTML(replaceURLs(quote)),
          }}
        />}


      </Typography>
    </Box>
  );
};

Bubble.propTypes = {};

Bubble.defaultProps = {};

export default Bubble;
