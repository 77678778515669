import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import {
  useSurveyContextState,
  useFormIntroState,
  useSurveyContextDispatcher,
} from "appComponents/SurveyForms";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tabs: {
    minHeight: 0,
    padding: theme.spacing(0.625),
    borderRadius: 999,
    backgroundColor: theme.palette.background.secondaryBox,

    // "& .MuiTabs-indicator": {
    //
    // },
  },
  wrapped: {
    minWidth: 0,
    minHeight: 0,
    padding: theme.spacing(0.875, 1.5),
    opacity: 1,
    color: theme.palette.secondary.main,
    transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    zIndex: 1,
    "&:first-of-type": {
      marginRight: theme.spacing(0.625),
    },
  },
  tabSelected: {
    color: theme.palette.secondary.contrastText,
  },
  tabIndicator: {
    height: "100%",
    borderRadius: 999,
    zIndex: 0,
    minWidth: "calc(50% - 5px)",
  },
  switchText: {
    fontSize: "10px",
    lineHeight: "100%",
    textTransform: "none",
  },
}));

const ActionPanel = ({ panel, setPanel, classes, onlyForResult }) => {
  const surveyContext = useSurveyContextState();
  const surveyContextDispatch = useSurveyContextDispatcher();
  const showIntro = useFormIntroState(surveyContext.hash);
  const userSetPanel = (value) => {
    surveyContextDispatch({ type: "SET_PANEL", panel: value });
    setPanel(value);
  };
  const localClasses = useStyles();
  useEffect(() => {
    if (showIntro && surveyContext.panel !== panel && !onlyForResult) {
      setPanel(surveyContext.panel || "form");
    }
  }, [showIntro, panel, setPanel, surveyContext, onlyForResult]);

  const handleChange = (event, newValue) => {
    newValue === 1 ? userSetPanel("userResponse") : userSetPanel("form");
  };
  const formLabel = (
    <Typography className={localClasses.switchText}>
      {_t("Global results")}
    </Typography>
  );
  const userResponseLabel = (
    <Typography className={localClasses.switchText}>
      {_t("My responses")}
    </Typography>
  );
  const tabSwitch = (
    <Tabs
      classes={{
        root: clsx(localClasses.tabs, classes.actionPanelContent),
        indicator: localClasses.tabIndicator,
      }}
      value={panel === "form" ? 0 : 1}
      onChange={handleChange}
      aria-label="actionPanel"
    >
      <Tab
        wrapped
        disableRipple
        label={formLabel}
        classes={{
          selected: localClasses.tabSelected,
          wrapped: localClasses.wrapped,
        }}
      />
      <Tab
        wrapped
        disableRipple
        label={userResponseLabel}
        classes={{
          selected: localClasses.tabSelected,
          wrapped: localClasses.wrapped,
        }}
      />
    </Tabs>
  );
  return (
    <Box className={clsx(classes.actionPanel, classes.panelRoot)}>
      {tabSwitch}
    </Box>
  );
};

ActionPanel.propTypes = {
  panel: PropTypes.string,
  setPanel: PropTypes.func,
  classes: PropTypes.object,
  onlyForResult: PropTypes.bool,
};
ActionPanel.defaultProps = {
  onlyForResult: false,
};

export default ActionPanel;
