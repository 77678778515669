import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useDispatch, useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import Form from "./Form";
import Preview from "./Preview";
import clsx from "clsx";
import Header from "./Header";
import Footer from "./Footer";
import { displayTabBar } from "app/state/ducks/liveShopping/actions";
import { useLocation, useParams } from "react-router-dom";
import { productsSelector } from "app/state/ducks/ressources/selectors";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    position: "relative",
    background: "#FFF",
    zIndex: 8,
  },
  mobileLayout: {
    overflow: "auto",
    flex: "1",
  },
  desktopLayout: {
    overflow: "hidden",
    display: "flex",
    padding: "10px",
    width: "100%",
    height: "100%",
    gap: "48px",
  },
}));

const index = () => {
  const classes = useStyles();
  const isMobile = useSelector(mobileLayoutSelector);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const products = useSelector(productsSelector);
  const { hash } = useParams();

  const product = products.find((product) => product.hash === hash);

  useEffect(() => {
    if (isMobile) {
      dispatch(displayTabBar(false));
    }
  }, []);

  return (
    <Box className={classes.root}>
      <Header />
      <Box
        className={clsx({
          [classes.mobileLayout]: isMobile,
          [classes.desktopLayout]: !isMobile,
        })}
      >
        <Box order={isMobile ? 1 : 2}>
          <Preview data={data} />
        </Box>
        <Form setData={setData} product={product} />
      </Box>
      <Footer data={data} />
    </Box>
  );
};

index.propTypes = {};

index.defaultProps = {};

export default index;
