import React from "react";
import { _t } from "i18n";
import { Redirect } from "react-router-dom";
import { isUser } from "utilities/access";
import { getUrl } from "app/views/containers/Application/Routes";

const Welcome = ({}) => {
  if (isUser("user+")) return <Redirect to={getUrl("board")} push={true} />;

  return <Redirect to="/connect" />;
};

Welcome.propTypes = {};

Welcome.defaultProps = {};

export default Welcome;
