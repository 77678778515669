import React, { useEffect, useState } from 'react';
import Appointment from './Appointment';
import { visitorReady } from 'app/liveShopping/backoffice/oto/Component/Live/service';
import { useDispatch, useSelector } from 'react-redux';
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import ResourceFactory from 'app/state/orm/resources/resourceFactory';
import Choice from './Onboard/Choice';
import { currentProjectSelector } from 'app/state/ducks/application/selectors';
import VisioPrepare from './Onboard/VisioPrepare';
import AppointmentReady from './AppointmentReady';
import { currentCalendarSelector } from 'app/state/ducks/liveShopping/selectors';

const Onboard = () => {
  const dispatch = useDispatch();

  const project = useSelector(currentProjectSelector);
  const currentVisitor = useSelector(currentVisitorSelector);
  const currentCalendar = useSelector(currentCalendarSelector);

  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const [takeAppointment, setTakeAppointment] = useState(false);
  const [prepare, setPrepare] = useState(false);
  const [mode, setMode] = useState(null);
  const [ready, setReady] = useState(false);

  const showChoice = !project?.options?.skip_choice_screen && currentCalendar?.active;

  const onReady = () => {
    const newVisitor = resourceFactory.create("Visitor", {
      hash: currentVisitor.hash,
      token: currentVisitor.token,
      options: {
        ...currentVisitor.options,
        mode,
      }
    });

    visitorReady(dispatch, newVisitor);
  };

  useEffect(() => {
    if (!ready) return;
    onReady();
  }, [ready]);

  if (takeAppointment)
    return <Appointment onCancel={() => setTakeAppointment(false)} />;

  if (showChoice && !prepare && !currentVisitor.scheduled_at)
    return <Choice onChoose={(mode) => mode === 'schedule' ? setTakeAppointment(true) : setPrepare(true)} />;

  if (!prepare && currentVisitor.scheduled_at)
    return <AppointmentReady setPrepare={setPrepare} />

  return <VisioPrepare
    onReady={(mode) => {
      setMode(mode)
      setReady(true)
    }}
    onCancel={() => setPrepare(false)}
  />;
};

export default Onboard;
