import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import SmsOutlinedIcon from "@material-ui/icons/SmsOutlined";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import FormatListNumberedOutlinedIcon from "@material-ui/icons/FormatListNumberedOutlined";
import LiveHelpOutlinedIcon from "@material-ui/icons/LiveHelpOutlined";
import StarHalfOutlinedIcon from "@material-ui/icons/StarHalfOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import images from "images";

const useStyles = makeStyles((theme) => ({
  oval: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.wisemblyColors.white,
    borderRadius: "50%",
    //padding: theme.spacing(0.5),
    width: (props) =>
      props.layoutMode === "split"
        ? "41px"
        : props.isThumbnailView
        ? "54px"
        : "65px",
    minWidth: (props) =>
      props.layoutMode === "split"
        ? "41px"
        : props.isThumbnailView
        ? "54px"
        : "65px",
    height: (props) =>
      props.layoutMode === "split"
        ? "41px"
        : props.isThumbnailView
        ? "54px"
        : "65px",
    marginRight: (props) => (props.isThumbnailView ? 0 : "30px"),
    padding: (props) =>
      props.layoutMode === "split" ? "10px 9px 9px 10px" : "17.5px",
    [theme.breakpoints.down("xs")]: {
      height: "41px",
      width: "41px",
      minWidth: "41px",
      margin: "3px 11px 12px 0",
      padding: "10px 9px 9px 10px",
    },
    "& svg": {
      fontSize: 30,
    },
  },
}));

const FormIcon = ({ hide, type, isThumbnailView, layoutMode }) => {
  const classes = useStyles({ isThumbnailView, layoutMode });
  let result;
  switch (type || "poll") {
    case "text":
      result = <SmsOutlinedIcon />;
      break;
    case "unique":
      result = <images.QuestionMark />;
      break;
    case "rating":
      result = <StarHalfOutlinedIcon />;
      break;
    case "image":
      result = <ImageOutlinedIcon />;
      break;
    case "grid":
      result = <LiveHelpOutlinedIcon />;
      break;
    case "ranking":
      result = <FormatListNumberedOutlinedIcon />;
      break;
    case "multiple":
      result = <images.QuestionMark />;
      break;
    case "poll":
      result = <ThumbUpAltIcon fontSize="large" htmlColor="white" />;
      break;
    case "form":
      result = <LiveHelpOutlinedIcon />;
      break;
    case "evaluation":
      result = <images.IconTrophy />;
      break;
    case "quote":
      result = <ChatOutlinedIcon />;
      break;
    default:
      result = <Box></Box>;
  }

  return (
    <Box
      display={!hide ? "flex" : "none"}
      justifyContent="center"
      alignItems="center"
      className={classes.oval}
    >
      {result}
    </Box>
  );
};
FormIcon.propTypes = {
  isThumbnailView: PropTypes.bool,
  type: PropTypes.string,
  layoutMode: PropTypes.string,
  hide: PropTypes.bool,
};
FormIcon.defaultProps = {
  isThumbnailView: false,
  hide: false,
};
export default FormIcon;
