import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resourceEdit, resourceDelete } from "ressourcesDucks/actions";
import * as Sentry from "@sentry/react";
import isEmpty from "lodash/isEmpty";
import { genericResourcesSelector } from "ressourcesDucks/selectors";
import { currentWizSelector } from "ressourcesDucks/selectors";
import { getKeyword } from "applicationDucks/selectors";
import ResourceFactory from "orm/resources";
import globalStore from "appStore";
import { resourceCreate } from "ressourcesDucks/actions";
import { useIsUser } from "coreContainers/hooks/userHooks";

export const useTagsEnabled = () => {
  const { tags_enabled } = useSelector(currentWizSelector);
  return tags_enabled;
};

export const useTags = () => {
  const isAdmin = useIsUser("admin+");
  const event = useSelector(currentWizSelector);
  const tags = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "tag",
      filter: (tag) => tag.event && tag.event.keyword === event.keyword,
    })
  );
  const tagsEnabled =
    event.public_tags_enabled || (event.tags_enabled && isAdmin);
  const tagsOptions = useMemo(() => {
    let list =
      !isEmpty(tags) &&
      tags.map((elem) => {
        return {
          name: elem.name,
          hash: elem.hash,
          color: elem.color,
          type: "checkbox",
        };
      });

    return list || [];
  }, [tags.length]);

  return [tags, tagsOptions, tagsEnabled];
};

export const useTagManager = (element) => {
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();
  //const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const state = globalStore.getState();
  const resourceFactory = new ResourceFactory(state);

  const handleStateTag = useCallback(
    (action, name, color, callback, errorCallback) => {
      switch (action) {
        case "create": {
          const newTag = resourceFactory.create("Tag", { name, color });
          newTag.setEvent(event);

          dispatch(
            resourceCreate(newTag, {
              slug: ["event", event.keyword, "tags"],

              callback: (error, response) => {
                if (error) {
                  //// TODO: error display
                  Sentry.captureException(error);
                  errorCallback && errorCallback();
                  return;
                }

                callback && callback();
                return response;
              },
            })
          );
          break;
        }
        case "edit": {
          dispatch(
            resourceEdit(Object.assign(element, { name, color }), {
              slug: ["event", event.keyword, "tags", element.hash],
              patch: ["name", "color"],

              callback: (error, response) => {
                if (error) {
                  //// TODO: error display
                  Sentry.captureException(error);
                  errorCallback && errorCallback();
                  return;
                }

                callback && callback();
                return response;
              },
            })
          );
          break;
        }
        case "delete": {
          dispatch(
            resourceDelete(element, {
              slug: ["event", event.keyword, "tags", element.hash],
              silent: true,

              callback: (error, response) => {
                if (error) {
                  //// TODO: error display
                  Sentry.captureException(error);
                  errorCallback && errorCallback();
                  return;
                }
                callback && callback();
                return response;
              },
            })
          );
          return;
        }
      }
    },
    [element, event, dispatch]
  );

  return handleStateTag;
};

export const useQuoteManager = (inQuote) => {
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();

  //const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const state = globalStore.getState();
  const resourceFactory = new ResourceFactory(state);

  const handleStateQuote = useCallback(
    //remove useForce
    (action, tag, callback, errorCallback) => {
      let newQuote = {};
      //create tag if necessary
      switch (action) {
        case "add": {
          let previoustags = inQuote.tags_list.map((prev) => ({
            type: "tag",
            id: prev,
          }));
          previoustags.push({ id: tag.id, type: "tag" });
          newQuote = resourceFactory.create("Custom", { tags: previoustags });
          break;
        }
        case "remove": {
          let filteredList = inQuote.tags_list.filter(
            (prev) => prev !== tag.id
          );
          filteredList = filteredList.map((elem) => ({
            id: elem,
            type: "tag",
          }));
          newQuote = resourceFactory.create("Custom", { tags: filteredList });
          break;
        }
      }

      dispatch(
        resourceEdit(newQuote, {
          slug: ["event", keyword, "quotes", inQuote.hash],
          silent: true,
          patch: ["tags"],
          callback: (error, response) => {
            if (error) {
              //// TODO: error display
              Sentry.captureException(error);
              errorCallback && errorCallback();
              return;
            }
            callback && callback();
            return response;
          },
        })
      );
    },
    [keyword, dispatch, inQuote]
  );

  return handleStateQuote;
};
