import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import IconButton from "app/liveShopping/backoffice/otm/components/reusables/IconButton";
import ThreeDots from "react-bootstrap-icons/dist/icons/three-dots";
import Plus from "react-bootstrap-icons/dist/icons/plus";
import { ClickAwayListener, Modal } from "@material-ui/core";
import Add from "./Add";
import PencilSquare from "react-bootstrap-icons/dist/icons/pencil-square";
import Edit from "./Edit";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import { useDispatch, useSelector } from "react-redux";
import { resourceDelete } from "app/state/ducks/ressources/actions";
import { groupsSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    maxWidth: "none",
    minWidth: "106px",
    backgroundColor: "black",
    padding: "8px 10px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "400px",
    gap: "10px",
  },
  line: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    cursor: "pointer",
  },
  edit: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
  clone: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
  delete: {
    color: theme.palette.colors.error,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
}));

const EditDeleteOptions = ({ hashGroup, title, urls }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);
  const groups = useSelector(groupsSelector);

  const group = groups.find((g) => g.hash === hashGroup);

  const handleDelete = () => {
    dispatch(
      resourceDelete(group, {
        slug: ["groups", hashGroup],
        batch: true,
        body: group,
        callback: (error, response) => {
          if (response) {
            console.log(response);
          } else {
            console.log(error);
          }
        },
      })
    );
  };
  return (
    <>
      <IconButton onClick={() => setOpen(!open)}>
        <ThreeDots />
      </IconButton>

      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box className={classes.root}>
            <Box className={classes.line} onClick={handleOpenEdit}>
              <PencilSquare size={15} color={"white"} /> <Typography className={classes.edit}>{_t("Edit")}</Typography>
            </Box>
            <Box className={classes.line} onClick={handleDelete}>
              <Trash size={15} color={"red"} /> <Typography className={classes.delete}>{_t("Delete")}</Typography>
            </Box>
          </Box>
        </ClickAwayListener>
      )}

      <Modal open={openEdit} onClose={handleCloseEdit} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Edit hashGroup={hashGroup} title={title} setOpen={setOpenEdit} urls={urls} />
      </Modal>
    </>
  );
};

EditDeleteOptions.propTypes = {};

EditDeleteOptions.defaultProps = {};

export default EditDeleteOptions;
