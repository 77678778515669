import React, { useEffect, useRef, useState } from "react";
import ModerationPanel from "../components/ModerationPanel";
import { useSelector } from "react-redux";
import { moderationSelector } from "applicationDucks/selectors";
import { useQuotesForModeration } from "../moderationHooks";
import { MODERATION_COLUMNS, MODERATION_TABS, MODERATION_TABS_MODERATION } from "constants";
import { NOTIFY_MODERATION, EVENT_RESET_MODERATION_UNREAD_INDICATOR, EVENT_MODERATION_LIST_IS_SCROLLABLE, EVENT_CHAT_MODERATION_DISPLAY_INDICATOR_NEW_MESSAGES } from "utilities/emitter/events";
import map from "lodash/map";
import last from "lodash/last";
import emitter from "utilities/emitter";
import _filter from "lodash/filter";

const ModerationPanelContainer = () => {
  //const classes = useStyles();
  const moderationState = useSelector(moderationSelector);
  const previous = useRef(0);
  const lastWizRank = useRef(0);
  const scrollableList = useRef(false);
  const [quotes, loadMore, total] = useQuotesForModeration({ moderation: moderationState.activated });
  const [countNewQuotes, setCountNewQuotes] = useState(0);
  useEffect(() => {
    //init lastWizRank
    if (lastWizRank.current === 0 && quotes.length > 0) {
      lastWizRank.current = last(quotes)?.wiz_rank;
    }
    if (lastWizRank.current) {
      const newSize = _filter(quotes, (element) => element.wiz_rank > lastWizRank.current).length;

      if (newSize !== countNewQuotes) {
        setCountNewQuotes(newSize);
      }
    }

    const refreshWizRank = () => {
      const newLastWizRank = last(quotes)?.wiz_rank;
      lastWizRank.current = newLastWizRank;
      setCountNewQuotes(0);
    };

    emitter.on(EVENT_RESET_MODERATION_UNREAD_INDICATOR, refreshWizRank);
    return () => {
      emitter.off(EVENT_RESET_MODERATION_UNREAD_INDICATOR, refreshWizRank);
    };
  }, [quotes.length]);

  useEffect(() => {
    // @TODO add inactif condition
    const newLastWizRank = last(quotes)?.wiz_rank;
    if (countNewQuotes > 0 && lastWizRank.current !== 0 && lastWizRank.current < newLastWizRank) {
      emitter.emit(EVENT_CHAT_MODERATION_DISPLAY_INDICATOR_NEW_MESSAGES, {
        count: countNewQuotes,
      });
    }
  }, [countNewQuotes]);
  const elementsHashes = map(quotes, (elem) => elem.hash);
  useEffect(() => {
    if (previous.current !== quotes.length && moderationState.mode === MODERATION_TABS && moderationState.currentTab !== MODERATION_TABS_MODERATION) {
      emitter.emit(NOTIFY_MODERATION, quotes.length);
    }
    previous.current = quotes.length;
  }, [quotes.length]);
  useEffect(() => {
    const callback = () => {
      emitter.emit(NOTIFY_MODERATION, 0);
    };
    const scrollableCallback = (scrollable) => {
      scrollableList.current = scrollable;
    };
    emitter.on(EVENT_RESET_MODERATION_UNREAD_INDICATOR, callback);
    emitter.on(EVENT_MODERATION_LIST_IS_SCROLLABLE, scrollableCallback);
    return () => {
      emitter.off(EVENT_RESET_MODERATION_UNREAD_INDICATOR, callback);
      emitter.off(EVENT_MODERATION_LIST_IS_SCROLLABLE, scrollableCallback);
    };
  }, []);
  //every time i switch on moderation tab reset notification
  useEffect(() => {
    if (moderationState.currentTab === MODERATION_TABS_MODERATION) {
      if (!scrollableList.current) {
        emitter.emit(NOTIFY_MODERATION, 0);
      }
    }
  }, [moderationState.currentTab]);
  return <ModerationPanel messages={elementsHashes} fetchData={loadMore} columns={moderationState.activated && moderationState.mode === MODERATION_COLUMNS} />;
};
// ModerationPanelContainer.propTypes = {
//
// };
export default ModerationPanelContainer;
