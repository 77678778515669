import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { sourcesSelector } from "../../containers/Visio/VisioVonage/selectors";
import FilterMenu from "coreUiKit/inputs/Menu";

const DeviceSelector = ({ devices, kind, label, setSourceDevice }) => {
  const sourceSelector = useSelector(sourcesSelector);
  const selectedSource = kind === "videoInput" ? sourceSelector.videoSource : sourceSelector.audioSource;

  const availableSources = devices
    .filter((device) => device.kind === kind)
    .map(({ label, deviceId }) => {
      return { title: label, hash: deviceId };
    });

  console.log('DeviceSelector', devices, kind, selectedSource, availableSources);

  return (
    <FilterMenu
      key={`deviceSelector_${kind}`}
      defaultOption={{
        hash: selectedSource,
      }}
      title={label}
      id={kind}
      options={availableSources}
      badgeContent={0}
      onFilterChange={setSourceDevice}
    />
  );
};

DeviceSelector.propTypes = {
  devices: PropTypes.array.isRequired,
  kind: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  setSourceDevice: PropTypes.func.isRequired,
};

export default DeviceSelector;
