import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { getTimeRemaining } from "utilities/utils/date";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.secondaryBox,
    color: theme.palette.colors.black,
  },
  DateBlock: {
    backgroundColor: "transparent",
    width: theme.spacing(25),
  },
  DurationBlock: {
    backgroundColor: "transparent",
    width: theme.spacing(25),
  },
  ParticipantBlock: {
    backgroundColor: "transparent",
    width: theme.spacing(25),
  },
  dateDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "20px",
  },
  dateDetailsBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "24px",
    height: "36px",
    borderRadius: "8px",
    padding: "15px",
    marginRight: "5px",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
  icon: {
    fontSize: theme.spacing(8),
  },
  label: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "100%",
    color: theme.palette.colors.white,
  },
  divider: {
    backgroundColor: theme.palette.colors.grey,
  },
  title: {
    fontSize: "16px",
    lineHeight: "1.56",
    letterSpacing: "0.78px",
    marginTop: theme.spacing(1.75),
  },
  value: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "100%",
    color: theme.palette.colors.white,
  },
  block: {
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
}));

const Timer = ({ onEnded }) => {
  const classes = useStyles();
  const wiz = useSelector(currentWizSelector);

  const [remaining, setRemaining] = useState(getTimeRemaining(wiz.live_start));

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = getTimeRemaining(wiz.live_start);
      setRemaining(remaining);

      if (remaining.total < 0) {
        clearInterval(interval);
        onEnded && onEnded();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const infos = [
    { label: _t("days"), value: remaining.days, hide: remaining.days === 0 },
    {
      label: _t("hours"),
      value: remaining.hours,
      hide: remaining.days === 0 && remaining.hours === 0,
    },
    { label: _t("minutes"), value: remaining.minutes },
    { label: _t("seconds"), value: remaining.seconds, hide: remaining.days !== 0 },
  ];

  if (remaining.total < 0) {
    onEnded && onEnded();
    return null;
  }

  return (
    <Box display="flex" flex={1} className={classes.block} flexDirection="row">
      {infos.map((info) => {
        if (info.hide) return null;

        const sNumber = info.value.toString();
        let output = [];
        for (let i = 0, len = sNumber.length; i < len; i += 1) {
          if (len === 1) output.push(0);
          output.push(+sNumber.charAt(i));
        }

        const result = output.map((el, i) => (
          <Box key={i} className={classes.dateDetailsBlock}>
            <Typography className={classes.value}>{el}</Typography>
          </Box>
        ));

        return (
          <Box key={info.label} className={classes.dateDetails}>
            <Box mb={1} display="flex">
              {result}
            </Box>
            <Box display="flex">
              <Typography className={classes.label}>{info.label}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

Timer.propTypes = {
  onEnded: PropTypes.func,
};

export default Timer;
