import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class SurveyAnswer extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = SurveyAnswer;
  }

  setSurvey(Survey) {
    this.survey = Survey.id;
  }
  setUser(User) {
    this.user = User.id;
  }
  // addItemAnswer(answer) {
  //   this.itemAnswers = this.itemAnswers || [];
  //   this.itemAnswers.push(answer);
  // }
}

SurveyAnswer.modelName = "SurveyAnswer";
SurveyAnswer.backendName = "surveyAnswer";

SurveyAnswer.fields = {
  id: attr(),
  hash: attr(),
  answered_at: attr(),
  is_anonymous: attr(),
  username: attr(),
  score: attr(),
  completed_time: attr(),
  via: attr(),
  //i think we should remove this relation. surveys.answers can be too huge, replace this relation by a simple attribute
  survey: fk("Survey", "answers"),
  user: fk("User", "answers"),
};

export default SurveyAnswer;
