export class NetworkError extends Error {
  name = "NetworkError";

  constructor(
    {
      extra = null,
      status = 0,
      statusCode = status,
      statusText = "UNKNOWN",
      responseData = null,
      message = statusCode
        ? `The server answered with an HTTP ${statusCode} (${statusText})`
        : "Unexpected network error",
    } = {},
    more = null
  ) {
    super(message);

    this.statusCode = statusCode;
    this.statusText = statusText;

    this.extra = extra;
    this.more = more;
    this.responseData = responseData;
  }
}

export class ReponseParseError extends Error {
  name = "ReponseParseError";
}

export class ApiTokenExpiredError extends Error {
  name = "ApiTokenExpiredError";
  statusCode = 403;

  constructor(message, ...rest) {
    super(message, ...rest);
    this.statusText = message;
  }
}

export class ApiRefreshTokenExpiredError extends Error {
  name = "ApiRefreshTokenExpiredError";
  statusCode = 403;

  constructor(message, ...rest) {
    super(message, ...rest);
    this.statusText = message;
  }
}
