import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { _t } from "i18n";
import ViewList from "react-bootstrap-icons/dist/icons/view-list";
import { cartIsOpenSelector, catalogIsOpenSelector, currentChapterSelector, mobileLayoutSelector, replayDurationSelector, replayIsOpenSelector } from "app/state/ducks/liveShopping/selectors";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentChapter, setOpenCart, setOpenCatalog, setOpenReplay } from "app/state/ducks/liveShopping/actions";
import emitter from "utilities/emitter";
import { REPLAY_TIMESTAMP_UPDATE } from "utilities/emitter/events";
import { currentWizReplaySelector } from "app/state/ducks/ressources/selectors";
import { getNextChapter, getPercent, getCurrentChapterIndex, getCurrentChapter } from "../../Replay/replayUtilities";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 20px",
    borderRadius: "99px",
    background: theme.palette.colors.white,
    position: "relative",
    overflow: "hidden",
  },
  typo: {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.primary,
    marginLeft: "5px",
  },
  progressContainer: {
    width: "90%",
    height: 5,
    position: "absolute",
    bottom: 0,
    background: theme.palette.colors.grey,
  },
  line: {
    width: (props) => props.progress + "%",
    height: 5,
    position: "absolute",
    bottom: 0,
    background: theme.palette.colors.error,
  },
}));

const ReplayChaptersButton = ({}) => {
  const mobileLayout = useSelector(mobileLayoutSelector);
  const dispatch = useDispatch();
  const catalogIsOpen = useSelector(catalogIsOpenSelector);
  const cartIsOpen = useSelector(cartIsOpenSelector);
  const replayIsOpen = useSelector(replayIsOpenSelector);
  const currentChapterHash = useSelector(currentChapterSelector);
  const replayDuration = useSelector(replayDurationSelector);

  const { chapters } = useSelector(currentWizReplaySelector);

  const [progress, setProgress] = useState(0);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const classes = useStyles({ progress });

  const computePercent = () => {
    const nextChapter = getNextChapter(chapters, currentChapter);
    const percent = getPercent(currentTimestamp, currentChapter, nextChapter, replayDuration);
    return percent;
  };

  useEffect(() => {
    emitter.on(REPLAY_TIMESTAMP_UPDATE, setCurrentTimestamp);
    return () => emitter.off(REPLAY_TIMESTAMP_UPDATE, setCurrentTimestamp);
  }, []);

  // GET CURRENT CHAPTER BY HASH
  const currentChapter = getCurrentChapter(chapters, currentChapterHash);

  useEffect(() => {
    const percent = computePercent();
    setProgress(Math.max(3, percent));
  }, [currentChapter]);

  // GET PERCENT && SET THE NEXT CHAPTER
  useEffect(() => {
    const nextChapter = getNextChapter(chapters, currentChapter);

    const percent = computePercent();
    setProgress(Math.max(3, percent));

    // auto-change current chapter if needed and we have not _playerSyncedWithChapter lock
    if (currentTimestamp >= nextChapter?.timestamp && window._playerSyncedWithChapter) {
      dispatch(setCurrentChapter(nextChapter.hash));
    }
  }, [currentTimestamp]);

  const openReplay = () => {
    if (mobileLayout) {
      dispatch(setOpenReplay(true));
    } else {
      if (cartIsOpen) {
        dispatch(setOpenCart(false));
        dispatch(setOpenCatalog(false));
        dispatch(setOpenReplay(true));
      } else {
        dispatch(setOpenCatalog(!catalogIsOpen));
        dispatch(setOpenReplay(!replayIsOpen));
      }
    }
  };

  if (!chapters || !chapters.length) return null;

  return (
    <ButtonBase className={classes.root} onClick={openReplay}>
      <ViewList />
      <Typography className={classes.typo}>
        {_t("Chapter")} {getCurrentChapterIndex(chapters, currentChapter) + 1}
      </Typography>
      <Box className={classes.progressContainer}>
        <Box className={classes.line} />
      </Box>
    </ButtonBase>
  );
};

ReplayChaptersButton.propTypes = {};

ReplayChaptersButton.defaultProps = {};

export default ReplayChaptersButton;
