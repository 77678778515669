import { Backdrop, Box, ButtonBase, Fade, Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ThreeDotsVertical from "react-bootstrap-icons/dist/icons/three-dots-vertical";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "utilities/i18n";
import { formatPrice } from "app/liveShopping/utils";
import dayjs from "dayjs";
import Bubble from "../Live/Chat/Bubble";
import Eye from "react-bootstrap-icons/dist/icons/eye";
import { useSelector } from "react-redux";
import { currentProjectSelector } from "app/state/ducks/application/selectors";

const useStyles = makeStyles((theme) => ({
  seeMore: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    borderRadius: "99px",
    backgroundColor: theme.palette.colors.grey200,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: "800px",
    height: "400px",
  },
  container: {
    display: "flex",
    gap: "20px",

  },
  title: {
    fontSize: "20px",
    lineHeight: "130%",
    fontWeight: 700,
    color: "#000000",
    marginBottom: "20px",
  },
  field: {
    display: "flex",
    gap: "5px",
    marginBottom: "10px",
  },
  titleField: {
    fontWeight: 700,
  },
  left: {
    width: "50%",
  },
  right: {
    width: "50%",
  },
  chat: {
    width: "100%",
    maxHeight: "338px",
    padding: " 10px 20px",
    overflowY: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    }
  },
}));

const Detail = ({ item }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const currentProject = useSelector(currentProjectSelector);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const waitingDiff = item?.handled_at ? dayjs(item?.handled_at).unix() - dayjs(item.created_at).unix() : dayjs(item?.terminated_at).unix() - dayjs(item.created_at).unix();

  const waitingTime_minutes = Math.floor(waitingDiff / 60);
  const waitingTime_seconds = waitingDiff - waitingTime_minutes * 60;

  const conversationDiff = dayjs(item.terminated_at).unix() - dayjs(item.handled_at).unix();
  const conversationTime_hours = Math.floor(conversationDiff / 3600);
  const conversationTime_minutes = Math.floor(conversationDiff / 60);
  const conversationTime_seconds = conversationDiff - conversationTime_minutes * 60;

  useEffect(() => {
    const chat = document.getElementById("LiveboutiqueChatHistory");
    if (!chat) return;
    chat.scrollTop = chat?.scrollHeight;
    // scroll to bottom
  }, []);

  return (
    <>
      <ButtonBase onClick={handleOpen} className={classes.seeMore}><Eye /></ButtonBase>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box className={classes.paper}>

            <Box className={classes.container}>
              <Box className={classes.left}>
                <Typography className={classes.title}>{_t("Details")}</Typography>
                <Box className={classes.field}>
                  <Typography className={classes.titleField}>{_t("Created Date")}</Typography>
                  <Typography>{item?.created_at}</Typography>
                </Box>
                <Box className={classes.field}>
                  <Typography className={classes.titleField}>{_t("Email")}</Typography>
                  <Typography>{JSON.parse(item.options)?.form?.email || _t("not specified")}</Typography>
                </Box>
                <Box className={classes.field}>
                  <Typography className={classes.titleField}>{_t("Handled by")}</Typography>
                  <Typography>{(item?.vendor_firstname || " ") + " " + (item?.vendor_lastname || " ")}</Typography>
                </Box>
                <Box className={classes.field}>
                  <Typography className={classes.titleField}>{_t("Waiting Time")}</Typography>
                  <Typography>{waitingTime_minutes + "m " + waitingTime_seconds + "s"}</Typography>
                </Box>
                <Box className={classes.field}>
                  <Typography className={classes.titleField}>{_t("Conversation Time")}</Typography>
                  <Typography>{isNaN(conversationTime_hours && conversationTime_minutes && conversationTime_seconds) ? "--" : conversationTime_hours + "h " + conversationTime_minutes + "m " + conversationTime_seconds + "s"}</Typography>
                </Box>
                <Box className={classes.field}>
                  <Typography className={classes.titleField}>{_t("NPS")}</Typography>
                  <Typography>{item?.nps_score || 0}</Typography>
                </Box>
                {currentProject.cart_integration && (
                  <Box className={classes.field}>
                    <Typography className={classes.titleField}>{_t("Total price")}</Typography>
                    <Typography>{formatPrice(Math.round(JSON.parse(item.cart)?.total_price / 100), "EUR") || 0}</Typography>
                  </Box>
                )}
                {currentProject.cart_integration && (
                  <Box className={classes.field}>
                    <Typography className={classes.titleField}>{_t("items in cart")}</Typography>
                    <Typography>{JSON.parse(item.cart)?.item_count || 0}</Typography>
                  </Box>
                )}
              </Box>
              <Box className={classes.right}>
                <Typography className={classes.title}>{_t("Conversation")}</Typography>
                <Box className={classes.chat} id="LiveboutiqueChatHistory">
                  {JSON.parse(item.conversation)?.length === 0 && <Typography>{_t("No conversation")}</Typography>}
                  {JSON.parse(item.conversation)?.map(({ user, message, date }, index) => {
                    return (
                      <Box key={index}>
                        <Bubble publishedAt={dayjs.unix(date || 0)} message={message} scope={user} />
                      </Box>
                    )
                  })}
                </Box>

              </Box>
            </Box>


          </Box>
        </Fade>
      </Modal>
    </>
  )
};

export default Detail;