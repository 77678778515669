import React, { useReducer, useEffect, useMemo } from "react";
import { _t } from "i18n";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { ActionButton as Button } from "appComponents/Mui";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import clone from "lodash/clone";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "auto",
  },
  close: {
    display: "flex",
    justifyContent: "flex-end",
  },
  content: {
    paddingTop: "30px",
    width: "100%",
  },
  inputRoot: {
    color: theme.wisemblyColors.grey,
    fontSize: "14px",
    fontWeight: 400,
    marginTop: "5px",
  },
  input: {
    padding: "15px",
  },
  labelRoot: {
    color: theme.wisemblyColors.darkish,
    fontSize: "14px",
    letterSpacing: "0.78px",
    marginBottom: "9px",
    fontWeight: 600,
  },
  labelAsterisk: {
    color: theme.wisemblyColors.pastelRed,
  },
  notchedOutline: {
    borderColor: theme.wisemblyColors.lightGray,
  },
  title: {
    color: theme.wisemblyColors.darkish,
    lineHeigh: "35px",
    letterSpacing: "1.22px",
    fontSize: "25px",
    fontWeight: "bold",
    textAlign: "center",
    fontStyle: "center",
  },
  formFields: {
    width: "100%",
  },
}));
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET_ERROR":
      return {
        ...state,
        formError: null,
        invalidForm: false,
        errors: {},
      };
    case "SET_FIELD":
      return {
        ...state,
        ...action.payload,
      };
    case "UPDATE_FIELD":
      return {
        ...state,
        [action.name]: action.value,
        invalidForm: false,
        errors: {},
      };

    default:
      return state;
  }
};

const GenericFormBuilder = ({
  fields,
  actionButton,
  handleSubmit,
  loading,
}) => {
  const fieldNames = useMemo(
    () => fields.map((item) => item.name),
    [JSON.stringify(fields)]
  );

  const [state, dispatch] = useReducer(reducer, {
    invalidForm: false,
    errors: {},
    openModal: true,
  });

  useEffect(() => {
    fields.forEach((item) => {
      dispatch({ type: "SET_FIELD", payload: { [item.name]: item.initValue } });
    });
  }, []);
  const classes = useStyles();
  const handleMailChange = (event) => {
    let errors = state.errors;
    const target = event.target;
    const value = target.value;
    const hasDomain = /(\.\w{2,}){1,2}$/.test(value);
    const isValid = event.target.validity.valid && hasDomain;
    dispatch({ type: "UPDATE_FIELD", name: target.name, value });
    dispatch({
      type: "SET_ERROR",
      payload: {
        invalidForm: true,
        errors: {
          ...errors,
          [target.name]: !isValid,
        },
      },
    });
  };
  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    dispatch({ type: "UPDATE_FIELD", name: target.name, value });
  };
  const checkFormValidity = () => {
    let errors = clone(state.errors);
    let resetError = true;
    fieldNames.forEach((elem, index) => {
      if (elem === "email" && errors.email) {
        resetError = false;
      } else if (isEmpty(state[elem]) && fields[index].required) {
        resetError = false;

        dispatch({
          type: "SET_ERROR",
          payload: {
            invalidForm: true,
            errors: {
              ...errors,
              [elem]: true,
            },
          },
        });
        errors[elem] = true;
      }
    });
    if (resetError) {
      dispatch({ type: "RESET_ERROR" });
    }

    return resetError;
  };
  const localeHandleSubmit = () => {
    const isValid = checkFormValidity(state);
    try {
      if (isValid) {
        handleSubmit(state);
      }
    } catch (e) {
      console.error("error", e);
      dispatch({
        type: "SET_FIELD",
        payload: { formError: _t("something happened") },
      });
    }
  };
  const handleDisableButton = () => {
    const { errors } = state;
    let shouldDisable = false;

    fieldNames.forEach((elem, index) => {
      if ((errors[elem] && fields[index].required) || loading) {
        shouldDisable = true;
      }
    });

    return shouldDisable;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={clsx("GenericFormBuilder", classes.root)}
      mb={1}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={classes.content}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={classes.formFields}
        >
          {fields.map((field) => {
            return (
              <Box
                key={field.name}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                mt={3}
                width="100%"
              >
                <InputLabel
                  error={state.errors[field?.name]}
                  required={field.required}
                  classes={clsx({
                    [classes.labelRoot]: true,
                    [classes.labelAsterisk]: field.required,
                  })}
                >
                  {field.label}
                </InputLabel>
                <OutlinedInput
                  type={field.type}
                  fullWidth
                  required={field.required}
                  id={field.name}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.input,
                    notchedOutline: classes.notchedOutline,
                  }}
                  error={state.errors[field?.name]}
                  name={field.name}
                  onChange={
                    field.type === "email" ? handleMailChange : handleChange
                  }
                  value={state[field.name]}
                  variant={field.variant}
                  placeholder={field.placeholder}
                  disabled={field.disabled}
                />
              </Box>
            );
          })}

          <Box
            display="flex"
            justifyContent={actionButton.position || "center"}
            alignItems="center"
            mt={4}
            width="100%"
            flexDirection="row"
          >
            <Button
              variant="contained"
              inactiveColor="primary"
              onClick={localeHandleSubmit}
              disabled={handleDisableButton()}
              size={actionButton.size || "medium"}
              fullWidth={actionButton.fullWidth}
              title={actionButton.title}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
GenericFormBuilder.propTypes = {
  errors: PropTypes.object,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  fields: PropTypes.array,
  actionButton: PropTypes.object,
};
GenericFormBuilder.defaultProps = {
  fields: [],
  actionButton: {},
};

export default GenericFormBuilder;
