import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, useTheme } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  nps: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    border: "1px solid #D6D8DC",
    borderRadius: "4px",
    margin: "24px 0",
    overflow: "auto",
  },
  number: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "14px 11px",
    width: "40px",
    height: "40px",
    cursor: "pointer",
    "&:hover": {
      background: "#F7F7F9",
    },
  },
}));

const Rate = ({ setRating, rating }) => {
  const classes = useStyles();
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const theme = useTheme();
  const setBorder = (number) => {
    if (number > 0 && number < 10) {
      return { borderRight: "1px solid #F0F0F4" };
    }
  };
  const setBackground = (number) => {
    if (rating === number) {
      return { background: theme.palette.colors.primary, color: "#fff" };
    }
  };

  const cssAditionnaly = (number) => {
    return { ...setBorder(number), ...setBackground(number) };
  };

  return <Box className={classes.nps}>
    {numbers.map((number, index) => {
      return <Box key={index}
        onClick={() => setRating(number)}
        className={classes.number}
        style={cssAditionnaly(number)}
      >
        {number}

      </Box>
    })}
  </Box>
};

export default Rate;