import orm from "orm";
import { ucfirst, forceArray } from "utilities/utils";

import emitter from "utilities/emitter";

import { DEBUG_MODE } from "settings/config";

import {
  EVENT_RESOURCE_SET,
  EVENT_RESOURCE_UNSET,
} from "utilities/emitter/events";

import {
  RESOURCES_SET,
  RESOURCE_UNSET,
  RESOURCES_STORE_UPDATE,
} from "./actionsTypes"; //or from "ressourcesActionTypes"
import { LOG_OUT } from "applicationDucks/actionsTypes";
import {
  getDefaultState,
  setResource,
  handleRelationships,
  //retrieveResource,
  mapIncluded,
  handleDeleteCascade,
} from "./utils";

const UNSUPPORTED = ["apiSession", "quoteStack", "ApiFirewallException"];

export default function resourcesReducer(state, action) {
  const session = orm.session(getDefaultState(state));

  switch (action.type) {
    case RESOURCES_SET: {
      action.resources = forceArray(action.resources);

      const includedMap = mapIncluded(action.included || []);

      // let's map data AND included resources to store them into store and handle relationships
      const resources = [...action.resources, ...action.included];
      resources.map((resource) => {
        let newResource;

        // do not try to map unsupported frontend resources
        if (UNSUPPORTED.includes(resource.type)) return;

        // do not update event resource if included
        //if (resource.type === "event" && includedMap[locatorString(resource)]) return;

        // set resource into session
        try {
          newResource = setResource(session, resource);
        } catch (error) {
          console.error(error.message, resource);
          return;
        }

        // handle relationships & included
        for (let name in resource.relationships) {
          handleRelationships(
            session,
            newResource,
            resource.relationships[name],
            name,
            includedMap
          );
        }

        emitter.emit(EVENT_RESOURCE_SET, newResource.locator);
      });

      break;
    }

    case RESOURCE_UNSET: {
      const resourceClass = session[ucfirst(action.locator.type)];

      if (!resourceClass) {
        console.error("RESOURCE_UNSET locator is invalid!");
        break;
      }

      if (!resourceClass.idExists(action.locator.id)) {
        break;
      }

      const resource = resourceClass.withId(action.locator.id);

      // handle relations and cascade delete here
      handleDeleteCascade(resource);

      resource.delete();
      emitter.emit(EVENT_RESOURCE_UNSET, action.locator);

      break;
    }

    // carefull with that one.. It just REPLACE all the previous redux-orm resources state
    case RESOURCES_STORE_UPDATE: {
      const newSession = action.session;

      if (!newSession || !newSession.state) {
        break;
      }

      return newSession.state;
    }
    case LOG_OUT:
      return orm.getEmptyState();
  }

  // expose session in window for debugging purpose
  if (DEBUG_MODE)
    window.debug_session = session;

  return session.state;
}
