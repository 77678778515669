import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import CustomChevronDown from "liveShopping/assets/icons/CustomChevronDown";

const useStyles = makeStyles((theme) => ({
  select: {
    display: "flex",
    width: "100%",
    height: "40px",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 12px",
    border: "1px solid #E2E4E8",
    background: "transparent",
    boxSizing: "border-box",
    borderRadius: "8px",
    WebkitAppearance: "none",
    appearance: "none",
    "&:-ms-expand": {
      display: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:selection": {
      backgroundColor: "#000",
    },
  },
  chevronDown: {
    float: "right",
    marginTop: "-27px",
    marginRight: "10px",
    pointerEvents: "none",
    backgroundColor: "transparent",
    color: "black",
    paddingRight: "5px",
  },
}));

const CustomSelect = ({ options, setVariant }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    const variantValue = options.find((item) => item.hash === e.target.value);
    setVariant(variantValue);
  };
  return (
    <>
      <select className={classes.select} onChange={handleChange}>
        {options?.map((item) => (
          <option key={item.hash} value={item.hash}>
            {item.name}
          </option>
        ))}
      </select>
      <CustomChevronDown size={18} className={classes.chevronDown} />
    </>
  );
};

CustomSelect.propTypes = {};

export default CustomSelect;
