import { Box, Typography } from "@material-ui/core";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import React from "react";
import { _t } from "utilities/i18n";

const EmailField = ({ email, valid, handleEmailChange }) => {
  return (
    <Box>
      <Typography>{_t("Email")}</Typography>
      <Input onChange={(e) => handleEmailChange(e)} value={email} />
      {!valid && email.length > 0 && <p style={{ color: "red", margin: 0 }}>{_t("Invalid Email")}</p>}
    </Box>
  )
};

export default EmailField;