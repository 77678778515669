import { Box, makeStyles, Typography } from "@material-ui/core";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { accessesSelector } from "app/state/ducks/ressources/selectors";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _t } from "utilities/i18n";
import Form from "./Form";
import List from "./List";

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginTop: "20px",
  },
}));

const Admins = ({ project }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accesses = useSelector(accessesSelector).filter((access) => {
    // console.log(access.project);
    return access.type === "project" && access.project.hash === project.hash;
  });
  console.log(accesses)

  useEffect(() => {
    dispatch(
      resourceFetch("access", {
        slug: ["projects", project.hash],
        callback: (error, response) => {
          if (error) {
            console.log("error", error);
          }
        },
      })
    );
  }, []);

  return (
    <div>
      <Typography className={classes.title}>{_t("Admins")}</Typography>

      <Form projectHash={project.hash} />
      <List projectHash={project.hash} accesses={accesses} />
    </div>
  );
}

export default Admins;