import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import FirstPageOutlinedIcon from "@material-ui/icons/FirstPageOutlined";
import LastPageOutlinedIcon from "@material-ui/icons/LastPageOutlined";
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";
import StopIcon from "@material-ui/icons/Stop";

import IconButton from "appComponents/Mui/IconButton";
import { useMediaManager } from "./hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    left: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
  },
  elements: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.25, 1.875),
    backgroundColor: theme.wisemblyColors.black,
    borderRadius: theme.spacing(0, 0, 0.625, 0.625),
  },
  icon: {
    color: theme.wisemblyColors.white,
    "&.Mui-disabled": {
      color: theme.wisemblyColors.white,
    },
  },
  stop: {
    marginLeft: theme.spacing(3.375),
  },
  next: {
    margin: theme.spacing(0, 1.375, 0, 1.5625),
  },
  previous: {
    margin: theme.spacing(0, 1.5625, 0, 1.375),
  },
  text: {
    fontSize: "14px",
    minWidth: "45px",
    textAlign: "center",
    color: theme.wisemblyColors.white,
  },
}));

const MediaControlBar = ({ media, position, size }) => {
  const classes = useStyles();
  const handleStateMedia = useMediaManager(media);

  if (size <= 1) {
    return null;
  }

  return (
    <Box className={clsx("LaunchpadMediaControlBar", classes.root)}>
      <Box className={classes.elements}>
        <IconButton
          className={classes.icon}
          color="secondary"
          withoutbg
          disabled={position === 0}
          onClick={() => handleStateMedia("first", { current_slide: 0 })}
        >
          <FirstPageOutlinedIcon />
        </IconButton>

        <IconButton
          className={clsx(classes.icon, classes.previous)}
          color="secondary"
          withoutbg
          disabled={position <= 0}
          onClick={() =>
            handleStateMedia("previous", { current_slide: position - 1 })
          }
        >
          <NavigateBeforeOutlinedIcon />
        </IconButton>

        <Typography className={classes.text}>{`${
          position + 1
        } / ${size}`}</Typography>

        <IconButton
          className={clsx(classes.icon, classes.next)}
          color="secondary"
          withoutbg
          disabled={position >= size - 1}
          onClick={() =>
            handleStateMedia("next", { current_slide: position + 1 })
          }
        >
          <NavigateNextOutlinedIcon />
        </IconButton>

        <IconButton
          className={classes.icon}
          color="secondary"
          withoutbg
          disabled={position === size - 1}
          onClick={() => handleStateMedia("last", { current_slide: size - 1 })}
        >
          <LastPageOutlinedIcon />
        </IconButton>

        <IconButton
          color="secondary"
          withoutbg
          className={clsx(classes.icon, classes.stop)}
          onClick={() => handleStateMedia("stop")}
        >
          <StopIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

MediaControlBar.propTypes = {
  media: PropTypes.object,
  position: PropTypes.number,
  size: PropTypes.number,
};

export default MediaControlBar;
