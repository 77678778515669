import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    width: "100%",
    height: "100%",
    padding: "3px",
    backgroundColor: "transparent",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    cursor: "pointer",
    zIndex: theme.zIndex.mobileStepper,
    borderRadius: "5px",
    color: theme.palette.text.primary,
  },
}));

const SwitchFocus = ({ layout, onSetFocus }) => {
  const classes = useStyles();

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      className={clsx("SwitchFocus", classes.root)}
      onClick={() => onSetFocus(layout)}
    ></Box>
  );
};

SwitchFocus.propTypes = {
  layout: PropTypes.string,
  onSetFocus: PropTypes.func,
};

export default SwitchFocus;
