import React from "react";
import { Box, ButtonBase, makeStyles, Typography } from "@material-ui/core";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: 0,
    right: 0,
    width: "100%",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.colors.white,
    zIndex: 1,
    gap: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.colors.grey300}`,
  },
  closeButton: {
    position: "absolute",
    top: "5px",
    right: 0,
    padding: theme.spacing(2),
    cursor: "pointer",
    margin: "auto",
  },
  typo: {
    fontSize: "14px",
    lineHeight: "18px",
    color: theme.palette.colors.black,
  },
  buttonTypo: {
    fontSize: "14px",
    lineHeight: "18px",
    height: "40px",
  },
}));

const PopinFullscreen = ({ setPopin }) => {
  const classes = useStyles();

  const handleFullscreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }

    setPopin(false);
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.typo}>{_t("activate the full screen mode of your browser before projecting it")}</Typography>
      <Button clsName={classes.buttonTypo} onClick={handleFullscreen}>{_t("Activate the full screen")}</Button>

      <ButtonBase className={classes.closeButton} onClick={() => setPopin(false)}><XLg size={14} /></ButtonBase>

    </Box>
  )
}

export default PopinFullscreen;