import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { Divider } from "@material-ui/core";
import BeWarned from "./BeWarned";
import Share from "./Share"
import Agenda from "./Agenda";
import Volume from "./Volume";
import Info from "../Player/Elements/Info";
import { useIsInfoOpen } from "app/liveShopping/frontoffice/otm/hooks/useIsInfoOpen";
import Quit from "../Player/Elements/Quit";
import Timer from "./Timer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "8px 14px 8px 10px",
    background: "rgba(0, 0, 0, 0.5)",
    borderRadius: "8px",
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: '10px',
    position: "relative",
    zIndex: 5,
  },
  top: {
    display: "flex",
  },
  typoGroup: {
    display: "flex",
    marginBottom: "5px",
    marginTop: "7px",
    flexDirection: "column",
    textAlign: "center"
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    color: "white",
    lineHeight: "12px",
    marginBottom: "10px",
    visibility: props => props.isInfoOpen ? "hidden" : "visible",
  },
  divider: {
    width: "100%",
    border: "1px solid rgba(255, 255, 255, 0.1)",
  },
  info: {
    position: "absolute",
    top: 5,
    left: 5,
  },
  quit: {
    position: "absolute",
    top: 5,
    right: 5,
  },
  gonnaStart: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    height: "27px",
  }
}));


const Prelive = ({ }) => {
  const isInfoOpen = useIsInfoOpen();
  const classes = useStyles({ isInfoOpen: isInfoOpen.isInfoOpen });
  const wiz = useSelector(currentWizSelector);
  const isIFrame = useSelector(state => state.liveShopping.isIFrame);
  const [isEnded, setIsEnded] = useState(false);

  return (
    <Box className={classes.root}>
      <Box className={classes.info}>
        <Info {...isInfoOpen} />
      </Box>

      {isIFrame && <Box className={classes.quit}><Quit /></Box>}

      <Box className={classes.top}>
        <Box className={classes.typoGroup}>
          {!isEnded &&
            <>
              <Typography variant={"bodySB13"} className={classes.text}>{_t("Debuts in")}</Typography>
              <Timer onEnded={() => setIsEnded(true)} />
            </>
          }

          {isEnded &&
            <Box className={classes.gonnaStart}>{_t("Gonna start any second now!")}</Box>
          }

        </Box>

      </Box>

      <Box className={classes.bottom}>
        <Box mt={1} mb={1} style={{ width: "100%", padding: "0px 10px" }}>
          <Divider className={classes.divider} />
        </Box>
        <Box style={{ display: "flex" }}>
          <BeWarned />
          <Agenda wiz={wiz} />
          <Share />
          {wiz.extra_settings.teasing_video && <Volume />}
        </Box>
      </Box>

    </Box>
  );
};


Prelive.propTypes = {};


Prelive.defaultProps = {};


export default Prelive;
