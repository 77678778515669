import React from "react";
import "./styles/ColorWheel.css";
import PropTypes from "prop-types";
import { _t } from "i18n";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Icon from "coreUiKit/inputs/Icon";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import AddIcon from "react-bootstrap-icons/dist/icons/plus";
import some from "lodash/some";
import TagInList from "coreComponents/Tag/TagInList";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.colors.black,
        padding: "15px",
    },
    textTag: {
        marginBottom: "15px",
    },
    color: {
        cursor: "pointer",
        width: "18px",
        height: "18px",
        borderRadius: "100%",
        display: "flex",
    },
    selected: {
        margin: "auto",
        width: "8px",
        height: "8px",
        borderRadius: "100%",
        backgroundColor: theme.palette.colors.white,
    },
    tags: {
        display: "flex",
        flexDirection: "row",
    },
    add: {
        backgroundColor: theme.palette.colors.white10,
    },
    icon: {
        margin: "2px",
        color: theme.palette.colors.white,
    },
    gear: {
        marginLeft: "8px",
        marginBottom: "-2px",
        marginTop: "2px",
    },
}));

const ListTags = ({ tags, tagsQuote, setTag, setView, quoteAddTag, quoteRemoveTag }) => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography variant={"bodySB13"} color="white" className={classes.textTag}>
                {_t("Tags")}
            </Typography>
            {tags && tags.map((tag) => {
                return <TagInList key={tag.hash} tag={tag} inQuote={some(tagsQuote, elem => elem.hash === tag.hash)} setTag={setTag} setView={setView} quoteAddTag={quoteAddTag} quoteRemoveTag={quoteRemoveTag} />
            })}
            <Box className={clsx(classes.tags)}>
                <Box mr={"8px"} onClick={() => setView("add")} className={clsx("Color", classes.color, classes.add)}>
                    <Icon
                        IconComponent={AddIcon}
                        className={classes.icon}
                        color={"white"}
                        hover={"white"}
                    />
                </Box>
                <Typography mr={"8px"} color="white" variant={"body13"}>
                    {_t("Add a tag")}
                </Typography>
            </Box>
        </Box>
    );
};

ListTags.propTypes = {
    tags: PropTypes.array,
    tagsQuote: PropTypes.array,
    setTag: PropTypes.func,
    setView: PropTypes.func,
    quoteAddTag: PropTypes.func,
    quoteRemoveTag: PropTypes.func,
};

export default ListTags;
