import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { CameraVideo, CameraVideoFill, MicFill } from "react-bootstrap-icons";
import Mic from "react-bootstrap-icons/dist/icons/mic";
import X from "react-bootstrap-icons/dist/icons/x";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    position: "relative",
  },
  xBox: {
    position: "absolute",
    right: "-4px",
    bottom: "1px",
    backgroundColor: theme.palette.colors.error,
    borderRadius: "2px",
    border: "1px solid white",
    color: "white",
  },
  icons: {
    color: theme.palette.colors.grey400,
  },
}));

const NavigatorBlockedIcon = ({ mode }) => {
  // reproduce the blocked icon from the navigator chrome

  const classes = useStyles();

  const renderIcon = (mode) => {
    return (
      <Box className={classes.iconContainer}>
        {mode === "audio" ? <MicFill className={classes.icons} size={14} /> : <CameraVideoFill className={classes.icons} size={14} />}
        <X size={10} className={classes.xBox} />
      </Box>
    )
  };

  return (
    <Box style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      {mode === "audio" ? renderIcon(mode) : (
        <>
          {renderIcon("audio")}
          {renderIcon("video")}
        </>
      )}
    </Box>

  );
};

export default NavigatorBlockedIcon;