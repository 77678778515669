import { _t } from "i18n";
export const STATUS_NOT_STARTED = "notStarted";
export const STATUS_ONGOING = "ongoing";
export const STATUS_FINISHED = "finished";
export const STATUS_DISPLAY_RESULTS = "displayResult";
export const STATUS_HIDE_RESULTS = "hideResult";
export const VOTE_SURVEY_TYPE = "poll";
export const FORMULAIRE_SURVEY_TYPE = "form";
export const QUIZZ_SURVEY_TYPE = "evaluation";
export const MEDIA_TYPE = "media";
export const REACTION_THUMBS_UP = "thumbs_up";
export const REACTION_THUMBS_DOWN = "thumbs_down";
export const REACTION_THINKING = "thinking";
export const REACTION_ANGRY = "angry";
export const REACTION_SURPRISED = "surprised";
export const REACTION_THRILLED = "thrilled";
export const MODERATION_COMPACT = "compact";
export const MODERATION_TABS = "tabs";
export const MODERATION_COLUMNS = "columns";
export const MODERATION_TABS_THREAD = "thread";
export const MODERATION_TABS_MODERATION = "moderation"

export const surveyFilterOptions = [
  {
    title: _t("Ongoing"),
    hash: STATUS_ONGOING,
    type: "checkbox",
  },
  {
    title: _t("Finished"),
    hash: STATUS_FINISHED,
    type: "checkbox",
  },
  {
    title: _t("Not started"),
    hash: STATUS_NOT_STARTED,
    type: "checkbox",
  },
];
export const mediaFilterOptions = [
  {
    title: _t("Ongoing"),
    hash: STATUS_ONGOING,
    type: "checkbox",
  },
  {
    title: _t("Not started"),
    hash: STATUS_NOT_STARTED,
    type: "checkbox",
  },
];
export const mockMediaList = [
  {
    id: "58716f7c7867492f2c0cd77594d15220c93687ee",
    class_name: "Media",
    data: {
      format: "wide",
      mimetype: "application/pdf",
      filename: "welcome.pdf",
      storage_size: 0,
      slides_count: 6,
      extension: "pdf",
    },
    hash: "58716f7c7867492f2c0cd77594d15220c93687ee",
    type: "document",
    source: null,
    status: "ready",
    playable: true,
    provider: "wisembly",
    persisted: true,
    uploaded_at: "2020-07-09T07:17:46+00:00",
    downloads_count: 0,
    title: "Welcome",
    public: true,
    favorite: true,
    fixtures: true,
    live: {
      active: true,
      display_status: "ongoing",
      extra: [],
      current_slide: 4,
    },
    position: 1,
    active: true,
    display_status: "ongoing",
    download_url:
      "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/58716f7c7867492f2c0cd77594d15220c93687ee/download",
    virus_status: "unknown",
    thumbnails: {
      fixtures: {
        width: 1440,
        height: 900,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/58716f7c7867492f2c0cd77594d15220c93687ee/thumbnail/{{ step }}/fixtures",
      },
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "8c0302e8d24927046ddb6cca296451c99e82de8f",
    class_name: "Media",
    data: {
      ratio: 1,
      format: "wide",
      mimetype: "image/png",
      filename: "2X_STOCK_TITLE_2.png",
      storage_size: 86467,
      extension: "png",
    },
    hash: "8c0302e8d24927046ddb6cca296451c99e82de8f",
    type: "image",
    source: null,
    status: "ready",
    playable: true,
    provider: "wisembly",
    persisted: true,
    uploaded_at: "2021-03-26T10:01:15+00:00",
    downloads_count: 0,
    title: "2X_STOCK_TITLE_2.png",
    public: true,
    favorite: true,
    fixtures: false,
    live: {
      active: false,
      display_status: "presented",
      extra: null,
    },
    position: 2,
    active: false,
    display_status: "presented",
    virus_status: "unknown",
    download_url:
      "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/8c0302e8d24927046ddb6cca296451c99e82de8f/download",
    thumbnails: {
      extra_small: {
        width: 360,
        height: 360,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/8c0302e8d24927046ddb6cca296451c99e82de8f/thumbnail/extra_small",
      },
      small: {
        width: 900,
        height: 900,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/8c0302e8d24927046ddb6cca296451c99e82de8f/thumbnail/small",
      },
      medium: {
        width: 1080,
        height: 1080,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/8c0302e8d24927046ddb6cca296451c99e82de8f/thumbnail/medium",
      },
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "e4cb7d8786f70fe96914f7d4c45669b2be18d12f",
    class_name: "Media",
    data: {
      ratio: 1,
      format: "wide",
      mimetype: "image/png",
      filename: "Instagram_CTA_2.png",
      storage_size: 138323,
      extension: "png",
    },
    hash: "e4cb7d8786f70fe96914f7d4c45669b2be18d12f",
    type: "image",
    source: null,
    status: "ready",
    playable: true,
    provider: "wisembly",
    persisted: true,
    uploaded_at: "2021-03-26T10:03:22+00:00",
    downloads_count: 0,
    title: "Instagram_CTA_2.png",
    public: true,
    favorite: true,
    fixtures: false,
    live: {
      active: false,
      display_status: "presented",
      extra: null,
    },
    position: 3,
    active: false,
    display_status: "presented",
    virus_status: "unknown",
    download_url:
      "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/e4cb7d8786f70fe96914f7d4c45669b2be18d12f/download",
    thumbnails: {
      extra_small: {
        width: 360,
        height: 360,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/e4cb7d8786f70fe96914f7d4c45669b2be18d12f/thumbnail/extra_small",
      },
      small: {
        width: 900,
        height: 900,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/e4cb7d8786f70fe96914f7d4c45669b2be18d12f/thumbnail/small",
      },
      medium: {
        width: 1080,
        height: 1080,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/e4cb7d8786f70fe96914f7d4c45669b2be18d12f/thumbnail/medium",
      },
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "3b33079e7e69fd8b8a49af8a017e789b54d3360d",
    class_name: "Media",
    data: {
      format: "narrow",
      mimetype: "application/pdf",
      filename: "Dossier réservation Mme DOMINGO PDF-signed.pdf",
      storage_size: 240316,
      slides_count: 6,
      extension: "pdf",
    },
    hash: "3b33079e7e69fd8b8a49af8a017e789b54d3360d",
    type: "document",
    source: null,
    status: "ready",
    playable: true,
    provider: "wisembly",
    persisted: true,
    uploaded_at: "2021-04-26T10:09:20+00:00",
    downloads_count: 0,
    title: "Dossier réservation Mme DOMINGO PDF-signed.pdf",
    public: true,
    favorite: true,
    fixtures: false,
    live: {
      active: false,
      display_status: "presented",
      extra: [],
      current_slide: 5,
    },
    position: 4,
    active: false,
    display_status: "presented",
    download_url:
      "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/3b33079e7e69fd8b8a49af8a017e789b54d3360d/download",
    virus_status: "unknown",
    thumbnails: {
      extra_small: {
        width: 640,
        height: 905,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/3b33079e7e69fd8b8a49af8a017e789b54d3360d/thumbnail/{{ step }}/extra_small",
      },
      small: {
        width: 1440,
        height: 2036,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/3b33079e7e69fd8b8a49af8a017e789b54d3360d/thumbnail/{{ step }}/small",
      },
      medium: {
        width: 2560,
        height: 3620,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/3b33079e7e69fd8b8a49af8a017e789b54d3360d/thumbnail/{{ step }}/medium",
      },
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "430c76d053476a879657595d209f9f3ae0b02297",
    class_name: "Media",
    data: {
      format: "wide",
      mimetype:
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      filename: "industry 4.0-Revolution-PowerPoint-Templates.pptx",
      storage_size: 2082666,
      slides_count: 48,
      extension: "pptx",
    },
    hash: "430c76d053476a879657595d209f9f3ae0b02297",
    type: "presentation",
    source: null,
    status: "ready",
    playable: true,
    provider: "wisembly",
    persisted: true,
    uploaded_at: "2021-05-12T14:43:21+00:00",
    downloads_count: 0,
    title: "industry 4.0-Revolution-PowerPoint-Templates.pptx",
    public: true,
    favorite: true,
    fixtures: false,
    live: {
      active: false,
      display_status: "presented",
      extra: [],
      current_slide: 0,
    },
    position: 5,
    active: false,
    display_status: "presented",
    download_url:
      "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/430c76d053476a879657595d209f9f3ae0b02297/download",
    virus_status: "unknown",
    thumbnails: {
      extra_small: {
        width: 640,
        height: 360,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/430c76d053476a879657595d209f9f3ae0b02297/thumbnail/{{ step }}/extra_small",
      },
      small: {
        width: 1601,
        height: 900,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/430c76d053476a879657595d209f9f3ae0b02297/thumbnail/{{ step }}/small",
      },
      medium: {
        width: 2846,
        height: 1600,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/430c76d053476a879657595d209f9f3ae0b02297/thumbnail/{{ step }}/medium",
      },
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "939b795ae9de23ddaa0bb9ff794ecee1725efd6d",
    class_name: "Media",
    data: {
      id: "h6vUx3NsOb0",
      title: "J. Cole - The Off-Season (Full Album)",
      description:
        "1. 0:00 9 5 . s o u t h\n2. 3:14 a m a r i\n3. 5:42 m y . l i f e\n4. 9:16 a p p l y i n g . p r e s s u r e\n5. 12:11 p u n c h i n ‘ . t h e . c l o c k\n6. 14:03 1 0 0 . m i l ‘\n7. 16:44 p r i d e . i s . t h e . d e v I l\n8. 20:22 l e t . g o . m y . h a n d\n9. 24:45 i n t e r l u d e\n10. 26:56 t h e . c l i m b . b a c k\n11. 31:59 c l o s e\n12. 34:48 h u n g e r . o n . h i l l s i d e",
      duration: 2328,
    },
    hash: "939b795ae9de23ddaa0bb9ff794ecee1725efd6d",
    type: "video",
    source: null,
    status: "ready",
    playable: true,
    provider: "youtube",
    persisted: true,
    uploaded_at: "2021-05-20T10:33:35+00:00",
    title: "J. Cole - The Off-Season (Full Album)",
    public: false,
    favorite: true,
    fixtures: false,
    live: {
      active: false,
      display_status: "presented",
      extra: null,
    },
    position: 5.5,
    active: false,
    display_status: "presented",
    external: false,
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "4b6183170111eb6dc7ddb624c1ebca948607ff8b",
    class_name: "Media",
    data: {
      id: "KCFv0eNRzDY",
      title: "A$AP Rocky: 1 Hour Of Chill Songs",
      description:
        "0:00 L$D\n4:01 Better Things\n7:23 Been Around The World\n11:26 Purple Swag\n13:25 Peso\n16:16 Wassup\n18:57 Houston Old Head\n23:19 Acid Drip\n26:06 Roll One Up\n28:49 Demons\n31:53 LVL\n35:33 Phoenix\n39:27 Purple Kisses\n42:47 New York Bittersweet Symphony \n45:34 Bass\n48:54 Back To The Future\n51:55 Kissin' Pink Ft. A$AP Ferg\n55:30 Leaf Ft. Main Attrakionz\n1:00:25 Ridin' Ft. Lana Del Rey",
      duration: 3832,
    },
    hash: "4b6183170111eb6dc7ddb624c1ebca948607ff8b",
    type: "video",
    source: null,
    status: "ready",
    playable: true,
    provider: "youtube",
    persisted: true,
    uploaded_at: "2021-05-12T14:48:00+00:00",
    title: "A$AP Rocky: 1 Hour Of Chill Songs",
    public: false,
    favorite: false,
    fixtures: false,
    live: {
      active: false,
      display_status: "pending",
      extra: null,
    },
    position: 6,
    active: false,
    display_status: "pending",
    external: false,
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "7bfd2fb756b68219e8d632c8482f41a2c59c947e",
    class_name: "Media",
    data: {
      format: "narrow",
      mimetype:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      filename: "gantt-chart_L.xlsx",
      storage_size: 114135,
      slides_count: 8,
      extension: "xlsx",
    },
    hash: "7bfd2fb756b68219e8d632c8482f41a2c59c947e",
    type: "spreadsheet",
    source: null,
    status: "ready",
    playable: true,
    provider: "wisembly",
    persisted: true,
    uploaded_at: "2021-05-12T14:48:14+00:00",
    downloads_count: 0,
    title: "gantt-chart_L.xlsx",
    public: true,
    favorite: true,
    fixtures: false,
    live: {
      active: false,
      display_status: "pending",
      extra: null,
      current_slide: null,
    },
    position: 7,
    active: false,
    display_status: "pending",
    download_url:
      "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/7bfd2fb756b68219e8d632c8482f41a2c59c947e/download",
    virus_status: "unknown",
    thumbnails: {
      extra_small: {
        width: 640,
        height: 829,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/7bfd2fb756b68219e8d632c8482f41a2c59c947e/thumbnail/{{ step }}/extra_small",
      },
      small: {
        width: 1440,
        height: 1865,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/7bfd2fb756b68219e8d632c8482f41a2c59c947e/thumbnail/{{ step }}/small",
      },
      medium: {
        width: 2560,
        height: 3315,
        url: "http://api.wisembly.biz/app_dev.php/api/4/event/evolvecapital/medias/7bfd2fb756b68219e8d632c8482f41a2c59c947e/thumbnail/{{ step }}/medium",
      },
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "6f8d04047fcbe4acdcd7833c6c746137b19c41c1",
    class_name: "Media",
    data: {
      id: "21qNxnCS8WU",
      title: "ＣＨＩＬＬ　ＲＡＤＩＯ ２４／７",
      description:
        "Lofi Hip Hop beats for chilling and studying to \n🎧 Chill / Study Beats 🎧\n\n\nThis is a 24/7 lofi hip hop stream from the bootleg boy 2 channel. This music will carry on forever so sit back, relax and enjoy the stream 😊\n\n\n😴 Listen to the sleepy radio\n•YouTube https://www.youtube.com/watch?v=l7TxwBhtTUY\n\n\n🎶 Listen offline\n • Spotify http://bit.do/lofispotify \n\n\n🔧 Tweet me if the stream goes down\n • Twitter https://twitter.com/thebootlegboy\n\n\n💕 Join the bootleg squad\n • https://bootlegboy.lnk.to/join\n\n\n👕 New Bootleg Merch (Ships Worldwide)\n • http://www.thebootlegboy.com\n\n\nPowered by OhBubble\n• https://www.ohbubble.com/\n\n\n💜 bootleg💜\nsoundcloud - https://soundcloud.com/dabootlegboy\ntwitter - https://twitter.com/thebootlegboy\ninstagram - https://instagram.com/thebootlegboy\nspotify - https://bootlegboy.lnk.to/spotify\nNEW MERCH - https://thebootlegboy.com\n\n💕Subscribe for more vibes like this💕",
      duration: 0,
    },
    hash: "6f8d04047fcbe4acdcd7833c6c746137b19c41c1",
    type: "video",
    source: null,
    status: "ready",
    playable: true,
    provider: "youtube",
    persisted: true,
    uploaded_at: "2021-05-12T14:52:56+00:00",
    title: "ＣＨＩＬＬ　ＲＡＤＩＯ ２４／７",
    public: false,
    favorite: true,
    fixtures: false,
    live: {
      active: false,
      display_status: "pending",
      extra: null,
    },
    position: 8,
    active: false,
    display_status: "pending",
    external: true,
    _persisted: true,
    event: "evolvecapital",
  },
];
export const mockSurveyList = [
  {
    id: "54aabcc17c2332c8e297db9414fe91ab3620ff83",
    class_name: "Survey",
    hash: "54aabcc17c2332c8e297db9414fe91ab3620ff83",
    type: "poll",
    name: "quel est le meilleur mangas de la saison 2",
    description: "Best manga of the saison",
    is_active: false,
    is_accessible: true,
    multiple_answers: true,
    live_results: true,
    created_at: "2021-06-10T09:20:46+00:00",
    position: -6,
    answers_count: 1,
    wiz_rank: 107198966,
    comments_count: 0,
    started_at: "2021-07-26T12:42:40+00:00",
    ended_at: "2021-07-26T12:43:33+00:00",
    persisted: true,
    description_image: {
      fullscreen: null,
      mobile: null,
      mobile_retina: null,
      desktop: null,
    },
    duration: {
      years: 0,
      months: 0,
      days: 25,
      hours: 1,
      minutes: 53,
      seconds: 28,
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "1d2bd430f177a34ecabcc23092f0200b3da37491",
    class_name: "Survey",
    hash: "1d2bd430f177a34ecabcc23092f0200b3da37491",
    type: "poll",
    name: "test long notation",
    description: "test long notation",
    is_active: false,
    is_accessible: false,
    multiple_answers: true,
    live_results: true,
    created_at: "2021-06-01T15:52:04+00:00",
    position: -5,
    answers_count: 0,
    wiz_rank: 107115539,
    comments_count: 0,
    started_at: "2021-07-26T10:23:30+00:00",
    ended_at: "2021-07-26T10:24:30+00:00",
    persisted: true,
    description_image: {
      fullscreen: null,
      mobile: null,
      mobile_retina: null,
      desktop: null,
    },
    duration: {
      years: 0,
      months: 0,
      days: 14,
      hours: 0,
      minutes: 17,
      seconds: 45,
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "9b646bf29339611399b6dbe99b1b0b354b4162e0",
    class_name: "Survey",
    hash: "9b646bf29339611399b6dbe99b1b0b354b4162e0",
    type: "poll",
    name: "vote 3 image live",
    description: "un vote pour des images",
    is_active: false,
    is_accessible: true,
    multiple_answers: false,
    live_results: true,
    created_at: "2021-05-31T10:17:58+00:00",
    position: -4,
    answers_count: 1,
    wiz_rank: 107199644,
    comments_count: 0,
    started_at: "2021-07-26T12:43:33+00:00",
    ended_at: "2021-07-26T12:44:41+00:00",
    persisted: true,
    description_image: {
      fullscreen: null,
      mobile: null,
      mobile_retina: null,
      desktop: null,
    },
    duration: {
      years: 0,
      months: 0,
      days: 1,
      hours: 17,
      minutes: 26,
      seconds: 32,
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "949518c38c81446e6d8da8e87eab091660d8c54b",
    class_name: "Survey",
    hash: "949518c38c81446e6d8da8e87eab091660d8c54b",
    type: "poll",
    name: "toooppp",
    description: "tooppp",
    is_active: true,
    is_accessible: true,
    multiple_answers: true,
    live_results: true,
    created_at: "2021-05-12T10:04:01+00:00",
    position: -3,
    answers_count: 1,
    wiz_rank: 193477109,
    comments_count: 0,
    started_at: "2021-11-03T09:18:27+00:00",
    ended_at: null,
    persisted: true,
    description_image: {
      fullscreen: null,
      mobile: null,
      mobile_retina: null,
      desktop: null,
    },
    duration: {
      years: 0,
      months: 0,
      days: 0,
      hours: 20,
      minutes: 56,
      seconds: 34,
    },
    _persisted: true,
    event: "evolvecapital",
  },
  {
    id: "b5affc67eab5e318adbea9dacd1f98cc3ef9bdb1",
    class_name: "Survey",
    hash: "b5affc67eab5e318adbea9dacd1f98cc3ef9bdb1",
    type: "form",
    name: "test no image",
    description: "test no image intro",
    is_active: true,
    is_accessible: true,
    multiple_answers: false,
    live_results: false,
    created_at: "2021-05-27T15:07:23+00:00",
    position: -3,
    answers_count: 1,
    wiz_rank: 193476130,
    comments_count: 0,
    started_at: "2021-11-03T09:16:50+00:00",
    ended_at: null,
    persisted: true,
    description_image: {
      fullscreen: null,
      mobile: null,
      mobile_retina: null,
      desktop: null,
    },
    displayed_in_popup: true,
    _persisted: true,
    event: "evolvecapital",
  },
];

export const mockChatMessages = [
  {
    id: "48be4929b205fd4c15dbc3a12cd6b6a079061601",
    class_name: "Quote",
    hash: "48be4929b205fd4c15dbc3a12cd6b6a079061601",
    quote: "And don't forget, listen, react but don't judge.",
    username: "Greg",
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-11-18T13:57:56+00:00",
    published_at: "2021-11-18T13:57:56+00:00",
    wiz_rank: 7727494,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "fe652e85796c07cb63af27ff826048f4",
    is_mine: false,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "0a9ff8c2506193ddda4957ed85336a9f2e0a916e",
    class_name: "Quote",
    hash: "0a9ff8c2506193ddda4957ed85336a9f2e0a916e",
    quote: "And don't forget, listen, react but don't judge.",
    username: "Greg",
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-11-18T13:57:55+00:00",
    published_at: "2021-11-18T13:57:55+00:00",
    wiz_rank: 7727487,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "fe652e85796c07cb63af27ff826048f4",
    is_mine: false,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "dfda7fa35367ee42b644774ac193370a7abb617d",
    class_name: "Quote",
    hash: "dfda7fa35367ee42b644774ac193370a7abb617d",
    quote:
      "To participate, you can ask your questions or react in the conversation flow on the right side of the screen.",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-11-18T13:56:45+00:00",
    published_at: "2021-11-18T13:56:45+00:00",
    wiz_rank: 7726781,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "72bbb6044479fa110455626c41b51834",
    is_mine: true,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "8ffa921cc61901a02866519bfdc9ef2774d84281",
    class_name: "Quote",
    hash: "8ffa921cc61901a02866519bfdc9ef2774d84281",
    quote:
      "To participate, you can ask your questions or react in the cquestions or react in the cquestions or react in the cquestions or react in the cquestions or react in the cquestions or react in the conversation flow on the right side of the screen.",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-11-18T13:56:43+00:00",
    published_at: "2021-11-18T13:56:44+00:00",
    wiz_rank: 7726771,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "72bbb6044479fa110455626c41b51834",
    is_mine: true,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "d02f07b6044c22c5826c8a091776e34c4aad8b0f",
    class_name: "Quote",
    hash: "d02f07b6044c22c5826c8a091776e34c4aad8b0f",
    quote: "Hello",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-11-18T13:55:39+00:00",
    published_at: "2021-11-18T13:55:40+00:00",
    wiz_rank: 7726133,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "75901f3934e8f3b610790009de516227",
    is_mine: true,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "d02f07b6044c22c5826c8a091776e34c4aad8b0fe",
    class_name: "Quote",
    hash: "d02f07b6044c22c5826c8a091776e34c4aad8b0fe",
    quote: "azertyuiazerty",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-11-18T13:55:39+00:00",
    published_at: "2021-11-18T13:55:40+00:00",
    wiz_rank: 7726133,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "75901f3934e8f3b610790009de516227",
    is_mine: false,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "07df02d5da205527a827e9ee7eaa5200b057bb99",
    class_name: "Quote",
    hash: "07df02d5da205527a827e9ee7eaa5200b057bb99",
    quote: "Test",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T11:16:26+00:00",
    published_at: "2021-07-09T11:16:27+00:00",
    wiz_rank: 92458700,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: ["11cf9df885ec8fe993512cce6977dba4b8561c97"],
    pinned: true,
    secure_token: "8ef430d00e9d9b79af588edb052d99e8",
    is_mine: true,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "c6767f0ed0322368694de74e8fb353a487b52a87",
    class_name: "Quote",
    hash: "c6767f0ed0322368694de74e8fb353a487b52a87",
    quote: "Tu",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: true,
    created_at: "2021-07-12T08:39:51+00:00",
    published_at: "2021-07-12T08:39:51+00:00",
    wiz_rank: 94956745,
    comments_count: 0,
    display_status: "pending",
    position_favorite: 7,
    processed: true,
    announcement: false,
    tags_list: [
      "e43fd6c2a242eb56d943eb6f068cfc418f7f8356",
      "569b225c6a23be31eb3f4b31f943913c444b88d6",
      "5c385fb1dd43632498ac259415bc6eaffdbbb1e3",
      "3327e196bd80cec7a071487dc2797a04826ff805",
      "11cf9df885ec8fe993512cce6977dba4b8561c97",
      "dea6e85e1d96a554b96e794c3db9bf6e653e808a",
      "ca46b4fa7b78941b57700967b12bc9c649442717",
      "4f778089a0e2c73f44593e1008aa13fed5c640ab",
    ],
    pinned: false,
    secure_token: "8ef430d00e9d9b79af588edb052d99e8",
    is_mine: true,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "5348930c99003e5a5c34b062ee93c5ed38fe0c60",
    class_name: "Quote",
    hash: "5348930c99003e5a5c34b062ee93c5ed38fe0c60",
    quote: "Boomm retuo",
    username: null,
    reaction_count: 1,
    reactions: {
      thumbs_up: 1,
    },
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T13:01:51+00:00",
    published_at: "2021-07-09T13:01:51+00:00",
    wiz_rank: 92521949,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "8ef430d00e9d9b79af588edb052d99e8",
    is_mine: true,
    user_reaction: "thumbs_up",
    has_commented: false,
  },
  {
    id: "b5c314faacc8e1c87d79c111c1e2268d78ce93ff",
    class_name: "Quote",
    hash: "b5c314faacc8e1c87d79c111c1e2268d78ce93ff",
    quote: "Boom",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T13:01:33+00:00",
    published_at: "2021-07-09T13:01:33+00:00",
    wiz_rank: 92521766,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "8ef430d00e9d9b79af588edb052d99e8",
    is_mine: true,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "18f02a6cd10fb9b8b07defaae4764b22a5675ba5",
    class_name: "Quote",
    hash: "18f02a6cd10fb9b8b07defaae4764b22a5675ba5",
    quote: "Trst",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T12:58:01+00:00",
    published_at: "2021-07-09T12:58:01+00:00",
    wiz_rank: 92519640,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "8ef430d00e9d9b79af588edb052d99e8",
    is_mine: true,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "3c403808ad9b712bb7c470d4979f920b25ab6019",
    class_name: "Quote",
    hash: "3c403808ad9b712bb7c470d4979f920b25ab6019",
    quote: "Farfa",
    username: "maman",
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T12:56:38+00:00",
    published_at: "2021-07-09T12:56:38+00:00",
    wiz_rank: 92518810,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "0029969e68d53c742019b60d02b0eb9f",
    is_mine: false,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "14910a5ca77852066d3954fb7d7737f9b5b0f39a",
    class_name: "Quote",
    hash: "14910a5ca77852066d3954fb7d7737f9b5b0f39a",
    quote: "Farfrf",
    username: "maman",
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T12:56:22+00:00",
    published_at: "2021-07-09T12:56:22+00:00",
    wiz_rank: 92518655,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "0029969e68d53c742019b60d02b0eb9f",
    is_mine: false,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "435e3e781e951c90b0249fa2047350662b500abd",
    class_name: "Quote",
    hash: "435e3e781e951c90b0249fa2047350662b500abd",
    quote: "Fare",
    username: "maman",
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T12:56:00+00:00",
    published_at: "2021-07-09T12:56:00+00:00",
    wiz_rank: 92518433,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "0029969e68d53c742019b60d02b0eb9f",
    is_mine: false,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "8f3552803b5b1a2b24c87fcaa15c211db4a13ee4",
    class_name: "Quote",
    hash: "8f3552803b5b1a2b24c87fcaa15c211db4a13ee4",
    quote: "Test",
    username: null,
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T12:55:52+00:00",
    published_at: "2021-07-09T12:55:52+00:00",
    wiz_rank: 92518351,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "8ef430d00e9d9b79af588edb052d99e8",
    is_mine: true,
    user_reaction: null,
    has_commented: false,
  },
  {
    id: "1e48ebfdd6fcb860f9df9a545741f20723387551",
    class_name: "Quote",
    hash: "1e48ebfdd6fcb860f9df9a545741f20723387551",
    quote: "Fqfrfi",
    username: "maman",
    reaction_count: 0,
    reactions: [],
    via: "web",
    moderate: true,
    favorite: false,
    created_at: "2021-07-09T12:55:26+00:00",
    published_at: "2021-07-09T12:55:26+00:00",
    wiz_rank: 92518095,
    comments_count: 0,
    display_status: "pending",
    position_favorite: null,
    processed: false,
    announcement: false,
    tags_list: [],
    pinned: false,
    secure_token: "0029969e68d53c742019b60d02b0eb9f",
    is_mine: false,
    user_reaction: null,
    has_commented: false,
  },
];
