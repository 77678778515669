import React, { useState, useEffect } from "react";
import URI from "urijs";
import { Box } from "@material-ui/core";
import { useMediaPredicate } from "react-media-hook";
import { MEDIA_QUERIES } from "utilities/mediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import { useViewInnerHeigh } from "app/liveShopping/frontoffice/otm/hooks/useViewInnerHeight";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setLayoutType, setOpenCatalog } from "liveShoppingDucks/actions";
import Mobile916Layout from "./frontoffice/otm/components/Mobile916Layout";
import Desktop916Layout from "./frontoffice/otm/components/Desktop916Layout";
import { liveShoppingInit, setIsIFrame, setIsPrelive, setOpenReplay } from "app/state/ducks/liveShopping/actions";
import { isUser } from "utilities/access";
import { _t } from "utilities/i18n";
import { trackEvent } from "app/state/ducks/application/actions";

const useStyles = makeStyles(() => ({
  liveShoppingWrapper: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    position: (props) => (props.isMobile ? "static" : "relative"),
    touchAction: "manipulation",
  },
}));

const LiveShopping = ({ wiz }) => {

  const [isMobile, setIsMobile] = useState(true);
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const popup = new URLSearchParams("popup=true");

  const mobile = useMediaPredicate(MEDIA_QUERIES.MOBILE);

  useViewInnerHeigh();

  // setIsMobile once on boot
  useEffect(() => {
    // can override mobile/desktop layout by url
    // usefull when opened in PIP in destkop, do not load mobile layout
    const { layout } = new URI(window.location).search(true);

    if (layout) {
      console.log("layout", layout)
      dispatch(setLayoutType(layout))
      setIsMobile(layout === "mobile");
      return;
    }

    setIsMobile(mobile);
  }, []);



  // mobile hack to avoid scroll on body induced by topbar/navbar (tested on iOS)
  useEffect(() => {
    document.body.style.overflow = "hidden";
    dispatch(liveShoppingInit());
    dispatch(trackEvent('pageview'))
  }, []);

  useEffect(() => {
    if (!wiz) return;

    if (wiz.status === "pre_live" || (wiz.status === "ready_for_live" && isUser("user-"))) {
      dispatch(setIsPrelive(true));
      return;
    }

    dispatch(setIsPrelive(false));
  }, [wiz]);

  useEffect(() => {

    if (window.location !== window.parent.location) dispatch(setIsIFrame(true));

    if (!isMobile) {
      if (wiz.hasReplayEnabled()) {
        dispatch(setOpenReplay(true));
      } else {
        dispatch(setOpenCatalog(true));
      }
    }
  }, [isMobile]);

  if (["draft", "closed"].includes(wiz.status))
    return <Box>{_t("Nothing here (yet or anymore)...")}</Box>;

  return <Box className={classes.liveShoppingWrapper}>{isMobile ? <Mobile916Layout /> : <Desktop916Layout />}</Box>;
};

export default LiveShopping;

LiveShopping.propTypes = {
  desktopLayout: PropTypes.bool,
};

// LiveShopping.whyDidYouRender = {
//   logOnDifferentValues: false,
//   customName: "LiveShopping",
//   trackAllPureComponents: true,
//   trackHooks: true,
// };

LiveShopping.defaultProps = {};
