import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";

import VideoField from "../VideoField";
import Button from "../reusables/Button";
import { streamKeysGenerator } from "./streamKeysGenerator";
import { Divider, Typography } from "@material-ui/core";
import Select from "../reusables/NewSelect";
import { useDispatch, useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import CustomCopyIcon from "app/liveShopping/assets/icons/CustomCopyIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "16px 30px",
    display: "flex",
    flexDirection: "row",
    gap: "30px",
    width: "100%",
  },
  forms: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  generate: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  streamInfos: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "10px",
    gap: "10px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    height: "fit-content",
    marginBottom: "20px",
  },
  title: {
    display: "flex",
    alignItems: "center",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "130%",
  },
  subtitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
  },
  link: {
    cursor: "pointer",
    color: theme.palette.colors.primary,
    textDecoration: "underline",
  },
  copy: {
    cursor: "pointer",
    height: "20px"
  }
}));

const VideoParameters = ({ }) => {
  const classes = useStyles();
  const [rtmp, setRtmp] = useState("rtmp://global-live.mux.com:5222/app");
  const dispatch = useDispatch();
  const wiz = useSelector(currentWizSelector);

  const RTMPServerURL = [
    {
      value: "rtmp://global-live.mux.com:5222/app",
      label: _t("standard RTMP entry point."),
    },
    {
      value: "rtmps://global-live.mux.com:443/app",
      label: _t("secure RTMPS entry point."),
    },
  ];

  const onReset = () => {
    dispatch(
      resourceEdit(
        Object.assign(wiz, {
          extra_settings: {
            ...wiz.extra_settings,
            stream_key: null,
            playback_id: null,
            rtmp_server_url: null,
          },
          live_media: { url: null, enabled: false },
        }),
        {
          slug: ["event", wiz.keyword],
          silent: true,
          patch: ["extra_settings", "live_media"],

          callback: (error) => {
            if (error) {
              emitter.emit(EVENT_FLASH_ERROR, _t("An error has been detected"));
              return;
            }
            emitter.emit(EVENT_FLASH_SUCCESS, _t("Reset ok"));
          },
        }
      )
    );
  };

  const onCopy = (text) => {
    navigator.clipboard.writeText(text);
    emitter.emit(EVENT_FLASH_SUCCESS, _t("Copied to clipboard"));
  };

  return (
    <Box className={classes.root}>

      {!wiz.live_media?.url && (
        <Box className={classes.forms}>
          <Box className={classes.generate}>
            <Typography className={classes.title}>{_t("Generate your stream key")} </Typography>
            <Typography className={classes.subtitle}>{_t("We'll create a dedicated RTMP(s) live stream for your live. Use the generated credentials with your favorite streaming software (OBS, Vmix, Larix, etc..) to stream")}</Typography>
            <Select options={RTMPServerURL} value={rtmp} onChange={(e) => setRtmp(e.target.value)} />
            <Button onClick={() => streamKeysGenerator(rtmp, wiz, dispatch)}>{_t("Generate stream keys")}</Button>
          </Box>
          <Divider />
          <VideoField />
        </Box>
      )}

      {wiz.extra_settings?.stream_key && (
        <Box>
          <Box className={classes.streamInfos}>
            <Typography className={classes.title}>{_t("Stream key")} <CustomCopyIcon className={classes.copy} onClick={() => onCopy(wiz.extra_settings?.stream_key)} /></Typography>
            <Typography>{wiz.extra_settings?.stream_key}</Typography>
            <Typography className={classes.title}>{_t("RTMP Server URL")} <CustomCopyIcon className={classes.copy} onClick={() => onCopy(wiz.extra_settings?.rtmp_server_url)} /></Typography>
            <Typography>{wiz.extra_settings?.rtmp_server_url}</Typography>
            <Typography className={classes.title}>{_t("Full RTMP URL")} <CustomCopyIcon className={classes.copy} onClick={() => onCopy(`${wiz.extra_settings?.rtmp_server_url}/${wiz.extra_settings?.stream_key}`)} /></Typography>
            <Typography>{wiz.extra_settings?.rtmp_server_url}/{wiz.extra_settings?.stream_key}</Typography>
          </Box>
          <Typography className={classes.subtitle}>{_t("If you want to use a custom external streaming service and directly enter its .m3u8 url:")}&nbsp;<a className={classes.link} onClick={onReset}>{_t("click here")}</a></Typography>
        </Box>
      )}

      {wiz.live_media?.url && !wiz.extra_settings?.stream_key && (
        <Box className={classes.streamInfos}>
          <Typography className={classes.title}>{_t("Stream url")}</Typography>
          <Typography>{wiz.live_media?.url}</Typography>
          <Typography className={classes.subtitle}>{_t("You chose a custom external streaming endpoint for your live. If you want to use our streaming servers:")}&nbsp;<a className={classes.link} onClick={onReset}>{_t("click here")}</a></Typography>
        </Box>
      )}

    </Box>
  );
};


VideoParameters.propTypes = {};


VideoParameters.defaultProps = {};


export default VideoParameters;
