//import "react-hot-loader/patch";
import "../wdyr";
import "core-js/stable";

import React from "react";
import ReactDom from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { DEBUG_MODE, SENTRY_ENV, SENTRY_URL } from "settings/config";

import dayjs from "dayjs";
import basil, { getCookie, setCookie } from "core/basil";

import emitter from "core/emitter";

//import { hot } from 'react-hot-loader'

import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { getUserSession } from "core/session";
import { localStorageInfo } from "core/utils";

import "core/segment";
//import "@babel/polyfill";
// Load Tippy style
// import 'react-tippy/dist/tippy.css'

import Application from "appContainers/Application";
import globalStore from "appStore";

import { _t, getBrowserLanguage } from "i18n";
import '@tremor/react/dist/esm/tremor.css';
// Polyfills
import "core/polyfills";

import {
  resourceFetch,
  resourcesFetch,
  resourceCreate,
  resourceEdit,
  resourceDelete,
} from "ressourcesDucks/actions";
import URI from "urijs";

if (SENTRY_URL) {
  Sentry.init({
    dsn: SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    environment: SENTRY_ENV,
    release: `latest-${SENTRY_ENV}`,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// Load the manifest.json into the app, and keep the name unchanged
//what does this do ??
//import '!file-loader?name=[name].[ext]!assets/manifest.json'

const render = (Component) => {
  ReactDom.render(
    <Provider store={globalStore}>
      <Router>
        <Component />
      </Router>
    </Provider>,

    document.getElementById("root")
  );
};

render(Application);

// if (module.hot) {
//   module.hot.accept("./containers/Application", () => {
//     const newApplication = require("./containers/Application").default;
//     render(newApplication);
//   });
// }

// expose moment, globalStore and actions in window for debugging purpose
if (DEBUG_MODE) {
  window.debug_reactVersion = React.version;
  window.debug_getUserSession = getUserSession;
  window.debug_basil = basil;
  window.debug_getCookie = getCookie;
  window.debug_setCookie = setCookie;
  window.debug_dayjs = dayjs;
  window.debug_emitter = emitter;
  window.debug_store = globalStore;
  window.debug_t = _t;
  window.debug_getBrowserLanguage = getBrowserLanguage;
  window.debug_resourceFetch = resourceFetch;
  window.debug_resourcesFetch = resourcesFetch;
  window.debug_resourceCreate = resourceCreate;
  window.debug_resourceEdit = resourceEdit;
  window.debug_resourceDelete = resourceDelete;
  window.debug_localStorageInfo = localStorageInfo;
  window.debug_URI = URI;
}

// Export the store to use it for dispatching actions outside of React Components
// HOWEVER, this is an anti-pattern.
export default globalStore;
