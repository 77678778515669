import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({}));

const InputColorWrapper = ({ children, colorPreview }) => {
  const classes = useStyles();
  return <span style={{ position: "relative", width: "100%" }}>{children}
    {colorPreview && <div style={{ width: "20px", height: "20px", backgroundColor: `${colorPreview}`, position: "absolute", top: 10, right: 10, borderRadius: "5px", border: "1px solid #ccc" }}></div>}</span>;
}

export default InputColorWrapper;