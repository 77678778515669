import { _t } from "utilities/i18n";

export const AGENDA_CONFIGURATION = [
  {
    day: _t("monday"),
    slots: [
      {
        start: "10:00",
        end: "13:00",
      },
    ],
  },
  {
    day: _t("tuesday"),
    slots: [
      {
        start: "10:00",
        end: "13:00",
      }
    ],
  },
  {
    day: _t("wednesday"),
    slots: [
      {
        start: "10:00",
        end: "13:00",
      },

    ],
  },
  {
    day: _t("thursday"),
    slots: [
      {
        start: "14:00",
        end: "20:00",
      },

    ],
  },
  {
    day: _t("friday"),
    slots: [
      {
        start: "10:00",
        end: "13:00",
      },

    ],
  },
  {
    day: _t("saturday"),
    slots: [
      {
        start: "10:00",
        end: "13:00",
      },
    ],
  },
  {
    day: _t("sunday"),
    slots: [],
  },
];

export const INDISPONIBILITIES = {
  "2023-04-20": [
    {
      start: "09:00",
      end: "09:30",
    },
    {
      start: "15:00",
      end: "15:30",
    },
  ],
  "2023-04-21": [],
};

export const OVERRIDE = {
  "2023-05-01": [
    {
      start: "14:00",
      end: "18:00",
    },
  ],
  "2023-05-02": [],
};
