import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import ExclamationCircleFill from "react-bootstrap-icons/dist/icons/exclamation-circle-fill";
import Laptop from "react-bootstrap-icons/dist/icons/laptop";
import Button from "../../reusables/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 10,
    top: 0,
    left: 0,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "15px",
    background: "#FFFFFF",
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    zIndex: 12,
    width: "358px",
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    borderRadius: "99px",
    background: (props) => {
      switch (props.type) {
        case "success":
          return "rgba(0, 150, 150, 0.1)";
        case "danger":
          return "rgba(240, 70, 75, 0.1)";
        default:
          return null;
      }
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "130%",
    textAlign: "center",
    color: theme.palette.colors.grey700,
    margin: "8px 0px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    textAlign: "center",
    color: theme.palette.colors.grey500,
    marginBottom: "30px",
  },
  typoGreyBtn: {
    color: theme.palette.colors.grey700,
  },
  typoBtn: {
    color: "white",
  },
}));

const Interaction = ({ type, title, subTitle, body, textBtn1, textBtn2, action1, action2 }) => {
  const classes = useStyles({ type });
  const theme = useTheme();

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box className={classes.iconWrapper}>
          {type === "danger" && <ExclamationCircleFill size={20} color={theme.palette.colors.error} />}
          {type === "success" && <Laptop size={20} color={theme.palette.colors.success} />}
        </Box>

        <Typography variant={"bodySB13"} className={classes.title}>
          {title}
        </Typography>

        {subTitle && (
          <Typography variant={"bodySB13"} className={classes.subTitle}>
            {subTitle}
          </Typography>
        )}

        {body && (
         <Box>{body}</Box>
        )}

        <Box style={{ display: "flex", gap: "8px" }}>

          {textBtn2 && (
            <Button color={theme.palette.colors.grey200} w100 onClick={action2}>
              <Typography variant={"bodySB13"} className={classes.typoGreyBtn}>
                {textBtn2}
              </Typography>
            </Button>
          )}

          {textBtn1 && (
            <Button onClick={action1} color={type === "danger" ? theme.palette.colors.error : theme.palette.colors.sucess} w100>
              <Typography variant={"bodySB13"} className={classes.typoBtn}>
                {textBtn1}
              </Typography>
            </Button>
          )}

        </Box>
      </Box>
    </Box>
  );
};

Interaction.propTypes = {};

Interaction.defaultProps = {};

export default Interaction;
