import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import TopBar from "./TopBar";
import Infos from "./Infos";
import Stats from "./Stats";
import Status from "./Status";
import { useDispatch, useSelector } from "react-redux";
import { desktopLayoutSelector, displayBackOfficeElementSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import MobileOptions from "./MobileOptions";
import { displayTabBar } from "app/state/ducks/liveShopping/actions";
import Catalog from "./Catalog/Catalog";
import Element from "../../Element";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  replayButton: {
    margin: "24px 30px 24px 50px",
  },
  typoButton: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
  },
}));

const index = ({ }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const isMobile = useSelector(mobileLayoutSelector)
  const classes = useStyles();
  const dispatch = useDispatch();
  const element = useSelector(displayBackOfficeElementSelector);
  const wiz = useSelector(currentWizSelector);

  console.log("wiz", wiz)
  useEffect(() => {
    if (isMobile) {
      dispatch(displayTabBar(false))
    }
  }, [])

  return (
    <>
      {isDesktop ? (
        <Box style={{ display: "flex", flexDirection: "row", height: "100%", width: "100%", position: "relative" }}>
          <Box style={{ display: "flex", justifyContent: "space-between", height: "100%", width: "100%" }}>
            <Box style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
              <Box style={{ height: "100%", flex: 1, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Infos />
                <TopBar />
              </Box>
              <Catalog />
            </Box>
          </Box>
          <Box>
            <Status />
            <Stats />
          </Box>
        </Box>
      ) :
        (
          <>
            <TopBar />
            <Infos />
            {element ? <Element /> : <MobileOptions />}
          </>
        )
      }
    </>
  );
};


index.propTypes = {};


index.defaultProps = {};


export default index;
