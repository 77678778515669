import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import map from "lodash/map";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import Box from "@material-ui/core/Box";
import toNumber from "lodash/toNumber";
import { makeStyles } from "@material-ui/core/styles";
import { ShowSurveyItemAnswer } from "appComponents/Viewers";
import { SurveyItemTitle } from "appComponents/Viewers";
import basil from "core/basil";
import { connect } from "react-redux";
import {
  useSurveyContextState,
  useSurveyContextDispatcher,
  PaginationButton,
  canUserSeeEvaluationAnswer,
} from "appComponents/SurveyForms";
import { getParsedPercent } from "utilities/utils";

const useStyles = makeStyles((theme) => ({
  formFieldRow: {
    //actionPanel height theme.spacing(4.75)
    flexDirection: "column",
    //height: `calc(100vh - ${theme.spacing(17.5)}px ) `,
    overflow: "auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "50px",
      paddingRight: "10%",
    },
    [theme.breakpoints.down("xs")]: {
      flex: "auto",
      paddingLeft: "25px",
      paddingRight: "5%",
    },
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
  },
  paginationScreen: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    justifyContent: "flex-start",
    overflowX: "hidden",
  },
  hidden: {
    display: "none",
  },
  paginator: {
    display: (props) => (props.showPaginator ? "flex" : "none"),
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    minHeight: theme.spacing(3),
  },
  evaluationNote: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  score: {
    [theme.breakpoints.up("sm")]: {
      fontSize: (props) => (props.isThumbnailView ? "30px" : "50px"),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "30px",
    },
  },
}));

const UserEvaluationNote = ({ answer, itemCount, isThumbnailView }) => {
  const classes = useStyles({ isThumbnailView });
  const note = (toNumber(get(answer, "score", 0)) / itemCount) * 100;
  return (
    <Box className={classes.evaluationNote}>
      <Box className={classes.score}>{`${getParsedPercent(note)} %`}</Box>
    </Box>
  );
};
UserEvaluationNote.propTypes = {
  answer: PropTypes.object,
  itemCount: PropTypes.number,
  isThumbnailView: PropTypes.bool,
};
UserEvaluationNote.defaultProps = {
  itemCount: 1,
  isThumbnailView: false,
};
//put pagination here
const ShowUserSurveyItemAnswer = ({
  answer,
  survey,
  isThumbnailView,
  useOwnPaginator,
  layoutMode,
}) => {
  const surveyContext = useSurveyContextState();
  const surveyContextDispatch = useSurveyContextDispatcher();

  //surveyItemAnswer
  const itemAnswers = answer.itemAnswers.toRefArray();
  const responseIndexMap = itemAnswers.map((item, i) => ({
    index: i,
    hash: item.hash,
  }));
  useEffect(() => {
    if (!isEqual(responseIndexMap, surveyContext.responseIndexMap)) {
      surveyContextDispatch({
        type: "SET_USER_RESPONSE_MAP",
        responseIndexMap: responseIndexMap,
      });
    }
  }, [survey.hash, responseIndexMap]);
  const currentFieldHash =
    responseIndexMap &&
    responseIndexMap[surveyContext.index] &&
    responseIndexMap[surveyContext.index].hash;

  const classes = useStyles({
    showPaginator:
      (itemAnswers.length > 1 && useOwnPaginator) ||
      (surveyContext.surveyType == "evaluation" &&
        useOwnPaginator &&
        itemAnswers.length > 0),
  });
  const canSeeEvaluationAnswer =
    survey &&
    survey.type === "evaluation" &&
    canUserSeeEvaluationAnswer(survey);

  //if pagination = -1 show note
  let result = map(itemAnswers, (surveyItemAnswer, key) => {
    //surveyItemAnswer.hash ===  surveyitem.hash
    return (
      <Box
        key={key}
        className={clsx(
          classes.paginationScreen,
          !isEqual(surveyItemAnswer.hash, currentFieldHash)
            ? classes.hidden
            : null
        )}
      >
        <ShowSurveyItemAnswer
          key={key}
          survey={survey}
          surveyItemAnswer={surveyItemAnswer}
          isThumbnailView={isThumbnailView}
          layoutMode={layoutMode}
        />
      </Box>
    );
  });
  const Note = (
    <Box
      key="note"
      className={clsx(
        "SurveyNote",
        surveyContext.index === -1 ? classes.paginationScreen : classes.hidden
      )}
    >
      <SurveyItemTitle
        survey={survey}
        isThumbnailView={isThumbnailView}
        layoutMode={layoutMode}
        forResult={true}
        showSurveyTitle={true}
      />
      <Box className={classes.formFieldRow} pt={2}>
        <UserEvaluationNote
          answer={answer}
          itemCount={itemAnswers.length || 1}
          isThumbnailView={isThumbnailView}
        />
      </Box>
    </Box>
  );
  //unshift if form or evaluation
  if (canSeeEvaluationAnswer) {
    result.unshift(Note);
  }
  return (
    <Box
      className={clsx("ShowUserSurveyItemAnswer")}
      display="flex"
      width="100%"
      flexDirection="column"
    >
      {result}
      <Box className={classes.paginator}>
        <PaginationButton />
      </Box>
    </Box>
  );
};
ShowUserSurveyItemAnswer.propTypes = {
  answer: PropTypes.object, //surveyAnswer
  items: PropTypes.array,
  survey: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  useOwnPaginator: PropTypes.bool,
  layoutMode: PropTypes.string,
};
ShowUserSurveyItemAnswer.defaultProps = {
  useOwnPaginator: false,
};
const mapStateToProps = (state, ownProps) => {
  return {
    layoutMode: basil.get("currentLayoutMode") || "split",
    //items : genericResourcesSelector(state, {type: "Item", filter: (item)=>isEqual})
  };
};

export default connect(mapStateToProps)(ShowUserSurveyItemAnswer);
