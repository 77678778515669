import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { FixedSizeList, VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import Separator from "coreUiKit/components/Chat/NewMessage/Separator";
import get from "lodash/get";
import arrayReverse from "lodash/reverse";
import min from "lodash/min";
import split from "lodash/split";
import toNumber from "lodash/toNumber";
import keys from "lodash/keys";
import last from "lodash/last";
import emitter from "utilities/emitter";
import memo, { renderLog } from "core/memo";
import ChatBubbleLightContainer from "../containers/ChatBubbleLight";
import { EVENT_RESET_SIZE_LIST_CHAT } from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
  margin: {
    //marginTop: "10px",
    //marginBottom: "10px",
  },

  virtualizedList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: (props) => (props.reverse ? "flex-end" : "flex-start"),
    //height: "auto !important",
    //marginLeft: "10px",
    scrollbarWidth: (props) => (props.hideScrollbar ? "none" : "10px"),
    MsOverflowStyle: (props) => (props.hideScrollbar ? "none" : "auto"),
    "&::-webkit-scrollbar": {
      width: (props) => (props.hideScrollbar ? "0px" : "8px"),
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "99px",
      background: theme.palette.colors.greyLight,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "99px",
      background: theme.palette.colors.grey,
    },
  },
}));
const LOCAL_NOTIFY_SEPARATOR = "LOCAL_NOTIFY_SEPARATOR";
let inDomRef2 = {};
let lastVisibleElementIndex2 = 0;
let lastVisibleElementDeltaFromEnd2 = 0;
let rowHeights2 = {};
let indexSeparator2 = null;
let listRef2 = null;
function getRowHeight2(index) {
  return rowHeights2[index] || 82;
}
function setRowHeight2(index, size) {
  //rewrite this
  if (listRef2) {
    listRef2.resetAfterIndex(0, true);
  }
  //listRef.current.resetAfterIndex(0, true);
  // if (size !== rowHeights2[index]) {
  //   console.log("size of index changed", index, rowHeights2[index], size);
  // } else {
  //   console.log(" index size did not changed", index);
  // }
  rowHeights2 = { ...rowHeights2, [index]: size };
}
const MemoRenderer = memo(({ index, style, data }) => {
  const classes = useStyles();
  const [reloader, setReloader] = useState(0);
  const element = data[index];
  const rowRef = useRef({});
  const previousSeparator = useRef(null);
  useEffect(() => {
    inDomRef2[index] = true;
    return () => {
      delete inDomRef2[index];
    };
  }, []);
  useEffect(() => {
    const callback = (separator) => {
      if (element === previousSeparator.current || separator === element) {
        indexSeparator2 = separator;
        previousSeparator.current = separator;
        setReloader(reloader + 1);
      }
    };
    emitter.on(LOCAL_NOTIFY_SEPARATOR, callback);
    return () => {
      emitter.off(LOCAL_NOTIFY_SEPARATOR, callback);
    };
  }, [element, indexSeparator2]);
  if (indexSeparator2 && element === indexSeparator2) {
    previousSeparator.current = indexSeparator2;
  }
  useEffect(() => {
    if (rowRef.current) {
      //console.log("inDomRef.current", inDomRef.current);
      //if (reverse) {
      //track last element beeing rendered

      //because in reverse list we should watch position from the bottom
      //i keep delta between last element at bottom and element being rendered

      //lastVisibleElementDeltaFromEnd.current = data.length - 1 - index;
      const lastElem = last(
        keys(inDomRef2)
          .map((elem) => toNumber(elem))
          .sort((a, b) => a - b)
      );

      lastVisibleElementIndex2 = lastElem;
      lastVisibleElementDeltaFromEnd2 = data.length - 1 - lastElem;

      setRowHeight2(index, rowRef.current.offsetHeight);
      //  }
    }
    // eslint-disable-next-line
  }, []);

  //the ref is forwaded so the ItemComponent should use forwarRef

  return (
    <Box className={classes.margin} style={style}>
      <Box ref={rowRef}>
        <ChatBubbleLightContainer id={element} />
        {indexSeparator2 && element === indexSeparator2 && <Separator />}
      </Box>
    </Box>
  );
});
const ScroolList = ({ fetchData, elements, total, width, height, reverse, variableSize, hideScrollbar, scrollDownEvent, onScrollDownEvent, separatorEvent, onReachBottom, onScrollableList }) => {
  const classes = useStyles({ reverse, hideScrollbar });
  const listRef = useRef(null);
  const innerRef = useRef(null);
  const outerRef = useRef(null);
  //const inDomRef = useRef({});
  const atBottom = useRef(false);
  //const indexSeparator = useRef(null);
  const scrollBackToItem = useRef(null);
  //const lastVisibleElementDeltaFromEnd = useRef(0);
  //const lastVisibleElementIndex = useRef(0);
  const deltaSize = useRef(0);

  //for reversed list like chat height will be the min between height: auto and parent height
  const [reverseHeight, setReverseHeight] = useState(height);
  const dimensions = useRef({ width: 0, height: 0 });
  //const scrollToBottomAtStart = useRef(false);
  const size = useRef(null);
  const sizeChangeHandled = useRef(false);
  const handleLoadMore = useRef(false);

  useEffect(() => {
    if (indexSeparator2) {
      indexSeparator2 = null;
    }

    const onScrollToBottom = () => {
      scrollToBottom();
      onScrollDownEvent();
    };

    const onDisplaySeparator = ({ count }) => {
      // @TOOD inactif user
      if (!atBottom.current && count > 0) {
        //const quote = get(elements, `[${count}]`);
        indexSeparator2 = get(elements, `[${count}]`);
        emitter.emit(LOCAL_NOTIFY_SEPARATOR, indexSeparator2);
      }
    };
    const resetChatBubbleSize = () => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0, true);
      }
    };
    emitter.on(EVENT_RESET_SIZE_LIST_CHAT, resetChatBubbleSize);
    emitter.on(scrollDownEvent, onScrollToBottom);
    emitter.on(separatorEvent, onDisplaySeparator);
    return () => {
      emitter.off(scrollDownEvent, onScrollToBottom);
      emitter.off(separatorEvent, onDisplaySeparator);
      emitter.off(EVENT_RESET_SIZE_LIST_CHAT, resetChatBubbleSize);
    };
  }, [elements.length]);

  //track and set list real height in reverse list
  useEffect(() => {
    if (innerRef.current) {
      dimensions.current.height = toNumber(split(innerRef.current.style?.height, "px")[0]);
      dimensions.current.width = toNumber(split(innerRef.current.style?.width, "px")[0]);
      if (dimensions.current.height > height) {
        onScrollableList(true);
      } else {
        onScrollableList(false);
      }

      //in reverse list to give the impression element print from bottom
      //list height is min between elements height and viewport height
      if (min([height, dimensions.current.height]) !== reverseHeight && reverse) {
        if (dimensions.current.height < height) {
          setReverseHeight(min([height, dimensions.current.height]) + 10);
        } else {
          setReverseHeight(min([height, dimensions.current.height]));
        }
      }
    }
  }, [JSON.stringify(elements), height, reverse]);

  const getInnerDivSize = () => {
    const newHeight = toNumber(split(innerRef.current.style?.height, "px")[0]);
    dimensions.current.height = newHeight;
    const newWidth = toNumber(split(innerRef.current.style?.width, "px")[0]);
    dimensions.current.width = newWidth;

    //in reverse list, list should be at bottom of parent and have minimal height
    //to give impression that elements start from bottom
    if (min([height, dimensions.current.height]) !== reverseHeight && reverse) {
      setReverseHeight(min([height, dimensions.current.height]));
    }
    //}
  };
  useEffect(() => {
    window.addEventListener("resize", getInnerDivSize);
    return () => {
      window.removeEventListener("resize", getInnerDivSize);
    };
  }, []);

  //const rowHeights = useRef({});
  const data = [...elements];
  if (reverse) {
    arrayReverse(data);
  }

  const List = variableSize ? VariableSizeList : FixedSizeList;
  const isItemLoaded = (index) => {
    if (reverse) {
      return index > 0;
    } else {
      return elements.length >= total || index < elements.length;
    }
  };

  const loadMore = () => {
    //not used i implement my own load more. ps: it's better
    return new Promise((resolve) => {
      //fetchData();
      resolve();
    });
  };
  //at start i scroll to bottom if is reverse list
  useEffect(() => {
    if (listRef.current && reverse && elements.length > 0) {
      const timeout = setTimeout(function () {
        scrollToBottom();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, []);

  //in reverse list when size change we should scroll back to where user was
  //because don't forget, element always render from top to bottom even if we read
  // from bottom to top
  useEffect(() => {
    if (!sizeChangeHandled.current && reverse) {
      if (listRef.current && scrollBackToItem.current !== null && (deltaSize.current > 1 || atBottom.current)) {
        listRef.current.scrollToItem(scrollBackToItem.current, "end");
        listRef.current.resetAfterIndex(0, true);
        //notify that list scrollBack to right element
        sizeChangeHandled.current = true;
      }
    }
  }, [elements.length]);

  //save initial elements size
  if (size.current === null && elements.length !== 0) {
    //init size
    size.current = elements.length;
  }
  //detect size change
  if (size.current !== null && size.current > 0 && size.current !== elements.length) {
    //const nbElementAdded = elements.length - size.current;
    //because the last index is most of the time invisible item that is hidden so i take 2 item before
    scrollBackToItem.current = elements.length - 1 - lastVisibleElementDeltaFromEnd2;
    deltaSize.current = elements.length - size.current;

    sizeChangeHandled.current = false;
    size.current = elements.length;
    //when size change after fetchData
    handleLoadMore.current = false;
  }

  function scrollToBottom() {
    listRef.current.scrollToItem(elements.length - 1, "end");
    listRef.current.resetAfterIndex(0, true);
    listRef.current.scrollToItem(elements.length - 1, "end"); // DON'T DELETE
    atBottom.current = true;
    onReachBottom(true);
  }

  const onScroll = ({
    //scrollDirection,
    scrollOffset,
    scrollUpdateWasRequested,
  }) => {
    const correctHeight = reverse ? reverseHeight : height;
    atBottom.current = correctHeight + scrollOffset >= toNumber(split(innerRef.current.style?.height, "px")[0]);
    onReachBottom(atBottom.current);
    //if scroll within 15 elements loadMore element
    // ignore if scroll was caused by scrollToItem()

    if ((!scrollUpdateWasRequested && scrollOffset !== 0) || (lastVisibleElementIndex2 && scrollOffset === 0)) {
      if (
        //  (lastVisibleElementIndex.current < 15 && reverse) ||
        (lastVisibleElementIndex2 && scrollOffset === 0 && reverse) ||
        (!reverse && elements.length - lastVisibleElementIndex2 < 15)
      ) {
        //loadmore only once until elements size change
        if (!handleLoadMore.current) {
          fetchData();
          handleLoadMore.current = true;
        }
      }
    }
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={0} loadMoreItems={loadMore} threshold={10}>
      {({ onItemsRendered, ref }) => (
        <List
          className={classes.virtualizedList}
          height={reverse ? reverseHeight || 10 : height}
          itemCount={elements.length}
          ref={(reference) => {
            ref(reference);
            listRef.current = reference;
            listRef2 = reference;
          }}
          estimatedItemSize={82}
          itemSize={getRowHeight2}
          itemData={data}
          onItemsRendered={onItemsRendered}
          width={width}
          itemKey={(index) => get(data, `[${index}]`)}
          innerRef={innerRef}
          outerRef={outerRef}
          onScroll={onScroll}
        >
          {MemoRenderer}
        </List>
      )}
    </InfiniteLoader>
  );
};
//{RowShower(ItemComponent,inDomRef, reverse, variableSize, setRowHeight,lastVisibleElementIndex, lastVisibleElementDeltaFromEnd, indexSeparator)}
ScroolList.propTypes = {
  total: PropTypes.number,
  elements: PropTypes.array,
  ItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
  fetchData: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  itemHeight: PropTypes.number.isRequired,
  reverse: PropTypes.bool,
  variableSize: PropTypes.bool,
  hideScrollbar: PropTypes.bool,
  variableSizePadding: PropTypes.number,
  onScrollableList: PropTypes.func,
};
ScroolList.defaultProps = {
  total: 0,
  itemHeight: 172, //card height + marginTop + marginBottom
  height: 500,
  width: "100%",
  reverse: false,
  variableSize: false,
  hideScrollbar: false,
  scrollDownEvent: "event:scrolldown",
  onScrollDownEvent: () => {},
  separatorEvent: "event:separator",
  onReachBottom: () => {},
  variableSizePadding: 0,
  onScrollableList: () => {},
};

export default memo(ScroolList);
