// import Raven from 'raven-js'

import {
  // RAVEN_URL,
  // RAVEN_REPORT_DIALOG,
  DEBUG_MODE,
  DEBUG_LEVEL,
} from "settings/config";

const levels = ["error", "warn", "log", "info"];

// if (RAVEN_URL) {
//   Raven
//     .config(RAVEN_URL)
//     .install()
// }

export function addLoggerContext(context) {
  // if (RAVEN_URL) {
  //   Raven.setUserContext(context)
  // }
}

function debug(method, ...args) {
  if (!DEBUG_MODE)
    return;

  if (levels.indexOf(method) <= levels.indexOf(DEBUG_LEVEL)) {
    // if (RAVEN_URL && method === 'error') {
    //   Raven.captureMessage(args)
    // }

    console[method].apply(console, args);
  }
}

function namespace(ns) {
  return levels.reduce((logger, method) => {
    logger[method] = debug.bind(console, method, `[${ns}]`);

    return logger;
  }, {});
}

export function catchError(error, dialog = false) {
  debug("error", error);

  // if (RAVEN_URL) {
  //   Raven.captureException(error)

  //   if (dialog && RAVEN_REPORT_DIALOG) {
  //     Raven.showReportDialog()
  //   }
  // }
}

export default namespace;
