import { isLight } from "utilities/utils";

const ThemedIconButton = (props, theme) => {
  let buttonPalette;
  let iconSize;
  let paddingVertical;
  let paddingHorizontal;
  let radius;
  let height = "auto";
  let width = "auto";
  let minHeight = 0;
  let minWidth = 0;

  /* Handle the color */
  switch (props.color) {
    case "primary":
    case "secondary":
    case "tertiary":
    case "alert":
    case "ghost":
      buttonPalette = theme.palette.iconButton[props.color];
      break;
    default:
      //to define
      buttonPalette = theme.palette.iconButton.secondary;
      break;
  }

  /* Handle the size */
  switch (props.size) {
    case "large":
      paddingVertical = 12;
      paddingHorizontal = 12;
      iconSize = 20;
      break;
    default:
    case "medium":
      paddingVertical = 10;
      paddingHorizontal = 10;
      iconSize = 18;
      break;
    case "small":
      paddingVertical = 8;
      paddingHorizontal = 8;
      iconSize = 16;
      break;
  }

  /* Handle the radius */
  switch (props.rounded) {
    default:
    case "full":
      radius = "999px";
      paddingHorizontal = paddingVertical;
      break;
    case "l":
    case "m":
    case "s":
      radius = "8px";
      break;
  }

  if (props.fullWidth) {
    width = "100%";
  }

  const transformPadding = (vertical, horizontal) => {
    return theme.spacing(vertical / 8, horizontal / 8);
  };

  let style = {};

  style = Object.assign(style, {
    color: buttonPalette.color,
    ...(buttonPalette.background.default
      ? { backgroundColor: buttonPalette.background.default }
      : {}),
    borderRadius: radius,
    height: height,
    fontSize: iconSize,
    width: width,
    minWidth: minWidth,
    minHeight: minHeight,
    padding: transformPadding(paddingVertical, paddingHorizontal),

    "& .MuiIconButton-label > *:first-child": {
      fontSize: iconSize,
    },
    "&:active": {
      ...(buttonPalette.background?.active
        ? {
            backgroundColor: buttonPalette.background.active,
            color: isLight(buttonPalette.background.active)
              ? theme.palette.colors.blackMedium
              : theme.palette.colors.white,
          }
        : {}),
    },
  });

  style = Object.assign(style, {
    "&:hover, &.MuiIconButton-colorSecondary:hover": {
      background: !["tertiary", "ghost"].includes(props.color)
        ? `linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), ${buttonPalette.background.hovered}`
        : buttonPalette.background.hovered,
      boxShadow: "none",
    },
    "&.Mui-disabled": {
      color: buttonPalette.color,
      opacity: buttonPalette.opacity.disabled,
      ...(buttonPalette.background.disabled
        ? { backgroundColor: buttonPalette.background.disabled }
        : {}),
    },
  });

  return style;
};

export default ThemedIconButton;
