import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class Step extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Step;
  }

  setWorkshop(Workshop) {
    this.workshop = Workshop.id;
  }

  setSession(Session) {
    this.session = Session.id;
  }
}

Step.modelName = "Step";
Step.backendName = "step";

Step.fields = {
  id: attr(),
  title: attr(),
  description: attr(),
  duration: attr(),
  settings: attr(),
  created_at: attr(),
  started_at: attr(),
  ended_at: attr(),
  workshop: fk("Workshop", "steps"),
  session: fk("EventSession", "steps"),
};

export default Step;
