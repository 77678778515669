export const RESOURCES_SET = "wisembly-next/GLOBAL_ACTIONS/RESOURCES_SET";
export const RESOURCE_UNSET = "wisembly-next/GLOBAL_ACTIONS/RESOURCE_UNSET";
export const RESOURCES_STORE_UPDATE =
  "wisembly-next/GLOBAL_ACTIONS/RESOURCES_STORE_UPDATE";
export const RESOURCE_FETCH = "loop/globalSagas/RESOURCE_FETCH";
export const RESOURCES_FETCH = "loop/globalSagas/RESOURCES_FETCH";
export const RESOURCE_CREATE = "loop/globalSagas/RESOURCE_CREATE";
export const RESOURCES_CREATE = "loop/globalSagas/RESOURCES_CREATE";
export const RESOURCE_EDIT = "loop/globalSagas/RESOURCE_EDIT";
export const RESOURCES_EDIT = "loop/globalSagas/RESOURCES_EDIT";
export const RESOURCE_DELETE = "loop/globalSagas/RESOURCE_DELETE";
export const RESOURCES_DELETE = "loop/globalSagas/RESOURCES_DELETE";
