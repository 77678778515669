import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { _t } from "i18n";
import { withScript } from "hoc/ScriptLoader";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import VolumeOff from "@material-ui/icons/VolumeOff";
import AppLoader from "appComponents/AppLoader";


const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    width: "100%",
    height: "100%",
    "& .vjs-big-play-button": {
      top: "50% !important",
      left: "50% !important",
      marginTop: "-1em !important",
      marginLeft: "-1em !important",
    },
    "& .video-js.vjs-playing": {
      pointerEvents: "none",
    },
    "& .vjs-tech": {
      pointerEvents: "none",
    },
  },
  muteAlert: {
    visibility: ({ videoMuted }) => (videoMuted ? "visible" : "hidden"),
    position: "absolute",
    top: "10px",
    left: "25%",
    right: "25%",
    color: theme.palette.text.primary,
    cursor: "pointer",
    backgroundColor: theme.palette.background.box,
    "& .MuiAlert-icon ": {
      color: theme.palette.text.primary,
    },
  },
}));

const VideoPlayer = ({
  scriptsLoaded,
  scriptsLoadedSuccessfully,
  src,
  type,
  autoPlay,
  noControls,
}) => {
  const ref = useRef(null);
  const [player, setPlayer] = useState(null);
  const [videoMuted, setVideoMuted] = useState(false);
  const [stylePath, setStylePath] = useState(
    "assets/vendors/videojs/videojs-7.11.4.min.css"
  );
  const classes = useStyles({ player, videoMuted });

  const playMuted = () => {
    player.muted(true);
    player.play();
  };

  // ensure to load videojsplayer only once
  useEffect(() => {
    if (window._playerCss) return;

    const head = document.head;
    const link = document.createElement("link");

    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = stylePath;

    head.appendChild(link);
    window._playerCss = true;
  }, [stylePath]);

  useEffect(() => {
    if (!ref.current) return;
    if (!scriptsLoadedSuccessfully) return;

    const options = {
      controls: true,
      preload: "auto",
      fill: true,
      sources: [
        {
          src,
          type: type ? type : "application/x-mpegURL",
        },
      ],
    };

    if (autoPlay) options.autoplay = true;

    if (noControls) {
      options.controlBar = {
        playToggle: false,
        captionsButton: false,
        chaptersButton: false,
        subtitlesButton: false,
        remainingTimeDisplay: false,
        progressControl: {
          seekBar: false,
        },
        fullscreenToggle: false,
        playbackRateMenuButton: false,
      };
    }

    setPlayer(
      videojs(ref.current, options, () => {
        console.log("player ready !");
      })
    );
  }, [scriptsLoaded, scriptsLoadedSuccessfully]);

  const volumeEventHandler = () => setVideoMuted(player.muted());

  useEffect(() => {
    if (!player) return;

    try {
      player.play().catch(function (error) {
        console.log("Autoplay is prevented, fallback to muted autoplay", error);
        playMuted();
      });
    } catch (error) {
      /* We do this try/catch to prevent outdated browsers like IE to crash ( as they dont support promises ) */
      console.log(
        "Promise not supported, take any unnecessary risks, start muted"
      );
      playMuted();
    }
    player.on("volumechange", volumeEventHandler);
    return () => {
      player.off("volumechange", volumeEventHandler);
    };
  }, [player]);

  // destroy player on unmount
  useEffect(() => {
    return () => {
      if (!player) return;
      player.dispose();
    };
  }, []);

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856

  if(!scriptsLoadedSuccessfully) return <AppLoader>{_t("Loading Wiz")}</AppLoader>
  return (
    <div data-vjs-player>
      <Alert
        className={classes.muteAlert}
        icon={<VolumeOff fontSize="inherit" />}
        onClick={() => player.muted(false)}
      >
        {_t("Audio is muted, click here to enable it!")}
      </Alert>
      <video
        ref={ref}
        className={clsx("video-js", "vjs-theme-city", classes.root)}
        controls
        playsInline
        disablePictureInPicture
      />
    </div>
  );
};

export default withScript(["assets/vendors/videojs/videojs-7.11.4.min.js"])(
  VideoPlayer
);
