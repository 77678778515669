import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { _t } from "core/i18n";

import { getKeyword } from "applicationDucks/selectors";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { resourceFetch, resourceDelete } from "ressourcesDucks/actions";

import * as Sentry from "@sentry/react";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { IconButton } from "appComponents/Mui";
import { Delete } from "appComponents/Actions";

const useStyles = makeStyles((theme) => ({
  list: {
    padding: 0,
    backgroundColor: theme.palette.secondary.contrastText,
    borderRadius: "5px",
  },
  item: {
    padding: theme.spacing(1.25, 2),
  },
  label: {
    fontSize: "14px",
  },
}));

const OptionsMenu = ({ template, handleOnClick, onEdit }) => {
  const classes = useStyles();
  //const handleSurveyState = useSurveyManager(survey);
  //{surveys:[]}
  // const liveLeaderBoard = useLiveLeaderboard();
  // const handleLeaderboard = useLeaderBoardManager();

  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const deleteTemplate = () => {
    dispatch(
      resourceDelete(template, {
        slug: ["event", keyword, "mailer-templates", template.id],
        silent: true,
        callback: (error, response) => {
          if (error) {
            Sentry.captureException(error);
            //// TODO: error display
          }
        },
      })
    );
    if (template.template_key !== "custom_email") {
      /* If we reset, we call back the api to get back the prototype */
      dispatch(
        resourceFetch("template", {
          slug: ["event", keyword, "mailer-templates"],
          query: [],
          XHR_ID: `event_templates_${keyword}`,
          silent: true,
          callback: (error, response) => {
            if (error) {
              return;
            }
          },
        })
      );
    }
  };

  const getOptions = () => {
    const configuration = {
      action: () => {
        //handleSurveyState("display");
        //dispatch(updateAdminPreview({}));
        onEdit(true);
        //cancel preview if available
        handleOnClick();
      },
      wording: _t("Update email"),
    };
    return { configuration };
  };

  const options = getOptions();

  return (
    <List
      className={clsx("OptionsMenuMail", classes.list)}
      dense
      disablePadding
    >
      {Object.keys(options).map((option) => {
        const data = options[option];
        return (
          <ListItem
            key={option}
            button
            onClick={data.action}
            className={classes.item}
          >
            <ListItemText className={classes.label} primary={data.wording} />
          </ListItem>
        );
      })}
      {template.is_prototype === false && (
        <Delete onClick={deleteTemplate}>
          <ListItem onClick={() => {}} button className={classes.item}>
            <ListItemText
              className={classes.label}
              primary={
                template.template_key === "custom_email"
                  ? _t("Delete email")
                  : _t("Reset email")
              }
            />
          </ListItem>
        </Delete>
      )}
    </List>
  );
};

OptionsMenu.propTypes = {
  template: PropTypes.object,
  handleOnClick: PropTypes.func,
};

OptionsMenu.defaultProps = {
  handleOnClick: () => {},
  template: {},
};

export default OptionsMenu;
