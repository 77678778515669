import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import clsx from "clsx";
import { _t } from "i18n";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Menu from "@material-ui/core/Menu";
import Box from "@material-ui/core/Box";

import { useTheme } from "@material-ui/core/styles";

import Button from "coreUiKit/inputs/Button";

import DatePicker from "sassy-datepicker";

import SelectedTimezone from "./SelectedTimezone";
import SelectedTime from "./SelectedTime";
import SelectedDate from "./SelectedDate";

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: "5px",
    borderRadius: "10px",
  },
  display: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  calendar: {
    flex: "1",
    width: "50%",
    margin: "30px",
  },
  divider: {
    left: "50%",
    top: "10%",
    bottom: "40%",
    margin: "30px 0",
    borderLeft: "1px solid " + theme.palette.colors.greyLight,
  },
  rightSide: {
    flex: "1",
    width: "50%",
    margin: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  form: {
    flex: "1",
    alignItems: "flex-start",
    marginBottom: "28px",
  },
}));

const PopoverDatePicker = ({
  anchorEl,
  onClickOutside,
  open,
  date,
  onSetDate,
  maxDate,
  minDate,
  disableHours,
  disableDate,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const classes = useStyles();
  const theme = useTheme();

  const handleClose = () => {
    onClickOutside();
  };

  const id = open ? "simple-popover" : undefined;

  const getHours = () => {
    if (!date) return dayjs().toDate().getHours();
    let hours = dayjs(date).toDate().getHours();
    if (hours.length < 2) hours = "0".repeat(2 - hours.length) + hours;
    return hours;
  };

  const getMinutes = () => {
    if (!date) return dayjs().toDate().getMinutes();
    let minutes = dayjs(date).toDate().getMinutes();
    if (minutes.length < 2) minutes = "0".repeat(2 - minutes.length) + minutes;
    return minutes;
  };

  const [day, setDay] = useState(dayjs());
  const [dayError, setDayError] = useState(false);
  const [hours, setHours] = useState(getHours);
  const [hoursError, setHoursError] = useState(false);
  const [minutes, setMinutes] = useState(getMinutes);
  const [minutesError, setMinutesError] = useState(false);
  const [timezones, setTimezone] = useState(dayjs.tz.guess());
  const [timezoneError, setTimezoneError] = useState(false);

  const onDayChange = (newDate) => {
    setDay(dayjs(newDate));
    setDayError(false);
  };

  useEffect(() => {
    setDay(dayjs(date));
  }, [date])

  const onValide = () => {
    day !== null ? "" : setDayError(true);
    hours !== null ? "" : setHoursError(true);
    minutes !== null ? "" : setMinutesError(true);
    timezones !== null ? "" : setTimezoneError(true);

    if (
      day !== null &&
      hours !== null &&
      minutes !== null &&
      timezones !== null
    ) {
      onSetDate(day.hour(hours).minute(minutes).tz(timezones));
      handleClose();
    }
  };

  const datePickerProps = {
    ...(maxDate ? { maxDate: maxDate.toDate() } : null),
    ...(minDate ? { minDate: minDate.toDate() } : null)
  };


  return (
    <Menu
      className={classes.popover}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box className={classes.display}>
        <Box className={classes.calendar}>
          <DatePicker
            onChange={onDayChange}
            selected={day.toDate()}
            style={{ "--theme-color": theme.palette.colors.primary }}

            language={"fr"}
            {...datePickerProps}
          />
        </Box>
        {!disableDate && !disableHours && <Box className={classes.divider} />}
        <Box className={clsx("DatePickerRight", classes.rightSide)}>
          <Box className={classes.form}>
            {!disableDate && <SelectedDate day={day} dayError={dayError} />}
            {!disableHours && <SelectedTime
              hours={hours}
              setHours={setHours}
              minutes={minutes}
              setMinutes={setMinutes}
              setErrorHours={setHoursError}
              setErrorMinutes={setMinutesError}
              hoursError={hoursError}
              minutesError={minutesError}
            />}
            {/* <SelectedTimezone
              setTimezone={setTimezone}
              timezone={timezones}
              timezoneError={timezoneError}
              setTimezoneError={setTimezoneError}
            /> */}
          </Box>
          <Button onClick={onValide}>{_t("Validate")}</Button>
        </Box>
      </Box>
    </Menu>
  );
};

PopoverDatePicker.propTypes = {
  anchorEl: PropTypes.object,
  setAnchorEl: PropTypes.func,
};

PopoverDatePicker.defaultProps = {
  anchorEl: null,
  setAnchorEl: () => { },
};

export default PopoverDatePicker;