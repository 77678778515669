import React from "react";
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Popper from "appComponents/Mui/Popper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.contrastText,
    borderRadius: "5px",
    boxShadow: theme.palette.shadow,
  },
  addBox: {
    background: theme.palette.button.primary.background.enable,
    color: theme.palette.button.primary.color.default,
    borderRadius: "999px",
    width: "24px",
    height: "24px",
  },
  option: {
    cursor: "pointer",
    padding: "10px",
  },
}));

const AddChipPopper = ({ options, onSelect, open, onClose, anchorEl }) => {
  const classes = useStyles();

  const handleClickAway = () => {
    onClose();
  };

  return (
    <Popper anchorEl={anchorEl} open={open} placement="right">
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box className={classes.root}>
          {options.map(([type, label]) => (
            <Box
              onClick={() => onSelect(type)}
              className={classes.option}
              key={type}
            >
              {label}
            </Box>
          ))}
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};

export default AddChipPopper;
