import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { Select } from "app/views/components/Mui";
import { ButtonBase } from "@material-ui/core";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import e from "cors";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "flex",
    justifyContent: "space-between",
    gap: 20,
    marginBottom: "16px",
  },
  select: {
    width: "300px",
  },
}));

const Form = ({ formValue, setFormValue }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValue((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const { email, scope } = formValue;
  return (
    <Box className={classes.line}>
      <Input style={{ width: "200px !important" }} name="email" value={email} onChange={(e) => handleChange(event)} placeholder={"example@wisembly.com"} />
      <Select
        className={classes.select}
        value={scope}
        onChange={(e) => handleChange(event)}
        name="scope"
        options={[
          { value: "vendor", label: _t("Vendor") },
          { value: "admin", label: _t("Admin") },
        ]}
      />
    </Box>
  );
};

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
