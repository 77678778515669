import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  VISIBLE_ELEMENT_PRESENT,
  EVENT_CHAT_DISPLAY_INDICATOR_NEW_MESSAGES,
  EVENT_RESET_CHAT_UNREAD_INDICATOR,
  EVENT_CHAT_SCROLLDOWN_TRIGGER,
  EVENT_RESET_SIZE_LIST_CHAT,
  EVENT_RESET_CHAT_HEIGHT,
} from "utilities/emitter/events";
import emitter from "utilities/emitter";
import Box from "@material-ui/core/Box";
import ChatBubbleLight from "./ChatBubbleLight";
import ChatBubbleLightContainer from "../containers/ChatBubbleLight";
import { QuoteProptypes } from "orm/resources/quote";
import Indicator from "../uikit/component/NewMessage/Indicator";
import CircularProgress from "coreUiKit/miscs/Progress";
import memo, { renderLog } from "core/memo";
import InfiniteScrollListBeta from "./ReverseInfiniteScrollList";
import random from "lodash/random";

const useStyles = makeStyles(
  () => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%",
      maxHeight: "100%",
      boxSizing: "border-box",
      position: "relative",
    },
    list: {
      flex: 1,
      boxSizing: "border-box",
      width: "100%",
      height: (props) => (props.height ? `${props.height}px` : "auto"),
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
    },
    progress: {
      position: "absolute",
      top: 10,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "row",
    },
  }),
  {
    name: "ChatList",
  }
);

const List = ({ Element, elements, total, fetchData }) => {
  const ref = useRef(null);

  const [atBottom, setAtBottom] = useState(false);
  const [height, setHeight] = useState(0);
  const classes = useStyles({ height });
  const [width, setWidth] = useState(260);
  const [hasNewQuotes, setHasNewQuotes] = useState(0);
  const [loading, showLoading] = useState(false);
  const [resize, setResize] = useState(0);
  const [countPresentElements, setCountPresentElements] = useState(0);

  const getListSize = () => {
    const newHeight = ref.current.clientHeight;
    setHeight(newHeight);
    const newWidth = ref.current.clientWidth;
    if (newWidth !== width) {
      setWidth(newWidth);
    }
  };
  // Get 'width' and 'height' after the initial render and every time the list changes
  useEffect(() => {
    getListSize();
  }, []);

  useEffect(() => {
    const callback = (count) => {
      setCountPresentElements(count);
      getListSize();
    };
    emitter.on(VISIBLE_ELEMENT_PRESENT, callback);
    return () => emitter.off(VISIBLE_ELEMENT_PRESENT, callback);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", getListSize);
    return () => {
      window.removeEventListener("resize", getListSize);
    };
  }, []);

  useEffect(() => {
    const onDisplayIndicator = ({ count }) => {
      setHasNewQuotes(count);
    };

    const resetHasNewQuotes = () => {
      setHasNewQuotes(0);
    };

    const resetChatBubbleSize = () => {
      console.log(" i remove resetChatBubbleSize");
      //setResize(random(0, 10));
    };
    const resetHeight = (height) => {
      setHeight((previous) => {
        return height;
      });
    };
    emitter.on(EVENT_CHAT_DISPLAY_INDICATOR_NEW_MESSAGES, onDisplayIndicator);
    emitter.on(EVENT_RESET_CHAT_UNREAD_INDICATOR, resetHasNewQuotes);
    emitter.on(EVENT_RESET_SIZE_LIST_CHAT, resetChatBubbleSize);
    emitter.on(EVENT_RESET_CHAT_HEIGHT, resetHeight);
    return () => {
      emitter.off(EVENT_CHAT_DISPLAY_INDICATOR_NEW_MESSAGES, onDisplayIndicator);
      emitter.off(EVENT_RESET_CHAT_UNREAD_INDICATOR, resetHasNewQuotes);
      emitter.off(EVENT_RESET_SIZE_LIST_CHAT, resetChatBubbleSize);
      emitter.off(EVENT_RESET_CHAT_HEIGHT, resetHeight);
    };
  }, []);

  const handleDisplayNewMessages = () => {
    emitter.emit(EVENT_CHAT_SCROLLDOWN_TRIGGER, { newQuotes: hasNewQuotes });
  };

  const _fetchData = () => {
    showLoading(true);
    fetchData();
  };

  useEffect(() => {
    if (loading) {
      showLoading(false);
    }
  }, [elements.length]);

  if (!Element) return null;

  return (
    <Box className={classes.root}>
      {hasNewQuotes > 0 && !atBottom && <Indicator count={hasNewQuotes} onClick={handleDisplayNewMessages} />}
      {loading && (
        <Box className={classes.progress}>
          <CircularProgress />
        </Box>
      )}
      <Box id="chatListRoot" ref={ref} className={classes.list}>
        <InfiniteScrollListBeta
          fetchData={_fetchData}
          elements={elements}
          ItemComponent={Element}
          total={elements.length}
          height={height}
          width={width}
          reverse={true}
          variableSize={true}
          scrollDownEvent={EVENT_CHAT_SCROLLDOWN_TRIGGER}
          separatorEvent={EVENT_CHAT_DISPLAY_INDICATOR_NEW_MESSAGES}
          onScrollDownEvent={() => emitter.emit(EVENT_RESET_CHAT_UNREAD_INDICATOR)}
          onReachBottom={(bottom) => {
            setAtBottom(bottom);
            if (bottom) {
              emitter.emit(EVENT_RESET_CHAT_UNREAD_INDICATOR);
            }
          }}
          resize={resize}
        />
      </Box>
    </Box>
  );
};

List.propTypes = {
  Element: PropTypes.oneOf([ChatBubbleLight, ChatBubbleLightContainer]),
  total: PropTypes.number,
  elements: PropTypes.arrayOf(PropTypes.shape(QuoteProptypes)),
  fetchData: PropTypes.func,
  reverse: PropTypes.bool,
  variableSize: PropTypes.bool,
};
List.defaultProps = {
  Element: ChatBubbleLight,
  elements: [],
  total: 0,
  fetchData: () => {},
};

export default memo(List);
