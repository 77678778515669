import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { setReplayOverlay } from "app/state/ducks/liveShopping/actions";
import { mobileLayoutSelector, replayOverlaySelector } from "app/state/ducks/liveShopping/selectors";
const useStyles = makeStyles((theme) => ({
  tapzone: {
    width: "100%",
    height: "calc(100% - 200px)",
    zIndex: 1,
    position: "absolute",
  },
}));

const TapZone = ({}) => {
  const replayOverlay = useSelector(replayOverlaySelector);
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ replayOverlay, isMobile });
  const dispatch = useDispatch();

  if (replayOverlay) return null;

  return <Box className={clsx("TapZone", classes.tapzone)} onClick={() => dispatch(setReplayOverlay(true))} />;
};

TapZone.propTypes = {};

TapZone.defaultProps = {};

export default TapZone;
