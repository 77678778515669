import URI from "urijs";

import { getResourceUrl } from "core/jsonApi";

export default function makeUrl(params, options) {
  let url = options.slug
    ? getResourceUrl(...options.slug)
    : getResourceUrl(params);

  if (options.query && Array.isArray(options.query)) {
    url = new URI(url);

    options.query.forEach((search) => url.addSearch(search.key, search.value));
    return url.toString();
  }

  if (options.query) {
    return `${url}?${options.query}`;
  }

  return url;
}
