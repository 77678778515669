import React, { useState, forwardRef } from "react";
import ControlledTextField from "../OutlinedTextField";
import PropTypes from "prop-types";

const OutlinedTextField = forwardRef(({ placeholder, onChange, ...rest }, ref) => {
  const [value, setValue] = useState("");
  const change = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return <ControlledTextField ref={ref} placeholder={placeholder} value={value} onChange={change} {...rest} />;
});
OutlinedTextField.displayName = "OutlinedTextField";
OutlinedTextField.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
export default OutlinedTextField;
