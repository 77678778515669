import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import { _t } from "i18n";
import SurveyAlert from "appComponents/Alert/SurveyAlert";
//import * as Sentry from "@sentry/react";

import {
  SurveySubmitSection,
  PaginatedFormViewer,
  canUserSeeEvaluationAnswer,
  ActionPanel,
} from "appComponents/SurveyForms";

import { ShowSurveyItemAnswersSummary } from "appComponents/Viewers";

import UserResponse from "appContainers/Viewers/UserResponse";
import ErrorBoundary from "appComponents/NotFound/ErrorBoundary";

import memo, { renderLog } from "core/memo";

const InteractivitySurveyComponent = ({
  classes,
  panel,
  setPanel,
  isAnswerableForm,
  survey,
  isThumbnailView,
  layoutMode,
  answers,
  formSchema,
  surveyItem,
  surveyHash,
  actionDispatcher,
}) => {
  // TODO: remove
  renderLog("InteractivitySurveyComponent");

  const userResponseDisplay = panel == "userResponse";

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
    >
      <Box
        display="flex"
        position="relative"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        className={clsx("SurveyPresentation", classes.root)}
      >
        <Box
          display="flex"
          flexDirection="column"
          className={clsx("SurveyContent", classes.surveyContent)}
        >
          <ActionPanel panel={panel} classes={classes} setPanel={setPanel} />

          {!userResponseDisplay && isAnswerableForm && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={clsx("SurveyFormSection", classes.surveyItems)}
            >
              <PaginatedFormViewer
                survey={survey}
                isThumbnailView={isThumbnailView}
                layoutMode={layoutMode}
                alreadyAnswer={!isEmpty(answers)}
                formSchema={formSchema}
                canUserSeeAnswer={canUserSeeEvaluationAnswer(survey)}
              />
            </Box>
          )}

          {!userResponseDisplay && !isAnswerableForm && (
            <Box flexDirection="column" className={classes.surveyItemsAnswers}>
              <ShowSurveyItemAnswersSummary
                surveyItem={
                  Array.isArray(surveyItem) ? surveyItem[0] : surveyItem
                }
                survey={survey}
                layoutMode={layoutMode}
                isThumbnailView={isThumbnailView}
                showSubmitSection
              />
            </Box>
          )}

          {userResponseDisplay && (
            <Box
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              className={clsx(classes.userResponsePanel)}
            >
              <ErrorBoundary>
                <UserResponse
                  survey={survey}
                  isThumbnailView={isThumbnailView}
                />
                <SurveySubmitSection
                  isThumbnailView={isThumbnailView}
                  surveyHash={surveyHash}
                />
              </ErrorBoundary>
            </Box>
          )}
        </Box>
      </Box>

      <SurveyAlert
        layoutMode={layoutMode}
        surveyHash={surveyHash}
        section={actionDispatcher === "timeline" ? "timeline" : "present"}
      />
    </Box>
  );
};

InteractivitySurveyComponent.propTypes = {
  classes: PropTypes.object,
  answerSurveryStatus: PropTypes.string,
  panel: PropTypes.string,
  setPanel: PropTypes.func,
  isAnswerableForm: PropTypes.bool,
  survey: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
  answers: PropTypes.array,
  formSchema: PropTypes.object,
  surveyItem: PropTypes.object,
  surveyHash: PropTypes.string,
  displaySubmit: PropTypes.bool,
  actionDispatcher: PropTypes.string,
};

//export default React.memo(Component, areEquals);
export default memo(InteractivitySurveyComponent);
