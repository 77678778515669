import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase, Typography, useTheme } from "@material-ui/core";
import PlayCircle from "react-bootstrap-icons/dist/icons/play-circle";
import { _t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { setPlay } from "app/state/ducks/liveShopping/actions";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    zIndex: 8,
    background: "rgba(0,0,0,.4)",
    cursor: "pointer",
  },
  playButton: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    borderRadius: "99px",
  },
  join: {
    color: "#ffffff",
    fontSize: "18px",
    fontWeight: "bolder",
    marginTop: "15px",
  },
}));

const PlayLayout = ({}) => {
  const classes = useStyles();
  const isPlayed = useSelector((state) => state.liveShopping.isPlayed);
  const wiz = useSelector(currentWizSelector);
  const theme = useTheme();
  const dispatch = useDispatch();

  const handlePlay = () => {
    dispatch(setPlay(true));
  };
  if (isPlayed) return null;
  return (
    <Box className={classes.root} id="buttonPlayerId" onClick={handlePlay}>
      <ButtonBase className={classes.playButton}>
        <PlayCircle size={70} color={theme.palette.colors.white} />
        {wiz.status === "live" && <Typography className={classes.join}>{_t("Join the Live")}</Typography>}
      </ButtonBase>
    </Box>
  );
};

PlayLayout.propTypes = {};

// PlayLayout.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: "PlayLayout",
// };

PlayLayout.defaultProps = {};

export default PlayLayout;
