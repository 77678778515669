import React from "react";
import PropTypes from "prop-types";

const CustomArrowClockPlus = ({ color, size, ...rest }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M17.5016 5.0097V6.26082C15.139 6.36229 12.8575 7.1748 10.959 8.59774C8.92686 10.1208 7.44289 12.2612 6.72933 14.6984C6.01576 17.1356 6.11101 19.7384 7.0008 22.117C7.8906 24.4955 9.52704 26.5217 11.665 27.8922C13.803 29.2626 16.3275 29.9035 18.8603 29.7189C21.393 29.5343 23.7979 28.534 25.7145 26.868C27.6311 25.2019 28.9564 22.9598 29.4918 20.4774C30.0264 17.9987 29.7449 15.4138 28.6894 13.1085C28.6312 12.9613 28.6311 12.7973 28.6891 12.6498C28.7483 12.4997 28.8632 12.3782 29.0098 12.3109C29.1565 12.2436 29.3235 12.2355 29.4759 12.2885C29.6256 12.3405 29.7498 12.4474 29.8235 12.5874C30.9917 15.1383 31.3033 17.9989 30.7115 20.7417C30.1189 23.4882 28.6524 25.9689 26.5317 27.8121C24.411 29.6552 21.7501 30.7617 18.9478 30.9657C16.1454 31.1697 13.3524 30.4603 10.987 28.9437C8.62172 27.4271 6.81143 25.1849 5.82733 22.5531C4.84323 19.9213 4.73831 17.0415 5.52827 14.3451C6.31823 11.6486 7.96056 9.28071 10.2092 7.596C12.3243 6.01141 14.8687 5.11147 17.5016 5.0097Z" />
      <path d="M18 10.0485V1.20151C18 1.09463 18.0305 0.989968 18.0879 0.899788C18.1453 0.809608 18.2272 0.73764 18.3239 0.692311C18.4207 0.646983 18.5284 0.63017 18.6345 0.643843C18.7405 0.657515 18.8404 0.701106 18.9225 0.769511L24.2325 5.19301C24.5025 5.41801 24.5025 5.83201 24.2325 6.05701L18.9225 10.4805C18.8404 10.5489 18.7405 10.5925 18.6345 10.6062C18.5284 10.6199 18.4207 10.603 18.3239 10.5577C18.2272 10.5124 18.1453 10.4404 18.0879 10.3502C18.0305 10.2601 18 10.1554 18 10.0485Z" />
      <path d="M8.76562 19.7979H10.875V21.8438H12.1592V19.7979H14.2734V18.5137H12.1592V16.4678H10.875V18.5137H8.76562V19.7979Z" />
      <path d="M17.3154 22H18.79V14.9541H17.3203L15.499 16.2188V17.5469L17.2275 16.3457H17.3154V22Z" />
      <path d="M23.4189 22.1807C25.1621 22.1807 26.2168 20.7549 26.2168 18.4795V18.4697C26.2168 16.1895 25.1621 14.7734 23.4189 14.7734C21.6807 14.7734 20.6211 16.1895 20.6211 18.4697V18.4795C20.6211 20.7549 21.6807 22.1807 23.4189 22.1807ZM23.4189 21.0137C22.5889 21.0137 22.1152 20.0713 22.1152 18.4795V18.4697C22.1152 16.873 22.5889 15.9404 23.4189 15.9404C24.2539 15.9404 24.7275 16.873 24.7275 18.4697V18.4795C24.7275 20.0713 24.2539 21.0137 23.4189 21.0137Z" />
    </svg>
  );
};

CustomArrowClockPlus.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomArrowClockPlus.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomArrowClockPlus;
