import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Input from "../reusables/Input";
import Button from "../reusables/Button";
import { useDispatch, useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import ResourceFactory from "orm/resources";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "130%",
    cursor: "pointer",
    textDecoration: "underline",
  },
}));

const VideoField = ({ }) => {
  const classes = useStyles();
  const wiz = useSelector(currentWizSelector);
  const [link, setLink] = useState(wiz.extra_settings?.stream_key ? "" : wiz.live_media.url || "");
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(wiz.live_media.enabled);
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const [displayed, setDisplayed] = useState(wiz.live_media.url && !wiz.extra_settings?.stream_key || false);

  const handleSave = () => {

    // check with regex that url starts with https and ends with .m3u8
    const regex = new RegExp("^https://.*.m3u8$");
    if (!regex.test(link)) {
      emitter.emit(EVENT_FLASH_ERROR, _t("Your link is not valid"));
      return;
    }

    dispatch(
      resourceEdit(
        Object.assign(wiz, {
          live_media: { url: link, enabled: false },
        }),
        {
          slug: ["event", wiz.keyword],
          silent: true,
          patch: ["live_media"],

          callback: (error, response) => {
            if (error) {
              emitter.emit(EVENT_FLASH_ERROR, _t("An error has been detected"));
              return;
            }
            emitter.emit(EVENT_FLASH_SUCCESS, _t("Your video link has been saved"));
          },
        }
      )
    );
  };

  useEffect(() => {
    const newEvent = resourceFactory.create("Event", { live_media: { enabled: isChecked } });
    dispatch(
      resourceEdit(newEvent, {
        slug: ["event", wiz.keyword],
        silent: true,
        patch: ["live_media"],
      })
    );
  }, [isChecked]);

  return (
    <Box className={classes.root}>
      <Box onClick={() => setDisplayed(!displayed)}>
        <Typography className={classes.title}>{_t("I have already my own provider, I enter my own .m3u8 https valid url")}</Typography>
      </Box>
      {displayed && (
        <>
          <Input placeholder="Provider link" value={link} onChange={(e) => setLink(e.target.value)} />
          <Button color={link.length === 0 && "#E1E1E1"} onClick={handleSave} disabled={link.length === 0}>
            {_t("Save")}
          </Button>
        </>
      )}

    </Box>
  );
};

VideoField.propTypes = {};

VideoField.defaultProps = {};

export default VideoField;
