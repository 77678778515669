import React from "react";
import PropTypes from "prop-types";
import { getInitialsFromUser, getInitialsFromString } from "./utils";
import { get } from "lodash";
import AvatarInitials from "./AvatarInitials";

const getInitials = (user) => {
  switch (true) {
    case !user:
      return "";
    case user.name:
      return getInitialsFromString(user.name);
    default:
      return getInitialsFromUser(user);
  }
};

// • L’user est anonyme → Icon eye + fond gris
// • L’user a son identité révélée et a une photo de profil → Photo de profil
// • L’user a son identité révélée mais n’a pas de photo de profil → Fond jaune + initiales
// • L’user est un admin qui parle au nom de l’event → Logo de l’event

const Avatar = ({ user, children, size }) => {
  const anonyme = !user || user?.isAnon();
  const initials = getInitials(user);
  const avatar = get(user, "avatar.external", false) || get(user, "avatar.128x128", false);

  return (
    <AvatarInitials anonymous={anonyme} initials={initials} avatar={avatar} size={size}>
      {children}
    </AvatarInitials>
  );
};

Avatar.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "medium", "mediumlarge", "large"]),
};

Avatar.defaultProps = {
  children: null,
  size: "small",
};

export default Avatar;
