import React from "react";
import PropTypes from "prop-types";

const CustomSendIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.6451 5.35381C29.6451 5.35381 29.4947 5.21832 29.3307 5.11856C29.1667 5.01879 28.8828 5.00377 28.8828 5.00377C28.6966 4.98902 28.5081 5.01766 28.3336 5.0878L6.00599 14.0161C5.73257 14.1251 5.49458 14.3075 5.31828 14.5432C5.14198 14.7789 5.03422 15.0588 5.0069 15.3518C4.97958 15.6449 5.03375 15.9398 5.16344 16.2041C5.2931 16.4682 5.49336 16.6916 5.74185 16.8492L13.3247 21.6737L18.1496 29.2572C18.3074 29.5052 18.5306 29.7048 18.7946 29.8341C19.0586 29.9634 19.3531 30.0174 19.6458 29.9901C19.9385 29.9627 20.2179 29.8552 20.4534 29.6793C20.6889 29.5034 20.8714 29.2658 20.9806 28.9929L29.9122 6.6647C30.001 6.44408 30.023 6.20224 29.9756 5.96921C29.9281 5.73613 29.8132 5.52214 29.6451 5.35381ZM25.4606 11.2529L19.327 26.5863L15.7489 20.9646L25.4606 11.2529ZM23.7463 9.5354L8.41084 15.6698L14.0338 19.2466L23.7463 9.5354ZM29.3292 5.66924V5.67078L29.3284 5.67001M29.3292 5.66924L29.33 5.67001L29.3307 5.67078"
      />
    </svg>
  );
};

CustomSendIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomSendIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomSendIcon;
