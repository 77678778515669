import { resourceEdit, partialUpdateResource } from "ressourcesDucks/actions";

import { put, select } from "redux-saga/effects";

import { currentWizSelector } from "ressourcesDucks/selectors";
import { visioElementsSelector } from "../selectors";

export function* focus({ stream }) {
  const wiz = yield select(currentWizSelector);
  const currentFocus = wiz.live_media?.focus;
  wiz.live_media = {
    ...wiz.live_media,
    focus: !stream || stream.streamId === currentFocus ? null : stream.streamId,
  };
  yield put(partialUpdateResource({ type: "event", id: wiz.id }, { live_media: wiz.live_media }));
  yield put(
    resourceEdit(wiz, {
      slug: ["event", wiz.keyword, "visio", "focus"],
      silent: true,
      patch: ["live_media"],
    })
  );
}

export function* setElementPosition({ streamId, position }) {
  const elements = yield select(visioElementsSelector);
  const streamVisioElement = elements ? elements[streamId] : null;
  if (position && streamVisioElement && streamVisioElement?.video && streamVisioElement[position]) {
    streamVisioElement[position].appendChild(streamVisioElement.video);
  } else {
    console.error(`Position ${position} is unknown for stream ${streamId}`);
  }
}
