import BaseModel from "./base";
import { attr, oneToOne } from "redux-orm";

class Queue extends BaseModel {
  constructor(args) {
    super(args);
    this.Model = Queue;
  }

  setGroup(Group) {
    this.group = Group.id;
  }
}

Queue.modelName = "Queue";
Queue.backendName = "queue";

Queue.fields = {
  id: attr(),
  avg_waiting_time_seconds: attr(),
  options: attr(),
  group: oneToOne("Group", "queue"),
};

export default Queue;
