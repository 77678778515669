const MSG_HEADER = "wisembly::";

export const emit = (action, { id, quantity, size, cartId, status, url } = {}) => {
  const data = {
    action,
    productId: id,
    cartId,
    quantity,
    size,
    status,
    url,
  };

  const msg = `${MSG_HEADER}${JSON.stringify(data)}`;

  window.parent.postMessage(msg, "*");
};

export const listen = (callback) => {
  window.addEventListener("message", (event) => {
    // warning, Vimeo sends many many events, Metamask too
    if (typeof event?.data !== "string") return;

    // ignore own postMessage events (which are basically sent to partent frame)
    if (event?.origin === window.location.origin) return;

    const dataBlocks = event.data.split("::");

    if (dataBlocks[0] !== "wisembly") return;

    try {
      callback({ status: dataBlocks[1], data: JSON.parse(dataBlocks[2]) });
    } catch (error) {
      console.log("Malformated json postMessage data", event.data);
    }
  });
};
