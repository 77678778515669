import { makeStyles, Tooltip } from "@material-ui/core";
import React from "react";
import InfoCircle from "react-bootstrap-icons/dist/icons/info-circle";

const useStyles = makeStyles((theme) => ({
  info: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
}));

const Info = ({ description }) => {
  const classes = useStyles();
  return (
    <Tooltip className={classes.info} title={description}>
      <InfoCircle />
    </Tooltip>
  );
};

export default Info;