import React, { useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { ActionButton, EmailChipInput } from "appComponents/Mui";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { _t, _n } from "core/i18n";
import { resourceCreate } from "ressourcesDucks/actions";
import { useDispatch, useSelector } from "react-redux";
import ResourceFactory from "orm/resources";
import { getKeyword } from "applicationDucks/selectors";
import emitter from "utilities/emitter";
import { ADMIN_EVENT_FLASH_SUCCESS_VANISH } from "utilities/emitter/events";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  emailSelector: {
    padding: theme.spacing(1),
  },
  sendMailButton: {
    marginTop: theme.spacing(1),
  },
}));

const EmailSelector = ({ sendAction }) => {
  const classes = useStyles();
  const [emails, setEmails] = useState([]);
  const [currentEditingValue, setCurrentEditingValue] = useState("");

  const onClickAction = () => {
    if (currentEditingValue) {
      emails.push(currentEditingValue);
    }
    sendAction(emails);
    emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
      message: _n("{count} mail sent", "{count} mails sent", emails.length, {
        count: emails.length,
      }),
    });
  };

  return (
    <Box className={classes.emailSelector}>
      <EmailChipInput
        onListChange={(tmpMails) => setEmails(tmpMails)}
        reset={false}
        getCurrentFieldValue={(value) => setCurrentEditingValue(value)}
      />
      <ActionButton
        className={classes.sendMailButton}
        title={_t("Send a test")}
        inactiveColor="primary"
        toogleColor={false}
        controlled={true}
        disabled={emails.length === 0 && !currentEditingValue}
        onClick={onClickAction}
      />
    </Box>
  );
};

EmailSelector.propTypes = {
  sendAction: PropTypes.func,
};

EmailSelector.defaultProps = {
  sendAction: () => {},
};

const TestMail = ({ template }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const keyword = useSelector(getKeyword);

  const sendTest = (emails) => {
    /* We push it to the back to be sent*/
    const mailList = resourceFactory.create("Custom", {
      emails,
    });

    const mailerHash = template.is_prototype
      ? template.template_key
      : template.hash;

    dispatch(
      resourceCreate(mailList, {
        slug: ["event", keyword, "mailer-templates", mailerHash, "send-test"],
        silent: true,
      })
    );
  };

  return (
    <Tooltip
      open={open}
      arrow
      placement="bottom-end"
      title={
        <EmailSelector
          sendAction={(emails) => {
            sendTest(emails);
            setOpen(false);
          }}
        />
      }
      interactive={true}
    >
      <ActionButton
        title={_t("Send a test")}
        inactiveColor="secondary"
        toogleColor={false}
        controlled={true}
        onClick={() => setOpen(!open)}
      />
    </Tooltip>
  );
};

TestMail.propTypes = {
  template: PropTypes.object,
};

TestMail.defaultProps = {
  template: {},
};

export default TestMail;
