import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import sortBy from "lodash/sortBy";
import get from "lodash/get";
import map from "lodash/map";
import forEach from "lodash/forEach";
import WordCloud from "appComponents/WordCloud";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  textResult: {
    width: "100%",
    "& > ul": {
      margin: 0,
      paddingLeft: 20,
    },
  },
  textLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: .5,
  },
  bulletPoint: {
    fontSize: 18,
    "&::marker": {
      color: theme.palette.secondary.main,
    },
  },
  tagcloud: {
    height: "100%",
  },
}));

export const TextSurveyItemResultNoLabel = ({ summary, displayType }) => {
  const classes = useStyles();

  switch (displayType) {
    case "tagcloud":
      const words = get(summary, "words", {});

      try {
        return <WordCloud words={words} />;
      } catch (e) {
        console.log("error in WordCloud", e);
      }

      return null;
    default: {
      const list = get(summary, "list", []);

      return (
        <ul>
          {map(list, (answer, key) => (
            <li className={classes.bulletPoint}>
              <Typography key={key} className={classes.textLabel}>
                {answer}
              </Typography>
            </li>
          ))}
        </ul>
      );
    }
  }
};

TextSurveyItemResultNoLabel.propTypes = {
  summary: PropTypes.object,
  displayType: PropTypes.string,
};

const TextSurveyItemResult = ({ item }) => {
  const classes = useStyles();
  const displayType = get(item, "display.type", "list");
  const summary = get(item, "summary", {});

  return (
    <Box
      display="flex"
      flexDirection="column"
      className={clsx("TextSurveyItemResult", classes.root)}
    >
      <Box
        className={clsx(
          classes.textResult,
          displayType === "tagcloud" ? classes.tagcloud : null
        )}
      >
        <TextSurveyItemResultNoLabel
          summary={summary}
          displayType={displayType}
        />
      </Box>
    </Box>
  );
};

TextSurveyItemResult.propTypes = {
  item: PropTypes.object,
};

export default TextSurveyItemResult;
