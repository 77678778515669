import React from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatPrice } from "app/liveShopping/utils";
import { Text } from "@tremor/react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import Detail from "./Detail";
import { _t } from "utilities/i18n";
import dayjs from "dayjs";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: "#F5F5F5",
    color: "#000000",
    fontSize: "14px",
    lineHeight: "130%",
    fontWeight: 700,
    position: "sticky",
    top: 0,
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80%",
    width: "100%",
  },
  seeMore: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    borderRadius: "99px",
  },
}));
const HistoryTable = ({ data }) => {
  const classes = useStyles();
  const currentProject = useSelector(currentProjectSelector);
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead className={classes.head}>
            <TableRow>

              <TableCell>
                {_t('N°')}
              </TableCell>
              <TableCell >
                {_t("Created date")}
              </TableCell>
              <TableCell >
                {_t("Email")}
              </TableCell>
              <TableCell >
                {_t("Handled by")}
              </TableCell>
              <TableCell >
                {_t("Waiting time")}
              </TableCell>
              <TableCell >
                {_t("Conversation time")}
              </TableCell>
              <TableCell >
                {_t("NPS")}
              </TableCell>
              {currentProject.cart_integration && (
                <TableCell >
                  {_t("Total price")}
                </TableCell>
              )}
              <TableCell >
                {_t("See more")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => {
              const waitingDiff = item?.handled_at ? dayjs(item?.handled_at).unix() - dayjs(item.created_at).unix() : dayjs(item?.terminated_at).unix() - dayjs(item.created_at).unix();

              const waitingTime_minutes = Math.floor(waitingDiff / 60);
              const waitingTime_seconds = waitingDiff - waitingTime_minutes * 60;

              const conversationDiff = dayjs(item.terminated_at).unix() - dayjs(item.handled_at).unix();
              const conversationTime_hours = Math.floor(conversationDiff / 3600);
              const conversationTime_minutes = Math.floor(conversationDiff / 60);
              const conversationTime_seconds = Math.floor(conversationDiff - conversationTime_minutes * 60);
              return (
                <TableRow key={index}>
                  <TableCell>
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    <Text>{dayjs(item.created_at).format("YYYY-MM-DD")}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{JSON.parse(item.options)?.form?.email || _t("Not specified")}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{(item.vendor_firstname || " ") + " " + (item.vendor_lastname || " ")}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{`${waitingTime_minutes}min ${waitingTime_seconds}sec`}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{!isNaN(conversationTime_minutes && conversationTime_seconds) ? `${conversationTime_minutes}min ${conversationTime_seconds}sec` : "--"}</Text>
                  </TableCell>
                  <TableCell>
                    <Text>{item.nps_score || "--"}</Text>
                  </TableCell>
                  {currentProject.cart_integration && (
                    <TableCell>
                      <Text>{formatPrice(Math.round(JSON.parse(item.cart)?.total_price / 100), "EUR") || 0}</Text>
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <Detail item={item} />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

      </TableContainer>
      {data?.length === 0 && (
        <Box className={classes.noData}>
          <Text>{_t("There is no data for this period")}</Text>
        </Box>
      )}
    </>
  )
};

export default HistoryTable;
