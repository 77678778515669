import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { ButtonBase } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import Interaction from "../../../../reusables/Notifications/Interaction";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "19px 16px",
    flex: "0 auto",
  },
  btnClose: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    width: "40px",
    height: "40px",
    background: "#F0F0F4",
    borderRadius: "99px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: (props) => (props.isMobile ? "30px" : "20px"),
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
}));

const Header = ({ }) => {
  const history = useHistory();
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const [openModal, setOpenModal] = useState(false);
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Edit this Product")}</Typography>
      <ButtonBase className={classes.btnClose} onClick={() => setOpenModal(true)}>
        <XLg />
      </ButtonBase>
      {openModal && (
        <Interaction
          type={"danger"}
          title={_t("Your modifications will not be saved")}
          subTitle={_t("Click on save to update your live informations")}
          textBtn2={_t("Cancel")}
          textBtn1={_t("Ok")}
          action2={() => setOpenModal(false)}
          action1={() => {
            setOpenModal(false);
            history.goBack();
          }}
        />
      )}
    </Box>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
