import React from "react";
import PropTypes from "prop-types";
import Filters from "../components/Filters";
import { useTags } from "../tagHooks";

const ChatFilter = ({ admin, ...rest }) => {
  const [tags, tagsEnabled] = useTags();

  return <Filters admin={admin} tags={tagsEnabled ? tags : []} {...rest} />;
};

ChatFilter.propTypes = {
  admin: PropTypes.bool,
};
export default ChatFilter;
