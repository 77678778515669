import React, { useState, useEffect, useCallback } from "react";
import { _t, _n } from "core/i18n";
import clsx from "clsx";
import PropTypes from "prop-types";
import emitter from "utilities/emitter";
import {
  ADMIN_EVENT_FLASH_SUCCESS_VANISH,
  ADMIN_EVENT_FLASH_ERROR_VANISH,
} from "utilities/emitter/events";

import Box from "@material-ui/core/Box";
import { EmailChipInput, ActionButton } from "appComponents/Mui";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useRoleUpdater } from "adminComponents/UserManagement/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
  },
  label: {
    marginLeft: "10px",
  },
  textArea: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  action: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "10px",
    width: "100%",
  },
  loading: {
    textAlign: "center",
    minHeight: theme.spacing(7.5),
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  progress: {
    color: theme.palette.secondary.main,
  },
}));
const ManualImport = ({ isAdmin }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [emailsList, setEmailsList] = useState([]);
  const [currentEditingValue, setCurrentEditingValue] = useState("");
  const [resetMailInput, setResetMailInput] = useState(false);

  const updateRole = useRoleUpdater();

  useEffect(() => {
    if (!currentEditingValue && resetMailInput) {
      setResetMailInput(false);
    }
  }, [currentEditingValue, resetMailInput]);
  const getCurrentFieldValue = (value) => {
    setCurrentEditingValue(value);
  };
  const addUserList = () => {
    let finalEmailList = emailsList;
    const callback = () => {
      // prettier-ignore
      emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
        message: _n("{count} user has been added", "{count} users has been added", finalEmailList.length, { count: finalEmailList.length, }),
      });
      setResetMailInput(true);
      setLoading(false);
      //stop loading
    };

    const callbackError = () => {
      emitter.emit(ADMIN_EVENT_FLASH_ERROR_VANISH, {
        message: _t("The Action could not be completed."),
      });
      setResetMailInput(true);
      setLoading(false);
    };
    if (currentEditingValue) {
      finalEmailList.push(currentEditingValue);
    }

    updateRole(
      "create",
      finalEmailList,
      isAdmin ? "modo" : "user",
      callback,
      callbackError,
      false
    );
  };

  const onListChange = (emails) => {
    setEmailsList(emails);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.textArea}>
        <EmailChipInput
          onListChange={onListChange}
          reset={resetMailInput}
          getCurrentFieldValue={getCurrentFieldValue}
        />
      </Box>
      <Box className={classes.action}>
        <ActionButton
          title={_t("Add")}
          badgeContent={0}
          inactiveColor="primary"
          toogleColor={false}
          onClick={() => addUserList()}
          disabled={emailsList.length === 0 && !currentEditingValue}
        />
      </Box>
      <Box className={clsx({ [classes.loading]: loading })} hidden={!loading}>
        <CircularProgress classes={{ root: classes.progress }} />
      </Box>
    </Box>
  );
};

ManualImport.propTypes = {
  handleOpen: PropTypes.func,
  isAdmin: PropTypes.bool,
  setIsAdmin: PropTypes.func,
};

export default ManualImport;
