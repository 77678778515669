import BaseModel from "./base";
import { attr, oneToOne } from "redux-orm";

class LeaderBoard extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = LeaderBoard;
  }
  setEvent(Event) {
    this.event = Event.id;
  }
}

LeaderBoard.modelName = "LeaderBoard";
LeaderBoard.backendName = "leaderboard";

LeaderBoard.fields = {
  boards: attr(),
  id: attr(),
  event: oneToOne("Event", "leaderboard"),
};

export default LeaderBoard;
