import isBoolean from "lodash/isBoolean";
import { getUserSession } from "core/session";
import { currentWizSelector } from "ressourcesDucks/selectors";
import { getUsername } from "applicationDucks/selectors";
import globalStore from "appStore";
import { _t } from "core/i18n";
import basil from "core/basil";

export const getVerifiedUsername = () => {
  const state = globalStore.getState();
  const session = getUserSession();
  const username = getUsername(state);
  const wiz = currentWizSelector(state);
  const allow_username = wiz ? wiz.allow_username : true;

  return hasUsername() && allow_username && !isAnonymousUserParticipation()
    ? username ?? basil.get(`u:${session.token}:username`)
    : null;
};

//this function is based on basil it's faster if you don't want to wait for change in redux
export const isAnonymousUserParticipation = () => {
  if (isBoolean(basil.get("u:anonymousUserParticipation"))) {
    return basil.get("u:anonymousUserParticipation");
  }

  return true;
};

export const updateUsername = (username) => {
  const session = getUserSession();

  if (!username) {
    basil.remove(`u:${session.token}:username`);
  }

  basil.set(`u:${session.token}:username`, username);
};

export const hasUsername = () => {
  const state = globalStore.getState();
  const session = getUserSession();
  const username = getUsername(state);

  return username || basil.get(`u:${session.token}:username`) !== null;

};
