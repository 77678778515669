import React, { memo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import isEqual from "lodash/isEqual";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";

import map from "lodash/map";
import forEach from "lodash/forEach";
import ReactWordcloud from "react-wordcloud";
import ErrorBoundary from "appComponents/NotFound/ErrorBoundaryWithReload";

const useStyles = makeStyles((theme) => ({
  tagCloudBox: {
    [theme.breakpoints.up("sm")]: {
      //width: theme.spacing(40),
      minWidth: theme.spacing(20),
    },
    minWidth: theme.spacing(20),
    //size from which words become visible
    minHeight: theme.spacing(5),
    height: "100%",
  },
}));

const WisembyCloud = ({ words }) => {
  const classes = useStyles();
  const theme = useTheme();
  const options = {
    //rotations: 2,
    colors: [theme.palette.text.primary],
    rotationAngles: [-90, 0],
    rotations: 0,
    fontSizes: [15, 30],
    enableTooltip: false,
    fontWeight: "700",
    padding: 10,
    fontFamily: "Montserrat",
  };
  words = map(words, (value, text) => ({ text, value }));

  return (
    <Box className={clsx("WordCloud", classes.tagCloudBox)}>
      <ErrorBoundary>
        <ReactWordcloud options={options} words={words} />
      </ErrorBoundary>
    </Box>
  );
};

WisembyCloud.propTypes = {
  words: PropTypes.array,
};

const MemoCLoud = memo(WisembyCloud, (prevProps, nextProps) =>
  isEqual(prevProps.words, nextProps.words)
);
export default MemoCLoud;
