import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { _n, _t } from "utilities/i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import ArrowDownShort from "react-bootstrap-icons/dist/icons/arrow-down-short";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      borderRadius: "99px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.colors.dark75,
      padding: "10px",
      position: "absolute",
      cursor: "pointer",
      zIndex: 2,
    },
    icon: {
      width: "12px",
      height: "12px",
      color: theme.palette.colors.white,
    },
    label: {
      marginLeft: "5px",
      color: theme.palette.colors.white,
      "&::first-letter": {
        textTransform: "uppercase",
      },
    },
  }),
  {
    name: "OneSeparator",
  }
);

const Indicator = ({ count, onClick }) => {
  const classes = useStyles();

  if (count === 0) {
    return null;
  }

  return (
    <Box className={classes.root} onClick={onClick}>
      <ArrowDownShort className={classes.icon} />
      <Typography className={classes.label} variant="extraSmallB1">
        {count <= 20 ? _n("{count} new message", "{count} new messages", count, { count }) : _t("more than 20 new messages")}
      </Typography>
    </Box>
  );
};

export default Indicator;
