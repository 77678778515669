import React from "react";
import PropTypes from "prop-types";

const CustomChevronUp = ({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill={color}
      {...rest}
    >
      <path
        fillRule="evenodd"
        d="M14.53,10.47l-6-6a.75.75,0,0,0-1.06,0l-6,6a.75.75,0,1,0,1.06,1.06L8,6.06l5.47,5.47a.75.75,0,1,0,1.06-1.06Z"
      />
    </svg>
  );
};

CustomChevronUp.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomChevronUp.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomChevronUp;
