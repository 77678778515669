import React, { useEffect, useState } from "react";
import { _t } from "utilities/i18n";
import clsx from "clsx";

import { Box, Typography, makeStyles } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useDispatch, useSelector } from "react-redux";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import VisioVonage from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage";
import { destroyPublisher, getDevices } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/actions";
import { setSource } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/actions";

import MicFill from "react-bootstrap-icons/dist/icons/mic-fill";
import MicOffFill from "react-bootstrap-icons/dist/icons/mic-mute-fill";
import CameraFill from "react-bootstrap-icons/dist/icons/camera-video-fill";
import CameraOffFill from "react-bootstrap-icons/dist/icons/camera-video-off-fill";
import ExplicativeScreen from "./ExplicativeScreen";
import { currentCalendarSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    height: "100%",
    position: "relative",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: "1 auto",
  },
  ctas: {
    display: "flex",
    flex: "none",
    paddingBottom: "15px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  choice: {
    display: "flex",
    padding: "15px",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
    cursor: "pointer",
  },
  propulsed: {
    position: "absolute",
    bottom: "5px",
    left: "0",
    width: "100%",
    textAlign: "center",
  },
  propulsedText: {
    textAlign: "center",
    fontSize: "12px",
    textDecoration: "none",
    color: theme.palette.colors.grey500,
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  check: {
    position: "relative",
    height: "450px",
  },
  cgu: {
    marginBottom: "20px",
  },
  typo: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    color: "grey",
  },
  link: {
    color: "#000000",
  },
}));

const AVAILABLE_MODES = ['video', 'audio', 'chat'];

const Layout = ({ children, cta, onCancel }) => {
  const classes = useStyles();
  const calendar = useSelector(currentCalendarSelector);
  return (
    <Box className={clsx("VisioPrepareWrapper", classes.root)}>
      <Box className={clsx("VisioPrepareContent", classes.content)}>
        {children}
      </Box>
      <Box className={clsx("VisioPrepareCTAs", classes.ctas)}>
        <Box display="flex">
          {calendar?.active && <Button color={`#000000`} onClick={onCancel}>{_t("Back")}</Button>}
        </Box>
        {cta && (
          <Box display="flex" justifyContent="flex-end" flex="1 auto">{cta}</Box>
        )}
      </Box>
      <Box className={clsx("VisioPreparePropulsed", classes.propulsed)}>
        <a href="https://liveboutique.io/" target="_blank" className={classes.propulsedText} rel="noreferrer">{_t("Propulsed by liveboutik")}</a>
      </Box>
    </Box>
  );
};

const getLocalStream = async (options) => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia(options)
      .then((stream) => {
        resolve(stream);

        // this is very important to avoid getting the camera indicator always on
        // this code is needed to trigger browser permission popup and detect if user has accepted or not
        // once done, we have to immediatly stop the stream otherwise the camera indicator will stay on, like forever...
        try {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        } catch (e) { }
      })
      .catch((err) => {
        resolve({ error: err });
      });
  });
}

const VisioPrepare = ({ onReady, onCancel }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const project = useSelector(currentProjectSelector);
  const availableModes = project?.options?.user_methods || AVAILABLE_MODES;

  const [allGood, setAllGood] = useState(false);
  const [mode, setMode] = useState(availableModes.length === 1 ? availableModes[0] : null);
  const [permissionsError, setPermissionsError] = useState(false);

  const onBack = () => {
    setAllGood(false);
    setPermissionsError(false);
    setMode(null);
  };

  const getPermissions = async () => {
    console.log('getPermissions', mode);
    const result = await getLocalStream({ video: mode === 'video', audio: true });

    console.log('result', result);

    if (result.error) {
      setPermissionsError(true)
      return;
    }

    dispatch(getDevices(mode));
    setAllGood(true);
  };

  useEffect(() => {
    // nothing to do on chat mode
    if (!mode || mode === 'chat') return;

    // get right permissions and ensure init publisher won't use video if audio only
    getPermissions(mode);
    dispatch(setSource('audioInput', 'default'));
    if (mode === "audio") dispatch(setSource('videoInput', false));
    if (mode === "video") dispatch(setSource('videoInput', 'default'));
  }, [mode]);

  if (mode) {
    if (allGood) {
      return (
        <Layout
          onCancel={() => {
            dispatch(destroyPublisher("video"));
            onBack();
          }}
          cta={<Button onClick={() => {
            dispatch(destroyPublisher("video"));
            onReady(mode);
          }}>{_t("Join!")}</Button>}
        >
          <Box className={classes.check}>
            <VisioVonage preview mode={mode} />
          </Box>
        </Layout>
      );
    }

    if (mode === 'chat') {
      return (
        <Layout onCancel={onBack} cta={<Button onClick={() => onReady(mode)}>Ok pour moi, continuer</Button>}>
          <ExplicativeScreen mode={mode} error={false} />
        </Layout>
      );
    }

    if (permissionsError) {
      return (
        <Layout onCancel={onBack} cta={<Button onClick={() => getPermissions(mode)}>Réessayer</Button>}>
          <ExplicativeScreen mode={mode} error={permissionsError} />
        </Layout>
      );
    }

    return (
      <Layout onCancel={onBack}>
        <ExplicativeScreen mode={mode} error={false} />
      </Layout>
    );
  }

  return (
    <Layout onCancel={onCancel}>
      <Box className={clsx("VisioPrepareTitle", classes.title)}>{_t("How would you like to communicate with your interlocutor?")}</Box>

      {availableModes.includes("video") && <Box className={clsx("VisioPrepareChoice", classes.choice)} onClick={() => setMode('video')}>
        <Box>
          <MicFill style={{ color: "green" }} className={classes.icon} />
          <CameraFill style={{ color: "green" }} className={classes.icon} />
        </Box>
        {_t("With audio and video (recommended)")}
      </Box>}
      {availableModes.includes("audio") && <Box className={clsx("VisioPrepareChoice", classes.choice)} onClick={() => setMode('audio')}>
        <Box>
          <MicFill style={{ color: "green" }} className={classes.icon} />
          <CameraOffFill style={{ color: "red" }} className={classes.icon} />
        </Box>
        {_t("With audio only")}
      </Box>}
      {availableModes.includes("chat") && <Box className={clsx("VisioPrepareChoice", classes.choice)} onClick={() => setMode('chat')}>
        <Box>
          <MicOffFill style={{ color: "red" }} className={classes.icon} />
          <CameraOffFill style={{ color: "red" }} className={classes.icon} />
        </Box>
        {_t("With written chat only")}
      </Box>}

      <Box className={classes.cgu}>
        <Typography className={classes.typo}>{_t("By contacting the brand, you agree to the use of your personal data to provide you with Service support Clients. For more details, please refer to our privacy policy.")}
          &nbsp;<a className={classes.link} href={`${project?.options.cgu_url}`} target="_blank">{_t("See more")}</a>
        </Typography>
      </Box>
    </Layout>
  );
};

export default VisioPrepare;
