import React from "react";
import PropTypes from "prop-types";

const Screen1x2Icon = ({ color, size, ...rest }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7252_47803)">
        <path d="M0.5 1C0.5 0.867392 0.552678 0.740215 0.646447 0.646447C0.740215 0.552678 0.867392 0.5 1 0.5H6C6.13261 0.5 6.25979 0.552678 6.35355 0.646446C6.44732 0.740215 6.5 0.867392 6.5 1V15C6.5 15.1326 6.44732 15.2598 6.35355 15.3536C6.25978 15.4473 6.13261 15.5 6 15.5H1C0.867392 15.5 0.740215 15.4473 0.646447 15.3536C0.552678 15.2598 0.5 15.1326 0.5 15V1ZM9.5 1C9.5 0.867392 9.55268 0.740215 9.64645 0.646447C9.74022 0.552678 9.86739 0.5 10 0.5H15C15.1326 0.5 15.2598 0.552678 15.3536 0.646446C15.4473 0.740215 15.5 0.867392 15.5 1V15C15.5 15.1326 15.4473 15.2598 15.3536 15.3536C15.2598 15.4473 15.1326 15.5 15 15.5H10C9.86739 15.5 9.74022 15.4473 9.64645 15.3536C9.55268 15.2598 9.5 15.1326 9.5 15V1Z" fill="white" stroke="white" />
      </g>
      <defs>
        <clipPath id="clip0_7252_47803">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

Screen1x2Icon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Screen1x2Icon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default Screen1x2Icon;
