import React, { useState, memo } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ControlBar from "adminComponents/UserManagement/ControlBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Paginator from "adminContainers/users/Paginator";
import { PaginatorContextProvider } from "adminContainers/users/paginatorContext";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.wisemblyColors.white,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  right: {},
}));
const Team = () => {
  const classes = useStyles();
  const [visibleColumns, setVisibleColumns] = useState([]);
  return (
    <Box className={clsx("Team", classes.root)}>
      <PaginatorContextProvider panel={"team"}>
        <ControlBar onVisibleColumnChange={setVisibleColumns} />
        <Box className={clsx("Table", classes.table)}>
          <Paginator admins={true} visibleColumns={visibleColumns} />
        </Box>
      </PaginatorContextProvider>
    </Box>
  );
};

export default memo(Team);
