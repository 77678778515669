import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import MuiIconButton from "@material-ui/core/IconButton";
import ThemedButton from "./ThemedButton";

const IconButton = withStyles((theme) => {
  return {
    root: (props) => {
      const themeButton = ThemedButton(props, theme);
      return {
        ...themeButton,
        boxShadow: "none",
      };
    },
    disabled: {},
    sizeSmall: {
      padding: theme.spacing(0.875),
    },
    label: (props) => {
      return {
        textAlign: "center",
        textTransform: "none",
        "& .MuiSvgIcon-root": {
          fontSize:
            props.size === "medium" || props.size !== "small" ? "26px" : "14px",
        },
      };
    },
  };
})(MuiIconButton);

MuiIconButton.propTypes = {
  withoutbg: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
};

export default IconButton;
