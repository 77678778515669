import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

export const ItemTypes = {
  CARD: "card",
  MEDIA: "media",
  SURVEY: "survey",
};

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
    cursor: (props) => (props.canDrag ? "move" : "auto"),
    //marginBottom: theme.spacing(3),
    opacity: (props) => props.opacity,
  },
}));

const Card = ({ id, index, moveCard, dropCard, children, canDrag, type }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: type,
    canDrop: () => canDrag,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen #optionalChaining
      const hoverBoundingRect = ref.current
        ? ref.current.getBoundingClientRect()
        : undefined;
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      dropCard(item);
      return;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type, id, index },
    canDrag: () => canDrag,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const didSmthDrop = monitor.didDrop();
      if (!didSmthDrop) {
        dropCard(item);
      }
    },
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  const classes = useStyles({ opacity, canDrag });
  return (
    <Box ref={ref} className={classes.root}>
      {children}
    </Box>
  );
};
Card.propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  children: PropTypes.object,
  moveCard: PropTypes.func,
  canDrag: PropTypes.bool,
};

Card.defaultProps = {
  dropCard: () => {},
  canDrag: true,
  type: ItemTypes.CARD,
};

export default Card;
