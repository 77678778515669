import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { currentUserSelector, getOwnAccessesSelector, visitorsSelector } from "app/state/ducks/ressources/selectors";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { useDispatch, useSelector } from "react-redux";
import { _ } from "core-js";
import Visitor from "./Visitor";
import AppLoader from "app/liveShopping/frontoffice/otm/components/AppLoader";
import { Divider } from "@material-ui/core";
import { openFishingRod, setCurrentVisitor } from "app/state/ducks/liveShopping/actions";
import dayjs from "dayjs";
import { fishingRodSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "320px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRight: "1px solid #F0F0F4",
    overflowY: "auto",
    paddingBottom: "50px",

  },
  wrapper: {
    display: "flex",
    width: "100%",
    position: "relative",
    justifyContent: "center",
    height: "164px",

  },
  divider: {
    position: "absolute",
    width: "288px",
    bottom: 0,
    background: theme.palette.colors.grey200,
  },
  empty: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.grey500,
  },
}));

const Queue = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const accesses = useSelector(getOwnAccessesSelector).filter((access) => access.available === true);
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useSelector(currentUserSelector);
  const visitors = useSelector(visitorsSelector);
  const fishingRod = useSelector(fishingRodSelector);
  const noAccesses = accesses.length === 0;
  const availableVisitors = visitors?.filter(({ status, handled_at, vendor, scheduled_at }) => (status === "waiting" || status === "ongoing" || status === "ringing" || scheduled_at !== null && status !== "ended") && (handled_at === null || vendor?.id === currentUser?.id));

  const fakeVisitor = {
    id: 1,
    token: "123456",
    referer: "https://www.google.com/",
    status: "waiting",
    hash: "123456",
    options: {}
  }
  useEffect(() => {
    accesses.map((access) => {
      dispatch(
        resourceFetch("queue", {
          slug: ["queues", access.group.queue.hash, "visitors"],
          callback: () => setIsLoading(false),
        })
      );
    });
  }, []);

  useEffect(() => {
    // check if vendor_id is mine and if status is ongoing, if yes, set current visitor
    const visitor_is_mine = visitors.find(({ vendor, status }) => vendor?.id === currentUser?.id && status === "ongoing");
    if (visitor_is_mine && fishingRod) {
      dispatch(openFishingRod(false))
      dispatch(setCurrentVisitor(visitor_is_mine.id));
    }
  }, [availableVisitors]);

  useEffect(() => {
    const alreadyHandledVisitor = availableVisitors.find(({ status }) => status === "ongoing");

    if (alreadyHandledVisitor) {
      dispatch(setCurrentVisitor(alreadyHandledVisitor.id));
    } else {
      dispatch(setCurrentVisitor(availableVisitors?.shift()?.id));
    }
  }, [])

  if (isLoading)
    return <AppLoader />;


  const sortVisitors = (visitors) => {
    const visitorsToSort = visitors.filter(({ scheduled_at }) => scheduled_at === null || dayjs(scheduled_at).isBefore(dayjs()) || dayjs(scheduled_at).isSame(dayjs(), "day"));
    return visitorsToSort.sort((a, b) => a.scheduled_at ? a.scheduled_at : a.created_at - b.scheduled_at ? b.scheduled_at : b.created_at)
  }

  return (
    <Box className={classes.root}>
      {availableVisitors?.length === 0 && accesses.length >= 1 && (
        <Box className={classes.wrapper}>
          <Visitor visitor={fakeVisitor} empty />
          <Divider className={classes.divider} />
        </Box>
      )}

      {!noAccesses && sortVisitors(availableVisitors)?.map((visitor) => {
        return (
          <Box className={classes.wrapper} key={visitor.hash}>
            <Visitor visitor={visitor} />
            <Divider className={classes.divider} />
          </Box>
        );
      })}
    </Box>
  );
};

Queue.propTypes = {};

Queue.defaultProps = {};

export default Queue;
