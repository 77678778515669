import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";
import { useSurveyContextState } from "appComponents/SurveyForms";
import PercentIndicator from "appComponents/Viewers/PercentIndicator";

const useStyles = makeStyles((theme) => ({
  root: {
    display: (props) => (props.visible ? "flex" : "none"),
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: theme.spacing(1),
    marginBottom: "20px",
    width: "100%",
  },

  label: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: "20",
    lineHeight: "24.38px",
    letterSpacing: "0.5px",
    marginBottom: "15px",
  },
}));

const ProgessBar = () => {
  const { indexMap, maxValidIndex, surveyType } = useSurveyContextState();
  const visible = ["form", "evaluation"].includes(surveyType);
  const classes = useStyles({ visible });
  if (!indexMap) return null;
  const percent = ((maxValidIndex + 1) / indexMap.length) * 100;
  return (
    visible && (
      <Box className={classes.root}>
        <Typography className={classes.label}>
          {`${maxValidIndex + 1} / ${indexMap.length}`}
        </Typography>
        <PercentIndicator percent={percent} />
      </Box>
    )
  );
};

export default ProgessBar;
