import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { AvatarGroup } from "@material-ui/lab";
import { Avatar } from "@material-ui/core";
import { useSelector } from "react-redux";
import { accessesSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  round: {
    root: {},
    backgroundColor: theme.palette.colors.primary,
    color: theme.palette.colors.white,
    border: "1px solid white",
  },
}));

const MembersAvatar = ({ hashGroup }) => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  const classes = useStyles({});
  const accesses = useSelector(accessesSelector).filter((access) => access?.group?.hash === hashGroup);
  return (
    <Box className={classes.root}>
      <AvatarGroup max={4}>
        {accesses.map((access) => (
          <Avatar alt={access.ref.user.email} style={{ backgroundColor: "#" + randomColor }} className={classes.round} key={access.hash}>
            {access.ref.user.email.charAt(0).toUpperCase()}
          </Avatar>
        ))}
      </AvatarGroup>
    </Box>
  );
};

MembersAvatar.propTypes = {};

MembersAvatar.defaultProps = {};

export default MembersAvatar;
