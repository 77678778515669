import React, { useRef, useEffect, useMemo, memo } from "react";
import { compose } from "redux";
import isEqual from "lodash/isEqual";
import { _t } from "i18n";

import { withUser } from "hoc/User";
import { withScript } from "hoc/ScriptLoader";

import { makeStyles } from "@material-ui/core/styles";
import { isUser } from "utilities/access";

const useStyles = makeStyles((theme) => ({
  iframe: {
    height: "100%",
    width: "100%",
  },
}));

const Jitsi = ({
  data,
  user,
  wiz,
  session,
  scriptsLoaded,
  scriptsLoadedSuccessfully,
}) => {
  const jitsiNodeRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (!scriptsLoaded || !scriptsLoadedSuccessfully) return;

    const domain = (data.url || "").replace("https://", "");
    const options = {
      width: "100%",
      height: "100%",
      roomName: data.room,
      userInfo: {
        displayName: user.isAnon()
          ? `${_t("Anonymous")}${session.token.substr(0, 12)}`
          : user.name,
        email: user.isAnon() ? "" : user.email,
      },
      parentNode: jitsiNodeRef.current,
      configOverwrite: {
        startAudioOnly:
          !wiz.getVisio("user_video", true) &&
          !wiz.getVisio("anim_video", true),
        // enableLayerSuspension: true,
        startWithAudioMuted: isUser("user-", user, wiz),
        startWithVideoMuted: isUser("user-", user, wiz),
      },
      interfaceConfigOverwrite: {
        SETTINGS_SECTIONS: [],
        AUTHENTICATION_ENABLE: false,
        RECENT_LIST_ENABLED: false,
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        INITIAL_TOOLBAR_TIMEOUT: 1000,
        TOOLBAR_TIMEOUT: 1000,
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISABLE_VIDEO_BACKGROUND: true,
        MOBILE_APP_PROMO:
          wiz.getVisio("user_video") && wiz.getVisio("anim_video"),
        TOOLBAR_BUTTONS: ["fodeviceselection", "videoquality", "tileview"],
      },
      onload: () => {
        window.jitsiApi.executeCommand("subject", " ");
      },
    };

    const scope = isUser("anim+", user, wiz) ? "anim" : "user";
    if (wiz.getVisio(`${scope}_video`))
      options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push("camera");

    if (wiz.getVisio(`${scope}_audio`))
      options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push("microphone");

    if (wiz.getVisio(`${scope}_share_screen`))
      options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push("desktop");

    if (wiz.getVisio(`${scope}_raise_hand`))
      options.interfaceConfigOverwrite.TOOLBAR_BUTTONS.push("raisehand");

    const api = new JitsiMeetExternalAPI(domain, options);
    if (!api) throw new Error("Failed to create JitsiMeetExternalAPI istance");
    window.jitsiApi = api;
  }, [scriptsLoaded, scriptsLoadedSuccessfully]);

  return (
    <div
      id="jitsi-dom-node"
      className={classes.iframe}
      ref={jitsiNodeRef}
    ></div>
  );
};

const WisemblyVisio = ({ data, wiz }) => {
  const scriptUri = `${data.url}external_api.js`;
  const WrappedComponent = compose(
    withScript([scriptUri], { cache: true }),
    withUser
  )(Jitsi);

  return <WrappedComponent data={data} wiz={wiz} />;
};
const MemoJitsi = memo(WisemblyVisio, (prevProps, nextProps) => {
  const dataIsEqual = isEqual(prevProps.data, nextProps.data);
  const wizIsEqual = isEqual(prevProps.wiz, nextProps.wiz);
  return wizIsEqual && dataIsEqual;
});
export default MemoJitsi;
