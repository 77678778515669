import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { desktopLayoutSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: (props) => (props.layoutIsOpen ? "flex" : "none"),
    position: "relative",
    zIndex: 2,
    width: "100%",
    height: "100%",
    background: theme.palette.colors.white,
    overflow: "hidden",
    flexDirection: "column",
  },
  rootDesktop: {
    display: (props) => (props.layoutIsOpen ? "flex" : "none"),
    width: "100%",
    height: "100%",
    background: theme.palette.colors.white,
    position: "relative",
    overflow: "hidden",
    flexDirection: "column",
    borderRadius: (props) => (props.isDesktop ? "10px 0px 0px 10px" : 0),
  },
}));

const LayoutWrapper = ({ children, layoutIsOpen }) => {
  const mobileLayout = useSelector(mobileLayoutSelector);
  const isDesktop = useSelector(desktopLayoutSelector);
  const classes = useStyles({ isDesktop, layoutIsOpen });

  return <Box className={mobileLayout ? classes.root : classes.rootDesktop}>{children}</Box>;
};

LayoutWrapper.propTypes = {};

LayoutWrapper.defaultProps = {};

export default LayoutWrapper;
