import { makeStyles, Typography } from "@material-ui/core";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { _t } from "utilities/i18n";
import Switch from "../reusables/Switch";

const useStyles = makeStyles((theme) => ({
  notification: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "150%",
  },
}));

const Notifications = ({ user }) => {
  const classes = useStyles();
  const [notified, setNotified] = useState(user?.extra_data?.notification);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!('Notification' in window)) {
      emitter.emit(EVENT_FLASH_ERROR, _t("This browser does not support desktop notification"))
    }

    if (notified && Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          emitter.emit(EVENT_FLASH_SUCCESS, _t("Notifications are activated"))
        }
      })
    }

    // if (notified && Notification.permission === 'granted') {
    //   emitter.emit(EVENT_FLASH_SUCCESS, _t("Notifications are activated"))
    // }

    if (Notification.permission === 'denied' && notified) {
      emitter.emit(EVENT_FLASH_ERROR, _t("You have refused notifications, you can activate them in your browser settings"))
    }
  }, [notified]);

  const ActivateAlerts = () => {

    dispatch(
      resourceEdit(Object.assign(user, { extra_data: { notification: !notified } }),
        {
          slug: ["user", user.id],
          patch: ["extra_data"],
          callback: (error, response) => {
            if (error) {
              emitter.emit(EVENT_FLASH_ERROR, _t("An error has occurred"));
            }
            setNotified(!notified);

          }
        }
      )
    );
  };

  return (
    <>
      <Switch checked={notified} onClick={ActivateAlerts} />
      <Typography className={classes.notification}>{_t("Notifications")}</Typography>
    </>
  )
};

export default Notifications;