import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { desktopLayoutSelector, replayIsOpenSelector } from "app/state/ducks/liveShopping/selectors";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Chapter from "./Chapter";
import { currentWizReplaySelector } from "app/state/ducks/ressources/selectors";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import { setOpenReplay, setOpenCatalog } from "app/state/ducks/liveShopping/actions";
import LayoutWrapper from "../LayoutWrapper";
const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginTop: "30px",
    marginBottom: "30px",
    paddingRight: 20,
    height: (props) => (props.desktopLayout ? "auto" : "170px"),
  },
  title: {
    color: theme.palette.colors.primary,
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "130%",
    marginLeft: "24px",
  },
  chapters: {
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    flex: "auto 1",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 25,
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const Replay = ({}) => {
  const replayIsOpen = useSelector(replayIsOpenSelector);
  const desktopLayout = useSelector(desktopLayoutSelector);
  const classes = useStyles({ desktopLayout });
  const { chapters } = useSelector(currentWizReplaySelector);
  const dispatch = useDispatch();

  const handleQuitReplay = () => {
    dispatch(setOpenReplay(false));
    dispatch(setOpenCatalog(true));
  };
  return (
    <LayoutWrapper layoutIsOpen={replayIsOpen}>
      <Box className={classes.header}>
        <Typography className={classes.title}>{_t("Chapters")}</Typography>
        <ButtonBase onClick={handleQuitReplay}>{desktopLayout && <XLg size={20} />}</ButtonBase>
      </Box>
      <Box className={classes.chapters}>
        {chapters.map((chapter, index) => (
          <Chapter key={chapter.hash} chapter={chapter} index={index} />
        ))}
      </Box>
    </LayoutWrapper>
  );
};

Replay.propTypes = {
  replayIsOpen: PropTypes.bool,
  desktopLayout: PropTypes.bool,
};

Replay.defaultProps = {};

export default Replay;
