import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "../../reusables/Button";
import { useSelector, useDispatch } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { resourceCreate, resourceEdit } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import { useLocation, useHistory } from "react-router-dom";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { getUrl } from "app/views/containers/Application/Routes";
import { getKeyword } from "app/state/ducks/application/selectors";
import { ensureFloat } from "app/liveShopping/utils";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "55px",
    background: theme.palette.colors.white,
    boxShadow: "0px -3px 15px rgba(0, 0, 0, 0.05)",
    flex: "0 auto",
    zIndex: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: (props) => (props.isMobile ? "center" : "flex-end"),
  },
  btn: {
    width: (props) => (props.isMobile ? "100%" : "auto"),
    margin: "0 16px",
  },
}));

const Footer = ({ data }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const location = useLocation();
  const history = useHistory();
  const keyword = useSelector(getKeyword);


  const ensureFloatOnPrice = (data) => {
    let newData = {};
    Object.keys(data).forEach((key) => {
      if (key === "price") {
        newData[key] = ensureFloat(data[key]);
      } else {
        newData[key] = data[key];
      }
    });

    return newData;
  };


  const handleSubmit = () => {

    data = ensureFloatOnPrice(data)

    console.log("data", data)

    const newProduct = resourceFactory.create("Product", { ...data });

    dispatch(
      resourceCreate(newProduct, {
        slug: ["event", keyword, "products"],
        callback: (error) => {
          if (error) {
            emitter.emit(EVENT_FLASH_ERROR, _t("An error has been detected"));
            return;
          }

          emitter.emit(EVENT_FLASH_SUCCESS, _t("The product has been created with success !"));
          history.push(getUrl("otm_live", { keyword }));
        },
      })
    );
  };

  return (
    <Box className={classes.root}>
      <Button clsName={classes.btn} onClick={handleSubmit}>
        <Typography color="white" variant={"bodySB13"}>
          {_t("Save")}
        </Typography>
      </Button>
    </Box>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
