import { Box, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import IconButton from "app/liveShopping/backoffice/otm/components/reusables/IconButton";
import { endCallVisitor } from "app/liveShopping/backoffice/oto/Component/Live/service";
import { isUser } from "core/access";
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { publishStateSelector } from "../../containers/Visio/VisioVonage/selectors";
import MicFill from "react-bootstrap-icons/dist/icons/mic-fill";
import MicOffFill from "react-bootstrap-icons/dist/icons/mic-mute-fill";
import CameraFill from "react-bootstrap-icons/dist/icons/camera-video-fill";
import CameraOffFill from "react-bootstrap-icons/dist/icons/camera-video-off-fill";
import { mute } from "../../containers/Visio/VisioVonage/actions";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { openNps } from "app/state/ducks/liveShopping/actions";
import { _t } from "utilities/i18n";
import CustomHangUpIcon from "../../assets/icons/CustomHangUpIcon";
import BiBoxArrowUpRight from "react-bootstrap-icons/dist/icons/box-arrow-up-right";
import { emit } from "utilities/liveshopping-sdk";
import { useLocation } from "react-router-dom";
import basil from "utilities/basil";
import { ThreeDotsVertical, XOctagonFill } from "react-bootstrap-icons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
const useStyles = makeStyles((theme) => ({
  root: {
    display: props => props.isPip && !props.isAdmin ? "none" : "flex",
    flexDirection: "row",
    padding: "16px 0px",
    gap: "16px",
    isolation: "isolate",
    zIndex: 5,
    position: props => props.isAdmin ? "absolute" : "relative",
    bottom: props => props.isAdmin ? "7px" : "auto",
    width: props => props.screenOrientation === "1x2_admin" && props.isAdmin ? "50%" : "100%",
    height: "60px",
    background: props =>
      (props.isOngoing || props.isRinging || props.isPreparing || props.isWaiting) && !props.isAdmin && (props.isMobile || !props.isMobile) ? theme.palette.colors.primary : props.isAdmin ? "transparent" : "rgba(0, 0, 0, 0.5)",
    // backdropFilter: props => props.isOngoing && !props.isAdmin && "blur(2px)",
  },
  wrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    gap: "16px",
    isolation: "isolate",
  },
  button: {
    width: 36,
    height: 36,
    background: "rgba(255, 255, 255, 0.1)",
    color: theme.palette.colors.white,
  },

  endButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    width: props => !props.isAdmin && 36,
    height: 36,
    color: theme.palette.colors.white,
  },
  hangupTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
  },
  dropdownOption: {
    display: "flex",
    flexDirection: "column",
    minWidth: "100px",
    height: "auto",
    background: theme.palette.colors.white,
    borderRadius: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    gap: "8px",
    position: "absolute",
    top: "100%",
    marginTop: "5px",
    left: "0",
    zIndex: 10,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  dropdownOptionItem: {
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "16px",
    paddingRight: "16px",
    display: "flex",
    gap: "12px",
    alignItems: "center",
    justifyContent: "flex-start",
    cursor: "pointer",
    "&:hover": {
      background: theme.palette.colors.grey100,
    },
  },
}));

const Actions = ({ visitor }) => {
  let query = useQuery();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [openDropDown, setOpenDropDown] = useState(false);
  const isAdmin = isUser("user+");
  const isMobile = useSelector(mobileLayoutSelector);
  const isPip = useMediaQuery("(max-height: 90px)");

  const isOngoing = visitor.status === "ongoing";
  const isWaiting = visitor.status === "waiting";
  const isRinging = visitor.status === "ringing";
  const isPreparing = visitor.status === "preparing";

  const mode = visitor?.options?.mode || 'video';
  const isPopup = !!query.get("popup");

  const screenOrientation = useSelector(state => state.liveShopping.screenOrientation);
  const classes = useStyles({ isOngoing, isAdmin, screenOrientation, isPip, isRinging, isWaiting, isMobile, isPreparing });

  const { publishAudio, publishVideo } = useSelector(publishStateSelector);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const handleEndCall = () => {
    endCallVisitor(dispatch, visitor);
    dispatch(openNps(true))
  };

  const quitApp = () => {
    endCallVisitor(dispatch, visitor, () => emit("QUIT"));
  };

  const handlePopup = () => {
    emit("OPEN_POPUP");
  };

  console.log('Actions', isAdmin)

  return (
    <Box className={classes.root}>
      <Box className={classes.wrapper}>

        {/* // Audio */}
        {(isAdmin || (mode !== "chat" && !isWaiting)) && (
          <IconButton
            clsName={classes.button}
            color={publishAudio ? theme.palette.colors.grey200 : theme.palette.colors.error}
            onClick={() => {
              dispatch(mute("audio", !publishAudio));
            }}
          >
            {publishAudio ? <MicFill size={16} /> : <MicOffFill size={16} />}
          </IconButton>
        )}

        {/* // Video */}
        {(isAdmin || (mode === "video" && !isWaiting)) && (
          <IconButton
            clsName={classes.button}
            color={publishVideo ? theme.palette.colors.grey200 : theme.palette.colors.error}
            onClick={() => {
              dispatch(mute("video", !publishVideo));
            }}
          >
            {publishVideo ? <CameraFill size={16} /> : <CameraOffFill size={16} />}
          </IconButton>
        )}

        {/* // End Call */}
        {!isWaiting && isAdmin && (
          <Button clsName={classes.endButton} color={theme.palette.colors.error} onClick={handleEndCall}>
            <CustomHangUpIcon />
            <Typography className={classes.hangupTypo}>{_t("Hang up")}</Typography>
          </Button>
        )}

        {!isWaiting && !isPreparing && !isRinging && !isAdmin && (
          <IconButton clsName={classes.endButton} color={theme.palette.colors.error} onClick={handleEndCall}>
            <CustomHangUpIcon />
          </IconButton>
        )}

        {!isAdmin && !isOngoing && (
          <Box style={{ position: "relative" }}>
            <IconButton clsName={classes.button} color={theme.palette.colors.grey200} onClick={() => setOpenDropDown(!openDropDown)}>
              <ThreeDotsVertical />
            </IconButton>

            {openDropDown && !isOngoing && (
              <Box className={classes.dropdownOption}>
                <Box className={classes.dropdownOptionItem} onClick={quitApp}>
                  <XOctagonFill color={theme.palette.colors.error} />
                  <Typography style={{ color: theme.palette.colors.error }}>Quit</Typography>
                </Box>

              </Box>)}
          </Box>
        )}

        {!isAdmin && !isPopup && isOngoing && (
          <Box sx={{ position: 'absolute', right: "10px" }}>
            <IconButton
              clsName={classes.button}
              color={theme.palette.colors.grey200}
              onClick={handlePopup}
            >
              <BiBoxArrowUpRight />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Actions;
