import React, { useState } from "react";
import ControlledTextField from "app/liveShopping/backoffice/otm/components/reusables/OutlinedTextField";
import PropTypes from "prop-types";

const OutlinedTextField = ({ placeholder, onChange, ...rest }) => {
  const [value, setValue] = useState("");
  const change = (event) => {
    setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <ControlledTextField
      placeholder={placeholder}
      value={value}
      onChange={change}
      {...rest}
    />
  );
};
OutlinedTextField.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
export default OutlinedTextField;
