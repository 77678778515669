import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Mic from "@material-ui/icons/Mic";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  barContainer: {
    width: "100%",
    height: "5px",
    background: "#dfdfe7",
    borderRadius: "12px",
    overflow: "hidden",
  },
  filler: {
    transition: "width 2s ease-i-out",
    height: "inherit",
    borderRadius: "inherit",
    overflow: "hidden",
  },
  fillerBackground: {
    height: "inherit",
    transition: "width 2s ease-i-out",
    background: "linear-gradient(90deg, #2DBE6E 0%, #2DBE6E, #FA962D)",
  },
}));
const MicrophoneLevel = ({ publisher, dots, permissions }) => {
  const classes = useStyles();
  const [currentLevel, setCurrentLevel] = useState(0);
  const fillerRelativePercentage = (100 / currentLevel) * 100;

  useEffect(() => {
    if (!publisher) return;
    publisher.on("audioLevelUpdated", function (event) {
      let movingAvg = null;
      if (movingAvg === null || movingAvg <= event.audioLevel) {
        movingAvg = event.audioLevel;
      } else {
        movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
      }
      // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
      var logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
      logLevel = Math.min(Math.max(logLevel, 0), 1);

      const levelToShow = Math.round(logLevel * 100);
      if (levelToShow !== currentLevel) {
        setCurrentLevel(levelToShow);
      }
    });

    return () => {
      if (!publisher) return;
      publisher.off("audioLevelUpdated");
    };
  }, [publisher]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >

      <div className={classes.barContainer}>
        <div className={classes.filler} style={{ width: `${currentLevel}%` }}>
          <div
            className={classes.fillerBackground}
            style={{ width: `${fillerRelativePercentage}%` }}
          />
        </div>
      </div>



      {/* {permissions.microphone && (
        <Box display="flex" justifyContent="center">
          {[...Array(dots).keys()].map((level) => {
            return (
              <FiberManualRecordIcon
                key={`micLevel-${level}`}
                className={clsx(
                  classes.dotLevel,
                  level < currentLevel ? "active" : null
                )}
              />
            );
          })}
        </Box>
      )} */}
    </Box>
  );
};

MicrophoneLevel.propTypes = {
  publisher: PropTypes.object,
  dots: PropTypes.number,
  permissions: PropTypes.object,
};
MicrophoneLevel.defaultProps = {
  dots: 5,
};

export default MicrophoneLevel;