import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { _t } from "i18n";
import Box from "@material-ui/core/Box";
import Button from "appComponents/Mui/Button";
import TextField from "@material-ui/core/TextField";
import ScreenLockPortraitIcon from "@material-ui/icons/ScreenLockPortrait";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getKeyword } from "applicationDucks/selectors";
import { resourceCreate } from "ressourcesDucks/actions";
import { getFetchResultStatusSelector } from "applicationDucks/selectors";
import ResourceFactory from "orm/resources";
import basil from "core/basil";
import { bindActionCreators } from "redux";
import emitter from "core/emitter";
import { GO_TO_WIZ_HOME } from "core/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  iconBG: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    backgroundColor: theme.palette.background.secondaryBox,
    color: theme.palette.secondary.main,
  },
  icon: {
    fontSize: theme.spacing(5),
  },
  buttonRoot: {
    marginTop: theme.spacing(2),
  },
}));
const PasswordRequieredWall = ({
  keyword,
  resourceFactory,
  resourceCreate,
  alreadyCheckPassword,
}) => {
  const classes = useStyles();
  const [checking, setChecking] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    setPassword(value);
    if (error) {
      setError(false);
    }
  };

  const handlePasswordSubmit = async () => {
    try {
      setChecking(true);
      setError(false);
      let submitObject = {
        keyword,
        password,
      };

      const newPassword = resourceFactory.create("Custom", submitObject);
      resourceCreate(newPassword, {
        slug: ["event", "check-password"],
        query: [
          {
            key: "keyword",
            value: keyword,
          },
        ],
        XHR_ID: `submit_wiz_password_${keyword}`,
        callback: (error) => {
          setChecking(false);
          if (!error) {
            basil.set(`${keyword}.password`, password);
            alreadyCheckPassword();
            return;
          }
          setError(error);
        },
        silent: true,
      });
    } catch (e) {
      setChecking(false);
      setError(true);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      justifyContent="flex-start"
      alignItems="center"
      className={clsx("UnverifiedUserWall", classes.root)}
    >
      <Box display="flex" mt={3}>
        <Typography>{_t("You need a password")}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        className={classes.iconBG}
        my={4}
      >
        <ScreenLockPortraitIcon className={classes.icon} />
      </Box>
      <Box display="flex" mb={2}>
        {/* prettier-ignore */}
        <Typography>
          {_t("Participants are not allowed to access this wiz without password.")}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" justifyContent="center">
        <TextField
          type="text"
          variant="filled"
          id="password"
          name="password"
          label={_t("wiz password")}
          onChange={handleChange}
          value={password}
          error={error}
          placeholder={_t("Enter the wiz password")}
        />
        {error && <Typography>{_t("Invalid password.")}</Typography>}
        <Button
          classes={{
            root: classes.buttonRoot,
          }}
          variant="contained"
          color="primary"
          size="large"
          onClick={handlePasswordSubmit}
          disabled={checking}
        >
          {_t("Validate")}
        </Button>
      </Box>
    </Box>
  );
};

PasswordRequieredWall.propTypes = {
  keyword: PropTypes.string,
  resourceFactory: PropTypes.object,
  resourceCreate: PropTypes.func,
  fetchSubmitWizPasswordState: PropTypes.string,
  alreadyCheckPassword: PropTypes.func,
};

PasswordRequieredWall.defaultProps = {
  alreadyCheckPassword: () => {},
};

const mapStateToProps = (state) => {
  const keyword = getKeyword(state);

  return {
    keyword: keyword || "",
    resourceFactory: new ResourceFactory(state),
    fetchSubmitWizPasswordState: getFetchResultStatusSelector(
      state,
      `submit_wiz_password_${keyword}`
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resourceCreate: bindActionCreators(resourceCreate, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordRequieredWall);
