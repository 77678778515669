import dayjs from "utilities/utils/date";
export const formatPrice = (price, currency) => {
  if (price === null || price === undefined || price === "") {
    price = 0;
  }
  switch (currency) {
    case "EUR":
      return price.toLocaleString("fr-FR", {
        style: "currency",
        currency: currency,
      });
    case "USD":
      return price.toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      });
    case "GBP":
      return price.toLocaleString("en-EN", {
        style: "currency",
        currency: currency,
      });
    default:
      return currency;
  }
};

export const randomInt = (number) => Math.floor(Math.random() * number);

export const filterReplayFocusedProduct = (
  { started_at, stopped_at },
  replay,
  currentTimestamp
) => {
  const replayDateUnix = dayjs(replay.starts_at)
    .add(currentTimestamp, "seconds")
    .unix();

  // product never started does not have to be displayed
  if (!started_at) return false;

  // if we are before product showed, do not show
  if (dayjs(started_at).unix() > replayDateUnix) return false;

  // if product was stopped before current time, do not show
  if (stopped_at && dayjs(stopped_at).unix() < replayDateUnix) return false;

  // otherwise, show it
  return true;
};

//that function choose focused products in live or in replay
export const focusedProductsUtility = (
  wiz,
  products,
  currentTimestamp,
  focusedProductLive
) => {
  if (wiz.status !== 'replay')
    return Array.isArray(focusedProductLive) ? focusedProductLive : [focusedProductLive];

  return products?.filter((product) =>
    filterReplayFocusedProduct(product, wiz.replay, currentTimestamp)
  );
};

export const truncate = (str, n, useWordBoundary) => {
  if (str.length <= n) { return str; }
  const subString = str.substr(0, n - 1); // the original check
  return (useWordBoundary
    ? subString.substr(0, subString.lastIndexOf(" "))
    : subString) + "...";
};

export const ensureFloat = (price) => {
  if (typeof price === "string") {
    if (price === "" || price === null) return 0;
    price = price.replace(",", ".");

    return parseFloat(price);
  }


  return price;
}