import { select } from "redux-saga/effects";
import basil from "utilities/basil";

// TODO: inspect why the fuck we have to use videoPublisher stored in Redux AND the one in ownStream :(
// This has something to do with saga and the way it handles things
import { getOwnVideoStream, publishersSelector } from "../selectors";

export function* muteSource({ mode, status }) {
  console.log("[VISIO SAGA] MUTE SOURCE", mode, status);

  const stream = yield select(getOwnVideoStream);
  const { videoPublisher } = yield select(publishersSelector);
  const method = mode === "audio" ? "publishAudio" : "publishVideo";

  // set preferences in localstorage
  console.log('basil set', mode, status);
  basil.set(mode === "audio" ? "audioPreference" : "videoPreference", status)

  if (stream?.publisher) stream.publisher[method](status);

  if (videoPublisher) videoPublisher[method](status);
}
