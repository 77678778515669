import React, { useState } from "react";
import "coreComponents/Tag/styles/ColorWheel.css"
import PropTypes from "prop-types";
import { _t } from "i18n";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "row",
    },
    color: {
        cursor: "pointer",
        width: "18px",
        height: "18px",
        borderRadius: "100%",
        display: 'flex',
        backgroundColor: theme.palette.colors.white,
    },
    selected: {
        margin: "auto",
        width: "8px",
        height: "8px",
        borderRadius: "100%",
        backgroundColor: theme.palette.colors.white,

    },
}));

const ColorList = ({ color, colorPickerOpen, setColor, setColorPickerOpen }) => {
    const classes = useStyles();
    const theme = useTheme();
    const list = Object.values(theme.palette.colors.tags.colors);

    return (
        <Box className={classes.root}>
            <Box onClick={() => { setColorPickerOpen(!colorPickerOpen) }} className={clsx("Color", classes.color, "wheel")}>
                <Box className={
                    clsx({
                        [classes.selected]: color != "" && !list.some(oneColor => oneColor === color),
                    })
                } />
            </Box>
            {list.map((oneColor, key) => {
                return (
                    <Box key={key} ml={"10px"} onClick={() => {setColor(oneColor); setColorPickerOpen(false)}} style={{ backgroundColor: oneColor }} className={clsx("Color", classes.color)}>
                        <Box className={
                            clsx({
                                [classes.selected]: color === oneColor,
                            })
                        } />
                    </Box>)
            })}
        </Box>
    );
};

ColorList.propTypes = {
    setColor: PropTypes.func,
};

export default ColorList;
