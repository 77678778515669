import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import TextInput from "app/liveShopping/backoffice/otm/components/reusables/OutlinedTextField";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import sha1 from "crypto-js/sha1";

import { OAUTH_BASE, SIGNIN_APP_ID, SIGNIN_APP_SECRET } from "settings/config";
import { onPressEnter } from "utilities/utils";


const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  aLink: {
    textDecoration: "none",
  },
  icon: {
    paddingRight: "8px",
    fontSize: "20px",
  },
  btn: { margin: "30px 0", width: "100%" },
  link: { display: "flex", justifyContent: "center", marginTop: "30px" },
  buttons: {
    width: "100%",
  },
  oauthButton: {
    width: "100%",
    backgroundColor: theme.palette.colors.white50,
    border: "1px solid " + theme.palette.colors.greyMedium,
    color: theme.palette.colors.greyDark,
    "&:hover": {
      backgroundColor: theme.palette.colors.white50,
    },
  },
  dividers: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
    margin: "30px 0px",
  },
  marginSide: {
    margin: "0 15px",
  },
  divider: { flex: 1, justifyContent: "center", alignItems: "center" },
}));

const SignInPart1 = ({
  email,
  onChange,
  setStep,
  errors,
  setErrors,
  validate,
  isModal,
  noEvent,
}) => {
  const classes = useStyles();

  const getUrl = (provider) => {
    const redirectPath = OAUTH_BASE.clone()
      .segment("signin")
      .addSearch("app_id", SIGNIN_APP_ID)
      .addSearch("hash", sha1(SIGNIN_APP_ID + SIGNIN_APP_SECRET))
      .addSearch("redirect_success", window.location.href)
      .addSearch("redirect_failure", window.location.href);

    return OAUTH_BASE.clone()
      .segment("connect")
      .segment(provider)
      .addSearch("redirect_path", redirectPath.toString())
      .toString();
  };

  const callback = async () => {
    const error = await validate();
    if (!error?.email) {
      setStep(2);
    }
  };

  useEffect(() => {
    if (email.length > 1) {
      document.addEventListener("keydown", (e) => onPressEnter(e, callback));
    }
    return () => {
      document.removeEventListener("keydown", (e) => onPressEnter(e, callback));
    };
  }, [email]);

  return (
    <Box className={classes.root}>
      <Typography variant={"bodySB13"}>{_t("E-mail")}</Typography>
      <Box mb={1} />
      <TextInput
        autoFocus
        size={"medium"}
        name={"email"}
        value={email}
        onChange={onChange}
        type={"text"}
        fullWidth
        required
        placeholder={_t("Enter your e-mail")}
        errorMessage={errors?.email}
        error={errors?.email?.length}
      />
      <Button
        style={{ width: "100%", marginTop: "20px" }}
        onClick={async () => {
          const error = await validate();
          if (!error?.email) {
            setStep(2);
            setErrors({});
          }
        }}
      >
        {_t("Continue")}
      </Button>




    </Box>
  );
};

SignInPart1.propTypes = {
  email: PropTypes.string,
  onChange: PropTypes.func,
  setStep: PropTypes.func,
  validate: PropTypes.func,
  errors: PropTypes.object,
  isModal: PropTypes.bool,
  noEvent: PropTypes.bool,
};

SignInPart1.defaultProps = {
  email: "",
  onChange: () => { },
  setStep: () => { },
  validate: () => { },
  errors: {},
  isModal: false,
  noEvent: false,
};

export default SignInPart1;