import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { _t } from "utilities/i18n";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Button from "coreUiKit/inputs/Button";

import Icon from "coreUiKit/inputs/Icon";

import Angry from "react-bootstrap-icons/dist/icons/emoji-angry";
import X from "react-bootstrap-icons/dist/icons/x";
import CheckCircleFill from "react-bootstrap-icons/dist/icons/check-circle-fill";
import Plus from "react-bootstrap-icons/dist/icons/plus";
import ExclamationTriangleFill from "react-bootstrap-icons/dist/icons/exclamation-triangle-fill";
import ExclamationCircleFill from "react-bootstrap-icons/dist/icons/exclamation-circle-fill";
import { PlusCircle } from "react-bootstrap-icons";
import ColorLensOutlined from "@material-ui/icons/ColorLensOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  popup: {
    flexDirection: "column",
    minWidth: "300px",
    minHeight: "52px",
    borderRadius: "10px",
    backgroundColor: theme.palette.colors.white,
    boxShadow: theme.palette.shadow.tooltip,
    alignItems: "center",
    overflow: "hidden",
  },
  data: {
    display: "flex",
    alignItems: "center",
  },
  line: {
    backgroundColor: (props) =>
      (props.color == "primary" && theme.palette.colors.primary) ||
      (props.color == "success" && theme.palette.colors.success) ||
      (props.color == "warning" && theme.palette.colors.warning) ||
      (props.color == "error" && theme.palette.colors.error),
    height: "3px",
    width: (props) => props.progress + "%",
  },
  icon: {
    borderRadius: "100%",
    backgroundColor: (props) =>
      (props.color == "primary" && theme.palette.colors.primary10) ||
      (props.color == "success" && theme.palette.colors.success10) ||
      (props.color == "warning" && theme.palette.colors.warning10) ||
      (props.color == "error" && theme.palette.colors.error10),
    margin: "10px",
    padding: "8px",
  },
  text: {
    width: "100%",
    display: "flex",
    margin: "17px 0 ",
  },
  close: {
    padding: "8px",
    margin: "0 5px",
  },
  closeBtn: {
    padding: "8px",
    margin: "0 5px",
    display: "flex",
  },
}));

const Notification = ({
  id,
  type,
  text,
  duration,
  customIcon = null,
  button,
  buttonTextOne,
  buttonActionOne,
  buttonTextTwo,
  buttonActionTwo,
}) => {
  const [icon, setIcon] = useState(customIcon === null ? Angry : customIcon);
  const [progress, setProgress] = useState(duration > 0 && 100);
  const [onHover, setOnHover] = useState(false);
  var color = "primary";

  switch (type) {
    case "success":
      icon != Angry ? icon : setIcon(CheckCircleFill);
      color = "success";
      break;
    case "warning":
      icon != Angry ? icon : setIcon(ExclamationTriangleFill);
      color = "warning";
      break;
    case "alert":
      icon != Angry ? icon : setIcon(ExclamationCircleFill);
      color = "error";
      break;
    default:
      icon != Angry ? icon : setIcon(Plus);
      color = "primary";
  }

  useEffect(() => {
    let timeoutId = null;
    if (progress > 0 && duration && !onHover) {
      timeoutId = setTimeout(
        () => setProgress(progress - (1 / duration) * 100),
        1000
      );
    }
    if (progress <= 0 && duration != 0) {
      buttonActionTwo(id);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [progress, onHover]);

  const classes = useStyles({ color, progress });

  const endButtons = (button, buttonTextOne, buttonTextTwo) => {
    return button ? (
      <Box className={classes.closeBtn}>
        <Box mr={"5px"}>
          <Button color={"primary"} size={"small"} onClick={buttonActionOne}>
            {buttonTextOne}
          </Button>
        </Box>
        <Box>
          <Button color={"secondary"} size={"small"} onClick={buttonActionTwo}>
            {buttonTextTwo}
          </Button>
        </Box>
      </Box>
    ) : (
      <Box className={classes.close}>
        <Icon IconComponent={X} size="20px" onClick={buttonActionTwo} />
      </Box>
    );
  };
  return (
    <Box
      className={classes.root}
      onMouseOver={() => setOnHover(true)}
      onMouseOut={() => setOnHover(false)}>
      <Box className={classes.popup}>
        {progress > 0 && <Box className={classes.line} />}
        <Box className={classes.data}>
          <Box className={classes.icon}>
            <Icon
              IconComponent={icon}
              size="16px"
              color={color}
              hover={color}
            />
          </Box>
          <Typography variant={"bodySB13"} className={classes.text}>
            {_t(text)}
          </Typography>
          {endButtons(button, buttonTextOne, buttonTextTwo)}
        </Box>
      </Box>
    </Box>
  );
};

Notification.propTypes = {
  duration: PropTypes.number,
  type: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.object,
  button: PropTypes.bool,
  buttonTextOne: PropTypes.string,
  buttonActionOne: PropTypes.func,
  buttonTextTwo: PropTypes.string,
  buttonActionTwo: PropTypes.func,
};

Notification.defaultProps = {
  duration: 0,
  type: "success",
  text: "Un nouveau vote est en cours !",
  icon: Plus,
  button: false,
  buttonTextOne: "Répondre",
  buttonActionOne: () => { },
  buttonTextTwo: "Ignorer",
  buttonActionTwo: () => { },
};

export default Notification;