import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import BeWarnedDialog from "./BeWarnedDialog";
import { ButtonBase } from "@material-ui/core";
import Envelope from "react-bootstrap-icons/dist/icons/envelope";
import basil from "core/basil";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  beWarned: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px 24px",
    background: ({ hasRegistered }) => (hasRegistered ? theme.palette.colors.success : "#FFFFFF"),
    color: ({ hasRegistered }) => (hasRegistered ? "#FFFFFF" : "#000000"),
    borderRadius: "99px",
    flex: "none",
    order: 0,
    flexGrow: 1,
    margin: "0px 8px",
  },
  beWarnedTypo: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
    color: ({ hasRegistered }) => (hasRegistered ? "#FFFFFF" : "#000000"),
  },
}));

const BeWarned = ({ }) => {
  const wiz = useSelector(currentWizSelector);
  const hasRegistered = basil.get(`u:r:${wiz.keyword}`);
  const classes = useStyles({ hasRegistered });
  const [open, setOpen] = useState(false);

  return (
    <>
      <ButtonBase
        className={classes.beWarned}
        onClick={() => {
          if (hasRegistered) return;
          setOpen(true);
        }}
      >
        <Envelope size={16} /> &nbsp; <Typography className={classes.beWarnedTypo}>{hasRegistered ? _t("Registered!") : _t("Be warned")}</Typography>
      </ButtonBase>
      {open && <BeWarnedDialog setOpen={setOpen} />}
    </>
  );
};

BeWarned.propTypes = {};

BeWarned.defaultProps = {};

export default BeWarned;
