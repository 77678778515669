import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class Tag extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Tag;
  }

  toArray() {
    return {
      name: this.name,
      color: this.color,
      sentiment: this.sentiment,
      anonymity: this.anonymity,
      hash: this.hash,
      id: this.id,
    };
  }

  setEvent(Event) {
    this.event = Event.id;
  }
}

Tag.modelName = "Tag";
Tag.backendName = "tag";

Tag.fields = {
  id: attr(),
  name: attr(),
  color: attr(),
  hash: attr(),
  sentiment: attr(),
  anonymity: attr(),
  event: fk("Event", "tags"),
};

export default Tag;
