import React, { useState } from "react";
import PropTypes from "prop-types";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PercentIndicator from "coreUiKit/miscs/PercentIndicator";

const useStyles = makeStyles((theme) => ({
  deleting: {
    height: "41px",
    backgroundColor: theme.palette.colors.alert,
    borderRadius: "8px",
    color: theme.palette.colors.white,
    paddingLeft: "15px",
    paddingRight: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  percentage: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
  },
  cancelDelete: {
    cursor: "pointer",
    color: theme.palette.colors.white,
    fontWeight: 600,
  },
  title: {
    color: theme.palette.colors.white,
    fontWeight: 400,
  },
}));

const DeletedQuoteNotifyer = ({ onCancel, onPause, onResume }) => {
  const classes = useStyles();
  const [pause, setPause] = useState(false);

  const onHover = () => {
    onPause();
    setPause(true);
  };
  const onLeave = () => {
    onResume();
    setPause(false);
  };

  return (
    <Box
      className={classes.deleting}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <Box>
        <Typography variant="body13" className={classes.title}>
          {_t("Quote deleted")}
        </Typography>
      </Box>
      <Box onClick={onCancel}>
        <Typography variant="smallBody1" className={classes.cancelDelete}>
          {_t("Cancel")}
        </Typography>
      </Box>
      <Box className={classes.percentage}>
        <PercentIndicator
          height="3px"
          width="100%"
          percent={100}
          dynamise={true}
          timer={3000}
          pause={pause}
        />
      </Box>
    </Box>
  );
};
DeletedQuoteNotifyer.propTypes = {
  onCancel: PropTypes.func,
  onPause: PropTypes.func,
  onResume: PropTypes.func,
};
DeletedQuoteNotifyer.defaultProps = {
  onCancel: () => { },
  onPause: () => { },
  onResume: () => { },
};

export default DeletedQuoteNotifyer;
