import { useState, useEffect, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";
import ResourceFactory from "orm/resources";
import {
  resourceEdit,
  resourcesEdit,
  resourceCreate,
  resourceDelete,
} from "app/state/ducks/ressources/actions";
import {
  currentWizSelector,
  userSelector,
  signatureForSelector,
} from "ressourcesDucks/selectors";
import { getConnectedUsers } from "applicationDucks/actions";
import { getKeyword } from "applicationDucks/selectors";
import makeRequest from "core/makeRequest";
import { ANALYTICS_PUSH_SERVER } from "config";
import { _t } from "core/i18n";
import get from "lodash/get";
import globalStore from "appStore";

export const useSignatureRequestManager = () => {
  //const keyword = useSelector(getKeyword);
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();
  const state = globalStore.getState();
  const resourceFactory = new ResourceFactory(state);
  //const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleSignatureRequest = useCallback(
    //remove useForce
    (
      action,
      signatureRequest,
      value,
      callback = () => {},
      callbackError = () => {}
    ) => {
      let resource = {};
      let actionCreator = resourceEdit;
      let XHR_ID = "";
      let method = "PATCH";
      let patch = null;
      let slug = ["event", event.keyword, "signature-request"];
      //let newAccesses = {};
      //create tag if necessary
      switch (action) {
        case "create": {
          const request = resourceFactory.create("SignatureRequest", {});
          request.setEvent(event);
          resource = request;
          actionCreator = resourceCreate;
          XHR_ID = `create_event_signature_request_${event.keyword}`;
          method = "POST";

          break;
        }

        case "edit": {
          //let hashs = [];
          let newRequest = Object.assign(signatureRequest, {
            ...value.content,
          });
          slug = [
            "event",
            event.keyword,
            "signature-request",
            signatureRequest.hash,
          ];
          resource = newRequest;
          actionCreator = resourceEdit;
          XHR_ID = "EDIT_BATCH_USER_ROLE";
          method = "PATCH";
          patch = [value.patch];
          //newAccesses = resourceFactory.create("Custom", { data: hashs });
          break;
        }
        case "delete": {
          //let newAccess = Object.assign(access, { scope: value });
          slug = [
            "event",
            event.keyword,
            "signature-request",
            signatureRequest.hash,
          ];
          resource = signatureRequest;
          XHR_ID = "DELETE_SIGNATURE_REQUEST";
          method = "DELETE";
          actionCreator = resourceDelete;
          break;
        }
        case "validate": {
          const request = resourceFactory.create("Signature", {
            ...value.content,
          });

          slug = [
            "event",
            event.keyword,
            "signature-request",
            signatureRequest.hash,
            "signature",
          ];
          request.setSignatureRequest(signatureRequest);
          request.setUser(value.user);
          resource = request;
          actionCreator = resourceCreate;
          XHR_ID = `create_event_valid_signature_${signatureRequest.hash}`;
          method = "POST";
          break;
        }
        default:
          break;
      }

      dispatch(
        actionCreator(resource, {
          slug,
          silent: true,
          method,
          patch,
          XHR_ID,
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          //transform: false,
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
              callbackError();
              //// TODO: error display
              return;
            } else {
              callback();
            }
          },
        })
      );
    },
    [event.keyword]
  );

  return handleSignatureRequest;
};

export const useSignatureUpdater = () => {
  //const keyword = useSelector(getKeyword);
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();
  //const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const state = globalStore.getState();
  const resourceFactory = new ResourceFactory(state);
  //const user = useSelector((state) => userSelector(state, userId));

  const handleSignature = useCallback(
    //remove useForce
    (
      action,
      signature,
      value,
      callback = () => {},
      callbackError = () => {}
    ) => {
      let resource = {};
      let actionCreator = resourceEdit;
      let XHR_ID = "";
      let method = "PATCH";
      let patch = null;
      let slug = ["event", event.keyword, "signature-request"];
      switch (action) {
        case "create": {
          const request = resourceFactory.create("Signature", {});
          slug = [
            "event",
            event.keyword,
            "signature-request",
            get(signature, "signatureRequest.hash"),
            "signature",
          ];
          request.setSignatureRequest(signature.signatureRequest);
          request.setUser(signature.user);
          resource = request;
          actionCreator = resourceCreate;
          XHR_ID = `create_event_signature_${signature.signatureRequest.hash}`;
          method = "POST";

          break;
        }
        case "edit": {
          //let hashs = [];
          let newRequest = Object.assign(signature, {
            ...value.content,
          });
          slug = [
            "event",
            event.keyword,
            "signature-request",
            get(signature, "signatureRequest.hash"),
            "signature",
            signature.hash,
          ];
          resource = newRequest;
          actionCreator = resourceEdit;
          XHR_ID = "EDIT_SIGNATURE_USER";
          method = "PATCH";
          patch = [value.patch];

          break;
        }
        case "validateSignatures": {
          const accesses = value.map((user) => {
            return resourceFactory.create("Custom", {
              type: "user",
              hash: user.hash,
              id: user.hash,
            });
          });

          slug = [
            "event",
            event.keyword,
            "signature-request",
            get(signature, "signatureRequest.hash"),
            "signature",
            "valid",
            "batch",
          ];

          resource = accesses;
          //XHR_ID = `create_event_signature_${signature.signatureRequest.hash}`;
          //resources = newSignatures;
          actionCreator = resourcesEdit;
          XHR_ID = "EDIT_BATCH_SIGNATURE";
          method = "PATCH";
          patch = ["data"];

          break;
        }
        case "postEdit": {
          const request = resourceFactory.create("Signature", {
            ...value.content,
          });
          slug = [
            "event",
            event.keyword,
            "signature-request",
            get(signature, "signatureRequest.hash"),
            "signature",
          ];
          request.setSignatureRequest(signature.signatureRequest);
          request.setUser(signature.user);
          resource = request;
          actionCreator = resourceCreate;
          XHR_ID = `create_event_signature_${signature.signatureRequest.hash}`;
          method = "POST";

          break;
        }
        default:
          break;
      }

      dispatch(
        actionCreator(resource, {
          slug,
          silent: true,
          method,
          patch,
          XHR_ID,
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          //transform: false,
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
              callbackError();
              //// TODO: error display
              return;
            } else {
              callback();
            }
          },
        })
      );
    },
    [event.keyword]
  );

  return handleSignature;
};

export const usePresentForSignature = (signatureRequestHash) => {
  //const [list, setList] = useState([]);
  const signatures = useSelector((state) =>
    signatureForSelector(state, signatureRequestHash)
  );

  const [data, allSignatures] = useMemo(() => {
    if (!signatures.length) return [[], []];
    const presents = signatures
      .filter((elem) => elem.has_signature && elem.validated_at)
      .map((elem) => elem.hash);
    return [presents, signatures.map((elem) => elem.hash)];
  }, [signatures]);
  // useEffect(() => {
  //   setList(data);
  // }, [data]);
  return [data.length, data, allSignatures];
};
