import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { desktopLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import BubbleContainer from "app/liveShopping/frontoffice/otm/containers/Chat/BubbleContainer";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: (props) => props.newHeight,
    maxHeight: "100%",
    overflowY: "scroll",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column-reverse",
    paddingLeft: 10,
    paddingRight: 10,
    maskImage: (props) => (props.isDesktop ? "none" : "linear-gradient(to bottom, transparent 1%, black 20%)"),
    WebkitMaskImage: (props) => (props.isDesktop ? "none" : "linear-gradient(to bottom, transparent 1%, black 20%)"),
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const ChatList = ({ quotes }) => {
  const [reduced, setReduced] = useState(false);
  const isDesktop = useSelector(desktopLayoutSelector);

  let newHeight = reduced ? "100px" : "200px";
  newHeight = isDesktop ? "100%" : newHeight;

  const classes = useStyles({ newHeight, isDesktop });
  const toggle = () => setReduced(!reduced);

  return (
    <Box id="onScrollDown" className={classes.root} onClick={toggle}>
      {quotes.map((quote) => {
        // check if there is an url in the quote
        const regexUrl = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/gmi;
        const url = quote.quote.match(regexUrl)
        if (url && quote.announcement) {
          quote.quote = quote.quote.replace(regexUrl, "")
          quote.url = url[0]
        }
        return <BubbleContainer key={quote.hash} quote={quote} />
      }
      )}
    </Box>
  );
};

ChatList.propTypes = {};

ChatList.defaultProps = {};

export default ChatList;
