import indexOf from "lodash/indexOf";
import forEach from "lodash/forEach";
import flattenDepth from "lodash/flattenDepth";
import { getUserSession } from "core/session";
import {
  userSelector,
  currentWizSelector,
  currentUserAccessesSelector,
} from "ressourcesDucks/selectors";
import globalStore from "appStore";

export const isUser = (scopes, user = null, event = null) => {
  const state = globalStore.getState();


  if (window.location.pathname.includes("/visio/")) return false;

  if (!user) {
    const { user_id } = getUserSession();
    user = userSelector(state, user_id);
  }
  event = event || currentWizSelector(state);
  scopes = scopes || "user";

  const allScopes = [
    "anonymous",
    "user",
    "speaker",
    "anim",
    "modo",
    "admin",
    "owner",
    "root",
  ];
  let ret = false;

  forEach(flattenDepth([scopes || ""], true), (scope) => {
    let operator = "";
    scope = scope.replace(/([\+\-]?)$/, function ($0, $1) {
      operator = $0;
      return "";
    });

    let index = indexOf(allScopes, scope);
    if (index === -1) {
      return;
    }

    const currentUserScope = getUserScope(user, event);

    if (operator === "+") {
      ret |= allScopes
        .slice(index, allScopes.length)
        .includes(currentUserScope);
    } else if (operator === "-") {
      ret |= allScopes.slice(0, index + 1).includes(currentUserScope);
    } else {
      ret |=
        scope === "owner"
          ? event && event.isOwner(user)
          : scope === currentUserScope;
    }
  });

  return !!ret;
};

export const getUserScope = (user, event) => {
  const state = globalStore.getState();

  if (user === null || user.isAnon()) return "anonymous";

  let userScopes = [
    "root",
    "owner",
    "admin",
    "modo",
    "anim",
    "speaker",
    "user",
  ];
  let userScope = null;
  let scope = null;

  for (let i = 0; !userScope && i < userScopes.length; i++) {
    scope = userScopes[i];
    switch (scope) {
      case "root":
        userScope = user.isRoot() ? scope : userScope;
        break;
      case "owner":
        userScope = event && event.isOwner(user) ? scope : userScope;
        break;
      case "admin":
      case "modo":
      case "anim":
      case "speaker":
        let accessScope = null;
        forEach(currentUserAccessesSelector(state) || [], function (access) {
          accessScope =
            !accessScope ||
              userScopes.indexOf(access.scope) < userScopes.indexOf(accessScope)
              ? access.scope
              : accessScope;
        });
        userScope = scope === accessScope ? scope : userScope;
        break;
      case "user":
        userScope = scope;
        break;
    }
  }

  return scope;
};
export const isAdmin = () => isUser("anim+");
window.isUser = isUser;
