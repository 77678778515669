import React from "react";
import PropTypes from "prop-types";

const CustomMessageIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="31"
      viewBox="0 0 35 31"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4992 0.789917C13.1187 0.789946 8.91759 2.23938 5.8201 4.81937C2.72261 7.39937 0.982452 10.8986 0.982422 14.5473C0.986414 16.9205 1.72743 19.2526 3.13346 21.3168C4.53949 23.381 6.5627 25.1071 9.00649 26.3274C8.35561 27.7845 7.37944 29.1509 6.1184 30.37C8.56387 29.9407 10.8595 29.0389 12.8266 27.7347C14.3433 28.1101 15.9169 28.302 17.4992 28.3046C21.8797 28.3046 26.0808 26.8551 29.1783 24.2751C32.2757 21.6951 34.0159 18.1959 34.0159 14.5473C34.0159 10.8986 32.2757 7.39937 29.1783 4.81937C26.0808 2.23938 21.8797 0.789946 17.4992 0.789917Z"
        fill={color}
      />
    </svg>
  );
};

CustomMessageIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomMessageIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomMessageIcon;
