import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import TextInput from "app/liveShopping/backoffice/otm/components/reusables/OutlinedTextField";
import { resourceCreate } from "ressourcesDucks/actions";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import Button from "coreUiKit/inputs/Button";
import ResourceFactory from "orm/resources";
import emitter from "core/emitter";
import { EVENT_SEND_NOTIFICATION } from "core/emitter/events";
import {
  validate,
  TYPE_FORGOT_PWD,
  TYPE_SIGNIN,
} from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import ArrowLeftShort from "react-bootstrap-icons/dist/icons/arrow-left-short";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  content: {
    width: "400px",
  },
  icon: {
    paddingRight: "8px",
    fontSize: "24px",
  },
  btn: { margin: "30px 0" },
  texts: {
    padding: "50px 0",
  },
  account: {
    display: "flex",
    alignItems: "center",
    paddingTop: "20px",
  },
  link: {
    cursor: "pointer",
  },
}));

const ForgotPassword = ({ handleSetType, email }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const formik = useFormik({
    initialValues: {
      email: email || "",
    },
    validateOnChange: false,
    validate: (values) => validate(values, TYPE_FORGOT_PWD),
    onSubmit: (values) => {
      const user = resourceFactory.create("User", { email: values.email });
      dispatch(
        resourceCreate(user, {
          slug: ["user", "lost-password"],
          XHR_ID: "user-lost-password",
          silent: true,
          callback: (error) => {
            formik.setSubmitting(false);
            if (error) {
              if (error.statusCode === 400) {
                /* prettier-ignore */
                emitter.emit(EVENT_SEND_NOTIFICATION, {
                  type: "success",
                  text: _t("A password reset request has already been made, please check your emails."),
                  button: false,
                  duration: 3,
                  hideTimer: true,
                });
                return;
              }
              emitter.emit(EVENT_SEND_NOTIFICATION, {
                type: "alert",
                text: error.statusText,
                button: false,
                duration: 3,
              });
              return;
            }
            emitter.emit(EVENT_SEND_NOTIFICATION, {
              type: "success",
              //prettier-ignore
              text: _t("Instructions for resetting your password have been sent to the address provided."),
              button: false,
              duration: 3,
              hideTimer: true,
            });
          },
        })
      );
    },
  });

  return (
    <Box className={classes.root}>
      <Box className={classes.content}>
        <Box>
          <Button
            variant="link"
            size="medium"
            color="primary"
            onClick={() => handleSetType(TYPE_SIGNIN)}
          >
            <ArrowLeftShort className={classes.icon} />
            {_t("Back to connexion")}
          </Button>
        </Box>

        <Box className={classes.texts}>
          <Typography variant={"title13"}>
            {_t("Forgotten password")}
          </Typography>
          <Box m={1} />
          <Typography color="greyDark" variant={"body15"}>
            {
              // prettier-ignore
              _t("Enter the email address associated with your account. We will send you a link to reset your password")
            }
          </Typography>
        </Box>

        <Typography variant={"bodySB13"}>{_t("E-mail")}</Typography>
        <Box mb={1} />
        <TextInput
          name={"email"}
          value={formik.values.email}
          onChange={formik.handleChange}
          type={"text"}
          fullWidth
          required
          placeholder={_t("Enter your e-mail")}
          errorMessage={formik.errors?.email}
          error={formik.errors?.email?.length}
        />
        <Button
          className={classes.btn}
          color="primary"
          fullWidth
          onClick={formik.handleSubmit}
          loading={formik.isSubmitting}
        >
          {_t("Send a reset email")}
        </Button>

        <Box className={classes.account}>
          <Typography color={"greyDark"} variant={"body15"}>
            {_t("Didn't you receive anything ?")}
          </Typography>
          <Box ml={"5px"} />
          <Typography
            className={classes.link}
            color={"primary"}
            variant={"body15"}
            onClick={formik.handleSubmit}
          >
            {_t("Resend link")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ForgotPassword.propTypes = {
  handleSetType: PropTypes.func,
  email: PropTypes.string,
};

ForgotPassword.defaultProps = {
  handleSetType: () => { },
};

export default ForgotPassword;