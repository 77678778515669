import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Form from "./Form";
import Header from "./Header"
import Footer from "./Footer"
import { useDispatch, useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import clsx from "clsx";
import { displayTabBar } from "app/state/ducks/liveShopping/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    width: "100%",
    height: "100%",
    position: "relative",
    background: "#FFF",
    zIndex: 8,
  },
  mobileLayout: {
    overflow: "auto",
    flex: "1",

  },
  desktopLayout: {
    overflow: "auto",
    flex: 1,
  }
}));

const index = ({ }) => {
  const classes = useStyles();
  const isMobile = useSelector(mobileLayoutSelector);
  const [data, setData] = useState(null)
  const dispatch = useDispatch();

  useEffect(() => {
    if (isMobile) {
      dispatch(displayTabBar(false))
    }
  }, [])

  return (
    <Box className={classes.root}>
      <Header />
      <Box className={clsx({
        [classes.mobileLayout]: isMobile,
        [classes.desktopLayout]: !isMobile
      })}>
        <Form setData={setData} wiz={null} />
      </Box>
      <Footer data={data} />
    </Box>
  );
};


index.propTypes = {};


index.defaultProps = {};


export default index;
