import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { resourceFetch } from "ressourcesDucks/actions";
import { getKeyword } from "applicationDucks/selectors";
import { pinnedQuoteSelector } from "ressourcesDucks/selectors";
import { makeStyles } from "@material-ui/core/styles";
import PinnedMessage from "../components/PinnedMessage";
import { useIsUser } from "../userHooks";
import { useLiveQuote, useQuote, useQuoteEditManager, useQuoteManager } from "../quoteHooks";
import Box from "@material-ui/core/Box";
import get from "lodash/get";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    top: "0",
    left: "0",
    zIndex: "4",
    display: "flex",
    paddingLeft: "5px",
  },
}));

const PinnedQuotes = () => {
  const classes = useStyles();
  const [isOpenPin, setIsOpenPin] = useState(true);
  const [isOpenHighlight, setIsOpenHighlight] = useState(true);
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const isAdmin = useIsUser("anim+");

  const handleOpenPin = () => {
    setIsOpenHighlight(false);
    setIsOpenPin(!isOpenPin);
  };

  const handleOpenHighlight = () => {
    setIsOpenPin(false);
    setIsOpenHighlight(!isOpenHighlight);
  };

  const hashQuoteLive = useLiveQuote();
  const hash = get(hashQuoteLive, "data.hash", null);

  useEffect(() => {
    if (hash !== null) {
      dispatch(
        resourceFetch("quote", {
          slug: ["event", keyword, "quotes", hash],
          XHR_ID: `quote_${hash}`,
          silent: true,
        })
      );
    }
  }, [keyword, hash]);

  const quoteLive = useQuote(hash);

  const quotes = useSelector(pinnedQuoteSelector);
  const quote = quotes.lastItem || null;
  const handleEditQuote = useQuoteEditManager(quote);
  const handleManageQuote = useQuoteManager(quoteLive);
  const removeHighlight = () => {
    handleEditQuote({ pinned: false });
  };

  const removePinned = () => {
    handleManageQuote("hide");
  };
  useEffect(() => {
    if (isOpenPin && quote && isOpenHighlight && quoteLive) {
      setIsOpenHighlight(false);
    }
  }, [isOpenPin, isOpenHighlight, !!quote, !!quoteLive]);

  return (
    <Box className={classes.root}>
      {quote && <PinnedMessage quote={quote} type={"pin"} handleOpenBox={handleOpenPin} isOpen={isOpenPin} remove={removeHighlight} isAdmin={isAdmin} />}
      {quoteLive && <PinnedMessage quote={quoteLive} type={"highlight"} handleOpenBox={handleOpenHighlight} isOpen={isOpenHighlight} remove={removePinned} isAdmin={isAdmin} />}
    </Box>
  );
};

PinnedQuotes.propTypes = {};

PinnedQuotes.defaultProps = {};

export default PinnedQuotes;
