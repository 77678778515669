import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Counter from "../../reusables/Counter";
import Card from "./Card";

const useStyles = makeStyles((theme) => ({
  root: {},
  typoPast: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "100%",
    color: theme.palette.colors.grey700,
  },
  top: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    //  marginTop: "44px",
    cursor: "pointer",
    gap: "16px",
  },
  topRight: {
    display: "flex",
    alignItems: "center",
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
  },
}));

const Wrapper = ({ lives, stateTitle }) => {
  const classes = useStyles();

  return (
    <Box mb={lives.length > 0 ? 5 : 0}>
      <Box className={classes.top}>
        <Typography className={classes.typoPast}>{stateTitle}</Typography>
        <Counter count={lives.length} />
      </Box>
      <Box className={classes.cards}>
        {lives.map(({ theme, title, keyword, status, live_start, live_stop }) => (
          <Card title={title} background={theme.background_image} keyword={keyword} isPublic={status !== "draft" && status !== "closed"} liveStart={live_start} liveStop={live_stop} />
        ))}
      </Box>
    </Box>
  );
};

Wrapper.propTypes = {};

Wrapper.defaultProps = {};

export default Wrapper;
