import React from "react";
import IconButton from "app/liveShopping/backoffice/otm/components/reusables/IconButton";
import { publishStateSelector } from "../../containers/Visio/VisioVonage/selectors";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import MicFill from "react-bootstrap-icons/dist/icons/mic-fill";
import MicOffFill from "react-bootstrap-icons/dist/icons/mic-mute-fill";
import CameraFill from "react-bootstrap-icons/dist/icons/camera-video-fill";
import CameraOffFill from "react-bootstrap-icons/dist/icons/camera-video-off-fill";

export const useJoinStageStyles = makeStyles((theme) => ({
  previewFooter: {
    zIndex: 3,
    position: "absolute",
    bottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 20,
    "& .MuiIconButton-root": {
      margin: "0 5px",
    },
  },
}));

const PreviewFooter = ({ mode, callback }) => {
  const classes = useJoinStageStyles();
  const theme = useTheme();
  const { publishAudio, publishVideo } = useSelector(publishStateSelector);

  const PublishButton = ({ icon, type, isPublishing }) => {
    return (
      <IconButton
        color={isPublishing ? theme.palette.colors.grey200 : theme.palette.colors.error}
        onClick={() => {
          callback(type, !isPublishing);
        }}
      >
        {icon}
      </IconButton>
    );
  };

  const modes = [];

  if (["audio", "video"].includes(mode))
    modes.push({
      type: "audio",
      icon: publishAudio ? <MicFill /> : <MicOffFill />,
      isPublishing: publishAudio,
    });

  if (mode === "video")
    modes.push({
      type: "video",
      icon: publishVideo ? <CameraFill /> : <CameraOffFill />,
      isPublishing: publishVideo,
    });

  return (
    <Box className={classes.previewFooter}>
      {modes.map(({ type, icon, isPublishing }) => (
        <PublishButton key={type} type={type} icon={icon} isPublishing={isPublishing} />
      ))}
    </Box>
  );
};

export default PreviewFooter;
