import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Box from "@material-ui/core/Box";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { _t } from "i18n";
import { resourceFetch, resourceCreate } from "ressourcesDucks/actions";
import { resourceSelector, userSelector } from "ressourcesDucks/selectors";
import ResourceFactory from "orm/resources";
import { getUserSession } from "utilities/session";
import {
  getKeyword,
  getFetchResultStatusSelector,
} from "applicationDucks/selectors";

import { genericResourcesSelector } from "ressourcesDucks/selectors";
import emitter from "core/emitter";
import {
  SURVEY_EVENT_FLASH_SUCCESS,
  EVENT_SHOW_FORM_ENDING,
  EVENT_RESET_SURVEY_FORM,
  EVENT_FLASH_SUCCESS_AFTER_GET_ANSWER,
  SURVEY_EVENT_FLASH_ERROR,
} from "core/emitter/events";
import { useCompleteSurveySubmit } from "appContainers/Loading/LoadingWrapper";
import * as Sentry from "@sentry/react";
import { InteractivitySurveyComponent } from "appComponents/Viewers";

import {
  Form,
  createFormSchema,
  initForm,
  formatFormAnswer,
  SurveyContextProvider,
  isSurveyObjectFull,
  canUserSeeEvaluationAnswer,
  useFormIntroState,
} from "appComponents/SurveyForms";

import { isValidParticipation } from "appComponents/UserVisibility/validParticipation";
import {
  getVerifiedUsername,
  isAnonymousUserParticipation,
} from "utilities/username";
import { filterFunction } from "appContainers/Viewers/UserResponse";

import memo from "core/memo";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: (props) => (props.isThumbnailView ? 0 : 10),
    borderTopRightRadius: (props) => (props.isThumbnailView ? 0 : 10),
    maxHeight: "100%",
    height: "100%",
    justifyContent: "flex-start",
    border: "none",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0",
    },
  },
  formWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100vw",
    },
  },

  surveyContent: {
    //overscrollBehavior: "contain",
    display: "flex",
    maxHeight: "100%",
    //height: "100%",
    height: (props) => (props.isThumbnailView ? "100%" : "auto"),
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
      overflow: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    // backgroundColor: (props) =>
    //   props.layoutMode === "split"
    //     ? theme.palette.background.default
    //     : "transparent",
    overflowX: "hidden",
  },
  submitSection: {
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      right: 15,
      bottom: 15,
    },
  },
  surveyItems: {
    // height: (props) =>
    //   props.showSwitch && !props.isThumbnailView
    //     ? `calc(100% - ${theme.spacing(4.75)}px)`
    //     : "100%",
    //100% - action panel - panelBottomMargin
    // marginTop: (props) =>
    //   props.displayActionPanel || props.isThumbnailView
    //     ? 0
    //     : theme.spacing(7.25),
    height: (props) =>
      !props.isThumbnailView ? `calc(100% - ${theme.spacing(7.25)}px)` : "100%",
    justifyContent: "flex-start",
    display: (props) => (props.panel == "userResponse" ? "none" : "flex"),
    [theme.breakpoints.down("xs")]: {
      height: (props) =>
        props.isThumbnailView
          ? "100%"
          : `calc(100% - ${theme.spacing(7.25)}px)`,
    },
  },
  surveyItemsAnswers: {
    borderRadius: 15,
    // marginTop: (props) =>
    //   props.displayActionPanel || props.isThumbnailView
    //     ? 0
    //     : theme.spacing(7.25),
    height: (props) =>
      !props.isThumbnailView ? `calc(100% - ${theme.spacing(7.25)}px)` : "100%",
    display: (props) => (props.panel == "userResponse" ? "none" : "flex"),
  },
  userResponsePanel: {
    // marginTop: (props) =>
    //   props.displayActionPanel || props.isThumbnailView
    //     ? 0
    //     : theme.spacing(7.25),
    height: (props) =>
      !props.isThumbnailView ? `calc(100% - ${theme.spacing(7.25)}px)` : "100%",
    //boxShadow: theme.palette.shadow,
    //borderRadius: 15,
    // borderBottomLeftRadius: (props) => (props.isThumbnailView ? 0 : "15px"),
    // borderBottomRightRadius: (props) => (props.isThumbnailView ? 0 : "15px"),
    // borderTopLeftRadius: (props) =>
    //   props.isThumbnailView || props.layoutMode === "split" ? 0 : "15px",
    // borderTopRightRadius: (props) =>
    //   props.isThumbnailView || props.layoutMode === "split" ? 0 : "15px",
    display: (props) => (props.panel === "form" ? "none" : "flex"),
  },
  actionPanelContent: {
    display: (props) => (props.showSwitch ? "flex" : "none"),
  },
  actionPanel: {
    display: (props) =>
      !props.isThumbnailView && !props.showIntro ? "flex" : "none",
    alignItems: "center",
    height: theme.spacing(7.25),
    width: "100%",
    flexDirection: "row",
    justifyContent: "center",
    // backgroundColor: (props) =>
    //   props.layoutMode === "split"
    //     ? theme.palette.survey.titleBg
    //     : "transparent",
    // backgroundColor: (props) =>
    //   props.showSwitch ? theme.palette.background.secondaryBox : "transparent",
    backgroundColor: theme.palette.background.secondaryBox,
    //marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      //marginBottom: "12px",
    },
  },
}));

const InteractivitySurveyViewer = ({
  layoutMode,
  hasFocus,
  survey,
  surveyItem,
  keyword,
  resourceFetch,
  surveyHash,
  fetchSurveyStatus,
  answerSurveryStatus,
  resourceCreate,
  resourceFactory,
  type,
  displayResult,
  fetchUserAnswersStatus,
  answers,
  actionDispatcher,
  preview,
}) => {
  const [formData, setFormData] = useState({});
  const [formSchema, setFormSchema] = useState({});
  const [showSwitch, setShowSwitch] = useState(false);
  const [justAnswerForm, setJustAnswerForm] = useState(false);
  const [forceFetchAnswers, setForceFetchAnswers] = useState(false);
  const [panel, setPanel] = useState("form"); //form/userResponse
  const showIntro = useFormIntroState(survey && survey.hash);

  //a custom hook that says if user is not waiting for answer after submit
  //it also has listener on EVENT_FLASH_SUCCESS_AFTER_GET_ANSWER event
  useCompleteSurveySubmit({
    waitForSurveyAnswer: surveyHash,
    fetchDataStatus: answerSurveryStatus,
  });
  const isThumbnailView = layoutMode === "pip" && !hasFocus;
  const displayBasedOnSwitch =
    (survey && survey.multiple_answers) ||
    (survey && !survey.multiple_answers && !showSwitch);
  const isAnswerableForm =
    type === "survey" ||
    (type === "surveyItem" &&
      survey &&
      survey.type === "poll" &&
      !displayResult);
  const displaySubmit =
    isAnswerableForm && !isThumbnailView && displayBasedOnSwitch;
  const classes = useStyles({
    isThumbnailView,
    layoutMode,
    displaySubmit,
    panel,
    showSwitch,
    showIntro,
    displayActionPanel: showSwitch && !isThumbnailView && !showIntro,
  });

  const section = actionDispatcher === "timeline" ? "timeline" : "present";
  useEffect(() => {
    if (survey) {
      const [_formData, formSchema] = initForm(
        createFormSchema(survey.items.toModelArray())
      );
      setFormData(_formData);
      setFormSchema(formSchema);
    }
  }, [survey]);

  //on survey Change
  useEffect(() => {
    if (panel !== "form") setPanel("form");
    if (forceFetchAnswers) setForceFetchAnswers(false);
  }, [surveyHash]);

  useEffect(() => {
    if (
      isEmpty(survey) ||
      (survey &&
        !isSurveyObjectFull(survey) &&
        !isEqual(fetchSurveyStatus, "pending"))
    ) {
      setShowSwitch(false);
      resourceFetch("survey", {
        slug: ["event", keyword, "surveys", surveyHash],

        XHR_ID: `interactivitySurvey${surveyHash}`,
      });
    }
  }, [resourceFetch, survey, surveyHash, keyword, fetchSurveyStatus]);

  //(!showSwitch || watchForAnswer) setWatchForAnswer state after i submit with success
  if (!isEqual(fetchUserAnswersStatus, "pending") && !isEmpty(answers)) {
    if (canUserSeeEvaluationAnswer(survey)) {
      //check if evaluation finished
      if (!showSwitch) setShowSwitch(true);
      //should systematicly show response panel if form or evaluation and user just answer

      if (
        survey &&
        (survey.type === "evaluation" || survey.type === "form") &&
        justAnswerForm
      ) {
        setPanel("userResponse");
        //make sure userResponseMap is set
        emitter.emit(EVENT_SHOW_FORM_ENDING, survey.hash);
        setJustAnswerForm(false);
      }
    }
  }
  if (isEmpty(answers) && showSwitch) {
    setShowSwitch(false);
  }

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    try {
      isValidParticipation({
        callBackOnInvalid: () => {
          setSubmitting(false);
        },
        callbackOnValid: () => {
          const isAnonymous = isAnonymousUserParticipation() ? true : false;
          const newAnswer = resourceFactory.create("SurveyAnswer", {
            is_anonymous: isAnonymous,
            username: getVerifiedUsername(),
            via: "web",
          });
          newAnswer.setSurvey(survey);
          const items = formatFormAnswer(values, survey);

          resourceCreate(newAnswer, {
            slug: ["event", keyword, "surveys", surveyHash, "answers"],
            XHR_ID: `answerSurvey_${surveyHash}`,
            inject: { path: "data.attributes.items", value: items },
            headers: {
              "Wisembly-App-Id": null, //prevent push event from being ignored
            },
            callback: (error) => {
              if (error) {
                console.log("error while responding", error);
              } else {
                //this callback will be called after i get survey answer
                const customCallBack = () => {
                  //emit this only after i get answer
                  emitter.emit(SURVEY_EVENT_FLASH_SUCCESS, {
                    surveyHash,
                    message: _t("You answer has been submitted."),
                    section,
                  });
                  emitter.emit(EVENT_RESET_SURVEY_FORM, survey.hash);
                  setJustAnswerForm(true);
                  setForceFetchAnswers(true);
                };
                emitter.emit(
                  EVENT_FLASH_SUCCESS_AFTER_GET_ANSWER,
                  customCallBack
                );
              }
            },
          });
          setSubmitting(false);
        },
      });
    } catch (e) {
      Sentry.captureException(e);
      setSubmitting(false);
      emitter.emit(SURVEY_EVENT_FLASH_ERROR, {
        surveyHash,
        message: _t("Unable to process your answer"),
        section,
      });
    }
  };

  if (
    (isEqual(fetchSurveyStatus, "pending") ||
      isEmpty(fetchSurveyStatus) ||
      isEmpty(formSchema)) &&
    isEmpty(survey)
  ) {
    if (panel !== "form") setPanel("form");
    if (forceFetchAnswers) setForceFetchAnswers(false);
    return <CircularProgress />;
  }

  if (isEmpty(survey)) return <div>{_t("unable to get the survey")}</div>;
  if (
    isEmpty(formSchema) &&
    !["form", "evaluation"].includes(get(survey, "type"))
  ) {
    return <Box>{_t("unable to show survey form")}</Box>;
  }

  return (
    <Form
      classes={classes.formWrapper}
      enableReinitialize
      initialValues={formData}
      onSubmit={onSubmit}
      survey={survey}
      surveyHash={surveyHash}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
    >
      <SurveyContextProvider
        surveyHash={surveyHash}
        survey={survey}
        panel={panel}
        isAnswerableForm={isAnswerableForm}
        section={section}
        displaySubmit={displaySubmit}
        isEmptyAnswers={isEmpty(answers)}
        preview={preview}
      >
        <InteractivitySurveyComponent
          classes={classes}
          panel={panel}
          setPanel={setPanel}
          isAnswerableForm={isAnswerableForm}
          survey={survey}
          isThumbnailView={isThumbnailView}
          layoutMode={layoutMode}
          answers={answers}
          formSchema={formSchema}
          surveyItem={surveyItem}
          surveyHash={surveyHash}
          actionDispatcher={actionDispatcher}
        />
      </SurveyContextProvider>
    </Form>
  );
};

InteractivitySurveyViewer.propTypes = {
  survey: PropTypes.object,
  items: PropTypes.array,
  hash: PropTypes.string,
  resourceFetch: PropTypes.func,
  surveyHash: PropTypes.string,
  keyword: PropTypes.string,
  fetchSurveyStatus: PropTypes.string,
  answerSurveryStatus: PropTypes.string,
  user: PropTypes.object,
  resourceCreate: PropTypes.func,
  resourceFactory: PropTypes.object,
  type: PropTypes.string,
  surveyItem: PropTypes.array,
  displayResult: PropTypes.bool,
  anonymousUserParticipation: PropTypes.bool,
  layoutMode: PropTypes.string,
  hasFocus: PropTypes.bool,
  answers: PropTypes.array, //[surveyAnswer]
  fetchUserAnswersStatus: PropTypes.string,
  actionDispatcher: PropTypes.string,
  preview: PropTypes.bool,
};

InteractivitySurveyViewer.defaultProps = {
  survey: {},
  type: "survey", //either survey or surveyItem
  displayResult: false,
  preview: false,
};

const mapStateToProps = (state, ownProps) => {
  const session = getUserSession();
  const { user_id } = session;
  const token = session.token;
  const survey = resourceSelector(state, {
    type: "Survey",
    id: ownProps.surveyHash,
  });

  const user = userSelector(state, user_id);
  let surveyItem = null;

  if (!isEmpty(survey) && ownProps.type === "surveyItem") {
    const items = survey.items.toModelArray();
    surveyItem = items.filter((item) => item.hash === ownProps.surveyItemHash);
  }

  return {
    resourceFactory: new ResourceFactory(state),
    survey,
    surveyItem,
    keyword: getKeyword(state),
    fetchSurveyStatus: getFetchResultStatusSelector(
      state,
      `interactivitySurvey${ownProps.surveyHash}`
    ),
    answerSurveryStatus: getFetchResultStatusSelector(
      state,
      `answerSurvey_${ownProps.surveyHash}`
    ),
    user,
    answers: genericResourcesSelector(state, {
      type: "SurveyAnswer",
      filter: filterFunction(
        user ? user.hash : null,
        token,
        ownProps.surveyHash
      ),
      order: ["desc"],
      sort: ["answered_at"],
    }),
    fetchUserAnswersStatus: getFetchResultStatusSelector(
      state,
      `user_me_survey_${ownProps.surveyHash}_answers`
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resourceFetch: bindActionCreators(resourceFetch, dispatch),
    resourceCreate: bindActionCreators(resourceCreate, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(InteractivitySurveyViewer));
