import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { useFormik } from "formik";
import Input from "../../reusables/Input";
import Select from "../../reusables/Select";
import DatePicker from "../../reusables/DatePicker/DatePicker";
import dayjs from "utilities/utils/date";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { ExclamationTriangle, Image } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { desktopLayoutSelector, projectsSelector } from "app/state/ducks/liveShopping/selectors";
import { slug } from "../../utils";
import { optionsDuration } from "./utils";
import { validate as defaultValidate, keywordValidation } from "./utils";
import Switch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import TextArea from "../../reusables/TextArea";
import { resourceFetch } from "app/state/ducks/ressources/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => (props.isDesktop ? "390px" : "100%"),
    height: (props) => props.isDesktop && "75vh",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
  },
  typoKeyword: {
    fontSize: "12px",
    color: theme.palette.colors.grey400,
  },
  inputWrapper: {
    margin: "16px 20px",
  },
  alertKeyword: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    borderRadius: "5px",
    padding: "5px 8px",
  },
  typoAlert: {
    fontSize: "12px",
    color: theme.palette.colors.error,
  },
  errors: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.error,
  },
  iframe: {
    border: 0,
    width: "112px",
    height: "187px",
    borderRadius: "12px",
  },
}));

const Form = ({ setData, wiz }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const dispatch = useDispatch();
  const classes = useStyles({ isDesktop });
  const [dateStart, setDateStart] = useState(dayjs(wiz?.live_start) || dayjs());
  const [cartIntegration, setCartIntegration] = useState(wiz?.cart_integration || false);
  const [moderate, setModerate] = useState(wiz?.moderate || false);
  const [isPublic, setIsPublic] = useState(wiz?.is_public || false);
  const [isOTO, setIsOTO] = useState(!!wiz?.oto_integration_id);
  const [selectedDuration, setSelectedDuration] = useState(0);
  const projects = useSelector(projectsSelector);

  useEffect(() => {
    dispatch(
      resourceFetch("project", {
        slug: ["projects"],
        once: true,
        silent: true,
      })
    );
  }, []);

  const addMinutesToDate = (minutes, dateToChange) => dateToChange.add(minutes, "minute");
  const theme = useTheme();

  const setupDateStop = (e) => {
    setSelectedDuration(e.target.value);
    const live_stop = addMinutesToDate(e.target.value, dateStart);
    formik.setValues({ ...formik.values, live_stop: live_stop?.$d });
  };

  const handleChangeTitle = (e) => formik.setValues({ ...formik.values, title: e.target.value });

  const handleChangeKeyword = (e) => formik.setValues({ ...formik.values, keyword: e.target.value });

  const handleChangeDate = (currentDate) => {
    formik.setValues({ ...formik.values, live_start: dayjs(currentDate.$d) });
    setDateStart(dayjs(currentDate.$d));
  };

  const defaultValueDuration = () => dayjs(wiz?.live_stop).diff(dayjs(wiz?.live_start), "minutes") || 30;

  const diffToAdd = dayjs(wiz?.live_stop).diff(dayjs(wiz?.live_start), "minutes") || selectedDuration;

  useEffect(() => {
    formik.setValues({ ...formik.values, live_stop: dayjs(dateStart.$d).add(diffToAdd, "minutes") });
  }, [dateStart]);

  const validate = (values) => {
    const errors = { ...defaultValidate(values), ...keywordValidation(values) };
    return errors;
  };

  const handleCartIntegration = () => {
    setCartIntegration(!cartIntegration);
    formik.setValues({ ...formik.values, cart_integration: !cartIntegration });
  };

  const handleModerate = () => {
    setModerate(!moderate);
    formik.setValues({ ...formik.values, moderate: !moderate });
  };

  const handleIsPublic = () => {
    setIsPublic(!isPublic);
    formik.setValues({ ...formik.values, is_public: !isPublic });
  };

  const handleIsOTO = (formik) => {
    if (!isOTO)
      formik.setValues({ ...formik.values, oto_integration_id: "" });

    setIsOTO(!isOTO);
  };

  const formik = useFormik({
    initialValues: {
      title: wiz?.title || "",
      start: wiz?.start || dayjs(),
      stop: wiz?.stop || dayjs(dateStart.$d).add(30, "day").$d,
      keyword: wiz?.keyword || "",
      live_start: wiz?.live_start || dayjs(dateStart.$d).add(60, "minutes").$d,
      live_stop: wiz?.live_stop || dayjs(dateStart.$d).add(90, "minutes").$d,
      cart_integration: wiz?.cart_integration || false,
      moderate: wiz?.moderate || false,
      is_public: wiz?.is_public || false,
      oto_integration_id: wiz?.oto_integration_id || "",
      extra_settings: wiz?.extra_settings || {
        event_description: wiz?.extra_settings?.event_description || "",
      },
    },
    validate: validate,
  });

  useEffect(() => {
    setData(formik.values);
  }, [formik.values]);

  return (
    <form className={classes.root}>
      <Box className={classes.inputWrapper}>
        <label className={classes.label} htmlFor="title">
          {_t("Title")}
        </label>
        <Input
          onBlur={formik.handleBlur}
          name={"title"}
          onChange={(e) => {
            handleChangeTitle(e);
            !wiz && formik.setFieldValue("keyword", slug(e.target.value));
          }}
          value={formik.values.title}
          placeholder={_t("Title of the live")}
        />
        {formik.touched.title && formik.errors.title && <Typography className={classes.errors}>{formik.errors.title}</Typography>}
      </Box>

      <Box className={classes.inputWrapper}>
        <label className={classes.label} htmlFor="keyword">
          {_t("Keyword")}
        </label>
        <Input onBlur={formik.handleBlur} name={"keyword"} onChange={(e) => handleChangeKeyword(e)} value={formik.values.keyword} placeholder={_t("Keyword")} />
        {formik.touched.keyword && formik.errors.keyword && <Typography className={classes.errors}>{formik.errors.keyword}</Typography>}
        <Box className={classes.alertKeyword}>
          {wiz && (
            <>
              <ExclamationTriangle color={theme.palette.colors.error} />
              <Typography className={classes.typoAlert}>{_t("Changing a keyword can break your e-commerce existing integration")}</Typography>
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.inputWrapper}>
        <label className={classes.label} htmlFor="start">
          {_t("Date of the live")}
        </label>
        <DatePicker date={dateStart} inputOnChange={(currentDate) => handleChangeDate(currentDate)} />
      </Box>

      <Box className={classes.inputWrapper}>
        <label className={classes.label} htmlFor="duration">
          {_t("Approximative duration")}
        </label>
        <Select defaultValue={defaultValueDuration()} options={optionsDuration} name={"duration"} onChange={(e) => setupDateStop(e)} placeholder={_t("Please enter an approximative duration")} />
      </Box>

      <Box className={classes.inputWrapper}>
        <label className={classes.label}>{_t("Cart integration")}</label>
        <Switch onClick={handleCartIntegration} checked={cartIntegration} />
      </Box>

      <Box className={classes.inputWrapper}>
        <label className={classes.label}>{_t("Moderation Mode")}</label>
        <Switch onClick={handleModerate} checked={moderate} />
      </Box>

      <Box className={classes.inputWrapper}>
        <label className={classes.label}>{_t("Public")}</label>
        <Switch onClick={handleIsPublic} checked={isPublic} />
      </Box>

      <Box className={classes.inputWrapper}>
        <label className={classes.label}>{_t("Activate One To One Feature")}</label>
        <Switch disabled={projects.length === 0} onClick={() => handleIsOTO(formik)} checked={isOTO} />
      </Box>

      {isOTO && projects.length > 0 && (
        <Box pl={2} mt={0} className={classes.inputWrapper}>
          <label className={classes.label} htmlFor="start">
            {_t("OTO Project")}
          </label>
          <Select
            defaultChoice={_t("Choose an OTO project")}
            defaultValue={formik.values.oto_integration_id}
            options={[{ hash: "", name: _t("Choose among your existing projects") }, ...projects.map(({ id, title }) => ({ hash: id, name: title }))]}
            name={"oto_integration_id"}
            onChange={(e) => formik.setValues({ ...formik.values, oto_integration_id: e.target.value })}
            placeholder={_t("Choose an OTO project")}
          />
        </Box>
      )}

      <Box className={classes.inputWrapper} style={{ paddingBottom: "20px" }}>
        <label className={classes.label}>{_t("Description")}</label>
        <TextArea
          value={formik.values.extra_settings.event_description}
          onChange={(e) => {
            formik.setValues({
              ...formik.values,
              extra_settings: { event_description: e.target.value },
            });
          }}
          placeholder={_t("Description")}
        />
      </Box>
    </form>
  );
};

Form.propTypes = {};

Form.defaultProps = {};

export default React.memo(Form);
