import React, { forwardRef, useState, useMemo, useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUpRounded";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
//import List from "@material-ui/core/List"
import MenuItem from "appComponents/Mui/MenuItem";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import cloneDeep from "lodash/cloneDeep";
import ActionButton from "appComponents/Mui/ActionButton";
import isArray from "lodash/isArray";

const useStyles = makeStyles((theme) => ({
  selectedItem: {
    borderBottom: `2px solid ${theme.wisemblyColors.orange}`,
  },
  menuBox: {
    position: "relative",
    margin: 0,
    height:"40px",
  },

  arrowUp: {
    position: "absolute",
    width: 0,
    height: 0,
    borderLeft: "15px solid transparent",
    borderRight: "15px solid transparent",
    top: -theme.spacing(2),
    zIndex: theme.zIndex.modal,
    //marginTop: - theme.spacing(1.4),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      left: "45%",
    },
    borderBottom: "15px solid white",
  },
  menuList: {
    paddingTop: 0,
    marginTop: theme.spacing(1),
  },
  menuPaper: {
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.colors.surface1,
    boxShadow: theme.palette.shadow,
    minWidth: (props) =>
      props.buttonWidth ? `${props.buttonWidth}px` : "100px",
  },
  label: {
    fontSize: "12px",
    fontWeight: "normal",
    letterSpacing: "0.5px",
    //textTransform: "capitalize",
  },
  badgeRoot: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.wisemblyColors.black,
  },
}));
const getDefaultOptions = (defaultOption, options) => {
  let result = [];
  if (!defaultOption) return [];
  if (isArray(defaultOption)) {
    result = defaultOption.map((opt) => opt.hash);
  } else {
    result = [defaultOption.hash];
  }

  if (result.includes("all")) {
    result = options.map((opt) => opt.hash);
  }
  return result;
};
const anchorOrigin = {
  vertical: "bottom",
  horizontal: "left",
};
const transformOrigin = {
  vertical: "top",
  horizontal: "left",
};
const FilterMenu = ({
  title,
  options,
  //highlight,
  id,
  notifyParentOnClick,
  //badgeContent,
  type,
  onFilterChange,
  defaultOption,
  buttonVariant,
  inactiveColor,
  ml,
  icon,
  keepOptionSelected,
  staticTitle,
  disableFixedOptions,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const classes = useStyles({
    buttonWidth: buttonRef.current && buttonRef.current.clientWidth,
  });
  //getDefaultOptions (defaultOption)
  const [selectedOptions, setSelectedOptions] = useState(
    getDefaultOptions(defaultOption, options)
  );

  const onClose = () => handleClose(null);
  useEffect(() => {
    setSelectedOptions(getDefaultOptions(defaultOption, options));
  }, [JSON.stringify(defaultOption)]);
  const menuTitle = useMemo(() => {
    if (type !== "checkbox" && !isEmpty(selectedOptions) && !staticTitle) {
      const data = options.filter((elem) => elem.hash === selectedOptions[0]);

      return get(data, "[0].title", title);
    } else return title;
  }, [selectedOptions, JSON.stringify(options), type, title, staticTitle]);

  const handleClick = (event) => {
    if (open) {
      handleClose();
      return;
    }
    if (get(event, "currentTarget")) {
      setAnchorEl(get(event, "currentTarget"));
    }

    setOpen(true);

    notifyParentOnClick();
  };

  const handleClose = (selected) => {
    if (selected !== null) {
      let nextSelection = cloneDeep(selectedOptions);
      if (nextSelection.includes(selected) && type === "checkbox") {
        if (keepOptionSelected.includes(selected)) {
          return true;
        }
        if (selected === "all") {
          nextSelection = [];
        } else {
          if (nextSelection.includes("all")) {
            const allIndex = nextSelection.indexOf("all");
            if (allIndex > -1) {
              nextSelection.splice(allIndex, 1);
            }
          }
          const index = nextSelection.indexOf(selected);
          if (index > -1) {
            nextSelection.splice(index, 1);
          }
        }
      } else {
        if (type === "select" || type === "selectText") {
          nextSelection = [];
        }
        if (selected === "all") {
          nextSelection = options.map((opt) => opt.hash);
        } else if (selected !== "") {
          nextSelection.push(selected);
        }
      }
      if (!staticTitle) {
        setSelectedOptions(nextSelection);
      }

      //improve control here to handle filter without checkbox
      const selection =
        type === "checkbox"
          ? {
              type: id,
              list: nextSelection,
            }
          : {
              type: id,
              value: selected,
            };

      onFilterChange && onFilterChange(selection);
    }
    if (!selected || type !== "checkbox") {
      setAnchorEl(null);
      setOpen(false);
    }
  };

  const endIcon = !isEmpty(options) ? (
    !open ? (
      <KeyboardArrowDownIcon fontSize="small" />
    ) : open ? (
      <KeyboardArrowUpIcon fontSize="small" />
    ) : null
  ) : null;

  return (
    <Box className={classes.menuBox}>
      <ActionButton
        title={<Typography className={classes.label}>{menuTitle}</Typography>}
        badgeContent={0}
        toogleColor={false}
        endIcon={endIcon}
        disableRipple
        onClick={handleClick}
        variant={buttonVariant}
        inactiveColor={inactiveColor}
        icon={icon}
        ml={ml}
        ref={buttonRef}
      />

      {!isEmpty(options) && (
        <Menu
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          id={`${title}-menu`}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={onClose}
          classes={{
            list: classes.menuList,
            paper: classes.menuPaper,
          }}
        >
          <Box style={{ position: "relative" }}>
            {options.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={handleClose}
                  item={item}
                  selectedOptions={selectedOptions}
                  type={type}
                  id={id}
                  disabled={
                    disableFixedOptions &&
                    keepOptionSelected.includes(item.hash)
                  }
                />
              );
            })}
          </Box>
        </Menu>
      )}
    </Box>
  );
};
FilterMenu.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      title: PropTypes.string,
      menu: PropTypes.shape({
        menuId: PropTypes.string,
        activated: PropTypes.bool,
        order: PropTypes.number,
      }),
    })
  ),
  highlight: PropTypes.bool,
  notifyParentOnClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  id: PropTypes.string,
  badgeContent: PropTypes.number,
  type: PropTypes.string,
  defaultOption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  buttonVariant: PropTypes.string,
  inactiveColor: PropTypes.string,
  ml: PropTypes.number,
  icon: PropTypes.element,
  keepOptionSelected: PropTypes.array,
  staticTitle: PropTypes.bool,
  disableFixedOptions: PropTypes.bool,
};

FilterMenu.defaultProps = {
  highlight: false,
  options: [],
  notifyParentOnClick: () => {},
  badgeContent: 0,
  buttonVariant: "text",
  inactiveColor: "tertiary",
  ml: 0,
  icon: null,
  keepOptionSelected: [],
  staticTitle: false,
  disableFixedOptions: false,
};

export default FilterMenu;
