import React, { useState } from "react";
import Avatar from "./Avatar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CustomChevronDown from "app/liveShopping/assets/icons/CustomChevronDown";
import CustomChevronUp from "app/liveShopping/assets/icons/CustomChevronUp";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ProfilDropDown from "liveShopping/backoffice/otm/components/Profil/ProfilDropDown";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  profil: {
    cursor: "pointer",
  },
  chevron: {
    margin: "0 5px",
    color: theme.palette.colors.blackMedium,
  },
}));

const Profil = ({ user }) => {
  const classes = useStyles();

  const [detailsOpen, setDetailsOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setDetailsOpen(false)}>
      <Box className={classes.root}>
        <Box
          className={classes.profil}
          onClick={() => setDetailsOpen(!detailsOpen)}
        >
          <Avatar user={user}>
            <Box className={classes.chevron}>
              {!detailsOpen ? (
                <CustomChevronDown size={10} />
              ) : (
                <CustomChevronUp size={10} />
              )}
            </Box>
          </Avatar>
        </Box>
        {detailsOpen && <ProfilDropDown user={user} />}
      </Box>
    </ClickAwayListener>
  );
};

Profil.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Profil;
