import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import { _t } from "i18n";
//// TODO: add options to call a custom callbacks
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log("error in boundary", error);
    console.log(errorInfo);
    Sentry.captureException(error);
    Sentry.captureException(errorInfo);
    this.forceUpdate();
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>{_t("Something went wrong please reload.")}</h1>;
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node,
};
export default ErrorBoundary;
