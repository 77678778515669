import React, { useRef, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import Popover from "@material-ui/core/Popover";
import AddTag from "coreComponents/Tag/AddTag";
import ModifyTag from "coreComponents/Tag/ModifyTag";
import ListTags from "coreComponents/Tag/ListTags";

const useStyles = makeStyles((theme) => ({
    popover: {
        marginTop: "6px",
    },
}));

const TagPopover = ({
  tags,
  tagsQuote,
  anchorEl,
  setAnchorEl,
  tag,
  setTag,
  quoteAddTag,
  quoteRemoveTag,
  createTag,
  editTag,
  deleteTag,
  hasRightAlign,
}) => {
    const classes = useStyles({ hasRightAlign });
    const popoverActions = useRef();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [view, setView] = useState();

    return (
        <Popover
            action={popoverActions}
            className={classes.popover}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: hasRightAlign ? 'right' : 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: hasRightAlign ? 'right' : 'left',
            }}>
            {
                view === "add" ?
                    <AddTag setView={setView} createTag={createTag} tags={tags} popoverActions={popoverActions} />
                    : view === "modify" ?
                        <ModifyTag tag={tag} setView={setView} editTag={editTag} deleteTag={deleteTag} tags={tags} popoverActions={popoverActions} />
                        : <ListTags tags={tags} tagsQuote={tagsQuote} setTag={setTag} setView={setView} quoteAddTag={quoteAddTag} quoteRemoveTag={quoteRemoveTag} />
            }
        </Popover>
    );
};

TagPopover.propTypes = {
    anchorEl: PropTypes.object,
    setAnchorEl: PropTypes.func,
    tags: PropTypes.array,
    tagsQuote: PropTypes.array,
    tag: PropTypes.object,
    setTag: PropTypes.func,
    quoteAddTag: PropTypes.func,
    quoteRemoveTag: PropTypes.func,
    createTag: PropTypes.func,
    editTag: PropTypes.func,
    deleteTag: PropTypes.func,
};

TagPopover.defaultProps = {
    anchorEl: Element,
    setAnchorEl: () => { },
    tags: [],
    tagsQuote: [],
};

export default TagPopover;
