import React from "react";
//import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { _t } from "i18n";
import { useSurveyContextState } from "appComponents/SurveyForms";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  metaText: {
    color: theme.palette.text.lightContrast,
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
    fontSize: "12px",
    marginBottom: 35,
  },
}));
const ItemMeta = () => {
  const classes = useStyles();
  const surveyContext = useSurveyContextState();
  return (
    <Typography className={classes.metaText}>
      {surveyContext.multipleAnswers
        ? _t("You can answer this many time.")
        : _t("You can answer this only once.")}
    </Typography>
  );
  // const notify_cant_see_answer_meta = (
  //   <Box className={classes.meta}>
  //     <Typography className={classes.metaText}>
  //       {_t("your answer will be available when the admin shows it.")}
  //     </Typography>
  //   </Box>
  // );
};

ItemMeta.propTypes = {};

export default ItemMeta;
