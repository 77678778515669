import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Divider from "@material-ui/core/Divider";
import Person from "react-bootstrap-icons/dist/icons/person";
import BoxArrowLeft from "react-bootstrap-icons/dist/icons/box-arrow-left";
import clsx from "clsx";
import { logOut } from "app/state/ducks/application/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getUrl } from "app/views/containers/Application/Routes";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import Notifications from "./Notifications";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    background: theme.palette.colors.white,
    boxShadow: "0px 5px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    background: "white",
    padding: "15px",
    position: "absolute",
    right: "0",
    marginTop: "8px",
    minWidth: "172px",
    zIndex: "9999",
  },
  name: {
    color: theme.palette.colors.blackMedium,
    margin: "5px 0",
  },
  mail: {
    color: theme.palette.colors.greyDark,
  },
  divider: {
    backgroundColor: theme.palette.colors.greyMedium,
    width: "calc(100% - 15px)",
    height: "1px",
    margin: "15px auto 0 auto",
  },
  icons: {
    width: "14px",
    height: "14px",
    color: theme.palette.colors.blackMedium,
  },
  links: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "15px 0 0 0",
    color: theme.palette.colors.blackMedium,
    cursor: "pointer",
    gap: "8px",
  },
  logout: {
    color: theme.palette.colors.error,
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "150%",
  },
  account: {
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "150%",
  },

}));

const ProfilDropDown = ({ user }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogOut = async () => {
    dispatch(logOut("LOGOUT"));
  };



  return (
    <Box className={classes.root}>
      <Box>
        <Typography className={classes.name} variant="bodySB1">
          {user?.name}
        </Typography>
        <Typography className={classes.mail} variant="smallBody13">
          {user?.email}
        </Typography>
      </Box>
      <Divider classes={{ root: classes.divider }} />
      <Box>
        <Box className={classes.links}>
          <Notifications user={user} />
        </Box>
        <Box className={classes.links} onClick={() => history.push(getUrl("user_account"))}>
          <Person className={classes.icons} />
          <Typography className={classes.account}>{_t("My account")}</Typography>
        </Box>
        <Box
          className={clsx({
            [classes.links]: true,
            [classes.logout]: true,
          })}
          onClick={handleLogOut}
        >
          <BoxArrowLeft className={clsx(classes.logout, classes.icons)} />
          <Typography className={classes.logout} >
            {_t("Log out")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

ProfilDropDown.propTypes = {};

export default ProfilDropDown;
