import { Box, makeStyles, Typography, useTheme } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import TextArea from "app/liveShopping/backoffice/otm/components/reusables/TextArea";
import React, { useState, useEffect } from "react";
import { _t } from "utilities/i18n";
import ResourceFactory from "orm/resources";
import { setCurrentVisitor } from "app/state/ducks/liveShopping/actions";
import { useDispatch, useSelector } from "react-redux";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import { rateAndComment } from "../../../service";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { emit } from "utilities/liveshopping-sdk";
import { destroyPublisher } from "../../../containers/Visio/VisioVonage/actions";
import Send from "react-bootstrap-icons/dist/icons/send";
import Rate from "./Rate";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    background: theme.palette.colors.white,
    width: props => props.isMobile ? "95%" : "100%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: 10,
    borderRadius: "5px",
  },
  divider: {
    borderRight: "1px solid #F0F0F4",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    textAlign: "center",
    margin: "0 24px",
  },
  on: {
    width: "30px",
    height: "30px",
    borderRadius: "99px",
    border: "1px solid #000",
    padding: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    background: "#000",
    color: "#fff",
  },
  buttons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "24px",
    marginTop: "24px",
  },
  sendButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    padding: "12px 24px",
  },
  typoSend: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
  },
  ignore: {
    color: theme.palette.colors.grey500,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    cursor: "pointer",
  }
}));

const Nps = ({ }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const dispatch = useDispatch();
  const currentVisitor = useSelector(currentVisitorSelector);
  const [isSending, setIsSending] = useState(true);
  const theme = useTheme();

  useEffect(() => {
    dispatch(destroyPublisher(currentVisitor?.options?.mode));
    emit("RESIZE", { size: "default" });
  }, []);



  const handleChange = (e) => {
    setComment(e.target.value);
  }

  const handleSend = () => {
    const newRateAndComment = resourceFactory.create("Visitor", { nps_comment: comment, nps_score: rating });

    rateAndComment(dispatch, currentVisitor, newRateAndComment, (error, response) => {
      if (error) {
        console.log(error)
      }

      setIsSending(false);
      setTimeout(() => {
        dispatch(setCurrentVisitor(null));
        emit("QUIT");
      }, 1500);
    });
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Thank you, how did you find this exchange?")}</Typography>
      <Rate setRating={setRating} rating={rating} />
      {(rating && rating <= 7) && <TextArea name="comment" onChange={(e) => handleChange(e)} value={comment} placeholder={_t("Leave a comment")} />}
      <Box className={classes.buttons}>
        <Button clsName={classes.sendButton} color={isSending ? theme.palette.colors.primary : theme.palette.colors.success} onClick={handleSend}>
          <Send size={16} />
          <Typography className={classes.typoSend}>{isSending ? _t("Send") : _t("Sent")}</Typography>
        </Button>
        <Typography onClick={() => {
          dispatch(setCurrentVisitor(null));
          emit("QUIT");
        }} className={classes.ignore}>{_t("Ignore")}</Typography>
      </Box>
    </Box>
  );
}
export default Nps;
