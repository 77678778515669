import { formatPrice } from "app/liveShopping/utils";
import { currentUserAccessesSelector, currentUserSelector } from "app/state/ducks/ressources/selectors";
import { browserName } from "detect-browser";
import { useSelector } from "react-redux";

export const unixToTwoDigits = (unix_timestamp) => {

  const convertedDate = new Date(unix_timestamp * 1000);
  const day = convertedDate.getDay();
  // Hours part from the timestamp
  const hours = convertedDate.getHours();
  // Minutes part from the timestamp
  const minutes = "0" + convertedDate.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + convertedDate.getSeconds();

  return {
    day,
    hours: hours,
    minutes: minutes.substr(-2),
    seconds: seconds.substr(-2),
  }
};

export const detectBrowser = (userAgent) => {
  if (!userAgent) return;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "Chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "Firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "Safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "Opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "Edge";
  } else {
    browserName = "No browser detection";
  }
  return browserName;
};


export const detectOS = () => {
  var OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

  return OSName;
};


export const isGranted = (object) => {
  const userAccesses = useSelector(currentUserAccessesSelector);
  const currentUser = useSelector(currentUserSelector);
  if (!object) return false;

  if (currentUser.license) return true;

  if (object.class_name === 'Project')
    return userAccesses.filter(access => access.type === 'project' && access.project?.hash === object.id).length > 0;

  if (object.class_name === 'Group')
    return userAccesses.filter(access => access.type === 'group' && access.group?.hash === object.id || access.project?.hash === object.project.id).length > 0;

  return false
}


export const calculateAverageFromAllItemsData = (data, key) => {
  let total = 0;
  data.forEach((item) => {
    total += item[key];
  });
  return isNaN(total / data.length) ? 0 : total / data.length;
};

export const calculateAverageFromAllItemsDataWithFormat = (data, key, currency) => {
  let total = 0;
  data.forEach((item) => {
    total += item[key];
  });
  return formatPrice(Math.round(isNaN(total / data.length / 100) ? 0 : total / data.length / 100), currency);
};

export const calculateAverageFromAllItemsDataWithFormatTime = (data, key) => {
  let total = 0;
  data.forEach((item) => {
    total += item[key];
  });
  let average = total / data.length;
  let minutes = Math.floor(isNaN(average) ? 0 : average / 60);
  let seconds = Math.floor(isNaN(average) ? 0 : average % 60);
  return `${minutes}m ${seconds}s`;
};


export const replaceBadWordsFromString = (string) => {
  // this method will replace a forbidden word by a **** string with the same length
  const englishBadWords = ["fuck", "bitch", "bollocks", "turd", "crap", "brotherfucker", "arsehole", "wanker", "bastard", "spastic", "dickhead", "bambuser", 'caast'];
  const frenchBadWords = ["pute", "putain", "putin", "salope", "connasse", "connard", "bite", "merde", "enculer", "enculé", "casse-couilles"];
  const stoplist = [...englishBadWords, ...frenchBadWords];

  const stopListRegex = new RegExp(`\\b(${stoplist.join("|")})\\b`, "gi");

  return string.replace(stopListRegex, (match) => "*".repeat(match.length));

};
