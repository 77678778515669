import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { resourceCreate } from "../../ressources/actions";
import { TRACK_EVENT } from "../actionsTypes";
import ResourceFactory from "orm/resources";
import { getKeyword } from "../selectors";

export function* trackEventCall({ eventName, data }) {
  const state = yield select();
  const keyword = yield select(getKeyword);
  const trackEvent = new ResourceFactory(state).create("TrackEvent", {
    event_name: eventName,
    data
  });

  yield put(resourceCreate(trackEvent, {
    slug: ["event", keyword, "track"],
    silent: true,
  }))
}

export default function* trackEventSaga() {
  yield all([
    yield takeEvery(TRACK_EVENT, trackEventCall),
  ]);
}
