import React from "react";
import PropTypes from "prop-types";

import { _t } from "core/i18n";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useSurveyManager } from "adminComponents/Animation/hooks";
import { IconAction } from "adminComponents/Mui";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.box,
  },
  title: {
    textAlign: "center",
    fontSize: "20px",
    paddingBottom: theme.spacing(2),
  },
  item: {
    borderTop: `1px solid ${theme.palette.border.default}`,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1.25, 2, 1.25, 1.25),
  },
  label: {
    fontSize: "14px",
  },
  actions: {
    marginLeft: "auto",
    display: "flex",
  },
}));

const ChoicesQuestion = ({ survey, liveElement, handleOpenDetails }) => {
  const classes = useStyles();
  const handleSurveyState = useSurveyManager(survey);
  const items = survey.items.toModelArray();

  const isLive = (item) => {
    if (!liveElement) return false;

    return liveElement.data?.hash === item.hash;
  };

  return (
    <Box className="ProjectionDisplayOptions">
      <Typography className={classes.title}>
        {_t("Display of questions")}
      </Typography>
      {items.map((item, key) => {
        return (
          <Box key={key} className={classes.item}>
            <Typography className={classes.label}>{item.label}</Typography>
            <Box className={classes.actions}>
              {isLive(item) ? (
                <IconAction
                  action="stop"
                  onClick={() => handleSurveyState("hide", item)}
                />
              ) : (
                <IconAction
                  action="play"
                  onClick={() => handleSurveyState("display", item)}
                />
              )}
              {!["rating", "grid"].includes(item.type) && (
                <IconAction
                  action="settings"
                  onClick={() => handleOpenDetails(key)}
                />
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

ChoicesQuestion.propTypes = {
  survey: PropTypes.object,
  liveElement: PropTypes.object,
  handleOpenDetails: PropTypes.func,
};

export default ChoicesQuestion;
