import React from "react";
import AvatarComponent from "../../components/Profil/Avatar";

import { useUser } from "../../userHooks";

const Avatar = () => {
  const user = useUser();

  return <AvatarComponent user={user} />;
};

export default Avatar;
