/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import MembersAvatar from "./MembersAvatar";
import EditDeleteOptions from "./EditDeleteOptions";
import MembersCTA from "./Members/MembersCTA";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { Calendar } from "react-bootstrap-icons";
import { getUrl } from "app/views/containers/Application/Routes";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import ResourceFactory from "app/state/orm/resources/resourceFactory";
import { resourceCreate } from "app/state/ducks/ressources/actions";
import { _t } from "utilities/i18n";
import { isGranted } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
    padding: "15px",
    height: "auto",
    width: "400px",
  },
  top: { display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "40px" },
  bottom: { display: "flex", justifyContent: "space-between", alignItems: "center" },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
  },
}));

const Group = ({ title, hash, urls, calendar }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const project = useSelector(currentProjectSelector);
  const user = useSelector(currentUserSelector);
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const hasRights = isGranted(project);

  const onCreate = (hash) => {
    const calendar = resourceFactory.create("Calendar", {});
    dispatch(resourceCreate(calendar, {
      slug: ["groups", hash, "calendar"],
    }));
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.top}>
        <Typography className={classes.title}>{title}</Typography>

        {hasRights && <EditDeleteOptions hashGroup={hash} title={title} urls={urls} />}
      </Box>
      <Box className={classes.bottom}>
        <Box style={{ display: "flex", gap: "10px" }}>
          <MembersCTA hashGroup={hash} hasRights={hasRights} />

          {calendar && <Button link url={getUrl("oto_appointment", { hash: project.hash, group: hash, tab: "availability" })}><Calendar /></Button>}
          {!calendar && <Button onClick={() => onCreate(hash)}><Calendar />&nbsp;{_t("Create")}</Button>}

        </Box>
        <MembersAvatar hashGroup={hash} />
      </Box>
    </Box>
  );
};

Group.propTypes = {};

Group.defaultProps = {};

export default Group;
