import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { SubmitButton } from "appComponents/SurveyForms";
import Typography from "@material-ui/core/Typography";
import LoadingWrapper from "appContainers/Loading/LoadingWrapper";
import {
  useSurveyDisplaySubmitState,
  useSurveyNotifyCanNotSeeAnswerState,
  useSurveyIsEmptyAnswersState,
  useSurveyPanelState,
  useIsAnswerableFormState,
} from "appComponents/SurveyForms";
import VisibilityState from "appComponents/UserVisibility/VisibilityState";
import { _t } from "i18n";

const useStyles = makeStyles((theme) => ({
  // actionBackground: {
  //   backgroundColor: theme.palette.secondary.main,
  // },
  // buttonLabel: {
  //   fontSize: "16px",
  //   fontWeight: "bold",
  //   color: theme.palette.secondary.contrastText,
  // },
  submitSection: {
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      right: 15,
      bottom: 15,
    },
  },
  respondActionSection: {
    //padding: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      width: "100%",
      padding: theme.spacing(1.5, 0, 0.5, 0),
      height: theme.spacing(9.75),
    },
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
    [theme.breakpoints.down("xs")]: {
      width: "auto",
      backgroundColor: "transparent",
      position: "absolute",
      right: 15,
      bottom: 15,
    },
  },
  helper: {
    color: theme.palette.text.lightContrast,
    marginTop: " 10px",
    fontSize: "10px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

const SurveySubmitSection = ({ customClass, surveyHash, isThumbnailView }) => {
  const classes = useStyles({ isThumbnailView });
  const displaySubmit = useSurveyDisplaySubmitState(surveyHash);
  const notifyCanNotSeeAnswer = useSurveyNotifyCanNotSeeAnswerState(surveyHash);
  const isEmptyAnswers = useSurveyIsEmptyAnswersState(surveyHash);
  const panel = useSurveyPanelState(surveyHash);
  const isAnswerableForm = useIsAnswerableFormState(surveyHash);
  const disableButton = !displaySubmit;
  const helper = displaySubmit ? (
    <VisibilityState />
  ) : !isEmptyAnswers ? (
    _t("Your answer has been submitted.")
  ) : (
    ""
  );

  const XHRId = `answerSurvey_${surveyHash}`;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={clsx(classes.respondActionSection, customClass)}
    >
      <LoadingWrapper XHRId={XHRId} waitForSurveyAnswer={surveyHash}>
        <SubmitButton
          title={_t("Submit")}
          disableButton={disableButton}
          panel={panel}
          isAnswerableForm={isAnswerableForm}
          notifyCanNotSeeAnswer={notifyCanNotSeeAnswer}
        />
      </LoadingWrapper>
      {helper && (
        <Typography className={classes.helper} variant="caption">
          {helper}
        </Typography>
      )}
    </Box>
  );
};
SurveySubmitSection.propTypes = {
  customClass: PropTypes.string,
  isThumbnailView: PropTypes.bool,
  surveyHash: PropTypes.string,
};
SurveySubmitSection.defaultProps = {
  customClass: "",
  disableButton: false,
  surveyHash: "",
};
export default SurveySubmitSection;
