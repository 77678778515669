import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Button from "coreUiKit/inputs/Button";
import Icon from "coreUiKit/inputs/Icon";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import EyeSlash from "react-bootstrap-icons/dist/icons/eye-slash";
import PinAngleFill from "react-bootstrap-icons/dist/icons/pin-angle-fill";
import MegaphoneFill from "react-bootstrap-icons/dist/icons/megaphone-fill";
import Dash from "react-bootstrap-icons/dist/icons/dash";
import ChatBubbleLight from "./ChatBubbleLight";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "15px",
    width: "325px",
    background: theme.palette.colors.white,
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    margin: "0 10px",
    position: "absolute",
    left: "0px",
    background: "#FFF",
  },
  pin: {
    margin: "10px 0 0 5px",
    height: "32px",
    width: "32px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 3px 15px rgba(0, 0, 0, 0.15)",
    background: theme.palette.colors.white,
    cursor: "pointer",
  },
  boxIcon: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  triangle: {
    width: 0,
    height: 0,
    margin: "5px 0 0 10px",
    borderLeft: "5px solid transparent",
    borderRight: " 5px solid transparent",
    borderBottom: `5px solid ${theme.palette.colors.white}`,
    zIndex: "5",
  },
  head: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  headIcon: {
    color: theme.palette.colors.sun,
    marginRight: "10px",
    display: "flex",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  dash: {
    cursor: "pointer",
    color: theme.palette.colors.blackMedium,
  },
  bubble: {
    paddingTop: "5px",
    width: "100%",
  },
  linkTop: {
    marginTop: "15px",
  },
}));

const PinnedMessage = ({ quote, type, isOpen, handleOpenBox, remove, isAdmin }) => {
  const classes = useStyles();

  const getIcon = (type) => {
    if (type === "highlight") return MegaphoneFill;
    else return PinAngleFill;
  };

  return (
    <Box>
      <Box className={classes.boxIcon}>
        <Box className={classes.pin} onClick={() => handleOpenBox(!isOpen)}>
          <Icon IconComponent={getIcon(type)} hover={"sun"} size={16} color={"sun"} />
        </Box>
        {isOpen && <Box className={classes.triangle} />}
      </Box>
      {isOpen && (
        <Box className={classes.tooltip}>
          <Box className={classes.head}>
            <Box className={classes.title}>
              <Box className={classes.headIcon}>{React.createElement(getIcon(type))}</Box>
              <Typography variant="bodySB13">{type === "highlight" ? _t("Highlighted message") : _t("Pinned message")}</Typography>
            </Box>
            <Box className={classes.dash} onClick={() => handleOpenBox(false)}>
              <Dash size={20} />
            </Box>
          </Box>
          <Box className={classes.bubble}>
            <ChatBubbleLight element={quote} pin={true} />
          </Box>

          {isAdmin && (
            <Button className={classes.linkTop} color="greyDark" onClick={() => remove()} variant="link" startIcon={<EyeSlash />}>
              {_t("Hide")}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

PinnedMessage.propTypes = {
  quote: PropTypes.object,
  type: PropTypes.string,
  handleOpenBox: PropTypes.func,
  isOpen: PropTypes.bool,
  remove: PropTypes.func,
  isAdmin: PropTypes.bool,
};
PinnedMessage.defaultProps = {
  quote: {},
  type: "pin",
  handleOpenBox: () => { },
  isOpen: false,
  remove: () => { },
  isAdmin: false,
};

export default PinnedMessage;
