import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import isEqual from "lodash/isEqual";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { SurveyItemTitle } from "appComponents/Viewers";
import {
  getFormElement,
  useSurveyContextState,
} from "appComponents/SurveyForms";

const useStyles = makeStyles((theme) => ({
  formFieldRow: {
    //height: "100%",
    //actionPanel height theme.spacing(4.75)
    maxHeight: `calc(100% - ${theme.spacing(17.5)}px ) `,
    //maxHeight: `calc(100% - ${theme.spacing(20.5)}px)`,
    height: "100%",
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
  },
  paginationScreen: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
  },
  hidden: {
    display: "none",
  },
}));

const PaginatedFormResponseViewer = ({
  formSchema,
  survey,
  isThumbnailView,
  layoutMode,
  showSwitch,
}) => {
  const classes = useStyles();
  //index, indexMap, length
  const surveyContext = useSurveyContextState();
  const indexMap = surveyContext.indexMap;
  const currentFieldHash = indexMap && indexMap[surveyContext.index].hash;
  //const formSchemaKeys = Object.keys(formSchema)
  const result = Object.keys(formSchema).map((key) => (
    <Box
      key={key}
      className={clsx(
        classes.paginationScreen,
        !isEqual(key, currentFieldHash) ? classes.hidden : null
      )}
    >
      <SurveyItemTitle
        survey={survey}
        item={formSchema[key]}
        isThumbnailView={isThumbnailView}
        layoutMode={layoutMode}
        forResult={false}
      />
      <Box className={classes.formFieldRow} mt={3}>
        {getFormElement(
          survey.live_results,
          formSchema[key],
          survey.multiple_answers || (!survey.multiple_answers && !showSwitch)
        )}
      </Box>
    </Box>
  ));

  return result;
};
PaginatedFormResponseViewer.propTypes = {
  survey: PropTypes.object,
  formSchema: PropTypes.object,
  layoutMode: PropTypes.string,
  isThumbnailView: PropTypes.bool,
  showSwitch: PropTypes.bool,
};
export default PaginatedFormResponseViewer;
