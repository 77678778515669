import basil from "utilities/basil";
import {
  SET_VIEWED_PRODUCT,
  SET_PLAYER_VOLUME,
  SET_OPEN_CATALOG,
  SET_CLOSE_CATALOG,
  SET_FULL_SCREEN,
  SET_LAYOUT_TYPE,
  SET_OPEN_CART,
  SET_PLAYER_LOADED,
  CHECK_IS_IFRAME,
  SET_PLAY,
  SET_OPEN_REPLAY,
  SET_REPLAY_DURATION,
  SET_CURRENT_CHAPTER,
  SET_REPLAY_OVERLAY,
  CREATE_CART,
  UPDATE_CART,
  SET_REPLAY_DIALOG,
  CHECK_IS_PRELIVE,
  DISPLAY_TABBAR,
  BACK_OFFICE_ELEMENT,
  SET_LIVE_SHOPPING_BOOTSTRAP,
  SET_CURRENT_VISITOR,
  IS_READY,
  IS_RETRACTED,
  OPEN_NPS,
  SET_SCREEN_ORIENTATION,
  ACTIVATE_NOTIFICATIONS,
  SET_CURRENT_CALENDAR,
  SET_CURRENT_LIVE_VENDORS,
  OPEN_FISHING_ROD,
} from "./actionsTypes";

const initialState = {
  viewedProduct: null,
  playerVolume: 1,
  catalogIsOpen: false,
  fullScreen: false,
  cartIsOpen: false,
  replayIsOpen: false,
  layoutType: "",
  playerIsLoaded: false,
  isPlayed: false,
  replayDuration: null,
  currentChapter: "",
  replayOverlay: false,
  replayDialog: false,
  isIFrame: false,
  isPrelive: false,
  liveShoppingBootstrap: {},
  cart: {
    hash: "",
    vendor_id: "",
    total: 0,
    created_at: "",
    proceeded_to_checkout_at: "",
    products: [],
  },
  displayTabBar: false,
  displayModalAlert: false,
  elements: null,
  currentVisitor: null,
  currentCalendar: null,
  currentLiveVendors: null,
  isReady: false,
  isRetracted: basil.get("isRetracted") || false,
  nps: false,
  screenOrientation: "1x2_admin",
  notifications: false,
  fishingRod: false,
};
const liveShoppingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.setLayoutType,
      };
    case SET_PLAYER_LOADED:
      return { ...state, playerIsLoaded: action.setPlayerLoaded };
    case SET_PLAY:
      return { ...state, isPlayed: action.setPlay };
    case SET_PLAYER_VOLUME:
      return { ...state, playerVolume: action.setPlayerVolume };
    case SET_VIEWED_PRODUCT:
      return { ...state, viewedProduct: action.setViewedProduct };
    case SET_OPEN_CATALOG:
      return { ...state, catalogIsOpen: action.setOpenCatalog };
    case SET_CLOSE_CATALOG:
      return { ...state, catalogIsOpen: action.setCloseCatalog };
    case SET_FULL_SCREEN:
      return { ...state, fullScreen: action.setFullScreen };
    case SET_OPEN_CART:
      return { ...state, cartIsOpen: action.setOpenCart };
    case SET_OPEN_REPLAY:
      return { ...state, replayIsOpen: action.setOpenReplay };
    case SET_REPLAY_DURATION:
      return { ...state, replayDuration: action.setReplayDuration };
    case SET_REPLAY_OVERLAY:
      return { ...state, replayOverlay: action.setReplayOverlay };
    case SET_CURRENT_CHAPTER:
      return { ...state, currentChapter: action.setCurrentChapter };
    case SET_REPLAY_DIALOG:
      return { ...state, replayDialog: action.setReplayDialog };
    case CHECK_IS_IFRAME:
      return { ...state, isIFrame: action.setIsIFrame };
    //CREATE CART
    case CREATE_CART:
      return { ...state, cart: action.createCart };
    //UPDATE CART
    case UPDATE_CART:
      return { ...state, cart: action.updateCart };
    case CHECK_IS_PRELIVE:
      return { ...state, isPrelive: action.setIsPrelive };
    case DISPLAY_TABBAR:
      return { ...state, displayTabBar: action.displayTabBar };
    case BACK_OFFICE_ELEMENT:
      return { ...state, elements: action.displayBackOfficeElement };
    case SET_LIVE_SHOPPING_BOOTSTRAP:
      return { ...state, liveShoppingBootstrap: action.setLiveShoppingBootstrap };
    case SET_CURRENT_VISITOR:
      return { ...state, currentVisitor: action.setCurrentVisitor };
    case IS_READY:
      return { ...state, isReady: action.isReady };
    case IS_RETRACTED:
      return { ...state, isRetracted: action.isRetracted };
    case OPEN_NPS:
      return { ...state, nps: action.openNps };
    case SET_SCREEN_ORIENTATION:
      return { ...state, screenOrientation: action.setScreenOrientation };
    case ACTIVATE_NOTIFICATIONS:
      return { ...state, notifications: action.activateNotifications };
    case SET_CURRENT_CALENDAR:
      return { ...state, currentCalendar: action.setCurrentCalendar };
    case SET_CURRENT_LIVE_VENDORS:
      return { ...state, currentLiveVendors: action.currentLiveVendors };
    case OPEN_FISHING_ROD:
      return { ...state, fishingRod: action.openFishingRod };
  }
  return state;
};

export default liveShoppingReducer;
