import { call, fork, put, select, take } from "redux-saga/effects";
import { sessionSelector, publishersSelector } from "../selectors";
import { CANNOT_PUBLISH } from "../errors";
import { setStreamId, setStream, setError } from "../actions";
import { createAudioChannel, audioLevelUpdated } from "./bindToDom";

const publish = (session, publisher) =>
  new Promise((resolve) => {
    session.publish(publisher, (error) => {
      if (error)
        return resolve({
          error: { code: CANNOT_PUBLISH, message: error.message },
        });

      resolve({ stream: publisher.stream });
    });
  });

// Publish own stream (audio/video or screen sharing)
// retrieve selected sources before entering, mute status and publish to session
export function* publishStream({ mode }) {
  console.log("[VISIO SAGA] PUBLISH STREAM")
  if (!window.OT) {
    console.error("No OT lib found");
    return;
  }

  const session = yield select(sessionSelector);
  const { videoPublisher } = yield select(publishersSelector);

  const { stream, error } = yield publish(session, videoPublisher);

  if (error) {
    yield put(setError(error));
    return;
  }

  yield put(setStreamId(mode, stream.id));
  yield put(setStream(stream));

  const channel = yield call(createAudioChannel, videoPublisher);
  while (true) {
    const payload = yield take(channel);

    try {
      yield fork(audioLevelUpdated, payload);
    } catch (e) {
      console.log("unable to handle payload for audioLevelUpdate", JSON.parse(payload));
    }
  }
}
