import makeRequest from "core/makeRequest";
import { API_BASE } from "settings/config";

import { CANNOT_GET_CREDENTIALS } from "../errors";

import { call, put, select } from "redux-saga/effects";

import { setError, setCredentials } from "../actions";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";

export function* getCredentials() {

  console.log("[VISIO SAGA] GET CREDENTIALS")

  const { hash, session_id } = yield select(currentVisitorSelector);

  const url = API_BASE.clone()
    .segment("visitors")
    .segment(hash)
    .segment("visio")
    .segment("credentials")
    .toString();

  try {

    const response = yield call(
      makeRequest,
      url,
      { type: "VonageCredentials" },
      { method: "GET" }
    );


    const credentials = response.resources[0].attributes.data;

    console.log(credentials)
    if (session_id) {
      yield put(setCredentials({ key: credentials.key, token: credentials.token, session: session_id }));
    }
  } catch (error) {
    yield put(setError(CANNOT_GET_CREDENTIALS, error));
  }
}
