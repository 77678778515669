import BaseModel from "./base";
import { attr, oneToOne } from "redux-orm";

class Report extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Report;
  }

  setEvent(Event) {
    this.event = Event.id;
  }
}

Report.modelName = "Report";
Report.backendName = "report";

Report.fields = {
  id: attr(),
  enabled: attr(),
  public: attr(),
  summary: attr(),
  poll: attr(),
  form: attr(),
  event: oneToOne("Event", "report"),
};

export default Report;
