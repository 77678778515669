import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import clsx from "clsx";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import size from "lodash/size";
import { getFetchResultStatusSelector } from "applicationDucks/selectors";
import { useUserAnswerToSurveyWatcher } from "appComponents/Timeline/hooks";
import emitter from "core/emitter";
import {
  EVENT_FLASH_SUCCESS_AFTER_GET_ANSWER,
  SURVEY_EVENT_FLASH_ERROR,
} from "core/emitter/events";
import { _t } from "i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    height: "auto",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    width: "100%",
    pointerEvents: (props) => (props.isLoading ? "none" : "auto"),
  },

  loaderRoot: {
    display: (props) => (props.isLoading ? "flex" : "none"),
    position: "absolute",
    top: "calc(50% - 20px)",
    left: "calc(50% - 20px)",
  },
  colorPrimary: {
    color: theme.wisemblyColors.orange,
  },
}));
//custom hook that says if user is waiting for answer after submit
//it also has listener on EVENT_FLASH_SUCCESS_AFTER_GET_ANSWER event
export const useCompleteSurveySubmit = ({
  fetchDataStatus,
  waitForSurveyAnswer,
}) => {
  const postAnswer = useRef(false);
  const callbackRef = useRef(() => {});
  const [watchForChange, setWatchForChange] = useState(false);
  const userAnswers = useUserAnswerToSurveyWatcher({
    surveyHash: waitForSurveyAnswer,
    stop: postAnswer.current === false,
  });
  const [answerCount, setAnswerCount] = useState(size(userAnswers));

  //when start posting something
  if (fetchDataStatus === "pending" && !postAnswer.current) {
    postAnswer.current = true;
    //save answers count and watch for changes in count
    setWatchForChange(true);
    setAnswerCount(size(userAnswers));
  }
  //after submit and get new answer
  if (
    watchForChange &&
    size(userAnswers) !== answerCount &&
    postAnswer.current === true &&
    fetchDataStatus === "success"
  ) {
    //some new answer has been fetch
    setWatchForChange(false);
    postAnswer.current = false;
    setAnswerCount(size(userAnswers));
    callbackRef.current();
  }
  //error while submiting
  if (fetchDataStatus === "error" && postAnswer.current) {
    postAnswer.current = false;
  }
  const shouldBeLoading =
    fetchDataStatus === "pending" ||
    (postAnswer.current === true && !isEmpty(waitForSurveyAnswer));

  //useEffect execute callback when finished loading
  useEffect(() => {
    const myCallback = (callback) => {
      callbackRef.current = callback;
    };
    emitter.on(EVENT_FLASH_SUCCESS_AFTER_GET_ANSWER, (callback) =>
      myCallback(callback)
    );
    //i used removeAllListeners here because im not using this event anywhere else
    //if that change in the future pay attention to change it to removeListener
    return () => {
      emitter.removeAllListeners([EVENT_FLASH_SUCCESS_AFTER_GET_ANSWER]);
    };
  }, []);
  useEffect(() => {
    if (shouldBeLoading) {
      const timeout = setTimeout(() => {
        if (shouldBeLoading) {
          //// TODO: maybe dispatch an event to inform user that i was unable to get his answer
          postAnswer.current = false;
          emitter.emit(SURVEY_EVENT_FLASH_ERROR, {
            surveyHash: waitForSurveyAnswer,
            message: _t("Unable to process your answer"),
          });
        }
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [shouldBeLoading, waitForSurveyAnswer]);
  return !shouldBeLoading;
};

const LoadingWrapper = ({ fetchDataStatus, waitForSurveyAnswer, children }) => {
  const postAnswer = useRef(false);

  const [watchForChange, setWatchForChange] = useState(false);
  const userAnswers = useUserAnswerToSurveyWatcher({
    surveyHash: waitForSurveyAnswer,
    stop: postAnswer.current === false,
  });
  const [answerCount, setAnswerCount] = useState(size(userAnswers));

  //when start posting something
  if (fetchDataStatus === "pending" && !postAnswer.current) {
    postAnswer.current = true;
    //save answers count and watch for changes in count
    setWatchForChange(true);
    setAnswerCount(size(userAnswers));
  }
  //after submit and get new answer
  if (
    watchForChange &&
    size(userAnswers) !== answerCount &&
    postAnswer.current === true &&
    fetchDataStatus === "success"
  ) {
    //some new answer has been fetch
    setWatchForChange(false);
    postAnswer.current = false;
    setAnswerCount(size(userAnswers));
  }
  //error while submiting
  if (fetchDataStatus === "error" && postAnswer.current) {
    postAnswer.current = false;
  }

  const shouldBeLoading =
    fetchDataStatus === "pending" ||
    (postAnswer.current === true && !isEmpty(waitForSurveyAnswer));
  //10s timeout on waiting
  useEffect(() => {
    if (shouldBeLoading) {
      const timeout = setTimeout(() => {
        if (shouldBeLoading) {
          // dispatch an event to inform user that i was unable to get his answer
          emitter.emit(SURVEY_EVENT_FLASH_ERROR, {
            surveyHash: waitForSurveyAnswer,
            message: _t("Unable to process your answer"),
          });
          postAnswer.current = false;
        }
      }, 10000);
      return () => clearTimeout(timeout);
    }
  }, [shouldBeLoading, waitForSurveyAnswer]);

  const classes = useStyles({ isLoading: shouldBeLoading });

  return (
    <Box className={clsx("LoadingWrapper", classes.root)}>
      {children}
      <CircularProgress
        classes={{
          root: classes.loaderRoot,
          colorPrimary: classes.colorPrimary,
        }}
      />
    </Box>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    fetchDataStatus: getFetchResultStatusSelector(state, ownProps.XHRId),
  };
};

LoadingWrapper.propTypes = {
  children: PropTypes.object,
  fetchDataStatus: PropTypes.string,
  waitForSurveyAnswer: PropTypes.string,
};
LoadingWrapper.defaultProps = {
  isLoading: false,
  waitForSurveyAnswer: "",
};
export default connect(mapStateToProps)(LoadingWrapper);
