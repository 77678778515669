import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Bag from "react-bootstrap-icons/dist/icons/bag";
import BoxArrowUp from "react-bootstrap-icons/dist/icons/box-arrow-up";
import BarChart from "react-bootstrap-icons/dist/icons/bar-chart";
import Gear from "react-bootstrap-icons/dist/icons/gear";
import { ButtonBase, useTheme } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { productsSelector } from "app/state/ducks/ressources/selectors";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { getKeyword } from "app/state/ducks/application/selectors";
import { displayBackOfficeElement } from "app/state/ducks/liveShopping/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.03)",
    height: "100%",
  },
  item: {
    height: "50%",
    width: "50%",
    border: "1px solid #F0F0F4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
  },
  count: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: theme.palette.colors.primary,
    border: "2px solid #FFFFFF",
    borderRadius: "999px",
    position: "absolute",
    left: "25.5px",
    top: "0.5px",
    width: "23px",
    height: "18px",
  },
  typoCount: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "100%",
    color: "white",
    textAlign: "center",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey600,
    textAlign: "center",
    margin: "8px 0px",
    marginTop: "8px",
  },
}));

const MobileOptions = ({ }) => {
  const classes = useStyles();
  const products = useSelector(productsSelector);
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    dispatch(
      resourceFetch("products", {
        slug: ["event", keyword, "products"],
        once: true,
        callback: (error, response) => {
          if (error) {
            return;
          }
        },
      })
    );
  }, []);
  return (
    <Box className={classes.root}>
      <ButtonBase className={classes.item} onClick={() => dispatch(displayBackOfficeElement("catalog"))}>
        <Box style={{ position: "relative", width: "50px", display: "flex", justifyContent: "center" }}>
          <Bag color={theme.palette.colors.grey500} size={28} />
          <Box className={classes.count}>
            <Typography className={classes.typoCount}>{products.length}</Typography>
          </Box>
        </Box>
        <Typography className={classes.title}>{_t("Products Catalog")}</Typography>
      </ButtonBase>
      <ButtonBase className={classes.item}>
        <BoxArrowUp color={theme.palette.colors.grey500} size={28} />
        <Typography className={classes.title}>{_t("Share")}</Typography>
      </ButtonBase>
      <ButtonBase className={classes.item} onClick={() => dispatch(displayBackOfficeElement("stats"))}>
        <BarChart color={theme.palette.colors.grey500} size={28} />
        <Typography className={classes.title}>{_t("Statistics")}</Typography>
      </ButtonBase>
      <ButtonBase className={classes.item}>
        <Gear color={theme.palette.colors.grey500} size={28} />
        <Typography className={classes.title}>{_t("Settings")}</Typography>
      </ButtonBase>
    </Box>
  );
};

MobileOptions.propTypes = {};

MobileOptions.defaultProps = {};

export default MobileOptions;
