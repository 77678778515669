import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import IntroCTA from "./IntroCTA";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      //padding: "0px 0px 5px",
      flex: 1,
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
  }),
  {
    name: "OneMessages",
  }
);

const Messages = ({ count, children }) => {
  const classes = useStyles();

  const introCTAMessages = [_t("« Hello 👋 »"), _t("« Hello, how are you ? »"), _t("« Happy to join you ! »")];
  return (
    <Box className={classes.root}>
      {count === 0 ? (
        <IntroCTA messagesBubbles={introCTAMessages} text={_t("Don't hesitate, send the first message")} />
      ) : (
        <Box
          style={{
            flex: 1,
            boxSizing: "border-box",
            width: "100%",
            height: "100%",
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
};

Messages.propTypes = {
  count: PropTypes.number,
  children: PropTypes.node,
};

Messages.defaultProps = {
  count: 0,
};

export default Messages;
