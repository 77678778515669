import BaseModel from "./base";
import { attr, oneToOne } from "redux-orm";
import isEqual from "lodash/isEqual"

class Replay extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Replay;
  }

  setEvent(Event) {
    this.event = Event.id;
  }

  update(attributes) {
    // ensure replay.chapters is an array
    if (isEqual(attributes?.chapters, {}) || isEqual(this.chapters, {}))
      attributes.chapters = [];

    super.update(attributes);
  }
}

Replay.modelName = "Replay";
Replay.backendName = "replay";

Replay.fields = {
  id: attr(),
  enabled: attr(),
  starts_at: attr(),
  replay_url: attr(),
  chapters: attr(),
  options: attr(),
  event: oneToOne("Event", "replay"),
};

export default Replay;
