import React, { useEffect } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { ActionButton } from "appComponents/Mui";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import { _t, _n } from "core/i18n";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { resourceCreate } from "ressourcesDucks/actions";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: "#FFF",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "40px",
    marginBottom: "40px",
    alignItems: "center",
    //overflowX: "auto",
  },
  description: {
    fontSize: "20px",
    lineHeight: "24.38px",
    letterSpacing: "0.78px",
    fontWeight: 500,
  },
  action: {},
}));
const Header = ({ defaultTemplate, keyword, setNewEvent }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const createTemplate = () => {
    const query = [];
    if (defaultTemplate) {
      dispatch(
        resourceCreate(defaultTemplate, {
          slug: ["event", keyword, "mailer-templates"],
          query,
          XHR_ID: `create_event_templates_${defaultTemplate.template_key}`,
          silent: true,
          callback: (error, response) => {
            setNewEvent(response?.resources[0]?.id);
            if (error) {
              return;
            }
          },
        })
      );
    }
  };
  //fetch templates
  useEffect(() => { }, []);

  return (
    <Box className={clsx("header", classes.root)}>
      <Box className={clsx("header", classes.description)}>
        {_t("Communicate with participants")}
      </Box>
      <Box className={clsx("action", classes.action)}>
        <Button onClick={() => createTemplate()}>
          <AddOutlinedIcon />
          <Typography>{_t("create mail")}</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Header;
