import { takeEvery } from "redux-saga/effects";
import { call } from "redux-saga/effects";

import segment from "core/segment";

import { APPLICATION_JSON_CALL } from "applicationDucks/actionsTypes";

import notify from "ressourcesDucks/sagas/notify";
import dispatch from "ressourcesDucks/sagas/dispatch";
import request from "ressourcesDucks/sagas/request";

function* makeCall({ url, body = {}, options = {} }) {
  options = {
    raw: true, // do not try to parse response in makeRequest cuz' not jsonApi
    transform: false,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    ...options,
  };

  const { analytics } = options;

  if (analytics && analytics.event) {
    segment.track(analytics.event, analytics.context || {});
  }

  const response = yield call(request, url, body, options);

  if (response === false) {
    return;
  }

  // if data is now raw and have been transformed, dispatch it to the store
  if (!options.raw) {
    yield call(dispatch, response);
  }

  yield call(notify, options, response);
}

export default function* applicationJSONSaga() {
  yield takeEvery(APPLICATION_JSON_CALL, makeCall);
}
