import BaseModel from "./base";
import { attr, fk } from "redux-orm";
import get from "lodash/get";
import dayjs from "dayjs";

class Event extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Event;
  }

  hasPopUpElement() {
    if (
      (this.live_media && this.live_media.enabled) ||
      (this.live && (this.live.visio !== null || (this.live.popup !== null && this.live.popup.hash !== null) || (this.live.leaderboard && this.live.leaderboard.displayed)))
    ) {
      return true;
    }
  }

  getVisio(key, placeholder) {
    return get(this.extra_settings, `visio.${key}`, placeholder);
  }

  get backgroundImageUri() {
    return this.theme.background_image;
  }

  setOwner(User) {
    this.owner = User.id;
  }

  isOwner(user) {
    if (!this.owner) return false;
    return user && user.id === this.owner.id;
  }

  isLiveEventEnabled() {
    return this.live_media.provider === "wisembly_webinar";
  }

  getDate() {
    const event_start = dayjs(this.start);
    const event_stop = dayjs(this.stop);
    const live_start = dayjs(this.live_start);
    const live_stop = dayjs(this.live_stop);

    if (!event_start.isValid() || !event_stop.isValid()) {
      return null;
    }

    return {
      start: this.getStartLiveDateWithFallback(),
      stop: this.getStopLiveDateWithFallback(),
    };
  }

  getState() {
    const event_start = dayjs(this.start);
    const event_stop = dayjs(this.stop);
    const live_start = dayjs(this.live_start);
    const live_stop = dayjs(this.live_stop);
    const now = new Date();

    if (!event_start.isValid() || !event_stop.isValid()) {
      return null;
    }

    if (!live_start.isValid() || !live_stop.isValid()) {
      return "WIZ_NO_LIVE";
    }

    if (live_start && live_start.isValid() && live_stop && live_stop.isValid() && live_start.isBefore(now) && live_stop.isAfter(now)) {
      return "WIZ_LIVE";
    }

    if (event_stop.isBefore(new Date())) {
      return "WIZ_COMPLETED";
    }

    if (live_stop && live_stop.isValid() && live_stop.isBefore(now)) {
      return "WIZ_FINISHED";
    }

    if (event_start.isBefore(now)) {
      return "WIZ_STARTED";
    }

    return "WIZ_NOT_STARTED";
  }

  hasLandingEnabled() {
    if (this.landing && this.landing_enabled) return true;
    return false;
  }

  hasRegistrationEnabled() {
    return this.landing_enabled && this.landing?.registration_enabled;
  }

  // needed to avoid error if somehow landing resource does not exist anymore in backend
  // not very importnt since we have a boolean here telling if we should consider landing or not
  unsetLanding() {
    // do nothing
  }

  // same here
  unsetOrganization() {
    // do nothing
  }

  // same here
  unsetReport() {
    // do nothing
  }

  // same here
  unsetReplay() {
    // do nothing
  }

  setOrganization(Organization) {
    this.organization = Organization.id;
  }

  getLogo(defautValue = true) {
    if (this.logo_paths?.mobile) {
      return this.logo_paths.mobile;
    }
    return defautValue ? "https://wisembly-content.s3.amazonaws.com/mails/assets/img/wisembly-logo.png" : null;
  }

  getStartLiveDateWithFallback() {
    return this.landing_enabled && this.live_start ? this.live_start : this.start;
  }

  getStopLiveDateWithFallback() {
    return this.landing_enabled && this.live_stop ? this.live_stop : this.stop;
  }

  hasReplayEnabled() {
    return this.status === "replay";
  }

  hasLiveStarted() {
    const live_start = dayjs(this.live_start);
    const now = new Date();

    return live_start.isBefore(now);
  }

  hasLiveStopped() {
    const live_stop = dayjs(this.live_stop);
    const now = new Date();

    return live_stop.isBefore(now);
  }

  isPublic() {
    return !["draft", "closed"].includes(this.status);
  }

  isLive() {
    return this.status === "live";
  }

  isReplay() {
    return this.status === "replay";
  }

  isAwaitingReplay() {
    return this.status === "awaiting_replay";
  }
}

Event.modelName = "Event";
Event.backendName = "event";

Event.fields = {
  id: attr(),
  keyword: attr(),
  title: attr(),
  start: attr(),
  stop: attr(),
  status: attr(),
  is_comments_open: attr(),
  public_tags_enabled: attr(),
  tags_enabled: attr(),
  media_in_quotes_enabled: attr(),
  post_quotes: attr(),
  contract: fk("Contract", "events"),
  live_media: attr(),
  live: attr(),
  live_start: attr(),
  live_stop: attr(),
  owner: fk("User", "events"),
  organization: fk("Organization", "events"),
  participation_mode: attr(),
  allow_username: attr(),
  force_username: attr(),
  reactions: attr(),
  extra_settings: attr(),
  landing_enabled: attr(),
  is_protected: attr(),
  is_public: attr(),
  oto_integration_id: attr(),
  is_password_protected: attr(),
  is_invitation_protected: attr(),
  is_network_protected: attr(),
  networks: attr(),
  total_participants: attr(),
  collect_connexion_logs: attr(),
  currency: attr(),
  moderate: attr(),
  cart_integration: attr(),
};

export default Event;
