import { eventChannel } from "redux-saga";
import { put, take, call, fork, select } from "redux-saga/effects";

import emitter from "core/emitter";

import { setDevices, setError, getDevices as getDevicesAction, clearError } from "../actions";
import { CANNOT_ACCESS_DEVICES, NEED_PERMISSION_PROMPT } from "../errors";
import { devicesSelector } from "../selectors";

const getUserMedia = (mode) =>
  new Promise((resolve) => {
    const options = {
      audioSource: true,
      videoSource: mode === "video",
    };

    window.OT.getUserMedia(options)
      .catch((error) => {
        console.log("[Vonage] Permission error while trying to access user media", error);
        resolve({ error: error.message });
      })
      .then((stream) => {
        resolve(stream);

          // this is very important to avoid getting the camera indicator always on
          // this code is needed to trigger browser permission popup and detect if user has accepted or not
          // once done, we have to immediatly stop the stream otherwise the camera indicator will stay on, like forever...
          try {
            stream.getTracks().forEach((track) => {
              track.stop();
            });
          } catch (e) {}
      });
  });

export const getUserDevices = () =>
  new Promise((resolve) => {

    window.OT.getDevices((error, devices) => {
      if (error) {
        resolve({ error: error.message });
        return;
      }

      resolve({ devices });
    });
  });

function* grantPermission(mode) {
  const { error } = yield getUserMedia(mode);

  if (error) {
    yield put(setError(CANNOT_ACCESS_DEVICES, error));
    return false;
  }

  return true;
}

export function* checkPermissionGranted({ mode }) {
  console.log("[VISIO SAGA] CHECK PERMISSION GRANTED")

  const { devices } = yield getUserDevices(mode);

  if (devices.find((devices) => devices.deviceId === undefined)) {
    // We found a device that is with undefined deviceId, this is a sign of a permission that is not granted
    yield put(setError(NEED_PERMISSION_PROMPT));
    return;
  }

  // If we have the devices allowed, we can start the session and get the devices
  // yield put(joinSession());
  yield put(getDevicesAction());
}

function* syncDevices() {
  console.log('[VISIO SAGA] SYNC DEVICES')
  const { devices, error } = yield getUserDevices();

  if (error) {
    yield put(setError(CANNOT_ACCESS_DEVICES, error));
    return;
  }

  yield put(setDevices(devices));

  return devices;
}

function createChannel() {
  return eventChannel((emit) => {
    navigator.mediaDevices.addEventListener("devicechange", emit);

    // eventChannel must return an unsubscribe function
    return () => { };
  });
}

function* handleDevicesChange() {
  console.log("[VISIO SAGA] HANDLE DEVICES CHANGE");

  const devices = yield select(devicesSelector);
  const newDevices = yield syncDevices();

  const devicesId = devices.map(({ deviceId }) => deviceId);
  const newDevicesId = newDevices.map(({ deviceId }) => deviceId);

  const added = newDevices.filter(({ deviceId }) => !devicesId.includes(deviceId));
  const removed = devices.filter(({ deviceId }) => !newDevicesId.includes(deviceId));

  emitter.emit("VONAGE_DEVICES_CHANGE", {
    devices: newDevices,
    added,
    removed,
  });

  // TODO
  // When used mic disconnected, fallback on deviceId 'default'
  // When used cam disconnected, unpublish video stream
}

export function* getDevices({ mode }) {
  console.log("[VISIO SAGA] GET DEVICES");

  try {
    const permissionGranted = yield grantPermission(mode);

    if (!permissionGranted) return;

    yield syncDevices();
    yield put(clearError());

    // hook on devices change
    const channel = yield call(createChannel);
    while (true) {
      yield take(channel);

      try {
        yield fork(handleDevicesChange);
      } catch (e) {
        console.log("[Vonage] unable to handle device change event", e);
      }
    }
  } catch (e) {
    yield put(setError(CANNOT_ACCESS_DEVICES, e));
  }
}
