import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase, Box, Typography, Divider, Slide } from "@material-ui/core";

import { useSelector, useDispatch } from "react-redux";
import { setViewedProduct } from "liveShoppingDucks/actions";
import CustomCloseIcon from "app/liveShopping/assets/icons/CustomCloseIcon";
import PropTypes from "prop-types";
import { _t } from "i18n";
import CustomSelect from "app/liveShopping/frontoffice/otm/components/CustomSelect";
import { QuantityInput } from "../QuantityInput";
import { mobileLayoutSelector, desktopLayoutSelector, viewedProductSelector } from "liveShoppingDucks/selectors";
import { addToCart, setOpenCatalog } from "app/state/ducks/liveShopping/actions";
import { currentUserSelector, currentWizSelector } from "app/state/ducks/ressources/selectors";
import Description from "./Description";
import { isUser } from "utilities/access";
import OtoChecker from "app/liveShopping/backoffice/otm/components/Live/Product/OtoChecker";
import { formatPrice, truncate } from "app/liveShopping/utils";
import { trackEvent } from "app/state/ducks/application/actions";

const useStyles = makeStyles((theme) => ({
  dialogShadow: {
    position: (props) => (props.desktopLayout ? "absolute" : "fixed"),
    height: "100%",
    width: "100%",
    top: 0,
    backgroundColor: "rgba(0, 0, 0, .6)",
    zIndex: 3,
    display: (props) => (props.desktopLayout ? "none" : "block"),
  },
  dialog: {
    width: "100%",
    height: (props) => (props.desktopLayout ? "100%" : "auto"),
    backgroundColor: "white",
    position: "absolute",
    bottom: 0,
    zIndex: 4,
    borderRadius: (props) => (props.mobileLayout ? "10px 10px 0px 0px" : "10px 10px 0px 8px"),
    display: "flex",
    flexDirection: "column",
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    marginTop: 20,
    marginLeft: 20,
    marginRight: 20,
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    padding: 25,
    // overflowY: "auto",
    height: "calc(100% - 100px)",
  },
  dialogContentTop: {
    display: "grid",
    gridTemplateColumns: '1fr 2fr',

  },
  dialogContentImage: {
    width: "94px",
    height: "94px",
    backgroundImage: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginRight: "7px",
    borderRadius: "10px",
  },
  dialogContentTitle: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "black",
  },
  dialogContentShortDesc: {
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    letterSpacing: "0em",
    textAlign: "left",
    color: theme.palette.colors.darkGrey,
  },
  dialogContentDesc: {
    marginTop: "20px",
    paddingLeft: "15px",
    paddingRight: "15px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    color: theme.palette.colors.darkGrey,
  },
  dialogContentPrice: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
    color: "black",
    marginRight: "7px",
  },
  dialogContentOldPrice: {
    textDecorationLine: "line-through",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "130%",
    color: theme.palette.colors.grey,
  },
  dialogContentDivider: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  closeIcon: {
    color: "black",
  },
  footer: {
    display: (props) => (props.user.isAnonymous ? "block" : "none"),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: (props) => (props.desktopLayout ? "absolute" : "unset"),
    bottom: (props) => (props.desktopLayout ? "0" : 0),
    padding: 25,
  },
  addToCartButton: {
    width: "auto",
    height: "44px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "none",
    alignItems: "center",
    background: (props) => (props.added ? theme.palette.colors.success : theme.palette.colors.primary),
    borderRadius: "999px",
    color: theme.palette.colors.white,
    fontSize: "14px",
    fontWeight: 700,
    padding: "15px 30px",
    cursor: "pointer",
  },
}));

const ProductDialog = ({ }) => {
  const mobileLayout = useSelector(mobileLayoutSelector);
  const desktopLayout = useSelector(desktopLayoutSelector);
  const viewedProduct = useSelector(viewedProductSelector);
  const user = useSelector(currentUserSelector);
  const dispatch = useDispatch();
  const wiz = useSelector(currentWizSelector);

  const [variant, setVariant] = useState(viewedProduct.variants[0]);
  const [quantity, setQuantity] = useState(1);
  const [added, setAdded] = useState(false);
  const project = wiz.oto_integration_id || null;

  const classes = useStyles({
    backgroundImage: viewedProduct.getPictureUrl(variant?.hash) || "",
    mobileLayout,
    desktopLayout,
    added,
    user,
    project
  });

  useEffect(() => {
    dispatch(trackEvent('viewproduct', viewedProduct))
  }, []);

  const handleQuitDialog = () => dispatch(setViewedProduct(null));

  const handleShop = () => {
    dispatch(
      addToCart({
        product_id: viewedProduct.id,
        variant_id: variant?.hash,
        quantity,
        variant_vendor_id: variant?.vendor_id,
      })
    );
    setAdded(true);
    setTimeout(() => setAdded(false), 1200);

    // go back on live once added, auto-close
    setTimeout(() => {
      dispatch(setViewedProduct(null));

      if (mobileLayout) dispatch(setOpenCatalog(false));
    }, 1500);
  };

  if (!viewedProduct) return null;

  return (
    <>
      <Box className={classes.dialogShadow} onClick={handleQuitDialog} />
      <Slide direction={desktopLayout ? "right" : "up"} in={viewedProduct}>
        <Box className={classes.dialog} onClick={(e) => e.stopPropagation()}>
          {/* Header */}
          <Box className={classes.dialogHeader}>

            <ButtonBase onClick={handleQuitDialog}>
              <CustomCloseIcon />
            </ButtonBase>
          </Box>
          {/* Content */}
          <Box className={classes.dialogContent}>
            {/* Top Content */}
            <Box className={classes.dialogContentTop}>
              <Box className={classes.dialogContentImage} />
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Typography className={classes.dialogContentTitle}>
                  {truncate(viewedProduct.name, 80, true)}
                  {viewedProduct.variants.length > 1 && " | " + variant?.name}
                </Typography>

                {viewedProduct.short && <Typography className={classes.dialogContentShortDesc}>{viewedProduct.short}</Typography>}

                <Box
                  style={{
                    display: !!variant?.price ? "flex" : "none",
                    flexDirection: "row",
                    marginBottom: "5px",
                  }}
                >
                  <Typography className={classes.dialogContentPrice}>
                    {!!variant?.price && formatPrice(variant?.price, "EUR")}
                  </Typography>

                  {variant?.previous_price && (
                    <Typography className={classes.dialogContentOldPrice}>
                      {!!variant?.previous_price && formatPrice(variant?.previous_price, "EUR")}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
            {
              !wiz?.cart_integration || project && <OtoChecker projectId={project} productUrl={viewedProduct.product_url} />
            }
            {/* Description */}
            <Description product={viewedProduct} />
            {viewedProduct.hasVariants() && <Divider className={classes.dialogContentDivider} />}

            {/* Cart Part */}
            {viewedProduct.hasVariants() && (
              <Box>
                <CustomSelect
                  options={viewedProduct.variants}
                  setVariant={(newVariant) => {
                    setVariant(newVariant);
                    setQuantity(1);
                  }}
                />
              </Box>
            )}
          </Box>
          {isUser("user-") && (wiz.isLive() || wiz.isReplay() || wiz.isAwaitingReplay()) && (
            <Box className={classes.footer}>

              {wiz.cart_integration && (
                <>
                  <QuantityInput size={44} defaultValue={quantity} onChange={(newQuantity) => setQuantity(newQuantity)} />
                  <ButtonBase className={classes.addToCartButton} onClick={handleShop}>
                    {added ? _t("Added!") : _t("Add to cart")}
                  </ButtonBase>
                </>
              )}

              {!wiz.cart_integration && variant.product_url && (
                <a
                  href={variant.product_url}
                  onClick={() => dispatch(trackEvent('gotoproduct', viewedProduct))}
                  target="_top _blank"
                  className={classes.addToCartButton}
                  style={{
                    width: "100%",
                    textDecoration: "none",
                  }}
                >
                  {_t("Go to the product page")}
                </a>
              )}

            </Box>
          )}
        </Box>
      </Slide>
    </>
  );
};

ProductDialog.propTypes = {
  backgroundImage: PropTypes.string,
  mobileLayout: PropTypes.bool,
  desktopLayout: PropTypes.bool,
};

ProductDialog.defaultProps = {};

export default ProductDialog;
