import React, { useEffect, useState } from "react";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiToggleButtonGroup-groupedHorizontal": {
      padding: "10px 17px",
    },
    "& .Mui-selected, .Mui-selected:hover": {
      backgroundColor: theme.palette.button.primary.background.enable,
      color: theme.palette.button.primary.color.default,
    },
  },
}));

const Toggle = ({ options, onChange, defaultValue, allowNull }) => {
  const [value, setValue] = useState(defaultValue);
  const classes = useStyles();
  const onChangeToggleGroup = (event, newValue) => {
    if (newValue === null && !allowNull) {
      return;
    }
    setValue(newValue);
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={onChangeToggleGroup}
      aria-label="text alignment"
      className={classes.root}
    >
      {Object.entries(options).map(([value, label]) => (
        <ToggleButton key={value} value={value} aria-label="left aligned">
          {label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

Toggle.propTypes = {
  options: PropTypes.object,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  allowNull: PropTypes.bool,
};

Toggle.defaultProps = {
  options: {},
  onChange: () => {},
  defaultValue: null,
  allowNull: false,
};

export default Toggle;
