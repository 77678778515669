import Jed from "jed";

import basil from "core/basil";
import get from "lodash/get";
import toLower from "lodash/toLower";

import { DEFAULT_LOCALE, ALLOWED_LOCALES } from "orm/resources/user";

class I18n {
  constructor() {
    this.jed = new Jed({});
  }

  translate(arg) {
    return this.jed.translate(arg);
  }

  load(json) {
    this.jed = new Jed(json);
  }

  default() {
    this.jed = new Jed({});
  }
}

function replace(string, replacements) {
  for (let key in replacements) {
    string = string.replace(new RegExp(`{${key}}`, "gi"), replacements[key]);
  }

  return string;
}

const i18n = new I18n();

/*
 * I18nTranslate('key')
 * I18nTranslate('key {dog} foo', { dog })
 * I18nTranslate('key {count} {dog}', 'plural {count} {dog}', count, { dog, count })
 */
export function i18nTranslate(...args) {
  let value = i18n.translate(args[0]);

  if (args.length > 2) {
    value = value.ifPlural(args[2], args[1]).fetch(args[2]);

    return args.length === 4 ? replace(value, args[3]) : value;
  }

  value = value.fetch();

  return args.length === 2 ? replace(value, args[1]) : value;
}

export function getLocale(user = { lang: null }, navigator = window.navigator) {
  let navLanguage = navigator.language || navigator.userLanguage;

  if (navLanguage.indexOf("-") !== -1) {
    navLanguage = navLanguage.split("-")[0];
  }

  if (navLanguage.indexOf("_") !== -1) {
    navLanguage = navLanguage.split("_")[0];
  }

  const locale = get(user, "lang") || basil.get("user:locale") || navLanguage;

  if (ALLOWED_LOCALES.indexOf(locale) === -1) {
    return DEFAULT_LOCALE;
  }

  return locale;
}

export function setLocale(locale) {
  basil.set("user:locale", locale);
}

export function _t() {
  return i18nTranslate(...arguments);
}

export function _n() {
  return i18nTranslate(...arguments);
}
export function getBrowserLanguage() {
  const defaultLocales = ["fr", "en"];

  let navLang = toLower(get(window, "navigator.language", "fr")).slice(0, 2);

  if (!defaultLocales.includes(navLang)) {
    navLang = "fr";
  }

  return navLang;
}
export default i18n;
