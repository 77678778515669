import { genericResourcesSelector } from "../ressources/selectors";

export const mobileLayoutSelector = (state) => state.liveShopping.layoutType === "mobile";

export const desktopLayoutSelector = (state) => state.liveShopping.layoutType === "desktop";

export const fullScreenSelector = (state) => state.liveShopping.fullScreen;

export const viewedProductSelector = (state) => state.liveShopping.viewedProduct;

export const playerLoadedSelector = (state) => state.liveShopping.playerIsLoaded;

export const catalogIsOpenSelector = (state) => state.liveShopping.catalogIsOpen;

export const cartIsOpenSelector = (state) => state.liveShopping.cartIsOpen;

export const replayIsOpenSelector = (state) => state.liveShopping.replayIsOpen;

export const replayOverlaySelector = (state) => state.liveShopping.replayOverlay;

export const replayDurationSelector = (state) => state.liveShopping.replayDuration;

export const currentChapterSelector = (state) => state.liveShopping.currentChapter;

export const allProductsSelector = (state) => genericResourcesSelector(state, { type: "Product" });

export const playerVolumeSelector = (state) => state.liveShopping.playerVolume;

export const isIFrameSelector = (state) => state.liveShopping.isIFrame;

export const isPreliveSelector = (state) => state.liveShopping.isPrelive;

export const displayTabBarSelector = (state) => state.liveShopping.displayTabBar;

export const displayBackOfficeElementSelector = (state) => state.liveShopping.elements;

export const liveShoppingBootstrapSelector = (state) => state.liveShopping.liveShoppingBootstrap;
export const projectsSelector = (state) => genericResourcesSelector(state, { type: "Project" });
export const groupsSelector = (state) => genericResourcesSelector(state, { type: "Group" });
export const currentVisitorSelector = (state) => state.liveShopping.currentVisitor;
export const currentCalendarSelector = (state) => state.liveShopping.currentCalendar;
export const currentLiveVendorsSelector = (state) => state.liveShopping.currentLiveVendors;
export const isReadySelector = (state) => state.liveShopping.isReady;
export const isRetractedSelector = (state) => state.liveShopping.isRetracted;
export const npsSelector = (state) => state.liveShopping.nps;
export const screenOrientationSelector = (state) => state.liveShopping.screenOrientation;
export const notificationsSelector = (state) => state.liveShopping.notifications;
export const fishingRodSelector = (state) => state.liveShopping.fishingRod;