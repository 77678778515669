import React from "react"
import { currentProjectSelector, getKeyword } from "app/state/ducks/application/selectors";
import { getUrl } from "app/views/containers/Application/Routes";
import { Calendar, Calendar4Week, ChatLeftQuote, ChatLeftQuoteFill, Grid, PlayBtn, SkipBackward, Sliders, ShopWindow } from "react-bootstrap-icons";
import Img from "react-bootstrap-icons/dist/icons/image";
import BarChart from "react-bootstrap-icons/dist/icons/bar-chart";
import People from "react-bootstrap-icons/dist/icons/people";
import { useSelector } from "react-redux";
import { _t } from "utilities/i18n";
import CameraVideo from "react-bootstrap-icons/dist/icons/camera-video";
import Envelope from "react-bootstrap-icons/dist/icons/envelope";
import { isGranted } from "app/liveShopping/backoffice/oto/utils";


export const TabListOTM = () => {
  const keyword = useSelector(getKeyword);
  return [
    {
      url: getUrl("otm_live", { keyword }),
      icon: <Grid />,
      title: _t("General"),
      active: false,
    },
    {
      url: getUrl("otm_video", { keyword }),
      icon: <CameraVideo />,
      title: _t("Video Parameters"),
    },
    {
      url: getUrl("otm_appearance", { keyword }),
      icon: <Img />,
      title: _t("Appearance"),
    },
    {
      url: getUrl("otm_replay", { keyword }),
      icon: <SkipBackward />,
      title: _t("Replay"),
    },
    {
      url: getUrl("otm_participants", { keyword }),
      icon: <People />,
      title: _t("Participants"),
    },
    {
      url: getUrl("otm_stats", { keyword }),
      icon: <BarChart />,
      title: _t("Statistics"),
    },
    {
      url: getUrl("otm_moderation", { keyword }),
      icon: <ChatLeftQuoteFill />,
      title: _t("Chat & Moderation"),
    },
    {
      url: getUrl("otm_mails", { keyword }),
      icon: <Envelope />,
      title: _t("Communication"),
    },
  ];
};

export const tabListOTO = () => {
  const project = useSelector(currentProjectSelector);
  const hash = localStorage.getItem("projectId");
  const list = [
    {
      url: getUrl("oto_dashboard", { hash }),
      icon: <Grid />,
      title: _t("Dashboard")
    },
    {
      url: getUrl("oto_live", { hash }),
      icon: <PlayBtn />,
      title: _t("Live")
    },
    {
      url: getUrl("oto_multi_chat", { hash }),
      icon: <ChatLeftQuote />,
      title: _t("Chat")
    },
    {
      url: getUrl("oto_appointments", { hash }),
      icon: <Calendar4Week />,
      title: _t("Appointments"),
    },
    {
      url: getUrl("oto_groups", { hash }),
      icon: <People />,
      title: _t("Groups"),
    },
    {
      url: getUrl("oto_history", { hash }),
      icon: <Envelope />,
      title: _t("History"),
    },
    {
      url: getUrl("oto_products", { hash }),
      icon: <ShopWindow />,
      title: _t("Products"),
    },
  ];

  if (isGranted(project)) {
    if (list.length === 0) return;
    list.push({
      url: getUrl("oto_parameters", { hash, tab: null }),
      icon: <Sliders />,
      title: _t("Parameters"),
    })
  }

  return list;
};
