import { put } from "redux-saga/effects";
import isEmpty from "lodash/isEmpty";

import { RESOURCES_SET, RESOURCE_UNSET } from "ressourcesDucks/actionsTypes";
import {
  UPDATE_TIMELINE,
  UPDATE_ARCHIVE_STREAM,
} from "applicationDucks/actionsTypes";

//update it to take request options
export default function* dispatch(
  { resources, included, meta, locator = null, calledOnce = false },
  options = null
) {
  // we already called this endpoint, no content were returned
  if (calledOnce) {
    return;
  }

  if (locator) {
    yield put({ type: RESOURCE_UNSET, locator });
  } else {
    yield put({ type: RESOURCES_SET, resources, included, meta });
    if (!isEmpty(options) && options.archive) {
      yield put({
        type: UPDATE_ARCHIVE_STREAM,
        resources,
        included,
        meta,
        refresh: options.refresh,
        archive: options.archive,
      });
    }
    if (!isEmpty(options) && options.timeline) {
      yield put({
        type: UPDATE_TIMELINE,
        resources,
        included,
        meta,
        refresh: options.refresh,
      });
    }
  }
}
