import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import AccordionOption from "./Accordion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "5px",
  },
  field: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  buttonSave: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
}));

const Catalog = ({ project }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(!!project.catalog_integration_url);
  const [catalogUrl, setcatalogUrl] = useState(project.catalog_integration_url);

  const handleSend = () => {
    project.catalog_integration_url = catalogUrl;

    if (catalogUrl === "")
      project.catalog_integration_url = null;

    dispatch(resourceEdit(project, {
      slug: ["projects", project.id],
      patch: ["catalog_integration_url"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }));
  };

  return (
    <Box className={classes.root}>
      <AccordionOption title={_t("Products catalog")}>
        <Box className={classes.field}>
          <Typography className={classes.label}>{_t("Catalog integration url")}</Typography>
          <Input value={catalogUrl} onChange={(e) => setcatalogUrl(e.target.value)} />
        </Box>

        <Typography>{_t("If left blank, not products catalog will be available")}</Typography>
        <Box className={classes.buttonSave}>
          <Button onClick={handleSend}>{_t("Save")}</Button>
        </Box>
      </AccordionOption>
    </Box>
  )
};

export default Catalog;
