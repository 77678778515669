import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { isUserOnlineSelector } from "applicationDucks/selectors";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    //overflowX: "auto",
  },
  greyCercle: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    backgroundColor: theme.wisemblyColors.uniGrey,
  },
  greenCercle: {
    width: 18,
    height: 18,
    borderRadius: "50%",
    backgroundColor: theme.wisemblyColors.green,
  },
}));

const Online = ({ user }) => {
  const isOnline = useSelector((state) => isUserOnlineSelector(state, user));
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        className={clsx({
          [classes.greyCercle]: !isOnline,
          [classes.greenCercle]: isOnline,
        })}
      ></Box>
    </Box>
  );
};
Online.propTypes = {
  user: PropTypes.string,
};
Online.defaultProps = {
  user: null,
};
export default Online;
