import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import uniq from "lodash/uniq";
import { _t } from "core/i18n";
import { makeStyles } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import TextField from "./TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
  },
  icon: {
    textAlign: "right",
  },
  mailsList: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    backgroundColor: theme.wisemblyColors.white,
    marginBottom: "10px",
    borderRadius: "5px",
    paddingTop: "10px",
  },
  tagItem: {
    backgroundColor: theme.wisemblyColors.grey1,
    borderRadius: "10px",
    //height: "24px",
    fontSize: "12px",
    lineHeigh: "14.53px",
    fontWeight: 400,
    letterSpacing: "0.5px",
    color: theme.wisemblyColors.black,
    paddingLeft: "10px",
    paddingRight: "10px",
    marginBottom: "10px",
    marginLeft: "5px",
  },
  iconButton: {
    height: "20px",
    width: "20px",
    marginLeft: "10px",
  },
}));

const ChipInput = ({ onListChange, reset, getCurrentFieldValue }) => {
  const [items, setItems] = useState([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);

  const classes = useStyles();
  useEffect(() => {
    onListChange(items);
  }, [items.length]);
  useEffect(() => {
    if (reset) {
      setItems([]);
      setValue("");
      setError(null);
    }
  }, [reset]);

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      let trimed = value.trim();

      if (trimed && isValid(trimed)) {
        setItems([...items, trimed]);
        setValue("");
      }
    }
  };
  useEffect(() => {
    let currentValue = "";
    if (value) {
      let trimed = value.trim();

      if (trimed && isValid(trimed, false)) {
        currentValue = trimed;
      }
    }
    getCurrentFieldValue(currentValue);
  }, [value]);
  const handleChange = (event) => {
    setValue(event.target.value);
    setError(null);
  };

  const handleDelete = (item) => {
    setItems(items.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();

    let paste = evt.clipboardData.getData("text");

    let emails = paste.match(
      /[\w\d\.-]+(\+[\w\d\.-]+)?@[\w\d\.-]+\.[\w\d\.-]+/g
    );
    emails = uniq(emails); //remove duplicates ;)
    if (emails) {
      let toBeAdded = emails.filter((email) => !isInList(email));
      setItems([...items, ...toBeAdded]);
    }
  };

  const isValid = (email, withNotification = true) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} ${_t("has already been added.")} `;
    }

    if (!isEmail(email)) {
      error = `${email} ${_t("is not a valid email address.")}`;
    }

    if (error) {
      if (withNotification) {
        setError(error);
      }

      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return items.includes(email);
  };

  const isEmail = (email) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  return (
    <Box className={classes.root}>
      {items.length > 0 && (
        <Box className={classes.mailsList}>
          {items.map((item, i) => (
            <Box className={classes.tagItem} key={`${item}${i}`}>
              {item}
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleDelete(item)}
                aria-label="close"
                classes={{ root: classes.iconButton }}
              >
                <CloseOutlinedIcon
                  fontSize="small"
                  className={classes.deleteIcon}
                />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
      <TextField
        className={clsx({ [classes.input]: true, [classes.error]: error })}
        value={value}
        placeholder={_t("Type or paste email addresses and press `Enter`...")}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
        disableUnderline
      />

      {error && <Box className={classes.error}>{error}</Box>}
    </Box>
  );
};
ChipInput.propTypes = {
  onListChange: PropTypes.func,
  reset: PropTypes.bool,
  getCurrentFieldValue: PropTypes.func,
};
ChipInput.defaultProps = {
  onListChange: () => {},
  reset: false,
  getCurrentFieldValue: () => {},
};
export default ChipInput;
