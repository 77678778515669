import { getUrl } from "app/views/containers/Application/Routes";
import React from "react"
import { Calendar3, Clock, Ladder, ChatDots } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import { _t } from "utilities/i18n";


export const TabListAppointment = () => {
  const { group, hash } = useParams();
  return [
    {
      url: getUrl("oto_appointment", { hash, group, tab: "availability" }),
      icon: <Calendar3 />,
      title: _t("Availability"),

    },
    {
      url: getUrl("oto_appointment", { hash, group, tab: "setup" }),
      icon: <Ladder />,
      title: _t("Event Setup"),

    },
    {
      url: getUrl("oto_appointment", { hash, group, tab: "limits" }),
      icon: <Clock />,
      title: _t("Limits"),

    },
    {
      url: getUrl("oto_appointment", { hash, group, tab: "communication" }),
      icon: <ChatDots />,
      title: _t("Communication"),

    },
  ];
};
