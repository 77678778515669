import { useCallback, useEffect, useState, useMemo } from "react";

import get from "lodash/get";
import { useSelector, useDispatch } from "react-redux";
import * as Sentry from "@sentry/react";
import { _t } from "i18n";

import {
  currentWizSelector,
  resourceSelector,
  leaderboardSelector,
} from "ressourcesDucks/selectors";
//import { currentFullLeaderBoard } from "applicationDucks/selectors";
import { resourceEdit, resourceCreate } from "ressourcesDucks/actions";
import ResourceFactory from "orm/resources";
import {
  EVENT_FLASH_SUCCESS,
  EVENT_FLASH_ERROR,
  MODIFY_LEADERBOARD,
  CANCEL_MODIFY_LEADERBOARD,
} from "utilities/emitter/events";
import emitter from "utilities/emitter";
import { adminPreviewSelector } from "applicationDucks/selectors";

export const useSurveyEditManager = (element) => {
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();

  const handleEditSurvey = useCallback(
    (data, item = null) => {
      const slug = ["event", event.keyword, "surveys", element.hash];

      if (item !== null) {
        slug.push("items", item.hash);
      }

      dispatch(
        resourceEdit(Object.assign(element, data), {
          slug: slug,
          silent: true,
          patch: Object.keys(data),
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
            }
          },
        })
      );
    },
    [event, element, dispatch]
  );

  return handleEditSurvey;
};
export const useLiveLeaderboard = () => {
  const event = useSelector(currentWizSelector);
  const liveLeaderBoard = get(event, "live.leaderboard", { surveys: [] });
  const result = useMemo(() => {
    return liveLeaderBoard;
  }, [JSON.stringify(liveLeaderBoard)]);
  return result;
};

export const useLeaderboard = () => {
  const event = useSelector(currentWizSelector);
  // const dispatch = useDispatch();
  // const [fetching, setFetching] = useState(false);
  const leaderboardData = useSelector((state) =>
    leaderboardSelector(state, event.keyword)
  );
  return leaderboardData;
};
export const useLeaderBoardManager = () => {
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();

  const handleLeaderBoardState = useCallback(
    (action = "stop", surveys = []) => {
      let data = {};
      switch (action) {
        case "edit_play": {
          data = {
            live: {
              ...event.live,
              leaderboard: {
                ...event.live.leaderboard,
                displayed: true,
                //surveys can be updated
                surveys,
              },
            },
          };
          break;
        }
        case "edit": {
          data = {
            live: {
              ...event.live,
              leaderboard: {
                ...event.live.leaderboard,
                //displayed: true,
                //surveys can be updated
                surveys,
              },
            },
          };
          break;
        }
        case "display": {
          data = {
            live: {
              ...event.live,
              leaderboard: {
                ...event.live.leaderboard,
                displayed: true,
                //surveys can be updated
                //surveys: [],
              },
            },
          };
          break;
        }
        case "hide": {
          data = {
            live: {
              ...event.live,
              leaderboard: {
                ...event.live.leaderboard,
                displayed: false,
                //surveys: [],
              },
            },
          };
          break;
        }
        case "edit_hide": {
          data = {
            live: {
              ...event.live,
              leaderboard: {
                ...event.live.leaderboard,
                displayed: false,
                surveys,
              },
            },
          };
          break;
        }
        default:
          return;
      }

      dispatch(
        resourceEdit(Object.assign(event, data), {
          slug: ["event", event.keyword],
          silent: true,
          patch: ["live"],
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
              //// TODO: error display
            }
          },
        })
      );
    },
    [event, dispatch]
  );

  return handleLeaderBoardState;
};
export const useSurveyManager = (element) => {
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();

  const handleStateSurvey = useCallback(
    (action = "stop", item = null) => {
      if (element.type === "poll") {
        const items = element.items.toModelArray();
        item = items[0];
      }

      let data = {};
      switch (action) {
        case "start": {
          data = {
            live: {
              ...event.live,
              poll: element.hash,
              popup:
                item === null
                  ? {
                      class_name: "Survey",
                      hash: element.hash,
                      parent_hash: null,
                    }
                  : {
                      class_name: "SurveyItem",
                      hash: get(item, "hash"),
                      type: item.type,
                      parent_hash: element.hash,
                    },
            },
          };
          break;
        }
        case "stop": {
          data = {
            live: {
              ...event.live,
              poll: null,
            },
          };
          break;
        }
        case "display": {
          data = {
            live: {
              ...event.live,
              popup:
                item === null
                  ? {
                      class_name: "Survey",
                      hash: element.hash,
                      parent_hash: null,
                    }
                  : {
                      class_name: "SurveyItem",
                      hash: get(item, "hash"),
                      type: item.type,
                      parent_hash: element.hash,
                    },
            },
          };
          break;
        }
        case "hide": {
          data = {
            live: {
              ...event.live,
              popup: {
                class_name: null,
                hash: null,
              },
            },
          };
          break;
        }
        default:
          return;
      }

      dispatch(
        resourceEdit(Object.assign(event, data), {
          slug: ["event", event.keyword],
          silent: true,
          patch: ["live"],
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
              //// TODO: error display
            }
          },
        })
      );
    },
    [element.type, element.hash, event, dispatch]
  );

  return handleStateSurvey;
};

export const useMediaManager = (element) => {
  const event = useSelector(currentWizSelector);
  const media = useSelector((state) =>
    resourceSelector(state, {
      type: "Media",
      id: element.hash,
    })
  );
  //prevent media action for now in preview
  const preview = useSelector(adminPreviewSelector);
  const dispatch = useDispatch();
  //media:
  const handleStateMedia = useCallback(
    (action = "stop", data = {}) => {
      if (element.class_name === "Media") {
        switch (action) {
          case "start":
          case "stop":
            dispatch(
              resourceEdit(
                Object.assign(event, {
                  live: {
                    ...event.live,
                    visio: action === "start" ? element.hash : null,
                  },
                }),
                {
                  slug: ["event", event.keyword],
                  silent: true,
                  patch: ["live"],
                  headers: {
                    "Wisembly-App-Id": null, //prevent push event from being ignored
                  },
                  callback: (error, response) => {
                    if (error) {
                      Sentry.captureException(error);
                      //// TODO: error display
                    }
                  },
                }
              )
            );
            break;
          case "next":
          case "previous":
          case "first":
          case "last":
            dispatch(
              resourceEdit(
                Object.assign(media, {
                  live: {
                    ...element.live,
                    ...data,
                  },
                }),
                {
                  slug: ["event", event.keyword, "medias", element.hash],
                  silent: true,
                  patch: ["live"],
                  callback: (error, response) => {
                    if (error) {
                      Sentry.captureException(error);
                      //// TODO: error display
                    }
                  },
                }
              )
            );
        }
      }
    },
    [element, event, dispatch]
  );

  return handleStateMedia;
};

export const useVisioManager = () => {
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();

  const handleStateVisio = useCallback(
    (action, data) => {
      switch (action) {
        case "test":
        case "enable":
        case "broadcast":
        case "record":
          dispatch(
            resourceEdit(
              Object.assign(event, {
                live_media: {
                  ...event.live_media,
                  ...data,
                },
              }),
              {
                slug: ["event", event.keyword],
                silent: true,
                patch: ["live_media"],
                headers: {
                  "Wisembly-App-Id": null, //prevent push event from being ignored
                },
                callback: (error) => {
                  if (error) {
                    Sentry.captureException(error);
                    //// TODO: error display
                  }
                },
              }
            )
          );
          break;
      }
    },
    [event, dispatch]
  );

  return handleStateVisio;
};

export const useArchiveManager = () => {
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleStateArchive = useCallback(
    (action, data, callback, callbackError) => {
      switch (action) {
        case "create": {
          const newSession = resourceFactory.create("EventSession", data);
          newSession.setEvent(event);
          dispatch(
            resourceCreate(newSession, {
              slug: ["event", event.keyword, "sessions", "archive"],
              silent: true,
              callback: (error) => {
                if (error) {
                  emitter.emit(EVENT_FLASH_ERROR, _t("An error has occurred"));
                  Sentry.captureException(error);
                  callbackError();
                  return;
                }

                emitter.emit(
                  EVENT_FLASH_SUCCESS,
                  _t("The archive has been created")
                );
                callback();
              },
            })
          );
        }
      }
    },
    [event, dispatch]
  );

  return handleStateArchive;
};

export const useEditingLeaderboard = () => {
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    const callback = () => {
      setEditing(true);
    };
    const cancelCallback = () => {
      setEditing(false);
    };
    emitter.on(MODIFY_LEADERBOARD, callback);
    emitter.on(CANCEL_MODIFY_LEADERBOARD, cancelCallback);
    return () => {
      emitter.off(MODIFY_LEADERBOARD, callback);
      emitter.off(CANCEL_MODIFY_LEADERBOARD, cancelCallback);
    };
  });
  return editing;
};
