import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { _t } from "core/i18n";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ChevronLeftOutlinedIcon from "@material-ui/icons/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@material-ui/icons/ChevronRightOutlined";

import IconButton from "appComponents/Mui/IconButton";
import Button from "appComponents/Mui/Button";
import { useSurveyEditManager } from "adminComponents/Animation/hooks";
import { Select } from "appComponents/Mui";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.box,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2.5),
    "& > *": {
      paddingBottom: theme.spacing(2.5),
    },
  },
  title: {
    fontSize: "20px",
    textAlign: "center",
    padding: theme.spacing(0.625),
    paddingBottom: theme.spacing(2),
  },
  backButton: {
    borderTop: `1px solid ${theme.palette.border.default}`,
    borderBottom: `1px solid ${theme.palette.border.default}`,
    padding: theme.spacing(2.25, 2.5, 2.25, 1),
  },
  item: {
    fontSize: "16px",
  },
  pages: {
    display: "flex",
    alignItems: "center",
  },
  pagesForm: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "center",
  },
  pagesContent: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: theme.spacing(6),
    textAlign: "center",
  },
}));

const getInitialValues = (type, defaultValues) => {
  const values = {
    current_page: 1,
    items_per_page: 5,
    auto_scroll: true, // true by default on fullscreen
  };

  switch (type) {
    case "grid": {
      let data = { ...values, ...defaultValues };
      delete data.items_per_page;
      delete data.auto_scroll;
      delete data.sorting;
      delete data.type;

      return data;
    }
    case "unique":
    case "multiple":
    case "image":
    case "ranking": {
      let data = { ...values, sorting: "desc", ...defaultValues };
      delete data.type;

      return data;
    }
    case "text": {
      let data = { ...values, type: "tagcloud", ...defaultValues };
      delete data.sorting;

      return data;
    }
  }

  return { ...values, ...defaultValues };
};

const ProjectionDisplayOptions = ({
  survey,
  itemSelected,
  handleBackToChoicesOfQ,
}) => {
  const classes = useStyles();
  const handleSurveyState = useSurveyEditManager(survey);
  const item = survey.items.toModelArray()[itemSelected];
  const [values, setValues] = useState(
    getInitialValues(item.type, item.display)
  );

  const handleChange = (name, value) => {
    const data = { [name]: value };
    if (name === "current_page") {
      data.auto_scroll = false;
    }

    const updateValues = { ...values, ...data };
    setValues(updateValues, handleSurveyState({ display: updateValues }, item));
  };

  const count =
    item.type === "text"
      ? item.summary.list && item.summary.list.length
        ? item.summary.list.length
        : values.items_per_page
      : item.options.choices.length;

  const total_pages = Math.ceil(count / values.items_per_page);

  const optionsItemPerPage = () => {
    let options = [];
    for (let i = 1; i <= (item.type === "image" ? 5 : 10); i++) {
      options.push({ value: i, label: i });
    }
    return options;
  };

  return (
    <Box className="ProjectionDisplayOptions">
      <Box>
        <Typography className={classes.title}>
          {_t("Display of the question")}
        </Typography>
        {survey.type !== "poll" && (
          <Box className={classes.backButton}>
            <Button
              onClick={handleBackToChoicesOfQ}
              startIcon={<ChevronLeftOutlinedIcon />}
              size="medium"
            >
              {_t("All questions")}
            </Button>
          </Box>
        )}
      </Box>
      <Box className={classes.form}>
        <Typography className={classes.item}>{item.label}</Typography>
        {values.current_page && (
          <Box className={classes.pages}>
            <Typography>{_t("Pages")}</Typography>
            <Box className={classes.pagesForm}>
              <IconButton
                color="primary"
                squared={true}
                size="large"
                disabled={values.current_page <= 1}
                onClick={() =>
                  handleChange("current_page", values.current_page - 1)
                }
              >
                <ChevronLeftOutlinedIcon />
              </IconButton>
              <Typography className={classes.pagesContent}>
                {_t(`${values.current_page} / ${total_pages}`)}
              </Typography>
              <IconButton
                color="primary"
                squared={true}
                size="large"
                disabled={values.current_page >= total_pages}
                onClick={() =>
                  handleChange("current_page", values.current_page + 1)
                }
              >
                <ChevronRightOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
        )}
        {values.type && (
          <Select
            label={_t("Display mode")}
            name="type"
            value={values.type}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            options={[
              { value: "list", label: _t("List") },
              { value: "tagcloud", label: _t("Tagcloud") },
            ]}
          />
        )}
        {values.sorting && (
          <Select
            label={_t("Sorting")}
            name="sorting"
            value={values.sorting}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            options={[
              { value: "initial", label: _t("Initial order") },
              { value: "desc", label: _t("Most voted first") },
              { value: "asc", label: _t("Less voted first") },
            ]}
          />
        )}
        {values.items_per_page &&
          (item.type !== "text" || values.type !== "tagcloud") && (
            <Select
              label={_t("Items per page")}
              name="items_per_page"
              value={values.items_per_page}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              options={optionsItemPerPage()}
            />
          )}
        {Object.prototype.hasOwnProperty.call(values, "auto_scroll") &&
          (item.type !== "text" || values.type !== "tagcloud") && (
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  name="auto_scroll"
                  checked={values.auto_scroll}
                  onChange={(e) =>
                    handleChange(e.target.name, e.target.checked)
                  }
                />
              }
              label={_t("Automatic page change")}
              labelPlacement="start"
            />
          )}
      </Box>
    </Box>
  );
};

ProjectionDisplayOptions.propTypes = {
  survey: PropTypes.object,
  itemSelected: PropTypes.number,
  handleBackToChoicesOfQ: PropTypes.func,
};

export default ProjectionDisplayOptions;
