import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useFormik } from "formik";
import Input from "../../reusables/Input";
import TextArea from "../../reusables/TextArea";
import Variant from "./Variant";
import { generateHash } from "utilities/utils";
import { useSelector } from "react-redux";
import { desktopLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import Button from "../../reusables/Button";
import Plus from "react-bootstrap-icons/dist/icons/plus";
import { validate } from "./util";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import OtoChecker from "./OtoChecker";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => (props.isDesktop ? "390px" : "100%"),
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    height: (props) => props.isDesktop && "calc(75vh - 23px)",
    marginBottom: (props) => !props.isDesktop && "10px",
    paddingBottom: (props) => props.isDesktop && "10px",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "8px",
  },
  divider: {
    margin: "40px 20px",
  },
  typoBtnAdd: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "100%",
  },
  errors: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.error,
  },
}));

const Form = ({ setData, product }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const classes = useStyles({ isDesktop });
  const [variants, setVariants] = useState(product?.variants || []);
  const wiz = useSelector(currentWizSelector);
  const theme = useTheme();
  const project = wiz.oto_integration_id;

  const updateVariant = (variant) => {
    const index = variants.findIndex(({ hash }) => hash === variant.hash);

    if (index === -1) {
      variants.push(variant);
    } else {
      variants[index] = Object.assign({}, variant);
    }

    setVariants([...variants]);
  };

  useEffect(() => {
    if (variants.length < 1) setVariants([...variants, { hash: generateHash(5) }]);
  }, []);
  const formik = useFormik({
    initialValues: {
      name: product?.name || "",
      short: product?.short || "",
      product_url: product?.product_url || "",
      description: product?.description || "",
      variants: [],
    },
    validate: (values) => validate(values, wiz),
  });

  useEffect(() => {
    formik.setValues({ ...formik.values, variants: variants });
  }, [variants]);

  useEffect(() => {
    setData(formik.values);
  }, [formik.values]);

  return (
    <form className={classes.root}>
      <Box style={{ margin: "16px 20px" }}>
        <label className={classes.label} htmlFor="name">
          {_t("Name of the product")}
        </label>
        <Input onBlur={formik.handleBlur} name={"name"} value={formik.values.name} onChange={formik.handleChange} placeholder={"Name of the product"} />
        {formik.touched.name && formik.errors.name && <Typography className={classes.errors}>{formik.errors.name}</Typography>}
      </Box>
      <Box style={{ margin: "16px 20px" }}>
        <label className={classes.label} htmlFor="short">
          {_t("Short Description")}
        </label>
        <Input name={"short"} value={formik.values.short} onChange={formik.handleChange} placeholder={"short description"} />
        {formik.errors.short && <Typography className={classes.errors}>{formik.errors.short}</Typography>}
      </Box>
      {!wiz?.cart_integration || project && (
        <Box style={{ margin: "16px 20px" }}>
          <label className={classes.label} htmlFor="product_url">
            {_t("Product URL")}
          </label>
          <Input onBlur={formik.handleBlur} name={"product_url"} value={formik.values.product_url} onChange={formik.handleChange} placeholder={"Add the product link"} />
          {formik.touched.product_url && formik.errors.product_url && <Typography className={classes.errors}>{formik.errors.product_url}</Typography>}
          <OtoChecker projectId={project} productUrl={formik.values.product_url} isBackOffice={true} />
        </Box>
      )}
      <Box style={{ margin: "16px 20px" }}>
        <label className={classes.label} htmlFor="description">
          {_t("Long Description")}
        </label>
        <TextArea onBlur={formik.handleBlur} name={"description"} value={formik.values.description} onChange={formik.handleChange} placeholder={"Add a long description"} />
        {formik.touched.description && formik.errors.description && <Typography className={classes.errors}>{formik.errors.description}</Typography>}
      </Box>

      {variants.length > 1 &&
        variants.map((variant, index) => (
          <span key={variant.hash}>
            <Variant
              index={index}
              variant={variant}
              onUpdate={updateVariant}
              onDelete={(variant) => {
                if (variants.length > 1) {
                  setVariants(variants.filter(({ hash }) => hash !== variant.hash));
                }
              }}
            />
          </span>
        ))}

      {variants.length <= 1 && <Variant index={0} variant={variants[0]} onUpdate={updateVariant} onDelete={() => { }} isMonoVariant={true} />}

      <Button
        style={{ margin: "0 20px", gap: "8px" }}
        color={theme.palette.colors.grey200}
        onClick={() => {
          setVariants([...variants, { hash: generateHash(5) }]);
        }}
      >
        {variants.length > 1 && (
          <>
            <Typography className={classes.typoBtnAdd} style={{ color: "black" }}>
              {_t("Add a variant")}
            </Typography>
            <Plus color={"black"} size={18} />
          </>
        )}

        {variants.length <= 1 && (
          <Typography className={classes.typoBtnAdd} style={{ color: "black" }}>
            {_t("This product has variants")}
          </Typography>
        )}
      </Button>
    </form>
  );
};

Form.propTypes = {};

Form.defaultProps = {};

export default Form;
