import React from "react";
import { Link } from "react-router-dom";

import { _t } from "i18n";

export default function NotFound() {
  return (
    <div className="AppPage">
      <div className="AppPage-body">
        <div className="Content-empty">
          <h4 className="u-mg--0">{_t("404")}</h4>
          <div className="t-secondary u-mgv--s">
            {_t("We couldn't find the page you're looking for.")}
          </div>
          <div>
            <Link to="/">{_t("Back to the home page")}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
