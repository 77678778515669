import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import AppLoader from "./frontoffice/otm/components/AppLoader";

const useStyles = makeStyles(() => {
  return {
    root: {
      maxWidth: "100%",
      height: "100%",
      margin: 0,
      padding: 0,
      overflow: "auto",
      overscrollBehavior: "contain",
      display: "flex",
      flex: "1",
    },
    contentRoot: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
      overflow: "hidden",
      overscrollBehavior: "contain",
    },
  };
});

const Layout = ({ children, loading }) => {

  const classes = useStyles({});
  return (
    <Box className={clsx("Layout", classes.root)}>
      {loading && <AppLoader />}

      {!loading && <Box className={clsx("Screen", classes.contentRoot)}>{children}</Box>}
    </Box>
  );
};

Layout.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Layout;
