import React from "react";
import { matchPath } from "react-router";
import {
  NEXT_ROUTES,
  NO_HEADER_ROUTES,
  SHOW_ADMINBAR_ROUTES,
  BASIC_LOGO,
} from "./routes";
import map from "lodash/map";
import { Link } from "react-router-dom";

export const hasRouteInList = (routes) => {
  return routes.some(
    (route) => matchPath(window.location.pathname, route)?.isExact === true
  );
};

export const isNextEnvironnement = () => {
  return hasRouteInList(NEXT_ROUTES);
};

export const showPersonalisedLogo = () => {
  return hasRouteInList(BASIC_LOGO);
};

export const shouldHideHeader = () => {
  return hasRouteInList(NO_HEADER_ROUTES);
};

export const shouldShowAdminBar = () => {
  return hasRouteInList(SHOW_ADMINBAR_ROUTES);
};

export const getUrlWithParams = (route, params) => {
  let newRoute = route;
  map(params, (value, key) => {
    newRoute = newRoute.replace(`:${key}`, value);
  });
  return newRoute;
};

export const LinkToRoute = ({ route, params, children, ...rest }) => {
  return (
    <Link to={getUrlWithParams(route, params)} {...rest}>
      {children}
    </Link>
  );
};