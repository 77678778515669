import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { _t } from "core/i18n";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { currentWizSelector } from "ressourcesDucks/selectors";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import { secondsToDhmsRounded } from "utilities/utils/date";
import { getStatus, getOpenedStat } from "./utils";
import { PopperOptions } from "adminComponents/Animation/Interactivities";
import { IconAction } from "adminComponents/Mui";
import { OptionsMenu } from "adminComponents/Mails";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";
import {
  replaceVariables,
  getSendDate,
} from "adminComponents/Mails/Editor/utils";

dayjs.extend(relativeTime);
dayjs.extend(timezone);
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: "#FFF",
    border: "1px solid #E2E4E8",
    flexDirection: "row",
    justifyContent: "space-between",
    //marginTop: "40px",
    marginBottom: "15px",
    alignItems: "center",
    minHeight: "100px",
    borderRadius: "10px",
    //overflowX: "auto",
    "&.templateNotAvailable": {
      "& .info": {
        flex: 4,
      },
      "& .subject": {
        flex: 3,
      },
      "& .title, .subtitle, .prerequisites": {
        color: "#000000",
        opacity: "0.5",
      },
    },
  },
  activated: {
    backgroundColor: "#FFF",
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: "normal",
    fontSize: "14px",
  },
  description: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "25px",
    padding: "15px 0",
  },
  action: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  cell: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: "15px",
    minWidth: "117px",
  },
  labelBox: {
    display: "flex",
    flexDirection: "column",
  },
  timing: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  topTiming: {
    fontWeight: "bold",
    fontSize: "normal",
    lineHeight: "20px",
    letterSpacing: "0.487013px",
  },
  bottomTiming: {},
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    color: theme.palette.text.primary,
    marginTop: "10px",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "20px",
  },
  subtitle: {
    marginTop: "10px",
    fontWeight: "normal",
    fontSize: "14px",
  },
  actionLabel: {
    opacity: 0.5,
  },
  templateTitle: {
    fontWeight: 500,
    fontSize: "20px",
  },
  hidden: {
    display: "none",
  },
  editModal: {
    display: "flex",
    zIndex: theme.zIndex.modal,
    backgroundColor: theme.palette.background.default,
    width: "90%",
    height: "90%",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
  },
  prerequisites: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flex: 1,
    padding: "10px",
  },
  prerequisitesLink: {
    opacity: 0.5,
    "&:hover": {
      cursor: "pointer",
      color: "#000000",
      opacity: 1,
    },
  },
}));

//

const GetAtTime = ({ template, send_type, send_delay, event, active }) => {
  let top = "-";
  let bottom = "";
  const classes = useStyles();
  const scheduled_sending = getSendDate(template);

  if (active) {
    switch (send_type) {
      case "sending_type_after_registration":
        top = _t("After inscription");
        bottom = _t("Automatic");
        break;
      case "sending_type_after_invitation":
        top = _t("After invitation");
        bottom = _t("Automatic");
        break;
      case "sending_type_after_event": {
        const difference_seconds = scheduled_sending.diff(
          template.event.getStopLiveDateWithFallback(),
          "seconds"
        );
        const relativeWord =
          difference_seconds > 0 ? _t("after") : _t("before");
        top = `${secondsToDhmsRounded(difference_seconds)} ${relativeWord}`;
        bottom = scheduled_sending.format(`DD[/]MM[/]YY [${_t("at")}] HH:mm`);
        break;
      }
      case "sending_type_before_event":
      case "sending_type_customized": {
        const difference_seconds = scheduled_sending.diff(
          template.event.getStartLiveDateWithFallback(),
          "seconds"
        );
        const relativeWord =
          difference_seconds > 0 ? _t("after") : _t("before");
        top = `${secondsToDhmsRounded(difference_seconds)} ${relativeWord}`;
        bottom = scheduled_sending.format(`DD[/]MM[/]YY [${_t("at")}] HH:mm`);
        break;
      }
      default:
        break;
    }
  }
  return (
    <Box className={classes.timing}>
      <Box className={classes.topTiming}>{top}</Box>
      <Box className={classes.bottomTiming}>{bottom}</Box>
    </Box>
  );
};
GetAtTime.propTypes = {
  event: PropTypes.object,
  send_type: PropTypes.string,
  send_delay: PropTypes.string,
  active: PropTypes.bool,
};

const TemplateHandler = ({ template, editTemplateLive }) => {
  const {
    template_key,
    active,
    send_delay,
    send_type,
    stats,
    subject,
    status,
  } = template;
  const event = useSelector(currentWizSelector);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(currentUserSelector);

  const showPrerequisites = () => {
    const showConditions = Object.entries(template.getShowConditions()).filter(
      ([condition, fullfiled]) => fullfiled === false
    );

    const link = (translation, url) => {
      const onClick = url ? () => window.open(url, "_blank") : () => {};
      return (
        <Box onClick={onClick} className={classes.prerequisitesLink}>
          {translation}
        </Box>
      );
    };

    const LinkMailHelp =
      "https://wisembly.zendesk.com/hc/fr/articles/360015645839";
    return (
      <Box className={clsx("prerequisites", classes.prerequisites)}>
        {showConditions.map(([condition, fullfiled]) => {
          switch (condition) {
            case "invitationEnabled":
              /* prettier-ignore */
              return _t("The relaunch email will only be available after you configure the invitation");
            case "registrationEnabled":
              /* prettier-ignore */
              return link(_t("The registration module is not enabled, you can find more information here to enable it"), LinkMailHelp);
            case "landingEnabled":
              /* prettier-ignore */
              return link(_t("The date and time of the event are not configured, you can find more information here to enable it"), LinkMailHelp);
          }
        })}
      </Box>
    );
  };

  const templateAvailable = template.isAvailable();

  return (
    <Box
      className={clsx({
        ["templateHandler"]: true,
        [classes.root]: true,
        [classes.activated]: true,
        ["templateNotAvailable"]: !templateAvailable,
      })}
      template-hash={template.id}
    >
      <Box className={clsx("subject", classes.description)}>
        <Box className={clsx("labelObject", classes.labelBox)}>
          <Box
            className={clsx({
              ["title"]: true,
              [classes.label]: true,
              [classes.templateTitle]: true,
            })}
          >
            {template.getTitle()}
          </Box>
          <Box
            className={clsx({
              ["subtitle"]: true,
              [classes.subtitle]: true,
              [classes.label]: true,
            })}
          >
            {replaceVariables(subject, event, user)}
          </Box>
        </Box>
      </Box>
      <Box className={clsx("info", classes.action)}>
        {templateAvailable ? (
          <>
            <Box className={clsx("cell", classes.cell)}>
              <Box
                className={clsx("title", classes.label, classes.actionLabel)}
              >
                {_t("At which moment")}
              </Box>
              <Box className={clsx("content", classes.content)}>
                <GetAtTime
                  template={template}
                  status={status}
                  send_delay={send_delay}
                  send_type={send_type}
                  event={event}
                  active={active}
                />
              </Box>
            </Box>
            <Box className={clsx("cell", classes.cell)}>
              <Box
                className={clsx("title", classes.label, classes.actionLabel)}
              >
                {_t("Status")}
              </Box>
              <Box className={clsx("content", classes.content)}>
                {getStatus(active, status)}
              </Box>
            </Box>
            <Box className={clsx("cell", classes.cell)}>
              <Box
                className={clsx("title", classes.label, classes.actionLabel)}
              >
                {_t("Opened")}
              </Box>
              <Box className={clsx("content", classes.content)}>
                {getOpenedStat(stats, status, "opened")}
              </Box>
            </Box>
            <Box className={clsx("cell", classes.cell)}>
              <Box
                className={clsx("title", classes.label, classes.actionLabel)}
              >
                {_t("Clicked")}
              </Box>
              <Box className={clsx("content", classes.content)}>
                {getOpenedStat(stats, status, "clicked")}
              </Box>
            </Box>
            <Box className={clsx("cell", classes.cell)}>
              <PopperOptions
                handleTooltipClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                buttonComponent={
                  <IconAction
                    action="moreVert"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  />
                }
              >
                <OptionsMenu
                  template={template}
                  handleOnClick={() => setAnchorEl(null)}
                  onEdit={() => {
                    editTemplateLive(template);
                  }}
                />
              </PopperOptions>
            </Box>
          </>
        ) : (
          showPrerequisites()
        )}
      </Box>
    </Box>
  );
};
TemplateHandler.propTypes = {
  template: PropTypes.string,
  active: PropTypes.bool,
  send_delay: PropTypes.number,
  send_type: PropTypes.string,
  stats: PropTypes.string,
  subject: PropTypes.string,
  is_prototype: PropTypes.bool,
  hash: PropTypes.string,
};
TemplateHandler.defaultProps = {
  active: false,
  editTemplateLive: () => {},
};
export default TemplateHandler;
