import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { _t } from "i18n";
import Bubble from "./reusables/Bubble";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: (props) => (props.isMyMessage ? "row" : "row-reverse"),
    justifyContent: "flex-end",
    paddingLeft: (props) => (props.isMyMessage ? "20px" : "0"),
  },
  element: {
    display: "flex",
    justifyContent: "flex-start",
    width: "auto",
  },
}));

const BubbleWrapper = ({ quote, origin, owner, isAdmin, url }) => {
  const isMyMessage = ["me"].includes(origin);
  const classes = useStyles({ isMyMessage });

  return (
    <Box className={classes.root}>
      <Box className={classes.element}>
        <Bubble quote={quote} origin={origin} owner={isMyMessage ? _t("You") : owner} isAdmin={isAdmin} url={url} />
      </Box>
    </Box>
  );
};

BubbleWrapper.propTypes = {};

BubbleWrapper.defaultProps = {};

export default BubbleWrapper;
