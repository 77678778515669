import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { setReplayDialog } from "app/state/ducks/liveShopping/actions";
import { currentWizReplaySelector } from "app/state/ducks/ressources/selectors";
import { Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    background: (props) => (props.isMobile ? "transparent" : theme.palette.colors.white),
    border: "1px solid",
    webKitBorder: "1px solid",
    borderColor: (props) => (props.isMobile ? "rgba(180, 182, 185, 0.5)" : "#E2E4E8"),
    boxSizing: "border-box",
    borderRadius: "8px",
    padding: "10px 12px",
    width: "100%",
    height: "38px",
    fontSize: "14px",
    marginRight: "15px",
    color: (props) => (props.isMobile ? theme.palette.colors.white : theme.palette.colors.black),
    "&:focus-visible": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "130%",
      color: (props) => (props.isMobile ? theme.palette.colors.white : theme.palette.colors.grey),
      opacity: 1,
    },
  },
  wrapper: {
    display: "flex",
    position: "relative",
    width: "100%",
  },
  loading: {
    position: "absolute",
    right: "25px",
    top: "13px",
    color: theme.palette.colors.grey,
  },
}));

const SendInput = ({ setMessage, message, onKeyPress, loading }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const replay = useSelector(currentWizReplaySelector);
  const classes = useStyles({ isMobile });
  const [focused, setFocused] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (focused && replay?.enabled) dispatch(setReplayDialog(true));
  }, [focused]);

  return (
    <Box className={classes.wrapper}>
      <input
        type="text"
        autoComplete="off"
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        name="message"
        className={classes.root}
        placeholder={_t("Send a message")}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={onKeyPress}
        disabled={loading}
      />
      {loading && <CircularProgress size={12} className={classes.loading} />}
    </Box>
  );
};

SendInput.propTypes = {};

SendInput.defaultProps = {};

export default SendInput;
