import React, { useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Icon from "coreUiKit/inputs/Icon";
import Image from "react-bootstrap-icons/dist/icons/image";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import ImageComponent from "coreUiKit/components/Chat/Image";
import { EVENT_SEND_NOTIFICATION } from "core/emitter/events";

import emitter from "core/emitter";
import { _t } from "i18n";

const useStyles = makeStyles(() => ({
  root: {
    // margin: "0 10px",
  },
  uplaoded: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *:last-child": {
      margin: "0 5px",
    },
  },
  disable: {},
  hide: {
    display: "none",
  },
}));

const UplaodImage = ({ onUpload, imgPath, setImgPath, disabled, isMobile }) => {
  const classes = useStyles({ disabled });
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    if (disabled) return;
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    const sizeMb = fileUploaded.size / (1000 * 1000);
    if (sizeMb > 2) {
      //should be under 2
      emitter.emit(EVENT_SEND_NOTIFICATION, {
        type: "alert",
        icon: null,
        button: false,
        userOnly: false,
        WisemblyIcon: null,
        duration: 5,
        hideTimer: true,
        text: _t("You can not upload a file bigger that 2Mb !"),
      });
    } else {
      const url = URL.createObjectURL(fileUploaded);
      setImgPath(url);
      onUpload(fileUploaded);
    }
  };

  const handleDelete = () => {
    setImgPath(null);
    onUpload(null);
  };

  return (
    <Box className={classes.root}>
      {imgPath ? (
        <Box className={classes.uplaoded}>
          <ImageComponent size={32} path={imgPath} isFooter={true} />
          <Icon
            IconComponent={Trash}
            color="error"
            hover="error"
            onClick={handleDelete}
          />
        </Box>
      ) : (
        <Box>
          <Icon
            IconComponent={Image}
            size={isMobile ? 24 : 16}
            hover={disabled ? "grey" : "greyDark"}
            onClick={handleClick}
            disabled={disabled}
          />
          <input
            accept="image/*"
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            className={classes.hide}
          />
        </Box>
      )}
    </Box>
  );
};

UplaodImage.propTypes = {
  onUpload: PropTypes.func,
  setImgPath: PropTypes.func,
  imgPath: PropTypes.string,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool,
};

UplaodImage.defaultProps = {
  onUpload: () => {},
  setImgPath: () => {},
  imgPath: "",
  disabled: false,
  isMobile: false,
};

export default UplaodImage;
