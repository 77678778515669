import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ListNotifications from "coreComponents/ListNotifications";
import {
  TYPE_SIGNUP,
  TYPE_FORGOT_PWD,
  TYPE_SIGNIN,
  TYPE_CONFIRM_TFA,
  TYPE_VERIFY_MAIL,
  TYPE_CONFIRM_PWD,
} from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import Register from "app/liveShopping/backoffice/otm/components/Connect/Register/Register";
import Verification from "app/liveShopping/backoffice/otm/components/Connect/Register/Verification";
import SignIn from "app/liveShopping/backoffice/otm/components/Connect/SignIn/SignIn";
import DoubleAuthentication from "app/liveShopping/backoffice/otm/components/Connect/DoubleAuthentication/DoubleAuthentication";
import ForgotPassword from "app/liveShopping/backoffice/otm/components/Connect/ForgotPassword/ForgotPassword";
import ConfirmPassword from "app/liveShopping/backoffice/otm/components/Connect/ConfirmPassword";

const useStyles = makeStyles((theme) => ({
  connectRoot: {
    width: "100%",
    height: "100%",
    display: "flex",
    boxSizing: "border-box",
  },
  notifications: {
    position: "fixed",
    right: "50%",
    left: "50%",
    marginRight: "auto",
    top: "30px",
    marginLeft: "auto",
    //for connect
    zIndex: theme.zIndex.modal + 1,
  },
}));

const ConnectScreenType = ({
  connection,
  handleSetType,
  isModal,
  onClose,
  noEvent,
  forFirewall,
}) => {
  const { type, email, value, password, title, callback } = connection;
  const classes = useStyles();
  let result;
  switch (type) {
    case TYPE_FORGOT_PWD:
      result = <ForgotPassword email={email} handleSetType={handleSetType} />;
      break;
    case TYPE_CONFIRM_PWD:
      result = (
        <ConfirmPassword
          handleSetType={handleSetType}
          token={connection?.pwdToken}
        />
      );
      break;
    case TYPE_CONFIRM_TFA:
      result = (
        <DoubleAuthentication handleSetType={handleSetType} token={value} />
      );
      break;
    case TYPE_SIGNUP:
      result = (
        <Register
          handleSetType={handleSetType}
          title={title}
          email={email}
          password={password}
          callback={callback}
          isModal={isModal}
          onClose={onClose}
          noEvent={noEvent}
          forFirewall={forFirewall}
        />
      );
      break;
    case TYPE_SIGNIN:
    default:
      result = (
        <SignIn
          handleSetType={handleSetType}
          title={title}
          email={email}
          password={password}
          callback={callback}
          isModal={isModal}
          onClose={onClose}
          noEvent={noEvent}
          forFirewall={forFirewall}
        />
      );
  }
  return (
    <Box className={classes.connectRoot}>
      <Box className={classes.notifications}>
        <ListNotifications />
      </Box>
      {result}
    </Box>
  );
};

ConnectScreenType.propTypes = {
  connection: PropTypes.object,
  handleSetType: PropTypes.func,
  isModal: PropTypes.bool,
  onClose: PropTypes.func,
  noEvent: PropTypes.bool,
  forFirewall: PropTypes.bool,
};

ConnectScreenType.defaultProps = {
  handleSetType: () => { },
  onClose: () => { },
  noEvent: true,
  forFirewall: false,
};

export default ConnectScreenType;