import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import CustomChevronDown from "liveShopping/assets/icons/CustomChevronDown";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { _t } from "utilities/i18n";
const useStyles = makeStyles((theme) => ({
  select: {
    background: "#FFFFFF",
    border: "1px solid #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "8px",
    height: props => props.isMobile ? "56px" : "40px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    width: "150px",
    WebkitAppearance: "none",
    appearance: "none",
    "&:-ms-expand": {
      display: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:selection": {
      backgroundColor: "#000",
    },
  },
  chevronDown: {
    float: "right",
    marginTop: props => props.isMobile ? "-35px" : "-27px",
    marginRight: "10px",
    pointerEvents: "none",
    backgroundColor: "transparent",
    color: "black",
    paddingRight: "5px",
  },
}));

const Dropdown = ({ name, value, placeholder, options, onChange, defaultValue, defaultTitle }) => {
  const isMobile = useSelector(mobileLayoutSelector)
  const classes = useStyles({ isMobile });

  return (
    <Box>
      <select className={classes.select} name={name} onChange={onChange} value={value} placeholder={placeholder} defaultValue={defaultValue}>
        <option value={"default"} disabled>
          {defaultTitle}
        </option>
        {options?.map((item) => (
          <option key={item.hash} value={item.hash}>
            {item.name}
          </option>
        ))}
      </select>
      <CustomChevronDown size={18} className={classes.chevronDown} />
    </Box>
  );
};

Dropdown.propTypes = {};

export default Dropdown;
