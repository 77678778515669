import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import Input from "../../reusables/Input";
import CustomChevronUp from "app/liveShopping/assets/icons/CustomChevronUp";
import CustomChevronDown from "app/liveShopping/assets/icons/CustomChevronDown";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import { useSelector } from "react-redux";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { ensureFloat } from "app/liveShopping/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "8px",
  },
  typoDelete: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "100%",
  },
  typoVariant: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
}));

const Variant = ({ variant, onUpdate, onDelete, index, isMonoVariant }) => {
  const classes = useStyles();
  const [confirm, setConfirm] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const wiz = useSelector(currentWizSelector);
  const theme = useTheme();


  const onChange = ({ currentTarget }) => {
    let value = currentTarget.value;

    variant[currentTarget.name] = value;
    onUpdate(variant);
  };

  return (
    <>
      {!isMonoVariant && (
        <Box style={{ display: "flex", justifyContent: "space-between", padding: "10px" }} onClick={() => setIsExpanded(!isExpanded)}>
          <Typography className={classes.typoVariant}>Variant {index + 1}</Typography>
          {isExpanded ? (
            <CustomChevronUp style={{ cursor: "pointer" }} color={theme.palette.colors.grey500} />
          ) : (
            <CustomChevronDown style={{ cursor: "pointer" }} color={theme.palette.colors.grey500} />
          )}
        </Box>
      )}

      {isExpanded && (
        <>
          {!isMonoVariant && (
            <Box style={{ margin: "16px 20px" }}>
              <label className={classes.label} htmlFor="name">
                {_t("Name of the variant")}
              </label>
              <Input name={"name"} value={variant?.name} onChange={onChange} placeholder={_t("Ex : Color, Size")} />
            </Box>
          )}

          <Box style={{ display: "flex", margin: "16px 20px", justifyContent: "space-between" }}>
            <Box>
              <label className={classes.label} htmlFor="price">
                {_t("Price")}
              </label>
              <Input name={"price"} value={variant?.price} onChange={onChange} placeholder={"0,00"} />
            </Box>
            <Box>
              <label className={classes.label} htmlFor="previous_price">
                {_t("Previous Price")}
              </label>
              <Input name={"previous_price"} value={variant?.previous_price} onChange={onChange} placeholder={"0,00"} />
            </Box>
          </Box>

          {(!wiz.cart_integration || wiz.cart_integration && wiz.oto_integration_id) && (
            <Box style={{ margin: "16px 20px" }}>
              <label className={classes.label} htmlFor="product_url">
                {_t("Product URL")}
              </label>
              <Input name={"product_url"} value={variant?.product_url} onChange={onChange} placeholder={"Product URL"} />
            </Box>
          )}

          <Box style={{ margin: "16px 20px" }}>
            <label className={classes.label} htmlFor="picture_url">
              {_t("Picture URL")}
            </label>
            <Input name={"picture_url"} value={variant?.picture_url} onChange={onChange} placeholder={"Picture URL"} />
          </Box>
          {wiz.cart_integration && (
            <Box style={{ margin: "16px 20px" }}>
              <label className={classes.label} htmlFor="vendor_id">
                {_t("e-commerce ID")}
              </label>
              <Input name={"vendor_id"} value={variant?.vendor_id} onChange={onChange} placeholder={"Add the e-commerce ID here"} />
            </Box>
          )}

          <Box
            sx={{ marginLeft: "10px", marginTop: "20px", cursor: "pointer" }}
            onClick={() => {
              if (!confirm) {
                setConfirm(true);
                setTimeout(() => setConfirm(false), 1500);
                return;
              }

              onDelete(variant);
            }}
          >
            <Box style={{ display: "flex", justifyContent: "center", color: theme.palette.colors.error, marginBottom: "24px" }}>
              {confirm ? (
                <Typography className={classes.typoDelete}>{_t("Are you sure?")}</Typography>
              ) : (
                <Box style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <Trash size={16} /> <Typography className={classes.typoDelete}>{_t("Delete")}</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

Variant.propTypes = {};

Variant.defaultProps = {
  isMonoVariant: false,
};

export default Variant;
