import React from "react";

import { DEBUG_MODE } from "settings/config";

import isEqual from "lodash/isEqual";
import areResourceEquals from "ressourcesDucks/utils/areEqual";

import ResourceFactory from "orm/resources";

window._renderCount = {
  global: 0,
};

/*
 * Add that to a functional component you'd like to track its rendering.
 * This method logs into console the total rendering count + specific component rendering count
 * ONLY works when DEBUG_MODE set to true
 */
export const renderLog = (componentName) => {
  if (!DEBUG_MODE) return;

  if (!window._renderCount[componentName])
    window._renderCount[componentName] = 0;

  console.log(
    `render ${componentName}`,
    ++window._renderCount.global,
    ++window._renderCount[componentName]
  );
};

/*
* React.memo with custom equal comparaison function
* Use this memoize wrapper to memoize your functionnal components, and re-render them ONLY when needed
* This checks all params given to component, and check for equality (or not) between two updades
* Currently fixes :
*   - our resources objects that seems to be problematic for react surface comparaison (or lodash/isEqual). Here we check only the declared fields
*   - array of our resources objects (eg/ [SurveyItem] from Survey components)
*   - resourceFactory which is every redux rerender a new instance :( (cancellable in option)
*   - inline functions that are considered new each rendering (cancellable in option)
*
* Available options and default configuration : {
  withLogs: false,
  withFunctions: false,
  withResourceFactory: false,
}
*/
const wizMemo = (Component, options = {}) => {
  const log = (...args) =>
    options.withLogs ? console.log(`[${Component.name}]`, ...args) : null;

  const areEqual = (prevProps, nextProps) => {
    log('areEqual', prevProps, nextProps);

    for (let prop in nextProps) {
      // if values seems not equal, dig a bit deeper
      if (prevProps[prop] !== nextProps[prop]) {
        // do not trigger re-rendering for functions (often inlined). Could be changed with option
        if (!options.withFunctions && "function" === typeof prevProps[prop])
          continue;

        // if we want to check bw two objects, check if there are Wisembly resources or not
        if ("object" === typeof prevProps[prop] && prevProps[prop] !== null) {
          // special test for arrays, need to iterate on every value to find contained Wisembly resources inside
          if (
            Array.isArray(prevProps[prop]) &&
            Array.isArray(nextProps[prop])
          ) {
            //if length != elems are different
            if (prevProps[prop].length !== nextProps[prop].length) {
              log(0, prevProps[prop], nextProps[prop]);
              return false;
            }
            for (let i = 0; i < prevProps[prop].length; i++) {
              //most of the time arrays children will be Resources so just check here
              if ("undefined" !== typeof prevProps[prop][i].locator) {
                if (
                  !areResourceEquals(prevProps[prop][i], nextProps[prop][i])
                ) {
                  log(1, prevProps[prop], nextProps[prop]);
                  return false;
                }

                log(">> aurait trigger un re-rendering", prop);
                continue;
              }
              //wondering is this recursive func is necessary. if it takes two big objects
              //those object will be destructured and it will loop on them (cf line 53 )
              //it can consume a lot of memory for very long objects
              //i think it's only necessary for array of arrays
              if (!isEqual(prevProps[prop][i], nextProps[prop][i])) {
                log(2, prevProps[prop][i]);
                return false;
              }
            }

            continue;
          }

          // we have a locator, so this is a Wisembly resource
          if ("undefined" !== typeof prevProps[prop].locator) {
            if (!areResourceEquals(prevProps[prop], nextProps[prop])) {
              log(3, prevProps[prop], nextProps[prop]);
              return false;
            }

            log(">> aurait trigger un re-rendering", prop);
            continue;
          }

          // otherwise, simple javascript object
          if (!isEqual(prevProps[prop], nextProps[prop])) {
            // do not trigger re-render because of ResourceFactory (which changes on every orm change..). Could be changed with option
            if (
              !options.withResourceFactory &&
              prevProps[prop] instanceof ResourceFactory
            ) {
              log(">> ResourceFactory aurait trigger un re-rendering");
              continue;
            }

            log(4, prop, typeof prevProps[prop]);
            return false;
          }

          continue;
        }

        log(5, prop);
        return false;
      }

      continue;
    }

    // console.log('are equal', Component);
    log("no need to re-render", Component.name);
    return true;
  };

  // return React.memo(Component);
  return React.memo(Component, areEqual);
};

export default wizMemo;
