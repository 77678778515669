import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { useDispatch } from "react-redux";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { onPressEnter } from "utilities/utils";
import { useFormik } from "formik";
import SignInPart1 from "app/liveShopping/backoffice/otm/components/Connect/SignIn/SignInPart1";
import SignInPart2 from "app/liveShopping/backoffice/otm/components/Connect/SignIn/SignInPart2";
import emitter from "core/emitter";
import { EVENT_SEND_NOTIFICATION } from "core/emitter/events";
import {
  createSession,
  validate,
  TYPE_SIGNUP,
  TYPE_SIGNIN,
} from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useHistory } from "react-router-dom";
import logo from "app/liveShopping/assets/images/liveboutiquelogo.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "30px",
    lineHeight: "130%",
    textAlign: "center",
  },
  account: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: (props) => (props.forFirewall ? "center" : "flex-start"),
    paddingTop: "8px",
  },
  link: {
    cursor: "pointer",
  },
  logo: {
    textAlign: "center",
    marginBottom: "30px",
  },
  logoText: {
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "130%",
    color: theme.palette.colors.primary,
  },
}),
  {
    name: "SigninComponent",
  }
);

const SignIn = ({
  handleSetType,
  callback,
  isModal,
  onClose,
  redirect,
  noEvent,
  forFirewall,
}) => {
  const classes = useStyles({ isModal, forFirewall });
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errorConnect, serErrorConnect] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();

  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const onSucces = () => {
    if (redirect) {
      history.push(redirect);
    }
    callback && callback();
    onClose();
    window.location.reload();
  };

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    initialErrors: {},
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => validate(values, TYPE_SIGNIN),
    onSubmit: async (values, { setValues, setErrors }) => {
      setErrors({});
      setLoading(true);
      createSession(
        dispatch,
        values.email,
        values.password,
        onSucces,
        callbackSignin
      );
      formik.setSubmitting(false);
      // setValues({ email: values.email, password: "" });
    },
  });

  const callbackSignin = (error) => {
    if (error.length) {
      serErrorConnect(error);
    }
    setLoading(false);
  };

  const callbackMagikLink = () => {
    emitter.emit(EVENT_SEND_NOTIFICATION, {
      type: "success",
      //prettier-ignore
      text: _t("A magic connection link has been sent to the indicated address."),
      button: false,
      duration: 3,
      hideTimer: true,
    });
  };

  const listenerCallback = (e) => onPressEnter(e, () => formik.handleSubmit())

  useEffect(() => {

    if (formik.values.password.length > 1 && !loading) {
      document.addEventListener("keydown", listenerCallback);
    }
    return () => {
      document.removeEventListener("keydown", listenerCallback);
    };
  }, [formik.values.password, loading]);

  return (
    <Box className={classes.root} flexDirection="column">

      <Box className={classes.logo}>
        <img src={logo} width={200} />
      </Box>

      <Typography className={classes.title}>{_t("Log in")}</Typography>

      {step === 1 && (
        <SignInPart1
          {...formik.values}
          errors={formik.errors}
          onChange={formik.handleChange}
          setStep={setStep}
          validate={formik.validateForm}
          isModal={isModal}
          noEvent={noEvent}
          setErrors={formik.setErrors}
        />
      )}

      {step === 2 && (
        <SignInPart2
          {...formik.values}
          errors={formik.errors}
          loading={loading}
          onChange={formik.handleChange}
          onSubmit={formik.handleSubmit}
          handleSetType={handleSetType}
          setStep={setStep}
          errorConnect={errorConnect}
          callbackMagikLink={callbackMagikLink}
          noEvent={noEvent}
        />
      )}

      <Box mt={1} className={classes.account}>
        <Typography color={"greyDark"} variant={"body15"}>
          {_t("No account yet ?")}
        </Typography>
        <Box ml={"5px"} />
        <Typography
          className={classes.link}
          color={"primary"}
          variant={"body15"}
          onClick={() => handleSetType(TYPE_SIGNUP)}
        >
          {_t("Create an account")}
        </Typography>
      </Box>

    </Box>
  );
};

SignIn.propTypes = {
  handleSetType: PropTypes.func,
  callback: PropTypes.func,
  isModal: PropTypes.bool,
  onClose: PropTypes.func,
  redirect: PropTypes.string,
  noEvent: PropTypes.bool,
  forFirewall: PropTypes.bool,
};

SignIn.defaultProps = {
  handleSetType: () => { },
  callback: () => { },
  isModal: false,
  redirect: null,
  noEvent: true,
  forFirewall: false,
};

export default SignIn;