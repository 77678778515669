import { call, fork, put, select, take } from "redux-saga/effects";
import { sourcesSelector, publishStateSelector, streamsIdSelector } from "../selectors";
import { CANNOT_PUBLISH } from "../errors";
import { setPublisher, setError, setSource, removeStream, destroyPublisher, mute } from "../actions";
import { eventChannel } from "redux-saga";
import { currentUserSelector } from "app/state/ducks/ressources/selectors";
import basil from "utilities/basil";

export const baseStreamOptions = {
  insertMode: "append",
  width: "100%",
  height: "100%",
  fitMode: "cover",
  // no UI style
  style: {
    buttonDisplayMode: "off",
    audioLevelDisplayMode: "off",
    archiveStatusDisplayMode: "off",
    nameDisplayMode: "off",
  },
  mirror: false,
};

export function createPublisherChannel(publisher) {
  return eventChannel((emit) => {
    const handler = (event) => {
      emit(event);
    };
    const unsubscribe = () => {
      publisher.off("streamDestroyed", handler);
    };
    publisher.on("streamDestroyed", handler);
    return unsubscribe;
  });
}

export function* publisherEvent(event) {
  const { videoStreamId, screenStreamId } = yield select(streamsIdSelector);

  if (event.type === "streamDestroyed") {
    const isOneOfMyStreams = [videoStreamId, screenStreamId].includes(event.stream.id);
    const isScreenPublisher = screenStreamId === event.stream.id;

    if (isOneOfMyStreams)
      yield put(destroyPublisher(isScreenPublisher ? "screen" : "video"));

    yield put(removeStream(event.stream));
  }
}

const init = (containerId, options) =>
  new Promise((resolve) => {
    if (options.audioSource === "default") delete options["audioSource"];
    if (options.videoSource === "default") delete options["videoSource"];
    console.log("[VISIO SAGA] INIT", containerId, document.querySelector(`#${containerId}`), options);

    const publisher = window.OT.initPublisher(containerId, options, (error) => {
      if (error)
        return resolve({
          error: { code: CANNOT_PUBLISH, message: error.message },
        });

      resolve({ publisher });
    });

    window._publisher = publisher;
  });

// Init own stream (audio/video or screen sharing)
// retrieve selected sources before entering, mute status and publish to session
export function* initPublisher({ mode, containerId, opts }) {
  console.log("[VISIO SAGA] INIT PUBLISHER", mode, containerId, opts)
  const sources = yield select(sourcesSelector);
  const publishState = yield select(publishStateSelector);
  const currentUser = yield select(currentUserSelector);


  // visitor stores in localstorage its preferences if its navigates to another page
  if (currentUser.isAnon()) {
    const audioPreference = basil.get("audioPreference");
    const videoPreference = basil.get("videoPreference");

    console.log('basil get', audioPreference, videoPreference)

    if (audioPreference !== null) {
      publishState.publishAudio = audioPreference;
      yield put(mute("audio", audioPreference));
    }

    if (videoPreference !== null) {
      publishState.publishVideo = videoPreference;
      yield put(mute("video", videoPreference));
    }
  }

  if (opts?.mode === "audio") {
    publishState.publishVideo = false;
    sources.videoSource = false;
  }

  const getOptions = () => {
    return {
      ...baseStreamOptions,
      mirror: true,
      // fitMode: user.isAnon() ? "cover" : "contain",
      ...sources, // select audio and video source
      ...publishState, // muted / unmuted audio/video
      ...opts,
    };
  };

  const options = getOptions();
  console.log("OPTIONS", options);

  const { publisher, error } = yield init(containerId, options);

  if (error) {
    yield put(setError(error));
    return;
  }

  const videoSourceId = publisher?.getVideoSource()?.deviceId;
  const audioSourceId = publisher?.getAudioSource()?.deviceId;

  console.log('videoSourceId', videoSourceId, 'audioSourceId', audioSourceId);

  /* This motherfucking code return an id but the real source name is "default" ... WTF ? */
  if (audioSourceId)
    yield put(setSource("audioInput", audioSourceId));

  if (videoSourceId)
    yield put(setSource("videoInput", videoSourceId));

  yield put(setPublisher(mode, publisher));

  const channel = yield call(createPublisherChannel, publisher);

  while (true) {
    const payload = yield take(channel);

    try {
      yield fork(publisherEvent, payload);
    } catch (e) {
      console.log("unable to handle payload for publisherEvent", JSON.parse(payload));
    }
  }
}
