import React, { useState } from "react";
import { _t } from "i18n";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import images from "images";
import basil from "core/basil";
import { DEFAULT_LOGO } from "images";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100%",
    background: "transparent",
  },
  content: {
    paddingTop: theme.spacing(8),
    width: "100%",
  },
  illustration: {
    fontSize: theme.spacing(30),
    [theme.breakpoints.down("md")]: {
      fontSize: theme.spacing(25),
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: theme.spacing(20),
    },
  },
  selectableBox: {
    cursor: "pointer",
    backgroundColor: theme.palette.background.box,
    borderRadius: 10,
    padding: theme.spacing(10),

    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
    },
  },
  divider: {
    [theme.breakpoints.up("sm")]: {
      width: "52px",
      display: "flex",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  wrapper: {
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background: (props) =>
      props.backgroundImage
        ? `url("${props.backgroundImage}") no-repeat`
        : "none",
    backgroundSize: (props) => (props.backgroundImage ? "cover" : "auto"),
    [theme.breakpoints.down("xs")]: {
      overflow: "auto",
    },
  },
  top: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  logo: {
    marginTop: theme.spacing(9),
    marginBottom: theme.spacing(2.75),
    [theme.breakpoints.up("sm")]: {
      maxWidth: theme.spacing(18.75),
      minWidth: theme.spacing(4),
      backgroundColor: (props) =>
        props.logoBackground ? props.logoBackground : "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: theme.spacing(18.75),
      minWidth: theme.spacing(4),
    },
  },
  titleColor: {
    textAlign: "center",
    color: theme.wisemblyColors.darkish,
    fontSize: "30px",
    fontWeight: "bold",
    letterSpacing: "1.46px",
    lineHeight: "1.07",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "1.35",
      letterSpacing: "0.97px",
    },
  },
  choiceLabel: {
    color: theme.palette.text.primary,
  },
  modal: {
    flex: "1 1 0%",
    zIndex: theme.zIndex.appBar,
    width: "100vw",
  },
  selectableContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginBottom: theme.spacing(5),
    },
  },
}));
const ModalHybrid = ({ onUserChoice, wiz }) => {
  //present or remote
  const choice = basil.get("u.hybridModeChoice");
  const logo = wiz && wiz.logo_paths && wiz.logo_paths.desktop;
  const [open, setOpen] = useState(isEmpty(choice));
  const [hybridModeChoice, setHybridModeChoice] = useState(choice);

  const classes = useStyles({
    backgroundImage: wiz && wiz.theme.background_image,
    logoBackground: wiz && wiz.theme && wiz.theme.logo_background,
  });
  const handleUserChoice = (value) => {
    setHybridModeChoice(value);
    setOpen(false);
    basil.set("u.hybridModeChoice", value);
    onUserChoice(value);
  };

  return (
    <Box display={open ? "flex" : "none"} className={classes.modal}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        className={clsx("LandingHybridModal", classes.root)}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
          className={clsx("ModalHybridWrapper", classes.wrapper)}
        >
          <Box className={classes.top}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                className={classes.logo}
                component="img"
                src={logo ? logo : DEFAULT_LOGO}
                alt={"wizlogo"}
              />
            </Box>
            <Box
              display="flex"
              className={clsx("EmptyStateTitle", classes.title)}
            >
              <Typography className={classes.titleColor}>
                {wiz.title}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            flexWrap="wrap"
            className={classes.selectableContainer}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => handleUserChoice("present")}
              className={classes.selectableBox}
            >
              <Box display="flex">
                <images.Animez className={classes.illustration} />
              </Box>
              <Box display="flex" mt={2}>
                <Typography
                  variant="h3"
                  className={clsx(classes.titleColor, classes.choiceLabel)}
                >
                  {_t("I'm on site")}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.divider}></Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              onClick={() => handleUserChoice("remote")}
              className={classes.selectableBox}
            >
              <Box display="flex">
                <images.Animez className={classes.illustration} />
              </Box>
              <Box display="flex" mt={2}>
                <Typography
                  variant="h3"
                  className={clsx(classes.titleColor, classes.choiceLabel)}
                >
                  {_t("I'm in remote")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ModalHybrid.propTypes = {
  onUserChoice: PropTypes.func,
  wiz: PropTypes.object,
};

export default ModalHybrid;
