import { getUserSession } from "core/session";
import { put } from "redux-saga/effects";

import { API_BASE } from "settings/config";
import makeCallAndUpdate from "./makeCallAndUpdate";

import { logOut } from "applicationDucks/actions";
import { REFRESH_SESSION } from "applicationDucks/actionsTypes";

export const refreshToken = (dispatch) => (force = false) => {
  const session = getUserSession();

  if (!force && !session.shouldBeRefreshed()) {
    return;
  }

  return new Promise((resolve) => {
    dispatch({
      type: REFRESH_SESSION,
      options: {
        callback: () => {
          resolve();
        },
      },
    });
  });
};

export default function* refreshSession(opts = {}) {
  const session = getUserSession();
  const { id, refresh_token, token } = session;

  const additionnalOpts = opts.options ? opts.options : {};

  try {
    const url = API_BASE.clone().segment("authentication").toString();
    const options = {
      ...additionnalOpts,
      silent: true,
      method: "PATCH",
      headers: { "Wisembly-Token": token },
    };

    yield makeCallAndUpdate(url, session, options, refresh_token);

    // If this call fails for whatever reason just clear the session and redirect to homepage
  } catch (error) {
    yield put(logOut(id));
  }
}
