import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class Signature extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Signature;
  }

  setEvent(Event) {
    this.event = Event.id;
  }

  setUser(User) {
    this.user = User.id;
  }
  setSignatureRequest(SignatureRequest) {
    this.signatureRequest = SignatureRequest.id;
  }
}

Signature.modelName = "Signature";
Signature.backendName = "signature";

//ressource to create and send cust
Signature.fields = {
  id: attr(),
  hash: attr(),
  type: attr(),
  created_at: attr(),
  validated_at: attr(),
  extra_data: attr(),
  signature: attr(),
  event: fk("Event", "signatures"),
  user: fk("User", "signatures"),
  signatureRequest: fk("SignatureRequest", "signatures"),
};

export default Signature;
