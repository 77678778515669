import { call, all, put, select } from "redux-saga/effects";
import { forceArray } from "utilities/utils";
import { surveyAnswersSelector } from "ressourcesDucks/selectors";
import { unsetResource } from "ressourcesDucks/actions";

//saga that check if a survey with 0 answers_count has some answer in the orm
//and clean thoses answers
export function* handleSurveyReset({ resources, included }) {
  resources = forceArray(resources);

  // let's map data AND included resources
  resources = [...resources, ...included];

  const callEffects = resources.map((resource) => {
    let surveyAnswers;
    let attributes = {
      ...resource.attributes,
    };

    //because i can't use yield in map function i have to return a call
    return call(function* () {
      if (resource.type === "survey" && attributes.answers_count === 0) {
        surveyAnswers = yield select(surveyAnswersSelector, attributes.hash);
        const innerCalls = surveyAnswers.map((answer) => {
          return call(function* () {
            yield put(unsetResource({ id: answer.id, type: "SurveyAnswer" }));
          });
        });
        yield all(innerCalls);
      }
    });
  });
  yield all(callEffects);
}
