import React, { useReducer, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  isSurveyObjectFull,
  createFormSchema,
  initForm,
  getLogicalJumps,
  isItemInLogicalJumps,
  canUserSeeEvaluationAnswer,
} from "appComponents/SurveyForms";
import union from "lodash/union";
import has from "lodash/has";
import min from "lodash/min";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import emitter from "core/emitter";
import { EVENT_SHOW_FORM_ENDING } from "core/emitter/events";
//import { EVENT_SHOW_FORM_INTRO } from "core/emitter/events";
//this context will mainly serve to know the index of the form to show
//the context can have as argument the formikContext(actually no)
//or call this context in the submit button compoentn
const SurveyContext = React.createContext({
  identifier: "",
  index: 0,
  length: null,
  hash: "",
  indexMap: [],
  maxValidIndex: 0,
  panel: "",
});
const SurveyDispatcherContext = React.createContext({
  dispatch: () => {},
});

const surveyContextReducer = (state, action) => {
  switch (action.type) {
    case "JUMP_TO_NEXT_ITEM": {
      const newIndex =
        action.index < state.length && action.index >= 0
          ? action.index
          : state.index;
      const fromIndex = state.index;
      let indexMap = state.indexMap;
      indexMap[newIndex].fromIndex = fromIndex;

      return {
        ...state,
        index: newIndex,
        maxValidIndex: state.panel === "form" ? newIndex : state.maxValidIndex,
        evaluationNote: state.panel === "form" ? state.evaluationNote : false,
        indexMap,
      };
    }
    case "NEXT_ITEM": {
      let newIndex =
        state.index + 1 < state.length ? state.index + 1 : state.index;
      const fromIndex = state.index;
      let indexMap = state.indexMap;

      if (state.panel === "form") {
        indexMap[newIndex].fromIndex = fromIndex;
      }
      if (state.panel === "userResponse" && !isEmpty(state.responseIndexMap)) {
        newIndex =
          state.index + 1 < state.responseIndexMap.length
            ? state.index + 1
            : state.responseIndexMap.length - 1;
      }

      return {
        ...state,
        index: newIndex,
        maxValidIndex: state.panel === "form" ? newIndex : state.maxValidIndex,
        evaluationNote: state.panel === "form" ? state.evaluationNote : false,
        indexMap,
      };
    }

    case "PREVIOUS_ITEM": {
      let more = {};
      let index = state.index - 1 >= 0 ? state.index - 1 : 0;
      if (state.index === -1) index = -1;
      if (
        state.canSeeEvaluationNote &&
        state.index === 0 &&
        state.panel == "userResponse"
      )
        index = -1;

      if (
        state.index === 0 &&
        state.panel === "form" &&
        (state.surveyType === "evaluation" || state.surveyType === "form") &&
        state.isAnswerableForm === true
      ) {
        //emitter.emit(EVENT_SHOW_FORM_INTRO, state.hash);
        more = {
          showIntro: true,
          alreadyShowIntro: true,
        };
      }

      let indexMap = state.indexMap;
      if (state.panel === "form" && has(indexMap[state.index], "fromIndex")) {
        //if (state.index !== indexMap[state.index].fromIndex) {
        index = indexMap[state.index].fromIndex;
        //  }
      }

      return {
        ...state,
        index: index,
        maxValidIndex: state.panel === "form" ? index : state.maxValidIndex,
        ...more,
      };
    }

    case "SET_PANEL": {
      return {
        ...state,
        panel: action.panel,
        index:
          action.panel === "form"
            ? state.maxValidIndex
            : action.panel === "userResponse" && state.canSeeEvaluationNote
            ? -1
            : min([state.index, 0]),
      };
    }
    case "SET_USER_RESPONSE_MAP": {
      return {
        ...state,
        responseIndexMap: action.responseIndexMap,
      };
    }
    case "SHOW_INTRO": {
      if (action.survey === state.hash) {
        return {
          ...state,
          showIntro: true,
          alreadyShowIntro: true,
          panel: "form",
        };
      }
      return state;
    }
    case "DISSMISS_INTRO": {
      if (action.survey === state.hash) {
        return {
          ...state,
          showIntro: false,
        };
      }
      return state;
    }
    case "SHOW_FORM_ENDING": {
      if (action.survey === state.hash) {
        return {
          ...state,
          showFormConclusion: true,
          maxValidIndex: 0,
          index: 0,
        };
      }
      return state;
    }
    case "DISSMISS_FORM_ENDING": {
      if (action.survey === state.hash) {
        return {
          ...state,
          showFormConclusion: false,
        };
      }
      return state;
    }
    case "SET_CAN_SEE_EVALUATION_NOTE": {
      return {
        ...state,
        canSeeEvaluationNote: true,
      };
    }
    case "SHOW_EVALUATION_NOTE": {
      //const index = state.index - 1 >= 0 ? state.index - 1 : 0;
      if (action.survey === state.hash) {
        const index = -1;
        return {
          ...state,
          index,
          evaluationNote: true,
          panel: "userResponse",
          canSeeEvaluationNote: true,
          //maxValidIndex: state.panel === "form" ? index : state.maxValidIndex,
        };
      }
      return state;
    }
    case "UPDATE": {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case "INIT": {
      let more = {};
      if (action.surveyType !== "form" && action.surveyType !== "evaluation") {
        more = {
          showIntro: false,
        };
      }
      return {
        ...state,
        hash: action.hash,
        index: action.index,
        length: action.length,
        indexMap: action.indexMap,
        surveyType: action.surveyType,
        panel: action.panel,
        maxValidIndex: action.maxValidIndex,
        multipleAnswers: action.multipleAnswers,
        name: action.name,
        surveyIsFull: action.surveyIsFull,
        evaluationNote: false,
        canSeeEvaluationNote: action.canSeeEvaluationNote,
        hasLogicalJumps: action.hasLogicalJumps,
        formSchema: action.formSchema,
        alreadyShowIntro: action.alreadyShowIntro,
        responseIndexMap: action.responseIndexMap,
        isAnswerableForm: action.isAnswerableForm,
        section: action.section,
        displaySubmit: action.displaySubmit,
        notifyCanNotSeeAnswer: action.notifyCanNotSeeAnswer,
        isEmptyAnswers: action.isEmptyAnswers,
        preview: action.preview,
        ...more,
      };
    }
    default:
      return state;
  }
};

const SurveyContextProvider = ({
  children,
  survey,
  panel,
  onlyForResult,
  isAnswerableForm,
  section,
  displaySubmit,
  isEmptyAnswers,
  preview,
}) => {
  const [surveyContextState, dispatch] = useReducer(surveyContextReducer, {
    index: 0,
    hash: "",
    maxValidIndex: 0,
  });

  useEffect(() => {
    if (displaySubmit !== surveyContextState.displaySubmit) {
      dispatch({
        type: "UPDATE",
        payload: { key: "displaySubmit", value: displaySubmit },
      });
    }
    if (isEmptyAnswers !== surveyContextState.isEmptyAnswers) {
      const notifyCanNotSeeAnswer =
        !isEmptyAnswers && !canUserSeeEvaluationAnswer(survey);
      dispatch({
        type: "UPDATE",
        payload: { key: "isEmptyAnswers", value: isEmptyAnswers },
      });
      dispatch({
        type: "UPDATE",
        payload: { key: "notifyCanNotSeeAnswer", value: notifyCanNotSeeAnswer },
      });
    }
    if (isAnswerableForm !== surveyContextState.isAnswerableForm) {
      dispatch({
        type: "UPDATE",
        payload: { key: "isAnswerableForm", value: isAnswerableForm },
      });
    }
  }, [
    displaySubmit,
    isEmptyAnswers,
    surveyContextState.displaySubmit,
    surveyContextState.isEmptyAnswers,
    surveyContextState.isAnswerableForm,
    isAnswerableForm,
  ]);
  useEffect(() => {
    if (survey && survey.hash !== surveyContextState.hash) {
      const items = survey.items.toModelArray();
      let itemsInLogicalJumps = [];
      items.forEach((item) => {
        itemsInLogicalJumps = union(
          itemsInLogicalJumps,
          getLogicalJumps(item.ref)
        );
      });
      const [_formData, formSchema] = initForm(createFormSchema(items));
      const notifyCanNotSeeAnswer =
        !isEmptyAnswers && !canUserSeeEvaluationAnswer(survey);
      dispatch({
        type: "INIT",
        index: 0,
        indexMap: items.map((item, i) => ({
          index: i,
          hash: item.hash,
          inLogicalJump: isItemInLogicalJumps(item.ref, itemsInLogicalJumps),
        })),
        hash: survey.hash,
        surveyType: survey.type,
        multipleAnswers: survey.multiple_answers,
        length: items.length,
        panel: panel,
        maxValidIndex: 0,
        name: survey.name,
        alreadyShowIntro: false,
        canSeeEvaluationNote:
          canUserSeeEvaluationAnswer(survey) && survey.type === "evaluation",
        surveyIsFull: isSurveyObjectFull(survey),
        hasLogicalJumps: itemsInLogicalJumps.length > 0,
        responseIndexMap: [],
        formSchema,
        isAnswerableForm,
        section,
        displaySubmit,
        notifyCanNotSeeAnswer,
        isEmptyAnswers,
        preview,
      });
      if (
        (survey.type === "evaluation" || survey.type === "form") &&
        isAnswerableForm
      ) {
        dispatch({
          type: "SHOW_INTRO",
          survey: survey.hash,
        });
      }

      if (canUserSeeEvaluationAnswer(survey) && onlyForResult) {
        if (survey && survey.type === "evaluation") {
          dispatch({
            type: "SHOW_EVALUATION_NOTE",
            survey: survey.hash,
          });
        }
      }
    }
  }, [survey, surveyContextState.hash, panel, isAnswerableForm, section]);
  useEffect(() => {
    if (
      survey &&
      !surveyContextState.surveyIsFull &&
      isSurveyObjectFull(survey) &&
      survey.hash === surveyContextState.hash
    ) {
      const items = survey.items.toModelArray();
      //maybe i can remove this long term
      let itemsInLogicalJumps = [];
      items.forEach((item) => {
        itemsInLogicalJumps = union(
          itemsInLogicalJumps,
          getLogicalJumps(item.ref)
        );
      });
      const [_formData, formSchema] = initForm(createFormSchema(items));
      dispatch({
        type: "INIT",
        index: 0,
        indexMap: items.map((item, i) => ({
          index: i,
          hash: item.hash,
          inLogicalJump: isItemInLogicalJumps(item.ref, itemsInLogicalJumps),
        })),
        hash: survey.hash,
        surveyType: survey.type,
        multipleAnswers: survey.multiple_answers,
        length: items.length,
        panel: panel,
        maxValidIndex: 0,
        name: survey.name,
        surveyIsFull: isSurveyObjectFull(survey),
        hasLogicalJumps: itemsInLogicalJumps.length > 0,
        canSeeEvaluationNote:
          canUserSeeEvaluationAnswer(survey) && survey.type === "evaluation",
        formSchema,
        showIntro: false,
        alreadyShowIntro: surveyContextState.showIntro,
        isAnswerableForm,
        section,
        preview,
      });

      if (
        (survey.type === "evaluation" ||
          (survey.type === "form" && !surveyContextState.alreadyShowIntro)) &&
        isAnswerableForm
      ) {
        dispatch({
          type: "SHOW_INTRO",
          survey: survey.hash,
        });
      }
      if (canUserSeeEvaluationAnswer(survey) && onlyForResult) {
        if (survey && survey.type === "evaluation") {
          dispatch({
            type: "SHOW_EVALUATION_NOTE",
            survey: survey.hash,
          });
        }
      }
    }
  }, [
    survey,
    survey.hash,
    surveyContextState.surveyIsFull,
    surveyContextState.hash,
    panel,
    surveyContextState.alreadyShowIntro,
    surveyContextState.showIntro,
    isAnswerableForm,
    section,
  ]);

  useEffect(() => {
    const callback = (surveyHash) => {
      if (
        surveyContextState.hash === surveyHash &&
        !surveyContextState.showFormConclusion
      ) {
        if (surveyContextState.panel === "form") {
          dispatch({
            type: "SET_PANEL",
            panel: "userResponse",
          });
        }

        dispatch({
          type: "SHOW_FORM_ENDING",
          survey: surveyContextState.hash,
        });
      }
    };
    emitter.on(EVENT_SHOW_FORM_ENDING, (surveyHash) => callback(surveyHash));
    return () => emitter.removeListener(EVENT_SHOW_FORM_ENDING, callback);
  }, [surveyContextState.hash, surveyContextState.showFormConclusion]);

  return (
    <SurveyDispatcherContext.Provider value={dispatch}>
      <SurveyContext.Provider value={surveyContextState}>
        {children}
      </SurveyContext.Provider>
    </SurveyDispatcherContext.Provider>
  );
};

SurveyContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  surveyHash: PropTypes.string,
  survey: PropTypes.object,
  panel: PropTypes.string,
  onlyForResult: PropTypes.bool,
  isAnswerableForm: PropTypes.bool,
  section: PropTypes.string,
  displaySubmit: PropTypes.bool,
  isEmptyAnswers: PropTypes.bool,
  preview: PropTypes.bool,
};
SurveyContextProvider.defaultProps = {
  onlyForResult: false,
  preview: false,
};
const useSurveyContextState = () => {
  const surveyContext = useContext(SurveyContext);
  if (surveyContext === undefined) {
    throw new Error(
      " useSurveyContextState must be used within a SurveyContextProvider"
    );
  }
  return surveyContext;
};
const useSurveyContextDispatcher = () => {
  const surveyContextDispatcher = useContext(SurveyDispatcherContext);
  if (surveyContextDispatcher === undefined) {
    throw new Error(
      " surveyContextDispatcher must be used within a SurveyDispatcherContext"
    );
  }
  return surveyContextDispatcher;
};

export {
  useSurveyContextState,
  SurveyContextProvider,
  surveyContextReducer,
  useSurveyContextDispatcher,
};
