import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";

import PlayArrow from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import ReplayIcon from "@material-ui/icons/Replay";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import ActionButton from "appComponents/Mui/ActionButton";

const useStyles = makeStyles((theme) => ({
  iconAction: {
    fontSize: "30px",
    color: theme.wisemblyColors.white,
  },

  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "45px",
    height: "45px",
    cursor: "pointer",
  },
  round: {
    borderRadius: "50%",
  },
  playBox: {
    backgroundColor: theme.palette.secondary.main,
  },
  stopBox: {
    backgroundColor: theme.wisemblyColors.pastelRed,
  },
  replayBox: {
    backgroundColor: theme.palette.background.secondaryBox,
    flexDirection: "row",
    "& $iconAction": {
      color: theme.palette.secondary,
    },
  },
  moreBox: {
    marginLeft: "10px",
    backgroundColor: theme.palette.background.secondaryBox,
    borderRadius: "4px",
  },
}));

const IconAction = ({ action, onClick }) => {
  const classes = useStyles();

  switch (action) {
    case "play":
      return (
        <ActionButton
          icon={<PlayArrow className={classes.iconAction} />}
          onClick={onClick}
          toogleColor={false}
          inactiveColor="primary"
          isIconButton={true}
          rounded="full"
          controlled="true"
        />
      );

    case "stop":
      return (
        <ActionButton
          icon={<StopIcon className={classes.iconAction} />}
          onClick={onClick}
          toogleColor={false}
          inactiveColor="alert"
          isIconButton={true}
          rounded="full"
          controlled="true"
        />
      );

    case "relaunch":
      return (
        <ActionButton
          icon={<ReplayIcon className={classes.iconAction} />}
          onClick={onClick}
          toogleColor={false}
          inactiveColor="secondary"
          isIconButton={true}
          rounded="full"
          controlled={true}
        />
      );

    case "more":
      return (
        <ActionButton
          icon={<MoreHorizOutlinedIcon className={classes.iconSettings} />}
          onClick={onClick}
          toogleColor={false}
          inactiveColor="secondary"
          isIconButton={true}
          controlled={true}
          squared={true}
          size="xLarge"
          ml={10}
        />
      );
    case "moreVert":
      return (
        <ActionButton
          icon={<MoreVertOutlinedIcon className={classes.iconSettings} />}
          onClick={onClick}
          toogleColor={false}
          inactiveColor="secondary"
          isIconButton={true}
          controlled={true}
          squared={true}
          size="xLarge"
          ml={10}
        />
      );

    case "settings":
      return (
        <ActionButton
          icon={<SettingsIcon className={classes.iconSettings} />}
          onClick={onClick}
          toogleColor={false}
          inactiveColor="secondary"
          isIconButton={true}
          controlled={true}
          squared={true}
          size="xLarge"
          ml={10}
        />
      );

    default:
      return null;
  }
};

IconAction.defaultProps = {
  onClick: () => {},
};

IconAction.propTypes = {
  action: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconAction;
