import React from "react";
import PropTypes from "prop-types";

const CustomCartIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7368 10.7392V11.0096H28.9952V26.5144C28.9952 27.4389 28.628 28.3254 27.9743 28.9791C27.3206 29.6328 26.434 30 25.5096 30H10.4856C9.56114 30 8.67457 29.6328 8.0209 28.9791C7.36723 28.3254 7 27.4389 7 26.5144V11.0096H12.2584V10.7392C12.2584 9.21706 12.8631 7.75727 13.9394 6.68097C15.0157 5.60466 16.4755 5 17.9976 5C19.5197 5 20.9795 5.60466 22.0558 6.68097C23.1321 7.75727 23.7368 9.21706 23.7368 10.7392ZM17.9976 7.46394C18.8662 7.46394 19.6993 7.80901 20.3135 8.42324C20.9278 9.03746 21.2728 9.87053 21.2728 10.7392V11.0096H14.7224V10.7392C14.7224 9.87053 15.0674 9.03746 15.6817 8.42324C16.2959 7.80901 17.1289 7.46394 17.9976 7.46394ZM26.5312 26.5144V13.4736H9.46394V26.5144C9.46394 26.7854 9.57158 27.0452 9.76317 27.2368C9.95477 27.4284 10.2146 27.5361 10.4856 27.5361H25.5096C25.7806 27.5361 26.0404 27.4284 26.232 27.2368C26.4236 27.0452 26.5312 26.7854 26.5312 26.5144Z"
      />
    </svg>
  );
};

CustomCartIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomCartIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomCartIcon;
