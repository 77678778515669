import React from "react";
import { createTheme } from "@material-ui/core";

import themeData from "./theme.json";
import { hexToRGB } from "utilities/utils";
import { isLight } from "utilities/utils";

const themeName = "Liveboutique OTO theme";

const defaultPalette = (primaryBackgroundColor) => {

  return {
    primary: primaryBackgroundColor || "#1E2023",
    secondary: "#FFC800",
    sun: "#FFC800",
    success: "#2DBE6E",
    alert: "#FF4646",
    error: "#F0464B",
    warning: "#FA962D",
    black: "#0A0A0F",
    blackMedium: "#1E2023",
    blackLight: "#323437",
    greyDark: "#808284",
    grey: "#B4B6B9",
    grey100: "#F7F7F9",
    grey200: "#F0F0F4",
    grey400: "#B4B6B9",
    grey500: "#808284",
    grey600: "#323437",
    grey700: "#1E2023",
    greyMedium: "#E2E4E8",
    greyLight: "#F0F0F4",
    white: "#FFFFFF",
    primary10: "rgba(0, 150, 150, 0.1)",
    success10: "rgba(45, 190, 110, 0.1)",
    error10: "rgba(240, 70, 75, 0.1)",
    warning10: "rgba(250, 150, 45, 0.1)",
    black50: "rgba(0, 0, 0, 0.5)",
    black15: "rgba(0, 0, 0, 0.15)",
    dark10: "rgba(0, 0, 0, 0.1)",
    dark05: "rgba(0, 0, 0, 0.05)",
    dark50: "rgba(0, 0, 0, 0.5)",
    dark75: "rgba(0, 0, 0, 0.75)",
    white: "#FFF",
    white10: "rgba(255, 255, 255, 0.1)",
    white15: "rgba(255, 255, 255, 0.15)",
    white50: "rgba(255, 255, 255, 0.5)",
    border: "#000000",
    secondaryFocus: "#07698D",
    tags: {
      colors: {
        red: "#F0464B",
        orange: "#FA962D",
        yellow: "#FFC800",
        green: "#2DBE6E",
        blue: "#5FD2FA",
        purple: "#9155E6",
      },
      background: {
        red10: "rgba(240, 70, 75, 0.1)",
        orange10: "rgba(250, 150, 45, 0.1)",
        yellow10: "rgba(255, 200, 0, 0.1)",
        green10: "rgba(45, 190, 110, 0.1)",
        blue10: "rgba(95, 210, 250, 0.1)",
        purple10: "rgba(145, 85, 230, 0.1)",
      },
    },
  };
};

const buttonsColor = {
  primary: "primary",
  alert: "error",
  secondary: "greyLight",
  tertiary: {
    default: "white10",
    hovered: "white15",
    disabled: "white10",
  },
  link: "primary",
};

const iconButtonsColor = {
  primary: "primary",
  alert: "error",
  secondary: "greyLight",
  tertiary: {
    default: "white10",
    hovered: "white15",
    active: "white",
    disabled: "white10",
  },
  ghost: { hovered: "black15" },
};

export const otoTheme = (options) => {
  const { primaryBackgroundColor } = options;

  const palette = { ...defaultPalette(primaryBackgroundColor), ...{} };

  let buttons = {};
  let iconButtons = {};
  Object.keys(buttonsColor).forEach((name) => {
    const color = buttonsColor[name];
    const defaultcolor = typeof color === "string" ? color : color.default;
    const disabled = typeof color === "string" ? color : color.disabled;
    const hovered = typeof color === "string" ? color : color.hovered;

    buttons = {
      ...buttons,
      [name]: {
        background: {
          default: palette[defaultcolor],
          disabled: palette[disabled],
          hovered: palette[hovered],
        },
        color: isLight(palette[defaultcolor]) ? palette.blackMedium : "#FFFFFF",
        opacity: {
          disabled: 0.5,
        },
      },
    };
  });

  Object.keys(iconButtonsColor).forEach((name) => {
    const color = iconButtonsColor[name];
    const defaultcolor = typeof color === "string" ? color : color?.default;
    const disabled = typeof color === "string" ? color : color?.disabled;
    const hovered = typeof color === "string" ? color : color?.hovered;
    const active = typeof color === "string" ? "" : color?.active;

    iconButtons = {
      ...iconButtons,
      [name]: {
        background: {
          default: defaultcolor && palette[defaultcolor],
          hovered: hovered && palette[hovered],
          disabled: disabled && palette[disabled],
          ...(active !== "" ? { active: palette[active] } : {}),
        },
        color: defaultcolor && isLight(palette[defaultcolor]) ? palette.blackMedium : "#FFFFFF",
        opacity: {
          disabled: 0.5,
        },
      },
    };
  });

  return createTheme({
    ...themeData,
    themeName,
    typography: {
      fontFamily: [
        "-apple-system", // MacOs
        "BlinkMacSystemFont", // MacOs
        "'Segoe UI'", // Windows
        "Roboto", // Android
        "Helvetica",
        "Arial",
        "sans-serif",
        "'Apple Color Emoji'", // Emoji
        "'Segoe UI Emoji'", // Emoji
        "'Segoe UI Symbol'", // Emoji
      ].join(", "),
    },

    palette: {
      colors: palette,
      type: "light",
      shadow: {
        light: "0px 3px 5px rgba(0, 0, 0, 0.03)",
        medium: "0px 3px 5px rgba(0, 0, 0, 0.03)",
        hard: "0px 3px 5px rgba(0, 0, 0, 0.03)",
        dropdown: "0px 3px 15px rgba(0, 0, 0, 0.08)",
        tooltip: "0px 3px 15px rgba(0, 0, 0, 0.15)",
      },
      error: {
        main: palette.alert,
      },
      button: buttons,
      iconButton: iconButtons,
      border: {
        default: hexToRGB(palette.border, "0.1"),
        divider: "#FFFFFF",
        textField: "#CED4DA",
        default20: hexToRGB(palette.border, "0.2"),
        default30: hexToRGB(palette.border, "0.3"),
        default40: hexToRGB(palette.border, "0.4"),
        default50: hexToRGB(palette.border, "0.5"),
      },
      background: {
        default: palette.surface1,
        box: palette.surface2,
        dark50: "rgba(0, 0, 0, 0.5)",
        secondaryBox: hexToRGB(palette.secondary, "0.1"),
        gradient: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 73.44%, rgba(255, 255, 255, 0) 100%)",
      },
      button: {
        one: {
          background: {
            enable: "#2997FF",
            disable: "#2997FF",
            focus: "#2997FF",
            hover: palette.greyLight,
          },
          color: {
            default: "#FFFFFF",
            focus: "#FFFFFF",
            outlined: "#2997FF",
          },
          opacity: {
            disabled: 0.5,
          },
          ripple: {
            background: "rgba(0, 0, 0, 0.2)",
          },
          border: "2px solid #2997FF",
        },
        default: {
          background: {
            enable: palette.greyLight,
            disable: hexToRGB(palette.secondary || palette.primary, 0.1),
            focus: palette.primary,
            hover: palette.greyLight,
            off: "rgb(204, 204, 204)",
          },
          color: {
            enable: palette.secondary || palette.primary,
          },
        },
        primary: {
          background: {
            enable: palette.primary,
            disable: palette.primary,
            focus: palette.primary,
            hover: palette.primary,
          },
          color: {
            default: "#FFFFFF",
            focus: "#FFFFFF",
          },
          opacity: {
            disabled: 0.5,
          },
          ripple: {
            background: "rgba(0, 0, 0, 0.2)",
          },
        },
        secondary: {
          background: {
            enable: palette.greyLight,
            disable: hexToRGB(palette.secondary, "0.1"),
            focus: palette.secondaryFocus,
            hover: palette.greyLight,
          },
          color: {
            default: "#009696",
            focus: "#FFFFFF",
          },
          opacity: {
            disabled: 0.5,
          },
          ripple: {
            /* Secondary has no ripple */
            background: "rgba(0, 0, 0, 0)",
          },
        },
        tertiary: {
          background: {
            enable: palette.greyLight,
            disable: hexToRGB(palette.secondary, "0.1"),
            focus: hexToRGB(palette.secondary, "0"),
            hover: palette.grey,
          },
          color: {
            default: palette.primary,
            focus: palette.primary,
            inverted: palette.primary,
          },
          opacity: {
            disabled: 0.5,
          },
          ripple: {
            /* Secondary has no ripple */
            background: "rgba(0, 0, 0, 0)",
          },
        },
        alert: {
          background: {
            enable: palette.alert,
            disable: palette.alert,
            focus: palette.alert,
            hover: palette.alert,
          },
          color: {
            default: "#FFFFFF",
            focus: "#FFFFFF",
          },
          opacity: {
            disabled: 0.5,
          },
          ripple: {
            background: "rgba(0, 0, 0, 0.2)",
          },
          border: `2px solid ${palette.alert}`,
        },
        valid: {
          background: {
            enable: palette.success,
            disable: palette.success,
            focus: palette.success,
            hover: palette.success,
          },
          color: {
            default: "#FFFFFF",
            focus: "#FFFFFF",
          },
          opacity: {
            disabled: 0.5,
          },
          ripple: {
            background: "rgba(0, 0, 0, 0.2)",
          },
          border: `2px solid ${palette.valid}`,
        },
      },
    },
    props: {
      MuiButton: {
        disableRipple: true,
      },
      MuiIconButton: {
        disableRipple: true,
      },
    },
  });
};
