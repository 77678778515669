import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Chip from "./Chip";
import AddChipPopper from "./AddChipPopper";
import { listExclude, mapListInclude } from "./utils";
import AddChipButton from "./AddChipButton";

const ChipList = ({ options, selected, onUpdate, defaultChip }) => {
  const [selection, setSelection] = useState(selected || []);
  const anchorEl = useRef();
  const [open, setOpen] = useState(false);

  const optionsList = Object.entries(options);
  const removeFromSelection = (type) => {
    setSelection(selection.filter((item) => item !== type));
  };

  const addToSelection = (key) => {
    setSelection([...selection, key]);
  };

  useEffect(() => {
    onUpdate(selection);
  }, [selection]);

  const availableOptions = listExclude(optionsList, selection);
  return (
    <Box>
      {!selection.length && defaultChip && <Chip label={defaultChip} />}
      {mapListInclude(optionsList, selection, (type, label) => (
        <Chip
          key={type}
          label={label}
          onDelete={() => removeFromSelection(type)}
        />
      ))}
      <AddChipPopper
        options={availableOptions}
        onSelect={addToSelection}
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={anchorEl.current}
      />
      <AddChipButton
        ref={anchorEl}
        onClick={() => setOpen(true)}
        visible={!!availableOptions.length}
      />
    </Box>
  );
};

ChipList.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.array,
  onUpdate: PropTypes.func,
};
ChipList.defaultProps = {
  options: [],
  selected: [],
  onUpdate: () => {},
};

export default ChipList;
