import { useState } from "react";
import dayjs from "dayjs";
import { QuerySet } from "redux-orm";

import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isInteger from "lodash/isInteger";
import isNumber from "lodash/isNumber";

import { APP_BASE } from "settings/config";
import { _t } from "i18n";

export const useForceUpdate = () => {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
};

const baseRegexp = APP_BASE.clone()
  .toString()
  .replace("//", "\\/\\/") // escape ://
  .replace(/\/$/, ""); // remove trailing slash

export const MEETING_URL_REGEX = new RegExp(
  `^${baseRegexp}\\/meeting\\/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})`
);

export const UUID_REGEX = new RegExp(
  /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/
);

export const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const PASSWORD_LETTER_REGEX = new RegExp(/^(?=.*[a-z])(?=.*[A-Z]).+$/);

export const PASSWORD_DIGIT_REGEX = new RegExp(/[0-9]/);

// http or https
export const URL_REGEX = new RegExp(
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(\.[a-z]{2,6})?\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
);

// force https here. Needed to embed something on Jam cuz' we are https and cannot embed somethin simply in http
export const HTTPS_URL_REGEX = new RegExp(
  /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(\.[a-z]{2,6})?\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
);

// export const PASSWORD_REGEX = new RegExp(/(?=\S)(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{7,}\S/)
export const PASSWORD_MIN_LENGTH = 8;

export const getMeetingUrl = (id) =>
  `${APP_BASE.clone().toString().replace(/\/$/, "")}/meeting/${id}`;

export function ucfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function firstLetterLastname(name) {
  const splitted = name.split(/[ .]+/);

  if (splitted.length >= 2 && splitted[1].match(/\w+/) !== null) {
    const firstname = splitted[0];

    return `${firstname} ${splitted[1].charAt(0).toUpperCase()}.`;
  }

  return ucfirst(splitted[0]);
}

export function truncate(text, length = 100, ellipsis = true) {
  if (!length || text.length <= length) {
    return text;
  }

  let truncated = text.substr(0, length);

  // retruncate to avoid breaking words
  truncated = truncated.substr(
    0,
    Math.min(truncated.length, truncated.lastIndexOf(" "))
  );

  return `${truncated}${ellipsis ? "…" : ""}`;
}

export function formatDayName(unix) {
  const id = dayjs.unix(unix).format("DDD");
  const relative = relativeDayFromNow(unix);
  if (
    relative === "yesterday" ||
    relative === "today" ||
    relative === "tomorrow"
  ) {
    return {
      title: _t(relative[0].toUpperCase() + relative.substr(1)),
      id,
    };
  }
  return { title: dayjs.unix(unix).format(_t("ddd, MMM D")), id };
}

export function formatDate(unix, format) {
  let date = dayjs.unix(unix);
  let formatted = date.format(format);
  if (date.hour() !== 0) {
    return formatted;
  }

  // REALLY ugly workaround to show '00' instead of '24' for the workaholic having a midnight meeting

  // only other number available as 24 is date
  let day = dayjs.unix(unix).date();

  if (day === 24) {
    date.date(25);
    formatted = date.format(format);
    formatted = formatted.replace("24", "00");
    formatted = formatted.replace("25", "24");
  } else {
    formatted = date.format(format);
    formatted = formatted.replace("24", "00");
  }

  return formatted;
}

export function generateHash(size = 7) {
  return Math.random().toString(36).substr(2, size);
}

export function redirectToUrl(newUrl, force = false) {
  if (typeof window.history.pushState !== "undefined" && !force) {
    return window.history.replaceState({}, document.title, newUrl);
  }

  window.location.href = newUrl;
}

export function forceArray(mixed) {
  if (!Array.isArray(mixed)) {
    mixed = [mixed];
  }

  return mixed;
}

export function cloneResource(resource) {
  if (!resource.Model || !resource.Model.backendName || !resource.ref) {
    throw new Error("Not a valid resource");
  }

  return Object.assign(
    {},
    { type: resource.Model.backendName },
    cloneDeep(resource.ref)
  );
}

// add fields in a resource by giving an array of properties in parameter
// patched resource will always have id and type
export function patchResource(resource, keys = []) {
  if (!resource.Model || !resource.Model.backendName || !resource.ref) {
    throw new Error("Not a valid resource");
  }

  const partial = {};

  keys.forEach((key) => {
    const prop = resource[key];

    if (typeof prop !== "undefined") {
      if (prop instanceof QuerySet) {
        let relationships = resource[key].toModelArray();
        partial[key] = [];

        relationships.map((relationship) => {
          partial[key].push({
            id: relationship.id,
            type: relationship.Model.backendName,
          });
        });
      } else {
        partial[key] = resource[key];
      }
    }
  });

  return Object.assign(
    {},
    { id: resource.id, type: resource.Model.backendName },
    partial
  );
}

/**
 * @function getDayTime
 * @param  {number} dayjsDate {dayjs js date}
 * @param  {{tresshold: number, title: string}} periods    {Object of periods}
 * @return {string} {Return the dayjs of the day}
 */
export function getDayTime(dayjsDate, periods) {
  periods = periods || [
    {
      tresshold: 20,
      title: _t("Night"),
      code: "night",
    },
    {
      tresshold: 17,
      title: _t("Evening"),
      code: "evening",
    },
    {
      tresshold: 12,
      title: _t("Afternoon"),
      code: "afternoon",
    },
    {
      tresshold: 0,
      title: _t("Morning"),
      code: "morning",
    },
  ];

  if (!dayjsDate || !dayjsDate.isValid()) {
    return;
  }

  const currentHour = parseFloat(dayjsDate.format("HH"));

  for (let i = 0; i < periods.length; i++) {
    if (currentHour >= periods[i].tresshold) {
      return periods[i].title;
    }
  }
}

export function relativeDayFromNow(date) {
  const today = dayjs().endOf("day").unix();
  const tomorrow = dayjs().add(1, "day").endOf("day").unix();
  const yesterday = dayjs().subtract(1, "day").endOf("day").unix();
  const before = dayjs().subtract(1, "day").startOf("day").unix();

  if (date < before) {
    return "before";
  }

  if (date < yesterday) {
    return "yesterday";
  }

  if (date < today) {
    return "today";
  }

  if (date < tomorrow) {
    return "tomorrow";
  }

  return "after";
}

export function shouldPureComponentUpdate(
  nextProps,
  nextState,
  context = null
) {
  context = context || this;

  // console.log(context.state, nextState, context.props, nextProps)
  // console.log(isEqual(context.props, nextProps), isEqual(context.state, nextState))

  return (
    !isEqual(context.props, nextProps) || !isEqual(context.state, nextState)
  );
}

export function focusContentElement(element) {
  if (!element) {
    return;
  }

  const range = document.createRange();
  const selection = window.getSelection();

  // https://gist.github.com/al3x-edge/1010364
  if (!element.innerHTML.length) {
    range.setStart(element, 0);
  } else {
    range.selectNodeContents(element);
    range.collapse(false);
  }

  selection.removeAllRanges();
  selection.addRange(range);
}

// noop function
export const noop = () => {};

export const getLocationHash = (location, def = null) => {
  return location.hash ? location.hash.replace("#", "") : def;
};

// focus an input and go to last character
export function focusInput(input, focusLast = true) {
  if (!input) {
    return;
  }

  input.focus();

  if (focusLast) {
    // Multiply by 2 to ensure the cursor always ends up at the end;
    // Opera sometimes sees a carriage return as 2 characters.
    const inputLength = input.value.length * 2;
    input.setSelectionRange(inputLength, inputLength);
  }
}

export function localStorageInfo() {
  let key;
  let keyLen;
  let totalSize = 0;

  for (key in window.localStorage) {
    if (key === "length" || typeof window.localStorage[key] === "function") {
      continue;
    }

    keyLen = (window.localStorage[key].length + key.length) * 2;
    totalSize += keyLen;

    console.log(`${key.substr(0, 50)} = ${(keyLen / 1024).toFixed(2)} kb`);
  }

  console.log(`[total] = ${(totalSize / 1024).toFixed(2)} kb`);
}

export function scrollTo(element, to, duration) {
  const start = element.scrollTop;
  const change = to - start;
  let currentTime = 0;
  const increment = 20;

  const animateScroll = function () {
    currentTime += increment;
    const val = Math.easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;

    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };

  // sometimes, scrollTop is read only that's why we are try/catching it here
  // https://stackoverflow.com/questions/11068507/why-sometime-scrolltop-scrollleft-not-writable
  try {
    animateScroll();
  } catch (e) {}
}

// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;

  if (t < 1) {
    return (c / 2) * t * t + b;
  }

  t--;

  return (-c / 2) * (t * (t - 2) - 1) + b;
};

window.scrollTo = scrollTo;

export const getParsedPercent = (percent) => {
  if (!isNumber(percent)) return 0;

  let finalValue = isInteger(percent) ? percent : percent.toFixed(2);
  if (finalValue > 100) finalValue = 100;
  return finalValue;
};

export const hexToRGB = (hex, alpha, string = true) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (!string) {
    return { r: r, g: g, b: b };
  }

  if (alpha !== null) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

export const getBrightness = (hex) => {
  //http://www.w3.org/TR/AERT#color-contrast
  const rgb = hexToRGB(hex, null, false);
  return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
};

export const isLight = (hex) => {
  return getBrightness(hex) >= 126;
};

export const isIframeEmbeded = () => window !== window.top;

export const onPressEnter = (event, callback) => {
  if (event.code === "Enter" || event.code === "NumpadEnter") {
    event.preventDefault();
    callback();
  }
};