import {
  RESOURCES_SET,
  RESOURCE_UNSET,
  RESOURCES_STORE_UPDATE,
  RESOURCE_FETCH,
  RESOURCES_FETCH,
  RESOURCE_CREATE,
  RESOURCES_CREATE,
  RESOURCE_EDIT,
  RESOURCES_EDIT,
  RESOURCE_DELETE,
  RESOURCES_DELETE,
} from "ressourcesActionTypes";
import emitter from "core/emitter"; //or utilities/emitter
import { CORE_RESOURCE_ACTION } from "core/emitter/events";
import get from "lodash/get";

export const setResources = (resources, included, meta) => ({
  type: RESOURCES_SET,
  resources,
  included: included || [],
  meta: meta || {},
});
export const setResource = (resource, included, meta) => setResources([resource], included, meta);
export const unsetResource = (locator) => ({ type: RESOURCE_UNSET, locator });
export const replaceResources = (session) => ({
  type: RESOURCES_STORE_UPDATE,
  session,
});

export const resourceFetch = (locator, options) => {
  emitter.emit(CORE_RESOURCE_ACTION, "fetch", { type: locator.type, options });

  return { type: RESOURCE_FETCH, locator, options };
};

export const resourcesFetch = (resourceType, options) => {
  emitter.emit(CORE_RESOURCE_ACTION, "fetch", { type: resourceType, options });

  return { type: RESOURCES_FETCH, resourceType, options };
};

export const resourceCreate = (resource, options) => {
  emitter.emit(CORE_RESOURCE_ACTION, "create", {
    type: resource.type || resource.model.backendName,
    options,
  });

  return { type: RESOURCE_CREATE, resource, options };
};
export const resourcesCreate = (resources, options) => {
  emitter.emit(CORE_RESOURCE_ACTION, "create", {
    type: get(resources, "[0].type") || get(resources, "[0].model.backendName"),
    options,
  });

  return { type: RESOURCES_CREATE, resources, options };
};

export const resourceEdit = (resource, options) => {
  if (options.method === "POST") return resourceCreate(resource, options);
  emitter.emit(CORE_RESOURCE_ACTION, "edit", {
    type: resource.type || resource.model.backendName,
    options,
  });

  return { type: RESOURCE_EDIT, resource, options };
};
export const resourcesEdit = (resources, options) => {
  return { type: RESOURCES_EDIT, resources, options };
};

// @DOC: resource or locator
export const resourceDelete = (resource, options) => {
  emitter.emit(CORE_RESOURCE_ACTION, "delete", {
    type: resource.type || resource.model.backendName,
    options,
  });

  const locator = resource.locator || resource;

  return { type: RESOURCE_DELETE, locator, options };
};
export const resourcesDelete = (resources, options) => {
  emitter.emit(CORE_RESOURCE_ACTION, "delete", {
    type: get(resources, "[0].type") || get(resources, "[0].model.backendName"),
    options,
  });

  return { type: RESOURCES_DELETE, resources, options };
};
