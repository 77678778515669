import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "i18n";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    border: "1px solid #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "8px",
    height: (props) => (props.isMobile ? "56px" : "40px"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "12px",
    width: "100%",
    "&:focus": {
      outline: "none",
    },
  },
}));

const Input = ({ name, onChange, value, placeholder, onBlur, style, disabled, clsName, type, onKeyDown, onFocus, id }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  return <input
    id={id}
    onFocus={onFocus}
    onKeyDown={onKeyDown}
    type={type}
    disabled={disabled}
    style={style}
    className={clsx(classes.root, clsName)}
    name={name}
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    onBlur={onBlur} />;
};

Input.propTypes = {};

Input.defaultProps = {};

export default Input;
