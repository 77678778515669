import { select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { call, take, fork, put } from "redux-saga/effects";

import { sessionSelector } from "../selectors";
import { setStream, removeStream, updateStream } from "../actions";
import { currentWizSelector } from "ressourcesDucks/selectors";
import { focus } from "./focus";
import get from "lodash/get";

// this is the redux-way to bind listeners with an eventChannel
function createChannel(session) {
  return eventChannel((emit) => {
    session.on("streamCreated", ({ type, stream }) => emit({ type, stream, session }));

    session.on("streamDestroyed", emit);

    session.on("streamPropertyChanged", ({ changedProperty, stream }) => {
      if (!["hasVideo", "hasAudio", "videoDimensions"].includes(changedProperty)) return console.log("unsupported property changed", changedProperty);

      emit({ type: "streamUpdated", stream });
    });

    // eventChannel must return an unsubscribe function
    return () => { };
  });
}

function* handleEvent({ type, stream, reason, target, session }) {
  const event = yield select(currentWizSelector);
  const focusedStreamId = get(event, "live_media.focus", null);

  console.log('handleEvent', type, stream, reason, target, session);

  switch (type) {
    case "streamCreated":
      yield put(setStream(stream));
      break;
    case "streamDestroyed":
      yield put(removeStream(stream));
      if (stream.id === focusedStreamId) {
        yield focus(stream); // Will unfocus if we detect that stream quitting while on focus
      }
      break;
    case "streamUpdated":
      yield put(updateStream(stream));
      break;
    default:
      console.log(`unsupported type ${type}`, reason, target);
  }
}

export function* subscribeToPublishers() {
  console.log('[OT] subscribeToPublishers');
  console.log("[VISIO SAGA] SUBSCRIBE TO PUBLISHERS")

  const session = yield select(sessionSelector);
  const channel = yield call(createChannel, session);

  while (true) {
    const event = yield take(channel);

    try {
      yield fork(handleEvent, event);
    } catch (e) {
      console.log("[Vonage] unable to handle vonage event", e);
    }
  }
}
