export const slug = (title) => {
  if (!title) return "";

  return title
    .toLowerCase()
    .split(" ")
    .join("")
    .replaceAll("'", "")
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .substr('start', 20);
}
