const ThemedButton = (props, theme) => {
  let buttonPalette;
  let fontSize;
  let paddingVertical;
  let paddingHorizontal;
  let squaredSize;
  let squaredFontSize;
  let fontWeight;
  let lineHeight;
  let radius;
  let iconSize;
  let height = "auto";
  let width = "auto";
  let minHeight = 0;
  let minWidth = 0;

  /* Handle the color */
  switch (props.color) {
    case "primary":
    case "secondary":
    case "alert":
    case "tertiary":
    case "valid":
    case "link":
      buttonPalette = theme.palette.button[props.color];
      break;
    default:
      //to define
      buttonPalette = theme.palette.button.primary;
      break;
  }

  /* Handle the size */
  switch (props.size) {
    case "large":
      fontSize = "14px";
      lineHeight = "14px";
      paddingVertical = 15;
      paddingHorizontal = 30;
      iconSize = "14px";
      squaredSize = 34;
      squaredFontSize = "20px";
      break;
    default:
    case "medium":
      fontSize = "14px";
      lineHeight = "14px";
      paddingVertical = 12;
      paddingHorizontal = 20;
      iconSize = "14px";
      squaredSize = 24;
      squaredFontSize = "20px";
      break;
    case "small":
      fontSize = "12px";
      lineHeight = "12px";
      paddingVertical = 10;
      paddingHorizontal = 15;
      iconSize = "12px";
      squaredSize = 24;
      squaredFontSize = "15px";
      break;
  }

  if (props.variant === "text") {
    paddingVertical = 0;
    paddingHorizontal = 0;
  }

  /* Handle the weight */
  switch (true) {
    case props.bold === true:
    case props.size === "large":
      fontWeight = 700;
      break;
    default:
      fontWeight = 600;
      break;
  }

  /* Handle the radius */
  switch (props.rounded) {
    case "full":
      radius = "999px";
      paddingHorizontal = paddingVertical;
      break;
    case "l":
    case "m":
    case "s":
    default:
      radius = "99px";
      break;
  }

  if (props.squared) {
    width = squaredSize;
    height = squaredSize;
    fontSize = squaredFontSize;
  }

  if (props.fullWidth) {
    width = "100%";
  }

  const transformPadding = (vertical, horizontal) => {
    return theme.spacing(vertical / 8, horizontal / 8);
  };

  let style = {};

  style = Object.assign(style, {
    color: buttonPalette.color,
    fontWeight: fontWeight,
    lineHeight: lineHeight,
    borderRadius: radius,
    height: height,
    fontSize: fontSize,
    width: width,
    minWidth: minWidth,
    minHeight: minHeight,
    padding: props.squared
      ? 0
      : transformPadding(paddingVertical, paddingHorizontal),

    "&:active, &:focus": {
      //backgroundColor: buttonPalette.background.focus,
      //color: buttonPalette.color.focus,
      //boxShadow: "none",
    },

    "&.MuiButtonGroup-groupedHorizontal:not(:first-child)": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
    "&.MuiButtonGroup-groupedHorizontal:not(:last-child)": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "&.MuiButtonGroup-grouped": {
      minWidth: "auto",
    },
    "&.MuiButtonGroup-groupedContainedHorizontal:not(:last-child)": {
      borderRight: `1px solid ${buttonPalette.background.hovered}`,
    },
  });

  // variant contained
  if (props.variant !== "text") {
    style = Object.assign(style, {
      backgroundColor: buttonPalette.background.default,
      "&:hover": {
        background:
          props.color !== "tertiary"
            ? `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), ${buttonPalette.background.hovered}`
            : buttonPalette.background.hovered,
        boxShadow: "none",
      },
      "&.Mui-disabled": {
        backgroundColor: buttonPalette.background.disabled,
        color: buttonPalette.color,
        opacity: buttonPalette.opacity.disabled,
      },
    });
  } else {
    // variant text
    style = Object.assign(style, {
      color: buttonPalette.background.default,
      "&:hover": {
        color: buttonPalette.background.hovered,
        filter: "brightness(90%)",
        boxShadow: "none",
        background: "none",
      },
      "&.Mui-disabled": {
        color: buttonPalette.background.disabled,
        opacity: buttonPalette.opacity.disabled,
      },
    });
  }
  //controlled button handle themeselve the active && focus state with color attribute
  if (!props.controlled) {
    style = Object.assign(style, {
      "&:active, &:focus": {
        backgroundColor: buttonPalette.background.default,
        color: buttonPalette.color,
        boxShadow: "none",
      },
    });
  }

  if (props.startIcon) {
    style = Object.assign(style, {
      "& .MuiButton-startIcon":
        props.variant === "text"
          ? { margin: "0 5px 0 0" }
          : { margin: "0 8px 0 0" },
      "& .MuiButton-startIcon > *:first-child": {
        fontSize: iconSize,
      },
    });
  }

  if (props.ml) {
    style = Object.assign(style, {
      marginLeft: `${props.ml}px`,
    });
  }

  return style;
};

export default ThemedButton;
