import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Divider, CircularProgress, useTheme } from "@material-ui/core";
import { _t } from "i18n";
import { genericResourcesSelector } from "ressourcesDucks/selectors";
import { formatPrice } from "liveShopping/utils";
import { currentCartSelector } from "app/state/ducks/ressources/selectors";

import emitter from "core/emitter";
import { CART_UPDATE, CART_UPDATED } from "utilities/emitter/events";
import { proceedToCheckout } from "app/state/ducks/liveShopping/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: "0 0 auto",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "auto",
    backgroundColor: theme.palette.colors.white,
  },
  top: { display: "flex", justifyContent: "space-between" },
  totalTypo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "130%",
    color: "black",
    marginRight: 8,
  },
  tvaTypo: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.darkGrey,
  },
  orderButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 30px",
    background: theme.palette.colors.primary,
    borderRadius: "99px",
    margin: "auto",
    marginTop: "19px",
    textDecoration: "none",
    cursor: "pointer",
  },
  orderButtonTypo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "100%",
    textAlign: "center",
    color: theme.palette.colors.white,
  },
  priceTypo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "130%",
    color: "black",
    marginRight: "5px",
  },
  redirectTypo: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "130%",
    textAlign: "center",
    marginTop: "14px",
    marginBottom: "29px",
  },
  previousPriceTypo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "130%",
    textDecorationLine: "line-through",
    color: theme.palette.colors.grey,
  },
}));

const Footer = ({ }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [isUpdating, setUpdating] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const onCartUpdate = () => setUpdating(true);
  const onCartUpdated = () => setUpdating(false);

  useEffect(() => {
    emitter.on(CART_UPDATE, onCartUpdate);
    emitter.on(CART_UPDATED, onCartUpdated);

    return () => {
      emitter.off(CART_UPDATE, onCartUpdate);
      emitter.off(CART_UPDATED, onCartUpdated);
    };
  }, []);

  const products = useSelector((state) => genericResourcesSelector(state, { type: "Product" }));
  const cart = useSelector(currentCartSelector);

  let total = 0;
  let undiscounted = 0;

  if (products.length && cart.products.length) {
    const totals = cart.products.reduce(
      (sum, { product_id, variant_id, quantity }) => {
        const product = products.find(({ id }) => id === product_id);

        if (!product || !product.getVariant(variant_id)) return sum;

        const { price, previous_price } = product?.getVariant(variant_id);

        sum.total += quantity * parseFloat(price);
        sum.undiscounted += quantity * (parseFloat(previous_price) || parseFloat(price));

        return sum;
      },
      { total: 0, undiscounted: 0 }
    );
    console.log("totalstotals", totals)
    total = totals.total;
    undiscounted = totals.undiscounted;
  }

  return (
    <Box className={classes.root}>
      <Divider style={{ marginBottom: "19px" }} />
      <Box style={{ marginLeft: 30, marginRight: 30 }}>
        <Box className={classes.top}>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.totalTypo}>{_t("Total")}</Typography>
            <Typography className={classes.tvaTypo}>{_t("Incl. VAT")}</Typography>
          </Box>

          {isUpdating ? (
            <CircularProgress size={20} style={{ color: theme.palette.colors.primary }} />
          ) : (
            <Box style={{ display: "flex" }}>
              <Typography className={classes.priceTypo}>{formatPrice(total, "EUR")}</Typography>
              <Typography className={classes.previousPriceTypo}>{formatPrice(undiscounted, "EUR")}</Typography>
            </Box>
          )}
        </Box>

        <a
          disabled={cart.products.length === 0}
          className={classes.orderButton}
          onClick={(e) => {
            if (cart.products.length === 0) return e.preventDefault();
            setIsRedirecting(true);
            dispatch(proceedToCheckout());
          }}
        >
          <Typography className={classes.orderButtonTypo}>{isRedirecting ? <CircularProgress size={12} style={{ color: "#FFF" }} /> : _t("Order")}</Typography>
        </a>
        <Typography className={classes.redirectTypo}>{_t("You'll be redirected to merchant checkout page.")}</Typography>
      </Box>
    </Box>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
