import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import MultiChat from "../../Component/MultiChat";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  title: {
    fontSize: "25px",
    lineHeight: "130%",
    fontWeight: 700,
    padding: "15px 20px",
  },
}));

const MultiChatContainer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Chat")}</Typography>
      <MultiChat />
    </Box>
  );
};

export default MultiChatContainer;