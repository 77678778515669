import { AreaChart, BarChart } from "@tremor/react";
import React from "react";
import { _t } from "utilities/i18n";


const HandledLost = ({ hours }) => {

  const dataToDisplay = hours?.map((hour) => {
    return {
      hour: hour.hour + "h",
      [_t("handled")]: hour.handled,
      [_t("lost")]: hour.lost,
    };
  });

  const dataFormatter = (number) => {
    return Intl.NumberFormat("us").format(number).toString();
  };

  return <AreaChart
    data={dataToDisplay}
    categories={[_t("handled"), _t("lost")]}
    dataKey="hour"
    colors={["blue", "rose"]}
    valueFormatter={dataFormatter}
    layout="horizontal"
    stack={false}
    relative={false}
    startEndOnly={false}
    showXAxis={true}
    showYAxis={true}
    autoMinValue={false}
    yAxisWidth="w-14"
    showTooltip={true}
    showLegend={true}
    showGridLines={true}
    showAnimation={true}
    height="h-80"
    marginTop="mt-0"
  />;
};

export default HandledLost;