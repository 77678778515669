import React from "react";
import PropTypes from "prop-types";

import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Box from "@material-ui/core/Box";
import Popper from "appComponents/Mui/Popper";

import { makeStyles } from "@material-ui/core/styles";

const PopperOptions = ({
  anchorEl,
  handleTooltipClose,
  children,
  buttonComponent,
  placement,
}) => {
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box>
        {buttonComponent}
        <Popper
          placement={placement}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
        >
          {children}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

PopperOptions.propTypes = {
  anchorEl: PropTypes.any,
  buttonComponent: PropTypes.any,
  children: PropTypes.any,
  handleTooltipClose: PropTypes.func,
  placement: PropTypes.string,
};
PopperOptions.defaultProps = {
  placement: "bottom",
};

export default PopperOptions;
