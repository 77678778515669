import URI from "urijs";
import { APP_BASE } from "config";

export const OTO_PREFIX = "oto";
export const OTM_PREFIX = "otm";

export const routes = {
  board: "/board",
  otm_lives: `${OTM_PREFIX}/lives`,
  otm_live_create: `${OTM_PREFIX}/live/create`,
  otm_live: `${OTM_PREFIX}/:keyword`,
  otm_live_edit: `${OTM_PREFIX}/:keyword/edit`,
  otm_product: `${OTM_PREFIX}/:keyword/product`,
  otm_product_edit: `${OTM_PREFIX}/:keyword/product/:hash`,
  otm_product_import: `${OTM_PREFIX}/:keyword/product/import`,
  otm_participants: `${OTM_PREFIX}/:keyword/participants`,
  otm_replay: `${OTM_PREFIX}/:keyword/replay`,
  otm_replay_library: `${OTM_PREFIX}/:keyword/replay/library`,
  otm_replay_create: `${OTM_PREFIX}/:keyword/replay/create`,
  otm_video: `${OTM_PREFIX}/:keyword/video`,
  otm_appearance: `${OTM_PREFIX}/:keyword/appearance`,
  otm_stats: `${OTM_PREFIX}/:keyword/stats`,
  otm_moderation: `${OTM_PREFIX}/:keyword/moderation`,
  otm_mails: `${OTM_PREFIX}/:keyword/mails`,
  otm_fullscreen: `${OTM_PREFIX}/:keyword/fullscreen`,
  user_account: "user/account",
  oto_dashboard: `${OTO_PREFIX}/:hash/dashboard`,
  oto_groups: `${OTO_PREFIX}/:hash/groups`,
  oto_live: `${OTO_PREFIX}/:hash/live`,
  oto_history: `${OTO_PREFIX}/:hash/history`,
  oto_multi_chat: `${OTO_PREFIX}/:hash/chat`,
  // OTO APPOINTMENTS
  oto_appointment: `${OTO_PREFIX}/:hash/groups/:group/appointment/:tab?`,
  oto_appointments: `${OTO_PREFIX}/:hash/appointments`,
  // OTO PARAMETERS
  oto_parameters: `${OTO_PREFIX}/:hash/parameters/:tab?`,
  oto_products: `${OTO_PREFIX}/:hash/products`,
};

export const getUrl = (key, params = {}, absolute = false) => {
  let route = routes[key];

  if (!route) {
    throw new Error(`Route ${key} not found`);
  }

  const uri = new URI(APP_BASE);

  Object.keys(params).map((key) => {
    if (route.indexOf(`:${key}`) === -1) {
      uri.addQuery(params[key]);
    } else {
      if (!params[key] && route.indexOf(`:${key}?`) !== -1)
        route = route.replace(`:${key}?`, "");
      else {
        const reg = new RegExp(`:${key}(\\?)?`, "gmi");
        route = route.replace(reg, params[key]);
      }
    }
  });

  uri.segment(route);
  if (!absolute) return `/${uri.relativeTo(APP_BASE).toString()}`;

  return uri.toString();
};

window.getUrl = getUrl;
