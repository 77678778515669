import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "utilities/i18n";
import { useDispatch, useSelector } from "react-redux";
import { getKeyword } from "app/state/ducks/application/selectors.js";
import UserManagement from "adminComponents/UserManagement";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },
  header: {
    margin: "16px 30px",
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginRight: "10px",
  },
  greyButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    width: props => props.isMobile ? "40px" : "32px",
    height: props => props.isMobile ? "40px" : "32px",
    borderRadius: "99px",
    background: "#F0F0F4",
    margin: "16px 30px",
   },
}));

const Participants = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <UserManagement />
    </Box>
  );
};

export default Participants;
