import React from "react";
import PropTypes from "prop-types";

const CustomCloseIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3183 2.68252C17.3765 2.74058 17.4227 2.80955 17.4542 2.88548C17.4857 2.96141 17.5019 3.04281 17.5019 3.12502C17.5019 3.20723 17.4857 3.28863 17.4542 3.36456C17.4227 3.44049 17.3765 3.50946 17.3183 3.56752L3.56829 17.3175C3.45093 17.4349 3.29176 17.5008 3.12579 17.5008C2.95982 17.5008 2.80065 17.4349 2.68329 17.3175C2.56593 17.2002 2.5 17.041 2.5 16.875C2.5 16.7091 2.56593 16.5499 2.68329 16.4325L16.4333 2.68252C16.4913 2.62431 16.5603 2.57814 16.6362 2.54663C16.7122 2.51512 16.7936 2.4989 16.8758 2.4989C16.958 2.4989 17.0394 2.51512 17.1153 2.54663C17.1913 2.57814 17.2602 2.62431 17.3183 2.68252Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.68166 2.68252C2.62346 2.74058 2.57728 2.80955 2.54577 2.88548C2.51427 2.96141 2.49805 3.04281 2.49805 3.12502C2.49805 3.20723 2.51427 3.28863 2.54577 3.36456C2.57728 3.44049 2.62346 3.50946 2.68166 3.56752L16.4317 17.3175C16.549 17.4349 16.7082 17.5008 16.8742 17.5008C17.0401 17.5008 17.1993 17.4349 17.3167 17.3175C17.434 17.2002 17.5 17.041 17.5 16.875C17.5 16.7091 17.434 16.5499 17.3167 16.4325L3.56666 2.68252C3.50861 2.62431 3.43964 2.57814 3.36371 2.54663C3.28777 2.51512 3.20637 2.4989 3.12416 2.4989C3.04196 2.4989 2.96055 2.51512 2.88462 2.54663C2.80869 2.57814 2.73972 2.62431 2.68166 2.68252Z"
        fill={color}
      />
    </svg>
  );
};

CustomCloseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomCloseIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomCloseIcon;
