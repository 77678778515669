import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Button from "../reusables/Button";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getUrl } from "app/views/containers/Application/Routes";
import { _t } from "utilities/i18n";
import { resourceCreate } from "app/state/ducks/ressources/actions";
import { getKeyword } from "app/state/ducks/application/selectors";
import ResourceFactory from "orm/resources";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "250px",
    height: "130px",
    backgroundImage: (props) => `url(${props.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "8px",
    position: "relative",
    transition: "all 0.3s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    gap: "8px",
    padding: "16px",
    position: "absolute",

  },
  whiteFilter: {
    backgroundColor: "rgba(255, 255, 255)",
    opacity: 0.5,
    height: "100%",
    width: "100%",
    position: "absolute",
  },
}));

const Card = ({ live }) => {
  const [isShown, setIsShown] = useState(false);
  const classes = useStyles({ backgroundImage: `https://image.mux.com/${live.playback_ids[0]?.id}/thumbnail.png`, isShown });
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const resourceFactory = useSelector((state) => new ResourceFactory(state));


  const parseDuration = (duration) => {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration - (hours * 3600)) / 60);
    const seconds = duration - (hours * 3600) - (minutes * 60);
    let result = (hours < 10 ? "0" + hours : hours);
    result += ":" + (minutes < 10 ? "0" + minutes : minutes);
    result += ":" + (seconds < 10 ? "0" + seconds : seconds);
    return result;
  }

  const onCreate = () => {
    const newReplay = resourceFactory.create("Replay", {
      replay_url: `https://stream.mux.com/${live.playback_ids[0]?.id}.m3u8`,
      enabled: false,
      starts_at: live.recording_times[0].started_at,
      options: {
        duration: live.duration,
        live_id: live.id,
        playback_id: live.playback_ids[0].id,
      },
    });

    dispatch(
      resourceCreate(newReplay, {
        slug: ["event", keyword, "replay"],
        callback: (error) => {
          if (error) return setError(error);
          window.location.href = getUrl("otm_replay", { keyword });
        },
      })
    );
  }

  return (
    <Box key={live.id}
      className={classes.card}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}>
      {isShown && <Box className={classes.whiteFilter} />}
      {isShown && <Box className={classes.cardContent}>
        <Typography>{dayjs.unix(live.created_at).format('DD/MM/YYYY HH:mm:ss')}</Typography>
        <Typography>{parseDuration(Math.round(live.duration))}</Typography>
        <Button onClick={onCreate}>{_t("Choose")}</Button>
      </Box>}
    </Box>
  )
};

export default Card;
