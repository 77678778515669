import { withStyles } from "@material-ui/styles";
import MuiButton from "@material-ui/core/Button";
import ThemedButton from "./ThemedButton";

const IconTextButton = withStyles((theme) => {
  return {
    root: (props) => {
      const themeButton = ThemedButton(props, theme);

      return {
        ...themeButton,
        lineHeight: "15px",
        letterSpacing: "0.5px",
        boxShadow: "none",
      };
    },
    disabled: {},
    textSecondary: {
      color: theme.palette.secondary.main,
      "&:hover": {
        color: theme.palette.secondary.main,
        backgroundColor: "transparent",
        boxShadow: "none",
        "& .MuiTouchRipple-root": {
          color: theme.palette.secondary.overlay,
        },
      },
    },
    label: {
      textAlign: "left",
      textTransform: "none",
    },
  };
})(MuiButton);

export default IconTextButton;
