import { withStyles } from "@material-ui/styles";
import MuiTextField from "@material-ui/core/TextField";

const TextField = withStyles((theme) => {
  return {
    root: (props) => {
      return {
        "& .MuiInputBase-root": {
          padding: props.size === "small" ? "7.5px 10px" : "12.5px 10px",
          borderRadius: theme.spacing(0.625),
          backgroundColor: theme.wisemblyColors.white,
          boxShadow: props.withoutborder ? "unset" : theme.palette.shadow,
        },
        "& .MuiInputBase-input": {
          padding: 0,
          fontSize: 12,
          lineHeight: 1.25,
          color: theme.wisemblyColors.black,
          "&::placeholder": {
            color: theme.wisemblyColors.black,
            opacity: 0.5,
          },
        },
        "& .MuiFormHelperText-contained": {
          margin: "5px 0 0",
          lineHeight: 1.25,
        },
        "& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            borderColor: theme.palette.border.default,
          },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderWidth: 1,
            borderColor: theme.palette.secondary.main,
          },
        "& .MuiOutlinedInput-root:hover.Mui-error .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            borderColor: theme.palette.error.main,
          },
      };
    },
  };
})(MuiTextField);

export default TextField;
