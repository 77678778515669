import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import OneSwitch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import Dropzone from "./Dropzone";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginTop: "20px",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "5px",
  },
  field: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "30px",
  },
}));

const Widget = ({ project }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [enabled, setEnabled] = useState(project?.options?.widget?.enabled || true);
  const [messageBubble, setMessageBubble] = useState(project?.options?.widget?.message_bubble || _t("One of our experts is available"));
  const [image, setImage] = useState(project?.options?.widget?.image || "");

  const widget = {
    enabled,
    image,
    message_bubble: messageBubble,
  };

  const handleSend = () => {
    const options = {
      ...project.options,
      widget,
    };

    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Widget configuration")}</Typography>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Enable widget")}</Typography>
        <OneSwitch onChange={() => setEnabled(!enabled)} checked={enabled} />
      </Box>
      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Bubble message")}</Typography>
        <Input value={messageBubble} onChange={(e) => setMessageBubble(e.target.value)} />
      </Box>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Avatar url (png, jpeg, gif)")}</Typography>
        <Dropzone value={image} setValue={setImage} />
      </Box>

      <Button onClick={handleSend} clsName={classes.buttonSave}>{_t("Save")}</Button>
    </Box>
  )
};

export default Widget;
