import { Box, makeStyles, Modal, Typography } from "@material-ui/core";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { _t } from "utilities/i18n";
import { triggerCartRefresh, visitorAddToCart } from "../Live/service";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Detail from "./Detail";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import ResourceFactory from "orm/resources";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "calc(100% - 50px)",
    paddingBottom: "10px",
  },
  title: {
    display: ({ embedded }) => (embedded ? "none" : "block"),
    fontSize: "25px",
    lineHeight: "130%",
    fontWeight: 700,
    padding: "15px 20px",
  },
  wrapper: {
    paddingLeft: "20px",
    overflow: ({ currentProduct }) => currentProduct ? "hidden" : "auto",
    maxHeight: "calc(100% - 50px)",
  },
  product: {
    display: "flex",
    padding: "10px 0",
    border: "1px solid #E5E5E5",
    borderRadius: "5px",
    padding: "10px 20px",
    marginBottom: "10px",
    width: "500px",
    maxHeight: "250px",
    overflowY: "auto",
    position: "relative",
  },
  productTitle: {
    fontWeight: 700,
  },
  img: {
    width: "100px",
    height: "100px",
    marginRight: "20px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  search: {
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: "10px",
    paddingLeft: "20px",
    width: ({ embedded }) => (embedded ? "100%" : "auto"),
  },
  searchInput: {
    width: ({ embedded }) => embedded ? "500px" : "300px",
  },
  detail: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
  },
  productDetail: {
    maxHeight: "200px",
    overflow: "auto",
  },
  settingsButton: {
    width: "fit-content !important",
  },
  empty: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    height: "100%",
    width: "100%",
  },
}));

let _searchTimeout = null;

const Products = ({ embedded, visitor }) => {
  const dispatch = useDispatch();

  const currentProject = useSelector(currentProjectSelector);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [search, setSearch] = useState("");
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const [currentProduct, setCurrentProduct] = useState(null);
  const [currentVariant, setCurrentVariant] = useState(null);

  const classes = useStyles({ embedded, currentProduct });


  const handleTriggerCartRefresh = () => {
    const newVisitor = resourceFactory.create("Visitor", { ...visitor?.cart, trigger_cart_refresh: true });
    triggerCartRefresh(dispatch, visitor, newVisitor);
  };

  const handleAddToCart = () => {
    visitorAddToCart(dispatch, visitor, currentProduct, currentVariant, 1, (error, response) => {
      if (error) emitter.emit(EVENT_FLASH_ERROR, _t("Error adding product to cart"));
      emitter.emit(EVENT_FLASH_SUCCESS, _t("Product added to cart"));
      handleTriggerCartRefresh();
      setCurrentProduct(null);
      setCurrentVariant(null);
    });
  };

  // throttle search change to fetch products
  useEffect(() => {
    if (!search) return;

    const searchProducts = async () => {
      const response = await fetch(`${currentProject.catalog_integration_url}/search?q=${search}`);

      try {
        const data = await response.json();

        if (!data.products) return;

        setSearchedProducts(data.products);
      } catch (error) {
        console.log(error);
      }
    };

    if (_searchTimeout) clearTimeout(_searchTimeout);

    _searchTimeout = setTimeout(() => {
      searchProducts();
    }, 200);

  }, [search]);

  useEffect(() => {
    if (!currentProject) return;
    if (!currentProject.catalog_integration_url) return;

    const fetchProducts = async () => {
      const response = await fetch(currentProject.catalog_integration_url);

      try {
        const data = await response.json();

        if (!data.products) return;

        setProducts(data.products);
      } catch (error) {
        console.log(error);
      }

      setLoading(false);
    };

    fetchProducts();

  }, [currentProject]);

  const productsToDisplay = search ? searchedProducts : products;

  if (!currentProject.catalog_integration_url) {
    return (
      <Box className={classes.root}>
          <Typography className={classes.title}>{_t("Products catalog")}</Typography>
        <Box className={classes.empty}>
          <Typography>{_t("No catalog integration url found")}</Typography>
          <Button clsName={classes.settingsButton} link={true} url={`/oto/${currentProject.id}/parameters/catalog`}>{_t("Go to settings")}</Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Products catalog")}</Typography>

      {currentProduct && (
        <Modal
          className={classes.modal}
          open={currentProduct}
          onClose={() => {
            setCurrentVariant(null);
            setCurrentProduct(null);
          }}
        >
          <Detail
            product={currentProduct}
            embedded={embedded}
            variant_id={currentVariant}
            setVariantId={setCurrentVariant}
            handleAddToCart={handleAddToCart}
            handleClose={() => {
              setCurrentVariant(null);
              setCurrentProduct(null);
            }}

          />
        </Modal>
      )}

      {products.length > 0 && (
        <Box className={classes.search}>
          <Input clsName={classes.searchInput} placeholder={_t("Search")} value={search} onChange={e => setSearch(e.target.value)} />
        </Box>
      )}

      <Box className={classes.wrapper}>
        {loading && (
         <Typography>{_t("Loading...")}</Typography>
        )}

        {!loading && (
          <Box>
            {productsToDisplay.length === 0 && (
              <Typography>{_t("No product found")}</Typography>
            )}

            {productsToDisplay.map((product) => {
              if (product.status !== "active") return;

              return <Box className={classes.product} key={product.id}>
                <Box className={classes.image}>
                  {product.images.length && (
                    <img
                      className={classes.img}
                      srcSet={product.images[0].src}
                      src={product.images[0].src}
                      loading="lazy"
                    />
                  )}
                  {!product.images.length && <Image src="https://via.placeholder.com/100" />}
                </Box>
                <Box className={classes.details}>
                  <Typography className={classes.productTitle}>{product.title}</Typography>

                  <Box>
                    { embedded && product.variants.map((variant) => (
                      <Box flexDirection="row" key={variant.id}>
                        <Typography>{variant.title} - {variant.price}€ {variant.inventory_management && variant.inventory_quantity <= 0 ? _t("Out of stock") : ''}</Typography>
                      </Box>
                    ))}

                    <Box className={classes.detail}>
                      <Button onClick={() => setCurrentProduct(product)}>{_t("Details")}</Button>
                    </Box>
                  </Box>

                </Box>
              </Box>
            })}
          </Box>
        )}
      </Box>
    </Box>
  )
};

export default Products;