import { backendMapping } from "orm";

export default function retrieveResource(session, { type, id }) {
  const resourceClass = session[backendMapping[type]];

  if (!resourceClass) {
    throw new Error(`resource "${type}" isnt supported`);
  }

  if (!resourceClass.idExists(id)) {
    throw new Error(`No resource found in store for type ${type} and id ${id}`);
  }

  return resourceClass.withId(id);
}
