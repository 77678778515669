import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { _t } from "i18n";
import { formatPrice, truncate } from "app/liveShopping/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
    width: "100%",
  },
  title: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    marginBottom: 4,
  },
  shortDesc: {
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "100%",
    color: theme.palette.colors.darkGrey,
  },

  price: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "18px",
    letterSpacing: "0em",
    marginRight: "6px",
  },
  previousPrice: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
    lineHeight: "130%",
    textDecorationLine: "line-through",
    color: theme.palette.colors.grey,
  },
}));

const Infos = ({ product }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{truncate(product.name, 40, true)}</Typography>
      <Typography className={classes.shortDesc}>{product.short?.slice(0, 20)}</Typography>
      <Box
        style={{
          display: !!product.price ? "flex" : "none",
          alignItems: "center",
          marginTop: "4px",
        }}
      >
        <Typography className={classes.price}>
          {!!product.price && formatPrice(product.price, "EUR")}
        </Typography>
        {product.previous_price ? (
          <Typography className={classes.previousPrice}>
            {!!product.previous_price && formatPrice(product.previous_price, product.currency)}
          </Typography>
        ) : null}
      </Box>
    </Box>
  );
};

Infos.propTypes = {};

Infos.defaultProps = {};

export default Infos;
