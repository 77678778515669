import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import OneSwitch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_ERROR, EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import AccordionOption from "./Accordion";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "5px",
  },
  field: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  buttonSave: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
}));

const Cobrowsing = ({ project }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { options } = project;

  const [enabled, setEnabled] = useState(options.cobrowsing?.enabled || false);
  const [startUrl, setStartUrl] = useState(options.cobrowsing?.startUrl || 'https://www.google.com');

  const cobrowsing = {
    enabled,
    startUrl,
  };

  const handleSend = () => {
    const options = {
      ...project.options,
      cobrowsing,
    };

    if (startUrl === "" && enabled) {
      emitter.emit(EVENT_FLASH_ERROR, _t("Start url is required"));
      return;
    }

    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  return (
    <Box className={classes.root}>
      <AccordionOption title={_t("Cobrowsing")}>
          <Box className={classes.field}>
            <Typography className={classes.label}>{_t("Enable cobrowsing")}</Typography>
            <OneSwitch onChange={() => setEnabled(!enabled)} checked={enabled} />
          </Box>
          <Box className={classes.field}>
            <Typography className={classes.label}>{_t("Start url")}</Typography>
            <Input value={startUrl} onChange={(e) => setStartUrl(e.target.value)} />
          </Box>
          <Box className={classes.buttonSave}>
            <Button onClick={handleSend}>{_t("Save")}</Button>
          </Box>
      </AccordionOption>
    </Box>
  )
};

export default Cobrowsing;
