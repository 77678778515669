import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { Image } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "342px",
    height: "min-content",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "10px",
    gap: "10px",
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.15)",
    borderRadius: "10px",
    margin: (props) => props.isMobile && "10px auto",
  },
  picture: {
    width: "60px",
    height: "60px",
    background: "#F0F0F4",
    borderRadius: "8px",
    background: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
  },
  infos: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "4px",
    width: "246px",
  },
  name: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "130%",
    color: theme.palette.colors.grey500,
  },
  short: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey500,
  },
  price: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "100%",
  },
  previousPrice: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "100%",
    textDecorationLine: "line-through",
    color: theme.palette.colors.grey400,
  },
  pictureSample: {
    width: "60px",
    height: "60px",
    borderRadius: "8px",
    background: theme.palette.colors.grey200,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Preview = ({ data }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ backgroundImage: data?.variants[0]?.picture_url, isMobile });
  const theme = useTheme();
  return (
    <Box className={classes.root}>
      {data?.variants[0]?.picture_url ? (
        <Box className={classes.picture} />
      ) : (
        <Box className={classes.pictureSample}>
          <Image color={theme.palette.colors.grey400} size={24} />
        </Box>
      )}
      <Box className={classes.infos}>
        <Typography variant={"bodySB13"} className={classes.name}>
          {data?.name || "Product name"}
        </Typography>
        <Typography variant={"bodySB13"} className={classes.short}>
          {data?.short || "A short description"}
        </Typography>
        <Box style={{ display: "flex", gap: 8 }}>
          <Typography variant={"bodySB13"} className={classes.price}>
            {data?.variants[0]?.price || "0.00"}
            {" €"}
          </Typography>
          {data?.variants[0]?.previous_price && (
            <Typography variant={"bodySB13"} className={classes.previousPrice}>
              {data?.variants[0]?.previous_price || "0.00"}
              {" €"}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

Preview.propTypes = {};

Preview.defaultProps = {};

export default Preview;
