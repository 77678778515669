import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { useDispatch, useSelector } from "react-redux";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import emitter from "utilities/emitter";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "20px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
  },
  cta: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
  },
  cancelButton: {
    background: theme.palette.colors.grey500,
  },
}));


const Cobrowsing = ({ setOpenCobrowsing, visitor }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [url, setUrl] = useState("");
  const project = useSelector(currentProjectSelector);

  const onPostMessage = (data) => {
    console.log('>> onPostMessage', data);

    if (data.type === "synchronize" && data.url)
      handleSynchronize(data.url);
  }

  useEffect(() => {
    emitter.on("postMessage", onPostMessage);

    return () => {
      emitter.off("postMessage", onPostMessage);
    }
  }, []);

  const handleSynchronize = (customUrl) => {

    const options = {
      ...visitor.options,
      cobrowsing: {
        url: customUrl || url,
        synchronized: false,
      }
    }

    dispatch(resourceEdit(Object.assign(visitor, { options }), {
      slug: ["visitors", visitor.hash, visitor.token],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }
      }
    }))
  };

  if (project.options.cobrowsing?.startUrl) {
    return (
      <Box className={classes.root}>
        <iframe src={`https://proxy.liveboutik.com/proxy?url=${project.options.cobrowsing.startUrl}`} width="100%" height="100%" style={{ border: 'none' }} />
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <h1>Cobrowsing</h1>
      <Typography>{_t("Enter an url to synchronize")}</Typography>
      <Input onChange={(e) => setUrl(e.target.value)} placeholder={"https://liveboutique.io/fr/"} />

      <Box className={classes.cta}>
        <Button clsName={classes.cancelButton} onClick={() => setOpenCobrowsing(false)}>{_t("Cancel")}</Button>
        <Button onClick={handleSynchronize}>{_t("Synchronize")}</Button>
      </Box>
    </Box>
  )
};

export default Cobrowsing;
