import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronCompactUp from "react-bootstrap-icons/dist/icons/chevron-compact-up";
import { useSelector } from "react-redux";
import { desktopLayoutSelector } from "liveShoppingDucks/selectors";
import ButtonBuy from "../Cards/ButtonBuy";
import { truncate } from "app/liveShopping/utils";
import { isUser } from "utilities/access";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "85%",
    minWidth: "279px",
    height: "100px",
    alignItems: "center",
    background: theme.palette.colors.white,
    borderRadius: "10px",
    boxShadow: (props) => (props.isActive ? "0px 3px 15px rgba(0, 0, 0, 0.15)" : "none"),
    margin: "unset",
    opacity: (props) => (props.isActive ? 1 : 0.3),
    pointerEvents: "auto",
    cursor: (props) => (props.desktopLayout ? "pointer" : "initial"),
  },
  titlePrices: {
    display: "flex",
    flexDirection: "column",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
    padding: "10px",
  },
  price: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 800,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    marginRight: "6px",
  },
  previousPrice: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
    textAlign: "left",
    lineHeight: "130%",
    textDecorationLine: "line-through",
    color: theme.palette.colors.grey,
  },
  productDescription: {
    fontSize: "10px",
    lineHeight: "10px",
    fontWeight: 400,
    color: theme.palette.colors.darkGrey,
  },
  img: {
    width: props => !props.desktopLayout ? "85px" : "75px",
    height: "100%",
    maxHeight: "100px",
    minWidth: ({ desktopLayout }) => (desktopLayout ? "75px" : "85px"),
    backgroundImage: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
}));

const Card = ({ product, isActive, onClick }) => {
  const theme = useTheme();
  const desktopLayout = useSelector(desktopLayoutSelector);
  const wiz = useSelector(currentWizSelector);
  const classes = useStyles({
    isActive,
    backgroundImage: product && product?.variants[0]?.picture_url,
    desktopLayout,
  });

  if (!product) return null;

  return (
    <Box className={classes.card} onClick={onClick}>
      <Box
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Box className={classes.img} />
        <Box className={classes.titlePrices}>
          <Typography style={{ fontSize: "14px" }}>{truncate(product.name, 40)}</Typography>

          {product.short && <Typography className={classes.productDescription}>{truncate(product.short, 20, true)}</Typography>}

          <Box
            style={{
              display: !!product.price ? "flex" : "none",
              alignItems: "center",
              marginTop: "4px",
            }}
          >
            {!isActive && (
              <>
              <Typography className={classes.price}>
              {product.price}
              {product.displayCurrency}
            </Typography>
            {product.previous_price && (
              <Typography className={classes.previousPrice}>
                {product.previous_price}
                {product.displayCurrency}
              </Typography>
            )}
              </>
            )}
          </Box>
        </Box>
        {isUser("user-") && wiz.status !== "pre_live" ? <ButtonBuy product={product} iconSize={20} /> : <Box style={{ height: "44px", width: "44px" }} />}
      </Box>
    </Box>
  );
};

export default Card;

Card.propTypes = {
  isActive: PropTypes.bool,
  backgroundImage: PropTypes.string,
  desktopLayout: PropTypes.bool,
};

Card.defaultProps = {};
