import dayjs from "dayjs";
import { APP_OLD_BASE } from "config";
import "dayjs/locale/fr";
import "dayjs/locale/en";

export const replaceEventLogo = (element, event) => {
  if (element) {
    const nodes = element.querySelectorAll(
      "img[src='{{eventMobileLogoPath}}']"
    );
    nodes.forEach((node) => {
      node.src = event.getLogo();
    });
  }
};

export const replaceEventBackground = (element, event) => {
  if (element) {
    const nodes = element.querySelectorAll(
      "table[background='{{eventBackgroundPath}}']"
    );
    nodes.forEach((node) => {
      node.style.background = `url("${event.backgroundImageUri}") center center / cover no-repeat`;
    });
  }
};

export const replaceVariables = (raw, event, user) => {
  const {
    title,
    timezone,
    keyword,
    start,
    stop,
    locale,
    live_start,
    live_stop,
  } = event;

  const formatDate = (date) => {
    return dayjs(date).toDate().toLocaleDateString(locale);
  };

  const formatTime = (date) => {
    return dayjs(date).toDate().toLocaleTimeString(locale).substr(0, 5);
  };

  const formatDateForHumans = (date, locale) => {
    dayjs.locale(locale);
    const dayJsDate = dayjs(date);
    if (locale === "fr") {
      // VENDREDI 29 OCTOBRE 2021 À 18:00
      return dayJsDate.format("dddd D MMMM YYYY [À] HH:mm");
    } else {
      // FRIDAY, OCTOBER 29, 2021 AT 6:00 PM
      return dayJsDate.format("dddd[,] MMMM D[,] YYYY [AT] h:mm A");
    }
  };

  const landingUrl = () => {
    return APP_OLD_BASE.clone().segment("l").segment(event.keyword).toString();
  };

  const isSetConvert = (date, fn, ...rest) => {
    return date ? fn(date, ...rest) : "";
  };

  const variables = [
    /* Event related */
    ["eventTitle", title],
    ["eventStartDate", formatDate(start)],
    ["eventStartTime", formatTime(start)],
    ["eventStopDate", formatDate(stop)],
    ["eventStopTime", formatTime(stop)],
    ["eventMobileLogoPath", event.getLogo()],
    ["eventTimezone", timezone],
    ["eventKeyword", keyword],
    ["eventBackgroundPath", event.backgroundImageUri],

    /* Session Related */
    [
      "eventLiveStartDate",
      isSetConvert(live_start, formatDateForHumans, user.lang),
    ],
    ["eventLiveStartTime", isSetConvert(live_start, formatTime)],
    ["eventLiveStopDate", isSetConvert(live_stop, formatDate)],
    ["eventLiveStopTime", isSetConvert(live_stop, formatTime)],
    /* User related */
    ["userFirstname", user.firstname],
    ["userLastname", user.lastname],
    ["userEmail", user.email],
    ["userCompany", user.company],
    ["userLocale", user.lang],

    /* Sender Related*/
    ["senderFullName", user.name],

    /* Misc urls */
    ["eventPathWithUser", landingUrl],
    ["eventPathWithoutUser", landingUrl],
    ["eventPathWithUserRsvpFalse", landingUrl],
    ["eventPathWithoutUserRsvpFalse", landingUrl],
    ["eventPathUserVerified", landingUrl],
    ["eventPathManageMails", landingUrl],
  ];

  variables.forEach((v) => {
    raw = raw.replaceAll("{{" + v[0] + "}}", v[1]);
  });

  return raw;
};

export const getDurationValueFromSeconds = (seconds) => {
  const minuteDuration = 60;
  const hourDuration = minuteDuration * 60;
  const dayDuration = hourDuration * 24;

  /* We have more than one day and we can split seconds in days without rest */
  if (seconds < dayDuration && !(seconds % dayDuration)) {
    return { unit: "day", value: seconds / dayDuration };
  }

  /* We have less than one day and we can split seconds in hours without rest */
  if (seconds < dayDuration && !(seconds % hourDuration)) {
    return { unit: "hour", value: seconds / hourDuration };
  }

  /* Otherwise we return the result as minutes */
  return { unit: "minute", value: Math.round(seconds / minuteDuration) };
};

export const getSendDate = (template) => {
  const { send_type, send_delay, event } = template;

  switch (send_type) {
    case "sending_type_customized":
      return dayjs.unix(send_delay).tz(event.timezone);
    case "sending_type_after_invitation":
    case "sending_type_after_registration":
      return null;
    case "sending_type_before_event":
      return dayjs(event.getStartLiveDateWithFallback())
        .add(send_delay, "second")
        .tz(event.timezone);
    case "sending_type_after_event":
      return dayjs(event.getStopLiveDateWithFallback())
        .add(send_delay, "second")
        .tz(event.timezone);
  }
};
