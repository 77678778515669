import URI from "urijs";

import dayjs from "dayjs";

import basil from "core/basil";

import { put, takeEvery, all } from "redux-saga/effects";
import { redirectToUrl } from "core/utils";

import { resourceEdit } from "ressourcesDucks/actions";

import { APPLICATION_INIT, USER_LOADED } from "applicationDucks/actionsTypes";

function* handleReferralOnStartup() {
  const { r, ref, u } = new URI(window.location).search(true);

  // when we have explicitely a referral or user in queryargs, set it into storage no matter what
  if (r || ref || u) {
    basil.set("referral:source", r || ref);
    basil.set("referral:user", u);

    redirectToUrl(
      new URI(window.location)
        .removeSearch("ref")
        .removeSearch("r")
        .removeSearch("u")
        .toString()
    );

    yield;
  }

  // if already something stored do nothing
  if (basil.get("referral:source")) {
    return;
  }

  // finally store document.referrer into basil
  basil.set("referral:source", document.referrer);
}

function* updateUserWithReferral({ user }) {
  // v1.0.0 pushed Tue 23 Jan 2018 - BTC was at $10 857
  const featureRelease = dayjs("20180123", "YYYYMMDD").unix();

  // try to patch only users creating an account after this feature release
  if (dayjs.unix(user.attributes.signupAt) < featureRelease) {
    return;
  }

  if (!user.attributes.referral || user.attributes.referral.source !== null) {
    return;
  }

  const patchedUser = {
    id: user.id,
    type: user.type,
    referral: {
      source: basil.get("referral:source") || "direct",
      user: basil.get("referral:user"),
    },
  };

  yield put(resourceEdit(patchedUser));
}

export default function* referralSaga() {
  yield all([
    yield takeEvery(APPLICATION_INIT, handleReferralOnStartup),
    yield takeEvery(USER_LOADED, updateUserWithReferral),
  ]);
}
