import React from "react";
import { _t } from "i18n";

import Box from "@material-ui/core/Box";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

import Dot from "react-bootstrap-icons/dist/icons/dot";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "end",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  dots: {
    display: "flex",
    justifyContent: "center",
    margin: "0 5px",
    color: theme.palette.colors.greyDark,
  },
  link: {
    cursor: "pointer",
  },
}));

const FooterLinks = () => {
  const classes = useStyles();
  return (
    <Box className={classes.content}>
      <Typography
        onClick={() => {
          window.open("https://www.wisembly.com/en/legal-notices");
        }}
        variant={"smallBody13"}
        className={classes.link}
        color={"greyDark"}
      >
        {_t("Legal Notices")}
      </Typography>
      <Box className={classes.dots}>
        <Dot />
      </Box>
      <Typography
        onClick={() => {
          window.open("https://www.wisembly.com/en/condition-of-sale");
        }}
        variant={"smallBody13"}
        className={classes.link}
        color={"greyDark"}
      >
        {_t("Condition of Sale")}
      </Typography>
      <Box className={classes.dots}>
        <Dot />
      </Box>
      <Typography
        onClick={() => {
          window.open("mailto:contact@wisembly.com");
        }}
        className={classes.link}
        variant={"smallBody13"}
        color={"greyDark"}
      >
        {_t("Contact us")}
      </Typography>
    </Box>
  );
};

FooterLinks.propTypes = {};

FooterLinks.defaultProps = {};

export default FooterLinks;