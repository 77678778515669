import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";

import ArrowExpand from "react-bootstrap-icons/dist/icons/arrows-angle-expand";

import emitter from "utilities/emitter";
import { CHAT_DISPLAY_IMAGE } from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => (props.width ? props.width : props.size),
    height: (props) => props.size,
    background: (props) => props.path && `center / cover no-repeat url(${props.path})`,
    borderRadius: "8px",
  },
  hover: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    width: (props) => (props.width ? props.width : props.size),
    height: (props) => props.size,
    backgroundColor: theme.palette.colors.dark50,
  },
}));

const Image = ({ path, size, width, isFooter }) => {
  const classes = useStyles({ path, size, width });
  const [isPictureHovered, setIsPictureHovered] = useState(false);

  const onClick = () => {
    emitter.emit(CHAT_DISPLAY_IMAGE, {
      path: path,
    });
  };

  return (
    <Box className={classes.root} onMouseEnter={() => setIsPictureHovered(true)} onMouseLeave={() => setIsPictureHovered(false)}>
      {isPictureHovered && !isFooter && (
        <Box className={classes.hover} onClick={() => onClick(path)}>
          <ArrowExpand size={24} />
        </Box>
      )}
    </Box>
  );
};

Image.propTypes = {
  path: PropTypes.string,
  size: PropTypes.number,
  width: PropTypes.number,
  isFooter: PropTypes.bool,
};

Image.defaultProps = {
  path: "",
  size: 32,
  isFooter: false,
};

export default Image;
