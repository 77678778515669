import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import { Divider } from "@material-ui/core";
import SendArea from "./SendArea";
import { useSelector } from "react-redux";
import { desktopLayoutSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import PinnedElement from "./reusables/PinnedElement";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "auto",
  },

  divider: {
    width: "100%",
    background: (props) => (props.desktopLayout ? theme.palette.colors.lightGrey : "rgba(180, 182, 185, 0.5)"),
  },
}));

const Footer = ({ quote }) => {
  const isDesktop = useSelector(desktopLayoutSelector);
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isDesktop });

  return (
    <Box className={classes.root}>
      {isMobile && <PinnedElement {...quote} />}
      <Divider className={classes.divider} />
      <SendArea />
    </Box>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
