import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  row: {
    display: "block",
    height: "15px",
    borderRadius: "99px",
    background: theme.wisemblyColors.loaderGradient,
    marginBottom: "31px",
    width: (props) => (props.width ? props.width : "100px"),
    //borderRadius: "5px",
    //backgroundColor: "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 73.44%, rgba(255, 255, 255, 0) 100%)",
    opacity: 0.1,
  },
  star1: {
    color: "rgba(7, 105, 141, 0.1)",
  },
  star2: {
    //"linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 73.44%, rgba(255, 255, 255, 0) 100%)",
    //color: "linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
    stroke: "url(#linearColors)",
  },
  star3: {
    color:
      "linear-gradient(90deg, #FFFFFF 23.03%, rgba(255, 255, 255, 0) 100%)",
  },
  star4: {
    color: "rgba(7, 105, 141, 0.1)",
  },
  star5: {
    color: "rgba(7, 105, 141, 0.1)",
  },
}));
//// TODO: find a way to correctly add gradient on material icons
const EmptyRatingLive = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.star1}>
        <StarOutlinedIcon />
      </Box>
      <Box>
        <svg width="30" height="30">
          <linearGradient id="linearColors" gradientTransform="rotate(270)">
            <stop offset="0%" stopColor="#FFFFFF" />
            <stop offset="100%" stopColor="rgba(255, 255, 255, 0)" />
          </linearGradient>
        </svg>
        <StarOutlinedIcon classes={{ root: classes.star2 }} />
      </Box>

      <Box className={classes.star3}>
        <StarOutlinedIcon />
      </Box>
      <Box className={classes.star4}>
        <StarOutlinedIcon />
      </Box>
      <Box className={classes.star5}>
        <StarOutlinedIcon />
      </Box>
    </Box>
  );
};

export default EmptyRatingLive;
