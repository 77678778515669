import URI from "urijs";
import { call, put, takeEvery, all } from "redux-saga/effects";

import fetchSession from "./fetchSession";
import refreshSession from "./refreshSession";
import fetchSessionWithOTT from "./fetchSessionWithOTT";

import { sessionUnset, logOut } from "applicationDucks/actions";
import { handleRequestError } from "ressourcesDucks/sagas/handleRequestError";

import {
  APPLICATION_INIT,
  REFRESH_SESSION,
  LOG_OUT,
} from "applicationDucks/actionsTypes";

import { getUserSession, createSession, clearSession } from "core/session";

import { resourceDelete } from "ressourcesDucks/actions";
import { redirectToUrl } from "core/utils";
import { isIframeEmbeded } from "utilities/utils";

function* handleSessionCheck() {
  const {
    id,
    token,
    refresh_token,
    ott
  } = new URI(window.location).search(true);

  // handle OAuth call back with session token and refresh in query arg
  if (token && refresh_token) {
    // create ASAP basil session to avoid race conditions with other calls that may have already been dispatched
    createSession({ token, refresh_token, id });

    // fetch proper session and update basil with user_id notamment
    // but if for any obscure reason fetch fails, logout and exit
    try {
      yield fetchSession({ token, refresh_token, id });

      // we have the session, clean the url from these query strings now
      redirectToUrl(
        new URI(window.location)
          .removeSearch("token")
          .removeSearch("refresh_token")
          .removeSearch("id")
          .toString()
      );
    } catch (error) {
      yield put(logOut());
    }

    return;
  }

  if (ott) {
    try {
      yield fetchSessionWithOTT(ott);
      window.history.replaceState(
        {},
        document.title,
        new URI(window.location.href).removeQuery("ott")
      );

      return;
    } catch (error) {
      /*
       * We used to store the invalid ott to display wall here
       * We deprecated it because it was not really cool for users with an invalid ott and a session opened
       * And we've got some problems with Google Auth & co
       *
       * Previous code :
       * console.log("invalid ott");
       * yield put(setInvalidOtt(true));
       * TODO : Later check error code is wrong_ott
       * */
    }
  }

  // retrieve up to date session
  const session = getUserSession();

  // if none (cuz not logged in, and no query args above) stop here
  if (!session.isAuthenticated()) {
    yield fetchSession({
      options: { method: "POST", tokenless: true },
      data: session.generateSigninJson(),
    });

    return;
  }

  // Refresh session before anything else
  // Sync process that makes the rest in waiting state
  // Do not perform this if we are embeded as module by wisembly-front (conflicts)
  try {
    if (session.shouldBeRefreshed() && !isIframeEmbeded())
      yield call(refreshSession);
  } catch (error) {
    // if we did not managed to properly refresh the session, then create a new anon session
    yield fetchSession({
      options: { method: "POST", tokenless: true },
      data: session.generateSigninJson(),
    });

    return;
  }

  // fetch up to date session and side load user + licenses
  try {
    yield fetchSession(session);
  } catch (error) {
    yield handleRequestError(error, true);
  }
}

function* handleLogOut() {
  const session = getUserSession();

  // Synchronously clear Basil session
  yield call(clearSession);

  yield put(sessionUnset(session));

  // delete user session silently (no need to warn if an error occurs)
  yield put(
    resourceDelete(session, {
      silent: true,
      callback: () => redirectToUrl("/", true),
    })
  );
}

export default function* sessionSaga() {
  yield all([
    yield takeEvery(REFRESH_SESSION, refreshSession),
    yield takeEvery(APPLICATION_INIT, handleSessionCheck),
    yield takeEvery(LOG_OUT, handleLogOut),
  ]);
}
