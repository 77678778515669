/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Calendar, ClockFill } from "react-bootstrap-icons";
import dayjs from "dayjs";
import { generateSummary } from "../Calendar/util";
import { _t } from "utilities/i18n";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { useSelector } from "react-redux";
import { currentCalendarSelector } from "app/state/ducks/liveShopping/selectors";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import PhoneField from "./PhoneField";
import EmailField from "./EmailField";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  text: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "16px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "32px",
    marginTop: "32px",
    gap: "16px",
    height: "310px",
    overflowY: "auto",
  },
}));

const Summary = ({ selectedDate, selectedTime, setFirstname, setLastname, setPhone, phone, valid, setValid, email, setEmail }) => {
  const classes = useStyles();
  const currentCalendar = useSelector(currentCalendarSelector);
  const currentProject = useSelector(currentProjectSelector);
  const method = currentProject?.options?.appointment_contact || 'email';

  const handlePhoneNumberChange = (event) => {
    const input = event.target.value;

    const frenchPhoneNumberRegex = /^(?:\+33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
    const internationalPhoneNumberRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;

    const isFrenchPhoneNumber = frenchPhoneNumberRegex.test(input);
    const isInternationalPhoneNumber = internationalPhoneNumberRegex.test(input);

    setPhone(input);
    setValid(isFrenchPhoneNumber || isInternationalPhoneNumber);
  };

  const handleEmailChange = (event) => {
    const input = event.target.value;

    const emailRegex = /\S+@\S+\.\S+/;

    const isValid = emailRegex.test(input);

    setEmail(input);
    setValid(isValid);
  };

  return (
    <Box>
      <Typography className={classes.title}>
        {currentCalendar?.slots_duration_minutes + " " + _t("minutes Meeting")}
      </Typography>

      <Typography className={classes.text}>
        <Calendar />
        {generateSummary(selectedDate, selectedTime)}
      </Typography>

      <Box className={classes.form}>
        <Box>
          <Typography>{_t("Firstname")}</Typography>
          <Input onChange={(e) => setFirstname(e.target.value)} />
        </Box>
        <Box>
          <Typography>{_t("Lastname")}</Typography>
          <Input onChange={(e) => setLastname(e.target.value)} />
        </Box>

        <Box>
          {['both', 'sms'].includes(method) && <PhoneField phone={phone} valid={valid} handlePhoneNumberChange={handlePhoneNumberChange} />}
          {['both', 'email'].includes(method) && <EmailField email={email} valid={valid} handleEmailChange={handleEmailChange} />}
        </Box>






      </Box>
    </Box>
  );
};

export default Summary;
