import React, { Component } from "react";
import { connect } from "react-redux";

import {
  currentWizSelector,
  currentWizReportSelector,
} from "ressourcesDucks/selectors";

export function withWiz(WrappedComponent) {
  class Wiz extends Component {
    static displayName = `WithWiz(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`;
    static getClass = () =>
      WrappedComponent.getClass
        ? WrappedComponent.getClass()
        : WrappedComponent;

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      wiz: currentWizSelector(state),
    };
  }

  return connect(mapStateToProps)(Wiz);
}

export function withWizReport(WrappedComponent) {
  class Wiz extends Component {
    static displayName = `WithWizReport(${
      WrappedComponent.displayName || WrappedComponent.name || "Component"
    })`;
    static getClass = () =>
      WrappedComponent.getClass
        ? WrappedComponent.getClass()
        : WrappedComponent;

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state) {
    return {
      report: currentWizReportSelector(state),
    };
  }

  return connect(mapStateToProps)(Wiz);
}
