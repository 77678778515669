import {
  SESSION_SET,
  SESSION_UNSET,
  DROP_FILE,
  VERIFY_USER,
  SET_LOCALE,
  SOCKET_SET,
  FETCHED_ONCE,
  CURRENT_WIZ_SET,
  CURRENT_WIZ_ERROR_SET,
  UPDATE_TIMELINE,
  UPDATE_REQUEST_STATUS,
  CONNECTION_SET,
  UPDATE_PARTICIPANTS_COUNT,
  LOG_OUT,
  ANONYMOUS_USER_PARTICIPATION_SET,
  DISPLAY_POPUP_PARTICIPATION_SET,
  UPDATE_STREAM,
  UPDATE_MODERATION,
  UPDATE_ARCHIVE_STREAM,
  USERNAME_SET,
  UPDATE_PREVIEW,
  UPDATE_USER_ACTION,
  UPDATE_CONNECTED_USERS_ACTION,
  INVALID_OTT,
  SET_CURRENT_PROJECT,
  SET_CUSTOM_THEME_COLORS,
  SET_OTO_DASHBOARD_DATES,
} from "./actionsTypes"; //or from applicationActionTypes
import isBoolean from "lodash/isBoolean";
import set from "lodash/set";
import get from "lodash/get";
import map from "lodash/map";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import concat from "lodash/concat";
import last from "lodash/last";
import size from "lodash/size";
import isNumber from "lodash/isNumber";
import remove from "lodash/remove";

import basil, { basilResetExcept } from "core/basil";
import { updateUsername } from "core/username";
import { detect } from "detect-browser";
import { forceArray } from "utilities/utils";
import { backendMapping } from "orm";

import Session, { getUserSession } from "utilities/session"; //or from "core/session"
import { setLocale, getLocale } from "utilities/i18n"; //or from "core/i18n"
import URI from "urijs";
import { TYPE_SIGNIN, TYPE_CONFIRM_PWD } from "app/liveShopping/backoffice/otm/containers/Connect/authentication";
import { MODERATION_COMPACT, MODERATION_TABS, MODERATION_COLUMNS } from "constants";

import dayjs from "dayjs";

const browserInfo = () => {
  const detected = detect();
  const { version } = detected || {};

  return {
    ...detected,
    versionString: version,
    version: parseInt(version || 0, 10),
  };
};

const getAnonymousUserParticipationValue = () => {
  if (isBoolean(basil.get("u:anonymousUserParticipation"))) {
    return basil.get("u:anonymousUserParticipation");
  }
  return true;
};

const getUsername = () => {
  const session = getUserSession();

  if (!session.token) {
    return null;
  }

  return basil.get(`u:${session.token}:username`);
};

const getDefaultConnection = () => {
  const url = new URI(window.location);
  let defaultData = {
    type: TYPE_SIGNIN,
    displayModal: false,
    forceConnection: false,
  };

  if (url.directory() === "/confirm-password") {
    defaultData.type = TYPE_CONFIRM_PWD;
  }

  if (url.hasQuery("connection-type")) {
    defaultData.type = url.query(true)["connection-type"];
  }

  if (url.hasQuery("connection-modal")) {
    defaultData.displayModal = url.query(true)["connection-modal"] !== "false";
  }

  return defaultData;
};

export const handleRequestStatusReducer = (state, action) => {
  return {
    ...state,
    requests_status: {
      ...state.requests_status,
      [action.payload.id]: action.payload.status,
    },
  };
};

// notifications :
const defaultState = {
  session: getUserSession(),
  browserInfo: browserInfo(),
  droppedFile: false,
  verifyUser: false,
  locale: getLocale(),
  socket: false,
  keyword: false,
  anonymousUserParticipation: getAnonymousUserParticipationValue(),
  displayPopupParticipation: false,
  username: getUsername(),
  error: false,
  notifications: {},
  timeline: { items: [], count: 0, lastDate: null },
  stream: { filter: "recent" },
  moderation: {
    activated: false,
    mode: MODERATION_COMPACT,
    currentTab: null,
  },
  archives: {},
  requests_status: {},
  connection: getDefaultConnection(),
  participants_count: 0,
  adminPreview: {},
  onlineUsers: {},
  invalidOtt: false,
  currentProject: null,
  customThemeColors: {},
  otoDashboardDates: {
    period: 'today',
    since: dayjs().startOf("day"),
    until: dayjs().endOf("day"),
  },
};
const getJsonResourceData = (resource) => {
  // obtain resource object
  //create resource object from resource
  try {
    let modelName = backendMapping[resource.type];

    let attributes = {
      ...resource.attributes,
      modelName,
    };

    if (resource.meta) {
      attributes._meta = resource.meta;
    }

    //newResource = setResource(session, resource);
    // handle relationships & included
    for (let name in resource.relationships) {
      set(attributes, `${name}`, get(resource, `relationships.${name}`, []));
    }
    return attributes;
  } catch (error) {
    console.error(error.message, resource);
    return;
  }
};
//do not take session as valid element
const getLastElementDate = (list) => {
  const lastElement = last(list);
  let date;
  switch (get(lastElement, "modelName")) {
    case "Survey":
      {
        const endedAt = get(lastElement, "ended_at");
        if (!isEmpty(endedAt)) {
          date = endedAt;
        } else {
          date = get(lastElement, "started_at");
        }
      }

      break;
    case "Media":
      date = get(lastElement, "live_at");
      break;
    case "Quote":
      date = get(lastElement, "published_at");
      break;
    // case "EventSession":
    //   date = get(lastElement, "started_at");
    //   break;
    default:
      console.error("unknown element in timeline or timeline empty", lastElement, list);
      return null;
  }
  return date;
};
const removeRedondantSurveys = (destination, source) => {
  let previousSurveys = destination.filter((item) => item.class_name === "Survey");

  previousSurveys = map(previousSurveys, (elem) => elem.hash);

  remove(source, (elem) => previousSurveys.includes(elem.hash));

  return source;
};

const updateTimeline = (state, action) => {
  action.resources = forceArray(action.resources);
  // let's map data AND included resources to store them into store and handle relationships
  let resources = [...action.resources];
  resources = resources.filter((resource) => resource.type !== "ApiFirewallException");
  let timelineChunck = map(resources, (resource) => {
    return getJsonResourceData(resource);
  });

  let lastDate = getLastElementDate(timelineChunck);
  let newItems = [];
  if (action.refresh) {
    newItems = timelineChunck;
  } else {
    timelineChunck = removeRedondantSurveys(get(state, "timeline.items", []), timelineChunck);
    lastDate = getLastElementDate(timelineChunck);
    newItems = concat(get(state, "timeline.items", []), timelineChunck);
  }

  return {
    ...state,
    timeline: {
      ...state.timeline,
      items: newItems,
      count: size(newItems),
      lastDate, //if lastDate is empty it means that we get to end of list and there is no older elements
    },
  };
};
const updateArchiveTimeline = (state, action) => {
  action.resources = forceArray(action.resources);
  // let's map data AND included resources to store them into store and handle relationships
  let resources = [...action.resources];
  resources = resources.filter((resource) => resource.type !== "ApiFirewallException");
  const archive = action.archive;
  if (!archive) {
    return state;
  }
  let timelineChunck = map(resources, (resource) => {
    return getJsonResourceData(resource);
  });

  let lastDate = getLastElementDate(timelineChunck);
  let newItems = [];
  if (action.refresh) {
    newItems = timelineChunck;
  } else {
    const previousChunk = get(state, `archives.${archive}.items`, []);
    const lastElement = last(previousChunk);

    if (isEqual(lastElement, timelineChunck[0])) {
      remove(timelineChunck, (chunck, index) => index === 0);
      lastDate = getLastElementDate(timelineChunck);
    }
    //if first element == last remove it
    newItems = concat(previousChunk, timelineChunck || []);
  }

  return {
    ...state,
    archives: {
      ...state.archives,
      [archive]: {
        items: newItems,
        count: size(newItems),
        lastDate,
      },

      //  lastDate, //if lastDate is empty it means that we get to end of list and there is no older elements
    },
  };
};

export default function applicationReducer(state = defaultState, action) {
  let fetchedOnce;

  switch (action.type) {
    case INVALID_OTT:
      return { ...state, invalidOtt: action.invalid };
    case SESSION_SET:
      return { ...state, session: new Session(action.session) };
    case SESSION_UNSET:
      return { ...state, session: new Session({}) };
    case FETCHED_ONCE:
      fetchedOnce = state.fetchedOnce || {};
      return { ...state, fetchedOnce: { ...fetchedOnce, [action.key]: true } };
    case DROP_FILE:
      return { ...state, droppedFile: action.file };
    case VERIFY_USER:
      return { ...state, verifyUser: action.verifyUser };
    case SET_LOCALE:
      setLocale(action.locale);
      return { ...state, locale: action.locale };
    case SOCKET_SET:
      return { ...state, socket: action.socket };
    case CURRENT_WIZ_SET:
      return { ...state, keyword: action.keyword };
    case CURRENT_WIZ_ERROR_SET:
      return { ...state, error: action.error };
    case UPDATE_TIMELINE: {
      return updateTimeline(state, action);
    }
    case UPDATE_ARCHIVE_STREAM: {
      return updateArchiveTimeline(state, action);
    }
    case UPDATE_REQUEST_STATUS:
      return handleRequestStatusReducer(state, action);
    case LOG_OUT: {
      basilResetExcept(["u:redirectToWiz", "cookies.consent"]);
      break;
    }
    case CONNECTION_SET: {
      let connection = { ...state.connection, ...action.connection };
      return { ...state, connection };
    }
    case UPDATE_STREAM:
      return { ...state, stream: action.stream };

    case DISPLAY_POPUP_PARTICIPATION_SET:
      return {
        ...state,
        displayPopupParticipation: action.displayPopupParticipation,
        popupParticipationCallback: action.callback,
      };
    case ANONYMOUS_USER_PARTICIPATION_SET:
      basil.set("u:anonymousUserParticipation", action.anonymousUserParticipation);
      return {
        ...state,
        anonymousUserParticipation: action.anonymousUserParticipation,
      };
    case USERNAME_SET:
      updateUsername(action.username);

      return {
        ...state,
        username: action.username,
      };
    case UPDATE_PREVIEW:
      return {
        ...state,
        adminPreview: action.preview,
      };
    case UPDATE_USER_ACTION: {
      const userAction = action.userAction;
      const user = userAction.user;
      let online = false;
      if (userAction.action === "join") {
        online = true;
      }

      return {
        ...state,
        onlineUsers: {
          ...state.onlineUsers,
          [user]: online,
        },
      };
    }
    case UPDATE_CONNECTED_USERS_ACTION: {
      const users = action.users || {};

      return {
        ...state,
        onlineUsers: {
          ...state.onlineUsers,
          ...users,
        },
      };
    }

    // case SET_TIMELINE_STATUS:
    //   return { ...state, notifications: updateNotifications(state, action) };
    // case NOTIFICATION_INCREMENT:
    //   return { ...state, notifications: updateNotifications(state, action) };
    // case NOTIFICATION_RESET:
    //   return { ...state, notifications: updateNotifications(state, action) };
    case UPDATE_PARTICIPANTS_COUNT:
      return {
        ...state,
        participants_count: isNumber(action.count) ? action.count : 0,
      };
    case UPDATE_MODERATION:
      return {
        ...state,
        moderation: {
          ...state.moderation,
          ...action.payload,
        },
      };
    case SET_CURRENT_PROJECT:
      return { ...state, currentProject: action.project };
    case SET_CUSTOM_THEME_COLORS:
      return { ...state, customThemeColors: action.setCustomThemeColors };
    case SET_OTO_DASHBOARD_DATES:
      return { ...state, otoDashboardDates: {
        since: action.since,
        until: action.until,
        period: action.period,
      }};
  }

  return state;
}
