import React from "react";
import Radio from "@material-ui/core/Radio";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  radio: {
    marginLeft: -5,
    padding: 5,
    "& svg": {
      fontSize: "30px",
    },
    "& svg:first-of-type": {
      color: "transparent",
      backgroundColor: theme.palette.background.secondaryBox,
      borderRadius: "100%",
    },
    "& svg:last-of-type": {
      color: theme.palette.secondary.main,
    },
  },
  checked: {
    color: theme.palette.secondary.main,
  },
}));
const Component = ({ disabled, name, value, onChange, checked, color }) => {
  const classes = useStyles();
  return (
    <Radio
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      checked={checked}
      classes={clsx({ [classes.radio]: true, [classes.checked]: checked })}
      color={color}
    />
  );
};

Component.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  color: PropTypes.string,
};

Component.defaultProps = {
  disabled: false,
  name: "",
  onChange: () => {},
  checked: false,
  color: "secondary",
};

export default Component;
