import React from "react";
import PropTypes from "prop-types";

const AddTask = ({ color, size, ...rest }) => {
  return (
<svg width="207" height="164" viewBox="0 0 207 164" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M101.409 156C144.236 156 178.979 121.085 178.979 77.914C178.979 34.7431 144.236 0 101.409 0C58.5821 0 23.839 34.9151 23.839 78.086C23.839 121.257 58.5821 156 101.409 156Z" fill="#EAEEF9"/>
<path d="M139.592 154.968C127.724 155.312 113.621 154.968 103.473 154.796C93.4973 155.14 79.2217 155.312 67.354 154.968C79.0497 148.26 83.1776 135.876 83.6936 127.621H122.909C123.941 135.704 128.068 148.26 139.592 154.968Z" fill="url(#paint0_linear_5429_41952)"/>
<g filter="url(#filter0_d_5429_41952)">
<path d="M177.603 23.5636V126.245C177.603 128.825 175.539 130.717 173.303 130.717H34.5026C32.0947 130.717 30.2028 128.653 30.2028 126.245V23.5636C30.2028 21.1557 32.2667 19.0917 34.5026 19.0917H173.131C175.711 19.0917 177.603 21.1557 177.603 23.5636Z" fill="white"/>
</g>
<path d="M177.603 23.5636V111.969H30.2028V23.5636C30.2028 21.1557 32.2667 19.0917 34.5026 19.0917H173.131C175.711 19.0917 177.603 21.1557 177.603 23.5636Z" fill="#989FB0"/>
<path d="M171.928 24.7673H35.191V106.981H172.1V24.7673H171.928Z" fill="white"/>
<path d="M103.645 124.591C105.709 124.591 107.257 123.043 107.257 120.979C107.257 118.915 105.709 117.367 103.645 117.367C101.581 117.367 100.033 118.915 100.033 120.979C100.033 123.043 101.581 124.591 103.645 124.591Z" fill="#D0D8E6"/>
<path d="M164.015 39.9033V50.3951C164.015 51.943 162.811 53.147 161.263 53.147H45.6822C44.1343 53.147 42.9303 51.943 42.9303 50.3951V39.9033C42.9303 38.3554 44.1343 37.1514 45.6822 37.1514H161.091C162.811 36.9794 164.015 38.3554 164.015 39.9033Z" fill="#E5E9F1"/>
<path d="M59.0981 36.9792V53.3187H45.5105C43.9625 53.3187 42.7585 52.1148 42.7585 50.5668V40.0751C42.7585 38.5271 43.9625 37.3231 45.5105 37.3231L59.0981 36.9792Z" fill="#E3EAF2"/>
<path opacity="0.8" d="M164.015 61.9186V72.4103C164.015 73.9582 162.811 75.1622 161.263 75.1622H45.6822C44.1343 75.1622 42.9303 73.9582 42.9303 72.4103V61.9186C42.9303 60.3706 44.1343 59.1666 45.6822 59.1666H161.091C162.811 58.9946 164.015 60.3706 164.015 61.9186Z" fill="#E5E9F1"/>
<path d="M59.0981 36.9792V53.3187H45.5105C43.9625 53.3187 42.7585 52.1148 42.7585 50.5668V40.0751C42.7585 38.5271 43.9625 37.3231 45.5105 37.3231L59.0981 36.9792Z" fill="#989FB0"/>
<path d="M50.4985 48.6746C50.1545 48.6746 49.8106 48.5026 49.6386 48.3306L47.0586 45.7507C46.5426 45.2347 46.5426 44.5467 47.0586 44.0307C47.5746 43.5147 48.2626 43.5147 48.7786 44.0307L50.3265 45.5787L54.1104 41.7948C54.6264 41.2788 55.3144 41.2788 55.8304 41.7948C56.3464 42.3108 56.3464 42.9988 55.8304 43.5147L51.1865 48.1586C51.0145 48.5026 50.6705 48.6746 50.4985 48.6746Z" fill="white"/>
<path opacity="0.5" d="M164.015 83.934V94.4257C164.015 95.9737 162.811 97.1776 161.263 97.1776H45.6822C44.1343 97.1776 42.9303 95.9737 42.9303 94.4257V83.934C42.9303 82.386 44.1343 81.1821 45.6822 81.1821H161.091C162.811 81.0101 164.015 82.214 164.015 83.934Z" fill="#E5E9F1"/>
<path opacity="0.3" d="M164.015 105.777V106.981H42.7585V105.777C42.7585 104.229 43.9625 103.026 45.5105 103.026H161.092C162.811 103.026 164.015 104.229 164.015 105.777Z" fill="#E5E9F1"/>
<path d="M41.3827 149.292C41.3827 152.216 38.9748 154.624 36.0508 154.624H6.46761C3.54368 154.624 1.13574 152.216 1.13574 149.292V79.8059C1.13574 76.8819 3.54368 74.474 6.46761 74.474H35.8789C38.8028 74.474 41.2107 76.8819 41.2107 79.8059L41.3827 149.292Z" fill="white"/>
<path d="M36.0508 74.3018C38.9748 74.3018 41.3827 76.7098 41.3827 79.6337V149.12C41.3827 152.044 38.9748 154.452 36.0508 154.452H6.46761C3.54368 154.452 1.13574 152.044 1.13574 149.12V79.8057C1.13574 76.8818 3.54368 74.4738 6.46761 74.4738L36.0508 74.3018Z" fill="#989FB0"/>
<path d="M38.8029 79.8059V149.292C38.8029 150.668 37.7709 152.044 36.223 152.044H36.051H6.46775H6.29575C4.91979 152.044 3.71582 150.84 3.71582 149.292V79.8059C3.71582 78.2579 4.91979 77.054 6.29575 77.054H10.5956C11.2836 77.054 11.6276 77.57 11.6276 78.086C11.6276 79.1179 12.4876 80.1499 13.6916 80.1499H28.6552C29.6872 80.1499 30.7191 79.2899 30.7191 78.086C30.7191 77.398 31.2351 77.054 31.5791 77.054H35.707C37.427 76.882 38.8029 78.2579 38.8029 79.8059Z" fill="white"/>
<path d="M23.8388 78.0862H19.8829C19.5389 78.0862 19.1949 77.7422 19.1949 77.3983C19.1949 77.0543 19.5389 76.7103 19.8829 76.7103H23.8388C24.1828 76.7103 24.5268 77.0543 24.5268 77.3983C24.3548 77.7422 24.0108 78.0862 23.8388 78.0862Z" fill="white"/>
<path d="M17.6473 78.0862H17.3033C16.9593 78.0862 16.6154 77.7422 16.6154 77.3983C16.6154 77.0543 16.9593 76.7103 17.3033 76.7103H17.6473C17.9913 76.7103 18.3353 77.0543 18.3353 77.3983C18.3353 77.7422 17.9913 78.0862 17.6473 78.0862Z" fill="white"/>
<path d="M34.6747 102.853H7.84337C6.98339 102.853 6.46741 102.165 6.46741 101.477V87.8897C6.46741 87.0297 7.15539 86.5137 7.84337 86.5137H34.8467C35.7067 86.5137 36.2226 87.2017 36.2226 87.8897V101.477C36.0506 102.165 35.5347 102.853 34.6747 102.853Z" fill="#989FB0"/>
<path d="M20.2275 98.2099C19.8835 98.2099 19.5396 98.0379 19.3676 97.8659L16.7876 95.286C16.2716 94.77 16.2716 94.082 16.7876 93.5661C17.3036 93.0501 17.9916 93.0501 18.5076 93.5661L20.0555 95.114L23.8394 91.3301C24.3554 90.8141 25.0434 90.8141 25.5594 91.3301C26.0754 91.8461 26.0754 92.5341 25.5594 93.0501L21.0875 97.5219C20.7435 98.0379 20.3995 98.2099 20.2275 98.2099Z" fill="white"/>
<path opacity="0.8" d="M34.6747 123.149H7.84337C6.98339 123.149 6.46741 122.461 6.46741 121.773V108.186C6.46741 107.326 7.15539 106.81 7.84337 106.81H34.8467C35.7067 106.81 36.2226 107.498 36.2226 108.186V121.773C36.0506 122.461 35.5347 123.149 34.6747 123.149Z" fill="#E5E9F1"/>
<path opacity="0.5" d="M34.6747 143.445H7.84337C6.98339 143.445 6.46741 142.757 6.46741 142.069V128.481C6.46741 127.621 7.15539 127.105 7.84337 127.105H34.8467C35.7067 127.105 36.2226 127.793 36.2226 128.481V142.069C36.0506 142.929 35.5347 143.445 34.6747 143.445Z" fill="#E5E9F1"/>
<path opacity="0.3" d="M36.0506 149.12V151.872H35.8787H6.46741H6.29541V149.12C6.29541 148.26 6.98339 147.744 7.67137 147.744H34.6747C35.5347 147.744 36.0506 148.432 36.0506 149.12Z" fill="#E5E9F1"/>
<path d="M40.8666 31.131C41.7265 31.131 42.4145 30.443 42.4145 29.583C42.4145 28.723 41.7265 28.0351 40.8666 28.0351C40.0066 28.0351 39.3186 28.723 39.3186 29.583C39.3186 30.443 40.0066 31.131 40.8666 31.131Z" fill="#E3EAF2"/>
<path d="M45.8544 31.131C46.7143 31.131 47.4023 30.443 47.4023 29.583C47.4023 28.723 46.7143 28.0351 45.8544 28.0351C44.9944 28.0351 44.3064 28.723 44.3064 29.583C44.3064 30.443 44.9944 31.131 45.8544 31.131Z" fill="#E3EAF2"/>
<path d="M50.8428 31.131C51.7027 31.131 52.3907 30.443 52.3907 29.583C52.3907 28.723 51.7027 28.0351 50.8428 28.0351C49.9828 28.0351 49.2948 28.723 49.2948 29.583C49.2948 30.443 49.9828 31.131 50.8428 31.131Z" fill="#E3EAF2"/>
<path d="M206.177 127.179C205.489 130.103 204.457 133.199 203.253 135.779C199.985 142.142 194.825 147.13 188.462 150.398C181.926 153.666 174.186 155.042 166.446 153.322C148.215 149.538 136.519 131.651 140.303 113.419C144.087 95.1876 161.802 83.3199 180.034 87.2758C186.57 88.6518 192.245 91.9197 197.061 96.3916C205.145 104.647 208.413 116.171 206.177 127.179Z" fill="url(#paint1_linear_5429_41952)"/>
<path d="M183.818 117.374H176.078V109.635C176.078 108.087 174.874 106.711 173.154 106.711C171.606 106.711 170.23 107.915 170.23 109.635V117.374H162.49C160.942 117.374 159.567 118.578 159.567 120.298C159.567 122.018 160.77 123.222 162.49 123.222H170.23V130.962C170.23 132.51 171.434 133.886 173.154 133.886C174.702 133.886 176.078 132.682 176.078 130.962V123.222H183.818C185.366 123.222 186.742 122.018 186.742 120.298C186.914 118.578 185.538 117.374 183.818 117.374Z" fill="white"/>
<path d="M1.67041 155.155L200.342 155.155" stroke="#989FB0" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.5468 67.4384C17.3943 67.4384 19.7027 65.13 19.7027 62.2825C19.7027 59.435 17.3943 57.1266 14.5468 57.1266C11.6992 57.1266 9.39087 59.435 9.39087 62.2825C9.39087 65.13 11.6992 67.4384 14.5468 67.4384Z" fill="#EAEEF9"/>
<path d="M9.39037 50.7131C11.335 50.7131 12.9115 49.1366 12.9115 47.192C12.9115 45.2473 11.335 43.6709 9.39037 43.6709C7.44572 43.6709 5.86926 45.2473 5.86926 47.192C5.86926 49.1366 7.44572 50.7131 9.39037 50.7131Z" fill="#EAEEF9"/>
<path d="M156.553 9.44999C158.497 9.44999 160.074 7.87354 160.074 5.92889C160.074 3.98424 158.497 2.40779 156.553 2.40779C154.608 2.40779 153.032 3.98424 153.032 5.92889C153.032 7.87354 154.608 9.44999 156.553 9.44999Z" fill="#EAEEF9"/>
<path d="M193.802 80.4081C197.414 80.4081 200.342 77.4804 200.342 73.8689C200.342 70.2574 197.414 67.3297 193.802 67.3297C190.191 67.3297 187.263 70.2574 187.263 73.8689C187.263 77.4804 190.191 80.4081 193.802 80.4081Z" fill="#EAEEF9"/>
<defs>
<filter id="filter0_d_5429_41952" x="8.20276" y="8.09172" width="191.4" height="155.625" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="11"/>
<feGaussianBlur stdDeviation="11"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5429_41952"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5429_41952" result="shape"/>
</filter>
<linearGradient id="paint0_linear_5429_41952" x1="103.487" y1="154.205" x2="103.487" y2="129.713" gradientUnits="userSpaceOnUse">
<stop stop-color="#E8ECF5"/>
<stop offset="0.9913" stop-color="#BDC4D7"/>
</linearGradient>
<linearGradient id="paint1_linear_5429_41952" x1="139.561" y1="120.298" x2="206.876" y2="120.298" gradientUnits="userSpaceOnUse">
<stop stopColor="#B0BACC"/>
<stop offset="1" stopColor="#969EAE"/>
</linearGradient>
</defs>
</svg>
  );
};

AddTask.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

AddTask.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default AddTask;
