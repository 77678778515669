import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Exclamation from "react-bootstrap-icons/dist/icons/exclamation-circle-fill";

export const useStyles = makeStyles(
  (theme) => ({
    input: {
      borderWidth: 1,
      padding: "10px 12px",
      paddingRight: (props) => (props.isMobileChat ? "30px" : null),
      cursor: (props) => (props.readOnly ? "default" : "auto"),
      "&.MuiInputBase-input": {
        "&::placeholder": {
          color: (props) => (props.darkMode ? theme.palette.colors.white50 : theme.palette.colors.greyDark),
        },
      },
      "&.MuiInputBase-inputAdornedStart": {
        padding: "10px 12px 10px 8px",
      },
      "&.MuiInputBase-inputAdornedEnd": {
        padding: "10px 8px 10px 12px",
      },
    },
    forceWidth: {
      width: (props) => (props.width ? props.width : props.fullWidth ? "100%" : "auto"),
    },
    textFieldRoot: {
      borderRadius: "8px",
      fontWeight: 400,
      color: (props) => (props.darkMode ? theme.palette.colors.white : theme.palette.colors.blackMedium),
      fontSize: (props) => (props.size === "medium" ? "14px" : "12px"),
      lineHeight: (props) => (props.size === "medium" ? "130%" : "100%"),
      backgroundColor: (props) => (props.darkMode ? theme.palette.colors.white10 : theme.palette.colors.white),
      borderColor: theme.palette.colors.greyMedium,
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.colors.grey,
      },
      "&:hover": {
        borderColor: theme.palette.colors.grey,
      },
      "&.MuiInputBase-adornedStart": {
        paddingLeft: "12px",
      },
      "&.MuiInputBase-adornedEnd": {
        paddingRight: "12px",
      },
      "&.MuiInputBase-input": {
        padding: "10px 12px",
        fontWeight: 400,
        fontSize: (props) => (props.size === "medium" ? "14px" : "12px"),
        lineHeight: (props) => (props.size === "medium" ? "130%" : "100%"),
        color: (props) => (props.darkMode ? theme.palette.colors.white : theme.palette.colors.blackMedium),
        "&::placeholder": {
          color: (props) => (props.darkMode ? theme.palette.colors.white10 : theme.palette.colors.greyDark),
        },
        "&.Mui-error": {
          color: theme.palette.colors.error,
        },
      },
    },
    error: {
      color: theme.palette.colors.error,
      borderColor: theme.palette.colors.error,
      "&:hover": {
        borderColor: theme.palette.colors.error,
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: 1,
        borderColor: theme.palette.colors.error,
      },
      "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.colors.error,
      },
    },
    inputFocused: {
      color: (props) => (props.darkMode ? theme.palette.colors.white : theme.palette.colors.blackMedium),
      backgroundColor: (props) => (props.darkMode ? theme.palette.colors.white15 : theme.palette.colors.white),
      // borderWidth: "1px",
      "&:hover": {
        backgroundColor: (props) => (props.darkMode ? theme.palette.colors.white15 : theme.palette.colors.white),
      },
      "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: (props) => (props.darkMode ? "none" : `1px solid ${theme.palette.colors.primary}`),
        boxShadow: (props) => (props.darkMode ? "none" : `0px 0px 0px 3px ${theme.palette.colors.primary10}`),
      },
      "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.colors.error,
        borderWidth: 1,
      },
      "&.MuiOutlinedInput-root.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.colors.error,
        borderWidth: 1,
        boxShadow: `0px 0px 0px 3px ${theme.palette.colors.error10}`,
      },
    },
    errorLabel: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      marginTop: "8px",
    },
    errorMessage: {
      color: theme.palette.colors.alert,
      fontSize: "12px",
      fontWeight: "normal",
      marginHorizontal: "5px",
    },
    errorIcon: {
      color: theme.palette.colors.alert,
      marginRight: "5px",
    },
    multiline: {
      padding: "0",
    },
    adornment: {
      height: "auto",
      color: theme.palette.colors.greyDark,
      margin: "0px",
      "& p": {
        lineHeight: "0px",
      },
      "& .MuiInputAdornment-root": {
        margin: "0px",
      },
    },
  }),
  {
    name: "OneOutlinedInput",
  }
);

export const CustomTextField = forwardRef(
  (
    {
      errorMessage,
      readOnly,
      error,
      mode,
      width,
      fullWidth,
      size,
      startAdornment, // start icon of input
      endAdornment, // start icon of input
      isMobileChat,
      ...rest
    },
    ref
  ) => {
    const classes = useStyles({
      readOnly,
      darkMode: mode === "dark",
      error,
      width,
      fullWidth,
      size,
      isMobileChat,
    });
    //inputProps={{ref: (input)=> ref = input}}
    return (
      <Box style={{ width: fullWidth ? "100%" : "auto" }}>
        <OutlinedInput
          inputProps={{ ref }}
          classes={{
            root: clsx({
              [classes.textFieldRoot]: true,
              [classes.forceWidth]: width || fullWidth,
            }),
            error: classes.error,
            focused: classes.inputFocused,
            input: classes.input,
            multiline: classes.multiline,
          }}
          readOnly={readOnly}
          error={error}
          startAdornment={
            startAdornment ? (
              <InputAdornment className={classes.adornment} position="start">
                {startAdornment}
              </InputAdornment>
            ) : null
          }
          endAdornment={
            endAdornment ? (
              <InputAdornment className={classes.adornment} position="end">
                {endAdornment}
              </InputAdornment>
            ) : null
          }
          {...rest}
        />
        {errorMessage && (
          <Box className={classes.errorLabel}>
            <Box className={classes.errorIcon}>
              <Exclamation width={12} height={12} />
            </Box>
            <Box className={classes.errorMessage}>{errorMessage}</Box>
          </Box>
        )}
      </Box>
    );
  }
);
CustomTextField.displayName = "CustomTextField";
CustomTextField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  multiline: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  readOnly: PropTypes.bool,
  mode: PropTypes.string,
  error: PropTypes.bool,
  size: PropTypes.oneOf(["small", "medium"]),
};
CustomTextField.defaultProps = {
  name: "default",
  id: "default",
  type: "text",
  placeholder: "",
  fullWidth: false,
  required: true,
  onChange: () => { },
  error: false,
  disabled: false,
  value: "",
  multiline: false,
  mode: "light",
  autoComplete: "off",
};

export default CustomTextField;
