import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import AvailabilitySwitcher from "../AvailabilitySwitcher";
import { useDispatch, useSelector } from "react-redux";
import { getOwnAccessesSelector, visitorsSelector } from "app/state/ducks/ressources/selectors";
import { resourceFetch } from "app/state/ducks/ressources/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    gap: "16px",
    width: "100%",
    height: "94px",
    boxShadow: props => props.isSelected ? "0px 2px 15px rgba(240, 78, 55, 0.1)" : "0px 4px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: "12px",
    border: props => props.isSelected && "1px solid rgba(240, 78, 55, 0.3)",
    padding: "0 24px",
    flex: "none",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    textAlign: "center",
    color: props => props.isSelected ? theme.palette.colors.primary : "#000000",
  },
  available: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "64px",
  },
  waitingNumber: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    color: props => props.isSelected ? theme.palette.colors.primary : "#000000",
  },
  waitingCustomers: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.grey500,
  },
}));

const Queue = ({ access }) => {
  const accesses = useSelector(getOwnAccessesSelector)
  const classes = useStyles({ isSelected: access.available });
  const visitors = useSelector(visitorsSelector).filter((visitor) => visitor?.queue.group.id === access.group.id);
  const availableVisitors = visitors?.filter(({ status }) => status === "waiting" || status === "ongoing" || status === "ringing");
  const dispatch = useDispatch();

  useEffect(() => {
    accesses.map((access) => {
      dispatch(
        resourceFetch("queue", {
          slug: ["queues", access.group.queue.hash, "visitors"],
          once: true,
        })
      );
    });
  }, []);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{access.group.title}</Typography>
      <Box className={classes.available}>
        <Box>
          <Typography className={classes.waitingNumber}>{availableVisitors.length}</Typography>
          <Typography className={classes.waitingCustomers}>{availableVisitors.length > 1 ? _t("Waiting customers") : _t("Waiting customer")}</Typography>
        </Box>
        <AvailabilitySwitcher access={access} />
      </Box>
    </Box>
  );
};

Queue.propTypes = {};

Queue.defaultProps = {};

export default Queue;
