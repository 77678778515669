import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { _t } from "i18n";
import ExportCSVButton from "./ExportCSVButton";
import Dropdown from "app/liveShopping/backoffice/otm/components/reusables/Dropdown";
import { DateRangePicker } from "@tremor/react";
import { useSelector } from "react-redux";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxShadow: "0px 15px 10px -15px rgba(240, 240, 244, 1)",
    padding: "16px 30px",
    backgroundColor: "transparent",
  },
  time: {
    display: "flex",
    gap: "10px",
  },
}));

const Header = ({ setDates, dates, setSelectedVendor, optionsVendors }) => {
  const classes = useStyles();
  const currentProject = useSelector(currentProjectSelector);
  const [datesLocal, setDatesLocal] = useState([dates.since, dates.until, dates.period]);

  useEffect(() => {
    if (!datesLocal[0] || !datesLocal[1]) return;
    const date = new Date(datesLocal[1]);
    date.setHours(23, 59, 59, 999);
    setDates([datesLocal[0], date, datesLocal[2]]);
  }, [datesLocal]);

  return (
    <Box className={classes.header}>
      <Typography className={classes.title}>{currentProject?.title}</Typography>
      <Box className={classes.time}>
        <DateRangePicker
          value={datesLocal}
          onValueChange={setDatesLocal}
          options={
            [{ value: 'today', text: _t("Today"), startDate: new Date(dayjs().startOf('day')) },
            { value: 'last_week', text: _t("Last week"), startDate: new Date(dayjs().subtract(7, 'days')) },
            { value: 'last_month', text: _t("Last month"), startDate: new Date(dayjs().subtract(30, 'days')) },
            { value: 'last_year', text: _t("Last year"), startDate: new Date(dayjs().subtract(365, 'days')) },
            ]}
          defaultValue={datesLocal}
        />
        <Dropdown options={optionsVendors} onChange={(e) => setSelectedVendor(e.target.value)} />
        <ExportCSVButton projectId={currentProject?.id} until={dates.until} since={dates.since} />
      </Box>
    </Box>
  );
};

export default Header;