import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Plus from "react-bootstrap-icons/dist/icons/plus";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import * as filestack from 'filestack-js';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    width: "125px",
    height: "210px",
    border: ({ value }) => !value ? "2px dashed #E2E4E8" : "1px solid #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "12px",
    cursor: ({ value }) => !value ? "pointer" : null,
    backgroundImage: ({ value }) => value ? `url(${value})` : "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
  },
  remove: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
    backgroundColor: "black",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    color: "white",
    opacity: 0.5,
  },
}));

const FILE_STACK_API_KEY = "AuYfvo0AfSNWvP4AaloUSz";

const Dropzone = ({ value, setValue }) => {
  const classes = useStyles({ value });

  const onRemove = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setValue(null);
  };

  const client = filestack.init(FILE_STACK_API_KEY);

  const openPicker = () => {
    client.picker({
      maxFiles: 1,
      uploadInBackground: true,
      onUploadDone: (res) => {
        setValue(res.filesUploaded[0].url);
      },
    }).open();
  };

  return (
    <Box className={classes.root} onClick={value ? null : openPicker}>
      {!value && (
        <Typography className={classes.title}>
          <Plus size={40} />
        </Typography>
      )}
      {
        // if value is not null, display the trash icon
        value && (
          <Box className={classes.remove} onClick={(e) => onRemove(e)} position="absolute" top="5px" right="5px">
            <Trash />
          </Box>
        )
      }
    </Box>
  );
}

export default Dropzone;