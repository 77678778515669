import { Box, Typography, makeStyles } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { genericResourceSelector } from "app/state/ducks/ressources/selectors";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { _t } from "utilities/i18n";
import Schedule from "./ScheduleEditor";
import ResourceFactory from "orm/resources";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import emitter from "utilities/emitter";
import Override from "./Override";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "15px 20px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  components: {
    display: "flex",
  }
}));

const Availability = () => {
  const classes = useStyles();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));
  const project = useSelector(currentProjectSelector);
  const { group } = useParams();
  const currentGroup = project.groups.toRefArray().find((g) => g.hash === group);
  const calendar = useSelector(state => genericResourceSelector(state, {
    type: "Calendar",
    filter: (cal) => {
      return cal.group?.hash === currentGroup.hash;
    },
  }));
  const [schedule, setSchedule] = useState(calendar?.configuration || []);
  const [override, setOverride] = useState(calendar?.overrides || []);
  const dispatch = useDispatch();

  const newCalendar = resourceFactory.create("Calendar", { configuration: schedule, overrides: override });

  const handleSave = () => {
    dispatch(
      resourceEdit(newCalendar, {
        slug: ["calendars", calendar.hash],
        patch: ["configuration", "overrides"],
        callback: (error, response) => {
          if (error) {
            console.log(error);
          } else {
            emitter.emit(EVENT_FLASH_SUCCESS, _t("The availability has been updated"));
          }
        }
      }
      ));
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Availability")}</Typography>
      <Box className={classes.components}>
        <Schedule schedule={schedule} setSchedule={setSchedule} />
        <Override override={override} setOverride={setOverride} />
      </Box>
      <Button onClick={handleSave} style={{ marginTop: "20px", position: "fixed", right: "20px", bottom: "20px" }}>{_t("Save")}</Button>
    </Box>
  );
};

export default Availability;
