import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, ButtonBase, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { _t } from "core/i18n";
import { mobileLayoutSelector, desktopLayoutSelector } from "liveShoppingDucks/selectors";
import { useSelector, useDispatch } from "react-redux";
import { setOpenCart, setOpenCatalog } from "liveShoppingDucks/actions";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import Footer from "./Footer";
import Product from "./Product";
import LayoutWrapper from "app/liveShopping/frontoffice/otm/components/LayoutWrapper";
import { allProductsSelector, cartIsOpenSelector, playerLoadedSelector } from "app/state/ducks/liveShopping/selectors";
import { currentCartSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  header: {
    flex: "0 0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: (props) => (props.isMobile ? "center" : "flex-start"),
    marginTop: 30,
    paddingLeft: 20,
    paddingRight: (props) => (props.isDesktop ? 20 : 0),
    marginBottom: (props) => (props.isMobile ? 110 : 20),
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "130%",
  },
  products: {
    flex: "1 1 auto",
    overflow: "auto",
    width: "100%",
    height: "100%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  empty: {
    marginTop: "50px",
    textAlign: "center",
  },
  close: {
    height: "44px",
    width: "44px",
    right: "24px",
    borderRadius: "99px",
    padding: "12px",
    background: theme.palette.colors.lightGrey,
  },
}));

const Cart = ({ }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const isDesktop = useSelector(desktopLayoutSelector);
  const playerIsLoaded = useSelector(playerLoadedSelector);
  const dispatch = useDispatch();
  const cartIsOpen = useSelector(cartIsOpenSelector);
  const classes = useStyles({ isMobile, isDesktop });

  const products = useSelector(allProductsSelector);

  const cart = useSelector(currentCartSelector);
  const totalProducts = cart.products.reduce((total, { quantity }) => total + quantity, 0);

  const handleQuitCart = () => {
    if (isMobile) {
      dispatch(setOpenCart(false));
    } else {
      dispatch(setOpenCart(false));
      dispatch(setOpenCatalog(true));
    }
  };

  return (
    <LayoutWrapper layoutIsOpen={cartIsOpen}>
      {/* Header */}
      <Box className={classes.header}>
        <Typography className={classes.title}>
          {_t("Your basket")}&nbsp;{`(${totalProducts})`}
        </Typography>
        {isDesktop && (
          <ButtonBase onClick={handleQuitCart}>
            <XLg size={20} />
          </ButtonBase>
        )}
        {isMobile && (
          <ButtonBase onClick={handleQuitCart} className={classes.close}>
            <XLg size={18} />
          </ButtonBase>
        )}
      </Box>

      {/* Content */}
      <Box className={classes.products}>
        {cart.products.length === 0 && <Typography className={classes.empty}>{_t("Your basket is currently empty.")}</Typography>}

        {cart.products.map(({ product_id, variant_id, quantity }, i) => {
          const product = products.find(({ hash }) => hash === product_id);
          if (!product) return null;
          return <Product key={variant_id} quantity={quantity} variant_id={variant_id} product_id={product_id} product={product} isLastProduct={i + 1 === cart.products.length} />;
        })}
      </Box>

      {/* Footer */}
      <Footer />
    </LayoutWrapper>
  );
};

Cart.propTypes = { isMobile: PropTypes.bool, cartIsOpen: PropTypes.bool };

Cart.defaultProps = {};

export default Cart;
