import { all, takeEvery } from "redux-saga/effects";

import {
  GET_VISIO_CREDENTIALS,
  SET_SESSION,
  GET_DEVICES,
  INIT_PUBLISHER,
  DESTROY_PUBLISHER,
  PUBLISH_STREAM,
  UNPUBLISH_STREAM,
  SET_SOURCE,
  MUTE,
  MUTE_OTHER,
  EJECT,
  SET_FOCUS,
  JOIN_SESSION,
  CHECK_PERMISSIONS,
  BIND_DOM,
  UNSUBSCRIBE_STREAM,
  SET_ELEMENT_POSITION,
  DISCONNECT_SESSION,
  START_VISIO,
  CLEAR_VISIO,
} from "./actions";

import { initSession, disconnectSession } from "./sagas/initSession";
import { getCredentials } from "./sagas/getCredentials";
import { subscribeToPublishers } from "./sagas/subscribeToPublishers";
import { subscribeToSignals } from "./sagas/subscribeToSignals";
import { getDevices } from "./sagas/getDevices";
import { checkPermissionGranted } from "./sagas/getDevices";
import { initPublisher } from "./sagas/initPublisher";
import { destroyPublisher } from "./sagas/destroyPublisher";
import { publishStream } from "./sagas/publishStream";
import { unpublishStream } from "./sagas/unpublishStream";
import { setSource } from "./sagas/setSource";
import { muteSource } from "./sagas/muteSource";
import { mute, eject } from "./sagas/signaling";
import { focus, setElementPosition } from "./sagas/focus";
import { bindToDom, unsubscribe } from "./sagas/bindToDom";
import { startVisio } from "./sagas/startVisio";
import { clearVisio } from "./sagas/clearVisio";

export default function* visioSaga() {
  yield all([
    yield takeEvery(GET_VISIO_CREDENTIALS, getCredentials),
    yield takeEvery(JOIN_SESSION, initSession),
    yield takeEvery(START_VISIO, startVisio),
    yield takeEvery(SET_SESSION, subscribeToPublishers),
    yield takeEvery(SET_SESSION, subscribeToSignals),
    yield takeEvery(DISCONNECT_SESSION, disconnectSession),
    yield takeEvery(GET_DEVICES, getDevices),
    yield takeEvery(INIT_PUBLISHER, initPublisher),
    yield takeEvery(DESTROY_PUBLISHER, destroyPublisher),
    yield takeEvery(PUBLISH_STREAM, publishStream),
    yield takeEvery(UNPUBLISH_STREAM, unpublishStream),
    yield takeEvery(SET_SOURCE, setSource),
    yield takeEvery(MUTE, muteSource),
    yield takeEvery(MUTE_OTHER, mute),
    yield takeEvery(EJECT, eject),
    yield takeEvery(SET_FOCUS, focus),
    yield takeEvery(CHECK_PERMISSIONS, checkPermissionGranted),
    yield takeEvery(BIND_DOM, bindToDom),
    yield takeEvery(UNSUBSCRIBE_STREAM, unsubscribe),
    yield takeEvery(SET_ELEMENT_POSITION, setElementPosition),
    yield takeEvery(CLEAR_VISIO, clearVisio),
  ]);
}
