import { _t } from "core/i18n";
import { getParsedPercent } from "utilities/utils";
import get from "lodash/get";
import toNumber from "lodash/toNumber";

export const TEMPLATE_KEYS = [
  "invitation_participant",
  "invitation_relaunch_participant",
  "registration_success",
  "reminder_day",
  "reminder_minutes",
  "wiz_ended_having_participated",
  "wiz_ended_not_having_participated",
  "custom_email",
];

//to complete
export const getMailjetStatusLabel = (status) => {
  let result = "-";
  switch (status) {
    case "processed":
    case "queued": {
      result = _t("Sending");
      break;
    }
    case "sent":
      result = _t("Sent");
      break;
    case "opened":
      result = _t("Opened");
      break;
    case "clicked":
      result = _t("Clicked");
      break;
    case "spam":
      result = _t("Spam");
      break;
    case "unsub":
      result = _t("Unsubscribed");
      break;
    case "blocked":
      result = _t("Blocked");
      break;
    case "bounce":
    case "hardbounce":
    case "softbounce":
    case "deferred":
      result = _t("Bounced");
      break;
    case "error":
      result = _t("Error");
      break;
    case "custom_email":
      title = _t("Custom email");
      break;
    default:
  }
  return result;
};
// mailjet status
// - `unknown`
// - `queued`
// - `sent`
// - `opened`
// - `clicked`
// - `bounce`
// - `spam`
// - `unsub`
// - `blocked`
// - `hardbounced`
// - `softbounced`
// - `deferred`
export const getTitle = (id) => {
  let title = _t("Invitations");
  switch (id) {
    case "invitation_participant":
      title = _t("Invitation");
      break;
    case "invitation_relaunch_participant":
      title = _t("Relaunch invitation");
      break;
    case "registration_success":
      title = _t("Confirmation of registration for the event");
      break;
    case "reminder_day":
      title = _t("Reminder 1 day before");
      break;
    case "reminder_minutes":
      title = _t("Reminder 10 minutes before");
      break;
    case "wiz_ended_having_participated":
      title = _t("Having participated");
      break;
    case "wiz_ended_not_having_participated":
      title = _t("Not having participated");
      break;
    case "custom_email":
      title = _t("Subject of your email");
      break;
    default:
      title = _t(id);
  }
  return title;
};
//can be improved it specs are updated
export const getStatus = (active, status) => {
  if (active === false) {
    return _t("Disabled");
  }
  switch (status) {
    case "status_working":
      return _t("Sending");
    case "status_todo":
      return _t("Programmed sending");
    case "status_error":
      return _t("Error");
    case "status_done":
      return _t("Sent");
    default:
      return "-";
  }
};

export const getOpenedStat = (stats, templateStatus, status) => {
  const statusValue = toNumber(get(stats, status, 0));

  const percentage =
    statusValue === 0
      ? 0
      : (toNumber(get(stats, status, 0)) / toNumber(get(stats, "total", 1))) *
        100;

  return _t(`${getParsedPercent(percentage)}%`);
};
