import React, { useRef, useReducer } from "react";
import TextField from "./TextField";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const NOT_SAVED = "notsaved";
const SAVING = "saving";
const SAVED = "saved";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    position: "relative",
  },
  loader: {
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const reducer = (state, action) => {
  switch (action.type) {
    case "save_text":
      return { text: action.text, savingState: NOT_SAVED };
    case "saving_text":
      return { text: action.text, savingState: SAVING };
    case "saved_text":
      return { text: action.text, savingState: SAVED };
    default:
      return state;
  }
};
const AutoSaveTextField = ({
  name,
  value,
  placeholder,
  onChange,
  debounceTime,
  ...rest
}) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, {
    text: value,
    savingState: NOT_SAVED,
  });

  const timer = useRef(null);
  const onValueChange = (event) => {
    clearTimeout(timer.current);
    dispatch({ type: "save_text", text: event.target.value });
    const textValue = event.target.value;

    timer.current = setTimeout(() => {
      dispatch({ type: "saving_text", text: textValue });
      onChange({
        value: textValue,
        onFinish: () => {
          dispatch({ type: "saved_text", text: textValue });
        },
      });
    }, debounceTime);
  };

  return (
    <Box className={classes.container}>
      <TextField
        name={name}
        placeholder={placeholder}
        value={state.text}
        onChange={onValueChange}
        disabled={state.savingState === SAVING}
        {...rest}
      />
      {state.savingState === SAVING && (
        <Box className={classes.loader}>
          <CircularProgress size={30} color="secondary" />
        </Box>
      )}
    </Box>
  );
};

AutoSaveTextField.propTypes = {
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  debounceTime: PropTypes.number,
};

AutoSaveTextField.defaultProps = {
  variant: "outlined",
  fullWidth: true,
  placeholder: "",
  multiline: true,
  name: "search",
  onChange: () => {},
  debounceTime: 1000,
};

export default AutoSaveTextField;
