import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class Cart extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Cart;
  }

  setEvent(Event) {
    this.event = Event.id;
  }

  // returns [new_quantity, previous_quantity]
  addProduct(product, variant, quantity) {
    const product_id = product.id;
    const variant_id = variant.hash;

    const index = this.products.findIndex(
      (elem) => elem.product_id === product_id && elem.variant_id === variant_id
    );

    // update total
    this.sum += quantity * variant.price;

    // add new line
    if (index === -1) {
      this.products.push({ product_id, variant_id, quantity });

      return [quantity, 0];
    }

    // update existing line
    const previous_quantity = this.products[index].quantity;
    this.products[index].quantity += quantity;

    return [this.products[index].quantity, previous_quantity];
  }

  // returns [new_quantity, previous_quantity]
  updateProduct(product, variant, quantity) {
    const product_id = product.id;
    const variant_id = variant.hash;

    const index = this.products.findIndex(
      (elem) => elem.product_id === product_id && elem.variant_id === variant_id
    );

    // update line and update sum
    const previous_quantity = this.products[index].quantity;
    this.products[index].quantity = quantity;
    this.sum += quantity * variant.price;

    return [quantity, previous_quantity];
  }

  removeProduct(product_id, variant_id) {

    this.products = this.products.filter(
      (elem) =>
        !(elem.product_id === product_id && elem.variant_id === variant_id)
    );

    this.sum = this.products.reduce(
      (total, { quantity }) => total + quantity,
      0
    );
  }
}

Cart.modelName = "Cart";
Cart.backendName = "cart";

Cart.fields = {
  id: attr(),
  vendor_id: attr(),
  created_at: attr(),
  proceeded_to_checkout_at: attr(),
  sum: attr(),
  currency: attr(),
  products: attr(),
  event: fk("Event", "carts"),
};

export default Cart;
