import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import get from "lodash/get";
import map from "lodash/map";
import PercentIndicator from "./PercentIndicator";
import { getParsedPercent } from "utilities/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  detailsPosition: {
    //padding: theme.spacing(2),
    height: "35px",
    width: "35px",
    borderRadius: "50%",
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.secondaryBox,
  },
  content: {},
  itemLabel: {
    marginLeft: theme.spacing(2),
  },
  detailsPercent: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
    width: "calc(100% - 40px)",
  },
  goodQuizzAnswer: {
    backgroundColor: theme.wisemblyColors.lightGreen,
  },
  badQuizzAnswer: {
    backgroundColor: theme.wisemblyColors.pastelRed,
  },
  detailsText: {
    flex: "auto",
    justifyContent: "flex-start",
    width: "calc(100% - 40px)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: (props) => (props.layoutMode === "split" ? "16px" : "18px"),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: 0.5,
  },
  detailsSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  percentText: {
    width: "45px",
  },
}));

const UniqueSurveyItemResult = ({ item, survey }) => {
  const classes = useStyles();

  const isQuizz = survey && survey.type === "evaluation";
  const options = get(item, "options.choices", []);
  const summary = get(item, "summary", {});

  const result = map(options, (option, key) => {
    const percent = get(summary, `${get(option, "hash", "hash")}.percent`);
    const label = get(option, "label", "label");

    return (
      <Box
        display="flex"
        mb={2}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        key={key}
        width="100%"
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            className={clsx(
              classes.detailsPosition,
              isQuizz && option.status ? classes.goodQuizzAnswer : null
            )}
          >
            {key + 1}
          </Box>
          <Box className={classes.detailsSection}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.detailsText}
            >
              {label}
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
              flexDirection="row"
              className={classes.detailsPercent}
            >
              <PercentIndicator percent={percent} />
              <Box ml={1} className={classes.percentText}>{`${getParsedPercent(
                percent
              )}%`}</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      className={clsx("UniqueSurveyItemResult", classes.root)}
    >
      <Box flex={1}>{result}</Box>
    </Box>
  );
};

UniqueSurveyItemResult.propTypes = {
  item: PropTypes.object,
  survey: PropTypes.object,
};

export default UniqueSurveyItemResult;
