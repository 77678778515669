import dayjs from "dayjs";
import { API_BASE, APP_OLD_BASE } from "config";
import URI from "urijs";
import { getTToken } from "core/session";
import { _t } from "i18n";

const combineDescriptionAndUrl = (description, keyword) => {
  const uri = APP_OLD_BASE.clone().segment("/l").segment(keyword).toString();
  return description + "\n\n" + _t("Click here to join the event : ") + uri;
};

const replaceText = (str, options = {}) => {
  let replacements = [
    [/&amp;/g, "&"],
    [/&quot;/g, "\""],
    [/&apos;/g, "'"],
    [/&lt;/g, "<"],
    [/&gt;/g, ">"],
  ];

  let newStr = str;

  replacements.forEach(([from, to]) => {
    newStr = newStr.replaceAll(from, to);
  });

  if (options.removeMarkdownLinks) {
    newStr = newStr.replaceAll(/(?:__|[*#])|\[(.*?)\]\(.*?\)/gm, "$1");
  }

  if (options.limit) {
    const suffix = newStr.length > options.limit ? "..." : "";
    newStr = newStr.substr(0, options.limit) + suffix;
  }

  if (options.stripHtmlTags) {
    newStr = newStr.replaceAll(/<[^>]*>?/gm, "");
  }

  if (options.nl2br) {
    newStr = newStr.replaceAll(/\n/g, "<br/>");
  }

  return encodeURIComponent(newStr);
};

const MicrosoftGenericProvider = (base, parameters) => {
  const { title, description, from, to } = parameters;
  const formattedDescription = replaceText(description, {
    stripHtmlTags: true,
    removeMarkdownLinks: true,
    limit: 650,
    nl2br: true,
  });

  return `${base}/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=${from}&enddt=${to}&subject=${title}&body=${formattedDescription}`;
};

const MicrosoftOffice365Provider = (parameters) => {
  return MicrosoftGenericProvider("https://outlook.office.com", parameters);
};

const MicrosoftOutlookProvider = (parameters) => {
  return MicrosoftGenericProvider("https://outlook.live.com", parameters);
};

const GoogleAgendaProvider = (parameters) => {
  let { title, description, from, to } = parameters;
  from = from.replaceAll("-", "").replaceAll(":", "");
  to = to.replaceAll("-", "").replaceAll(":", "");
  const formattedDescription = replaceText(description, {
    stripHtmlTags: true,
    removeMarkdownLinks: true,
  });
  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${formattedDescription}&dates=${from}%2F${to}`;
};

const AppleAgendaProvider = (parameters) => {
  let { keyword } = parameters;

  try {
    let baseTarget = new URI(API_BASE);
    baseTarget
      .segment("event")
      .segment(keyword)
      .segment("landing")
      .segment("ics");

    baseTarget.search(getTToken({ url: baseTarget.toString() }));
    return baseTarget.toString();
  } catch (e) {
    console.log("error", e);
  }
  return null;
};

export const generateUrl = (provider, wiz) => {
  let providerFunction = null;

  const wizStart = wiz.getStartLiveDateWithFallback();
  const wizStop = wiz.getStopLiveDateWithFallback();

  const parameters = {
    title: wiz.title,
    description: combineDescriptionAndUrl(wiz.keyword),
    from: dayjs(wizStart).utc().format(),
    to: dayjs(wizStop).utc().format(),
    keyword: wiz.keyword,
  };

  switch (provider) {
    case "google":
      providerFunction = GoogleAgendaProvider;
      break;
    case "microsoft_live":
      providerFunction = MicrosoftOutlookProvider;
      break;
    case "microsoft_365":
      providerFunction = MicrosoftOffice365Provider;
      break;
    case "apple":
      providerFunction = AppleAgendaProvider;
      break;
  }
  if (providerFunction) {
    return providerFunction(parameters);
  }
};
