import React, { useEffect, useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import PopinFullscreen from "./PopinFullscreen";
import { useDispatch, useSelector } from "react-redux";
import { genericResourcesSelector } from "app/state/ducks/ressources/selectors";
import ChatList from "app/liveShopping/frontoffice/otm/components/Chat/ChatList";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import { getKeyword } from "app/state/ducks/application/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  chat: {
    width: "100%",
    height: "100%",
    padding: "25px"
  },
  filter: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "10px",
    padding: "10px",
    background: theme.palette.colors.white,

  },
  filterRow: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
}));

const Fullscreen = () => {
  const classes = useStyles();
  const [popin, setPopin] = useState(true);
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const [filter, setFilter] = useState("recent");

  useEffect(() => {
    dispatch(
      resourceFetch("quote", {
        slug: ["event", keyword, "quotes"],
        query: [
          { key: "sort", value: "pinned,recent" },
          { key: "limit", value: 20 },
          { key: "unmoderated", value: false }
        ],
        silent: true,
      })
    );
  }, []);

  const quotes = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "quote",
      sort: "created_at",
      order: "desc",
      filter: (e) => {
        return (filter === "favorite" ? e && e?.favorite === true : e);
      },
    })
  ).slice(0, 50);

  return (
    <Box className={classes.root}>
      {popin && <PopinFullscreen setPopin={setPopin} />}

      <Box className={classes.filter}>
        <Box className={classes.filterRow}>
          <Typography>Favorite</Typography>
          <input type="radio" onChange={(e) => setFilter("favorite")} checked={filter === "favorite"} />
        </Box>
        <Box className={classes.filterRow}>
          <Typography>Recent</Typography>
          <input type="radio" onChange={(e) => setFilter("recent")} checked={filter === "recent"} />
        </Box>
      </Box>
      <Box className={classes.chat}>
        <ChatList quotes={quotes} />
      </Box>
    </Box>
  )
};

export default Fullscreen;