import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import MuiPopper from "@material-ui/core/Popper";

const useStyles = makeStyles((theme) => ({
  arrow: {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: "''",
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  },
  popper: {
    zIndex: 3000,
    boxShadow: theme.palette.shadow,
    "&[x-placement*='bottom']": {
      marginTop: theme.spacing(2),
    },
    "&[x-placement*='top']": {
      marginBottom: theme.spacing(2),
    },
    "&[x-placement*='right']": {
      marginLeft: theme.spacing(2),
    },
    "&[x-placement*='left']": {
      marginRight: theme.spacing(2),
    },
    "&[x-placement*='bottom'] $arrow": {
      top: 0,
      left: 0,
      marginTop: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "0 1em 1em 1em",
        borderColor: `transparent transparent ${theme.palette.secondary.contrastText} transparent`,
      },
    },
    "&[x-placement*='top'] $arrow": {
      bottom: 0,
      left: 0,
      marginBottom: "-0.9em",
      width: "3em",
      height: "1em",
      "&::before": {
        borderWidth: "1em 1em 0 1em",
        borderColor: `${theme.palette.secondary.contrastText} transparent transparent transparent`,
      },
    },
    "&[x-placement*='right'] $arrow": {
      left: 0,
      marginLeft: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 1em 1em 0",
        borderColor: `transparent ${theme.palette.secondary.contrastText} transparent transparent`,
      },
    },
    "&[x-placement*='left'] $arrow": {
      right: 0,
      marginRight: "-0.9em",
      height: "3em",
      width: "1em",
      "&::before": {
        borderWidth: "1em 0 1em 1em",
        borderColor: `transparent transparent transparent ${theme.palette.secondary.contrastText}`,
      },
    },
  },
}));

const Popper = ({ children, anchorEl, open, placement }) => {
  const classes = useStyles();
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <MuiPopper
      className={classes.popper}
      anchorEl={anchorEl}
      open={open}
      placement={placement}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: "scrollParent",
        },
        arrow: {
          enabled: true,
          element: arrowRef,
        },
      }}
    >
      <span className={classes.arrow} ref={setArrowRef} />
      {children}
    </MuiPopper>
  );
};

Popper.propTypes = {
  children: PropTypes.any,
  anchorEl: PropTypes.any,
  open: PropTypes.bool,
  placement: PropTypes.string,
};

Popper.defaultProps = {
  placement: "bottom",
};

export default Popper;
