import React from "react";
import PropTypes from "prop-types";
import MuiTooltip from "@material-ui/core/Tooltip";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      maxWidth: "none",
      backgroundColor: theme.palette.colors.black,
      boxShadow: theme.palette.shadow.tooltip,
      padding: "8px 10px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      marginTop: (props) => (props.placement.includes("bottom") ? "5px" : 0),
      marginRight: (props) => (props.placement.includes("left") ? "5px" : 0),
      marginBottom: (props) => (props.placement.includes("top") ? "5px" : 0),
      marginLeft: (props) => (props.placement.includes("right") ? "5px" : 0),
    },
    icon: {
      marginRight: "5px",
      fontSize: "12px",
      color: theme.palette.colors.white,
      backgroundColor: theme.palette.colors.black,
    },
  }),
  {
    name: "OneTooltip",
  }
);

const Tooltip = ({
  Icon,
  title,
  enterDelay,
  enterNextDelay,
  placement,
  children,
  open,
  disableFocusListener,
  disableHoverListener,
  disableTouchListener,
  leaveDelay,
}) => {
  const classes = useStyles({ placement });

  const text = (
    <Typography color="white" variant="smallBodySB13">
      {title}
    </Typography>
  );

  const content = !Icon ? (
    text
  ) : (
    <>
      <Icon className={classes.icon} /> {text}
    </>
  );

  return (
    <MuiTooltip
      classes={{ tooltip: classes.root }}
      title={content}
      placement={placement}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      open={open}
      leaveDelay={leaveDelay}
      disableFocusListener={disableFocusListener}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
    >
      {children}
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  Icon: PropTypes.object,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  enterDelay: PropTypes.number,
  enterNextDelay: PropTypes.number,
  placement: PropTypes.string,
  open: PropTypes.bool,
  disableFocusListener: PropTypes.bool,
  disableHoverListener: PropTypes.bool,
  disableTouchListener: PropTypes.bool,
  leaveDelay: PropTypes.number,
};

Tooltip.defaultProps = {
  enterDelay: 1500,
  enterNextDelay: 1500,
  placement: "right",
};

export default Tooltip;
