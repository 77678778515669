import React, { useEffect, useState, useCallback, useReducer } from "react";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import uuid from "core/uuid";

import emitter from "core/emitter";
import { EVENT_SEND_NOTIFICATION } from "core/emitter/events";
import Notification from "coreUiKit/components/Notification/Notification";

const ListNotifications = ({ }) => {
    const notificationReducer = (state, payload) => {
        switch (payload.action) {
            case "ADD": {
                const {
                    type,
                    text,
                    duration,
                    icon,
                    button,
                    buttonTextOne,
                    buttonTextTwo,
                    buttonActionOne,
                } = payload;
                return {
                    ...state,
                    notifications: [
                        ...state.notifications,
                        {
                            id: uuid(),
                            type,
                            text,
                            duration,
                            icon,
                            button,
                            buttonTextOne,
                            buttonTextTwo,
                            buttonActionOne,
                        },
                    ],
                };
            }
            case "REMOVE": {
                return {
                    ...state,
                    notifications: state.notifications.filter(
                        (item) => item.id !== payload.id
                    ),
                };
            }
        }
    };

    const [state, dispatch] = useReducer(notificationReducer, {
        notifications: [],
    });

    useEffect(() => {
        const addNotification = (props) => {
            dispatch({ ...props, action: "ADD" });
        };
        emitter.on(EVENT_SEND_NOTIFICATION, addNotification);
        return () => emitter.off(EVENT_SEND_NOTIFICATION, addNotification);
    }, []);

    return (
        <Box>
            {state.notifications.map(
                ({
                    id,
                    type,
                    text,
                    duration,
                    icon,
                    button,
                    buttonTextOne,
                    buttonTextTwo,
                    buttonActionOne,
                }) => (
                    <Box mb={"5px"} key={id}>
                        <Notification
                            id={id}
                            type={type}
                            text={text}
                            duration={duration}
                            customIcon={icon}
                            button={button}
                            buttonTextOne={buttonTextOne}
                            buttonTextTwo={buttonTextTwo}
                            buttonActionOne={buttonActionOne}
                            buttonActionTwo={() => dispatch({ action: "REMOVE", id })}
                        />
                    </Box>
                )
            )}
        </Box>
    );
};

export default ListNotifications;
