import { Box, makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textDecoration: "none",
    height: "40px",
    "&:hover": {
      backgroundColor: theme.palette.colors.grey100,
    },
  },
  activeBox: {
    width: "4px",
    height: "24px",
    background: (props) => (props.active ? theme.palette.colors.primary : "transparent"),
    borderRadius: "99px",
  },
  typo: {
    color: (props) => (props.active ? theme.palette.colors.primary : "#1E2023"),
    marginRight: "8px",
  },
  icon: {
    fontSize: "16px",
    marginLeft: "8px",
    marginRight: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: (props) => (props.active ? theme.palette.colors.primary : "#808284"),
  },
}));

const AppointmentMenuTab = ({ title, icon, url, }) => {
  const [active, setActive] = useState(false);
  const classes = useStyles({ active });

  const handleActive = (match) => (match ? setActive(true) : setActive(false));

  return (
    <NavLink className={classes.root} to={url} exact={true} isActive={(match) => handleActive(match)}>
      <Box className={classes.activeBox} />
      <Box className={classes.icon}>{icon}</Box>
      <Typography className={classes.typo}>
        {title}
      </Typography>
    </NavLink>
  );
};

export default AppointmentMenuTab;