import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "coreUiKit/inputs/Button";
import Box from "@material-ui/core/Box";
import Secondaries from "./Secondaries";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles(
  (theme) => ({
    // logoMobile: {
    //   [theme.breakpoints.up("sm")]: {
    //     display: "none",
    //   },
    // },
    root: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "315px",
      marginBottom: "15px",
      [theme.breakpoints.down("xs")]: {
        width: "auto",
        marginBottom: "0",
        padding: "0 10px",
      },
    },
  }),
  {
    name: "ChatAction",
  }
);

const Actions = ({ onUpload, buttonLabel, onSend, disableSend, loading, disabled, disabledPicture }) => {
  const classes = useStyles();
  const [imgPath, setImgPath] = useState(null);
  const mobileScreen = useSelector(mobileLayoutSelector);
  const theme = useTheme();

  const sendMessage = () => {
    onSend(() => {
      setImgPath(null);
    });
  };

  return (
    <Box className={classes.root}>
      <Secondaries imgPath={imgPath} setImgPath={setImgPath} onUpload={onUpload} disabled={disabledPicture || disabled} isMobile={mobileScreen} />
      {!mobileScreen && (
        <Button size="small" color={theme.palette.colors.primary} onClick={sendMessage} disabled={disableSend || disabled} loading={loading}>
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};

Actions.propTypes = {
  onUpload: PropTypes.func,
  buttonLabel: PropTypes.string,
  onSend: PropTypes.func,
  disableSend: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledPicture: PropTypes.bool,
};

Actions.defaultProps = {
  onUpload: () => {},
  buttonLabel: "",
  onSend: () => {},
  loading: false,
  disableSend: false,
  disabled: false,
  disabledPicture: false,
};

export default Actions;
