import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import PropTypes from "prop-types";
import { _t } from "i18n";
import PlayFill from "react-bootstrap-icons/dist/icons/play-fill";
import { setCurrentChapter, setOpenCatalog, setOpenReplay } from "app/state/ducks/liveShopping/actions";
import { useDispatch, useSelector } from "react-redux";
import emitter from "utilities/emitter";
import { REPLAY_TIMESTAMP_UPDATE } from "utilities/emitter/events";
import { getCurrentChapter, getNextChapter, getPercent, getCurrentChapterIndex, getChapterTime } from "./replayUtilities";
import { currentWizReplaySelector } from "app/state/ducks/ressources/selectors";
import { currentChapterSelector, desktopLayoutSelector, replayDurationSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minHeight: "88px",
    height: "auto",
    border: "1px solid #F0F0F4",
    boxSizing: "border-box",
    borderRadius: "10px",
    marginBottom: "10px",
    position: "relative",
    overflow: "hidden",
  },
  number: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "100%",
    color: theme.palette.colors.darkGrey,
    paddingLeft: 10,
    paddingTop: 10,
    marginBottom: 10,
  },
  time: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "100%",
    color: theme.palette.colors.darkGrey,
    paddingRight: 10,
    paddingTop: 10,
    marginBottom: 10,
  },
  title: {
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "100%",
    color: theme.palette.colors.primary,
    marginBottom: 8,
  },
  subTitle: {
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "100%",
    color: theme.palette.colors.darkGrey,
  },
  playButton: {
    width: 44,
    height: 44,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "12px",
    background: theme.palette.colors.lightGrey,
    borderRadius: "99px",
  },
  progressContainer: {
    width: "100%",
    height: 5,
    position: "absolute",
    bottom: 0,
    background: theme.palette.colors.grey,
  },
  line: {
    width: (props) => props.progress + "%",
    height: 5,
    position: "absolute",
    bottom: 0,
    background: theme.palette.colors.error,
  },
}));

const Chapter = ({ chapter, index }) => {
  const dispatch = useDispatch();
  const [currentTimestamp, setCurrentTimestamp] = useState(0);
  const replayDuration = useSelector(replayDurationSelector);
  const currentChapterHash = useSelector(currentChapterSelector);
  const isDesktop = useSelector(desktopLayoutSelector);
  const [progress, setProgress] = useState(0);
  const classes = useStyles({ progress });
  const { chapters } = useSelector(currentWizReplaySelector);

  // GET CURRENT CHAPTER BY HASH
  const currentChapter = getCurrentChapter(chapters, currentChapterHash);

  useEffect(() => {
    emitter.on(REPLAY_TIMESTAMP_UPDATE, setCurrentTimestamp);
    return () => emitter.off(REPLAY_TIMESTAMP_UPDATE, setCurrentTimestamp);
  }, []);

  // GET PERCENT && SET THE NEXT CHAPTER
  useEffect(() => {
    const currentIndex = getCurrentChapterIndex(chapters, currentChapter);

    if (currentIndex !== index) return setProgress(currentIndex < index ? 0 : 100);

    setProgress(Math.max(3, getPercent(currentTimestamp, currentChapter, getNextChapter(chapters, currentChapter), replayDuration)));
  }, [currentTimestamp]);

  const handleChapter = () => {
    // disable auto change chapter while choosing a new chapter
    window._playerSyncedWithChapter = false;
    dispatch(setCurrentChapter(chapter.hash));
    dispatch(setOpenReplay(false));
    isDesktop && dispatch(setOpenCatalog(true));
  };

  const chapterTime = getChapterTime(chapter.timestamp);

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className={classes.number}>{index + 1}</Typography>
        <Typography className={classes.time}>{chapterTime}</Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 10,
          paddingRight: 10,
          paddingBottom: 15,
        }}
      >
        <Box>
          <Typography className={classes.title}>{chapter.title}</Typography>
          <Typography className={classes.subTitle}>{chapter.short}</Typography>
        </Box>
        <ButtonBase className={classes.playButton} onClick={handleChapter}>
          <PlayFill size={20} />
        </ButtonBase>
      </Box>
      <Box className={classes.progressContainer}>
        <Box className={classes.line} />
      </Box>
    </Box>
  );
};

Chapter.propTypes = {};

Chapter.defaultProps = {};

export default Chapter;
