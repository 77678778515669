import React from "react";
import PropTypes from "prop-types";

const CustomShareIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4236_24171)">
        <path
          d="M13.6101 15.9317L21.3101 12.356L21.2501 11.6964C21.25 10.846 21.549 10.0226 22.0947 9.37032C22.6404 8.71804 23.3981 8.27841 24.2353 8.12832C25.0724 7.97823 25.9357 8.12724 26.6741 8.54928C27.4124 8.97133 27.9789 9.63955 28.2743 10.437C28.5697 11.2345 28.5753 12.1105 28.29 12.9117C28.0048 13.7128 27.4468 14.3882 26.7139 14.8196C25.9809 15.251 25.1196 15.4109 24.2807 15.2715C23.5688 15.1532 22.9115 14.8257 22.3903 14.3351L14.6907 17.9105C14.7715 18.3467 14.7715 18.7941 14.6907 19.2303L22.3906 22.8059C22.9464 22.2835 23.6557 21.9473 24.4189 21.8509C25.3099 21.7383 26.211 21.9605 26.9476 22.4744C27.6841 22.9882 28.2037 23.7572 28.4055 24.6323C28.6074 25.5074 28.4771 26.4263 28.04 27.2108C27.6029 27.9953 26.8901 28.5897 26.0398 28.8786C25.1894 29.1676 24.262 29.1306 23.4374 28.7749C22.6127 28.4192 21.9495 27.77 21.5762 26.9532C21.2648 26.2717 21.1738 25.5141 21.3094 24.7845L13.6101 21.2091C13.1678 21.6256 12.6262 21.9257 12.0341 22.0791C11.3173 22.2648 10.5608 22.2266 9.86639 21.9695C9.17196 21.7124 8.57292 21.2489 8.14986 20.6412C7.72679 20.0335 7.5 19.3108 7.5 18.5704C7.5 17.83 7.72679 17.1073 8.14986 16.4996C8.57292 15.8919 9.17196 15.4284 9.86639 15.1713C10.5608 14.9142 11.3173 14.876 12.0341 15.0617C12.6262 15.2151 13.1678 15.5152 13.6101 15.9317Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_4236_24171">
          <rect width="25" height="25" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

CustomShareIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomShareIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomShareIcon;
