import emitter from "core/emitter";
import { noop } from "core/utils";

export default function notify(
  { emit = null, callback = noop },
  response = null,
  error = null
) {
  /* could give emit in two formats
   *
   *  @DOC
   *  1. emit: EVENT_NAME => will emit response to events listening this event name
   *  2. emit: [ EVENT_NAME, data ] => will emit data to events listening this event name
   */
  if (!error && emit) {
    if (Array.isArray(emit) && emit.length === 2) {
      emitter.emit(emit[0], emit[1]);
    } else {
      emitter.emit(emit, response);
    }
  }

  // @DOC
  // alias to get easier single resource responses
  if (response && typeof response === "object" && response.resources) {
    response.single = response.resources[0];
  }

  // @DOC
  callback(error, response);
}
