import { Box, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import OneSwitch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import InputColorWrapper from "./InputColorWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginTop: "20px",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginBottom: "5px",
  },
  field: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  buttonSave: {
    position: "absolute",
    bottom: "10px",
    right: "30px",
  },
}));

const Apparence = ({ project }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { options } = project;

  const [primaryTextColor, setPrimaryTextColor] = useState(options.apparence?.primary_text_color || '#FFFFFF');
  const [secondaryTextColor, setSecondaryTextColor] = useState(options.apparence?.secondary_text_color || '#FFFFFF');
  const [primaryBackgroundColor, setPrimaryBackgroundColor] = useState(options.apparence?.primary_background_color || '#000000');
  const [secondaryBackgroundColor, setSecondaryBackgroundColor] = useState(options.apparence?.secondary_background_color || '#000000');

  const apparence = {
    primary_text_color: primaryTextColor,
    secondary_text_color: secondaryTextColor,
    primary_background_color: primaryBackgroundColor,
    secondary_background_color: secondaryBackgroundColor,
  };

  const handleSend = () => {
    const options = {
      ...project.options,
      apparence,
    };

    dispatch(resourceEdit(Object.assign(project, { options }), {
      slug: ["projects", project.id],
      patch: ["options"],
      callback: (error) => {
        if (error) {
          console.log(error);
          return;
        }

        emitter.emit(EVENT_FLASH_SUCCESS, _t("Saved"));
      },
    }))
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Apparence")}</Typography>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Primary text color")}</Typography>
        <InputColorWrapper colorPreview={primaryTextColor}>
          <Input value={primaryTextColor} onChange={(e) => setPrimaryTextColor(e.target.value)} />
        </InputColorWrapper>
      </Box>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Primary background color")}</Typography>
        <InputColorWrapper colorPreview={primaryBackgroundColor}>
          <Input value={primaryBackgroundColor} onChange={(e) => setPrimaryBackgroundColor(e.target.value)} />
        </InputColorWrapper>
      </Box>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Secondary text color")}</Typography>
        <InputColorWrapper colorPreview={secondaryTextColor}>
          <Input value={secondaryTextColor} onChange={(e) => setSecondaryTextColor(e.target.value)} />
        </InputColorWrapper>
      </Box>

      <Box className={classes.field}>
        <Typography className={classes.label}>{_t("Secondary background color")}</Typography>
        <InputColorWrapper colorPreview={secondaryBackgroundColor}>
          <Input value={secondaryBackgroundColor} onChange={(e) => setSecondaryBackgroundColor(e.target.value)} />
        </InputColorWrapper>
      </Box>

      <Button onClick={handleSend} clsName={classes.buttonSave}>{_t("Save")}</Button>
    </Box>
  )
};

export default Apparence;
