import React, { useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { connect } from "react-redux";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import toNumber from "lodash/toNumber";

import { _t } from "i18n";
import { SurveyItemTitle } from "appComponents/Viewers";
import { makeStyles } from "@material-ui/core/styles";
import basil from "core/basil";
import {
  canUserSeeEvaluationAnswer,
  useSurveyContextState,
  useSurveyContextDispatcher,
} from "appComponents/SurveyForms";
import { getParsedPercent } from "utilities/utils";

const useStyles = makeStyles((theme) => ({
  formFieldRow: {
    //actionPanel height theme.spacing(4.75)
    flexDirection: "column",
    //height: `calc(100% - ${theme.spacing(17.5)}px) `,
    //height: "auto",
    overflow: "auto",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "50px",
      paddingRight: "10%",
    },

    [theme.breakpoints.down("xs")]: {
      flex: "auto",
      paddingLeft: "25px",
      paddingRight: "5%",
    },
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
  },
  paginationScreen: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: "100%",
  },
  hidden: {
    display: "none",
  },
  evaluationNote: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  score: {
    fontSize: "50px",
    display: (props) => (props.canSeeNote ? "flex" : "none"),
  },
  titleText: {
    marginBottom: "50px",
    marginTop: "26px",
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.5px",
    fontSize: "20px",
  },
}));

const UserEvaluationEndNote = ({ answer, itemCount, canSeeNote }) => {
  const classes = useStyles({ canSeeNote });
  const note = (toNumber(get(answer, "score", 0)) / itemCount) * 100;
  return (
    <Box className={classes.evaluationNote}>
      {/* prettier-ignore */}
      <Typography display="block" className={classes.titleText}>
        {canSeeNote
          ? _t("Thank you for answering. Here is your answer.")
          : _t("Thank you for answering. You answer has been taking into account.")}
      </Typography>
      <Box className={classes.score}>{`${getParsedPercent(note)} %`}</Box>
    </Box>
  );
};
UserEvaluationEndNote.propTypes = {
  answer: PropTypes.object,
  itemCount: PropTypes.number,
  canSeeNote: PropTypes.bool,
};
UserEvaluationEndNote.defaultProps = {
  itemCount: 1,
};
const ShowUserSurveyEndView = ({
  answer,
  survey,
  isThumbnailView,
  layoutMode,
}) => {
  const classes = useStyles({ isThumbnailView });
  const surveyContext = useSurveyContextState();
  const surveyContextDispatch = useSurveyContextDispatcher();
  const itemAnswers = answer.itemAnswers.toRefArray();
  const canSeeEvaluationNote = canUserSeeEvaluationAnswer(survey);

  const responseIndexMap = itemAnswers.map((item, i) => ({
    index: i,
    hash: item.hash,
  }));

  useEffect(() => {
    if (!isEqual(responseIndexMap, surveyContext.responseIndexMap)) {
      surveyContextDispatch({
        type: "SET_USER_RESPONSE_MAP",
        responseIndexMap: responseIndexMap,
      });
    }
  }, [survey.hash, responseIndexMap]);

  return (
    <Box className={clsx("ShowUserSurveyEndView", classes.paginationScreen)}>
      <SurveyItemTitle
        survey={survey}
        isThumbnailView={isThumbnailView}
        layoutMode={layoutMode}
        forResult={true}
      />
      {/* prettier-ignore */}
      <Box className={classes.formFieldRow} pt={2}>
        {survey.type === "evaluation" ? (
          <UserEvaluationEndNote
            answer={answer}
            itemCount={itemAnswers.length || 1}
            canSeeNote={canSeeEvaluationNote}
          />
        ) : (
          <Typography display="block" className={classes.titleText}>
            {_t("Thank you for answering. You answer has been taking into account.")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

ShowUserSurveyEndView.propTypes = {
  answer: PropTypes.object,
  survey: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
};
const mapStateToProps = (state, ownProps) => {
  return {
    layoutMode: basil.get("currentLayoutMode") || "split",
    //items : genericResourcesSelector(state, {type: "Item", filter: (item)=>isEqual})
  };
};
export default connect(mapStateToProps)(ShowUserSurveyEndView);
