import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
//import Radio from '@material-ui/core/Radio';
import PropTypes from "prop-types";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import PercentIndicator from "appComponents/Viewers/PercentIndicator";
import {
  useCurrentFieldHash,
  ItemMeta,
  SurveyProgressBar,
} from "appComponents/SurveyForms";
import { SurveyItemImageViewer } from "appContainers/Viewers";
import basil from "core/basil";
import emitter from "core/emitter";
import { EVENT_RESET_SURVEY_FORM } from "core/emitter/events";
import { getMediaHashFromImage } from "utilities/utils/media";
import { getParsedPercent } from "utilities/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  optionBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(43.25),
      width: theme.spacing(30),
      marginRight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(23.25),
      width: theme.spacing(17),
    },
    marginBottom: theme.spacing(3),
  },
  description: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(1),
    width: "100%",
    height: theme.spacing(3.75),
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(3.75),
    },
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    color: theme.palette.text.primary,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1.875),
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-around",
    },
  },
  main: {
    cursor: (props) => (props.canAnswer ? "pointer" : "not-allowed"),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    flex: "auto",
    width: "100%",
    borderRadius: "8px",
    // borderBottomLeftRadius: "8px",
    // borderBottomRightRadius: "8px",
  },
  optionLabel: {
    flexDirection: "row",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "normal",
    letterSpacing: "0.78px",
    lineHeight: (props) => (props.layoutMode === "split" ? "1.56" : "2.13"),
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexGrow: 1,
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
  },
  imageBox: {
    width: "100%",
    //flex: "auto",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(30.375),
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(17.25),
    },
    //height: theme.spacing(20),
  },
  answerType: {},
  itemHelp: {
    marginBottom: theme.spacing(1),
  },
  itemLabel: {},
  errorMsg: {
    color: theme.palette.error.main,
  },
  required: {
    color: (props) => (props.error ? theme.palette.error.main : "currentcolor"),
    display: (props) => (props.error ? "block" : "none"),
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "0.5px",
    marginTop: "30px",
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  detailsPercent: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    width: "100%",
  },
  percentText: {
    width: "45px",
    textAlign: "right",
  },
}));

//update later with material ui radio
export const ImageField = ({
  name,
  required,
  options,
  summary,
  withLiveResult,
  canAnswer,
  hash,
}) => {
  const currentFieldHash = useCurrentFieldHash();
  const [userChoice, setUserChoice] = useState();
  const validate = (value) => {
    let error;
    if (currentFieldHash !== hash) return;
    if (required && (!value || isEmpty(value.trim()) < 0)) {
      error = _t("field is required");
    }
    if (required && options.choices) {
      const values = [];
      const labels = [];

      options.choices.forEach(({ hash, label }) => {
        values.push(hash);
        labels.push(label);
      });
      /* prettier-ignore */
      error = _t("option must be one of options {options}", { options: labels.join(",") });
      if (values.includes(value)) error = null;
    }
    return error;
  };
  const [field, meta, helpers] = useField({
    name,
    validate,
  });

  const layoutMode = basil.get("currentLayoutMode") || "split";
  const classes = useStyles({ error: meta.error, canAnswer, layoutMode });
  const { setValue } = helpers;
  useEffect(() => {
    const callback = () => {
      setUserChoice("");
      setValue("", false);
    };
    emitter.on(EVENT_RESET_SURVEY_FORM, callback);
    return () => emitter.removeListener(EVENT_RESET_SURVEY_FORM, callback);
  }, [setValue]);
  const onSelect = (hash) => {
    if (!canAnswer) return;
    setUserChoice(hash);
    setValue(hash, true);
  };

  const result =
    options.choices &&
    options.choices.map((optn) => {
      const percent = get(summary, `${get(optn, "hash", "hash")}.percent`);
      return (
        <Box key={optn.hash} className={classes.optionBlock}>
          <Box
            onClick={() => onSelect(optn.hash)}
            className={clsx(classes.main)}
          >
            <Box className={classes.imageBox}>
              <SurveyItemImageViewer
                mediaHash={getMediaHashFromImage(
                  get(options.images, `${optn.hash}`)
                )}
                selected={optn.hash === userChoice}
                rounded
              />
            </Box>
            <Box className={clsx(classes.description)}>
              <Typography className={classes.optionLabel}>
                {optn.label}
              </Typography>
            </Box>
            {withLiveResult && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                flexDirection="row"
                className={classes.detailsPercent}
              >
                <PercentIndicator percent={percent} />
                <Box
                  ml={1}
                  className={classes.percentText}
                >{`${getParsedPercent(percent)}%`}</Box>
              </Box>
            )}
          </Box>
        </Box>
      );
    });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
    >
      <SurveyProgressBar />
      <ItemMeta />
      {required && (
        <Typography className={classes.required}>
          {_t("This field is required")}
        </Typography>
      )}
      <Box className={classes.container}>{result}</Box>
    </Box>
  );
};
ImageField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.object,
  help: PropTypes.string,
  withLiveResult: PropTypes.bool,
  summary: PropTypes.array,
  canAnswer: PropTypes.bool,
  hash: PropTypes.string,
};

export default ImageField;
