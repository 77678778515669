import React, { useState } from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import Select from "app/liveShopping/backoffice/otm/components/reusables/NewSelect";
import { _t } from "utilities/i18n";
import timezones from "./timezones.json";
import { useDispatch, useSelector } from "react-redux";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { useParams } from "react-router-dom";
import { genericResourceSelector } from "app/state/ducks/ressources/selectors";
import OneSwitch from "app/liveShopping/backoffice/otm/components/reusables/Switch";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import ResourceFactory from "orm/resources";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import emitter from "utilities/emitter";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "15px 20px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  slotsDuration: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  active: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  timezone: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  timezoneInput: {
    height: "40px !important",
    width: "200px !important",
  },
  text: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000000",
  },
  components: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
}));

const Setup = () => {
  const classes = useStyles();
  const project = useSelector(currentProjectSelector);
  const { group } = useParams();
  const currentGroup = project.groups.toRefArray().find((g) => g.hash === group);
  const calendar = useSelector(state => genericResourceSelector(state, {
    type: "Calendar",
    filter: (cal) => {
      return cal.group?.hash === currentGroup.hash;
    },
  }));

  const [active, setActive] = useState(calendar?.active || false);
  const [slotsDuration, setSlotsDuration] = useState(calendar?.slots_duration_minutes || 30);
  const [timezone, setTimezone] = useState(calendar?.timezone);
  const dispatch = useDispatch();
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const handleTimezoneChange = (e) => {
    console.log(e.target.value)
    setTimezone(e.target.value);
  };

  const newCalendar = resourceFactory.create("Calendar", {
    slots_duration_minutes: slotsDuration,
    active,
    timezone
  });

  const handleSave = () => {
    dispatch(
      resourceEdit(newCalendar, {
        slug: ["calendars", calendar.hash],
        patch: ["slots_duration_minutes", "active", "timezone"],
        callback: (error, response) => {
          if (error) {
            console.log(error);
            return;
          }

          emitter.emit(EVENT_FLASH_SUCCESS, _t("The setup has been updated"));
        }
      }
      ));
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Setup")}</Typography>
      <Box className={classes.components}>
        <Box className={classes.active}>
          <Typography className={classes.text}>{_t("Active calendar")}</Typography>
          <OneSwitch checked={active} onChange={() => setActive(!active)} />
        </Box>
        <Box className={classes.slotsDuration}>
          <Typography className={classes.text}>{_t("Slots duration (minutes)")}</Typography>
          <Input style={{ width: "70px" }} value={slotsDuration} onChange={(e) => setSlotsDuration(e.target.value)} type={"number"} />
        </Box>
        <Box className={classes.timezone}>
          <Typography className={classes.text}>{_t("Timezone")}</Typography>
          <Select defaultValue={timezone} onChange={(e) => handleTimezoneChange(e)} className={classes.timezoneInput} options={timezones} />
        </Box>
      </Box>
      <Button onClick={handleSave} style={{ marginTop: "20px", position: "fixed", right: "20px", bottom: "20px" }}>{_t("Save")}</Button>

    </Box>
  )
};

export default Setup;
