import { eventChannel } from "redux-saga";
import {
  take,
  put,
  call,
  takeEvery,
  all,
  delay,
  select,
  fork,
} from "redux-saga/effects";
import { APP_ID } from "settings/config";
import { Parser } from "core/jsonApi";
import emitter from "core/emitter";
import {
  EVENT_TIMELINE_NOTIFICATION,
  FORCE_TIMELINE_UPDATE,
  NEW_ACCEPTANCE,
} from "core/emitter/events";
import { socketSet } from "applicationDucks/actions";
import get from "lodash/get";
import { isWizAccessible } from "appComponents/Header/WizState";
import { currentWizSelector } from "ressourcesDucks/selectors";

import {
  EVENT_JOIN,
  EVENT_LEAVE,
  SESSION_SET,
  SESSION_UNSET,
  SET_CURRENT_PROJECT,
} from "applicationDucks/actionsTypes";

import { setResources, unsetResource } from "ressourcesDucks/actions";
import { handleSurveyReset } from "ressourcesDucks/sagas/surveyReset";

import logger from "core/logger";
const debug = logger("realtime");
import basil from "core/basil";
import { isUser } from "utilities/access";

// PUSH EVENTS to relay to emitter
const RELAY = {
  'visitorCreate': 'visitorCreate',
  'visitorEdit': 'visitorEdit',
};

const SHOULD_REFRESH_TIMELINE_EVENTS = [
  "evaluationUpdate",
  "pollUpdate",
  "formUpdate",
  "sessionCreate",
];
//because there is delay before media are available in database when admin swipe
const SHOULD_REFRESH_TIMELINE_EVENTS_AFTER_DELAY = ["mediaUpdate"];
const SHOULD_WATCH_SURVEY_RESET_EVENTS = [
  "evaluationUpdate",
  "pollUpdate",
  "formUpdate",
];

// listen for broadcase events on socket.io and give them to saga to handle them then
function createSocketChannel(realtime) {
  return eventChannel((emit) => {
    realtime.on("broadcast", emit);

    // eventChannel must return an unsubscribe function
    return () => { };
  });
}

function* handlePushEvent(json) {
  // why having to parse ?
  try {
    json = JSON.parse(json);
  } catch (error) {
    console.error("push error,", error);
  }

  // If there is no data in the payload, do nothing.
  if (!json.data) {
    // return debug.warn(`No metadata provided for event ${JSON.stringify(json)}. Nothing will be done`)
    return;
  }
  // Get the sender APP_ID
  const data = json.data;
  const sourceAppId = json.source;
  const pushEventName = json.eventName;

  // If the APP_ID is the same as ours, ignore the push event
  if (sourceAppId === APP_ID) {
    debug.log(`Ignoring push event ${pushEventName}.`, data);
    return;
  }

  debug.log(`New push event ${pushEventName}.`, data);
  //for now must be improved

  if (pushEventName === "acceptanceCreate") {
    emitter.emit(NEW_ACCEPTANCE);
  }

  if (pushEventName === "wizUpdate" || pushEventName === "evaluationUpdate") {
    //yield put(incrementNotification("timeline"));
    //emitter.emit(EVENT_TIMELINE_NOTIFICATION, "timeline");
  }

  // emit push event through emitter for specific actions like `template_applied`
  if (RELAY[pushEventName]) {
    emitter.emit(pushEventName, json);
  }

  // UNSET deleted entity
  if (pushEventName.indexOf("Delete") !== -1) {
    if (data.data?.id && data.data?.type) {
      yield put(unsetResource({ id: data.data.id, type: data.data.type }));
    }

    return;
  }

  const { resources, included, meta, links } = Parser.parse(data);
  if (SHOULD_WATCH_SURVEY_RESET_EVENTS.includes(pushEventName)) {
    try {
      yield call(handleSurveyReset, { resources, included });
    } catch (e) {
      console.log("fail to handle surveyReset", e);
    }
  }

  const event = yield select(currentWizSelector);
  let delayMs = 1;

  if (
    !isUser("speaker+") &&
    get(event, "live_media.enabled") &&
    get(event, "live_media.provider") === "wisembly_webinar" &&
    (!event.is_hybrid ||
      (basil.get("u.hybridModeChoice") === "remote" &&
        isWizAccessible(event.start, event.stop)))
  ) {
    delayMs = 15 * 1000;
  }

  yield delay(delayMs);
  yield put(setResources(resources, included, meta, links));
  //also delete surverAnswers if answerCount = 0
  if (SHOULD_REFRESH_TIMELINE_EVENTS.includes(pushEventName)) {
    setTimeout(() => {
      emitter.emit(EVENT_TIMELINE_NOTIFICATION, "timeline");
      emitter.emit(FORCE_TIMELINE_UPDATE);
      basil.set("u:shouldRefreshTimeline", true);
    }, 1000);
  }
  if (SHOULD_REFRESH_TIMELINE_EVENTS_AFTER_DELAY.includes(pushEventName)) {
    setTimeout(() => {
      emitter.emit(EVENT_TIMELINE_NOTIFICATION, "timeline");
      emitter.emit(FORCE_TIMELINE_UPDATE);
      basil.set("u:shouldRefreshTimeline", true);
    }, 5000);
  }
}

function* connect(Realtime, { session }) {
  const needsReconnect =
    session && get(session, "token") !== get(Realtime, "state.token");

  // do not init Socket if already auth / currently auth
  if (
    !needsReconnect &&
    (Realtime.isAuthenticated() || Realtime.isAuthenticating())
  )
    return;

  // init Realtime and authenticate if needed
  if (!Realtime.isInitialized()) Realtime.init();

  Realtime.authenticateSocket(session);

  yield put(socketSet(Realtime.socket));
}

function* listen(Realtime, { keyword, project } = {}) {
  // create a redux-saga event channel to listen to socket.io external events
  const channel = yield call(createSocketChannel, Realtime);

  if (keyword) Realtime.joinMeetingRoom(keyword);
  if (project) Realtime.joinProjectRoom(project);

  // take every socket.io incoming event and then handle them in a redux-saga sync way!
  while (true) {
    const payload = yield take(channel);
    try {
      yield fork(handlePushEvent, payload);
    } catch (e) {
      console.log("unable to handle pushEvent", JSON.parse(payload));
    }
  }
}

//export default function* realtimeSaga(Realtime) {
function* handleSessionSet(Realtime, { session }) {
  const wiz = yield select(currentWizSelector);

  yield connect(Realtime, { session });
  yield listen(Realtime, { keyword: wiz ? wiz.keyword : null });
}

function* handleSessionUnset(Realtime) {
  Realtime.onCleanUp();
  yield;
}

function* handleMeetingJoin(Realtime, { keyword }) {
  // case where we are annon. We aren\'t yet connected to socket
  // so we connect and join event in the same action
  if (!Realtime.isAuthenticated() && !Realtime.isAuthenticating()) {
    yield connect(Realtime, { session: {} });
    yield listen(Realtime, { keyword });

    return;
  }

  // if we are already connected, just join the event room
  Realtime.joinMeetingRoom(keyword);
}

function* handleMeetingLeave(Realtime, { keyword }) {
  Realtime.leaveMeetingRoom(keyword);
  yield;
}

function* handleProjectSet(Realtime, { project }) {
  // case where we are annon. We aren\'t yet connected to socket
  // so we connect and join event in the same action
  if (!Realtime.isAuthenticated() && !Realtime.isAuthenticating()) {
    yield connect(Realtime, { session: {} });
    yield listen(Realtime, { project: project.hash });

    return;
  }

  // if we are already connected, just join the event room
  Realtime.joinProjectRoom(project.hash);
}

//bad anyway, it's just simple to include the realtime object here with import core/realtime
export default function* realtimeSaga(Realtime) {
  yield all([
    yield takeEvery(SESSION_SET, handleSessionSet, Realtime),
    yield takeEvery(SESSION_UNSET, handleSessionUnset, Realtime),
    yield takeEvery(EVENT_JOIN, handleMeetingJoin, Realtime),
    yield takeEvery(EVENT_LEAVE, handleMeetingLeave, Realtime),
    yield takeEvery(SET_CURRENT_PROJECT, handleProjectSet, Realtime),
  ]);
}
