import { Box, makeStyles, Typography } from "@material-ui/core";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import { genericResourceSelector } from "app/state/ducks/ressources/selectors";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { _t } from "utilities/i18n";
import EndDateComponent from "../EndDateComponent";
import ResourceFactory from "orm/resources";
import emitter from "utilities/emitter";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import { EVENT_FLASH_SUCCESS } from "utilities/emitter/events";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    padding: "15px 20px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  simultaneousSlots: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    marginBottom: "20px",
  },
  text: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#000000",
  },
}));

const Limits = () => {
  const classes = useStyles();
  const project = useSelector(currentProjectSelector);
  const { group } = useParams();
  const currentGroup = project.groups.toRefArray().find((g) => g.hash === group);
  const calendar = useSelector(state => genericResourceSelector(state, {
    type: "Calendar",
    filter: (cal) => {
      return cal.group?.hash === currentGroup.hash;
    },
  }));

  const [simultaneousSlots, setSimultaneousSlots] = useState(calendar.simultaneous_slots || 1);
  const [endAtAbsolute, setEndAtAbsolute] = useState(calendar?.end_at_absolute ? dayjs(calendar?.end_at_absolute) : dayjs());
  const [endAtRelative, setEndAtRelative] = useState(calendar.end_at_relative || 30);
  const [selectedOption, setSelectedOption] = useState("");
  const dispatch = useDispatch();

  const resourceFactory = useSelector((state) => new ResourceFactory(state));


  const newCalendarObject = {
    simultaneous_slots: simultaneousSlots,
    end_at_absolute: selectedOption === "end_at_absolute" ? endAtAbsolute : null,
    end_at_relative: selectedOption === "end_at_relative" ? parseInt(endAtRelative) : null,
  };

  const newCalendar = resourceFactory.create("Calendar", newCalendarObject);

  const handleSave = () => {
    dispatch(
      resourceEdit(newCalendar, {
        slug: ["calendars", calendar.hash],
        patch: ["simultaneous_slots", "end_at_absolute", "end_at_relative"],
        callback: (error, response) => {
          if (error) {
            console.log(error);
          } else {
            emitter.emit(EVENT_FLASH_SUCCESS, _t("The limits has been updated"));
          }
        }
      }
      ));
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Limits")}</Typography>
      <Box className={classes.simultaneousSlots}>
        <Typography className={classes.text}>{_t("Simultaneous Slots")}</Typography>
        <Input style={{ width: "70px" }} value={simultaneousSlots} onChange={(e) => setSimultaneousSlots(e.target.value)} type={"number"} />
      </Box>
      <EndDateComponent
        endAtAbsolute={endAtAbsolute}
        setEndAtAbsolute={setEndAtAbsolute}
        endAtRelative={endAtRelative}
        setEndAtRelative={setEndAtRelative}
        setSelectedOption={setSelectedOption}
        selectedOption={selectedOption}
        calendar={calendar} />

      <Button onClick={handleSave} style={{ marginTop: "20px", position: "fixed", right: "20px", bottom: "20px" }}>{_t("Save")}</Button>
    </Box>
  )
};

export default Limits;