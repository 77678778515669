import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { TextField } from "appComponents/Mui";
import { replaceVariables } from "adminComponents/Mails/Editor/utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import SegmentsList from "./SegmentsList";
import EditButton from "./EditButton";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid #CCCCCE",
  },
  field: {
    display: "flex",
    padding: (props) => (props.spaced ? "5px 0px 5px 0px" : "0px"),
    margin: "0 15px",
    alignItems: "center",
    borderBottom: (props) => (props.separation ? "1px solid #CCCCCE" : "none"),
  },
  fieldLabel: {
    color: theme.wisemblyColors.black,
    opacity: 0.5,
    fontSize: "14px",
  },
  senderName: {
    fontSize: "14px",
    fontWeight: 800,
  },
  senderMail: {
    fontSize: "14px",
    paddingLeft: "3px",
  },
  editableField: {
    width: "100%",
    "&.preview:hover": {
      "& *": {
        cursor: "pointer",
      },
      "& .MuiInputBase-root": {
        background: "#F5F5F5",
      },
      "& .editButton": {
        visibility: "visible",
      },
      "& .MuiInputAdornment-root": {
        background: "white",
      },
    },
    "&.editing": {
      "& .MuiInputBase-root": {
        background: "rgba(7, 105, 141, 0.1)",
      },
      "& .editButton": {
        visibility: "visible",
        background: theme.palette.button.primary.background.enable,
        color: theme.palette.button.primary.color.default,
      },
    },
  },
  editButton: {
    visibility: "hidden",
    position: "absolute",
    top: "calc(50% - 15px)",
    right: "-20px",
    height: "28px",
    width: "28px",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px rgba(0, 0, 0, 0.1)",
    background: "#FFF",
    cursor: "pointer",
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  input: {
    width: "100%",
    border: "none",
    outline: "none",
    lineHeight: "1.5",
    "& .MuiInputBase-root": {
      boxShadow: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiInputBase-input": {
      fontSize: "14px",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      color: theme.wisemblyColors.black,
    },
  },
  recipient: {
    backgroundColor: "rgba(7, 105, 141, 0.1)",
    borderRadius: "5px",
    padding: "5px",
  },
  boxSender: {
    position: "relative",
  },
}));

const EditableField = ({ editing, setEditing, children }) => {
  const classes = useStyles();
  const toggleEditing = () => {
    setEditing(!editing);
  };
  const noop = () => {};
  return (
    <Box
      className={clsx(
        classes.boxSender,
        classes.editableField,
        editing ? "editing" : "preview"
      )}
      onClick={editing ? noop : toggleEditing}
    >
      {children}
      <Box
        className={clsx(classes.editButton, "editButton")}
        onClick={toggleEditing}
      >
        <EditButton editing={editing} />
      </Box>
    </Box>
  );
};

const MailField = ({ label, children, separation = false, spaced = false }) => {
  const classes = useStyles({ separation, spaced });
  return (
    <Box display="flex" className={classes.field}>
      <Typography className={classes.fieldLabel}>
        {label}&nbsp;:&nbsp;
      </Typography>
      {children}
    </Box>
  );
};

const SenderField = ({ event, user, template, onSave }) => {
  const classes = useStyles();
  const [senderName, setSenderName] = useState(
    template.sender_name || event.title
  );
  const senderAddress = "<notifications@wisembly.com>";
  const [editing, setEditing] = useState(false);
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    template.setSender(senderName);
  }, [senderName]);

  useEffect(() => {
    if (hasEdited) {
      setHasEdited(false);
      onSave(template);
    }
  }, [editing]);

  return (
    <EditableField editing={editing} setEditing={setEditing}>
      <TextField
        name="message"
        multiline
        fullWidth
        variant="outlined"
        value={editing ? senderName : replaceVariables(senderName, event, user)}
        disabled={!editing}
        onChange={(e) => {
          setSenderName(e.target.value);
          setHasEdited(true);
        }}
        className={clsx(classes.input)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={clsx(classes.senderMail)}>
              <Typography className={classes.senderMail}>
                {senderAddress}
              </Typography>
            </InputAdornment>
          ),
        }}
      />
    </EditableField>
  );
};

const MailSubjectField = ({ template, event, user, onSave }) => {
  const classes = useStyles();
  const [subject, setSubject] = useState(template.subject);
  const [editing, setEditing] = useState(false);
  const [hasEdited, setHasEdited] = useState(false);

  useEffect(() => {
    template.setSubject(subject);
  }, [subject]);

  useEffect(() => {
    if (hasEdited) {
      setHasEdited(false);
      onSave(template);
    }
  }, [editing]);

  return (
    <EditableField editing={editing} setEditing={setEditing}>
      <TextField
        name="message"
        multiline
        fullWidth
        variant="outlined"
        value={editing ? subject : replaceVariables(subject, event, user)}
        disabled={!editing}
        onChange={(e) => {
          setSubject(e.target.value);
          setHasEdited(true);
        }}
        className={clsx(classes.input)}
      />
    </EditableField>
  );
};

const MailPreviewFields = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <MailField label={_t("Sender")} separation>
        <SenderField {...props} />
      </MailField>
      <MailField label={_t("Recipients")} separation spaced>
        <SegmentsList {...props} />
      </MailField>
      <MailField label={_t("Subject")}>
        <MailSubjectField {...props} />
      </MailField>
    </Box>
  );
};

export { EditableField };
export default MailPreviewFields;
