import { Box, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { CameraVideo, CameraVideoFill, Mic, MicFill } from "react-bootstrap-icons";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    backgroundColor: "#1E2023",
    color: "#fff",
    width: "80%",
    borderRadius: "5px",
    position: "relative",
    gap: "5px",
  },
  exit: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
    color: theme.palette.colors.grey400,
    fontWeight: "bold",
  },
  camera: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  microphone: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  accessTypo: {
    fontSize: "10px",
  },
  picto: {
    color: theme.palette.colors.grey400,
  },
  cta: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
    alignItems: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: `0.5px solid ${theme.palette.colors.grey500}`,
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
    height: "27px",
  },
  buttonTypo: {
    fontSize: "10px",
    fontWeight: "bold",
    color: "#7CBBFF",
  },
  greenLine: {
    border: `1px solid ${theme.palette.colors.success}`,
    borderRadius: "5px",
    padding: "5px",
  },
}));

const NavigatorAccessScreen = ({ mode }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography>{`${"this website"} ${_t("wants")}`}</Typography>
      <XLg size={10} className={classes.exit} />

      {mode === "video" && <Box className={classes.camera}>
        <CameraVideoFill className={classes.picto} />
        <Typography className={classes.accessTypo}>{_t("Use your camera")}</Typography>
      </Box>}

      <Box className={classes.microphone}>
        <MicFill className={classes.picto} />
        <Typography className={classes.accessTypo}>{_t("Use your microphone")}</Typography>
      </Box>

      <Box className={classes.cta}>

        <Box className={classes.button}>
          <Typography className={classes.buttonTypo}>{_t("Block")}</Typography>
        </Box>
        <Box className={classes.greenLine}>
          <Box className={classes.button}>
            <Typography className={classes.buttonTypo}>{_t("Allow")}</Typography>
          </Box>
        </Box>


      </Box>
    </Box>
  )
};

export default NavigatorAccessScreen;