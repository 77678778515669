import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import IconButton from "app/liveShopping/backoffice/otm/components/reusables/IconButton";
import ThreeDots from "react-bootstrap-icons/dist/icons/three-dots";
import Plus from "react-bootstrap-icons/dist/icons/plus";
import { ClickAwayListener, Modal } from "@material-ui/core";
import Add from "./Add";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    maxWidth: "none",
    minWidth: "106px",
    backgroundColor: "black",
    padding: "8px 10px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    top: "57px",
    marginRight: "-120px",
    gap: "10px",
  },
  line: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    cursor: "pointer",
  },
  edit: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
  clone: {
    color: "white",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
  delete: {
    color: theme.palette.colors.error,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
  },
}));

const AddOptions = ({ }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);
  return (
    <>
      <IconButton onClick={() => setOpen(!open)}>
        <ThreeDots />
      </IconButton>

      {open && (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Box className={classes.root}>
            <Box className={classes.line} onClick={handleOpenAdd}>
              <Plus size={20} color={"white"} /> <Typography className={classes.edit}>{_t("Add")}</Typography>
            </Box>
          </Box>
        </ClickAwayListener>
      )}

      <Modal open={openAdd} onClose={handleCloseAdd} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Add onAdd={handleCloseAdd} />
      </Modal>
    </>
  );
};

AddOptions.propTypes = {};

AddOptions.defaultProps = {};

export default AddOptions;
