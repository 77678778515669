import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import clsx from "clsx";
import { _t } from "i18n";
import get from "lodash/get";

import Box from "@material-ui/core/Box";
import Button from "appComponents/Mui/Button";
import EmailIcon from "@material-ui/icons/Email";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

import { getKeyword } from "applicationDucks/selectors";

import makeRequest from "utilities/makeRequest";
import makeUrl from "ressourcesDucks/sagas/makeUrl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  iconBG: {
    height: theme.spacing(7),
    width: theme.spacing(7),
    backgroundColor: theme.palette.background.secondaryBox,
    color: theme.palette.secondary.main,
  },
  icon: {
    fontSize: theme.spacing(5),
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline",
    marginLeft: "3px",
  },
}));
const UnverifiedUserWall = ({ keyword }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const sendVerificationMail = async () => {
    try {
      setLoading(true);
      //setLoading(true);
      const url = makeUrl(
        { type: "Custom" },
        {
          slug: ["user", "verification-email"],
          XHR_ID: `verification_email_${keyword}`,
        }
      );

      await makeRequest(url, { type: "Custom" }, { method: "POST" });

      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const refresh = () => window.location.reload(false);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      justifyContent="flex-start"
      alignItems="center"
      className={clsx("UnverifiedUserWall", classes.root)}
    >
      <Box display="flex" mt={3}>
        <Typography>{_t("You must verify your email.")}</Typography>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="50%"
        className={classes.iconBG}
        my={4}
      >
        <EmailIcon className={classes.icon} />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
        <Typography>
          {_t("To continue, you need to validate your account.")}
        </Typography>
        {/* prettier-ignore */}
        <Typography>
          {_t("You must have received an email that allows you to validate and access the next page.")}
        </Typography>
      </Box>
      <Button
        color="primary"
        variant="contained"
        size="large"
        onClick={sendVerificationMail}
        disabled={loading}
      >
        {_t("Resend email")}
      </Button>
      <Box display="flex" mt={2}>
        <Typography>
          {_t("I have just validated my account, I can access the following ")}
        </Typography>
        <Box onClick={refresh} className={classes.link}>
          <Typography>{_t("by clicking here.")}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

UnverifiedUserWall.propTypes = {
  keyword: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
  return {
    keyword: get(ownProps, "keyword", getKeyword(state)),
  };
};

export default connect(mapStateToProps)(UnverifiedUserWall);
