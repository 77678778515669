import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import PropTypes from "prop-types";
import clsx from "clsx";
import WifiTetheringIcon from "@material-ui/icons/WifiTethering";
import { _t } from "i18n";

const useStyles = makeStyles((theme) => ({
  notStarted: {
    backgroundColor: theme.wisemblyColors.orange,
  },
  inProgress: {
    backgroundColor: theme.wisemblyColors.green,
  },
  finished: {
    backgroundColor: theme.wisemblyColors.pastelRed,
  },
  base: {
    //height: theme.spacing(3),
    //height: "18px",
    height: "auto",
    padding: "3px 7px",
  },
  live: {
    backgroundColor: theme.wisemblyColors.pastelRed,
  },
  icon: {
    fontSize: "10px",
  },
  text: {
    position: "relative",
    top: 1,
    padding: 0,
    fontSize: 10,
    lineHeight: "100%",
    textTransform: "uppercase",
    color: "#fff",
    // HEX en dur car non modifiable
  },
}));

const WizStateChip = ({
  status,
  customClass,
  customTextClass,
  customIconClass,
}) => {
  const classes = useStyles();

  let config = {};
  switch (status) {
    case "WIZ_FINISHED":
      config = { classe: classes.finished, label: _t("Finished") };
      break;
    case "WIZ_STARTED":
      config = {
        classe: classes.inProgress,
        label: _t("In Progress"),
      };
      break;
    case "WIZ_NOT_STARTED":
      config = { classe: classes.notStarted, label: _t("Not started") };
      break;
    case "WIZ_LIVE":
      config = {
        classe: classes.live,
        label: _t("Live"),
        icon: <WifiTetheringIcon className={classes.icon} />,
      };
      break;
    default:
      config = { classe: classes.notStarted, label: _t("Not started") };
      break;
    //date probably not valid
  }

  return (
    <Chip
      icon={config.icon || null}
      label={config.label || null}
      clickable={false}
      classes={{
        root: clsx(config.classe || "NoClass", classes.base, customClass),
        label: clsx(classes.text, customTextClass),
        icon: clsx(classes.icon, customIconClass),
      }}
    />
  );
};

WizStateChip.propTypes = {
  status: PropTypes.string,
  customClass: PropTypes.string,
  customTextClass: PropTypes.string,
  customIconClass: PropTypes.string,
};

export default WizStateChip;
