import React, { useRef, useState } from "react";
import { Box, makeStyles, useMediaQuery } from "@material-ui/core";
import VideoCard from "app/liveShopping/frontoffice/oto/components/Visio/VideoCard";
import MicMuteFill from "react-bootstrap-icons/dist/icons/mic-mute-fill";
import CameraVideoOffFill from "react-bootstrap-icons/dist/icons/camera-video-off-fill";
import { isUser } from "utilities/access";
import { useSelector } from "react-redux";
import { publishStateSelector } from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage/selectors";
import clsx from "clsx";
import { _t } from "utilities/i18n";
import ChevronDown from "react-bootstrap-icons/dist/icons/chevron-down";
import ChevronUp from "react-bootstrap-icons/dist/icons/chevron-up";
import ChevronRight from "react-bootstrap-icons/dist/icons/chevron-right";
import ChevronLeft from "react-bootstrap-icons/dist/icons/chevron-left";
import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  visio: {
    backgroundColor: theme.palette.colors.grey700,
    height: "85%",
    width: "100%",
    color: theme.palette.colors.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    visibility: ({ isAdmin, mode, isPip }) => !isAdmin && mode !== 'video' && isPip ? "hidden" : "visible",
  },
  own: {
    position: props => props.isMobile && "absolute",
    height: props => props.isMobile ? "140px" : props.reduced ? "25px !important" : "100%",
    width: props => props.isMobile ? "90px" : "50%",
    right: props => props.isMobile && "10px",
    top: props => props.isMobile && "10px",
    zIndex: 5,
    filter: props => props.isMobile && "drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25))",
    borderRadius: "8px !important",
    display: "flex",
    position: "relative",
    position: "absolute",
    background: theme.palette.colors.primary10,
  },
  ownAdminPip: {
    position: "absolute",
    height: "100%",
    width: "100%",
  },
  ownAdmin: {
    width: "50%",
    height: "100%",
    aspectRatio: "16/9",
  },
  ownMobilePip: {
    width: props => props.device === "mobile" ? "60px" : "160px",
    height: props => props.device === "mobile" ? "107px" : "90px",
    borderRadius: "8px",
  },
  ownFullScreen: {
    width: "160px !important",
    height: "90px !important",
    position: "absolute",
    right: "10px",
    top: "10px",
    borderRadius: "8px !important",
  },
  ownPip: {
    display: "none !important",
  },
  reduced: {
    position: "absolute",
    bottom: props => props.device === "mobile" && 0,
    left: props => props.device === "desktop" && 0,
    width: props => props.device === "mobile" ? "100%" : "25px",
    height: props => props.device === "mobile" ? "25px" : "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "transparent",
    textAlign: "center",
    cursor: "pointer",
    zIndex: 9999,
    color: theme.palette.colors.grey500,
  },
  mutedIcon: {
    position: props => props.device === "mobile" ? "unset" : "absolute",
    bottom: "5px",
    right: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    background: "#000000"
  },
  cameraIcon: {
    position: props => props.device === "mobile" ? "unset" : "absolute",
    bottom: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    width: "22px",
    height: "22px",
    borderRadius: "50%",
    background: "#000000"
  },
  ownMobileReduced: {
    width: "60px !important",
    height: "25px !important",
    borderRadius: "8px",
  },
  ownReduced: {
    width: "25px !important",
    height: "90px !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
  },
  icons: {
    padding: "5px",
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    alignItems: "flex-start",
    justifyContent: "center"
  },
}));
const Own = ({ visitor }) => {
  const isMobile = useMediaQuery("@media (max-width:600px)");
  const isAdmin = isUser("user+");
  const [reduced, setReduced] = useState(false);
  const currentVisitor = useSelector(currentVisitorSelector);
  const { publishAudio, publishVideo } = useSelector(publishStateSelector);
  const device = currentVisitor?.client_info.device;
  const isPip = useMediaQuery("(max-height: 90px)");

  const ownRef = useRef();
  const screenOrientation = useSelector((state) => state.liveShopping.screenOrientation);
  const classes = useStyles({ isMobile, reduced, device, mode: visitor?.options?.mode || 'visio', isAdmin, isPip });

  return (
    <Box className={clsx({
      [classes.visio]: true,
      [classes.own]: !isAdmin,
      [classes.ownMobileReduced]: reduced && !isAdmin && device === "mobile" && !publishVideo,
      [classes.ownReduced]: reduced && !isAdmin && device === "desktop" && !publishVideo,
      [classes.ownAdminPip]: screenOrientation === "admin_pip" && isAdmin,
      [classes.ownAdmin]: screenOrientation === "1x2_admin" && isAdmin,
      [classes.ownMobilePip]: isMobile,
      [classes.ownFullScreen]: !isAdmin && !isMobile && !isPip,
      [classes.ownPip]: !isAdmin && isPip,

    })} ref={ownRef}>
      <VideoCard
        key={"own_video"}
        streamId={"own_video"}
        stream={{
          name: _t("You"),
          hasAudio: publishAudio,
          hasVideo: publishVideo,
        }}
        isOther={false}
        onMounted={() => console.log("mounted")}
      />
      <Box className={classes.icons}>

        {!publishAudio && !isAdmin && !reduced && <Box className={classes.mutedIcon}><MicMuteFill color={"white"} /></Box>}
        {!publishVideo && !isAdmin && !reduced && <Box className={classes.cameraIcon}><CameraVideoOffFill color={"white"} /></Box>}
      </Box>
      {!isAdmin && !publishVideo && (
        <Box className={classes.reduced} onClick={() => setReduced(!reduced)}>
          {
            device === "mobile" && (
              <>{reduced ? <ChevronDown /> : <ChevronUp />}</>
            )
          }
          {
            device === "desktop" && (
              <>{reduced ? <ChevronLeft /> : <ChevronRight />}</>
            )
          }
        </Box>
      )}
    </Box>
  )
};

export default Own;
