import React, { useRef, useEffect, useLayoutEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { FixedSizeList, VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import Separator from "coreUiKit/components/Chat/NewMessage/Separator";
import get from "lodash/get";
import arrayReverse from "lodash/reverse";
import min from "lodash/min";
import split from "lodash/split";
import toNumber from "lodash/toNumber";
import keys from "lodash/keys";
import last from "lodash/last";
import emitter from "utilities/emitter";
import memo, { renderLog } from "core/memo";

const useStyles = makeStyles((theme) => ({
  margin: {
    //marginTop: "10px",
    //marginBottom: "10px",
  },

  virtualizedList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: (props) => (props.reverse ? "flex-end" : "flex-start"),
    //height: "auto !important",
    //marginLeft: "10px",
    scrollbarWidth: (props) => (props.hideScrollbar ? "none" : "10px"),
    MsOverflowStyle: (props) => (props.hideScrollbar ? "none" : "auto"),
    "&::-webkit-scrollbar": {
      width: (props) => (props.hideScrollbar ? "0px" : "8px"),
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "99px",
      background: theme.palette.colors.greyLight,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "99px",
      background: theme.palette.colors.grey,
    },
  },
}));

const ScroolList = ({
  fetchData,
  elements,
  ItemComponent,
  total,
  width,
  height,
  itemHeight,
  reverse,
  variableSize,
  variableSizePadding,
  hideScrollbar,
  scrollDownEvent,
  onScrollDownEvent,
  separatorEvent,
  onReachBottom,
  onScrollableList,
}) => {
  renderLog("InfiniteScrollListBeta");
  const classes = useStyles({ reverse, hideScrollbar });
  const listRef = useRef(null);
  const innerRef = useRef(null);
  const outerRef = useRef(null);
  const inDomRef = useRef({});
  const atBottom = useRef(false);
  const indexSeparator = useRef(null);
  const scrollBackToItem = useRef(null);
  const lastVisibleElementDeltaFromEnd = useRef(0);
  const lastVisibleElementIndex = useRef(0);
  const deltaSize = useRef(0);

  //for reversed list like chat height will be the min between height: auto and parent height
  const [reverseHeight, setReverseHeight] = useState(height);
  const dimensions = useRef({ width: 0, height: 0 });
  //const scrollToBottomAtStart = useRef(false);
  const size = useRef(null);
  const sizeChangeHandled = useRef(false);
  const handleLoadMore = useRef(false);

  useEffect(() => {
    if (indexSeparator.current) {
      indexSeparator.current = null;
    }

    const onScrollToBottom = () => {
      scrollToBottom();
      onScrollDownEvent();
    };

    const onDisplaySeparator = ({ count }) => {
      // @TOOD inactif user
      if (!atBottom.current && count > 0) {
        //const quote = get(elements, `[${count}]`);
        indexSeparator.current = reverse ? get(elements, `[${count - 1}]`) : get(elements, `[${elements.length - count - 1}]`);
      }
    };

    emitter.on(scrollDownEvent, onScrollToBottom);
    emitter.on(separatorEvent, onDisplaySeparator);
    return () => {
      emitter.off(scrollDownEvent, onScrollToBottom);
      emitter.off(separatorEvent, onDisplaySeparator);
    };
  }, [elements.length]);

  //track and set list real height in reverse list
  useEffect(() => {
    if (innerRef.current) {
      dimensions.current.height = toNumber(split(innerRef.current.style?.height, "px")[0]);
      dimensions.current.width = toNumber(split(innerRef.current.style?.width, "px")[0]);
      if (dimensions.current.height > height) {
        onScrollableList(true);
      } else {
        onScrollableList(false);
      }

      //in reverse list to give the impression element print from bottom
      //list height is min between elements height and viewport height
      if (min([height, dimensions.current.height]) !== reverseHeight && reverse) {
        if (dimensions.current.height < height) {
          setReverseHeight(min([height, dimensions.current.height]) + 10);
        } else {
          setReverseHeight(min([height, dimensions.current.height]));
        }
      }
    }
  }, [JSON.stringify(elements), height, reverse]);

  const getInnerDivSize = () => {
    const newHeight = toNumber(split(innerRef.current.style?.height, "px")[0]);
    dimensions.current.height = newHeight;
    const newWidth = toNumber(split(innerRef.current.style?.width, "px")[0]);
    dimensions.current.width = newWidth;

    //in reverse list, list should be at bottom of parent and have minimal height
    //to give impression that elements start from bottom
    if (min([height, dimensions.current.height]) !== reverseHeight && reverse) {
      setReverseHeight(min([height, dimensions.current.height]));
    }
    //}
  };
  useEffect(() => {
    window.addEventListener("resize", getInnerDivSize);
    return () => {
      window.removeEventListener("resize", getInnerDivSize);
    };
  }, []);

  const rowHeights = useRef({});
  const data = [...elements];
  if (reverse) {
    arrayReverse(data);
  }

  const List = variableSize ? VariableSizeList : FixedSizeList;
  const isItemLoaded = (index) => {
    if (reverse) {
      return index > 0;
    } else {
      return elements.length >= total || index < elements.length;
    }
  };

  const loadMore = () => {
    //not used i implement my own load more. ps: it's better
    return new Promise((resolve) => {
      //fetchData();
      resolve();
    });
  };
  //at start i scroll to bottom if is reverse list
  useEffect(() => {
    if (listRef.current && reverse && elements.length > 0) {
      const timeout = setTimeout(function () {
        scrollToBottom();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, []);

  //in reverse list when size change we should scroll back to where user was
  //because don't forget, element always render from top to bottom even if we read
  // from bottom to top
  useEffect(() => {
    if (!sizeChangeHandled.current && reverse) {
      if (listRef.current && scrollBackToItem.current !== null && (deltaSize.current > 1 || atBottom.current)) {
        listRef.current.scrollToItem(scrollBackToItem.current, "end");
        listRef.current.resetAfterIndex(0, true);
        //notify that list scrollBack to right element
        sizeChangeHandled.current = true;
      }
    }
  }, [elements.length]);

  //save initial elements size
  if (size.current === null && elements.length !== 0) {
    //init size
    size.current = elements.length;
  }
  //detect size change
  if (size.current !== null && size.current > 0 && size.current !== elements.length) {
    //const nbElementAdded = elements.length - size.current;
    //because the last index is most of the time invisible item that is hidden so i take 2 item before
    scrollBackToItem.current = elements.length - 1 - lastVisibleElementDeltaFromEnd.current;
    deltaSize.current = elements.length - size.current;
    // if (lastVisibleElementIndex.current + 3 < size.current) {
    //   scrollBackToItem.current =
    //     elements.length - 1 - lastVisibleElementDeltaFromEnd.current - 2;
    // } else {
    //   scrollBackToItem.current =
    //     elements.length - 1 - lastVisibleElementDeltaFromEnd.current;
    // }
    sizeChangeHandled.current = false;
    size.current = elements.length;
    //when size change after fetchData
    handleLoadMore.current = false;
  }

  //const RowRenderer = (ItemComponent) => {
  const Renderer = ({ index, style }) => {
    const classes = useStyles();
    const rowRef = useRef({});
    useEffect(() => {
      inDomRef.current[index] = true;

      return () => {
        delete inDomRef.current[index];
      };
    }, []);
    useEffect(() => {
      if (rowRef.current) {
        //console.log("inDomRef.current", inDomRef.current);
        if (reverse) {
          //track last element beeing rendered

          //because in reverse list we should watch position from the bottom
          //i keep delta between last element at bottom and element being rendered

          //lastVisibleElementDeltaFromEnd.current = data.length - 1 - index;
          const lastElem = last(
            keys(inDomRef.current)
              .map((elem) => toNumber(elem))
              .sort((a, b) => a - b)
          );

          lastVisibleElementIndex.current = lastElem;
          lastVisibleElementDeltaFromEnd.current = data.length - 1 - lastElem;
        }
        //for variable height elements take actual component lenght with
        //offsetHeight
        //this will work only on elements with forward ref
        if (variableSize) {
          setRowHeight(index, rowRef.current.offsetHeight + variableSizePadding);
        }
      }
      // eslint-disable-next-line
    }, []);

    //the ref is forwaded so the ItemComponent should use forwarRef
    const element = data[index];
    return (
      <Box className={classes.margin} style={style}>
        <Box ref={rowRef}>
          <ItemComponent id={element} />
          {indexSeparator?.current && element === indexSeparator.current && <Separator />}
        </Box>
      </Box>
    );
  };
  //   return Renderer;
  // };

  //rowHeight is 82 by default until first RowRenderer
  function getRowHeight(index) {
    return rowHeights.current[index] || 82;
  }

  function setRowHeight(index, size) {
    listRef.current.resetAfterIndex(0, true);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function scrollToBottom() {
    listRef.current.scrollToItem(elements.length - 1, "end");
    listRef.current.resetAfterIndex(0, true);
    listRef.current.scrollToItem(elements.length - 1, "end"); // DON'T DELETE
    atBottom.current = true;
    onReachBottom(true);
  }

  const onScroll = ({
    //scrollDirection,
    scrollOffset,
    scrollUpdateWasRequested,
  }) => {
    const correctHeight = reverse ? reverseHeight : height;
    atBottom.current = correctHeight + scrollOffset >= toNumber(split(innerRef.current.style?.height, "px")[0]);
    onReachBottom(atBottom.current);
    //if scroll within 15 elements loadMore element
    // ignore if scroll was caused by scrollToItem()

    if ((!scrollUpdateWasRequested && scrollOffset !== 0) || (lastVisibleElementIndex.current && scrollOffset === 0)) {
      if (
        //  (lastVisibleElementIndex.current < 15 && reverse) ||
        (lastVisibleElementIndex.current && scrollOffset === 0 && reverse) ||
        (!reverse && elements.length - lastVisibleElementIndex.current < 15)
      ) {
        //loadmore only once until elements size change
        if (!handleLoadMore.current) {
          fetchData();
          handleLoadMore.current = true;
        }
      }
    }
  };

  return (
    <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={total} loadMoreItems={loadMore} threshold={10}>
      {({ onItemsRendered, ref }) => (
        <List
          className={classes.virtualizedList}
          height={reverse ? reverseHeight || 10 : height}
          itemCount={elements.length}
          ref={(reference) => {
            ref(reference);
            listRef.current = reference;
          }}
          estimatedItemSize={82}
          itemSize={variableSize ? getRowHeight : itemHeight}
          onItemsRendered={onItemsRendered}
          width={width}
          itemKey={(index) => get(data, `[${index}]`)}
          innerRef={innerRef}
          outerRef={outerRef}
          onScroll={onScroll}
        >
          {Renderer}
        </List>
      )}
    </InfiniteLoader>
  );
};
//{RowShower(ItemComponent,inDomRef, reverse, variableSize, setRowHeight,lastVisibleElementIndex, lastVisibleElementDeltaFromEnd, indexSeparator)}
ScroolList.propTypes = {
  total: PropTypes.number,
  elements: PropTypes.array,
  ItemComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.object]),
  fetchData: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number.isRequired,
  itemHeight: PropTypes.number.isRequired,
  reverse: PropTypes.bool,
  variableSize: PropTypes.bool,
  hideScrollbar: PropTypes.bool,
  variableSizePadding: PropTypes.number,
  onScrollableList: PropTypes.func,
};
ScroolList.defaultProps = {
  total: 0,
  itemHeight: 172, //card height + marginTop + marginBottom
  height: 500,
  width: "100%",
  reverse: false,
  variableSize: false,
  hideScrollbar: false,
  scrollDownEvent: "event:scrolldown",
  onScrollDownEvent: () => {},
  separatorEvent: "event:separator",
  onReachBottom: () => {},
  variableSizePadding: 0,
  onScrollableList: () => {},
};

export default memo(ScroolList);
