import { currentVisitorSelector } from "app/state/ducks/ressources/selectors";
import { resourceEdit } from "app/state/ducks/ressources/actions";
import { put, select } from "redux-saga/effects";

export function* startVisio() {
    console.log("[VISIO SAGA] START VISIO")

    const visitor = yield select(currentVisitorSelector);

    yield put(resourceEdit(visitor, {
        slug: ["visitors", visitor.hash, "start"],
        method: "POST",
        callback: (error, response) => {
            if (error) {
                console.log("error", error)
            }
        }
    }));
}
