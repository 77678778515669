export const isSelected = (haystack, needle) =>
  !!haystack.find((hay) => hay === needle);

/* We remove from our list all options in selected */
export const listExclude = (options, selected) => {
  return options.filter(([key]) => !isSelected(selected, key));
};

/* We filter our list to only get options present in selected */
export const listInclude = (options, selected) => {
  return options.filter(([key]) => isSelected(selected, key));
};

/* We run a map function using listExclude */
export const mapListExclude = (options, selected, fn) => {
  return listExclude(options, selected).map(([key, value]) => fn(key, value));
};

/* We run a map function using listInclude */
export const mapListInclude = (options, selected, fn) => {
  return listInclude(options, selected).map(([key, value]) => fn(key, value));
};
