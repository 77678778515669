import {
  SET_VIEWED_PRODUCT,
  SET_PLAYER_VOLUME,
  SET_OPEN_CATALOG,
  SET_CLOSE_CATALOG,
  SET_FULL_SCREEN,
  SET_LAYOUT_TYPE,
  SET_OPEN_CART,
  SET_PLAYER_LOADED,
  SET_PLAY,
  SET_OPEN_REPLAY,
  SET_REPLAY_DURATION,
  SET_CURRENT_CHAPTER,
  SET_REPLAY_OVERLAY,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  UPDATE_CART,
  UPDATE_CART_QUANTITY,
  LIVESHOPPING_INIT,
  PRELIVE_INIT,
  SET_REPLAY_DIALOG,
  CHECK_IS_IFRAME,
  PROCEED_TO_CHECKOUT,
  CHECK_IS_PRELIVE,
  DISPLAY_TABBAR,
  DISPLAY_MODAL_ALERT,
  BACK_OFFICE_ELEMENT,
  SET_LIVE_SHOPPING_BOOTSTRAP,
  SET_CURRENT_VISITOR,
  IS_READY,
  IS_RETRACTED,
  OPEN_NPS,
  SET_SCREEN_ORIENTATION,
  ACTIVATE_NOTIFICATIONS,
  SET_CURRENT_CALENDAR,
  SET_CURRENT_LIVE_VENDORS,
  OPEN_FISHING_ROD,
} from "./actionsTypes";

export const liveShoppingInit = () => ({
  type: LIVESHOPPING_INIT,
});
export const preliveInit = () => ({
  type: PRELIVE_INIT,
});
export const setLayoutType = (layout) => ({
  type: SET_LAYOUT_TYPE,
  setLayoutType: layout,
});

export const setViewedProduct = (product) => ({
  type: SET_VIEWED_PRODUCT,
  setViewedProduct: product,
});

export const setPlayerLoaded = (player) => ({
  type: SET_PLAYER_LOADED,
  setPlayerLoaded: player,
});

export const setPlay = (player) => ({
  type: SET_PLAY,
  setPlay: player,
});

export const setPlayerVolume = (volume) => ({
  type: SET_PLAYER_VOLUME,
  setPlayerVolume: volume,
});

export const setOpenCatalog = (catalog) => ({
  type: SET_OPEN_CATALOG,
  setOpenCatalog: catalog,
});

export const setCloseCatalog = () => ({
  type: SET_CLOSE_CATALOG,
  setCloseCatalog: false,
});

export const setOpenReplay = (replay) => ({
  type: SET_OPEN_REPLAY,
  setOpenReplay: replay,
});

export const setFullScreen = (fullscreen) => ({
  type: SET_FULL_SCREEN,
  setFullScreen: fullscreen,
});

export const setOpenCart = (cart) => ({
  type: SET_OPEN_CART,
  setOpenCart: cart,
});

export const setReplayDuration = (duration) => ({
  type: SET_REPLAY_DURATION,
  setReplayDuration: duration,
});

export const setReplayOverlay = (overlay) => ({
  type: SET_REPLAY_OVERLAY,
  setReplayOverlay: overlay,
});

export const setCurrentChapter = (hash) => ({
  type: SET_CURRENT_CHAPTER,
  setCurrentChapter: hash,
});

export const setReplayDialog = (bool) => ({
  type: SET_REPLAY_DIALOG,
  setReplayDialog: bool,
});

export const setIsIFrame = (bool) => ({
  type: CHECK_IS_IFRAME,
  setIsIFrame: bool,
});

export const setIsPrelive = (bool) => ({
  type: CHECK_IS_PRELIVE,
  setIsPrelive: bool,
});

export const updateCart = (cart) => ({
  type: UPDATE_CART,
  updateCart: cart,
});

export const addToCart = ({ product_id, variant_id, variant_vendor_id, quantity }) => ({
  type: ADD_TO_CART,
  product_id,
  variant_id,
  variant_vendor_id,
  quantity,
});
export const updateQuantity = ({ product_id, variant_id, variant_vendor_id, quantity }) => ({
  type: UPDATE_CART_QUANTITY,
  product_id,
  variant_id,
  variant_vendor_id,
  quantity,
});
export const removeFromCart = ({ product_id, variant_id, variant_vendor_id }) => ({
  type: REMOVE_FROM_CART,
  product_id,
  variant_id,
  variant_vendor_id,
});
export const proceedToCheckout = () => ({ type: PROCEED_TO_CHECKOUT });

export const displayTabBar = (bool) => ({
  type: DISPLAY_TABBAR,
  displayTabBar: bool,
});

export const displayModalAlert = (bool) => ({
  type: DISPLAY_MODAL_ALERT,
  displayModalAlert: bool,
});

export const displayBackOfficeElement = (component) => ({
  type: BACK_OFFICE_ELEMENT,
  displayBackOfficeElement: component,
});

export const setLiveShoppingBootstrap = (setLiveShoppingBootstrap) => ({
  type: SET_LIVE_SHOPPING_BOOTSTRAP,
  setLiveShoppingBootstrap: {
    ...setLiveShoppingBootstrap,
    client_datetime: (new Date()).toISOString(),
    datetime_diff_sec: Math.floor(((new Date()).getTime() - (new Date(setLiveShoppingBootstrap.server_datetime)).getTime()) / 1000),
  },
});

export const setCurrentVisitor = (setCurrentVisitor) => ({
  type: SET_CURRENT_VISITOR,
  setCurrentVisitor,
});

export const setCurrentLiveVendors = (currentLiveVendors) => ({
  type: SET_CURRENT_LIVE_VENDORS,
  currentLiveVendors,
});

export const isReady = (isReady) => ({
  type: IS_READY,
  isReady,
});

export const isRetracted = (isRetracted) => ({
  type: IS_RETRACTED,
  isRetracted,
});

export const openNps = (openNps) => ({
  type: OPEN_NPS,
  openNps,
})

export const setScreenOrientation = (setScreenOrientation) => ({
  type: SET_SCREEN_ORIENTATION,
  setScreenOrientation,
})

export const activateNotifications = (activateNotifications) => ({
  type: ACTIVATE_NOTIFICATIONS,
  activateNotifications,
})

export const setCurrentCalendar = (setCurrentCalendar) => ({
  type: SET_CURRENT_CALENDAR,
  setCurrentCalendar,
})


export const openFishingRod = (openFishingRod) => ({
  type: OPEN_FISHING_ROD,
  openFishingRod,
})