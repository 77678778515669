import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import dayjs from "utilities/utils/date";
import localizedFormat from "dayjs/plugin/localizedFormat";
import Button from "../../reusables/Button";
import { useSelector } from "react-redux";
import { displayBackOfficeElementSelector, mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import Timer from "./Timer";
import StatusLive from "../../Lives/Cards/Status";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: "0 20px",
    marginBottom: (props) => !props.isMobile && "56px",
    height: (props) => !props.isMobile && "187px",
    display: "flex",
    flexDirection: "column",
    marginTop: "24px",
  },
  image: {
    width: "112px",
    height: "187px",
    borderRadius: "12px",
    background: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "cover !important",
    backgroundRepeat: "no-repeat !important",
    backgroundPosition: "center !important",
    marginRight: "10px",
    position: "relative",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
    marginTop: "8px",
  },
  date: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
    marginTop: "8px",
  },

  typoButton: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "100%",
    color: "#FFF",
  },
  typoBtnGrey: {
    color: theme.palette.colors.grey700,
    marginLeft: "8px",
  },
}));

const Infos = ({ }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  dayjs.extend(localizedFormat);
  const elements = useSelector(displayBackOfficeElementSelector);
  const wiz = useSelector(currentWizSelector);
  const classes = useStyles({ backgroundImage: wiz?.theme.background_image, isMobile });

  let label = _t("Open the live");

  if (wiz.status === "draft" || wiz.status === "pre_live") label = _t("Open the pre live");

  if (wiz.status === "awaiting_replay" || wiz.status === "replay") label = _t("Open the replay");

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", marginBottom: "37px" }}>
        <Box className={classes.image}>{wiz && <StatusLive wiz={wiz} />}</Box>

        <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
          <Box>
            <Typography className={classes.title}>{wiz?.title}</Typography>
            <Typography className={classes.date}>{dayjs(wiz?.live_start).format("LLL")}</Typography>
          </Box>
          <Timer />
        </Box>
      </Box>
      {!elements && isMobile && (
        <Button link url={`/ls/${wiz.keyword}`} w100>
          <Typography className={classes.typoButton}>{label}</Typography>
        </Button>
      )}
    </Box>
  );
};

Infos.propTypes = {};

Infos.defaultProps = {};

export default Infos;
