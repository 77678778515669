import React from "react";
import PropTypes from "prop-types";

const CustomChevronDown = ({ color, size, ...rest }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      fill={color}
      {...rest}
    >
      <path
        fill-rule="evenodd"
        d="M14.53,4.47a.75.75,0,0,0-1.06,0L8,9.94,2.53,4.47A.75.75,0,1,0,1.47,5.53l6,6a.75.75,0,0,0,1.06,0l6-6a.75.75,0,0,0,0-1.06Z"
      />
    </svg>
  );
};

CustomChevronDown.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomChevronDown.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomChevronDown;
