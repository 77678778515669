import React from "react";
import PropTypes from "prop-types";

const ScreenPipIcon = ({ color, size, ...rest }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_7245_45767" fill="white">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H15C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893ZM10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15H14C14.5523 15 15 14.5523 15 14V10C15 9.44772 14.5523 9 14 9H10Z" />
      </mask>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1V15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H15C15.2652 16 15.5196 15.8946 15.7071 15.7071C15.8946 15.5196 16 15.2652 16 15V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893ZM10 9C9.44772 9 9 9.44772 9 10V14C9 14.5523 9.44772 15 10 15H14C14.5523 15 15 14.5523 15 14V10C15 9.44772 14.5523 9 14 9H10Z" fill="white" />
      <path d="M0.292893 0.292893L1 1L0.292893 0.292893ZM0.292893 15.7071L1 15L0.292893 15.7071ZM15.7071 0.292893L15 1L15.7071 0.292893ZM1 1L-0.414214 -0.414214C-0.789286 -0.0391408 -1 0.469567 -1 1H1ZM1 15V1H-1V15H1ZM1 15H1H-1C-1 15.5304 -0.789286 16.0391 -0.414213 16.4142L1 15ZM1 15H1L-0.414213 16.4142C-0.0391412 16.7893 0.469566 17 1 17V15ZM15 15H1V17H15V15ZM15 15V17C15.5304 17 16.0391 16.7893 16.4142 16.4142L15 15ZM15 15L16.4142 16.4142C16.7893 16.0391 17 15.5304 17 15H15ZM15 1V15H17V1H15ZM15 1V1H17C17 0.469566 16.7893 -0.0391412 16.4142 -0.414213L15 1ZM15 1V1L16.4142 -0.414213C16.0391 -0.789286 15.5304 -1 15 -1V1ZM1 1H15V-1H1V1ZM1 1V-1C0.469567 -1 -0.0391408 -0.789286 -0.414214 -0.414214L1 1ZM10 10V8C8.89543 8 8 8.89543 8 10H10ZM10 14V10H8V14H10ZM10 14H8C8 15.1046 8.89543 16 10 16V14ZM14 14H10V16H14V14ZM14 14V16C15.1046 16 16 15.1046 16 14H14ZM14 10V14H16V10H14ZM14 10H16C16 8.89543 15.1046 8 14 8V10ZM10 10H14V8H10V10Z" fill="white" mask="url(#path-1-inside-1_7245_45767)" />
    </svg>

  );
};

ScreenPipIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ScreenPipIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default ScreenPipIcon;
