import BaseModel from "./base";
import { attr, fk, oneToOne } from "redux-orm";

class Group extends BaseModel {
  constructor(args) {
    super(args);
    this.Model = Group;
  }

  setProject(Project) {
    this.project = Project.id;
  }

  setCalendar(Calendar) {
    this.calendar = Calendar.id;
  }

  // needed to avoid error if somehow queue resource does not exist yet in backend
  unsetQueue() {
    // do nothing
  }

}

Group.modelName = "Group";
Group.backendName = "group";

Group.fields = {
  id: attr(),
  title: attr(),
  options: attr(),
  urls: attr(),
  project: fk("Project", "groups"),
  calendar: oneToOne("Calendar", "group"),
};

export default Group;
