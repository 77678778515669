import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import UplaodImage from "./UplaodImage";
import FilterGear from "./FilterGear";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: (props) => (props.isMobile ? "row" : "row-reverse"),
    alignItems: "center",
  },
  iconImg: {
    padding: (props) => (props.disabled ? "0" : props.isMobile ? "0 10px 0 0" : "0 0 0 10px"),
  },
}));

const Secondaries = ({ onUpload, setImgPath, imgPath, disabled, isMobile }) => {
  const classes = useStyles({ isMobile, disabled });

  return (
    <Box className={classes.root}>
      {/* <Box className={classes.iconImg}>{!disabled && <UplaodImage setImgPath={setImgPath} imgPath={imgPath} onUpload={onUpload} disabled={disabled} isMobile={isMobile} />}</Box>
      <FilterGear isMobile={isMobile} /> */}
    </Box>
  );
};

Secondaries.propTypes = {
  onUpload: PropTypes.func,
  setImgPath: PropTypes.func,
  imgPath: PropTypes.string,
  disabled: PropTypes.bool,
  isMobile: PropTypes.bool,
};

Secondaries.defaultProps = {
  onUpload: () => {},
  setImgPath: () => {},
  imgPath: "",
  disabled: false,
  isMobile: false,
};

export default Secondaries;
