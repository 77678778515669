import { locatorString } from "./index";

export default function mapIncluded(included) {
  let includedMap = {};

  included.map((resource) => {
    includedMap[locatorString(resource)] = resource;
  });

  return includedMap;
}
