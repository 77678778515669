import React, { forwardRef } from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import MuiIconButton from "@material-ui/core/IconButton";
import ThemedIconButton from "./ThemedIconButton";

const CustomIconButton = withStyles((theme) => {
  return {
    root: (props) => {
      const themeButton = ThemedIconButton(props, theme);
      return {
        ...themeButton,
        boxShadow: "none",
      };
    },
    disabled: {},
    label: {
      textAlign: "center",
      textTransform: "none",
    },
  };
})(MuiIconButton);

const IconButton = forwardRef(({ color, ...props }, ref) => {
  const params = {
    ...props,
    color,
    ...(color !== "ghost" ? {} : { rounded: "s" }),
  };

  return <CustomIconButton ref={ref} {...params} />;
});

IconButton.displayName = "Button";

IconButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "alert",
    "tertiary",
    "ghost",
  ]),
};

IconButton.defaultProps = {
  color: "primary",
};

export default IconButton;
