import React, { useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { _t } from "i18n";
import Popper from "appComponents/Mui/Popper";
import Box from "@material-ui/core/Box";
import Button from "appComponents/Mui/Button";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { getSendDate } from "./utils";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  popper: {
    background: theme.palette.secondary.contrastText,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    maxWidth: "250px",
    fontSize: "12px",
  },
  popperButton: {
    marginTop: theme.spacing(1.4),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1.4),
    marginLeft: theme.spacing(1),
  },
  text: {
    padding: theme.spacing(3),
    textAlign: "center",
  },
  cannotSchedule: {
    textAlign: "center",
    padding: theme.spacing(3),
  },
  helperCannotSchedule: {
    fontSize: "12px",
    marginTop: theme.spacing(1),
  },
}));

const ConfirmSendingButton = ({ template, onClick, children, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const ref = useRef(null);

  const handleClick = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
  };

  const open = disabled ? false : Boolean(anchorEl);

  const closePopper = () => {
    setAnchorEl(null);
  };

  const handleConfirm = (event) => {
    onClick(event);
    closePopper();
  };
  const sendDate = getSendDate(template);
  let inTheFuture = true;
  let canBeScheduled = true;
  if (
    template.send_type !== "sending_type_after_registration" &&
    template.send_type !== "sending_type_after_invitation"
  ) {
    // Automatic emails are "always" in the future
    inTheFuture = dayjs().isBefore(sendDate);
    canBeScheduled = dayjs().add(5, "minute").isBefore(sendDate);
  }
  return (
    <>
      <Popper anchorEl={anchorEl} open={open}>
        <ClickAwayListener onClickAway={closePopper}>
          {canBeScheduled ? (
            <Box className={classes.popper} ref={ref}>
              <Box className={classes.text}>
                {sendDate
                  ? _t("Your email will be sent the {date}", {
                      date: sendDate.format(`DD[/]MM[/]YY [${_t("at")}] HH:mm`),
                    })
                  : _t("Your email will be sent automatically")}
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  className={classes.popperButton}
                  size="medium"
                  variant="contained"
                  color="secondary"
                  onClick={closePopper}
                >
                  {_t("Cancel")}
                </Button>
                <Button
                  className={classes.popperButton}
                  size="medium"
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                >
                  {_t("Validate")}
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className={classes.popper} ref={ref}>
              <Box
                display="flex"
                alignItems="center"
                flexDirection="column"
                className={classes.cannotSchedule}
              >
                {
                  /* prettier-ignore */
                  inTheFuture
                  ? _t("You cannot schedule your email to be sent less than 5 minutes from now, please change the settings")
                  : _t("You cannot schedule your email to be sent in the past, please change the settings", )
                }
                <Typography className={classes.helperCannotSchedule}>
                  {_t("Selected : {date}", {
                    date: sendDate.format(`DD[/]MM[/]YY [${_t("at")}] HH:mm`),
                  })}
                </Typography>
              </Box>
            </Box>
          )}
        </ClickAwayListener>
      </Popper>
      <Box onClick={handleClick}>{children}</Box>
    </>
  );
};

ConfirmSendingButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.object,
};

ConfirmSendingButton.defaultProps = {
  onClick: () => {},
};

export default ConfirmSendingButton;
