import { generateHash } from "utilities/uuid";

const importer = data => {
  if (!data?.products) return false;

  let products = [];

  data.products.forEach(({ title, body_html, src, variants, image, images }) => {
    const product = {
      hash: generateHash(40),
      name: title,
      description: body_html,
      enabled: false,
      product_url: src,
      variants: [],
    };

    variants.forEach(({ title, price, compare_at_price, id, image_id }) => {
      product.variants.push({
        vendor_id: id,
        price: parseFloat(price),
        previous_price: compare_at_price,
        name: title,
        hash: generateHash(5),
        picture_url: image_id ? images.find(({ id }) => id === image_id)?.src : image.src,
      });
    });

    products.push(product);
  });

  return products;
}

export default importer;
