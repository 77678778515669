import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "utilities/i18n";
import Divider from "@material-ui/core/Divider";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 15px 6px 15px",
    },
    divider: {
      backgroundColor: theme.palette.colors.error,
      flexGrow: "1",
    },
    label: {
      marginLeft: "9px",
      color: theme.palette.colors.error,
    },
  }),
  {
    name: "OneSeparator",
  }
);

const Separator = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Divider classes={{ root: classes.divider }} />
      <Typography className={classes.label} variant="extraSmallB1">
        {_t("New")}
      </Typography>
    </Box>
  );
};

export default Separator;
