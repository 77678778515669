import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(7, 105, 141, 0.1)",
    borderRadius: "5px",
    padding: "5px",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "2px",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "125%",
    letterSpacing: "0.5px",
  },
  icon: {
    height: "15px",
  },
}));

const Chip = ({ label, onDelete }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>{" "}
      {onDelete && (
        <ClearIcon className={classes.icon} onClick={() => onDelete()} />
      )}
    </Box>
  );
};

Chip.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

Chip.defaultProps = {
  label: "",
  onDelete: null,
};

export default Chip;
