import BaseModel from "./base";
import { attr, fk } from "redux-orm";
import PropTypes from "prop-types";

class Media extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Media;
  }

  setEvent(Event) {
    this.event = Event.id;
  }
}

Media.modelName = "Media";
Media.backendName = "media";

Media.fields = {
  id: attr(),
  data: attr(),
  type: attr(),
  source: attr(),
  status: attr(),
  playable: attr(),
  provider: attr(),
  uploaded_at: attr(),
  persisted: attr(),
  live: attr(),
  event: fk("Event", "medias"),
};

export default Media;

export const MediaProptypes = {
  id: PropTypes.string,
  data: PropTypes.object,
  type: PropTypes.string,
  source: PropTypes.string,
  status: PropTypes.string,
  playable: PropTypes.bool,
  provider: PropTypes.string,
  uploaded_at: PropTypes.string,
  persisted: PropTypes.bool,
  live: PropTypes.object,
};
