import React from "react";
import PropTypes from "prop-types";
import ColorPicker from 'react-color-picker-wheel';

const ColorPick = ({ size, initialColor, shadesBar, brightnessBar, onChange }) => {
    return (
        <ColorPicker onChange={onChange} size={size} initialColor={initialColor} shadesBar={shadesBar} brightnessBar={brightnessBar} />
    );
};

ColorPick.propTypes = {
    size: PropTypes.number,
    initialColor: PropTypes.string,
    shadesBar: PropTypes.bool,
    brightnessBar: PropTypes.bool,
    onChange: PropTypes.func,
};

ColorPick.defaultProps = {
    size: 300,
    initialColor: "#BBFF78",
    shadesBar: false,
    brightnessBar: false,
    onChange: () => { },
};

export default ColorPick;
