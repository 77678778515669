import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
      cursor: "pointer",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      opacity: (props) => (props.disabled ? 0.5 : 1),
      alignItems: "center",
      height: (props) => (props.checked ? "18px" : "18px"),
      width: (props) => (props.checked ? "18px" : "18px"),
      borderRadius: "50px",
      color: theme.palette.colors.white,
      border: (props) =>
        props.checked ? "none" : `1px solid ${theme.palette.colors.greyMedium}`,
      backgroundColor: (props) =>
        props.checked
          ? theme.palette.colors.primary
          : theme.palette.colors.white,
    },
    smallCircle: {
      borderRadius: "50%",

      backgroundColor: theme.palette.colors.white,
      height: "6px",
      width: "6px",
    },
  }),
  {
    name: "OneRadio",
  }
);
//controlled checkBox
const Radio = ({ checked, onChange, disabled, value, name }) => {
  const classes = useStyles({ checked, disabled });
  return (
    <Box
      id={name}
      onClick={() => !disabled && onChange(value)}
      className={classes.root}
    >
      <Box className={classes.smallCircle}></Box>
    </Box>
  );
};
Radio.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
export default Radio;
