import React, { useEffect, useState } from "react";
import Chat from "app/liveShopping/frontoffice/otm/components/Chat/Chat";
import { useReplayQuotes } from "./hooks";
import { useDispatch, useSelector } from "react-redux";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import {
  currentWizReplaySelector,
  currentWizSelector,
  genericResourcesSelector,
} from "app/state/ducks/ressources/selectors";
import { REPLAY_TIMESTAMP_UPDATE } from "utilities/emitter/events";
import emitter from "utilities/emitter";

const ChatContainer = () => {
  const wiz = useSelector(currentWizSelector);
  const isReplay = wiz.status === 'replay';

  const dispatch = useDispatch();
  const replay = useSelector(currentWizReplaySelector);
  const [currentTimestamp, setCurrentTimestamp] = useState(0);

  const updateTimestamp = (s) => setCurrentTimestamp(s);

  useEffect(() => {
    if (isReplay)
      emitter.on(REPLAY_TIMESTAMP_UPDATE, updateTimestamp);
    return () => emitter.off(REPLAY_TIMESTAMP_UPDATE, updateTimestamp);
  }, []);

  // https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
  const replayQuotes = useReplayQuotes(wiz, replay, currentTimestamp);
  const recentQuotes = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "quote",
      sort: "created_at",
      order: "desc",
    })
  ).slice(0, 50);

  let quotes = isReplay ? replayQuotes : recentQuotes;

  // if wiz is moderated, only display moderated quotes
  if (wiz.moderate)
    quotes = quotes.filter(({ moderate }) => !!moderate);

  useEffect(() => {
    if (isReplay) return;

    dispatch(
      resourceFetch("quote", {
        slug: ["event", wiz.keyword, "quotes"],
        query: [
          { key: "sort", value: "pinned,recent" },
          { key: "limit", value: 20 },
          { key: "unmoderated", value: false }
        ],
        silent: true,
      })
    );
  }, []);

  return <Chat quotes={quotes} />;
};

ChatContainer.propTypes = {};

ChatContainer.defaultProps = {};

export default ChatContainer;
