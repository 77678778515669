import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { ButtonBase } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "18px 18px",
    width: (props) => props.isMobile && props.w100 && "100%",
    background: (props) => props.color || theme.palette.colors.primary,
    opacity: (props) => props.disabled && 0.5,
    borderRadius: "99px",
    color: theme.palette.colors.white,
    height: (props) => (props.isMobile ? "40px" : "32px"),
    textDecoration: "none",
  },
}));

const Button = ({ onClick, link, url, children, color, clsName, w100, style, disabled, target, rel, type }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ color, isMobile, w100, disabled });

  if (link) {
    return (
      <Link style={style} target={target} rel={rel} to={url} className={clsx({ [classes.root]: true, [clsName]: true })} disabled={disabled}>
        {children}
      </Link>
    );
  }
  return (
    <ButtonBase type={type} style={style} onClick={onClick} className={clsx({ [classes.root]: true, [clsName]: true })} disabled={disabled}>
      {children}
    </ButtonBase>
  );
};

Button.propTypes = {};

Button.defaultProps = {};

export default Button;
