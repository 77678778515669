import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MuiTypography from "@material-ui/core/Typography";

const defaultTypography = {
  lineHeight: "130%",
  fontWeight: "400",
};

const useStyles = makeStyles((theme) => ({
  headline13: {
    ...defaultTypography,
    fontSize: "30px",
    fontWeight: "700",
  },
  title1: {
    fontSize: "25px",
    lineHeight: "1",
    fontWeight: "700",
  },

  // SUB TITLE
  subtitle1: {
    fontSize: "18px",
    lineHeight: "1",
    fontWeight: "700",
  },
  subtitle13: {
    ...defaultTypography,
    fontSize: "18px",
    fontWeight: "700",
  },

  // LARGE BODY
  largeBody1: {
    ...defaultTypography,
    fontSize: "16px",
    lineHeight: "1",
  },
  largeBody13: {
    ...defaultTypography,
    fontSize: "16px",
  },
  largeBody15: {
    ...defaultTypography,
    fontSize: "16px",
    lineHeight: "150%",
  },
  largeBodySB1: {
    fontSize: "16px",
    lineHeight: "1",
    fontWeight: "600",
  },
  largeBodySB13: {
    ...defaultTypography,
    fontSize: "16px",
    fontWeight: "600",
  },
  largeBodySB15: {
    fontSize: "16px",
    lineHeight: "150%",
    fontWeight: "600",
  },
  largeBodyB1: {
    fontSize: "16px",
    lineHeight: "1",
    fontWeight: "700",
  },
  largeBodyB13: {
    ...defaultTypography,
    fontSize: "16px",
    fontWeight: "700",
  },
  largeBodyB15: {
    fontSize: "16px",
    lineHeight: "150%",
    fontWeight: "700",
  },

  // BODY
  body1: {
    ...defaultTypography,
    fontSize: "14px",
    lineHeight: "1",
  },
  body13: {
    ...defaultTypography,
    fontSize: "14px",
  },
  body15: {
    ...defaultTypography,
    fontSize: "14px",
    lineHeight: "150%",
  },
  bodySB1: {
    fontSize: "14px",
    lineHeight: "1",
    fontWeight: "600",
  },
  bodySB13: {
    ...defaultTypography,
    fontSize: "14px",
    fontWeight: "600",
  },
  bodySB15: {
    fontSize: "14px",
    lineHeight: "150%",
    fontWeight: "600",
  },
  bodyB1: {
    fontSize: "14px",
    lineHeight: "1",
    fontWeight: "700",
  },
  bodyB13: {
    ...defaultTypography,
    fontSize: "14px",
    fontWeight: "700",
  },
  bodyB15: {
    fontSize: "14px",
    lineHeight: "150%",
    fontWeight: "700",
  },

  // SMALL BODY
  smallBody1: {
    ...defaultTypography,
    fontSize: "12px",
    lineHeight: "1",
  },
  smallBody13: {
    ...defaultTypography,
    fontSize: "12px",
  },
  smallBody15: {
    ...defaultTypography,
    fontSize: "12px",
    lineHeight: "150%",
  },
  smallBodySB1: {
    fontSize: "12px",
    lineHeight: "1",
    fontWeight: "600",
  },
  smallBodySB13: {
    ...defaultTypography,
    fontSize: "12px",
    fontWeight: "600",
  },
  smallBodySB15: {
    fontSize: "12px",
    lineHeight: "150%",
    fontWeight: "600",
  },
  smallBodyB1: {
    fontSize: "12px",
    lineHeight: "1",
    fontWeight: "700",
  },
  smallBodyB13: {
    ...defaultTypography,
    fontSize: "12px",
    fontWeight: "700",
  },
  smallBodyB15: {
    fontSize: "12px",
    lineHeight: "150%",
    fontWeight: "700",
  },

  // EXTRA SMALL
  extraSmall1: {
    ...defaultTypography,
    fontSize: "10px",
  },
  extraSmall15: {
    ...defaultTypography,
    fontSize: "10px",
    lineHeight: "150%",
  },
  extraSmallB1: {
    ...defaultTypography,
    fontSize: "10px",
    fontWeight: "700",
  },
  extraSmallB15: {
    fontSize: "10px",
    lineHeight: "150%",
    fontWeight: "700",
  },
  color: {
    color: (props) => (props.color ? theme.palette.colors[props.color] : "#000"),
  },
  root: {
    wordBreak: "break-word",
  },
}));

const Typography = ({ children, variant, className, color, ...props }) => {
  const classes = useStyles({ color });

  return (
    <MuiTypography className={clsx([classes.root, classes.color, classes[variant], ...className.split(" ")])} {...props} variant="body1">
      {children}
    </MuiTypography>
  );
};

Typography.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  variant: PropTypes.string,
  className: PropTypes.string,
};

Typography.defaultProps = {
  className: "",
};

export default Typography;
