import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: "1 auto",
    justifyContent: "center",
    alignItems: "center",
  },
  loader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    marginBottom: 8,
    fontWeight: 600,
  },
}));

export default function AppLoader({ className, wrapperClass, children = <span>&nbsp;</span> }) {
  const classes = useStyles();

  return (
    <Box className={clsx("AppLoader", classes.root, wrapperClass)}>
      <Box className={clsx("Loader", classes.loader, className)}>
        <Typography className={classes.loading}>{children}</Typography>
        <CircularProgress style={{ color: "#000" }} />
      </Box>
    </Box>
  );
}

AppLoader.propTypes = {
  className: PropTypes.string,
  wrapperClass: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
};
