const areEqual = (resourceA, resourceB) => {
  if (!resourceA || !resourceB) return false;
  if (
    "function" !== typeof resourceA.isEqualTo ||
    "function" !== typeof resourceB.isEqualTo
  )
    return false;
  return resourceA.isEqualTo(resourceB);
};

export default areEqual;
