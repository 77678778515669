import BaseModel from "./base";
import { attr } from "redux-orm";

class Contract extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = Contract;
  }
}

Contract.modelName = "Contract";
Contract.backendName = "contract";

Contract.fields = {
  id: attr(),
  type: attr(),
  name: attr(),
  description: attr(),
};

export default Contract;
