import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { _t } from "utilities/i18n";
import { MenuUni as Menu } from "appComponents/Mui";
import Calendar2Plus from "react-bootstrap-icons/dist/icons/calendar2-plus";
import { makeStyles } from "@material-ui/core/styles";
import images from "images";
import AppleIcon from "@material-ui/icons/Apple";

import { generateUrl } from "../LandingLiveShopping/utils/utils";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "14px",
    marginBottom: "30px",
    textAlign: "center",
  },
  icon: {
    color: "black !important",
    fontSize: 20,
    // marginLeft: "4px",
  },
  itemIcon: {
    height: "18px",
    width: "18px",
    color: theme.palette.colors.black,
  },
  checkIcon: {
    color: theme.palette.colors.success,
    height: "25px",
    width: "25px",
    marginRight: "12.5px",
  },
  inscriptionState: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: "18px",
  },
  inscriptionStateLabel: {
    fontFamily: "Open Sans",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "24px",
  },
  button: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    borderRadius: "99px",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    margin: 0,
    cursor: "pointer",
    "&::hover": {
      backgroundColor: "#FFFFFF !important",
      border: "none !important",
      color: "#000 !important",
    },

    "& .MuiButton-startIcon": {
      margin: 0,
    },
  },
}));

const Agenda = ({ wiz, landing }) => {
  const classes = useStyles({
    // logoBackground: wiz && wiz.theme && wiz.theme.logo_background,
    // backgroundImage: wiz && wiz.theme.background_image,
  });
  //add is live notifier
  const menuOptions = [
    {
      icon: <AppleIcon className={classes.itemIcon} />,
      title: "Apple",
      hash: "apple",
    },
    {
      icon: <images.GoolgeCalendar className={classes.itemIcon} />,
      title: "Google Agenda",
      hash: "google",
    },
    {
      icon: <images.Microsoft_logo className={classes.itemIcon} />,
      title: "Microsoft 365",
      hash: "microsoft_365",
    },
    {
      icon: <images.Microsoft_Outlook className={classes.itemIcon} />,
      title: "Outlook",
      hash: "microsoft_live",
    },
  ];

  const onFilterChange = (filter) => {
    const url = generateUrl(filter.value, wiz);
    if (url) {
      window.open(url, "_blank").focus();
    }
  };
  return (
    <Menu
      title={""}
      key="calendar"
      id="calendar"
      type="selectText"
      options={menuOptions}
      onFilterChange={onFilterChange}
      buttonVariant="outlined"
      inactiveColor="secondary"
      ml={10}
      icon={<Calendar2Plus className={classes.icon} />}
      chevron={false}
      buttonClassName={clsx("button style", classes.button)}
    />
  );
};

Agenda.propTypes = {
  wiz: PropTypes.object,
  landing: PropTypes.object,
};

export default Agenda;
