import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CardMedia from "@material-ui/core/CardMedia";
import clsx from "clsx";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { FormIcon } from "appComponents/Viewers";
import { useFormIntroState } from "appComponents/SurveyForms";
import emitter from "core/emitter";
import { TOOLTIP_INTERACTIVITY } from "core/emitter/events";

const useStyles = makeStyles((theme) => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  surveyIntro: {
    borderRadius: (props) => (props.isThumbnailView ? 0 : "15px"),

    color: theme.palette.text.primary,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      minHeight: (props) =>
        props.isThumbnailView ? "100%" : theme.spacing(40),
      padding: (props) => (props.isThumbnailView ? "5px 9px 9px 6px" : "30px"),
    },
    [theme.breakpoints.down("xs")]: {
      padding: "30px",
    },
  },
  responseTitle: {
    color: theme.palette.button.default.color.enable,
    minHeight: theme.spacing(10),
  },

  icon: {
    fontSize: (props) => (props.isThumbnailView ? "1rem" : "2.18rem"),
  },

  content: {
    flexDirection: "column",
    justifyContent: (props) =>
      props.isThumbnailView ? "space-around" : "center",
  },
  titleText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    //flexGrow: (props) => (props.isThumbnailView ? 0 : 1),
    marginTop: (props) => (props.isThumbnailView ? 0 : 30),
    display: (props) => (props.isThumbnailView ? "none" : "-webkit-box"),
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    color: theme.palette.button.default.color.enable,
    fontWeight: 700,
    letterSpacing: " 0.58px",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: (props) =>
        props.isThumbnailView
          ? "12px"
          : props.layoutMode === "split"
          ? "20px"
          : "25px",
    },
  },
  actionText: {
    color: theme.palette.secondary.contrastText,
  },

  media: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
  },
  mediaBox: {
    display: (props) =>
      props.isThumbnailView || !props.hasImageDesc ? "none" : "flex",
    // minHeight: "50vh",
    // maxHeight: "50vh",
    minHeight: theme.spacing(10),
    //maxHeight: "calc(100vh - 100px)",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "289px",
    },
    [theme.breakpoints.down("xs")]: {
      //100vh - header - bottomTabBar
      maxHeight: "200px",
    },
    marginTop: 50,
    //maxHeight: "50vh",
  },
  description: {
    marginTop: 20,
    display: (props) => (props.isThumbnailView ? "none" : "flex"),
  },
}));

const SurveyIntro = ({ survey, isThumbnailView, layoutMode, forResult }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const showIntro = useFormIntroState(get(survey, "hash", ""));

  const classes = useStyles({
    isThumbnailView,
    smallScreen,
    layoutMode,
    forResult,
    showIntro,
    hasImageDesc: !isEmpty(get(survey, "description_image.fullscreen")),
  });
  useEffect(() => {
    //if (surveySection === "present") {
    emitter.emit(TOOLTIP_INTERACTIVITY, survey.name);
    //}
  }, [survey.name]);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={clsx("SurveyIntro", classes.surveyIntro)}
    >
      <Box
        flex={1}
        display="flex"
        height="100%"
        width="100%"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.content}
      >
        <FormIcon
          type={survey.type}
          isThumbnailView={isThumbnailView}
          layoutMode={layoutMode}
        />

        <Typography display="block" className={classes.titleText}>
          {survey.name}
        </Typography>

        <Typography className={classes.description}>
          {survey.description}
        </Typography>

        <Box flex={1} className={classes.mediaBox}>
          <CardMedia
            component="img"
            image={get(survey, "description_image.fullscreen")}
            alt={survey.hash}
            className={classes.media}
          />
        </Box>
      </Box>
    </Box>
  );
};
SurveyIntro.propTypes = {
  survey: PropTypes.object,
  item: PropTypes.object,
  isThumbnailView: PropTypes.bool,
  layoutMode: PropTypes.string,
  forResult: PropTypes.bool,
  notifyCanNotSeeAnswer: PropTypes.bool,
  showSurveyTitle: PropTypes.bool,
};
SurveyIntro.defaultProps = {
  isThumbnailView: false,
  forResult: false,
  showSurveyTitle: false,
};

export default SurveyIntro;
