import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import BubbleWrapper from "app/liveShopping/frontoffice/otm/components/Chat/BubbleWrapper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "auto",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}));

const BubbleContainer = ({ quote }) => {
  const classes = useStyles();

  const getOriginMessage = (message) => {
    return message.is_mine ? "me" : "others";
  };

  const params = {
    quote: quote.quote,
    url: quote.url,
    origin: getOriginMessage(quote),
    owner: quote.username ? quote.username : _t("Anonymous"),
    isAdmin: quote.announcement,
    createdAt: quote.published_at,
    hash: quote.hash,
  };

  return (
    <Box className={classes.root}>
      <BubbleWrapper {...params} />
    </Box>
  );
};

BubbleContainer.propTypes = {};

BubbleContainer.defaultProps = {};

export default BubbleContainer;
