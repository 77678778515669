import { frontTheme as front } from "./front";
import { backTheme as back } from "./back";
import { otoTheme as oto } from "./oto";
const themes = {
  front,
  back,
  oto,
};

export default function getTheme(theme, options = {}) {
  return themes[theme](options);
}
