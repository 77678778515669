import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, ButtonBase, Divider, CircularProgress, useTheme } from "@material-ui/core";
import Trash from "react-bootstrap-icons/dist/icons/trash";
import { _t } from "i18n";
import { QuantityInput } from "../QuantityInput";
import { formatPrice } from "liveShopping/utils";
import { useDispatch, useSelector } from "react-redux";
import { updateQuantity, removeFromCart } from "app/state/ducks/liveShopping/actions";
import emitter from "core/emitter";
import { CART_UPDATE, CART_UPDATED } from "core/emitter/events";
import { genericResourcesSelector } from "app/state/ducks/ressources/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  productWrapper: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  img: {
    width: "48px",
    height: "48px",
    backgroundImage: (props) => (props.backgroundImage ? `url("${props.backgroundImage}")` : "none"),
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginRight: "10px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    color: "#000",
  },
  description: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.darkGrey,
  },
  price: {
    fontStyle: "normal",
    fontWeight: 800,
    fontSize: "14px",
    lineHeight: "130%",
    color: "#000",
    marginLeft: "10px",
  },
  previousPrice: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "18px",
    letterSpacing: "0em",
    lineHeight: "130%",
    textDecorationLine: "line-through",
    color: theme.palette.colors.grey,
    marginLeft: "5px",
  },
  select: {
    width: "100%",
    marginTop: "15px",
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  bottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "15px",
    marginBottom: "20px",
  },
  priceByUnitTypo: {
    textAlign: "right",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "100%",
    color: "#808284",
  },
}));

const Product = ({ product, product_id, variant_id, quantity, isLastProduct }) => {
  const dispatch = useDispatch();
  const classes = useStyles({
    backgroundImage: product.getPictureUrl(variant_id) || "",
  });
  const theme = useTheme();

  const [isUpdating, setIsUpdating] = useState(false);
  const variant = product.getVariant(variant_id);
  const products = useSelector((state) => genericResourcesSelector(state, { type: "Product" }));

  const onIncrement = (quantity) => {
    dispatch(
      updateQuantity({
        product_id,
        variant_id,
        variant_vendor_id: variant.vendor_id,
        quantity,
      })
    );
  };

  const onRemove = () => {
    emitter.emit(CART_UPDATE);
    setIsUpdating(true);
    dispatch(
      removeFromCart({
        product_id,
        variant_id,
      })
    );
  };

  const onCartUpdated = () => setIsUpdating(false);

  useEffect(() => {
    const productExist = products.find((product) => product.id === product_id && product.variants.find(({ hash }) => hash === variant_id));
    if (!productExist) {
      dispatch(
        removeFromCart({
          product_id,
          variant_id,
        })
      );
      return null;
    }
    emitter.on(CART_UPDATED, onCartUpdated);

    return () => {
      emitter.off(CART_UPDATED, onCartUpdated);
    };
  }, []);

  console.log("ICI", product)
  return (
    <Box className={classes.root}>
      <Box className={classes.productWrapper}>
        <Box className={classes.img} />
        <Box style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box className={classes.top}>
            <Box style={{ maxWidth: "200px" }}>
              <Typography className={classes.title}>
                {product.name}
                &nbsp;
                {product.hasVariants() && <span>|&nbsp;{product.getVariant(variant_id).name}</span>}
              </Typography>

              {product.short && <Typography className={classes.description}>{product.short}</Typography>}
            </Box>
            <ButtonBase onClick={onRemove}>
              <Trash size={18} color={theme.palette.colors.darkGrey} />
            </ButtonBase>
          </Box>

          <Box className={classes.bottom}>
            <QuantityInput
              product={product.hash}
              variant={variant_id}
              defaultValue={quantity}
              size={32}
              onChange={(quantity) => {
                emitter.emit(CART_UPDATE);
                setIsUpdating(true);
              }}
              onDebouncedChange={(quantity) => {
                // do shopify && api stuff
                onIncrement(quantity);
              }}
            />

            {!isUpdating ? (
              <Box>
                <Box style={{ display: "flex" }}>
                  <Typography className={classes.price}>{formatPrice(quantity * product.getVariantPrice(variant_id), "EUR")}</Typography>

                  {product.previous_price && <Typography className={classes.previousPrice}>{formatPrice(quantity * product.previous_price, "EUR")}</Typography>}
                </Box>
                {quantity > 1 && (
                  <Typography className={classes.priceByUnitTypo}>
                    ( {product.getVariantPrice(variant_id)} {product.displayCurrency} {_t("the unit")})
                  </Typography>
                )}
              </Box>
            ) : (
              <CircularProgress size={20} style={{ color: theme.palette.colors.primary }} />
            )}
          </Box>
        </Box>
      </Box>
      {!isLastProduct ? <Divider style={{ marginBottom: 31 }} /> : null}
    </Box>
  );
};

Product.propTypes = {};

Product.defaultProps = {};

export default Product;
