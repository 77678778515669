import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import URI from "urijs";
import { getTToken } from "core/session";
import { API_BASE } from "config";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { _t, _n } from "core/i18n";
import images from "images";
import emitter from "utilities/emitter";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { EVENT_ADD_USERS, ADMIN_EVENT_FLASH_ERROR_VANISH, ADMIN_EVENT_FLASH_SUCCESS_VANISH, EVENT_VALIDATE_SIGNATURES } from "utilities/emitter/events";
import { usePaginatorContextDispatcher, usePaginatorUserDataContextState } from "adminContainers/users/paginatorContext";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import GroupAddOutlined from "@material-ui/icons/GroupAddOutlined";
import SaveAltOutlinedIcon from "@material-ui/icons/SaveAltOutlined";
import ViewWeekOutlinedIcon from "@material-ui/icons/ViewWeekOutlined";
import Hidden from "@material-ui/core/Hidden";
import LibraryAddCheckOutlinedIcon from "@material-ui/icons/LibraryAddCheckOutlined";
import { allColumns } from "adminComponents/UserManagement/constants";
import { TEMPLATE_KEYS, getTitle } from "adminComponents/Mails/utils";
import { ActionButton, TextField, Menu } from "appComponents/Mui";
import { useRoleUpdater } from "adminComponents/UserManagement/hooks";
import DialogSendNotify, { NotifyUpdateRole } from "./DialogSendNotify";
import { currentWizSelector } from "ressourcesDucks/selectors";
import { useSignatureUpdater, usePresentForSignature } from "adminComponents/signatures/hooks";
import memo, { renderLog } from "utilities/memo";
import isEqual from "lodash/isEqual";
import Filter from "react-bootstrap-icons/dist/icons/filter";
import Input from "app/liveShopping/backoffice/otm/components/reusables/Input";
import Download from "react-bootstrap-icons/dist/icons/download";
import { PersonPlus } from "react-bootstrap-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    backgroundColor: theme.palette.colors.white,
    flexDirection: "column",
    justifyContent: "flex-start",
    marginTop: "28px",
    alignItems: "center",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  firstRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: (props) => (props.signature ? "19px" : "0px"),
  },
  right: {},
  moreFilter: {
    marginTop: "19px",
    width: "calc(100%)",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "20px",
    marginLeft: "20px",
    paddingLeft: "30px",
    marginBottom: "19px",
    display: "flex",
    visibility: (props) => (props.moreFilter ? "visible" : "hidden"),
    height: "54px",
    backgroundColor: theme.palette.colors.white,
    borderRadius: "4px",
  },
  inputRoot: {
    boxShadow: "none",
  },
  textField: {
    width: "300px",
  },
  input: {
    padding: "10px",
  },
  notchedOutline: {
    borderColor: theme.palette.colors.lightGray,
  },
}));

const bulkActionRole = {
  promoteToSpeaker: "speaker",
  promoteToAnimator: "anim",
  promoteToAdministrator: "admin",
  promoteToModerator: "modo",
};

const callbackError = () => {
  emitter.emit(ADMIN_EVENT_FLASH_ERROR_VANISH, {
    message: _t("The Action could not be completed."),
  });
};

const removeSuccessCallback = (count) => {
  /* prettier-ignore */
  emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
    message: _n("{count} user has been deleted", "{count} users has been deleted", count, { count }),
  });
};

const successInvitationCallback = (count) => {
  /* prettier-ignore */
  emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
    message: _n("{count} invitation has been sent", "{count} invitations has been sent", count, { count }),
  });
};

const successCallback = (count) => {
  /* prettier-ignore */
  emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
    message: _n("{count} user role has been changed", "{count} users role has been changed", count, { count }),
  });
};

const successSignatureValidationCallback = (count) => {
  /* prettier-ignore */
  emitter.emit(ADMIN_EVENT_FLASH_SUCCESS_VANISH, {
    message: _n("{count} signature validated", "{count} signatures validated", count, { count }),
  });
};

//should create a filter object
const ControlBar = ({ forTeam, onVisibleColumnChange, signature, signatureRequest }) => {
  renderLog(`ControlBar for ${forTeam ? "team" : "participant"}`);
  const [moreFilter, setMoreFilter] = useState(false);
  const event = useSelector(currentWizSelector);
  const hasRegistrationEnabled = event.hasRegistrationEnabled();
  const [search, setSearch] = useState();
  const classes = useStyles({ moreFilter, signature });
  const contextState = usePaginatorUserDataContextState();
  const roleUpdater = useRoleUpdater();
  const signatureUpdater = useSignatureUpdater();
  const selection = contextState.selected || [];
  const [presentCount, presentList, allSignatures] = usePresentForSignature(signatureRequest?.hash);

  const selectionSize = selection.length;

  const AvailableBulkedActionOptions = [
    {
      hash: "sendInvitation",
      title: _t("Send invitation"),
      forRole: ["admin", "participant"],
    },
    {
      hash: "validateSignatures",
      title: _t("Validate"),
      forRole: ["signature"],
    },

    {
      hash: "removeFromWiz",
      title: _t("Remove from wiz "),
      forRole: ["admin", "participant"],
    },
    {
      hash: "promoteToSpeaker",
      title: _t("Promote as speaker "),
      forRole: ["admin"],
    },
    {
      hash: "promoteToAnimator",
      title: _t("Promote as animator "),
      forRole: ["admin"],
    },
    {
      hash: "promoteToAdministrator",
      title: _t("Promote as administrator "),
      forRole: ["admin"],
    },
    {
      hash: "promoteToModerator",
      title: _t("Promote as moderator "),
      forRole: ["admin"],
    },
  ];

  if (selection.length && allSignatures.length) {
    const selectedUsersData = contextState.usersData.filter((elem) => selection.includes(elem.id));
    const presentInSelected = selectedUsersData.filter((elem) => allSignatures.includes(elem?.signature?.hash));

    const presentInSelectedCount = presentInSelected.length;
    if (presentInSelectedCount) {
      /* prettier-ignore */
      AvailableBulkedActionOptions.push({
        hash: "exportSignatures",
        title: _n("Export individual ({userCount} pdf)", "Export individual ({userCount} pdf)", presentInSelectedCount, { userCount: presentInSelectedCount }),
        forRole: ["signature"],
      });
    }
  }

  const [updateRole, setUpdateRole] = useState(null);

  const paginatorContextDispatcher = usePaginatorContextDispatcher();
  const onSearchChange = (event) => {
    paginatorContextDispatcher({
      type: "UPDATE_SEARCH",
      search: event.target.value,
    });
    setSearch(event.target.value);
  };
  const emailsOptions = useMemo(() => {
    let list =
      !isEmpty(TEMPLATE_KEYS) &&
      TEMPLATE_KEYS.map((elem) => {
        return { title: getTitle(elem), hash: elem, type: "select" };
      });
    list.unshift({ title: _t("All emails"), hash: "", type: "select" });

    return list || [];
  }, []);
  //special columns new, checkbox,avatar
  let columns = allColumns.filter((col) => {
    if (col?.conditionallyActivated === "registration" && !hasRegistrationEnabled) {
      return false;
    }

    return col.forRole.includes(forTeam ? "admin" : signature ? "signature" : "participant") && !col.deactivated;
  });
  columns = event.collect_connexion_logs !== true ? columns.filter((col) => col.id !== "exportLogs") : columns;

  const defaultVisibleColumns = useMemo(() => {
    return columns.filter((col) => col.visibleByDefault);
  }, [columns.length]);

  const notHideableColumns = useMemo(() => {
    return columns.filter((col) => col.hideable === false).map((col) => col.id);
  }, [columns.length]);
  //add init value
  const columnsOptions = columns.map((col) => Object.assign(col, { hash: col.id, title: _t(col.title) }));

  const bulkActionOptions = useMemo(() => {
    return AvailableBulkedActionOptions.filter((elem) => (forTeam ? elem.forRole.includes("admin") : signature ? elem.forRole.includes("signature") : elem.forRole.includes("participant")));
  }, [forTeam, signature, JSON.stringify(AvailableBulkedActionOptions)]);

  useEffect(() => {
    const newData = defaultVisibleColumns.map((col) => col.id);

    onVisibleColumnChange((previous) => {
      if (!isEqual(previous, newData)) {
        return newData;
      } else {
        return previous;
      }
    });
  }, []);

  const onFilterChange = (filter) => {
    onVisibleColumnChange(filter.list);
  };
  const onExportSignatures = (selectedUsers) => {
    try {
      let baseTarget = new URI(API_BASE);
      baseTarget
        .segment("event")
        .segment(event.keyword)
        .segment("signature-request")
        .segment(signatureRequest?.hash)
        .segment("export")
        .addSearch(
          "hashs[]",
          selectedUsers.map((elem) => elem?.signature?.hash)
        );
      baseTarget.addSearch(getTToken({ url: baseTarget.toString() }));
      window.open(baseTarget.toString(), "_blank");
    } catch (e) {
      console.log("error", e);
    }
  };
  const onBulkActionChange = useCallback(
    (filter) => {
      const selectedElems = contextState.selected;
      const selectedUsersData = contextState.usersData.filter((elem) => selectedElems.includes(elem.id));

      switch (filter.value) {
        case "sendInvitation":
          roleUpdater(
            "sendInvitation",
            selectedUsersData.map((elem) => elem.access.ref),
            null,
            () => successInvitationCallback(selectedUsersData.length),
            callbackError,
            true
          );
          break;
        case "removeFromWiz": {
          roleUpdater(
            "delete",
            selectedUsersData.map((elem) => elem.access.ref),
            null,
            () => removeSuccessCallback(selectedUsersData.length),
            callbackError
          );
          break;
        }
        case "promoteToSpeaker":
        case "promoteToAnimator":
        case "promoteToAdministrator":
        case "promoteToModerator": {
          setUpdateRole({
            action: "edit",
            access: selectedUsersData.map((elem) => elem.access.ref),
            value: bulkActionRole[filter.value],
            successCallback: () => successCallback(selectedUsersData.length),
            errorCallback: callbackError,
          });
          break;
        }
        case "validateSignatures": {
          signatureUpdater(
            "validateSignatures",
            { signatureRequest },
            selectedUsersData.map((elem) => elem.access.user),
            () => successSignatureValidationCallback(selectedUsersData.length),
            callbackError
          );
          break;
        }
        case "exportSignatures": {
          onExportSignatures(selectedUsersData);
          break;
        }
        default:
          console.log("action not implemented");
      }
    },
    [contextState, updateRole]
  );
  const onFilterValuesChange = (filter) => {
    //onVisibleColumnChange(filter.list);
    paginatorContextDispatcher({
      type: "UPDATE_FILTERS",
      filter: filter.type,
      value: get(filter, "list", filter.value),
    });
  };

  const onExport = () => {
    try {
      let baseTarget = new URI(API_BASE);
      baseTarget.segment("export").segment(event.keyword).segment("excel").segment("accesses");

      baseTarget.search(getTToken({ url: baseTarget.toString() }));
      window.open(baseTarget.toString(), "_blank");
    } catch (e) {
      console.log("error", error);
    }
  };

  const columnsLength = columns.length;

  const roleUpdateAction = (notify) => {
    const { action, access, value, successCallback, errorCallback } = updateRole;
    roleUpdater(action, access, value, successCallback, errorCallback, notify);
    setUpdateRole(null);
  };

  return (
    <Box className={clsx("TeamHeader", classes.root)}>
      <DialogSendNotify open={updateRole} roleUpdateAction={roleUpdateAction} notify={<NotifyUpdateRole count={selectionSize} />} />
      <Box className={clsx(classes.firstRow)}>
        <Box className={classes.content}>
          <Hidden xsUp={signature}>
            <Input
              name={"search"}
              placeholder={forTeam ? _t("Find a team Member") : _t("Find a participant")}
              style={{ width: "300px", marginRight: "8px" }}
              value={search}
              onChange={onSearchChange}
            />
            {/* <TextField
              
              multiline
              fullWidth
              variant="outlined"
              value={search}
              onChange={onSearchChange}
              classes={{ root: classes.textField }}
              inputProps={{}}
            /> */}
            <Button style={{ height: "40px", display: "flex", gap: "5px" }} onClick={() => setMoreFilter(!moreFilter)}>
              <Filter /> {_t("Filters")}
            </Button>
            {/* <Button
              icon={<images.Filters2 style={{ marginLeft: "13px" }} />}
              title={_t("Filters")}
             
              onClick={() => setMoreFilter(!moreFilter)}
            /> */}
          </Hidden>

          <Hidden xsUp={isEmpty(selection)}>
            {/* prettier-ignore */}
            <Menu
              key="GroupedActions"
              title={
                _n("Grouped actions ({count})", "Grouped action ({count}) ", selectionSize, { count: selectionSize })
              }
              id="GroupedActions"
              type="selectText"
              options={bulkActionOptions}
              onFilterChange={onBulkActionChange}
              buttonVariant="contained"
              inactiveColor="secondary"
              ml={10}
              icon={<LibraryAddCheckOutlinedIcon />}
              staticTitle={true}
            />
          </Hidden>
        </Box>

        <Box className={classes.content}>
          <Hidden xsUp={signature}>
            {/* prettier-ignore */}
            <Menu
              key="columns"
              title={_n("{count} column", "{count} columns", columnsLength, { count: columnsLength, })}
              id="columns"
              type="checkbox"
              options={columnsOptions}
              onFilterChange={onFilterChange}
              buttonVariant="contained"
              inactiveColor="secondary"
              defaultOption={defaultVisibleColumns}
              keepOptionSelected={notHideableColumns}
              ml={10}
              icon={<ViewWeekOutlinedIcon />}
              disableFixedOptions
            />
            <Button style={{ display: "flex", gap: "5px", marginLeft: "8px", height: "40px" }} onClick={onExport}>
              <Download /> {_t("Export")}
            </Button>

            <Button style={{ display: "flex", gap: "5px", marginLeft: "8px", height: "40px" }} onClick={() => emitter.emit(EVENT_ADD_USERS, forTeam)}>
              <PersonPlus /> {_t("Add")}
            </Button>
          </Hidden>
          <Hidden xsUp={!signature}>
            <ActionButton
              icon={<images.SignaturePen />}
              title={_t("Validate signature")}
              badgeContent={0}
              inactiveColor="primary"
              toogleColor={false}
              disabled={signatureRequest?.isValidated}
              onClick={() => emitter.emit(EVENT_VALIDATE_SIGNATURES, signatureRequest?.hash)}
              ml={10}
            />
          </Hidden>
        </Box>
      </Box>
      <Hidden xsUp={signature}>
        <Box className={clsx("moreFilter", classes.moreFilter)}>
          <Menu key="emails" title={_t("Emails")} id="emails" options={emailsOptions} badgeContent={0} type="selectText" defaultOption={{ hash: "" }} onFilterChange={onFilterValuesChange} />

          <Menu
            key="sendingStatus"
            title={_t("Sending status")}
            id="sendingStatus"
            type="checkbox"
            options={[
              { type: "checkbox", title: _t("All"), hash: "all" },
              {
                type: "checkbox",
                title: _t("Unsubscribed"),
                hash: "unsubscribed",
              },
              { type: "checkbox", title: _t("Sending"), hash: "sending" },
              { type: "checkbox", title: _t("Blocked"), hash: "blocked" },
              { type: "checkbox", title: _t("Error"), hash: "error" },
              { type: "checkbox", title: _t("Spam"), hash: "spam" },
              { type: "checkbox", title: _t("Opened"), hash: "opened" },
              { type: "checkbox", title: _t("Sent"), hash: "sent" },
              { type: "checkbox", title: _t("Clicked"), hash: "clicked" },
            ]}
            defaultOption={{ hash: "all" }}
            badgeContent={0}
            onFilterChange={onFilterValuesChange}
          />

          <Menu
            key="sendingOrigin"
            title={_t("Sending origin")}
            id="sendingOrigin"
            type="checkbox"
            options={[
              { type: "checkbox", title: _t("All"), hash: "all" },
              { type: "checkbox", title: _t("Other"), hash: "origin_other" },
              { type: "checkbox", title: _t("Import"), hash: "origin_import" },
              //{ type: "checkbox", title: _t("Email"), hash: "email" },
              {
                type: "checkbox",
                title: _t("Wisembly"),
                hash: "origin_wisembly",
              },
            ]}
            defaultOption={{ hash: "all" }}
            badgeContent={0}
            onFilterChange={onFilterValuesChange}
          />

          <Menu
            key="online"
            title={_t("Online")}
            id="online"
            type="checkbox"
            options={[
              { type: "checkbox", title: _t("All"), hash: "all" },
              { type: "checkbox", title: _t("Online"), hash: "online" },
              { type: "checkbox", title: _t("Offline"), hash: "offline" },
            ]}
            defaultOption={{ hash: "all" }}
            badgeContent={0}
            onFilterChange={onFilterValuesChange}
          />

          {/* {hasRegistrationEnabled && (
            <Hidden xsUp={false}>
              <Menu
                key="rsvp"
                title={_t("RSVP")}
                id="rsvp"
                type="checkbox"
                options={[
                  { type: "checkbox", title: _t("All"), hash: "all" },
                  {
                    type: "checkbox",
                    title: _t("Will come"),
                    hash: "willcome",
                  },
                  {
                    type: "checkbox",
                    title: _t("Will not come"),
                    hash: "wontcome",
                  },
                  {
                    type: "checkbox",
                    title: _t("Not answered"),
                    hash: "notanswered",
                  },
                ]}
                defaultOption={{ hash: "all" }}
                badgeContent={0}
                onFilterChange={onFilterValuesChange}
              />
            </Hidden>
          )} */}
        </Box>
      </Hidden>
    </Box>
  );
};
ControlBar.propTypes = {
  forTeam: PropTypes.bool,
  onVisibleColumnChange: PropTypes.func,
  signature: PropTypes.bool,
  signatureRequest: PropTypes.object,
};
ControlBar.defaultProps = {
  forTeam: true,
  signature: false,
  onVisibleColumnChange: () => { },
  signatureRequest: {},
};
// ControlBar.whyDidYouRender = {
//   logOnDifferentValues: true,
//   customName: "ControlBar",
// };
export default memo(ControlBar);
