import { put } from "redux-saga/effects";

import { logOut } from "applicationDucks/actions";

import refreshSession from "applicationDucks/sagas/sessionSaga/refreshSession";

/*
 *
 * Handles API Request errors, that are caught in the makeRequest core
 * NetworkError: default API Error, ie the back-end request failed.
 * ApiTokenExpiredError: token is expired => refresh the token
 * ApiRefreshTokenExpiredError: refresh token is expired => log out
 *
 */
export function* handleRequestError(error, isFetchSession = false) {
  // Refresh the session
  if (error.name === 'ApiTokenExpiredError') {
    yield refreshSession();
    return;
  }

  // Log out the session
  if (error.name === 'ApiRefreshTokenExpiredError') {
    yield put(logOut());
    return;
  }

  if (error.name === 'NetworkError') {
    if (error.statusText === "wrong_token" && isFetchSession) {
      yield put(logOut());
      return;
    }

    yield null;
    return;
  }

  // do something else here too
}