import { useMemo, useCallback, useEffect, useState, useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import ResourceFactory from "orm/resources";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import globalStore from "appStore";
import { getKeyword } from "applicationDucks/selectors";
import { setError, fileUploadSaga } from "app/state/ducks/application/actions";
import { isValidParticipation } from "appComponents/UserVisibility/validParticipation";
import {
  getVerifiedUsername,
  isAnonymousUserParticipation,
} from "utilities/username";
import { filterQuote, isFilterEmpty } from "./utils";
import {
  resourceEdit,
  resourceDelete,
  resourceFetch,
  resourceCreate,
} from "ressourcesDucks/actions";
import {
  currentWizSelector,
  genericResourcesSelector,
  genericResourceSelector,
  countGenericResourcesSelector,
} from "ressourcesDucks/selectors";
import isEmpty from "lodash/isEmpty";
import { getCookie } from "core/basil";
import forEach from "lodash/forEach";
import { getUserSession } from "core/session";
import set from "lodash/set";
import { getFetchResultStatusSelector } from "applicationDucks/selectors";
import * as Sentry from "@sentry/react";
import last from "lodash/last";

export const useQuote = (hash) => {
  const keyword = useSelector(getKeyword);
  return useSelector((state) =>
    genericResourceSelector(state, {
      type: "Quote",
      filter: (quote) =>
        quote.event && quote.event.id === keyword && quote.hash === hash,
    })
  );
};

export const useLiveQuote = () => {
  const event = useSelector(currentWizSelector);
  const { live } = event;

  let liveQuote = null;
  if (get(live, "popup.class_name") === "Quote") {
    liveQuote = {
      type: "Quote",
      data: live.popup,
    };
  }

  const result = useMemo(() => {
    return liveQuote;
  }, [JSON.stringify(liveQuote)]);

  return result;
};

export const useIsLiveQuote = (quote) => {
  const liveQuote = useLiveQuote();

  return liveQuote && liveQuote.data?.hash === quote.hash;
};

export const useQuoteEditManager = (quote) => {
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();

  const handleEditQuote = useCallback(
    (data) => {
      const newQuote = Object.assign(quote, data);

      dispatch(
        resourceEdit(newQuote, {
          slug: ["event", keyword, "quotes", quote.hash],
          silent: true,
          patch: Object.keys(data),
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
            }
          },
        })
      );
    },
    [quote, dispatch]
  );

  return handleEditQuote;
};

export const useQuoteDeleteManager = (quote) => {
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();

  const handleDeleteQuote = useCallback(() => {
    dispatch(
      resourceDelete(quote, {
        slug: ["event", keyword, "quotes", quote.hash],
        silent: true,
        headers: {
          "Wisembly-App-Id": null, //prevent push event from being ignored
        },
        callback: (error, response) => {
          if (error) {
            Sentry.captureException(error);
            //// TODO: error display
          }
        },
      })
    );
  }, [quote, dispatch]);

  return handleDeleteQuote;
};

export const useQuoteManager = (element) => {
  const event = useSelector(currentWizSelector);
  const dispatch = useDispatch();

  const handleStateQuote = useCallback(
    (action) => {
      let data = {};
      switch (action) {
        case "display": {
          data = {
            live: {
              ...event.live,
              popup: {
                class_name: "Quote",
                // hash: element.hash,
              },
            },
          };
          break;
        }
        case "hide": {
          data = {
            live: {
              ...event.live,
              popup: {
                class_name: null,
                // hash: null,
              },
            },
          };
          break;
        }
        default:
          return;
      }

      const newEvent = Object.assign(event, data);

      dispatch(
        resourceEdit(newEvent, {
          slug: ["event", event.keyword],
          silent: true,
          patch: ["live"],
          headers: {
            "Wisembly-App-Id": null, //prevent push event from being ignored
          },
          callback: (error, response) => {
            if (error) {
              Sentry.captureException(error);
              //// TODO: error display
            }
          },
        })
      );
    },
    [event, dispatch]
  );

  return handleStateQuote;
};

export const usePostQuote = ({ admin }) => {
  const keyword = useSelector(getKeyword);
  const state = globalStore.getState();
  const resourceFactory = new ResourceFactory(state);

  const dispatch = useDispatch();

  const handlePostQuote = useCallback(
    (quote) => {
      const { message, media, tags, announcement } = quote;
      const callbackOnValid = () => {
        const isAnonymous = admin ? false : isAnonymousUserParticipation();
        const newQuote = resourceFactory.create("Quote", {
          quote: message,
          username: getVerifiedUsername(),
        });
        tags.map((tag) => newQuote.addTags(tag));

        if (admin) {
          newQuote.announcement = announcement;
        }

        let slug = ["event", keyword, "quotes"];
        const callbackFunc = (error, response) => {
          if (error) {
            setError(error);
            return;
          }
        };

        if (media) {
          let quote = newQuote.toArray();
          quote.is_anonymous = isAnonymous;

          const data = new FormData();
          data.append("file", media);
          data.append("provider", "wisembly");
          data.append("quote", JSON.stringify(quote));

          slug.push("media");
          dispatch(fileUploadSaga(slug, data, { callback: callbackFunc }));
          return;
        }

        dispatch(
          resourceCreate(newQuote, {
            slug: slug,
            inject: [
              {
                path: "data.attributes.is_anonymous",
                value: isAnonymous,
              },
            ],
            callback: callbackFunc,
          })
        );

        return;
      };

      if (admin) {
        callbackOnValid();
        return;
      }
      isValidParticipation({
        callbackOnValid: callbackOnValid,
      });
    },
    [dispatch]
  );

  return handlePostQuote;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "REFRESH_QUOTES":
      return { ...state, loadMore: false, refresh: true, loading: false };

    case "FETCH_MORE":
      return { ...state, loadMore: true, refresh: false, loading: false };

    case "LOADING":
      return { ...state, loadMore: false, refresh: false, loading: true };

    case "FINISH_LOADING":
      return { ...state, loading: false, loadMore: false, refresh: false };
    default:
      return state;
  }
};

export const useQuotes = ({ filters, admin }) => {

  const [state, dispatch] = useReducer(reducer, {
    loadMore: false,
    refresh: false,
    loading: false,
  });

  const keyword = useSelector(getKeyword);
  const reduxDispatch = useDispatch();
  const eventSession = useSelector((state) =>
    genericResourceSelector(state, {
      type: "eventSession",
      filter: (session) => {
        return (
          session.ongoing === true &&
          session.event &&
          session.event.id === keyword
        );
      },
    })
  );
  //const adminFilters = [];
  const quotes = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "quote",
      sort: filters.default?.includes("reacted")
        ? "reaction_count"
        : "created_at",
      order: "desc",
      filter: (quote) =>
        filterQuote(quote, keyword, filters, eventSession, admin),
    })
  );
  const lastQuote = last(quotes);
  const lastQuoteWizRank = lastQuote ? lastQuote.wiz_rank : null;
  const [total, setTotal] = useState(quotes.length || 0);
  const fetchQuotesStatus = useSelector((state) =>
    getFetchResultStatusSelector(state, `quotes_stream_${keyword}`)
  );

  const fetchWizQuotes = useCallback(
    (shouldRefresh, wizRank) => {
      const fetchQuotes = (shouldRefresh, wizRank) => {
        if (!shouldRefresh && !wizRank) {
          //probably get to end of list
          return;
        }
        let query = [
          {
            key: "sort",
            value:
              "pinned," +
              (["mine", "reacted"].includes(get(filters, "default[0]", null))
                ? filters.default[0]
                : "recent"),
          },
          {
            key: "limit",
            value: 20,
          },
        ];

        // add wizRank only to fetch old quotes
        if (wizRank !== null && !shouldRefresh) {
          query.push({ key: "wizRank", value: wizRank });
        }

        if (filters.default?.includes("mine")) {
          const session = getUserSession();
          const { token } = getCookie({ key: "api_session.anonymous" });
          if (!session.isAnon() && token) {
            query.push({ key: "anonymous_token", value: token });
          }

          query.push({ key: "mine", value: true });
          //setFetchedOnceMine(true);
        } else {
          if (filters.default?.includes("reacted")) {
            query.push({ key: "filter", value: "reacted" });
          }

          query.push({
            key: "unmoderated",
            value: filters?.admin?.includes("unmoderated") ? true : false,
          });

          if (eventSession) {
            query.push({ key: "session", value: eventSession.hash });
          }
          if (admin) {
            if (!isEmpty(filters.tags)) {
              forEach(filters.tags, (elem) => {
                query.push({
                  key: "tags[]",
                  value: elem,
                });
              });
            }

            query.push({
              key: "processed",
              value: filters.admin?.includes("unprocessed")
                ? "unprocessed"
                : "both",
            });
            if (filters.admin?.includes("favorite")) {
              query.push({ key: "filter", value: "favorite" });
              set(query, "[0].value", "favorite");
            }

          }
        }

        reduxDispatch(
          resourceFetch("quote", {
            slug: ["event", keyword, "quotes"],
            query: query,
            XHR_ID: `quotes_stream_${keyword}`,
            silent: true,
            //important
            refresh: shouldRefresh,
            callback: (error, response) => {
              if (error) {
                return;
              }
              if (total < response.meta?.total) {
                setTotal(response?.meta?.total || total);
              }
            },
          })
        );

      };

      fetchQuotes(shouldRefresh, wizRank);
    },
    [keyword, admin, JSON.stringify(filters), total]
  );
  //handle loading state
  useEffect(() => {
    if (
      (state.loadMore || state.refresh) &&
      isEqual(fetchQuotesStatus, "pending")
    ) {
      dispatch({ type: "LOADING" });
    }

    if (
      (isEqual(fetchQuotesStatus, "success") ||
        isEqual(fetchQuotesStatus, "error")) &&
      state.loading
    ) {
      dispatch({ type: "FINISH_LOADING" });
    }
  });

  //loadMore data
  useEffect(() => {
    if (
      state.loadMore &&
      !state.loading &&
      !isEqual(fetchQuotesStatus, "pending")
    ) {
      fetchWizQuotes(false, lastQuoteWizRank);
    }
  }, [state.loadMore, state.loading, fetchQuotesStatus]);

  //refresh data
  useEffect(() => {
    if (
      state.refresh &&
      !state.loading &&
      !isEqual(fetchQuotesStatus, "pending")
    ) {

      fetchWizQuotes(true, lastQuoteWizRank);
    }
  }, [state.refresh, state.loading, fetchQuotesStatus]);

  //refreshes data after filter change -- exclude init fetch or switch tabs
  useEffect(() => {
    if (!isEmpty(fetchQuotesStatus)) {

      if(!isFilterEmpty(filters)){
        refresh();
      }

    }
  }, [JSON.stringify(filters)]);

  useEffect(() => {
    if (isEmpty(quotes) && isEmpty(fetchQuotesStatus)) {
      fetchWizQuotes(true, lastQuoteWizRank);
    }
  }, []);
  const loadMore = useCallback(() => {
    dispatch({ type: "FETCH_MORE" });
  }, [dispatch]);
  const refresh = useCallback(() => {
    dispatch({ type: "REFRESH_QUOTES" });
  }, [dispatch]);
  const fetchingAtStart =
    (isEqual(fetchQuotesStatus, "pending") || isEmpty(fetchQuotesStatus)) &&
    isEmpty(quotes);
  return [quotes, loadMore, total];
};

export const useCountNewQuotes = ({ filters, admin }) => {

  const keyword = useSelector(getKeyword);
  const eventSession = useSelector((state) =>
    genericResourceSelector(state, {
      type: "eventSession",
      filter: (session) => {
        return (
          session.ongoing === true &&
          session.event &&
          session.event.id === keyword
        );
      },
    })
  );

  return useSelector((state) =>
    countGenericResourcesSelector(state, {
      type: "quote",
      sort: filters.default?.includes("reacted")
        ? "reaction_count"
        : "created_at",
      order: "desc",
      filter: (quote) =>
        filterQuote(quote, keyword, filters, eventSession, admin),
    })
  );
};

export const useToggleReaction = (quote) => {
  const keyword = useSelector(getKeyword);
  const dispatch = useDispatch();

  const handleToggleReaction = useCallback(
    (reaction) => {
      dispatch(
        resourceCreate(
          {
            type: "Reaction",
            attributes: { reaction },
          },
          {
            slug: ["event", keyword, "quotes", quote.hash, "react"],
            callback: (error, response) => {
              if (error) {
                Sentry.captureException(error);
              }
            },
          }
        )
      );
    },
    [quote.hash, dispatch]
  );

  return handleToggleReaction;
};
