import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { useDispatch, useSelector } from "react-redux";
import { currentUserSelector, eventsSelector } from "app/state/ducks/ressources/selectors";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { resourcesFetch } from "app/state/ducks/ressources/actions";
import AddTask from "liveShopping/assets/images/AddTask";
import Lives from "../../components/Lives/Lives";
import AppLoader from "app/liveShopping/frontoffice/otm/components/AppLoader";
import { getUrl } from "app/views/containers/Application/Routes";

const useStyles = makeStyles((theme) => ({
  root: {},
  noLives: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    textAlign: "center",
    maxWidth: "300px",
    color: theme.palette.colors.grey700,
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: "40px 0px",
  },
}));

const LivesContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(currentUserSelector);
  const events = useSelector(eventsSelector).filter(({ product }) => product === "liveshopping");

  const liveEvents = events.filter(({ status }) => ["ready_for_live", "live"].includes(status));
  const comingEvents = events.filter(({ status }) => status === "pre_live");
  const draftEvents = events.filter(({ status }) => status === "draft");
  const replayEvents = events.filter(({ status }) => ["awaiting_replay", "replay", "closed"].includes(status));

  // put a loading state when we have 0 events in store. It would be removed by next useEffect callback
  const [loading, setIsLoading] = useState(events.length === 0);

  useEffect(() => {
    dispatch(
      resourcesFetch("event", {
        slug: ["user", user.hash, "wiz"],
        callback: () => {
          setIsLoading(false);
        },
      })
    );
  }, []);

  if (loading) return <AppLoader />;

  if (events.length === 0)
    return (
      <Box className={classes.noLives}>
        <AddTask />
        <Typography className={classes.title}>{_t("Create your first Live Shopping in few clics")}</Typography>
        <Button link={true} url={getUrl("otm_live_create")}>
          {_t("Create a live")}
        </Button>
      </Box>
    );

  return <Lives live={liveEvents} coming={comingEvents} draft={draftEvents} replay={replayEvents} />;
};

LivesContainer.propTypes = {};

LivesContainer.defaultProps = {};

export default LivesContainer;
