import React, { useEffect, useState } from "react";
import { withScript } from "hoc/ScriptLoader";
import { devicesSelector, errorSelector } from "./selectors";
import { useDispatch, useSelector } from "react-redux";
import CheckSetup from "app/liveShopping/frontoffice/oto/components/Visio/CheckSetup";
import { CANNOT_ACCESS_DEVICES, NEED_PERMISSION_PROMPT } from "./errors";
import { checkPermissions, getDevices } from "./actions";
import RequestPermissions from "app/liveShopping/frontoffice/oto/components/Visio/RequestPermissions";
import AppLoader from "app/liveShopping/frontoffice/otm/components/AppLoader";
import Visio from "app/liveShopping/backoffice/oto/Component/Live/Visio/Visio";
import { isUser } from "utilities/access";
import CannotAccessDevices from "app/liveShopping/frontoffice/oto/components/Visio/CannotAccessDevices";

const VisioContainer = ({ scriptsLoadedSuccessfully, preview, visitor, mode }) => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const [isLoading, setIsLoading] = useState(true);
  const devices = useSelector(devicesSelector);
  const isAdmin = isUser('user+');

  useEffect(() => {
    if (!scriptsLoadedSuccessfully) return;
    setIsLoading(false);

    // admin directly goes to visio and check permissions
    if (isAdmin)
      dispatch(checkPermissions('video'));
  }, [scriptsLoadedSuccessfully]);

  if (isLoading) return <AppLoader />;

  if (isAdmin && error?.code === NEED_PERMISSION_PROMPT) {
    return (
      <RequestPermissions
        onClick={() => dispatch(getDevices())}
      />
    );
  }

  if (isAdmin && error?.code === CANNOT_ACCESS_DEVICES) {
    return (
      <CannotAccessDevices />
    );
  }

  if (preview) return <CheckSetup preview checkMode={mode} />;

  if (!visitor) return null;

  return <Visio visitor={visitor} />;
};

const scriptUri = "https://static.opentok.com/v2/js/opentok.min.js";
const WrappedVisioContainer = withScript([scriptUri], { cache: true })(VisioContainer);

const VisioVonage = ({ visitor, preview, mode }) =>
  <WrappedVisioContainer
    mode={mode}
    visitor={visitor}
    preview={preview}
  />;

export default VisioVonage;
