import { _t } from "core/i18n";
//types checkbox, select,bool, string, actionStatus, action, avatarProfile
export const ALIGN_CENTER_COLUMN = [
  "online",
  "signature",
  "signatureValidation",
];
export const allColumns = [
  //avatar and new are not really columns
  // {
  //   type: "checkbox",
  //   title: _t("New"),
  //   id: "new",
  //   sortable: false,
  //   hideable: false,
  //   visibleByDefault: true,
  //   forRole: ["admin", "participant"],
  // },
  // {
  //   type: "checkbox",
  //   title: _t("Avatar"),
  //   id: "avatar",
  //   sortable: false,
  //   hideable: false,
  //   visibleByDefault: true,
  //   forRole: ["admin", "participant"],
  // },
  // {
  //   type: "checkbox",
  //   title: _t("check"),
  //   id: "checkbox",
  //   sortable: false,
  //   hideable: false,
  //   visibleByDefault: true,
  //   notSelectable: true,
  //   forRole: ["admin", "participant"],
  // },

  {
    type: "string",
    title: _t("Name"),
    id: "name",
    sortable: false,
    hideable: false,
    visibleByDefault: true,
    forRole: ["admin", "participant", "signature"],
  },
  {
    type: "string",
    title: _t("Email"),
    id: "email",
    sortable: true,
    hideable: false,
    visibleByDefault: true,
    forRole: ["admin", "participant"],
  },
  {
    type: "string",
    title: _t("Entreprise"),
    id: "entreprise",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["admin", "participant", "signature"],
  },
  {
    type: "select",
    title: _t("Role"),
    id: "role",
    sortable: true,
    hideable: false,
    visibleByDefault: true,
    forRole: ["admin", "participant"],
  },
  {
    type: "string",
    title: _t("Confirmation of registration to Wisembly"),
    id: "registrationConfirmation",
    sortable: true,
    hideable: true,
    visibleByDefault: true,
    forRole: ["admin", "participant"],
    deactivated: true,
  },

  {
    type: "actionStatus",
    title: _t("Invitation to the team"),
    id: "invitationToTheTeam",
    sortable: true,
    hideable: true,
    visibleByDefault: true,
    forRole: ["admin"],
    deactivated: true,
  },
  {
    type: "actionStatus",
    title: _t("Invitation"),
    id: "invitation",
    sortable: true,
    hideable: true,
    visibleByDefault: true,
    forRole: ["participant"],
  },
  {
    type: "string",
    title: _t("Relaunch invitation"),
    id: "relaunchInvitation",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["participant"],
    deactivated: false,
  },
  {
    type: "string",
    title: _t("Confirmation of registration for the event"),
    id: "eventRegistrationConfirmation",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["participant"],
    deactivated: false,
  },
  {
    type: "string",
    title: _t("Reminder 1 day before"),
    id: "oneDayReminder",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["participant"],
    deactivated: false,
  },
  {
    type: "string",
    title: _t("Reminder 10 minutes before"),
    id: "tenMinuteReminder",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["participant"],
    deactivated: false,
  },

  {
    type: "string",
    title: _t("Having participated"),
    id: "havingParticipated",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["participant"],
    deactivated: false,
  },
  {
    type: "string",
    title: _t("Not having participated"),
    id: "notHavingParticipated",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["participant"],
    deactivated: false,
  },
  {
    type: "string",
    title: _t("Origin"),
    id: "origin",
    sortable: true,
    hideable: true,
    visibleByDefault: true,
    forRole: ["admin", "participant"],
    deactivated: true,
  },
  {
    type: "string",
    title: _t("Origin : Detail"),
    id: "originDetails",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["admin", "participant"],
    deactivated: true,
  },
  {
    type: "string",
    title: _t("Origin Url"),
    id: "originUrl",
    sortable: true,
    hideable: true,
    visibleByDefault: false,
    forRole: ["admin", "participant"],
    deactivated: true,
  },
  {
    type: "string",
    title: _t("RSVP"),
    id: "rsvp",
    sortable: true,
    hideable: true,
    visibleByDefault: true,
    forRole: ["participant", "admin"],
    conditionallyActivated: "registration",
    deactivated: true,
  },
  {
    type: "bool",
    title: _t("Online"),
    id: "online",
    sortable: true,
    hideable: false,
    visibleByDefault: true,
    forRole: ["admin", "participant"],
  },
  {
    type: "bool",
    title: _t("Signature"),
    id: "signature",
    sortable: true,
    hideable: false,
    visibleByDefault: true,
    forRole: ["signature"],
  },
  {
    type: "action",
    title: _t("Export"),
    id: "exportSignature",
    sortable: false,
    hideable: false,
    visibleByDefault: true,
    forRole: ["signature"],
  },
  {
    type: "action",
    title: _t("Export Logs"),
    id: "exportLogs",
    sortable: false,
    hideable: true,
    visibleByDefault: true,
    forRole: ["admin", "participant"],
  },
  {
    type: "actionStatus",
    title: _t("Validation"),
    id: "signatureValidation",
    sortable: false,
    hideable: false,
    visibleByDefault: true,
    forRole: ["signature"],
  },
  //those are not really column
  // {
  //   type: "checkbox",
  //   title: _t("Fields created by admin"),
  //   id: "FieldsCreatedByAdmin",
  //   sortable: true,
  //   hideable: true,
  //   visibleByDefault: false,
  //   forRole: ["participant"],
  // },
  // {
  //   type: "checkbox",
  //   title: _t("Emails created by admin"),
  //   id: "emailsCreatedByAdmin",
  //   sortable: true,
  //   hideable: true,
  //   visibleByDefault: false,
  //   forRole: ["participant"],
  // },
  //notRealy a column
  // {
  //   type: "action",
  //   title: _t("Remove"),
  //   id: "remove",
  //   sortable: false,
  //   hideable: false,
  //   visibleByDefault: false,
  //   forRole: ["admin", "participant"],
  // },
];
