import React from "react";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  row: {
    display: "block",
    height: "15px",
    borderRadius: "99px",
    background: theme.wisemblyColors.loaderGradient,
    marginBottom: "31px",
    width: (props) => (props.width ? props.width : "100px"),
    //borderRadius: "5px",
    opacity: 0.1,
  },
}));
const Line = ({ width }) => {
  const classes = useStyles({ width });
  return <Box className={classes.row}></Box>;
};
Line.propTypes = {
  width: PropTypes.string,
};
const EmptyLiveText = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Line width="365px" />
      <Line width="182px" />
      <Line width="243px" />
      <Line width="145px" />
      <Line width="182px" />
    </Box>
  );
};

export default EmptyLiveText;
