import React, { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { _t } from "core/i18n";
import { setPopupParticipationMode, setUserParticipation, setUsername } from "applicationDucks/actions";
import { displayPopupParticipation } from "applicationDucks/selectors";
import { getCurrentMode, setParticipationMode } from "utilities/participationMode";
import MenuItem from "../uikit/component/MenuItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import TextField from "../OutlinedTextField";
import Box from "@material-ui/core/Box";
import AvatarInitials from "./Profil/AvatarInitials";
import Avatar from "../containers/Profil/Avatar";
import { useIsUser } from "../userHooks";
import Button from "../../components/reusables/Button";
import { useSelector } from "react-redux";
import { currentWizSelector } from "ressourcesDucks/selectors";
import { setConnection } from "applicationDucks/actions";
import { TYPE_SIGNIN } from "../../containers/Connect/authentication";

import { OPEN_PARTICIPANT_MODAL } from "utilities/emitter/events";
import emitter from "utilities/emitter";

import memo from "core/memo";
import { SET_PARTICIPATION_MODE } from "app/state/ducks/application/actionsTypes";
import { getParticipation, getUsername } from "app/state/ducks/application/selectors";
import { getInitialsFromString } from "./Profil/utils";
import { getConnection } from "applicationDucks/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    // "& .MuiDialog-paper": {
    //
    // },
    zIndex: `calc(${theme.zIndex.appBar - 10}) !important`,
  },
  row: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    marginBottom: 15,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  dialogPaper: {
    backgroundColor: theme.palette.colors.white,
    maxWidth: 500,
    width: "100%",
    padding: 30,
    borderRadius: 10,
  },
  contentRoot: {
    marginTop: "30px",
    padding: 0,
    marginBottom: "30px",
  },
  actionsRoot: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 0,
    gap: 15,
  },
  textField: {
    paddingTop: 5,
    maxWidth: 263,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 68,
    },
  },
  menuItem: {
    padding: 0,
  },
}));

const DialogTitle = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    textAlign: "center",
    padding: 0,
  },
}))(MuiDialogTitle);

const VisibilityDialog = ({
  displayPopupParticipation,
  setPopupParticipationMode,
  setAnonymousUserParticipation,
  setConnection,
  setUsername,
  allow_username,
  participation_mode,
  popupParticipationCallback,
  popupParticipationCancelCallback,
  user,
}) => {
  const classes = useStyles();
  const currentMode = getCurrentMode();
  const dispatch = useDispatch();

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [pseudo, setPseudo] = useState(null);
  const fieldRef = useRef(null);

  const participationsModeSelected = useSelector(getParticipation);
  const name = useSelector(getUsername);
  const wiz = useSelector(currentWizSelector);
  const { displayModal } = useSelector(getConnection);
  const isAdmin = useIsUser("admin+");

  useEffect(() => {
    if (displayModal) {
      setPopupParticipationMode(false);
    }
  }, [displayModal]);

  useEffect(() => {
    if (selectedOption === "is_participant" && fieldRef.current) {
      fieldRef.current.focus();
    }
  }, [selectedOption]);

  useEffect(() => {
    if (currentMode) {
      setSelectedOption(currentMode);
    }
  }, [currentMode]);

  // useEffect(() => {
  //   emitter.on(OPEN_PARTICIPANT_MODAL, () => setPopupParticipationMode(true));
  // }, []);

  useEffect(() => {
    if (participation_mode !== undefined) {
      const optionsSelected = [];
      switch (participation_mode) {
        case null:
          optionsSelected.push(options.user);
          if (user && isAdmin) optionsSelected.push(options.admin);
          if (user || (allow_username && !user)) optionsSelected.push(options.participant);
          optionsSelected.push(options.anonymous);
          break;
        default:
          if (user) {
            optionsSelected.push(options.user);
            if (isAdmin) {
              optionsSelected.push(options.admin);
            }
          } else {
            setMode({}, true);
          }
      }
      setFilteredOptions(optionsSelected);
    }
  }, [participation_mode, allow_username, name]);

  const options = {
    admin: {
      title: _t("Appear as administrator"),
      subtitle: user && user.company ? `${user.company}` : _t("Company"),
      hash: "is_admin",
      initialViewer: <AvatarInitials anonymous={false} avatar={wiz ? wiz.getLogo() : null} initials={getInitialsFromString(wiz ? wiz.title : "Aa")} />,
    },
    user: {
      title: _t("Use my account info"),
      subtitle: user ? `${user.name}` : _t("firstname lastname"),
      initialViewer: !user ? <AvatarInitials anonymous={false} initials="Aa" /> : <Avatar />,
      hash: "is_user",
    },
    participant: {
      title: _t("Use a pseudonyme"),
      hash: "is_participant",
      initialViewer: <AvatarInitials anonymous={false} initials={getInitialsFromString(pseudo ? pseudo : "Aa")} />,
    },
    anonymous: {
      title: _t("Be anonymous"),
      hash: "is_anonymous",
      initialViewer: <AvatarInitials anonymous={true} />,
    },
  };

  const handleChange = (event) => {
    setPseudo(event.target.value);
  };

  const setMode = (data, anonymousParticipation = false, popupParticipantMode = false, username = null) => {
    setParticipationMode(data);
    dispatch({ type: SET_PARTICIPATION_MODE, payload: data });
    setAnonymousUserParticipation(anonymousParticipation);
    setUsername(username);
    setPopupParticipationMode(popupParticipantMode);
  };

  const handleAction = () => {
    switch (selectedOption) {
      case "is_user":
        if (!user) {
          dispatch(
            setConnection({
              displayModal: true,
              type: TYPE_SIGNIN,
              callback: () => {
                setMode({ is_user: true }, false, false);
                popupParticipationCallback();
              },
            })
          );
          return;
        }
        setMode({ is_user: true }, false, false, user.name);
        break;
      case "is_participant":
        setMode({ is_participant: true }, false, false, pseudo);
        popupParticipationCallback();
        break;
      case "is_anonymous":
        setMode({ is_anonymous: true }, true, false, _t("Anonymous"));
        popupParticipationCallback();
        break;
      case "is_admin":
        setMode({ is_admin: true }, false, false);
        popupParticipationCallback();
    }
  };

  const handleClickField = () => {
    if (selectedOption !== "is_participant") {
      setSelectedOption("is_participant");
    }
  };

  if (wiz && wiz.participation_mode && !user) {
    return null;
  }

  return (
    <Dialog
      classes={{ root: classes.root, paper: classes.dialogPaper }}
      open={displayPopupParticipation}
      onClose={() => {
        setPopupParticipationMode(false);
      }}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle disableTypography>
        <Typography color="blackMedium" variant="subtitle13">
          {_t("Participation mode")}
        </Typography>
        <Typography color="greyDark" variant="body15">
          {_t("Choose the way you want to appear to participate")}
        </Typography>
      </DialogTitle>
      <MuiDialogContent classes={{ root: classes.contentRoot }}>
        <Box className={classes.form}>
          {filteredOptions.map((option, i) => {
            return (
              <Box key={`${option.id}_${i}`} className={classes.row}>
                {option.type === "checkbox" && <Box className={classes.preBox}></Box>}
                <Box display="flex" style={{ flex: 1 }}>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={() => setSelectedOption(option.hash)}
                    item={option}
                    selected={!selectedOption ? participationsModeSelected[option.hash] : selectedOption === option.hash}
                    type="select"
                    id={option.hash}
                    customElement={option.initialViewer}
                    disabled={false}
                  />
                </Box>
                {option.hash === "is_participant" && (
                  <Box className={classes.textField}>
                    <TextField
                      ref={fieldRef}
                      value={participationsModeSelected?.is_participant && !pseudo ? name : pseudo}
                      placeholder={_t("enter a pseudo")}
                      onChange={handleChange}
                      onClick={handleClickField}
                      error={isEmpty(pseudo) && selectedOption === "usePseudo"}
                      maxRows={1}
                      type={"text"}
                      fullWidth
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      </MuiDialogContent>
      <MuiDialogActions classes={{ root: classes.actionsRoot }} disableSpacing>
        <Button
          onClick={() => {
            setPopupParticipationMode(false);
            popupParticipationCancelCallback();
          }}
          color="secondary"
          size="small"
        >
          {_t("Cancel")}
        </Button>

        <Button disabled={isEmpty(pseudo) && selectedOption === "is_participant"} onClick={handleAction}>
          {_t("Validate")}
        </Button>
      </MuiDialogActions>
    </Dialog>
  );
};

VisibilityDialog.propTypes = {
  displayPopupParticipation: PropTypes.bool,
  setPopupParticipationMode: PropTypes.func,
  setAnonymousUserParticipation: PropTypes.func,
  setConnection: PropTypes.func,
  setUsername: PropTypes.func,
  setSession: PropTypes.func,
  force_username: PropTypes.bool,
  allow_username: PropTypes.bool,
  popupParticipationCallback: PropTypes.func,
  popupParticipationCancelCallback: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { allow_username, participation_mode } = currentWizSelector(state) || {};

  const displayPopupState = displayPopupParticipation(state);

  return {
    allow_username,
    participation_mode,
    displayPopupParticipation: displayPopupState.displayPopupParticipation,
    popupParticipationCallback: displayPopupState.popupParticipationCallback,
    popupParticipationCancelCallback: displayPopupState.popupParticipationCancelCallback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConnection: bindActionCreators(setConnection, dispatch),
    setPopupParticipationMode: bindActionCreators(setPopupParticipationMode, dispatch),
    setAnonymousUserParticipation: bindActionCreators(setUserParticipation, dispatch),
    setUsername: bindActionCreators(setUsername, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(VisibilityDialog));
