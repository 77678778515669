import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class License extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = License;
  }

  setContract(Contract) {
    this.contract = Contract.id;
  }
}

License.modelName = "License";
License.backendName = "license";

License.fields = {
  id: attr(),
  start: attr(),
  stop: attr(),
  active: attr(),
  type: attr(),
  is_customized: attr(),
  contract: fk("Contract", "licenses"),
};

export default License;
