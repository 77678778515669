import React from "react";
import PropTypes from "prop-types";
import { _t } from "i18n";
import dayjs from "dayjs";

import Box from "@material-ui/core/Box";

import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import TextInput from "coreComponents/OutlinedTextField";

const SelectedDate = ({
    day,
    dayError
}) => {
    return (
        <Box mb={"15px"}>
            <Typography variant={"bodySB13"}>
                {_t("Date")}
            </Typography>
            <Box mb={"8px"} />
            <TextInput
                value={day.format("DD/MM/YYYY")}
                type={"text"}
                fullWidth
                readOnly
                error={dayError}
            >
            </TextInput>
        </Box>
    );
};

SelectedDate.propTypes = {
    day: PropTypes.object,
    startEnd: PropTypes.string,
};

SelectedDate.defaultProps = {
    day: Date(),
    startEnd: "",
};

export default SelectedDate;