import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Block, Flex, Metric, Text, Title } from "@tremor/react";
import { Box, Divider, Typography, useTheme } from "@material-ui/core";
import { _t } from "utilities/i18n";
import { formatPrice } from "app/liveShopping/utils";
import Fade from 'react-reveal/Fade';
import Card from "../Card";
import Info from "../Info";
import {
  Cash,
  ChatDots,
  ClipboardData,
  GraphDownArrow,
  GraphUpArrow,
  TicketPerforatedFill,
  HourglassSplit,
} from "react-bootstrap-icons";
import Icon from "../Icon";
import makeUrl from "app/state/ducks/ressources/sagas/makeUrl";
import makeRequest from "utilities/makeRequest";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  countsWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "20px",
  },
  counts: {
    display: "flex",
    gap: "10px",
    flexDirection: "row",
    width: "100%",
    height: "100%",
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    marginTop: "20px",
    marginBottom: "15px",
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  divider: {
    marginTop: "15px",
    width: "50%",
  },
}));

const Global = ({ dates, currentProject }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchStats = async ({ since, until }) => {

    try {
      setLoading(true);
      const urlGeneral = makeUrl(
        { type: "Custom" },
        {
          slug: ["stats", "project", currentProject?.id, "general"],
          query: [{ key: "since", value: since.toISOString() }, { key: "until", value: until.toISOString() }],
        }
      );

      const generalResult = await makeRequest(urlGeneral, { type: "Custom" }, { method: "GET" });

      const finalGeneralResult = Object.assign(generalResult.resources[0].attributes.data.stats);

      setLoading(false);
      setData(finalGeneralResult);
    } catch (e) {
      setLoading(false);
      setData(null);
    }
  }

  useEffect(() => {
    fetchStats(dates);
  }, [dates, currentProject]);

  const impressionsParser = (impressions) => {
    if (impressions >= 1000000) {
      return `${(impressions / 1000000).toFixed(2)}M`;
    } else if (impressions >= 1000) {
      return `${(impressions / 1000).toFixed(2)}K`;
    }
    return impressions;
  };

  const averageTimeInMinutesAndSeconds = (duration) => {
    if (!duration)
      return '--';

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    if (hours > 0)
      return `${hours} h ${minutes} m ${seconds} s`;

    return `${minutes} m ${seconds} s`;
  }

  return (
    <>
      <Fade left>
        <Typography className={classes.title}>{_t("General statistics")}</Typography>
        <Box className={classes.root}>
          <Box className={classes.countsWrapper}>
            <Box className={classes.counts}>
              <Card maxWidth="20rem">
                <Info description={_t("This shows how many times the Widget or Button have been displayed to visitors")} />
                <Flex justifyContent="justify-start" spaceX="space-x-4">
                  <Icon
                    icon={TicketPerforatedFill}
                    size={"1.5rem"}
                    color={theme.palette.colors.primary}
                  />
                  <Block truncate={true}>
                    <Text>{_t("Impressions")}</Text>
                    <Metric>{impressionsParser(data?.impressions) || 0}</Metric>
                  </Block>
                </Flex>
              </Card>
              <Card maxWidth="20rem">
                <Info description={_t("Out of the total impressions, how many contacts have been made by interested visitors")} />
                <Flex justifyContent="justify-start" spaceX="space-x-4">
                  <Icon
                    icon={ChatDots}
                    size={"1.5rem"}
                    color={theme.palette.colors.primary}
                  />
                  <Block truncate={true}>
                    <Text>{_t("Contact rate")}</Text>
                    <Metric>{(data?.conversation_rate * 100).toFixed(2) || 0} %</Metric>
                  </Block>
                </Flex>
                <Divider className={classes.divider} />
                <Box className={classes.footer}>
                  <Text>{_t("Total contacts") + " : "}</Text>
                  <Title>{data?.conversations_debounced || 0}</Title>
                </Box>
              </Card>
              <Card maxWidth="20rem">
                <Info description={_t("Out of the contacts made, how many visitors have successfully been taken by vendors")} />
                <Flex justifyContent="justify-start" spaceX="space-x-4">
                  <Icon

                    icon={ClipboardData}
                    size={"1.5rem"}
                    color={theme.palette.colors.primary}
                  />
                  <Block truncate={true}>
                    <Text>{_t("Service rate")}</Text>
                    <Metric>{(data?.service_rate * 100).toFixed(2) || 0} %</Metric>
                  </Block>
                </Flex>
                <Divider className={classes.divider} />
                <Box className={classes.footer}>
                  <Text>{_t("Handled conversations") + " : "}</Text>
                  <Title>{(data?.handled) || 0}</Title>
                </Box>
              </Card>
            </Box>
            <Box className={classes.counts}>
              {
                currentProject.cart_integration && (
                  <>
                    <Card maxWidth="20rem">
                      <Info description={_t("Average handled visitors cart value at the end of their call")} />
                      <Flex justifyContent="justify-start" spaceX="space-x-4">
                        <Icon
                          icon={Cash}
                          size={"1.5rem"}
                          color={theme.palette.colors.primary}
                        />
                        <Block truncate={true}>
                          <Text>{_t("Average cart value")}</Text>
                          <Metric>{formatPrice(Math.round(data?.average_cart_value / 100), "EUR") || 0}</Metric>
                        </Block>
                      </Flex>
                    </Card>
                    <Card maxWidth="20rem">
                      <Info description={_t("Total revenue estimated by handled visitors")} />
                      <Flex justifyContent="justify-start" spaceX="space-x-4">
                        <Icon
                          icon={GraphUpArrow}
                          size={"1.5rem"}
                          color={theme.palette.colors.primary}
                        />
                        <Block truncate={true}>
                          <Text>{_t("Total revenue generated")}</Text>
                          <Metric>{formatPrice(Math.round(data?.revenue_generated / 100), "EUR") || 0}</Metric>
                        </Block>
                      </Flex>
                    </Card>
                    <Card maxWidth="20rem">
                      <Info description={_t("Total revenue missed estimated, depending on average cart value and service rate")} />
                      <Flex justifyContent="justify-start" spaceX="space-x-4">
                        <Icon
                          icon={GraphDownArrow}
                          size={"1.5rem"}
                          color={theme.palette.colors.primary}
                        />
                        <Block truncate={true}>
                          <Text>{_t("Estimated revenue missed")}</Text>
                          <Metric>{formatPrice(Math.round(data?.revenue_missed / 100), "EUR") || 0}</Metric>
                        </Block>
                      </Flex>
                    </Card>
                  </>
                )
              }
            </Box>
            <Box className={classes.counts}>
              <Card maxWidth="20rem">
                <Info description={_t("Average time a visitor has to wait before a vendor take the call")} />
                <Flex justifyContent="justify-start" spaceX="space-x-4">
                  <Icon
                    icon={HourglassSplit}
                    size={"1.5rem"}
                    color={theme.palette.colors.primary}
                  />
                  <Block truncate={true}>
                    <Text>{_t("Average waiting time")}</Text>
                    <Metric>{averageTimeInMinutesAndSeconds(data?.avg_waiting_time)}</Metric>
                  </Block>
                </Flex>
              </Card>
              <Card maxWidth="20rem">
                <Info description={_t("Average time a visitor requires when in a conversation with a vendor")} />
                <Flex justifyContent="justify-start" spaceX="space-x-4">
                  <Icon
                    icon={HourglassSplit}
                    size={"1.5rem"}
                    color={theme.palette.colors.primary}
                  />
                  <Block truncate={true}>
                    <Text>{_t("Average conversation time")}</Text>
                    <Metric>{averageTimeInMinutesAndSeconds(data?.avg_conversation_time)}</Metric>
                  </Block>
                </Flex>
              </Card>
            </Box>
          </Box>
        </Box>
      </Fade>
    </>

  );
};

export default Global;
