import React from "react";
import { makeStyles, Box, Typography, useTheme } from "@material-ui/core";
import { _t } from "utilities/i18n";
import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { notifySMSVisitor } from "./service";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    padding: "20px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "16px",
  },
  title: {
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "100%",
  },
  info: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "130%",
    padding: "20px",
    color: theme.palette.colors.grey500,
  },
  cta: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "16px",
  },
  cancelButton: {
    background: theme.palette.colors.grey500,
  },
}));

const NotifyVisitor = ({ setNotifyVisitor, visitor }) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleNotify = () => {
    notifySMSVisitor(dispatch, visitor, () => {
      setNotifyVisitor(false);
    });
  };

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>{_t("Notify visitor")}</Typography>

      <Typography className={classes.info}>{_t("Send once a reminder to the visitor to notify that it's time and that you're here")}</Typography>

      <Box className={classes.cta}>
        <Button clsName={classes.cancelButton} onClick={() => setNotifyVisitor(false)}>{_t("Cancel")}</Button>
        <Button color={theme.palette.colors.success} onClick={handleNotify}>{_t("Notify")}</Button>
      </Box>
    </Box>
  );
}

export default NotifyVisitor;
