import React, { useEffect, useState } from "react";
import MuxPlayer from "@mux/mux-player-react";
import clsx from "clsx";
import { Box, makeStyles } from "@material-ui/core";
import { _t } from "utilities/i18n";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  player: {
    width: "100%",
    height: "100%",
    position: "absolute",
    objectFit: "cover",
    zIndex: 0,
    "--controls": "none",
  },
  caption: {
    zIndex: 1,
    position: "absolute",
    textAlign: "center",
    color: "#000",
    padding: "10px",
    fontFamily: "Arial, Helvetica, sans-serif",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    width: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    fontSize: "9px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const FishingRodClient = () => {
  const classes = useStyles();
  const [text, setText] = useState("");
  let catchPhrase = "Bonjour, Puis-je vous aider à trouver quelque chose en particulier ? N'hésitez pas a me poser des questions, si vous en avez besoin. Je suis là pour vous conseiller.";

  // retrieve "catchphrase" query arg in url if exists
  const urlParams = new URLSearchParams(window.location.search);
  const catchphraseParam = urlParams.get("catchphrase");

  if (catchphraseParam)
    catchPhrase = decodeURIComponent(atob(catchphraseParam));

  // couper la phrases sur tous les points de ponctuation en les gardants dans la phrase d'avant
  const phrasesInArray = catchPhrase.split(/(?<=[.?!])/);
  console.log("phrasesInArray", phrasesInArray);
  let blocks = [];
  phrasesInArray.forEach((phrase, index) => {
    phrasesInArray[index] = phrase.trim();

    blocks.push(phrasesInArray[index].split(" ").reduce((acc, cur) => {
        if (acc.length === 0) {
          acc.push(cur);
        } else {
          const last = acc[acc.length - 1];
          if (last.split(" ").length < 4) {
            acc[acc.length - 1] = `${last} ${cur}`;
          } else {
            acc.push(cur);
          }
        }
        return acc;
      }, []));
  });
  // maintenant il faut applatir le tableau blocks pour avoir un tableau de phrases a une dimension
  blocks = blocks.flat();
  console.log("blocks", blocks);

  // const phrasesInArray = catchPhrase.split(" ").reduce((acc, cur) => {
  //   if (acc.length === 0) {
  //     acc.push(cur);
  //   } else {
  //     const last = acc[acc.length - 1];
  //     if (last.split(" ").length < 4) {
  //       acc[acc.length - 1] = `${last} ${cur}`;
  //     } else {
  //       acc.push(cur);
  //     }
  //   }
  //   return acc;
  // }, []);

  useEffect(() => {
    let i = 0;
    setInterval(() => {
      if (i >= blocks.length) i = 0;
      setText(blocks[i]);
      i++;
    }, 1500);
  }, []);
  return (
    <Box className={classes.root}>
      <MuxPlayer
        className={clsx("Player", classes.player, "mux-player")}
        streamType="on-demand"
        playbackId={"QqWR7dsYUTnZvxWFjRpJj3rPh8DkO01tw4K0000008KiTic"}
        controls={false}
        hideControls={true}
        autoPlay={true}
        muted={true}
        loop={true}
      />
      <Box className={classes.caption}>
        <h1 id="caption_text">{text}</h1>
      </Box>
    </Box>

  );
};

export default FishingRodClient;