import React from "react";
import { useSelector } from "react-redux";
import TopBar from "liveShopping/backoffice/otm/components/TopBar/TopBar";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { currentUserSelector } from "ressourcesDucks/selectors";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

const TopBarContainer = () => {
  const classes = useStyles();
  const user = useSelector(currentUserSelector);

  if (
    window.location.pathname.includes("/ls/") ||
    window.location.pathname.includes("/visio/")
  ) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <TopBar user={user} />
    </Box>
  );
};

TopBarContainer.propTypes = {};

TopBarContainer.defaultProps = {};

export default TopBarContainer;
