import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: (props) => (props.disabled ? "default" : "pointer"),
    },
    icon: {
      width: (props) => (props.size ? `${props.size}px` : "14px"),
      height: (props) => (props.size ? `${props.size}px` : "14px"),
      color: (props) => (props.color ? theme.palette.colors[props.color] : theme.palette.colors.grey),
      "&:hover": {
        color: (props) => (props.hover ? theme.palette.colors[props.hover] : theme.palette.colors.greyDark),
      },
    },
  }),
  {
    name: "OneIcon",
  }
);

const Icon = ({ IconComponent, color, hover, size, onClick, className, classBox, disabled }) => {
  const classes = useStyles({ color, hover, size, disabled });

  const handleClick = (e) => {
    if (!disabled) onClick(e);
  };
  if (!IconComponent) return null;

  return (
    <Box component="a" onClick={handleClick} className={clsx([classes.root, ...classBox.split(" ")])}>
      <IconComponent className={clsx([classes.icon, ...className.split(" ")])} />
    </Box>
  );
};

Icon.propTypes = {
  color: PropTypes.string,
  hover: PropTypes.string,
  IconComponent: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  classBox: PropTypes.string,
  size: PropTypes.number,
  disabled: PropTypes.bool,
};

Icon.defaultProps = {
  onClick: () => {},
  className: "",
  classBox: "",
  disabled: false,
};

export default Icon;
