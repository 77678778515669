import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import BarChart from "react-bootstrap-icons/dist/icons/bar-chart";
import { useSelector, useDispatch } from "react-redux";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";
import XLg from "react-bootstrap-icons/dist/icons/x-lg";
import { ButtonBase } from "@material-ui/core";
import { displayBackOfficeElement } from "app/state/ducks/liveShopping/actions";
import { genericResourcesSelector, productsSelector } from "app/state/ducks/ressources/selectors";
import { computeInfos } from "./utils";
import { resourceFetch } from "app/state/ducks/ressources/actions";
import URI from "urijs";
import { API_BASE } from "config";
import { applicationJSONCall } from "app/state/ducks/application/actions";
import { getKeyword } from "app/state/ducks/application/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    width: (props) => (props.isMobile ? "100%" : "338px"),
    height: (props) => props.isMobile && "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px 24px 48px 32px",
    boxShadow: "0px 4px 40px rgba(0, 0, 0, 0.05)",
    borderRadius: (props) => (!props.isMobile ? "12px" : "0"),
    margin: (props) => (!props.isMobile ? "24px 30px 24px 50px" : "0"),
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  typoTitle: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "130%",
    marginLeft: "9px",
  },
  numbers: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "130%",
    color: theme.palette.colors.grey700,
  },
  block: {
    textAlign: "center",
    maxWidth: "75px",
    flex: "0 0 33.333333%",
    marginBottom: "48px",
  },
  blockTitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "130%",
    color: theme.palette.colors.grey500,
  },
  wrapperStats: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "100%",
  },
}));

const Stats = ({ }) => {
  const isMobile = useSelector(mobileLayoutSelector);
  const classes = useStyles({ isMobile });
  const [data, setData] = useState(null);
  const dispatch = useDispatch();
  const keyword = useSelector(getKeyword);
  const carts = useSelector((state) =>
    genericResourcesSelector(state, {
      type: "cart",
      sort: "created_at",
      order: "desc",
    })
  );

  const products = useSelector(productsSelector);
  const { paid } = computeInfos(carts);

  useEffect(() => {
    dispatch(
      resourceFetch("cart", {
        slug: ["event", keyword, "carts"],
        silent: true,
      })
    );

    const url = new URI(API_BASE.toString().replace("/6", "/4"))
      .segment("event")
      .segment(keyword)
      .segment("analytics")
      .query({ "modules[]": ["quotes", "participants"] })
      .toString();
    dispatch(
      applicationJSONCall(
        url,
        {},
        {
          callback: (error, response) => {
            if (error) return;

            setData(response.success.data);
          },
        }
      )
    );
  }, []);

  const uniqueVisitors = data?.participants?.true_reach;
  const chatMessages = data?.quotes?.total;
  const registeredUsers = data?.participants?.authenticated_users;
  const visitedUsers = data?.participants?.anonymous_users;
  const activeUsers = data?.participants?.active_users;

  return (
    <Box className={classes.root}>
      <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginBottom: "48px" }}>
        <Box className={classes.title}>
          <BarChart />
          <Typography className={classes.typoTitle} variant={"bodySB13"}>
            {_t("Statistics")}
          </Typography>
        </Box>

        {isMobile && (
          <ButtonBase onClick={() => dispatch(displayBackOfficeElement(""))}>
            <XLg size={20} />
          </ButtonBase>
        )}
      </Box>
      <Box className={classes.wrapperStats}>
        <Box className={classes.block}>
          <Typography className={classes.numbers}>{uniqueVisitors}</Typography>
          <Typography className={classes.blockTitle}>{_t("participants")}</Typography>
        </Box>
        <Box className={classes.block}>
          <Typography className={classes.numbers}>{activeUsers}</Typography>
          <Typography className={classes.blockTitle}>{_t("registered")}</Typography>
        </Box>
        <Box className={classes.block}>
          <Typography className={classes.numbers}>{chatMessages}</Typography>
          <Typography className={classes.blockTitle}>{_t("messages")}</Typography>
        </Box>
        <Box className={classes.block}>
          <Typography className={classes.numbers}>{products.length}</Typography>
          <Typography className={classes.blockTitle}>{_t("products in catalog")}</Typography>
        </Box>
        <Box className={classes.block}>
          <Typography className={classes.numbers}>0</Typography>
          <Typography className={classes.blockTitle}>{_t("presented products")}</Typography>
        </Box>
        <Box className={classes.block}>
          <Typography className={classes.numbers}>{paid}</Typography>
          <Typography className={classes.blockTitle}>{_t("paid products")}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

Stats.propTypes = {};

Stats.defaultProps = {};

export default Stats;
