import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import HeartFill from "react-bootstrap-icons/dist/icons/heart-fill";
import clsx from "clsx";
import { randomInt } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  anim: {
    animation: "$heart 3.5s ease-in-out",
  },
  heart: {
    position: "absolute",
    right: (props) => props.randomRight,
    bottom: "25px",
    zIndex: 10,
    color: (props) => (props.mine ? theme.palette.colors.success : theme.palette.colors.error),
  },

  "@keyframes heart": {
    "0%": {
      transform: "rotate(20deg)",
      "-webkit-transform": "rotate(20deg)",
      opacity: 0,
    },
    "10%": {
      transform: "rotate(20deg)",
      "-webkit-transform": "rotate(20deg)",
      opacity: 0,
    },
    "20%": {
      transform: "rotate(-20deg)",
      "-webkit-transform": "rotate(-20deg)",
      opacity: 0.5,
    },
    "30%": {
      transform: "rotate(20deg)",
      "-webkit-transform": "rotate(20deg)",
      opacity: 1,
    },
    "40%": {
      transform: "rotate(-20deg)",
      "-webkit-transform": "rotate(-20deg)",
      opacity: 1,
    },
    "50%": {
      transform: "rotate(20deg)",
      "-webkit-transform": "rotate(20deg)",
      opacity: 0.5,
    },
    "60%": {
      transform: "rotate(20deg)",
      "-webkit-transform": "rotate(20deg)",
      opacity: 0.4,
    },
    "70%": {
      transform: "rotate(20deg)",
      "-webkit-transform": "rotate(20deg)",
      opacity: 0.3,
    },
    "80%": {
      transform: "rotate(20deg)",
      "-webkit-transform": "rotate(20deg)",
      opacity: 0.2,
    },
    "90%": {
      transform: "rotate(20deg)",
      "-webkit-transform": "rotate(20deg)",
      opacity: 0.1,
    },
    "100%": {
      transform: "rotate(-20deg)",
      "-webkit-transform": "rotate(-20deg)",
      opacity: 0,
      bottom: "400px",
    },
  },
}));

const Heart = ({ randomRight, mine }) => {
  const [readyToAnimate, setReadyToAnimate] = useState(mine);
  const [animation, setAnimation] = useState(false);
  const classes = useStyles({ animation, randomRight, mine });

  // add a small delay for others hearts to avoid rendering all the same time
  useEffect(() => {
    if (readyToAnimate) return;
    setTimeout(() => {
      setReadyToAnimate(true);
    }, 100 * randomInt(15));
  }, []);

  useEffect(() => {
    if (!readyToAnimate) return;

    setAnimation(true);
    let timer = setTimeout(() => setAnimation(false), 3500);
    return () => clearTimeout(timer);
  }, [readyToAnimate]);

  if (!animation) return null;

  return (
    <HeartFill
      className={clsx({
        [classes.heart]: true,
        [classes.anim]: animation,
      })}
      size={30}
    />
  );
};

Heart.propTypes = { randomRight: PropTypes.number };

Heart.defaultProps = {};

export default Heart;
