import React from "react";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import toString from "lodash/toString";
import isUndefined from "lodash/isUndefined";
import get from "lodash/get";
import keys from "lodash/keys";
import {
  TextField,
  UniqueField,
  MultipleItem,
  RatingField,
  GridField,
  ImageField,
  RankingField,
  CanNotAnswerView,
} from "appComponents/SurveyForms";

const createFormSchema = (surveyItemList = []) => {
  let formSchema = {};
  forEach(surveyItemList, (item) => {
    const object = item.ref;
    formSchema[object.hash] = getItemFormFieldSchema(object);
  });

  return formSchema;
};

const getItemFormFieldSchema = (item = {}) => {
  //options array has objects with label and hash
  switch (item.type) {
    case "text":
      return {
        type: item.type,
        label: item.label,
        required: item.required,
        help: item.help,
        name: item.hash || "empty",
        summary: item.summary,
        hash: item.hash,
        displayType: get(item, "display.type", "list"),
      };
    case "unique":
      return {
        type: item.type,
        label: item.label,
        required: item.required,
        options: item.options,
        help: item.help,
        name: item.hash || "empty",
        summary: item.summary,
        hash: item.hash,
      };
    case "rating": {
      return {
        type: item.type,
        label: item.label,
        required: item.required,
        options: item.options,
        help: item.help,
        name: item.hash || "empty",
        summary: item.summary,
        hash: item.hash,
      };
    }
    case "multiple":
    default:
      return {
        type: item.type,
        label: item.label,
        required: item.required,
        options: item.options,
        help: item.help,
        name: item.hash || "empty",
        summary: item.summary,
        hash: item.hash,
      };
    //
    // case "html":
    //
    // case "ranking":
    //
    // case "image":
  }
};
const getFormElement = (withLiveResult, elementSchema, canAnswer) => {
  if (isEmpty(elementSchema)) return;
  if (!withLiveResult && !canAnswer) return <CanNotAnswerView />;
  switch (elementSchema.type) {
    case "text":
      return (
        <TextField
          {...elementSchema}
          withLiveResult={withLiveResult}
          canAnswer={canAnswer}
        />
      );
    case "unique":
      return (
        <UniqueField
          {...elementSchema}
          withLiveResult={withLiveResult}
          canAnswer={canAnswer}
        />
      );

    case "multiple":
      return (
        <MultipleItem
          {...elementSchema}
          withLiveResult={withLiveResult}
          canAnswer={canAnswer}
        />
      );

    case "rating":
      return (
        <RatingField
          {...elementSchema}
          withLiveResult={withLiveResult}
          canAnswer={canAnswer}
        />
      );
    case "image":
      return (
        <ImageField
          {...elementSchema}
          withLiveResult={withLiveResult}
          canAnswer={canAnswer}
        />
      );
    case "grid":
      return (
        <GridField
          {...elementSchema}
          canAnswer={canAnswer}
          withLiveResult={withLiveResult}
        />
      );
    case "ranking":
      return (
        <RankingField
          {...elementSchema}
          canAnswer={canAnswer}
          withLiveResult={withLiveResult}
        />
      );
    default:
      return null;
  }
};

const formatFormAnswer = (values, survey) => {
  //let included = [];
  let items = [];
  //let datas = [];
  const surveyItems = survey.items.toModelArray();
  //const session = orm.session();
  const itemKeys = keys(values);
  forEach(itemKeys, (key) => {
    let item = surveyItems.filter((item) => item.hash === key);
    //avoid submitting of empty and not required field
    if (!isEmpty(toString(values[key]))) {
      items.push({ hash: item[0].id, value: values[key] });
    }
  });

  return items;
};

const initForm = (formSchema) => {
  let _formData = {};

  for (var key of Object.keys(formSchema)) {
    _formData[key] = "";
  }
  return [_formData, formSchema];
};

//it's not really perfect for now
const isSurveyOrSurveyItemToAnswer = (layoutData) => {
  if (isEmpty(layoutData)) return false;
  if (layoutData.displayPoll) return false;
  if (layoutData.type === "Survey" || layoutData.type === "SurveyItem")
    return true;
  // if (get(layoutData, "data.hash") !== get(layoutData, "data.parent_hash"))
  //   return true;
  return false;
};
const REQUIRED_SURVEY_FIELD = [
  "type",
  "name",
  "position",
  "live_results",
  "created_at",
  "description",
  "hash",
  "is_active",
  "multiple_answers",
  "wiz_rank",
];

//usefull function because survey data fetch by timeline at start
// is not complete
const isSurveyObjectFull = (survey) => {
  let full = true;
  if (isEmpty(survey)) return false;
  REQUIRED_SURVEY_FIELD.forEach((item) => {
    if (isUndefined(get(survey, item))) {
      full = false;
    }
  });

  return full;
};

//use this check when you're sure user already answered
const canUserSeeEvaluationAnswer = (survey) => {
  if (isEmpty(survey)) return false;
  //check if evaluation  not finished
  if (
    survey.type === "evaluation" &&
    !survey.is_instant_grade &&
    isEmpty(survey.ended_at)
  ) {
    return false;
  }
  return true;
};

const getLogicalJumps = (item) => {
  let logicJumps = [];
  if (item && item.type == "unique" && item.options.use_skips) {
    const choices = item.options.choices;
    choices.forEach((choice) => {
      logicJumps.push({ skip: choice.skip, hash: choice.hash });
    });
  }
  return logicJumps;
};
const isItemInLogicalJumps = (item, itemsInLogicalJumps) => {
  const result = itemsInLogicalJumps.filter((jump) => jump.skip == item.hash);
  return !isEmpty(result);
};
const getChoiceLogicalJump = (choiceHash, itemsInLogicalJumps) => {
  if (isEmpty(choiceHash) || isEmpty(itemsInLogicalJumps)) return null;
  const nextJump = itemsInLogicalJumps.filter(
    (jump) => jump.hash === choiceHash
  );
  if (isEmpty(nextJump)) return null;

  return nextJump[0].skip;
};

export {
  getItemFormFieldSchema,
  createFormSchema,
  getFormElement,
  initForm,
  formatFormAnswer,
  isSurveyOrSurveyItemToAnswer,
  isSurveyObjectFull,
  canUserSeeEvaluationAnswer,
  getLogicalJumps,
  isItemInLogicalJumps,
  getChoiceLogicalJump,
};
