import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";

const FormError = ({ answerSurveryStatus, type, displayResult, survey }) => {
  if (
    isEqual(answerSurveryStatus, "error") &&
    (type === "survey" ||
      (type === "surveyItem" && survey.type === "poll" && !displayResult))
  ) {
    return <Box>{_t("there was an error while submitting form")}</Box>;
  }
  return null;
};
FormError.propTypes = {
  answerSurveryStatus: PropTypes.string,
  type: PropTypes.string,
  displayResult: PropTypes.bool,
  survey: PropTypes.object,
};
export default FormError;
