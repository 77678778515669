import React from "react";
import PropTypes from "prop-types";

import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import ModerationList from "./ModerationList";
import ChatModerationBubble from "../containers/ChatModerationBubble";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { _t } from "i18n";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: (props) => (props.empty ? "center" : "flex-end"),
      alignItems: "center",
      width: "345px",
      minWidth: "345px",
      borderRadius: (props) => (props.columns ? "10px 0px 0px 10px " : "10px"),
      backgroundColor: theme.palette.colors.greyLight,
      position: "relative",
      height: "100%",
    },
    empty: {
      color: theme.palette.colors.greyDark,
    },
  }),
  {
    name: "ChatModeration",
  }
);

const ModerationPanel = ({ messages, fetchData, Element, total, columns }) => {
  const classes = useStyles({ columns, empty: !messages.length });

  if (!messages.length) {
    return (
      <Box className={classes.root}>
        <Box className={classes.empty}>
          <Typography variant={"bodySB13"}>{_t("No message to moderate")}</Typography>
        </Box>
      </Box>
    );
  }
  return (
    <Box className={classes.root}>
      <ModerationList Element={Element} elements={messages} total={total} fetchData={fetchData} />
    </Box>
  );
};

ModerationPanel.propTypes = {
  messages: PropTypes.array,

  fetchData: PropTypes.func,
  total: PropTypes.number,
  Element: PropTypes.oneOf([ChatModerationBubble]),
  columns: PropTypes.bool,
};

ModerationPanel.defaultProps = {
  Element: ChatModerationBubble,
  messages: [],
  handleEdidQuote: () => { },
  handleDeleQuote: () => { },
  total: 0,
  fetchData: () => { },
  columns: false,
};

export default ModerationPanel;
