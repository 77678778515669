import React, { useRef, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { _t } from "i18n";
import Popper from "appComponents/Mui/Popper";
import Box from "@material-ui/core/Box";
import Button from "appComponents/Mui/Button";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

const useStyles = makeStyles((theme) => ({
  popper: {
    background: theme.palette.secondary.contrastText,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  popperButton: {
    marginTop: theme.spacing(1.4),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1.4),
    marginLeft: theme.spacing(1),
  },
}));

const Delete = ({ onClick, children, notifyParentOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const ref = useRef(null);

  const handleClick = (event) => {
    const target = event.currentTarget;
    setAnchorEl(target);
    notifyParentOpen(true);
  };

  const open = Boolean(anchorEl);

  const closePopper = () => {
    setAnchorEl(null);
    notifyParentOpen(false);
  };

  const handleConfirm = (event) => {
    onClick(event);
    closePopper();
  };

  return (
    <>
      <Popper anchorEl={anchorEl} open={open}>
        <ClickAwayListener onClickAway={closePopper}>
          <Box className={classes.popper} ref={ref}>
            <Button
              className={classes.popperButton}
              size="medium"
              variant="contained"
              color="secondary"
              onClick={closePopper}
            >
              {_t("Cancel")}
            </Button>
            <Button
              className={classes.popperButton}
              size="medium"
              variant="contained"
              color="alert"
              onClick={handleConfirm}
            >
              {_t("Delete")}
            </Button>
          </Box>
        </ClickAwayListener>
      </Popper>
      <Box onClick={handleClick}>{children}</Box>
    </>
  );
};

Delete.propTypes = {
  onClick: PropTypes.func,
  notifyParentOpen: PropTypes.func,
};

Delete.defaultProps = {
  onClick: () => {},
  notifyParentOpen: () => {},
};

export default Delete;
