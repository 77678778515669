import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import emitter from "core/emitter";
import { TOOLTIP_INTERACTIVITY } from "core/emitter/events";

import { _t } from "i18n";

import SwitchFocus from "./SwitchFocus";
import {
  InteractivitySurveyViewer,
  InteractivityQuoteViewer,
  InteractivityLeaderBoardViewer,
} from "appContainers/Viewers";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: "100%",
    zIndex: (props) =>
      props.layoutMode === "pip" && !props.hasFocus
        ? theme.zIndex.mobileStepper
        : "auto",
    //overflow: "auto",
  },
  tooltip: {
    display: (props) =>
      props.layoutMode === "pip" && !props.hasFocus ? "flex" : "none",
    backgroundColor: theme.wisemblyColors.black,
  },
  tooltipArrow: {
    color: theme.wisemblyColors.black,
  },
  popper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
}));

const DisplayInteractivity = ({
  layoutMode,
  hasFocus,
  onSetFocus,
  layoutData,
}) => {
  const classes = useStyles({ layoutMode, hasFocus });
  const [tooltip, setTooltip] = useState(null);
  const [open, setOpen] = useState(false);
  const onHover = (value) => {
    setOpen(value);
  };
  useEffect(() => {
    const callback = (payload) => {
      if (tooltip !== payload) {
        setTooltip(payload);
      }
    };

    emitter.on(TOOLTIP_INTERACTIVITY, callback);
    return () => emitter.removeListener(TOOLTIP_INTERACTIVITY, callback);
  }, [tooltip]);
  if (isEmpty(layoutData)) return null;

  const handleLayoutData = (layoutData, layoutMode, hasFocus) => {
    switch (layoutData.type) {
      case "Survey":
        return (
          <InteractivitySurveyViewer
            surveyHash={get(layoutData, "data.hash", "empty")}
            type="survey"
            layoutMode={layoutMode}
            hasFocus={hasFocus}
            preview={get(layoutData, "data.preview", false)}
          />
        );

      case "Quote":
        return (
          <InteractivityQuoteViewer
            quoteHash={get(layoutData, "data.hash", "empty")}
            layoutMode={layoutMode}
            hasFocus={hasFocus}
          />
        );

      case "SurveyItem":
        return (
          <InteractivitySurveyViewer
            surveyHash={get(layoutData, "data.parent_hash", "empty")}
            surveyItemHash={get(layoutData, "data.hash", "empty")}
            type="surveyItem"
            displayResult={layoutData.displayPoll}
            layoutMode={layoutMode}
            hasFocus={hasFocus}
            preview={get(layoutData, "data.preview", false)}
          />
        );
      case "leaderboard":
        return (
          <InteractivityLeaderBoardViewer
            layoutMode={layoutMode}
            hasFocus={hasFocus}
          />
        );

      default:
        return <Box style={{ height: "50px" }}>{_t("Unknown type")}</Box>;
    }
  };

  return (
    <Tooltip
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.tooltipArrow,
        popper: classes.popper,
      }}
      open={open}
      arrow
      title={tooltip}
      placement="right"
    >
      <Box
        display="flex"
        position="relative"
        flex={1}
        justifyContent="center"
        alignItems="center"
        className={clsx("DisplayInteractivity", classes.root)}
        onMouseOver={() => onHover(true)}
        onMouseOut={() => onHover(false)}
      >
        {layoutMode === "pip" && !hasFocus && (
          <SwitchFocus layout="interactivity" onSetFocus={onSetFocus} />
        )}
        <Box display="flex" justifyContent="center" width="100%" height="100%">
          {handleLayoutData(layoutData, layoutMode, hasFocus)}
        </Box>
      </Box>
    </Tooltip>
  );
};

DisplayInteractivity.propTypes = {
  layoutData: PropTypes.object,
  layoutMode: PropTypes.string,
  hasFocus: PropTypes.bool,
  onSetFocus: PropTypes.func,
};

export default DisplayInteractivity;
