import Session from "core/session";
import { resourceCreate } from "ressourcesDucks/actions";
import { setConnection } from "applicationDucks/actions";
import { createSession as coreCreateSession } from "core/session";
import emitter from "core/emitter";
import { REFRESH_WIZ_DATA } from "core/emitter/events";
import { APP_BASE } from "config";
import makeUrl from "ressourcesDucks/sagas/makeUrl";
import makeRequest from "core/makeRequest";

import {
  EMAIL_REGEX,
  PASSWORD_LETTER_REGEX,
  PASSWORD_DIGIT_REGEX,
  PASSWORD_MIN_LENGTH,
} from "core/utils";
import { _t } from "core/i18n";
import find from "lodash/find";
import { sessionSet } from "applicationDucks/actions";
import { setSessionUser } from "applicationDucks/sagas/sessionSaga/makeCallAndUpdate";

const TYPE_SIGNUP = "signup";
const TYPE_SIGNIN = "signin";
const TYPE_MAGIC_LINK_SIGNIN = "magic_link_signin";
const TYPE_FORGOT_PWD = "forgot_pwd";
const TYPE_CONFIRM_PWD = "confirm_pwd";
const TYPE_CONFIRM_TFA = "confirm_tfa";
const TYPE_VERIFY_MAIL = "verify_mail";

const validate = (values, type) => {
  const errors = {};

  if (![TYPE_FORGOT_PWD, TYPE_MAGIC_LINK_SIGNIN].includes(type)) {
    if (!values.password) {
      errors.password = _t("Please enter a password");
    } else if (
      [TYPE_SIGNUP, TYPE_CONFIRM_PWD].includes(type) &&
      (values.password.length < PASSWORD_MIN_LENGTH ||
        !PASSWORD_LETTER_REGEX.test(values.password) ||
        !PASSWORD_DIGIT_REGEX.test(values.password))
    ) {
      errors.password = _t("Please enter a valid password");
    }
  }

  if (type !== TYPE_CONFIRM_PWD) {
    if (!values.email) {
      errors.email = _t("Please enter an email address");
    } else if (!EMAIL_REGEX.test(values.email)) {
      errors.email = _t("Please enter a valid email address");
    }
  }

  return errors;
};

const getErrorMessage = (error, defaultMessage) => {
  switch (error) {
    case "wrong_email_or_secret":
      return _t("There is a mistake in your email or password.");
    case "user_blocked":
      // prettier-ignore
      return _t("You have tried to log in too many times with this address. We have temporarily blocked the login attempts.")
    default:
      return defaultMessage;
  }
};

const sendVerificationMail = async (email, keyword) => {
  try {
    const slug = keyword ? ["event", keyword, "magic-link"] : ["magic-link"];
    const query = keyword
      ? [{ key: "email", value: email }]
      : [
          { key: "email", value: email },
          { key: "redirectTo", value: APP_BASE._string },
        ];
    const url = makeUrl(
      { type: "Custom" },
      {
        slug,
        XHR_ID: `verification_email`,
        query: query,
      }
    );

    await makeRequest(url, { type: "Custom" }, { method: "GET" });
  } catch (e) {
    console.error("sendVerificationMail e", e);
  }
};

const createSession = (
  dispatch,
  email,
  password,
  onSuccess = () => {},
  callback = () => {}
) => {
  const session = new Session();
  const json = session.generateSigninJson(email, password);

  dispatch(
    resourceCreate(json, {
      XHR_ID: "authentication",
      tokenless: true,
      silent: true,
      slug: ["authentication"],
      callback: (error, response) => {
        if (error) {
          const errorMessage = getErrorMessage(error.statusText, error.message);
          callback(errorMessage);
          return;
        }

        if (
          response.resources.length === 0 &&
          Object.prototype.hasOwnProperty.call(response.meta, "twofa_token")
        ) {
          dispatch(
            setConnection({
              type: TYPE_CONFIRM_TFA,
              value: response.meta.twofa_token,
            })
          );
          return;
        }

        const user = find(response.included, (o) => o.type === "user");
        dispatchSession(dispatch, response.resources[0], user);
        emitter.emit(REFRESH_WIZ_DATA);
        onSuccess();
      },
    })
  );
};

const dispatchSession = (dispatch, session, user = null) => {
  const newAttributes = {
    token: session.attributes.token,
    refresh_token: session.attributes.refresh_token,
    expires_at: session.attributes.expires_at,
    broadcasted_at: session.attributes.broadcasted_at,
  };
  session = { ...session, ...newAttributes };
  setSessionUser(session, user);
  dispatch(sessionSet(coreCreateSession(session)));
  // dispatch(setConnection({ displayModal: false, type: TYPE_SIGNIN }));
};

export {
  createSession,
  dispatchSession,
  validate,
  sendVerificationMail,
  TYPE_SIGNUP,
  TYPE_SIGNIN,
  TYPE_FORGOT_PWD,
  TYPE_CONFIRM_PWD,
  TYPE_CONFIRM_TFA,
  TYPE_MAGIC_LINK_SIGNIN,
  TYPE_VERIFY_MAIL,
};