import BaseModel from "./base";
import { attr, oneToOne } from "redux-orm";

export const DEFAULT_LOCALE = "fr";
export const ALLOWED_LOCALES = ["en", "fr"];

export const defaultAttributes = {
  isAnonymous: true,
};

class User extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = User;
  }

  setLicense(License) {
    this.license = License.id;
  }

  isEqual(User) {
    if (!User) {
      return false;
    }

    if (!User.locator || User.locator.type !== "user") {
      return false;
    }

    return this.id === User.id;
  }

  isAnon() {
    return this.isAnonymous === true;
  }

  isRoot() {
    return this.is_root === true;
  }
}

User.modelName = "User";
User.backendName = "user";

User.fields = {
  id: attr(),
  email: attr(),
  optin: attr(),
  name: attr(),
  firstname: attr(),
  lastname: attr(),
  lang: attr(),
  isAnonymous: attr(),
  company: attr(),
  extra_data: attr(),
  is_verified: attr(),
  license: oneToOne("License", "owner"),
  is_root: attr(),
};

export default User;
