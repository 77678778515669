import React, { forwardRef } from "react";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import { _t } from "i18n";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { RESET_MESSAGES_FILTER } from "utilities/emitter/events";
import emitter from "utilities/emitter";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    backgroundColor: "transparent",
    marginBottom: (props) => (props.hidden ? 0 : "10px"),
  },
  link: {
    textDecoration: "underlined",
    cursor: "pointer",
    color: theme.palette.colors.primary,
    marginLeft: "5px",
  },
  title: {
    color: theme.palette.colors.greyDark,
  },
}));

const config = {
  reacted: _t("Popular messages"),
  mine: _t("My messages only"),
  favorite: _t("Favorite message only"),
  unprocessed: _t("Unprocessed messages"),
};

const countFilters = (filter) => {
  const tagsCount = filter?.tags?.length || 0;
  const adminCount = filter?.admin?.length || 0;
  let count = tagsCount + adminCount;
  let title = "";
  //const adminCount =
  //let defaultCount = filter?.default.length || 1
  if (
    get(filter, "default[0]") !== "all_messages" &&
    !isEmpty(filter?.default)
  ) {
    count++;
  }
  if (count === 1) {
    if (tagsCount) {
      const currentTag = filter.tagsOptions.filter(
        (elem) => elem.hash === filter.tags[0]
      )[0];
      title = _t(" Tag {tag} applied.", { tag: currentTag.name });
    }
    if (adminCount) {
      title = config[filter.admin[0]];
    }
    if (
      get(filter, "default[0]") !== "all_messages" &&
      !isEmpty(filter?.default)
    ) {
      title = config[filter.default[0]];
    }
  }
  return { count, title };
};

const onReset = () => {
  emitter.emit(RESET_MESSAGES_FILTER);
};

const FilterRemove = forwardRef(({ filter }, ref) => {
  const { count, title } = countFilters(filter);
  const classes = useStyles({ hidden: count === 0 });

  return (
    <Box ref={ref} className={classes.root}>
      <Hidden xsUp={!count}>
        <Typography variant="smallBody13" className={classes.title}>
          {count === 1
            ? title
            : _t("{count} filters applied.", { count: count })}
        </Typography>
        <Typography
          variant="smallBody13"
          className={classes.link}
          onClick={onReset}
        >
          {count === 1 ? _t(" Delete filter") : _t(" Delete filters")}
        </Typography>
      </Hidden>
    </Box>
  );
});

FilterRemove.displayName = "FilterRemove";
FilterRemove.propTypes = {
  filter: PropTypes.shape({
    tags: PropTypes.array,
    default: PropTypes.array,
    admin: PropTypes.array,
  }),
};

export default FilterRemove;
