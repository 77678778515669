import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import clsx from "clsx";

import basil from "core/basil";
import { _t } from "core/i18n";
import { EMAIL_REGEX } from "core/utils";
import { currentWizSelector } from "ressourcesDucks/selectors";
import { setPopupParticipationMode, setUserParticipation, setConnection, setUsername } from "applicationDucks/actions";
import { getUserSession } from "core/session";
import { displayPopupParticipation } from "applicationDucks/selectors";
import { getVerifiedUsername } from "utilities/username";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";

import Button from "app/liveShopping/backoffice/otm/components/reusables/Button";
import { TYPE_SIGNIN } from "app/liveShopping/backoffice/otm/containers/Connect/authentication";

import memo from "core/memo";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      backgroundColor: theme.palette.colors.white,
    },
  },
  arrow: {
    "&::before": {
      width: "0",
      height: "0",
      margin: "auto",
      content: "''",
      display: "block",
      borderStyle: "solid",
      borderColor: `transparent transparent ${theme.palette.colors.white} transparent`,
      borderWidth: theme.spacing(0, 1, 1, 1),
    },
  },
  divider: {
    margin: theme.spacing(3, 0),
    color: theme.palette.colors.grey,
    position: "relative",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    border: "0",
    "&::before, &::after": {
      boxSizing: "border-box",
      content: "''",
      flex: "1 1 auto",
      borderTop: "1px solid #B4B6B9",
    },
  },

  dividerLabel: {
    display: "inline-block",
    fontWeight: "600",
    flex: "0 0 auto",
    width: "2.85714rem",
    textAlign: "center",
    color: "#B4B6B9",
  },
  title: {
    fontSize: "1.2rem",
    color: "black",
  },
  text: {
    marginTop: "15px",
    color: "black",
    fontSize: ".8rem",
  },
  disabled: {
    backgroundColor: theme.palette.background.secondaryBox,
    "&:hover": {
      backgroundColor: theme.palette.background.seconda,
    },
  },
  textField: {
    width: "inherit",
    display: "flex",
    margin: "15px 10px 5px 10px !important",
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `#E2E4E8 !important`,
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#E2E4E8 !important",
    borderRadius: "8px",
  },
  usePseudo: {
    padding: "15px 30px",
    color: "white",
    borderRadius: "99px",
    background: "black",
    "&:hover": {
      background: "black",
    },
  },
  stayAnon: {
    padding: "15px 30px",
    color: "white",
    borderRadius: "99px",
    background: theme.palette.colors.primary,
    "&:hover": {
      background: "black",
    },
  },
}));

const CancelButton = withStyles((theme) => ({
  root: {
    width: "100%",
  },
}))(Button);

const DialogTitle = withStyles((theme) => ({
  root: {
    fontWeight: "bold",
    textAlign: "center",
    paddingBottom: theme.spacing(0.25),
  },
}))(MuiDialogTitle);

const DialogContent = withStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0.25),
    color: theme.palette.text.lightContrast,
    "& .textfield": {
      margin: theme.spacing(8.75, 0, 0, 0),
      "& .MuiInputBase-root": {
        backgroundColor: theme.palette.colors.white,
      },
      "& .MuiInputBase-input": {
        color: theme.palette.colors.darkish,
      },
      "& label": {
        color: theme.palette.colors.grey,
      },
      "& fieldset": {
        borderColor: theme.palette.colors.grey,
      },
      "& label.Mui-focused": {
        color: theme.palette.secondary.main,
      },
      "& .MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    margin: theme.spacing(0, 1, 2, 1),
    padding: theme.spacing(1, 3),
  },
}))(MuiDialogActions);

const VisibilityDialog = ({
  displayPopupParticipation,
  setPopupParticipationMode,
  setAnonymousUserParticipation,
  setConnection,
  setUsername,
  force_username,
  allow_username,
  session,
  keyword,
  popupParticipationCallback,
}) => {
  const classes = useStyles();
  let data = {
    subTitle: _t("Would you like to use a username to participate?"),
    textField: {
      data: "username",
      label: _t("Using a Username"),
    },
    cancel: {
      label: _t("Remain anonymous"),
      action: () => {
        basil.set(`participationMode:${session.token}:${keyword}:remainAnonymous`, true);
        handleParticipationMode(true);
        popupParticipationCallback();
      },
    },
    ok: {
      label: _t("Use this username"),
      action: ({ username }) => {
        setUsername(username);
        handleParticipationMode(false);

        popupParticipationCallback();
      },
    },
  };

  if (!allow_username) {
    /* prettier-ignore */
    data.subTitle = _t("If you wish to participate on your own behalf, you must log in.");
    data.textField.data = "email";
    data.textField.label = _t("Your email");
    data.ok.label = _t("Login");
    data.ok.action = ({ email }) => {
      setConnection({ displayModal: true, type: TYPE_SIGNIN, email });
      handleParticipationMode(false);
    };
  }

  if (force_username) {
    data.subTitle = _t("You must fill in a username to intervene.");
    delete data.cancel;
  }

  const formik = useFormik({
    initialValues: {
      [data.textField.data]: getVerifiedUsername(),
    },
    validate: (values) => {
      const errors = {};

      if (!values[data.textField.data]) {
        errors[data.textField.data] = "Required";
      }

      if (data.textField.data === "email" && !EMAIL_REGEX.test(values.email)) {
        errors.email = _t("Incorrect email address.");
      }

      return errors;
    },
    onSubmit: (values) => {
      data.ok.action(values);
    },
  });

  const handleParticipationMode = (isAnonymous) => {
    setAnonymousUserParticipation(isAnonymous);
    setPopupParticipationMode(false);
  };

  return (
    <Dialog className={classes.root} open={displayPopupParticipation} onClose={() => setPopupParticipationMode(false)} aria-labelledby="form-dialog-title">
      <DialogTitle disableTypography>
        <Typography className={classes.title} variant="h3">
          {_t("Choose your mode of participation")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography align="center" variant="subtitle1" className={classes.text}>
          {data.subTitle}
        </Typography>
        <TextField
          className={clsx("textfield", classes.textField)}
          margin="none"
          size="small"
          variant="outlined"
          placeholder={_t("Choose a pseudo")}
          name={data.textField.data}
          value={formik.values[data.textField.data]}
          onChange={formik.handleChange}
          autoComplete="off"
          inputProps={{ maxLength: 25 }}
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
            inputMode: "text",
          }}
          fullWidth
          error={!!formik.errors[data.textField.data]}
          helperText={formik.errors[data.textField.data] ? formik.errors[data.textField.data] : ""}
        />
      </DialogContent>
      <DialogActions disableSpacing>
        <Button className={classes.usePseudo} onClick={() => formik.handleSubmit()}>
          {data.ok.label}
        </Button>
        {Object.prototype.hasOwnProperty.call(data, "cancel") && (
          <>
            <Box className={classes.divider}>
              <Typography className={classes.dividerLabel} variant="caption">
                {_t("or").toUpperCase()}
              </Typography>
            </Box>
            <Button className={classes.stayAnon} onClick={() => data.cancel.action()}>
              {data.cancel.label}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

VisibilityDialog.propTypes = {
  displayPopupParticipation: PropTypes.bool,
  setPopupParticipationMode: PropTypes.func,
  setAnonymousUserParticipation: PropTypes.func,
  setConnection: PropTypes.func,
  setUsername: PropTypes.func,
  setSession: PropTypes.func,
  force_username: PropTypes.bool,
  allow_username: PropTypes.bool,
  session: PropTypes.object,
  keyword: PropTypes.string,
  popupParticipationCallback: PropTypes.func,
};

const mapStateToProps = (state) => {
  const { force_username, allow_username, keyword } = currentWizSelector(state) || {};

  const displayPopupState = displayPopupParticipation(state);
  return {
    session: getUserSession(),
    force_username,
    allow_username,
    keyword,
    displayPopupParticipation: displayPopupState.displayPopupParticipation,
    popupParticipationCallback: displayPopupState.popupParticipationCallback,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setConnection: bindActionCreators(setConnection, dispatch),
    setPopupParticipationMode: bindActionCreators(setPopupParticipationMode, dispatch),
    setAnonymousUserParticipation: bindActionCreators(setUserParticipation, dispatch),
    setUsername: bindActionCreators(setUsername, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(VisibilityDialog));
