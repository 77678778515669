import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import EyeFill from "react-bootstrap-icons/dist/icons/eye-fill";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import theme from "app/views/styles/theme";
const useStyles = makeStyles((theme) => ({
  viewersWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "32px",
    padding: "10px",
    background: "rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
  },
  eyeColor: {
    color: theme.palette.colors.white,
  },
  viewersTypo: {
    fontSize: "12px",
    marginLeft: "5px",
    color: theme.palette.colors.white,
  },
}));
const Viewers = ({ isInfoOpen }) => {
  const classes = useStyles({});
  const viewers = useSelector((state) => state.application.participants_count);
  if (isInfoOpen) return null;
  return (
    <Box className={classes.viewersWrapper}>
      <EyeFill size={12} className={classes.eyeColor} />
      <Typography className={classes.viewersTypo}>{viewers}</Typography>
    </Box>
  );
};

Viewers.propTypes = {};

export default Viewers;
