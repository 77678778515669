import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import {
  REACTION_ANGRY,
  REACTION_THINKING,
  REACTION_THRILLED,
  REACTION_SURPRISED,
  REACTION_THUMBS_UP,
  REACTION_THUMBS_DOWN,
} from "constants";
import {
  ThumbsUp,
  ThumbsDown,
  ThinkingFace,
  PoutingFace,
  OpenMouthFace,
  GrinningFace,
} from "coreUiKit/assets/images/reactions";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "6px",
    background: theme.palette.colors.white,
    boxShadow: theme.palette.shadow.tooltip,
    borderRadius: "99px",
    width: "fit-content",
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& > *": {
      display: "flex",
      alignItems: "center",
      marginRight: "3px",
      "&:last-child": {
        marginRight: 0,
      },
    },
  },
  reaction: {
    width: "32px",
    height: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "99px",
    cursor: "pointer",
    "&:focus": {
      backgroundColor: theme.palette.colors.primary10,
    },
  },
  selected: {
    backgroundColor: theme.palette.colors.primary10,
  },
  img: {
    width: "20px",
    height: "20px",
  },
}));

const getReaction = (reaction) => {
  switch (reaction) {
    default:
    case REACTION_THUMBS_UP:
      return ThumbsUp;
    case REACTION_THUMBS_DOWN:
      return ThumbsDown;
    case REACTION_THINKING:
      return ThinkingFace;
    case REACTION_ANGRY:
      return PoutingFace;
    case REACTION_SURPRISED:
      return OpenMouthFace;
    case REACTION_THRILLED:
      return GrinningFace;
  }
};

const Reactions = ({ reactions, selectedReaction, onClick }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.box}>
        {reactions.map((reaction, i) => (
          <Box
            onClick={() => onClick(reaction)}
            className={clsx(classes.reaction, {
              [classes.selected]: reaction === selectedReaction,
            })}
            key={i}
          >
            <img className={classes.img} src={getReaction(reaction)} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

Reactions.propTypes = {
  reactions: PropTypes.array,
  selectedReaction: PropTypes.string,
  onClick: PropTypes.func,
};

Reactions.defaultProps = {
  reactions: [],
  onClick: () => {},
  selectedReaction: "",
};

export default Reactions;
