import React, { Component } from "react";
import { connect } from "react-redux";
import { APP_BASE } from "config";
import { logOut } from "applicationDucks/actions";
import basil from "core/basil";
import PropTypes from "prop-types";
import AppLoader from "appComponents/AppLoader";
import { redirectToUrl } from "utilities/request";

class Logout extends Component {
  componentDidMount() {
    this.props.dispatch(logOut());
    const urlRedirection = basil.get("u:redirectToWiz")
      ? APP_BASE.clone().segment(basil.get("u:redirectToWiz")).toString()
      : null;

    redirectToUrl(urlRedirection);
  }

  render() {
    return <AppLoader />;
  }
}
Logout.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(Logout);
