import React from "react";
import PropTypes from "prop-types";

const CustomCrossLg = ({ color, size, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill={color} {...rest} viewBox="0 0 14 14">
      <path fillRule="evenodd" d="M9.06,8l5-5A.75.75,0,1,0,13,2l-5,5L3,2A.75.75,0,1,0,2,3l5,5L2,13A.75.75,0,1,0,3,14l5-5,5,5A.75.75,0,0,0,14,13Z" />
      <path
        d="M13.9422 2.05748C14.0004 2.11554 14.0466 2.18451 14.0781 2.26044C14.1096 2.33637 14.1258 2.41777 14.1258 2.49998C14.1258 2.58219 14.1096 2.66359 14.0781 2.73952C14.0466 2.81545 14.0004 2.88442 13.9422 2.94248M13.9422 2.05748L13.1459 2.14598L13.0575 2.05759L13.0574 2.05768C13.0574 2.05769 13.0574 2.05769 13.0574 2.0577L2.05751 13.0576C1.94018 13.1749 1.87427 13.3341 1.87427 13.5C1.87427 13.6659 1.94018 13.825 2.05751 13.9424C2.17484 14.0597 2.33397 14.1256 2.4999 14.1256C2.66583 14.1256 2.82496 14.0597 2.94229 13.9424L13.9422 2.94248M13.9422 2.05748L13.8539 2.14598L13.9422 2.05748ZM13.9422 2.94248L13.8539 2.85398L13.9423 2.94237L13.9422 2.94248Z"
        fill={color}
        stroke={color}
        strokeWidth="0.25"
      />
      <path
        d="M2.05753 2.05759C1.99938 2.11562 1.95324 2.18455 1.92175 2.26044L2.94242 2.0577L2.85392 2.14598L2.94231 2.05759L2.94238 2.05766C2.88433 1.99948 2.81537 1.95331 2.73946 1.92181C2.66353 1.8903 2.58213 1.87408 2.49992 1.87408C2.41771 1.87408 2.33631 1.8903 2.26038 1.92181C2.18449 1.9533 2.11556 1.99944 2.05753 2.05759ZM2.05764 2.94248L2.14592 2.85398L2.05753 2.94237L2.05764 2.94248Z"
        fill={color}
        stroke={color}
        strokeWidth="0.25"
      />
      -
    </svg>
  );
};

CustomCrossLg.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomCrossLg.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomCrossLg;
