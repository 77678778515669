import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Select } from "appComponents/Mui";
import { _n } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import { TextField } from "appComponents/Mui";
import clsx from "clsx";
import { getDurationValueFromSeconds } from "./utils";

const useStyles = makeStyles((theme) => ({
  relativeTimeField: {
    display: "flex",
    "& > .valueField": {
      flex: 1,
      "& .MuiInputBase-root": {
        borderRadius: "5px 0px 0px 5px",
      },
    },
    "& > .typeField > .MuiInputBase-root": {
      flex: 3,
      borderRadius: "0px 5px 5px 0px",
      "& .MuiSelect-root": {
        lineHeight: "1",
      },
    },
  },
}));

const MailRelativeTimePicker = ({
  negative = false,
  defaultValue,
  onChange,
  disabled,
}) => {
  const classes = useStyles();
  const { unit: stateDefaultUnit, value: stateDefaultValue } =
    getDurationValueFromSeconds(defaultValue);

  const [value, setValue] = useState(null);
  const [type, setType] = useState(null);
  const [timeoutRef, setTimeoutRef] = useState(null);
  const isValid = !!(value && !isNaN(value));
  const safeValue = isValid ? value : 1;

  useEffect(() => {
    const { unit: stateDefaultUnit, value: stateDefaultValue } =
      getDurationValueFromSeconds(defaultValue);
    setValue(Math.abs(stateDefaultValue));
    setType(stateDefaultUnit);
  }, [defaultValue]);

  useEffect(() => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    const multiplier = negative ? -1 : 1;
    const delayExecution = (value) => {
      setTimeoutRef(setTimeout(() => onChange(value), 800));
    };
    switch (type) {
      case "minute":
        delayExecution(multiplier * value * 60);
        break;
      case "hour":
        delayExecution(multiplier * value * 60 * 60);
        break;
      case "day":
        delayExecution(multiplier * value * 60 * 60 * 24);
        break;
    }
  }, [value, type]);

  // prettier-ignore
  const allTypes = [
    {
      value: "minute",
      label: negative
        ? _n("minute before", "minutes before", safeValue)
        : _n("minute after", "minutes after", safeValue),
    },
    {
      value: "hour",
      label: negative
        ? _n("hour before", "hours before", safeValue)
        : _n("hour after", "hours after", safeValue),
    },
    {
      value: "day",
      label: negative
        ? _n("day before", "days before", safeValue)
        : _n("days after", "days after", safeValue),
    },
  ];

  return (
    <>
      <Box className={classes.relativeTimeField}>
        <TextField
          type="text"
          required
          id="value"
          value={value}
          name="value"
          onChange={(e) => setValue(e.target.value)}
          className={clsx("valueField")}
          withoutborder="true"
          variant={"outlined"}
          disabled={disabled}
        />
        <Select
          name="type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          options={allTypes}
          className={clsx("typeField")}
          disabled={disabled}
        />
      </Box>
    </>
  );
};

export default MailRelativeTimePicker;
