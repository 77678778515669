import React from "react";
import ChipList from "appComponents/ChipList/ChipList";
import { _t } from "i18n";

const SegmentsList = ({ template, setSegments }) => {
  const allSegements = template.getAllSegements();
  const selectedSegments = template.getSelectedSegments();
  return (
    <ChipList
      options={allSegements}
      selected={selectedSegments}
      onUpdate={(segments) => {
        setSegments(segments);
      }}
      defaultChip={_t("All the participants")}
    />
  );
};

export default SegmentsList;
