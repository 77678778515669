import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: "50%",
    boxShadow: "0 0 0 0 rgba(0, 0, 0, 1)",
    margin: "10px",
    height: "10px",
    width: "10px",
    transform: "scale(1)",
    animation: "$pulse-red 2s infinite",
  },

  red: {
    background: "rgba(255, 82, 82, 1)",
    boxShadow: "0 0 0 0 rgba(255, 82, 82, 1)",
    animation: "$pulse-red 2s infinite",
  },

  "@keyframes pulse-red": {
    "0%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(255, 82, 82, 0.7)",
    },

    "70%": {
      transform: "scale(1)",
      boxShadow: "0 0 0 10px rgba(255, 82, 82, 0)",
    },

    "100%": {
      transform: "scale(0.95)",
      boxShadow: "0 0 0 0 rgba(255, 82, 82, 0)",
    },
  },
  pulseWrapper: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

const Pulse = () => {
  const classes = useStyles();

  return (
    <Box className={classes.pulseWrapper}>
      <Box className={clsx({ [classes.root]: true, [classes.red]: true })} />
    </Box>
  );
};

Pulse.propTypes = {};

Pulse.defaultProps = {};

export default Pulse;
