import React, { useState, useMemo, useEffect } from "react";
import { useField } from "formik";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import toNumber from "lodash/toNumber";
import random from "lodash/random";
import remove from "lodash/remove";
import Checkbox from "@material-ui/core/Checkbox";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PercentIndicator from "appComponents/Viewers/PercentIndicator";
import { _t, _n } from "i18n";
import {
  useCurrentFieldHash,
  ItemMeta,
  SurveyProgressBar,
} from "appComponents/SurveyForms";
import basil from "core/basil";
import emitter from "core/emitter";
import { EVENT_RESET_SURVEY_FORM } from "core/emitter/events";
import { getParsedPercent } from "utilities/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  optionRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1.875),
  },
  optionLabel: {
    paddingLeft: theme.spacing(1.25),
    display: "flex",
    flex: "auto",
    width: "calc(100% - 40px)",
    flexDirection: "row",
    alignItems: "center",

    [theme.breakpoints.up("sm")]: {
      fontSize: (props) => (props.layoutMode === "split" ? "16px" : "18px"),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: 0.5,
  },
  answerType: {},
  itemHelp: {
    marginBottom: theme.spacing(1),
  },
  itemLabel: {},
  errorMsg: {
    color: theme.palette.error.main,
  },
  detailsPercent: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    letterSpacing: "0.5px",
    width: "calc(100% - 40px)",
    paddingLeft: theme.spacing(2.25),
  },
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flex: 1,
  },
  meta: {
    //marginBottom: theme.spacing(3),
    color: (props) =>
      props.error ? theme.palette.error.main : theme.palette.text.lightContrast,
    position: "absolute",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
    fontSize: "12px",
    marginTop: 25,
  },
  checkbox: {
    marginLeft: -7.5,
    padding: 7.5,
    borderRadius: 5,
    "& svg": {
      backgroundColor: theme.palette.background.secondaryBox,
      borderRadius: 5,
      padding: 0,
      width: "25px",
      height: "25px",
      color: "transparent",
    },
  },
  checkboxChecked: {
    "& svg": {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  },
  checkboxLabel: {
    height: "100%",
  },
  required: {
    color: (props) =>
      props.error && props.errorType === "required"
        ? theme.palette.error.main
        : "currentcolor",
    display: (props) =>
      props.error && props.errorType === "required" ? "block" : "none",
    position: "absolute",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
    fontSize: "12px",
    marginTop: 25,
    marginBottom: "15px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  },
  detailsSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
  },
  percentText: {
    width: "45px",
  },
}));
const LimitDisplay = ({ min, max, error }) => {
  const classes = useStyles({ error });
  let text = "";
  if (min !== null && max !== null) {
    /* prettier-ignore */
    text = _n("You should make exactly {count} choice", "You should make exactly {count} choices",
      toNumber(min),
      { count: toNumber(min) }
    );
  } else if (min !== null) {
    /* prettier-ignore */
    text = _n("You should make at least {count} choice", "You should make at least {count} choices",
      toNumber(min),
      { count: toNumber(min) }
    );
  } else if (max !== null) {
    /* prettier-ignore */
    text = _n("Select at most {count} choice", "Select at most {count} choices",
      toNumber(max),
      { count: toNumber(max) }
    );
  }

  return <Typography className={classes.meta}>{text}</Typography>;
};
LimitDisplay.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  error: PropTypes.bool,
};

export const Multiple = ({
  name,
  required,
  options,
  help,
  summary,
  withLiveResult,
  canAnswer,
  hash,
}) => {
  const [userChoices, setUserChoices] = useState([]);
  const [errorType, setErrorType] = useState();
  const layoutMode = basil.get("currentLayoutMode") || "split";
  const currentFieldHash = useCurrentFieldHash();

  const validate = (value) => {
    let error;
    if (currentFieldHash !== hash) return;
    if (required && (!value || isEmpty(value))) {
      setErrorType("required");
      error = _t("field is required");
    }
    if (required && options.choices && value) {
      const values = [];
      const labels = [];

      options.choices.forEach(({ hash, label }) => {
        values.push(hash);
        labels.push(label);
      });
      value.forEach((choice) => {
        if (!values.includes(choice))
          error = _t("option must be one of options {labels}", {
            labels: labels.join(","),
          });
      });
    }
    //control max and min
    if (options.min !== null && value.length < toNumber(options.min)) {
      setErrorType("other");
      /* prettier-ignore */
      error = _n("You should make at least {count} choice", "You should make at least {count} choices",
        options.min,
        { count: options.min }
      );
    }
    if (options.max !== null && value.length > toNumber(options.max)) {
      setErrorType("other");
      /* prettier-ignore */
      error = _n("You can not make more than {count} choice", "You can not make more than {count} choices",
        options.max,
        { count: options.max }
      );
    }

    return error;
  };

  const [field, meta, helpers] = useField({
    name,
    validate,
  });

  const classes = useStyles({ error: meta.error, errorType, layoutMode });
  const { setValue } = helpers;

  const handleChange = (event) => {
    let choices = userChoices;
    if (choices.includes(event.target.value)) {
      remove(choices, (choice) => choice === event.target.value);
    } else {
      choices.push(event.target.value);
    }
    setUserChoices(choices);

    setValue(choices, true);
  };

  useEffect(() => {
    const callback = () => {
      setUserChoices([]);
      setValue([], false);
    };

    emitter.on(EVENT_RESET_SURVEY_FORM, callback);
    return () => emitter.removeListener(EVENT_RESET_SURVEY_FORM, callback);
  }, [setValue]);

  let elementsPosition = useMemo(() => {
    let positions = [];
    options.choices.forEach((item) => {
      positions.push(options.randomize ? random(0, options.choices.length) : 0);
    });
    return positions;
  }, [options.choices, options.randomize]);

  const result = options.choices.map((optn, i) => {
    const percent = get(summary, `${get(optn, "hash", "hash")}.percent`);
    return (
      <Box
        order={elementsPosition[i]}
        key={optn.hash}
        display="flex"
        flexDirection="column"
      >
        <Box flexWrap="wrap" className={classes.optionRow}>
          <Box className={classes.main}>
            <Checkbox
              checkedIcon={<CheckOutlinedIcon />}
              checked={userChoices.includes(optn.hash)}
              value={optn.hash}
              onChange={handleChange}
              name={name}
              inputProps={{ "aria-label": "primary checkbox" }}
              disabled={!canAnswer}
              classes={{
                root: classes.checkbox,
                checked: classes.checkboxChecked,
              }}
            />
            <Box className={classes.detailsSection}>
              <Box className={classes.optionLabel}>{optn.label}</Box>
              {withLiveResult && (
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="row"
                  alignItems="center"
                  className={classes.detailsPercent}
                >
                  <PercentIndicator percent={percent} />
                  <Box
                    ml={1}
                    className={classes.percentText}
                  >{`${getParsedPercent(percent)}%`}</Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  });

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <Box display="flex" flexDirection="column" mb={3.25}>
        <SurveyProgressBar />
        <ItemMeta />

        {required && (
          <Typography className={classes.required}>
            {_t("This field is required")}
          </Typography>
        )}
        <LimitDisplay min={options.min} max={options.max} error={meta.error} />
        {result}
      </Box>
    </Box>
  );
};
Multiple.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,

  required: PropTypes.bool,
  hash: PropTypes.string,
  summary: PropTypes.object,
  help: PropTypes.string,
  withLiveResult: PropTypes.bool,
  canAnswer: PropTypes.bool,
};

export default Multiple;
