import React from "react";
import PropTypes from "prop-types";
//import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { _t } from "core/i18n";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    //overflowX: "auto",
  },
  icon: {
    marginRight: "5px",
    color: theme.wisemblyColors.black,
  },
  text: {
    color: theme.palette.text.primary,
  },
}));
const SignatureValidationStatus = ({ signature }) => {
  const { has_signature } = signature;
  const classes = useStyles({ has_signature });
  const text = has_signature ? _t("Present") : _t("Absent");
  const icon = has_signature ? <CheckOutlinedIcon /> : <HighlightOffIcon />;
  return (
    <Box className={classes.root}>
      <Box className={classes.icon}>{icon}</Box>
      <Box className={classes.text}>{text}</Box>
    </Box>
  );
};

SignatureValidationStatus.propTypes = {
  signature: PropTypes.object,
};

SignatureValidationStatus.defaultProps = {
  signature: {},
};
export default SignatureValidationStatus;
