import { put } from "redux-saga/effects";
import { destroyPublisher, setCredentials, setSession, setStreamId, unpublishStream } from "../actions";

export function* clearVisio() {
  console.log("[VISIO SAGA] CLEAR VISIO");

  yield put(unpublishStream("video"));
  // yield put(destroyPublisher("video"));
  // yield put(setStreamId("video", null));
  // yield put(setSession(null));
  // yield put(setCredentials(null));
}
