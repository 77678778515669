import React from "react";
import Box from "@material-ui/core/Box";

import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    cursor: (props) => (props.disabled ? "default" : "pointer"),
    "&:hover": {
      backgroundColor: theme.palette.border.default,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 5,
      paddingRight: 5,
    },
  },
  oval: {
    width: "30px",
    height: "30px",
    opacity: 1,
    backgroundColor: (props) =>
      props.color ? props.color : theme.wisemblyColors.green,
    borderRadius: "50%",
  },
  picto: {
    width: "15px",
    height: "15px",
    objectFit: "contain",
    //filter: "contrast(200%)"
    //filter: "hue-rotate(90deg)"
  },
  text: {
    fontWeight: 700,
  },
  listItemIconRoot: {
    minWidth: theme.spacing(5),
  },
  saturate: { filter: "hue-rotate(90deg)" },
  listitemText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  label: {
    marginLeft: "10px",
  },
  radio: {
    marginLeft: -5,
    padding: 5,
    "& svg": {
      fontSize: "30px",
    },
    "& svg:first-of-type": {
      color: "transparent",
      backgroundColor: theme.palette.background.secondaryBox,
      borderRadius: "100%",
      width: "24px",
      height: "24px",
    },
    "& svg:last-of-type": {
      color: theme.palette.secondary.main,
      width: "24px",
      height: "24px",
    },
  },
  checked: {
    color: theme.palette.secondary.main,
  },
}));

//select menu item, and clickable menu item
const MenuItem = ({ item, onClick, type, selectedOptions, id, disabled }) => {
  //item should have a type
  const title = get(item, "title", "");
  const hash = get(item, "hash", "");
  const classes = useStyles({ disabled });

  const handleClick = () => {
    //add element in onclick
    onClick ? onClick(hash) : null;
  };

  return (
    <ListItem className={classes.listItemRoot} onClick={handleClick}>
      <Box className={classes.listitemText}>
        {type === "checkbox" && (
          <Checkbox
            checked={selectedOptions.includes(hash)}
            disabled={disabled}
          />
        )}
        {type === "select" && (
          <Radio
            name={id}
            value={hash}
            checked={selectedOptions.includes(hash)}
            classes={{ root: classes.radio, checked: classes.checked }}
            color="secondary"
            disabled={disabled}
          />
        )}
        {type === "selectText" && (
          <Box mr={1.25} className={classes.icon}>
            {item.icon}
          </Box>
        )}
        <Box className={classes.label} fontWeight="fontWeightRegular">
          {title}
        </Box>
      </Box>
    </ListItem>
  );
};

MenuItem.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  selectedOptions: PropTypes.array,

  id: PropTypes.string,
  item: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.element,
    menu: PropTypes.shape({
      order: PropTypes.number,
    }),
  }),
  disabled: PropTypes.bool,
};
MenuItem.defaultProps = {
  disabled: false,
};
export default MenuItem;
