import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import { _t } from "i18n";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px 8px",
    height: "18px",
    background: "rgba(240, 78, 55, 0.1)",
    borderRadius: "4px",

  },
  typo: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "10px",
    lineHeight: "100%",
    textAlign: "center",
    color: theme.palette.colors.primary,
  }
}));


const Counter = ({ count }) => {
  const classes = useStyles();


  return (
    <Box className={classes.root}>
      <Typography variant={'bodySB13'} className={classes.typo}>{count}</Typography>
    </Box>
  );
};


Counter.propTypes = {};


Counter.defaultProps = {};


export default Counter;