import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _t } from "i18n";
import Form from "../Create/Form";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import { useSelector } from "react-redux";
import Header from "./Header";
import Footer from "./Footer";
import clsx from "clsx";
import { mobileLayoutSelector } from "app/state/ducks/liveShopping/selectors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    height: "100%",
    width: "100%",
    position: "relative",
    background: "#FFF",
    zIndex: 8,
  },
  mobileLayout: {
    overflow: "auto",
    flex: "1",

  },
  desktopLayout: {
    overflow: "auto",
    flex: 1,
  }
}));


const Edit = ({ }) => {
  const classes = useStyles();
  const wiz = useSelector(currentWizSelector);
  const isMobile = useSelector(mobileLayoutSelector);
  const [data, setData] = useState(null);

  if (!wiz) return null;

  return (
    <Box className={classes.root}>
      <Header />
      <Box className={clsx({
        [classes.mobileLayout]: isMobile,
        [classes.desktopLayout]: !isMobile
      })}>
        <Form setData={setData} wiz={wiz} />
      </Box>
      <Footer data={data} />
    </Box>
  );
};


Edit.propTypes = {};


Edit.defaultProps = {};


export default Edit;
