import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import ChatBubble from "./ChatBubble";
import { getUrl } from "utilities/utils/media";
import { QuoteProptypes } from "orm/resources/quote";
import { _t } from "i18n";

const getOriginMessage = (message) => {
  return message.is_mine ? "me" : "others";
};

const getUrlImg = (message) => {
  if (message.media) {
    return getUrl({
      isMobile: false,
      media: message.media,
      thumbnails: message.media.thumbnails,
    });
  } else {
    return "";
  }
};

const Bubble = forwardRef(({ element, ActionElements, tagsEnabled, tagsDisplay, pin }, ref) => {
  const params = {
    quote: element.quote,
    origin: getOriginMessage(element),
    owner: element.getOwnerName && element.getOwnerName(),
    createdAt: element.published_at,
    isAdmin: element.announcement,
    imgPath: getUrlImg(element),
    hash: element.hash,
    ActionElements,
    reactions: element.reactions,
    reaction_count: element.reaction_count,
    tagsEnabled,
    tagsDisplay,
    pin,
  };
  return <ChatBubble {...params} ref={ref} />;
});

Bubble.displayName = "Bubble";

Bubble.propTypes = {
  element: PropTypes.shape(QuoteProptypes),
  ActionElements: PropTypes.func,
  pin: PropTypes.bool,
  tagsEnabled: PropTypes.bool,
  tagsDisplay: PropTypes.bool,
};

Bubble.defaultProps = {
  pin: false,
};

export default Bubble;
