import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { _n, _t } from "i18n";
import Queue from "../../Component/Live/Queue";
import { currentVisitorSelector, getOwnAccessesSelector, visitorsSelector } from "app/state/ducks/ressources/selectors";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { currentProjectSelector } from "app/state/ducks/application/selectors";
import VisioVonage from "app/liveShopping/frontoffice/oto/containers/Visio/VisioVonage";
import { isReadySelector } from "app/state/ducks/liveShopping/selectors";
import Chat from "../../Component/Live/Chat/Chat";
import VisitorInfos from "../../Component/Live/VisitorInfos";
import Call from "../../Component/Live/Call";
import Comment from "../../Component/Live/Comment";
import clsx from "clsx";
import QueuesWidgetContainer from "./QueuesWidgetContainer";
import ErrorComponent from "../../Component/ErrorComponent";
import { getUrl } from "app/views/containers/Application/Routes";
import FishingRodContainer from "./FishingRodContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    zIndex: 1
  },
  bottom: {
    display: "flex",
    width: "100%",
    height: "50%",
    flexDirection: "row",
    gap: "20px",
  },
  waiting: {
    width: "30%",
    height: "100%",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
    borderRadius: "10px",
  },
  infos: {
    display: "flex",
    padding: "20px",
    gap: "20px",
    width: "70%",
    height: "100%",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.15)",
    borderRadius: "10px",
  },
  infoTitle: {
    fontWeight: "bold",
  },
  hangup: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  tools: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRight: "1px solid #F0F0F4",
    borderBottom: "1px solid #F0F0F4",
    height: "48px",
    position: "relative",
  },
  retract: {
    marginTop: "10px",
    marginLeft: "10px",
    marginBottom: "6px",
  },

  right: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  visio: {
    width: "100%",
    height: "50%",
    position: "relative",
    borderBottom: "1px solid #F0F0F4",
  },
  empty: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));

const LiveContainer = ({ }) => {
  const currentVisitor = useSelector(currentVisitorSelector);
  const currentProject = useSelector(currentProjectSelector);
  const accesses = useSelector(getOwnAccessesSelector).filter((access) => access.available === true);
  const visitors = useSelector(visitorsSelector);
  const isReady = useSelector(isReadySelector);
  const classes = useStyles({ currentVisitor });
  const isRinging = currentVisitor?.status === "ringing";
  const hash = localStorage.getItem("projectId");
  if (!currentProject) return <Redirect to={getUrl("oto_dashboard", { hash })} />;
  if (!isReady) return <VisioVonage preview />
  if (accesses.length === 0) {
    setTimeout(() => {
      window.location.href = getUrl("oto_live", { hash: hash });
    }, 5000);
    return <ErrorComponent text={_t("You don't have any queues selected, you will be redirected to queues")} />;
  }
  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.tools}>
          <QueuesWidgetContainer />
          <FishingRodContainer />
        </Box>
        <Queue />
      </Box>
      <Box className={classes.right}>
        {currentVisitor && !isReady && isRinging && <VisioVonage preview />}

        {!currentVisitor ? <ErrorComponent text={_t("If there are, please choose a customer")} /> : (
          <>
            <Box className={clsx({ [classes.visio]: true, [classes.emptyVisio]: !isReady && !currentVisitor })}>
              {currentVisitor && (
                <>
                  {!currentVisitor.session_id && <Call visitor={currentVisitor} />}
                  {isReady && currentVisitor.session_id && <VisioVonage visitor={currentVisitor} />}
                </>
              )}
              {currentVisitor?.terminated_at && <Comment />}
            </Box>

            <Box display="flex" style={{ height: "50%" }}>
              <VisitorInfos />
              <Box style={{ height: "100%", width: "40%" }}>
                <Chat key={currentVisitor?.hash} disabled={!currentVisitor} />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

LiveContainer.propTypes = {};

LiveContainer.defaultProps = {};

export default LiveContainer;
