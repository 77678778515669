import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { _t } from "i18n";

import { makeStyles, useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import TextInput from "coreComponents/OutlinedTextField";

import Icon from "coreUiKit/inputs/Icon";
import CarretUp from "react-bootstrap-icons/dist/icons/caret-up-fill";
import CarretDown from "react-bootstrap-icons/dist/icons/caret-down-fill";

const useStyles = makeStyles((theme) => ({
  iconBox: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Stepper = ({
  value,
  valueOnChange,
  disabled,
  valueMax,
  width,
  error,
  setError,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [errorMessage, setErrorMessage] = useState("");

  const upValue = () => {
    handleChange(parseInt(value) + 1);
  };
  const downValue = () => {
    handleChange(parseInt(value) - 1);
  };

  const handleChange = (newValue) => {
    if (!isNaN(newValue)) {
      if (newValue >= valueMax) valueOnChange(0);
      else if (newValue < 0) valueOnChange(valueMax - 1);
      else {
        if (newValue.toString().length < valueMax.toString().length) {
          var zero = "0".repeat(
            (valueMax - 1).toString().length - newValue.toString().length
          );
          valueOnChange(zero + newValue);
        } else if (newValue.toString().length <= valueMax.toString().length)
          valueOnChange(newValue);
        else if (
          newValue.toString().length > valueMax.toString().length &&
          newValue[0] === "0"
        )
          valueOnChange(newValue.substring(1));
        setError(false);
        setErrorMessage("");
      }
    }
  };

  useEffect(() => {
    if (isNaN(value)) {
      setError(true);
      setErrorMessage(_t("Invalid value, must be numeric"));
    } else {
      setError(false);
      setErrorMessage("");
    }
  }, [value]);

  return (
    <Box flexDirection="row" alignItems="center">
      <TextInput
        value={value}
        width={width}
        onChange={handleChange}
        error={error}
        errorMessage={errorMessage}
        disabled={disabled}
        endAdornment={
          <Box className={classes.iconBox}>
            <Icon
              IconComponent={CarretUp}
              color="black"
              hover="black"
              size="12"
              onClick={upValue}
            />
            <Icon
              IconComponent={CarretDown}
              color="black"
              hover="black"
              size="12"
              onClick={downValue}
            />
          </Box>
        }
      ></TextInput>
    </Box>
  );
};
Stepper.propTypes = {
  value: PropTypes.number,
  inputOnChange: PropTypes.func,
  disabled: PropTypes.bool,
  valueMax: PropTypes.number,
  width: PropTypes.number,
  error: PropTypes.bool,
  setError: PropTypes.func,
};
Stepper.defaultProps = {
  value: "00",
  valueOnChange: () => {},
  disabled: false,
  valueMax: 99,
  error: false,
  setError: () => {},
};

export default Stepper;