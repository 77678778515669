import React from "react";
import PropTypes from "prop-types";
//import clsx from "clsx";
import map from "lodash/map";
import Box from "@material-ui/core/Box";
//import Typography from "@material-ui/core/Typography";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },

  detailsText: {
    flex: "auto",
    justifyContent: "flex-start",
    width: "calc(100% - 40px)",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 18,
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
    fontWeight: "normal",
    lineHeight: 1.25,
    letterSpacing: 0.5,
  },
  bulletPoint: {
    paddingLeft: 30,
    "&::marker": {
      color: theme.palette.secondary.main,
    },
  },
}));

const UserRankingSurveyItemResult = ({
  surveyItemAnswer,
  surveyItem,
  survey,
}) => {
  const userChoices = surveyItemAnswer.value;
  const options = get(surveyItem, "options.choices", []);

  const classes = useStyles();
  const result = map(userChoices, (choice, key) => {
    const option = options.filter((optn) => optn.hash === choice)[0];
    return (
      <Box
        key={key}
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        className={classes.detailsText}
      >
        <li className={classes.bulletPoint}>{get(option, "label", "")}</li>
      </Box>
    );
  });

  return result;
};
UserRankingSurveyItemResult.propTypes = {
  surveyItem: PropTypes.object,
  surveyItemAnswer: PropTypes.object,
  survey: PropTypes.object,
};

export default UserRankingSurveyItemResult;
