import React, { useState, useEffect } from "react";
import { useField } from "formik";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import get from "lodash/get";
import { _t } from "i18n";
import { makeStyles } from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import RatingSurveyItemResult from "appComponents/Viewers/RatingSurveyItemResult";
import {
  useCurrentFieldHash,
  ItemMeta,
  SurveyProgressBar,
} from "appComponents/SurveyForms";
import emitter from "core/emitter";
import { EVENT_RESET_SURVEY_FORM } from "core/emitter/events";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    //height: theme.spacing(5),
  },
  optionRow: {},
  optionLabel: {
    marginLeft: theme.spacing(2),
  },
  answerType: {},
  itemHelp: {
    marginBottom: theme.spacing(1),
  },
  label: {
    fontSize: "18px",
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: 1.25,
    letterSpacing: "0.5px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  ratingRoot: {
    marginLeft: "25px",
    marginRight: "25px",
    "& .MuiRating-iconEmpty": {
      color: theme.palette.background.secondaryBox,
    },
  },

  liveResult: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  required: {
    color: (props) => (props.error ? theme.palette.error.main : "currentcolor"),
    display: (props) => (props.error ? "block" : "none"),
    position: "absolute",
    textAlign: "left",
    fontWeight: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    lineHeight: "1.25",
    letterSpacing: "0.5px",
    fontSize: "14px",
    marginTop: "30px",
    marginBottom: "15px",
    fontSize: "12px",
    marginTop: 55,
    top: 0,
  },
  main: {
    width: "100%",
  },
  ratingSection: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "50px",
  },
  ratingLabel: {
    fontSize: 60,
    marginLeft: 10,
    "&:first-of-type": {
      marginLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem",
    },
  },
  ratingIconEmpty: {},
  iconFilled: {
    color: theme.palette.secondary.main,
  },
  iconHover: {
    color: theme.palette.secondary.main,
  },
}));

export const RatingField = ({
  name,

  required,
  options,

  summary,
  withLiveResult,
  canAnswer,
  hash,
}) => {
  //label={optn.label || optn.hash}
  const [ratingValue, setRatingValue] = useState(0);
  const currentFieldHash = useCurrentFieldHash();
  const ratingObject = options.rating;

  const validate = (value) => {
    let error;
    if (currentFieldHash !== hash) return;
    if (required && (!value || value < 0)) {
      error = _t("field is required");
    }
    return error;
  };
  //field  { name, value, onChange, onBlur } plus any other props passed to directly to useField
  //helpers {setValue, setTouched, setError}
  //const [field, meta, helpers] = useField
  const [field, meta, helpers] = useField({
    name,
    validate,
    // value: ratingValue,
    // type: "number",
    // min: "1",
    // max: ratingObject.max || 5,
  });
  const classes = useStyles({ error: meta.error });

  //const { value } = meta;
  //setValue(value: any, shouldValidate?: boolean): void
  const { setValue } = helpers;

  const handleRatingChange = (event, value) => {
    setValue(value, true);
    setRatingValue(value);
  };
  useEffect(() => {
    const callback = () => {
      setRatingValue(0);
      setValue(0, false);
    };
    emitter.on(EVENT_RESET_SURVEY_FORM, callback);
    return () => emitter.removeListener(EVENT_RESET_SURVEY_FORM, callback);
  }, [setValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width="100%"
    >
      <SurveyProgressBar />
      <ItemMeta />
      <Box
        flexDirection="column"
        display="flex"
        className={classes.main}
      >
        {required && (
          <Typography className={classes.required}>
            {_t("This field is required")}
          </Typography>
        )}

        <Box className={classes.ratingSection}>
          <Box className={clsx(classes.leftLabel, classes.label)}>
            {get(ratingObject, "labels.min")}
          </Box>
          <Rating
            classes={{
              root: classes.ratingRoot,
              label: classes.ratingLabel,
              iconFilled: classes.iconFilled,
              iconHover: classes.iconHover,
            }}
            value={ratingValue}
            onChange={handleRatingChange}
            precision={ratingObject.step || 1}
            max={ratingObject.max || 5}
            disabled={!canAnswer}
          />
          <Box className={clsx(classes.rightLabel, classes.label)}>
            {get(ratingObject, "labels.max")}
          </Box>
        </Box>
      </Box>
      {withLiveResult && (
        <Box mt={1} className={classes.liveResult}>
          <RatingSurveyItemResult
            item={{ summary, options }}
            showDetails={false}
          />
        </Box>
      )}
    </Box>
  );
};
RatingField.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.object,
  required: PropTypes.bool,
  help: PropTypes.string,
  withLiveResult: PropTypes.bool,
  summary: PropTypes.object,
  canAnswer: PropTypes.bool,
  hash: PropTypes.string,
};

export default RatingField;
