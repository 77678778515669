import {
  put,
  takeEvery,
  take,
  all,
  call,
  select,
  fork,
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import {
  currentCartSelector,
  currentWizSelector,
  productSelector,
  productsSelector,
  currentVisitorSelector,
} from "app/state/ducks/ressources/selectors";
import ResourceFactory from "orm/resources";
import {
  resourceFetch,
  resourceCreate,
  resourceEdit,
  resourceDelete,
} from "ressourcesDucks/actions";
import { getKeyword } from "applicationDucks/selectors";
import { emit, listen } from "core/liveshopping-sdk";
import globalStore from "appStore";

import emitter from "core/emitter";
import { CART_UPDATE, CART_UPDATED, SHARE_MODAL } from "core/emitter/events";

import {
  ADD_TO_CART,
  UPDATE_CART_QUANTITY,
  PROCEED_TO_CHECKOUT,
  LIVESHOPPING_INIT,
  PRELIVE_INIT,
} from "liveShoppingDucks/actionsTypes";
import { REMOVE_FROM_CART } from "../actionsTypes";
import dayjs from "dayjs";

function* getCartSlug() {
  const keyword = yield select(getKeyword);
  return ["event", keyword, "cart"];
}

// always increment variant in basket when new quantity is set
function* onAddToCart({ product_id, variant_id, quantity, variant_vendor_id }) {
  const cart = yield select(currentCartSelector);
  const product = yield select(productSelector, product_id);
  const variant = product.getVariant(variant_id);

  const [newQuantity, previousQuantity] = cart.addProduct(
    product,
    variant,
    quantity
  );

  if (variant_vendor_id)
    emit("ADD", {
      id: variant_vendor_id,
      quantity: newQuantity,
      previous_quantity: previousQuantity,
      cartId: cart.id,
    });

  emitter.emit(CART_UPDATE);
  const slug = yield call(getCartSlug);

  yield put(
    resourceEdit(cart, {
      slug,
      callback: () => {
        emitter.emit(CART_UPDATED);
      },
    })
  );
}

// replace current quantity by new one
function* onUpdateQuantity({
  product_id,
  variant_id,
  quantity,
  variant_vendor_id,
}) {
  const cart = yield select(currentCartSelector);
  const product = yield select(productSelector, product_id);
  const variant = product.getVariant(variant_id);

  const [newQuantity, previousQuantity] = cart.updateProduct(
    product,
    variant,
    quantity
  );

  if (variant_vendor_id)
    emit("UPDATE", {
      id: variant_vendor_id,
      quantity: newQuantity,
      previous_quantity: previousQuantity,
      cartId: cart.id,
    });

  emitter.emit(CART_UPDATE);
  const slug = yield call(getCartSlug);

  yield put(
    resourceEdit(cart, {
      slug,
      callback: () => {
        emitter.emit(CART_UPDATED);
      },
    })
  );
}

function* onRemoveFromCart({ product_id, variant_id, variant_vendor_id }) {
  const cart = yield select(currentCartSelector);

  cart.removeProduct(product_id, variant_id);

  if (variant_vendor_id) emit("DELETE", { id: variant_vendor_id });

  emitter.emit(CART_UPDATE);
  const slug = yield call(getCartSlug);

  yield put(
    resourceEdit(cart, {
      slug,
      callback: () => {
        emitter.emit(CART_UPDATED);
      },
    })
  );
}

function createPostMessageChannel() {
  return eventChannel((emit) => {
    listen(emit);

    // eventChannel must return an unsubscribe function
    return () => {};
  });
}

function* handlePostMessage(status, payload) {
  const cart = yield select(currentCartSelector);
  const currentVisitor = yield select(currentVisitorSelector);

  if (currentVisitor && payload.action === "ADD") {
    console.log('OTO ADD product', payload.data, payload.action_data, currentVisitor.options.add_to_cart);

    // update currentVisitor.options.add_to_cart to change statusses accordingly
    const product = currentVisitor.options.add_to_cart.find(({ variant_id }) => variant_id === payload.action_data.id);

    if (!product) {
      console.log('product not found! :(');
      return;
    }

    product.status = "added";

    console.log('updating product !')

    yield put(
      resourceEdit(currentVisitor, {
        slug: ["visitors", currentVisitor.hash, currentVisitor.token],
        patch: ["options"],
      })
    );

    return;
  }

  console.log('current cart', cart);

  // handle Cart token
  if (payload?.data?.token && cart?.id) {
    const slug = yield call(getCartSlug);

    // TODO handle cart sync ? (if used changed quantities and products directly from e-commerce)
    console.log("Get shopify cart", payload.data);

    // we already have proper cart vendor token, no need to update
    if (cart?.vendor_id === payload.data.token) return;

    // if cart already has an id but here is a new one, delete and recreate a new cart !
    if (cart?.vendor_id && cart?.vendor_id !== payload.data.token) {
      yield put(resourceDelete(cart, { slug }));
      yield call(createNewCart, payload.data.token);
      return;
    }

    cart.vendor_id = payload.data.token;

    yield put(resourceEdit(cart, { slug, silent: true }));
    return;
  }

  // handle error and rollbacks
  if (status === "error" && payload?.previous_quantity) {
    // retrieve product vendor by vendor_id and patch it
    yield call(handleRollback, cart, payload);
  }

  if (
    status === "error" &&
    payload.message === "navigator_share_not_supported"
  ) {
    emitter.emit(SHARE_MODAL);
  }
}

function* handleRollback(cart, { id, previous_quantity }) {
  console.log('making a rollbak', id, previous_quantity);
  const wiz = yield select(currentWizSelector);
  const products = yield select(productsSelector);

  let product, variant;

  for (let i = 0; i < products.length; i++) {
    if (!products[i].getVariantByVendorId(id)) continue;

    product = products[i];
    variant = products[i].getVariantByVendorId(id);
  }

  if (!product || !variant) return;

  cart.updateProduct(product, variant, previous_quantity);

  emitter.emit(CART_UPDATE);

  yield put(
    resourceEdit(cart, {
      slug: ["event", wiz.keyword, "cart"],
      callback: () => {
        emitter.emit(CART_UPDATED);
      },
    })
  );
}

// we need to wrap this dispatch in a Promise in order to use it with blocking call() method
// put() is not blocking and we could not ensure to have fetch cart before trying to access it in store
// https://redux-saga.js.org/docs/Glossary/#blockingnon-blocking-call
const synchronousFetchCart = () =>
  new Promise((resolve, reject) => {
    const keyword = getKeyword(globalStore.getState());

    if (!keyword)
      return reject('No keyword found, cannot fetch cart from API');

    const slug = ["event", keyword, "cart"];

    globalStore.dispatch(
      resourceFetch("Cart", {
        slug,
        silent: true,
        once: true,
        callback: () => {
          resolve();
        },
      })
    );
  });

function* cartInit() {
  // blocking call, ensure we properly finish fetching before calling cart selector
  try {
    yield call(synchronousFetchCart);
  } catch (e) {
    console.log(e);
    return;
  }

  const cart = yield select(currentCartSelector);

  // if cart does not exist, create it
  if (!cart.id) yield call(createNewCart);

  console.log('Cart fetched from API, need to call it from Provider now');
  setTimeout(() => emit("GET_CART"), 1000); // put small timeout to avoid race conditions (btw getting shopify cart data vs. retrieving it from store)
}

function* createNewCart(vendor_id = null) {
  const state = yield select();
  const slug = yield call(getCartSlug);
  const newCart = new ResourceFactory(state).create("Cart", {
    sum: 0,
    currency: "EUR",
    vendor_id,
  });

  yield put(resourceCreate(newCart, { slug }));
}

function* initSdkCommunication() {
  const channel = yield call(createPostMessageChannel);

  while (true) {
    const { status, data } = yield take(channel);

    try {
      yield fork(handlePostMessage, status, data);
    } catch (error) {
      console.log("Unable to handle postMessage", error, status, data);
    }
  }
}

function* checkout() {
  const keyword = yield select(getKeyword);
  const cart = yield select(currentCartSelector);

  cart.proceeded_to_checkout_at = dayjs().toISOString();

  yield put(
    resourceEdit(cart, {
      slug: ["event", keyword, "cart"],
      callback: () => {
        emit('PROCEED_TO_CHECKOUT');
      },
    })
  );
}

export default function* cartSaga() {
  yield all([
    yield takeEvery(ADD_TO_CART, onAddToCart),
    yield takeEvery(UPDATE_CART_QUANTITY, onUpdateQuantity),
    yield takeEvery(REMOVE_FROM_CART, onRemoveFromCart),
    yield takeEvery(LIVESHOPPING_INIT, initSdkCommunication),
    yield takeEvery(PRELIVE_INIT, initSdkCommunication),
    yield takeEvery(LIVESHOPPING_INIT, cartInit),
    yield takeEvery(PROCEED_TO_CHECKOUT, checkout),
  ]);
}
