import React, { useState, useMemo, useEffect } from "react";
import KeyboardArrowDownIcon from "react-bootstrap-icons/dist/icons/chevron-down";
import KeyboardArrowUpIcon from "react-bootstrap-icons/dist/icons/chevron-up";
import Menu from "@material-ui/core/Menu";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import OutlinedInput from "../../../app/liveShopping/backoffice/otm/components/reusables/OutlinedTextField";
import MenuItem from "./MenuItem";
import Box from "@material-ui/core/Box";
import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";

const useStyles = makeStyles(
  (theme) => ({
    menuBox: {
      width: (props) => (props.width ? props.width : "auto"),
    },
    menuList: {
      paddingTop: 0,
      marginTop: "8px",
    },
    menuPaper: {
      width: (props) => (props.MenuWidth ? props.MenuWidth : "auto"),
      marginTop: "5px",
      backgroundColor: theme.palette.colors.white,
      boxShadow: theme.palette.shadow.dropdown,
    },
  }),
  {
    name: "OneMenu",
  }
);
const getDefaultOptions = (defaultOption, options, keepOptionSelected) => {
  let result = [];
  if (!defaultOption) return [];
  const allHashs = options.map((opt) => opt.hash);
  if (isArray(defaultOption)) {
    result = defaultOption.map((opt) => opt.hash);
  } else {
    result = [defaultOption.hash];
  }
  if (isArray(keepOptionSelected)) {
    //keep only valid options
    keepOptionSelected.forEach((elem) => {
      if (allHashs.includes(elem)) {
        result.push(elem);
      }
    });
  }

  if (result.includes("all")) {
    result = allHashs;
  }
  return result;
};
const FilterMenu = ({
  title,
  options,
  id,
  notifyParentOnClick,
  type,
  onFilterChange,
  defaultOption,
  keepOptionSelected,
  staticTitle,
  disableFixedOptions,
  error,
  errorMessage,
  disabled,
  width,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  //getDefaultOptions (defaultOption)
  const [selectedOptions, setSelectedOptions] = useState(
    getDefaultOptions(defaultOption, options)
  );
  useEffect(() => {
    setSelectedOptions(
      getDefaultOptions(defaultOption, options, keepOptionSelected)
    );
  }, [JSON.stringify(defaultOption), JSON.stringify(keepOptionSelected)]);
  const menuTitle = useMemo(() => {
    if (type !== "checkbox" && !isEmpty(selectedOptions) && !staticTitle) {
      const data = options.filter((elem) => elem.hash === selectedOptions[0]);

      return get(data, "[0].title", title);
    } else return title;
  }, [selectedOptions, JSON.stringify(options), type, title, staticTitle]);

  const handleClick = (event) => {
    if (open) {
      handleClose();
      return;
    }
    if (get(event, "currentTarget")) {
      setAnchorEl(get(event, "currentTarget"));
    }

    setOpen(true);

    notifyParentOnClick();
  };

  const handleClose = (selected) => {
    if (selected !== null && selected !== undefined) {
      let nextSelection = cloneDeep(selectedOptions);
      if (nextSelection.includes(selected) && type === "checkbox") {
        if (keepOptionSelected.includes(selected)) {
          return true;
        }
        if (selected === "all") {
          nextSelection = [];
        } else {
          if (nextSelection.includes("all")) {
            const allIndex = nextSelection.indexOf("all");
            if (allIndex > -1) {
              nextSelection.splice(allIndex, 1);
            }
          }
          const index = nextSelection.indexOf(selected);
          if (index > -1) {
            nextSelection.splice(index, 1);
          }
        }
      } else {
        if (type === "select" || type === "textIcon") {
          nextSelection = [];
        }
        if (selected === "all") {
          nextSelection = options.map((opt) => opt.hash);
        } else if (selected !== "") {
          nextSelection.push(selected);
        }
      }

      setSelectedOptions(nextSelection);

      //improve control here to handle filter without checkbox
      const selection =
        type === "checkbox"
          ? {
            type: id,
            list: nextSelection,
          }
          : {
            type: id,
            value: selected,
          };

      onFilterChange && onFilterChange(selection);
    }
    if (!selected || type !== "checkbox") {
      setAnchorEl(null);
      setOpen(false);
    }
  };

  const classes = useStyles({
    MenuWidth: anchorEl && anchorEl.offsetWidth,
    width,
  });
  const endIcon = !isEmpty(options) ? (
    !open ? (
      <KeyboardArrowDownIcon />
    ) : open ? (
      <KeyboardArrowUpIcon />
    ) : null
  ) : null;

  return (
    <Box className={classes.menuBox}>
      <OutlinedInput
        type="text"
        fullWidth
        value={menuTitle}
        endAdornment={endIcon}
        onClick={!disabled && handleClick}
        autoFocus={false}
        readOnly={true}
        disabled={disabled}
        error={error}
        errorMessage={errorMessage}
      />

      {!isEmpty(options) && (
        <Menu
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          id={`${title}-menu`}
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleClose(null)}
          classes={{ list: classes.menuList, paper: classes.menuPaper }}
        >
          {options.map((item, index) => {
            return (
              <MenuItem
                key={index}
                onClick={handleClose}
                item={item}
                selected={selectedOptions.includes(item?.hash)}
                type={type}
                id={id}
                disabled={
                  disableFixedOptions && keepOptionSelected.includes(item?.hash)
                }
              />
            );
          })}
        </Menu>
      )}
    </Box>
  );
};

FilterMenu.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      title: PropTypes.string,
      menu: PropTypes.shape({
        menuId: PropTypes.string,
        activated: PropTypes.bool,
        order: PropTypes.number,
      }),
    })
  ),
  notifyParentOnClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  id: PropTypes.string,
  type: PropTypes.oneOf(["select", "checkbox", "textIcon"]),
  defaultOption: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  keepOptionSelected: PropTypes.array,
  staticTitle: PropTypes.bool,
  disableFixedOptions: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  width: PropTypes.string,
};

FilterMenu.defaultProps = {
  options: [],
  notifyParentOnClick: () => { },
  keepOptionSelected: [],
  staticTitle: false,
  disableFixedOptions: false,
};

export default FilterMenu;
