import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, ButtonBase } from "@material-ui/core";
import { _t } from "i18n";
import { useSelector, useDispatch } from "react-redux";
import { setReplayDialog } from "app/state/ducks/liveShopping/actions";
import CustomCloseIcon from "app/liveShopping/assets/icons/CustomCloseIcon";
import { resourceCreate } from "app/state/ducks/ressources/actions";
import ResourceFactory from "orm/resources";
import { currentWizSelector } from "app/state/ducks/ressources/selectors";
import theme from "app/views/styles/theme";

const useStyles = makeStyles((theme) => ({
  dialogShadow: {
    position: (props) => (props.desktopLayout ? "absolute" : "fixed"),
    height: "100%",
    // width: "100%",
    top: 0,
    backgroundColor: "rgba(0, 0, 0, .6)",
    zIndex: 11,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  dialog: {
    width: "80%",
    height: "auto",
    backgroundColor: "white",
    zIndex: 12,
    padding: 20,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "130%",
    textAlign: "center",
    color: "#000000",
    marginTop: "20px",
  },
  input: {
    padding: "10px 12px",
    border: "1px solid #E2E4E8",
    boxSizing: "border-box",
    borderRadius: "8px",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "130%",
    width: "100%",
    marginTop: "20px",
    marginBottom: "20px",
    WebkitAppearance: "none",
    "&:focus": {
      outline: "none",
    },
    "&:placeholder": {
      color: "#808284",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: "130%",
    },
  },
  button: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 30px",
    background: (props) => (props.registered ? theme.palette.colors.success : props.alreadyRegistered ? theme.palette.colors.error : theme.palette.colors.primary),
    borderRadius: "99px",
    margin: "auto",
  },
  buttonTypo: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "100%",
    textAlign: "center",
    color: "#FFFFFF",
  },
  close: {
    display: "flex",
    justifyContent: "end",
  },
}));

const ReplayDialog = ({}) => {
  const [registered, setRegistered] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const classes = useStyles({ registered, alreadyRegistered });
  const replayDialog = useSelector((state) => state.liveShopping.replayDialog);
  const wiz = useSelector(currentWizSelector);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const handleQuitDialog = () => {
    dispatch(setReplayDialog(false));
  };
  const regexValidation = /\S+@\S+\.\S+/;
  const validation = email.length > 0 && regexValidation.test(email);
  const resourceFactory = useSelector((state) => new ResourceFactory(state));

  const newRegistration = resourceFactory.create("Custom", { email: email });
  const handleSend = () => {
    dispatch(
      resourceCreate(newRegistration, {
        slug: ["event", wiz.keyword, "register"],
        silent: true,
        callback: (error, response) => {
          if (error?.statusCode === 409) {
            setAlreadyRegistered(true);
            setTimeout(() => {
              setAlreadyRegistered(false);
              dispatch(setReplayDialog(false));
            }, 1200);
            return;
          }
          if (response) {
            setRegistered(true);
            setTimeout(() => setRegistered(false), 1200);
            return;
          }
        },
      })
    );
  };
  if (!replayDialog) return null;
  return (
    <Box className={classes.dialogShadow} onClick={handleQuitDialog}>
      <Box className={classes.dialog} onClick={(e) => e.stopPropagation()}>
        <Box className={classes.close}>
          <ButtonBase onClick={handleQuitDialog}>
            <CustomCloseIcon />
          </ButtonBase>
        </Box>
        {/* prettier-ignore */}
        <Typography className={classes.title}>
          {_t("There is no operator connected on the chat, please leave your email if you wish to be contacted")}
        </Typography>

        <input name="email" className={classes.input} type="text" placeholder="Email" value={email} autoFocus onChange={(e) => setEmail(e.target.value)} />

        <ButtonBase disabled={!validation} className={classes.button} onClick={handleSend}>
          <Typography className={classes.buttonTypo}>{registered ? _t("You're registered") : alreadyRegistered ? _t("You're already registered") : _t("Send")}</Typography>
        </ButtonBase>
      </Box>
    </Box>
  );
};

ReplayDialog.propTypes = {};

ReplayDialog.defaultProps = {};

export default ReplayDialog;
