import Box from "@material-ui/core/Box";
import clsx from "clsx";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "app/liveShopping/backoffice/otm/components/reusables/Typography";
import EyeSlashFill from "react-bootstrap-icons/dist/icons/eye-slash-fill";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: ({ size }) => (size === "largeSquare" ? "12px" : "99px"),
    width: ({ size }) => (size === "small" ? "32px" : size === "medium" ? "50px" : size === "mediumlarge" || size === "largeSquare" ? "80px" : "100px"),
    height: ({ size }) => (size === "small" ? "32px" : size === "medium" ? "50px" : size === "mediumlarge" || size === "largeSquare" ? "80px" : "100px"),
    background: ({ avatar, anonyme, size }) => avatar && !anonyme && `center / ${size === "largeSquare" ? "cover" : "contain"} no-repeat url(${avatar})`,
  },

  initials: {
    background: theme.palette.colors.primary,
  },
  anonyme: {
    background: theme.palette.colors.grey,
  },
}));

const AvatarInitials = ({ avatar, initials = false, anonymous, children, size }) => {
  const classes = useStyles({ avatar, anonymous, size });

  const initialVariant = size === "small" ? "smallBodySB13" : size === "medium" ? "subtitle13" : "headline13";
  const sizeIcon = size === "small" ? 16 : size === "medium" ? 26 : 39;

  return (
    <Box className={clsx(classes.root, "AvatarInitialsWrapper")}>
      <Box
        className={clsx("AvatarInitials", {
          [classes.avatar]: true,
          [classes.initials]: initials,
          [classes.anonyme]: anonymous,
        })}
      >
        <EyeSlashFill height={sizeIcon} width={sizeIcon} color={"white"} />
      </Box>
      {children}
    </Box>
  );
};

AvatarInitials.propTypes = {
  avatar: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  initials: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  size: PropTypes.oneOf(["small", "medium", "large", "mediumlarge", "largeSquare"]),
  anonymous: PropTypes.bool,
  children: PropTypes.node,
};

AvatarInitials.defaultProps = {
  avatar: false,
  initials: "",
  anonymous: false,
  children: null,
  size: "small",
};

export default AvatarInitials;
