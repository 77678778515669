import { ORM } from "redux-orm";

import {
  User,
  License,
  Contract,
  Event,
  EventSession,
  Quote,
  Comment,
  Survey,
  Item,
  Tag,
  Workshop,
  Step,
  Media,
  Reaction,
  SurveyAnswer,
  SurveyItemAnswer,
  Landing,
  Access,
  Report,
  Custom,
  Product,
  LeaderBoard,
  Template,
  Organization,
  SignatureRequest,
  Signature,
  Replay,
  Cart,
  Watcher,
  Group,
  Project,
  Queue,
  Visitor,
  TrackEvent,
  Calendar,
} from "./resources";

const orm = new ORM({
  stateSelector: (state) => state.resources,
});

orm.register(
  User,
  License,
  Contract,
  Event,
  EventSession,
  Quote,
  Comment,
  Survey,
  Item,
  Tag,
  Workshop,
  Step,
  Media,
  Reaction,
  SurveyAnswer,
  SurveyItemAnswer,
  Landing,
  Access,
  Custom,
  Report,
  LeaderBoard,
  Product,
  Template,
  Organization,
  SignatureRequest,
  Signature,
  Replay,
  Cart,
  Watcher,
  Group,
  Project,
  Queue,
  Visitor,
  TrackEvent,
  Calendar,
);

function mapping() {
  const map = {};
  const registry = orm.registry;

  registry.forEach((resource) => {
    if (!resource.backendName) {
      throw new Error(`Backend name should be present for entity ${resource.modelName}`);
    }

    map[resource.backendName] = resource.modelName;
  });

  return map;
}

const backendMapping = mapping();

export { backendMapping, orm as default };
