import React from "react";
import PropTypes from "prop-types";

const CustomCopyIcon = ({ color, size, ...rest }) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill={color}
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.34126 12.2662L6.76876 14.8387C5.71378 15.8937 5.12109 17.3246 5.12109 18.8166C5.12109 20.3085 5.71378 21.7394 6.76876 22.7944C7.82374 23.8494 9.2546 24.442 10.7466 24.442C12.2385 24.442 13.6694 23.8494 14.7244 22.7944L18.1519 19.365C18.7881 18.7287 19.2623 17.9491 19.5349 17.0917C19.8074 16.2342 19.8703 15.3238 19.7182 14.437C19.5661 13.5502 19.2035 12.7128 18.6608 11.9951C18.1181 11.2775 17.4112 10.7004 16.5994 10.3125L15.5006 11.4112C15.3891 11.523 15.2921 11.6484 15.2119 11.7844C15.8391 11.9647 16.4084 12.3056 16.8636 12.7734C17.3187 13.2411 17.6438 13.8196 17.8069 14.4515C17.9699 15.0835 17.9652 15.747 17.7933 16.3766C17.6213 17.0062 17.288 17.58 16.8263 18.0412L13.4006 21.4688C12.697 22.1724 11.7426 22.5677 10.7475 22.5677C9.75239 22.5677 8.79804 22.1724 8.09438 21.4688C7.39073 20.7651 6.99542 19.8107 6.99542 18.8156C6.99542 17.8205 7.39073 16.8662 8.09438 16.1625L9.58126 14.6775C9.37147 13.8914 9.29042 13.0764 9.34126 12.2644V12.2662Z"
        fill={color}
      />
      <path
        d="M12.8484 8.76001C12.2122 9.39628 11.738 10.1759 11.4654 11.0334C11.1929 11.8908 11.13 12.8012 11.2821 13.688C11.4342 14.5748 11.7968 15.4122 12.3395 16.1299C12.8822 16.8476 13.5891 17.4246 14.4009 17.8125L15.854 16.3575C15.2183 16.187 14.6387 15.8521 14.1734 15.3866C13.708 14.9211 13.3734 14.3413 13.2032 13.7055C13.0329 13.0698 13.033 12.4003 13.2034 11.7646C13.3739 11.1288 13.7086 10.5491 14.174 10.0838L17.5997 6.65626C18.3033 5.95261 19.2577 5.5573 20.2528 5.5573C21.2479 5.5573 22.2023 5.95261 22.9059 6.65626C23.6096 7.35991 24.0049 8.31427 24.0049 9.30938C24.0049 10.3045 23.6096 11.2589 22.9059 11.9625L21.419 13.4475C21.629 14.235 21.7097 15.0506 21.659 15.8606L24.2315 13.2881C25.2865 12.2332 25.8792 10.8023 25.8792 9.31032C25.8792 7.81835 25.2865 6.38749 24.2315 5.33251C23.1766 4.27753 21.7457 3.68484 20.2537 3.68484C18.7618 3.68484 17.3309 4.27753 16.2759 5.33251L12.8484 8.76001Z"
        fill={color}
      />
    </svg>
  );
};

CustomCopyIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

CustomCopyIcon.defaultProps = {
  color: "currentColor",
  size: "1em",
};

export default CustomCopyIcon;
