import React, { useState, createContext, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core";
import getTheme from "./base";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { customThemeColorsSelector } from "app/state/ducks/application/selectors";
export const ThemeContext = createContext();

const ThemeProvider = (props) => {
  // State to hold the selected theme name
  const [themeName, _setThemeName] = useState("back");
  const customThemeColors = useSelector(customThemeColorsSelector);

  let location = useLocation();
  const isLS = location.pathname.indexOf("/ls/") === -1 ? false : true;
  const isOTO = location.pathname.indexOf("/visio/") === -1 ? false : true;

  useEffect(() => {
    if (isLS) {
      _setThemeName("front");
    }
    if (isOTO) {
      _setThemeName("oto");
    }
  }, []);

  // Retrieve the theme object by theme name
  const theme = getTheme(themeName, customThemeColors);

  return (
    <ThemeContext.Provider value={_setThemeName}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
