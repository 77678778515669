import BaseModel from "./base";
import { attr, fk } from "redux-orm";

class SurveyItemAnswer extends BaseModel {
  constructor(args) {
    super(args);

    this.Model = SurveyItemAnswer;
  }

  setSurveyAnswer(SurveyAnswer) {
    this.surveyAnswer = SurveyAnswer.id;
  }
  setItem(Item) {
    this.item = Item.id;
  }
}

SurveyItemAnswer.modelName = "SurveyItemAnswer";
SurveyItemAnswer.backendName = "surveyItemAnswer";

SurveyItemAnswer.fields = {
  id: attr(),
  hash: attr(),
  value: attr(),
  surveyAnswer: fk("SurveyAnswer", "itemAnswers"),
  item: fk("Item", "itemAnswers"),
};

export default SurveyItemAnswer;
